/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import {
  Typography,
  Button,
  DataTable,
  DataTableColumn,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Zoom,
  Grid,
  Alert as AlertComponent
} from '@barracuda-internal/bds-core'
import { GridNoRecords as DataTableNoRecords, GridRowProps } from '@progress/kendo-react-grid'

import Pager from 'global/components/lib/grid/pager/Pager'
import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import { Alert } from 'global/types/api/ato'
import DEFAULTS, { COLORS } from 'global/configs/theme/defaults'
import { useFormatMessage, useErrorFormatMessage } from 'global/lib/localization'
import CircularProgress from 'global/components/lib/circularProgress/CircularProgress'
import { getCountryName } from 'global/lib/isoCountries'

import styles from 'sen/components/lib/dialogs/signins/signinsDialogStyles'
import useUserRelatedSigninsLogic, {
  ModifiedAlertSignin,
  UserRelatedAlert,
  DialogType as Types
} from 'sen/components/lib/dialogs/signins/useUserRelatedSigninsLogic'
import useCountryRelatedSigninsLogic, {
  Country
} from 'sen/components/lib/dialogs/signins/useCountryRelatedSigninsLogic'

export interface SigninsDialogProps {
  dialogType: Types
  alert?: Alert
  user?: UserRelatedAlert
  country?: Country
  onBack?: () => void
  onClose?: () => void
}

export { Types as DialogType }

const BASE_I18N_KEY = 'sen.app.signins_dialog'
const BASE_I18N_TABLE_KEY = 'sen.app.data_tables.alert_signins'

export const SigninsDialog: React.FC<SigninsDialogProps> = ({ alert, user, country, onBack, onClose, dialogType }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatMessageTable = useFormatMessage(BASE_I18N_TABLE_KEY)
  const formatMessageError = useErrorFormatMessage()
  const classes = styles()

  const countryRelatedSigninsLogic = useCountryRelatedSigninsLogic({
    country: country?.code || '',
    timeframe: country?.timeframe || 0,
    dialogType
  })
  const userRelatedSigninsLogic = useUserRelatedSigninsLogic({ alert, user, dialogType })

  const [isLoaded, inProgress, error, tableConfig, timeframe, limit] =
    dialogType === Types.Country ? countryRelatedSigninsLogic : userRelatedSigninsLogic

  const rowRender = (trElement: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
    const suspiciousSignin = !!props.dataItem.detectionType
    const highlightedBgColor = { backgroundColor: COLORS.SCALE_GRAY_100 }
    const trProps: any = suspiciousSignin ? { style: highlightedBgColor } : {}
    return React.cloneElement(trElement, { ...trProps }, trElement.props.children)
  }

  return useMemo(() => {
    return (
      <Dialog
        style={{ zIndex: DEFAULTS.DIALOGS_ZINDEX }}
        maxWidth="lg"
        fullWidth
        open
        TransitionComponent={Zoom}
        data-testid={`signins-dialog-${dialogType}`}
      >
        <DialogTitle>
          {error && (
            <AlertComponent
              className={classes.error}
              variant="filled"
              severity="error"
              data-testid="signins-dialog-alert"
            >
              {formatMessageError(error)}
            </AlertComponent>
          )}

          <Typography className={classes.dialogTitle} data-testid="country-name" data-country={country?.code}>
            {formatMessage(`${dialogType}_title`, {
              email: alert?.emailAddress || user?.emailAddress,
              country: getCountryName(country?.code || '') || 'Unknown',
              timeframe,
              b: (txt: string) => <b key={txt}>{txt}</b>
            })}
          </Typography>

          <Typography className={classes.dialogSubtitle}>
            {formatMessage(`${dialogType}_subtitle`, {
              br: (text: string) => (
                <data key={text}>
                  <br />
                  {text}
                </data>
              )
            })}
          </Typography>
        </DialogTitle>

        <DialogContent>
          {!isLoaded && (
            <Grid container justifyContent="center" className={classes.loading}>
              <CircularProgress />
            </Grid>
          )}
          {isLoaded && (
            <Grid
              className={classes[tableConfig.isFlexibleTable ? 'flexibleTableArea' : 'tableArea']}
              data-table="alert-signins"
              item
              xs={12}
            >
              {inProgress && <LinearProgress className={classes.indicator} />}

              <DataTable
                className={
                  classes[
                    tableConfig.tableData.total
                      ? tableConfig.isFlexibleTable
                        ? 'flexibleDataTable'
                        : 'dataTable'
                      : 'emptyDataTable'
                  ]
                }
                data={tableConfig.tableData}
                {...tableConfig.pageConfig}
                pager={(props: any) => <Pager limit={limit} {...props} />}
                rowRender={rowRender}
              >
                <DataTableNoRecords data-field="empty-table">
                  <Typography>{!tableConfig.tableData.total && formatMessageTable('empty_table')}</Typography>
                </DataTableNoRecords>

                <DataTableColumn
                  width={110}
                  field={tableConfig.columns.DATE}
                  {...tableConfig.columnsConfig[tableConfig.columns.DATE]}
                  title={formatMessageTable(tableConfig.columns.DATE)}
                  cell={({ dataItem }: { dataItem: ModifiedAlertSignin }) => (
                    <Cell className={classes.cell} data-field="table-column-date">
                      <CellText className={classes.normalCell} data-field="table-column-date-text">
                        {dataItem.formattedDate}
                      </CellText>
                    </Cell>
                  )}
                />
                {dialogType === Types.Country && (
                  <DataTableColumn
                    field={tableConfig.columns.ACCOUNT}
                    {...tableConfig.columnsConfig[tableConfig.columns.ACCOUNT]}
                    title={formatMessageTable(tableConfig.columns.ACCOUNT)}
                    cell={({ dataItem }: { dataItem: ModifiedAlertSignin }) => (
                      <Cell className={classes.cell} data-field="table-column-account">
                        <CellText className={classes.normalCell} data-field="table-column-account-text">
                          {dataItem.userPrincipalName}
                        </CellText>
                      </Cell>
                    )}
                  />
                )}
                <DataTableColumn
                  field={tableConfig.columns.IP}
                  {...tableConfig.columnsConfig[tableConfig.columns.IP]}
                  title={formatMessageTable(tableConfig.columns.IP)}
                  cell={({ dataItem }: { dataItem: ModifiedAlertSignin }) => (
                    <Cell className={classes.cell} data-field="table-column-ip">
                      <CellText className={classes.normalCell} data-field="table-column-ip-text">
                        {dataItem.ip}
                      </CellText>
                    </Cell>
                  )}
                />
                <DataTableColumn
                  field={tableConfig.columns.USER_AGENT}
                  {...tableConfig.columnsConfig[tableConfig.columns.USER_AGENT]}
                  title={formatMessageTable(tableConfig.columns.USER_AGENT)}
                  cell={({ dataItem }: { dataItem: ModifiedAlertSignin }) => (
                    <Cell className={classes.cell} data-field="table-column-user-agent">
                      <CellText className={classes.normalCell} data-field="table-column-user-agent-text">
                        {dataItem.userAgent}
                      </CellText>
                    </Cell>
                  )}
                />
                {[Types.Alert, Types.User].includes(dialogType) && (
                  <DataTableColumn
                    field={tableConfig.columns.LOCATION}
                    {...tableConfig.columnsConfig[tableConfig.columns.LOCATION]}
                    title={formatMessageTable(tableConfig.columns.LOCATION)}
                    cell={({ dataItem }: { dataItem: ModifiedAlertSignin }) => (
                      <Cell className={classes.cell} data-field="table-column-location">
                        <CellText className={classes.normalCell} data-field="table-column-location-text">
                          {dataItem.countryName}
                        </CellText>
                      </Cell>
                    )}
                  />
                )}
                {
                  ([Types.User, Types.Country].includes(dialogType) && (
                    <DataTableColumn
                      field={tableConfig.columns.STATUS}
                      {...tableConfig.columnsConfig[tableConfig.columns.STATUS]}
                      title={formatMessageTable(tableConfig.columns.STATUS)}
                      cell={({ dataItem }: { dataItem: ModifiedAlertSignin }) => (
                        <Cell className={classes.cell} data-field="table-column-status">
                          <CellText className={classes.normalCell} data-field="table-column-status-text">
                            {formatMessage(dataItem.status ? 'success' : 'failure')}
                          </CellText>
                        </Cell>
                      )}
                    />
                  )) as any
                }
              </DataTable>
            </Grid>
          )}
        </DialogContent>

        <DialogActions>
          {onBack && (
            <Button color="primary" size="large" disabled={inProgress} onClick={onBack} variant="text">
              &nbsp;&nbsp;{formatMessage('back')}&nbsp;&nbsp;
            </Button>
          )}
          {onClose && (
            <Button color="primary" variant="contained" size="large" disabled={inProgress} onClick={onClose}>
              &nbsp;&nbsp;{formatMessage('close')}&nbsp;&nbsp;
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  }, [
    classes,
    formatMessage,
    onClose,
    onBack,
    alert,
    tableConfig,
    inProgress,
    formatMessageTable,
    isLoaded,
    error,
    formatMessageError,
    timeframe,
    dialogType,
    user,
    limit,
    country
  ])
}

SigninsDialog.propTypes = {
  alert: PropTypes.any,
  user: PropTypes.any,
  onClose: PropTypes.func,
  onBack: PropTypes.func
}

SigninsDialog.defaultProps = {
  alert: undefined,
  user: undefined,
  onBack: undefined,
  onClose: undefined
}

export default SigninsDialog
