import userDataLib from 'global/lib/userData/userData'
import accessTokenLib from 'global/lib/accessToken/accessToken'

import * as configurationLib from 'global/lib/configuration/configuration'
import * as analyticsLib from 'global/lib/analytics/analyticsService'

import { config } from 'global/lib/config'
import { User, SignUpType } from 'global/types/api/userType'

declare global {
  export interface Window {
    Intercom: any
  }
}

export interface Flags {
  [key: string]: string
}

/* eslint-disable @typescript-eslint/naming-convention */
const { intercomAppId } = config
const isIntercomDefined = !!window.Intercom && intercomAppId

export function initialize() {
  // Initialize Intercom if there is an AppID and it is not running on dev environment
  if (isIntercomDefined) {
    const productAbbr = `${configurationLib.getProductAbbr()}-user`
    const intercomData: any = {
      app_id: intercomAppId,
      hide_default_launcher: true
    }
    intercomData[productAbbr] = true

    if (userDataLib.hasUser()) {
      const user = userDataLib.getUser()
      intercomData.user_id = user.id
      intercomData.user_hash = user.intercom_hash
    }

    if (isIntercomDefined) {
      window.Intercom('boot', intercomData)
    }
  }
}

export function setUser(user: User, signUp?: SignUpType): void {
  if (isIntercomDefined) {
    const productAbbr = `${configurationLib.getProductAbbr()}-user`
    const intercomData: any = {
      app_id: intercomAppId,
      user_id: user.id,
      bcc_user_id: user.bccUserId,
      email: user.email,
      user_hash: user.intercom_hash,
      name: user.displayName,
      state: user.state,
      zip_code: user.zip
    }
    intercomData[productAbbr] = true
    if (signUp) {
      intercomData.signed_up_from = signUp.signedUpFrom
      intercomData.signed_up_bcc_id = signUp.signedUpBccId
    }
    if (user.accounts) {
      intercomData.companies = user.accounts.flatMap(account =>
        account.accessTokens.reduce(
          (
            all: {
              bcc_account_id: string
              msp_managed: boolean
              id: string
              name: string
              ets: boolean
              ip: boolean
              ir: boolean
            }[],
            flatAccount
          ) => [
            ...all,
            {
              bcc_account_id: account.bccId,
              msp_managed: account.mspManaged,
              id: flatAccount.id,
              name: flatAccount.name,
              ets: flatAccount.products.includes(config.PRODUCTS.ETS),
              ip: flatAccount.products.includes(config.PRODUCTS.SENTINEL),
              ir: flatAccount.products.includes(config.PRODUCTS.FORENSICS)
            }
          ],
          []
        )
      )
      // grab the AT from the URL and use that to get account info
      // otherwise use the first account
      const accessTokenId = accessTokenLib.getAccessTokenFromUrl()
      const account = accessTokenId ? userDataLib.getAccountByAccessToken(accessTokenId) : undefined

      intercomData.url_access_token = accessTokenId
      intercomData.product_account_id = account ? account.accountId : user.accounts[0].accountId
      intercomData.bcc_account_id = account ? account.bccId : user.accounts[0].bccId

      intercomData.bos_serial = accessTokenId ? analyticsLib.getSerialNumber(accessTokenId) : undefined
    }

    window.Intercom('update', intercomData)
  }
}

export function setUserFlags(user: User, flags: Flags, includeUnderscores = true) {
  if (isIntercomDefined) {
    const productAbbr: any = `${configurationLib.getProductAbbr()}-user`
    const intercomData: any = Object.keys(flags).reduce(
      (all: Flags, flagName: any) => {
        return {
          ...all,
          [`__${flagName}`]: flags[flagName]
        }
      },
      {
        app_id: intercomAppId,
        user_id: user.id,
        user_hash: user.intercom_hash || ''
      }
    )
    intercomData[productAbbr] = true

    window.Intercom('update', intercomData)
  }
}

export function trackEvent(eventName: string, metadata?: {}) {
  if (isIntercomDefined) {
    window.Intercom('trackEvent', userDataLib.isImpersonationMode() ? `[imp] ${eventName}` : eventName, metadata)
  }
}

export function trackEventDelayed(eventName: string, metadata?: {}) {
  window.setTimeout(() => {
    trackEvent(eventName, metadata)
  })
}

export function openNewMessage() {
  if (isIntercomDefined) {
    window.Intercom('showNewMessage')
  }
}
