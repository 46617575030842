import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography
} from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'global/lib/localization'

interface RenameReportDialogProps {
  open: boolean
  onClose: () => void
  onSave: () => void
  onUpdate: (e: any) => void
  reportName: string
}

const BASE_I18N_KEY = 'app.unified_reporting.rename_report_dialog'

const RenameReportDialog: React.FC<RenameReportDialogProps> = ({ open, onClose, onSave, onUpdate, reportName }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{formatMessage('title')}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{formatMessage('subtitle')}</Typography>
        <TextField
          autoFocus
          margin="dense"
          label={formatMessage('label')}
          fullWidth
          value={reportName}
          onChange={onUpdate}
        />
      </DialogContent>
      <DialogActions>
        <Button size="large" color="primary" variant="text" onClick={onClose}>
          {formatMessage('buttons.cancel')}
        </Button>
        <Button size="large" color="primary" variant="contained" onClick={onSave}>
          {formatMessage('buttons.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RenameReportDialog
