import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Tooltip } from '@barracuda-internal/bds-core'

import { COLORS } from 'global/configs/theme/defaults'
import TextLabel, { LabelColors } from 'global/components/lib/textLabel/TextLabel'
import { useFormatMessage } from 'global/lib/localization'

import styles from 'global/components/lib/dmarcTypeLabel/dmarcTypeLabelStyles'

export interface DmarcTypeLabelProps {
  status: StatusIds | undefined
}

const BASE_STATUS_TYPES_I18N_KEY = 'app.dmarc_statuses'

export enum StatusIds {
  unverified = 'unverified',
  notConfigured = 'not_configured',
  reportingOnly = 'reporting_only',
  protected = 'protected'
}

export enum StatusColors {
  caution = 'caution',
  warning = 'warning',
  success = 'success'
}

export type StatusConfig = {
  [key in StatusIds]: StatusColors
}

const STATUS_COLORS: StatusConfig = {
  // caution
  [StatusIds.reportingOnly]: StatusColors.caution,

  // warning
  [StatusIds.unverified]: StatusColors.warning,
  [StatusIds.notConfigured]: StatusColors.warning,

  // success
  [StatusIds.protected]: StatusColors.success
}

const COLOR_VALUES = {
  [StatusColors.caution]: COLORS.ORANGE_400,
  [StatusColors.warning]: COLORS.RED_600,
  [StatusColors.success]: COLORS.TEAL_600
}

const DmarcTypeLabel: React.FC<DmarcTypeLabelProps> = ({ status }) => {
  const formatMessage = useFormatMessage(BASE_STATUS_TYPES_I18N_KEY)
  const classes = styles()

  return useMemo(() => {
    if (!status) {
      return null
    }

    const bgColor = STATUS_COLORS[status]

    return (
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        title={formatMessage(`${String(status.toLowerCase())}_tooltip`)}
        placement="top-start"
      >
        <data>
          <TextLabel color={LabelColors.light} bgColor={COLOR_VALUES[bgColor]}>
            {formatMessage(String(status.toLowerCase()))}
          </TextLabel>
        </data>
      </Tooltip>
    )
  }, [status, formatMessage, classes])
}

DmarcTypeLabel.propTypes = {
  status: PropTypes.any
}

DmarcTypeLabel.defaultProps = {
  status: undefined
}

export default DmarcTypeLabel
