import { createAsyncThunk } from '@reduxjs/toolkit'

import * as dmarcService from 'global/lib/domain/dmarc'
import { AttacksQueryParams } from 'global/types/api/attacksQueryParamsType'
import { buildQueryFromState } from 'global/redux/features/dataTables/buildQueryForTable'
import { AccessToken } from 'global/types/api/accessTokenType'
import restClient, { validateApiError } from 'global/lib/api/restClient'
import { ApiRoute } from 'global/lib/api/apiRoutes'
import { RootState } from 'ets/redux/toolkit/store'

import sortDomains from 'ets/lib/sortDomains'
import apiRoutes from 'ets/lib/api/apiRoutes'

export interface GetChartsReports {
  accessTokenId?: AccessToken['id']
}
export interface GetReport {
  accessTokenId?: AccessToken['id']
  query?: AttacksQueryParams
}
export interface DeactivateReportSharing {
  accessTokenId: AccessToken['id']
  secretId?: string
}
export interface ConvertETS2Sentinel {
  isSettingsConversion?: boolean
  settings: any
  accessTokenId: AccessToken['id']
  email: string
  accountId: string
}
export interface SetAccessTokenSettingsConvert {
  settings: any
  accessTokenId: AccessToken['id']
  accountId: string
  email: string
}

export interface RerunCurrentScan {
  accessTokenId: AccessToken['id']
  accountId: string
  email: string
}

export interface ReportRouteConfig {
  route: ApiRoute
  payloadType: string
}

const PAYLOAD_TYPES = {
  DATA: 'data',
  PDF_DATA: 'pdfData'
}

export const DEFAULT_QUERY = {
  offset: 0,
  limit: 100,
  filter: ['isBootstrap:true'],
  search: undefined,
  order: undefined
}

export const getChartsReports = createAsyncThunk('REPORTS/getChartsReports', async function doGetChartsReports(
  payload: GetChartsReports,
  { rejectWithValue }
) {
  const { accessTokenId } = payload
  const body = {
    accessTokenId,
    query: { ...DEFAULT_QUERY }
  }
  try {
    const resp = await restClient(apiRoutes.ETS_CHARTS_REPORTS, {
      data: body
    })
    return {
      ...resp.data,
      attacksPerMonth: { report: resp.data.spAttacksPerMonth },
      attacksByThreats: { report: resp.data.spAttacksByTaxonomy },
      employeesWithThreatsPerMonth: { report: resp.data.spAttacksIdentitiesPerMonth }
    }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export async function getReport(
  payload: GetReport | undefined,
  routeConfig: ReportRouteConfig,
  baseQuery: AttacksQueryParams = {},
  reject: (value: any) => any,
  baseAccessTokenId?: string,
  successCb: (resp: any) => any = resp => resp.data
) {
  const { accessTokenId, query = {} } = payload || {}
  const body = {
    accessTokenId: baseAccessTokenId || accessTokenId,
    query: { ...baseQuery, ...query }
  }

  try {
    const resp = await restClient(routeConfig.route, {
      data: body
    })
    return { report: successCb(resp), offset: body.query.offset, query: body.query }
  } catch (e) {
    return reject('unable_to_load_threats')
  }
}

export const getAttacksByDomainReport = createAsyncThunk(
  'REPORTS/getAttacksByDomainReport',
  async function doGetAttacksByDomainReport(payload: GetReport | undefined, { rejectWithValue, getState }) {
    const { accessToken, dataTables } = getState() as RootState
    const query = buildQueryFromState(dataTables.attackingDomains)

    return getReport(
      payload,
      {
        route: apiRoutes.SP_ATTACKS_BY_DOMAIN,
        payloadType: PAYLOAD_TYPES.DATA
      },
      query,
      rejectWithValue,
      accessToken?.accessToken?.id
    )
  }
)

export const getPDFAttacksByDomainReport = createAsyncThunk(
  'REPORTS/getPDFAttacksByDomainReport',
  async function doGetPDFAttacksByDomainReport(payload: GetReport | undefined, { rejectWithValue, getState }) {
    const { accessToken, dataTables } = getState() as RootState
    const query = buildQueryFromState(dataTables.attackingDomains, true)

    return getReport(
      payload,
      {
        route: apiRoutes.SP_ATTACKS_BY_DOMAIN,
        payloadType: PAYLOAD_TYPES.PDF_DATA
      },
      query,
      rejectWithValue,
      accessToken?.accessToken?.id
    )
  }
)

export const getAttacksByThreatsReport = createAsyncThunk(
  'REPORTS/getAttacksByThreatsReport',
  async function doGetAttacksByThreatsReport(payload: GetReport, { rejectWithValue, getState }) {
    const accessTokenId = (getState() as RootState).accessToken?.accessToken?.id

    return getReport(
      payload,
      {
        route: apiRoutes.SP_ATTACKS_BY_TAXONOMY,
        payloadType: PAYLOAD_TYPES.DATA
      },
      DEFAULT_QUERY,
      rejectWithValue,
      accessTokenId
    )
  }
)

export const getAttacksPerMonthReport = createAsyncThunk(
  'REPORTS/getAttacksPerMonthReport',
  async function doGetAttacksPerMonthReport(payload: GetReport, { rejectWithValue, getState }) {
    const accessTokenId = (getState() as RootState).accessToken?.accessToken?.id

    return getReport(
      payload,
      {
        route: apiRoutes.SP_ATTACKS_PER_MONTH,
        payloadType: PAYLOAD_TYPES.DATA
      },
      DEFAULT_QUERY,
      rejectWithValue,
      accessTokenId
    )
  }
)

export const getDomainsReport = createAsyncThunk('REPORTS/getDomainsReport', async function doGetDomainsReport(
  payload: GetReport,
  { rejectWithValue, getState }
) {
  const { accessToken, dataTables } = getState() as RootState
  const query = buildQueryFromState(dataTables.domains)

  return getReport(
    payload,
    {
      route: apiRoutes.COMPANY_DOMAINS,
      payloadType: PAYLOAD_TYPES.DATA
    },
    query,
    rejectWithValue,
    accessToken?.accessToken?.id,
    resp => ({
      ...resp.data,
      report: {
        ...resp.data.report,
        data: sortDomains(
          resp.data.report.data.map((reportItem: any) => ({
            ...reportItem,
            dmarcState: dmarcService.getETSDmarcState(reportItem.dmarc)
          }))
        )
      }
    })
  )
})

export const getEmployeesReport = createAsyncThunk('REPORTS/getEmployeesReport', async function doGetEmployeesReport(
  payload: GetReport | undefined,
  { rejectWithValue, getState }
) {
  const { accessToken, dataTables } = getState() as RootState
  const query = buildQueryFromState(dataTables.employees)

  return getReport(
    payload,
    {
      route: apiRoutes.SP_COUNTS,
      payloadType: PAYLOAD_TYPES.DATA
    },
    query,
    rejectWithValue,
    accessToken?.accessToken?.id
  )
})

export const getPDFEmployeesReport = createAsyncThunk(
  'REPORTS/getPDFEmployeesReport',
  async function doGetPDFEmployeesReport(payload: GetReport | undefined, { rejectWithValue, getState }) {
    const { accessToken, dataTables } = getState() as RootState
    const query = buildQueryFromState(dataTables.employees, true)

    return getReport(
      payload,
      {
        route: apiRoutes.SP_COUNTS,
        payloadType: PAYLOAD_TYPES.PDF_DATA
      },
      query,
      rejectWithValue,
      accessToken?.accessToken?.id
    )
  }
)

export const getEmployeesWithThreatsByMonthReport = createAsyncThunk(
  'REPORTS/getEmployeesWithThreatsByMonthReport',
  async function doGetEmployeesWithThreatsByMonthReport(payload: GetReport, { rejectWithValue, getState }) {
    const accessTokenId = (getState() as RootState).accessToken?.accessToken?.id

    return getReport(
      payload,
      {
        route: apiRoutes.SP_ATTACKS_IDENTITIES_PER_MONTH,
        payloadType: PAYLOAD_TYPES.DATA
      },
      DEFAULT_QUERY,
      rejectWithValue,
      accessTokenId
    )
  }
)

export const getThreatsReport = createAsyncThunk('REPORTS/getThreatsReport', async function doGetThreatsReport(
  payload: GetReport | undefined,
  { rejectWithValue, getState }
) {
  const { accessToken, dataTables } = getState() as RootState
  const query = buildQueryFromState(dataTables.threats)

  return getReport(
    payload,
    {
      route: apiRoutes.SP_ATTACKS_GROUPED,
      payloadType: PAYLOAD_TYPES.DATA
    },
    query,
    rejectWithValue,
    accessToken?.accessToken?.id
  )
})

export const getPDFThreatsReport = createAsyncThunk('REPORTS/getPDFThreatsReport', async function doGetPDFThreatsReport(
  payload: GetReport | undefined,
  { rejectWithValue, getState }
) {
  const { accessToken, dataTables } = getState() as RootState
  const query = buildQueryFromState(dataTables.threats, true)

  return getReport(
    payload,
    {
      route: apiRoutes.SP_ATTACKS_GROUPED,
      payloadType: PAYLOAD_TYPES.PDF_DATA
    },
    query,
    rejectWithValue,
    accessToken?.accessToken?.id
  )
})

export const getThreatsByUsernameReport = createAsyncThunk(
  'REPORTS/getThreatsByUsernameReport',
  async function doGetThreatsByUsernameReport(payload: GetReport | undefined, { rejectWithValue, getState }) {
    const { accessToken, dataTables } = getState() as RootState

    const query = buildQueryFromState(dataTables.userEmails)

    return getReport(
      payload,
      {
        route: apiRoutes.SP_ATTACKS_ALL,
        payloadType: PAYLOAD_TYPES.DATA
      },
      query,
      rejectWithValue,
      accessToken?.accessToken?.id
    )
  }
)
