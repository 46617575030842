import React from 'react'
import PropTypes from 'prop-types'

import { Button, Dialog, DialogActions, DialogContent, Zoom } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'global/lib/localization'
import AdditionalActions from './pages/AdditionalActions'

const BASE_I18N_KEY = 'fir.app.follow_up'

export interface Props {
  isOpened: boolean
  onClose: any
  contentConfig: any
}
export type AdditionalActionsDialogProps = Props

const AdditionalActionsDialog: React.FC<Props> = ({ isOpened = false, onClose, contentConfig }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return (
    <Dialog fullWidth maxWidth="lg" open={isOpened} TransitionComponent={Zoom}>
      <DialogContent>
        <AdditionalActions {...contentConfig} />
        <DialogActions>
          <Button onClick={onClose} color="primary" variant="contained">
            {formatMessage('buttons.close')}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

AdditionalActionsDialog.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  contentConfig: PropTypes.any.isRequired
}

export default AdditionalActionsDialog
