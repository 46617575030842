import React, { useCallback, useMemo, useState } from 'react'
import { FilterOperation } from '@barracuda/reporting'
import {
  FormControl,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectLabel,
  SelectWrapper,
  TextField
} from '@barracuda-internal/bds-core'
import { Filter } from '@barracuda-internal/bds-core/dist/Icons/Core'

import { Filter as FilterType } from 'global/types/api/unifiedReporting'

export interface Condition {
  label: string
  value: string
}

interface ConditionFilterProps {
  columnId: string
  columnFilter?: FilterType
  conditionLabel: string
  conditions: Condition[]
  setColumnFilter: (filterValue: FilterType) => void
  handleRemoveColumnFilter: (columnId: string) => void
}

const ConditionInputFilter: React.FC<ConditionFilterProps> = ({
  columnId,
  columnFilter,
  conditionLabel,
  conditions,
  handleRemoveColumnFilter,
  setColumnFilter
}) => {
  const [conditionValue, setConditionValue] = useState<string>(columnFilter?.operation || conditions[0].value)
  const [filterValue, setFilterValue] = useState<string>(columnFilter?.value || '')
  const [showInput, setShowInput] = useState<boolean>(true)

  const handleSelectChange = useCallback(
    (e: any) => {
      // If the operation is 'BLANK' or 'NOT_BLANK' no value is needed and we send the filter to the backend
      if (e.target.value === FilterOperation.BLANK || e.target.value === FilterOperation.NOT_BLANK) {
        setShowInput(false)
        setConditionValue(e.target.value)
        setFilterValue('')
        setColumnFilter({ fieldName: columnId, value: null, operation: e.target.value })
        return
      }

      setShowInput(true)
      setConditionValue(e.target.value)
    },
    [columnId, setColumnFilter]
  )

  const handleFilterChange = useCallback(
    (e: any) => {
      setFilterValue(e.target.value)
    },
    [setFilterValue]
  )

  return useMemo(
    () => (
      <>
        <MenuItem key="title">
          <ListItemIcon>
            <Filter fontSize="small" />
          </ListItemIcon>
          <ListItemText>Filter</ListItemText>
        </MenuItem>

        <Grid container justifyContent="center" style={{ paddingBottom: '15px', marginBottom: '5px' }}>
          <FormControl style={{ width: '85%' }}>
            <SelectWrapper variant="outlined">
              <SelectLabel id="condition-filter-outlined-label">{conditionLabel}</SelectLabel>
              <Select label="Condition" value={conditionValue} onChange={handleSelectChange}>
                {conditions.map((c: Condition) => (
                  <MenuItem key={c.value} value={c.value}>
                    {c.label}
                  </MenuItem>
                ))}
              </Select>
            </SelectWrapper>
            {/* we remove the input box if the operation is 'BLANK' or 'NOT_BLANK' */}
            {showInput && (
              <TextField
                label="Value"
                id="condition-filter-value"
                onChange={handleFilterChange}
                value={filterValue}
                // The onKeyDown prop is neeeded to prevent BNFIR-2603
                onKeyDown={(event: any) => {
                  event.stopPropagation()
                  if (event.key === 'Enter') {
                    if (filterValue.length !== 0) {
                      setColumnFilter({ fieldName: columnId, value: filterValue, operation: conditionValue })
                    } else {
                      handleRemoveColumnFilter(columnId)
                    }
                  }
                }}
              />
            )}
          </FormControl>
        </Grid>
      </>
    ),
    [
      conditionLabel,
      conditionValue,
      handleSelectChange,
      conditions,
      showInput,
      handleFilterChange,
      filterValue,
      setColumnFilter,
      columnId,
      handleRemoveColumnFilter
    ]
  )
}

export default ConditionInputFilter
