import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  wrapper: {},
  textWrapper: {
    borderRadius: 15,
    display: 'inline-block',
    padding: '3px 14px'
  },
  enabled: {
    '& div': {
      background: theme.palette.success.main,
      color: theme.palette.success.contrastText
    }
  },
  disabled: {
    '& div': {
      background: theme.palette.info.main,
      color: theme.palette.info.contrastText
    }
  }
}))
