import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { validateApiError } from 'global/lib/api/restClient'

import apiRoutes from 'sen/lib/api/apiRoutes'

export const cleanupAllThreats = createAsyncThunk('CLEANUP/cleanupAllThreats', async function doCleanupAllThreats(
  _,
  { rejectWithValue }
) {
  try {
    const resp = await restClient(apiRoutes.CLEANUP_ALL_THREATS, {
      data: {}
    })

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})
