/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react'

import { Grid, Alert, Button } from '@barracuda-internal/bds-core'
import { Back } from '@barracuda-internal/bds-core/dist/Icons/Core'

import { useFormatMessage, useErrorFormatMessage } from 'global/lib/localization'
import Loading from 'global/components/lib/loading/Loading'

import styles from 'sen/components/pages/domainFraudDmarcReview/content/domainFraudDmarcReviewContentStyles'
import { DmarcReviewTabs } from 'sen/components/pages/domainFraudDmarcReview/domainFraudDmarcReviewTypes'
import useDomainFraudDmarcReviewContentLogic from 'sen/components/pages/domainFraudDmarcReview/content/useDomainFraudDmarcReviewContentLogic'
import DmarcReviewSources from 'sen/components/pages/domainFraudDmarcReview/content/sections/DmarcReviewSources'
import DmarcReviewTopCountries from 'sen/components/pages/domainFraudDmarcReview/content/sections/DmarcReviewTopCountries/DmarcReviewTopCountries'
import DmarcReviewYourDnsConfiguration from 'sen/components/pages/domainFraudDmarcReview/content/sections/DmarcReviewYourDnsConfiguration/DmarcReviewYourDnsConfiguration'
import ContentLayout from 'sen/components/lib/layout/ContentLayout'
import Layout from 'sen/components/lib/layout/Layout'

export interface DomainFraudDmarcReviewContentProps {
  activeTab: DmarcReviewTabs
}

const BASE_I18N_KEY = 'sen.app.domain_fraud_dmarc_review'

const DomainFraudDmarcReviewContent: React.FC<DomainFraudDmarcReviewContentProps> = ({ activeTab }) => {
  const classes = styles()
  const [logicConfig, eventHandlers] = useDomainFraudDmarcReviewContentLogic()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const errorFormatMessage = useErrorFormatMessage()

  return useMemo(() => {
    return (
      <Layout
        pageTitle={logicConfig.domain ? logicConfig.domain.name : ''}
        rightContent={
          <Button
            className={classes.mastHeadButton}
            size="medium"
            variant="outlined"
            color="primary"
            startIcon={<Back />}
            onClick={eventHandlers.onBackToDomains}
          >
            {formatMessage('back_to_domains')}
          </Button>
        }
      >
        <ContentLayout>
          <Grid className={classes.container} container direction="column" data-field="root-grid">
            {logicConfig.error && (
              <Alert className={classes.error} severity="error" data-field="dmarc-review-alert">
                {errorFormatMessage(logicConfig.error)}
              </Alert>
            )}

            {!logicConfig.domain && <Loading />}
            {logicConfig.domain && (
              <Grid container direction="column">
                <Grid container direction="row" spacing={2}>
                  <Grid item md={12} lg={8}>
                    <DmarcReviewSources domain={logicConfig.domain} activeTab={activeTab} />
                  </Grid>
                  <Grid item md={12} lg={4}>
                    <Grid container direction="column">
                      <DmarcReviewTopCountries />
                      <br />
                      <DmarcReviewYourDnsConfiguration companyDomain={logicConfig.domain} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </ContentLayout>
      </Layout>
    )
  }, [classes, activeTab, logicConfig, eventHandlers, formatMessage, errorFormatMessage])
}

export default DomainFraudDmarcReviewContent
