import { COLORS } from 'global/configs/theme/defaults'

export default {
  title: {
    fontSize: 18,
    fontWeight: 600
  },
  input: {
    height: 32,
    padding: 7,
    width: '100%',
    borderWidth: 1,
    borderColor: '#7F7F7F',

    '&:focus': {
      outlineColor: '#3887c8'
    },

    '&[data-error="true"]': {
      outlineColor: COLORS.ERROR,
      borderColor: COLORS.ERROR
    }
  },
  inputTitle: {
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 5
  },
  blueText: {
    fontWeight: 400,
    cursor: 'pointer',
    color: '#5199d0',
    textDecoration: 'none'
  },
  blueButton: {
    height: 38,
    color: 'white !important',
    backgroundColor: '#3B86C9 !important',

    '&[disabled]': {
      backgroundColor: '#B2B2B2 !important'
    }
  },
  o365Button: {
    width: '100%',
    color: 'white !important',
    backgroundColor: `${COLORS.ORANGE} !important`
  },
  o365Icon: {
    width: 'auto',
    height: 24,
    marginRight: 8
  }
}
