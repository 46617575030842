import { makeStyles } from '@material-ui/core/styles'
import { COLORS } from 'global/configs/theme/defaults'

import tableStyles, {
  CELL_HEIGHT,
  TABLE_HEADER_HEIGHT,
  TABLE_FOOTER_HEIGHT
} from 'global/components/lib/grid/tableStyles'
import dialogStyles from 'global/components/lib/dialogs/dialogStyles'

export { TABLE_HEADER_HEIGHT, TABLE_FOOTER_HEIGHT }
export const TABLE_CELL_HEIGHT = CELL_HEIGHT
const TABLE_MIN_HEIGHT = TABLE_CELL_HEIGHT * 5 + TABLE_HEADER_HEIGHT + TABLE_FOOTER_HEIGHT

export default makeStyles(theme => {
  const defaultTableStyles = tableStyles(theme)
  const defaultDialogStyles = dialogStyles(theme)
  const dataTable: any = {
    ...defaultTableStyles.dataTable,
    height: TABLE_MIN_HEIGHT,

    '& td': {
      lineHeight: `${theme.spacing(2.5)}px`
    }
  }

  return {
    ...defaultDialogStyles,
    indicatorForm: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0
    },
    paper: {
      overflowY: 'auto'
    },
    dialogContent: {
      height: 'auto'
    },
    dialogWrapper: {
      paddingBottom: theme.spacing(2.5)
    },
    dialogStepWrapper: {
      paddingTop: 0
    },
    dialogButtons: {
      marginTop: theme.spacing(2)
    },
    paddedTitle: {
      ...defaultDialogStyles.dialogTitle,
      marginBottom: theme.spacing(2)
    },
    /* Block account step */
    listItem: {
      '&::marker': {
        color: COLORS.BLUE,
        fontSize: '20px'
      }
    },
    text: {
      ...defaultDialogStyles.text,
      fontWeight: 400
    },
    grayText: {
      ...defaultDialogStyles.text,
      fontWeight: 400,
      color: COLORS.GRAY
    },
    redText: {
      ...defaultDialogStyles.text,
      fontWeight: 400,
      color: COLORS.RED
    },
    listItemDescription: {
      ...defaultDialogStyles.subtitle,
      marginTop: '0px'
    },
    signInButton: {
      marginLeft: theme.spacing(3)
    },

    /* New Incident Init step */
    initList: {
      listStyle: 'auto',
      paddingLeft: theme.spacing(2)
    },
    initListItem: {
      paddingBottom: theme.spacing(1)
    },

    /* New Incident Form Step */
    error: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2)
    },
    formControl: {
      width: '100%'
    },
    textField: {
      ...defaultDialogStyles.dialogText,
      width: '100%'
    },
    inputLabel: {
      fontSize: 12,
      color: theme.palette.text.secondary
    },
    /* New Incident Confirm Step */
    ...defaultTableStyles,
    tableArea: {
      ...defaultTableStyles.tableArea,
      minHeight: TABLE_MIN_HEIGHT
    },
    flexibleTableArea: {
      ...defaultTableStyles.tableArea,
      minHeight: 'auto',
      marginBottom: theme.spacing(2)
    },
    infoContainer: {
      marginTop: theme.spacing(1)
    },
    dataTable,
    flexibleDataTable: {
      ...dataTable,
      height: 'auto'
    },
    icon: {
      verticalAlign: -5,
      color: COLORS.BLACK_50,
      cursor: 'pointer'
    },
    tooltip: {
      backgroundColor: COLORS.BLACK_50,
      fontSize: 12,
      letterSpacing: 0,
      lineHeight: '16px',
      boxShadow: '0 2px 5px 0 rgba(0,0,0,0.26), 0 2px 10px 0 rgba(0,0,0,0.16)'
    },
    /* Reset password */
    spacer: {
      paddingTop: theme.spacing(1)
    },
    redDescriptionText: {
      ...defaultDialogStyles.text,
      fontSize: 14,
      color: COLORS.RED
    },
    copyText: {
      fontSize: 10,
      display: 'inline-block',
      textAlign: 'center'
    },
    /* Inbox Rules */
    contentLoading: {
      marginTop: theme.spacing(2)
    },
    checkcircleIcon: {
      fontSize: '72px',
      color: COLORS.BLUE
    },
    /* Quarantine */
    radioButtonLabels: {
      fontSize: 14
    },
    /* Notify */
    editEmailButton: {
      marginTop: theme.spacing(3)
    },
    iframe: {
      display: 'none'
    },
    /* Done */
    spacerBottom: {
      paddingBottom: theme.spacing(2)
    }
  }
})
