import { createStyles, Theme } from '@material-ui/core/styles'

import { COLORS } from 'global/configs/theme/defaults'

export const CELL_HEIGHT = 61
export const TABLE_HEADER_HEIGHT = 55
export const TABLE_FOOTER_HEIGHT = 60
export const EMPTY_TABLE_HEIGHT = 335

export const INDICATOR_HEIGHT = 4

const cellText = {
  fontSize: 14,
  letterSpacing: 0,
  lineHeight: '16px'
}

export default function defaultTableStyles(
  theme: Theme,
  cellHeight = CELL_HEIGHT,
  emptyTableHeight = EMPTY_TABLE_HEIGHT
) {
  return createStyles({
    indicator: {
      position: 'absolute',
      top: -INDICATOR_HEIGHT,
      left: 0,
      right: 0
    },

    tableArea: {
      position: 'relative',
      marginTop: INDICATOR_HEIGHT,

      '& .k-grid-content': {
        '-ms-overflow-style': 'none',
        scrollbarWidth: 'none'
      },

      '& ::-webkit-scrollbar': {
        display: 'none'
      },

      '&.empty-table .k-grid-content': {
        overflow: 'hidden'
      },

      '& .k-grid': {
        border: 0,

        '& ::selection': {
          backgroundColor: `${COLORS.SELECTION.BG_COLOR} !important`,
          color: `${COLORS.SELECTION.COLOR} !important`
        },

        '& tr:hover': {
          backgroundColor: 'transparent'
        },

        '& tr.faded-row': {
          backgroundColor: COLORS.LIGHT_BACKGROUND
        },

        '& tr.deleted-row': {
          backgroundColor: COLORS.LIGHT_RED,

          '& > td': {
            opacity: 0.5
          }
        },

        '& th': {
          textTransform: 'none',
          fontWeight: theme.font.weight.regular,
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1)
        },
        '& td': {
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1)
        }
      },
      '& .k-grid-header': {
        border: 0,
        paddingRight: '0 !important'
      },
      '& .k-grid-header-wrap': {
        border: 0
      },
      '& .k-pager-wrap': {
        borderColor: 'rgba(0,0,0,0.12)',
        color: theme.palette.text.primary,
        paddingLeft: 0,
        paddingRight: 0
      },
      '& .k-pager-numbers .k-link': {
        color: theme.palette.text.primary,
        fontWeight: theme.font.weight.bold,

        '&.k-state-selected': {
          color: 'white',
          backgroundColor: COLORS.BLUE
        }
      }
    },
    dataTable: {
      '& .k-grid-norecords': {
        display: 'none'
      },

      '& th': {
        fontWeight: theme.font.weight.medium,
        paddingBottom: 5
      }
    },
    emptyDataTable: {
      '& .k-grid-norecords': {
        backgroundColor: 'rgba(0,0,0,0.04)',
        height: emptyTableHeight,

        '&:hover': {
          backgroundColor: 'rgba(0,0,0,0.04) !important'
        }
      },

      '& th': {
        fontWeight: theme.font.weight.medium,
        paddingBottom: 5
      }
    },
    tableLoadIndicator: {
      position: 'absolute',
      top: -4,
      left: 0,
      right: 0,
      zIndex: 1
    },
    cell: {
      height: cellHeight - 20
    },
    flexibleCell: {
      height: 'auto'
    },
    boldCell: {
      ...cellText,
      fontWeight: 500
    },
    redBoldCell: {
      ...cellText,
      fontWeight: 500,
      color: COLORS.RED
    },
    normalCell: {
      ...cellText
    },
    lightCell: {
      ...cellText,
      color: theme.palette.text.secondary
    },
    successCell: {
      ...cellText,
      color: COLORS.GREEN
    },
    warningCell: {
      ...cellText,
      color: COLORS.YELLOW
    },
    breakWordCell: {
      ...cellText,
      wordBreak: 'break-all'
    },
    breakSpacesCell: {
      ...cellText,
      whiteSpace: 'break-spaces'
    },
    blueNormalCell: {
      ...cellText,
      color: COLORS.BLUE,
      cursor: 'pointer'
    },
    largerRedBoldTextCell: {
      ...cellText,
      color: COLORS.RED,
      fontSize: 25,
      fontWeight: 500,
      lineHeight: '32px',
      textAlign: 'center'
    },
    centerAlignedText: {
      textAlign: 'center !important' as 'center'
    },
    rightAlignedText: {
      textAlign: 'right !important' as 'right'
    },
    tooltip: {
      backgroundColor: COLORS.BLACK_50,
      fontSize: 12,
      letterSpacing: 0,
      lineHeight: '16px',
      boxShadow: '0 2px 5px 0 rgba(0,0,0,0.26), 0 2px 10px 0 rgba(0,0,0,0.16)'
    }
  })
}
