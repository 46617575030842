const UI_ROUTES = {
  signinSignup: {
    SIGNIN_SIGNUP: {
      path: '/signup'
    }
  },
  dashboard: {
    DASHBOARD_DOMAIN_FRAUD_ROOT: {
      path: '/admin/dashboard/domain-fraud'
    },
    DASHBOARD_DOMAIN_FRAUD: {
      path: '/admin/dashboard/domain-fraud/accounts'
    },
    DASHBOARD_ETS: {
      path: '/admin/dashboard/ets'
    },
    DASHBOARD_ETS_SCANS: {
      path: '/admin/dashboard/ets/scans'
    },
    DASHBOARD_EMAIL_THREAT_SCANNER_ROOT: {
      path: '/admin/dashboard/email-threat-scanner'
    },
    DASHBOARD_EMAIL_THREAT_SCANNER: {
      path: '/admin/dashboard/email-threat-scanner/scans'
    },
    DASHBOARD_SENTINEL: {
      path: '/admin/dashboard/sentinel'
    },
    DASHBOARD_SENTINEL_ACCOUNTS: {
      path: '/admin/dashboard/sentinel/accounts'
    },
    DASHBOARD_IMPERSONATION_PROTECTION_ROOT: {
      path: '/admin/dashboard/impersonation-protection'
    },
    DASHBOARD_IMPERSONATION_PROTECTION: {
      path: '/admin/dashboard/impersonation-protection/accounts'
    },
    DASHBOARD_FORENSICS: {
      path: '/admin/dashboard/forensics'
    },
    DASHBOARD_FORENSICS_ACCOUNTS: {
      path: '/admin/dashboard/forensics/accounts'
    },
    DASHBOARD_INCIDENT_RESPONSE_ROOT: {
      path: '/admin/dashboard/incident-response'
    },
    DASHBOARD_INCIDENT_RESPONSE: {
      path: '/admin/dashboard/incident-response/accounts'
    }
  }
}

export default UI_ROUTES
