import React, { useMemo } from 'react'

import { MenuItem, TextField } from '@barracuda-internal/bds-core'

import { NODE_TYPES } from 'fir/redux/types/Workflows'

import { useFormatMessage } from 'global/lib/localization'
import WebhookDialog from 'fir/components/pages/automatedWorkflows/webhookDialog/WebhookDialog'
import styles from '../workflowDialogStyles'

import useWorkflowBuilderLogic, { UseWorkflowBuilderParams } from './useWorkflowBuilderLogic'
import EventRow from './EventRow'

type WorkflowBuilderProps = UseWorkflowBuilderParams

const BASE_I18N_KEY = 'fir.app.automated_workflows.workflow_dialog'

const WorkflowBuilder: React.FC<WorkflowBuilderProps> = props => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [workflowBuilderLogic] = useWorkflowBuilderLogic()

  return useMemo(() => {
    const {
      conditionDataErrorText,
      displayNewIcon,
      handleConditionData,
      isValidConditionValue,
      isWorkflowBlueprint,
      onChangeEventType,
      onChangeOperator,
      onAddAction,
      onAddCondition,
      onAddTrigger,
      onChangeEvent,
      webhookDialogConfig,
      workflowBuilderConfig
    } = workflowBuilderLogic

    return (
      <>
        {webhookDialogConfig.isOpened && <WebhookDialog {...webhookDialogConfig} />}
        {!isWorkflowBlueprint && (
          <TextField
            select
            disabled={workflowBuilderConfig.triggerCount === 0}
            label={formatMessage('labels.event_types')}
            onChange={onChangeEventType}
            value={workflowBuilderConfig.eventTypeValue}
          >
            {Object.keys(NODE_TYPES).map((eventType: string) => (
              <MenuItem key={eventType} value={eventType} className={classes.menuItems}>
                {formatMessage(`labels.${eventType}`)}
              </MenuItem>
            ))}
          </TextField>
        )}
        {(workflowBuilderConfig.eventTypeValue === NODE_TYPES.triggers || isWorkflowBlueprint) && (
          <EventRow
            key={workflowBuilderConfig.triggerUuid}
            {...{
              eventRowConfig: {
                eventCount: workflowBuilderConfig.triggerCount,
                eventType: NODE_TYPES.triggers,
                eventValue: workflowBuilderConfig.triggerValue
              },
              disableTriggerSelect: isWorkflowBlueprint,
              displayNewIcon,
              isWorkflowBlueprint,
              hideAddButton: isWorkflowBlueprint,
              onAddEvent: onAddTrigger,
              onChangeEvent
            }}
          />
        )}
        {(workflowBuilderConfig.eventTypeValue === NODE_TYPES.conditions || isWorkflowBlueprint) && (
          <EventRow
            key={workflowBuilderConfig.conditionUuid}
            {...{
              eventRowConfig: {
                eventCount: workflowBuilderConfig.conditionCount,
                eventData: workflowBuilderConfig.conditionData,
                eventOperator: workflowBuilderConfig.operatorValue,
                eventType: NODE_TYPES.conditions,
                eventValue: workflowBuilderConfig.conditionValue,
                parentEventValue: workflowBuilderConfig.triggerValue,
                triggerId: workflowBuilderConfig.triggerId
              },
              conditionDataErrorText,
              handleEventData: handleConditionData,
              hideAddButton: isWorkflowBlueprint,
              isValidConditionValue,
              isWorkflowBlueprint,
              onAddEvent: onAddCondition,
              onChangeEvent,
              onChangeEventOperator: onChangeOperator
            }}
          />
        )}
        {(workflowBuilderConfig.eventTypeValue === NODE_TYPES.actions || isWorkflowBlueprint) && (
          <EventRow
            key={workflowBuilderConfig.actionUuid}
            {...{
              eventRowConfig: {
                conditionId: workflowBuilderConfig.conditionId,
                eventCount: workflowBuilderConfig.actionCount,
                eventType: NODE_TYPES.actions,
                eventValue: workflowBuilderConfig.actionValue,
                triggerId: workflowBuilderConfig.triggerId
              },
              displayNewIcon,
              hideAddButton: isWorkflowBlueprint,
              isWorkflowBlueprint,
              onAddEvent: onAddAction,
              onChangeEvent
            }}
          />
        )}
        {workflowBuilderConfig.eventRowList}
      </>
    )
  }, [classes, formatMessage, workflowBuilderLogic])
}

export default WorkflowBuilder
