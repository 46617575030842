import { useMemo } from 'react'

import { isPending, isSuccess } from 'global/redux/toolkit/api'

import { getRecipients, getRecipientDetails, resetRecipientDetails } from 'sen/redux/features/identity/identitySlice'
import useIdentitiesAutocompleteLogic, {
  IdentityAutocompleteConfig,
  SelectedIdentity
} from 'sen/components/lib/autocompletes/identity/useIdentityAutocompleteLogic'
import { useAppSelector } from 'sen/redux/toolkit/hooks'

export type RecipientsAutocompleteConfig = IdentityAutocompleteConfig
export type SelectedRecipient = SelectedIdentity

export interface UseSendersAutocompleteLogicProps {
  shouldSetInititalIdentity?: boolean
  isRequired?: boolean
}

export default function useRecipientsAutocompleteLogic({
  shouldSetInititalIdentity = false,
  isRequired = false
}: UseSendersAutocompleteLogicProps = {}): [RecipientsAutocompleteConfig, SelectedRecipient] {
  const { recipients, isGetRecipients, isGetRecipientsSuccess, recipientIdentityDetails } = useAppSelector(_stores => ({
    recipients: _stores.identity.recipients,
    isGetRecipients: isPending(_stores.identity.getRecipientsApiStatus),
    isGetRecipientsSuccess: isSuccess(_stores.identity.getRecipientsApiStatus),
    recipientIdentityDetails: _stores.identity.recipientIdentityDetails
  }))

  const [identityAutocompleteConfig, selectedIdentity] = useIdentitiesAutocompleteLogic({
    identites: recipients,
    isLoading: isGetRecipients,
    isSuccess: isGetRecipientsSuccess,
    identityDetails: recipientIdentityDetails,
    resetIdentityDetails: resetRecipientDetails,
    getIdentities: getRecipients,
    getDetails: getRecipientDetails,
    shouldSetInititalIdentity,
    isRequired
  })

  return useMemo(() => {
    return [identityAutocompleteConfig, selectedIdentity]
  }, [identityAutocompleteConfig, selectedIdentity])
}
