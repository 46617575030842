import { useEffect, useMemo, useCallback } from 'react'

import { process } from '@progress/kendo-data-query'

import { isPending } from 'global/redux/toolkit/api'
import { DmarcSubdomain } from 'global/types/api/dmarc'
import { BDSGridPagerConfig } from 'global/types/dataTables/dataTables'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import { getDmarcFailedSubdomains, getDmarcPassedSubdomains } from 'sen/redux/features/dmarc/dmarcSlice'
import * as T from 'sen/components/lib/dialogs/investigateDmarc/investigateDmarcDialogTypes'
import useInvestigateDmarcAnalysisLogic from 'sen/components/lib/dialogs/investigateDmarc/views/lib/useInvestigateDmarcAnalysis'
import {
  update as updateRDmarcFailedSubdomainsTable,
  reset as resetDmarcFailedSubdomainsTable
} from 'sen/redux/features/dataTables/dmarcSubdomains/failedSubdomains'
import {
  update as updateRDmarcPassedSubdomainsTable,
  reset as resetDmarcPassedSubdomainsTable
} from 'sen/redux/features/dataTables/dmarcSubdomains/passedSubdomains'

export interface LogicConfig extends T.ViewsLogicConfig {
  getDmarcPercentage: (subdomain: DmarcSubdomain) => string
}

export default function useViewByDomainLogic({ dmarcSource, domain, selectedTab }: T.UseViewLogicProps): [LogicConfig] {
  const {
    dmarcFailedDomains,
    dmarcPassedDomains,
    isDmarcFailedDomainsLoading,
    isDmarcPassedDomainsLoading,
    dmarcFailedDomainsTable,
    dmarcPassedDomainsTable,
    loadedDmarcFailedSubdomainsOffsets,
    loadedDmarcPassedSubdomainsOffsets
  } = useAppSelector(_stores => ({
    dmarcFailedDomains: _stores.dmarc.dmarcFailedDomains,
    isDmarcFailedDomainsLoading: isPending(_stores.dmarc.getDmarcFailedSubdomainsApiStatus),
    loadedDmarcFailedSubdomainsOffsets: _stores.dmarc.loadedDmarcFailedSubdomainsOffsets,
    dmarcFailedDomainsTable: _stores.dataTables.dmarcSubdomains.failed,
    dmarcPassedDomains: _stores.dmarc.dmarcPassedDomains,
    isDmarcPassedDomainsLoading: isPending(_stores.dmarc.getDmarcPassedSubdomainsApiStatus),
    loadedDmarcPassedSubdomainsOffsets: _stores.dmarc.loadedDmarcPassedSubdomainsOffsets,
    dmarcPassedDomainsTable: _stores.dataTables.dmarcSubdomains.passed
  }))
  const dispatch = useAppDispatch()
  const [investigateDmarcAnalysisLogic] = useInvestigateDmarcAnalysisLogic()

  // init
  useEffect(() => {
    return () => {
      dispatch(resetDmarcFailedSubdomainsTable())
      dispatch(resetDmarcPassedSubdomainsTable())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isFail = useMemo(() => {
    return selectedTab === T.Tabs.fail
  }, [selectedTab])

  const failedSubdomainstableData = useMemo(() => {
    const { skip, take } = dmarcFailedDomainsTable

    const { data } = process(
      (dmarcFailedDomains?.results || []).map((report: DmarcSubdomain) => ({
        ...(report && {
          ...report
        })
      })),
      { skip, take }
    )

    return {
      data: data.filter(report => report.host),
      total: dmarcFailedDomains?.count || 0
    }
  }, [dmarcFailedDomains, dmarcFailedDomainsTable])

  const failedSubdomainsPageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = dmarcFailedDomainsTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: failedSubdomainstableData.total,
      onPageChange: (e: any) => {
        dispatch(updateRDmarcFailedSubdomainsTable(e.page))

        if (!loadedDmarcFailedSubdomainsOffsets?.includes(e.page.skip)) {
          dispatch(getDmarcFailedSubdomains({ domain: domain.name, host: dmarcSource.host }))
        }
      }
    }
  }, [
    dmarcFailedDomainsTable,
    failedSubdomainstableData,
    dispatch,
    loadedDmarcFailedSubdomainsOffsets,
    dmarcSource,
    domain
  ])

  const passedSubdomainstableData = useMemo(() => {
    const { skip, take } = dmarcPassedDomainsTable

    const { data } = process(
      (dmarcPassedDomains?.results || []).map((report: DmarcSubdomain) => ({
        ...(report && {
          ...report
        })
      })),
      { skip, take }
    )

    return {
      data: data.filter(report => report.host),
      total: dmarcPassedDomains?.count || 0
    }
  }, [dmarcPassedDomains, dmarcPassedDomainsTable])

  const passedSubdomainsPageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = dmarcPassedDomainsTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: passedSubdomainstableData.total,
      onPageChange: (e: any) => {
        dispatch(updateRDmarcPassedSubdomainsTable(e.page))

        if (!loadedDmarcPassedSubdomainsOffsets?.includes(e.page.skip)) {
          dispatch(getDmarcPassedSubdomains({ domain: domain.name, host: dmarcSource.host }))
        }
      }
    }
  }, [
    dmarcPassedDomainsTable,
    passedSubdomainstableData,
    dispatch,
    loadedDmarcPassedSubdomainsOffsets,
    dmarcSource,
    domain
  ])

  const tableConfig = useMemo(() => {
    if (isFail) {
      return {
        isLoaded: true,
        inProgress: isDmarcFailedDomainsLoading,
        tableData: failedSubdomainstableData,
        pageConfig: failedSubdomainsPageConfig,
        columns: dmarcFailedDomainsTable.GRID_COLUMNS,
        columnsConfig: dmarcFailedDomainsTable.columnsConfig,
        isFlexibleTable: failedSubdomainstableData.total < dmarcFailedDomainsTable.ITEMS_PER_PAGE
      }
    }

    return {
      isLoaded: true,
      inProgress: isDmarcPassedDomainsLoading,
      tableData: passedSubdomainstableData,
      pageConfig: passedSubdomainsPageConfig,
      columns: dmarcPassedDomainsTable.GRID_COLUMNS,
      columnsConfig: dmarcPassedDomainsTable.columnsConfig,
      isFlexibleTable: passedSubdomainstableData.total < dmarcPassedDomainsTable.ITEMS_PER_PAGE
    }
  }, [
    isFail,
    isDmarcFailedDomainsLoading,
    isDmarcPassedDomainsLoading,
    failedSubdomainstableData,
    passedSubdomainstableData,
    failedSubdomainsPageConfig,
    passedSubdomainsPageConfig,
    dmarcFailedDomainsTable,
    dmarcPassedDomainsTable
  ])

  const getDmarcPercentage = useCallback(
    (subdomain: DmarcSubdomain) => {
      return investigateDmarcAnalysisLogic.getDmarcPercentage(isFail, dmarcSource, subdomain)
    },
    [isFail, dmarcSource, investigateDmarcAnalysisLogic]
  )

  return useMemo(() => {
    return [
      {
        isFail,
        tableConfig,
        getDmarcPercentage
      }
    ]
  }, [tableConfig, getDmarcPercentage, isFail])
}
