import { useMemo, useCallback, useEffect } from 'react'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { isPending, isSuccess, getErrorMessage } from 'global/redux/toolkit/api'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import { cleanupAllThreats } from 'sen/redux/features/cleanup/cleanupSlice'

export type InProgress = boolean
export type OnDelete = () => void
export type OnCancel = () => void
export type ErrorMsg = string | undefined

export default function useCleanupDialogLogic(
  _: boolean,
  onClose: () => void
): [InProgress, OnDelete, OnCancel, ErrorMsg] {
  const { accessTokenId, error, isCleanupPending, isCleanupSuccess } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id,
    isCleanupPending: isPending(_stores.cleanup.cleanupAllThreatsApiStatus),
    error: getErrorMessage(_stores.cleanup.cleanupAllThreatsApiStatus),
    isCleanupSuccess: isSuccess(_stores.cleanup.cleanupAllThreatsApiStatus)
  }))
  const dispatch = useAppDispatch()

  const inProgress: boolean = useMemo(() => {
    return isCleanupPending
  }, [isCleanupPending])

  // onDelete
  useEffect(() => {
    if (isCleanupSuccess) {
      onClose()
    }
  }, [dispatch, isCleanupPending, isCleanupSuccess, onClose])

  const onDelete = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.CLEANUP_DIALOG_CONFIRM, {
      accessTokenId
    })

    dispatch(cleanupAllThreats())
  }, [dispatch, accessTokenId])

  const onCancel = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.CLEANUP_DIALOG_CANCEL, {
      accessTokenId
    })

    onClose()
  }, [accessTokenId, onClose])

  return useMemo(() => {
    return [inProgress, onDelete, onCancel, error]
  }, [inProgress, onDelete, onCancel, error])
}
