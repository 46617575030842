import { IncidentDetailsSource } from 'global/types/api/newIncident'
import { InboxRuleActions, InboxRuleConditions } from 'global/types/api/ato'

export interface UserAgent {
  count: number
  userAgent: string
}

export interface ClickedBy {
  clientIp: string
  count: number
  userAgents: UserAgent[]
}

export interface IncidentTag {
  id: number
  name: string
}

export interface RemediationActions {
  addBCSPolicy: boolean
  addDomainPolicy: boolean
  addSenderPolicy: boolean
  delete: boolean
  enableContinuousRemediation: boolean
  notify: boolean
  quarantine: boolean
  queueInsights: boolean
  sendSummary: boolean
  updateMessageLog: boolean
}

export interface TaskStatuses {
  clickedLinks?: string
  incidentCreate?: string
}

export interface Sender {
  displayName?: string
  firstName?: string
  lastName?: string
  title?: string
  email: string
}

export interface Recipient {
  displayName: string
  email: string
  status: string
  isInternal: boolean
  domainId: string
  clickedLinkStatus: string
  clickedLinks: string[]
  replied: string
  repliedStatus: string
  forwarded: string
  forwardedStatus: string
  isContinuousRemediation: boolean
  sendingDirection: string
}

export interface IncidentDetails {
  sourceDomainId?: string
  sourceEmailId?: string
  threats?: Threat[]
  source: IncidentDetailsSource
  subSource: string
}

export interface Threat {
  extra: string
  path: string
  type: string
  vector: string
}

export interface IncidentClick {
  clickedBy: ClickedBy[]
  contentType: string
  dateSent: string
  essId: string
  mimeType: string
  recipients: string[]
  subject: string
  url: string
}

export interface IncidentEmail {
  attachments?: string[]
  clickedLinks?: string[]
  domainId?: string
  essId?: string
  dateSent: string
  displayName: string
  email: string
  emailId: string
  forwarded: string
  isContinuousRemediation: boolean
  isInternal: boolean
  isRead: string
  replied: string
  senderEmail: string
  sendingDirection: string
  subject: string
  status: string
  title?: string
}

export interface IncidentUser {
  clickedLinks: string
  email: string
  forwarded: string
  isRead: string
  replied: string
  userType: string
}

export enum RemediationStatuses {
  notStarted = 'Not Started',
  inProgress = 'In Progress',
  completed = 'Completed'
}

export interface Incident {
  continuousRemediationEnabled?: boolean
  continuousRemediationUntil?: string | null
  createdBy?: string
  createdByName?: string
  domains?: string[]
  incidentDetails?: IncidentDetails
  labels?: IncidentTag[]
  maliciousDomains?: string[]
  remediationActions?: RemediationActions
  senderPolicies?: string[]
  taskStatuses?: TaskStatuses
  attachmentName: string
  continuousRemediationCount: number
  created: string
  distinctInternalRecipientCount: number
  distinctExternalRecipientCount: number
  externalAttackCount: number
  id: string
  internalAttackCount: number
  lastRemediateAction: string
  notifiedEmailCount: number
  quarantineStatus: string
  remediatedEmailCount: number
  remediationStatus: RemediationStatuses
  sender: Sender
  subject: string
  timeframe: number
  bodyLinks?: string
  bodyText?: string
}

export interface IncidentsEmailSearch {
  accessTokenId: string
  report: {
    count: number
    totalCount: number
    data: IncidentEmailSearch[]
  }
}

export interface IncidentEmailSearchFormValues {
  senderEmail: string
  emailSubject: string
  timeframe: number
}
export interface IncidentEmailSearch {
  displayName: string
  dateSent: string
  senderEmail: string
  subject: string
  recipients: Recipient[]
  reportedBy: string[]
  attachments: string[]
  emailId: string
}

export interface InboxRule {
  actions: InboxRuleActions
  conditions: InboxRuleConditions
  displayName: string
  hasError: boolean
  id: string
  isEnabled: boolean
  isReadOnly: boolean
  sequence: number
  exceptions?: boolean
}

export interface InboxRuleFeed {
  changeTime: string
  rulesAfter: InboxRule[]
  rulesBefore: InboxRule[]
  userDisplayName: string
  userPrincipalName: string
}

export interface ModifiedInboxRuleFeed extends InboxRuleFeed {
  formattedAction: string | false
  formattedDate: string
}

export interface Incidents {
  count: number
  totalCount: number
  data: Incident[]
}

export interface IncidentsReport {
  accessTokenId: string
  report: Incidents
}

export interface IncidentEmailReport {
  accessTokenId: string
  report: {
    count: number
    totalCount: number
    data: IncidentEmail[]
  }
}

export interface InboxRulesReport {
  accessTokenId: string
  report: InboxRule[]
}

export interface IncidentEmailBody {
  data: string
  headers: []
}

export interface NotifyExternalEmails {
  incidentId: string
  notificationSender: {
    displayName: string
    email: string
  }
  notificationBody: string
  notificationSubject: string
}

export interface O365Permissions {
  hasReadMailboxSettingsPermission: boolean
  hasWriteMailboxSettingsPermission: boolean
  hasReadActivityFeedPermission: boolean
}
