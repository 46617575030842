const REGEX =
  '(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z0-9]{2,}))'
const EMAIL_REGEX = new RegExp(REGEX, 'gi')

/**
 *  @param address: string
 *  this is set to 'any' because of typescript conflicts with the RemediationLogic file in src/fir
 * */
export function parseAddress(address: any): string {
  if (!address) {
    return ''
  }

  const parsed = address.match(EMAIL_REGEX)
  if (!parsed) {
    return ''
  }

  // return the email inside <> if multiple emails found
  const returnAddress = parsed.find((addr: string) => {
    return address.indexOf(`<${addr}>`) >= 0
  })

  // give preference to first address inside <> otherwise return first email found
  // shouldn't ever hit the || '', but it's there because typescript complains
  return returnAddress || parsed.shift() || ''
}

export function parseAddresses(addresses: string): string[] {
  if (!addresses) {
    return []
  }
  return addresses.match(EMAIL_REGEX) || []
}
