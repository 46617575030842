import { useMemo, useCallback } from 'react'

import useNewIncidentDialogWizardLogic, {
  ButtonStatus
} from 'sen/components/lib/dialogs/newIncidentDialog/useNewIncidentDialogWizardLogic'

export default function useConfirmIncidentLogic(): [ButtonStatus] {
  const [multiStepConfig] = useNewIncidentDialogWizardLogic()

  const currentStep = 0
  const currentPage = 'New Incident Initial Page'

  const onDispatchPrevStep = useCallback(() => {
    multiStepConfig.onPrevStep(currentStep, currentPage)
  }, [multiStepConfig, currentStep])

  const onDispatchNextStep = useCallback(() => {
    multiStepConfig.onNextStep(currentStep, currentPage)
  }, [multiStepConfig, currentStep])

  return useMemo(() => {
    return [
      {
        cancel: 'cancel',
        onNext: 'next',
        onNextStep: onDispatchNextStep,
        onPrevStep: onDispatchPrevStep
      }
    ]
  }, [onDispatchNextStep, onDispatchPrevStep])
}
