import { useEffect, useMemo, useCallback, useReducer } from 'react'

import { useAppSelector, useAppDispatch } from 'global/redux/toolkit/hooks'
import { User } from 'global/types/api/signupUser'
import { config } from 'global/lib/config'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import {
  newSignup,
  setUserFlags,
  saveSignupUser,
  resetSetUserFlag,
  logout,
  resetError,
  saveSignupCompleteForm
} from 'global/redux/features/auth/authSlice'
import { isPending, isSuccess, isFailed } from 'global/redux/toolkit/api'

export interface State {
  password: string
  confirmPassword: string
  phone: string
  isAcceptedPrivacyPolicy: boolean
}

export interface SignupCompleteLogicProps {
  error: string | false
  user: User
  formValues: State
  onPasswordChange: (passType: string) => (e: any) => void
  onPhoneChange: (e: any) => void
  isButtonDisabled: boolean
  onTogglePrivacyPolicy: () => void
  onReadPrivacyPolicy: (e: any) => void
  onBlurField: (e: any) => void
  onScanNow: () => void
  formErrors: any
  isFormInProgress: boolean
  isPasswordError: boolean
  logout: () => void
}

export interface UseSignupCompleteLogicProps {
  routesConfig: any
  gotoNextStep: () => void
  scanType: string
}

const PASSWORD_VALIDATOR = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S]{8,}$/
const PHONE_VALIDATOR = /^[0-9+ ()-]{5,20}$/

const INITIAL_FORM_VALUES = {
  password: '',
  confirmPassword: '',
  phone: '',
  isAcceptedPrivacyPolicy: false
}
let isPolicyCheck = false

export default function useSignupCompleteLogic({
  routesConfig,
  gotoNextStep,
  scanType
}: UseSignupCompleteLogicProps): [SignupCompleteLogicProps | undefined] {
  const { auth } = useAppSelector(_stores => ({
    auth: _stores.auth
  }))
  const [formValues, setFormValues] = useReducer(
    (_formValues: State, newFormValues: any) => ({ ..._formValues, ...newFormValues }),
    auth.savedCompleteFormValues || INITIAL_FORM_VALUES
  )
  const [state, setState] = useReducer((_state: State, newState: any) => ({ ..._state, ...newState }), {
    visitedInputs: {},
    isFormInProgress: false,
    isPasswordError: false,
    isSignupStarted: false
  })
  const dispatch = useAppDispatch()

  // init
  useEffect(() => {
    if (!auth.signup.id) {
      routesConfig.SIGNIN_SIGNUP.goto()
    }

    analyticsLib.trackAppEvent(analyticsLib.EVENTS.SIGNUP_SAVE_PROFILE_PAGEVIEW, { url: window.location.href })

    // unmount
    return () => {
      if (!isPolicyCheck) {
        dispatch(saveSignupCompleteForm(undefined))
      }

      isPolicyCheck = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // signup started, save user flag
  useEffect(() => {
    if (state.isSignupStarted) {
      return
    }

    if (!state.isFormInProgress && isPending(auth.newSignupApiStatus)) {
      setState({ isFormInProgress: true })
    } else if (state.isFormInProgress && !isPending(auth.newSignupApiStatus)) {
      setState({ isSignupStarted: true })

      if (isSuccess(auth.newSignupApiStatus)) {
        const { user, savedUser } = auth.signup

        // set user flags
        if (user.flags) {
          if (savedUser) {
            analyticsLib.setUserFlags(savedUser, user.flags)
          }

          dispatch(setUserFlags())
        }

        if (savedUser) {
          analyticsLib.setUser(
            { ...savedUser },
            {
              signedUpFrom: scanType,
              signedUpBccId: auth.signup.savedUser?.accounts[0].bccId
            },
            // eslint-disable-next-line camelcase
            user?.flags?.mixpanel_distinct_id
          )
        }

        analyticsLib.trackAppEvent(
          analyticsLib.EVENTS.SIGNUP_SAVE_PROFILE,
          {
            scanType,
            url: window.location.href
          },
          savedUser
        )
      } else if (!auth.error?.includes('bad_password_exception')) {
        dispatch(logout())
      } else {
        setState({ isFormInProgress: false, isPasswordError: true })
        setFormValues({ password: '', confirmPassword: '' })
      }
    }
  }, [
    state.isFormInProgress,
    state.isSignupStarted,
    auth.newSignupApiStatus,
    auth.signup,
    auth.error,
    dispatch,
    routesConfig,
    auth.setUserFlagsApiStatus,
    scanType
  ])

  // save user profile
  useEffect(() => {
    if (!state.isSignupStarted) {
      return
    }

    if (isSuccess(auth.setUserFlagsApiStatus)) {
      const { savedUser } = auth.signup
      dispatch(resetSetUserFlag())
      analyticsLib.trackAppEvent(
        analyticsLib.EVENTS.ACCOUNT_SIGNUP,
        {
          scanType,
          url: window.location.href
        },
        savedUser
      )

      dispatch(saveSignupUser({ scanType, provider: config.CLOUD_PROVIDERS.office365.id }))
    } else if (isFailed(auth.setUserFlagsApiStatus)) {
      dispatch(resetSetUserFlag())
      setState({ isFormInProgress: false })
    }
  }, [auth.setUserFlagsApiStatus, auth.signup, dispatch, state.isSignupStarted, scanType])

  // save user profile is done
  useEffect(() => {
    if (!state.isSignupStarted) {
      return
    }

    if (isSuccess(auth.saveSignupUserApiStatus)) {
      setState({ isFormInProgress: false, isSignupStarted: false })
      gotoNextStep()
    } else if (isFailed(auth.saveSignupUserApiStatus)) {
      setState({ isFormInProgress: false, isSignupStarted: false })
      dispatch(logout())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.saveSignupUserApiStatus, gotoNextStep, state.isFormInProgress, state.isSignupStarted])

  // event handlers
  const onPasswordChange = useCallback(
    (passType: string) => {
      return (e: any) => {
        if (state.isPasswordError) {
          setState({ isPasswordError: false })
        }
        setFormValues({ [passType]: e.target.value.replace(/\s+/g, '') })
      }
    },
    [state.isPasswordError]
  )

  const onPhoneChange = useCallback((e: any) => {
    setFormValues({ phone: e.target.value })
  }, [])

  const onTogglePrivacyPolicy = useCallback(() => {
    setFormValues({ isAcceptedPrivacyPolicy: !formValues.isAcceptedPrivacyPolicy })
  }, [formValues])

  const onReadPrivacyPolicy = useCallback(
    (e: any) => {
      e.stopPropagation()
      e.preventDefault()

      dispatch(saveSignupCompleteForm({ ...formValues }))
      isPolicyCheck = true
      routesConfig.SIGNUP_COMPLETE_POLICY.goto()
    },
    [routesConfig, formValues, dispatch]
  )

  const onBlurField = useCallback(
    (e: any) => {
      const field = e.target.getAttribute(['data-field'])

      if (formValues[field] && formValues[field].length) {
        setState({ visitedInputs: { ...state.visitedInputs, [field]: true } })
      }
    },
    [formValues, state]
  )

  const onScanNow = useCallback(() => {
    dispatch(resetError())
    dispatch(newSignup({ password: formValues.password, phone: formValues.phone }))
  }, [formValues, dispatch])

  // form validation
  const passwordStates = useMemo(() => {
    const isValid = !!formValues.password.length && PASSWORD_VALIDATOR.test(formValues.password)

    return {
      isValid,
      isMatch: isValid && !!formValues.confirmPassword.length && formValues.password === formValues.confirmPassword
    }
  }, [formValues.password, formValues.confirmPassword])

  const phoneStates = useMemo(() => {
    return {
      isValid: !!formValues.phone.length && PHONE_VALIDATOR.test(formValues.phone)
    }
  }, [formValues.phone])

  const formErrors = useMemo(() => {
    return {
      isWeakPassword: state.visitedInputs.password && !!formValues.password.length && !passwordStates.isValid,
      isPasswordDontMatch:
        passwordStates.isValid &&
        state.visitedInputs.confirmPassword &&
        !!formValues.confirmPassword.length &&
        !passwordStates.isMatch,
      isInvalidPhone: state.visitedInputs.phone && !!formValues.phone.length && !phoneStates.isValid
    }
  }, [passwordStates, phoneStates, formValues.password, formValues.confirmPassword, formValues.phone, state])

  const isButtonDisabled = useMemo(() => {
    return (
      [formValues.password, formValues.confirmPassword, formValues.phone].some(
        (formValue: string) => !formValue.length
      ) ||
      !formValues.isAcceptedPrivacyPolicy ||
      Object.values(formErrors).some((error: boolean) => error)
    )
  }, [
    formValues.password,
    formValues.confirmPassword,
    formValues.phone,
    formValues.isAcceptedPrivacyPolicy,
    formErrors
  ])

  return useMemo(() => {
    if (!auth.signup.id) {
      return [undefined]
    }

    return [
      {
        error: auth.error || false,
        user: auth.signup.user,
        formValues,
        isPasswordError: state.isPasswordError,
        onPasswordChange,
        onPhoneChange,
        isButtonDisabled,
        onTogglePrivacyPolicy,
        onReadPrivacyPolicy,
        onBlurField,
        onScanNow,
        formErrors,
        isFormInProgress: state.isFormInProgress,
        logout: () => {
          dispatch(logout())
        }
      }
    ]
  }, [
    auth,
    formValues,
    state.isPasswordError,
    state.isFormInProgress,
    onPasswordChange,
    onPhoneChange,
    isButtonDisabled,
    onTogglePrivacyPolicy,
    onReadPrivacyPolicy,
    onBlurField,
    onScanNow,
    formErrors,
    dispatch
  ])
}
