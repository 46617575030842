import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import {
  Alert,
  Checkbox,
  CheckboxLabel,
  DialogContent,
  Grid,
  TextField,
  Typography
} from '@barracuda-internal/bds-core'

import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'

import { useFormatMessage, useErrorFormatMessage } from 'global/lib/localization'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import DateRangeSelector from 'global/components/lib/dateRangeSelector/DateRangeSelector'
import IdentityAutocomplete from 'sen/components/lib/autocompletes/identity/IdentityAutocomplete'

import BaseDialogActions from 'sen/components/lib/dialogs/newIncidentDialog/lib/BaseDialogActions'
import useNewIncidentFormLogic, {
  MAX_LENGTH
} from 'sen/components/lib/dialogs/newIncidentDialog/steps/useNewIncidentFormLogic'

import styles from 'sen/components/lib/dialogs/newIncidentDialog/newIncidentDialogStyles'

const BASE_I18N_KEY = 'sen.app.new_incident_dialog.step_new_incident_form'
const BASE_RECIPIENTS_AUTOCOMPLETE_I18N_KEY = 'sen.app.autocompletes.compromised_account'

export interface Props {
  onClose: () => void
  activeStep: number
}

const NewIncidentForm: React.FC<Props> = ({ activeStep, onClose }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatMessageError = useErrorFormatMessage()
  const classes = styles()
  const [
    buttonStatus,
    newIncidentFormConfig,
    recipientsSelector,
    dateRangeSelector,
    inProgress
  ] = useNewIncidentFormLogic()
  return useMemo(
    () => (
      <Grid container item direction="column">
        <DialogContent className={classes.dialogContent}>
          {newIncidentFormConfig.noData && (
            <Alert className={classes.error} variant="filled" severity="warning">
              {formatMessage('search_no_results')}
            </Alert>
          )}
          {newIncidentFormConfig.error && (
            <Alert className={classes.error} variant="filled" severity="error">
              {formatMessageError(`${newIncidentFormConfig.error}`)}
            </Alert>
          )}

          {inProgress && <LinearProgress className={classes.indicatorForm} data-field="linear-progress" />}

          <Grid container>
            <Typography className={classes.dialogText}>{formatMessage('title')}</Typography>
            <Grid item xs={12}>
              <Grid item>
                <FormControl className={classes.formControl}>
                  <IdentityAutocomplete
                    {...recipientsSelector.config}
                    BASE_I18N_KEY={BASE_RECIPIENTS_AUTOCOMPLETE_I18N_KEY}
                    inProgress={false}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  label={formatMessage('placeholder_subject')}
                  id="subject"
                  onChange={newIncidentFormConfig.handleSubject}
                  value={newIncidentFormConfig.emailSubject}
                  disabled={newIncidentFormConfig.isNoSubjectChecked}
                  error={!newIncidentFormConfig.isSubjectValid || newIncidentFormConfig.emailSubjectLength > MAX_LENGTH}
                  helperText={
                    (!newIncidentFormConfig.isSubjectValid && formatMessage('error_subject')) ||
                    (newIncidentFormConfig.emailSubjectLength > MAX_LENGTH && formatMessage('error_subject_length'))
                  }
                  required
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <CheckboxLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={newIncidentFormConfig.isNoSubjectChecked}
                    onChange={newIncidentFormConfig.handleSubjectCheckbox}
                  />
                }
                label={formatMessage('no_subject_checkbox')}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <FormLabel>{formatMessage('dropdown_title')}</FormLabel>
                <DateRangeSelector {...dateRangeSelector} />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <BaseDialogActions activeStep={activeStep} buttonStatus={buttonStatus} onClose={onClose} />
      </Grid>
    ),
    [
      activeStep,
      classes,
      formatMessage,
      formatMessageError,
      buttonStatus,
      newIncidentFormConfig,
      recipientsSelector,
      dateRangeSelector,
      inProgress,
      onClose
    ]
  )
}

export default NewIncidentForm

NewIncidentForm.propTypes = {
  activeStep: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired
}
