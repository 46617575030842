import { useEffect, useMemo, useCallback } from 'react'
import { process } from '@progress/kendo-data-query'

import { IncidentEmail } from 'global/types/api/remediation'
import { BDSGridPagerConfig } from 'global/types/dataTables/dataTables'
import useRadioButtons, { BaseRadioButtonsConfig } from 'global/lib/useRadioButtons/useRadioButtons'

import { isPending, isSuccess, getErrorMessage } from 'global/redux/toolkit/api'
import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'

import { TableConfig, RecipientsConfig } from 'sen/components/lib/dialogs/newIncidentDialog/lib/RecipientsTable'

import {
  getRecipients,
  resetRecipients,
  deleteEmails,
  quarantineEmails
} from 'sen/redux/features/remediation/remediationSlice'
import {
  update as updateRecipientsWizardTable,
  reset as resetRecipientsWizardTable
} from 'sen/redux/features/dataTables/incident/recipientsWizardSlice'

import useNewIncidentDialogWizardLogic, {
  ButtonStatus
} from 'sen/components/lib/dialogs/newIncidentDialog/useNewIncidentDialogWizardLogic'

export type Error = string | undefined

export interface RadioButtonConfig {
  RADIO_VALUES: number[]
  radioButtonsConfig: BaseRadioButtonsConfig
}

export default function useQuarantineLogic(): [ButtonStatus, RecipientsConfig, TableConfig, Error, RadioButtonConfig] {
  const {
    createIncidentId,
    recipientsTable,
    recipients,
    hasRecipients,
    loadedRecipientsOffsets,
    tableInProgress,
    isLoaded,
    error,
    deleteEmailsSuccess,
    quarantineEmailsSuccess
  } = useAppSelector(_stores => ({
    createIncidentId: _stores.remediation?.currentIncident?.id || '',
    recipientsTable: _stores.dataTables.incident.recipientsWizard,
    recipients: _stores.remediation?.recipients,
    hasRecipients: !!_stores.remediation?.recipients?.report?.data?.length,
    loadedRecipientsOffsets: _stores.remediation.loadedGetRecipientsWizardOffsets,
    tableInProgress:
      isPending(_stores.remediation.getRecipientsApiStatus) ||
      isPending(_stores.remediation.deleteEmailsApiStatus) ||
      isPending(_stores.remediation.quarantineEmailsApiStatus),
    error:
      getErrorMessage(_stores.remediation.getRecipientsApiStatus) ||
      getErrorMessage(_stores.remediation.deleteEmailsApiStatus) ||
      getErrorMessage(_stores.remediation.quarantineEmailsApiStatus),
    isLoaded: !!_stores.remediation.recipients?.accessTokenId,
    deleteEmailsSuccess: isSuccess(_stores.remediation.deleteEmailsApiStatus),
    quarantineEmailsSuccess: isSuccess(_stores.remediation.quarantineEmailsApiStatus)
  }))
  const dispatch = useAppDispatch()
  const [selectedCleanupMode, setSelectedCleanupMode] = useRadioButtons('0')
  const [multiStepConfig] = useNewIncidentDialogWizardLogic()
  const currentStep = 2
  const currentPage = 'Quarantine Page'
  const RADIO_VALUES = [0, 1]
  const JUNK_SETTING = '1'
  const DELETE_SETTING = '0'

  // Init
  useEffect(() => {
    return () => {
      dispatch(resetRecipients(true))
      dispatch(resetRecipientsWizardTable())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // recipients table
  const tableData = useMemo(() => {
    const { skip, take } = recipientsTable

    const { data } = process(
      (recipients?.report?.data || []).map((report: IncidentEmail) => ({
        ...(report && {
          ...report
        })
      })),
      { skip, take }
    )

    return {
      data: data.filter(report => report.status),
      total: recipients?.report?.totalCount || 0
    }
  }, [recipients, recipientsTable])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = recipientsTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: tableData.total,
      onPageChange: (e: any) => {
        dispatch(updateRecipientsWizardTable(e.page))

        if (!loadedRecipientsOffsets?.includes(e.page.skip)) {
          dispatch(getRecipients({ incidentId: createIncidentId, distinctRecipient: false, wizardTable: true }))
        }
      }
    }
  }, [recipientsTable, tableData, dispatch, loadedRecipientsOffsets, createIncidentId])

  // dialog action
  const onDispatchNextStep = useCallback(() => {
    if (hasRecipients) {
      if (hasRecipients && selectedCleanupMode === DELETE_SETTING) {
        dispatch(deleteEmails(createIncidentId))
      } else if (hasRecipients && selectedCleanupMode === JUNK_SETTING) {
        dispatch(quarantineEmails(createIncidentId))
      }
    } else {
      multiStepConfig.onNextStep(currentStep, currentPage, 'continue')
    }
  }, [dispatch, createIncidentId, selectedCleanupMode, hasRecipients, multiStepConfig])

  // Before we move to notify, we need to make sure these succeed
  useEffect(() => {
    if (deleteEmailsSuccess || quarantineEmailsSuccess) {
      const options = deleteEmailsSuccess ? 'delete emails' : 'quarantine emails'
      multiStepConfig.onNextStep(currentStep, currentPage, options)
    }
  }, [dispatch, multiStepConfig, deleteEmailsSuccess, quarantineEmailsSuccess])

  const onDispatchPrevStep = useCallback(() => {
    // This step doesn't have a back
    // Skip allows the user to go to the next step
    const options = 'Skip'
    multiStepConfig.onNextStep(currentStep, currentPage, options)
  }, [multiStepConfig])

  return useMemo(() => {
    return [
      {
        disabled: tableInProgress,
        disableNext: tableInProgress,
        cancel: 'cancel',
        onPrev: hasRecipients ? 'skip' : '',
        onNext: hasRecipients ? 'clean_up' : 'continue',
        onNextStep: onDispatchNextStep,
        onPrevStep: onDispatchPrevStep
      },
      {
        inProgress: tableInProgress,
        hasRecipients
      },
      {
        isLoaded: isLoaded || !!error,
        tableData,
        pageConfig,
        columns: recipientsTable.GRID_COLUMNS,
        columnsConfig: recipientsTable.columnsConfig,
        isFlexibleTable: tableData.total < recipientsTable.ITEMS_PER_PAGE
      },
      error,
      {
        RADIO_VALUES,
        radioButtonsConfig: {
          selectedRadioButton: selectedCleanupMode,
          onSelectRadioButton: setSelectedCleanupMode
        }
      }
    ]
  }, [
    onDispatchNextStep,
    onDispatchPrevStep,
    tableInProgress,
    recipientsTable,
    tableData,
    pageConfig,
    isLoaded,
    hasRecipients,
    error,
    selectedCleanupMode,
    setSelectedCleanupMode,
    RADIO_VALUES
  ])
}
