import insertToArray from 'global/lib/insertToArray'

export interface ReducerParams {
  state: any
  payload: any
}

export type HardResetReport = boolean | undefined

export function insertReportData(report: any, payload: { report: any; offset: number | undefined }) {
  return {
    ...report,
    ...payload?.report,
    report: {
      ...report?.report,
      ...payload?.report?.report,
      data: insertToArray(report?.report?.data || [], payload.report.report.data, payload.offset || 0)
    }
  }
}

export function resetReport(report: any, doHardReset: HardResetReport): any | undefined {
  if (report) {
    if (doHardReset) {
      return undefined
    }
    return {
      ...report,
      report: {
        ...report.report,
        data: []
      }
    }
  }

  return undefined
}
