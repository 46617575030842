import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  wrapper: {
    margin: 30
  },
  settingsIcon: {
    marginBottom: -theme.spacing(1)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  previewLabel: {
    marginTop: theme.spacing(1)
  },
  emailLabel: {
    color: '#777777',
    display: 'inline-block',
    minWidth: 100
  },
  emailValue: {
    display: 'inline-block'
  },
  greyText: {
    color: '#777777',
    display: 'inline-block'
  },
  frameContent: {
    overflow: 'scroll',
    width: '100%',
    border: 0,
    marginTop: theme.spacing(1),
    height: 400
  }
}))
