const API_ROUTES = {
  auth: {
    SIGNIN: {
      method: 'POST',
      path: '/auth/signin'
    },
    SIGNIN_OTP: {
      method: 'POST',
      path: '/auth/signin/otp'
    },
    GET_USER: {
      method: 'GET',
      path: '/auth/user'
    },
    ACTIVATE_DEVELOPER_INTERFACE: {
      method: 'POST',
      path: '/auth/developer-interface/activate'
    }
  },
  signup: {
    SIGNUP: {
      method: 'POST',
      path: '/signup/create'
    },
    SIGNUP_FLAGS: {
      method: 'POST',
      path: '/signup/flags'
    },
    SIGNUP_SAVE_PROFILE: {
      method: 'POST',
      path: '/signup/save-profile'
    }
  },
  report: {
    SCAN_STATUS: {
      method: 'POST',
      path: '/report/:provider/scan-status'
    },
    SCAN_STATS: {
      method: 'POST',
      path: '/report/:provider/stats/sources'
    },
    SCAN_THREAT_LOG: {
      method: 'POST',
      path: '/report/scan-threat-log'
    }
  },
  contest: {
    CONTEST_SCAN_STATS: {
      method: 'POST',
      path: '/contest/scan-stats'
    }
  },
  sp: {
    COMPANY_DOMAINS: {
      method: 'POST',
      path: '/sp/company-domains'
    },
    ETS_CHARTS_REPORTS: {
      method: 'POST',
      path: '/sp/ets-charts-reports'
    },
    SP_ATTACKS_ALL: {
      method: 'POST',
      path: '/sp/attacks'
    },
    SP_ATTACKS_GROUPED: {
      method: 'POST',
      path: '/sp/attacks-grouped'
    },
    SP_COUNTS: {
      method: 'POST',
      path: '/sp/counts'
    },
    SP_ATTACK_DETAILS: {
      method: 'POST',
      path: '/sp/details'
    },
    SP_ATTACKS_BY_DOMAIN: {
      method: 'POST',
      path: '/sp/attacks-by-domain'
    },
    SP_ATTACKS_BY_TAXONOMY: {
      method: 'POST',
      path: '/sp/attacks-by-taxonomy'
    },
    SP_ATTACKS_PER_MONTH: {
      method: 'POST',
      path: '/sp/attacks-per-month'
    },
    SP_ATTACKS_IDENTITIES_PER_MONTH: {
      method: 'POST',
      path: '/sp/attacks-identities-per-month'
    }
  },
  admin: {
    REVERT_IMPERSONATION: {
      method: 'POST',
      path: '/admin/revert-impersonation'
    }
  },
  account: {
    GET_SERIAL_WITH_ACCOUNT_ID: {
      method: 'POST',
      path: '/account/get-serial-with-accountId'
    }
  },
  LOGOUT: {
    method: 'POST',
    path: '/logout'
  },
  DELETE_ETS_REPORT: {
    method: 'POST',
    path: '/settings/ets/delete-report'
  },
  ACTIVATE_REPORT_SHARING: {
    method: 'POST',
    path: '/settings/report-sharing/activate'
  },
  DEACTIVATE_REPORT_SHARING: {
    method: 'POST',
    path: '/settings/report-sharing/deactivate'
  },
  CONVERT_ETS_2_SENTINEL: {
    method: 'POST',
    path: '/settings/convertETS2Sentinel'
  },
  SET_ACCESS_TOKEN_SETTINGS_CONVERT: {
    method: 'POST',
    path: '/settings/access-token/update-convert'
  },
  RERUN_CURRENT_SCAN: {
    method: 'POST',
    path: '/settings/ets/rerun-current-scan'
  }
}

export default API_ROUTES
