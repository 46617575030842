import { select } from 'redux-saga/effects'

import { DataTableState } from 'sen/redux/types/dataTables'

export default function* buildQueryFor(tableId: string, pdfConfig = false) {
  const { skip, take, search, filter, sort, SORT_FIELDS, ITEMS_PER_REQUEST } = yield select(stores =>
    pdfConfig ? stores.dataTables[tableId].pdfConfig : stores.dataTables[tableId]
  ) as any

  return {
    offset: skip,
    limit: ITEMS_PER_REQUEST || take,
    filter,
    search: search.replace(/['"]/g, ''),
    order: sort.length ? `${sort[0].dir === 'asc' ? '' : '-'}${SORT_FIELDS[sort[0].field]}` : ''
  }
}

export function buildQueryFromState(config: any, pdfConfig = false) {
  const { skip, take, search, filter, sort, SORT_FIELDS, ITEMS_PER_REQUEST } = pdfConfig ? config.pdfConfig : config
  return {
    offset: skip,
    limit: ITEMS_PER_REQUEST || take,
    filter,
    search: search.replace(/['"]/g, ''),
    order: sort.length ? `${sort[0].dir === 'asc' ? '' : '-'}${SORT_FIELDS[sort[0].field]}` : ''
  }
}

export function buildReportQueryFor(tableConfig: DataTableState, withPagePagination = false) {
  const { skip, take, search, filter, sort, SORT_FIELDS, ITEMS_PER_REQUEST } = tableConfig

  return {
    offset: skip,
    ...(withPagePagination && { page: 1 + Math.floor(skip / (ITEMS_PER_REQUEST || take)) }),
    limit: ITEMS_PER_REQUEST || take,
    filter,
    search: search.replace(/['"]/g, ''),
    order: sort.length ? `${sort[0].dir === 'asc' ? '' : '-'}${SORT_FIELDS[sort[0].field]}` : ''
  }
}

export function buildReportQueryForAccessTokens(tableConfig: DataTableState) {
  const { skip, take, search, sort, SORT_FIELDS, ITEMS_PER_REQUEST } = tableConfig

  return {
    page: skip / 10 + 1,
    limit: ITEMS_PER_REQUEST || take,
    search: search.replace(/['"]/g, ''),
    order: sort.length ? `${sort[0].dir === 'asc' ? '' : '-'}${SORT_FIELDS[sort[0].field]}` : ''
  }
}
