import { config } from 'global/lib/config'
import { reduxStore } from 'global/lib/reduxStore'
import {
  activateDeveloperInterface,
  getUser,
  reset as resetAuth,
  resetError as resetAuthError,
  signin,
  signup,
  newSignup,
  logout
} from 'global/redux/features/auth/authSlice'

export interface Action {
  type: string
  payload?: any
}

const DEVELOPER_INTERFACE_SESSION_TTL_IN_MINUTES = 30

const AVAILABLE_ACTIONS = [
  getUser.toString(),
  resetAuth.toString(),
  resetAuthError.toString(),
  signin.toString(),
  signup.toString(),
  newSignup.toString(),
  logout.toString()
]

let ttlInterval: any

export interface ESFDeveloperInterface {
  activate?: (key: string) => void
  deactivate?: () => void
  getReduxState?: () => any
  dispatchAction?: (action: Action) => void
}

/* eslint-disable no-underscore-dangle */
declare global {
  interface Window {
    __esfDeveloperInterface__: any
  }
}

function startTTL() {
  clearInterval(ttlInterval)
  ttlInterval = setInterval(init, DEVELOPER_INTERFACE_SESSION_TTL_IN_MINUTES * 60000)
}

// init
export function activate(key: string) {
  const activatedDeveloperInterfaceKey = reduxStore.getState()?.auth?.activatedDeveloperInterfaceKey

  if (config.developerInterfaceKey === key && activatedDeveloperInterfaceKey !== key) {
    startTTL()

    reduxStore.dispatch(activateDeveloperInterface())

    window.__esfDeveloperInterface__ = {
      deactivate: init,
      getReduxState,
      dispatchAction,
      getConfig,
      AVAILABLE_ACTIONS
    }
  }
}

export function init() {
  clearInterval(ttlInterval)

  window.__esfDeveloperInterface__ = {
    activate
  }
}

// helper methods
export function getReduxState() {
  startTTL()

  return reduxStore.getState()
}

export function dispatchAction(action: Action) {
  startTTL()

  if (AVAILABLE_ACTIONS.includes(action.type)) {
    reduxStore.dispatch(action)
  }
}

export function getConfig() {
  startTTL()

  return config
}
/* eslint-enable no-underscore-dangle */
