import { useCallback, useMemo, useState } from 'react'
// TODO: Import Account type directly when BDS exports it
import { Account as BDSAccountSwitcherAccount } from '@barracuda-internal/bds-core/dist/UnifiedComponents/AccountSwitcher/AccountSwitcher'

import {
  setCurrentAccessToken,
  setCurrentBccAccount,
  reset as resetAccessToken
} from 'global/redux/features/accessToken/accessTokenSlice'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import useUserDataLib from 'global/lib/userData/useUserData'
import { NavbarContextMenuO365Props } from 'global/components/lib/layout/navbar'
import accessTokenLib from 'global/lib/accessToken/accessToken'

import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'
import { forensicsGetEssAccount } from 'fir/redux/features/remediation/remediationSlice'
import routesConfig from 'fir/lib/routes/routesConfig'

export interface FirLayoutLogic {
  initAccount: string
  navbarContextMenuO365Props?: NavbarContextMenuO365Props
  onSelectAccountCallback: (selectedAccount: BDSAccountSwitcherAccount) => void
}

export default function useLayoutLogic(): FirLayoutLogic[] {
  const dispatch = useAppDispatch()
  const [userDataLib] = useUserDataLib()

  const { accessTokenId, bccAccount } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id || '',
    bccAccount: _stores.accessToken.bccAccount || ''
  }))

  const [initAccount, setInitAccount] = useState(accessTokenId || bccAccount)
  const accounts = userDataLib.getAccounts()

  const onSelectAccountCallback = useCallback(
    async (selectedAccount: BDSAccountSwitcherAccount) => {
      const account = userDataLib.getAccountByBccId(selectedAccount.id)
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.NAVBAR_SWITCH_ACCOUNT, {
        selectedAccountId: selectedAccount.id
      })

      // If the account has a nestedAccounts property, it indicates that this account is a bccAccount (parent account) without an accessTokenId.
      if (selectedAccount.nestedAccounts) {
        setInitAccount(selectedAccount.id)
        dispatch(resetAccessToken())
        dispatch(setCurrentBccAccount(selectedAccount.id))

        const firSerialState = account?.bccSerialNumbers.fir.state
        if (firSerialState !== 'A') {
          routesConfig.START_TRIAL.goto()
          return
        }
        routesConfig.SIGNIN_CONNECT.goto()
      }
      // also need to verify if bccId changes when switching from bccId to accessTokenId
      else if (selectedAccount.id !== accessTokenId || selectedAccount.id !== accessTokenLib.getCurrentBccAccount()) {
        await dispatch(setCurrentAccessToken(selectedAccount.id))

        const validatedToken = userDataLib.getAccountByAccessToken(selectedAccount.id)
        if (validatedToken) {
          dispatch(setCurrentBccAccount(validatedToken.bccId as string))
        }

        dispatch(
          forensicsGetEssAccount({
            accessTokenId: selectedAccount.id
          })
        )
        routesConfig.REMEDIATION.goto({
          accessToken: selectedAccount.id
        })
      }
    },
    [accessTokenId, dispatch, userDataLib]
  )

  const navbarContextMenuO365Props: NavbarContextMenuO365Props = useMemo(
    () => ({
      accounts
    }),
    [accounts]
  )

  return useMemo(
    () => [
      {
        initAccount,
        navbarContextMenuO365Props,
        onSelectAccountCallback
      }
    ],
    [initAccount, navbarContextMenuO365Props, onSelectAccountCallback]
  )
}
