import React, { useMemo, useState } from 'react'

import {
  Button,
  Checkbox,
  CheckboxLabel,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Zoom
} from '@barracuda-internal/bds-core'

import DEFAULTS from 'global/configs/theme/defaults'
import { config } from 'global/lib/config'
import { useFormatMessage } from 'global/lib/localization'

import styles from 'admin/components/lib/dialogs/deactivate/scaryDialogStyles'

export interface ScaryDialogProps {
  accessTokenId: string
  deactivateType: string
  onClose: (confirm: boolean, deactivateType?: string) => void
  product: string
  updateDeactivateType: any
}

const BASE_I18N_KEY = 'admin.app.dialogs.scary_dialog'
const PRODUCT_BASE_I18N_KEY = 'admin.app.tabs'

const ScaryDialog: React.FC<ScaryDialogProps> = props => {
  const [confirmed, setConfirmed] = useState(false)
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const productFormatMessage = useFormatMessage(PRODUCT_BASE_I18N_KEY)
  const classes = styles()
  const { accessTokenId, deactivateType, onClose, product, updateDeactivateType } = props
  let productName = productFormatMessage('ets_name')

  switch (product) {
    case config.PRODUCTS.DFP:
      productName = productFormatMessage('dfp_name')
      break
    case config.PRODUCTS.FORENSICS:
      productName = productFormatMessage('fir_name')
      break
    case config.PRODUCTS.SENTINEL:
      productName = productFormatMessage('sen_name')
      break
    default:
      break
  }

  return useMemo(() => {
    return (
      <Dialog
        style={{ zIndex: DEFAULTS.SECONDARY_DIALOGS_ZINDEX }}
        maxWidth="md"
        open
        TransitionComponent={Zoom}
        data-field="dialog"
      >
        <DialogTitle className={classes.titleWrapper} disableTypography data-field="title-wrapper">
          <Typography data-field="dialog-title" className={classes.title}>
            {formatMessage('title')}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent} data-field="dialog-content">
          <RadioGroup name="deactivateProduct" value={deactivateType} onChange={updateDeactivateType()}>
            <FormControlLabel
              control={<Radio color="primary" />}
              value="single"
              label={formatMessage('deactivate_single', { productName })}
            />
            <FormControlLabel control={<Radio color="primary" />} value="all" label={formatMessage('deactivate_all')} />
          </RadioGroup>
          <Typography className={classes.label} data-field="dialog-label">
            {formatMessage('label', { accessTokenId, span: (text: string) => <span key={text}>{text}</span> })}
          </Typography>
          <CheckboxLabel
            className={classes.checkbox}
            control={
              <Checkbox
                color="primary"
                checked={confirmed}
                onChange={() => setConfirmed(!confirmed)}
                data-field="checkbox"
                data-action="confirm"
              />
            }
            label={formatMessage('checkbox')}
            data-field="checkbox-label"
          />
        </DialogContent>
        <DialogActions className={classes.actions} data-field="dialog-actions">
          <Button
            className={classes.cancelBtn}
            variant="text"
            onClick={() => onClose(false)}
            data-action="cancel"
            data-field="cancel-btn"
          >
            {formatMessage('cancel')}
          </Button>
          <Button
            className={classes.deactivateBtn}
            variant="text"
            onClick={() => onClose(true, deactivateType)}
            disabled={!confirmed}
            data-action="deactivate"
            data-field="deactivate-btn"
          >
            {formatMessage('deactivate')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }, [accessTokenId, classes, confirmed, deactivateType, formatMessage, onClose, productName, updateDeactivateType])
}

export default ScaryDialog
