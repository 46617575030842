import React from 'react'
import PropTypes from 'prop-types'

import numeral from 'numeral'

import { BarChart as GlobalBarChart } from '@barracuda-internal/bds-core'

import { ChartSeriesItemProps } from '@progress/kendo-react-charts'
import { COLORS } from 'ets/configs/styles/defaults'

import { CHART_AREA_HEIGHT, CHART_AREA_BIGGER_HEIGHT } from 'ets/components/lib/chartPanel/chartPanelStyles'

export interface BarChartProps {
  chartData?: ChartSeriesItemProps | undefined
  biggerChart?: boolean
  onSeriesHover?: (e: any) => void
  onCategoryAxisLabelHover?: (localizedValue: string) => void
}

const chartSteps = {
  1: 5,
  2: 2,
  3: 2,
  4: 2
} as { [key: number]: number }

const threatTypesDemoData = ['Conversation Hijacking', 'Extortion', 'Impersonation', 'Phishing', 'Scamming']
  .map(type => ({
    count: Math.floor(Math.random() * Math.floor(50000)),
    type
  }))
  .sort((a, b) => b.count - a.count)

const highRiskEmployeesDemoData = ['Accountants', 'Human Resources', 'Executives', 'Vice Presidents', 'IT']
  .map(type => ({
    count: Math.floor(Math.random() * Math.floor(300)),
    type
  }))
  .sort((a, b) => b.count - a.count)

const STEP_LIMIT = 1000

const BarChart: React.FC<BarChartProps> = ({ chartData, biggerChart, onSeriesHover, onCategoryAxisLabelHover }) => {
  const isEmptyChart = !!chartData && !(chartData.data || []).some(dataItem => dataItem[chartData.field || ''] > 0)
  const validatedChartData: any = chartData || {
    data: biggerChart ? highRiskEmployeesDemoData : threatTypesDemoData,
    field: 'count',
    categoryField: 'type'
  }

  const valueAxisMaxValue: number = validatedChartData.data.reduce((all: number, data: any) => {
    return Math.max(data[validatedChartData.field], all)
  }, 0)
  let valueAxisStep: number = chartSteps[valueAxisMaxValue]

  if (!valueAxisStep) {
    valueAxisStep = validatedChartData.data[0].count > STEP_LIMIT ? 2 : 1
  }

  return (
    <GlobalBarChart
      barChart={{
        chartProps: {
          ...(onSeriesHover && { onSeriesHover }),
          renderAs: 'canvas',
          onRender: e => {
            return e.target.chartInstance?.surface.bind('mouseenter', (e2: any) => {
              const { categoryAxisLabel } = e2.element.parent

              if (categoryAxisLabel && onCategoryAxisLabelHover) {
                onCategoryAxisLabelHover(categoryAxisLabel)
              }
            })
          }
        },
        chartStyle: { width: '100%', height: biggerChart ? CHART_AREA_BIGGER_HEIGHT : CHART_AREA_HEIGHT },
        chartValueAxisItemProps: {
          ...(isEmptyChart && { max: 30 }),
          labels: {
            step: isEmptyChart ? 2 : valueAxisStep,
            content: (e: any) => numeral(e.value).format('0a')
          }
        },
        chartCategoryAxisItemProps: {
          labels: {
            step: 1,
            visual: e => {
              const visual: any = e.createVisual()
              visual.categoryAxisLabel = e.value
              visual.children[0].options.cursor = 'default'
              return visual
            }
          },
          majorGridLines: { visible: false }
        },
        chartSeriesItemProps: {
          type: 'bar',
          field: validatedChartData.field,
          categoryField: validatedChartData.categoryField,
          gap: biggerChart ? 5 : 3,
          tooltip: { background: COLORS.RED, format: 'n', border: { color: 'black' } },
          border: { color: 'transparent' },
          color: COLORS.RED
        },
        chartData: validatedChartData.data
      }}
    />
  )
}

BarChart.propTypes = {
  chartData: PropTypes.any,
  biggerChart: PropTypes.bool,
  onSeriesHover: PropTypes.func,
  onCategoryAxisLabelHover: PropTypes.func
}

BarChart.defaultProps = {
  chartData: undefined,
  biggerChart: false,
  onSeriesHover: undefined,
  onCategoryAxisLabelHover: undefined
}

export default BarChart
