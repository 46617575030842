export enum StatusIds {
  emptyStatus = 'empty_status',
  markedAsFp = 'marked_as_false_positive',
  movedToJunkFolder = 'moved_to_junk_folder',
  alertedRecipient = 'alerted_recipient',
  alertedSentinelAdmin = 'alerted_sentinel_admin',
  alertedImpersonatedUser = 'alerted_impersonated_sender',
  deleted = 'deleted',
  failedToDelete = 'failed_to_delete',
  inProgress = 'in_progress',
  markedAsFpRemediation = 'marked_as_false_positive_remediation',
  reviewed = 'reviewed',
  notReviewed = 'not_reviewed',
  olderThank30days = 'older_than_30_days'
}
