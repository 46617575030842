import React from 'react'
import PropTypes from 'prop-types'

import styles from './cellStyles'

export interface Props {
  icon: any
  className?: string
}

const CellIcon: React.FC<Props> = ({ icon: Icon, className, ...rest }) => {
  const classes = styles()

  return <Icon className={`${classes.icon} ${className}`} {...rest} />
}

CellIcon.propTypes = {
  icon: PropTypes.any.isRequired,
  className: PropTypes.string
}

CellIcon.defaultProps = {
  className: undefined
}

export default CellIcon
