import { makeStyles } from '@material-ui/core/styles'
import colors from '@barracuda-internal/bds-core/dist/styles/colors'

import DEFAULTS from 'ets/configs/styles/defaults'

export default makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  appBar: {
    backgroundColor: `${colors.bgEmailProtection}`,
    bottom: 0,
    height: 66,
    top: 'auto'
  },
  footer: {
    width: DEFAULTS.DESKTOP_WIDTH
  },
  text: {
    color: '#FFFFFF',
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 0.12,
    width: 547
  },
  barracudaLogoWhite: {
    height: 40
  }
}))
