import { useCallback, useEffect, useMemo } from 'react'

import { useCookies } from 'react-cookie'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { isFailed, isPending } from 'global/redux/toolkit/api'

import { getPostDelivery, getRelated, resetPotentialIncidentsFailures } from 'fir/redux/features/insights/insightsSlice'
import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'
import { PotentialThreat } from 'fir/redux/types/Insights'

import { POTENTIAL_INCIDENTS_CTA_COOKIE } from 'fir/components/lib/ctaBanner/useCTABannerLogic'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UsePotentialIncidentsParams {}

export interface PotentialIncidentsProps {
  hideCTA: boolean
  isLoading: boolean
  onClosePageAlert: () => void
  potentialThreats: PotentialThreat[]
  showPotentialIncidentsPageError: boolean
}

export default function usePotentialIncidentsLogic(params: UsePotentialIncidentsParams): [PotentialIncidentsProps] {
  const [cookies] = useCookies([POTENTIAL_INCIDENTS_CTA_COOKIE])
  const dispatch = useAppDispatch()

  // Redux Toolkit stores
  const { accessTokenId, insights } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id || '',
    insights: _stores.insights
  }))

  // insights state
  const { isPostDeliveryFailed, isPostDeliveryLoading, isRelatedFailed, isRelatedLoading, postDelivery, related } = {
    isPostDeliveryFailed: isFailed(insights.getPostDeliveryApiStatus),
    isPostDeliveryLoading: isPending(insights.getPostDeliveryApiStatus),
    isRelatedFailed: isFailed(insights.getRelatedApiStatus),
    isRelatedLoading: isPending(insights.getRelatedApiStatus),
    postDelivery: insights.postDelivery,
    related: insights.related
  }

  useEffect(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.VIEW_INSIGHTS_PAGE, {
      accessTokenId
    })

    dispatch(getPostDelivery({ accessTokenId }))
    dispatch(getRelated({ accessTokenId }))
  }, [accessTokenId, dispatch])

  const onClosePageAlert = useCallback(() => {
    dispatch(resetPotentialIncidentsFailures())
  }, [dispatch])

  const potentialThreats = useMemo(() => {
    const sorted = [...postDelivery, ...related].sort((a: PotentialThreat, b: PotentialThreat) => {
      if (a.reportedDate < b.reportedDate) {
        return 1
      }
      if (a.reportedDate > b.reportedDate) {
        return -1
      }
      return 0
    })
    return sorted
  }, [postDelivery, related])

  const showPotentialIncidentsPageError = useMemo(() => {
    return isPostDeliveryFailed || isRelatedFailed
  }, [isPostDeliveryFailed, isRelatedFailed])

  return useMemo(
    () => [
      {
        hideCTA: cookies[POTENTIAL_INCIDENTS_CTA_COOKIE] === 'true',
        isLoading: isPostDeliveryLoading || isRelatedLoading,
        onClosePageAlert,
        potentialThreats,
        showPotentialIncidentsPageError
      }
    ],
    [
      cookies,
      isPostDeliveryLoading,
      isRelatedLoading,
      onClosePageAlert,
      potentialThreats,
      showPotentialIncidentsPageError
    ]
  )
}
