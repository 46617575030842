import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'

import { process } from '@progress/kendo-data-query'

import { isFailed, isPending } from 'global/redux/toolkit/api'
import { SearchFieldProps, useSearchFieldLogic } from 'global/components/lib/searchField/SearchField'
import { BDSGridPagerConfig, BDSGridSortConfig } from 'global/types/dataTables/dataTables'
import useTablePeriodicCheck from 'global/lib/useTablePeriodicCheck/useTablePeriodicCheck'
import { formatDate } from 'global/lib/datetime'
import { config } from 'global/lib/config'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'

import { ColumnsConfig } from 'admin/redux/types/dataTables'
import { useAppDispatch, useAppSelector } from 'admin/redux/toolkit/hooks'
import {
  deactivate,
  deactivateAndReset,
  listAccessTokens,
  resetAccessTokens,
  reset as resetAdminSlice
} from 'admin/redux/features/admin/adminSlice'
import {
  update as updateAccountsTable,
  reset as resetAccountsTable
} from 'admin/redux/features/dataTables/dfpAccessTokens/dfpAccessTokensSlice'
import { ScaryDialogProps } from 'admin/components/lib/dialogs/deactivate/ScaryDialog'
import { DeactivateDialogProps } from 'admin/components/lib/dialogs/deactivate/DeactivateDialog'
import { ListAccessToken, ModifiedListAccessToken } from 'admin/redux/types/scans'
import { TokenDialogProps } from 'admin/components/lib/dialogs/tokenDialog/TokenDialog'

export type InProgress = boolean
export interface TableConfig {
  columns: { [key: string]: string }
  columnsConfig: ColumnsConfig
  isLoaded: boolean
  inProgress: boolean
  onOpenScary: (account: ModifiedListAccessToken, deactivateType: string) => void
  onOpenToken: (account: ModifiedListAccessToken) => void
  onOpenUserSelect: (account: ModifiedListAccessToken) => void
  pageConfig: BDSGridPagerConfig
  searchFieldConfig: SearchFieldProps
  sortConfig: BDSGridSortConfig | {}
  tableData: {
    total: number
    data: ModifiedListAccessToken[]
  }
}

export interface ScaryDialogConfig {
  accessTokenId: ScaryDialogProps['accessTokenId'] | undefined
  deactivateType: string
  onClose: (confirmed: boolean, deactivateType?: string) => void
  open: boolean
  product: string
  updateDeactivateType: () => void
}
export interface DeactivateDialogConfig {
  open: boolean
  accessTokenId: DeactivateDialogProps['accessTokenId'] | undefined
}
export interface TokenDialogConfig {
  open: boolean
  onClose: () => void
  accessToken: TokenDialogProps['accessToken'] | undefined
  product: TokenDialogProps['product']
}
export interface UserSelectConfig {
  bccAccountId: number | undefined
  createdByEmail: string | undefined
  onClose: () => void
  open: boolean
}

const accessTokenProduct = 'dfp'
const product = config.PRODUCTS.DFP
const SEARCH_FIELD_ID = 'scans-table-search'

export default function useAllAccountLogic(): [
  TableConfig,
  ScaryDialogConfig,
  DeactivateDialogConfig,
  TokenDialogConfig,
  UserSelectConfig
] {
  const dispatch = useAppDispatch()
  const {
    accessTokens,
    accessTokenTable,
    deactivateFailed,
    deactivateAndResetFailed,
    listAccessTokenInProgress,
    loadedAccessTokensOffsets
  } = useAppSelector(_stores => ({
    accessTokens: _stores.admin.accessTokens,
    accessTokenTable: _stores.dataTables.dfpAccessTokens,
    deactivateFailed: isFailed(_stores.admin.deactivateApiStatus),
    deactivateAndResetFailed: isFailed(_stores.admin.deactivateAndResetApiStatus),
    listAccessTokenInProgress: isPending(_stores.admin.listAccessTokensApiStatus),
    loadedAccessTokensOffsets: _stores.admin.loadedAccessTokensOffsets
  }))
  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    deactivateType: 'single',
    searchString: ''
  })

  const [initTableRefresh] = useTablePeriodicCheck()
  const [isTokenDialogOpened, toggleTokenDialog] = useDialogLogic()
  const [isScaryDialogOpened, toggleScaryDiaog] = useDialogLogic()
  const [isDeactivateDialogOpened, toggleDeactivateDiaog] = useDialogLogic()
  const [isUserSelectDialogOpened, toggleUserSelectDialog] = useDialogLogic()
  const [selectedAccessToken, setSelectedAccessToken] = useState<{
    accessToken: ModifiedListAccessToken
  }>()

  // init
  useEffect(() => {
    initTableRefresh(tableRefresh)

    dispatch(listAccessTokens({ product: accessTokenProduct }))

    // unmount
    return () => {
      dispatch(resetAccessTokens(true))
      dispatch(resetAccountsTable())
      dispatch(resetAdminSlice())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resetTable = useCallback(() => {
    dispatch(resetAccessTokens())
    dispatch(listAccessTokens({ product: accessTokenProduct }))
  }, [dispatch])

  const tableRefresh = useCallback(() => {
    if (!listAccessTokenInProgress) {
      resetTable()
    }
  }, [resetTable, listAccessTokenInProgress])

  const updateTableData = useCallback(
    (changes: any = {}) => {
      dispatch(
        updateAccountsTable({
          ...changes,
          skip: 0
        })
      )

      initTableRefresh(tableRefresh)
      resetTable()
    },
    [dispatch, resetTable, initTableRefresh, tableRefresh]
  )

  const [debouncedOnChange, validateSearchString] = useSearchFieldLogic(
    (search: string) => {
      updateTableData({ search: search.trim() })
      setState({ searchString: search.trim() })
    },
    listAccessTokenInProgress,
    SEARCH_FIELD_ID
  )

  const tableData = useMemo(() => {
    const { skip, take } = accessTokenTable

    const { data } = process(
      (accessTokens?.data || []).map((report: ListAccessToken) => ({
        ...(report && {
          ...report,
          createdOnFormatted: formatDate(report.createdOn || '', config.DATETIME.DATE_WITH_TIME_FORMAT_WITHOUT_AT)
        })
      })),
      { skip, take }
    )

    return {
      data: data.filter(report => report.id),
      total: accessTokens?.totalCount || 0
    }
  }, [accessTokens, accessTokenTable])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = accessTokenTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: tableData.total,
      onPageChange: (e: any) => {
        dispatch(updateAccountsTable(e.page))

        if (!loadedAccessTokensOffsets?.includes(e.page.skip)) {
          dispatch(listAccessTokens({ product: accessTokenProduct }))
        }
      }
    }
  }, [accessTokenTable, tableData, dispatch, loadedAccessTokensOffsets])

  const sortConfig: BDSGridSortConfig | {} = useMemo(() => {
    if (!tableData.total) {
      return {}
    }

    return {
      sortable: !listAccessTokenInProgress && {
        allowUnsort: false,
        mode: 'single'
      },
      sort: accessTokenTable.sort,
      onSortChange: (e: any) => {
        updateTableData({ sort: e.sort })
      }
    }
  }, [accessTokenTable, updateTableData, tableData.total, listAccessTokenInProgress])

  const searchFieldConfig: SearchFieldProps = useMemo(() => {
    return {
      id: SEARCH_FIELD_ID,
      value: state.searchString,
      onChange: (e: any) => {
        const validatedSearchString = validateSearchString(e.target.value)

        if (accessTokenTable.search !== validatedSearchString) {
          debouncedOnChange(validatedSearchString)
          setState({ searchString: validatedSearchString })
        }
      },
      disabled: listAccessTokenInProgress
    }
  }, [state.searchString, accessTokenTable, debouncedOnChange, validateSearchString, listAccessTokenInProgress])

  const onOpenToken = useCallback(
    (account: ModifiedListAccessToken) => {
      setSelectedAccessToken({ accessToken: account })
      toggleTokenDialog()
    },
    [toggleTokenDialog]
  )

  const onCloseDetails = useCallback(() => {
    setSelectedAccessToken(undefined)
    toggleTokenDialog()
  }, [toggleTokenDialog])

  const onOpenScary = useCallback(
    (account: ModifiedListAccessToken, deactivateType: string) => {
      setSelectedAccessToken({ accessToken: account })
      setState({ deactivateType })
      toggleScaryDiaog()
    },
    [toggleScaryDiaog]
  )

  const onCloseScary = useCallback(
    (confirmed: boolean, deactivateType?: string) => {
      toggleScaryDiaog()
      if (confirmed && selectedAccessToken) {
        if (deactivateType === 'all') {
          // Product doesn't not beed to be passed because Forensics is the default deactivate case  in apiThunk
          dispatch(
            deactivateAndReset({
              accountId: selectedAccessToken.accessToken.accountUuid,
              accessTokenId: selectedAccessToken.accessToken.accessTokenId,
              email: selectedAccessToken.accessToken.createdByEmail,
              product
            })
          )
        } else {
          dispatch(
            deactivate({
              accountId: selectedAccessToken.accessToken.accountUuid,
              accessTokenId: selectedAccessToken.accessToken.accessTokenId,
              product
            })
          )
        }
        setState({ deactivateType: 'single' })
        toggleDeactivateDiaog()
      } else {
        setSelectedAccessToken(undefined)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toggleScaryDiaog, dispatch, selectedAccessToken]
  )

  const onOpenUserSelect = useCallback(
    (account: ModifiedListAccessToken) => {
      setSelectedAccessToken({ accessToken: account })
      toggleUserSelectDialog()
    },
    [toggleUserSelectDialog]
  )

  const onCloseUserSelectDialog = useCallback(() => {
    setSelectedAccessToken(undefined)
    toggleUserSelectDialog()
  }, [toggleUserSelectDialog])

  // close deactivate dialog on failed request
  useEffect(() => {
    if ((deactivateFailed || deactivateAndResetFailed) && isDeactivateDialogOpened) {
      setSelectedAccessToken(undefined)
      toggleDeactivateDiaog()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deactivateFailed, deactivateAndResetFailed])

  return useMemo(() => {
    return [
      {
        isLoaded: !!accessTokens.data,
        inProgress: listAccessTokenInProgress,
        searchFieldConfig,
        tableData,
        pageConfig,
        sortConfig,
        columns: accessTokenTable.GRID_COLUMNS,
        columnsConfig: accessTokenTable.columnsConfig,
        onOpenToken,
        onOpenScary,
        onOpenUserSelect
      },
      {
        accessTokenId: selectedAccessToken?.accessToken.accessTokenId,
        deactivateType: state.deactivateType,
        onClose: onCloseScary,
        open: isScaryDialogOpened,
        product,
        updateDeactivateType: () => (e: any) => {
          setState({ deactivateType: e.target.value })
        }
      },
      {
        open: isDeactivateDialogOpened,
        accessTokenId: selectedAccessToken?.accessToken.accessTokenId
      },
      {
        open: isTokenDialogOpened,
        onClose: onCloseDetails,
        accessToken: selectedAccessToken?.accessToken,
        product
      },
      {
        bccAccountId: selectedAccessToken?.accessToken.bccId,
        createdByEmail: selectedAccessToken?.accessToken.createdByEmail,
        open: isUserSelectDialogOpened,
        onClose: onCloseUserSelectDialog
      }
    ]
  }, [
    accessTokens.data,
    listAccessTokenInProgress,
    searchFieldConfig,
    tableData,
    pageConfig,
    sortConfig,
    accessTokenTable.GRID_COLUMNS,
    accessTokenTable.columnsConfig,
    onOpenToken,
    onOpenScary,
    onOpenUserSelect,
    selectedAccessToken,
    state.deactivateType,
    onCloseScary,
    isScaryDialogOpened,
    isDeactivateDialogOpened,
    isTokenDialogOpened,
    onCloseDetails,
    isUserSelectDialogOpened,
    onCloseUserSelectDialog
  ])
}
