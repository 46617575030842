import { useCallback, useEffect, useMemo, useReducer } from 'react'

import { EventConstants } from '@barracuda/automated-workflows'

import IMAGES from 'global/configs/theme/images.config'
import { config } from 'global/lib/config'
import useAccessTokenLib from 'global/lib/accessToken/useAccessToken'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { useFormatMessage } from 'global/lib/localization'

import { isPending } from 'global/redux/toolkit/api'

import {
  resetSettingsFailures,
  saveAccessTokenSettings,
  updateAccessTokenSettings
} from 'fir/redux/features/settings/settingsSlice'
import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'

export interface UseWebhookDialogParams {
  isOpened: boolean
  onCloseWebhookDialog: () => void
  toggleDialog: () => void
  webhookSource: WebhookSource
}

export interface WebhookDialogProps {
  isOpened: boolean
  isSaveSettingsLoading: boolean
  isWebhookValid: boolean
  onClose: () => void
  onSave: () => void
  showValidationError: boolean
  updateWebhook: (formProp: string) => (e: any) => void
  webhookComponentStrings: WebhookComponentStrings
  webhookSource: WebhookSource
  webhookValue: string
}

interface WebhookSource {
  action: string
  name: string
}

// Modify this to return an object of strings (return link, label, title, description, error text, and icon)
interface WebhookComponentStrings {
  description?: string
  errorText?: string
  helpLink?: any
  helpLinkLabel?: string
  helperText?: string
  iconPath?: string
  label?: string
  title?: string
}

const BASE_I18N_KEY = 'fir.app.automated_workflows.webhook_dialog'
const SETTINGS_I18N_KEY = 'fir.app.settings_dialog.automated_workflows'
const { VALID_SLACK_WEBHOOK_STARTS, VALID_MSTEAMS_WEBHOOK_CONTAINS } = config.VALIDATORS

export default function useWebhookDialogLogic(params: UseWebhookDialogParams): [WebhookDialogProps] {
  const { isOpened, onCloseWebhookDialog, toggleDialog, webhookSource } = params
  const dispatch = useAppDispatch()
  const formatDialogMessage = useFormatMessage(SETTINGS_I18N_KEY)
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [accessTokenLib] = useAccessTokenLib()

  // Redux Toolkit stores
  const { accessTokenId, settings } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id || '',
    settings: _stores.settings
  }))

  const { isSaveSettingsLoading } = {
    isSaveSettingsLoading: isPending(settings.saveAccessTokenSettingsApiStatus)
  }

  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    showValidationError: false,
    webhookValue: ''
  })

  // Get webhook values from accessToken.settings store
  const initializeWebhooks = useCallback(() => {
    const currentSettings = accessTokenLib.getCurrentSettings()
    const currentWebhooks = {
      forensicsAutomatedWorkflowSlackWebhook: currentSettings.forensicsAutomatedWorkflowSlackWebhook,
      forensicsAutomatedWorkflowMSTeamsWebhook: currentSettings.forensicsAutomatedWorkflowMSTeamsWebhook
    }

    // Only updates settings.forensics store
    dispatch(updateAccessTokenSettings(currentWebhooks))
  }, [accessTokenLib, dispatch])

  // Set webhook values in settings.forensics store
  useEffect(() => {
    initializeWebhooks()
  }, [initializeWebhooks])

  // Check to see if webhook is valid
  const isWebhookValid = useMemo(() => {
    switch (webhookSource.action) {
      case EventConstants.SLACK_NOTIFICATION:
        return state.webhookValue.startsWith(VALID_SLACK_WEBHOOK_STARTS)
      case EventConstants.MSTEAMS_NOTIFICATION:
        return state.webhookValue.indexOf(VALID_MSTEAMS_WEBHOOK_CONTAINS) !== -1
      default:
        return false
    }
  }, [state.webhookValue, webhookSource])

  // Update the value of the webhook entered
  const updateWebhook = useCallback(
    (formProp: string) => (e: any) => {
      if (formProp === 'slackNotification') {
        setState({ webhookValue: e.target.value })
      }
      if (formProp === 'msteamsNotification') {
        setState({ webhookValue: e.target.value })
      }
    },
    []
  )

  // Modify this to return an object of strings (return link, label, title, description, error text, and icon)
  const webhookComponentStrings = useMemo(() => {
    const dialogStrings = {
      description: formatMessage('labels.webhook_dialog_description', {
        webhookSource: webhookSource.name
      }),
      helpLinkLabel: formatMessage('labels.help_link_label', {
        webhookSource: webhookSource.name
      }),
      title: formatMessage('labels.webhook_dialog_title', { webhookSource: webhookSource.name })
    }

    switch (webhookSource.action) {
      case EventConstants.SLACK_NOTIFICATION:
        return {
          ...dialogStrings,
          errorText: formatDialogMessage('labels.slack_webhook_error'),
          helpLink: config.LINKS.SLACK_WEBHOOK_HELP,
          helperText: formatMessage('labels.webhook_dialog_helper_text', { webhookSource: webhookSource.name }),
          iconPath: IMAGES.slackIcon,
          label: formatMessage('labels.slack_webhook'),
          title: formatMessage('labels.webhook_dialog_title', { webhookSource: webhookSource.name })
        }
      case EventConstants.MSTEAMS_NOTIFICATION:
        return {
          ...dialogStrings,
          errorText: formatDialogMessage('labels.msTeams_webhook_error'),
          helpLink: config.LINKS.MSTEAMS_WEBHOOK_HELP,
          helperText: formatMessage('labels.webhook_dialog_helper_text', { webhookSource: webhookSource.name }),
          iconPath: IMAGES.msTeamsIcon,
          label: formatMessage('labels.msteams_webhook')
        }
      default:
        return {}
    }
  }, [formatDialogMessage, formatMessage, webhookSource])

  const onClose = useCallback(() => {
    setState({ webhookValue: '' })
    dispatch(resetSettingsFailures())
    onCloseWebhookDialog()
  }, [dispatch, onCloseWebhookDialog])

  const onSave = useCallback(() => {
    if (isWebhookValid) {
      if (webhookSource.action === EventConstants.SLACK_NOTIFICATION) {
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.SAVE_SETTINGS, {
          accessTokenId,
          page: 'automated-workflows',
          setting: 'forensicsAutomatedWorkflowSlackWebhook'
        })
        dispatch(
          saveAccessTokenSettings({
            accessTokenId,
            settings: { forensicsAutomatedWorkflowSlackWebhook: state.webhookValue }
          })
        )
      } else {
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.SAVE_SETTINGS, {
          accessTokenId,
          page: 'automated-workflows',
          setting: 'forensicsAutomatedWorkflowMSTeamsWebhook'
        })
        dispatch(
          saveAccessTokenSettings({
            accessTokenId,
            settings: { forensicsAutomatedWorkflowMSTeamsWebhook: state.webhookValue }
          })
        )
      }
      toggleDialog()
    } else {
      setState({ showValidationError: true })
    }
  }, [accessTokenId, dispatch, isWebhookValid, state.webhookValue, toggleDialog, webhookSource.action])

  return useMemo(
    () => [
      {
        isOpened,
        isSaveSettingsLoading,
        isWebhookValid,
        onClose,
        onSave,
        showValidationError: state.showValidationError,
        toggleDialog,
        updateWebhook,
        webhookComponentStrings,
        webhookSource,
        webhookValue: state.webhookValue
      }
    ],
    [
      isOpened,
      isSaveSettingsLoading,
      isWebhookValid,
      onClose,
      onSave,
      state,
      toggleDialog,
      updateWebhook,
      webhookComponentStrings,
      webhookSource
    ]
  )
}
