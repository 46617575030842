import { makeStyles } from '@material-ui/core/styles'
import { COLORS } from 'global/configs/theme/defaults'
import selectLabelStyles from 'global/components/lib/select/selectLabelStyles'

export const SELECT_WIDTH = 400

export default makeStyles(theme => ({
  stepper: {
    width: 100,
    height: 30,
    boxShadow: 'none',
    position: 'absolute',
    right: theme.spacing(3),
    top: theme.spacing(1)
  },
  selectWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: SELECT_WIDTH
  },
  select: {
    fontSize: 14
  },
  menuItem: {
    width: SELECT_WIDTH
  },
  text: {
    ...selectLabelStyles.wrappedLabel,
    fontSize: '14px !important'
  },
  echoError: {
    background: COLORS.ALERT_BOX.ALERT_BACKGROUND,
    borderRadius: theme.spacing(0.5),
    color: COLORS.ALERT_BOX.RED
  },
  echoErrorText: {
    color: COLORS.ALERT_BOX.ALERT_TEXT,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '150%'
  },
  echoErrorDetailText: {
    color: COLORS.ALERT_BOX.ALERT_TEXT,
    fontSize: 14
  },
  boldLinks: {
    fontWeight: 700
  }
}))
