import React, { createContext, useMemo } from 'react'

import { Popover, IconButton } from '@barracuda-internal/bds-core'
import Icon from '@material-ui/core/Icon'

import IMAGES from 'global/configs/theme/images.config'
import styles from 'global/components/lib/layout/navbar/navbarStyles'
import useCommonAnchorElLogic from 'global/components/lib/anchorElLogic/useAnchorElLogic'

export interface NavbarContextMenuProps {
  children: false | null | JSX.Element | (false | JSX.Element)[]
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const NavbarContextMenuContext = createContext({ closeMenu: () => {} } as { closeMenu: () => void })

const NavbarContextMenu: React.FC<NavbarContextMenuProps> = ({ children }) => {
  const classes = styles()
  const [anchorEl, handleClick, handleClose] = useCommonAnchorElLogic()

  return useMemo(
    () => (
      <div>
        <IconButton aria-label="Open menu" edge="end" onClick={handleClick}>
          <Icon>
            <img className={classes.vertButton} alt="MENU" src={IMAGES.iconAccountWhite} />
          </Icon>
        </IconButton>
        <Popover
          className={classes.vertPopover}
          id="fir-account-list"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <NavbarContextMenuContext.Provider value={{ closeMenu: handleClose }}>
            {children}
          </NavbarContextMenuContext.Provider>
        </Popover>
      </div>
    ),
    [classes, anchorEl, handleClick, handleClose, children]
  )
}

export default NavbarContextMenu
