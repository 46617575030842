/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react'

import {
  Typography,
  DataTable,
  DataTableColumn,
  Button,
  Grid,
  Paper,
  Alert as AlertComponent
} from '@barracuda-internal/bds-core'
import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'

import { useFormatMessage, useErrorFormatMessage } from 'global/lib/localization'
import DateRangeSelector from 'global/components/lib/dateRangeSelector/DateRangeSelector'
import CircularProgress from 'global/components/lib/circularProgress/CircularProgress'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import Pager from 'global/components/lib/grid/pager/Pager'
import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'
import CellImage from 'global/components/lib/grid/cell/CellImage'
import GeoChart from 'global/components/lib/charts/GeoChart'
import * as analyticsLib from 'global/lib/analytics/analyticsService'

import IdentityAutocomplete from 'sen/components/lib/autocompletes/identity/IdentityAutocomplete'
import styles from 'sen/components/pages/ato/content/tabs/tabStyles'
import signinsStyles from 'sen/components/pages/ato/content/tabs/signIns/signinsStyles'
import useSigninsLogic, {
  ModifiedSigninByAllCountries
} from 'sen/components/pages/ato/content/tabs/signIns/useSigninsLogic'
import SigninsDialog, { DialogType } from 'sen/components/lib/dialogs/signins/SigninsDialog'

const BASE_I18N_KEY = 'sen.app.account_takeover.signins'
const BASE_I18N_TABLE_KEY = 'sen.app.data_tables.signins_by_country_table'
const BASE_RECIPIENTS_AUTOCOMPLETE_I18N_KEY = 'sen.app.autocompletes.recipients'

const SignIns: React.FC = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const tableFormatMessage = useFormatMessage(BASE_I18N_TABLE_KEY)
  const formatMessageError = useErrorFormatMessage()
  const classes = styles()
  const signinsClasses = signinsStyles()
  const [
    recipientsSelector,
    signinsDialog,
    eventHandlers,
    dateRangeSelectorConfig,
    tableConfig,
    chartConfig,
    error
  ] = useSigninsLogic()

  return useMemo(
    () => (
      <Grid container direction="column" data-field="sign-ins-grid">
        {error && (
          <AlertComponent variant="filled" severity="error" data-field="signins-alert">
            {formatMessageError(error)}
          </AlertComponent>
        )}

        <Grid item data-field="sign-ins-grid-title">
          <Typography className={classes.title} data-field="sign-ins-title">
            {formatMessage('title')}
          </Typography>
          <Typography className={classes.subtitle} variant="subtitle2" data-field="sign-ins-subtitle">
            {formatMessage('subtitle')}
          </Typography>
        </Grid>
        <Grid item data-field="sign-ins-investigate-accounts">
          <Paper className={classes.autocompleteContainer} elevation={0}>
            <Grid container direction="row" alignItems="center" justifyContent="center">
              <Grid item xs data-testid="autocomplete-wrapper">
                <IdentityAutocomplete
                  {...recipientsSelector.config}
                  BASE_I18N_KEY={BASE_RECIPIENTS_AUTOCOMPLETE_I18N_KEY}
                  inProgress={signinsDialog.isDialogOpened}
                  dataTestid="signins-identities"
                />
              </Grid>
              <Grid item xs="auto">
                <Button
                  color="primary"
                  size="large"
                  className={classes.autocompleteButton}
                  disabled={!recipientsSelector.selectedRecipient}
                  onClick={eventHandlers.onViewUserSignins}
                  data-field="view-signins"
                >
                  &nbsp;&nbsp;{formatMessage('view_signins')}&nbsp;&nbsp;
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid
          className={signinsClasses.dateRangeSelector}
          container
          justifyContent="flex-end"
          alignItems="center"
          data-field="date-range"
        >
          <Grid item className={signinsClasses.dateRangeSelectorInner}>
            <DateRangeSelector {...dateRangeSelectorConfig} />
          </Grid>
        </Grid>

        <Grid className={signinsClasses.signinsByCountryContainer} container spacing={2} direction="column">
          <Grid item xs={12}>
            <Typography className={classes.title} data-field="map-title">
              {formatMessage('map_title')}
            </Typography>

            <div className={signinsClasses.chartWrapper}>
              <div className={signinsClasses.chart}>
                <GeoChart
                  data={[chartConfig.header, ...chartConfig.data]}
                  onChartSelect={eventHandlers.onViewCountrySignins(
                    analyticsLib.EVENTS.ATO_SIGNINS_COUNTRY_DRILLDOWN_MAP
                  )}
                />
                <div className={signinsClasses.maskLayer} />
              </div>
            </div>
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.title} data-field="table-title">
              {formatMessage('table_title')}
            </Typography>

            <Paper className={classes.tableCard} elevation={0}>
              {!tableConfig.isLoaded && (
                <Grid container justifyContent="center" className={classes.tableCircularProgress}>
                  <CircularProgress data-field="loader" />
                </Grid>
              )}

              {tableConfig.isLoaded && (
                <Grid
                  container
                  direction="column"
                  className={classes.tableWrapper}
                  data-field="signins-by-country-table-wrapper"
                >
                  <Grid
                    className={`${tableConfig.isFlexibleTable ? classes.flexibleTableArea : classes.tableArea} ${
                      !tableConfig.tableData.total ? 'empty-table' : ''
                    }`}
                    data-table="signins-by-country"
                    item
                    xs={12}
                    data-field="grid-table"
                  >
                    {tableConfig.inProgress && (
                      <LinearProgress className={classes.indicator} data-field="linear-progress" />
                    )}
                    <DataTable
                      data-testid="signins-by-country-table"
                      className={`${
                        tableConfig.tableData.total
                          ? tableConfig.isFlexibleTable
                            ? classes.flexibleDataTable
                            : classes.dataTable
                          : classes.emptyDataTable
                      } signin-by-country-table`}
                      data={tableConfig.tableData}
                      {...tableConfig.pageConfig}
                      {...tableConfig.sortConfig}
                      pager={Pager}
                      data-field="data-table"
                    >
                      <DataTableNoRecords data-field="empty-table">
                        <Typography>
                          {!tableConfig.inProgress && !tableConfig.tableData.total && tableFormatMessage('empty_table')}
                        </Typography>
                      </DataTableNoRecords>
                      <DataTableColumn
                        field={tableConfig.columns.COUNTRY}
                        {...tableConfig.columnsConfig[tableConfig.columns.COUNTRY]}
                        title={tableFormatMessage(tableConfig.columns.COUNTRY)}
                        cell={({ dataItem }: { dataItem: ModifiedSigninByAllCountries }) => (
                          <Cell className={classes.cell} data-field="table-column-country">
                            <CellImage data-field="table-column-country-image" src={dataItem.flagSrc} />
                            <CellText className={classes.normalCell} data-field="table-column-country-text">
                              {dataItem.name.replace('of America', '')}
                            </CellText>
                          </Cell>
                        )}
                      />
                      <DataTableColumn
                        field={tableConfig.columns.TOTAL_SIGNINS}
                        {...tableConfig.columnsConfig[tableConfig.columns.TOTAL_SIGNINS]}
                        title={tableFormatMessage(tableConfig.columns.TOTAL_SIGNINS)}
                        cell={({ dataItem }: { dataItem: ModifiedSigninByAllCountries }) => (
                          <Cell className={classes.cell} data-field="table-column-total-signins">
                            <CellText className={classes.normalCell} data-field="table-column-total-signins-text">
                              {dataItem.signInCount}
                            </CellText>
                          </Cell>
                        )}
                      />
                      <DataTableColumn
                        field={tableConfig.columns.FAILED_SIGNINS}
                        {...tableConfig.columnsConfig[tableConfig.columns.FAILED_SIGNINS]}
                        title={tableFormatMessage(tableConfig.columns.FAILED_SIGNINS)}
                        cell={({ dataItem }: { dataItem: ModifiedSigninByAllCountries }) => (
                          <Cell className={classes.cell} data-field="table-column-failed-signins">
                            <CellText className={classes.normalCell} data-field="table-column-failed-signins-text">
                              {dataItem.failedLoginCount}
                            </CellText>
                          </Cell>
                        )}
                      />
                      <DataTableColumn
                        field={tableConfig.columns.ACTIONS}
                        {...tableConfig.columnsConfig[tableConfig.columns.ACTIONS]}
                        title=" "
                        cell={({ dataItem }: { dataItem: ModifiedSigninByAllCountries }) => (
                          <Cell className={classes.cell} data-field="table-column-actions">
                            <Grid container direction="row" justifyContent="center">
                              <Button
                                color="primary"
                                size="large"
                                onClick={() => {
                                  eventHandlers.onViewCountrySignins(
                                    analyticsLib.EVENTS.ATO_SIGNINS_COUNTRY_DRILLDOWN_TABLE
                                  )(dataItem.country)
                                }}
                                data-field="view-country-signins-button"
                              >
                                {tableFormatMessage('view')}
                              </Button>
                            </Grid>
                          </Cell>
                        )}
                      />
                    </DataTable>
                  </Grid>
                </Grid>
              )}
            </Paper>
          </Grid>
        </Grid>

        {signinsDialog.isDialogOpened && (
          <SigninsDialog
            dialogType={signinsDialog.dialogType as DialogType}
            user={signinsDialog.alert}
            country={signinsDialog.country}
            onClose={signinsDialog.toggleDialog}
          />
        )}
      </Grid>
    ),
    [
      classes,
      signinsClasses,
      formatMessage,
      recipientsSelector,
      signinsDialog,
      eventHandlers,
      dateRangeSelectorConfig,
      tableConfig,
      tableFormatMessage,
      error,
      formatMessageError,
      chartConfig
    ]
  )
}

export default SignIns
