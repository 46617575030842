import { makeStyles } from '@material-ui/core/styles'

export const CELL_HEIGHT = 41

export default makeStyles(theme => ({
  sidemenuContainer: {
    padding: theme.spacing(2, 3),
    gap: theme.spacing(2)
  },
  searchContainer: {
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.border}`,
    backgroundColor: theme.palette.background.paper,
    height: 40,
    display: 'flex'
  },
  list: {
    cursor: 'pointer'
  },
  listLabelCount: {
    paddingRight: '10px'
  },
  listLabel: {
    cursor: 'pointer',
    fontWeight: 600
  },
  dropdownList: {
    paddingLeft: theme.spacing(2)
  }
}))
