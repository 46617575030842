import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  button: {
    float: 'right',
    marginLeft: 12,
    marginTop: theme.spacing(1.5)
  },
  exportIframe: {
    display: 'none'
  },
  header: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    position: 'relative',
    zIndex: 1
  },
  link: {
    marginRight: 20
  },
  title: {
    display: 'inline-block'
  },
  wrapper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 2,
    margin: theme.spacing(2, 2, 3, 2),
    overflow: 'hidden',
    position: 'relative'
  }
}))
