import { makeStyles, createStyles } from '@material-ui/core/styles'
import { CELL_HEIGHT } from '../gridStyles'

const IMAGE_DIMENSION = '15px'

const styles = createStyles({
  actionCell: {
    lineHeight: `${CELL_HEIGHT}px`,
    cursor: 'default',
    '& div': {
      cursor: 'pointer'
    }
  }
})

export default makeStyles(theme => ({
  cell: {
    lineHeight: `${CELL_HEIGHT}px`,
    cursor: 'default'
  },
  reviewCell: {
    ...styles.actionCell,
    width: '150px'
  },
  createIncidentCell: {
    ...styles.actionCell,
    width: '170px'
  },
  pendingIncidentCell: {
    ...styles.actionCell,
    width: '170px',
    color: 'gray'
  },
  userReportedActionsCell: {
    ...styles.actionCell,
    display: 'flex',
    justifyContent: 'space-around',
    width: '85%'
  },
  workflowActionsCell: {
    ...styles.actionCell,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  image: {
    width: IMAGE_DIMENSION,
    height: IMAGE_DIMENSION,
    marginRight: theme.spacing(1),
    verticalAlign: 'middle'
  },
  flag: {
    height: '15px',
    marginRight: theme.spacing(1),
    verticalAlign: 'middle',
    width: '15px'
  },
  icon: {
    color: 'gray',
    verticalAlign: 'middle',
    marginRight: theme.spacing(1)
  },
  text: {
    display: 'inline-block'
    // lineheight.cell does not exist on bds
    // lineHeight: theme.font.lineheight.cell
  },
  ellipsisText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  tooltip: {
    color: `${theme.palette.text.primary} !important`,
    backgroundColor: 'white !important',
    fontSize: 12,
    letterSpacing: 0,
    lineHeight: '16px',
    boxShadow: '0 2px 5px 0 rgba(0,0,0,0.26), 0 2px 10px 0 rgba(0,0,0,0.16)'
  },
  tooltipContent: {
    padding: theme.spacing(1)
  },
  tooltipArrow: {
    color: 'white !important',

    '&:before': {
      backgroundColor: 'transparent'
    }
  }
}))
