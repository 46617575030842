import { CompanyDomain } from 'global/types/api/dmarc'

export interface DmarcEnforcementWizardDialogProps {
  onClose: (isDomainModified: boolean) => void
  domain: CompanyDomain
  goal: EnforcementGoal.enforcement | EnforcementGoal.reporting
}

export enum EnforcementSteps {
  init = 'init',
  dmarcCheck = 'dmarc_check',
  result = 'result'
}

export enum EnforcementGoal {
  reporting = 'reporting',
  enforcement = 'enforcement',
  unknown = 'unknown'
}

export interface DialogConfig {
  step: EnforcementSteps
  goal: EnforcementGoal.enforcement | EnforcementGoal.reporting
  dmarcResult: EnforcementGoal
  domainName: CompanyDomain['name']
  inProgress: boolean
  dmarcRecord: string
}

export interface DialogEvents {
  onCloseDialog: () => void
  onSetStepToInit: () => void
  onCheckDmarc: () => void
}

export interface StepProps {
  config: DialogConfig
  events: DialogEvents
}
