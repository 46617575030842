import { useMemo, useState, useCallback, useEffect } from 'react'

import validator from 'validator'
import { isEqual } from 'lodash'

import useRadioButtons, { BaseRadioButtonsConfig } from 'global/lib/useRadioButtons/useRadioButtons'
import useDialogLogic, { ToggleDialog, IsDialogOpened } from 'global/lib/dialogs/useDialogLogic'
import { useEffectOnInit, useEffectOnlyOnce } from 'global/lib/useCustomEffect'
import useProductLib from 'global/lib/product/useProduct'
import { updateCurrentAccessToken } from 'global/redux/features/accessToken/accessTokenSlice'
import { AccessToken } from 'global/types/api/accessTokenType'
import usePrevious from 'global/lib/usePrevious'
import { isPending, isSuccess } from 'global/redux/toolkit/api'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import { getNotificationEmail, updateAccessToken } from 'sen/redux/features/settings/settingsSlice'
import { AdminModeProps } from 'sen/components/pages/settings/shared/AdminMode'
import { UpdateTabSettings } from 'sen/components/pages/settings/useSettingsLogic'

const ACTIVE_SETTING_STATE = '1'
const INACTIVE_SETTING_STATE = '0'
const QUARANTINE_VALUES = [1, 2, 0]
const ENDUSER_VALUES = [1, 0]
const ADMIN_VALUES = [1, 0]

export type OnSetAdminEmail = (e: any) => void

export interface TabConfig {
  isTabInProgress: boolean
  errorMsg: string | undefined
}

export interface QuarantineModeSectionConfig {
  QUARANTINE_VALUES: number[]
  isDisabled: boolean
  radioButtonsConfig: BaseRadioButtonsConfig
}
export interface EnduserModeSelectionConfig {
  ENDUSER_VALUES: number[]
  isDisabled: boolean
  radioButtonsConfig: BaseRadioButtonsConfig
  onToggleCustomizationDialog: ToggleDialog
  isCustomizeAlertDialogOpened: IsDialogOpened
}
export type AdminModeSelectionConfig = Omit<AdminModeProps, 'isTabInProgress' | 'BASE_I18N_KEY' | 'withTitle'>

export default function useSpearPhishingTabLogic(
  updateTabSettings: UpdateTabSettings
): [TabConfig, QuarantineModeSectionConfig, EnduserModeSelectionConfig, AdminModeSelectionConfig] {
  const dispatch = useAppDispatch()
  const [productLib] = useProductLib()

  const {
    isUpdateAccessToken,
    updatedAccessTokenSettings,
    isUpdateAccessTokenSuccess,
    notificationEmail,
    isGetNotificationEmail,
    isGetNotificationEmailSuccess,
    isSetNotificationEmail,
    settingsError,
    accessToken,
    spAttackQuarantine,
    spAttackAlertEndUser,
    spAttackAlertAdmin
  } = useAppSelector(_stores => ({
    isUpdateAccessToken: isPending(_stores.settings.updateAccessTokenApiStatus),
    isUpdateAccessTokenSuccess: isSuccess(_stores.settings.updateAccessTokenApiStatus),
    updatedAccessTokenSettings: _stores.settings.accessTokenSettings,
    notificationEmail: _stores.accessToken.accessToken?.settings?.notifyAddress || _stores.settings.notificationEmail,
    isGetNotificationEmail: isPending(_stores.settings.getNotificationEmailApiStatus),
    isGetNotificationEmailSuccess: isSuccess(_stores.settings.getNotificationEmailApiStatus),
    isSetNotificationEmail: isPending(_stores.settings.setNotificationEmailApiStatus),
    settingsError: _stores.settings.errorMsg,
    accessToken: _stores.accessToken.accessToken,
    spAttackQuarantine: _stores.accessToken.accessToken?.settings?.spAttackQuarantine || ACTIVE_SETTING_STATE,
    spAttackAlertEndUser: _stores.accessToken.accessToken?.settings.spAttackAlertEndUser || ACTIVE_SETTING_STATE,
    spAttackAlertAdmin: _stores.accessToken.accessToken?.settings.spAttackAlertAdmin || ACTIVE_SETTING_STATE
  }))
  const [selectedQuarantineMode, setSelectedQuarantineMode] = useRadioButtons(String(spAttackQuarantine))
  const [selectedEnduserMode, setSelectedEnduserMode] = useRadioButtons(spAttackAlertEndUser)
  const [selectedAdminMode, setSelectedAdminMode] = useRadioButtons(spAttackAlertAdmin)
  const [isCustomizeAlertDialogOpened, toggleCustomizeAlertDialog] = useDialogLogic()
  const [adminEmail, setAdminEmail] = useState<string>('')
  const prevSelectedAdminMode = usePrevious(selectedAdminMode)

  // init
  useEffectOnInit(() => {
    if (!isGetNotificationEmail && !isGetNotificationEmailSuccess) {
      dispatch(getNotificationEmail())
    }
  }, [])

  // set the admin email state variable when the notificationEmail is loaded
  useEffectOnlyOnce(
    () => {
      setAdminEmail(notificationEmail as string)
    },
    [notificationEmail],
    isGetNotificationEmailSuccess
  )

  // successfully updated accessToken's settings
  useEffect(() => {
    if (isUpdateAccessTokenSuccess && !isEqual(accessToken?.settings, updatedAccessTokenSettings)) {
      dispatch(
        updateCurrentAccessToken({
          ...accessToken,
          settings: updatedAccessTokenSettings
        } as AccessToken)
      )
    }
  }, [isUpdateAccessTokenSuccess, accessToken, dispatch, updatedAccessTokenSettings])

  // fill the admin email input if no value is set and the admin mode is changed
  useEffect(() => {
    if (prevSelectedAdminMode !== selectedAdminMode && !adminEmail.length && notificationEmail) {
      setAdminEmail(notificationEmail as string)
    }
  }, [selectedAdminMode, prevSelectedAdminMode, adminEmail, notificationEmail])

  // focus on the email field
  useEffect(() => {
    if (selectedAdminMode === ACTIVE_SETTING_STATE) {
      // eslint-disable-next-line no-unused-expressions
      document.getElementById('admin-email')?.focus()
    }
  }, [selectedAdminMode])

  const isDirtyForm: boolean = useMemo(() => {
    return (
      selectedQuarantineMode !== String(spAttackQuarantine) ||
      selectedEnduserMode !== spAttackAlertEndUser ||
      selectedAdminMode !== spAttackAlertAdmin ||
      adminEmail !== notificationEmail
    )
  }, [
    selectedQuarantineMode,
    selectedEnduserMode,
    selectedAdminMode,
    adminEmail,
    notificationEmail,
    spAttackQuarantine,
    spAttackAlertEndUser,
    spAttackAlertAdmin
  ])

  const hasLimitedAccess: boolean = useMemo(() => {
    return productLib.hasLimitedSentinelAccess(accessToken?.id)
  }, [productLib, accessToken])

  const settingsFromAccessToken = useMemo(() => {
    return {
      spAttackQuarantine: Number(hasLimitedAccess ? INACTIVE_SETTING_STATE : selectedQuarantineMode),
      spAttackAlertEndUser: selectedEnduserMode,
      spAttackAlertAdmin: selectedAdminMode,
      spAttackAlertImpersonatedUser: accessToken?.settings.spAttackAlertImpersonatedUser || ACTIVE_SETTING_STATE,
      notifyAddress: adminEmail
    }
  }, [hasLimitedAccess, selectedQuarantineMode, selectedEnduserMode, selectedAdminMode, accessToken, adminEmail])

  const isTabInProgress: boolean = useMemo(() => {
    return isUpdateAccessToken || isGetNotificationEmail || isSetNotificationEmail
  }, [isUpdateAccessToken, isGetNotificationEmail, isSetNotificationEmail])

  const onSetAdminEmail: OnSetAdminEmail = useCallback(e => {
    setAdminEmail(e.target?.value?.trim())
  }, [])

  const isEmailFieldDisabled = useMemo(() => isTabInProgress || Number(selectedQuarantineMode) !== 1, [
    isTabInProgress,
    selectedQuarantineMode
  ])

  const isSaveButtonDisabled: boolean = useMemo(() => {
    return isTabInProgress || !isDirtyForm || (!validator.isEmail(adminEmail) && !isEmailFieldDisabled)
  }, [isTabInProgress, isDirtyForm, adminEmail, isEmailFieldDisabled])

  // Keep this useEffect at the bottom, as it relies on values defined above.
  useEffect(() => {
    updateTabSettings(updateAccessToken({ settings: { ...settingsFromAccessToken } }), isSaveButtonDisabled)
  }, [updateTabSettings, settingsFromAccessToken, isSaveButtonDisabled])

  return useMemo(() => {
    return [
      {
        isTabInProgress,
        errorMsg: settingsError
      },
      {
        QUARANTINE_VALUES,
        isDisabled: hasLimitedAccess || isTabInProgress,
        radioButtonsConfig: {
          selectedRadioButton: selectedQuarantineMode,
          onSelectRadioButton: setSelectedQuarantineMode
        }
      },
      {
        ENDUSER_VALUES,
        isDisabled: isTabInProgress,
        radioButtonsConfig: {
          selectedRadioButton: selectedEnduserMode,
          onSelectRadioButton: setSelectedEnduserMode
        },
        onToggleCustomizationDialog: toggleCustomizeAlertDialog,
        isCustomizeAlertDialogOpened
      },
      {
        ADMIN_VALUES,
        isDisabled: isTabInProgress || isGetNotificationEmail,
        radioButtonsConfig: {
          selectedRadioButton: selectedAdminMode,
          onSelectRadioButton: setSelectedAdminMode
        },
        adminEmail,
        setAdminEmail: onSetAdminEmail,
        isValidAdminEmail: validator.isEmail(adminEmail)
      }
    ]
  }, [
    isTabInProgress,
    hasLimitedAccess,
    selectedQuarantineMode,
    setSelectedQuarantineMode,
    selectedEnduserMode,
    setSelectedEnduserMode,
    selectedAdminMode,
    setSelectedAdminMode,
    isCustomizeAlertDialogOpened,
    toggleCustomizeAlertDialog,
    isGetNotificationEmail,
    adminEmail,
    onSetAdminEmail,
    settingsError
  ])
}
