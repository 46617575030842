export interface Header {
  name: string
  value: string
}

export enum ConfidenceLevel {
  'UNDETERMINED' = 0,
  'MODERATE' = 3,
  'HIGH' = 4,
  'VERY_HIGH' = 5
}

export interface Attachment {
  contentType: string
  id?: string
  isInline: boolean
  name: string
  size: string
}

export interface Participant {
  email: string
  displayName: string
}

export interface Impersonation {
  impersonation_type?: string
}

export interface Remediation {
  date: string
  actionType: string
  status: string
  activities: string[]
}

export interface Geoip {
  ip: string
  code2: string
  code3: string
  name: string
  latitude: number
  longitude: number
  accuracyRadius: number
  isp: string
}

export interface AttackType {
  id: string
  date: string
  subject: string
  body: string
  bodyMimeType: string
  headers: Header[]
  attachments: Attachment[]
  identity: Participant
  sender: Participant
  replyTo: Participant[]
  impersonationType: string | null
  attacks: any
  feedbackPublicKey: string
  markedAsFp: boolean
  attackTaxonomy: string
  confidenceLevel: ConfidenceLevel
  attackAttributes: {
    attacked_domain_type?: string
    bad_domain?: string
    domain?: string
    domains?: string[]
    highlighting_analysis_attributes?: { [key: string]: boolean }
    highlighting_keywords?: string[]
    highlighting_suspicious_headers?: string[]
    highlighting_suspicious_attachments?: Attachment[]
    impersonation?: Impersonation
    keywords?: string[]
    reason?: string
  }
  threatId: string
  remediation: Remediation
  senderDomain: string
  senderDomainRegistrationDate: string
  senderIpAddress: string
  senderIpReputation: number
  senderIpLocation: string
  threatDetectsCount: number
  isDkimAuthenticated: boolean
  isSpfAuthenticated: boolean
  isDmarcAuthenticated: boolean
  isDmarcAlignmentAuthenticated: boolean
}
