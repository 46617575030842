import React from 'react'
import PropTypes from 'prop-types'

import { Chip, TextField, Autocomplete } from '@barracuda-internal/bds-core'

export interface TagInputProps {
  autocompleteTags: string[]
  currentTags: string[]
  handleTagChange: any
}

const TagInput: React.FC<TagInputProps> = ({ autocompleteTags, currentTags, handleTagChange }) => {
  return (
    <Autocomplete
      freeSolo
      multiple
      disableClearable
      options={autocompleteTags}
      defaultValue={currentTags}
      renderInput={params => <TextField {...params} />}
      renderTags={(value: string[], getTagProps) =>
        value.map((tagName: string, index: number) => (
          <Chip key={tagName} label={tagName} {...getTagProps({ index })} />
        ))
      }
      onChange={(event: any, value: string[]) => {
        handleTagChange(value)
      }}
    />
  )
}

TagInput.propTypes = {
  autocompleteTags: PropTypes.array.isRequired,
  currentTags: PropTypes.array.isRequired,
  handleTagChange: PropTypes.any.isRequired
}

export default TagInput
