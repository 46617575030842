import { makeStyles } from '@material-ui/core/styles'

import DEFAULTS from 'global/configs/theme/defaults'

export default makeStyles(() => {
  return {
    popper: {
      zIndex: DEFAULTS.SECONDARY_DIALOGS_ZINDEX
    }
  }
})
