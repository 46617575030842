import React, { useMemo } from 'react'

import { Dialog, Stepper, Step, StepLabel } from '@barracuda-internal/bds-core'

import DEFAULTS from 'global/configs/theme/defaults'

import useAddDomainWizardDialogLogic from 'sen/components/lib/dialogs/addDomainWizardDialog/useAddDomainWizardDialogLogic'
import {
  AddDomainWizardDialogLogicProps,
  WizardSteps
} from 'sen/components/lib/dialogs/addDomainWizardDialog/addDomainWizardDialogTypes'
import AddDomainStepInit from 'sen/components/lib/dialogs/addDomainWizardDialog/steps/AddDomainStepInit'
import AddDomainStepVerificationInstructions from 'sen/components/lib/dialogs/addDomainWizardDialog/steps/AddDomainStepVerificationInstructions'
import AddDomainStepVerificationFailure from 'sen/components/lib/dialogs/addDomainWizardDialog/steps/AddDomainStepVerificationFailure'
import AddDomainStepVerificationSuccess from 'sen/components/lib/dialogs/addDomainWizardDialog/steps/AddDomainStepVerificationSuccess'
import styles from 'sen/components/lib/dialogs/addDomainWizardDialog/addDomainWizardDialogStyles'

export const AddDomainWizardDialog: React.FC<AddDomainWizardDialogLogicProps> = ({ onClose, domain }) => {
  const [logicConfig, formEvents] = useAddDomainWizardDialogLogic({ onClose, domain })
  const classes = styles()

  return useMemo(
    () => (
      <Dialog
        style={{ zIndex: DEFAULTS.DIALOGS_ZINDEX }}
        maxWidth="md"
        fullWidth
        open
        data-dialog-type="add-domain-wizard"
      >
        <Stepper className={classes.stepper} activeStep={logicConfig.stepperStep} alternativeLabel>
          {[...Array(3).keys()].map(label => (
            <Step key={label}>
              <StepLabel />
            </Step>
          ))}
        </Stepper>

        {logicConfig.wizardStep === WizardSteps.init && <AddDomainStepInit config={logicConfig} events={formEvents} />}
        {logicConfig.wizardStep === WizardSteps.verificationInstructions && (
          <AddDomainStepVerificationInstructions config={logicConfig} events={formEvents} />
        )}
        {logicConfig.wizardStep === WizardSteps.verificationFailure && (
          <AddDomainStepVerificationFailure config={logicConfig} events={formEvents} />
        )}
        {logicConfig.wizardStep === WizardSteps.verificationSuccess && (
          <AddDomainStepVerificationSuccess config={logicConfig} events={formEvents} />
        )}
      </Dialog>
    ),
    [logicConfig, formEvents, classes]
  )
}

export default AddDomainWizardDialog
