import { useCallback, useEffect, useMemo, useState } from 'react'

import { CompanyDomain } from 'global/types/api/dmarc'
import { useErrorFormatMessage, useFormatMessage } from 'global/lib/localization'
import { getErrorMessage, isFailed, isPending, isSuccess } from 'global/redux/toolkit/api'
import mixpanelEvents from 'global/lib/analytics/mixpanel_events'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { dmarcIsProtectedThroughBarracuda, dmarcIsReportingThroughBarracuda } from 'global/lib/domain/dmarc'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import {
  getDmarcDkimPassingHosts,
  resetDmarcDkimPassingHosts,
  resetResolveSpf,
  resolveSpf
} from 'sen/redux/features/dmarc/dmarcSlice'
import { EnforcementGoal } from 'sen/components/lib/dialogs/dmarcEnforcementWizard/dmarcEnforcementWizardDialogTypes'
import {
  ActionButton,
  AllowedDmarcGoals,
  Block,
  ListRecord,
  Logic
} from 'sen/components/pages/domainFraudDmarcReview/content/sections/DmarcReviewYourDnsConfiguration/dmarcReviewYourDnsConfigurationTypes'

export const useDmarcReviewYourDnsConfigurationLogic = (companyDomain: CompanyDomain, baseI18nKey: string): Logic => {
  const formatMessage = useFormatMessage(baseI18nKey)
  const errorFormatMessage = useErrorFormatMessage()
  const [isEnforcementDialogOpen, setIsEnforcementDialogOpen] = useState(false)
  const [isFetchDone, setIsFetchDone] = useState(false)
  const [alertMessage, setAlertMessage] = useState<string>()

  const dispatch = useAppDispatch()
  const {
    spfApiStatus,
    isPendingSpfApiStatus,
    isFailedSpfApiStatus,
    isSuccessSpfApiStatus,
    resolvedSpf,
    dkimApiStatus,
    isPendingDkimApiStatus,
    isFailedDkimApiStatus,
    isSuccessDkimApiStatus,
    dmarcDkimPassingHosts
  } = useAppSelector(_stores => ({
    spfApiStatus: _stores.dmarc.resolveSpfApiStatus,
    isPendingSpfApiStatus: isPending(_stores.dmarc.resolveSpfApiStatus),
    isFailedSpfApiStatus: isFailed(_stores.dmarc.resolveSpfApiStatus),
    isSuccessSpfApiStatus: isSuccess(_stores.dmarc.resolveSpfApiStatus),
    resolvedSpf: _stores.dmarc.resolvedSpf,
    dkimApiStatus: _stores.dmarc.getDmarcDkimPassingHostsApiStatus,
    isPendingDkimApiStatus: isPending(_stores.dmarc.getDmarcDkimPassingHostsApiStatus),
    isFailedDkimApiStatus: isFailed(_stores.dmarc.getDmarcDkimPassingHostsApiStatus),
    isSuccessDkimApiStatus: isSuccess(_stores.dmarc.getDmarcDkimPassingHostsApiStatus),
    dmarcDkimPassingHosts: _stores.dmarc.dmarcDkimPassingHosts
  }))

  const fetchDomainInfo = useCallback(() => {
    dispatch(getDmarcDkimPassingHosts({ domain: companyDomain.name, query: { page: 1 } }))
    dispatch(resolveSpf(companyDomain.name))
  }, [dispatch, companyDomain.name])

  const resetComponent = useCallback(() => {
    dispatch(resetResolveSpf())
    dispatch(resetDmarcDkimPassingHosts())
  }, [dispatch])

  const initializeComponentEffect = useCallback(() => {
    if (!companyDomain.name) {
      setAlertMessage(formatMessage('domain_name_is_required'))
      setIsFetchDone(true)
      return resetComponent
    }
    fetchDomainInfo()
    return resetComponent
  }, [companyDomain.name, fetchDomainInfo, formatMessage, resetComponent])

  const getFailedApiStatus = useCallback(() => {
    switch (true) {
      case isFailedSpfApiStatus:
        return spfApiStatus
      case isFailedDkimApiStatus:
        return dkimApiStatus
      default:
        return undefined
    }
  }, [dkimApiStatus, isFailedDkimApiStatus, isFailedSpfApiStatus, spfApiStatus])

  const setIsFetchDoneEffect = useCallback(() => {
    if (isFetchDone) {
      return
    }
    const failedApiStatus = getFailedApiStatus()
    if (failedApiStatus) {
      setIsFetchDone(true)
      setAlertMessage(errorFormatMessage(getErrorMessage(failedApiStatus) || ''))
      return
    }
    const hasSpfFetchResult = isSuccessSpfApiStatus || isFailedSpfApiStatus
    const hasDkimFetchResult = isSuccessDkimApiStatus || isFailedDkimApiStatus
    const hasAllFetchResult = hasSpfFetchResult && hasDkimFetchResult
    if (hasAllFetchResult) {
      setIsFetchDone(true)
    }
  }, [
    errorFormatMessage,
    getFailedApiStatus,
    isFailedDkimApiStatus,
    isFailedSpfApiStatus,
    isFetchDone,
    isSuccessDkimApiStatus,
    isSuccessSpfApiStatus
  ])

  useEffect(() => initializeComponentEffect(), [initializeComponentEffect])

  useEffect(() => setIsFetchDoneEffect(), [setIsFetchDoneEffect])

  const goalConfig = useMemo((): [AllowedDmarcGoals, true] | [EnforcementGoal.unknown, false] => {
    const { dmarc } = companyDomain
    if (dmarcIsProtectedThroughBarracuda(dmarc)) {
      return [EnforcementGoal.reporting, true]
    }
    if (dmarcIsReportingThroughBarracuda(dmarc)) {
      return [EnforcementGoal.enforcement, true]
    }
    return [EnforcementGoal.unknown, false]
  }, [companyDomain])

  const dmarcBlock = useMemo(
    (): Block<string> => ({
      title: formatMessage('blocks.dmarc.title'),
      record: companyDomain.dmarc.entry
    }),
    [companyDomain.dmarc.entry, formatMessage]
  )

  const spfBlock = useMemo((): Block<string> => {
    const title = formatMessage('blocks.spf.title')
    switch (true) {
      case isPendingSpfApiStatus:
        return { title, record: formatMessage('blocks.api_fetch_in_progress') }
      case isFailedSpfApiStatus || !resolvedSpf:
        return { title, record: formatMessage('blocks.api_fetch_error') }
      case !resolvedSpf?.isSpfRecord:
        return { title, record: formatMessage('blocks.no_record') }
      default:
        return { title: formatMessage('blocks.spf.title'), record: resolvedSpf?.entry as string }
    }
  }, [formatMessage, isFailedSpfApiStatus, isPendingSpfApiStatus, resolvedSpf])

  const dkimBlock = useMemo((): Block<ListRecord> => {
    const title = formatMessage('blocks.dkim.title')
    switch (true) {
      case isPendingDkimApiStatus || isFailedDkimApiStatus:
        return {
          title,
          record: {
            header: formatMessage(isPendingDkimApiStatus ? 'blocks.api_fetch_in_progress' : 'blocks.api_fetch_error'),
            list: []
          }
        }
      default: {
        const list = (dmarcDkimPassingHosts?.results || []).map(item => item.host)
        // Removing duplicates so list items can be used as react keys
        const dedupedList = Array.from(new Set(list))
        const header =
          dedupedList.length === 0 ? formatMessage('blocks.dkim.not_configured') : formatMessage('blocks.dkim.header')
        return {
          title,
          record: {
            header,
            list: dedupedList
          }
        }
      }
    }
  }, [dmarcDkimPassingHosts, formatMessage, isFailedDkimApiStatus, isPendingDkimApiStatus])

  const actionButton = useMemo((): ActionButton | undefined => {
    if (!goalConfig[1]) {
      return undefined
    }
    const [goal] = goalConfig
    const buttonLabelId = goal === EnforcementGoal.enforcement ? 'enforce_dmarc_button' : 'change_to_reporting_button'
    const mixpanelEvent =
      goal === EnforcementGoal.enforcement
        ? mixpanelEvents.DF_DMARC_REVIEW_DNS_CONFIG_ENFORCE_DMARC
        : mixpanelEvents.DF_DMARC_REVIEW_DNS_CONFIG_CHANGE_TO_REPORTING_ONLY

    const onClickEnforceDmarcButton = () => {
      analyticsLib.trackAppEvent(mixpanelEvent, {
        domain: companyDomain.name
      })
      setIsEnforcementDialogOpen(true)
    }

    return {
      onClick: onClickEnforceDmarcButton,
      label: formatMessage(buttonLabelId)
    }
  }, [companyDomain.name, formatMessage, goalConfig])

  const onCloseEnforcementDialog = useCallback(() => {
    setIsEnforcementDialogOpen(false)
  }, [])

  return useMemo(() => {
    return [
      {
        isFetchDone,
        alertMessage
      },
      dmarcBlock,
      spfBlock,
      dkimBlock,
      actionButton,
      {
        domain: companyDomain,
        goalConfig,
        isOpen: isEnforcementDialogOpen,
        onClose: onCloseEnforcementDialog
      }
    ]
  }, [
    isFetchDone,
    alertMessage,
    dmarcBlock,
    spfBlock,
    dkimBlock,
    actionButton,
    companyDomain,
    goalConfig,
    isEnforcementDialogOpen,
    onCloseEnforcementDialog
  ])
}
