import React from 'react'

import { TooltipPoint } from '@progress/kendo-react-charts'

import styles from './multiSeriesTooltipStyles'

export function MultiSeriesTooltip(props: any): React.ReactElement {
  const classes = styles()
  const { points, categoryText } = props

  return (
    <div>
      <div className={classes.chartTooltipTitle}>{categoryText}</div>
      {points.map((point: TooltipPoint) => (
        <div key={point.series.name} className={classes.chartTooltipWrapper}>
          <div>
            <div className={classes.chartTooltipValueCell}>
              <span className={classes.bold}>{point.value}</span>
            </div>
            <div className={classes.chartTooltipValueDetails}>
              <div className={`${classes.chartTooltipIcon}`} style={{ background: `${point.series.color}` }} />
              <div className={classes.chartTooltipValueDetailsText}>{point.series.name}</div>
            </div>
          </div>
        </div>
      ))}
      <div className={`${classes.bold} ${classes.chartTooltipValueCell}`}>
        Total: {points.reduce((prev: any, curr: any) => prev + curr.value, 0)}{' '}
      </div>
    </div>
  )
}
