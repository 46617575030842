import React from 'react'
import { Tooltip } from '@barracuda-internal/bds-core'

interface ChartIconWrapperProps {
  backgroundColor?: string
  children: React.ReactNode
  classes: {
    showChartIconWrapper: string
    buttons: string
    showChartIcon: string
  }
  tooltipTitle: string
}

const ChartIconWrapper: React.FC<ChartIconWrapperProps> = ({ backgroundColor, children, classes, tooltipTitle }) => (
  <Tooltip title={tooltipTitle} placement="bottom">
    <div className={`${classes.showChartIconWrapper} ${classes.buttons}`} style={{ backgroundColor }}>
      {children}
    </div>
  </Tooltip>
)

export default ChartIconWrapper
