import React, { useMemo } from 'react'

import Dialog from '@material-ui/core/Dialog'
import Zoom from '@material-ui/core/Zoom'
import DEFAULTS from 'global/configs/theme/defaults'
import { EmailDetailsDialogProps } from 'global/components/lib/dialogs/emailDetailsDialog/emailDetailDialogTypes'
import EmailDetails from 'global/components/lib/dialogs/emailDetailsDialog/components/emailDetails/EmailDetails'

export interface EmailPreviewDialogProps {
  buttonText?: string
  data: EmailDetailsDialogProps
  hideReplyTo?: boolean
  isExpandRow?: boolean
  isGroupedRecipients?: boolean
  showThreatTab?: boolean
  titleText?: string
}
export const EmailPreviewDialog: React.FC<EmailPreviewDialogProps> = props => {
  return useMemo(
    () => (
      <Dialog
        style={{ zIndex: DEFAULTS.DIALOGS_ZINDEX }}
        maxWidth="lg"
        fullWidth
        open
        TransitionComponent={Zoom}
        data-testid="email-details-dialog"
      >
        <EmailDetails {...props} />
      </Dialog>
    ),
    [props]
  )
}

export default EmailPreviewDialog
