import { createSlice } from '@reduxjs/toolkit'
import { getContestScanStatus } from 'global/redux/features/contest/contestApiThunks'
import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'global/redux/toolkit/api'

export interface ContestState {
  contestApiStatus: ApiStatus
}

// initialState
export const INITIAL_STATE: ContestState = {
  contestApiStatus: inIdle
}

/* eslint-disable no-param-reassign */
export const contestSlice = createSlice({
  name: 'CONTEST',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getContestScanStatus.pending, state => {
        state.contestApiStatus = inProgress
      })
      .addCase(getContestScanStatus.fulfilled, (state, action) => {
        state.contestApiStatus = successResponse
      })
      .addCase(getContestScanStatus.rejected, (state, action) => {
        state.contestApiStatus = failedResponse(action.payload as string)
      })
  }
})
/* eslint-enable no-param-reassign */

export { getContestScanStatus }

export default contestSlice.reducer
