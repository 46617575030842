import React from 'react'
import PropTypes from 'prop-types'

import { Tooltip } from '@barracuda-internal/bds-core'
import { makeStyles } from '@material-ui/core/styles'

import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'
import tableStyles from 'global/components/lib/grid/tableStyles'

export interface Props {
  name?: string
  email?: string
  CELL_HEIGHT?: number
}

const EmailCell: React.FC<Props> = ({ name, email, CELL_HEIGHT }) => {
  const styles = makeStyles(theme => {
    return tableStyles(theme, CELL_HEIGHT)
  })
  const classes = styles()

  return (
    <Cell className={classes.cell} data-field="email-cell">
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        title={
          <div>
            <b>{name}</b>
            <br />
            {email}
          </div>
        }
        placement="top-start"
        data-field="email-cell-tooltip"
      >
        <span>
          <CellText ellipsis disableTitle className={classes.boldCell} data-field="email-cell-name">
            {name}
          </CellText>
          {email && (
            <>
              {name && <br />}
              <CellText
                ellipsis
                disableTitle
                className={classes[name ? 'lightCell' : 'boldCell']}
                data-field="email-cell-email"
              >
                {email}
              </CellText>
            </>
          )}
        </span>
      </Tooltip>
    </Cell>
  )
}

EmailCell.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  CELL_HEIGHT: PropTypes.number
}

EmailCell.defaultProps = {
  name: undefined,
  email: undefined,
  CELL_HEIGHT: undefined
}

export default EmailCell
