import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  tooltip: {
    color: `${theme.palette.text.primary} !important`,
    backgroundColor: 'white !important',
    fontSize: 12,
    letterSpacing: 0,
    lineHeight: '16px',
    boxShadow: '0 2px 5px 0 rgba(0,0,0,0.26), 0 2px 10px 0 rgba(0,0,0,0.16)'
  },
  tooltipContent: {
    padding: theme.spacing(1)
  },
  tooltipArrow: {
    color: 'white !important',

    '&:before': {
      backgroundColor: 'transparent'
    }
  }
}))
