import React from 'react'
import PropTypes from 'prop-types'

import styles from './cellStyles'

export interface Props {
  children?: any
  className?: string
  id?: string
  title?: string
  onClick?: () => void
}

const Cell: React.FC<Props> = ({ children, className, id, title, onClick, ...rest }) => {
  const classes = styles()

  return (
    <td
      {...rest}
      id={id}
      title={title}
      role="presentation"
      onClick={onClick}
      className={`${classes.cell} ${className}`}
    >
      {children}
    </td>
  )
}

Cell.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func
}

Cell.defaultProps = {
  children: null,
  className: undefined,
  id: undefined,
  title: undefined,
  onClick: undefined
}

export default Cell
