import { makeStyles, createStyles } from '@material-ui/core/styles'

import { COLORS } from 'global/configs/theme/defaults'
import tableStyles, {
  CELL_HEIGHT,
  TABLE_HEADER_HEIGHT,
  TABLE_FOOTER_HEIGHT
} from 'global/components/lib/grid/tableStyles'
import dialogStyles from 'global/components/lib/dialogs/dialogStyles'

export { TABLE_HEADER_HEIGHT, TABLE_FOOTER_HEIGHT }
export const TABLE_CELL_HEIGHT = CELL_HEIGHT
const TABLE_MIN_HEIGHT = TABLE_CELL_HEIGHT * 5 + TABLE_HEADER_HEIGHT + TABLE_FOOTER_HEIGHT

export default makeStyles(theme => {
  const defaultTableStyles = tableStyles(theme)
  const defaultDialogStyles = dialogStyles(theme)

  const generalStyles = createStyles({
    licenseBox: {
      width: theme.spacing(37),
      display: 'inline-block',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      paddingRight: theme.spacing(5),
      border: `1px solid ${COLORS.SCALE_GRAY_100}`,
      boxSizing: 'border-box',
      borderRadius: theme.spacing(0.5)
    },
    countText: {
      fontWeight: 500,
      fontSize: 24,
      lineHeight: '120%',
      color: COLORS.SCALE_GRAY_900,
      marginBottom: theme.spacing(1)
    }
  })

  return {
    ...defaultDialogStyles,
    exportButton: {
      position: 'absolute',
      right: theme.spacing(2),
      marginTop: theme.spacing(-2)
    },
    searchField: {
      marginTop: theme.spacing(4)
    },
    ...defaultTableStyles,
    tableArea: {
      ...defaultTableStyles.tableArea,
      marginTop: theme.spacing(2),
      minHeight: TABLE_MIN_HEIGHT
    },
    dataTable: {
      ...defaultTableStyles.dataTable,
      height: TABLE_MIN_HEIGHT
    },
    icon: {
      verticalAlign: -5
    },
    licenseBox: {
      ...generalStyles.licenseBox
    },
    errorLicenseBox: {
      ...generalStyles.licenseBox,
      borderColor: 'transparent',
      backgroundColor: COLORS.ALERT_BOX.ALERT_BACKGROUND
    },
    loadingCount: {
      width: '12px !important',
      height: '12px !important'
    },
    licenseText: {
      color: COLORS.SCALE_GRAY_900,
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '130%',
      marginBottom: theme.spacing(1)
    },
    countText: {
      ...generalStyles.countText
    },
    errorCountText: {
      ...generalStyles.countText,
      color: COLORS.ROSE_700
    },
    purchasedCountText: {
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '130%'
    },
    purchasedText: {
      color: COLORS.SCALE_GRAY_500,
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '125%'
    },
    contactLink: {
      color: 'white',
      textDecoration: 'none',
      fontWeight: 500
    },
    alert: {
      marginBottom: -theme.spacing(1),

      '& [role="alert"]': {
        backgroundColor: COLORS.DEEP_OCEAN_500
      }
    }
  }
})
