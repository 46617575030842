import React, { useMemo } from 'react'
import Navbar, {
  NavbarSpacer,
  NavbarControlWrapper,
  NavbarContextMenu,
  NavbarContextMenuLogout,
  NavbarLogoText
} from 'global/components/lib/layout/navbar'
import useNavbarLogic from 'admin/components/lib/layout/useNavbarLogic'
import { useFormatMessage } from 'global/lib/localization'

const BASE_I18N_KEY = 'admin.app'

const AdminNavbar: React.FC = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [navbarLogic] = useNavbarLogic()

  return useMemo(() => {
    const { isNavbarVisible, onLogout } = navbarLogic

    return (
      <Navbar isNavbarVisible={isNavbarVisible}>
        <NavbarLogoText logoText={formatMessage('title')} />
        <NavbarSpacer />
        <NavbarSpacer />
        <NavbarControlWrapper>
          <NavbarContextMenu>
            <NavbarContextMenuLogout onLogout={onLogout} />
          </NavbarContextMenu>
        </NavbarControlWrapper>
      </Navbar>
    )
  }, [navbarLogic, formatMessage])
}

export default AdminNavbar
