import { useEffect, useMemo, useState } from 'react'

import { useAppSelector } from 'global/redux/toolkit/hooks'
import { isFailed, isSuccess } from 'global/redux/toolkit/api'
import { useFormatMessage } from 'global/lib/localization'
import { EmptyReportProps } from 'global/components/features/emptyReport/EmptyReport'

const BASE_I18N_KEY = 'app.error'

export default function useEmptyReportLogic(handler: EmptyReportProps['handler']) {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [errorMessage, setErrorMessage] = useState('')
  const { accessTokenId, isCompletedGetUserApiStatus } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id,
    isCompletedGetUserApiStatus: isFailed(_stores.auth.getUserApiStatus) || isSuccess(_stores.auth.getUserApiStatus)
  }))

  useEffect(() => {
    if (!isCompletedGetUserApiStatus) {
      return undefined
    }
    if (!accessTokenId) {
      setErrorMessage(formatMessage('no_accesstokens'))
      return undefined
    }
    return handler(accessTokenId)
  }, [accessTokenId, formatMessage, handler, isCompletedGetUserApiStatus])

  return useMemo(() => [errorMessage], [errorMessage])
}
