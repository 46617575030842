import { makeStyles } from '@material-ui/core/styles'

import dialogStyles from 'global/components/lib/dialogs/dialogStyles'

export default makeStyles(theme => {
  const defaultDialogStyles = dialogStyles(theme)

  return {
    ...defaultDialogStyles,
    textAreaLabel: {
      fontSize: 12,
      lineHeight: `${theme.spacing(5)}px`,
      color: theme.palette.text.secondary
    },
    indicator: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0
    },
    textField: {
      width: '100%',
      fontSize: 14
    },
    textArea: {
      fontFamily: '"Roboto", sans-serif',
      width: '100%',
      resize: 'none',
      padding: theme.spacing(1),
      fontSize: 14,

      // force the border color until we standardize the form elements in BDS
      borderColor: '#b7b7b7',

      '&:hover': {
        borderColor: '#6d6d6d'
      },

      '&:focus': {
        outline: 'none !important',
        border: '1px solid #81b5e0'
      }
    },
    formResetButton: {
      float: 'right'
    },
    formContainer: {
      marginBottom: theme.spacing(2)
    },
    renderContainer: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1)
    },
    emailHeaderRow: {
      marginTop: theme.spacing(1)
    },
    contentLabel: {
      color: theme.palette.text.secondary
    },
    iframe: {
      marginTop: theme.spacing(1),
      border: '1px solid #b7b7b7',
      width: '100%',
      height: 200
    }
  }
})
