import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { RadioGroup, DialogContentText, Grid } from '@barracuda-internal/bds-core'
import { Settings as SettingsIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'

import { config } from 'global/lib/config'
import RadioButtonPanel from 'global/components/lib/radioButtonPanel/RadioButtonPanel'
import RadioButtonPanelSeparator from 'global/components/lib/radioButtonPanel/RadioButtonPanelSeparator'
import { useFormatMessage } from 'global/lib/localization'

import styles from 'global/components/lib/modeHandlerSelector/modeHandlerSelectorStyles'

const BASE_I18N_KEY = 'app.mode_handler_selector'

export interface ModeHandlerSelectorProps {
  appName: string
  onUpdateCMSettings: (e: any) => void
  selectedCMSetting: string
}

export const ModeHandlerSelector: React.FC<ModeHandlerSelectorProps> = ({
  appName,
  onUpdateCMSettings,
  selectedCMSetting
}) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  return useMemo(
    () => (
      <Grid container>
        <RadioGroup
          row
          className={classes.radioButtonGroup}
          name="ets-sentinel-conversion"
          value={selectedCMSetting}
          onChange={(e: any) => onUpdateCMSettings(e.target.value)}
        >
          <RadioButtonPanel
            value={config.CM_SETTINGS.ENFORCE_MODE.name}
            isActive={selectedCMSetting === config.CM_SETTINGS.ENFORCE_MODE.name}
            onClick={onUpdateCMSettings}
            title={formatMessage('enforcement_mode')}
            subtitle={formatMessage('enforcement_mode_description')}
          />
          <RadioButtonPanelSeparator />
          <RadioButtonPanel
            value={config.CM_SETTINGS.REPORTING_MODE.name}
            isActive={selectedCMSetting === config.CM_SETTINGS.REPORTING_MODE.name}
            onClick={onUpdateCMSettings}
            title={formatMessage('reporting_mode')}
            subtitle={formatMessage('reporting_mode_description')}
          />
        </RadioGroup>
        <Grid container alignItems="center" justifyContent="center">
          <div className={classes.separator} />
        </Grid>
        <Grid container alignItems="center">
          <DialogContentText className={classes.footerText} data-field="content-text-footer">
            {formatMessage('footer', { icon: <SettingsIcon key="icon" className={classes.settingsIcon} />, appName })}
          </DialogContentText>
        </Grid>
      </Grid>
    ),
    [onUpdateCMSettings, selectedCMSetting, classes, formatMessage, appName]
  )
}

ModeHandlerSelector.propTypes = {
  onUpdateCMSettings: PropTypes.func.isRequired,
  selectedCMSetting: PropTypes.string.isRequired
}

export default ModeHandlerSelector
