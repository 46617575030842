import React, { useMemo } from 'react'

import { Alert } from '@barracuda-internal/bds-core'

import Loading from 'global/components/lib/loading/Loading'
import { useErrorFormatMessage } from 'global/lib/localization'

import Layout from 'sen/components/lib/layout/Layout'
import ScanInProgress from 'sen/components/pages/dashboard/scanInProgress/ScanInProgress'
import useSentinelContentCondition, {
  UseSentinelContentConditionParams,
  UIState
} from 'sen/components/lib/useSentinelContentCondition/useSentinelContentCondition'
import MissingRequirement from 'sen/components/pages/missingRequirement/MissingRequirement'

export type UseSentinelContentConditionProps = UseSentinelContentConditionParams

export interface SentintelContentLoader {
  default: (props: UseSentinelContentConditionProps, userEmail: string) => any
  loading?: (props: UseSentinelContentConditionProps, userEmail: string) => any
  missingBccEntitlement?: (props: UseSentinelContentConditionProps, userEmail: string) => any
  scanInProgress?: (props: UseSentinelContentConditionProps, userEmail: string) => any
  failedToLoadScan?: (props: UseSentinelContentConditionProps, userEmail: string) => any
}

export default function sentintelContentLoader(loaderConfig: SentintelContentLoader) {
  const SentintelContentLoader: React.FC<UseSentinelContentConditionProps> = props => {
    const formatErrorMessage = useErrorFormatMessage()
    const [uiState, userEmail, apiError] = useSentinelContentCondition()

    return useMemo(() => {
      switch (uiState) {
        case UIState.failedToLoadScan:
          return (
            loaderConfig.failedToLoadScan?.(props, userEmail) || (
              <Layout>
                <Alert variant="filled" severity="error" data-field="sentinelcontentloader-alert">
                  {formatErrorMessage(apiError as string)}
                </Alert>
              </Layout>
            )
          )
        case UIState.loading:
          return (
            loaderConfig.loading?.(props, userEmail) || (
              <Layout>
                <Loading data-field="loading" />
              </Layout>
            )
          )
        case UIState.missingBccEntitlement:
          return loaderConfig.missingBccEntitlement?.(props, userEmail) || <MissingRequirement product="sen" />
        case UIState.scanInProgress:
          return loaderConfig.scanInProgress?.(props, userEmail) || <ScanInProgress userEmail={userEmail} />
        default:
          return loaderConfig.default(props, userEmail)
      }
    }, [uiState, userEmail, apiError, formatErrorMessage, props])
  }

  return SentintelContentLoader
}
