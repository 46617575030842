import React, { useCallback, useEffect, useMemo, useReducer } from 'react'
import { useParams } from 'react-router-dom'

import {
  TASK_STATUS_COMPLETE,
  UrlParams
} from 'fir/components/pages/remediation/incidentDetails/useIncidentDetailsLogic'
import apiRoutes from 'fir/lib/api/apiRoutes'
import {
  forensicsGetIncidentUserDetails,
  forensicsGetMoreIncidentUserDetails,
  forensicsGetAllIncidentUserDetails,
  forensicsResetIncidentUserDetails
} from 'fir/redux/features/remediation/remediationSlice'
import * as userDetailsByIncidentTableActions from 'fir/redux/features/dataTables/userDetailsByIncident/userDetailsByIncidentSlice'
import {
  createSecurityTrainingAddressBook,
  resetCreateAddressBook
} from 'fir/redux/features/securityTraining/securityTrainingSlice'
import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'
import { INCIDENT_USER_STATUS } from 'fir/redux/types/Remediation'
import { GetSecurityTrainingInstancesApiResponse } from 'fir/redux/types/SecurityTraining'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { getComparator, stableSort } from 'global/lib/comparator'
import useFeatureLib from 'global/lib/feature/useFeature'
import { luxonDate } from 'global/lib/datetime'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'
import { useFormatMessage } from 'global/lib/localization'
import { getErrorMessage, isPending, isSuccess } from 'global/redux/toolkit/api'
import { Order } from 'global/types/dataTables/dataTables'

export interface TrainingGroupDialogConfigProps {
  addressBookName: string
  createAddressBookErrorMessage: string
  formatMessage: (message: string, data?: { count: number }) => string
  isCreateAddressBookLoading: boolean
  isOpened: boolean
  onClose: () => void
  onCreateAddressBook: () => void
  selectInstanceError: boolean
  selectedInstance: string
  selectedRecipients: string[]
  sortedInstancesName: GetSecurityTrainingInstancesApiResponse[]
  updateInstance: (e: React.ChangeEvent<{ value: any }>) => void
}

export interface UsersDetailsByIncidentProps {
  addressBookName: string
  exportPath: string
  handleChangePage: (event: any, newPage: number) => void
  handleClick: (event: React.MouseEvent<any>, name: string) => void
  handleRequestSort: (event: React.MouseEvent<any>, property: string) => void
  handleSelectAllClick: (event: React.ChangeEvent<any>) => void
  hasESS: boolean
  isClickedLinksPending: boolean | undefined
  isCreateAddressBookEnabled: boolean
  isCreateAddressBookSuccess: boolean
  isPageInProgress: boolean
  isSelected: (name: string) => boolean
  itemsChangePagination: string
  numSelected: number
  onCloseAlert: () => void
  onExportData: () => void
  openTrainingGroupDialog: () => void
  order: Order
  orderBy: string
  securityTrainingCampaignLink: string
  tableData: any
  totalPage: number
  trainingGroupDialogConfig: TrainingGroupDialogConfigProps
}

export interface DataItem {
  clickedLinks: string
  email: string
  forwarded: string
  isRead: string
  replied: string
  userType: string
}

const BASE_I18N_KEY = 'fir.app.remediation.users_by_incident.training_group_dialog'

export default function useUsersDetailsByIncidentLogic(): [UsersDetailsByIncidentProps] {
  const urlParams: UrlParams = useParams()
  const dataTime = luxonDate()
  const dispatch = useAppDispatch()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [featureLib] = useFeatureLib()
  const [isTrainingGroupDialogOpened, toggleTrainingGroupDialog] = useDialogLogic()

  const {
    accessTokenId,
    bccAccountId,
    createAddressBookError,
    createdAddressBook,
    dataTables,
    isCreateAddressBookLoading,
    isCreateAddressBookSuccess,
    isGetSecurityTrainingInstancesSuccess,
    isIncidentUserDetailsLoading,
    isIncidentUserDetailsSuccess,
    order,
    orderBy,
    page,
    securityTrainingInstances,
    remediation,
    rowsPerPage,
    userDetailsData,
    userDetailsTotalCount
  } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id || '',
    bccAccountId: _stores.accessToken.bccAccount || '',
    createAddressBookError: getErrorMessage(_stores.securityTraining.createAddressBookApiStatus),
    createdAddressBook: _stores.securityTraining.createdAddressBook,
    dataTables: _stores.dataTables,
    isCreateAddressBookLoading: isPending(_stores.securityTraining.createAddressBookApiStatus),
    isCreateAddressBookSuccess: isSuccess(_stores.securityTraining.createAddressBookApiStatus),
    isGetSecurityTrainingInstancesSuccess: isSuccess(_stores.securityTraining.getSecurityTrainingInstancesApiStatus),
    isIncidentUserDetailsLoading: isPending(_stores.remediation.getIncidentUserDetailsApiStatus),
    isIncidentUserDetailsSuccess: isSuccess(_stores.remediation.getIncidentUserDetailsApiStatus),
    order: _stores.dataTables.userDetailsByIncident.order,
    orderBy: _stores.dataTables.userDetailsByIncident.orderBy,
    page: _stores.dataTables.userDetailsByIncident.page,
    securityTrainingInstances: _stores.securityTraining.securityTrainingInstances,
    remediation: _stores.remediation,
    rowsPerPage: _stores.dataTables.userDetailsByIncident.rowsPerPage,
    userDetailsData: _stores.remediation.userDetailsByIncident.data,
    userDetailsTotalCount: _stores.remediation.userDetailsByIncident.totalCount
  }))

  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    exportPath: null,
    selectInstanceError: false,
    selectedInstance: '',
    selectedRecipients: []
  })

  // Init and component unmount
  useEffect(() => {
    dispatch(
      forensicsGetIncidentUserDetails({
        accessTokenId,
        config: apiParams,
        incidentId: urlParams.incidentId as string
      })
    )
    return () => {
      dispatch(forensicsResetIncidentUserDetails())
      dispatch(resetCreateAddressBook())
      dispatch(userDetailsByIncidentTableActions.reset())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Get all data for multi selection
  useEffect(() => {
    if (isIncidentUserDetailsSuccess) {
      // Walk around the limit === 1 query bug issue on the PROD recipientDetails API call
      const limit =
        remediation.userDetailsByIncident.totalCount < rowsPerPage
          ? Number(rowsPerPage)
          : remediation.userDetailsByIncident.totalCount
      dispatch(
        forensicsGetAllIncidentUserDetails({
          accessTokenId,
          config: { query: { ...apiParams.query, limit } },
          incidentId: urlParams.incidentId as string
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remediation.userDetailsByIncident.totalCount])

  // Sort hook
  useEffect(() => {
    if (isIncidentUserDetailsSuccess) {
      dispatch(
        forensicsGetMoreIncidentUserDetails({
          accessTokenId,
          config: apiParams,
          incidentId: urlParams.incidentId as string
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order])

  // Pagination hook
  useEffect(() => {
    if (isIncidentUserDetailsSuccess) {
      dispatch(
        forensicsGetMoreIncidentUserDetails({
          accessTokenId,
          config: apiParams,
          incidentId: urlParams.incidentId as string
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    if (isCreateAddressBookSuccess && isTrainingGroupDialogOpened) {
      toggleTrainingGroupDialog()
      setState({
        selectedRecipients: [],
        selectInstanceError: false
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateAddressBookSuccess, isTrainingGroupDialogOpened])

  const apiParams = useMemo(() => {
    return {
      query: {
        page: page + 1,
        limit: Number(rowsPerPage),
        order: (order === 'desc' ? '-' : '') + orderBy,
        filter: []
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTables.userDetailsByIncident])

  const addressBookName = useMemo(() => {
    const sourceOfCreation = formatMessage('sourceOfCreation')
    const formattedDateTime = dataTime.toFormat('yyyy-MM-dd-HH:mm')
    return `${sourceOfCreation}-${formattedDateTime}`
  }, [formatMessage, dataTime])

  const createAddressBookApiParams = useMemo(() => {
    const selectedDetails = state.selectedRecipients.map((email: string) => {
      return {
        address: email,
        givenName: '',
        familyName: '',
        fullName: ''
      }
    })
    return {
      instance: securityTrainingInstances.length > 1 ? state.selectedInstance : securityTrainingInstances[0]?.name,
      bccAccountId,
      name: addressBookName,
      addressBookCreationType: 'inline',
      addressBookSourceType: 'API - Incident Response',
      detailRecords: selectedDetails
    }
  }, [addressBookName, bccAccountId, securityTrainingInstances, state.selectedInstance, state.selectedRecipients])

  const onCreateAddressBook = useCallback(() => {
    // if more than one Phishline Instance, we need to make sure user select one instance from selection dropdown
    if (securityTrainingInstances.length === 1 || state.selectedInstance) {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.CREATE_SECURITY_TRAINING_ADDRESS_BOOK, {
        accessTokenId,
        addressBookName,
        incidentId: remediation.incident.id,
        instance: createAddressBookApiParams.instance,
        selectedUsers: createAddressBookApiParams.detailRecords
      })
      dispatch(createSecurityTrainingAddressBook({ config: createAddressBookApiParams, accessTokenId }))
      return
    }

    setState({ selectInstanceError: true })
  }, [
    accessTokenId,
    addressBookName,
    createAddressBookApiParams,
    dispatch,
    remediation.incident.id,
    securityTrainingInstances,
    state.selectedInstance
  ])

  const onTableConfigChange = useCallback(
    (newConfig: any) => {
      dispatch(userDetailsByIncidentTableActions.update({ config: newConfig }))
    },
    [dispatch]
  )

  const handleRequestSort = useCallback(
    (event: React.MouseEvent<any>, field: string) => {
      onTableConfigChange({
        order: order === 'asc' ? 'desc' : 'asc',
        orderBy: field
      })
    },
    [order, onTableConfigChange]
  )

  const handleChangePage = useCallback(
    (event: any, newPage: number) => {
      onTableConfigChange({ page: newPage - 1 })
    },
    [onTableConfigChange]
  )

  const hasESS = useMemo(() => {
    return featureLib.hasESSFeature(accessTokenId)
  }, [accessTokenId, featureLib])

  const isClickedLinksPending = useMemo(() => {
    const { taskStatuses, created } = remediation.incident
    const tasksComplete = (new Date().getTime() - new Date(created).getTime()) / 1000 > 24 * 3600

    return taskStatuses && taskStatuses.clickedLinks !== TASK_STATUS_COMPLETE && !tasksComplete
  }, [remediation.incident])

  const onExportData = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.exportAsCsv('Emails'), {
      accessTokenId,
      incidentId: remediation.incident.id,
      page: 'incidents'
    })
    setState({
      exportPath: apiRoutes.FORENSICS_GET_INCIDENT_CSV.path({
        accessTokenId,
        incidentId: remediation.incident.id
      })
    })
  }, [accessTokenId, remediation.incident.id])

  const handleClick = useCallback(
    (event: React.MouseEvent<any>, name: string) => {
      const selectedIndex = state.selectedRecipients.indexOf(name)
      let newSelected: string[] = []

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(state.selectedRecipients, name)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(state.selectedRecipients.slice(1))
      } else if (selectedIndex === state.selectedRecipients.length - 1) {
        newSelected = newSelected.concat(state.selectedRecipients.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          state.selectedRecipients.slice(0, selectedIndex),
          state.selectedRecipients.slice(selectedIndex + 1)
        )
      }
      setState({ selectedRecipients: newSelected })
    },
    [state.selectedRecipients]
  )

  const isSelected = useCallback((name: string) => state.selectedRecipients.indexOf(name) !== -1, [
    state.selectedRecipients
  ])

  const totalPage = useMemo(() => {
    return Math.ceil(userDetailsTotalCount / rowsPerPage)
  }, [userDetailsTotalCount, rowsPerPage])

  const itemsChangePagination = useMemo(() => {
    let itemsPerPage
    if (page !== totalPage - 1) {
      itemsPerPage = `${rowsPerPage * page + 1} - ${rowsPerPage * (page + 1)}`
    } else {
      itemsPerPage = `${rowsPerPage * page + 1} - ${userDetailsTotalCount}`
    }

    return `${itemsPerPage} of ${userDetailsTotalCount} items`
  }, [page, rowsPerPage, totalPage, userDetailsTotalCount])

  const sortableTableData = useMemo(() => {
    const data = userDetailsData
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .reduce((all: any, user: any) => {
        if (!user) {
          return [...all, {} as any]
        }
        return [
          ...all,
          {
            email: user.email,
            clickedLinks:
              hasESS && !isClickedLinksPending && user.clickedLinks === INCIDENT_USER_STATUS.UNKNOWN
                ? INCIDENT_USER_STATUS.OFF
                : user.clickedLinks,
            isRead: user.isRead,
            replied: user.replied,
            forwarded: user.forwarded,
            userType: user.userType
          } as DataItem
        ]
      }, [])
      .filter((item: any) => Object.keys(item).length)
    return {
      data,
      totalCount: userDetailsTotalCount
    }
  }, [hasESS, isClickedLinksPending, page, rowsPerPage, userDetailsData, userDetailsTotalCount])

  const sortedAllTableData = useMemo(() => {
    return stableSort(remediation.allUserDetailsByIncident.data, getComparator(order, orderBy))
  }, [order, orderBy, remediation.allUserDetailsByIncident.data])

  const handleSelectAllClick = useCallback(
    (event: React.ChangeEvent<any>) => {
      if (event.target.checked) {
        const newSelecteds = sortedAllTableData
          .filter((dataItem: DataItem) => dataItem.userType === 'Internal')
          .map((dataItem: DataItem) => dataItem.email)
        setState({ selectedRecipients: newSelecteds })
        return
      }
      setState({ selectedRecipients: [] })
    },
    [sortedAllTableData]
  )

  const openTrainingGroupDialog = useCallback(() => {
    dispatch(resetCreateAddressBook())
    toggleTrainingGroupDialog()
  }, [dispatch, toggleTrainingGroupDialog])

  const closeTrainingGroupDialog = useCallback(() => {
    dispatch(resetCreateAddressBook())
    toggleTrainingGroupDialog()
    setState({
      selectedRecipients: [],
      selectedInstance: '',
      selectInstanceError: false
    })
  }, [dispatch, toggleTrainingGroupDialog])

  const onCloseAlert = useCallback(() => {
    dispatch(resetCreateAddressBook())
  }, [dispatch])

  const updateInstance = useCallback((e: React.ChangeEvent<{ value: any }>) => {
    setState({
      selectedInstance: e.target.value,
      selectInstanceError: false
    })
  }, [])

  const createAddressBookErrorMessage = useCallback(() => {
    if (!createAddressBookError) {
      return ''
    }
    if (createAddressBookError === 'address_book_already_created') {
      return formatMessage('error.unique_address_book_name')
    }
    return formatMessage('error.try_later_time')
  }, [createAddressBookError, formatMessage])

  const sortedInstancesName: GetSecurityTrainingInstancesApiResponse[] = useMemo(() => {
    // set default to ascending order
    const data = stableSort(securityTrainingInstances, getComparator('asc', 'name')).filter(
      (instance: GetSecurityTrainingInstancesApiResponse) => instance.active
    )
    return data
  }, [securityTrainingInstances])

  const isCreateAddressBookEnabled = useMemo(() => {
    // securityTrainingInstances can be empty[] when bccid is empty or system can't find bccid in securityTraining DB
    if (isGetSecurityTrainingInstancesSuccess && securityTrainingInstances.length > 0) {
      if (securityTrainingInstances.length === 1) {
        return securityTrainingInstances[0].active && securityTrainingInstances[0].enableProductIntegration
      }
      return true
    }
    return false
  }, [isGetSecurityTrainingInstancesSuccess, securityTrainingInstances])

  const trainingGroupDialogConfig = useMemo(() => {
    return {
      addressBookName,
      createAddressBookErrorMessage: createAddressBookErrorMessage(),
      formatMessage,
      isCreateAddressBookLoading,
      isOpened: isTrainingGroupDialogOpened,
      onClose: closeTrainingGroupDialog,
      onCreateAddressBook,
      selectInstanceError: state.selectInstanceError,
      selectedInstance: state.selectedInstance,
      selectedRecipients: state.selectedRecipients,
      sortedInstancesName,
      updateInstance
    }
  }, [
    addressBookName,
    createAddressBookErrorMessage,
    formatMessage,
    closeTrainingGroupDialog,
    isCreateAddressBookLoading,
    isTrainingGroupDialogOpened,
    onCreateAddressBook,
    state.selectInstanceError,
    state.selectedInstance,
    state.selectedRecipients,
    sortedInstancesName,
    updateInstance
  ])

  return useMemo(
    () => [
      {
        addressBookName,
        exportPath: state.exportPath,
        handleChangePage,
        handleClick,
        handleRequestSort,
        handleSelectAllClick,
        hasESS,
        isClickedLinksPending,
        isCreateAddressBookEnabled,
        isCreateAddressBookSuccess,
        isPageInProgress: isIncidentUserDetailsLoading,
        isSelected,
        itemsChangePagination,
        numSelected: state.selectedRecipients.length,
        onCloseAlert,
        onExportData,
        openTrainingGroupDialog,
        order,
        orderBy,
        securityTrainingCampaignLink: createdAddressBook.securityTrainingCampaignLink,
        tableData: sortableTableData,
        totalPage,
        trainingGroupDialogConfig
      }
    ],
    [
      addressBookName,
      createdAddressBook.securityTrainingCampaignLink,
      handleChangePage,
      handleClick,
      handleRequestSort,
      handleSelectAllClick,
      hasESS,
      isClickedLinksPending,
      isCreateAddressBookEnabled,
      isCreateAddressBookSuccess,
      isIncidentUserDetailsLoading,
      isSelected,
      itemsChangePagination,
      onCloseAlert,
      onExportData,
      openTrainingGroupDialog,
      order,
      orderBy,
      sortableTableData,
      state.exportPath,
      state.selectedRecipients.length,
      totalPage,
      trainingGroupDialogConfig
    ]
  )
}
