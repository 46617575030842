export type Filename = string
export type Type = string

export function downloadCSVFile(downloadData: any, filename: Filename, type: Type) {
  const data = new Blob([downloadData], { type })
  const csvURL = window.URL.createObjectURL(data)

  const tempLink = document.createElement('a')
  tempLink.href = csvURL
  tempLink.setAttribute('download', filename)
  tempLink.click()
}

// Prompts download of PDF file, downloadData must be base64 encoded
export function downloadPDFFile(downloadData: any, filename: Filename) {
  const url = downloadData

  const tempLink = document.createElement('a')
  tempLink.href = url
  tempLink.setAttribute('download', filename)
  tempLink.click()
}
