import { Column } from 'global/types/api/unifiedReporting'

import { remediationEmailColumnConfig } from './remediationEmail'
import { remediationIncidentColumnConfig } from './remediationIncident'
import { automatedWorkflowsEventColumnConfig } from './automatedWorkflowsEvent'
import { insightsUserSubmissionColumnConfig } from './insightsUserSubmission'
import { BASE_DATA_TYPES } from './constants'

export const getColumnConfig = (baseDataType?: string): Column[] => {
  switch (baseDataType) {
    case BASE_DATA_TYPES.AUTOMATED_WORKFLOWS_EVENT:
      return automatedWorkflowsEventColumnConfig

    case BASE_DATA_TYPES.INSIGHTS_USER_SUBMISSION:
      return insightsUserSubmissionColumnConfig

    case BASE_DATA_TYPES.REMEDIATION_EMAIL:
      return remediationEmailColumnConfig

    case BASE_DATA_TYPES.REMEDIATION_INCIDENT:
      return remediationIncidentColumnConfig

    default:
      return []
  }
}
