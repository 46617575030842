import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

import queryString from 'query-string'

import SigninSignup from 'sen/components/pages/signup/signinSignup/Signup'
import DomainFraudSignup from 'sen/components/pages/signup/domainFraudSignup/DomainFraudSignup'

export interface SigninSignupProps {
  location?: any
}

const ONPREM = 'onprem'
const PRODUCT_DF = 'dp'

const Signup: React.FC<SigninSignupProps> = props => {
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const isDomainFraudSignup = queryParams?.provider === ONPREM || queryParams?.product === PRODUCT_DF

  if (isDomainFraudSignup) {
    return <DomainFraudSignup {...props} />
  }

  return <SigninSignup {...props} />
}

Signup.propTypes = {
  location: PropTypes.any
}

Signup.defaultProps = {
  location: undefined
}

export default Signup
