import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { Typography, Button, Box } from '@barracuda-internal/bds-core'

import StaticPageLayout from 'global/components/features/static/layout/StaticPageLayout'
import IMAGES from 'ets/configs/theme/images.config'
import styles from 'global/components/features/static/staticPageStyles'

const BASE_I18N_KEY = 'app.feedback'

interface FeedbackProps {
  onReportClick: () => void
  logo?: JSX.Element
  isSending?: boolean
  isSent?: boolean
  error?: string
}

const Feedback: React.FC<FeedbackProps> = props => {
  const { onReportClick, logo, isSending, isSent, error } = props
  const intl = useIntl()
  const classes = styles()

  return (
    <StaticPageLayout
      logo={logo}
      error={error ? `sen.app.http.${error}` : undefined}
      bgImage={IMAGES.loginBackground}
      isLoading={isSending}
      small
    >
      {isSent && (
        <Box display="flex" px={5}>
          <div>
            <Typography variant="h6" className={classes.pageTitle}>
              {intl.formatMessage({ id: `${BASE_I18N_KEY}.successTitle` })}
            </Typography>
            <Typography variant="body2">{intl.formatMessage({ id: `${BASE_I18N_KEY}.successText` })}</Typography>
          </div>
          <img src={IMAGES.statusSuccess} alt="feedback-success" className={classes.image} />
        </Box>
      )}
      {!isSent && (
        <Box px={5}>
          <Typography variant="h6" className={classes.pageTitle}>
            {intl.formatMessage({ id: `${BASE_I18N_KEY}.title` })}
          </Typography>
          <Typography variant="body2">{intl.formatMessage({ id: `${BASE_I18N_KEY}.text` })}</Typography>
          <div className={classes.btnContainer}>
            <Button onClick={onReportClick} color="primary" variant="contained" disabled={isSending}>
              {intl.formatMessage({ id: `${BASE_I18N_KEY}.buttonText` })}
            </Button>
          </div>
        </Box>
      )}
    </StaticPageLayout>
  )
}

Feedback.propTypes = {
  onReportClick: PropTypes.func.isRequired,
  logo: PropTypes.any,
  isSending: PropTypes.bool,
  isSent: PropTypes.bool,
  error: PropTypes.string
}

Feedback.defaultProps = {
  logo: undefined,
  isSending: false,
  isSent: false,
  error: ''
}

export default Feedback
