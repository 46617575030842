import React from 'react'

import IMAGES from 'global/configs/theme/images.config'

import { useFormatMessage } from 'global/lib/localization'
import styles from './loadingStyles'

const BASE_I18N_KEY = 'app'

const Loading: React.FC = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return (
    <div className={classes.appLoading}>
      <div className={classes.wholeScreen}>
        <div className={classes.wholeScreenLoading}>
          <div className={classes.appLoadingWrapper}>
            <div>
              <img className={classes.cudaLogo} alt="" src={IMAGES.cudaTeeth} />
            </div>
            <div>{formatMessage('loading')}...</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Loading
