import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { TextField, Typography, Grid } from '@barracuda-internal/bds-core'
import FormControl from '@material-ui/core/FormControl'

import YesNoSwitch from 'global/components/lib/form/YesNoSwitch'
import { useFormatMessage } from 'global/lib/localization'

import styles, { potentialIncidentsSettingsStyles } from '../SettingsStyles'

const BASE_I18N_KEY = 'fir.app.settings_dialog'

const UserReportedSettings: React.FC<any> = ({
  userReportedSettingsOptions: { isBasicProduct, isInvalidEmail, updateUserReportedSettingsOptions }
}) => {
  const baseClasses = styles()
  // re-using the styles from potential incidents settings since the two pages are almost identical
  const classes = potentialIncidentsSettingsStyles()
  const { forensicsSettings }: any = useSelector((_stores: any) => ({ forensicsSettings: _stores.settings.forensics }))
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return (
    <div data-field="wrapper" className={baseClasses.wrapper}>
      <div>
        <Typography data-field="header" variant="h5">
          {formatMessage('user_reported.labels.source')}
        </Typography>
      </div>
      <FormControl data-field="root-form" component="fieldset" className={classes.formControl}>
        <Grid data-field="root-grid" component="div" container spacing={2}>
          <Grid component="label" className={classes.label} xs={6} item>
            <Typography variant="body1" className={classes.wordWrap}>
              {formatMessage('user_reported.labels.ignore_sat_emails')}
            </Typography>
          </Grid>
          <Grid data-field="user-reported-ignore-sat-grid" xs={6} item>
            <YesNoSwitch
              checked={forensicsSettings.forensicsUserReportedIgnoreSatCampaigns}
              onChange={updateUserReportedSettingsOptions('forensicsUserReportedIgnoreSatCampaigns', true)}
            />
          </Grid>
          <Grid component="label" className={classes.label} xs={6} item>
            <Typography variant="body1" className={classes.wordWrap}>
              {formatMessage('user_reported.labels.send_email_alert')}
            </Typography>
          </Grid>
          <Grid data-field="user-reported-notifications-grid" xs={6} item>
            <YesNoSwitch
              checked={forensicsSettings.forensicsUserReportedNotifications}
              onChange={updateUserReportedSettingsOptions('forensicsUserReportedNotifications', true)}
            />
          </Grid>
          <Grid
            component="label"
            className={`${!forensicsSettings.forensicsUserReportedNotifications ? baseClasses.disabled : ''} ${
              classes.label
            }`}
            xs={6}
            item
          >
            <div className={baseClasses.subSetting}>
              <Typography variant="body1" className={classes.wordWrap}>
                {formatMessage(
                  `user_reported.labels.${
                    isBasicProduct ? 'sync_autoincident_email_address' : 'sync_incident_email_address'
                  }`
                )}
              </Typography>
            </div>
          </Grid>
          <Grid
            data-field="sync-address-grid"
            className={!forensicsSettings.forensicsUserReportedNotifications ? baseClasses.disabled : ''}
            xs={6}
            item
          >
            <YesNoSwitch
              checked={forensicsSettings.forensicsUserReportedSyncAddress}
              onChange={updateUserReportedSettingsOptions('forensicsUserReportedSyncAddress', true)}
            />
          </Grid>
          <Grid
            data-field="notify-address-grid"
            className={
              !forensicsSettings.forensicsUserReportedNotifications ||
              forensicsSettings.forensicsUserReportedSyncAddress
                ? baseClasses.disabled
                : ''
            }
            xs={6}
            item
          >
            <div className={baseClasses.subSetting}>
              <TextField
                data-field="notify-address-field"
                className={classes.formLine}
                value={forensicsSettings.forensicsUserReportedNotifyAddress}
                onChange={updateUserReportedSettingsOptions('forensicsUserReportedNotifyAddress')}
                label={formatMessage('user_reported.labels.notify_address')}
                error={isInvalidEmail}
                helperText={isInvalidEmail ? formatMessage('labels.invalid_email') : ''}
              />
            </div>
          </Grid>
        </Grid>
      </FormControl>
    </div>
  )
}

UserReportedSettings.propTypes = {
  userReportedSettingsOptions: PropTypes.any.isRequired
}

export default UserReportedSettings
