import { CancelToken } from 'axios'

import { isString, merge } from 'lodash'
import accessTokenLib from 'global/lib/accessToken/accessToken'

interface Config {
  cancelToken?: CancelToken
  withCredentials: boolean
  headers: {}
  data: {
    accessTokenId?: string | undefined
  }
}

export interface AdditionalConfig {
  [key: string]: any
}

export interface MiddlewareGeneratorConfig {
  additionalConfigGenerator?: (baseConfig: any) => AdditionalConfig
}

export default function requestMiddlewareGenerator(middlewareGeneratorConfig: MiddlewareGeneratorConfig = {}) {
  return function requestMiddleware(config: Config) {
    let newConfig: Config & AdditionalConfig = { ...config }
    const { data } = config

    if (data && !isString(data.accessTokenId)) {
      data.accessTokenId = accessTokenLib.getActiveAccessTokenId()
    }

    const baseConfig: Config = {
      ...config,
      ...(data && { ...data }),
      withCredentials: true,
      headers: {
        ...config.headers
      }
    }

    newConfig = merge(baseConfig, {
      ...(middlewareGeneratorConfig?.additionalConfigGenerator?.(baseConfig) || {})
    })

    // Because a deep merge will create new objects that destroy
    // all references. Probably best to only ever use a deep merge
    // when dealing with freshly parsed JSON.
    //
    // https://github.com/TehShrike/deepmerge/issues/208
    //
    newConfig.cancelToken = config.cancelToken

    return newConfig
  }
}
