export default function onetrustCookieAcceptance(onetrustId: string | undefined) {
  const isPartnerPortal = window.location.pathname.includes('howmanyscanspartnerportal')

  // Only load OneTrust if we have an ID and we are not viewing how many scans pp page
  if (onetrustId && !isPartnerPortal) {
    const onetrustScriptLoader = document.createElement('script')
    onetrustScriptLoader.setAttribute('src', 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js')
    onetrustScriptLoader.setAttribute('type', 'text/javascript')
    onetrustScriptLoader.setAttribute('charset', 'UTF-8')
    onetrustScriptLoader.setAttribute('data-domain-script', onetrustId)
    document.head.appendChild(onetrustScriptLoader)

    const onetrustScriptStarter = document.createElement('script')
    onetrustScriptStarter.setAttribute('type', 'text/javascript')
    onetrustScriptStarter.textContent = 'function OptanonWrapper() { }'
    document.head.appendChild(onetrustScriptStarter)
  }
}
