import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { config } from 'global/lib/config'

import { Products } from 'global/types/redux/productsType'

import { COLORS } from 'global/configs/theme/defaults'
import TextLabel from 'global/components/lib/textLabel/TextLabel'
import { useFormatMessage } from 'global/lib/localization'

import styles from 'global/components/lib/productTypeLabel/productTypeLabelStyles'

export interface KeyType {
  [key: string]: string
}

export interface ProductTypeLabelProps {
  accessTokenId: string
  products: Products[]
  bundleVersion?: string
}

const BASE_PRODUCT_TYPES_I18N_KEY = 'app.product_types'

const INCIDENT_RESPONSE = 'incident_response'
const IMPERSONATION_PROTECTION = 'impersonation_protection'
const DOMAIN_FRAUD_PROTECTION = 'domain_fraud_protection'
const EMAIL_THREAT_SCANNER = 'email_threat_scanner'

const SEN_PRODUCT_TYPES: KeyType = {
  B1: IMPERSONATION_PROTECTION,
  B2: IMPERSONATION_PROTECTION,
  DP: DOMAIN_FRAUD_PROTECTION
}

const PRODUCT_COLORS: KeyType = {
  EMAIL_THREAT_SCANNER: COLORS.TEAL_500,
  INCIDENT_RESPONSE: COLORS.BLUE_600,
  IMPERSONATION_PROTECTION: COLORS.BLUE_700,
  DOMAIN_FRAUD_PROTECTION: COLORS.SKY_BLUE_500
}

const ProductTypeLabel: React.FC<ProductTypeLabelProps> = ({ accessTokenId, products, bundleVersion }) => {
  const formatMessage = useFormatMessage(BASE_PRODUCT_TYPES_I18N_KEY)
  const classes = styles()

  const productType: string | null = useMemo(() => {
    if (!accessTokenId) {
      return null
    }

    switch (true) {
      case (config.domainConfig.isSentinel || config.domainConfig.isEts) && products.includes(config.PRODUCTS.SENTINEL):
        return SEN_PRODUCT_TYPES[bundleVersion || '']
      case (config.domainConfig.isSentinel || config.domainConfig.isEts) && products.includes(config.PRODUCTS.ETS):
        return EMAIL_THREAT_SCANNER
      case config.domainConfig.isForensics && products.includes(config.PRODUCTS.FORENSICS):
        return INCIDENT_RESPONSE
      default:
        return null
    }
  }, [accessTokenId, bundleVersion, products])

  if (!productType) {
    return null
  }

  return (
    <data className={classes.label}>
      <TextLabel bgColor={PRODUCT_COLORS[productType.toUpperCase()]}>{formatMessage(`${productType}`)}</TextLabel>
    </data>
  )
}

ProductTypeLabel.propTypes = {
  accessTokenId: PropTypes.string.isRequired,
  products: PropTypes.array.isRequired,
  bundleVersion: PropTypes.string
}

ProductTypeLabel.defaultProps = {
  bundleVersion: undefined
}

export default ProductTypeLabel
