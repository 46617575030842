import { createSlice } from '@reduxjs/toolkit'

import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'global/redux/toolkit/api'

import { getSyslogConfig, saveSyslogConfig, testSyslogConfig } from 'sen/redux/features/syslog/syslogApiThunk'
import { SyslogConfig, SyslogResults } from 'sen/redux/features/syslog/syslogTypes'

export interface SyslogState {
  getSyslogConfigApiStatus: ApiStatus
  saveSyslogConfigApiStatus: ApiStatus
  testSyslogConfigApiStatus: ApiStatus
  syslogConfig: SyslogConfig | undefined
  syslogResult: SyslogResults | undefined
  errorMsg: string | undefined
}

export const INITIAL_STATE: SyslogState = {
  getSyslogConfigApiStatus: inIdle,
  saveSyslogConfigApiStatus: inIdle,
  testSyslogConfigApiStatus: inIdle,
  syslogConfig: undefined,
  syslogResult: undefined,
  errorMsg: undefined
}

/* eslint-disable no-param-reassign */
export const syslogSlice = createSlice({
  name: 'SYSLOG',
  initialState: INITIAL_STATE,
  reducers: {
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  },
  extraReducers: builder => {
    builder
      // getSyslogConfig
      .addCase(getSyslogConfig.pending, state => {
        state.getSyslogConfigApiStatus = inProgress
        state.syslogConfig = INITIAL_STATE.syslogConfig
        state.errorMsg = INITIAL_STATE.errorMsg
      })
      .addCase(getSyslogConfig.fulfilled, (state, action) => {
        state.getSyslogConfigApiStatus = successResponse
        state.syslogConfig = action.payload || INITIAL_STATE.syslogConfig
      })
      .addCase(getSyslogConfig.rejected, (state, action) => {
        const error = action.payload as string
        state.getSyslogConfigApiStatus = failedResponse(error)
        state.errorMsg = error
      })

      // saveSyslogConfig
      .addCase(saveSyslogConfig.pending, state => {
        state.saveSyslogConfigApiStatus = inProgress
        state.testSyslogConfigApiStatus = INITIAL_STATE.testSyslogConfigApiStatus
        state.syslogResult = INITIAL_STATE.syslogResult
        state.errorMsg = INITIAL_STATE.errorMsg
      })
      .addCase(saveSyslogConfig.fulfilled, (state, action) => {
        state.saveSyslogConfigApiStatus = successResponse
        state.syslogConfig = action.meta.arg.syslogConfig || INITIAL_STATE.syslogConfig
        state.syslogResult = action.payload || INITIAL_STATE.syslogResult
      })
      .addCase(saveSyslogConfig.rejected, (state, action) => {
        const error = action.payload as string
        state.saveSyslogConfigApiStatus = failedResponse(error)
        state.errorMsg = error
      })

      // testSyslogConfig
      .addCase(testSyslogConfig.pending, state => {
        state.testSyslogConfigApiStatus = inProgress
        state.saveSyslogConfigApiStatus = INITIAL_STATE.saveSyslogConfigApiStatus
        state.syslogResult = INITIAL_STATE.syslogResult
        state.errorMsg = INITIAL_STATE.errorMsg
      })
      .addCase(testSyslogConfig.fulfilled, (state, action) => {
        state.testSyslogConfigApiStatus = successResponse
        state.syslogResult = action.payload || INITIAL_STATE.syslogResult
      })
      .addCase(testSyslogConfig.rejected, (state, action) => {
        const error = action.payload as string
        state.testSyslogConfigApiStatus = failedResponse(error)
        state.errorMsg = error
      })
  }
})
/* eslint-enable no-param-reassign */

export const { reset } = syslogSlice.actions

export { getSyslogConfig, saveSyslogConfig, testSyslogConfig }

export default syslogSlice.reducer
