import React, { useMemo } from 'react'

import {
  Typography,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Zoom,
  Alert as AlertComponent
} from '@barracuda-internal/bds-core'

import CircularProgress from 'global/components/lib/circularProgress/CircularProgress'
import DEFAULTS from 'global/configs/theme/defaults'
import { useFormatMessage, useErrorFormatMessage } from 'global/lib/localization'

import styles from 'sen/components/lib/dialogs/inboxRules/inboxRulesDialogStyles'
import useInboxRulesDialog from 'sen/components/lib/dialogs/inboxRules/useInboxRulesDialogLogic'
import InboxRulesTable from 'sen/components/lib/dialogs/inboxRules/inboxRulesTable/InboxRulesTable'

export interface InboxRulesDialogProps {
  userPrincipalName: string
  onClose: () => void
}

const BASE_I18N_KEY = 'sen.app.inbox_rules_dialog'

export const InboxRulesDialog: React.FC<InboxRulesDialogProps> = ({ userPrincipalName, onClose }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatMessageError = useErrorFormatMessage()
  const classes = styles()
  const [isLoaded, tableConfig, error] = useInboxRulesDialog(userPrincipalName)

  return useMemo(
    () => (
      <Dialog
        style={{ zIndex: DEFAULTS.DIALOGS_ZINDEX }}
        maxWidth="lg"
        fullWidth
        open
        TransitionComponent={Zoom}
        data-testid="inbox-rules-dialog"
      >
        <DialogTitle>
          {error && (
            <AlertComponent
              className={classes.error}
              variant="filled"
              severity="error"
              data-testid="inbox-rules-dialog-alert"
            >
              {formatMessageError(error)}
            </AlertComponent>
          )}

          <Typography className={classes.dialogTitle}>
            {formatMessage('title', {
              email: userPrincipalName,
              b: (txt: string) => <b key={txt}>{txt}</b>
            })}
          </Typography>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          {!isLoaded && (
            <Grid container justifyContent="center" className={classes.loading}>
              <CircularProgress />
            </Grid>
          )}

          {isLoaded && (
            <Grid container direction="column">
              <InboxRulesTable tableConfig={tableConfig} />
            </Grid>
          )}
        </DialogContent>

        <DialogActions>
          <Button color="primary" variant="contained" size="large" onClick={onClose}>
            &nbsp;&nbsp;{formatMessage('button')}&nbsp;&nbsp;
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [classes, formatMessage, onClose, tableConfig, error, formatMessageError, isLoaded, userPrincipalName]
  )
}

export default InboxRulesDialog
