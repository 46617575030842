import React from 'react'

import { Grid } from '@barracuda-internal/bds-core'

import AppAlert from 'global/components/lib/appAlert/AppAlert'

import styles from 'sen/components/lib/layout/contentLayoutStyles'

export interface ContentLayoutProps {
  children?: any
}

export default function ContentLayout({ children }: ContentLayoutProps): JSX.Element {
  const classes = styles()

  return (
    <Grid container className={classes.container}>
      <AppAlert />
      {children}
    </Grid>
  )
}
