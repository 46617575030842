import { makeStyles } from '@material-ui/core/styles'

import tabStyles from 'global/theme/tabStyles'
import dialogStyles from 'global/components/lib/dialogs/dialogStyles'

export default makeStyles(theme => {
  const defaultDialogStyles = dialogStyles(theme)
  const tabClasses = tabStyles(theme)

  return {
    ...defaultDialogStyles,
    ...tabClasses,
    tabs: {
      ...tabClasses.tabs,
      width: '100%'
    },
    tabContent: {
      ...tabClasses.tabContent,
      marginTop: theme.spacing(0.5)
    },
    tabsContainer: {
      marginTop: theme.spacing(2)
    }
  }
})
