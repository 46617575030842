import React from 'react'
import PropTypes from 'prop-types'

import { Chip, MenuItem, ListItemIcon, TextField, IconButton } from '@barracuda-internal/bds-core'
import { Add as AddIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'

import { EventConstants } from '@barracuda/automated-workflows'

import { getCountryListCode3 } from 'global/lib/isoCountries'
import { useFormatMessage } from 'global/lib/localization'

import { NODE_TYPES } from 'fir/redux/types/Workflows'

import styles from '../workflowDialogStyles'

interface EventRowProps {
  displayNewIcon?: boolean
  handleEventData?: (e: any) => void
  hideAddButton?: boolean
  isWorkflowBlueprint?: boolean
  disableTriggerSelect?: boolean
  onAddEvent: () => void
  onChangeEvent: (e: any, uuid: string) => void
  onChangeEventOperator?: (e: any) => void
  conditionDataErrorText?: string
  isValidConditionValue?: boolean
  eventRowConfig: any
}

interface OperatorDetails {
  types: string[]
  value: string
}

interface Operators {
  [key: string]: OperatorDetails
}

const BASE_I18N_KEY = 'fir.app.automated_workflows.workflow_dialog'
const CONDITION_OPERATORS = {
  contains: {
    types: ['multiCountry', 'string'],
    value: 'CONTAINS'
  },
  notContains: {
    types: ['multiCountry', 'string'],
    value: 'NOT_CONTAINS'
  },
  equals: {
    types: ['number', 'singleCountry', 'string'],
    value: '=='
  },
  notEquals: {
    types: ['number', 'singleCountry', 'string'],
    value: '!='
  },
  greaterThan: {
    types: ['number'],
    value: '>'
  },
  lessThan: {
    types: ['number'],
    value: '<'
  }
} as Operators
const MULTI_COUNTRY_CONDITIONS = ['potentialIncidentSenderCountry', 'senderPolicySenderCountry']
const SINGLE_COUNTRY_CONDITIONS = ['userReportedSenderCountry']

const EventRow: React.FC<EventRowProps> = ({
  /* eslint-disable @typescript-eslint/no-empty-function */
  conditionDataErrorText,
  disableTriggerSelect,
  displayNewIcon,
  eventRowConfig,
  handleEventData = () => {},
  hideAddButton,
  isValidConditionValue,
  isWorkflowBlueprint,
  onAddEvent,
  onChangeEvent,
  onChangeEventOperator = () => {}
  /* eslint-enable @typescript-eslint/no-empty-function */
}) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  // basic event select, covers triggers and actions
  let eventInput = (
    <TextField
      select
      className={!isWorkflowBlueprint ? classes.eventRowSelectInput : ''}
      disabled={disableTriggerSelect}
      label={formatMessage(`labels.${eventRowConfig.eventType}`)}
      onChange={(e: any) => onChangeEvent(e, eventRowConfig.eventType)}
      value={eventRowConfig.eventValue}
    >
      ...
      {EventConstants[eventRowConfig.eventType.toUpperCase()]
        .map((event: string) => (
          <MenuItem id={event} key={event} value={event} className={classes.menuItems}>
            {formatMessage(`events.${eventRowConfig.eventType}.${event}`)}
            {/* Remove after 3 months from Oct. 2022 */}
            {event === 'msteamsNotification' && displayNewIcon && (
              <ListItemIcon>
                <Chip className={classes.eventRowMenuListNewIcon} label="NEW" />
              </ListItemIcon>
            )}
          </MenuItem>
        ))
        .sort((menuItemA: any, menuItemB: any) => (menuItemA.props.children > menuItemB.props.children ? 1 : -1))}
    </TextField>
  )

  // add button for root-level event (i.e. triggers)
  let addEventButton = (
    <>
      <IconButton
        className={classes.addEventRowIconButton}
        disabled={eventRowConfig.eventCount > 0 || !eventRowConfig.eventValue}
        onClick={() => {
          onAddEvent()
        }}
      >
        <AddIcon />
      </IconButton>
    </>
  )

  // condition events have a more complex input and addButton logic
  const conditionInputs = (
    <>
      <TextField
        select
        className={!isWorkflowBlueprint ? classes.eventRowSelectInput : ''}
        label={formatMessage(`labels.${eventRowConfig.eventType}`)}
        onChange={(e: any) => onChangeEvent(e, eventRowConfig.eventType)}
        value={eventRowConfig.eventValue}
      >
        ...
        {eventRowConfig.parentEventValue &&
          EventConstants[eventRowConfig.eventType.toUpperCase()]
            .filter((event: string) => event.indexOf(eventRowConfig.parentEventValue) === 0)
            .map((event: string) => (
              <MenuItem key={event} value={event} className={classes.menuItems}>
                {formatMessage(`events.${eventRowConfig.eventType}.${event}`)}
              </MenuItem>
            ))
            .sort((menuItemA: any, menuItemB: any) => (menuItemA.props.children > menuItemB.props.children ? 1 : -1))}
      </TextField>
      {eventRowConfig.eventValue && !eventRowConfig.eventValue.endsWith('_noInput') && (
        <>
          <TextField
            select
            className={!isWorkflowBlueprint ? classes.eventRowSelectInput : ''}
            error={!eventRowConfig.eventOperator && isWorkflowBlueprint}
            helperText={
              !eventRowConfig.eventOperator && isWorkflowBlueprint ? formatMessage('errors.select_operator') : ''
            }
            label={formatMessage('labels.operator')}
            onChange={onChangeEventOperator}
            value={eventRowConfig.eventOperator}
          >
            {Object.keys(CONDITION_OPERATORS)
              .filter((operator: string) => {
                const operatorDetails = CONDITION_OPERATORS[operator]
                return (
                  (SINGLE_COUNTRY_CONDITIONS.indexOf(eventRowConfig.eventValue) > -1 &&
                    operatorDetails.types.includes('singleCountry')) ||
                  (MULTI_COUNTRY_CONDITIONS.indexOf(eventRowConfig.eventValue) > -1 &&
                    operatorDetails.types.includes('multiCountry')) ||
                  (eventRowConfig.eventValue.endsWith('Count') && operatorDetails.types.includes('number')) ||
                  (!eventRowConfig.eventValue.endsWith('Count') &&
                    !eventRowConfig.eventValue.endsWith('Country') &&
                    operatorDetails.types.includes('string'))
                )
              })
              .map((operator: string) => {
                const operatorDetails = CONDITION_OPERATORS[operator]
                return (
                  <MenuItem key={operatorDetails.value} value={operatorDetails.value} className={classes.menuItems}>
                    {formatMessage(`events.operators.${operator}`)}
                  </MenuItem>
                )
              })}
          </TextField>
          {eventRowConfig.eventValue.indexOf('Country') === -1 && (
            <TextField
              className={!isWorkflowBlueprint ? classes.eventRowSelectInput : ''}
              data-field="conditionData"
              error={!isValidConditionValue}
              helperText={!isValidConditionValue ? conditionDataErrorText : ''}
              value={eventRowConfig.eventData}
              onChange={handleEventData}
              label={formatMessage('labels.condition_details')}
              type="text"
            />
          )}
          {eventRowConfig.eventValue.indexOf('Country') !== -1 && (
            <TextField
              select
              className={!isWorkflowBlueprint ? classes.eventRowSelectInput : ''}
              error={!isValidConditionValue}
              helperText={!isValidConditionValue ? conditionDataErrorText : ''}
              label={formatMessage('labels.country')}
              onChange={handleEventData}
              value={eventRowConfig.eventData}
            >
              ...
              {getCountryListCode3().map((menuItem: any) => (
                <MenuItem key={menuItem.code} value={menuItem.code}>
                  {menuItem.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        </>
      )}
    </>
  )

  // Need to render this component this way because of styling
  if (eventRowConfig.eventType === NODE_TYPES.conditions) {
    eventInput = isWorkflowBlueprint ? (
      <div className={classes.conditionDataWrapper}>{conditionInputs}</div>
    ) : (
      conditionInputs
    )

    addEventButton = (
      <>
        <IconButton
          className={classes.addEventRowIconButton}
          disabled={
            !eventRowConfig.eventValue ||
            (!eventRowConfig.eventValue.endsWith('_noInput') &&
              (!eventRowConfig.eventOperator || !eventRowConfig.eventData || !isValidConditionValue)) ||
            !eventRowConfig.triggerId
          }
          onClick={() => {
            onAddEvent()
          }}
        >
          <AddIcon />
        </IconButton>
      </>
    )
  }

  // add button for action events
  if (eventRowConfig.eventType === NODE_TYPES.actions) {
    addEventButton = (
      <>
        <IconButton
          className={classes.addEventRowIconButton}
          disabled={!eventRowConfig.eventValue || !eventRowConfig.triggerId}
          onClick={() => {
            onAddEvent()
          }}
        >
          <AddIcon />
        </IconButton>
      </>
    )
  }

  return (
    <>
      {eventInput}
      {hideAddButton ? '' : addEventButton}
    </>
  )
}

EventRow.propTypes = {
  conditionDataErrorText: PropTypes.string,
  disableTriggerSelect: PropTypes.bool,
  displayNewIcon: PropTypes.bool,
  handleEventData: PropTypes.func,
  hideAddButton: PropTypes.bool,
  isValidConditionValue: PropTypes.bool,
  isWorkflowBlueprint: PropTypes.bool,
  onChangeEventOperator: PropTypes.func,
  eventRowConfig: PropTypes.any.isRequired,
  onAddEvent: PropTypes.func.isRequired,
  onChangeEvent: PropTypes.func.isRequired
}

EventRow.defaultProps = {
  conditionDataErrorText: '',
  disableTriggerSelect: false,
  displayNewIcon: false,
  handleEventData: undefined,
  hideAddButton: false,
  isValidConditionValue: true,
  isWorkflowBlueprint: false,
  onChangeEventOperator: undefined
}

export default EventRow
