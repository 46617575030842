import { CompanyDomain } from 'global/types/api/dmarc'

export enum DmarcReviewTabs {
  approved = 'approved',
  rejected = 'rejected',
  unknownState = 'unknown'
}

export interface SectionsProps {
  domain: CompanyDomain
}
