import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  bold: {
    fontWeight: 'bold'
  },
  chartTooltipIcon: {
    height: 12,
    width: 12,
    borderRadius: 6,
    marginBottom: -1,
    display: 'inline-block'
  },
  chartTooltipTitle: {
    opacity: 0.5,
    fontWeight: 'bold',
    overflowWrap: 'anywhere'
  },
  chartTooltipValueCell: {
    float: 'right'
  },
  chartTooltipValueDetails: {
    verticalAlign: 'middle'
  },
  chartTooltipValueDetailsText: {
    display: 'inline-block',
    paddingLeft: 7,
    fontWeight: 'bold',
    textTransform: 'capitalize'
  },
  chartTooltipWrapper: {
    width: 225
  }
}))
