import React, { useMemo } from 'react'

import { v4 as uuidv4 } from 'uuid'

import { Typography, Grid } from '@barracuda-internal/bds-core'

import ChartPanel from 'ets/components/lib/chartPanel/ChartPanel'

import styles from 'ets/components/pages/dashboard/overview/dashboardOverviewStyles'

import useDashboardOverviewLogic, {
  DashboardOverviewLogicProps,
  DmarcConfig
} from 'ets/components/pages/dashboard/overview/useDashboardOverviewLogic'
import { useFormatMessage } from 'global/lib/localization'

const BASE_I18N_KEY = 'ets.app.dashboard.overview'
const BASE_ATTACK_TYPES_KEY = 'app.attack_types'

const DashboardOverview: React.FC<DashboardOverviewLogicProps> = props => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatMessageAttack = useFormatMessage(BASE_ATTACK_TYPES_KEY)
  const classes = styles()
  const [dashboardOverviewLogic] = useDashboardOverviewLogic(props)

  return useMemo(() => {
    const {
      totalThreatsFoundPanelConfig,
      employeesWithThreatsPanelConfig,
      threatTypesFoundPanelConfig,
      domainDmarcStatusConfig,
      hoveredThreatType,
      dmarcConfig
    } = dashboardOverviewLogic

    return (
      <Grid className={classes.root} container alignItems="center" justifyContent="center" spacing={2}>
        <ChartPanel id="total-threats-found" {...totalThreatsFoundPanelConfig} />
        <ChartPanel id="employees-with-threats" {...employeesWithThreatsPanelConfig} />
        <ChartPanel id="threat-types-found" {...threatTypesFoundPanelConfig}>
          <Grid className={classes.subContainer} container direction="column">
            <Typography align="left" className={classes.subtitle}>
              {formatMessage('threat_types_found.subtitle', {
                threatType: formatMessageAttack(`${hoveredThreatType}.text`)
              })}
            </Typography>
            <Typography align="left" className={classes.subDescription}>
              {formatMessageAttack(`${hoveredThreatType}.tooltip`)}
            </Typography>
          </Grid>
        </ChartPanel>
        <ChartPanel id="domain-dmarc-status" {...domainDmarcStatusConfig} withSpacer>
          {dmarcConfig.items.map((config: DmarcConfig) => (
            <Grid key={config.id} container direction="row">
              <Grid
                className={classes[config.color]}
                item
                style={{ maxWidth: dmarcConfig.maxWidth, flexBasis: dmarcConfig.maxWidth }}
              >
                <span className={classes.dmarcCount}>{config.count}</span>
              </Grid>
              <Grid className={classes.dmarcText} item>
                {formatMessage(`domain_dmarc_status.${config.id}`, {
                  br: () => <br key={uuidv4()} />,
                  b: (text: string) => <b key={text}>{text}</b>
                })}
              </Grid>
            </Grid>
          ))}
        </ChartPanel>
      </Grid>
    )
  }, [dashboardOverviewLogic, classes, formatMessage, formatMessageAttack])
}

export default DashboardOverview
