import { makeStyles } from '@material-ui/core/styles'

const breadcrumb = {
  display: 'inline-block',
  cursor: 'default'
}

export default makeStyles(() => ({
  breadcrumb: {
    ...breadcrumb
  },
  breadcrumbLink: {
    ...breadcrumb,
    cursor: 'pointer',
    textDecoration: 'underline',
    color: '#757575'
  }
}))
