import React from 'react'
import validateSession from 'global/components/lib/authLayer/validateSession'
import { forceRedirectToSignup } from 'global/lib/routes/routesConfig'
import globalPrivateRoute from 'global/components/lib/routes/PrivateRoute'
import validateAdmin from 'global/components/lib/validateAdmin/validateAdmin'

export default globalPrivateRoute((component, childProps) => {
  const WrappedComponent = validateAdmin(validateSession(component, forceRedirectToSignup))
  return <WrappedComponent {...childProps} />
})
