import React from 'react'
import PropTypes from 'prop-types'

export interface NavbarLogoBDSProps {
  Logo: React.ComponentType<any>
}

const NavbarLogoBDS: React.FC<NavbarLogoBDSProps> = ({ Logo }) => {
  return <Logo height="40" />
}

NavbarLogoBDS.propTypes = {
  Logo: PropTypes.any.isRequired
}

export default NavbarLogoBDS
