import React from 'react'
import PropTypes from 'prop-types'

import {
  Checkbox,
  CheckboxLabel,
  Link,
  MenuItem,
  Select,
  Radio,
  RadioGroup,
  Typography,
  Grid
} from '@barracuda-internal/bds-core'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import YesNoSwitch from 'global/components/lib/form/YesNoSwitch'
import { config } from 'global/lib/config'
import { useFormatMessage } from 'global/lib/localization'

import { SenderPolicyActions, SenderPolicyTypes } from 'fir/redux/types/Settings'
import styles, { potentialIncidentsSettingsStyles } from '../SettingsStyles'

const BASE_I18N_KEY = 'fir.app.settings_dialog'
const BASE_LABELS_I18N_KEY = 'fir.app.settings_dialog.remediation.labels'

const PolicySettings: React.FC<any> = ({
  policySettingsOptions: { forensicsSettings, updatePolicySettingsOptions, isEntitledESS, isEntitledBCS }
}) => {
  const baseClasses = styles()
  const classes = potentialIncidentsSettingsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatMessageLabels = useFormatMessage(BASE_LABELS_I18N_KEY)

  return (
    <div data-field="wrapper" className={baseClasses.wrapper}>
      <div>
        <Typography data-field="header" variant="h5">
          {formatMessageLabels('policy')}
        </Typography>
        <Typography variant="subtitle2">
          <div>
            <span className={`${baseClasses.opacity}`}>{formatMessageLabels('policy_subtitle')}</span>
          </div>
        </Typography>
      </div>
      <br />
      <FormControl data-field="root-form" component="fieldset" className={classes.formControl}>
        <Grid data-field="root-grid" component="div" container spacing={2}>
          <Grid xs={12} item>
            <Typography variant="subtitle2">
              <span className={`${baseClasses.opacity}`}>{formatMessageLabels('sender_policy_subtitle')}</span>{' '}
              <Link target="_blank" href={config.LINKS.BARRACUDA_ESSENTIALS}>
                {formatMessage('learn_more')}...
              </Link>
            </Typography>
          </Grid>
          <Grid data-field="sender-policy-grid" xs={12} item>
            <CheckboxLabel
              control={
                <Checkbox
                  disabled={!isEntitledESS}
                  color="primary"
                  checked={forensicsSettings.forensicsIncidentSenderPolicy}
                  onChange={updatePolicySettingsOptions('forensicsIncidentSenderPolicy', true)}
                />
              }
              label={
                <span className={`${!isEntitledESS ? baseClasses.disabled : ''}`}>
                  {formatMessageLabels('add_sender_policy')}
                  <Select
                    disabled={!isEntitledESS}
                    className={classes.policyPlaceSelector}
                    value={forensicsSettings.forensicsIncidentSenderPolicyAction}
                    onChange={updatePolicySettingsOptions('forensicsIncidentSenderPolicyAction')}
                  >
                    {[SenderPolicyActions.quarantine, SenderPolicyActions.block].map((place: string) => (
                      <MenuItem value={place} key={place}>
                        <span className={baseClasses.textLowercase}>
                          {formatMessageLabels(`sender_policy_actions.${place}`)}
                        </span>
                      </MenuItem>
                    ))}
                  </Select>{' '}
                  <span className={baseClasses.textLowercase}>{formatMessageLabels('email')}</span>
                </span>
              }
            />
            <FormControl className={`${classes.formControl} ${classes.subFormControl} ${baseClasses.closerToTop}`}>
              <RadioGroup
                row
                name="sender-policy-action"
                value={forensicsSettings.forensicsIncidentSenderPolicyType}
                onChange={updatePolicySettingsOptions('forensicsIncidentSenderPolicyType')}
              >
                <FormControlLabel
                  control={<Radio color="primary" />}
                  disabled={!forensicsSettings.forensicsIncidentSenderPolicy}
                  value={SenderPolicyTypes.senders}
                  label={formatMessageLabels('sender_policy_types.senders', {
                    b: (txt: any) => (
                      <span key={txt} className={baseClasses.bold}>
                        {txt}
                      </span>
                    )
                  })}
                />
                <FormControlLabel
                  control={<Radio color="primary" />}
                  disabled={!forensicsSettings.forensicsIncidentSenderPolicy}
                  value={SenderPolicyTypes.domains}
                  label={formatMessageLabels('sender_policy_types.domains', {
                    b: (txt: any) => (
                      <span key={txt} className={baseClasses.bold}>
                        {txt}
                      </span>
                    )
                  })}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid component="div" container spacing={2} className={baseClasses.splitSettings}>
          <Grid xs={12} item>
            <Typography variant="subtitle2">
              <span className={`${baseClasses.opacity}`}>{formatMessageLabels('content_shield_policy_subtitle')}</span>{' '}
              <Link target="_blank" href={config.LINKS.BARRACUDA_CONTENT_SHIELD}>
                {formatMessage('learn_more')}...
              </Link>
            </Typography>
          </Grid>
          <Grid
            component="label"
            className={`${classes.label} ${!isEntitledBCS ? baseClasses.disabled : ''}`}
            xs={6}
            item
          >
            <Typography variant="body1">{formatMessageLabels('add_content_shield_policy')}</Typography>
          </Grid>
          <Grid
            data-field="content-shield-grid"
            xs={6}
            item
            className={`${!isEntitledBCS ? baseClasses.disabled : ''}`}
          >
            <YesNoSwitch
              checked={forensicsSettings.forensicsIncidentContentShieldPolicy}
              onChange={updatePolicySettingsOptions('forensicsIncidentContentShieldPolicy', true)}
            />
          </Grid>
        </Grid>
      </FormControl>
    </div>
  )
}

PolicySettings.propTypes = {
  policySettingsOptions: PropTypes.any.isRequired
}

export default PolicySettings
