import React, { useMemo } from 'react'

import { Button, Card, Menu, MenuItem, Tooltip, Typography } from '@barracuda-internal/bds-core'
import { DropDownArrow } from '@barracuda-internal/bds-core/dist/Icons/Controls'
import { MenuVertical } from '@barracuda-internal/bds-core/dist/Icons/Core'

import RenameReportDialog from 'global/components/lib/dialogs/renameReportDialog/RenameReportDialog'
import SaveReportDialog from 'global/components/lib/dialogs/saveReportDialog/SaveReportDialog'
import { ScheduledReportsDrawerConfig } from 'global/components/features/scheduledReports/ScheduledReports'
import ScheduledReportsDrawer from 'global/components/features/scheduledReports/scheduledReportsDrawer/ScheduledReportsDrawer'
import { useFormatMessage } from 'global/lib/localization'
import { DateRangeConfig } from 'global/components/lib/dataTable/DataTable'

import styles from './unifiedReportingHeaderStyles'

const BASE_I18N_KEY = 'app.unified_reporting'

export interface DownLoadButtonConfig {
  downloadOptions: string[]
  downLoadAnchorEl: null | HTMLElement
  handleDownLoadButtonClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  handleDownLoadMenuItemClick: (option: string) => void
  handleDownLoadMenuClose: () => void
}

export interface ReportingOptionsButtonConfig {
  // TODO: uncomment once we have more options
  // reportingOptions: JSX.Element | JSX.Element[]
  reportingOptions: JSX.Element[]
  reportingOptionsAnchorEl: null | HTMLElement
  handleReportingOptionsButtonClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  handleReportingOptionsMenuClose: () => void
}

export interface ReportDialogConfig {
  dataRetentionWarning: {
    dataLossDate: string
    shouldShowWarning: boolean
  }
  dateRangeConfig: DateRangeConfig
  isSaveReportDialogOpen: boolean
  isRenameReportDialogOpen: boolean
  isUpdate: boolean
  toggleSaveReportDialog: () => void
  toggleRenameReportDialog: () => void
  onSave: () => void
  onUpdate: (e: any) => void
  reportName: string
  shouldDisableSaveButton: boolean
}

export interface UnifiedReportingHeaderLogicProps {
  downLoadButtonConfig: DownLoadButtonConfig
  isScheduledReportsDrawerOpen?: any
  reportingOptionsButtonConfig: ReportingOptionsButtonConfig
  reportTitle: string
  reportDialogConfig: ReportDialogConfig
  scheduledReportsDrawerConfig: ScheduledReportsDrawerConfig
  toggleDrawer?: any
}

const MAX_REPORT_NAME_LENGTH = 60

const UnifiedReportingHeader: React.FC<UnifiedReportingHeaderLogicProps> = ({
  downLoadButtonConfig,
  isScheduledReportsDrawerOpen,
  reportingOptionsButtonConfig,
  reportTitle,
  reportDialogConfig,
  scheduledReportsDrawerConfig,
  toggleDrawer
}) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const {
    downloadOptions,
    downLoadAnchorEl,
    handleDownLoadButtonClick,
    handleDownLoadMenuItemClick,
    handleDownLoadMenuClose
  } = downLoadButtonConfig

  const {
    handleReportingOptionsButtonClick,
    handleReportingOptionsMenuClose,
    reportingOptions,
    reportingOptionsAnchorEl
  } = reportingOptionsButtonConfig

  return useMemo(
    () => (
      <Card className={classes.container}>
        <ScheduledReportsDrawer
          isScheduleDialogOpen={isScheduledReportsDrawerOpen}
          toggleScheduleDialog={toggleDrawer}
          scheduledReportsDrawerConfig={scheduledReportsDrawerConfig}
        />
        <MenuVertical className={classes.buttons} onClick={handleReportingOptionsButtonClick} />
        <Menu
          getContentAnchorEl={null}
          anchorEl={reportingOptionsAnchorEl}
          keepMounted
          open={Boolean(reportingOptionsAnchorEl)}
          onClose={handleReportingOptionsMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          {reportingOptions}
        </Menu>
        <Button
          className={classes.buttons}
          size="medium"
          variant="contained"
          color="primary"
          onClick={reportDialogConfig.toggleSaveReportDialog}
        >
          {formatMessage(`buttons.${reportDialogConfig.isUpdate ? 'save' : 'save_as'}`)}
        </Button>
        <Button
          className={classes.buttons}
          size="medium"
          variant="text"
          color="primary"
          endIcon={<DropDownArrow />}
          onClick={handleDownLoadButtonClick}
        >
          {formatMessage('buttons.export_as')}
        </Button>
        <Menu
          getContentAnchorEl={null}
          anchorEl={downLoadAnchorEl}
          keepMounted
          open={Boolean(downLoadAnchorEl)}
          onClose={handleDownLoadMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          {downloadOptions.map((option: string) => (
            <MenuItem key={option} value={option} onClick={() => handleDownLoadMenuItemClick(option)}>
              {option}
            </MenuItem>
          ))}
        </Menu>
        <Tooltip title={reportTitle.length > MAX_REPORT_NAME_LENGTH ? reportTitle : ''} placement="bottom" interactive>
          <Typography className={classes.titleLabel} variant="h4">
            {reportTitle.length > MAX_REPORT_NAME_LENGTH
              ? `${reportTitle.slice(0, MAX_REPORT_NAME_LENGTH)}...`
              : reportTitle}
          </Typography>
        </Tooltip>
        {reportDialogConfig.isRenameReportDialogOpen && (
          <RenameReportDialog
            open={reportDialogConfig.isRenameReportDialogOpen}
            onClose={reportDialogConfig.toggleRenameReportDialog}
            onSave={reportDialogConfig.onSave}
            onUpdate={reportDialogConfig.onUpdate}
            reportName={reportDialogConfig.reportName}
          />
        )}
        {reportDialogConfig.isSaveReportDialogOpen && (
          <SaveReportDialog
            dataRetentionWarning={reportDialogConfig.dataRetentionWarning}
            dateRangeConfig={reportDialogConfig.dateRangeConfig}
            open={reportDialogConfig.isSaveReportDialogOpen}
            onClose={reportDialogConfig.toggleSaveReportDialog}
            onSave={reportDialogConfig.onSave}
            onUpdate={reportDialogConfig.onUpdate}
            reportName={reportDialogConfig.reportName}
            shouldDisableSaveButton={reportDialogConfig.shouldDisableSaveButton}
          />
        )}
      </Card>
    ),
    [
      classes.buttons,
      classes.container,
      classes.titleLabel,
      downLoadAnchorEl,
      downloadOptions,
      formatMessage,
      handleDownLoadButtonClick,
      handleDownLoadMenuClose,
      handleDownLoadMenuItemClick,
      handleReportingOptionsButtonClick,
      handleReportingOptionsMenuClose,
      isScheduledReportsDrawerOpen,
      reportDialogConfig.dataRetentionWarning,
      reportDialogConfig.dateRangeConfig,
      reportDialogConfig.isRenameReportDialogOpen,
      reportDialogConfig.isSaveReportDialogOpen,
      reportDialogConfig.isUpdate,
      reportDialogConfig.onSave,
      reportDialogConfig.onUpdate,
      reportDialogConfig.reportName,
      reportDialogConfig.shouldDisableSaveButton,
      reportDialogConfig.toggleRenameReportDialog,
      reportDialogConfig.toggleSaveReportDialog,
      reportTitle,
      reportingOptions,
      reportingOptionsAnchorEl,
      scheduledReportsDrawerConfig,
      toggleDrawer
    ]
  )
}

export default UnifiedReportingHeader
