import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient from 'global/lib/api/restClient'
import { Email } from 'global/types/api/emailsType'

import apiRoutes from 'fir/lib/api/apiRoutes'

export interface GetEmail {
  emailId: string
  accessTokenId?: string
}

export const getEmailInfo = createAsyncThunk('EMAILS/getEmailInfo', async function doGetEmailInfo(
  payload: GetEmail,
  { rejectWithValue }
) {
  const { emailId, accessTokenId = null } = payload

  try {
    const resp = await restClient(apiRoutes.EMAIL_INFORMATION, {
      data: { emailId, accessTokenId }
    })

    return resp.data?.emailInfo as Email
  } catch (e) {
    return rejectWithValue(emailId)
  }
})
