import { useMemo, useState, useCallback } from 'react'

export type SelectedTab = number | undefined
export type OnTabSelected = (_e: any, newTab: number) => void

export interface BaseTabsConfig {
  selectedTab: SelectedTab
  onTabSelected: OnTabSelected
}

export default function useTabs(initialTab?: number): [SelectedTab, OnTabSelected] {
  const [selectedTab, setSelectedTab] = useState<SelectedTab>(initialTab)

  const onTabSelected = useCallback((_e, newTab) => {
    setSelectedTab(newTab)
  }, [])

  return useMemo(() => {
    return [selectedTab, onTabSelected]
  }, [selectedTab, onTabSelected])
}
