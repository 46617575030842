import React, { useCallback, useMemo, useState } from 'react'
import { ChartPeriod, ChartConfig } from 'global/components/lib/chartsV2/ChartConfig'

export interface ChartWrapperLogic {
  chartPeriodAnchorEl: null | HTMLElement
  chartPeriodOptions: string[]
  handleBarLineChartTypeSwitch: () => void
  handleChartPeriodOptionsButtonClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  handleChartPeriodOptionsMenuClose: () => void
  handleChartPeriodOptionsMenuItemClick: (option: string) => void
  handleStackedGroupedChartTypeSwitch: () => void
}

const chartPeriodOptions = [ChartPeriod.hourly, ChartPeriod.daily, ChartPeriod.weekly, ChartPeriod.monthly]

export default function useChartWrapperLogic({
  setShowBarChart,
  setShowStackedBarChart,
  updateChartConfig
}: ChartConfig): [ChartWrapperLogic] {
  const [chartPeriodAnchorEl, setChartPeriodAnchorEl] = useState<null | HTMLElement>(null)
  // TODO update the initial value with the default date time selection range  from the table

  const handleChartPeriodOptionsButtonClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setChartPeriodAnchorEl(event.currentTarget)
  }, [])

  const handleChartPeriodOptionsMenuItemClick = useCallback(
    (option: string) => {
      setChartPeriodAnchorEl(null)
      updateChartConfig('chartPeriod', option)
    },
    [updateChartConfig]
  )

  const handleChartPeriodOptionsMenuClose = useCallback(() => {
    setChartPeriodAnchorEl(null)
  }, [])

  const handleBarLineChartTypeSwitch = useCallback(() => {
    setShowBarChart()
  }, [setShowBarChart])

  const handleStackedGroupedChartTypeSwitch = useCallback(() => {
    setShowStackedBarChart()
  }, [setShowStackedBarChart])

  return useMemo(
    () => [
      {
        chartPeriodAnchorEl,
        chartPeriodOptions,
        handleBarLineChartTypeSwitch,
        handleChartPeriodOptionsButtonClick,
        handleChartPeriodOptionsMenuClose,
        handleChartPeriodOptionsMenuItemClick,
        handleStackedGroupedChartTypeSwitch
      }
    ],
    [
      chartPeriodAnchorEl,
      handleBarLineChartTypeSwitch,
      handleChartPeriodOptionsButtonClick,
      handleChartPeriodOptionsMenuClose,
      handleChartPeriodOptionsMenuItemClick,
      handleStackedGroupedChartTypeSwitch
    ]
  )
}
