import React from 'react'
import PropTypes from 'prop-types'

import AreaChart from '@barracuda-internal/bds-core/dist/AreaChart/AreaChart'
import ChartWrapper from '@barracuda-internal/bds-core/dist/ChartWrapper/ChartWrapper'

export interface CreatedIncidentsDashboardProps {
  chartConfig: any
  chartWrapperConfig: any
}

const CreatedIncidentsDashboard: React.FC<CreatedIncidentsDashboardProps> = ({ chartConfig, chartWrapperConfig }) => {
  return (
    <ChartWrapper chartWrapper={{ ...chartWrapperConfig }}>
      <AreaChart areaChart={{ ...chartConfig }} />
    </ChartWrapper>
  )
}

CreatedIncidentsDashboard.propTypes = {
  chartConfig: PropTypes.any.isRequired,
  chartWrapperConfig: PropTypes.any.isRequired
}

export default CreatedIncidentsDashboard
