import { createAsyncThunk } from '@reduxjs/toolkit'
import { ChartPeriod } from 'global/components/lib/chartsV2/ChartConfig'

import { globalApiRoutes } from 'global/lib/api/apiRoutes'
import restClient, { ApiRejectResponse, ResponseGenerator, validateApiError } from 'global/lib/api/restClient'
import {
  CreateUpdateUnifiedReportingReportApiConfig,
  UnifiedReportingFileDownloadConfig,
  UnifiedReportingReportData,
  UnifiedReportingReports,
  UnifiedReportingReport
} from 'global/types/api/unifiedReporting'

export const getUnifiedReportingReports = createAsyncThunk<
  UnifiedReportingReports,
  { product: string },
  ApiRejectResponse
>('UNIFIED_REPORTING/getUnifiedReportingReports', async function doGetUnifiedReportingReports(
  payload,
  { rejectWithValue }
) {
  try {
    const resp: ResponseGenerator = await restClient(globalApiRoutes.GET_UNIFIED_REPORTING_REPORTS, {
      data: { ...payload }
    })

    return {
      pinnedReportUuid: resp.data.reports.pinnedReportUuid,
      reports: resp.data.reports.reports
    }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

// This is not currently used, but is here for reference
export const getUnifiedReportingReport = createAsyncThunk<UnifiedReportingReport, { uuid: string }, ApiRejectResponse>(
  'UNIFIED_REPORTING/getUnifiedReportingReport',
  async function doGetUnifiedReportingReport(payload, { rejectWithValue }) {
    try {
      const resp: ResponseGenerator = await restClient(globalApiRoutes.GET_UNIFIED_REPORTING_REPORT, {
        data: { ...payload }
      })

      return resp.data.report
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getUnifiedReportingReportData = createAsyncThunk<
  UnifiedReportingReportData,
  {
    absoluteDateRangeEnd?: string | null
    absoluteDateRangeStart?: string | null
    chartPeriod: ChartPeriod
    chartType: string
    filters: any[]
    groupBy: any[]
    hiddenColumns: string[]
    page?: number
    relativeDateRange?: string | null
    searchQuery?: string | null
    size?: number
    sortBy: any[]
    topDisplayCount?: number
    uuid: string
    zoneId?: string
  },
  ApiRejectResponse
>('UNIFIED_REPORTING/getUnifiedReportingReportData', async function doGetUnifiedReportingReportData(
  payload,
  { rejectWithValue }
) {
  try {
    const resp: ResponseGenerator = await restClient(globalApiRoutes.GET_UNIFIED_REPORTING_REPORT_DATA, {
      data: { ...payload }
    })

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

// The result of this thunk is not currently used, but is here for reference
export const createUnifiedReportingReport = createAsyncThunk<
  UnifiedReportingReport,
  CreateUpdateUnifiedReportingReportApiConfig,
  ApiRejectResponse
>('UNIFIED_REPORTING/createUnifiedReportingReport', async function doCreateUnifiedReportingReport(
  payload,
  { rejectWithValue }
) {
  try {
    const resp: ResponseGenerator = await restClient(globalApiRoutes.CREATE_UNIFIED_REPORTING_REPORT, {
      data: { ...payload }
    })

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const updateUnifiedReportingReport = createAsyncThunk<
  UnifiedReportingReport,
  CreateUpdateUnifiedReportingReportApiConfig,
  ApiRejectResponse
>('UNIFIED_REPORTING/updateUnifiedReportingReport', async function doUpdateUnifiedReportingReport(
  payload,
  { rejectWithValue }
) {
  try {
    const resp: ResponseGenerator = await restClient(globalApiRoutes.UPDATE_UNIFIED_REPORTING_REPORT, {
      data: { ...payload }
    })

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const deleteUnifiedReportingReport = createAsyncThunk<unknown, { uuid: string }, ApiRejectResponse>(
  'UNIFIED_REPORTING/deleteUnifiedReportingReport',
  async function doDeleteUnifiedReportingReport(payload, { rejectWithValue }) {
    try {
      await restClient(globalApiRoutes.DELETE_UNIFIED_REPORTING_REPORT, {
        data: { ...payload }
      })

      return {}
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const pinUnifiedReportingReport = createAsyncThunk<
  string,
  { product?: string; reportUuid: string },
  ApiRejectResponse
>('UNIFIED_REPORTING/pinUnifiedReportingReport', async function doPinUnifiedReportingReport(
  payload,
  { rejectWithValue }
) {
  try {
    const resp = await restClient(globalApiRoutes.PIN_UNIFIED_REPORTING_REPORT, {
      data: { ...payload }
    })

    return resp.data.reportUuid
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getUnifiedReportingCSVReport = createAsyncThunk<
  { filename: string; data: any },
  UnifiedReportingFileDownloadConfig,
  ApiRejectResponse
>('UNIFIED_REPORTING/getUnifiedReportingCSVReport', async function doGetUnifiedReportingReportCSV(
  payload,
  { rejectWithValue }
) {
  try {
    const resp: ResponseGenerator = await restClient(globalApiRoutes.GET_UNIFIED_REPORTING_REPORT_CSV, {
      data: { ...payload }
    })

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getUnifiedReportingPDFReport = createAsyncThunk<
  { filename: string; data: any },
  UnifiedReportingFileDownloadConfig,
  ApiRejectResponse
>('UNIFIED_REPORTING/getUnifiedReportingPDFReport', async function doGetUnifiedReportingReportPDF(
  payload,
  { rejectWithValue }
) {
  try {
    const resp: ResponseGenerator = await restClient(globalApiRoutes.GET_UNIFIED_REPORTING_REPORT_PDF, {
      data: { ...payload }
    })

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})
