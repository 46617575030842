import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { validateApiError } from 'global/lib/api/restClient'

import apiRoutes from 'admin/lib/api/apiRoutes'

export interface GetIdentityStatsPayload {
  accessTokenId: string
}

export const getIdentityStats = createAsyncThunk('INSIGHTS/getIdentityStats', async function doGetIdentityStats(
  payload: GetIdentityStatsPayload,
  { rejectWithValue }
) {
  const { accessTokenId } = payload

  try {
    const resp = await restClient(apiRoutes.GET_IDENTITY_STATS, {
      data: { accessTokenId }
    })

    return resp.data.identity
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})
