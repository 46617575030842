import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { validateApiError } from 'global/lib/api/restClient'
import { buildReportQueryFor } from 'global/redux/features/dataTables/buildQueryForTable'
import { Incident, NotifyExternalEmails } from 'global/types/api/remediation'

import apiRoutes from 'sen/lib/api/apiRoutes'
import { RootState } from 'sen/redux/toolkit/store'

export interface GetIncidents {
  isTotalCheck?: boolean
  query?: {
    limit: number
    page: number
  }
}

export interface DeleteInboxRule {
  userPrincipalName: string
  ruleId: string
}

export interface GetRecipients {
  incidentId: Incident['id']
  distinctRecipient?: boolean
  wizardTable?: boolean
}

export interface SearchForIncidentEmails {
  senderEmail: string | undefined
  emailSubject?: string | undefined
  timeframe: number
  query: {
    limit: number
    page: number
  }
}

export interface GetIncidentEmailBody {
  senderEmail: string
  emailId: string
}

export interface CreateIncident {
  sender: {
    displayName: string
    email: string
    title: string
    firstName: string
    lastName: string
    id: string
  }
  emailSubject: string
  timeframe: number
  createdBy: string
}

export interface IncidentTaskStatus {
  incidentId: Incident['id']
  taskName: string
}

export type GetInboxRules = string

export const DEFAULT_QUERY_FOR_COUNT = { limit: 1, page: 1 }

export const getInboxRules = createAsyncThunk('REMEDIATION/getInboxRules', async function doGetInboxRules(
  payload: GetInboxRules,
  { rejectWithValue, getState }
) {
  try {
    const accessTokenId = (getState() as RootState).accessToken.accessToken?.id

    const resp = await restClient(apiRoutes.ACCOUNT_COMPROMISE_GET_INBOX_RULES, {
      data: {
        userPrincipalName: payload
      }
    })

    return { accessTokenId, report: resp.data?.rules }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const deleteInboxRule = createAsyncThunk('REMEDIATION/deleteInboxRule', async function doDeleteInboxRule(
  payload: DeleteInboxRule,
  { rejectWithValue, getState }
) {
  try {
    const accessTokenId = (getState() as RootState).accessToken.accessToken?.id

    const resp = await restClient(apiRoutes.ACCOUNT_COMPROMISE_DELETE_INBOX_RULE, {
      data: {
        userPrincipalName: payload.userPrincipalName,
        ruleId: payload.ruleId
      }
    })
    return { accessTokenId, report: resp.data?.feed }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getInboxRuleFeed = createAsyncThunk('REMEDIATION/getInboxRuleFeed', async function doGetInboxRuleFeed(
  _,
  { rejectWithValue, getState }
) {
  try {
    const nextPageKey = (getState() as RootState).remediation.inboxRuleFeedNextKey

    const resp = await restClient(apiRoutes.ACCOUNT_COMPROMISE_GET_INBOX_RULE_FEED, {
      data: { nextPageKey }
    })

    return resp.data?.feed
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const createIncident = createAsyncThunk('REMEDIATION/createIncident', async function doCreateIncident(
  payload: CreateIncident,
  { rejectWithValue }
) {
  try {
    const resp = await restClient(apiRoutes.ACCOUNT_COMPROMISE_CREATE_INCIDENT, {
      data: {
        sender: payload.sender,
        emailSubject: payload.emailSubject,
        timeframe: payload.timeframe,
        createdBy: payload.createdBy
      }
    })

    return resp.data?.incident
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getIncidentsCount = createAsyncThunk('REMEDIATION/getIncidentsCount', async function doGetIncidentsCount(
  _,
  { rejectWithValue }
) {
  try {
    const resp = await restClient(apiRoutes.ACCOUNT_COMPROMISE_INCIDENTS, {
      data: { query: DEFAULT_QUERY_FOR_COUNT }
    })

    return resp.data?.incidents?.totalCount
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getIncidents = createAsyncThunk('REMEDIATION/getIncidents', async function doGetIncidents(
  payload: GetIncidents | undefined,
  { rejectWithValue, getState }
) {
  const query = buildReportQueryFor((getState() as RootState).dataTables.incidents)
  const accessTokenId = (getState() as RootState).accessToken.accessToken?.id

  try {
    const resp = await restClient(apiRoutes.ACCOUNT_COMPROMISE_INCIDENTS, {
      data: { query: payload?.query || query }
    })

    return { report: { accessTokenId, report: resp.data?.incidents }, offset: query.offset }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getIncidentDetails = createAsyncThunk(
  'REMEDIATION/getIncidentDetails',
  async function doGetIncidentDetails(payload: Incident['id'], { rejectWithValue }) {
    try {
      const resp = await restClient(apiRoutes.ACCOUNT_COMPROMISE_INCIDENT_DETAILS, {
        data: { incidentId: payload }
      })

      return resp.data?.incident
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getRecipients = createAsyncThunk('REMEDIATION/getRecipients', async function doGetRecipients(
  payload: GetRecipients,
  { rejectWithValue, getState }
) {
  try {
    const defaultQuery = buildReportQueryFor((getState() as RootState).dataTables.incident.recipients)
    const wizardQuery = buildReportQueryFor((getState() as RootState).dataTables.incident.recipientsWizard)

    const query = payload.wizardTable ? wizardQuery : defaultQuery
    const accessTokenId = (getState() as RootState).accessToken.accessToken?.id

    const resp = await restClient(apiRoutes.ACCOUNT_COMPROMISE_GET_RECIPIENTS, {
      data: {
        incidentId: payload.incidentId,
        distinctRecipient: payload.distinctRecipient || false,
        query: {
          ...query,
          filter: [`isInternal=${!payload.distinctRecipient}`]
        }
      }
    })

    return { report: { accessTokenId, report: resp.data?.recipients }, offset: query.offset }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const searchForIncidentEmails = createAsyncThunk(
  'REMEDIATION/searchForIncidentEmails',
  async function doSearchForIncidentEmails(payload: SearchForIncidentEmails, { rejectWithValue, getState }) {
    try {
      const query = buildReportQueryFor((getState() as RootState).dataTables.incidentsEmails)
      const accessTokenId = (getState() as RootState).accessToken.accessToken?.id

      const resp = await restClient(apiRoutes.ACCOUNT_COMPROMISE_SEARCH_EMAILS, {
        data: {
          senderEmail: payload.senderEmail,
          emailSubject: payload.emailSubject,
          timeframe: payload.timeframe,
          query: payload.query
        }
      })

      return { report: { accessTokenId, report: resp.data?.incidents }, offset: query.offset }
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getIncidentEmailBody = createAsyncThunk(
  'REMEDIATION/getIncidentEmailBody',
  async function doGetIncidentEmailBody(payload: GetIncidentEmailBody, { rejectWithValue }) {
    try {
      const resp = await restClient(apiRoutes.ACCOUNT_COMPROMISE_GET_EMAIL_BODY, {
        data: { senderEmail: payload.senderEmail, emailId: payload.emailId }
      })

      return resp
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const quarantineEmails = createAsyncThunk('REMEDIATION/quarantineEmails', async function doQuarantineEmails(
  payload: Incident['id'],
  { rejectWithValue }
) {
  try {
    const resp = await restClient(apiRoutes.ACCOUNT_COMPROMISE_QUARANTINE_EMAILS, {
      data: { incidentId: payload }
    })

    return resp.data?.recipients
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const deleteEmails = createAsyncThunk('REMEDIATION/deleteEmails', async function doDeleteEmails(
  payload: Incident['id'],
  { rejectWithValue }
) {
  try {
    const resp = await restClient(apiRoutes.ACCOUNT_COMPROMISE_DELETE_EMAILS, {
      data: { incidentId: payload }
    })

    return resp.data?.recipients
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const notifyExternalEmails = createAsyncThunk(
  'REMEDIATION/notifyExternalEmails',
  async function doNotifyExternalEmails(payload: NotifyExternalEmails, { rejectWithValue }) {
    try {
      const resp = await restClient(apiRoutes.ACCOUNT_COMPROMISE_NOTIFY_EXTERNAL_EMAILS, {
        data: {
          incidentId: payload.incidentId,
          notificationSender: payload.notificationSender,
          notificationBody: payload.notificationBody,
          notificationSubject: payload.notificationSubject
        }
      })
      return resp
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getIncidentTaskStatus = createAsyncThunk(
  'REMEDIATION/getIncidentTaskStatus',
  async function doGetIncidentTaskStatus(payload: IncidentTaskStatus, { rejectWithValue }) {
    try {
      const resp = await restClient(apiRoutes.ACCOUNT_COMPROMISE_INCIDENT_TASK_STATUS, {
        data: {
          incidentId: payload.incidentId,
          taskName: payload.taskName
        }
      })
      return resp
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)
