import { FONT as font } from 'global/configs/theme/defaults'

export default {
  root: {
    fontSize: font.size.medium,
    '@media (min-width: 600px)': {
      minHeight: 48
    },
    width: 256
  }
}
