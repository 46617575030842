import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(6)
  },
  content: {
    paddingTop: theme.spacing(2),
    fontWeight: theme.font.weight.regular
  },
  image: {
    width: '200px'
  }
}))
