import React from 'react'

import { CircularProgress, CircularProgressProps } from '@barracuda-internal/bds-core'

import { withStyles } from '@material-ui/core/styles'

const StyledLinearProgress = withStyles({
  colorPrimary: {
    color: 'black'
  }
})(CircularProgress)

const CircularProgressComponent: React.FC<CircularProgressProps> = props => {
  return <StyledLinearProgress size={24} {...props} />
}

export default CircularProgressComponent
