import React, { useEffect, useMemo } from 'react'

import { useAppSelector, useAppDispatch } from 'global/redux/toolkit/hooks'
import Loading from 'global/components/lib/loading/Loading'
import { getUser } from 'global/redux/features/auth/authSlice'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { isSuccess, isFailed } from 'global/redux/toolkit/api'

export default function validateSession<P extends {}>(
  WrappedComponent: React.ComponentType<P>,
  invalidateSession: () => void
) {
  const ValidateSession: React.FC<any> = props => {
    const { auth, hasUser } = useAppSelector((_stores: any) => ({
      auth: _stores.auth,
      hasUser: !!_stores.user.data.id
    }))
    const dispatch = useAppDispatch()

    // init
    useEffect(() => {
      if (!hasUser) {
        dispatch(getUser())
      }
    }, [dispatch, hasUser])

    const isAuthenticated: boolean = useMemo(() => {
      if (!hasUser) {
        if (isSuccess(auth.getUserApiStatus) || isFailed(auth.getUserApiStatus)) {
          // user is NOT logged in but the report is being opened from email
          if (window.location.toString().includes('emailscancomplete')) {
            analyticsLib.trackAppEvent(analyticsLib.EVENTS.REPORT_OPENED_IN_EMAIL, {
              url: window.location.href
            })
          }
          invalidateSession()
        }

        return false
      }

      return true
    }, [auth, hasUser])

    return useMemo(() => {
      if (!isAuthenticated) {
        return <Loading />
      }

      return <WrappedComponent {...props} />
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated])
  }

  return ValidateSession
}
