import React, { useMemo } from 'react'

import { Tabs, Tab, Grid } from '@barracuda-internal/bds-core'

import useDashboardMenuLogic, {
  DashboardMenuLogicProps
} from 'ets/components/pages/dashboard/menu/useDashboardMenuLogic'
import styles from 'ets/components/pages/dashboard/menu/dashboardMenuStyles'
import { useFormatMessage } from 'global/lib/localization'

const BASE_I18N_KEY = 'ets.app.dashboard.menu'

export const DashboardMenu: React.FC<DashboardMenuLogicProps> = props => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const [dashboardMenuLogic] = useDashboardMenuLogic(props)

  return useMemo(() => {
    const { tabs, activeTab, onTabChange } = dashboardMenuLogic

    return (
      <Grid
        container
        style={{ position: '-webkit-sticky' }}
        justifyContent="center"
        className={classes.root}
        data-menu
        data-field="grid"
      >
        <Tabs
          className={classes.tabs}
          value={activeTab === -1 ? false : activeTab}
          onChange={onTabChange}
          classes={{ indicator: classes.indicator }}
          textColor="primary"
          centered
          data-field="tabs"
        >
          {tabs.map((tab: string) => (
            <Tab key={tab} className={classes.tab} label={formatMessage(`tabs.${tab}`)} data-field="tab" />
          ))}
        </Tabs>
      </Grid>
    )
  }, [dashboardMenuLogic, classes, formatMessage])
}

export default DashboardMenu
