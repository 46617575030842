import { combineReducers, AnyAction } from 'redux'
import { splitReducer } from '@splitsoftware/splitio-redux'

import { RootState } from 'ets/redux/toolkit/store'

// global slices
import accessToken, {
  INITIAL_STATE as accessTokenInitialState
} from 'global/redux/features/accessToken/accessTokenSlice'
import account from 'global/redux/features/account/accountSlice'
import admin, { INITIAL_STATE as adminInitialState } from 'global/redux/features/admin/adminSlice'
import analytics, { INITIAL_STATE as analyticsInitialState } from 'global/redux/features/analytics/analyticsSlice'
import app, { INITIAL_STATE as appInitialState } from 'global/redux/features/app/appSlice'
import attack, { INITIAL_STATE as attackInitialState } from 'global/redux/features/attack/attackSlice'
import auth, { logout } from 'global/redux/features/auth/authSlice'
import report, { INITIAL_STATE as reportInitialState } from 'global/redux/features/report/reportSlice'
import scan, { INITIAL_STATE as scanInitialState } from 'global/redux/features/scan/scanSlice'
import contest, { INITIAL_STATE as contestInitialState } from 'global/redux/features/contest/contestSlice'

// ets slices
import 'ets/redux/features/auth/authSlice'
import cloud, { INITIAL_STATE as cloudInitialState } from 'ets/redux/features/cloud/cloudSlice'
import dataTables, { INITIAL_STATE as dataTablesInitialState } from 'ets/redux/features/dataTables/dataTablesSlice'
import reports, { INITIAL_STATE as reportsInitialState } from 'ets/redux/features/reports/reportsSlice'
import user, { INITIAL_STATE as userInitialState } from 'ets/redux/features/user/userSlice'
import settings, { INITIAL_STATE as settingsIntialState } from 'ets/redux/features/settings/settingsSlice'

export const reducers = {
  cloud,
  dataTables,
  reports,
  user,
  settings,
  splitio: splitReducer
}

const combinedReducers = combineReducers({
  accessToken,
  account,
  admin,
  analytics,
  app,
  attack,
  auth,
  cloud,
  dataTables,
  report,
  reports,
  scan,
  settings,
  user,
  contest,
  splitio: splitReducer
})

export default function(state: RootState, action: AnyAction) {
  let appState = { ...state }

  if (logout.pending.match(action) && appState.accessToken?.accessToken?.id) {
    appState.auth = {
      ...appState.auth,
      logoutValues: {
        accessTokenId: appState.accessToken?.accessToken?.id
      }
    }
  }

  if (logout.fulfilled.match(action)) {
    // reset stores on logout
    appState = {
      ...appState,
      splitio: {
        ...appState.splitio,
        treatments: {}
      },
      app: {
        ...appInitialState,
        metadata: appState.app.metadata
      },
      auth: {
        ...appState.auth
      },
      accessToken: accessTokenInitialState,
      analytics: analyticsInitialState,
      attack: attackInitialState,
      cloud: cloudInitialState,
      dataTables: dataTablesInitialState,
      report: reportInitialState,
      reports: reportsInitialState,
      scan: scanInitialState,
      user: userInitialState,
      settings: settingsIntialState,
      admin: adminInitialState,
      contest: contestInitialState
    }
  }

  return combinedReducers(appState, action)
}
