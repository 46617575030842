import { createSlice } from '@reduxjs/toolkit'

import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'global/redux/toolkit/api'

import { cleanupAllThreats } from 'sen/redux/features/cleanup/cleanupApiThunks'

export interface CleanupState {
  cleanupAllThreatsApiStatus: ApiStatus
}

// initialState
export const INITIAL_STATE: CleanupState = {
  cleanupAllThreatsApiStatus: inIdle
}

/* eslint-disable no-param-reassign */
export const cleanupSlice = createSlice({
  name: 'CLEANUP',
  initialState: INITIAL_STATE,
  reducers: {
    // resetCleanup
    resetCleanupAllThreats: state => {
      state.cleanupAllThreatsApiStatus = INITIAL_STATE.cleanupAllThreatsApiStatus
    },

    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(cleanupAllThreats.pending, state => {
        state.cleanupAllThreatsApiStatus = inProgress
      })
      .addCase(cleanupAllThreats.fulfilled, state => {
        state.cleanupAllThreatsApiStatus = successResponse
      })
      .addCase(cleanupAllThreats.rejected, (state, action) => {
        state.cleanupAllThreatsApiStatus = failedResponse(action.payload as string)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { resetCleanupAllThreats, reset } = cleanupSlice.actions

export { cleanupAllThreats }

export default cleanupSlice.reducer
