import { makeStyles } from '@material-ui/core/styles'
import { COLORS } from 'ets/configs/styles/defaults'

export default makeStyles(() => ({
  root: {
    height: '100%',
    overflow: 'auto',
    padding: 8,
    '@media screen and (min-width:960px)': {
      padding: 16
    }
  },
  card: {
    position: 'relative',
    margin: 8,
    padding: 26
  },
  card2: {
    position: 'relative',
    margin: 8,
    '@media screen and (max-width:960px)': {
      marginTop: 20
    },
    padding: 26
  },
  logoWrapper: {
    textAlign: 'center'
  },
  barracudaLogo: {
    height: 40,
    marginBottom: 16
  },
  headingTitle: {
    fontWeight: 700,
    fontSize: 14,
    marginBottom: 16,
    color: 'rgba(0, 0, 0, 0.87)',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  headingSubtitle: {
    color: 'rgba(0, 0, 0, 0.87)',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    fontSize: 14
  },
  title: {
    fontSize: 20,
    marginBottom: 20
  },
  cardNumbering: {
    color: 'rgba(0, 0, 0, 0.87)',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  emailInput: {
    marginTop: 16,
    marginBottom: 46
  },
  linkRow: {
    height: 94,
    alignItems: 'flex-start'
  },
  linkInput: {
    marginTop: 18
  },
  copyToClipboard: {
    marginTop: 6,
    textAlign: 'right'
  },
  footer: {
    color: 'rgba(0, 0, 0, 0.87)',
    marginTop: 20,
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  link: {
    color: COLORS.GRAY,
    textDecoration: 'underline',
    cursor: 'pointer',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  }
}))
