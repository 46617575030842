import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import {
  Link,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Zoom
} from '@barracuda-internal/bds-core'

import { getAnalystsEmail } from 'global/lib/configuration/configuration'
import { config } from 'global/lib/config'

import DEFAULTS from 'global/configs/theme/defaults'
import styles from 'sen/components/lib/dialogs/reportMissedAttack/reportMissedAttackDialogStyles'
import { useFormatMessage } from 'global/lib/localization'

export interface ReportMissedAttackDialogProps {
  onClose: () => void
}

const BASE_I18N_KEY = 'sen.app.dashboard.header.report_missed_attack_dialog'

export const ReportMissedAttackDialog: React.FC<ReportMissedAttackDialogProps> = ({ onClose }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  return useMemo(
    () => (
      <Dialog style={{ zIndex: DEFAULTS.DIALOGS_ZINDEX }} maxWidth="md" fullWidth open TransitionComponent={Zoom}>
        <DialogTitle>
          <Typography className={classes.dialogTitle}>{formatMessage('title')}</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography>
            {formatMessage('content', {
              email: () => (
                <Link key="emailLink" href={`mailto:${getAnalystsEmail()}`}>
                  {getAnalystsEmail()}
                </Link>
              ),
              instructions: (txt: string) => (
                <Link key="instructions" target="_blank" href={config.LINKS.SENTINEL_REPORT_MISSED_ATTACK}>
                  {txt}
                </Link>
              )
            })}
          </Typography>
          <Typography className={classes.subContent}>{formatMessage('sub_content')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" size="large" onClick={onClose}>
            &nbsp;&nbsp;{formatMessage('button')}&nbsp;&nbsp;
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [classes, formatMessage, onClose]
  )
}

ReportMissedAttackDialog.propTypes = {
  onClose: PropTypes.func.isRequired
}

export default ReportMissedAttackDialog
