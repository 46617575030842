import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import {
  Typography,
  Button,
  DataTable,
  DataTableColumn,
  Paper,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Zoom
} from '@barracuda-internal/bds-core'
import { Checkmark as CheckmarkIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'

import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'

import CircularProgress from 'global/components/lib/circularProgress/CircularProgress'
import ExportToCsvButton from 'global/components/lib/exportToCsvButton/ExportToCsvButton'
import SearchField from 'global/components/lib/searchField/SearchField'
import DEFAULTS from 'global/configs/theme/defaults'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import Pager from 'global/components/lib/grid/pager/Pager'
import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'
import highlightText from 'global/lib/highlightText/highlightText'
import { useFormatMessage } from 'global/lib/localization'

import styles from 'sen/components/lib/dialogs/mailboxes/mailboxesDialogStyles'
import useMailboxesDialogLogic from 'sen/components/lib/dialogs/mailboxes/useMailboxesDialogLogic'

export interface MailboxesDialogProps {
  onClose: () => void
}

const BASE_I18N_KEY = 'sen.app.mailboxes_dialog'
const BASE_I18N_TABLE_KEY = 'sen.app.data_tables.mailboxes'

export const MailboxDialog: React.FC<MailboxesDialogProps> = ({ onClose }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatMessageTable = useFormatMessage(BASE_I18N_TABLE_KEY)
  const classes = styles()
  const [license, searchFieldConfig, inProgress, tableConfig, exportToCsvConfig] = useMailboxesDialogLogic()

  return useMemo(
    () => (
      <Dialog style={{ zIndex: DEFAULTS.DIALOGS_ZINDEX }} maxWidth="lg" fullWidth open TransitionComponent={Zoom}>
        {license.enabled && (
          <DialogContent className={classes.dialogContent}>
            <Paper className={license.showLimitError ? classes.errorLicenseBox : classes.licenseBox}>
              <Typography className={classes.licenseText}>{formatMessage('license_title')}</Typography>
              {!tableConfig.isLoaded && <CircularProgress className={classes.loadingCount} />}
              <Typography className={license.showLimitError ? classes.errorCountText : classes.countText}>
                {tableConfig.isLoaded && license.used}
              </Typography>
              <Typography className={classes.purchasedText}>
                <data className={classes.purchasedCountText}>{license.purchased || 0}</data>&nbsp;
                {formatMessage('license_purchased')}
              </Typography>
            </Paper>
          </DialogContent>
        )}

        <DialogTitle>
          <Typography className={classes.dialogTitle}>{formatMessage('title')}</Typography>
        </DialogTitle>

        <DialogContent>
          {!tableConfig.isLoaded && (
            <Grid container justifyContent="center" className={classes.loading}>
              <CircularProgress />
            </Grid>
          )}

          {tableConfig.isLoaded && (
            <>
              <ExportToCsvButton className={classes.exportButton} {...exportToCsvConfig} />
              <Grid container direction="column">
                <Grid className={classes.searchField} item xs={12}>
                  <SearchField {...searchFieldConfig} placeholder={formatMessage('search_field.placeholder')} />
                </Grid>

                <Grid className={classes.tableArea} data-table="mailboxes" item xs={12}>
                  {inProgress && <LinearProgress className={classes.indicator} />}

                  <DataTable
                    className={classes.dataTable}
                    data={tableConfig.tableData}
                    {...tableConfig.pageConfig}
                    {...tableConfig.sortConfig}
                    pager={Pager}
                  >
                    <DataTableNoRecords>
                      <Typography>{!tableConfig.tableData.total && formatMessageTable('empty_table')}</Typography>
                    </DataTableNoRecords>
                    <DataTableColumn
                      field={tableConfig.columns.DISPLAY_NAME}
                      {...tableConfig.columnsConfig[tableConfig.columns.DISPLAY_NAME as any]}
                      title={formatMessageTable(`${tableConfig.columns.DISPLAY_NAME}`)}
                      cell={({ dataItem }: { dataItem: any }) => (
                        <Cell className={classes.cell}>
                          <CellText ellipsis className={classes.boldCell}>
                            {highlightText(dataItem.displayName, tableConfig.highlightKeywords)}
                          </CellText>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      field={tableConfig.columns.EMAIL}
                      {...tableConfig.columnsConfig[tableConfig.columns.EMAIL as any]}
                      title={formatMessageTable(`${tableConfig.columns.EMAIL}`)}
                      cell={({ dataItem }: { dataItem: any }) => (
                        <Cell className={classes.cell}>
                          <CellText ellipsis className={classes.normalCell}>
                            {highlightText(dataItem.email, tableConfig.highlightKeywords)}
                          </CellText>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      field={tableConfig.columns.TITLE}
                      {...tableConfig.columnsConfig[tableConfig.columns.TITLE as any]}
                      title={formatMessageTable(`${tableConfig.columns.TITLE}`)}
                      cell={({ dataItem }: { dataItem: any }) => (
                        <Cell className={classes.cell}>
                          <CellText ellipsis className={classes.normalCell}>
                            {highlightText(dataItem.title, tableConfig.highlightKeywords)}
                          </CellText>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      field={tableConfig.columns.DEPARTMENT}
                      {...tableConfig.columnsConfig[tableConfig.columns.DEPARTMENT as any]}
                      title={formatMessageTable(`${tableConfig.columns.DEPARTMENT}`)}
                      cell={({ dataItem }: { dataItem: any }) => (
                        <Cell className={classes.cell}>
                          <CellText ellipsis className={classes.normalCell}>
                            {dataItem.department}
                          </CellText>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      width={100}
                      field={tableConfig.columns.LICENSED}
                      {...tableConfig.columnsConfig[tableConfig.columns.LICENSED as any]}
                      title={formatMessageTable(`${tableConfig.columns.LICENSED}`)}
                      cell={({ dataItem }: { dataItem: any }) => (
                        <Cell className={classes.cell}>
                          <CellText>{dataItem.hasMailbox && <CheckmarkIcon className={classes.icon} />}</CellText>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      width={100}
                      field={tableConfig.columns.PROTECTED}
                      {...tableConfig.columnsConfig[tableConfig.columns.PROTECTED as any]}
                      title={formatMessageTable(`${tableConfig.columns.PROTECTED}`)}
                      cell={({ dataItem }: { dataItem: any }) => (
                        <Cell className={classes.cell}>
                          <CellText>{dataItem.isMonitored && <CheckmarkIcon className={classes.icon} />}</CellText>
                        </Cell>
                      )}
                    />
                  </DataTable>
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button color="primary" variant="contained" size="large" onClick={onClose}>
            &nbsp;&nbsp;{formatMessage('button')}&nbsp;&nbsp;
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [
      classes,
      formatMessage,
      formatMessageTable,
      onClose,
      searchFieldConfig,
      inProgress,
      tableConfig,
      exportToCsvConfig,
      license
    ]
  )
}

MailboxDialog.propTypes = {
  onClose: PropTypes.func.isRequired
}

export default MailboxDialog
