import React, { useMemo } from 'react'

import { Typography, Paper } from '@barracuda-internal/bds-core'
import { Grid, GridColumn } from '@progress/kendo-react-grid'

import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import { useFormatMessage } from 'global/lib/localization'

import useThreatDetailsByIncidentLogic from 'fir/components/pages/remediation/incidentDetails/threatsByIncident/useThreatByIncidentLogic'
import styles, { threatGridStyle } from 'fir/components/pages/remediation/incidentDetails/IncidentDetailsStyles'

const BASE_I18N_KEY = 'fir.app.remediation.threats_by_incident'

const ThreatDetailsByIncident: React.FC = () => {
  const classes = styles()
  const gridClasses = threatGridStyle()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [threatByIncidentLogic] = useThreatDetailsByIncidentLogic()

  return useMemo(() => {
    const { isPageInProgress, gridData, columnsConfig, GRID_COLUMNS } = threatByIncidentLogic

    return (
      <>
        <Paper className={classes.wrapper} elevation={1}>
          <div className={classes.header}>
            <div>
              <Typography variant="h5">{formatMessage('labels.page_title')}</Typography>
              <Typography variant="subtitle1">{formatMessage('labels.page_subtitle')}</Typography>
            </div>
          </div>
          {isPageInProgress && <LinearProgress />}
          <Grid className={gridClasses.gridLayout} data={gridData}>
            <GridColumn
              field={GRID_COLUMNS.USER_EMAIL}
              {...columnsConfig[GRID_COLUMNS.USER_EMAIL]}
              title={formatMessage('threat_details_table.type')}
              cell={({ dataItem }) => (
                <Cell>
                  <CellText>{dataItem.type}</CellText>
                </Cell>
              )}
            />
            <GridColumn
              field={GRID_COLUMNS.CLICKED_LINKS}
              {...columnsConfig[GRID_COLUMNS.CLICKED_LINKS]}
              title={formatMessage('threat_details_table.threat')}
              cell={({ dataItem }) => (
                <Cell>
                  <CellText>{dataItem.path}</CellText>
                </Cell>
              )}
            />
            <GridColumn
              field={GRID_COLUMNS.OPENED}
              {...columnsConfig[GRID_COLUMNS.OPENED]}
              title={formatMessage('threat_details_table.category')}
              cell={({ dataItem }) => (
                <Cell>
                  <CellText>{dataItem.vector}</CellText>
                </Cell>
              )}
            />
            <GridColumn
              field={GRID_COLUMNS.REPLIED}
              {...columnsConfig[GRID_COLUMNS.REPLIED]}
              title={formatMessage('threat_details_table.extra')}
              cell={({ dataItem }) => (
                <Cell>
                  <CellText>{dataItem.extra}</CellText>
                </Cell>
              )}
            />
          </Grid>
        </Paper>
      </>
    )
  }, [classes, threatByIncidentLogic, formatMessage, gridClasses])
}

export default ThreatDetailsByIncident
