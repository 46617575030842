import userDataLib from 'global/lib/userData/userData'
import accessTokenLib from 'global/lib/accessToken/accessToken'

import * as analyticsLib from 'global/lib/analytics/analyticsService'

import { config } from 'global/lib/config'
import { AccessToken } from 'global/types/api/accessTokenType'
import { User } from 'global/types/api/userType'

declare global {
  interface Window {
    mixpanel?: Mixpanel
  }
}

export interface Flags {
  [key: string]: string
}

const isMixpanelDefined: boolean = !!window.mixpanel && config.mixpanelToken

/* eslint-disable @typescript-eslint/naming-convention */

// Question: if I set up user: User then issue with accessTokens
export function setUser(user: any, signUp: any, mixpanelDistinctId?: string) {
  if (isMixpanelDefined && user?.id) {
    const data: any = {
      id: user.id,
      bcc_user_id: user.bccUserId,
      $email: user.email,
      $name: user.displayName,
      state: user.state,
      zip_code: user.zip,
      $phone: user.phone
    }
    if (signUp) {
      data.$signed_up_from = signUp.signedUpFrom
      data.$signed_up_bcc_id = signUp.signedUpBccId
    }
    if (user.accounts) {
      const accessTokenId = accessTokenLib.getAccessTokenFromUrl()
      const account = accessTokenId ? userDataLib.getAccountByAccessToken(accessTokenId) : undefined

      data.url_access_token = accessTokenId
      data.product_account_id = account ? account.accountId : user.accounts[0].accountId
      data.bcc_account_id = account ? account.bccId : user.accounts[0].bccId

      data.bos_serial = accessTokenId ? analyticsLib.getSerialNumber(accessTokenId) : undefined
    }
    if (user.accounts && user.accounts.accessTokens) {
      const accessTokens: {
        id: string
        name: string
      }[] = user.accounts.accessTokens.map((accessToken: AccessToken) => ({
        id: accessToken.id,
        name: accessToken.name
      }))

      data.access_tokens = accessTokens
    }

    if (signUp && mixpanelDistinctId) {
      window.mixpanel.alias(user.id, mixpanelDistinctId)
    }

    window.mixpanel.identify(user.id)

    window.mixpanel.register({ $email: user.email })
    window.mixpanel.people.set(data)
  }
}

export function setUserFlags(user: User, flags: Flags) {
  if (isMixpanelDefined && user?.id) {
    const data: Flags = Object.keys(flags).reduce(
      (all: Flags, flagName: string) => {
        return {
          ...all,
          [`__${flagName}`]: flags[flagName]
        }
      },
      { $email: user.email }
    )

    window.mixpanel.register({ $email: user.email })
    window.mixpanel.people.set(data)
  }
}

export function updateAlias(userFlags: { mixpanel_distinct_id?: string }) {
  if (isMixpanelDefined && userFlags.mixpanel_distinct_id) {
    const distinctId = window.mixpanel.get_distinct_id()
    window.mixpanel.alias(userFlags.mixpanel_distinct_id as string, distinctId)
  }
}

/* eslint-enable @typescript-eslint/naming-convention */

export function trackEvent(eventName: string, metadata?: {}) {
  if (isMixpanelDefined) {
    window.mixpanel.track(userDataLib.isImpersonationMode() ? `[imp] ${eventName}` : eventName, metadata)
  }
}
