import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { validateApiError } from 'global/lib/api/restClient'
import { buildReportQueryFor } from 'global/redux/features/dataTables/buildQueryForTable'
import { Alert, AlertThreat, SigninByCountry } from 'global/types/api/ato'

import apiRoutes from 'sen/lib/api/apiRoutes'
import { RootState } from 'sen/redux/toolkit/store'

export interface GetAlertSummary {
  timeframe?: number
}

export type GetAlertDetails = AlertThreat['threatId']
export type GetAlert = Alert['id']
export type MarkAlertAsFp = Alert['id']
export type GetSigninsByAllCountries = number
export interface GetSigninsByCountry {
  timeframe: number
  country: SigninByCountry['country']
}

export interface GetUserRelatedSignins {
  startTime: string
  endTime: string
  userPrincipalName: string
}

export const DEFAULT_ALERT_SUMMARY_TIMEFRAME = 30
export const DEFAULT_QUERY = {
  limit: 10,
  page: 1
}

export const getAlerts = createAsyncThunk('ATO/getAlerts', async function doGetAlerts(
  _,
  { rejectWithValue, getState }
) {
  try {
    const query = buildReportQueryFor((getState() as RootState).dataTables.alerts)

    const resp = await restClient(apiRoutes.GET_ALERTS, {
      data: { query: { ...query } }
    })

    return { report: resp.data, offset: query.offset }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getAlertInboxRules = createAsyncThunk('ATO/getAlertInboxRules', async function doGetAlertInboxRules(
  payload: GetAlert,
  { rejectWithValue, getState }
) {
  try {
    const query = buildReportQueryFor((getState() as RootState).dataTables.alert.inboxRules)

    const resp = await restClient(apiRoutes.DRILLDOWN_INBOX_RULES, {
      data: { alertId: payload, query: { ...query } }
    })

    return { report: resp.data, offset: query.offset }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getAlertSignins = createAsyncThunk('ATO/getAlertSignins', async function doGetAlertSignins(
  payload: GetAlert,
  { rejectWithValue, getState }
) {
  try {
    const query = buildReportQueryFor((getState() as RootState).dataTables.alert.signins)

    const resp = await restClient(apiRoutes.DRILLDOWN_SIGNINS, {
      data: { alertId: payload, query: { ...query } }
    })

    return { report: resp.data, offset: query.offset }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getUserRelatedSignins = createAsyncThunk(
  'ATO/getUserRelatedSignins',
  async function doGetUserRelatedSignins(payload: GetUserRelatedSignins, { rejectWithValue, getState }) {
    try {
      const query = buildReportQueryFor((getState() as RootState).dataTables.signinsByUser)

      const resp = await restClient(apiRoutes.USER_RELATED_SIGNINS, {
        data: { ...payload, query: { ...query } }
      })

      return { report: resp.data, offset: query.offset }
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getSigninsByAllCountries = createAsyncThunk(
  'ATO/getSigninsByAllCountries',
  async function doGetSigninsByAllCountries(payload: GetSigninsByAllCountries, { rejectWithValue }) {
    try {
      const resp = await restClient(apiRoutes.SIGNINS_BY_ALL_COUNTRIES, {
        data: { timeframe: payload }
      })

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getSigninsByCountry = createAsyncThunk('ATO/getSigninsByCountry', async function doGetSigninsByCountry(
  payload: GetSigninsByCountry,
  { rejectWithValue, getState }
) {
  try {
    const query = buildReportQueryFor((getState() as RootState).dataTables.signinsByCountry)

    const resp = await restClient(apiRoutes.SIGNINS_BY_COUNTRY, {
      data: { timeframe: payload.timeframe, country: payload.country, query }
    })

    return { report: resp.data, offset: query.offset }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getAlertThreats = createAsyncThunk('ATO/getAlertThreats', async function doGetAlertThreats(
  payload: GetAlert,
  { rejectWithValue, getState }
) {
  try {
    const query = buildReportQueryFor((getState() as RootState).dataTables.alert.threats)

    const resp = await restClient(apiRoutes.DRILLDOWN_THREATS, {
      data: { alertId: payload, query: { ...query } }
    })

    return { report: resp.data, offset: query.offset }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getAlertSummary = createAsyncThunk('ATO/getAlertSummary', async function doGetAlertSummary(
  payload: GetAlertSummary | undefined,
  { rejectWithValue }
) {
  try {
    const resp = await restClient(apiRoutes.ALERT_SUMMARY, {
      data: { timeframe: payload?.timeframe || DEFAULT_ALERT_SUMMARY_TIMEFRAME }
    })

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getAlertDetails = createAsyncThunk('ATO/getAlertDetails', async function doGetAlertDetails(
  payload: GetAlertDetails,
  { rejectWithValue }
) {
  try {
    const resp = await restClient(apiRoutes.GET_ALERT_DETAILS, {
      data: { threatId: payload }
    })

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const closeAtoAlert = createAsyncThunk('ATO/closeAtoAlert', async function doCloseAtoAlert(
  payload: GetAlert,
  { rejectWithValue }
) {
  try {
    const resp = await restClient(apiRoutes.CLOSE_ALERT, {
      data: { alertId: payload }
    })

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const markAlertAsFp = createAsyncThunk('ATO/markAlertAsFp', async function doMarkAlertAsFp(
  payload: MarkAlertAsFp,
  { rejectWithValue }
) {
  try {
    const resp = await restClient(apiRoutes.ATO_MARK_AS_FP, {
      data: { alertId: payload }
    })

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})
