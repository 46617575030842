import React from 'react'

import DashboardContent from 'sen/components/pages/dashboard/content/DashboardContent'
import ScanInProgress from 'sen/components/pages/dashboard/scanInProgress/ScanInProgress'

import sentintelContentLoader from 'sen/components/lib/useSentinelContentCondition/SentinelContentLoader'

export default sentintelContentLoader({
  scanInProgress: (_, userEmail) => <ScanInProgress userEmail={userEmail} />,
  default: () => <DashboardContent data-field="dashboard-content" />
})
