import React from 'react'

import Layout from 'fir/components/lib/layout/Layout'

import useConvergedMessageLogLogic from './useConvergedMessageLogLogic'

const ConvergedMessageLog: React.FC = () => {
  const [convergedMessageLogsLogic] = useConvergedMessageLogLogic()
  const { messageLogs, pageTitle, pageSubtitle } = convergedMessageLogsLogic

  return (
    <Layout pageTitle={pageTitle} pageSubtitle={pageSubtitle}>
      <div>
        <h1>ConvergedMessageLog</h1>
        <div>
          {messageLogs?.map(({ message, timestamp, id }) => (
            <div key={id}>
              <h3>{message}</h3>
              <p>{timestamp}</p>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default ConvergedMessageLog
