import React, { useMemo } from 'react'

import { Typography, Grid } from '@barracuda-internal/bds-core'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'

import DashboardHeader from 'ets/components/pages/dashboard/header/DashboardHeader'
import DashboardMenu from 'ets/components/pages/dashboard/menu/DashboardMenu'
import DashboardOverview from 'ets/components/pages/dashboard/overview/DashboardOverview'
import DashboardEmployees from 'ets/components/pages/dashboard/employees/DashboardEmployees'
import DashboardThreats from 'ets/components/pages/dashboard/threats/DashboardThreats'
import DashboardDomains from 'ets/components/pages/dashboard/domains/DashboardDomains'
import DashboardAttackingDomains from 'ets/components/pages/dashboard/attackingDomains/DashboardAttackingDomains'
import FreeTrialBanner from 'ets/components/pages/dashboard/FreeTrialBanner'

import useDashboardLogic, { TABS, APP_HEADER_HEIGHT } from 'ets/components/pages/dashboard/useDashboardLogic'
import styles from 'ets/components/pages/dashboard/dashboardStyles'
import { useFormatMessage } from 'global/lib/localization'

const BASE_I18N_KEY = 'ets.app.dashboard'

export const Dashboard: React.FC = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const [dashboardLogic] = useDashboardLogic()

  return useMemo(() => {
    const {
      inProgress,
      scrollToSection,
      getSectionElement,
      isUserInputDisabledForTable,
      fixTableHeight
    } = dashboardLogic

    return (
      <>
        {inProgress && <LinearProgress />}
        <Grid container alignItems="center" justifyContent="center" data-field="grid-root">
          <Grid container alignItems="center" justifyContent="center" direction="column" data-field="grid-inner-root">
            <div className={classes.innerRoot}>
              <DashboardHeader />
            </div>
            <DashboardMenu
              TABS={TABS}
              scrollToSection={scrollToSection}
              getSectionElement={getSectionElement}
              APP_HEADER_HEIGHT={APP_HEADER_HEIGHT}
            />
            <Grid className="overview-section" container justifyContent="center" data-field="grid">
              <Typography className={classes.title} data-field="title">
                {formatMessage('overview.title')}{' '}
              </Typography>
            </Grid>
            <div className={classes.innerRoot}>
              <DashboardOverview scrollToSection={scrollToSection} />
            </div>
            <Grid container alignItems="center" justifyContent="center">
              <div className={classes.separator} />
            </Grid>
            <Grid className="employees-section" container justifyContent="center">
              <Typography className={classes.title}>{formatMessage('employees.title')}</Typography>
            </Grid>
            <div className={classes.innerRoot}>
              <DashboardEmployees
                isUserInputDisabledForTable={isUserInputDisabledForTable}
                fixTableHeight={fixTableHeight}
              />
            </div>
            <Grid container alignItems="center" justifyContent="center">
              <div className={classes.separator} />
            </Grid>
            <Grid className="threats-section" container justifyContent="center">
              <Typography className={classes.title}>{formatMessage('threats.title')}</Typography>
            </Grid>
            <div className={classes.innerRoot}>
              <DashboardThreats
                isUserInputDisabledForTable={isUserInputDisabledForTable}
                fixTableHeight={fixTableHeight}
              />
            </div>
            <Grid container alignItems="center" justifyContent="center">
              <div className={classes.separator} />
            </Grid>
            <Grid className="domains-section" container justifyContent="center">
              <Typography className={classes.title}>{formatMessage('domains.title')}</Typography>
            </Grid>
            <div className={classes.innerRoot}>
              <DashboardDomains
                isUserInputDisabledForTable={isUserInputDisabledForTable}
                fixTableHeight={fixTableHeight}
              />
            </div>
            <Grid container alignItems="center" justifyContent="center">
              <div className={classes.separator} />
            </Grid>
            <Grid className="attacks-section" container justifyContent="center">
              <Typography className={classes.title}>{formatMessage('attacks.title')}</Typography>
            </Grid>
            <div className={classes.innerRoot}>
              <DashboardAttackingDomains
                isUserInputDisabledForTable={isUserInputDisabledForTable}
                fixTableHeight={fixTableHeight}
              />
            </div>
            <Grid container alignItems="center" justifyContent="center">
              <div className={classes.separator} />
            </Grid>
          </Grid>
        </Grid>
        <Grid className="free-trial-banner" container justifyContent="center">
          <FreeTrialBanner />
        </Grid>
      </>
    )
  }, [dashboardLogic, classes, formatMessage])
}

export default Dashboard
