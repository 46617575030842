import { createAsyncThunk } from '@reduxjs/toolkit'

import apiRoutes from 'fir/lib/api/apiRoutes'
import {
  CreateSecurityTrainingAddressBookAPIConfig,
  CreateSecurityTrainingAddressBookApiResponse,
  GetSecurityTrainingInstancesApiConfig,
  GetSecurityTrainingInstancesApiResponse
} from 'fir/redux/types/SecurityTraining'

import restClient, { ApiRejectResponse, ResponseGenerator, validateApiError } from 'global/lib/api/restClient'

export const createSecurityTrainingAddressBook = createAsyncThunk<
  CreateSecurityTrainingAddressBookApiResponse,
  CreateSecurityTrainingAddressBookAPIConfig,
  ApiRejectResponse
>('SECURITY_TRAINING/createSecurityTrainingAddressBook', async function doForensicsCreateTrainingGroup(
  payload,
  { rejectWithValue }
) {
  try {
    const resp: ResponseGenerator = await restClient(apiRoutes.FORENSICS_CREATE_SECURITY_TRAINING_ADDRESS_BOOK, {
      data: { ...payload }
    })
    return resp.data.addressBook.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getSecurityTrainingInstances = createAsyncThunk<
  GetSecurityTrainingInstancesApiResponse[],
  GetSecurityTrainingInstancesApiConfig,
  ApiRejectResponse
>('SECURITY_TRAINING/getSecurityTrainingInstances', async function dogetSecurityTrainingInstances(
  payload,
  { rejectWithValue }
) {
  try {
    const resp: ResponseGenerator = await restClient(apiRoutes.FORENSICS_GET_SECURITY_TRAINING_INSTANCES, {
      data: { ...payload }
    })
    return resp.data.instances.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})
