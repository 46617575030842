import React from 'react'
import PropTypes from 'prop-types'

import { Typography, Radio, Grid, Paper } from '@barracuda-internal/bds-core'

import styles from 'global/components/lib/radioButtonPanel/radioButtonPanelStyles'

export interface RadioButtonPanelProps {
  value: string
  isActive: boolean
  onClick: (panelId: string) => void
  title: any
  subtitle: any
}

const RadioButtonPanel: React.FC<RadioButtonPanelProps> = ({ value, isActive, onClick, title, subtitle }) => {
  const classes = styles()

  return (
    <Paper variant="outlined" className={isActive ? classes.activeRectangle : classes.inactiveRectangle}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        style={{ cursor: 'pointer' }}
        onClick={() => onClick(value)}
      >
        <Grid item xs={1} className={classes.radioButtonWrapper}>
          <Radio color="primary" value={value} name={value} />
        </Grid>
        <Grid item xs={11} className={classes.descriptionWrapper} data-panel-description>
          <Grid item>
            <Typography className={isActive ? classes.activeText : classes.text} data-field="enforcement">
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.radioText} data-field="enforcement">
              {subtitle}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

RadioButtonPanel.propTypes = {
  value: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.any.isRequired,
  subtitle: PropTypes.any.isRequired
}

export default RadioButtonPanel
