import { Alert, AlertStatus } from 'global/types/api/ato'

import { luxonDate, isBeforeDate } from 'global/lib/datetime'

export enum AtoAlertStatus {
  Info = 'info',
  Reviewed = 'reviewed',
  Fp = 'marked_as_fp',
  Expired = 'expired',
  NoStatus = 'no_status'
}

export default function getAtoAlertStatus(alert: Alert): AtoAlertStatus {
  switch (true) {
    case isBeforeDate({
      initialDate: luxonDate(alert.createdOn).toISO(),
      subtractedDate: luxonDate()
        .minus({ days: 30 })
        .toISO()
    }):
      return AtoAlertStatus.Expired
    case alert.markedAsFp:
      return AtoAlertStatus.Fp
    case alert.status === AlertStatus.open:
      return AtoAlertStatus.Info
    case alert.status === AlertStatus.close:
      return AtoAlertStatus.Reviewed
    default:
      return AtoAlertStatus.NoStatus
  }
}
