import React from 'react'

import { Link, Typography } from '@barracuda-internal/bds-core'

import { config } from 'global/lib/config'

import { useFormatMessage } from 'global/lib/localization'
import styles from '../CTABannerStyles'

const BASE_I18N_KEY = 'fir.app.cta.remediation'

const RemediationCTA: React.FC<any> = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const baseClasses = styles()

  return (
    <div>
      <Typography variant="body1">
        {formatMessage('description', {
          b: (txt: any) => (
            <span key="bold-text" className={baseClasses.bold}>
              {txt}
            </span>
          )
        })}
        &nbsp;
        <Link target="_blank" href={config.LINKS.CAMPUS_CREATE_INCIDENT}>
          {formatMessage('learn_more')}...
        </Link>
      </Typography>
    </div>
  )
}

export default RemediationCTA
