import { useMemo, useCallback } from 'react'

import { useAppSelector, useAppDispatch } from 'sen/redux/toolkit/hooks'
import { resetCompromisedAccount } from 'sen/redux/features/ato/atoSlice'

export type DisplayName = string
export type NewPassword = string
export type OnResetCompromisedAccount = () => void

export default function useResetPasswordDoneDialogLogic(): [DisplayName, NewPassword, OnResetCompromisedAccount] {
  const { newPassword, displayName } = useAppSelector(_stores => ({
    newPassword: _stores.ato.resetPassword?.newPassword || '',
    displayName: _stores.ato.compromisedAccount?.displayName || _stores.ato.compromisedAccount?.emailAddress || ''
  }))
  const dispatch = useAppDispatch()

  const onResetCompromisedAccount = useCallback(() => {
    dispatch(resetCompromisedAccount())
  }, [dispatch])

  return useMemo(() => {
    return [displayName, newPassword, onResetCompromisedAccount]
  }, [displayName, newPassword, onResetCompromisedAccount])
}
