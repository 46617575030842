import { useMemo, useState, useCallback, SetStateAction, Dispatch } from 'react'

import { intersection } from 'lodash'

import { AttackType } from 'global/types/api/attackType'

export const WIRE_TRANSFER = 'WT'
export const INVOICES = 'INV'
export const HR = 'HR'
export const VIP = 'VIP'

export const REMEDIATION_TYPES = {
  QUARANTINE: 'SP_QUARANTINE_EMAIL',
  CLEANUP: 'SP_CLEANUP_EMAIL',
  MOVE_BACK: 'SP_MOVE_BACK_EMAIL'
}

export const REMEDIATION_STATUSES = {
  SUCCESS: 'S',
  FAILURE: 'E',
  IN_PROGRESS: 'P'
}

export const IMPERSONATIONS_TYPES = {
  REPLY_TO: 'reply_to',
  FROM: 'sender'
}

export const RISK_LEVELS = {
  HIGH: 'High',
  MEDIUM: 'Medium',
  LOW: 'Low'
}

export const GAUGE_SCORES = {
  [RISK_LEVELS.HIGH]: 90,
  [RISK_LEVELS.MEDIUM]: 60,
  [RISK_LEVELS.LOW]: 35
}

export const RISK_TYPES = {
  WIRE_TRANSFER,
  INVOICES,
  HR,
  VIP
}

export const STATES = {
  QUARANTINED: 'q',
  NOTIFIED_RECIPIENT: 'notify_r',
  NOTIFIED_ADMIN: 'notify_a',
  NOTIFIED_SENDER: 'notify_s',
  UNKNOWN: '',
  MOVED_BACK: 'move_back',
  DELETED: 'd'
}

export interface SpService {
  getRiskTypeByScore: (score: number) => string
  getGaugeByScore: (score: number) => number
  getStatus: (attack?: AttackType) => string
  hasStatus: (status: string, attack?: AttackType) => boolean
  isQuarantined: (attack?: AttackType) => boolean
  hasFromImpersonation: (attack?: AttackType) => boolean
  hasReplytoImpersonation: (attack?: AttackType) => boolean
  hasImpersonation: () => boolean
  hasRemediation: (attack?: AttackType) => boolean
  remediationInProgress: (attack?: AttackType) => boolean
}

export type SetAttack = Dispatch<SetStateAction<AttackType | undefined>>

export default function useSpService(): [SpService, SetAttack] {
  const [attack, setAttack] = useState<AttackType>()

  const getRiskTypeByScore = useCallback((score: number) => {
    switch (true) {
      case score > 2:
        return RISK_LEVELS.HIGH
      case score > 0:
        return RISK_LEVELS.MEDIUM
      default:
        return RISK_LEVELS.LOW
    }
  }, [])

  const getGaugeByScore = useCallback(
    (score: number) => {
      return GAUGE_SCORES[getRiskTypeByScore(score)]
    },
    [getRiskTypeByScore]
  )

  const getStatus = useCallback(
    (spAttack = attack) => {
      if (!spAttack) {
        return STATES.UNKNOWN
      }

      if (spAttack.remediation && spAttack.remediation.status === REMEDIATION_STATUSES.SUCCESS) {
        if (spAttack.remediation.actionType === REMEDIATION_TYPES.QUARANTINE) {
          return [
            ...intersection(
              [
                STATES.DELETED,
                STATES.QUARANTINED,
                STATES.NOTIFIED_RECIPIENT,
                STATES.NOTIFIED_ADMIN,
                STATES.NOTIFIED_SENDER
              ],
              spAttack.remediation.activities
            ),
            STATES.UNKNOWN
          ][0]
        }
        if (
          spAttack.remediation.actionType === REMEDIATION_TYPES.MOVE_BACK &&
          spAttack.remediation.activities.includes(STATES.MOVED_BACK)
        ) {
          return STATES.MOVED_BACK
        }

        return STATES.UNKNOWN
      }

      return STATES.UNKNOWN
    },
    [attack]
  )

  const hasStatus = useCallback(
    (status: string, spAttack = attack) => {
      return getStatus(spAttack) === status
    },
    [getStatus, attack]
  )

  const isQuarantined = useCallback(
    (spAttack = attack) => {
      return (
        spAttack?.remediation?.status === REMEDIATION_STATUSES.SUCCESS &&
        spAttack?.remediation?.actionType === REMEDIATION_TYPES.QUARANTINE
      )
    },
    [attack]
  )

  const hasRemediation = useCallback(
    (spAttack = attack) => {
      return !!spAttack?.remediation?.status.includes(REMEDIATION_STATUSES.SUCCESS || REMEDIATION_STATUSES.IN_PROGRESS)
    },
    [attack]
  )

  const remediationInProgress = useCallback(
    (spAttack = attack) => {
      return spAttack?.remediation?.status === REMEDIATION_STATUSES.IN_PROGRESS
    },
    [attack]
  )

  const hasFromImpersonation = useCallback(
    (spAttack = attack) => {
      return spAttack?.impersonationType === IMPERSONATIONS_TYPES.FROM
    },
    [attack]
  )

  const hasReplytoImpersonation = useCallback(
    (spAttack = attack) => {
      return spAttack?.impersonationType === IMPERSONATIONS_TYPES.REPLY_TO
    },
    [attack]
  )

  const hasImpersonation = useCallback(() => {
    return hasFromImpersonation() || hasReplytoImpersonation()
  }, [hasFromImpersonation, hasReplytoImpersonation])

  return useMemo(() => {
    return [
      {
        getRiskTypeByScore,
        getGaugeByScore,
        getStatus,
        hasStatus,
        isQuarantined,
        hasFromImpersonation,
        hasReplytoImpersonation,
        hasImpersonation,
        hasRemediation,
        remediationInProgress
      },
      setAttack
    ]
  }, [
    setAttack,
    getRiskTypeByScore,
    getGaugeByScore,
    getStatus,
    hasStatus,
    isQuarantined,
    hasFromImpersonation,
    hasReplytoImpersonation,
    hasImpersonation,
    hasRemediation,
    remediationInProgress
  ])
}
