import { FilterOperation, Nullable } from '@barracuda/reporting'

import { ChartPeriod, ChartType } from 'global/components/lib/chartsV2/ChartConfig'

type FilterKeys = keyof typeof FilterOperation
type FilterValues = typeof FilterOperation[FilterKeys]

export interface Column {
  accessorKey: string
  header: string
  fieldType: string
  possibleValues: Nullable<Nullable<string>[]>
  category: string
  hideColumnFromList?: boolean
}

export interface CreateUpdateUnifiedReportingReportApiConfig {
  baseDataType: string
  config: UnifiedReportingConfig
  lastModifiedBy: string
  name: string
  product: string
  uuid?: string
}

export interface Filter {
  fieldName: string
  operation: FilterValues
  value: any
}

export enum RelativeDateRange {
  LAST_24_HOURS = 'LAST_24_HOURS',
  LAST_DAY = 'LAST_DAY',
  LAST_3_DAYS = 'LAST_3_DAYS',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_90_DAYS = 'LAST_90_DAYS',
  LAST_6_MONTHS = 'LAST_6_MONTHS'
}

export interface UnifiedReportingFileDownloadConfig {
  accessTokenId: string
  accountId: string
  uuid: string
  absoluteDateRangeEnd?: string | null
  absoluteDateRangeStart?: string | null
  chartPeriod?: ChartPeriod
  chartType?: string
  filters?: any[]
  groupBy?: any[]
  hiddenColumns?: string[]
  relativeDateRange?: string | null
  reportName?: string | undefined
  searchQuery?: string | null
  sortBy?: any[]
  topDisplayCount?: number
  zoneId?: string
}

export interface UnifiedReportingReportData {
  table: {
    data: any[]
    count: number
    totalCount: number
    isGrouped: boolean
    baseDataType: string
  }
  chart: {
    data: any[]
    isGrouped: boolean
    fieldNames: string[]
  }
}

export interface UnifiedReportingConfig {
  absoluteDateRangeEnd?: string
  absoluteDateRangeStart?: string
  chartPeriod: ChartPeriod
  chartType: ChartType
  filters: Filter[]
  groupBy: string[]
  hiddenColumns: string[]
  relativeDateRange?: string
  searchQuery?: string
  sortBy: string[]
  topDisplayCount?: number
}

export interface UnifiedReportingReport {
  baseDataType: string
  config: UnifiedReportingConfig
  createdOn: string
  lastModifiedBy: string
  lastModifiedOn: string
  name: string
  product: UnifiedReportingReportProduct
  type: UnifiedReportingReportType
  uuid: string
}

export enum UnifiedReportingReportProduct {
  IR = 'IR',
  IP = 'IP'
}

export interface UnifiedReportingReports {
  pinnedReportUuid: string
  reports: UnifiedReportingReport[]
}

export enum UnifiedReportingReportType {
  BARRACUDA = 'BARRACUDA',
  CUSTOM = 'CUSTOM'
}

export interface UnifiedReportingReportsApiResponse {
  baseDataType: string
  config: UnifiedReportingConfig
  createdOn: string
  lastModifiedBy: string
  lastModifiedOn: string
  name: string
  product: UnifiedReportingReportProduct
  type: UnifiedReportingReportType
  uuid: string
}
