import { reduxStore } from 'global/lib/reduxStore'
import { setCurrentAccessToken } from 'global/redux/features/accessToken/accessTokenSlice'
import { User } from 'global/types/api/userType'
import accessTokenLib from 'global/lib/accessToken/accessToken'
import userDataLib from 'global/lib/userData/userData'
import { setScan, reset as resetScan } from 'global/redux/features/scan/scanSlice'
import { reset as resetDataTables } from 'global/redux/features/dataTables/dataTablesSlice'
import { logout, setError } from 'global/redux/features/auth/authSlice'

import routesConfig from 'ets/lib/routes/routesConfig'
import { reset as resetReports } from 'ets/redux/features/reports/reportsSlice'
import { setUser } from 'ets/redux/features/user/userSlice'

export interface GotoReportPageParams {
  user?: User
  scan?: any
  accessTokenId?: string
}

export default function gotoReportPage({ user, scan, accessTokenId }: GotoReportPageParams) {
  // reset stores
  reduxStore.dispatch(resetScan())
  reduxStore.dispatch(resetDataTables())
  reduxStore.dispatch(resetReports())

  // store optional values
  if (user) reduxStore.dispatch(setUser(user))
  if (scan) reduxStore.dispatch(setScan(scan))

  const accounts = userDataLib.getAccounts()

  const validatedAccessTokenId =
    accessTokenId || accessTokenLib.getDefaultBccAccountAccessTokenId() || accessTokenLib.getAllAccessTokens()[0]?.id

  const currentUserAdminFlag = validatedAccessTokenId
    ? userDataLib.getAccountByAccessToken(validatedAccessTokenId)?.userAdminFlag
    : (accounts || [{}])[0].userAdminFlag

  if (!currentUserAdminFlag && !userDataLib.isImpersonationMode()) {
    reduxStore.dispatch(logout())
    reduxStore.dispatch(setError('no_permissions'))
  }

  if (validatedAccessTokenId) {
    // set new accessToken then move to the new report page
    reduxStore.dispatch(setCurrentAccessToken(validatedAccessTokenId))

    // navigate to the report page
    routesConfig.DASHBOARD.goto({ reportId: validatedAccessTokenId })
  } else {
    routesConfig.SIGNIN_CONNECT.goto()
  }
}
