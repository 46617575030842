import React from 'react'
import PropTypes from 'prop-types'

import { ButtonConfig } from 'global/components/lib/grid/pager/usePagerLogic'
import styles from 'global/components/lib/grid/pager/pagerStyles'

export interface PagerIconProps {
  config: ButtonConfig
  title: string
  imageSrc: string
}

const PagerIcon: React.FC<PagerIconProps> = ({ config, title, imageSrc }) => {
  const classes = styles()

  return (
    <button
      className={`k-link k-pager-nav k-pager-first ${
        config.isDisabled ? `k-state-disabled ${classes.disabledIcon}` : ''
      }`}
      onClick={config.onClick}
    >
      <img className={`k-icon ${classes.icon}`} alt="" aria-label={title} src={imageSrc} />
    </button>
  )
}
PagerIcon.propTypes = {
  config: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired
}

export default PagerIcon
