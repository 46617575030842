import { countries } from 'countries-list'
import isoCountries from 'i18n-iso-countries'

import { config } from 'global/lib/config'

export { default } from 'i18n-iso-countries'

export function getCountryList() {
  const countryList = Object.entries(countries).map(([code, details]) => {
    return {
      code,
      name: details.name
    }
  })

  return countryList.concat().sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
}

export function getCountryListCode3() {
  const countryList = Object.entries(countries).map(([code, details]) => {
    return {
      code: isoCountries.alpha2ToAlpha3(code),
      name: details.name
    }
  })

  return countryList.concat().sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
}

// works for ISO 3166-1 Alpha-2, Alpha-3 or Numeric code
export function getCountryNameFromCode2(code2: string | undefined) {
  if (!code2) {
    return null
  }

  return isoCountries.getName(code2, config.LANGUAGE)?.replace(' of America', '')
}

export function getCountryName(code: string): string | undefined {
  return getCountryList().find(countryObject => countryObject.code === code)?.name
}

export function getCode3CountryName(code: string): string | undefined {
  return getCountryListCode3().find(countryObject => countryObject.code === code)?.name
}
