import { useMemo, useEffect, useCallback, useState } from 'react'

import { ModifiedAlert } from 'global/types/api/ato'
import useTabs, { BaseTabsConfig } from 'global/lib/useTabs/useTabs'
import { AtoAlertStatus } from 'global/lib/getAtoAlertStatus/getAtoAlertStatus'
import { isSuccess, getErrorMessage } from 'global/redux/toolkit/api'
import useDialogLogic, { UseDialogLogic } from 'global/lib/dialogs/useDialogLogic'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import {
  getAlertInboxRules,
  getAlertSignins,
  getAlertThreats,
  resetAlert,
  closeAtoAlert,
  resetCloseAtoAlert
} from 'sen/redux/features/ato/atoSlice'

export type IsLoaded = boolean
export type Email = string
export type ToggleDialog = () => void

export interface TabsConfig extends BaseTabsConfig {
  tabsCount: (number | undefined)[]
}
export type ApiError = string | undefined

export interface ButtonConfig {
  visible?: boolean
  disabled?: boolean
}

export interface ButtonsConfig {
  close: ButtonConfig
  createIncident: ButtonConfig
  onMarkAsFp: () => void
  isMarkedAsFp: boolean
  onOpenCreateIncident: () => void
  markAsFp: ButtonConfig
}

export interface UseAlertDialogLogic {
  alert: ModifiedAlert
  isReviewed: boolean
}

export default function useAlertDialogLogic({
  alert
}: UseAlertDialogLogic): [IsLoaded, ApiError, Email, TabsConfig, ButtonsConfig, UseDialogLogic] {
  const dispatch = useAppDispatch()
  const {
    accessTokenId,
    inboxRules,
    inboxRulesApiError,
    signins,
    signinsApiError,
    threats,
    threatsApiError,
    isSuccessFullyMarkedAsFp,
    compromisedAccount
  } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id,
    inboxRules: _stores.ato.alertInboxRules,
    inboxRulesApiError: getErrorMessage(_stores.ato.getAlertInboxRulesApiStatus),
    signins: _stores.ato.alertSignins,
    signinsApiError: getErrorMessage(_stores.ato.getAlertSigninsApiStatus),
    threats: _stores.ato.alertThreats,
    threatsApiError: getErrorMessage(_stores.ato.getAlertThreatsApiStatus),
    isSuccessFullyMarkedAsFp: isSuccess(_stores.ato.markAlertAsFpApiStatus),
    compromisedAccount: _stores.ato.compromisedAccount
  }))
  const [selectedTab, onTabSelected] = useTabs(0)
  const [isMarkAlertAsFpDialogOpened, toggleMarkAlertAsFpDialog] = useDialogLogic()
  const [isMarkedAsFp, setIsMarkedAsFp] = useState<boolean>(false)

  const apiError: ApiError = useMemo(() => {
    return inboxRulesApiError || signinsApiError || threatsApiError
  }, [inboxRulesApiError, signinsApiError, threatsApiError])

  const isLoaded = useMemo(() => {
    return (!!inboxRules && !!signins && !!threats) || !!apiError
  }, [inboxRules, signins, threats, apiError])

  const tabsCount = useMemo(() => {
    return [threats?.report?.totalCount, signins?.report?.totalCount, inboxRules?.report?.totalCount]
  }, [threats, signins, inboxRules])

  // Init
  useEffect(() => {
    dispatch(getAlertInboxRules(alert.id))
    dispatch(getAlertSignins(alert.id))
    dispatch(getAlertThreats(alert.id))

    return () => {
      dispatch(resetAlert(true))
      dispatch(resetCloseAtoAlert())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isLoaded) {
      if (threats?.report?.totalCount) {
        onTabSelected(undefined, 0)
      } else if (signins?.report?.totalCount) {
        onTabSelected(undefined, 1)
      } else if (inboxRules?.report?.totalCount) {
        onTabSelected(undefined, 2)
      }
    }
  }, [isLoaded, threats, signins, inboxRules, onTabSelected])

  // alert is marked as fp
  useEffect(() => {
    if (isSuccessFullyMarkedAsFp && !isMarkedAsFp) {
      setIsMarkedAsFp(true)
    }
  }, [isSuccessFullyMarkedAsFp, isMarkedAsFp])

  const isCreateIncidentButtonDisabled = useMemo(() => {
    return (
      !isLoaded ||
      ![AtoAlertStatus.Info, AtoAlertStatus.Reviewed].includes(alert?.atoAlertStatus) ||
      isMarkedAsFp ||
      !!apiError
    )
  }, [alert, isLoaded, isMarkedAsFp, apiError])

  const onMarkAsFp = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.ATO_OPEN_MARK_ALERT_AS_FP, {
      accessTokenId,
      alert: alert.id
    })
    toggleMarkAlertAsFpDialog()
  }, [accessTokenId, alert.id, toggleMarkAlertAsFpDialog])

  const onOpenCreateIncident = useCallback(() => {
    // this will mark the alert as reviewed
    dispatch(closeAtoAlert(alert.id))

    analyticsLib.trackAppEvent(analyticsLib.EVENTS.ATO_INCIDENT_WIZARD, {
      accessTokenId,
      url: window.location.href,
      displayName: compromisedAccount?.displayName,
      subject: compromisedAccount?.sampleSubject,
      email: compromisedAccount?.emailAddress,
      id: compromisedAccount?.id,
      alertId: alert.id
    })
  }, [compromisedAccount, accessTokenId, dispatch, alert.id])

  return useMemo(() => {
    return [
      isLoaded,
      apiError,
      alert.emailAddress,
      { selectedTab, onTabSelected, tabsCount },
      {
        createIncident: { disabled: isCreateIncidentButtonDisabled },
        close: { disabled: !isLoaded },
        onMarkAsFp,
        isMarkedAsFp,
        onOpenCreateIncident,
        markAsFp: { disabled: !isLoaded || !!apiError }
      },
      {
        isOpen: isMarkAlertAsFpDialogOpened,
        toggle: toggleMarkAlertAsFpDialog
      }
    ]
  }, [
    isLoaded,
    alert,
    tabsCount,
    selectedTab,
    onTabSelected,
    isCreateIncidentButtonDisabled,
    onMarkAsFp,
    onOpenCreateIncident,
    apiError,
    isMarkAlertAsFpDialogOpened,
    toggleMarkAlertAsFpDialog,
    isMarkedAsFp
  ])
}
