import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import styles from 'global/components/features/signinSignup/signinSignupStylesNew'

export interface SigninSignupHeaderProps {
  children: any
}

const SigninSignupHeader: React.FC<SigninSignupHeaderProps> = ({ children }) => {
  const classes = styles()

  return useMemo(() => <span className={classes.headingWrapper}>{children}</span>, [children, classes])
}

SigninSignupHeader.propTypes = {
  children: PropTypes.any.isRequired
}

export default SigninSignupHeader
