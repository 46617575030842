import { createSlice } from '@reduxjs/toolkit'
import { union } from 'lodash'

import insertToArray from 'global/lib/insertToArray'
import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'global/redux/toolkit/api'
import { Whitelist, WhitelistItem } from 'global/types/redux/settings'

import {
  addWhitelistItem,
  createForensicsCustomNotification,
  deleteForensicsCustomNotification,
  getForensicsCustomNotification,
  getForensicsNotificationPreview,
  deleteWhitelistItem,
  editWhitelistItem,
  getForensicsSiemSettings,
  getWhitelistItemList,
  saveAccessTokenSettings,
  saveForensicsSiemSettings,
  testForensicsSiemSettings
} from 'fir/redux/features/settings/settingsApiThunks'
import {
  CustomNotificationPreviewResponse,
  DEFAULT_TEMPLATE_NAME,
  EditEmailFormValues,
  ForensicsSettings,
  NotificationPreview,
  SenderPolicyActions,
  SenderPolicyTypes,
  SiemSettings
} from 'fir/redux/types/Settings'

export interface SettingsState {
  addWhitelistItemApiStatus: ApiStatus
  errorMsg: string | undefined
  createForensicsCustomNotificationApiStatus: ApiStatus
  deleteForensicsCustomNotificationApiStatus: ApiStatus
  deleteWhitelistItemApiStatus: ApiStatus
  editEmailFormValues: EditEmailFormValues
  editWhitelistItemApiStatus: ApiStatus
  forensics: ForensicsSettings
  forensicsSiem: SiemSettings
  getForensicsCustomNotificationApiStatus: ApiStatus
  getForensicsNotificationPreviewApiStatus: ApiStatus
  getForensicsSiemSettingsApiStatus: ApiStatus
  getWhitelistItemListApiStatus: ApiStatus
  isClearSettingsErrors: boolean
  isCustomizeEmailAlertOpened: boolean
  notificationPreview: NotificationPreview
  saveAccessTokenSettingsApiStatus: ApiStatus
  saveSiemSettingsApiStatus: ApiStatus
  testSiemSettingsApiStatus: ApiStatus
  whitelist: Whitelist
  whitelistItem: WhitelistItem | Record<string, unknown>
  whiteListLoadedOffsets: number[]
}

// Initial state
export const INITIAL_STATE: SettingsState = {
  addWhitelistItemApiStatus: inIdle,
  createForensicsCustomNotificationApiStatus: inIdle,
  deleteForensicsCustomNotificationApiStatus: inIdle,
  deleteWhitelistItemApiStatus: inIdle,
  editEmailFormValues: {
    formIntro: '',
    formSignature: '',
    formSubject: ''
  },
  editWhitelistItemApiStatus: inIdle,
  errorMsg: undefined,
  forensics: {
    forensicsEndUserNotificationTemplate: DEFAULT_TEMPLATE_NAME,
    forensicsIncidentAlertAdmin: false,
    forensicsIncidentAlertEndUser: false,
    forensicsIncidentContentShieldPolicy: false,
    forensicsIncidentContinuousRemediation: false,
    forensicsIncidentDeleteEmails: false,
    forensicsIncidentSenderPolicy: false,
    forensicsIncidentSenderPolicyAction: SenderPolicyActions.quarantine,
    forensicsIncidentSenderPolicyType: SenderPolicyTypes.senders,
    forensicsIncidentAutoRemediateEmails: false,
    forensicsAutomatedWorkflowNotifyAddress: '',
    forensicsAutomatedWorkflowSlackWebhook: '',
    forensicsAutomatedWorkflowMSTeamsWebhook: '',
    forensicsAutomatedWorkflowSyncAddress: false,
    forensicsAutomaticIncidentAlertAdmin: false,
    forensicsAutomaticIncidentDeleteEmails: false,
    forensicsAutomaticIncidentContinuousRemediation: false,
    forensicsAutomaticIncidentAlertEndUser: false,
    forensicsAutomaticIncidentIgnorePhishline: false,
    forensicsAutomaticIncidentNotifyAddress: '',
    forensicsNotifyAddress: '',
    forensicsPostDeliveryNotifications: false,
    forensicsPostDeliveryNotifyAddress: '',
    forensicsPostDeliverySyncAddress: false,
    forensicsUserReportedIgnoreSatCampaigns: true,
    forensicsUserReportedNotifications: false,
    forensicsUserReportedNotifyAddress: '',
    forensicsUserReportedSyncAddress: false
  },
  forensicsSiem: {
    isActive: false,
    syslogHost: ''
  },
  getForensicsCustomNotificationApiStatus: inIdle,
  getForensicsNotificationPreviewApiStatus: inIdle,
  getForensicsSiemSettingsApiStatus: inIdle,
  getWhitelistItemListApiStatus: inIdle,
  isClearSettingsErrors: false,
  isCustomizeEmailAlertOpened: false,
  notificationPreview: {
    body: '',
    subject: ''
  },
  saveAccessTokenSettingsApiStatus: inIdle,
  saveSiemSettingsApiStatus: inIdle,
  testSiemSettingsApiStatus: inIdle,
  whitelist: { report: {} } as Whitelist,
  whitelistItem: {},
  whiteListLoadedOffsets: []
}

const UNABLE_TO_SAVE_ERROR = 'save_error'
const DEFAULT_ERROR = 'default'

/* eslint-disable no-param-reassign */
export const settingsSlice = createSlice({
  name: 'SETTINGS',
  initialState: INITIAL_STATE,
  // reducers object creates an action and executes the reducer function
  reducers: {
    displayCustomizeEmailAlertDialog: (state, action) => {
      state.isCustomizeEmailAlertOpened = action.payload as boolean
    },
    resetClearErrors: state => {
      state.isClearSettingsErrors = INITIAL_STATE.isClearSettingsErrors
    },
    resetForensicsNotificationForm: state => {
      state.errorMsg = INITIAL_STATE.errorMsg
      state.editEmailFormValues = INITIAL_STATE.editEmailFormValues
    },
    resetSettingsDemoAlert: state => {
      state.errorMsg = INITIAL_STATE.errorMsg
      state.saveAccessTokenSettingsApiStatus = INITIAL_STATE.saveAccessTokenSettingsApiStatus
    },
    resetSettingsFailures: state => {
      state.errorMsg = INITIAL_STATE.errorMsg
      state.isClearSettingsErrors = true
      state.saveAccessTokenSettingsApiStatus = INITIAL_STATE.saveAccessTokenSettingsApiStatus
    },
    resetSiemSettings: state => {
      state.forensicsSiem = INITIAL_STATE.forensicsSiem
      state.getForensicsSiemSettingsApiStatus = INITIAL_STATE.getForensicsSiemSettingsApiStatus
      state.saveSiemSettingsApiStatus = INITIAL_STATE.saveSiemSettingsApiStatus
      state.testSiemSettingsApiStatus = INITIAL_STATE.testSiemSettingsApiStatus
    },
    resetWhitelistItemList: state => {
      state.getWhitelistItemListApiStatus = INITIAL_STATE.getWhitelistItemListApiStatus
      state.whitelist = INITIAL_STATE.whitelist
      state.whiteListLoadedOffsets = INITIAL_STATE.whiteListLoadedOffsets
    },
    resetWhitelistItem: state => {
      state.addWhitelistItemApiStatus = INITIAL_STATE.addWhitelistItemApiStatus
      state.editWhitelistItemApiStatus = INITIAL_STATE.editWhitelistItemApiStatus
      state.deleteWhitelistItemApiStatus = INITIAL_STATE.deleteWhitelistItemApiStatus
      state.whitelistItem = INITIAL_STATE.whitelistItem
      state.errorMsg = INITIAL_STATE.errorMsg
    },
    reset: () => {
      return { ...INITIAL_STATE }
    },
    resetError: state => {
      state.errorMsg = INITIAL_STATE.errorMsg
    },
    updateAccessTokenSettings: (state, action) => {
      state.forensics = { ...state.forensics, ...(action.payload as ForensicsSettings) }
    }
  },

  // extraReducers do not create an action but executes the reducer function
  extraReducers: builder => {
    builder
      // addWhitelistItem
      .addCase(addWhitelistItem.pending, state => {
        state.addWhitelistItemApiStatus = inProgress
        state.whitelistItem = INITIAL_STATE.whitelistItem
        state.errorMsg = INITIAL_STATE.errorMsg
      })
      .addCase(addWhitelistItem.fulfilled, (state, action) => {
        state.addWhitelistItemApiStatus = successResponse
        state.whitelistItem = action.payload || INITIAL_STATE.whitelistItem
      })
      .addCase(addWhitelistItem.rejected, (state, action) => {
        state.addWhitelistItemApiStatus = failedResponse(action.payload as string)
        state.errorMsg = UNABLE_TO_SAVE_ERROR
      })

      // deleteWhitelistItem
      .addCase(deleteWhitelistItem.pending, state => {
        state.deleteWhitelistItemApiStatus = inProgress
        state.errorMsg = INITIAL_STATE.errorMsg
      })
      .addCase(deleteWhitelistItem.fulfilled, state => {
        state.deleteWhitelistItemApiStatus = successResponse
      })
      .addCase(deleteWhitelistItem.rejected, (state, action) => {
        state.deleteWhitelistItemApiStatus = failedResponse(action.payload as string)
        state.errorMsg = UNABLE_TO_SAVE_ERROR
      })

      // editWhitelistItem
      .addCase(editWhitelistItem.pending, state => {
        state.editWhitelistItemApiStatus = inProgress
        state.whitelistItem = INITIAL_STATE.whitelistItem
        state.errorMsg = INITIAL_STATE.errorMsg
      })
      .addCase(editWhitelistItem.fulfilled, (state, action) => {
        state.editWhitelistItemApiStatus = successResponse
        state.whitelistItem = action.payload || INITIAL_STATE.whitelistItem
      })
      .addCase(editWhitelistItem.rejected, (state, action) => {
        state.editWhitelistItemApiStatus = failedResponse(action.payload as string)
        state.errorMsg = UNABLE_TO_SAVE_ERROR
      })

      // createForensicsCustomNotification
      .addCase(createForensicsCustomNotification.pending, state => {
        state.createForensicsCustomNotificationApiStatus = inProgress
        state.errorMsg = INITIAL_STATE.errorMsg
      })
      .addCase(createForensicsCustomNotification.fulfilled, (state, action) => {
        state.createForensicsCustomNotificationApiStatus = successResponse
      })
      .addCase(createForensicsCustomNotification.rejected, (state, action) => {
        state.createForensicsCustomNotificationApiStatus = failedResponse(action.payload as string)
        state.errorMsg = UNABLE_TO_SAVE_ERROR
      })

      // getForensicsCustomNotification
      .addCase(getForensicsCustomNotification.pending, state => {
        state.editEmailFormValues = INITIAL_STATE.editEmailFormValues
        state.getForensicsCustomNotificationApiStatus = inProgress
        state.errorMsg = INITIAL_STATE.errorMsg
      })
      .addCase(getForensicsCustomNotification.fulfilled, (state, action) => {
        state.getForensicsCustomNotificationApiStatus = successResponse
        state.editEmailFormValues = (action.payload as CustomNotificationPreviewResponse).editEmailFormValues
        state.notificationPreview = (action.payload as CustomNotificationPreviewResponse).notificationPreview
      })
      .addCase(getForensicsCustomNotification.rejected, (state, action) => {
        state.getForensicsCustomNotificationApiStatus = failedResponse(action.payload as string)
        state.errorMsg = INITIAL_STATE.errorMsg
      })

      // getForensicsNotificationPreview
      .addCase(getForensicsNotificationPreview.pending, state => {
        state.notificationPreview = INITIAL_STATE.notificationPreview
        state.getForensicsNotificationPreviewApiStatus = inProgress
        state.errorMsg = INITIAL_STATE.errorMsg
      })
      .addCase(getForensicsNotificationPreview.fulfilled, (state, action) => {
        state.getForensicsNotificationPreviewApiStatus = successResponse
        state.notificationPreview = action.payload as NotificationPreview
      })
      .addCase(getForensicsNotificationPreview.rejected, (state, action) => {
        state.getForensicsNotificationPreviewApiStatus = failedResponse(action.payload as string)
        state.errorMsg = DEFAULT_ERROR
      })

      // getForensicsSiemSettings
      .addCase(getForensicsSiemSettings.pending, state => {
        state.forensicsSiem = INITIAL_STATE.forensicsSiem
        state.saveSiemSettingsApiStatus = INITIAL_STATE.saveSiemSettingsApiStatus
        state.testSiemSettingsApiStatus = INITIAL_STATE.testSiemSettingsApiStatus
        state.getForensicsSiemSettingsApiStatus = inProgress
        state.errorMsg = INITIAL_STATE.errorMsg
      })
      .addCase(getForensicsSiemSettings.fulfilled, (state, action) => {
        state.getForensicsSiemSettingsApiStatus = successResponse
        state.forensicsSiem = action.payload as SiemSettings
      })
      .addCase(getForensicsSiemSettings.rejected, (state, action) => {
        state.getForensicsSiemSettingsApiStatus = failedResponse(action.payload as string)
        state.errorMsg = DEFAULT_ERROR
      })

      // getWhitelistItemList
      .addCase(getWhitelistItemList.pending, (state, action) => {
        const currentOffset = (action.meta.arg?.query || {}).offset || 0
        state.getWhitelistItemListApiStatus = inProgress
        state.whiteListLoadedOffsets = union(state.whiteListLoadedOffsets, [currentOffset])
        state.errorMsg = INITIAL_STATE.errorMsg
      })
      .addCase(getWhitelistItemList.fulfilled, (state, action) => {
        state.getWhitelistItemListApiStatus = successResponse
        state.whitelist = {
          ...action.payload,
          report: {
            ...action.payload.report,
            data: insertToArray(
              state.whitelist.report.data || [],
              action.payload.report.data,
              (action.meta.arg?.query || {}).offset || 0
            )
          }
        }
      })
      .addCase(getWhitelistItemList.rejected, (state, action) => {
        state.getWhitelistItemListApiStatus = failedResponse(action.payload as string)
        state.errorMsg = DEFAULT_ERROR
      })

      // saveAccessTokenSettings
      .addCase(saveAccessTokenSettings.pending, state => {
        state.saveAccessTokenSettingsApiStatus = inProgress
        state.errorMsg = INITIAL_STATE.errorMsg
      })
      .addCase(saveAccessTokenSettings.fulfilled, (state, action) => {
        state.saveAccessTokenSettingsApiStatus = successResponse
        state.forensics = action.payload as ForensicsSettings
      })
      .addCase(saveAccessTokenSettings.rejected, (state, action) => {
        state.saveAccessTokenSettingsApiStatus = failedResponse(action.payload as string)
        state.errorMsg = UNABLE_TO_SAVE_ERROR
      })

      // saveForensicsSiemSettings
      .addCase(saveForensicsSiemSettings.pending, state => {
        state.testSiemSettingsApiStatus = INITIAL_STATE.testSiemSettingsApiStatus
        state.saveSiemSettingsApiStatus = inProgress
        state.errorMsg = INITIAL_STATE.errorMsg
      })
      .addCase(saveForensicsSiemSettings.fulfilled, (state, action) => {
        state.saveSiemSettingsApiStatus = successResponse
        state.forensicsSiem = action.payload as SiemSettings
      })
      .addCase(saveForensicsSiemSettings.rejected, (state, action) => {
        state.saveSiemSettingsApiStatus = failedResponse(action.payload as string)
        state.errorMsg = UNABLE_TO_SAVE_ERROR
      })

      // testForensicsSiemSettings
      .addCase(testForensicsSiemSettings.pending, state => {
        state.saveSiemSettingsApiStatus = INITIAL_STATE.saveSiemSettingsApiStatus
        state.testSiemSettingsApiStatus = inProgress
        state.errorMsg = INITIAL_STATE.errorMsg
      })
      .addCase(testForensicsSiemSettings.fulfilled, state => {
        state.testSiemSettingsApiStatus = successResponse
      })
      .addCase(testForensicsSiemSettings.rejected, (state, action) => {
        state.testSiemSettingsApiStatus = failedResponse(action.payload as string)
        state.errorMsg = UNABLE_TO_SAVE_ERROR
      })
  }
})
/* eslint-enable no-param-reassign */

export const {
  displayCustomizeEmailAlertDialog,
  resetClearErrors,
  resetForensicsNotificationForm,
  resetSettingsDemoAlert,
  resetSettingsFailures,
  resetSiemSettings,
  resetWhitelistItem,
  resetWhitelistItemList,
  reset,
  updateAccessTokenSettings
} = settingsSlice.actions

export {
  addWhitelistItem,
  createForensicsCustomNotification,
  deleteForensicsCustomNotification,
  deleteWhitelistItem,
  editWhitelistItem,
  getForensicsCustomNotification,
  getForensicsNotificationPreview,
  getForensicsSiemSettings,
  getWhitelistItemList,
  saveAccessTokenSettings,
  saveForensicsSiemSettings,
  testForensicsSiemSettings
}

export default settingsSlice.reducer
