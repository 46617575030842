import React from 'react'
import PropTypes from 'prop-types'

import { ConfidenceLevel } from 'global/types/api/attackType'
import Score, { ScoreLevel, ScoreSize } from 'global/components/lib/score/Score'

export interface CellConfidenceScoreProp {
  level: ConfidenceLevel
  className?: string
}

const CellConfidenceScore: React.FC<CellConfidenceScoreProp> = ({ className, level, ...rest }) => {
  const scoreLvl = level ? (ConfidenceLevel[level] as ScoreLevel) : undefined

  return (
    <div {...rest} className={className} role="presentation">
      <Score size={ScoreSize.small} level={scoreLvl} />
    </div>
  )
}

CellConfidenceScore.propTypes = {
  level: PropTypes.any,
  className: PropTypes.string
}

CellConfidenceScore.defaultProps = {
  level: undefined,
  className: undefined
}

export default CellConfidenceScore
