import React from 'react'
import PropTypes from 'prop-types'

import styles from './cellStyles'

export interface Props {
  src: string
  className?: string
}

const CellImage: React.FC<Props> = ({ src, className }) => {
  const classes = styles()

  return <img src={src} className={`${classes.image} ${className}`} alt="" />
}

CellImage.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string
}

CellImage.defaultProps = {
  className: undefined
}

export default CellImage
