import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Grid } from '@barracuda-internal/bds-core'

export interface SigninSignupContentProps {
  children: any
}

const SigninSignupContent: React.FC<SigninSignupContentProps> = ({ children }) => {
  return useMemo(() => <Grid container>{children}</Grid>, [children])
}

SigninSignupContent.propTypes = {
  children: PropTypes.any.isRequired
}

export default SigninSignupContent
