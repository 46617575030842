import { DateTime } from 'luxon'
import { createSlice } from '@reduxjs/toolkit'

import { ApiStatus, failedResponse, inIdle, inProgress, successResponse } from 'global/redux/toolkit/api'
import {
  Frequency,
  FrequencyMonthly,
  ReportFormat,
  ScheduledReport,
  ScheduledReportsDrawerType,
  TimeRange
} from 'global/types/api/scheduledReports'

import {
  createScheduledReport,
  deleteScheduledReport,
  getScheduledReport,
  getScheduledReports,
  updateScheduledReport
} from './scheduledReportsApiThunks'

export interface ScheduledReportsState {
  createScheduledReportApiStatus: ApiStatus
  deleteScheduledReportApiStatus: ApiStatus
  errorMessage: string
  getScheduledReportsApiStatus: ApiStatus
  getScheduledReportApiStatus: ApiStatus
  scheduledReport: ScheduledReportsDrawerType
  scheduledReports: ScheduledReport[]
  updateScheduledReportApiStatus: ApiStatus
}

type UpdatePayload = Partial<ScheduledReportsState>

export const INITIAL_SCHEDULED_REPORT = {
  enabled: true,
  format: ReportFormat.CSV,
  frequency: Frequency.daily,
  frequencyDayOfMonth: 2,
  frequencyDayOfWeek: 0,
  frequencyMonthly: FrequencyMonthly.first,
  frequencyTime: '19:00',
  isReportNameFixed: false,
  lastModifiedOn: '',
  product: 'IR',
  recipients: [],
  relativeDateRange: TimeRange.lastDay,
  reportName: '',
  reportUuid: '',
  uuid: '',
  zoneId: DateTime.local().zoneName
}

// Initial state
export const INITIAL_STATE: ScheduledReportsState = {
  createScheduledReportApiStatus: inIdle,
  deleteScheduledReportApiStatus: inIdle,
  errorMessage: '',
  getScheduledReportsApiStatus: inIdle,
  getScheduledReportApiStatus: inIdle,
  scheduledReport: INITIAL_SCHEDULED_REPORT,
  scheduledReports: [],
  updateScheduledReportApiStatus: inIdle
}

/* eslint-disable no-param-reassign */
export const scheduledReportsSlice = createSlice({
  name: 'SCHEDULED_REPORTS',
  initialState: INITIAL_STATE,
  reducers: {
    setErrorMessage: (state, action: any) => {
      state.errorMessage = action.payload
    },
    reset: () => ({ ...INITIAL_STATE }),
    resetScheduledReport: state => {
      return {
        ...state,
        scheduledReport: INITIAL_SCHEDULED_REPORT,
        createScheduledReportApiStatus: inIdle,
        updateScheduledReportApiStatus: inIdle
      }
    },
    update: (state, action: { payload: UpdatePayload }) => {
      return {
        ...state,
        ...action.payload
      }
    }
  },
  // extraReducers do not create an action but executes the reducer function
  extraReducers: builder => {
    builder
      .addCase(getScheduledReports.pending, state => {
        state.getScheduledReportsApiStatus = inProgress
      })
      .addCase(getScheduledReports.fulfilled, (state, action) => {
        state.scheduledReports = action.payload
        state.getScheduledReportsApiStatus = successResponse
      })
      .addCase(getScheduledReports.rejected, (state, action) => {
        state.getScheduledReportsApiStatus = failedResponse(action.payload)
      })
      .addCase(createScheduledReport.pending, state => {
        state.createScheduledReportApiStatus = inProgress
      })
      .addCase(createScheduledReport.fulfilled, (state, action) => {
        state.createScheduledReportApiStatus = successResponse
      })
      .addCase(createScheduledReport.rejected, (state, action) => {
        state.createScheduledReportApiStatus = failedResponse(action.payload)
      })
      .addCase(updateScheduledReport.pending, state => {
        state.updateScheduledReportApiStatus = inProgress
      })
      .addCase(updateScheduledReport.fulfilled, (state, action) => {
        state.updateScheduledReportApiStatus = successResponse
      })
      .addCase(updateScheduledReport.rejected, (state, action) => {
        state.updateScheduledReportApiStatus = failedResponse(action.payload)
      })
      .addCase(deleteScheduledReport.pending, state => {
        state.deleteScheduledReportApiStatus = inProgress
      })
      .addCase(deleteScheduledReport.fulfilled, (state, action) => {
        state.deleteScheduledReportApiStatus = successResponse
      })
      .addCase(deleteScheduledReport.rejected, (state, action) => {
        state.deleteScheduledReportApiStatus = failedResponse(action.payload)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { reset, resetScheduledReport, setErrorMessage, update } = scheduledReportsSlice.actions

export { createScheduledReport, deleteScheduledReport, getScheduledReports, getScheduledReport, updateScheduledReport }

export default scheduledReportsSlice.reducer
