// Maybe should be included as part of BDS theme?
import colors from '@barracuda-internal/bds-core/dist/styles/colors'
import { FONT as font } from 'global/configs/theme/defaults'

import palette from './palette'
import overrides from './overrides'
import breakpoints from './breakpointsETS'

export default {
  palette,
  breakpoints,
  colors,
  font,
  overrides
}
