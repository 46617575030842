import { useEffect, useMemo, useCallback } from 'react'
import { process } from '@progress/kendo-data-query'
import { config } from 'global/lib/config'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { InboxRule } from 'global/types/api/remediation'
import { BDSGridPagerConfig } from 'global/types/dataTables/dataTables'
import { ColumnsConfig } from 'global/types/dataTables/columnsConfigType'

import { isPending, isSuccess, getErrorMessage } from 'global/redux/toolkit/api'

import useInboxRules from 'sen/components/lib/dialogs/alertDialog/tabs/useInboxRules'
import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'

import { inboxRulesActions } from 'sen/redux/features/dataTables/inboxRules/inboxRulesSlice'
import { DataTableState } from 'sen/redux/types/dataTables'

import { getInboxRules, resetInboxRules, deleteInboxRule } from 'sen/redux/features/remediation/remediationSlice'

import usePermissionBannerLogic from 'global/components/lib/layout/permissionBanner/usePermissionBannerLogic'
import useNewIncidentDialogWizardLogic, {
  ButtonStatus
} from 'sen/components/lib/dialogs/newIncidentDialog/useNewIncidentDialogWizardLogic'

export type Error = string | undefined
export type IsLoaded = boolean
export type InProgress = boolean
export type AtoAccountEmail = string

export interface InboxRulesState {
  isDataLoaded: boolean
  hasInboxRules: boolean
  isPermissionChecked: boolean
  hasPermissions: boolean
  reconnectO365: OnReconnectO365
  isFinalError: boolean
}

export type OnDeleteInboxRule = (inboxRule: ModifiedAlertInboxRule) => void
export type OnReconnectO365 = () => void

export interface ModifiedAlertInboxRule extends InboxRule {
  formattedActions: any
  formattedConditions: any
}

export interface TableConfig {
  tableData: {
    total: number
    data: ModifiedAlertInboxRule[]
  }
  pageConfig: BDSGridPagerConfig
  columns: { [key: string]: string }
  columnsConfig: { [key: string]: ColumnsConfig }
}

export interface UseInboxRulesTableLogic {
  inboxRulesTable: DataTableState
  inboxRules: InboxRule[] | undefined
  updateTable: any
}

export default function useInboxRulesLogic(): [
  AtoAccountEmail,
  TableConfig,
  ButtonStatus,
  OnDeleteInboxRule,
  Error,
  InProgress,
  InboxRulesState
] {
  const {
    accessTokenId,
    formValues,
    inboxRules,
    inboxError,
    inboxRulesTable,
    deleteError,
    deleteInboxRulePending,
    deleteInboxRuleSuccess,
    hasInboxRules,
    o365Permissions,
    isPermissionChecked
  } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken?.accessToken?.id || '',
    formValues: _stores.remediation.incidentEmailSearchFormValues,
    inboxRules: _stores.remediation.inboxRules,
    inboxError: getErrorMessage(_stores.remediation.getInboxRulesApiStatus),
    inboxRulesTable: _stores.dataTables.inboxRules,
    deleteError: getErrorMessage(_stores.remediation.deleteInboxRuleApiStatus),
    deleteInboxRulePending: isPending(_stores.remediation.deleteInboxRuleApiStatus),
    deleteInboxRuleSuccess: isSuccess(_stores.remediation.deleteInboxRuleApiStatus),
    hasInboxRules: !!_stores.remediation.inboxRules?.report.length,
    o365Permissions: _stores.globalRemediation.o365Permissions,
    isPermissionChecked: isSuccess(_stores.globalRemediation.checkO365PermissionsApiStatus)
  }))

  const dispatch = useAppDispatch()
  const [, globalReconnectO365] = usePermissionBannerLogic()
  const [getActions, getConditions] = useInboxRules()

  const [multiStepConfig] = useNewIncidentDialogWizardLogic()

  const currentStep = 4
  const currentPage = 'Inbox Rules Page'

  // Init
  useEffect(() => {
    return () => {
      resetInboxRules()
      inboxRulesActions.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // inbox rules table
  const tableData = useMemo(() => {
    const { skip, take } = inboxRulesTable as DataTableState

    const { data } = process(
      ((inboxRules?.report as []) || []).map((report: InboxRule) => ({
        ...(report && {
          ...report,
          formattedActions: getActions(report),
          formattedConditions: getConditions(report)
        })
      })),
      { skip, take }
    )

    return {
      data: data.filter(report => report.id),
      total: inboxRules?.report.length || 0
    }
  }, [inboxRules, inboxRulesTable, getActions, getConditions])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = inboxRulesTable as DataTableState

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: tableData.total,
      onPageChange: (e: any) => {
        dispatch(inboxRulesActions.update(e.page))
      }
    }
  }, [inboxRulesTable, tableData, dispatch])

  const onDeleteInboxRule = useCallback(
    dataItem => {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.ACCOUNT_COMPROMISE_INCIDENT_WIZARD_EMAIL_PREVIEW, {
        accessTokenId,
        userPrincipalName: formValues.senderEmail,
        ruleId: dataItem.id
      })
      dispatch(deleteInboxRule({ userPrincipalName: formValues.senderEmail, ruleId: dataItem.id }))
    },
    [formValues.senderEmail, dispatch, accessTokenId]
  )

  useEffect(() => {
    if (deleteInboxRuleSuccess) {
      dispatch(resetInboxRules())
      dispatch(getInboxRules(formValues.senderEmail))
    }
  }, [deleteInboxRuleSuccess, dispatch, formValues.senderEmail])

  const reconnectO365 = useCallback(() => {
    globalReconnectO365(config.RECONNECT_SOURCES.ATO_WIZARD)
  }, [globalReconnectO365])

  // dialog action
  const onDispatchNextStep = useCallback(() => {
    dispatch(resetInboxRules())
    multiStepConfig.onNextStep(currentStep, currentPage)
  }, [multiStepConfig, dispatch])

  const onDispatchPrevStep = useCallback(() => {
    dispatch(resetInboxRules())
    multiStepConfig.onPrevStep(currentStep, currentPage)
  }, [multiStepConfig, dispatch])

  return useMemo(() => {
    return [
      formValues.senderEmail,
      {
        tableData,
        pageConfig,
        columns: inboxRulesTable.GRID_COLUMNS,
        columnsConfig: inboxRulesTable.columnsConfig
      },
      {
        disabled: deleteInboxRulePending,
        disableNext: deleteInboxRulePending,
        cancel: 'cancel',
        onNext: 'next',
        onNextStep: onDispatchNextStep,
        onPrevStep: onDispatchPrevStep
      },
      onDeleteInboxRule,
      inboxError || deleteError,
      deleteInboxRulePending,
      {
        isDataLoaded: !!inboxRules?.accessTokenId,
        hasInboxRules,
        hasPermissions:
          o365Permissions.hasReadMailboxSettingsPermission && o365Permissions.hasWriteMailboxSettingsPermission,
        reconnectO365,
        isFinalError: inboxError === 'inbox_rules_final_error',
        isPermissionChecked
      }
    ]
  }, [
    formValues.senderEmail,
    tableData,
    pageConfig,
    inboxRulesTable,
    onDispatchNextStep,
    onDispatchPrevStep,
    reconnectO365,
    onDeleteInboxRule,
    inboxError,
    deleteError,
    deleteInboxRulePending,
    inboxRules,
    isPermissionChecked,
    hasInboxRules,
    o365Permissions.hasReadMailboxSettingsPermission,
    o365Permissions.hasWriteMailboxSettingsPermission
  ])
}
