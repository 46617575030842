import React from 'react'

import globalPrivateRoute from 'global/components/lib/routes/PrivateRoute'
import validateSession from 'global/components/lib/authLayer/validateSession'
import validateRoute from 'global/components/lib/authLayer/validateRoute'

import routesConfig from 'ets/lib/routes/routesConfig'
import validateAppForAccessToken from 'ets/lib/validateAppForAccessToken'

export default globalPrivateRoute((component, childProps, route) => {
  const WrappedComponent = validateSession(
    validateRoute(component, { validateAppForAccessToken, route }),
    routesConfig.SIGNIN_SIGNUP.goto
  )
  return <WrappedComponent {...childProps} />
})
