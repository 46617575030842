import React from 'react'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { Card, Alert } from '@barracuda-internal/bds-core'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import styles from 'global/components/features/static/staticPageStyles'

interface StaticPageLayoutProps {
  logo?: JSX.Element
  children: React.ReactNode
  bgImage?: string
  bgImageGradient?: string
  error?: string | false
  isLoading?: boolean
  small?: boolean
  customLayout?: boolean
}

const StaticPageLayout: React.FC<StaticPageLayoutProps> = props => {
  const { children, bgImageGradient, error, bgImage, isLoading, logo, small, customLayout } = props
  const classes = styles()
  const intl = useIntl()
  const backgroundImage = bgImageGradient || `url(${bgImage})`

  return (
    <div className={classes.root} style={{ backgroundImage, backgroundColor: 'rgba(0, 0, 0, 0.25)' }}>
      {error && (
        <Alert className={classes.alert} variant="filled" severity="error" data-field="signinsignup-alert">
          {intl.formatMessage({ id: error })}
        </Alert>
      )}
      {!customLayout && (
        <Card className={classNames(classes.card, { [classes.small]: small })}>
          {isLoading && (
            <div className={classes.loader}>
              <LinearProgress />
            </div>
          )}
          {logo && <div className={classes.logoContainer}>{logo}</div>}
          {children}
        </Card>
      )}
      {customLayout && children}
    </div>
  )
}

StaticPageLayout.propTypes = {
  logo: PropTypes.any,
  children: PropTypes.any.isRequired,
  bgImage: PropTypes.string.isRequired,
  bgImageGradient: PropTypes.string.isRequired,
  error: PropTypes.any,
  isLoading: PropTypes.bool,
  small: PropTypes.bool,
  customLayout: PropTypes.bool
}

StaticPageLayout.defaultProps = {
  error: false,
  isLoading: false,
  small: false,
  customLayout: false,
  logo: undefined
}

export default StaticPageLayout
