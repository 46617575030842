import deepmerge from 'deepmerge'

import { makeStyles } from '@material-ui/core/styles'
import { COLORS } from 'global/configs/theme/defaults'
import { makeWorkflowFlowStyles } from 'global/components/lib/flowDiagram/flowDiagramStyles'
import selectLabelStyles from 'global/components/lib/select/selectLabelStyles'

export default makeStyles(theme => ({
  addEventRowIconButton: {
    marginTop: theme.spacing(0.125)
  },
  eventRowMenuListNewIcon: {
    marginLeft: `${theme.spacing(1)}px !important`,
    backgroundColor: `${COLORS.RED_600} !important`,
    color: `${COLORS.WHITE} !important`,
    height: '19px !important'
  },
  eventRowSelectInput: {
    marginLeft: '16px',
    minWidth: '100px'
  },
  eventRowTextInput: {
    top: '15px'
  },
  blueprintControls: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '17px'
  },
  conditionDataWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '35px',
    marginTop: '35px',
    '& .Mui-error': {
      paddingBottom: '5px'
    }
  },
  editWorkflowControls: {
    right: 'unset !important',
    left: '4px !important'
  },
  errorText: {
    color: theme.palette.error.main
  },
  flowChart: {
    display: 'flex',
    height: 500,
    position: 'relative'
  },
  helperText: {
    fontWeight: theme.font.weight.regular,
    paddingTop: '20px',
    paddingBottom: '5px'
  },
  inputs: {
    width: '40%'
  },
  loadingContainer: {
    paddingTop: '20px'
  },
  menuItems: {
    width: '100%'
  },
  selectHeader: {
    ...selectLabelStyles.wrappedLabel
  },
  workflowNameInput: {
    '& .Mui-error': {
      paddingBottom: '5px'
    }
  }
}))

export const workflowFlowStyle = {}

export const workflowFlowDiagramStyles = makeStyles(theme => ({
  ...deepmerge(makeWorkflowFlowStyles(theme), workflowFlowStyle)
}))
