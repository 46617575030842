import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Zoom
} from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'global/lib/localization'
import DEFAULTS from 'global/configs/theme/defaults'
import CopyToClipboard from 'global/components/lib/copyToClipboard/CopyToClipboard'

import useResetPasswordDoneDialogLogic from 'sen/components/lib/dialogs/newIncidentDialog/resetPasswordDialog/useResetPasswordDoneDialogLogic'
import styles from 'sen/components/lib/dialogs/newIncidentDialog/newIncidentDialogStyles'

const BASE_I18N_KEY = 'sen.app.new_incident_dialog.step_reset_password_done'
const BASE_I18N_KEY_BUTTONS = 'sen.app.new_incident_dialog.buttons'

export interface ResetPasswordDoneDialogProps {
  open?: boolean
  onClose: () => void
}

export interface Props {
  onOpenNewIncidentDialog: () => void
}

const ResetPasswordDoneDialog: React.FC<ResetPasswordDoneDialogProps & Props> = ({
  open,
  onClose,
  onOpenNewIncidentDialog
}) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatMessageButtons = useFormatMessage(BASE_I18N_KEY_BUTTONS)

  const classes = styles()
  const [displayName, newPassword, onResetCompromisedAccount] = useResetPasswordDoneDialogLogic()

  return useMemo(
    () => (
      <Dialog style={{ zIndex: DEFAULTS.DIALOGS_ZINDEX }} maxWidth="md" fullWidth open TransitionComponent={Zoom}>
        <DialogTitle>
          <Typography className={classes.dialogTitle}>
            {formatMessage('title', {
              displayName,
              b: (txt: string) => <b key={txt}>{txt}</b>
            })}
          </Typography>
        </DialogTitle>
        <Grid container direction="column">
          <DialogContent className={classes.dialogContent}>
            <Grid item className={classes.spacer}>
              <Typography className={classes.text}>{formatMessage('description_password')}</Typography>
            </Grid>
            <Grid container direction="row" className={classes.spacer}>
              <Grid item>
                <TextField
                  id="new-password-read-only"
                  label={formatMessage('placeholder')}
                  defaultValue={newPassword}
                  placeholder={formatMessage('placeholder')}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid item>
                <CopyToClipboard textToCopy={newPassword} customButtonText=" " positionCopiedMessageToRight />
              </Grid>
            </Grid>
            <Grid item className={classes.spacer}>
              <Typography className={classes.redDescriptionText}>{formatMessage('description_copy')}</Typography>
            </Grid>
          </DialogContent>
        </Grid>

        <DialogActions>
          <Button
            size="large"
            color="primary"
            variant="text"
            onClick={() => {
              onResetCompromisedAccount()
              onClose()
            }}
          >
            &nbsp;&nbsp;{formatMessageButtons('close')}&nbsp;&nbsp;
          </Button>
          <Button size="large" color="primary" variant="contained" onClick={onOpenNewIncidentDialog}>
            &nbsp;&nbsp;{formatMessageButtons('clean_up')}&nbsp;&nbsp;
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [
      formatMessage,
      formatMessageButtons,
      classes,
      onClose,
      displayName,
      newPassword,
      onOpenNewIncidentDialog,
      onResetCompromisedAccount
    ]
  )
}

ResetPasswordDoneDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onOpenNewIncidentDialog: PropTypes.func.isRequired
}

export default ResetPasswordDoneDialog
