import { useMemo, useCallback } from 'react'

import { useSelector } from 'react-redux'

import useSpService, { STATES as ATTACK_STATES, REMEDIATION_STATUSES } from 'global/lib/useSpService/useSpService'
import { StatusIds } from 'global/components/lib/statusTypeLabel/StatusIds.enum'
import { AttackType } from 'global/types/api/attackType'

export type GetStatusId = (attack: AttackType) => StatusIds
export type GetCleanupStatusId = (attack: AttackType, cleanupInProgress: boolean) => StatusIds

export interface StatusTypeLabelLogic {
  getStatusId: GetStatusId
  getCleanupStatusId: GetCleanupStatusId
}

export default function useStatusTypeLabelLogicForSpAttacks(): [StatusTypeLabelLogic] {
  const [spService] = useSpService()
  const { listFeedback }: any = useSelector((_stores: any) => ({
    listFeedback: _stores.sp?.listFeedback
  }))
  const getFalsePositiveStatus = useCallback(
    (threatId: string) => {
      const feedbackForThreat = listFeedback?.feedback?.data?.find((feedback: any) => feedback.threatId === threatId)
      return feedbackForThreat && !!feedbackForThreat.markedAsFp
    },
    [listFeedback]
  )

  const getStatusId: GetStatusId = useCallback(
    (attack: AttackType) => {
      if (!attack) {
        return StatusIds.emptyStatus
      }

      const ATTACK_STATUSES = {
        [ATTACK_STATES.QUARANTINED]: StatusIds.movedToJunkFolder,
        [ATTACK_STATES.NOTIFIED_RECIPIENT]: StatusIds.alertedRecipient,
        [ATTACK_STATES.NOTIFIED_ADMIN]: StatusIds.alertedSentinelAdmin,
        [ATTACK_STATES.NOTIFIED_SENDER]: StatusIds.alertedImpersonatedUser,
        [ATTACK_STATES.DELETED]: StatusIds.deleted
      }

      const isMarkedAsFp = getFalsePositiveStatus(attack.threatId)

      switch (true) {
        case isMarkedAsFp || attack.markedAsFp:
          return StatusIds.markedAsFp
        case !isMarkedAsFp:
          return ATTACK_STATUSES[spService.getStatus(attack)] || StatusIds.emptyStatus
        default:
          return StatusIds.emptyStatus
      }
    },
    [spService, getFalsePositiveStatus]
  )

  const getCleanupStatusId: GetCleanupStatusId = useCallback(
    (attack: AttackType, cleanupInProgress: boolean) => {
      if (!attack) {
        return StatusIds.emptyStatus
      }

      const isMarkedAsFp = getFalsePositiveStatus(attack.threatId)

      switch (true) {
        case isMarkedAsFp || attack.markedAsFp:
          return StatusIds.markedAsFpRemediation
        case !isMarkedAsFp && !cleanupInProgress && attack.remediation?.status === REMEDIATION_STATUSES.SUCCESS:
          return StatusIds.deleted
        case !isMarkedAsFp && !cleanupInProgress && attack.remediation?.status === REMEDIATION_STATUSES.FAILURE:
          return StatusIds.failedToDelete
        case !isMarkedAsFp && (cleanupInProgress || attack.remediation?.status === REMEDIATION_STATUSES.IN_PROGRESS):
          return StatusIds.inProgress
        default:
          return StatusIds.emptyStatus
      }
    },
    [getFalsePositiveStatus]
  )

  return useMemo(() => {
    return [{ getStatusId, getCleanupStatusId }]
  }, [getStatusId, getCleanupStatusId])
}
