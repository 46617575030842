import React from 'react'

import { LinearProgress, LinearProgressProps } from '@barracuda-internal/bds-core'
import { withStyles } from '@material-ui/core/styles'

const StyledLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: 'white'
  },
  barColorPrimary: {
    backgroundColor: 'black'
  }
})(LinearProgress)

const LinearProgressComponent: React.FC<LinearProgressProps> = props => {
  return <StyledLinearProgress {...props} />
}

export default LinearProgressComponent
