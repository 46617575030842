/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Typography, Button, Dialog, DialogActions, Zoom, DialogTitle } from '@barracuda-internal/bds-core'

import DEFAULTS from 'global/configs/theme/defaults'
import { useFormatMessage } from 'global/lib/localization'
import EmailDetailsDialog from 'global/components/lib/dialogs/emailDetailsDialog/EmailDetailsDialog'

import styles from 'sen/components/lib/dialogs/dmarcCountries/dmarcCountriesStyles'
import useDmarcCountriesLogic, { Views } from 'sen/components/lib/dialogs/dmarcCountries/useDmarcCountriesLogic'
import DmarcCountriesMap from 'sen/components/lib/dialogs/dmarcCountries/dmarcCountriesMap/DmarcCountriesMap'
import DmarcFraudTable from 'sen/components/lib/dialogs/dmarcCountries/dmarcFraudTable/DmarcFraudTable'

export interface DmarcCountriesDialogProps {
  onClose: () => void
}

const BASE_I18N_KEY = 'sen.app.dmarc_countries_dialog'

export const DmarcCountriesDialog: React.FC<DmarcCountriesDialogProps> = ({ onClose }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  const [
    viewConfig,
    countryConfig,
    fraudConfig,
    dialogActions,
    useEmailDetailsRufSampleInterface
  ] = useDmarcCountriesLogic()
  const [emailDetailDialogConfig, emailDetailDialogActions] = useEmailDetailsRufSampleInterface

  return useMemo(
    () => (
      <Dialog
        style={{ zIndex: DEFAULTS.DIALOGS_ZINDEX }}
        maxWidth="md"
        fullWidth
        open
        TransitionComponent={Zoom}
        data-field="dmarc-countries-dialog"
      >
        <DialogTitle>
          {viewConfig.activeView === Views.map && (
            <>
              <Typography className={classes.dialogTitle} data-field="dialog-map-title">
                {formatMessage('map_title')}
              </Typography>
              <Typography className={classes.dialogSubtitle} data-field="dialog-map-subtitle">
                {formatMessage('map_subtitle')}
              </Typography>
            </>
          )}
          {viewConfig.activeView === Views.table && (
            <Typography className={classes.dialogTitle} data-field="dialog-table-title">
              {formatMessage('table_title', { countryName: countryConfig.selectedCountry.name })}
            </Typography>
          )}
        </DialogTitle>
        {viewConfig.activeView === Views.map && <DmarcCountriesMap onChartSelect={countryConfig.onCountrySelect} />}
        {viewConfig.activeView === Views.table && (
          <DmarcFraudTable country={countryConfig.selectedCountry.code} onOpenFraud={fraudConfig.onOpenFraud} />
        )}

        {viewConfig.activeView === Views.map && (
          <DialogActions className={classes.actions}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={onClose}
              data-field="dialog-map-close-button"
              data-action="map-close-action"
            >
              &nbsp;&nbsp;{formatMessage('close')}&nbsp;&nbsp;
            </Button>
          </DialogActions>
        )}
        {viewConfig.activeView === Views.table && (
          <DialogActions className={classes.actions}>
            <Button
              color="primary"
              variant="text"
              size="large"
              onClick={onClose}
              data-field="dialog-table-close-button"
              data-action="table-close-action"
            >
              &nbsp;&nbsp;{formatMessage('close')}&nbsp;&nbsp;
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={() => dialogActions.onTableBack()}
              data-field="dialog-table-back-button"
              data-action="table-back-action"
            >
              &nbsp;&nbsp;{formatMessage('back')}&nbsp;&nbsp;
            </Button>
          </DialogActions>
        )}

        {emailDetailDialogActions.open && (
          <EmailDetailsDialog data={emailDetailDialogConfig} buttonText={formatMessage('close')} />
        )}
      </Dialog>
    ),
    [
      formatMessage,
      classes,
      viewConfig,
      countryConfig,
      fraudConfig,
      dialogActions,
      onClose,
      emailDetailDialogConfig,
      emailDetailDialogActions
    ]
  )
}

DmarcCountriesDialog.propTypes = {
  onClose: PropTypes.func.isRequired
}

export default DmarcCountriesDialog
