import { splitReducer } from '@splitsoftware/splitio-redux'
import { combineReducers, AnyAction } from 'redux'

import { RootState } from 'fir/redux/toolkit/store'

// global slices
import globalRemediation, {
  INITIAL_STATE as globalRemediationState
} from 'global/redux/features/globalRemediation/globalRemediationSlice'
import accessToken, {
  INITIAL_STATE as accessTokenInitialState
} from 'global/redux/features/accessToken/accessTokenSlice'
import account from 'global/redux/features/account/accountSlice'
import admin from 'global/redux/features/admin/adminSlice'
import analytics, { INITIAL_STATE as analyticsInitialState } from 'global/redux/features/analytics/analyticsSlice'
import app from 'global/redux/features/app/appSlice'
import auth, { logout } from 'global/redux/features/auth/authSlice'
import cloud, { INITIAL_STATE as cloudInitialState } from 'global/redux/features/cloud/cloudSlice'
import convergedMessageLog, {
  INITIAL_STATE as convergedMessageLogInitialState
} from 'global/redux/features/convergedMessageLog/convergedMessageLogSlice'
import unifiedReporting, {
  INITIAL_STATE as unifiedReportingState
} from 'global/redux/features/unifiedReporting/unifiedReportingSlice'
import scheduledReports, {
  INITIAL_STATE as scheduledReportsInitialState
} from 'global/redux/features/scheduledReports/scheduledReportsSlice'

import 'fir/redux/features/auth/authSlice'
import dataTables, { INITIAL_STATE as dataTablesInitialState } from 'fir/redux/features/dataTables/dataTablesSlice'
import emails, { INITIAL_STATE as emailsInitialState } from 'fir/redux/features/emails/emailsSlice'
import insights, { INITIAL_STATE as insightsInitialState } from 'fir/redux/features/insights/insightsSlice'
import newIncident, { INITIAL_STATE as newIncidentInitialState } from 'fir/redux/features/newIncident/newIncidentSlice'
import remediation, { INITIAL_STATE as remediationInitialState } from 'fir/redux/features/remediation/remediationSlice'
import search, { INITIAL_STATE as searchInitialState } from 'fir/redux/features/search/searchSlice'
import securityTraining, {
  INITIAL_STATE as securityTrainingInitialState
} from 'fir/redux/features/securityTraining/securityTrainingSlice'
import settings, { INITIAL_STATE as settingsInitialState } from 'fir/redux/features/settings/settingsSlice'
import stats, { INITIAL_STATE as statsInitialState } from 'fir/redux/features/stats/statsSlice'
import user, { INITIAL_STATE as userInitialState } from 'fir/redux/features/user/userSlice'
import userReported, {
  INITIAL_STATE as userReportedInitialState
} from 'fir/redux/features/userReported/userReportedSlice'
import workflows, { INITIAL_STATE as workflowsInitialState } from 'fir/redux/features/workflows/workflowsSlice'

// Until we convert all reducers to use Redux toolkit, we need to add fir slices here, the combinedReducers, and in appState
export const reducers = {
  dataTables,
  emails,
  insights,
  newIncident,
  remediation,
  scheduledReports,
  search,
  securityTraining,
  settings,
  stats,
  user,
  userReported,
  workflows,
  splitio: splitReducer
}

const combinedReducers = combineReducers({
  accessToken,
  account,
  admin,
  analytics,
  app,
  auth,
  cloud,
  convergedMessageLog,
  dataTables,
  emails,
  globalRemediation,
  insights,
  newIncident,
  remediation,
  scheduledReports,
  search,
  securityTraining,
  settings,
  stats,
  user,
  userReported,
  unifiedReporting,
  workflows,
  splitio: splitReducer
})

export default function(state: RootState, action: AnyAction) {
  let appState = { ...state }

  if (logout.pending.match(action) && appState.accessToken?.accessToken?.id) {
    appState.auth = {
      ...appState.auth,
      logoutValues: {
        accessTokenId: appState.accessToken?.accessToken?.id
      }
    }
  }

  if (logout.fulfilled.match(action)) {
    // reset stores on logout
    appState = {
      ...appState,
      auth: {
        ...appState.auth
      },
      accessToken: accessTokenInitialState,
      analytics: analyticsInitialState,
      cloud: cloudInitialState,
      convergedMessageLog: convergedMessageLogInitialState,
      dataTables: dataTablesInitialState,
      emails: emailsInitialState,
      globalRemediation: globalRemediationState,
      insights: insightsInitialState,
      newIncident: newIncidentInitialState,
      remediation: remediationInitialState,
      scheduledReports: scheduledReportsInitialState,
      search: searchInitialState,
      securityTraining: securityTrainingInitialState,
      settings: settingsInitialState,
      stats: statsInitialState,
      user: userInitialState,
      userReported: userReportedInitialState,
      unifiedReporting: unifiedReportingState,
      workflows: workflowsInitialState
    }
  }

  return combinedReducers(appState, action)
}
