import React from 'react'

import useConvergedMessageLogLogic from './useConvergedMessageLogLogic'

const ConvergedMessageLog: React.FC = () => {
  const [convergedMessageLogsLogic] = useConvergedMessageLogLogic()
  const { messageLogs } = convergedMessageLogsLogic

  return (
    <div>
      <h1>ConvergedMessageLog</h1>
      <div>
        {messageLogs?.map(({ message, timestamp, id }) => (
          <div key={id}>
            <h3>{message}</h3>
            <p>{timestamp}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ConvergedMessageLog
