import { config } from 'global/lib/config'
import { DmarcEntry, DmarcTags, CompanyDomain, DmarcSource, DMARC_MIN_PASSED_RATIO } from 'global/types/api/dmarc'

const POLICY_REJECT = 'reject'
const POLICY_QUARANTINE = 'quarantine'
const POLICY_NONE = 'none'
const DMARC_BARRACUDA_EMAIL = 'dmarc.barracudanetworks.com'

const PROTECTED_POLICIES = [POLICY_QUARANTINE, POLICY_REJECT]
const UNPROTECTED_POLICIES = [POLICY_NONE]

const { DOMAIN_RESOLVE_STATES } = config

export const ALL_POLICIES = [POLICY_NONE, POLICY_QUARANTINE, POLICY_REJECT]
export const DMARC_STATES = DOMAIN_RESOLVE_STATES.DMARC

export type DmarcStateImages = { [key: string]: string }

export const DMARC_STATE_IMAGES = {
  [DMARC_STATES.PROTECTED]: 'statusSuccess',
  [DMARC_STATES.REPORTING]: 'statusWarning',
  [DMARC_STATES.MISCONFIGURED]: 'statusWarning',
  [DMARC_STATES.UNPROTECTED]: 'statusFailed'
} as DmarcStateImages

export function getETSDmarcState(dmarcEntry: DmarcEntry) {
  if (dmarcIsConfigured(dmarcEntry, PROTECTED_POLICIES)) {
    return [DMARC_STATES.PROTECTED, 3]
  }
  if (dmarcIsConfigured(dmarcEntry, UNPROTECTED_POLICIES)) {
    return [DMARC_STATES.REPORTING, 2]
  }

  return [DMARC_STATES.UNPROTECTED, 0]
}

export function getDmarcState(dmarcEntry: DmarcEntry) {
  if (dmarcIsConfigured(dmarcEntry, PROTECTED_POLICIES, true)) {
    return [DMARC_STATES.PROTECTED, 3]
  }
  if (dmarcIsConfigured(dmarcEntry, UNPROTECTED_POLICIES, true)) {
    return [DMARC_STATES.REPORTING, 2]
  }
  if (dmarcEntry?.tags?.p && dmarcPolicyIsInList(dmarcEntry.tags.p, ALL_POLICIES)) {
    return [DMARC_STATES.MISCONFIGURED, 1]
  }

  return [DMARC_STATES.UNPROTECTED, 0]
}

export function getDomainDmarcState(domain: CompanyDomain) {
  return domain.verified ? getDmarcState(domain.dmarc) : [DMARC_STATES.UNVERIFIED]
}

export function dmarcCounts(data: CompanyDomain[], isETS = false): { [key: string]: number } {
  return data.reduce(
    (all: { [key: string]: number }, domain: CompanyDomain) => {
      const [state] = isETS ? getETSDmarcState(domain.dmarc) : getDmarcState(domain.dmarc)

      if (state) {
        return {
          ...all,
          [state]: all[state] + 1
        }
      }

      return all
    },
    {
      [DMARC_STATES.PROTECTED]: 0,
      [DMARC_STATES.REPORTING]: 0,
      [DMARC_STATES.MISCONFIGURED]: 0,
      [DMARC_STATES.UNPROTECTED]: 0
    }
  )
}

export function sortAboutDmarcState(a: any[0], b: any[0]) {
  const [, aValue] = a
  const [, bValue] = b

  if (aValue > bValue) {
    return 1
  }
  if (aValue < bValue) {
    return -1
  }

  return 0
}

export function getReportingRecord(domain: CompanyDomain) {
  return getDmarcRecord(domain, POLICY_NONE)
}

export function getEnforcementRecord(domain: CompanyDomain) {
  return getDmarcRecord(domain, POLICY_REJECT)
}

export function dmarcIsConfiguredThroughBarracuda(dmarc: CompanyDomain['dmarc']) {
  return dmarcIsConfigured(dmarc, ALL_POLICIES, true)
}

export function dmarcIsProtectedThroughBarracuda(dmarc: CompanyDomain['dmarc']) {
  return dmarcIsConfigured(dmarc, PROTECTED_POLICIES, true)
}

export function dmarcIsReportingThroughBarracuda(dmarc: CompanyDomain['dmarc']) {
  return dmarcIsConfigured(dmarc, UNPROTECTED_POLICIES, true)
}

export function isDmarcFailed(source: DmarcSource) {
  return source.dmarcFailRatio > DMARC_MIN_PASSED_RATIO
}

function dmarcIsConfigured(dmarcEntry: DmarcEntry, policies: string[], validateBarracudaSettings = false) {
  if (!dmarcEntry) {
    return false
  }

  const { tags } = dmarcEntry
  let recordCheck = true

  if (validateBarracudaSettings) {
    recordCheck =
      checkRufRuaRecord(tags.ruf, dmarcEntry.domain, DmarcTags.ruf) &&
      checkRufRuaRecord(tags.rua, dmarcEntry.domain, DmarcTags.rua) &&
      tags?.fo === '1'
  }

  return tags?.v === 'DMARC1' && dmarcPolicyIsInList(dmarcEntry.policy, policies) && recordCheck
}

function getDmarcRecord(domain: CompanyDomain, policy?: string) {
  return [
    'v=DMARC1',
    `p=${policy || domain.dmarc?.tags?.p}`,
    'fo=1',
    `rua=${getSampleReportingEmail(domain, DmarcTags.rua)}`,
    `ruf=${getSampleReportingEmail(domain, DmarcTags.ruf)}`
  ].join('; ')
}

function getSampleReportingEmail(domain: CompanyDomain, tag: DmarcTags) {
  const record = `mailto:${tag}+${domain.name}@${DMARC_BARRACUDA_EMAIL}`

  if (domain.dmarc?.tags?.[tag]) {
    return [domain.dmarc?.tags?.[tag]?.split(',').filter(item => !item.includes(`@${DMARC_BARRACUDA_EMAIL}`)), record]
      .filter(item => !!item?.length)
      .join()
  }

  return record
}

function checkRufRuaRecord(record: DmarcEntry['tags']['ruf'], domain: DmarcEntry['domain'], type: DmarcTags) {
  if (record) {
    return record.toLowerCase().includes(`mailto:${type}+${domain.toLowerCase()}@${DMARC_BARRACUDA_EMAIL}`)
  }
  return false
}

function dmarcPolicyIsInList(policy: DmarcEntry['policy'], policies: string[]) {
  return policy && policies.includes(policy)
}

const helpers = { dmarcIsConfigured, getDmarcRecord, getSampleReportingEmail, checkRufRuaRecord, dmarcPolicyIsInList }
export { helpers }
