/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react'

import { TextField, Autocomplete } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'global/lib/localization'
import CircularProgress from 'global/components/lib/circularProgress/CircularProgress'

import { IdentityAutocompleteConfig } from 'sen/components/lib/autocompletes/identity/useIdentityAutocompleteLogic'
import styles from 'sen/components/lib/autocompletes/identity/autocompleteStyles'

export interface IdentityAutocompleteProps extends IdentityAutocompleteConfig {
  inProgress: boolean
  BASE_I18N_KEY: string
  dataTestid?: string
}

const IdentityAutocomplete: React.FC<IdentityAutocompleteProps> = ({
  widget,
  input,
  inProgress,
  BASE_I18N_KEY,
  dataTestid
}) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  return useMemo(
    () => (
      <Autocomplete
        freeSolo
        classes={{ popper: classes.popper }}
        noOptionsText={!widget.loading && formatMessage('no_employees_matching', { email: input.value })}
        disabled={inProgress}
        {...widget}
        renderInput={(params: any) => (
          <TextField
            {...params}
            error={input.error}
            helperText={input.error ? formatMessage('error') : ''}
            placeholder={formatMessage('placeholder')}
            type="text"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {widget.loading ? <CircularProgress size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
        ListboxProps={{
          'data-listbox-id': dataTestid
        }}
      />
    ),
    [widget, input, inProgress, formatMessage, classes, dataTestid]
  )
}

export default IdentityAutocomplete
