import { useCallback, useMemo } from 'react'

import { useFormatMessage } from 'global/lib/localization'
import truncateMiddle from 'global/lib/truncateMiddle/truncateMiddle'

export interface UseStatisticsFieldParams {
  [key: string]: any
  emailStats: any
  shouldHighlightAnalysis: (field: string) => boolean
  switchKeywordHighlighting: boolean
}

export interface StatisticsFieldProps {
  renderBooleanStatisticsFieldValue: (value: string, passI18nPath: string, failedI18nPath: string) => string
  renderFieldContent: (fieldName: string, value: string, ellipsisMiddle?: boolean) => string
}

const BASE_I18N_KEY = 'app.email_details.statistics_tab'
const BASE_I18N_FIELD_KEY = 'app.email_details.statistics_tab.fields'

export default function useStatisticsFieldLogic(): [StatisticsFieldProps] {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatFieldMessage = useFormatMessage(BASE_I18N_FIELD_KEY)

  const renderBooleanStatisticsFieldValue = useCallback(
    (value: string, passI18nPath: string, failedI18nPath: string): string => {
      if (typeof value !== 'boolean') {
        return formatMessage('none')
      }

      return formatMessage(value ? passI18nPath : failedI18nPath)
    },
    [formatMessage]
  )

  const renderFieldContent = useCallback(
    (fieldName: string, value: string | any, ellipsisMiddle?: boolean) => {
      switch (fieldName) {
        case 'isDmarcAuthenticated':
        case 'isDkimAuthenticated':
        case 'isSpfAuthenticated':
          return `${formatFieldMessage(fieldName)} - ${renderBooleanStatisticsFieldValue(value, 'pass', 'fail')}`
        case 'domain':
          return `${ellipsisMiddle ? truncateMiddle(value, 35) : value}`
        case 'senderIpAddress':
        case 'senderIpLocation':
          return `${formatFieldMessage(fieldName)}: ${value}`
        case 'senderIpReputation':
          return `${formatFieldMessage(fieldName)}: ${value}/100`
        case 'senderDomainRegistrationDate':
          return `${formatFieldMessage(fieldName)} ${value}`
        case 'threatsDetectCount':
          return `${value} ${formatFieldMessage(fieldName)}`
        default:
          return `${value}`
      }
    },
    [formatFieldMessage, renderBooleanStatisticsFieldValue]
  )

  return useMemo(() => [{ renderBooleanStatisticsFieldValue, renderFieldContent }], [
    renderBooleanStatisticsFieldValue,
    renderFieldContent
  ])
}
