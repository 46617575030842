export type ChartCountryEmailHeader = [string, string, { p: { html: boolean }; type: string; role: string }]
export const getChartCountryEmailHeader = (): ChartCountryEmailHeader => {
  return [
    'Country',
    'Emails',
    {
      p: {
        html: true
      },
      type: 'string',
      role: 'tooltip'
    }
  ]
}
