/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react'

import { Grid, Typography, Alert, DataTable, DataTableColumn, Button, Tooltip } from '@barracuda-internal/bds-core'
import { Block as BlockIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'
import { Success as SuccessIcon, Critical as CriticalIcon } from '@barracuda-internal/bds-core/dist/Icons/Feedback'
import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'

import { useFormatMessage, useErrorFormatMessage } from 'global/lib/localization'
import SearchField from 'global/components/lib/searchField/SearchField'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import Pager from 'global/components/lib/grid/pager/Pager'
import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'

import { SectionsProps } from 'sen/components/pages/domainFraudDmarcReview/domainFraudDmarcReviewTypes'
import styles from 'sen/components/pages/domainFraudDmarcReview/content/domainFraudDmarcReviewContentStyles'
import useApprovedTabLogic, {
  ModifiedDmarcSource
} from 'sen/components/pages/domainFraudDmarcReview/content/sections/tabs/useApprovedTabLogic'
import InvestigateDmarcDialog from 'sen/components/lib/dialogs/investigateDmarc/InvestigateDmarcDialog'

const BASE_I18N_KEY = 'sen.app.domain_fraud_dmarc_review.sources.tab_contents.approved'
const BASE_I18N_TABLE_KEY = 'sen.app.data_tables.dmarc_sources'

const ApprovedTab: React.FC<SectionsProps> = ({ domain }) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const tableFormatMessage = useFormatMessage(BASE_I18N_TABLE_KEY)
  const errorFormatMessage = useErrorFormatMessage()
  const [logicConfig, eventHandlers] = useApprovedTabLogic({
    domain
  })

  return useMemo(() => {
    return (
      <Grid container direction="column">
        {logicConfig.error && (
          <Alert className={classes.tabMargin} severity="error" data-field="dmarc-review-approved-alert">
            {errorFormatMessage(logicConfig.error)}
          </Alert>
        )}

        <Grid container direction="column">
          <Grid item className={classes.tabInfoContainer} xs>
            <Typography className={classes.tabInfoTitle}>{formatMessage('information_title')}</Typography>
            <Typography className={classes.tabInfoSubtitle}>{formatMessage('information_subtitle_1')}</Typography>
            <br />
            <Typography className={classes.tabInfoSubtitle}>{formatMessage('information_subtitle_2')}</Typography>
          </Grid>

          <Grid item className={classes.searchfieldContainer}>
            <SearchField
              {...logicConfig.searchFieldConfig}
              placeholder={formatMessage('search_placeholder')}
              data-field="search-field"
            />
          </Grid>

          <Grid item className={classes.tabMargin}>
            <Typography data-first-custom-header="true" className={classes.customHeaderTitle}>
              {tableFormatMessage('host')}
            </Typography>
            <Typography data-second-custom-header="true" className={classes.customHeaderTitle}>
              {tableFormatMessage('email_count', {
                br: (txt: string) => (
                  <data key={txt}>
                    <br />
                    {txt}
                  </data>
                )
              })}
            </Typography>
            <Typography className={classes.customHeaderTitle}>{tableFormatMessage('dmarc')}</Typography>
          </Grid>

          <Grid className={classes.progressContainer} container direction="column">
            {logicConfig.tableConfig.inProgress && (
              <LinearProgress className={classes.tableTitleProgress} data-field="linear-progress" />
            )}
          </Grid>

          {!logicConfig.tableConfig.isLoaded && (
            <Grid container className={classes.reservedTableArea} data-field="reserved-table-area" />
          )}
          {logicConfig.tableConfig.isLoaded && (
            <Grid container direction="column" className={classes.tableWrapper} data-field="domains-table-wrapper">
              <Grid
                ref={logicConfig.dmarcSourcesTableRef}
                className={`${
                  logicConfig.tableConfig.isFlexibleTable ? classes.flexibleTableArea : classes.tableArea
                } ${!logicConfig.tableConfig.tableData.total ? 'empty-table' : ''}`}
                data-table="all-volume-sources"
                item
                xs={12}
                data-field="grid-table"
              >
                <DataTable
                  data-testid="all-volume-sources-table"
                  data-field="data-table"
                  className={`${
                    logicConfig.tableConfig.tableData.total
                      ? logicConfig.tableConfig.isFlexibleTable
                        ? classes.flexibleDataTable
                        : classes.dataTable
                      : classes.emptyDataTable
                  } alerts-table`}
                  data={logicConfig.tableConfig.tableData}
                  {...logicConfig.tableConfig.pageConfig}
                  pager={Pager}
                >
                  <DataTableNoRecords data-field="empty-table">
                    <Typography>
                      {!logicConfig.tableConfig.tableData.total && tableFormatMessage('empty_table')}
                    </Typography>
                  </DataTableNoRecords>

                  <DataTableColumn
                    field={logicConfig.tableConfig.columns.HOST}
                    {...logicConfig.tableConfig.columnsConfig[logicConfig.tableConfig.columns.HOST]}
                    title={' '}
                    cell={({ dataItem }: { dataItem: ModifiedDmarcSource }) => (
                      <Cell className={classes.cell} data-field="table-column-host">
                        <CellText ellipsis className={classes.normalCell} data-field="table-column-host-text">
                          {!dataItem.product && (
                            <Tooltip title={dataItem.host} placement="top-start">
                              <data>{dataItem.host}</data>
                            </Tooltip>
                          )}
                          {dataItem.product && (
                            <Tooltip
                              title={`${dataItem.product.name} (${dataItem.host})`}
                              placement="top-start"
                              data-field="product-tooltip"
                            >
                              <data>
                                {dataItem.product.name}
                                <br />
                                <data className={classes.lightText}>({dataItem.host})</data>
                              </data>
                            </Tooltip>
                          )}
                        </CellText>
                      </Cell>
                    )}
                  />

                  <DataTableColumn
                    width={150}
                    field={logicConfig.tableConfig.columns.EMAIL_COUNT}
                    {...logicConfig.tableConfig.columnsConfig[logicConfig.tableConfig.columns.EMAIL_COUNT]}
                    title={' '}
                    cell={({ dataItem }: { dataItem: ModifiedDmarcSource }) => (
                      <Cell className={classes.cell} data-field="table-column-email-count">
                        <CellText className={classes.normalCell} data-field="table-column-email-count-text">
                          {!dataItem.inProgressAction && dataItem.emailCount.toLocaleString()}
                        </CellText>
                      </Cell>
                    )}
                  />

                  <DataTableColumn
                    width={150}
                    field={logicConfig.tableConfig.columns.DMARC}
                    {...logicConfig.tableConfig.columnsConfig[logicConfig.tableConfig.columns.DMARC]}
                    title={' '}
                    cell={({ dataItem }: { dataItem: ModifiedDmarcSource }) => (
                      <Cell className={classes.cell} data-field="table-column-dmarc">
                        <CellText
                          className={dataItem.isDmarcFailed ? classes.failedNormaCell : classes.greenNormaCell}
                          data-field="table-column-dmarc-text"
                        >
                          {dataItem.isDmarcFailed && (
                            <>
                              <CriticalIcon className={classes.failedIcon} />
                              {Math.round(dataItem.dmarcFailRatio)}%&nbsp;
                              {formatMessage('failed')}
                            </>
                          )}
                          {!dataItem.isDmarcFailed && (
                            <>
                              <SuccessIcon className={classes.passedIcon} />
                              {100 - Math.round(dataItem.dmarcFailRatio)}%&nbsp;
                              {formatMessage('passed')}
                            </>
                          )}
                        </CellText>
                      </Cell>
                    )}
                  />

                  <DataTableColumn
                    width={220}
                    field={logicConfig.tableConfig.columns.ACTIONS}
                    {...logicConfig.tableConfig.columnsConfig[logicConfig.tableConfig.columns.ACTIONS]}
                    title={' '}
                    cell={({ dataItem }: { dataItem: ModifiedDmarcSource }) => (
                      <Cell className={classes.cell} data-field="table-column-actions">
                        <CellText className={classes.actionCell} data-field="table-column-email-actions">
                          <Grid container direction="column" justifyContent="flex-end">
                            <Button
                              className={classes.investigateButton}
                              size="small"
                              color="primary"
                              variant="text"
                              onClick={() => {
                                eventHandlers.onOpenInvestigateDialog(dataItem)
                              }}
                              data-action="open-investigate-dialog"
                            >
                              {formatMessage('investigate')}
                            </Button>
                            <Button
                              disabled={logicConfig.isMarkInProgress}
                              size="small"
                              color="primary"
                              variant="outlined"
                              onClick={() => {
                                eventHandlers.onMarkAsRejected(dataItem)
                              }}
                              data-action="mark-as-rejected"
                            >
                              <BlockIcon className={classes.blockIcon} />
                              {formatMessage('mark_as_rejected')}
                            </Button>
                          </Grid>
                        </CellText>
                      </Cell>
                    )}
                  />
                </DataTable>
              </Grid>
            </Grid>
          )}
        </Grid>

        {logicConfig.investigateDialogConfig.isOpen && logicConfig.investigateDialogConfig.source && (
          <InvestigateDmarcDialog
            domain={domain}
            dmarcSource={logicConfig.investigateDialogConfig.source}
            onClose={logicConfig.investigateDialogConfig.toggle}
          />
        )}
      </Grid>
    )
  }, [classes, formatMessage, logicConfig, errorFormatMessage, tableFormatMessage, eventHandlers, domain])
}

export default ApprovedTab
