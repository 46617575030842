import React, { useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'

import operators, { CURIO_CONTAINS, CURIO_CONTAINS_PHRASE } from './operators'

export interface TextFilterLogicProps {
  intl: any
  firstFilterProps: any
  operators?: string[]
}

const BASE_I18N_KEY = 'app.filters'

export default function textFilterLogic(WrappedComponent: React.ComponentType<any>) {
  const TextFilterLogic: React.FC<TextFilterLogicProps> = props => {
    // shallow update filter
    const updateFilter = useCallback(
      (config: any) => {
        props.firstFilterProps.onChange({
          value: props.firstFilterProps.value,
          operator: props.firstFilterProps.operator,
          ...config
        })
      },
      [props.firstFilterProps]
    )

    // init
    useEffect(() => {
      updateFilter({ operator: operators[(props.operators || [])[0]] })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // textField
    const onValueChange = useCallback(
      (e: any) => {
        updateFilter({ value: e.target.value })
      },
      [updateFilter]
    )

    // dropdown
    const onDropdownChange = useCallback(
      (e: any) => {
        updateFilter({ operator: e.target.value })
      },
      [updateFilter]
    )

    const dropdownValues = useMemo(() => {
      return (props.operators || []).map((operator: string) => ({
        value: operators[operator],
        content: props.intl.formatMessage({ id: `${BASE_I18N_KEY}.${operator.toLowerCase()}` })
      }))
    }, [props.intl, props.operators])

    return (
      <WrappedComponent
        {...props}
        onDropdownChange={onDropdownChange}
        dropdownValues={dropdownValues}
        onValueChange={onValueChange}
        value={props.firstFilterProps.value}
        selectedOperator={props.firstFilterProps.operator}
      />
    )
  }

  TextFilterLogic.propTypes = {
    intl: PropTypes.any.isRequired,
    operators: PropTypes.array,
    firstFilterProps: PropTypes.any.isRequired
  }

  TextFilterLogic.defaultProps = {
    operators: [CURIO_CONTAINS_PHRASE, CURIO_CONTAINS]
  }

  return TextFilterLogic
}
