import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

import {
  Alert,
  Button,
  DataTable,
  DataTableColumn,
  DialogContent,
  Grid,
  Typography,
  Tooltip,
  Box
} from '@barracuda-internal/bds-core'
import {
  Delete as DeleteIcon,
  CheckmarkCircle as CheckCircleOutlineIcon
} from '@barracuda-internal/bds-core/dist/Icons/Core'

import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'

import CircularProgress from 'global/components/lib/circularProgress/CircularProgress'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'

import { InboxRule } from 'global/types/api/remediation'

import Pager from 'global/components/lib/grid/pager/Pager'
import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'

import { useFormatMessage, useErrorFormatMessage } from 'global/lib/localization'

import BaseDialogActions from 'sen/components/lib/dialogs/newIncidentDialog/lib/BaseDialogActions'
import useInboxRulesLogic from 'sen/components/lib/dialogs/newIncidentDialog/steps/useInboxRulesLogic'

import styles from 'sen/components/lib/dialogs/newIncidentDialog/newIncidentDialogStyles'

const BASE_I18N_KEY = 'sen.app.new_incident_dialog.step_inbox_rules'
const BASE_I18N_TABLE_KEY = 'sen.app.data_tables.inbox_rules'

export interface ModifiedAlertInboxRule extends InboxRule {
  formattedActions: any
  formattedConditions: any
}

export interface InboxRulesProps {
  onClose: () => void
  activeStep: number
}

const InboxRules: React.FC<InboxRulesProps> = ({ onClose, activeStep }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatMessageTable = useFormatMessage(BASE_I18N_TABLE_KEY)
  const formatMessageError = useErrorFormatMessage()

  const classes = styles()

  const [
    atoAccountEmail,
    tableConfig,
    buttonStatus,
    onDeleteInboxRule,
    error,
    inProgress,
    inboxRulesState
  ] = useInboxRulesLogic()

  return useMemo(
    () => (
      <Grid container item direction="column">
        <DialogContent className={classes.dialogContent}>
          {!inboxRulesState.isFinalError && error && (
            <Alert className={classes.error} variant="filled" severity="error">
              {formatMessageError(error)}
            </Alert>
          )}

          {!inboxRulesState.isPermissionChecked ||
            (!inboxRulesState.isDataLoaded && !error && (
              <Grid container justifyContent="center" className={classes.contentLoading}>
                <CircularProgress />
              </Grid>
            ))}

          {inboxRulesState.isDataLoaded && inboxRulesState.isPermissionChecked && !inboxRulesState.hasPermissions && (
            <Grid container item direction="row">
              <Grid container item xs={11} spacing={2} direction="column">
                <Grid item>
                  <Typography className={classes.title}>
                    {formatMessage('title_no_permission', { email: atoAccountEmail })}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.text}>{formatMessage('description_no_permission')}</Typography>
                </Grid>
                <Button
                  color="primary"
                  size="large"
                  data-field="add_permission"
                  onClick={inboxRulesState.reconnectO365}
                >
                  {formatMessage('add_permission')}
                </Button>
              </Grid>
            </Grid>
          )}

          {inboxRulesState.isPermissionChecked && !inboxRulesState.hasInboxRules && inboxRulesState.isFinalError && (
            <Grid container item direction="row">
              <Grid container item xs={11} spacing={2} direction="column">
                <Grid item>
                  <Typography className={classes.title}>
                    {formatMessage('title_no_rules_final_error', { email: atoAccountEmail })}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.text}>{formatMessage('description_no_rules_final_error')}</Typography>
                </Grid>
              </Grid>
            </Grid>
          )}

          {inboxRulesState.isDataLoaded && inboxRulesState.isPermissionChecked && !inboxRulesState.hasInboxRules && (
            <Grid container item direction="row">
              <Grid container item xs={1} alignItems="center" justifyContent="center">
                <CheckCircleOutlineIcon className={classes.checkcircleIcon} />
              </Grid>
              <Grid container item xs={11} spacing={2} direction="column">
                <Grid item>
                  <Typography className={classes.title}>
                    {formatMessage('title_no_rules_no_error', { email: atoAccountEmail })}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.text}>
                    {formatMessage('description_no_rules_no_error', { email: atoAccountEmail })}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {inboxRulesState.isDataLoaded && inboxRulesState.isPermissionChecked && inboxRulesState.hasInboxRules && (
            <Grid container item>
              <Grid container spacing={2}>
                <Grid item>
                  <Typography className={classes.title}>
                    {formatMessage('title', { email: atoAccountEmail })}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.text}>{formatMessage('description')}</Typography>
                </Grid>
              </Grid>

              <Grid className={classes.flexibleTableArea} data-table="inbox-rules" item xs={12}>
                {inProgress && <LinearProgress className={classes.indicator} />}
                <DataTable
                  className={classes.flexibleDataTable}
                  data={tableConfig.tableData}
                  {...tableConfig.pageConfig}
                  pager={Pager}
                >
                  <DataTableNoRecords>
                    <Typography>{!tableConfig.tableData.total && formatMessageTable('empty_table')}</Typography>
                  </DataTableNoRecords>
                  <DataTableColumn
                    width={100}
                    field={tableConfig.columns.SEQUENCE}
                    {...tableConfig.columnsConfig[tableConfig.columns.SEQUENCE]}
                    title={formatMessageTable(`${tableConfig.columns.SEQUENCE}`)}
                    cell={({ dataItem }: { dataItem: ModifiedAlertInboxRule }) => (
                      <Cell className={classes.cell} data-field="table-column-squence">
                        <CellText ellipsis className={classes.normalCell} data-field="table-column-sequence-text">
                          {dataItem.sequence}
                        </CellText>
                      </Cell>
                    )}
                  />
                  <DataTableColumn
                    field={tableConfig.columns.NAME}
                    {...tableConfig.columnsConfig[tableConfig.columns.NAME]}
                    title={formatMessageTable(`${tableConfig.columns.NAME}`)}
                    cell={({ dataItem }: { dataItem: ModifiedAlertInboxRule }) => (
                      <Cell className={classes.flexibleCell} data-field="table-column-name">
                        <CellText className={classes.normalCell} data-field="table-column-name-text">
                          {dataItem.displayName}
                        </CellText>
                      </Cell>
                    )}
                  />
                  <DataTableColumn
                    field={tableConfig.columns.ACTIONS}
                    {...tableConfig.columnsConfig[tableConfig.columns.ACTIONS]}
                    title={formatMessageTable(`${tableConfig.columns.ACTIONS}`)}
                    cell={({ dataItem }: { dataItem: ModifiedAlertInboxRule }) => (
                      <Cell className={classes.flexibleCell} data-field="table-column-actions">
                        <CellText className={classes.breakWordCell} data-field="table-column-actions-text">
                          {dataItem.formattedActions
                            .reduce((all: any[], action: string) => {
                              return [...all, action, <br key={uuidv4()} />]
                            }, [])
                            .slice(0, -1)}
                        </CellText>
                      </Cell>
                    )}
                  />
                  <DataTableColumn
                    field={tableConfig.columns.CONDITIONS}
                    {...tableConfig.columnsConfig[tableConfig.columns.CONDITIONS]}
                    title={formatMessageTable(`${tableConfig.columns.CONDITIONS}`)}
                    cell={({ dataItem }: { dataItem: ModifiedAlertInboxRule }) => (
                      <Cell className={classes.flexibleCell} data-field="table-column-conditions">
                        <CellText className={classes.breakWordCell} data-field="table-column-conditions-text">
                          {dataItem.formattedConditions
                            .reduce((all: any[], action: string) => {
                              return [...all, action, <br key={uuidv4()} />]
                            }, [])
                            .slice(0, -1)}
                        </CellText>
                      </Cell>
                    )}
                  />
                  <DataTableColumn
                    width={100}
                    field={tableConfig.columns.EXCEPTIONS}
                    {...tableConfig.columnsConfig[tableConfig.columns.EXCEPTIONS]}
                    title={formatMessageTable(tableConfig.columns.EXCEPTIONS)}
                    cell={({ dataItem }: { dataItem: ModifiedAlertInboxRule }) => (
                      <Cell className={classes.cell} data-field="table-column-exceptions">
                        <CellText className={classes.normalCell} data-field="table-column-exceptions-text">
                          {formatMessageTable(dataItem.exceptions ? 'yes' : 'no')}
                        </CellText>
                      </Cell>
                    )}
                  />
                  <DataTableColumn
                    width={100}
                    field={tableConfig.columns.ENABLED}
                    {...tableConfig.columnsConfig[tableConfig.columns.ENABLED]}
                    title={formatMessageTable(tableConfig.columns.ENABLED)}
                    cell={({ dataItem }: { dataItem: ModifiedAlertInboxRule }) => (
                      <Cell className={classes.cell} data-field="table-column-enabled">
                        <CellText className={classes.normalCell} data-field="table-column-enabled-text">
                          {formatMessageTable(dataItem.isEnabled ? 'yes' : 'no')}
                        </CellText>
                      </Cell>
                    )}
                  />
                  <DataTableColumn
                    width={50}
                    field={tableConfig.columns.DELETE}
                    {...tableConfig.columnsConfig[tableConfig.columns.DELETE as any]}
                    title=" "
                    cell={({ dataItem }: { dataItem: ModifiedAlertInboxRule }) => (
                      <Cell className={classes.cell} data-field="table-column-delete">
                        <CellText>
                          <Tooltip
                            classes={{ tooltip: classes.tooltip }}
                            title={formatMessage('delete_rule')}
                            placement="top-start"
                            data-field="table-column-delete-tooltip"
                          >
                            <Box>
                              <DeleteIcon
                                data-testid={`delete-${dataItem.id}`}
                                onClick={() => {
                                  onDeleteInboxRule(dataItem)
                                }}
                                className={classes.icon}
                                data-action="inbox-rule-delete"
                              />
                            </Box>
                          </Tooltip>
                        </CellText>
                      </Cell>
                    )}
                  />
                </DataTable>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <BaseDialogActions activeStep={activeStep} buttonStatus={buttonStatus} onClose={onClose} />
      </Grid>
    ),
    [
      classes,
      formatMessage,
      formatMessageTable,
      formatMessageError,
      tableConfig,
      buttonStatus,
      onDeleteInboxRule,
      activeStep,
      atoAccountEmail,
      onClose,
      error,
      inProgress,
      inboxRulesState
    ]
  )
}

InboxRules.propTypes = {
  onClose: PropTypes.func.isRequired,
  activeStep: PropTypes.number.isRequired
}

export default InboxRules
