import { createAsyncThunk } from '@reduxjs/toolkit'
import restClient, { validateApiError } from 'global/lib/api/restClient'
import apiRoutes from 'sen/lib/api/apiRoutes'

export const sendFeedback = createAsyncThunk('FEEDBACK/sendFeedback', async function doSendFeedback(
  payload: { publicKey: string; userEmail: string },
  { rejectWithValue }
) {
  const { publicKey, userEmail } = payload
  try {
    const resp = await restClient(apiRoutes.SEND_FEEDBACK, {
      urlParams: { publicKey },
      data: { comment: null, userEmail }
    })

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})
