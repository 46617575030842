import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Card, Link, Button, MenuItem, Select, SelectWrapper, Typography } from '@barracuda-internal/bds-core'

import InputLabel from '@material-ui/core/InputLabel'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import PermissionDialog from 'global/components/features/signinSignup/permissionDialog/PermissionDialog'
import IMAGES from 'global/configs/theme/images.config'
import useSigninConnectLogic, {
  UseSigninConnectLogicProps
} from 'global/components/features/signinSignup/signinConnect/useSigninConnectLogic'
import styles from 'global/components/features/signinSignup/signinConnect/signinConnectStyles'

import DEFAULTS from 'global/configs/theme/defaults'
import { useFormatMessage } from 'global/lib/localization'

export interface SigninConnectProps extends UseSigninConnectLogicProps {
  localizationPath: string
}

export const SigninConnect: React.FC<SigninConnectProps> = ({ localizationPath, ...logicProps }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const classes = styles()
  const BASE_I18N_KEY = localizationPath
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [signinConnectLogic] = useSigninConnectLogic(logicProps)

  const {
    appName,
    isPermissionDialogVisible,
    loggedInUser,
    multipleAccountConfig,
    onConnect,
    onTogglePermissionDialog,
    onUseAnotherAccount,
    requestDemoPath,
    sendDemoRequestTrackingEvent
  } = signinConnectLogic

  return (
    <>
      <Card className={classes.wrapper} elevation={4}>
        <div>{false && <LinearProgress />}</div>
        <Typography className={classes.title} align="center" data-field="title">
          {formatMessage('title')}
        </Typography>
        <Typography className={classes.subtitle1} align="left" data-field="subtitle-1">
          {formatMessage('subtitle_1')}
        </Typography>
        <Typography className={classes.subtitle2} align="left" data-field="subtitle-2">
          {formatMessage('subtitle_2', {
            perm: (txt: any) => (
              <data
                id="permission-text"
                key={txt}
                onClick={onTogglePermissionDialog}
                role="presentation"
                className={classes.blueText}
                data-action="permission-dialog-toggle"
                data-field="toggle-permission"
                ref={setAnchorEl}
              >
                {txt}
              </data>
            ),
            demo: (txt: any) => (
              <Link
                key={txt}
                className={classes.blueText}
                target="_blank"
                href={requestDemoPath}
                data-field="hyperlink"
                onClick={sendDemoRequestTrackingEvent}
              >
                {txt}
              </Link>
            )
          })}
        </Typography>
        {isPermissionDialogVisible && (
          <PermissionDialog
            title={formatMessage('permission_dialog_title')}
            appName={appName}
            onClose={onTogglePermissionDialog}
            anchorEl={anchorEl as HTMLElement}
          />
        )}
        {multipleAccountConfig.isActive && (
          <SelectWrapper className={classes.selectWrapper} size="medium" data-field="select-wrapper">
            <InputLabel className={classes.selectText}>{formatMessage('account_want_to_connect')}</InputLabel>
            <Select
              MenuProps={{
                style: { zIndex: DEFAULTS.DIALOGS_ZINDEX }
              }}
              value={multipleAccountConfig.selectedAccountId}
              onChange={multipleAccountConfig.onAccountChange}
            >
              {multipleAccountConfig.accounts.map((account: any) => (
                <MenuItem className={classes.menuItem} key={account.accountId} value={account.accountId}>
                  {account.accountName}
                </MenuItem>
              ))}
            </Select>
          </SelectWrapper>
        )}
        <Button className={classes.o365Button} onClick={onConnect} variant="contained" data-action="connect">
          <img className={classes.formO365Icon} alt="" src={IMAGES.o365LogoWhite} data-field="o365-icon" />
          {formatMessage('o365_button')}
        </Button>
      </Card>
      <div className={classes.footerWrapper}>
        <Typography className={classes.footer1} align="center" data-field="footer-1">
          {loggedInUser}
        </Typography>
        <Typography
          className={classes.footer2}
          align="center"
          role="presentation"
          onClick={onUseAnotherAccount}
          data-field="footer-2"
        >
          {formatMessage('footer_text')}
        </Typography>
      </div>
    </>
  )
}

SigninConnect.propTypes = {
  localizationPath: PropTypes.string.isRequired,
  routesConfig: PropTypes.any.isRequired,
  o365Connect: PropTypes.any.isRequired,
  appName: PropTypes.string,
  location: PropTypes.any
}

SigninConnect.defaultProps = {
  appName: undefined,
  location: undefined
}

export default SigninConnect
