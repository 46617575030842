import React, { useMemo } from 'react'

import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Step,
  StepLabel,
  Stepper
} from '@barracuda-internal/bds-core'

import { getCountryNameFromCode2 } from 'global/lib/isoCountries'
import Alert from 'global/components/lib/alerts/Alert'
import { useFormatMessage } from 'global/lib/localization'

import AdditionalActions from 'fir/components/lib/additionalActionsDialog/pages/AdditionalActions'
import useNewIncidentDialogLogic, {
  STEPS,
  NewIncidentLogicParams
} from 'fir/components/lib/newIncidentDialog/useNewIncidentDialogLogic'
import SearchingForEmails from 'fir/components/lib/newIncidentDialog/pages/SearchingForEmails'
import ReviewMessages from 'fir/components/lib/newIncidentDialog/pages/ReviewMessages'
import UserOptions from 'fir/components/lib/newIncidentDialog/pages/UserOptions'
import PolicyOptions from 'fir/components/lib/newIncidentDialog/pages/PolicyOptions'
import styles from 'fir/components/lib/newIncidentDialog/newIncidentStyles'

const BASE_I18N_KEY = 'fir.app.new_incident_wizard'

export type NewIncidentDialogProps = NewIncidentLogicParams

const NewIncidentDialog: React.FC<NewIncidentDialogProps> = props => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const [newIncidentDialogLogic] = useNewIncidentDialogLogic(props)
  const { isOpened, country, onClose } = props

  return useMemo(() => {
    const {
      activeStep,
      alertConfig = { alertContent: '' },
      buttons,
      contentConfig,
      dialogActions,
      errorMsg = '',
      isCreatingIncident = false,
      isEntitledBCS = false,
      isEntitledESS = false,
      onNextStep,
      onPrevStep
    } = newIncidentDialogLogic

    return (
      <Dialog fullWidth maxWidth="lg" open={isOpened}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {STEPS.map((label: string) => (
            <Step key={label}>
              <StepLabel>{formatMessage(`steps.${label}`)}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {!!errorMsg.length && <Alert {...alertConfig} />}
        <DialogTitle>
          <Typography variant="h5" component="span">
            {activeStep === 0
              ? formatMessage(`labels.${country ? 'country_search' : 'search'}`, {
                  country: getCountryNameFromCode2(country)
                })
              : formatMessage(`labels.${isCreatingIncident ? 'creating_incident' : STEPS[activeStep]}`)}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {activeStep === 0 && <SearchingForEmails {...contentConfig} />}
          {activeStep === 1 && <ReviewMessages {...contentConfig} />}
          {activeStep === 2 && <UserOptions {...contentConfig} />}
          {activeStep === 3 && (
            <PolicyOptions
              {...contentConfig}
              isCreatingIncident={isCreatingIncident}
              isEntitledBCS={isEntitledBCS}
              isEntitledESS={isEntitledESS}
            />
          )}
          {activeStep === 4 && <AdditionalActions {...contentConfig} />}
          <DialogActions ref={dialogActions} className={classes.dialogButtons}>
            {buttons[activeStep].cancel && (
              <Button
                onClick={() => onClose(activeStep)}
                color="primary"
                variant="text"
                disabled={buttons[activeStep].disabled}
              >
                {formatMessage(`buttons.${buttons[activeStep].cancel}`)}
              </Button>
            )}
            {buttons[activeStep].onPrev && (
              <Button onClick={onPrevStep} variant="outlined" color="primary" disabled={buttons[activeStep].disabled}>
                {formatMessage(`buttons.${buttons[activeStep].onPrev}`)}
              </Button>
            )}
            {buttons[activeStep].onNext && (
              <Button
                onClick={onNextStep}
                color="primary"
                variant="contained"
                disabled={buttons[activeStep].disabled || buttons[activeStep].disableNext}
              >
                {formatMessage(`buttons.${buttons[activeStep].onNext}`)}
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
    )
  }, [newIncidentDialogLogic, classes, country, formatMessage, isOpened, onClose])
}

export default NewIncidentDialog
