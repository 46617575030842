import React from 'react'
import PropTypes from 'prop-types'

import styles from 'global/components/lib/layout/navbar/navbarStyles'

export interface NavbarControlWrapperProps {
  children?: null | JSX.Element | JSX.Element[]
}

const NavbarControlWrapper: React.FC<NavbarControlWrapperProps> = ({ children }) => {
  const classes = styles()

  return <div className={classes.controlWrapper}>{children}</div>
}

NavbarControlWrapper.propTypes = {
  children: PropTypes.any
}

NavbarControlWrapper.defaultProps = {
  children: null
}

export default NavbarControlWrapper
