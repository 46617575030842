import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { ApiStatus, failedResponse, inIdle, inProgress, successResponse } from 'global/redux/toolkit/api'
import restClient, { validateApiError } from 'global/lib/api/restClient'

import { globalApiRoutes } from 'global/lib/api/apiRoutes'

import { O365Permissions } from 'global/types/api/remediation'

export interface GlobalRemediationState {
  o365Permissions: O365Permissions
  checkO365PermissionsApiStatus: ApiStatus
}

// initialState
export const INITIAL_STATE: GlobalRemediationState = {
  o365Permissions: {
    hasReadMailboxSettingsPermission: false,
    hasWriteMailboxSettingsPermission: false,
    hasReadActivityFeedPermission: false
  },
  checkO365PermissionsApiStatus: inIdle
}

export const checkO365Permissions = createAsyncThunk(
  'GLOBAL_REMEDIATION/checkO365Permissions',
  async function doCheckO365Permissions(_, { rejectWithValue }) {
    try {
      const resp = await restClient(globalApiRoutes.CHECK_O365_PERMISSIONS, { data: {} })

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

/* eslint-disable no-param-reassign */
export const globalRemediationSlice = createSlice({
  name: 'GLOBAL_REMEDIATION',
  initialState: INITIAL_STATE,
  reducers: {
    resetO365Permissions: state => {
      state.o365Permissions = { ...INITIAL_STATE.o365Permissions }
    }
  },
  extraReducers: builder => {
    builder
      // checkO365Permissions
      .addCase(checkO365Permissions.pending, state => {
        state.checkO365PermissionsApiStatus = inProgress
        state.o365Permissions = { ...INITIAL_STATE.o365Permissions }
      })
      .addCase(checkO365Permissions.fulfilled, (state, { payload }) => {
        state.checkO365PermissionsApiStatus = successResponse
        state.o365Permissions = payload
      })
      .addCase(checkO365Permissions.rejected, (state, action) => {
        state.checkO365PermissionsApiStatus = failedResponse(action.payload as string)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { resetO365Permissions } = globalRemediationSlice.actions

export default globalRemediationSlice.reducer
