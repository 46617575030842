import { useMemo, useCallback, useReducer, useEffect } from 'react'

import { process } from '@progress/kendo-data-query'

import { BDSGridPagerConfig, BDSGridSortConfig } from 'global/types/dataTables/dataTables'
import { SearchFieldProps, useSearchFieldLogic } from 'global/components/lib/searchField/SearchField'
import { ColumnsConfig } from 'global/types/dataTables/columnsConfigType'
import { ExportToCsvButtonProps } from 'global/components/lib/exportToCsvButton/ExportToCsvButton'
import useProductLib from 'global/lib/product/useProduct'
import useUserDataLib from 'global/lib/userData/useUserData'
import { resetAlerts } from 'global/redux/features/app/appSlice'
import { FEATURES, isMyFeatureOn } from 'global/lib/splitio/splitio'
import { isPending } from 'global/redux/toolkit/api'

import {
  update as updateMailboxesTable,
  reset as resetMailboxesTable
} from 'sen/redux/features/dataTables/mailboxes/mailboxesSlice'
import { getMonitoredIdentities, resetMonitoredIdentities } from 'sen/redux/features/sp/spSlice'
import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import apiRoutes from 'sen/lib/api/apiRoutes'

const SEARCH_FIELD_ID = 'mailboxes-table-search'

export type InProgress = boolean
export interface TableConfig {
  tableData: {
    total: number
    data: any[]
  }
  pageConfig: BDSGridPagerConfig
  sortConfig: BDSGridSortConfig | {}
  columns: { [key: string]: string }
  columnsConfig: { [key: string]: ColumnsConfig }
  highlightKeywords: string[]
  isLoaded: boolean
}

export interface LicenseInformation {
  enabled: boolean
  used: number
  purchased: number
  showLimitError: boolean
  hasAlerts: boolean
}

export default function useMailboxesDialogLogic(): [
  LicenseInformation,
  SearchFieldProps,
  InProgress,
  TableConfig,
  ExportToCsvButtonProps
] {
  const dispatch = useAppDispatch()
  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    searchString: ''
  })
  const [productLib] = useProductLib()
  const [userDataLib] = useUserDataLib()
  const {
    accessTokenId,
    alerts,
    inProgress,
    loadedOffsets,
    mailboxesTable,
    monitoredIdentities,
    splitStore,
    usedMailboxes
  } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id || '',
    alerts: _stores.app.alerts,
    inProgress: isPending(_stores.sp.getMonitoredIdentitiesApiStatus),
    loadedOffsets: _stores.sp.monitoredIdentitiesLoadedOffsets,
    mailboxesTable: _stores.dataTables.mailboxes,
    monitoredIdentities: _stores.sp.monitoredIdentities,
    splitStore: _stores.splitio,
    usedMailboxes: _stores.cm.stats?.mailboxCount || 0
  }))

  // Init
  useEffect(() => {
    dispatch(getMonitoredIdentities())

    return () => {
      dispatch(resetMonitoredIdentities(true))
      dispatch(resetMailboxesTable())
      dispatch(resetAlerts())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const capacity: number = useMemo(() => {
    return productLib.getSentinelCapacityForAccessToken(accessTokenId) || 0
  }, [accessTokenId, productLib])

  const showLimitError = useMemo(() => {
    return !!monitoredIdentities?.accessTokenId && usedMailboxes > capacity
  }, [monitoredIdentities, usedMailboxes, capacity])

  const updateTableData = useCallback(
    (changes: any = {}) => {
      const { ...updatedValues } = changes

      dispatch(
        updateMailboxesTable({
          ...updatedValues,
          skip: 0
        })
      )
      dispatch(resetMonitoredIdentities())
      dispatch(getMonitoredIdentities())
    },
    [dispatch]
  )

  const [debouncedOnChange, validateSearchString] = useSearchFieldLogic(
    (search: string) => {
      updateTableData({ search: search.trim() })
      setState({ searchString: search.trim() })
    },
    inProgress,
    SEARCH_FIELD_ID
  )

  const searchFieldConfig: SearchFieldProps = useMemo(() => {
    return {
      id: SEARCH_FIELD_ID,
      value: state.searchString,
      onChange: (e: any) => {
        const validatedSearchString = validateSearchString(e.target.value)

        if (mailboxesTable.search !== validatedSearchString) {
          debouncedOnChange(validatedSearchString)
          setState({ searchString: validatedSearchString })
        }
      },
      disabled: inProgress
    }
  }, [state.searchString, mailboxesTable, debouncedOnChange, validateSearchString, inProgress])

  const tableData = useMemo(() => {
    const { skip, take } = mailboxesTable

    const { data } = process(
      (monitoredIdentities?.report?.data || []).map((report: any) => ({
        ...(report && {
          ...report
        })
      })),
      { skip, take }
    )

    return {
      data: data.filter(report => report.displayName),
      total: monitoredIdentities?.report?.totalCount || 0
    }
  }, [monitoredIdentities, mailboxesTable])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = mailboxesTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: tableData.total,
      onPageChange: (e: any) => {
        dispatch(updateMailboxesTable(e.page))

        if (!loadedOffsets.includes(e.page.skip)) {
          dispatch(getMonitoredIdentities())
        }
      }
    }
  }, [mailboxesTable, tableData, loadedOffsets, dispatch])

  const sortConfig: BDSGridSortConfig | {} = useMemo(() => {
    if (!tableData.total) {
      return {}
    }

    return {
      sortable: !inProgress && {
        allowUnsort: false,
        mode: 'single'
      },
      sort: mailboxesTable.sort,
      onSortChange: (e: any) => {
        updateTableData({ sort: e.sort })
      }
    }
  }, [mailboxesTable, updateTableData, tableData.total, inProgress])

  const exportToCsvConfig: ExportToCsvButtonProps = useMemo(() => {
    return {
      getExportPath: sessionId => {
        return apiRoutes.EXPORT_MAILBOXES_AS_CSV.path({
          accessTokenId,
          sessionId
        })
      },
      exportName: 'SP Mailboxes',
      analyticsParams: {
        accessTokenId,
        totalMailboxes: String(monitoredIdentities?.report?.totalCount)
      },
      totalCount: monitoredIdentities?.report?.totalCount || 0
    }
  }, [accessTokenId, monitoredIdentities])

  // Use the current account Id to select splitio treatment value
  const accountId = useMemo(() => userDataLib.getAccountByAccessToken(accessTokenId)?.accountId, [
    accessTokenId,
    userDataLib
  ])
  const isLicensingInformationRolloutOn = useMemo(
    () => isMyFeatureOn(splitStore, FEATURES.IP_LICENSING_INFORMATION_ROLLOUT, accountId),
    [accountId, splitStore]
  )

  return useMemo(() => {
    return [
      {
        enabled: isLicensingInformationRolloutOn,
        used: usedMailboxes,
        purchased: capacity,
        showLimitError,
        hasAlerts: !!alerts.length
      },
      searchFieldConfig,
      inProgress,
      {
        tableData,
        pageConfig,
        sortConfig,
        columns: mailboxesTable.GRID_COLUMNS,
        columnsConfig: mailboxesTable.columnsConfig,
        highlightKeywords: [mailboxesTable.search],
        isLoaded: !!monitoredIdentities?.accessTokenId
      },
      exportToCsvConfig
    ]
  }, [
    alerts.length,
    capacity,
    exportToCsvConfig,
    inProgress,
    isLicensingInformationRolloutOn,
    mailboxesTable.GRID_COLUMNS,
    mailboxesTable.columnsConfig,
    mailboxesTable.search,
    monitoredIdentities,
    pageConfig,
    searchFieldConfig,
    showLimitError,
    sortConfig,
    tableData,
    usedMailboxes
  ])
}
