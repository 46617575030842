import { makeStyles } from '@material-ui/core/styles'

import dialogStyles from 'ets/configs/styles/dialogStyles'

export default makeStyles(theme => {
  const defaultDialogStyles = dialogStyles(theme)

  return {
    ...defaultDialogStyles,
    paper: {
      width: 387,
      height: 261
    },
    description: {
      color: theme.palette.text.primary,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: '18px'
    },
    primary: {
      height: 40,
      width: 95
    },
    secondary: {
      height: 40,
      width: 65
    },
    buttonProgress: {
      position: 'absolute',
      left: '76%'
    }
  }
})
