// TODO update with value from config service
import { config } from 'global/lib/config'
import globalImages from 'global/configs/theme/images.config'

const BASE_IMG_PATH = `/${config.API_VERSION}/assets/img/`

function imagePath(path: string) {
  return `${BASE_IMG_PATH}${path}`
}

const images = {
  ...globalImages,
  barracudaEtsForDark: imagePath('barracuda/logo_ets.svg'),
  barracudaETSForDarkPng: imagePath('barracuda/logo_ets.png'),
  barracudaETSForLight: imagePath('barracuda/logo_ets_white.svg'),
  barracudaETSForLightPng: imagePath('barracuda/logo_ets_white.png'),
  barracudaLogoWhite: imagePath('barracuda/logo_barracuda_main_white.svg'),
  loginBackground: imagePath('ets_background.jpg'),
  checkCircleGreen: imagePath('icons/icon_check_circle_outline-green.svg'),
  checkGreen: imagePath('icons/checkmark_24px.svg'),
  pdfCover: imagePath('PDF-cover-bg.png'),
  msPermissions: imagePath('ms-permissions.png'),
  iconO365White: imagePath('icon-o365-white.svg'),
  bgScales: imagePath('ets_background_2.png')
}

export type ImageKeys = keyof typeof images
export type Images = typeof images[ImageKeys]

export default images
