import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import reducerHandlers from 'global/redux/features/dataTables/reducerHandlers'
import { GridColumns, UpdateDataTable } from 'global/types/dataTables/dataTables'

import { DataTableState } from 'sen/redux/types/dataTables'

export const GRID_COLUMNS: GridColumns = {
  RECIPIENT: 'recipient',
  READ: 'read',
  STATUS: 'status'
}

export const SORT_FIELDS = {
  [GRID_COLUMNS.RECIPIENT]: 'email',
  [GRID_COLUMNS.READ]: 'isRead'
}

export const COLUMNS_CONFIG = {
  [GRID_COLUMNS.RECIPIENT]: {
    sortable: true,
    orderIndex: 0,
    show: true
  },
  [GRID_COLUMNS.READ]: {
    sortable: true,
    orderIndex: 1,
    show: true
  },
  [GRID_COLUMNS.STATUS]: {
    sortable: false,
    orderIndex: 2,
    show: true
  }
}

const ITEMS_PER_PAGE = 10

// initialState
export const INITIAL_STATE: DataTableState = {
  SORT_FIELDS,
  GRID_COLUMNS,
  ITEMS_PER_PAGE,
  columnsConfig: COLUMNS_CONFIG,
  skip: 0, // offset
  take: ITEMS_PER_PAGE, // limit
  filter: ['isInternal=true'],
  search: '',
  sort: [{ field: GRID_COLUMNS.RECIPIENT, dir: 'asc' }]
}

export const incidentRecipientsSlice = createSlice({
  name: 'DATA_TABLE/INCIDENT/RECIPIENTS',
  initialState: INITIAL_STATE,
  reducers: {
    update: {
      reducer: (state: DataTableState, action: PayloadAction<{ config: UpdateDataTable }>) => {
        return reducerHandlers({ COLUMNS_CONFIG }).update({ state, payload: action.payload })
      },
      prepare: (config: UpdateDataTable) => ({
        payload: { config }
      })
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  }
})

export const { update, reset } = incidentRecipientsSlice.actions

export default incidentRecipientsSlice.reducer
