import colors from '@barracuda-internal/bds-core/dist/styles/colors'

export default {
  appheader: {
    main: colors.barracudaBlack70
  },
  error: {
    main: colors.statusCritical,
    chart: colors.statusCritical
  },
  warning: {
    chart: colors.statusWarning
  },
  success: {
    chart: colors.statusSuccess
  },
  info: {
    main: colors.statusInformational,
    contrastText: colors.breakerWhite,
    chart: colors.statusInformational
  },
  text: {
    tertiary: colors.breakerWhite,
    link: colors.barracudaBlue,
    light: colors.barracudaBlack30
  },
  icon: {
    default: colors.barracudaBlack70,
    hover: colors.uiElement
  },
  pagination: {
    selected: colors.uiElement
  },
  border: colors.uiElement,
  hover: colors.hoverGray,
  rowHover: colors.hoverGray,
  red: {
    color: colors.coralOrange
  },
  blue: {
    color: colors.skyBlue
  }
}
