import React from 'react'
import PropTypes from 'prop-types'

import { Chip, Paper } from '@barracuda-internal/bds-core'
import { Assignment as AssignmentIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'

import { Grid as KendoGrid, GridColumn } from '@progress/kendo-react-grid'

import { Cell, CellAction, CellIcon, CellText } from 'global/components/lib/grid/cell'
import cellsStyles from 'global/components/lib/grid/cell/cellStyles'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import OnOffLabel from 'global/components/lib/label/OnOffLabel'

import { Incidents } from 'fir/redux/types/Remediation'

import styles, { incidentGridStyle } from 'fir/components/pages/remediation/remediationStyles'
import { useFormatMessage } from 'global/lib/localization'

const BASE_I18N_KEY = 'fir.app.remediation'

export interface IncidentsTableProps {
  isPageInProgress: boolean
  isMoreResultsLoading: boolean
  gridData: Incidents[]
  columnMenuConfig: {
    columns: any
  }
  columnsConfig: any
  pageConfig: {
    skip: number
    take: number
    total: number
    onPageChange: (e: any) => void
  }
  GRID_COLUMNS: any
  gotoIncident: any
}

const IncidentsTable: React.FC<IncidentsTableProps> = ({
  isPageInProgress,
  isMoreResultsLoading,
  gridData,
  columnMenuConfig,
  columnsConfig,
  pageConfig,
  GRID_COLUMNS,
  gotoIncident
}) => {
  const classes = styles()
  const gridClasses = incidentGridStyle()
  const cellClasses = cellsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return (
    <Paper className={classes.wrapper} elevation={1}>
      {(isPageInProgress || isMoreResultsLoading) && <LinearProgress />}
      <KendoGrid className={gridClasses.gridLayout} pageable {...pageConfig} data={gridData}>
        <GridColumn
          field={GRID_COLUMNS.CREATED_ON}
          {...columnsConfig[GRID_COLUMNS.CREATED_ON]}
          title={formatMessage('incident_table.created_on')}
          cell={({ dataItem }) => (
            <Cell>
              <CellText>{dataItem.createdOn}</CellText>
            </Cell>
          )}
        />
        <GridColumn
          field={GRID_COLUMNS.CREATED_BY}
          {...columnsConfig[GRID_COLUMNS.CREATED_BY]}
          title={formatMessage('incident_table.created_by')}
          cell={({ dataItem }) => (
            <Cell>
              {dataItem.createdByName ? (
                <span className={classes.bold}>{dataItem.createdByName}</span>
              ) : (
                dataItem.createdBy
              )}
            </Cell>
          )}
        />
        <GridColumn
          field={GRID_COLUMNS.INCIDENTS}
          {...columnsConfig[GRID_COLUMNS.INCIDENTS]}
          title={formatMessage('incident_table.incident')}
          cell={({ dataItem }) => {
            return (
              <Cell>
                <CellText>
                  Email
                  {(dataItem.senderName || dataItem.senderEmail) && (
                    <>
                      {' '}
                      from{' '}
                      <span className={classes.bold}>
                        {dataItem.senderName} {dataItem.senderEmail && `<${dataItem.senderEmail}>`}
                      </span>
                    </>
                  )}
                  {dataItem.subject && (
                    <>
                      {' '}
                      with subject <span className={classes.bold}>{dataItem.subject}</span>
                    </>
                  )}
                  {dataItem.attachmentName && (
                    <>
                      {' '}
                      with attachment name <span className={classes.bold}>{dataItem.attachmentName}</span>
                    </>
                  )}
                  {dataItem.bodyText && (
                    <>
                      {' '}
                      with body text <span className={classes.bold}>{dataItem.bodyText}</span>
                    </>
                  )}
                  {dataItem.bodyLinks && (
                    <>
                      {' '}
                      with body URL <span className={classes.bold}>{dataItem.bodyLinks}</span>
                    </>
                  )}
                </CellText>
              </Cell>
            )
          }}
        />
        <GridColumn
          field={GRID_COLUMNS.MESSAGES_RECEIVED}
          {...columnsConfig[GRID_COLUMNS.MESSAGES_RECEIVED]}
          title={formatMessage('incident_table.messages_received')}
          cell={({ dataItem }) => (
            <Cell>
              <CellText>{dataItem.messagesReceived}</CellText>
            </Cell>
          )}
        />
        <GridColumn
          field={GRID_COLUMNS.CONTINUOUS_REMEDIATION}
          {...columnsConfig[GRID_COLUMNS.CONTINUOUS_REMEDIATION]}
          title={formatMessage('incident_table.continuous_remediation')}
          cell={({ dataItem }) => (
            <Cell>
              <CellText ellipsis>
                <OnOffLabel isEnabled={dataItem.continuousRemediation || false} />
              </CellText>
            </Cell>
          )}
        />
        <GridColumn
          field={GRID_COLUMNS.TAGS}
          {...columnsConfig[GRID_COLUMNS.TAGS]}
          title={formatMessage('incident_table.tags')}
          cell={({ dataItem }) => (
            <Cell>
              <CellText>
                {dataItem.tags.map((tag: string) => (
                  <Chip key={tag} label={tag} size="small" className={classes.tags} />
                ))}
              </CellText>
            </Cell>
          )}
        />
        <GridColumn
          {...columnsConfig[GRID_COLUMNS.VIEW_INCIDENTS]}
          groupable={false}
          headerClassName={cellClasses.createIncidentCell}
          cell={({ dataItem }) =>
            dataItem.pendingCreate ? (
              <Cell className={cellClasses.pendingIncidentCell}>
                <CellText>{formatMessage('incident_table.pending_incident')}</CellText>
              </Cell>
            ) : (
              <Cell className={cellClasses.createIncidentCell}>
                <CellAction
                  onClick={() => {
                    gotoIncident(dataItem)
                  }}
                >
                  <CellIcon icon={AssignmentIcon} />
                  <CellText>{formatMessage('incident_table.view_incident')}</CellText>
                </CellAction>
              </Cell>
            )
          }
        />
      </KendoGrid>
    </Paper>
  )
}

IncidentsTable.propTypes = {
  columnMenuConfig: PropTypes.any.isRequired,
  columnsConfig: PropTypes.any.isRequired,
  gotoIncident: PropTypes.func.isRequired,
  gridData: PropTypes.any.isRequired,
  GRID_COLUMNS: PropTypes.any.isRequired,
  isPageInProgress: PropTypes.bool.isRequired,
  isMoreResultsLoading: PropTypes.bool.isRequired,
  pageConfig: PropTypes.any.isRequired
}

export default IncidentsTable
