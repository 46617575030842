import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%'
  },
  actions: {
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  bold: {
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.tertiary
  },
  threatDetails: {
    padding: theme.spacing(2, 0, 1, 0)
  },
  tooltip: {
    marginLeft: 8
  },
  matchedEmailCount: {
    fontSize: theme.font.size.xxlarge,
    fontWeight: theme.font.weight.medium
  }
}))
