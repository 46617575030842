import { useEffect, useMemo, useCallback, useState, useRef } from 'react'

import { process } from '@progress/kendo-data-query'
import { BDSGridPagerConfig } from 'global/types/dataTables/dataTables'
import { getDomainDmarcState } from 'global/lib/domain/dmarc'
import { isPending, isSuccess, getErrorMessage } from 'global/redux/toolkit/api'
import { CompanyDomain, CompanyDomainWithState } from 'global/types/api/dmarc'
import { ColumnsConfig } from 'global/types/dataTables/columnsConfigType'
import { config } from 'global/lib/config'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import {
  getCompanyDomains,
  getDomainsStats,
  resetCompanyDomains,
  resetDomainsStats
} from 'sen/redux/features/dmarc/dmarcSlice'
import useDmarcAnalyticsLogic from 'sen/lib/dmarc/useDmarcAnalyticsLogic'
import {
  update as updateDomainsTable,
  reset as resetDomainsTable
} from 'sen/redux/features/dataTables/domains/domainsSlice'
import routesConfig from 'sen/lib/routes/routesConfig'

export enum ReviewButtonModes {
  setupDmarcReporting = 'setup_dmarc_reporting',
  verifyDomain = 'verify_domain',
  waitingForSufficientReports = 'waiting_for_sufficient_report',
  reviewHighVolSources = 'review_high_vol_sources',
  reviewFailures = 'review_failures',
  viewReport = 'view_report'
}

export interface ModifiedCompanyDomain extends CompanyDomainWithState {
  unknownSources: string | undefined
  failures: string | undefined
  isReadyToBeEnforced: boolean
  reviewButtonMode: ReviewButtonModes
  reviewButtonIsDisabled: boolean
  deleteIsDisabled: boolean
}

export interface ReadyToEnforceConfig {
  isVisible: boolean
  isHidden: boolean
  onHide: () => void
  readyForEnforcingDmarcItems: CompanyDomainWithState[]
}

export interface TableConfig {
  isLoaded: boolean
  isDomainsStatsLoaded: boolean
  tableData: {
    total: number
    data: ModifiedCompanyDomain[]
  }
  pageConfig: BDSGridPagerConfig
  columns: { [key: string]: string }
  columnsConfig: { [key: string]: ColumnsConfig }
  isFlexibleTable: boolean
  error: string | undefined
}

export interface EventHandlers {
  onOpenEnforcementWizard: (item: ModifiedCompanyDomain) => void
  onActionSelected: (item: ModifiedCompanyDomain) => void
  onDeleteDomain: (item: ModifiedCompanyDomain) => void
  onAddDomainForDmarcReporting: () => void
}

export interface DialogConfigs {
  dmarcEnforcementWizard: {
    isOpen: boolean
    onClose: (isDomainModified: boolean) => void
    domain: CompanyDomain | undefined
  }
  addDomainWizard: {
    isOpen: boolean
    onClose: (isDomainModified: boolean) => void
    domain: CompanyDomain | undefined
  }
  deleteDomain: {
    isOpen: boolean
    onClose: () => void
    domain: CompanyDomain | undefined
  }
  domainWizard: {
    isOpen: boolean
    onClose: (isDomainModified: boolean) => void
    domain: CompanyDomain | undefined
  }
}

export const DMARC_RESOLVE_STATES = config.DOMAIN_RESOLVE_STATES.DMARC
const DMARC_STATES = {
  UNFINISHED_STATES: [DMARC_RESOLVE_STATES.ERROR, DMARC_RESOLVE_STATES.LOADING],
  UNPROTECTED_STATES: [DMARC_RESOLVE_STATES.UNPROTECTED, DMARC_RESOLVE_STATES.MISCONFIGURED],
  PROTECTED_STATES: [DMARC_RESOLVE_STATES.PROTECTED, DMARC_RESOLVE_STATES.REPORTING],
  UNVERIFIED_STATES: [DMARC_RESOLVE_STATES.UNVERIFIED]
}

export default function useDomainFraudLogic(): [ReadyToEnforceConfig, TableConfig, EventHandlers, DialogConfigs] {
  const {
    accessTokenId,
    compnanyDomains,
    isCompanyDomainsLoading,
    isCompanyDomainsLoaded,
    companyDomainsError,
    domainsTable,
    domainsStats,
    isDomainsStatsLoaded,
    domainsStatsError
  } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken?.accessToken?.id || '',
    compnanyDomains: _stores.dmarc.companyDomains,
    isCompanyDomainsLoading: isPending(_stores.dmarc.getCompanyDomainsApiStatus),
    isCompanyDomainsLoaded: isSuccess(_stores.dmarc.getCompanyDomainsApiStatus),
    companyDomainsError: getErrorMessage(_stores.dmarc.getCompanyDomainsApiStatus),
    domainsTable: _stores.dataTables.domains,
    domainsStats: _stores.dmarc.domainsStats,
    isDomainsStatsLoaded: isSuccess(_stores.dmarc.getDomainsStatsApiStatus),
    domainsStatsError: getErrorMessage(_stores.dmarc.getDomainsStatsApiStatus)
  }))

  const dispatch = useAppDispatch()
  const dmarcAnalyticsLogic = useDmarcAnalyticsLogic()
  const [hideReadyToEnforceMessage, setHideReadyToEnforceMessage] = useState<boolean>(false)
  const [enforcedDomain, setEnforcedDomain] = useState<CompanyDomain | undefined>()
  const [verifyDomain, setVerifyDomain] = useState<CompanyDomain | undefined>()
  const [deleteDomain, setDeleteDomain] = useState<CompanyDomain | undefined>()
  const [domainWizard, setDomainWizard] = useState<CompanyDomain | undefined>()
  const [isDmarcEnforcementWizardDialogOpened, toggleDmarcEnforcementWizardDialog] = useDialogLogic()
  const [isAddDomainWizardDialogOpened, toggleAddDomainWizardDialog] = useDialogLogic()
  const [isDeleteDomainDialogOpened, toggleDeleteDomainDialog] = useDialogLogic()
  const [isDomainWizardDialogOpened, toggleDomainWizardDialog] = useDialogLogic()
  const isGotoDmarcReviewPage = useRef(false)

  // init
  useEffect(() => {
    if (!isCompanyDomainsLoaded) {
      dispatch(getCompanyDomains())
    }

    analyticsLib.trackAppEvent(analyticsLib.EVENTS.DMARC_VIEW, { accessTokenId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // unmount
  useEffect(() => {
    return () => {
      if (!isGotoDmarcReviewPage.current) {
        dispatch(resetCompanyDomains())
        dispatch(resetDomainsStats())
        dispatch(resetDomainsTable())
      }
    }
  }, [dispatch])

  const domainsWithState: CompanyDomainWithState[] = useMemo(() => {
    return compnanyDomains.map(companyDomain => ({ ...companyDomain, state: getDomainDmarcState(companyDomain)[0] }))
  }, [compnanyDomains])

  const readyForEnforcingDmarcItems = useMemo(() => {
    return domainsWithState.filter(companyDomain => dmarcAnalyticsLogic.isReadyToBeEnforced(companyDomain))
  }, [domainsWithState, dmarcAnalyticsLogic])

  const onHideReadyToEnforceMessage = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.DF_DOMAINS_CLOSE_READY_TO_ENFORCE)
    setHideReadyToEnforceMessage(true)
  }, [setHideReadyToEnforceMessage])

  const getReviewButtonMode = useCallback(
    (item: CompanyDomainWithState): string | undefined => {
      const isSavedDomain = domainsStats?.[item.name]
      const isProtected = isSavedDomain && DMARC_STATES.PROTECTED_STATES.includes(item.state)

      switch (true) {
        case DMARC_STATES.UNPROTECTED_STATES.includes(item.state):
          return ReviewButtonModes.setupDmarcReporting
        case DMARC_STATES.UNVERIFIED_STATES.includes(item.state):
          return ReviewButtonModes.verifyDomain
        case !isSavedDomain:
          return undefined
        case isProtected && !dmarcAnalyticsLogic.isDmarcReportReady(item):
          return ReviewButtonModes.waitingForSufficientReports
        case isProtected && !dmarcAnalyticsLogic.hasNoUnknownMajorSenders(item):
          return ReviewButtonModes.reviewHighVolSources
        case isProtected && !dmarcAnalyticsLogic.isDmarcPassing(item):
          return ReviewButtonModes.reviewFailures
        default:
          return ReviewButtonModes.viewReport
      }
    },
    [dmarcAnalyticsLogic, domainsStats]
  )

  // load the domains stats
  useEffect(() => {
    if (compnanyDomains.length) {
      dispatch(getDomainsStats(compnanyDomains.map((domain: CompanyDomain) => domain.name)))
    }
  }, [compnanyDomains, dispatch])

  const unknownSourceCount = useCallback(
    (domain: CompanyDomainWithState): string | undefined => {
      switch (true) {
        case getReviewButtonMode(domain) && DMARC_STATES.PROTECTED_STATES.includes(domain.state):
          return String(dmarcAnalyticsLogic.getUnknownMajorSourcesCount(domain))
        case !DMARC_STATES.PROTECTED_STATES.includes(domain.state) || !getReviewButtonMode(domain):
          return '-'
        default:
          return undefined
      }
    },
    [dmarcAnalyticsLogic, getReviewButtonMode]
  )

  const failuresCount = useCallback(
    (domain: CompanyDomainWithState) => {
      const isProtected = getReviewButtonMode(domain) && DMARC_STATES.PROTECTED_STATES.includes(domain.state)
      const failsFromapprovedSources = dmarcAnalyticsLogic.getFailsFromApprovedSources(domain) || 0
      const approvedSourcesPercentage = dmarcAnalyticsLogic.getDomainFailuresFromApprovedSourcesPercentage(domain)

      switch (true) {
        case isProtected && approvedSourcesPercentage <= 0:
          return failsFromapprovedSources.toLocaleString()
        case isProtected && approvedSourcesPercentage < 1:
          return `${failsFromapprovedSources.toLocaleString()} (<1%)`
        case isProtected && approvedSourcesPercentage >= 1:
          return `${failsFromapprovedSources.toLocaleString()} (${Math.round(approvedSourcesPercentage)}%)`
        case !DMARC_STATES.PROTECTED_STATES.includes(domain.state) || !getReviewButtonMode(domain):
          return '-'
        default:
          return undefined
      }
    },
    [dmarcAnalyticsLogic, getReviewButtonMode]
  )

  const tableData = useMemo(() => {
    const { skip, take } = domainsTable

    const { data } = process(
      domainsWithState.map((report: CompanyDomainWithState) => {
        const reviewButtonMode = getReviewButtonMode(report)
        const reviewButtonIsDisabled =
          reviewButtonMode === ReviewButtonModes.waitingForSufficientReports ||
          (reviewButtonMode === ReviewButtonModes.verifyDomain && report.verificationInprogress)

        return {
          ...(report && {
            ...report,
            isReadyToBeEnforced: dmarcAnalyticsLogic.isReadyToBeEnforced(report),
            unknownSources: unknownSourceCount(report),
            failures: failuresCount(report),
            reviewButtonMode,
            reviewButtonIsDisabled,
            deleteIsDisabled: report.domainSource === config.DOMAIN_SOURCES.O365
          })
        }
      }),
      { skip, take }
    )

    return {
      data: data.filter(report => report.name),
      total: domainsWithState.length
    }
  }, [domainsWithState, domainsTable, dmarcAnalyticsLogic, getReviewButtonMode, failuresCount, unknownSourceCount])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = domainsTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: tableData.total,
      onPageChange: (e: any) => {
        dispatch(updateDomainsTable(e.page))
      }
    }
  }, [tableData, domainsTable, dispatch])

  const onOpenEnforcementWizard: EventHandlers['onOpenEnforcementWizard'] = useCallback(
    domain => {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.DF_DOMAINS_OPEN_ENFORCE_DMARC_WIZARD, { domain })
      setEnforcedDomain(domain)
      toggleDmarcEnforcementWizardDialog()
    },
    [toggleDmarcEnforcementWizardDialog]
  )

  const onCloseEnforcementWizard: DialogConfigs['dmarcEnforcementWizard']['onClose'] = useCallback(
    (isDomainModified: boolean) => {
      setEnforcedDomain(undefined)
      toggleDmarcEnforcementWizardDialog()
      if (isDomainModified) {
        dispatch(getCompanyDomains())
      }
    },
    [toggleDmarcEnforcementWizardDialog, dispatch]
  )

  const onCloseVerifyDomainWizard: DialogConfigs['addDomainWizard']['onClose'] = useCallback(
    (isDomainModified: boolean) => {
      setVerifyDomain(undefined)
      toggleAddDomainWizardDialog()
      if (isDomainModified) {
        dispatch(getCompanyDomains())
      }
    },
    [toggleAddDomainWizardDialog, dispatch]
  )

  const onCloseDeleteDomain: DialogConfigs['deleteDomain']['onClose'] = useCallback(() => {
    setDeleteDomain(undefined)
    toggleDeleteDomainDialog()
  }, [toggleDeleteDomainDialog])

  const onCloseDomainWizard: DialogConfigs['domainWizard']['onClose'] = useCallback(
    (isDomainModified: boolean) => {
      setDomainWizard(undefined)
      toggleDomainWizardDialog()
      if (isDomainModified) {
        dispatch(getCompanyDomains())
      }
    },
    [toggleDomainWizardDialog, dispatch]
  )

  const onActionSelected: EventHandlers['onActionSelected'] = useCallback(
    domain => {
      switch (domain.reviewButtonMode) {
        case ReviewButtonModes.reviewFailures:
          analyticsLib.trackAppEvent(analyticsLib.EVENTS.DF_DOMAINS_VIEW_APPROVED_TAB, { domain: domain.name })
          isGotoDmarcReviewPage.current = true
          routesConfig.DOMAIN_FRAUD_DMARC_REVIEW_WITH_APPROVED_TAB.goto({
            reportId: accessTokenId,
            domain: domain.name
          })
          break
        case ReviewButtonModes.reviewHighVolSources:
        case ReviewButtonModes.viewReport:
          analyticsLib.trackAppEvent(analyticsLib.EVENTS.DF_DOMAINS_VIEW_REPORT_UNKNOWN, { domain: domain.name })
          isGotoDmarcReviewPage.current = true
          routesConfig.DOMAIN_FRAUD_DMARC_REVIEW_WITH_UNKNOWN_TAB.goto({ reportId: accessTokenId, domain: domain.name })
          break
        case ReviewButtonModes.setupDmarcReporting:
          analyticsLib.trackAppEvent(analyticsLib.EVENTS.DF_DOMAINS_FRAUD_PROTECTION_WIZARD, { domain: domain.name })
          setDomainWizard(domain)
          toggleDomainWizardDialog()
          break
        case ReviewButtonModes.verifyDomain:
          analyticsLib.trackAppEvent(analyticsLib.EVENTS.DF_DOMAINS_VERIFY_DOMAIN, { domain: domain.name })
          setVerifyDomain(domain)
          toggleAddDomainWizardDialog()
          break
        default:
      }
    },
    [toggleAddDomainWizardDialog, toggleDomainWizardDialog, accessTokenId]
  )

  const onDeleteDomain: EventHandlers['onDeleteDomain'] = useCallback(
    domain => {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.DF_DOMAINS_DELETE_DOMAIN, { domain: domain.name })
      setDeleteDomain(domain)
      toggleDeleteDomainDialog()
    },
    [toggleDeleteDomainDialog]
  )

  const onAddDomainForDmarcReporting = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.DF_DOMAINS_ADD_DOMAIN, { accessTokenId })
    toggleAddDomainWizardDialog()
  }, [accessTokenId, toggleAddDomainWizardDialog])

  return useMemo(() => {
    return [
      {
        isVisible: !!readyForEnforcingDmarcItems.length,
        isHidden: hideReadyToEnforceMessage,
        onHide: onHideReadyToEnforceMessage,
        readyForEnforcingDmarcItems
      },
      {
        isLoaded: !isCompanyDomainsLoading,
        isDomainsStatsLoaded,
        tableData,
        pageConfig,
        columns: domainsTable.GRID_COLUMNS,
        columnsConfig: domainsTable.columnsConfig,
        isFlexibleTable: tableData.total < domainsTable.ITEMS_PER_PAGE,
        error: companyDomainsError || domainsStatsError
      },
      {
        onOpenEnforcementWizard,
        onActionSelected,
        onDeleteDomain,
        onAddDomainForDmarcReporting
      },
      {
        dmarcEnforcementWizard: {
          isOpen: isDmarcEnforcementWizardDialogOpened,
          onClose: onCloseEnforcementWizard,
          domain: enforcedDomain
        },
        addDomainWizard: {
          isOpen: isAddDomainWizardDialogOpened,
          onClose: onCloseVerifyDomainWizard,
          domain: verifyDomain
        },
        deleteDomain: {
          isOpen: isDeleteDomainDialogOpened,
          onClose: onCloseDeleteDomain,
          domain: deleteDomain
        },
        domainWizard: {
          isOpen: isDomainWizardDialogOpened,
          onClose: onCloseDomainWizard,
          domain: domainWizard
        }
      }
    ]
  }, [
    tableData,
    domainsTable,
    isCompanyDomainsLoading,
    isDomainsStatsLoaded,
    companyDomainsError,
    domainsStatsError,
    pageConfig,
    onOpenEnforcementWizard,
    onActionSelected,
    onDeleteDomain,
    hideReadyToEnforceMessage,
    readyForEnforcingDmarcItems,
    onHideReadyToEnforceMessage,
    onAddDomainForDmarcReporting,
    isDmarcEnforcementWizardDialogOpened,
    onCloseEnforcementWizard,
    enforcedDomain,
    isAddDomainWizardDialogOpened,
    verifyDomain,
    onCloseVerifyDomainWizard,
    isDeleteDomainDialogOpened,
    onCloseDeleteDomain,
    deleteDomain,
    isDomainWizardDialogOpened,
    onCloseDomainWizard,
    domainWizard
  ])
}
