import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import reducerHandlers from 'global/redux/features/dataTables/reducerHandlers'
import { ColumnsConfig, GridColumns, SortFields, UpdateDataTable } from 'global/types/dataTables/dataTables'

import { DataTableState } from 'sen/redux/types/dataTables'

export const GRID_COLUMNS: GridColumns = {
  DATE: 'date',
  SEQUENCE: 'sequence',
  NAME: 'name',
  ACTIONS: 'actions',
  CONDITIONS: 'conditions',
  EXCEPTIONS: 'exceptions',
  ENABLED: 'enabled'
}

export const SORT_FIELDS: SortFields = {}

export const COLUMNS_CONFIG: ColumnsConfig = {
  [GRID_COLUMNS.DATE]: {
    sortable: false,
    orderIndex: 0,
    show: true
  },
  [GRID_COLUMNS.SEQUENCE]: {
    sortable: false,
    orderIndex: 1,
    show: true
  },
  [GRID_COLUMNS.NAME]: {
    sortable: false,
    orderIndex: 2,
    show: true
  },
  [GRID_COLUMNS.ACTIONS]: {
    sortable: false,
    orderIndex: 3,
    show: true
  },
  [GRID_COLUMNS.CONDITIONS]: {
    sortable: false,
    orderIndex: 4,
    show: true
  },
  [GRID_COLUMNS.EXCEPTIONS]: {
    sortable: false,
    orderIndex: 5,
    show: true
  },
  [GRID_COLUMNS.ENABLED]: {
    sortable: false,
    orderIndex: 6,
    show: true
  }
}

const ITEMS_PER_PAGE = 5

// Store
export const INITIAL_STATE: DataTableState = {
  SORT_FIELDS,
  GRID_COLUMNS,
  ITEMS_PER_PAGE,
  columnsConfig: COLUMNS_CONFIG,
  skip: 0, // offset
  take: ITEMS_PER_PAGE, // limit
  filter: null,
  search: '',
  sort: []
}

export const alertInboxRulesSlice = createSlice({
  name: 'DATA_TABLE/alert/INBOX_RULES',
  initialState: INITIAL_STATE,
  reducers: {
    update: {
      reducer: (state: DataTableState, action: PayloadAction<{ config: UpdateDataTable }>) => {
        return reducerHandlers({ COLUMNS_CONFIG }).update({ state, payload: action.payload })
      },
      prepare: (config: UpdateDataTable) => ({
        payload: { config }
      })
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  }
})

export const { update, reset } = alertInboxRulesSlice.actions

export default alertInboxRulesSlice.reducer
