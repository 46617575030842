import { combineReducers, AnyAction } from 'redux'
import { splitReducer } from '@splitsoftware/splitio-redux'

import { RootState } from 'admin/redux/toolkit/store'

// global slices
import accessToken, {
  INITIAL_STATE as accessTokenInitialState
} from 'global/redux/features/accessToken/accessTokenSlice'
import app, { INITIAL_STATE as appInitialState } from 'global/redux/features/app/appSlice'
import auth, { logout } from 'global/redux/features/auth/authSlice'
import account, { INITIAL_STATE as accountInitialState } from 'global/redux/features/account/accountSlice'

// admin slices
import 'admin/redux/features/auth/authSlice'
import admin, { INITIAL_STATE as adminInitialState } from 'admin/redux/features/admin/adminSlice'
import dataTables, { INITIAL_STATE as dataTablesInitialState } from 'admin/redux/features/dataTables/dataTablesSlice'
import insights, { INITIAL_STATE as insightsInitialState } from 'admin/redux/features/insights/insightsSlice'
import user, { INITIAL_STATE as userInitialState } from 'admin/redux/features/user/userSlice'

export const reducers = {
  admin,
  dataTables,
  insights,
  user,
  splitio: splitReducer
}

const combinedReducers = combineReducers({
  accessToken,
  admin,
  app,
  auth,
  account,
  dataTables,
  insights,
  user,
  splitio: splitReducer
})

export default function(state: RootState, action: AnyAction) {
  let appState = { ...state }

  if (logout.pending.match(action) && appState.accessToken?.accessToken?.id) {
    appState.auth = {
      ...appState.auth,
      logoutValues: {
        accessTokenId: appState.accessToken?.accessToken?.id
      }
    }
  }

  if (action.type === logout.fulfilled.toString()) {
    // reset stores on logout
    appState = {
      ...appState,
      splitio: {
        ...appState.splitio,
        treatments: {}
      },
      auth: {
        ...appState.auth
      },
      accessToken: accessTokenInitialState,
      admin: adminInitialState,
      app: appInitialState,
      account: accountInitialState,
      dataTables: dataTablesInitialState,
      insights: insightsInitialState,
      user: userInitialState
    }
  }

  return combinedReducers(appState, action)
}
