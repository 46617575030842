import React from 'react'
import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'

import { Checkbox, CheckboxLabel, Button, Typography } from '@barracuda-internal/bds-core'
import { Email as EmailIcon } from '@barracuda-internal/bds-core/dist/Icons/Email'

import { FormControl } from '@material-ui/core'

import TagInput from 'global/components/lib/tagInput/TagInput'

import { useFormatMessage } from 'global/lib/localization'
import EditEmailAlertDialog from '../editEmailAlertDialog/EditEmailAlertDialog'
import styles, { remediationOptionsStyles } from '../newIncidentStyles'

const BASE_I18N_KEY = 'fir.app.new_incident_wizard'

const UserOptions: React.FC<any> = ({
  userOptions: {
    editEmailAlertDialogConfig,
    forensicsNotifyAddress,
    isEditEmailAlertDialogVisible,
    tagInputConfig,
    toggleEditEmailDialogVisibility,
    updateUserOptions
  }
}) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const baseClasses = styles()
  const classes = remediationOptionsStyles()
  const { newIncident }: any = useSelector((_stores: any) => ({
    newIncident: _stores.newIncident
  }))

  return (
    <div className={baseClasses.wrapper}>
      {isEditEmailAlertDialogVisible && (
        <EditEmailAlertDialog {...editEmailAlertDialogConfig} updateUserOptions={updateUserOptions} />
      )}
      <Typography className={baseClasses.opacity} variant="subtitle1">
        {formatMessage('select_options', { b: (txt: any) => <b key="bold-text">{txt}</b> })}
      </Typography>
      <FormControl className={classes.formControl}>
        <CheckboxLabel
          control={
            <Checkbox
              color="primary"
              checked={newIncident.userOptions.isDeleteSelectedEmails}
              onChange={updateUserOptions('isDeleteSelectedEmails', true)}
            />
          }
          label={formatMessage('delete_selected_emails', { count: newIncident.emails.totalCount })}
        />
        <FormControl className={classes.subFormControl}>
          <CheckboxLabel
            disabled={!newIncident.userOptions.isDeleteSelectedEmails}
            control={
              <Checkbox
                color="primary"
                checked={newIncident.userOptions.isTurnOnContinuousRemediation}
                onChange={updateUserOptions('isTurnOnContinuousRemediation', true)}
              />
            }
            label={formatMessage('turn_on_continuous')}
          />
          <Typography className={classes.subLabel} variant="body2">
            {formatMessage('turn_on_to_scan')}
          </Typography>
        </FormControl>
      </FormControl>
      <FormControl className={classes.formControl}>
        <CheckboxLabel
          control={
            <Checkbox
              color="primary"
              checked={newIncident.userOptions.isSendWarningEmail}
              onChange={updateUserOptions('isSendWarningEmail', true)}
            />
          }
          label={
            <span>
              {formatMessage('send_warning_email')}
              <Button
                color="primary"
                variant="text"
                className={classes.editEmailButton}
                onClick={toggleEditEmailDialogVisibility}
              >
                <>
                  <EmailIcon />
                  &nbsp;
                  {formatMessage('buttons.edit_email_alert')}
                </>
              </Button>
            </span>
          }
        />
      </FormControl>
      <Typography className={classes.title} variant="h6">
        {formatMessage('incident_management')}
      </Typography>
      <FormControl className={classes.formControl}>
        <CheckboxLabel
          control={
            <Checkbox
              color="primary"
              checked={newIncident.userOptions.isSendIncidentSummary}
              onChange={updateUserOptions('isSendIncidentSummary', true)}
            />
          }
          label={
            <span>
              {formatMessage('send_incident_summary')}
              <span className={baseClasses.bold}>{forensicsNotifyAddress}</span>
            </span>
          }
        />
        <Typography className={classes.subLabel} variant="body2">
          {formatMessage('email_summary_of')}
        </Typography>
      </FormControl>
      <div className={`${classes.formControl} ${classes.tagForm}`}>
        <Typography variant="body1">{formatMessage('tags')}</Typography>
        <TagInput {...tagInputConfig} />
      </div>
    </div>
  )
}

UserOptions.propTypes = {
  searchingForEmails: PropTypes.any.isRequired,
  userOptions: PropTypes.any.isRequired
}

export default UserOptions
