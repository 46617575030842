import { useCallback, useEffect, useMemo, useReducer } from 'react'

import { NodeConstants } from '@barracuda/automated-workflows'

import { CompositeFilterDescriptor, process, SortDescriptor } from '@progress/kendo-data-query'
import { GridColumnMenuFilter } from '@progress/kendo-react-grid'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'

import { kotlinFilters } from 'global/components/lib/grid/filter/operators'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { FEATURES, isMyFeatureOn } from 'global/lib/splitio/splitio'
import { config } from 'global/lib/config'
import * as datetime from 'global/lib/datetime'
import useProductLib from 'global/lib/product/useProduct'
import useUserDataLib from 'global/lib/userData/useUserData'

import routesConfig from 'fir/lib/routes/routesConfig'

import * as automatedWorkflowsTableActions from 'fir/redux/features/dataTables/automatedWorkflows/automatedWorkflowsSlice'
import {
  getWorkflow,
  getWorkflows,
  getMoreWorkflows,
  resetToggleWorkflowStatus,
  reset as resetWorkflows,
  resetWorkflow,
  toggleWorkflowStatus
} from 'fir/redux/features/workflows/workflowsSlice'
import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'
import { Workflow } from 'fir/redux/types/Workflows'

import { AlertProps } from 'global/components/lib/alerts/Alert'
import { useFormatMessage } from 'global/lib/localization'
import { getErrorMessage, isPending, isSuccess } from 'global/redux/toolkit/api'
import { UseWorkflowDialogParams } from './workflowDialog/useWorkflowDialogLogic'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseAutomatedWorkflowsParams {}

export interface AutomatedWorkflowsProps {
  alertConfig: AlertProps
  closeWorkflowDialog: (uuid?: string) => void
  columnsConfig: any
  columnMenuConfig: any
  filterConfig: any
  gotoWorkflowView: (uuid: string) => void
  GRID_COLUMNS: any
  gridData: any
  isColumnActive: (field: string) => boolean
  isGetWorkflowsLoading: boolean
  onToggleViewState: () => void
  onWorkflowStatusChange: (workflowUUID: string) => void
  openWorkflowDialog: (uuid?: string) => void
  pageConfig: any
  showErrorAlert: boolean
  sortConfig: any
  viewState: string
  workflowDialogConfig: UseWorkflowDialogParams
}

const BASE_I18N_KEY = 'app.error'

export default function useAutomatedWorkflowsLogic(params: UseAutomatedWorkflowsParams): [AutomatedWorkflowsProps] {
  const dispatch = useAppDispatch()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [productLib] = useProductLib()
  const [userDataLib] = useUserDataLib()
  const [isWorkflowDialogOpened, toggleWorkflowDialog] = useDialogLogic()

  // Redux Toolkit stores
  const { accessTokenId, automatedWorkflows, automatedWorkflowsTable, splitStore } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id || ' ',
    automatedWorkflows: _stores.workflows,
    automatedWorkflowsTable: _stores.dataTables.automatedWorkflows,
    splitStore: _stores.splitio
  }))

  // Use the current account Id to select splitio treatment value
  const accountId = useMemo(() => userDataLib.getAccountByAccessToken(accessTokenId)?.accountId, [
    accessTokenId,
    userDataLib
  ])
  const isIRV2AutomatedWorkflowsOn = useMemo(
    () => isMyFeatureOn(splitStore, FEATURES.IR_V2_AUTOMATED_WORKFLOWS_UI, accountId),
    [accountId, splitStore]
  )

  //  workflows state
  const { isGetWorkflowsLoading, isGetWorkflowsSuccess, isToggleWorkflowStatusSuccess, toggleWorkflowErrorMsg } = {
    isGetWorkflowsLoading: isPending(automatedWorkflows.getWorkflowsAPIStatus),
    isGetWorkflowsSuccess: isSuccess(automatedWorkflows.getWorkflowsAPIStatus),
    isToggleWorkflowStatusSuccess: isSuccess(automatedWorkflows.toggleWorkflowStatusAPIStatus),
    toggleWorkflowErrorMsg: getErrorMessage(automatedWorkflows.toggleWorkflowStatusAPIStatus)
  }

  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    isNewWorkflow: true,
    workflowDetails: {},
    workflowStatus: automatedWorkflowsTableActions.ENABLED_STATE
  })

  const getWorkflowStats = (stats: any[], type: string) => {
    const aggeragatedStats = stats
      .filter(stat => stat.type === type)
      .reduce((total, value) => {
        return total + value.count
      }, 0)

    return aggeragatedStats
  }

  // init and reload after toggling workflowStatus
  useEffect(() => {
    if (productLib.getForensicsSerialBundleForAccessToken(accessTokenId) !== config.BUNDLES.BUNDLE1) {
      const resetApiParams = apiParams
      resetApiParams.query.page = 1

      analyticsLib.trackAppEvent(
        state.workflowStatus === automatedWorkflowsTableActions.ENABLED_STATE
          ? analyticsLib.EVENTS.AUTOMATED_WORKFLOW_VIEWED_ENABLED
          : analyticsLib.EVENTS.AUTOMATED_WORKFLOW_VIEWED_DISABLED,
        {
          accessTokenId,
          page: 'automated-workflows'
        }
      )

      dispatch(getWorkflows({ accessTokenId, config: resetApiParams }))
    } else {
      routesConfig.REMEDIATION.goto()
    }

    return () => {
      // Reset workflows object and workflows table actions
      dispatch(automatedWorkflowsTableActions.reset())
      dispatch(resetWorkflows())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.workflowStatus])

  // on sortChange or on filterChange
  useEffect(() => {
    if (isGetWorkflowsSuccess) {
      dispatch(resetWorkflows())
      dispatch(getMoreWorkflows({ accessTokenId, config: apiParams }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [automatedWorkflowsTable.filter, automatedWorkflowsTable.sort, dispatch])

  // Pagination
  useEffect(() => {
    if (isGetWorkflowsSuccess && !automatedWorkflows.workflows.data[automatedWorkflowsTable.page.skip]) {
      dispatch(getMoreWorkflows({ accessTokenId, config: apiParams }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [automatedWorkflowsTable.page.skip])

  // Create filter for api query
  const filters = useMemo(() => {
    return kotlinFilters(automatedWorkflowsTable.filter)
  }, [automatedWorkflowsTable.filter])

  const apiParams = useMemo(() => {
    const { page, sort } = automatedWorkflowsTable

    return {
      query: {
        search: [
          ...filters.flat(),
          state.workflowStatus === automatedWorkflowsTableActions.ENABLED_STATE
            ? automatedWorkflowsTableActions.ENABLED_FILTER
            : automatedWorkflowsTableActions.DISABLED_FILTER
        ],
        limit: Number(page.take),
        page: Math.abs(Number(page.skip) / Number(page.take)) + 1,
        order: `${(sort[0].dir as string) === 'asc' ? '' : '-'}${sort[0].field}`
      }
    }
  }, [automatedWorkflowsTable, filters, state.workflowStatus])

  // Reload workflow table data after enabling or disabling workflow
  useEffect(() => {
    if (isToggleWorkflowStatusSuccess) {
      const resetApiParams = apiParams
      resetApiParams.query.page = 1

      dispatch(automatedWorkflowsTableActions.update({ config: { page: { skip: 0, take: 10 } } }))
      dispatch(resetWorkflows())
      dispatch(getWorkflows({ accessTokenId, config: resetApiParams }))
    }
  }, [accessTokenId, apiParams, dispatch, isToggleWorkflowStatusSuccess])

  const closeWorkflowDialog = useCallback(
    (uuid?: string) => {
      toggleWorkflowDialog()
      if (uuid) {
        dispatch(resetWorkflow())
        dispatch(getWorkflow({ accessTokenId, uuid }))
      } else {
        dispatch(resetWorkflows())
        dispatch(getWorkflows({ accessTokenId, config: apiParams }))
      }

      setState({ isNewWorkflow: true })
    },
    [accessTokenId, apiParams, dispatch, toggleWorkflowDialog]
  )

  const gotoWorkflowView = useCallback(
    (workflowId: string) => {
      // Add split treatment here
      if (isIRV2AutomatedWorkflowsOn) {
        // When we have the route setup for Automated Workflow V2 page replace window.open()
        window.open('https://http.cat/404')
      } else {
        routesConfig.AUTOMATED_WORKFLOWS_WORKFLOW.goto({ workflowId })
      }
    },
    [isIRV2AutomatedWorkflowsOn]
  )

  const gridData = useMemo(() => {
    const { page } = automatedWorkflowsTable

    const results = process(
      (automatedWorkflows.workflows.data as any)
        .slice(page.skip, page.skip + page.take)
        .reduce((all: Workflow[], workflow: any & { [key: string]: any[] }) => {
          if (!workflow) {
            return [...all, {}]
          }

          return [
            ...all,
            {
              formatedDate: datetime.formatDate(workflow.createdOn, config.DATETIME.DEFAULT_DATE_WITH_TIME_FORMAT),
              createdOn: workflow.createdOn,
              editor: workflow.editor,
              name: workflow.name,
              numberOfActionsTaken: getWorkflowStats(workflow.stats, NodeConstants.ACTION),
              numberOfConditionsChecked: getWorkflowStats(workflow.stats, NodeConstants.CONDITION),
              numberOfTimesTriggered: getWorkflowStats(workflow.stats, NodeConstants.TRIGGER),
              workflowUUID: workflow.uuid
            }
          ]
        }, [])
        .filter((item: any) => Object.keys(item).length),
      { sort: automatedWorkflowsTable.sort }
    )

    return { data: results.data, total: automatedWorkflows.workflows.total }
  }, [automatedWorkflows.workflows.data, automatedWorkflows.workflows.total, automatedWorkflowsTable])

  const isColumnActive = useCallback(
    (field: string) => {
      return GridColumnMenuFilter.active(field, automatedWorkflowsTable.filter as CompositeFilterDescriptor | undefined)
    },
    [automatedWorkflowsTable.filter]
  )

  const onTableConfigChange = useCallback(
    (newConfig: any) => {
      dispatch(automatedWorkflowsTableActions.update({ config: newConfig }))
    },
    [dispatch]
  )

  const onToggleViewState = useCallback(() => {
    if (state.workflowStatus === automatedWorkflowsTableActions.ENABLED_STATE) {
      setState({ workflowStatus: automatedWorkflowsTableActions.DISABLED_STATE })
    } else {
      setState({ workflowStatus: automatedWorkflowsTableActions.ENABLED_STATE })
    }
  }, [state.workflowStatus])

  const onWorkflowStatusChange = useCallback(
    (workflowUUID: string) => {
      if (state.workflowStatus === automatedWorkflowsTableActions.DISABLED_STATE) {
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.AUTOMATED_WORKFLOW_ENABLED, {
          accessTokenId,
          page: 'automated-workflows',
          workflowId: workflowUUID
        })

        dispatch(toggleWorkflowStatus({ workflowUUID, workflowStatus: automatedWorkflowsTableActions.ENABLED_STATE }))
      } else {
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.AUTOMATED_WORKFLOW_DISABLED, {
          accessTokenId,
          page: 'automated-workflows',
          workflowId: workflowUUID
        })

        dispatch(toggleWorkflowStatus({ workflowUUID, workflowStatus: automatedWorkflowsTableActions.DISABLED_STATE }))
      }
    },
    [accessTokenId, dispatch, state.workflowStatus]
  )

  const openWorkflowDialog = useCallback(
    (uuid?: string) => {
      if (uuid) {
        setState({ isNewWorkflow: false })
        dispatch(getWorkflow({ accessTokenId, uuid }))
      }
      if (isIRV2AutomatedWorkflowsOn) {
        // When we have the route setup for Automated Workflow V2 page replace window.open()
        window.open('https://http.cat/404')
      } else {
        toggleWorkflowDialog()
      }
    },
    [accessTokenId, dispatch, isIRV2AutomatedWorkflowsOn, toggleWorkflowDialog]
  )

  const workflowDialogConfig = useMemo(() => {
    return {
      existingWorkflow: automatedWorkflows.workflow,
      isNewWorkflow: state.isNewWorkflow,
      isOpened: isWorkflowDialogOpened,
      onClose: closeWorkflowDialog
    }
  }, [automatedWorkflows.workflow, closeWorkflowDialog, isWorkflowDialogOpened, state.isNewWorkflow])

  const alertConfig = useMemo(() => {
    return {
      alertContent: toggleWorkflowErrorMsg ? formatMessage(toggleWorkflowErrorMsg) : '',
      closeAction: () => {
        dispatch(resetToggleWorkflowStatus())
      },
      pageAlert: true,
      showClose: true
    }
  }, [dispatch, formatMessage, toggleWorkflowErrorMsg])

  return useMemo(
    () => [
      {
        columnsConfig: automatedWorkflowsTable.columnsConfig,
        columnMenuConfig: {
          filter: automatedWorkflowsTable.filter,
          columns: automatedWorkflowsTable.columnsConfig
        },
        filterConfig: {
          onFilterChange: (e: any) => {
            onTableConfigChange({ filter: e.filter as any, page: { skip: 0, take: 10 } })
          }
        },
        GRID_COLUMNS: { ...automatedWorkflowsTable.GRID_COLUMNS },
        isGetWorkflowsLoading,
        pageConfig: {
          ...automatedWorkflowsTable.page,
          total: automatedWorkflows.workflows.total,
          onPageChange: (e: any) => {
            onTableConfigChange({ page: e.page as { skip: number; take: number } })
          }
        },
        sortConfig: {
          sortable: {
            allowUnsort: false,
            mode: 'single'
          },
          sort: automatedWorkflowsTable.sort,
          onSortChange: (e: any) => {
            onTableConfigChange({ sort: e.sort as SortDescriptor[] })
          }
        },
        viewState: state.workflowStatus,
        closeWorkflowDialog,
        gotoWorkflowView,
        gridData,
        isColumnActive,
        onToggleViewState,
        onWorkflowStatusChange,
        openWorkflowDialog,
        workflowDialogConfig,
        alertConfig,
        showErrorAlert: !!toggleWorkflowErrorMsg
      }
    ],
    [
      alertConfig,
      automatedWorkflowsTable.columnsConfig,
      automatedWorkflowsTable.filter,
      automatedWorkflowsTable.GRID_COLUMNS,
      automatedWorkflowsTable.page,
      automatedWorkflowsTable.sort,
      automatedWorkflows.workflows.total,
      closeWorkflowDialog,
      gotoWorkflowView,
      gridData,
      isColumnActive,
      isGetWorkflowsLoading,
      onTableConfigChange,
      onToggleViewState,
      onWorkflowStatusChange,
      openWorkflowDialog,
      state.workflowStatus,
      toggleWorkflowErrorMsg,
      workflowDialogConfig
    ]
  )
}
