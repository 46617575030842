import { createSlice, createAction } from '@reduxjs/toolkit'

// initialState
export const INITIAL_STATE = {}

const reset = createAction('DATATABLES/reset/all')

/* eslint-disable no-param-reassign */
export const reportSlice = createSlice({
  name: 'DATATABLES',
  initialState: {},
  reducers: {},
  extraReducers: builder => {
    builder.addCase(reset, () => {
      return {
        ...INITIAL_STATE
      }
    })
  }
})
/* eslint-enable no-param-reassign */

export { reset }

export default reportSlice.reducer
