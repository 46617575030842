import React from 'react'

import globalPrivateRoute from 'global/components/lib/routes/PrivateRoute'
import validateSession from 'global/components/lib/authLayer/validateSession'

import reportLogic from 'fir/components/lib/report/report'
import routesConfig from 'fir/lib/routes/routesConfig'
import validateAppForAccessToken from 'fir/lib/validateAppForAccessToken'

export default globalPrivateRoute((component, childProps) => {
  const WrappedComponent = validateSession(
    reportLogic(component, { validateAppForAccessToken }),
    routesConfig.SIGNIN_SIGNUP.goto
  )
  return <WrappedComponent {...childProps} />
})
