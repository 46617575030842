import { useMemo, useEffect } from 'react'

import { process } from '@progress/kendo-data-query'

import { luxonDate, formatDateWithTime } from 'global/lib/datetime'

import { BDSGridPagerConfig } from 'global/types/dataTables/dataTables'
import { ColumnsConfig } from 'global/types/dataTables/columnsConfigType'
import { isPending, getErrorMessage } from 'global/redux/toolkit/api'
import { Alert as AlertType, AlertSignin } from 'global/types/api/ato'
import { getCountryName } from 'global/lib/isoCountries'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'

import {
  update as updateuserRelatedSigninsTable,
  reset as resetUserRelatedSigninsTable
} from 'sen/redux/features/dataTables/signinsByUser/signinsByUser'
import { getUserRelatedSignins, resetUserRelatedSignins } from 'sen/redux/features/ato/atoSlice'
import { GetUserRelatedSignins } from 'sen/redux/features/ato/atoApiThunks'

export interface ModifiedAlertSignin extends AlertSignin {
  userPrincipalName?: string
  formattedDate: string
  countryName: string
}

export type InProgress = boolean
export type IsLoaded = boolean
export type ApiError = string | undefined
export interface TableConfig {
  tableData: {
    total: number
    data: any[]
  }
  isFlexibleTable: boolean
  pageConfig: BDSGridPagerConfig
  columns: { [key: string]: string }
  columnsConfig: { [key: string]: ColumnsConfig }
}
export type Timeframe = number | undefined

export interface UserRelatedAlert {
  emailAddress: string
  timeframe: number
}

export enum DialogType {
  Alert = 'alert',
  User = 'user',
  Country = 'country'
}

export interface UseUserRelatedSigninsLogic {
  alert?: AlertType
  user?: UserRelatedAlert
  dialogType: DialogType
}

export default function useUserRelatedSigninsLogic({
  alert,
  user,
  dialogType
}: UseUserRelatedSigninsLogic): [IsLoaded, InProgress, ApiError, TableConfig, Timeframe] {
  const dispatch = useAppDispatch()
  const { inProgress, apiError, userRelatedSigninsTable, userRelatedSignins, loadedOffsets } = useAppSelector(
    _stores => ({
      inProgress: isPending(_stores.ato.getUserRelatedSigninsApiStatus),
      apiError: getErrorMessage(_stores.ato.getUserRelatedSigninsApiStatus),
      userRelatedSigninsTable: _stores.dataTables.signinsByUser,
      userRelatedSignins: _stores.ato.userRelatedSignins,
      loadedOffsets: _stores.ato.loadedUserRelatedSigninsOffsets
    })
  )
  const isActiveLogic = [DialogType.Alert, DialogType.User].includes(dialogType)

  // Init
  useEffect(() => {
    if (isActiveLogic) {
      dispatch(getUserRelatedSignins(userRelatedSigninsApiParams))
    }

    return () => {
      if (isActiveLogic) {
        dispatch(resetUserRelatedSigninsTable())
        dispatch(resetUserRelatedSignins(true))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const userRelatedSigninsApiParams: GetUserRelatedSignins = useMemo(() => {
    const baseParams = {
      userPrincipalName: alert?.emailAddress || user?.emailAddress || ''
    }

    if (dialogType === DialogType.Alert) {
      return {
        ...baseParams,
        startTime: luxonDate(alert?.createdOn)
          .minus({ days: 4 })
          .toUTC()
          .toISO(),
        endTime: luxonDate(alert?.createdOn)
          .plus({ days: 4 })
          .toUTC()
          .toISO()
      }
    }

    return {
      ...baseParams,
      startTime: luxonDate()
        .minus({ days: user?.timeframe })
        .toUTC()
        .toISO(),
      endTime: luxonDate()
        .toUTC()
        .toISO()
    }
  }, [alert, user, dialogType])

  const tableData = useMemo(() => {
    const { skip, take } = userRelatedSigninsTable

    const { data } = process(
      (userRelatedSignins?.report?.data || []).map((report: AlertSignin) => ({
        ...(report && {
          ...report,
          formattedDate: formatDateWithTime(report.date || ''),
          countryName: getCountryName(report.country)
        })
      })),
      { skip, take }
    )

    return {
      data: data.filter(report => report.date),
      total: userRelatedSignins?.report?.totalCount || 0
    }
  }, [userRelatedSignins, userRelatedSigninsTable])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take } = userRelatedSigninsTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: tableData.total,
      onPageChange: (e: any) => {
        dispatch(updateuserRelatedSigninsTable(e.page))

        if (!loadedOffsets.includes(e.page.skip)) {
          dispatch(getUserRelatedSignins(userRelatedSigninsApiParams))
        }
      }
    }
  }, [userRelatedSigninsTable, tableData, loadedOffsets, dispatch, userRelatedSigninsApiParams])

  return useMemo(() => {
    return [
      !!userRelatedSignins || !!apiError,
      inProgress,
      apiError,
      {
        tableData,
        pageConfig,
        isFlexibleTable: !!tableData.total && tableData.total < userRelatedSigninsTable.ITEMS_PER_PAGE,
        columns: userRelatedSigninsTable.GRID_COLUMNS,
        columnsConfig: userRelatedSigninsTable.columnsConfig
      },
      user?.timeframe
    ]
  }, [tableData, pageConfig, userRelatedSigninsTable, inProgress, userRelatedSignins, apiError, user])
}
