import React, { useMemo, createContext, useState } from 'react'
import PropTypes from 'prop-types'

import { Typography, Dialog, DialogTitle, Step, StepLabel, Stepper } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'global/lib/localization'

import DEFAULTS from 'global/configs/theme/defaults'

import NewIncidentInit from 'sen/components/lib/dialogs/newIncidentDialog/steps/NewIncidentInit'
import NewIncidentForm from 'sen/components/lib/dialogs/newIncidentDialog/steps/NewIncidentForm'
import ConfirmIncident from 'sen/components/lib/dialogs/newIncidentDialog/steps/ConfirmIncident'
import Quarantine from 'sen/components/lib/dialogs/newIncidentDialog/steps/Quarantine'
import Notify from 'sen/components/lib/dialogs/newIncidentDialog/steps/Notify'
import InboxRules from 'sen/components/lib/dialogs/newIncidentDialog/steps/InboxRules'
import Done from 'sen/components/lib/dialogs/newIncidentDialog/steps/Done'

import styles from 'sen/components/lib/dialogs/newIncidentDialog/newIncidentDialogStyles'

export interface NewIncidentDialogWizardProps {
  open?: boolean
  onClose: () => void
}

export const STEPS = ['init', 'new_incident', 'quarantine', 'notify', 'inbox_rules', 'done']
const BASE_I18N_KEY = 'sen.app.new_incident_dialog'

export type ActiveStepState = {
  activeStep: number
}

export type ConfirmIncidentState = {
  gotoConfirmIncident: boolean
}

export type InboxRulesState = {
  gotoInboxRules: boolean
}

const contextDefaultValue = {
  stepState: {
    activeStep: 0
  },
  confirmIncidentState: {
    gotoConfirmIncident: false
  },
  inboxRulesState: {
    gotoInboxRules: false
  },
  /* eslint-disable @typescript-eslint/no-empty-function */
  setStepState: (state: ActiveStepState) => {},
  setConfirmIncidentState: (state: ConfirmIncidentState) => {},
  setInboxRulesState: (state: InboxRulesState) => {}
  /* eslint-enable @typescript-eslint/no-empty-function */
}

export const NewIncidentDialogWizardContext = createContext(contextDefaultValue)

const NewIncidentDialogWizard: React.FC<NewIncidentDialogWizardProps> = ({ open = true, onClose }) => {
  const [stepState, setStepState] = useState(contextDefaultValue.stepState)
  const [confirmIncidentState, setConfirmIncidentState] = useState(contextDefaultValue.confirmIncidentState)
  const [inboxRulesState, setInboxRulesState] = useState(contextDefaultValue.inboxRulesState)

  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  return useMemo(
    () => (
      <NewIncidentDialogWizardContext.Provider
        value={{
          stepState,
          setStepState,
          confirmIncidentState,
          setConfirmIncidentState,
          inboxRulesState,
          setInboxRulesState
        }}
      >
        <Dialog
          fullWidth
          maxWidth="md"
          open={open}
          style={{ zIndex: DEFAULTS.DIALOGS_ZINDEX }}
          classes={{ paper: classes.paper }}
        >
          <Stepper activeStep={stepState.activeStep} alternativeLabel>
            {STEPS.map(label => (
              <Step key={label}>
                <StepLabel />
              </Step>
            ))}
          </Stepper>
          <DialogTitle className={classes.dialogStepWrapper}>
            <Typography className={classes.dialogTitle}>{formatMessage('wizard_title')}</Typography>
          </DialogTitle>
          {stepState.activeStep === 0 && <NewIncidentInit onClose={onClose} activeStep={stepState.activeStep} />}

          {!confirmIncidentState.gotoConfirmIncident && stepState.activeStep === 1 && (
            <NewIncidentForm onClose={onClose} activeStep={stepState.activeStep} />
          )}
          {confirmIncidentState.gotoConfirmIncident && stepState.activeStep === 1 && (
            <ConfirmIncident onClose={onClose} activeStep={stepState.activeStep} />
          )}

          {stepState.activeStep === 2 && <Quarantine onClose={onClose} activeStep={stepState.activeStep} />}
          {stepState.activeStep === 3 && <Notify onClose={onClose} activeStep={stepState.activeStep} />}
          {stepState.activeStep === 4 && <InboxRules onClose={onClose} activeStep={stepState.activeStep} />}
          {stepState.activeStep === 5 && <Done onClose={onClose} activeStep={stepState.activeStep} />}
        </Dialog>
      </NewIncidentDialogWizardContext.Provider>
    ),
    [formatMessage, classes, open, stepState, confirmIncidentState, onClose, inboxRulesState]
  )
}

NewIncidentDialogWizard.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired
}

NewIncidentDialogWizard.defaultProps = {
  open: true
}

export default NewIncidentDialogWizard
