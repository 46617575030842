import { useCallback, useEffect, useMemo, useReducer } from 'react'

import gridFilteroperator from 'global/components/lib/grid/filter/operators'

export interface Dropdown {
  value: string
  content: any
}

export interface UseDropdownFilterLogicProps {
  firstFilterProps?: any
  operators?: string[]
  dropdownValues: Dropdown[]
  intl: any
  selectedOperator: string
  onDropdownChange: (e: any) => void
}

const NOT_REMEDIATED_STATUSES = ['FO', 'IP', 'ER']
const REMEDIATED_STATUSES = ['DE', 'NF', 'QU']
const BASE_I18N_KEY = 'app.filters'

export default function useDropdownFilterLogic(
  firstFilterProps: any,
  intl: any,
  operators: string[] = [gridFilteroperator.DJANGO_REMEDIATED, gridFilteroperator.DJANGO_NOT_REMEDIATED]
): [UseDropdownFilterLogicProps] {
  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    selectedOperator: ''
  })

  // shallow update filter
  const updateFilter = useCallback(
    (config: any) => {
      firstFilterProps.onChange({
        value: firstFilterProps.value,
        operator: firstFilterProps.operator,
        ...config
      })
    },
    [firstFilterProps]
  )

  // init
  useEffect(() => {
    updateFilter({ operator: gridFilteroperator[(operators || [])[0]], title: 'Init' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dropdownValues = useMemo(() => {
    return (operators || []).map((operator: string) => ({
      value: gridFilteroperator[operator],
      content: intl.formatMessage({ id: `${BASE_I18N_KEY}.${operator.toLowerCase()}` })
    }))
  }, [intl, operators])

  // dropdown
  const onDropdownChange = useCallback(
    (e: any) => {
      if (e.target.value.toLowerCase() === 'remediated') {
        const test = REMEDIATED_STATUSES.map((status: any) => {
          return status
        })
        setState({ selectedOperator: 'Remediated' })
        updateFilter({ operator: '=', value: test.toString() })
      } else {
        const test = NOT_REMEDIATED_STATUSES.map((status: any) => {
          return status
        })
        setState({ selectedOperator: 'Not Remediated' })
        updateFilter({ operator: '=', value: test.toString() })
      }
    },
    [updateFilter]
  )

  return useMemo(() => {
    return [
      {
        dropdownValues,
        intl,
        onDropdownChange,
        selectedOperator: state.selectedOperator
      }
    ]
  }, [dropdownValues, intl, onDropdownChange, state.selectedOperator])
}
