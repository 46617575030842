import { useMemo } from 'react'

import { ConnectO365DialogLogicProps } from 'global/components/lib/dialogs/connectO365/useConnectO365DialogLogic'
import { config } from 'global/lib/config'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import useFeatureLib from 'global/lib/feature/useFeature'
import useDialogLogic, { DialogProps } from 'global/lib/dialogs/useDialogLogic'

import gotoReportPage from 'ets/lib/gotoReportPage'
import { useAppSelector } from 'ets/redux/toolkit/hooks'

export interface EmptyReportLogic {
  toggleDialog: DialogProps['toggleDialog']
  connectO365DialogProps: ConnectO365DialogLogicProps
}

export default function useEmptyReportLogic(): [EmptyReportLogic] {
  const [isDialogOpened, toggleDialog] = useDialogLogic()

  const { accounts } = useAppSelector(_stores => ({
    accounts: _stores.user.data.accounts
  }))
  const [featureLib] = useFeatureLib()

  const connectO365DialogProps: ConnectO365DialogLogicProps = useMemo(() => {
    return {
      successCb: (data: any, scanType: string) => {
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.O365_EMPTY_REPORT_CONNECT_SUCCESSFUL, {
          accessTokenId: data.accessToken.id
        })
        if (scanType === config.SCAN_TYPES.SENTINEL) {
          window.location.replace(`${config.domains.sentinel}/report/${data.accessToken.id}`)
        } else {
          gotoReportPage({ accessTokenId: data.accessToken.id, user: data.user, scan: data.scan })
        }
      },
      showScanTypeSelection:
        (accounts && featureLib.hasSentinelFeatureForAnyAccessToken()) || featureLib.hasETSReadOnlyForAnyAccessToken(),
      toggleDialog,
      isDialogOpened,
      accounts
    }
  }, [accounts, toggleDialog, isDialogOpened, featureLib])

  return useMemo(
    () => [
      {
        toggleDialog,
        connectO365DialogProps
      }
    ],
    [toggleDialog, connectO365DialogProps]
  )
}
