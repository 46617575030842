import { makeStyles } from '@material-ui/core/styles'
import { COLORS } from 'global/configs/theme/defaults'

import dialogStyles from 'global/components/lib/dialogs/dialogStyles'

export default makeStyles(theme => {
  const defaultDialogStyles = dialogStyles(theme)

  return {
    ...defaultDialogStyles,
    dialogSubtitle: {
      color: COLORS.ERROR
    }
  }
})
