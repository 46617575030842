import { createSlice, isAnyOf } from '@reduxjs/toolkit'

import { reset as resetDataTables } from 'global/redux/features/dataTables/dataTablesSlice'

import {
  DataTableState,
  DataTableWithDemoState,
  DataTableWithFilterAndDemoState,
  ThreatEmailsState
} from 'ets/redux/types/dataTables'
import * as attackingDomains from 'ets/redux/features/dataTables/attackingDomains/attackingDomainsSlice'
import * as domains from 'ets/redux/features/dataTables/domains/domainSlice'
import * as employees from 'ets/redux/features/dataTables/employees/employeesSlice'
import * as threatEmails from 'ets/redux/features/dataTables/threatEmails/threatEmailsSlice'
import * as threats from 'ets/redux/features/dataTables/threats/threatsSlice'
import * as userEmails from 'ets/redux/features/dataTables/userEmails/userEmailsSlice'

export interface DataTablesState {
  attackingDomains: DataTableWithDemoState
  domains: DataTableWithDemoState
  threatEmails: ThreatEmailsState
  employees: DataTableWithFilterAndDemoState
  userEmails: DataTableState
  threats: DataTableWithFilterAndDemoState
}

// initialState
export const INITIAL_STATE = {
  attackingDomains: attackingDomains.INITIAL_STATE,
  domains: domains.INITIAL_STATE,
  threatEmails: threatEmails.INITIAL_STATE,
  employees: employees.INITIAL_STATE,
  userEmails: userEmails.INITIAL_STATE,
  threats: threats.INITIAL_STATE
}

/* eslint-disable no-param-reassign */
export const dataTablesSlice = createSlice({
  name: 'DATA_TABLES',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(resetDataTables.toString(), () => {
        return {
          ...INITIAL_STATE
        }
      })
      .addMatcher(isAnyOf(attackingDomains.update, attackingDomains.reset), (state: DataTablesState, action) => {
        state.attackingDomains = { ...attackingDomains.default(state.attackingDomains, action) }
      })
      .addMatcher(isAnyOf(domains.update, domains.reset), (state: DataTablesState, action) => {
        state.domains = { ...domains.default(state.domains, action) }
      })
      .addMatcher(isAnyOf(employees.update, employees.reset), (state: DataTablesState, action) => {
        state.employees = { ...employees.default(state.employees, action) }
      })
      .addMatcher(isAnyOf(threats.update, threats.reset), (state: DataTablesState, action) => {
        state.threats = { ...threats.default(state.threats, action) }
      })
      .addMatcher(isAnyOf(userEmails.update, userEmails.reset), (state: DataTablesState, action) => {
        state.userEmails = { ...userEmails.default(state.userEmails, action) }
      })
  }
})
/* eslint-enable no-param-reassign */

export default dataTablesSlice.reducer
