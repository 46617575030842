import { useMemo, useCallback } from 'react'

import { EmailInfo } from 'global/components/lib/dialogs/emailDetailsDialog/components/emailContentDetails/useEmailContentDetailsLogic'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { config } from 'global/lib/config'
import { formatDateWithTime } from 'global/lib/datetime'
import { useAppSelector } from 'global/redux/toolkit/hooks'
import {
  EmailDetailsDialogProps,
  HeaderDetail
} from 'global/components/lib/dialogs/emailDetailsDialog/emailDetailDialogTypes'

export interface UseEmailPreviewDialogProps {
  data: EmailDetailsDialogProps
  hideReplyTo?: boolean
  isGroupedRecipients?: boolean
}

export type SearchForensics = () => void
export type IsHtml = boolean

export function renderEmail(emailObject: any = {}, isGroupedRecipients?: boolean) {
  const { email = '', displayName = '' }: { email: string; displayName: string } = emailObject
  if (!email.length) {
    return ''
  }
  return isGroupedRecipients ? email : `${displayName} <${email}>`
}

export const HTML_MIME_TYPE = 'text/html'

export default function useAttackDetailDialogLogic({
  data,
  hideReplyTo,
  isGroupedRecipients
}: UseEmailPreviewDialogProps): [EmailInfo | undefined, HeaderDetail[], IsHtml, SearchForensics] {
  const { accessTokenId } = useAppSelector((_stores: any) => ({
    accessTokenId: _stores.accessToken.accessToken?.id || ''
  }))

  const { emailDetails, emailDataStatuses } = data

  const titleDate: string = useMemo(() => {
    return emailDetails.date ? formatDateWithTime(emailDetails.date) : ''
  }, [emailDetails])

  const onFindMessagesInForensics: SearchForensics = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.SEARCH_FORENSICS)

    window.open(
      `${config.domains.forensics}/new-incident?email=${emailDetails.from.email}&subject=${emailDetails.subject}&source=sentinel&accessToken=${accessTokenId}`
    )
  }, [accessTokenId, emailDetails])

  const isHtml = useMemo(() => {
    return emailDetails.bodyMimeType === HTML_MIME_TYPE
  }, [emailDetails.bodyMimeType])

  const headerDetails: HeaderDetail[] = useMemo(() => {
    if (!emailDataStatuses.isLoaded) {
      return []
    }

    let headerIds = ['from', 'to', 'reply_to', 'date', 'subject']
    headerIds = headerIds.filter(headerId => headerId !== (hideReplyTo && 'reply_to'))
    const headerIdsDetails = headerIds.map((headerId: string) => ({
      id: headerId,
      ...(headerId === 'from' && { content: renderEmail(emailDetails.from) }),
      ...(headerId === 'to' && { content: renderEmail(emailDetails.to, isGroupedRecipients) }),
      ...(headerId === 'reply_to' && {
        content: (emailDetails.replyTo || [])
          .reduce((all: string[], recipient: any) => {
            return [...all, renderEmail(recipient)]
          }, [])
          .join(', ')
      }),
      ...(headerId === 'date' && { content: titleDate }),
      ...(headerId === 'subject' && { content: emailDetails.subject })
    }))
    return headerIdsDetails.filter(headerDetail => !!headerDetail.content)
  }, [emailDetails, emailDataStatuses, hideReplyTo, isGroupedRecipients, titleDate])

  const emailInfo: EmailInfo | undefined = useMemo(() => {
    if (!emailDataStatuses.isLoaded) {
      return undefined
    }

    return {
      headers: emailDetails.headers,
      failureReport: emailDetails.failureReport,
      statistics: emailDetails.statistics,
      content: isHtml ? emailDetails.body : `<div>${emailDetails.body}</div>`,
      attachments: emailDetails.attachments
    }
  }, [emailDetails, emailDataStatuses, isHtml])

  return useMemo(() => {
    return [emailInfo, headerDetails, isHtml, onFindMessagesInForensics]
  }, [emailInfo, headerDetails, isHtml, onFindMessagesInForensics])
}
