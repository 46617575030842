import { useMemo, useCallback, useReducer } from 'react'

import { isEqual } from 'lodash'
import validator from 'validator'

import { useEffectOnInit, useEffectOnlyOnce } from 'global/lib/useCustomEffect'
import { isPending, isSuccess } from 'global/redux/toolkit/api'

import { getSyslogConfig, saveSyslogConfig, testSyslogConfig } from 'sen/redux/features/syslog/syslogSlice'
import { SyslogConfig } from 'sen/redux/features/syslog/syslogTypes'
import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'

export type IsTabInProgress = boolean
export type OnSave = () => void
export type OnTest = () => void
export type Error = string | undefined

export interface FormHandlers {
  onFormChange: (formValue: Partial<SyslogConfig>) => void
  onSave: OnSave
  onTest: OnTest
}

export interface FormStates {
  isValidHost: boolean
  isValidPort: boolean
  isSaveButtonDisabled: boolean
  isTestButtonDisabled: boolean
  isSuccessTest: boolean
  isFailedTest: boolean
  error: Error
}

const DEFAULT_SYSLOG_CONFIG: SyslogConfig = {
  isActive: false,
  syslogHost: '',
  syslogPort: 6514
}

export default function useSyslogIntegrationTabLogic(): [IsTabInProgress, SyslogConfig, FormHandlers, FormStates] {
  const dispatch = useAppDispatch()
  const {
    isGetSyslogConfig,
    isGetSyslogConfigSuccess,
    isSaveSyslogConfig,
    isTestSyslogConfig,
    isTestSyslogConfigSuccess,
    syslogConfig,
    syslogResult,
    settingsError
  } = useAppSelector(_stores => ({
    isGetSyslogConfig: isPending(_stores.syslog.getSyslogConfigApiStatus),
    isGetSyslogConfigSuccess: isSuccess(_stores.syslog.getSyslogConfigApiStatus),
    syslogConfig: _stores.syslog.syslogConfig,
    isSaveSyslogConfig: isPending(_stores.syslog.saveSyslogConfigApiStatus),
    isTestSyslogConfig: isPending(_stores.syslog.testSyslogConfigApiStatus),
    isTestSyslogConfigSuccess: isSuccess(_stores.syslog.testSyslogConfigApiStatus),
    syslogResult: _stores.syslog.syslogResult,
    settingsError: _stores.syslog.errorMsg
  }))
  const [formValues, setFormValues] = useReducer(
    (_state: SyslogConfig, newState: Partial<SyslogConfig>) => ({ ..._state, ...newState }),
    {
      ...DEFAULT_SYSLOG_CONFIG
    }
  )

  // init
  useEffectOnInit(() => {
    if (!isGetSyslogConfig && !isGetSyslogConfigSuccess) {
      dispatch(getSyslogConfig())
    }
  }, [])

  // set the initial form value
  useEffectOnlyOnce(
    () => {
      if (syslogConfig) {
        setFormValues(syslogConfig)
      }
    },
    [syslogConfig],
    isGetSyslogConfigSuccess
  )

  const isTabInProgress: boolean = useMemo(() => {
    return isGetSyslogConfig || isSaveSyslogConfig || isTestSyslogConfig
  }, [isGetSyslogConfig, isTestSyslogConfig, isSaveSyslogConfig])

  const isValidHost: boolean = useMemo(() => {
    return (
      !!formValues.syslogHost.length &&
      (validator.isURL(formValues.syslogHost) || validator.isIP(formValues.syslogHost))
    )
  }, [formValues.syslogHost])

  const isValidPort: boolean = useMemo(() => {
    return !!String(formValues.syslogPort).length && validator.isPort(String(formValues.syslogPort))
  }, [formValues.syslogPort])

  const isValidForm: boolean = useMemo(() => {
    return isValidHost && isValidPort
  }, [isValidHost, isValidPort])

  const isSavedForm: boolean = useMemo(() => {
    return isEqual(formValues, syslogConfig || DEFAULT_SYSLOG_CONFIG)
  }, [formValues, syslogConfig])

  const isSaveButtonDisabled: boolean = useMemo(() => {
    return isTabInProgress || !isValidForm || isSavedForm
  }, [isTabInProgress, isSavedForm, isValidForm])

  const isTestButtonDisabled: boolean = useMemo(() => {
    return isTabInProgress || !isValidForm || !isSavedForm || !formValues.isActive
  }, [isTabInProgress, isValidForm, isSavedForm, formValues])

  const onSave: OnSave = useCallback(() => {
    dispatch(
      saveSyslogConfig({
        syslogConfig: formValues
      })
    )
  }, [dispatch, formValues])

  const onTest: OnTest = useCallback(() => {
    dispatch(testSyslogConfig())
  }, [dispatch])

  return useMemo(() => {
    return [
      isTabInProgress,
      formValues,
      {
        onFormChange: setFormValues,
        onSave,
        onTest
      },
      {
        isValidHost,
        isValidPort,
        isSaveButtonDisabled,
        isTestButtonDisabled,
        isSuccessTest: isTestSyslogConfigSuccess && !!syslogResult?.delivered,
        isFailedTest: isTestSyslogConfigSuccess && !syslogResult?.delivered,
        error: settingsError
      }
    ]
  }, [
    onTest,
    syslogResult,
    isTabInProgress,
    onSave,
    settingsError,
    formValues,
    setFormValues,
    isSaveButtonDisabled,
    isTestButtonDisabled,
    isTestSyslogConfigSuccess,
    isValidHost,
    isValidPort
  ])
}
