import { useEffect, useMemo, useState } from 'react'

import { getEmailInfo, reset } from 'fir/redux/features/emails/emailsSlice'
import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'

import {
  EmailDetailsDialogProps,
  OnCloseDialogCallback,
  DialogActions,
  EMPTY_EMAIL_DETAILS
} from 'global/components/lib/dialogs/emailDetailsDialog/emailDetailDialogTypes'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'
import { isFailed, isPending } from 'global/redux/toolkit/api'

export interface UseEmailDetailsInterfaceProps {
  onClose?: OnCloseDialogCallback
}

export interface SelectedEmail {
  selectedEmail: string
}

export type UseEmailDetailsInterface = [EmailDetailsDialogProps, DialogActions<(emailId: string) => void>]

export interface EmailDetail {
  address: string
  name: string | undefined
}

export function renderEmail(details: EmailDetail) {
  return `${details.name}${details.name ? ' <' : ''}${details.address}${details.name ? '>' : ''}`
}

export function collectRecipients(recipients: EmailDetail[]) {
  return recipients.map((recipient: EmailDetail) => renderEmail(recipient)).join(', ')
}

export default function useEmailDetailsInterface({ onClose }: UseEmailDetailsInterfaceProps): UseEmailDetailsInterface {
  const dispatch = useAppDispatch()
  const { emails, isEmailInfoFailed, isEmailInfoLoading } = useAppSelector(_stores => ({
    emails: _stores.emails,
    isEmailInfoFailed: isFailed(_stores.emails.emailInfoLoadingApiStatus),
    isEmailInfoLoading: isPending(_stores.emails.emailInfoLoadingApiStatus)
  }))
  const [isDialogOpened, toggleDialog] = useDialogLogic()
  const [selectedEmail, setSelectedEmail] = useState<string>('')

  const selectedEmailInfo = emails.emailInfoList[selectedEmail]

  // init
  useEffect(() => {
    return () => {
      dispatch(reset())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dialogActions = useMemo(
    () => ({
      open: isDialogOpened,
      onOpen: (dataItem: any) => {
        setSelectedEmail(dataItem.emailId)
        dispatch(getEmailInfo({ emailId: dataItem.emailId }))
        toggleDialog()
      },
      onClose: () => {
        if (onClose) {
          onClose()
        }
        setSelectedEmail('')
        toggleDialog()
      }
    }),
    [dispatch, isDialogOpened, onClose, toggleDialog]
  )

  return useMemo(() => {
    return [
      {
        emailDetails: selectedEmailInfo
          ? {
              attachments: selectedEmailInfo.attachments,
              body: selectedEmailInfo.body.content,
              bodyMimeType: 'text/html',
              date: selectedEmailInfo.created,
              from: {
                email: selectedEmailInfo.sender.address,
                displayName: selectedEmailInfo?.sender.name
              },
              headers: selectedEmailInfo['internet-message-headers'],
              subject: selectedEmailInfo.subject,
              to: {
                email: collectRecipients(selectedEmailInfo.recipients || []),
                displayName: ''
              }
            }
          : { ...EMPTY_EMAIL_DETAILS, replyTo: undefined },
        emailDataStatuses: {
          inProgress: isEmailInfoLoading,
          isLoaded: !!emails,
          isFailed: isEmailInfoFailed
        },
        eventHandlers: {
          onClose: dialogActions.onClose
        }
      },
      dialogActions
    ]
  }, [dialogActions, emails, isEmailInfoFailed, isEmailInfoLoading, selectedEmailInfo])
}
