import _ from 'lodash'

import userDataLib from 'global/lib/userData/userData'
import * as analyticsLib from 'global/lib/analytics/analyticsService'

import { User } from 'global/types/api/userType'

// Todo: Need to set the authenticated user for Sentry and Analytics after BNFIR-861 is completed
// if (this.userDataService.hasUser()) {
//   this.initializeUserInServices()
// }

export function initializeUserInServices(): void {
  // this.ravenService.setUser(this.userDataService.getUser())
  analyticsLib.setUser(userDataLib.getUser())
}

export function authenticate(adminUser: User): void {
  // have to create deep and recursive copy of the user object instead of plain assignment
  // due to some IE specific permission issues after object is returned from child window
  const impersonatedUser = userDataLib.getUser()
  _.merge(impersonatedUser, adminUser)
  // initializeUserInServices()
}
