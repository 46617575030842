import deepmerge from 'deepmerge'

import { makeStyles } from '@material-ui/core/styles'
import { makeGridStyles } from 'global/components/lib/grid/gridStyles'
import { makeWorkflowFlowStyles } from 'global/components/lib/flowDiagram/flowDiagramStyles'

export default makeStyles(theme => ({
  bold: {
    fontWeight: theme.font.weight.bold
  },
  breadcrumb: {
    marginTop: theme.spacing(1)
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  editor: {
    paddingBottom: '10px'
  },
  editWorkflowButton: {
    float: 'right',
    margin: 6
  },
  flowContainer: {
    height: 500
  },
  header: {
    padding: theme.spacing(2),
    position: 'relative',
    zIndex: 1
  },
  pageTitle: {
    margin: theme.spacing(3, 2, 1, 2)
  },
  title: {
    display: 'inline-block'
  },
  wrapper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 2,
    margin: theme.spacing(2, 2, 3, 2),
    overflow: 'hidden',
    position: 'relative'
  }
}))

export const gridStyle = {
  gridLayout: {
    '& .k-link': {
      whiteSpace: 'normal'
    },
    '& .k-grid-table': {
      wordBreak: 'break-word'
    },
    '& .k-grid-header col:nth-of-type(1)': {
      width: '2%'
    },
    '& .k-grid-table col:nth-of-type(1)': {
      width: '2%'
    },
    '& .k-grid-header col:nth-of-type(2)': {
      width: '8%'
    },
    '& .k-grid-table col:nth-of-type(2)': {
      width: '8%'
    },
    '& .k-grid-header col:nth-of-type(3)': {
      width: '15%'
    },
    '& .k-grid-table col:nth-of-type(3)': {
      width: '15%'
    },
    '& .k-grid-header col:nth-of-type(4)': {
      width: '15%'
    },
    '& .k-grid-table col:nth-of-type(4)': {
      width: '15%'
    },
    // Disable hover over background color
    '& .k-grid-table tr:hover': {
      background: 'transparent'
    },
    '& .k-grid-table tr.k-alt:hover': {
      background: 'transparent'
    },
    '& .k-grid-header tr:hover': {
      background: 'transparent'
    },
    '& .k-grid-header tr.k-alt:hover': {
      background: 'transparent'
    }
  }
}

export const workflowFlowStyle = {}

export const workflowDetailsGridStyles = makeStyles(theme => ({
  ...deepmerge(makeGridStyles(theme), gridStyle)
}))

export const workflowFlowDiagramStyles = makeStyles(theme => ({
  ...deepmerge(makeWorkflowFlowStyles(theme), workflowFlowStyle)
}))
