import React from 'react'
import PropTypes from 'prop-types'

import styles from './cellStyles'

export interface Props {
  children: any
  onClick: any
  className?: string
}

const CellAction: React.FC<Props> = ({ children, onClick, className, ...rest }) => {
  const classes = styles()

  return (
    <div className={`${classes.icon} ${className}`} role="presentation" onClick={onClick} {...rest}>
      {children}
    </div>
  )
}

CellAction.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.any.isRequired,
  className: PropTypes.string
}

CellAction.defaultProps = {
  className: undefined
}

export default CellAction
