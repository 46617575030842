import { useMemo, useState } from 'react'
import { isNumber } from 'lodash'

import { isPending, isSuccess, isFailed } from 'global/redux/toolkit/api'
import {
  EmailDetailsDialogProps,
  OnCloseDialogCallback,
  OnCloseReportFpDialogCallback,
  DialogActions,
  MarkedasFpDialogActions,
  Analysis,
  EmailStatistics
} from 'global/components/lib/dialogs/emailDetailsDialog/emailDetailDialogTypes'
import { AttackType } from 'global/types/api/attackType'
import { StatusIds } from 'global/components/lib/statusTypeLabel/StatusIds.enum'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { useAppDispatch, useAppSelector } from 'global/redux/toolkit/hooks'
import {
  getSpAttack,
  reset as resetAttack,
  resetAttackId,
  resetFeedbackPublicKey
} from 'global/redux/features/attack/attackSlice'
import analyzeAttack from 'global/lib/analyzeAttack/analyzeAttack'
import useStatusTypeLabelLogicForSpAttacks from 'global/components/lib/statusTypeLabel/useStatusTypeLabelLogicForSpAttacks'
import useSpService from 'global/lib/useSpService/useSpService'
import { formatDate, relativeDate } from 'global/lib/datetime'
import { getCountryNameFromCode2 } from 'global/lib/isoCountries'

export interface UseEmailDetailsSpAttackInterfaceProps {
  onClose?: OnCloseDialogCallback
  onCloseReportFpDialog?: OnCloseReportFpDialogCallback
  withFindMessagesInForensics?: boolean
  withMarkAsFp?: boolean
}

export default function useEmailDetailsSpAttackInterface({
  onClose,
  onCloseReportFpDialog,
  withFindMessagesInForensics = true,
  withMarkAsFp = true
}: UseEmailDetailsSpAttackInterfaceProps): [
  EmailDetailsDialogProps,
  DialogActions<(attack: AttackType) => void>,
  MarkedasFpDialogActions
] {
  const {
    accessTokenId,
    isAttackDetailsLoading,
    isAttackDetailsLoaded,
    isAttackDetailsFailed,
    attackDetails
  } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id || '',
    isAttackDetailsLoading: isPending(_stores.attack.spAttackApiStatus),
    isAttackDetailsLoaded: isSuccess(_stores.attack.spAttackApiStatus),
    isAttackDetailsFailed: isFailed(_stores.attack.spAttackApiStatus),
    attackDetails: _stores.attack.details
  }))
  const [isDialogOpened, toggleDialog] = useDialogLogic()
  const [isReportFalsePositiveDialogOpened, toggleReportFalsePositiveDialog] = useDialogLogic()
  const [selectedAttack, setSelectedAttack] = useState<AttackType | undefined>()

  const dispatch = useAppDispatch()
  const [statusTypeLabelLogic] = useStatusTypeLabelLogicForSpAttacks()
  const [spService] = useSpService()

  const statusId: Analysis['statusId'] | undefined = useMemo(() => {
    if (selectedAttack) {
      return statusTypeLabelLogic.getStatusId(selectedAttack)
    }

    return undefined
  }, [selectedAttack, statusTypeLabelLogic])

  const analyzedAttack: Analysis | undefined = useMemo(() => {
    if (attackDetails) {
      return {
        ...analyzeAttack(attackDetails),
        statusId,
        isStatusEmpty: statusId === StatusIds.emptyStatus,
        isMarkedAsFp: statusId === StatusIds.markedAsFp,
        hasRemediation: spService.hasRemediation(attackDetails)
      }
    }

    return undefined
  }, [attackDetails, statusId, spService])

  const statistics: EmailStatistics | undefined = useMemo(() => {
    if (attackDetails.senderDomain) {
      return {
        domain: attackDetails.senderDomain,
        senderDomainRegistrationDate:
          attackDetails.senderDomainRegistrationDate && formatDate(attackDetails.senderDomainRegistrationDate),
        senderDomainRegistrationDateRelative:
          attackDetails.senderDomainRegistrationDate && relativeDate(attackDetails.senderDomainRegistrationDate),
        senderIpAddress: attackDetails.senderIpAddress,
        senderIpLocation: getCountryNameFromCode2(attackDetails.senderIpLocation) || undefined,
        senderIpReputation: isNumber(attackDetails.senderIpReputation)
          ? String(attackDetails.senderIpReputation)
          : undefined,
        threatsDetectCount: isNumber(attackDetails.threatDetectsCount)
          ? String(attackDetails.threatDetectsCount)
          : undefined,
        isDkimAuthenticated: attackDetails.isDkimAuthenticated,
        isSpfAuthenticated: attackDetails.isSpfAuthenticated,
        isDmarcAuthenticated: attackDetails.isDmarcAuthenticated,
        isDmarcAlignmentAuthenticated: attackDetails.isDmarcAlignmentAuthenticated
      }
    }

    return undefined
  }, [attackDetails])

  const dialogActions = useMemo(
    () => ({
      open: isDialogOpened,
      onOpen: (attack: AttackType) => {
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.OPEN_ATTACK_DETAIL, {
          accessTokenId,
          attackId: attack.threatId,
          url: window.location.href
        })

        toggleDialog()
        dispatch(getSpAttack({ attackId: attack.threatId }))
        setSelectedAttack(attack)
      },
      onClose: () => {
        if (onClose) {
          onClose()
        }

        dispatch(resetAttack())
        toggleDialog()
        setSelectedAttack(undefined)
      }
    }),
    [dispatch, onClose, accessTokenId, isDialogOpened, toggleDialog]
  )

  const markedAsFpDialogActions = useMemo(
    () => ({
      open: isReportFalsePositiveDialogOpened,
      onOpen: (attackId: AttackType['id'], confidenceLevel: string) => {
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.OPEN_REPORT_FALSE_POSITIVE, {
          accessTokenId,
          attackId,
          confidenceLevel
        })

        toggleReportFalsePositiveDialog()
        dispatch(getSpAttack({ attackId }))
      },
      onClose: (isMarked = false) => {
        if (onCloseReportFpDialog) {
          onCloseReportFpDialog(isMarked)
        }
        dispatch(resetFeedbackPublicKey())
        dispatch(resetAttackId())
        toggleReportFalsePositiveDialog()
      }
    }),
    [dispatch, onCloseReportFpDialog, accessTokenId, isReportFalsePositiveDialogOpened, toggleReportFalsePositiveDialog]
  )

  return useMemo(() => {
    return [
      {
        emailDetails: {
          subject: attackDetails.subject,
          from: attackDetails.sender,
          to: attackDetails.identity,
          replyTo: attackDetails.replyTo,
          date: attackDetails.date,
          headers: attackDetails.headers,
          bodyMimeType: attackDetails.bodyMimeType,
          body: attackDetails.body,
          attachments: attackDetails.attachments,
          statistics
        },
        emailDataStatuses: {
          inProgress: isAttackDetailsLoading,
          isLoaded: isAttackDetailsLoaded,
          isFailed: isAttackDetailsFailed
        },
        eventHandlers: {
          ...(withMarkAsFp && { onMarkAsFp: markedAsFpDialogActions.onOpen }),
          ...(withFindMessagesInForensics && { onFindMessagesInForensics: true }),
          onClose: dialogActions.onClose
        },
        analysis: analyzedAttack
      },
      dialogActions,
      markedAsFpDialogActions
    ]
  }, [
    withFindMessagesInForensics,
    withMarkAsFp,
    dialogActions,
    markedAsFpDialogActions,
    isAttackDetailsLoading,
    isAttackDetailsLoaded,
    isAttackDetailsFailed,
    attackDetails,
    analyzedAttack,
    statistics
  ])
}
