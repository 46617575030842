import { useEffect, useMemo, useCallback, useRef } from 'react'

import { isSuccess, getErrorMessage } from 'global/redux/toolkit/api'
import { CompanyDomain } from 'global/types/api/dmarc'
import * as analyticsLib from 'global/lib/analytics/analyticsService'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import { getCompanyDomains, resetCompanyDomains, resetDomainsStats } from 'sen/redux/features/dmarc/dmarcSlice'
import routesConfig from 'sen/lib/routes/routesConfig'
import { reset as resetDomainsTable } from 'sen/redux/features/dataTables/domains/domainsSlice'

export interface LogicConfig {
  domain: CompanyDomain | undefined
  error: string | undefined
}

export interface EventHandlers {
  onBackToDomains: () => void
}

export default function useDomainFraudDmarcReviewContentLogic(): [LogicConfig, EventHandlers] {
  const {
    activePath,
    accessTokenId,
    companyDomains,
    isCompanyDomainsLoaded,
    companyDomainsError,
    domainTotalsError
  } = useAppSelector(_stores => ({
    activePath: _stores.app.activePath,
    accessTokenId: _stores.accessToken?.accessToken?.id || '',
    companyDomains: _stores.dmarc.companyDomains,
    isCompanyDomainsLoaded: isSuccess(_stores.dmarc.getCompanyDomainsApiStatus),
    companyDomainsError: getErrorMessage(_stores.dmarc.getCompanyDomainsApiStatus),
    domainTotalsError: getErrorMessage(_stores.dmarc.getDomainTotalsApiStatus)
  }))

  const dispatch = useAppDispatch()
  const isGotoDomainsPage = useRef(false)

  // init
  useEffect(() => {
    if (!isCompanyDomainsLoaded) {
      dispatch(getCompanyDomains())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // unmount
  useEffect(() => {
    return () => {
      if (!isGotoDomainsPage.current) {
        dispatch(resetCompanyDomains())
        dispatch(resetDomainsStats())
        dispatch(resetDomainsTable())
      }
    }
  }, [dispatch])

  const domain: CompanyDomain | undefined = useMemo(() => {
    return companyDomains.find((companyDomain: CompanyDomain) => companyDomain.name === activePath?.params?.domain)
  }, [companyDomains, activePath])

  // If companyDomains is loaded and activePath.params.domain has already been updated, but the activePath domain doesn't match
  //  the company domain name,we should redirect the user back to the DOMAIN_FRAUD page.
  useEffect(() => {
    if (isCompanyDomainsLoaded && !!activePath?.params?.domain && !domain) {
      routesConfig.DOMAIN_FRAUD.goto({
        reportId: accessTokenId
      })
    }
  }, [accessTokenId, activePath, domain, isCompanyDomainsLoaded])

  const onBackToDomains = useCallback(() => {
    isGotoDomainsPage.current = true
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.DF_DOMAINS_DMARC_REVIEW_BACK_TO_DOMAINS, { domain })
    routesConfig.DOMAIN_FRAUD.goto({
      reportId: accessTokenId
    })
  }, [accessTokenId, domain])

  return useMemo(() => {
    return [
      {
        domain,
        error: companyDomainsError || domainTotalsError
      },
      {
        onBackToDomains
      }
    ]
  }, [domain, onBackToDomains, companyDomainsError, domainTotalsError])
}
