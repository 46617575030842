import React from 'react'

import { Card, Typography, CardContent, CardHeader } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'global/lib/localization'

import Layout from 'sen/components/lib/layout/Layout'

import styles from './missingRequirementStyles'

const BASE_I18N_KEY = 'sen.app.missing_requirements'
type MissingRequirementProps = { product: string }

const MissingRequirement: React.FC<MissingRequirementProps> = ({ product = 'sen' }) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return (
    <Layout pageTitle={formatMessage('page_title')}>
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardHeader classes={{ title: classes.header }} title={formatMessage('card_title')} />
          <CardContent>
            <Typography variant="subtitle1" color="inherit">
              {formatMessage(`entitlements.${product}_text`)}
            </Typography>
          </CardContent>
        </Card>
      </div>
    </Layout>
  )
}

export default MissingRequirement
