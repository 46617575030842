import { useEffect, useMemo, useCallback } from 'react'

import { useCookies } from 'react-cookie'

import { process, SortDescriptor } from '@progress/kendo-data-query'

import { GeoChartProps } from 'global/components/lib/charts/GeoChart'
import { getChartCountryEmailHeader } from 'global/components/lib/charts/geoChartHeader'
import { CUSTOM_RANGES } from 'global/components/lib/dateRangePicker/CustomRanges'
import { UseDateRangePickerLogicParams } from 'global/components/lib/dateRangePicker/useDateRangePickerLogic'
import { BASE_IMG_PATH } from 'global/configs/theme/images.config'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { getHours, luxonDate } from 'global/lib/datetime'
import * as isoCountries from 'global/lib/isoCountries'
import { useFormatMessage } from 'global/lib/localization'
import { isFailed, isPending, isSuccess } from 'global/redux/toolkit/api'

import { EMAILS_BY_REGION_CTA_COOKIE } from 'fir/components/lib/ctaBanner/useCTABannerLogic'
import routesConfig from 'fir/lib/routes/routesConfig'
import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'
import { getGeoipAggregate } from 'fir/redux/features/search/searchApiThunks'
import { resetGeoip, resetGeoipAggregateFailures } from 'fir/redux/features/search/searchSlice'
import * as emailsByRegionTableActions from 'fir/redux/features/dataTables/emailsByRegion/emailsByRegionSlice'

export interface EmailsByRegionProps {
  dateRangePickerLogicConfig: UseDateRangePickerLogicParams
  flagSrc: (countryCode2: string) => string
  geoChartConfig: GeoChartProps
  gotoGeoipCountry: (countryCode2: string) => void
  GRID_COLUMNS: any
  gridData: {
    data: Country[]
    total: number
  }
  hideCTA: boolean
  isPageInProgress: boolean
  onClosePageAlert: () => void
  showGeoipAggregatePageError?: boolean
  sortConfig: {
    sort: SortDescriptor[]
    onSortChange: any
  }
}

interface Country {
  country: string
  code2: string
  count: number
}

const BASE_I18N_KEY = 'fir.app.insights.emails_by_region'
const BASE_APP_RANGES_I18N_KEY = 'app.custom_ranges'

const MAX_DATE_RANGE_IN_DAYS = 30

export default function useEmailsByRegionLogic(): [EmailsByRegionProps] {
  const [cookies] = useCookies([EMAILS_BY_REGION_CTA_COOKIE])
  const dispatch = useAppDispatch()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatMessageAppRanges = useFormatMessage(BASE_APP_RANGES_I18N_KEY)

  // Redux Toolkit stores
  const { accessTokenId, dataTables, geoip, isGeoipFailed, isGeoipLoading, isGeoipSuccess } = useAppSelector(
    _stores => ({
      accessTokenId: _stores.accessToken.accessToken?.id || '',
      dataTables: _stores.dataTables,
      geoip: _stores.search.geoip,
      isGeoipFailed: isFailed(_stores.search.getGeoipApiStatus),
      isGeoipLoading: isPending(_stores.search.getGeoipApiStatus),
      isGeoipSuccess: isSuccess(_stores.search.getGeoipApiStatus)
    })
  )

  // Init
  useEffect(() => {
    if (!isGeoipSuccess) {
      loadGeoipData()
    }

    // Reset Geoip data when component unmounts
    return () => {
      dispatch(resetGeoip())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // make sure date range has loaded and then track pageview
  useEffect(() => {
    if (dataTables.emailsByRegion.dateRange) {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.INSIGHTS_VIEW_GEOIP_PAGE, {
        accessTokenId,
        page: 'insights',
        timePeriod: dataTables.emailsByRegion.timeframe
      })
    }
  }, [accessTokenId, dataTables.emailsByRegion.timeframe, dataTables.emailsByRegion.dateRange])

  // Geochart
  const chartData: [string, number][] = useMemo(() => {
    return (geoip as any[]).reduce((all: [string, number][], { code2, count }: { code2: string; count: number }) => {
      const allowedEmails = formatMessage('allowed_emails')

      const tooltip = `
          <div style="min-width: 150px">
            <img src="${BASE_IMG_PATH}flags/${code2.toLowerCase()}.svg" width="10" height="10" alt="" />
            <strong>${isoCountries.getCountryNameFromCode2(code2)}</strong>
            <br />
            ${allowedEmails}: <strong>${count}</strong>
          </div>
        `

      return [...all, [code2, count, tooltip] as [string, number, any]]
    }, [])
  }, [geoip, formatMessage])

  const loadGeoipData = useCallback(
    (newDateRange = dataTables.emailsByRegion.dateRange) => {
      dispatch(getGeoipAggregate({ timeframe: getHours(newDateRange) }))
    },
    [dispatch, dataTables.emailsByRegion.dateRange]
  )

  // DateRangePicker
  const dateRangePickerLogicConfig: UseDateRangePickerLogicParams = useMemo(() => {
    const customRanges = [
      CUSTOM_RANGES.LAST_HOUR,
      CUSTOM_RANGES.LAST_12_HOURS,
      CUSTOM_RANGES.LAST_24_HOURS,
      CUSTOM_RANGES.LAST_2_DAYS,
      CUSTOM_RANGES.LAST_7_DAYS,
      CUSTOM_RANGES.LAST_30_DAYS
    ].map((range: any) => ({
      ...range,
      text: formatMessageAppRanges(`${range.id}`)
    }))

    return {
      isEndDateFixed: true,
      dateRange: dataTables.emailsByRegion.dateRange,
      min: luxonDate()
        .minus({ days: MAX_DATE_RANGE_IN_DAYS })
        .startOf('day')
        .toJSDate(),
      max: luxonDate()
        .endOf('day')
        .toJSDate(),
      steps: 'hour',
      setDateRange: (dateRange: any, isCustomRange?: boolean) => {
        dispatch(emailsByRegionTableActions.update({ config: { dateRange }, isCustomRange }))
      },
      onDateRangesChanged: loadGeoipData,
      customRanges,
      selectedRange: customRanges.find((range: any) => range.timeframe === dataTables.emailsByRegion.timeframe)
    }
  }, [
    dispatch,
    loadGeoipData,
    dataTables.emailsByRegion.dateRange,
    dataTables.emailsByRegion.timeframe,
    formatMessageAppRanges
  ])

  const gotoGeoipCountry = useCallback(
    (countryCode2: string) => {
      const selectedCountry = geoip.find(({ code2 }: { code2: string }) => code2 === countryCode2)

      if (selectedCountry) {
        routesConfig.INSIGHTS_GEOIP_COUNTRY.goto({ country: selectedCountry.countryCode })
      }
    },
    [geoip]
  )

  const geoChartConfig = useMemo(() => {
    return {
      data: [getChartCountryEmailHeader(), ...chartData],
      onChartSelect: gotoGeoipCountry
    }
  }, [chartData, gotoGeoipCountry])

  // Grid
  const gridData = useMemo(() => {
    return process(
      (geoip as any[]).reduce((all: [string, number][], { code2, count }: { code2: string; count: number }) => {
        return [...all, { country: isoCountries.getCountryNameFromCode2(code2), count, code2 }]
      }, []),
      { sort: dataTables.emailsByRegion.sort }
    )
  }, [geoip, dataTables.emailsByRegion.sort])

  const flagSrc = useCallback((countryCode2: string) => {
    return `${BASE_IMG_PATH}flags/${countryCode2.toLowerCase()}.svg`
  }, [])

  const onClosePageAlert = useCallback(() => {
    dispatch(resetGeoipAggregateFailures())
  }, [dispatch])

  const onSortChange = useCallback(
    (sortConfig: SortDescriptor[]) => {
      dispatch(emailsByRegionTableActions.update({ config: { sort: sortConfig } }))
    },
    [dispatch]
  )

  return useMemo(
    () => [
      {
        dateRangePickerLogicConfig,
        flagSrc,
        geoChartConfig,
        gotoGeoipCountry,
        gridData,
        hideCTA: cookies[EMAILS_BY_REGION_CTA_COOKIE] === 'true',
        isPageInProgress: isGeoipLoading,
        loadGeoipData,
        onClosePageAlert,
        onSortChange,
        showGeoipAggregatePageError: isGeoipFailed,
        sortConfig: {
          sort: dataTables.emailsByRegion.sort as SortDescriptor[],
          onSortChange: (e: any) => {
            onSortChange(e.sort as SortDescriptor[])
          }
        },
        GRID_COLUMNS: dataTables.emailsByRegion.GRID_COLUMNS
      }
    ],
    [
      cookies,
      dateRangePickerLogicConfig,
      dataTables.emailsByRegion,
      flagSrc,
      geoChartConfig,
      gotoGeoipCountry,
      gridData,
      isGeoipFailed,
      isGeoipLoading,
      loadGeoipData,
      onClosePageAlert,
      onSortChange
    ]
  )
}
