import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Alert, Button, DialogContent, Grid, Typography } from '@barracuda-internal/bds-core'

import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'

import { useFormatMessage, useErrorFormatMessage } from 'global/lib/localization'
import ExportToCsvButton from 'global/components/lib/exportToCsvButton/ExportToCsvButton'

import BaseDialogActions from 'sen/components/lib/dialogs/newIncidentDialog/lib/BaseDialogActions'
import RecipientsTable from 'sen/components/lib/dialogs/newIncidentDialog/lib/RecipientsTable'
import IdentityAutocomplete from 'sen/components/lib/autocompletes/identity/IdentityAutocomplete'
import NotificationEmailDialog from 'sen/components/lib/dialogs/newIncidentDialog/lib/notificationEmailDialog/NotificationEmailDialog'

import styles from 'sen/components/lib/dialogs/newIncidentDialog/newIncidentDialogStyles'
import useNotifyLogic from 'sen/components/lib/dialogs/newIncidentDialog/steps/useNotifyLogic'

const BASE_I18N_KEY = 'sen.app.new_incident_dialog.step_notify'
const BASE_SENDERS_AUTOCOMPLETE_I18N_KEY = 'sen.app.autocompletes.notify_external'

export interface NotifyProps {
  onClose: () => void
  activeStep: number
}
const Notify: React.FC<NotifyProps> = ({ activeStep, onClose }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatMessageError = useErrorFormatMessage()
  const classes = styles()

  const [
    buttonStatus,
    recipientsConfig,
    tableConfig,
    sendersSelector,
    error,
    exportToCsvConfig,
    notificationEmailDialogConfig,
    editNotificationEmail
  ] = useNotifyLogic()

  return useMemo(
    () => (
      <Grid container direction="column">
        <DialogContent className={classes.dialogContent}>
          {error && (
            <Alert className={classes.error} variant="filled" severity="error">
              {formatMessageError(error)}
            </Alert>
          )}

          <Grid container item>
            {tableConfig.isLoaded && recipientsConfig.hasRecipients && (
              <Grid container item xs={12} justifyContent="flex-end">
                <ExportToCsvButton {...exportToCsvConfig} />
              </Grid>
            )}

            <RecipientsTable
              tableConfig={tableConfig}
              recipientsConfig={recipientsConfig}
              BASE_I18N_KEY={BASE_I18N_KEY}
            />
          </Grid>
          {tableConfig.isLoaded && recipientsConfig.hasRecipients && (
            <>
              <Grid container item direction="row">
                <Grid item xs={9}>
                  <FormControl className={classes.formControl}>
                    <FormLabel>
                      <Typography>{formatMessage('select_account')}</Typography>
                    </FormLabel>
                    <IdentityAutocomplete
                      {...sendersSelector.config}
                      BASE_I18N_KEY={BASE_SENDERS_AUTOCOMPLETE_I18N_KEY}
                      inProgress={false}
                    />
                  </FormControl>
                </Grid>
                <Grid item className={classes.editEmailButton}>
                  <Button
                    color="primary"
                    variant="text"
                    onClick={editNotificationEmail}
                    disabled={!notificationEmailDialogConfig.sender}
                  >
                    {formatMessage('edit_email')}
                  </Button>
                </Grid>
              </Grid>

              <Typography className={classes.text}>{formatMessage('footer')}</Typography>
            </>
          )}
        </DialogContent>
        <BaseDialogActions activeStep={activeStep} buttonStatus={buttonStatus} onClose={onClose} />
        {notificationEmailDialogConfig.open && notificationEmailDialogConfig.sender && (
          <NotificationEmailDialog
            onClose={notificationEmailDialogConfig.onClose}
            selectedSender={notificationEmailDialogConfig.sender}
          />
        )}
      </Grid>
    ),
    [
      classes,
      formatMessage,
      formatMessageError,
      activeStep,
      buttonStatus,
      error,
      onClose,
      tableConfig,
      recipientsConfig,
      sendersSelector.config,
      exportToCsvConfig,
      editNotificationEmail,
      notificationEmailDialogConfig
    ]
  )
}

Notify.propTypes = {
  onClose: PropTypes.func.isRequired,
  activeStep: PropTypes.number.isRequired
}

export default Notify
