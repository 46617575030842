import { useEffect, useMemo } from 'react'

import {
  User as UserIcon,
  SupervisedUserCircle as SupervisorAccount
} from '@barracuda-internal/bds-core/dist/Icons/Core'
import { MarkunreadMailbox } from '@barracuda-internal/bds-core/dist/Icons/Email'

import { isPending } from 'global/redux/toolkit/api'

import { useAppDispatch, useAppSelector } from 'admin/redux/toolkit/hooks'
import { scanTotals } from 'admin/redux/features/admin/adminSlice'
import { CardData } from 'admin/components/pages/dashboard/metrics/Metrics'

export type InProgress = boolean

const scanType = 'sentinel'

export default function useImpersonationProtectionLogic(): [InProgress, CardData[]] {
  const dispatch = useAppDispatch()
  const { inProgress, totals } = useAppSelector(_stores => ({
    inProgress: isPending(_stores.admin.scanTotalsApiStatus),
    totals: _stores.admin.totals
  }))

  // init
  useEffect(() => {
    dispatch(scanTotals({ scanType }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return useMemo(() => {
    const cards = [
      {
        icon: UserIcon,
        value: totals.accountsCount,
        messageId: 'accounts'
      },
      {
        icon: MarkunreadMailbox,
        value: totals.mailboxCount,
        messageId: 'mailboxes'
      },
      {
        icon: SupervisorAccount,
        value: totals.spAttacksCount,
        messageId: 'attacks'
      }
    ]
    return [inProgress, cards]
  }, [inProgress, totals])
}
