import React, { useMemo } from 'react'

import { Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer'

import baseStyles from 'ets/components/lib/PDFReport/pdfReportStyles'

import IMAGES from 'ets/configs/theme/images.config'

export interface Page1CoverConfig {
  reportName: string
  reportDate: string
}

export interface Page1CoverProps {
  pdfConfig: Page1CoverConfig
  intl: any
}

const TABLE_OF_CONTENTS = {
  summary: {
    dots: 60,
    page: 1
  },
  employees: {
    dots: 52,
    page: 2
  },
  threats: {
    dots: 54,
    page: 3
  },
  domains: {
    dots: 23,
    page: 4
  },
  attacks: {
    dots: 63,
    page: 5
  }
}

const styles = StyleSheet.create({
  ...baseStyles,
  page: {
    backgroundColor: '#3C4654',
    padding: '60px 80px'
  },
  background: { position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 },
  logo: { width: 200 },
  reportName: { ...baseStyles.boldText, fontSize: 36, marginTop: 50, marginBottom: 5 },
  reportDate: { ...baseStyles.text, fontSize: 9, marginBottom: 50 },
  title: { ...baseStyles.text, fontSize: 16, marginBottom: 15 },
  contentWrapper: {
    marginBottom: 15,
    display: 'flex',
    flexDirection: 'row'
  },
  pageContent: {
    ...baseStyles.text,
    fontSize: 12,
    maxLines: 1,
    order: 1,
    width: 300
  },
  pageNumber: { ...baseStyles.text, fontSize: 12, order: 2, marginLeft: 3 }
})

const BASE_I18N_KEY = 'ets.app.pdf.cover'

const Page1Cover: React.FC<Page1CoverProps> = ({ pdfConfig, intl }) => {
  return useMemo(() => {
    return (
      <Page size="A4" style={styles.page}>
        <Image style={styles.background} src={IMAGES.pdfCover} />
        <Image style={styles.logo} src={IMAGES.barracudaETSForLightPng} />
        <Text style={styles.reportName}>{pdfConfig.reportName}</Text>
        <Text style={styles.reportDate}>{pdfConfig.reportDate}</Text>
        <Text style={styles.title}>Table of Contents</Text>
        {Object.values(TABLE_OF_CONTENTS).map((contentConfig: { dots: number; page: number }) => (
          <View key={contentConfig.page} style={styles.contentWrapper}>
            <Text style={styles.pageContent}>
              {`${intl.formatMessage({ id: `${BASE_I18N_KEY}.page_${contentConfig.page}_title` })} ${'.'.repeat(
                contentConfig.dots
              )}`}
            </Text>
            <Text style={styles.pageNumber}>
              {`${intl.formatMessage({ id: `${BASE_I18N_KEY}.page` })} ${contentConfig.page}`}
            </Text>
          </View>
        ))}
      </Page>
    )
  }, [pdfConfig, intl])
}

export default Page1Cover
