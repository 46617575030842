/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react'

import { Grid, Typography, Alert, DataTable, DataTableColumn, Button, Tooltip } from '@barracuda-internal/bds-core'
import { Checkmark as CheckmarkIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'
import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'

import { useFormatMessage, useErrorFormatMessage } from 'global/lib/localization'
import SearchField from 'global/components/lib/searchField/SearchField'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import Pager from 'global/components/lib/grid/pager/Pager'
import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'

import { SectionsProps } from 'sen/components/pages/domainFraudDmarcReview/domainFraudDmarcReviewTypes'
import styles from 'sen/components/pages/domainFraudDmarcReview/content/domainFraudDmarcReviewContentStyles'
import useRejectedTabLogic, {
  ModifiedDmarcSource
} from 'sen/components/pages/domainFraudDmarcReview/content/sections/tabs/useRejectedTabLogic'

const BASE_I18N_KEY = 'sen.app.domain_fraud_dmarc_review.sources.tab_contents.rejected'
const BASE_I18N_TABLE_KEY = 'sen.app.data_tables.dmarc_sources'

const RejectedTab: React.FC<SectionsProps> = ({ domain }) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const tableFormatMessage = useFormatMessage(BASE_I18N_TABLE_KEY)
  const errorFormatMessage = useErrorFormatMessage()
  const [logicConfig, eventHandlers] = useRejectedTabLogic({
    domain
  })

  return useMemo(() => {
    return (
      <Grid container direction="column">
        {logicConfig.error && (
          <Alert className={classes.errorWithTabMargin} severity="error" data-field="dmarc-review-rejected-alert">
            {errorFormatMessage(logicConfig.error)}
          </Alert>
        )}

        <Grid container direction="column">
          <Grid item className={classes.searchfieldContainer}>
            <SearchField
              {...logicConfig.searchFieldConfig}
              placeholder={formatMessage('search_placeholder')}
              data-field="search-field"
            />
          </Grid>

          <Grid item className={classes.tabMargin}>
            <Typography data-first-custom-header="true" className={classes.customHeaderTitle}>
              {tableFormatMessage('source')}
            </Typography>
          </Grid>

          <Grid className={classes.progressContainer} container direction="column">
            {logicConfig.tableConfig.inProgress && (
              <LinearProgress className={classes.tableTitleProgress} data-field="linear-progress" />
            )}
          </Grid>

          {!logicConfig.tableConfig.isLoaded && (
            <Grid container className={classes.reservedTableArea} data-field="reserved-table-area" />
          )}
          {logicConfig.tableConfig.isLoaded && (
            <Grid container direction="column" className={classes.tableWrapper} data-field="domains-table-wrapper">
              <Grid
                ref={logicConfig.dmarcSourcesTableRef}
                className={`${
                  logicConfig.tableConfig.isFlexibleTable ? classes.flexibleTableArea : classes.tableArea
                } ${!logicConfig.tableConfig.tableData.total ? 'empty-table' : ''}`}
                data-table="all-volume-sources"
                item
                xs={12}
                data-field="grid-table"
              >
                <DataTable
                  data-testid="all-volume-sources-table"
                  data-field="data-table"
                  className={`${
                    logicConfig.tableConfig.tableData.total
                      ? logicConfig.tableConfig.isFlexibleTable
                        ? classes.flexibleDataTable
                        : classes.dataTable
                      : classes.emptyDataTable
                  } alerts-table`}
                  data={logicConfig.tableConfig.tableData}
                  {...logicConfig.tableConfig.pageConfig}
                  pager={Pager}
                >
                  <DataTableNoRecords data-field="empty-table">
                    <Typography>
                      {!logicConfig.tableConfig.tableData.total && tableFormatMessage('empty_table')}
                    </Typography>
                  </DataTableNoRecords>

                  <DataTableColumn
                    field={logicConfig.tableConfig.columns.SOURCE}
                    {...logicConfig.tableConfig.columnsConfig[logicConfig.tableConfig.columns.SOURCE]}
                    title={' '}
                    cell={({ dataItem }: { dataItem: ModifiedDmarcSource }) => (
                      <Cell className={classes.cell} data-field="table-column-host">
                        <CellText ellipsis className={classes.normalCell} data-field="table-column-host-text">
                          <Tooltip title={dataItem.host} placement="top-start">
                            <data>{dataItem.host}</data>
                          </Tooltip>
                        </CellText>
                      </Cell>
                    )}
                  />

                  <DataTableColumn
                    width={200}
                    field={logicConfig.tableConfig.columns.ACTIONS}
                    {...logicConfig.tableConfig.columnsConfig[logicConfig.tableConfig.columns.ACTIONS]}
                    title={' '}
                    cell={({ dataItem }: { dataItem: ModifiedDmarcSource }) => (
                      <Cell className={classes.cell} data-field="table-column-actions">
                        <CellText className={classes.actionCell} data-field="table-column-email-actions">
                          <Button
                            disabled={logicConfig.isMarkInProgress}
                            size="small"
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                              eventHandlers.onMarkAsApproved(dataItem)
                            }}
                            data-action="mark-as-approved"
                          >
                            <CheckmarkIcon className={classes.checkmarIcon} />
                            {formatMessage('mark_as_approved')}
                          </Button>
                        </CellText>
                      </Cell>
                    )}
                  />
                </DataTable>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    )
  }, [classes, formatMessage, logicConfig, errorFormatMessage, tableFormatMessage, eventHandlers])
}

export default RejectedTab
