import { FILTER_CONFIG } from 'ets/redux/features/dataTables/employees/employeesSlice'

export { FILTER_CONFIG }

export default (score: number) => {
  if (score >= FILTER_CONFIG.HIGH_RISK.level) {
    return FILTER_CONFIG.HIGH_RISK.id
  }
  if (score >= FILTER_CONFIG.MEDIUM_RISK.level) {
    return FILTER_CONFIG.MEDIUM_RISK.id
  }
  return FILTER_CONFIG.LOW_RISK.id
}
