import restClient, { validateApiError } from 'global/lib/api/restClient'
import { EmailsApiConfig } from 'global/types/api/search'

import apiRoutes from 'fir/lib/api/apiRoutes'

export interface GetEmails {
  resetResult?: boolean
  config: EmailsApiConfig
}

export async function doGetEmails(payload: GetEmails, { rejectWithValue }: any) {
  const { config } = payload

  try {
    const resp = await restClient(apiRoutes.EMAILS_QUERY, {
      data: { ...config }
    })

    return { emails: resp.data.emails, skip: config.page.offset || 0 }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
}
