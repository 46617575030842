import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  Typography,
  Alert as AlertComponent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Zoom
} from '@barracuda-internal/bds-core'
import { Launch as LaunchIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'

import { useFormatMessage, useErrorFormatMessage } from 'global/lib/localization'
import DEFAULTS from 'global/configs/theme/defaults'

import useResetPasswordDialogLogic from 'sen/components/lib/dialogs/newIncidentDialog/resetPasswordDialog/useResetPasswordDialogLogic'
import styles from 'sen/components/lib/dialogs/newIncidentDialog/newIncidentDialogStyles'

const BASE_I18N_KEY = 'sen.app.new_incident_dialog.step_reset_password'
const BASE_I18N_KEY_BUTTONS = 'sen.app.new_incident_dialog.buttons'

export interface ResetPasswordDialogProps {
  onClose: () => void
}

export interface Props {
  onOpenNewIncidentDialog: () => void
}

const ResetPasswordDialog: React.FC<ResetPasswordDialogProps & Props> = ({ onClose, onOpenNewIncidentDialog }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatErrorMessage = useErrorFormatMessage()
  const formatMessageButtons = useFormatMessage(BASE_I18N_KEY_BUTTONS)

  const classes = styles()

  const [resetPasswordDialogConfig] = useResetPasswordDialogLogic()

  return useMemo(
    () => (
      <Dialog
        style={{ zIndex: DEFAULTS.DIALOGS_ZINDEX }}
        maxWidth="md"
        fullWidth
        open
        TransitionComponent={Zoom}
        data-testid="reset-password-dialog"
      >
        <DialogTitle className={classes.dialogWrapper}>
          {resetPasswordDialogConfig.showConnectError && (
            <AlertComponent className={classes.error} variant="filled" severity="error">
              {formatErrorMessage(resetPasswordDialogConfig.connectError)}
            </AlertComponent>
          )}
          <Typography className={classes.dialogTitle}>{formatMessage('title')}</Typography>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <Typography className={classes.text}>
            {formatMessage('description', {
              displayName: resetPasswordDialogConfig.displayName,
              b: (txt: string) => <b key={txt}>{txt}</b>
            })}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button
            size="large"
            color="primary"
            variant="text"
            onClick={() => {
              resetPasswordDialogConfig.onResetCompromisedAccount()
              onClose()
            }}
          >
            &nbsp;&nbsp;{formatMessageButtons('close')}&nbsp;&nbsp;
          </Button>
          <Button
            size="large"
            color="primary"
            variant="text"
            onClick={() => {
              onOpenNewIncidentDialog()
            }}
          >
            &nbsp;&nbsp;{formatMessageButtons('skip_step')}&nbsp;&nbsp;
          </Button>
          <Button
            size="large"
            color="primary"
            variant="contained"
            disabled={resetPasswordDialogConfig.buttonDisabled}
            onClick={() => {
              resetPasswordDialogConfig.onResetPassword()
            }}
          >
            &nbsp;&nbsp;{formatMessageButtons('reset_password')}&nbsp;&nbsp; <LaunchIcon />
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [
      classes,
      formatMessage,
      formatErrorMessage,
      formatMessageButtons,
      onClose,
      resetPasswordDialogConfig,
      onOpenNewIncidentDialog
    ]
  )
}

ResetPasswordDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onOpenNewIncidentDialog: PropTypes.func.isRequired
}

export default ResetPasswordDialog
