import { createSlice } from '@reduxjs/toolkit'

import { CmStats } from 'global/types/api/cmStatsType'
import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'global/redux/toolkit/api'
import { getCmStats } from 'sen/redux/features/cm/cmApiThunks'

export interface CmState {
  stats: CmStats | undefined
  getCmStatsApiStatus: ApiStatus
}

// initialState
export const INITIAL_STATE: CmState = {
  stats: undefined,
  getCmStatsApiStatus: inIdle
}

/* eslint-disable no-param-reassign */
export const cmSlice = createSlice({
  name: 'CM',
  initialState: INITIAL_STATE,
  reducers: {
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getCmStats.pending, state => {
        state.getCmStatsApiStatus = inProgress
      })
      .addCase(getCmStats.fulfilled, (state, action) => {
        state.getCmStatsApiStatus = successResponse
        state.stats = action.payload
      })
      .addCase(getCmStats.rejected, (state, action) => {
        state.getCmStatsApiStatus = failedResponse(action.payload as string)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { reset } = cmSlice.actions

export { getCmStats }

export default cmSlice.reducer
