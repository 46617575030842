import { makeStyles } from '@material-ui/core/styles'
import { navbarHeight } from 'global/components/lib/layout/navbar/navbarStyles'
import DEFAULTS from 'ets/configs/styles/defaults'

export default makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1.5) + 4,
    zIndex: 1399,
    backgroundColor: '#F7F7F7',
    height: navbarHeight + 2,
    top: 0,
    minWidth: DEFAULTS.DESKTOP_WIDTH,
    position: 'sticky'
  },
  tabs: {
    marginTop: 8
  },
  indicator: {
    backgroundColor: 'transparent'
  },
  tab: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    minWidth: 'auto',

    '&.Mui-selected': {
      color: theme.palette.text.primary
    }
  }
}))
