import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { validateApiError } from 'global/lib/api/restClient'
import { globalApiRoutes } from 'global/lib/api/apiRoutes'
import { authenticate } from 'global/lib/auth/auth'
import { config } from 'global/lib/config'

export interface RevertImpersonationPayload {
  v2Impersonate?: boolean
}

export const revertImpersonation = createAsyncThunk('ADMIN/revertImpersonation', async function doRevertImpersionation(
  payload: RevertImpersonationPayload,
  { rejectWithValue }
) {
  try {
    const isV2Admin = payload.v2Impersonate
    const resp = await restClient(globalApiRoutes.REVERT_IMPERSONATION)

    authenticate(resp.data.user)

    let urlConfig = []
    switch (true) {
      case config.domainConfig.isForensics:
        urlConfig = [config.domains.forensics, isV2Admin ? 'incident-response/accounts' : 'forensics/accounts']
        break
      case config.domainConfig.isEts:
        urlConfig = [config.domains.ets, isV2Admin ? 'email-threat-scanner/scans' : 'ets/accounts']
        break
      default:
        urlConfig = [config.domains.sentinel, isV2Admin ? 'impersonation-protection/accounts' : 'sentinel/accounts']
    }

    const [appUrl, appUri] = urlConfig
    window.location.href = `${appUrl}/${isV2Admin ? 'v2/' : ''}admin/dashboard/${appUri}`

    return resp
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})
