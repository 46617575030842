import { createSlice } from '@reduxjs/toolkit'

import { revertImpersonation } from 'global/redux/features/admin/adminApiThunks'
import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'global/redux/toolkit/api'

export interface AdminState {
  revertImpersonationApiStatus: ApiStatus
}

// initialState
export const INITIAL_STATE: AdminState = {
  revertImpersonationApiStatus: inIdle
}

/* eslint-disable no-param-reassign */
export const adminSlice = createSlice({
  name: 'ADMIN',
  initialState: INITIAL_STATE,
  reducers: {
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(revertImpersonation.pending, state => {
        state.revertImpersonationApiStatus = inProgress
      })
      .addCase(revertImpersonation.fulfilled, state => {
        state.revertImpersonationApiStatus = successResponse
      })
      .addCase(revertImpersonation.rejected, (state, action) => {
        state.revertImpersonationApiStatus = failedResponse(action.payload as string)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { reset } = adminSlice.actions

export { revertImpersonation }

export default adminSlice.reducer
