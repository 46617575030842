import { useMemo, useCallback } from 'react'

import { useCookies } from 'react-cookie'

import { config } from 'global/lib/config'
import useUserDataLib from 'global/lib/userData/useUserData'

export const EMAILS_BY_REGION_CTA_COOKIE = 'forensics-geoip-cta'
export const POTENTIAL_INCIDENTS_CTA_COOKIE = 'forensics-potential-incidents-cta'
export const REMEDIATION_CTA_COOKIE = 'forensics-incidents-cta'
export const USER_REPORTED_CTA_COOKIE = 'forensics-user-reported-cta'

export interface CtaBannerLogic {
  contentConfig: {
    userReported: {
      securityServiceLink: string
    }
  }
  onClose: (ctaCookie: string) => void
}

export default function useCtaBannerLogic(): [CtaBannerLogic] {
  const [, setCookie] = useCookies([
    EMAILS_BY_REGION_CTA_COOKIE,
    POTENTIAL_INCIDENTS_CTA_COOKIE,
    REMEDIATION_CTA_COOKIE,
    USER_REPORTED_CTA_COOKIE
  ])
  const [userDataLib] = useUserDataLib()

  const contentConfig = useMemo(() => {
    const userEssRegion = userDataLib.getUser().essRegion

    return {
      userReported: {
        securityServiceLink:
          userEssRegion === 'US'
            ? config.LINKS.BARRACUDA_ESS_LOG.US
            : config.LINKS.BARRACUDA_ESS_LOG.NON_US.replace('REGION', userEssRegion)
      }
    }
  }, [userDataLib])

  const onClose = useCallback(
    (ctaCookie: string) => {
      setCookie(ctaCookie, true, { path: '/' })
    },
    [setCookie]
  )

  return useMemo(
    () => [
      {
        contentConfig,
        onClose
      }
    ],
    [contentConfig, onClose]
  )
}
