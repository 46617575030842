// TODO update with value from config service
import { config } from 'global/lib/config'
import globalImages from 'global/configs/theme/images.config'

const BASE_IMG_PATH = `/${config.API_VERSION}/assets/img/`

function imagePath(path: string) {
  return `${BASE_IMG_PATH}${path}`
}

const images = {
  ...globalImages,
  loginBackground: imagePath('sen/Sentinel-background.jpg')
}

export type ImageKeys = keyof typeof images
export type Images = typeof images[ImageKeys]

export default images
