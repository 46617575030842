import { createSlice } from '@reduxjs/toolkit'
import { union } from 'lodash'
import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'global/redux/toolkit/api'
import {
  addWhitelistItem,
  deleteWhitelistItem,
  editWhitelistItem,
  getCustomNotificationEmailTemplate,
  getNotificationEmail,
  getWhitelistItemList,
  saveCustomNotificationTemplate,
  setNotificationEmail,
  updateAccessToken
} from 'sen/redux/features/settings/settingsApiThunk'
import { AccessTokenSettings } from 'global/types/api/accessTokenType'
import insertToArray from 'global/lib/insertToArray'
import { CustomNoficationEmail, Whitelist, WhitelistItem } from './types'

export interface SettingsState {
  accessTokenSettings: AccessTokenSettings | {}
  addWhitelistItemApiStatus: ApiStatus
  customNotificationEmail: CustomNoficationEmail
  customNotificationEmailError: undefined | string
  deleteWhitelistItemApiStatus: ApiStatus
  editWhitelistItemApiStatus: ApiStatus
  errorMsg: string | undefined
  getCustomNotificationEmailTemplateApiStatus: ApiStatus
  getNotificationEmailApiStatus: ApiStatus
  getWhitelistItemListApiStatus: ApiStatus
  notificationEmail: undefined | string
  saveCustomNotificationTemplateApiStatus: ApiStatus
  setNotificationEmailApiStatus: ApiStatus
  whitelist: Whitelist
  whitelistItem: WhitelistItem | {}
  whiteListLoadedOffsets: number[]
  updateAccessTokenApiStatus: ApiStatus
}

export const INITIAL_STATE: SettingsState = {
  accessTokenSettings: {},
  addWhitelistItemApiStatus: inIdle,
  customNotificationEmail: {} as CustomNoficationEmail,
  customNotificationEmailError: undefined,
  deleteWhitelistItemApiStatus: inIdle,
  editWhitelistItemApiStatus: inIdle,
  errorMsg: undefined,
  getCustomNotificationEmailTemplateApiStatus: inIdle,
  getNotificationEmailApiStatus: inIdle,
  getWhitelistItemListApiStatus: inIdle,
  notificationEmail: undefined,
  saveCustomNotificationTemplateApiStatus: inIdle,
  setNotificationEmailApiStatus: inIdle,
  whitelist: { report: {} } as Whitelist,
  whitelistItem: {},
  whiteListLoadedOffsets: [],
  updateAccessTokenApiStatus: inIdle
}

/* eslint-disable no-param-reassign */
export const settingsSlice = createSlice({
  name: 'SETTINGS',
  initialState: INITIAL_STATE,
  reducers: {
    reset: () => ({ ...INITIAL_STATE }),

    resetWhitelistItemList: state => {
      state.getWhitelistItemListApiStatus = INITIAL_STATE.getWhitelistItemListApiStatus
      state.whitelist = INITIAL_STATE.whitelist
      state.whiteListLoadedOffsets = INITIAL_STATE.whiteListLoadedOffsets
    },

    resetWhitelistItem: state => {
      state.addWhitelistItemApiStatus = INITIAL_STATE.addWhitelistItemApiStatus
      state.editWhitelistItemApiStatus = INITIAL_STATE.editWhitelistItemApiStatus
      state.deleteWhitelistItemApiStatus = INITIAL_STATE.deleteWhitelistItemApiStatus
      state.errorMsg = INITIAL_STATE.errorMsg
    },

    resetNotificationEmail: state => {
      state.getNotificationEmailApiStatus = INITIAL_STATE.getNotificationEmailApiStatus
      state.setNotificationEmailApiStatus = INITIAL_STATE.setNotificationEmailApiStatus
      state.notificationEmail = INITIAL_STATE.notificationEmail
      state.errorMsg = INITIAL_STATE.errorMsg
    },

    resetCustomEmailNotificationEmailTemplate: state => {
      state.getCustomNotificationEmailTemplateApiStatus = INITIAL_STATE.getCustomNotificationEmailTemplateApiStatus
      state.customNotificationEmail = INITIAL_STATE.customNotificationEmail
      state.customNotificationEmailError = INITIAL_STATE.customNotificationEmailError
    },

    resetError: state => {
      state.errorMsg = INITIAL_STATE.errorMsg
    }
  },
  extraReducers: builder => {
    builder
      // updateAccessToken
      .addCase(updateAccessToken.pending, state => {
        state.updateAccessTokenApiStatus = inProgress
        state.accessTokenSettings = INITIAL_STATE.accessTokenSettings
        state.errorMsg = undefined
      })
      .addCase(updateAccessToken.fulfilled, (state, action) => {
        state.updateAccessTokenApiStatus = successResponse
        state.accessTokenSettings = action.payload.settings || INITIAL_STATE.accessTokenSettings
      })
      .addCase(updateAccessToken.rejected, (state, action) => {
        state.updateAccessTokenApiStatus = failedResponse(action.payload as string)
        state.errorMsg = action.payload as string
      })

      // getWhitelistItemList
      .addCase(getWhitelistItemList.pending, (state, action) => {
        const currentOffset = (action.meta.arg?.query || {}).offset || 0
        state.getWhitelistItemListApiStatus = inProgress
        state.whiteListLoadedOffsets = union(state.whiteListLoadedOffsets, [currentOffset])
        state.errorMsg = INITIAL_STATE.errorMsg
      })
      .addCase(getWhitelistItemList.fulfilled, (state, action) => {
        state.getWhitelistItemListApiStatus = successResponse
        state.whitelist = {
          ...action.payload,
          report: {
            ...action.payload.report,
            data: insertToArray(
              state.whitelist.report.data || [],
              action.payload.report.data,
              (action.meta.arg?.query || {}).offset || 0
            )
          }
        }
      })
      .addCase(getWhitelistItemList.rejected, (state, action) => {
        state.getWhitelistItemListApiStatus = failedResponse(action.payload as string)
        state.errorMsg = action.payload as string
      })

      // addWhitelistItem
      .addCase(addWhitelistItem.pending, state => {
        state.addWhitelistItemApiStatus = inProgress
        state.whitelistItem = INITIAL_STATE.whitelistItem
        state.errorMsg = undefined
      })
      .addCase(addWhitelistItem.fulfilled, (state, action) => {
        state.addWhitelistItemApiStatus = successResponse
        state.whitelistItem = action.payload || INITIAL_STATE.whitelistItem
      })
      .addCase(addWhitelistItem.rejected, (state, action) => {
        state.addWhitelistItemApiStatus = failedResponse(action.payload as string)
        state.errorMsg = action.payload as string
      })

      // editWhitelistItem
      .addCase(editWhitelistItem.pending, state => {
        state.editWhitelistItemApiStatus = inProgress
        state.whitelistItem = INITIAL_STATE.whitelistItem
        state.errorMsg = undefined
      })
      .addCase(editWhitelistItem.fulfilled, (state, action) => {
        state.editWhitelistItemApiStatus = successResponse
        state.whitelistItem = action.payload || INITIAL_STATE.whitelistItem
      })
      .addCase(editWhitelistItem.rejected, (state, action) => {
        state.editWhitelistItemApiStatus = failedResponse(action.payload as string)
        state.errorMsg = action.payload as string
      })

      // deleteWhitelistItem
      .addCase(deleteWhitelistItem.pending, state => {
        state.deleteWhitelistItemApiStatus = inProgress
        state.errorMsg = undefined
      })
      .addCase(deleteWhitelistItem.fulfilled, state => {
        state.deleteWhitelistItemApiStatus = successResponse
      })
      .addCase(deleteWhitelistItem.rejected, (state, action) => {
        state.deleteWhitelistItemApiStatus = failedResponse(action.payload as string)
        state.errorMsg = action.payload as string
      })

      // getNotificationEmail
      .addCase(getNotificationEmail.pending, state => {
        state.getNotificationEmailApiStatus = inProgress
        state.notificationEmail = INITIAL_STATE.notificationEmail
        state.errorMsg = undefined
      })
      .addCase(getNotificationEmail.fulfilled, (state, action) => {
        state.getNotificationEmailApiStatus = successResponse
        state.notificationEmail = action.payload.emailAddress
      })
      .addCase(getNotificationEmail.rejected, (state, action) => {
        state.getNotificationEmailApiStatus = failedResponse(action.payload as string)
        state.errorMsg = action.payload as string
      })

      // setNotificationEmail
      .addCase(setNotificationEmail.pending, state => {
        state.setNotificationEmailApiStatus = inProgress
        state.errorMsg = undefined
      })
      .addCase(setNotificationEmail.fulfilled, (state, action) => {
        state.setNotificationEmailApiStatus = successResponse
        state.notificationEmail = action.meta.arg.email
      })
      .addCase(setNotificationEmail.rejected, (state, action) => {
        state.setNotificationEmailApiStatus = failedResponse(action.payload as string)
        state.errorMsg = action.payload as string
      })

      // getCustomNotificationEmailTemplate
      .addCase(getCustomNotificationEmailTemplate.pending, state => {
        state.getCustomNotificationEmailTemplateApiStatus = inProgress
        state.customNotificationEmail = INITIAL_STATE.customNotificationEmail
        state.customNotificationEmailError = INITIAL_STATE.customNotificationEmailError
      })
      .addCase(getCustomNotificationEmailTemplate.fulfilled, (state, action) => {
        state.getCustomNotificationEmailTemplateApiStatus = successResponse
        state.customNotificationEmail = action.payload
          ? {
              template: action.payload[0].template,
              preview: action.payload[1].preview,
              defaults: action.payload[2].template
            }
          : INITIAL_STATE.customNotificationEmail
      })
      .addCase(getCustomNotificationEmailTemplate.rejected, (state, action) => {
        state.getCustomNotificationEmailTemplateApiStatus = failedResponse(action.payload as string)
        state.customNotificationEmailError = action.payload as string
      })

      // saveCustomNotificationTemplate
      .addCase(saveCustomNotificationTemplate.pending, state => {
        state.saveCustomNotificationTemplateApiStatus = inProgress
        state.customNotificationEmailError = INITIAL_STATE.customNotificationEmailError
      })
      .addCase(saveCustomNotificationTemplate.fulfilled, state => {
        state.saveCustomNotificationTemplateApiStatus = successResponse
      })
      .addCase(saveCustomNotificationTemplate.rejected, (state, action) => {
        state.saveCustomNotificationTemplateApiStatus = failedResponse(action.payload as string)
        state.customNotificationEmailError = action.payload as string
      })
  }
})
/* eslint-enable no-param-reassign */

export const {
  reset,
  resetWhitelistItemList,
  resetWhitelistItem,
  resetNotificationEmail,
  resetCustomEmailNotificationEmailTemplate,
  resetError
} = settingsSlice.actions

export {
  updateAccessToken,
  getWhitelistItemList,
  addWhitelistItem,
  editWhitelistItem,
  deleteWhitelistItem,
  getNotificationEmail,
  setNotificationEmail,
  getCustomNotificationEmailTemplate,
  saveCustomNotificationTemplate
}

export default settingsSlice.reducer
