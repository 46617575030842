import { createSlice } from '@reduxjs/toolkit'

import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'global/redux/toolkit/api'

import { getIdentityStats } from 'admin/redux/features/insights/insightsApiThunks'
import { IdentityStats } from 'admin/redux/types/insights'

export interface InsightsState {
  getIdentityStatsApiStatus: ApiStatus
  identityStats: IdentityStats
}

// initialState
export const INITIAL_STATE: InsightsState = {
  getIdentityStatsApiStatus: inIdle,
  identityStats: {} as IdentityStats
}

/* eslint-disable no-param-reassign */
export const insightsSlice = createSlice({
  name: 'ADMIN',
  initialState: INITIAL_STATE,
  reducers: {
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getIdentityStats.pending, state => {
        state.getIdentityStatsApiStatus = inProgress
      })
      .addCase(getIdentityStats.fulfilled, (state, action) => {
        state.getIdentityStatsApiStatus = successResponse
        state.identityStats = action.payload
      })
      .addCase(getIdentityStats.rejected, (state, action) => {
        state.getIdentityStatsApiStatus = failedResponse(action.payload as string)
      })
  }
})

/* eslint-enable no-param-reassign */
export const { reset } = insightsSlice.actions

export { getIdentityStats }

export default insightsSlice.reducer
