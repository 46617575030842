import React, { useMemo } from 'react'

import { Select, MenuItem } from '@barracuda-internal/bds-core'
import { FormControl } from '@material-ui/core'

import styles from '../../../../../../global/components/lib/grid/filter/filterStyles'
import useDropdownFilterLogic from './DropdownFilterLogic'

export interface DropdownFilterProps {
  operators?: string[]
  firstFilterProps: any
  intl: any
}

const DropdownFilter: React.FC<DropdownFilterProps> = props => {
  const classes = styles()
  const [dropdownFilterLogic] = useDropdownFilterLogic(props.firstFilterProps, props.intl, props.operators)

  return useMemo(() => {
    const { dropdownValues, onDropdownChange, selectedOperator } = dropdownFilterLogic

    return (
      <FormControl component="fieldset" className={classes.formControl}>
        <Select className={classes.dropdown} value={selectedOperator} onChange={onDropdownChange}>
          {dropdownValues.map((item: any) => (
            <MenuItem key={item.content} value={item.content}>
              {item.content}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }, [dropdownFilterLogic, classes.formControl, classes.dropdown])
}

export default DropdownFilter
