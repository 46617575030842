import React, { useMemo } from 'react'

import {
  Button,
  Typography,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  CircularProgress
} from '@barracuda-internal/bds-core'

import ModeHandlerSelector from 'global/components/lib/modeHandlerSelector/ModeHandlerSelector'
import { useErrorFormatMessage, useFormatMessage } from 'global/lib/localization'

import useFreeTrialDialogLogic from 'ets/components/lib/dialogs/freeTrialDialog/useFreeTrialDialogLogic'
import styles from 'ets/components/lib/dialogs/freeTrialDialog/freeTrialDialogStyles'
import DEFAULTS from 'ets/configs/styles/defaults'

const BASE_I18N_KEY = 'ets.app.dialogs.free_trial'

export const FreeTrialDialog: React.FC = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatErrorMessage = useErrorFormatMessage()
  const classes = styles()
  const [freeTrialDialogLogic] = useFreeTrialDialogLogic()

  return useMemo(() => {
    const {
      isFreeTrialDialogOpened,
      isFreeTrialInProgress,
      onFreeTrial,
      onToggleFreeTrialDialog,
      onUpdateCMSettings,
      selectedCMSetting,
      isDialogDisabled,
      error,
      errorMessage
    } = freeTrialDialogLogic

    return (
      <>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          disabled={isDialogDisabled}
          className={classes.freeTrialButton}
          onClick={onToggleFreeTrialDialog}
          data-action="free-trial"
        >
          {formatMessage('button')}
        </Button>
        <Dialog
          style={{ zIndex: DEFAULTS.DIALOGS_ZINDEX }}
          classes={{ paper: classes[error ? 'withAlert' : 'paper'] }}
          open={isFreeTrialDialogOpened}
          onClose={onToggleFreeTrialDialog}
          data-field="free-trial-dialog"
        >
          {error && (
            <Alert variant="filled" severity="error">
              {formatErrorMessage(errorMessage || 'default')}
            </Alert>
          )}

          <DialogTitle className={classes.titleWrapper} id="form-dialog-title" data-field="title">
            <Typography className={classes.title}>{formatMessage('title')}</Typography>
          </DialogTitle>

          <DialogContent data-field="content" style={{ overflow: 'hidden' }}>
            <DialogContentText className={classes.text} data-field="content-text">
              {formatMessage('description')}
            </DialogContentText>

            <ModeHandlerSelector
              onUpdateCMSettings={onUpdateCMSettings}
              selectedCMSetting={selectedCMSetting}
              appName={formatMessage('app_name')}
            />

            <Grid container alignItems="center" justifyContent="flex-end">
              <DialogActions data-field="dialog-actions" className={classes.buttonWrapper}>
                <Button
                  className={classes.secondaryButton}
                  color="primary"
                  variant="text"
                  onClick={onToggleFreeTrialDialog}
                  disabled={isFreeTrialInProgress}
                  data-action="close"
                >
                  {formatMessage('close')}
                </Button>
                <Button
                  className={classes.primaryButton}
                  variant="contained"
                  onClick={onFreeTrial}
                  color="primary"
                  disabled={isFreeTrialInProgress}
                  data-action="activate"
                >
                  {formatMessage('activate')}
                </Button>
                {isFreeTrialInProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
              </DialogActions>
            </Grid>
          </DialogContent>
        </Dialog>
      </>
    )
  }, [classes, formatMessage, formatErrorMessage, freeTrialDialogLogic])
}

export default FreeTrialDialog
