import React, { useMemo } from 'react'

import GlobalUnifiedReporting from 'global/components/features/unifiedReporting/UnifiedReporting'
import { useFormatMessage } from 'global/lib/localization'

import Layout from 'sen/components/lib/layout/Layout'
import sentintelContentLoader from 'sen/components/lib/useSentinelContentCondition/SentinelContentLoader'

import useSideMenuLogic from './useUnifiedReportingSideMenuLogic'
import useUnifiedReportingLogic from './useUnifiedReportingLogic'
import useUnifiedReportingChartLogic from './useUnifiedReportingChartLogic'
import useUnifiedReportingHeaderLogic from './useUnifiedReportingHeaderLogic'
import useUnifiedReportingDatatableLogic from './useUnifiedReportingDatatableLogic'

const BASE_I18N_KEY = 'sen.app.unified_reporting'

const UnifiedReporting: React.FC = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [sideMenuConfig] = useSideMenuLogic()
  const [reportingChartConfig] = useUnifiedReportingChartLogic()
  const [unifiedReportingLogic] = useUnifiedReportingLogic()
  const [unifiedReportingHeaderConfig] = useUnifiedReportingHeaderLogic()
  const [reportingDatatableConfig] = useUnifiedReportingDatatableLogic()

  const { alertConfig, isLoading } = unifiedReportingLogic

  return useMemo(
    () => (
      <Layout pageTitle={formatMessage('page_title')}>
        <GlobalUnifiedReporting
          alertConfig={alertConfig}
          isLoading={isLoading}
          chartProps={reportingChartConfig}
          dataTableProps={reportingDatatableConfig}
          sideMenuProps={sideMenuConfig}
          reportHeaderProps={unifiedReportingHeaderConfig}
        />
      </Layout>
    ),
    [
      alertConfig,
      formatMessage,
      isLoading,
      reportingChartConfig,
      reportingDatatableConfig,
      sideMenuConfig,
      unifiedReportingHeaderConfig
    ]
  )
}

export default sentintelContentLoader({
  default: () => <UnifiedReporting />
})
