import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import reducerHandlers from 'global/redux/features/dataTables/reducerHandlers'
import { UpdateDataTable } from 'global/types/dataTables/dataTables'

import { DataTableWithFilterAndDemoState } from 'ets/redux/types/dataTables'

export const GRID_COLUMNS = {
  NAME: 'name',
  EMAIL: 'email',
  TITLE: 'title',
  RISK_LEVEL: 'risk_level',
  HIGH_RISK_FACTORS: 'high_risk_factors',
  THREATS_FOUND: 'threats_found',
  ACTION: 'action'
}

export const SORT_FIELDS = {
  [GRID_COLUMNS.NAME]: 'displayName',
  [GRID_COLUMNS.EMAIL]: 'email',
  [GRID_COLUMNS.TITLE]: 'title',
  [GRID_COLUMNS.RISK_LEVEL]: 'spScore',
  [GRID_COLUMNS.THREATS_FOUND]: 'spFraudCount'
}

export const FILTER_LEVELS = [3, 1]
export const FILTER_CONFIG = {
  ALL: {
    id: 'all_employees'
  },
  HIGH_RISK: {
    id: 'high_risk',
    level: FILTER_LEVELS[0],
    filterQuery: [`${SORT_FIELDS[GRID_COLUMNS.RISK_LEVEL]}:>=${FILTER_LEVELS[0]}`]
  },
  MEDIUM_RISK: {
    id: 'medium_risk',
    level: FILTER_LEVELS[1],
    filterQuery: [
      `${SORT_FIELDS[GRID_COLUMNS.RISK_LEVEL]}:>=${FILTER_LEVELS[1]}`,
      `${SORT_FIELDS[GRID_COLUMNS.RISK_LEVEL]}:<${FILTER_LEVELS[0]}`
    ]
  },
  LOW_RISK: {
    id: 'low_risk',
    filterQuery: [`${SORT_FIELDS[GRID_COLUMNS.RISK_LEVEL]}:<${FILTER_LEVELS[1]}`]
  }
} as any

export const DEMO_DATA = {
  ALL: 415,
  COUNTS: {
    [FILTER_CONFIG.ALL.id]: 415,
    [FILTER_CONFIG.HIGH_RISK.id]: 15,
    [FILTER_CONFIG.MEDIUM_RISK.id]: 119,
    [FILTER_CONFIG.LOW_RISK.id]: 281
  }
}

export const COLUMNS_CONFIG = {
  [GRID_COLUMNS.NAME]: {
    sortable: true,
    orderIndex: 0,
    show: true
  },
  [GRID_COLUMNS.EMAIL]: {
    sortable: true,
    orderIndex: 1,
    show: true
  },
  [GRID_COLUMNS.TITLE]: {
    sortable: true,
    orderIndex: 2,
    show: true
  },
  [GRID_COLUMNS.RISK_LEVEL]: {
    isDescOrderFirst: true,
    sortable: true,
    orderIndex: 3,
    show: true
  },
  [GRID_COLUMNS.HIGH_RISK_FACTORS]: {
    sortable: false,
    orderIndex: 4,
    show: true
  },
  [GRID_COLUMNS.THREATS_FOUND]: {
    isDescOrderFirst: true,
    sortable: true,
    orderIndex: 5,
    show: true
  },
  [GRID_COLUMNS.ACTION]: {
    sortable: false,
    orderIndex: 6,
    show: true
  }
}

const ITEMS_PER_PAGE = 10
const PDF_ITEMS_PER_PAGE = 10

// initialState
// Do not allow isBootstrap in filter
export const INITIAL_STATE: DataTableWithFilterAndDemoState = {
  GRID_COLUMNS,
  SORT_FIELDS,
  FILTER_CONFIG,
  DEMO_DATA,
  ITEMS_PER_PAGE,
  columnsConfig: COLUMNS_CONFIG,
  skip: 0, // offset
  take: ITEMS_PER_PAGE, // limit
  filter: null,
  search: '',
  sort: [{ field: GRID_COLUMNS.RISK_LEVEL, dir: 'desc' }],
  pdfConfig: {
    SORT_FIELDS,
    skip: 0, // offset
    take: PDF_ITEMS_PER_PAGE, // limit
    filter: null,
    search: '',
    sort: [{ field: GRID_COLUMNS.THREATS_FOUND, dir: 'desc' }]
  }
}

export const employeesSlice = createSlice({
  name: 'DATATABLE/EMPLOYEES',
  initialState: INITIAL_STATE,
  reducers: {
    update: {
      reducer: (state: DataTableWithFilterAndDemoState, action: PayloadAction<{ config: UpdateDataTable }>) => {
        return reducerHandlers({ COLUMNS_CONFIG }).update({ state, payload: action.payload })
      },
      prepare: (config: UpdateDataTable) => ({
        payload: { config }
      })
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  }
})

export const { update, reset } = employeesSlice.actions

export default employeesSlice.reducer
