import { useMemo, useState, useCallback, useEffect } from 'react'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { getCountryName } from 'global/lib/isoCountries'
import { FraudUnifiedRufData } from 'global/types/api/dmarc'
import useEmailDetailsRufSampleInterface, {
  UseEmailDetailsRufSampleInterface
} from 'sen/components/lib/dialogs/emailDetailsDialog/interfaces/useEmailDetailsRufSampleInterface'
import { downloadCSVFile } from 'global/lib/downloadFile'

import { resetFraudDataMap } from 'sen/redux/features/dmarc/dmarcSlice'
import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'

export enum Views {
  map = 'map',
  table = 'table',
  Preview = 'preview'
}

export interface CountryInfo {
  code: string
  name: string
}

export interface ViewProps {
  activeView: Views
}

export interface CountryProps {
  onCountrySelect: (country: string) => void
  selectedCountry: CountryInfo
}
export interface FraudProps {
  onOpenFraud: (fraud: FraudUnifiedRufData) => void
}

export interface DialogActionProps {
  onTableBack: () => void
}

export default function useDmarcCountriesLogic(): [
  ViewProps,
  CountryProps,
  FraudProps,
  DialogActionProps,
  UseEmailDetailsRufSampleInterface
] {
  const { activePath, rufSample } = useAppSelector(_stores => ({
    activePath: _stores.app.activePath,
    rufSample: _stores.dmarc.rufSample
  }))
  const dispatch = useAppDispatch()

  // init
  useEffect(() => {
    return () => {
      dispatch(resetFraudDataMap())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onDownloadEmail = useCallback(() => {
    if (rufSample) {
      analyticsLib.trackEvent(
        analyticsLib.EVENTS.DF_DOMAINS_DMARC_REVIEW_COUNTRIES_RUF_SAMPLES_PREVIEW_DOWNLOAD_EMAIL,
        {
          domain: activePath.params.domain
        }
      )

      downloadCSVFile(rufSample.rawEmail, 'sample.eml', 'message/rfc822;charset=utf-8')
    }
  }, [rufSample, activePath])

  const [emailDetailDialogConfig, emailDetailDialogActions] = useEmailDetailsRufSampleInterface({ onDownloadEmail })

  const [activeView, setActiveView] = useState<Views>(Views.map)
  const [selectedCountry, setSelectedCountry] = useState<CountryInfo>({ code: '', name: '' })

  const onCountrySelect = useCallback(
    (country: string) => {
      analyticsLib.trackEvent(analyticsLib.EVENTS.DF_DOMAINS_DMARC_REVIEW_COUNTRIES_CLICK_COUNTRY, {
        domain: activePath.params.domain,
        country
      })

      setSelectedCountry({ code: country, name: getCountryName(country) || '' })
      setActiveView(Views.table)
    },
    [activePath]
  )

  const onOpenFraud = useCallback(
    (fraud: FraudUnifiedRufData) => {
      analyticsLib.trackEvent(analyticsLib.EVENTS.DF_DOMAINS_DMARC_REVIEW_COUNTRIES_PREVIEW_SAMPLES, {
        domain: activePath.params.domain,
        country: selectedCountry
      })
      emailDetailDialogActions.onOpen({ domain: activePath.params.domain, key: fraud.sampleKey })
    },
    [activePath, selectedCountry, emailDetailDialogActions]
  )

  const onTableBack = useCallback(() => {
    analyticsLib.trackEvent(analyticsLib.EVENTS.DF_DOMAINS_DMARC_REVIEW_COUNTRIES_BACK_TO_ALL_COUNTRIES, {
      domain: activePath.params.domain
    })
    setSelectedCountry({ code: '', name: '' })
    setActiveView(Views.map)
  }, [activePath])

  return useMemo(() => {
    return [
      {
        activeView
      },
      {
        onCountrySelect,
        selectedCountry
      },
      {
        onOpenFraud
      },
      {
        onTableBack
      },
      [emailDetailDialogConfig, emailDetailDialogActions]
    ]
  }, [
    activeView,
    onCountrySelect,
    selectedCountry,
    onOpenFraud,
    onTableBack,
    emailDetailDialogConfig,
    emailDetailDialogActions
  ])
}
