import React from 'react'

import GlobalSignupPolicy from 'global/components/features/signinSignup/signupPolicy/SignupPolicy'

import routesConfig from 'ets/lib/routes/routesConfig'
import IMAGES from 'ets/configs/theme/images.config'

export const SignupPolicy: React.FC = () => (
  <GlobalSignupPolicy bgImage={IMAGES.loginBackground} onGoto={routesConfig.SIGNUP_COMPLETE.goto} />
)

export default SignupPolicy
