import { createAsyncThunk } from '@reduxjs/toolkit'

import { config } from 'global/lib/config'
import { User } from 'global/types/api/userType'
import restClient, { validateApiError } from 'global/lib/api/restClient'
import { globalApiRoutes } from 'global/lib/api/apiRoutes'

export interface AnalyticsMixpanelTrackEvent {
  user: User
  eventName: string
  metadata?: { [key: string]: string | number }
}

export const analyticsMixpanelTrackEvent = createAsyncThunk(
  'ANALYTICS/analyticsMixpanelTrackEvent',
  async function doAnalyticsMixpanelTrackEvent(payload: AnalyticsMixpanelTrackEvent, { rejectWithValue }) {
    const isMixpanelDefined: boolean = !!window.mixpanel && config.mixpanelToken

    if (isMixpanelDefined) {
      try {
        const { user, eventName, metadata = {} } = payload

        await restClient(globalApiRoutes.MIXPANEL_TRACK_EVENT, {
          data: { user, eventName, metadata }
        })

        return { eventName }
      } catch (e) {
        return rejectWithValue(validateApiError(e))
      }
    } else if (config.isDev) {
      // eslint-disable-next-line no-console
      console.log('Mixpanel is not defined, blocked event:', payload)
    }

    return rejectWithValue(undefined)
  }
)
