import { createSlice } from '@reduxjs/toolkit'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { getUser, logout, signin } from 'global/redux/features/auth/authApiThunks'
import { AccessToken } from 'global/types/api/accessTokenType'
import { Account } from 'global/types/api/accountType'
import { User } from 'global/types/api/userType'

export interface UserState {
  data: User
  isFirDemoUser: boolean
  isUserDataLoaded: boolean
}

// initialState
export const INITIAL_STATE: UserState = {
  data: {} as User,
  isFirDemoUser: false,
  isUserDataLoaded: false
}

/* eslint-disable no-param-reassign */
export const userSlice = createSlice({
  name: 'USER',
  initialState: INITIAL_STATE,
  // reducers object creates an action and executes the reducer function
  reducers: {
    setUser: (state, action) => {
      analyticsLib.setUser(state.data)
      state.data = action.payload?.user || {}
      state.isUserDataLoaded = true
    },
    setUserEssRegion: (state, action) => {
      state.data.essRegion = action.payload?.essRegion
    },
    setBCSSubscriptionForAccessToken: (state, action) => {
      // Find the account that have accessTokens that need to be updated
      const account = state.data.accounts.find((accountToCheck: Account) => {
        return accountToCheck.accountId === action.payload.accountId
      }) as Account

      const accessToken = account.accessTokens.find((token: AccessToken) => {
        return token.id === action.payload.accessTokenId
      }) as AccessToken

      if (accessToken) {
        accessToken.bcsSubscription = action.payload?.bcsSubscription
      }
    },
    setSettingsForAccessToken: (state, action) => {
      const accessToken = state.data.accounts.reduce((all: AccessToken | undefined, account: Account) => {
        return account.accessTokens.find((token: AccessToken) => token.id === action.payload?.accessTokenId)
      }, undefined)

      if (accessToken) {
        accessToken.settings = action.payload?.settings
      }
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  },

  extraReducers: builder => {
    builder
      .addCase(getUser.pending, () => {
        return {
          ...INITIAL_STATE
        }
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.data = action.payload || {}
        state.isFirDemoUser = action.payload?.switches?.includes('FORENSICS_DEMO')
        state.isUserDataLoaded = true
      })
      .addCase(getUser.rejected, state => {
        state.isUserDataLoaded = true
      })
      .addCase(signin.fulfilled, (state, action) => {
        state.data = action.payload || {}
        state.isUserDataLoaded = true
      })
      .addCase(logout.fulfilled, state => {
        state.data = INITIAL_STATE.data
      })
  }
})
/* eslint-enable no-param-reassign */

export const {
  reset,
  setBCSSubscriptionForAccessToken,
  setSettingsForAccessToken,
  setUser,
  setUserEssRegion
} = userSlice.actions

export default userSlice.reducer
