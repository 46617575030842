import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Select, MenuItem } from '@barracuda-internal/bds-core'
import FormControl from '@material-ui/core/FormControl'

import { useFormatMessage } from 'global/lib/localization'
import DEFAULTS from 'global/configs/theme/defaults'

export interface DateRangeProps {
  defaultRange?: number
  ranges: (HourRanges | DayRanges)[]
  onSelectRange: (range: number) => void
  zIndex?: number
}

export enum DayRanges {
  '7days' = 7,
  '30days' = 30,
  '90days' = 90
}

export enum HourRanges {
  '1Hour' = 1,
  '12hours' = 12,
  'lastDay' = 24,
  'lastWeek' = 168,
  'lastMonth' = 720
}

const LOCALIZATION_PATHS: any = {
  [HourRanges['1Hour']]: 'last_hour',
  [HourRanges['12hours']]: 'hour_range',
  [HourRanges.lastDay]: 'last_day',
  [HourRanges.lastWeek]: 'last_week',
  [HourRanges.lastMonth]: 'last_month',
  [DayRanges['7days']]: 'day_range',
  [DayRanges['30days']]: 'day_range',
  [DayRanges['90days']]: 'day_range'
}

const BASE_I18N_KEY = 'app.date_range_selector'

const DateRangeSelector: React.FC<DateRangeProps> = ({
  defaultRange = 0,
  ranges,
  onSelectRange,
  zIndex = DEFAULTS.DATE_RANGE_ZINDEX
}) => {
  // default report page is 30 days
  const [daysBack, setDaysBack] = useState<number>(defaultRange)
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return (
    <FormControl>
      <Select
        MenuProps={{
          style: { zIndex }
        }}
        value={daysBack}
        autoWidth
        onChange={event => {
          setDaysBack(event.target.value as number)
          onSelectRange(event.target.value as number)
        }}
        data-field="select"
      >
        {ranges.map((range, index) => (
          <MenuItem key={`select-${range}`} value={index}>
            {formatMessage(LOCALIZATION_PATHS[range], { range })}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

DateRangeSelector.propTypes = {
  defaultRange: PropTypes.number,
  ranges: PropTypes.array.isRequired,
  onSelectRange: PropTypes.func.isRequired,
  zIndex: PropTypes.number
}

DateRangeSelector.defaultProps = {
  defaultRange: 0,
  zIndex: 1300
}

export default DateRangeSelector
