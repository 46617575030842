import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const CELL_HEIGHT = 35

export default makeStyles(theme => makeGridStyles(theme))

export function makeGridStyles(theme: Theme) {
  return createStyles({
    // Grid Overrides
    gridLayout: {
      borderColor: theme.palette.divider,
      borderWidth: 0,
      '& .k-grid-header .k-header': {
        verticalAlign: 'middle',
        whiteSpace: 'normal !important'
      },
      // START - PLEASE DO NOT DELETE THESE RULES
      '& .k-grid-header-wrap': {
        border: 'none'
      },
      '& .empty-cell': {
        height: CELL_HEIGHT
      },
      '& th.k-header.active > div > div': {
        backgroundColor: theme.palette.primary.main, // "#ff6358"
        borderRadius: '2px',
        color: theme.palette.info.contrastText
      },
      // END - PLEASE DO NOT DELETE THESE RULES
      '& th': {
        fontWeight: theme.font.weight.medium,
        padding: theme.spacing(1, 2)
      },
      '& th:last-of-type': {
        borderRightWidth: 0
      },
      // this is for the <gridToolbar>
      '& > :first-child': {
        padding: 0
      },
      '& td': {
        padding: theme.spacing(1, 2)
      },
      '& .k-detail-cell': {
        padding: theme.spacing(1),
        '& > div': {
          backgroundColor: theme.palette.info.contrastText,
          boxShadow: '0 1px 1px 0 rgba(0,0,0,0.25), 0 1px 1px 0 rgba(0,0,0,0.1)',
          padding: theme.spacing(2, 4),
          '& strong': {
            display: 'block',
            fontSize: theme.font.size.small,
            fontWeight: theme.font.weight.medium,
            marginBottom: theme.spacing(0.5)
          },
          '& p': {
            fontSize: theme.font.size.regular
            // lineHeight: theme.font.lineheight.paragraph
          }
        }
      },
      '& .k-pager-wrap .k-pager-numbers .k-state-selected': {
        backgroundColor: theme.palette.pagination.selected,
        color: theme.palette.text.primary
      },
      '& .k-grouping-row .k-icon': {
        marginLeft: theme.spacing(-1),
        outline: 'none'
      },

      '&.k-grid .k-hierarchy-cell>.k-icon': {
        padding: theme.spacing(1),
        width: theme.spacing(2)
      },

      '& .k-i-collapse::before, .k-minus::before': {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '3px',
        boxSizing: 'border-box',
        content: '"\\2212"',
        display: 'block',
        fontSize: theme.font.size.regular,
        fontWeight: theme.font.weight.bold,
        height: theme.spacing(2),
        lineHeight: '8px',
        textAlign: 'center',
        width: theme.spacing(2)
      },

      '& .k-i-expand::before, .k-plus::before': {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '3px',
        boxSizing: 'border-box',
        content: '"\\2b"',
        display: 'block',
        fontSize: theme.font.size.regular,
        fontWeight: theme.font.weight.bold,
        height: theme.spacing(2),
        lineHeight: '8px',
        textAlign: 'center',
        width: theme.spacing(2)
      },

      // Disable hover over background color
      '& .k-grid-table tr:hover': {
        background: 'transparent'
      },
      '& .k-grid-table tr.k-alt:hover': {
        background: 'transparent'
      },
      '& .k-grid-header tr:hover': {
        background: 'transparent'
      },
      '& .k-grid-header tr.k-alt:hover': {
        background: 'transparent'
      }
    },

    gridColumn: {
      lineHeight: `${CELL_HEIGHT}px !important`
    },

    gridToolbar: {
      backgroundColor: theme.palette.background.default,
      borderTop: `1px solid ${theme.palette.divider}`,
      display: 'block',
      height: '44px'
    }
  })
}
