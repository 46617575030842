import { createSlice } from '@reduxjs/toolkit'

import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'global/redux/toolkit/api'
import { getConvergedMessageLogs } from 'global/redux/features/convergedMessageLog/convergedMessageLogThunks'

export type MessageLog = {
  id: string
  message: string
  timestamp: string
}

export interface ConvergedMessageLogState {
  convergedMessageLogApiStatus: ApiStatus
  messageLogs: MessageLog[]
}

// initialState
export const INITIAL_STATE: ConvergedMessageLogState = {
  convergedMessageLogApiStatus: inIdle,
  messageLogs: []
}

export const convergedMessageLogSlice = createSlice({
  name: 'CONVERGED_MESSAGE_LOG',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getConvergedMessageLogs.pending, state => {
        state.convergedMessageLogApiStatus = inProgress
      })
      .addCase(getConvergedMessageLogs.fulfilled, (state, action) => {
        state.convergedMessageLogApiStatus = successResponse
        state.messageLogs = action.payload as MessageLog[]
      })
      .addCase(getConvergedMessageLogs.rejected, (state, action) => {
        state.convergedMessageLogApiStatus = failedResponse(action.payload as string)
      })
  }
})

export { getConvergedMessageLogs }

export default convergedMessageLogSlice.reducer
