import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => {
  return {
    menuItem: {
      pointerEvents: 'none'
    },
    filterActionsContainer: {
      padding: `0 ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`
    },
    formControlContainer: {
      padding: `0 ${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px`,
      borderBottom: `1px solid ${theme.palette.divider}`,
      width: '100%'
    },
    checkBoxContainer: {
      padding: theme.spacing(1)
    },
    label: {
      display: 'flex',
      padding: theme.spacing(1),
      whiteSpace: 'pre-wrap'
    }
  }
})
