import { sortBy } from 'lodash'

import { reduxStore } from 'global/lib/reduxStore'
import * as dmarcService from 'global/lib/domain/dmarc'

export default function softUpdateTableData(data: any[]) {
  const domainsTable = reduxStore.getState().dataTables.domains
  const sortConfig: { field: string; dir: 'asc' | 'desc' } = domainsTable.sort[0]

  let sortedData = []
  switch (sortConfig.field) {
    case domainsTable.GRID_COLUMNS.DOMAIN:
      sortedData = sortBy(data, ['name'])
      return sortConfig.dir === 'asc' ? sortedData : sortedData.reverse()
    case domainsTable.GRID_COLUMNS.STATUS:
      sortedData = data.sort((a: any, b: any) => dmarcService.sortAboutDmarcState(a.dmarcState, b.dmarcState))
      return sortConfig.dir === 'asc' ? sortedData : sortedData.reverse()
    default:
      return data
  }
}
