import { makeStyles } from '@material-ui/core/styles'
import { COLORS } from 'global/configs/theme/defaults'

export const webhookDialogStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  helperText: {
    wordWrap: 'break-word',
    color: COLORS.BLACK_50
  },
  helpLink: {
    fontSize: '14px'
  },
  iconPadding: {
    paddingRight: theme.spacing(1)
  },
  invisibleIcon: {
    color: 'white'
  },
  label: {
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  mdTextField: {
    width: '65%'
  },
  wordWrap: {
    wordWrap: 'break-word'
  }
}))

export default makeStyles(theme => ({
  invisibleIcon: {
    color: 'white'
  }
}))
