import React from 'react'
import PropTypes from 'prop-types'

import { Tooltip } from '@barracuda-internal/bds-core'

import { COLORS } from 'global/configs/theme/defaults'
import attackTypeValidator, { ATTACK_TYPES } from 'global/lib/attackTypeValidator/attackTypeValidator'
import TextLabel from 'global/components/lib/textLabel/TextLabel'
import styles from 'global/components/lib/attackTypeLabel/attackTypeLabelStyles'
import { useFormatMessage } from 'global/lib/localization'

export interface AttackTypeLabelProps {
  attackType: string | undefined
}

const BASE_ATTACK_TYPES_I18N_KEY = 'app.attack_types'

const ATTACK_COLORS: { [key: string]: string } = {
  [ATTACK_TYPES.CONVERSATION_HIJACKING]: COLORS.MAGENTA_600,
  [ATTACK_TYPES.EXTORTION]: COLORS.SCALE_GRAY_800,
  [ATTACK_TYPES.IMPERSONATION]: COLORS.BLUE_600,
  [ATTACK_TYPES.PHISHING]: COLORS.TEAL_600,
  [ATTACK_TYPES.SCAMMING]: COLORS.HUMPBACK_GRAY_600
}

const AttackTypeLabel: React.FC<AttackTypeLabelProps> = ({ attackType }) => {
  const formatMessage = useFormatMessage(BASE_ATTACK_TYPES_I18N_KEY)
  const classes = styles()

  if (!attackType) {
    return null
  }

  const validatedAttackType = attackTypeValidator(attackType)
  if (!validatedAttackType) {
    return null
  }

  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
      arrow
      title={
        <div className={classes.tooltipContent}>
          <b>{formatMessage(`${validatedAttackType}.text`)}</b>
          <br />
          <br />
          {formatMessage(`${validatedAttackType}.tooltip`)}
        </div>
      }
      placement="top-start"
    >
      <data>
        <TextLabel bgColor={ATTACK_COLORS[validatedAttackType]} data-field="attack-type-label">
          {formatMessage(`${validatedAttackType}.text`)}
        </TextLabel>
      </data>
    </Tooltip>
  )
}

AttackTypeLabel.propTypes = {
  attackType: PropTypes.string.isRequired
}

export default AttackTypeLabel
