import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => {
  return {
    radioButtonGroup: {
      margin: `${theme.spacing(2)}px 0`
    },
    adminContainer: {
      paddingLeft: theme.spacing(4)
    }
  }
})
