import { makeStyles } from '@material-ui/core/styles'
import selectLabelStyles from 'global/components/lib/select/selectLabelStyles'

export const searchingForEmailsStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  formLine: {
    marginBottom: theme.spacing(3)
  },
  formLineError: {
    marginBottom: theme.spacing(3),
    color: '#EF5350'
  },
  inlineTextField: {
    width: '100%'
  },
  matchExactFrame: {
    marginLeft: theme.spacing(1)
  },
  selectLabel: {
    ...selectLabelStyles.formControlLabel
  }
}))

export const reviewMessagesStyles = makeStyles(theme => ({
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  emailColumn: {
    width: 65
  },
  hidden: {
    visibility: 'hidden'
  },
  leftGrid: {
    backgroundColor: '#F7F7F7',
    color: '#646464',
    fontWeight: 'bold',
    textAlign: 'right'
  },
  rightGrid: {
    color: '#646464'
  },
  grid: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    // Disable hover over background color
    '& .k-grid-table tr:hover': {
      background: 'transparent'
    },
    '& .k-grid-table tr.k-alt:hover': {
      background: 'transparent'
    },
    '& .k-grid-header tr:hover': {
      background: 'transparent'
    },
    '& .k-grid-header tr.k-alt:hover': {
      background: 'transparent'
    }
  },
  pointer: {
    cursor: 'pointer'
  },
  smallIcon: {
    zoom: 0.5
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  warningIcon: {
    paddingRight: theme.spacing(0.5)
  },
  warningText: {
    alignItems: 'center',
    color: theme.colors.redOrange600,
    display: 'flex',
    fontWeight: theme.font.weight.bold
  }
}))

export const remediationOptionsStyles = makeStyles(theme => ({
  bottomText: {
    marginTop: theme.spacing(5)
  },
  createIncident: {
    marginTop: theme.spacing(2)
  },
  editEmailButton: {
    marginLeft: theme.spacing(2)
  },
  formControl: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2)
  },
  policyPlaceSelector: {
    '& .MuiSelect-root': {
      fontWeight: 600
    },
    '& .MuiSvgIcon-root': {
      color: theme.colors.barracudaBlack70
    }
  },
  policyComment: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(4)
  },
  senderPolicyLabel: {
    marginBottom: theme.spacing(1)
  },
  subFormControl: {
    marginLeft: theme.spacing(1)
  },
  subLabel: {
    color: theme.colors.barracudaBlack50,
    marginLeft: theme.spacing(4),
    marginTop: -theme.spacing(1)
  },
  tagForm: {
    maxWidth: '700px',
    width: '100%'
  },
  title: {
    marginTop: theme.spacing(4)
  }
}))

export default makeStyles(theme => ({
  wrapper: {
    marginTop: theme.spacing(2)
  },
  dialogButtons: {
    marginTop: theme.spacing(3),
    paddingRight: 0
  },
  emailDetailDialog: {
    padding: theme.spacing(2)
  },
  disabledLabel: {
    color: '#aaaaaa'
  },
  opacity: {
    opacity: 0.8
  },
  inline: {
    display: 'inline-block'
  },
  closerToTop: {
    marginTop: 0
  },
  bold: {
    fontWeight: theme.font.weight.bold
  },
  lowercase: {
    textTransform: 'lowercase'
  }
}))
