export interface Highlights {
  [key: string]: string[]
}

export default function collectHighlights(filters: any, allFilters: any): Highlights {
  return filters.reduce((all: Highlights, filter: any) => {
    if (filter.filters) {
      return { ...collectHighlights(filter.filters, all) }
    }

    if (filter.field) {
      return {
        ...all,
        [filter.field]: [...(all[filter.field] || ''), filter.value]
      }
    }

    return all
  }, allFilters)
}
