import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  columnMenuCustom: {
    '& .k-columnmenu-item.k-state-selected': {
      color: theme.palette.primary.main
    },
    '& .k-columnmenu-actions .k-button.k-primary': {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: theme.palette.info.contrastText
    },
    '& .k-column-list-item .k-checkbox:checked': {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: theme.palette.info.contrastText
    },
    '& .k-column-list': {
      maxHeight: '225px'
    }
  }
}))
