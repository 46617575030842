import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import {
  Typography,
  Button,
  Alert,
  Dialog,
  DialogContent,
  DialogActions,
  Zoom,
  Grid,
  DataTable,
  DataTableColumn
} from '@barracuda-internal/bds-core'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import DEFAULTS from 'global/configs/theme/defaults'
import { useErrorFormatMessage, useFormatMessage } from 'global/lib/localization'
import { config } from 'global/lib/config'
import { formatDate } from 'global/lib/datetime'
import { Cell, CellText } from 'global/components/lib/grid/cell'

import styles from 'admin/components/lib/dialogs/incidentDialog/incidentDialogStyles'
import useIncidentDialogLogic from 'admin/components/lib/dialogs/incidentDialog/useIncidentDialogLogic'
import { TransformedRemediationStats } from 'admin/redux/types/remediation'

export interface IncidentDialogProps {
  accessTokenId: string
  onClose: () => void
}

const BASE_I18N_KEY = 'admin.app.dialogs.incident_dialog'
const BASE_I18N_TABLE_KEY = 'admin.app.dialogs.incident_dialog.table'

export const IncidentDialog: React.FC<IncidentDialogProps> = ({ accessTokenId, onClose }) => {
  const [isLoading, remediationStats, identityStats, tableConfig, error] = useIncidentDialogLogic(accessTokenId)
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const tableFormatMessage = useFormatMessage(BASE_I18N_TABLE_KEY)
  const formatErrorMessage = useErrorFormatMessage()
  const classes = styles()

  return useMemo(
    () => (
      <Dialog
        style={{ zIndex: DEFAULTS.SECONDARY_DIALOGS_ZINDEX }}
        maxWidth="md"
        fullWidth
        open
        TransitionComponent={Zoom}
        data-field="dialog"
      >
        {error && (
          <Alert data-field="error" variant="filled" severity="error">
            {formatErrorMessage('default')}
          </Alert>
        )}
        {isLoading && <LinearProgress data-field="loader" />}
        {!isLoading && remediationStats && (
          <DialogContent className={classes.dialogContent} data-field="dialog-content">
            <Typography className={classes.title} data-field="dialog-title">
              {formatMessage('title')}
            </Typography>
            <Grid className={classes.contentRow}>
              <Typography className={classes.label} data-field="dialog-serial-label">
                {formatMessage('last_incident_created')}:{' '}
              </Typography>
              <Typography data-field="dialog-serial-value">
                {formatDate(remediationStats?.lastIncidentCreatedDate, config.DATETIME.DEFAULT_DATE_FORMAT)}
              </Typography>
            </Grid>
            <Grid className={classes.contentRow}>
              <Typography className={classes.label} data-field="dialog-serial-label">
                {formatMessage('mailbox_count')}:{' '}
              </Typography>
              <Typography data-field="dialog-serial-value">{identityStats?.count}</Typography>
            </Grid>
            {remediationStats?.lastIncidentCreatedDate && (
              <Grid
                className={classes.tableArea}
                data-table="scans"
                data-testid="scans"
                item
                xs={12}
                data-field="table-grid"
              >
                <DataTable
                  className={`${classes.dataTable} incident-table`}
                  data={tableConfig.tableData}
                  data-field="table"
                >
                  <DataTableColumn
                    width={400}
                    field={tableConfig.columns.STATISTIC}
                    {...tableConfig.columnsConfig[tableConfig.columns.STATISTIC]}
                    title={tableFormatMessage(`${tableConfig.columns.STATISTIC}`)}
                    cell={({ dataItem }: { dataItem: TransformedRemediationStats }) => (
                      <Cell className={classes.cell}>
                        <CellText ellipsis data-field="created-on">
                          {dataItem.name}
                        </CellText>
                      </Cell>
                    )}
                  />
                  <DataTableColumn
                    field={tableConfig.columns.LAST_30_DAYS}
                    {...tableConfig.columnsConfig[tableConfig.columns.LAST_30_DAYS]}
                    title={tableFormatMessage(`${tableConfig.columns.LAST_30_DAYS}`)}
                    cell={({ dataItem }: { dataItem: TransformedRemediationStats }) => (
                      <Cell className={classes.cell}>
                        <CellText ellipsis data-field="created-on">
                          {dataItem.last30Days}
                        </CellText>
                      </Cell>
                    )}
                  />
                  <DataTableColumn
                    field={tableConfig.columns.LIFETIME}
                    {...tableConfig.columnsConfig[tableConfig.columns.LIFETIME]}
                    title={tableFormatMessage(`${tableConfig.columns.LIFETIME}`)}
                    cell={({ dataItem }: { dataItem: TransformedRemediationStats }) => (
                      <Cell className={classes.cell}>
                        <CellText ellipsis data-field="created-on">
                          {dataItem.lifetime}
                        </CellText>
                      </Cell>
                    )}
                  />
                </DataTable>
              </Grid>
            )}
          </DialogContent>
        )}
        <DialogActions className={classes.actions}>
          <Button color="primary" variant="contained" size="large" onClick={onClose} data-field="dialog-dismiss-button">
            &nbsp;&nbsp;{formatMessage('dismiss')}&nbsp;&nbsp;
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [
      onClose,
      isLoading,
      remediationStats,
      identityStats,
      tableConfig,
      error,
      classes,
      formatMessage,
      tableFormatMessage,
      formatErrorMessage
    ]
  )
}

IncidentDialog.propTypes = {
  accessTokenId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
}

export default IncidentDialog
