import { useCallback, useMemo, useReducer } from 'react'
import validator from 'validator'

import { useEffectOnInit } from 'global/lib/useCustomEffect'
import { config } from 'global/lib/config'
import * as analyticsLib from 'global/lib/analytics/analyticsService'

import routesConfig from 'ets/lib/routes/routesConfig'

export interface ContestLogicProps {
  onEmailChange: (e: any) => void
  email: string
  link: string
}

export default function useContestLogic(querySource: string): [ContestLogicProps] {
  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    email: '',
    link: '',
    source: querySource
  })

  // init
  useEffectOnInit(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.MY_LINK_PAGEVIEW, { url: window.location.href })
  }, [])

  const onEmailChange = useCallback(
    (e: any) => {
      const email = e.target.value
      const isValidEmail = validator.isEmail(email)
      if (isValidEmail) {
        const link = `${config.domains.ets}${routesConfig.SIGNIN_SIGNUP.path}?source=${state.source}&ref=${email}`
        setState({ email, link })
      } else {
        setState({ email })
      }
    },
    [state.source]
  )

  return useMemo(() => {
    return [
      {
        onEmailChange,
        email: state.email,
        link: state.link
      }
    ]
  }, [onEmailChange, state.email, state.link])
}
