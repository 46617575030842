import { FilterDescriptor, SortDescriptor } from '@progress/kendo-data-query'
import { createSlice } from '@reduxjs/toolkit'

import { ColumnsConfig, GridColumns } from 'global/types/dataTables/dataTables'
import { config } from 'global/lib/config'

interface EmailsByIncidentState {
  GRID_COLUMNS: GridColumns
  collapsedGroups: any[]
  columnsConfig: ColumnsConfig
  expandedRows: any[]
  filter: null | FilterDescriptor[] | string[]
  page: { skip: number; take: number }
  sort: SortDescriptor[]
}

export const GRID_COLUMNS = {
  AFFECTED_MAILBOX: 'email',
  ATTACHMENTS: 'attachments',
  RECEIVED_DATE: 'date',
  SENDER_EMAIL: 'senderEmail',
  STATUS: 'status',
  SUBJECT: 'subject'
}

export const COLUMNS_CONFIG = {
  [GRID_COLUMNS.RECEIVED_DATE]: {
    orderIndex: 0,
    show: true,
    sortable: true
  },
  [GRID_COLUMNS.SENDER_EMAIL]: {
    orderIndex: 1,
    show: true,
    sortable: true
  },
  [GRID_COLUMNS.AFFECTED_MAILBOX]: {
    orderIndex: 2,
    show: true,
    sortable: true
  },
  [GRID_COLUMNS.SUBJECT]: {
    show: true,
    orderIndex: 3,
    sortable: true
  },
  [GRID_COLUMNS.STATUS]: {
    orderIndex: 4,
    show: true,
    sortable: false
  },
  [GRID_COLUMNS.ATTACHMENTS]: {
    orderIndex: 5,
    show: false,
    sortable: false
  }
}

// Store
export const INITIAL_STATE: EmailsByIncidentState = {
  GRID_COLUMNS,
  collapsedGroups: [],
  columnsConfig: COLUMNS_CONFIG,
  expandedRows: [],
  filter: null,
  page: { skip: 0, take: config.DATA_TABLE.FORENSICS.ITEMS_PER_PAGE },
  sort: [{ field: GRID_COLUMNS.RECEIVED_DATE, dir: 'desc' }]
}

export const emailsByIncidentSlice = createSlice({
  name: 'DATATABLE/EMAILS_BY_INCIDENT',
  initialState: INITIAL_STATE,
  reducers: {
    update: (state, action) => {
      return {
        ...state,
        ...action.payload?.config
      }
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  }
})

export const { update, reset } = emailsByIncidentSlice.actions

export default emailsByIncidentSlice.reducer
