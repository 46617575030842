import React from 'react'
import PropTypes from 'prop-types'

import { Typography, Grid } from '@barracuda-internal/bds-core'

import styles from 'global/components/lib/maskLayer/maskLayerStyles'

export interface MaskLayerProps {
  children?: any
}

const MaskLayer: React.FC<MaskLayerProps> = ({ children }) => {
  const classes = styles()

  return (
    <Grid className={classes.wrapper} container direction="row">
      <div className={classes.maskLayer} />
      <div className={classes.textLayer}>
        <Typography className={classes.text}>{children}</Typography>
      </div>
    </Grid>
  )
}

MaskLayer.propTypes = {
  children: PropTypes.any
}

MaskLayer.defaultProps = {
  children: null
}

export default MaskLayer
