import { useMemo } from 'react'

import { isPending, isSuccess } from 'global/redux/toolkit/api'

import { getSenders, getSenderDetails, resetSenderDetails } from 'sen/redux/features/identity/identitySlice'
import useIdentitiesAutocompleteLogic, {
  IdentityAutocompleteConfig,
  SelectedIdentity
} from 'sen/components/lib/autocompletes/identity/useIdentityAutocompleteLogic'
import { useAppSelector } from 'sen/redux/toolkit/hooks'

export type SendersAutocompleteConfig = IdentityAutocompleteConfig
export type SelectedSender = SelectedIdentity

export interface UseSendersAutocompleteLogicProps {
  shouldSetInititalIdentity?: boolean
  isRequired?: boolean
}

export default function useSendersAutocompleteLogic({
  shouldSetInititalIdentity = false,
  isRequired = false
}: UseSendersAutocompleteLogicProps = {}): [SendersAutocompleteConfig, SelectedSender] {
  const { senders, isGetSenders, isGetSendersSuccess, senderIdentityDetails } = useAppSelector(_stores => ({
    senders: _stores.identity.senders,
    isGetSenders: isPending(_stores.identity.getSendersApiStatus),
    isGetSendersSuccess: isSuccess(_stores.identity.getSendersApiStatus),
    senderIdentityDetails: _stores.identity.senderIdentityDetails
  }))

  const [identityAutocompleteConfig, selectedIdentity] = useIdentitiesAutocompleteLogic({
    identites: senders,
    isLoading: isGetSenders,
    isSuccess: isGetSendersSuccess,
    identityDetails: senderIdentityDetails,
    resetIdentityDetails: resetSenderDetails,
    getIdentities: getSenders,
    getDetails: getSenderDetails,
    shouldSetInititalIdentity,
    isRequired
  })

  return useMemo(() => {
    return [identityAutocompleteConfig, selectedIdentity]
  }, [identityAutocompleteConfig, selectedIdentity])
}
