import React, { useCallback, useMemo, useState } from 'react'
import _ from 'lodash'
import {
  Checkbox,
  CheckboxGroup,
  CheckboxLabel,
  FormControl,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectLabel,
  SelectWrapper,
  Typography
} from '@barracuda-internal/bds-core'
import { Filter } from '@barracuda-internal/bds-core/dist/Icons/Core'

import { Filter as FilterType } from 'global/types/api/unifiedReporting'

import styles from './filterStyles'

export interface Condition {
  label: string
  value: string
}

interface ConditionFilterProps {
  columnFilter?: FilterType
  columnId: string
  columnValues: Condition[]
  conditionLabel: string
  conditions: Condition[]
  filterTitle: string
  handleRemoveColumnFilter: (columnId: string) => void
  selectAllLabel: string
  setColumnFilters: (filters: any) => void
}

const ConditionCheckboxFilter: React.FC<ConditionFilterProps> = ({
  columnFilter,
  columnId,
  columnValues,
  conditionLabel,
  conditions,
  filterTitle,
  handleRemoveColumnFilter,
  selectAllLabel,
  setColumnFilters
}) => {
  const classes = styles()
  const [conditionValue, setConditionValue] = useState<string>(
    columnFilter ? columnFilter.operation : conditions[0].value
  )
  const [checkedFilters, setCheckedFilters] = useState<string[]>(columnFilter ? columnFilter.value : [])

  const handleSelectChange = useCallback(
    (e: any) => {
      setConditionValue(e.target.value)
      setColumnFilters({ fieldName: columnId, value: checkedFilters, operation: e.target.value })
    },
    [checkedFilters, columnId, setColumnFilters]
  )

  // When a Checkbox is clicked determine if it is checked or unchecked and add or remove it from the checkedFilters state.
  const handleCheckboxChange = useCallback(
    (e: any) => {
      const { value, checked } = e.target
      let updatedCheckedFilters

      if (checked) {
        updatedCheckedFilters = [...checkedFilters, value]
        setCheckedFilters(updatedCheckedFilters)
      } else {
        updatedCheckedFilters = checkedFilters.filter((filter: string) => filter !== value)
        setCheckedFilters(updatedCheckedFilters)
      }

      if (updatedCheckedFilters.length === 0) {
        handleRemoveColumnFilter(columnId)
      } else {
        setColumnFilters({ fieldName: columnId, value: updatedCheckedFilters, operation: conditionValue })
      }
    },
    [checkedFilters, columnId, conditionValue, handleRemoveColumnFilter, setColumnFilters]
  )

  const handleCheckboxSelectAllChange = useCallback(
    (e: any) => {
      const { checked } = e.target

      if (!checked) {
        setCheckedFilters([])
        handleRemoveColumnFilter(columnId)
      } else {
        const updatedCheckedFilters = _.map(columnValues, 'value')
        setCheckedFilters(updatedCheckedFilters)
        setColumnFilters({ fieldName: columnId, value: updatedCheckedFilters, operation: conditionValue })
      }
    },
    [columnId, columnValues, conditionValue, handleRemoveColumnFilter, setColumnFilters]
  )

  return useMemo(
    () => (
      <>
        <MenuItem key="title" className={classes.menuItem}>
          <ListItemIcon>
            <Filter fontSize="small" />
          </ListItemIcon>
          <ListItemText>{filterTitle}</ListItemText>
        </MenuItem>

        <Grid container className={classes.filterActionsContainer} component="li">
          <FormControl className={classes.formControlContainer}>
            <SelectWrapper variant="outlined" fullWidth>
              <SelectLabel id="condition-filter-outlined-label">{conditionLabel}</SelectLabel>
              <Select label="Condition" value={conditionValue} onChange={handleSelectChange}>
                {conditions.map((c: Condition) => (
                  <MenuItem key={c.value} value={c.value}>
                    {c.label}
                  </MenuItem>
                ))}
              </Select>
            </SelectWrapper>
            <CheckboxGroup className={classes.checkBoxContainer}>
              <CheckboxLabel
                control={
                  <Checkbox
                    size="small"
                    checked={checkedFilters.length === columnValues.length}
                    indeterminate={checkedFilters.length > 0 && checkedFilters.length < columnValues.length}
                    onChange={handleCheckboxSelectAllChange}
                    name="select all"
                    color={checkedFilters.length === columnValues.length ? 'primary' : 'secondary'}
                  />
                }
                label={<Typography variant="body2">{selectAllLabel}</Typography>}
              />
              {columnValues.map(({ label, value }) => (
                <CheckboxLabel
                  key={value}
                  control={
                    <Checkbox
                      size="small"
                      checked={checkedFilters.includes(value)}
                      color="primary"
                      onChange={handleCheckboxChange}
                      value={value}
                    />
                  }
                  label={<Typography variant="body2">{label}</Typography>}
                />
              ))}
            </CheckboxGroup>
          </FormControl>
        </Grid>
      </>
    ),
    [
      classes.menuItem,
      classes.filterActionsContainer,
      classes.formControlContainer,
      classes.checkBoxContainer,
      filterTitle,
      conditionLabel,
      conditionValue,
      handleSelectChange,
      conditions,
      checkedFilters,
      columnValues,
      handleCheckboxSelectAllChange,
      selectAllLabel,
      handleCheckboxChange
    ]
  )
}

export default ConditionCheckboxFilter
