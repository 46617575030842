import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Button, DialogContent, Link, Typography, Grid, DialogActions } from '@barracuda-internal/bds-core'
import { Launch as LaunchIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'

import { config } from 'global/lib/config'

import { useFormatMessage } from 'global/lib/localization'

import useNewIncidentDialogLogic from 'sen/components/lib/dialogs/newIncidentDialog/useNewIncidentDialogLogic'

import styles from 'sen/components/lib/dialogs/newIncidentDialog/newIncidentDialogStyles'

const BASE_I18N_KEY = 'sen.app.new_incident_dialog.step_block_account'

export interface BlockAccountProps {
  onClose: () => void
  cleanupMessages: () => void
}

const BlockAccount: React.FC<BlockAccountProps> = ({ onClose, cleanupMessages }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const [newIncidentDialogLogicConfig] = useNewIncidentDialogLogic()

  return useMemo(
    () => (
      <Grid container direction="column">
        <DialogContent className={classes.dialogContent}>
          <Typography className={classes.text}>{formatMessage('subtitle')}</Typography>
          <ul>
            <li className={classes.listItem}>
              <Grid item>
                <span className={classes.text}>{formatMessage('list_disable')}&nbsp;&nbsp;</span>
                <span className={classes.grayText}>{formatMessage('recommended')}</span>
              </Grid>
              <Grid item className={classes.listItemDescription}>
                {formatMessage('list_disable_description', {
                  a: (txt: string) => (
                    <Link key="link" target="_blank" href={config.LINKS.OFFICE_SUPPORT_DISABLE_SIGN_IN}>
                      {txt}
                    </Link>
                  )
                })}
              </Grid>
            </li>
            <li className={classes.listItem}>
              <Grid item>
                <span className={classes.text}>{formatMessage('existing_sessions')}&nbsp;&nbsp;</span>
                <span className={classes.grayText}>{formatMessage('recommended')}</span>
              </Grid>
              <Grid item className={classes.listItemDescription}>
                {formatMessage('existing_sessions_description', {
                  a: (txt: string) => (
                    <Link key="link" target="_blank" href={config.LINKS.OFFICE_SUPPORT_KILL_SESSIONS}>
                      {txt}
                    </Link>
                  )
                })}
              </Grid>
            </li>
            <li className={classes.listItem}>
              <Grid item>
                <span className={classes.text}>{formatMessage('reset_password')}&nbsp;&nbsp;</span>
                <span className={classes.redText}>{formatMessage('critical')}</span>
              </Grid>
              <Grid item className={classes.listItemDescription}>
                {formatMessage('reset_password_description', {
                  a: (txt: string) => (
                    <Link key="link" target="_blank" href={config.LINKS.OFFICE_SUPPORT_RESET_PASSWORD}>
                      {txt}
                    </Link>
                  )
                })}
              </Grid>
            </li>
          </ul>
          <Grid item xs={12}>
            <Button
              className={classes.signInButton}
              color="primary"
              variant="contained"
              onClick={() => {
                newIncidentDialogLogicConfig.onOpenOfficeAdminPortal()
              }}
            >
              {formatMessage('sign_in')}
              <LaunchIcon />
            </Button>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="text"
            onClick={() => {
              onClose()
              newIncidentDialogLogicConfig.onResetCompromisedAccount()
            }}
          >
            &nbsp;&nbsp;{formatMessage('close')}&nbsp;&nbsp;
          </Button>
          <Button color="primary" variant="contained" onClick={cleanupMessages}>
            &nbsp;&nbsp;{formatMessage('clean_up')}&nbsp;&nbsp;
          </Button>
        </DialogActions>
      </Grid>
    ),
    [classes, formatMessage, newIncidentDialogLogicConfig, cleanupMessages, onClose]
  )
}

BlockAccount.propTypes = {
  onClose: PropTypes.func.isRequired,
  cleanupMessages: PropTypes.any
}

BlockAccount.defaultProps = {
  cleanupMessages: undefined
}
export default BlockAccount
