import { useEffect, useMemo, useState, useCallback, useRef, RefObject } from 'react'

import { process } from '@progress/kendo-data-query'

import { isPending, isSuccess, getErrorMessage } from 'global/redux/toolkit/api'
import {
  DMARC_SENDER_APPROVAL_STATUSES,
  DmarcSenderApprovalStatuses,
  DmarcSource,
  CompanyDomain,
  DmarcSourceTypes,
  DmarcSourceVolumes
} from 'global/types/api/dmarc'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { BDSGridPagerConfig } from 'global/types/dataTables/dataTables'
import { ColumnsConfig } from 'global/types/dataTables/columnsConfigType'
import { SearchFieldProps, useSearchFieldLogic } from 'global/components/lib/searchField/SearchField'
import useDialogLogic, { UseDialogLogic } from 'global/lib/dialogs/useDialogLogic'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import {
  getDomainTotals,
  getDmarcHighVolumeSources,
  getDmarcLowVolumeSources,
  resetDmarcHighVolumeSources,
  resetDmarcLowVolumeSources,
  setApprovalStatusForSender,
  setApprovalStatusForVolumeGroup,
  resetApprovalStatuses
} from 'sen/redux/features/dmarc/dmarcSlice'
import {
  update as updateDmarcHighVolumeSourcesTable,
  reset as resetDmarcHighVolumeSourcesTable
} from 'sen/redux/features/dataTables/dmarcSources/dmarcHighVolumeSources'
import {
  update as updateDmarcLowVolumeSourcesTable,
  reset as resetDmarcLowVolumeSourcesTable
} from 'sen/redux/features/dataTables/dmarcSources/dmarcLowVolumeSources'

export interface TableConfig {
  isLoaded: boolean
  inProgress: boolean
  tableData: {
    total: number
    data: DmarcSource[]
  }
  pageConfig: BDSGridPagerConfig
  columns: { [key: string]: string }
  columnsConfig: { [key: string]: ColumnsConfig }
  isFlexibleTable: boolean
  markedSources: number
}

export interface UseUnknownTabLogicProps {
  domain: CompanyDomain
}

export interface LogicConfig {
  searchFieldConfig: SearchFieldProps
  isMarkInProgress: boolean
  dmarcSourcesTableRef: RefObject<any>
  dmarcHighVolumeSourcesTableConfig: TableConfig
  dmarcLowVolumeSourcesTableConfig: TableConfig
  investigateDialogConfig: UseDialogLogic & {
    source: DmarcSource | undefined
  }
  error: string | undefined
}

export interface EventHandlers {
  onVisitSource: (sourceWebsite: DmarcSource['product']['website']) => void
  onOpenInvestigateDialog: (source: DmarcSource) => void
  onMarkAsRejected: (source: DmarcSource, type: DmarcSourceTypes) => void
  onMarkAsApproved: (source: DmarcSource, type: DmarcSourceTypes) => void
  onMarkAllAsRejected: () => void
  onUndo: (source: DmarcSource, type: DmarcSourceTypes) => void
}

export const SEARCH_FIELD_ID = 'dmarc-review-tables-search'

export default function useUnknownTabLogic({ domain }: UseUnknownTabLogicProps): [LogicConfig, EventHandlers] {
  const {
    dmarcHighVolumeSources,
    isDmarcHighVolumeSourcesLoading,
    dmarcHighVolumeSourcesError,
    dmarcHighVolumeSourcesLoadedOffsets,
    dmarcHighVolumeSourcesTable,
    dmarcLowVolumeSources,
    isDmarcLowVolumeSourcesLoading,
    dmarcLowVolumeSourcesError,
    dmarcLowVolumeSourcesLoadedOffsets,
    dmarcLowVolumeSourcesTable,
    isMarkInProgress,
    markError,
    isMarkSuccess
  } = useAppSelector(_stores => ({
    dmarcHighVolumeSources: _stores.dmarc.dmarcHighVolumeSources,
    isDmarcHighVolumeSourcesLoading: isPending(_stores.dmarc.getDmarcHighVolumeSourcesApiStatus),
    dmarcHighVolumeSourcesError: getErrorMessage(_stores.dmarc.getDmarcHighVolumeSourcesApiStatus),
    dmarcHighVolumeSourcesLoadedOffsets: _stores.dmarc.loadedDmarcHighVolumeSourcesOffsets,
    dmarcHighVolumeSourcesTable: _stores.dataTables.dmarcSources.highVolume,
    dmarcLowVolumeSources: _stores.dmarc.dmarcLowVolumeSources,
    isDmarcLowVolumeSourcesLoading: isPending(_stores.dmarc.getDmarcLowVolumeSourcesApiStatus),
    dmarcLowVolumeSourcesError: getErrorMessage(_stores.dmarc.getDmarcLowVolumeSourcesApiStatus),
    dmarcLowVolumeSourcesLoadedOffsets: _stores.dmarc.loadedDmarcLowVolumeSourcesOffsets,
    dmarcLowVolumeSourcesTable: _stores.dataTables.dmarcSources.lowVolume,
    isMarkInProgress:
      isPending(_stores.dmarc.setApprovalStatusForSenderApiStatus) ||
      isPending(_stores.dmarc.setApprovalStatusForVolumeGroupApiStatus),
    isMarkSuccess:
      isSuccess(_stores.dmarc.setApprovalStatusForSenderApiStatus) ||
      isSuccess(_stores.dmarc.setApprovalStatusForVolumeGroupApiStatus),
    markError:
      getErrorMessage(_stores.dmarc.setApprovalStatusForSenderApiStatus) ||
      getErrorMessage(_stores.dmarc.setApprovalStatusForVolumeGroupApiStatus)
  }))
  const dispatch = useAppDispatch()
  const [searchString, setSearchString] = useState<string>('')
  const [markedHighSources, setMarkedHighSources] = useState<number>(0)
  const [markedLowSources, setMarkedLowSources] = useState<number>(0)
  const [marked, setMarked] = useState<{ type: DmarcSourceTypes; status: DmarcSenderApprovalStatuses } | undefined>()
  const [isInvestigateDmarcDialogOpened, toggleInvestigateDmarcDialog] = useDialogLogic()
  const [selectedSource, setSelectedSource] = useState<DmarcSource | undefined>()
  const dmarcSourcesTableRef = useRef(null)

  function setCustomTitleWidth() {
    const firstColumnWidth = document.getElementsByTagName('th')?.[0]?.getBoundingClientRect()?.width
    const customFirstHeaderTitle: any = document.querySelectorAll('[data-first-custom-header="true"]')?.[0]

    if (firstColumnWidth && customFirstHeaderTitle) {
      customFirstHeaderTitle.style.width = `${firstColumnWidth}px`
    }
  }

  // init
  useEffect(() => {
    dispatch(getDmarcHighVolumeSources({ domain: domain.name, status: DMARC_SENDER_APPROVAL_STATUSES.unknown }))
    dispatch(getDmarcLowVolumeSources({ domain: domain.name, status: DMARC_SENDER_APPROVAL_STATUSES.unknown }))
    window.addEventListener('resize', setCustomTitleWidth)

    return () => {
      dispatch(resetDmarcHighVolumeSourcesTable())
      dispatch(resetDmarcLowVolumeSourcesTable())

      dispatch(resetDmarcHighVolumeSources())
      dispatch(resetDmarcLowVolumeSources())

      dispatch(resetApprovalStatuses())

      window.removeEventListener('resize', setCustomTitleWidth)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCustomTitleWidth()
  }, [dmarcHighVolumeSources])

  // dmarc is successfully marked
  useEffect(() => {
    if (isMarkSuccess && marked) {
      dispatch(getDomainTotals(domain.name))

      if (marked.type === DmarcSourceTypes.high) {
        setMarkedHighSources(
          marked.status === DMARC_SENDER_APPROVAL_STATUSES.unknown ? markedHighSources - 1 : markedHighSources + 1
        )
      } else if (marked.type === DmarcSourceTypes.low) {
        setMarkedLowSources(
          marked.status === DMARC_SENDER_APPROVAL_STATUSES.unknown ? markedLowSources - 1 : markedLowSources + 1
        )
      }

      setMarked(undefined)
    }
  }, [dispatch, isMarkSuccess, domain, markedHighSources, markedLowSources, marked])

  const [debouncedOnChange, validateSearchString] = useSearchFieldLogic(
    (search: string) => {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.DF_DOMAINS_DMARC_REVIEW_UNKNOWN_TAB_SEARCH, { domain })

      dispatch(updateDmarcHighVolumeSourcesTable({ search: search.trim(), skip: 0 }))
      dispatch(updateDmarcLowVolumeSourcesTable({ search: search.trim(), skip: 0 }))
      dispatch(resetDmarcHighVolumeSources())
      dispatch(resetDmarcLowVolumeSources())
      dispatch(getDmarcHighVolumeSources({ domain: domain.name, status: DMARC_SENDER_APPROVAL_STATUSES.unknown }))
      dispatch(getDmarcLowVolumeSources({ domain: domain.name, status: DMARC_SENDER_APPROVAL_STATUSES.unknown }))
      setMarkedLowSources(0)
      setMarkedHighSources(0)

      setSearchString(search.trim())
    },
    isDmarcLowVolumeSourcesLoading || isDmarcHighVolumeSourcesLoading,
    SEARCH_FIELD_ID
  )

  const searchFieldConfig: SearchFieldProps = useMemo(() => {
    return {
      id: SEARCH_FIELD_ID,
      value: searchString,
      onChange: (e: any) => {
        const validatedSearchString = validateSearchString(e.target.value)

        debouncedOnChange(validatedSearchString)
        setSearchString(validatedSearchString)
      },
      disabled: isDmarcLowVolumeSourcesLoading || isDmarcHighVolumeSourcesLoading || isMarkInProgress
    }
  }, [
    searchString,
    debouncedOnChange,
    validateSearchString,
    isDmarcHighVolumeSourcesLoading,
    isDmarcLowVolumeSourcesLoading,
    isMarkInProgress
  ])

  const dmarcHighVolumeSourcesTableData = useMemo(() => {
    const { skip, take } = dmarcHighVolumeSourcesTable

    const { data } = process(
      (dmarcHighVolumeSources?.results || []).map((report: DmarcSource) => ({
        ...(report && {
          ...report
        })
      })),
      { skip, take }
    )

    return {
      data: data.filter(report => report.host),
      total: dmarcHighVolumeSources?.count || 0
    }
  }, [dmarcHighVolumeSources, dmarcHighVolumeSourcesTable])

  const dmarcHighVolumeSourcesPageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = dmarcHighVolumeSourcesTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: dmarcHighVolumeSourcesTableData.total,
      onPageChange: (e: any) => {
        dispatch(updateDmarcHighVolumeSourcesTable(e.page))
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.DF_DOMAINS_DMARC_REVIEW_UNKNOWN_TAB_PAGINATE, { domain })

        if (!dmarcHighVolumeSourcesLoadedOffsets?.includes(e.page.skip)) {
          dispatch(getDmarcHighVolumeSources({ domain: domain.name, status: DMARC_SENDER_APPROVAL_STATUSES.unknown }))
        }
      }
    }
  }, [
    dmarcHighVolumeSourcesTable,
    dmarcHighVolumeSourcesTableData,
    dispatch,
    dmarcHighVolumeSourcesLoadedOffsets,
    domain
  ])

  const dmarcLowVolumeSourcesTableData = useMemo(() => {
    const { skip, take } = dmarcLowVolumeSourcesTable

    const { data } = process(
      (dmarcLowVolumeSources?.results || []).map((report: DmarcSource) => ({
        ...(report && {
          ...report
        })
      })),
      { skip, take }
    )

    return {
      data: data.filter(report => report.host),
      total: dmarcLowVolumeSources?.count || 0
    }
  }, [dmarcLowVolumeSources, dmarcLowVolumeSourcesTable])

  const dmarcLowVolumeSourcesPageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = dmarcLowVolumeSourcesTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: dmarcLowVolumeSourcesTableData.total,
      onPageChange: (e: any) => {
        dispatch(updateDmarcLowVolumeSourcesTable(e.page))
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.DF_DOMAINS_DMARC_REVIEW_UNKNOWN_TAB_PAGINATE, {
          domain
        })

        if (!dmarcLowVolumeSourcesLoadedOffsets?.includes(e.page.skip)) {
          dispatch(getDmarcLowVolumeSources({ domain: domain.name, status: DMARC_SENDER_APPROVAL_STATUSES.unknown }))
        }
      }
    }
  }, [dmarcLowVolumeSourcesTable, dmarcLowVolumeSourcesTableData, dispatch, dmarcLowVolumeSourcesLoadedOffsets, domain])

  const onVisitSource: EventHandlers['onVisitSource'] = useCallback(
    sourceWebsite => {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.DF_DOMAINS_DMARC_REVIEW_UNKNOWN_TAB_VISIT_SOURCE, {
        domain,
        sourceWebsite
      })
      window.open(sourceWebsite, '_blank')
    },
    [domain]
  )

  const onOpenInvestigateDialog: EventHandlers['onOpenInvestigateDialog'] = useCallback(
    source => {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.DF_DOMAINS_DMARC_REVIEW_UNKNOWN_TAB_OPEN_INVESTIGATE_DIALOG, {
        domain,
        source: source.host
      })

      setSelectedSource(source)
      toggleInvestigateDmarcDialog()
    },
    [domain, toggleInvestigateDmarcDialog]
  )

  const onCloseInvestigateDialog: LogicConfig['investigateDialogConfig']['toggle'] = useCallback(() => {
    setSelectedSource(undefined)
    toggleInvestigateDmarcDialog()
  }, [toggleInvestigateDmarcDialog])

  const handleMarkFlow = useCallback(
    (event: string, source: DmarcSource, type: DmarcSourceTypes, status: DmarcSenderApprovalStatuses) => {
      analyticsLib.trackAppEvent(event, {
        domain,
        source: source.host
      })

      dispatch(
        setApprovalStatusForSender({
          sender: source.host,
          domain: domain.name,
          status,
          sourceProperty: type
        })
      )

      setMarked({ type, status })
    },
    [domain, dispatch]
  )

  const onMarkAsRejected: EventHandlers['onMarkAsRejected'] = useCallback(
    (source, type) => {
      handleMarkFlow(
        analyticsLib.EVENTS.DF_DOMAINS_DMARC_REVIEW_UNKNOWN_TAB_MARK_AS_REJECTED,
        source,
        type,
        DMARC_SENDER_APPROVAL_STATUSES.rejected
      )
    },
    [handleMarkFlow]
  )

  const onMarkAsApproved: EventHandlers['onMarkAsApproved'] = useCallback(
    (source, type) => {
      handleMarkFlow(
        analyticsLib.EVENTS.DF_DOMAINS_DMARC_REVIEW_UNKNOWN_TAB_MARK_AS_APPROVED,
        source,
        type,
        DMARC_SENDER_APPROVAL_STATUSES.approved
      )
    },
    [handleMarkFlow]
  )

  const onMarkAllAsRejected: EventHandlers['onMarkAllAsRejected'] = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.DF_DOMAINS_DMARC_REVIEW_UNKNOWN_TAB_MARK_ALL_AS_REJECTED, { domain })

    dispatch(
      setApprovalStatusForVolumeGroup({
        sourceProperty: DmarcSourceTypes.low,
        volume: DmarcSourceVolumes.lowVolume,
        domain: domain.name,
        status: DMARC_SENDER_APPROVAL_STATUSES.rejected
      })
    )

    setMarkedLowSources(dmarcLowVolumeSourcesTableData.total)
  }, [dmarcLowVolumeSourcesTableData, dispatch, domain])

  const onUndo: EventHandlers['onUndo'] = useCallback(
    (source, type) => {
      handleMarkFlow(
        analyticsLib.EVENTS.DF_DOMAINS_DMARC_REVIEW_UNKNOWN_TAB_UNDO,
        source,
        type,
        DMARC_SENDER_APPROVAL_STATUSES.unknown
      )
    },
    [handleMarkFlow]
  )

  return useMemo(() => {
    return [
      {
        searchFieldConfig,
        isMarkInProgress,
        dmarcSourcesTableRef,
        dmarcHighVolumeSourcesTableConfig: {
          isLoaded: !!dmarcHighVolumeSources,
          inProgress: isDmarcHighVolumeSourcesLoading,
          tableData: dmarcHighVolumeSourcesTableData,
          pageConfig: dmarcHighVolumeSourcesPageConfig,
          columns: dmarcHighVolumeSourcesTable.GRID_COLUMNS,
          columnsConfig: dmarcHighVolumeSourcesTable.columnsConfig,
          isFlexibleTable: dmarcHighVolumeSourcesTableData.total < dmarcHighVolumeSourcesTable.ITEMS_PER_PAGE,
          markedSources: markedHighSources
        },
        dmarcLowVolumeSourcesTableConfig: {
          isLoaded: !!dmarcLowVolumeSources,
          inProgress: isDmarcLowVolumeSourcesLoading,
          tableData: dmarcLowVolumeSourcesTableData,
          pageConfig: dmarcLowVolumeSourcesPageConfig,
          columns: dmarcLowVolumeSourcesTable.GRID_COLUMNS,
          columnsConfig: dmarcLowVolumeSourcesTable.columnsConfig,
          isFlexibleTable: dmarcLowVolumeSourcesTableData.total < dmarcLowVolumeSourcesTable.ITEMS_PER_PAGE,
          markedSources: markedLowSources
        },
        investigateDialogConfig: {
          isOpen: isInvestigateDmarcDialogOpened,
          toggle: onCloseInvestigateDialog,
          source: selectedSource
        },
        error: dmarcHighVolumeSourcesError || dmarcLowVolumeSourcesError || markError
      },
      {
        onVisitSource,
        onOpenInvestigateDialog,
        onMarkAsRejected,
        onMarkAsApproved,
        onMarkAllAsRejected,
        onUndo
      }
    ]
  }, [
    searchFieldConfig,
    isMarkInProgress,
    markError,
    dmarcHighVolumeSourcesError,
    dmarcLowVolumeSourcesError,
    dmarcHighVolumeSources,
    isDmarcHighVolumeSourcesLoading,
    dmarcHighVolumeSourcesTableData,
    dmarcHighVolumeSourcesPageConfig,
    dmarcHighVolumeSourcesTable,
    dmarcLowVolumeSources,
    isDmarcLowVolumeSourcesLoading,
    dmarcLowVolumeSourcesTableData,
    dmarcLowVolumeSourcesPageConfig,
    dmarcLowVolumeSourcesTable,
    onVisitSource,
    onOpenInvestigateDialog,
    onMarkAsRejected,
    onMarkAsApproved,
    onUndo,
    dmarcSourcesTableRef,
    markedHighSources,
    markedLowSources,
    onMarkAllAsRejected,
    isInvestigateDmarcDialogOpened,
    onCloseInvestigateDialog,
    selectedSource
  ])
}
