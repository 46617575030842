import { isEqual } from 'lodash'
import { Middleware } from 'redux'
import { updateAccessToken } from 'ets/redux/features/user/userSlice'
import { RootState } from 'global/redux/toolkit/store'

// the problem why this middleware exist:
//  - we store the accessToken in multiple places across the store:
//    - top-level accessToken property
//    - deeply nested accessToken property under `user.data.accounts.index.accessTokens`
//  - most of the actions updates only the `top-level accessToken`, thus the nested accessToken becomes outdated very easily
// idea: update the nested accessToken after the top-level changed
const accessTokenMiddleware: Middleware<{}, RootState> = store => next => action => {
  if (!action.type.includes('ACCESS_TOKEN/')) {
    return next(action)
  }

  const state = store.getState()
  if (!state?.accessToken?.accessToken) {
    // if there is no accessToken, then skip the logic
    return next(action)
  }

  const prevAccessToken = state.accessToken.accessToken

  // let the state update happen
  const processedAction = next(action)

  const nextState = store.getState()
  if (!nextState?.accessToken?.accessToken) {
    return processedAction
  }

  const nextAccessToken = nextState.accessToken.accessToken

  if (!isEqual(prevAccessToken, nextAccessToken)) {
    store.dispatch(updateAccessToken(nextAccessToken))
  }

  return processedAction
}

export default accessTokenMiddleware
