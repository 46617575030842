import React, { useMemo } from 'react'

import { Tooltip, Typography, Grid, Paper } from '@barracuda-internal/bds-core'
import AttachmentIcon from '@barracuda-internal/bds-core/dist/Icons/Email/Attachment'
import styles from 'global/components/lib/dialogs/emailDetailsDialog/emailDetailsDialogStyles'
import { formatNumber } from 'global/lib/number'
import { config } from 'global/lib/config'
import { Attachment } from 'global/types/api/attackType'
import truncateMiddle from 'global/lib/truncateMiddle/truncateMiddle'

export interface EmailAttachmentsProps {
  attachments: Attachment[]
  shouldHighlightAttachment: (attachmentName: string) => boolean
}
export const EmailAttachments: React.FC<EmailAttachmentsProps> = ({ attachments, shouldHighlightAttachment }) => {
  const classes = styles()
  return useMemo(
    () => (
      <Grid className={classes.emailHeaderWrapper} container spacing={1} direction="column">
        <Grid item>
          <Typography>Attachment {attachments.length}</Typography>
        </Grid>

        <Grid container item spacing={2} alignItems="center" justifyContent="flex-start" direction="row">
          {attachments.map((attachment: any) => {
            return (
              <Grid item key={attachment.name} xs={12} sm={12} md={3} lg={3}>
                <Paper
                  className={`${classes.attachmentContainer} ${
                    shouldHighlightAttachment(attachment.name) ? classes.highlightAttachmentContainer : undefined
                  }`}
                  elevation={0}
                >
                  <Grid container item wrap="nowrap" spacing={1}>
                    <Grid container item xs={2} alignItems="center" justifyContent="center">
                      <AttachmentIcon />
                    </Grid>
                    <Grid
                      className={
                        shouldHighlightAttachment(attachment.name) ? classes.highlightAttachmentContent : undefined
                      }
                      item
                      xs={10}
                    >
                      <Tooltip title={attachment.name} placement="top">
                        <Typography className={classes.attachmentName} variant="caption">
                          {truncateMiddle(attachment.name, 22)}
                        </Typography>
                      </Tooltip>
                      <Typography variant="caption" className={classes.attachmentInfo}>
                        {formatNumber(attachment.size, config.NUMBER.TWO_DIGITS_FORMAT)}
                        {attachment.isInline && ', Inline'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    ),
    [
      attachments,
      classes.attachmentName,
      classes.attachmentContainer,
      classes.attachmentInfo,
      classes.emailHeaderWrapper,
      classes.highlightAttachmentContainer,
      classes.highlightAttachmentContent,
      shouldHighlightAttachment
    ]
  )
}

export default EmailAttachments
