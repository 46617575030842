import React, { useMemo } from 'react'
import ReactPlayer from 'react-player'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Link,
  Typography
} from '@barracuda-internal/bds-core'
import { SortDescending as ExpandMoreIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'

import { useFormatMessage } from 'global/lib/localization'

import styles from 'global/components/features/signinSignup/signinSignupStylesNew'
import Loading from 'global/components/lib/loading/Loading'

import IMAGES from 'ets/configs/theme/images.config'
import SigninSignupLayout from 'ets/components/pages/signinSignup/layout/SigninSignupV3Layout'
import useSigninSignupLogic, { ACCORDION_PANELS } from 'ets/components/pages/signinSignup/useSigninSignupLogic'

const BASE_I18N_KEY = 'ets.app.signin_signup'

const FREE_SCANS = 5

const SigninSignup: React.FC = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [signinSignupLogic] = useSigninSignupLogic()

  return useMemo(() => {
    const {
      isLoading,
      onSignin,
      onSignup,
      demoVideoPath,
      onTrackDemoVideoStart,
      onTrackDemoVideoEnd,
      requestDemoPath,
      onTogglePermissionDialog,
      isPermissionDialogVisible,
      error,
      sendSpeakToExpertTrackingEvent,
      expandedAccordionPanel,
      setExpandedAccordionPanel,
      isCobranded
    } = signinSignupLogic

    return (
      <>
        {isLoading && <Loading data-field="loader" />}
        {!isLoading && (
          <SigninSignupLayout error={error}>
            <SigninSignupLayout.Wrapper>
              <SigninSignupLayout.Content>
                <SigninSignupLayout.ContentColumn className="form-area" data-field="form-area">
                  <span className={classes.formAreaWrapper}>
                    <SigninSignupLayout.Header>
                      <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item className={classes.logoWrapper}>
                          <img className={classes.barracudaLogo} alt="" src={IMAGES.barracudaEtsForDark} />
                        </Grid>

                        {isCobranded && (
                          <Grid item className={classes.logoWrapper}>
                            <img className={classes.barracudaLogo} alt="" src={IMAGES.awsMarketplace} />
                          </Grid>
                        )}
                      </Grid>

                      <Typography className={classes.headingTitle} align="left" data-field="title">
                        {formatMessage('info.title_new')}
                      </Typography>
                      <Typography className={classes.headingSubTitle} align="left" data-field="subtitle">
                        {formatMessage('info.subtitle', { freeScans: FREE_SCANS })}
                      </Typography>
                      <Typography className={classes.headingText} align="left" data-field="headertext">
                        {formatMessage('info.headertext_new')}
                      </Typography>
                    </SigninSignupLayout.Header>
                    <>
                      <SigninSignupLayout.Connect
                        appName={formatMessage('app_name')}
                        permissionDialogtitle={formatMessage('form.permission_dialog_title')}
                        onTogglePermissionDialog={onTogglePermissionDialog}
                        isPermissionDialogVisible={isPermissionDialogVisible}
                        requestDemoPath={requestDemoPath}
                        sendSpeakToExpertTrackingEvent={sendSpeakToExpertTrackingEvent as any}
                        onSignup={onSignup}
                      />
                      <Typography align="center">
                        {formatMessage('form.already_have_account_2', {
                          link: (txt: any) => (
                            <data key={txt} onClick={onSignin} role="presentation" className={classes.accountBlueText}>
                              {txt}
                            </data>
                          ),
                          b: (txt: any) => (
                            <data key={txt} role="presentation" className={classes.strongText}>
                              {txt}
                            </data>
                          )
                        })}
                      </Typography>
                    </>
                  </span>
                </SigninSignupLayout.ContentColumn>
                <SigninSignupLayout.ContentColumn className="info-area" data-field="info-area">
                  <Box className={classes.infoAreaBG}>
                    <Box className={classes.accordionWrapper}>
                      <Accordion
                        elevation={0}
                        square
                        className={classes.infoAccordionItem}
                        expanded={expandedAccordionPanel === ACCORDION_PANELS.PANEL_1}
                        onChange={setExpandedAccordionPanel(ACCORDION_PANELS.PANEL_1)}
                      >
                        <AccordionSummary
                          className={classes.infoAccordionItemSummary}
                          expandIcon={<ExpandMoreIcon className={classes.whiteIcon} />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography
                            className={classes.infoAccordionItemSummaryTitle}
                            align="left"
                            data-field="accordion-title"
                          >
                            <span className={classes.infoAccordionItemSummaryTitleLarge}>
                              {formatMessage('info.accordion_1_title')}
                            </span>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.infoAccordionItemDetails}>
                          <Grid
                            container
                            className={classes.infoContent}
                            style={{ flexWrap: 'wrap', alignItems: 'center' }}
                            spacing={2}
                          >
                            <Grid item xs={12} sm={7} md={8} lg={9}>
                              <Typography className={classes.infoContentText} data-field="info-intro">
                                {formatMessage('info.accordion_1_intro')}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={5} md={4} lg={3}>
                              <Link
                                target="_blank"
                                href={requestDemoPath}
                                data-field="hyperlink"
                                onClick={sendSpeakToExpertTrackingEvent}
                                underline="none"
                              >
                                <Typography
                                  component="div"
                                  className={classes.speakToExpertText}
                                  align="center"
                                  data-field="speak-to-expert-text"
                                >
                                  {formatMessage('info.speak_to_expert')}
                                </Typography>
                              </Link>
                            </Grid>
                            <Grid item xs={12}>
                              <div className={classes.playerWrapper}>
                                <ReactPlayer
                                  className={classes.reactPlayer}
                                  height="100%"
                                  url={demoVideoPath}
                                  width="100%"
                                  onStart={onTrackDemoVideoStart}
                                  onEnded={onTrackDemoVideoEnd}
                                  data-field="react-player"
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        elevation={0}
                        square
                        className={classes.infoAccordionItem}
                        expanded={expandedAccordionPanel === ACCORDION_PANELS.PANEL_2}
                        onChange={setExpandedAccordionPanel(ACCORDION_PANELS.PANEL_2)}
                      >
                        <AccordionSummary
                          className={classes.infoAccordionItemSummary}
                          expandIcon={<ExpandMoreIcon className={classes.whiteIcon} />}
                          aria-controls="panel2bh-content"
                          id="panel2bh-header"
                        >
                          <Typography
                            className={classes.infoAccordionItemSummaryTitle}
                            align="left"
                            data-field="accordion-title-2"
                          >
                            {formatMessage('info.accordion_2_title')}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.infoAccordionItemDetails}>
                          <Grid container className={classes.infoContent} spacing={3}>
                            <Grid item>
                              <Typography className={classes.infoContentText} data-field="info-intro-2">
                                {formatMessage('info.accordion_2_intro')}
                              </Typography>
                              <ul className={classes.infoContentList}>
                                {Array.from(Array(7), (_, i) => i + 1).map(index => (
                                  <li className={classes.infoContentListItem} key={index}>
                                    <Typography className={classes.infoContentText} data-field="info-list-item">
                                      {formatMessage(`info.accordion_2_list_${index}`)}
                                    </Typography>
                                  </li>
                                ))}
                              </ul>
                            </Grid>
                            <Grid item>
                              <img
                                className={classes.msPermissionsImg}
                                alt=""
                                src={IMAGES.msPermissions}
                                data-field="permission-img"
                              />
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        elevation={0}
                        square
                        className={classes.infoAccordionItem}
                        expanded={expandedAccordionPanel === ACCORDION_PANELS.PANEL_3}
                        onChange={setExpandedAccordionPanel(ACCORDION_PANELS.PANEL_3)}
                      >
                        <AccordionSummary
                          className={classes.infoAccordionItemSummary}
                          expandIcon={<ExpandMoreIcon className={classes.whiteIcon} />}
                          aria-controls="panel3bh-content"
                          id="panel3bh-header"
                        >
                          <Typography
                            className={classes.infoAccordionItemSummaryTitle}
                            align="left"
                            data-field="accordion-title-3"
                          >
                            {formatMessage('info.accordion_3_title')}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.infoAccordionItemDetails}>
                          <Grid container className={classes.infoContent} spacing={3}>
                            <Grid item>
                              <Typography className={classes.infoContentText} data-field="info-intro-3">
                                {formatMessage('info.accordion_3_intro')}
                              </Typography>
                              <ul className={classes.infoContentList}>
                                {Array.from(Array(9), (_, i) => i + 1).map(index => (
                                  <li className={classes.infoContentListItem} key={index}>
                                    <Typography className={classes.infoContentText} data-field="info-list-item-2">
                                      {formatMessage(`info.accordion_3_list_${index}`)}
                                    </Typography>
                                  </li>
                                ))}
                              </ul>
                            </Grid>
                            <Grid item>
                              <img
                                className={classes.msPermissionsImg}
                                alt=""
                                src={IMAGES.msPermissions}
                                data-field="permission-img-2"
                              />
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        elevation={0}
                        square
                        className={classes.infoAccordionItem}
                        expanded={expandedAccordionPanel === ACCORDION_PANELS.PANEL_4}
                        onChange={setExpandedAccordionPanel(ACCORDION_PANELS.PANEL_4)}
                      >
                        <AccordionSummary
                          className={classes.infoAccordionItemSummary}
                          expandIcon={<ExpandMoreIcon className={classes.whiteIcon} />}
                          aria-controls="panel4bh-content"
                          id="panel4bh-header"
                        >
                          <Typography
                            className={classes.infoAccordionItemSummaryTitle}
                            align="left"
                            data-field="accordion-title-4"
                          >
                            {formatMessage('info.accordion_4_title')}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.infoAccordionItemDetails}>
                          <Grid container className={classes.infoContent} spacing={3}>
                            <Grid item>
                              <Typography className={classes.infoContentText} data-field="info-intro-4">
                                {formatMessage('info.accordion_4_intro')}
                              </Typography>
                              <ul className={classes.infoContentList}>
                                {Array.from(Array(1), (_, i) => i + 1).map(index => (
                                  <li className={classes.infoContentListItem} key={index}>
                                    <Typography className={classes.infoContentText} data-field="info-list-item-3">
                                      {formatMessage(`info.accordion_4_list_${index}`)}
                                    </Typography>
                                  </li>
                                ))}
                              </ul>
                            </Grid>
                            <Grid item>
                              <img
                                className={classes.msPermissionsImg}
                                alt=""
                                src={IMAGES.msPermissions}
                                data-field="permission-img-3"
                              />
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  </Box>
                </SigninSignupLayout.ContentColumn>
              </SigninSignupLayout.Content>
            </SigninSignupLayout.Wrapper>
          </SigninSignupLayout>
        )}
      </>
    )
  }, [classes, formatMessage, signinSignupLogic])
}

export default SigninSignup
