import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Button, DialogActions } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'global/lib/localization'

import styles from 'sen/components/lib/dialogs/newIncidentDialog/newIncidentDialogStyles'

const BASE_I18N_KEY = 'sen.app.new_incident_dialog'

export interface ButtonStatus {
  disabled?: boolean | false
  disableNext?: boolean | false
  cancel?: string | false
  onPrev?: string | false
  onNext?: string | false
  onNextStep: OnDispatch
  onPrevStep: OnDispatch
}

export type OnDispatch = (activeStep: number) => void

export interface BaseDialogActionsProps {
  activeStep: number
  buttonStatus: ButtonStatus
  onClose: (activeStep?: number) => void
}

const BaseDialogActions: React.FC<BaseDialogActionsProps> = ({ activeStep, buttonStatus, onClose }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  return useMemo(
    () => (
      <DialogActions className={classes.dialogButtons}>
        {buttonStatus && buttonStatus.cancel && (
          <Button onClick={() => onClose(activeStep)} color="primary" variant="text">
            {formatMessage(`buttons.${buttonStatus.cancel}`)}
          </Button>
        )}
        {buttonStatus && buttonStatus.onPrev && (
          <Button
            onClick={() => {
              buttonStatus.onPrevStep(activeStep)
            }}
            disabled={buttonStatus.disabled}
            variant="text"
            color="primary"
          >
            {formatMessage(`buttons.${buttonStatus.onPrev}`)}
          </Button>
        )}
        {buttonStatus && buttonStatus.onNext && (
          <Button
            onClick={() => {
              buttonStatus.onNextStep(activeStep)
            }}
            color="primary"
            variant="contained"
            disabled={buttonStatus.disabled || buttonStatus.disableNext}
          >
            {formatMessage(`buttons.${buttonStatus.onNext}`)}
          </Button>
        )}
      </DialogActions>
    ),
    [classes, formatMessage, activeStep, buttonStatus, onClose]
  )
}

BaseDialogActions.propTypes = {
  activeStep: PropTypes.number.isRequired,
  buttonStatus: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired
}

BaseDialogActions.defaultProps = {}

export default BaseDialogActions
