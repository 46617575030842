import { config } from 'global/lib/config'
import productLib from 'global/lib/product/product'
import { reduxStore } from 'global/lib/reduxStore'

import { setCurrentAccessToken } from 'global/redux/features/accessToken/accessTokenSlice'

export default async function validateAppForAccessToken(accessTokenId: string, cb?: any) {
  if (productLib.hasSentinelProduct(accessTokenId) || productLib.hasForensicsProduct(accessTokenId)) {
    let newHref = `${config.domains.sentinel}/report/${accessTokenId}/dashboard`

    if (config.isDev) {
      newHref = newHref.replace('3005', window.location.port)
    }

    window.location.replace(newHref)
  } else if (cb) {
    await reduxStore.dispatch(setCurrentAccessToken(accessTokenId))
    cb(true)
  }
}
