import { FONT as font } from 'global/configs/theme/defaults'

export default {
  root: {
    cursor: 'default'
  },
  h1: {
    fontSize: font.size.h1,
    fontWeight: font.weight.medium,
    lineHeight: font.lineheight.h1
  },
  h2: {
    fontSize: font.size.h2,
    fontWeight: font.weight.light,
    lineHeight: font.lineheight.h2
  },
  h3: {
    fontSize: font.size.h3,
    fontWeight: font.weight.regular,
    lineHeight: font.lineheight.h3
  },
  h4: {
    fontSize: font.size.h4,
    fontWeight: font.weight.regular,
    lineHeight: font.lineheight.h4
  },
  h5: {
    fontSize: font.size.h5,
    fontWeight: font.weight.regular,
    lineHeight: font.lineheight.h5
  },
  h6: {
    fontSize: font.size.h6,
    fontWeight: font.weight.medium,
    lineHeight: font.lineheight.h6
  },
  subtitle1: {
    fontSize: font.size.regular,
    fontWeight: font.weight.regular,
    lineHeight: font.lineheight.large
  },
  subtitle2: {
    fontSize: font.size.medium,
    fontWeight: font.weight.regular,
    lineHeight: font.lineheight.large
  },
  body1: {
    fontSize: font.size.regular,
    fontWeight: font.weight.regular,
    lineHeight: font.lineheight.large
  },
  body2: {
    fontSize: font.size.medium,
    fontWeight: font.weight.regular,
    lineHeight: font.lineheight.large
  },
  button: {
    fontSize: font.size.medium,
    fontWeight: font.weight.medium,
    lineHeight: font.lineheight.xlarge
  },
  caption: {
    fontSize: font.size.small,
    fontWeight: font.weight.regular,
    lineHeight: font.lineheight.regular
  },
  overline: {
    fontSize: font.size.xsmall,
    fontWeight: font.weight.medium,
    lineHeight: font.lineheight.regular
  }
}
