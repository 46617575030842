/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import {
  Typography,
  DataTable,
  DataTableColumn,
  Grid,
  Paper,
  Alert as AlertComponent
} from '@barracuda-internal/bds-core'
import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'

import { useFormatMessage, useErrorFormatMessage } from 'global/lib/localization'
import CircularProgress from 'global/components/lib/circularProgress/CircularProgress'
import Pager from 'global/components/lib/grid/pager/Pager'
import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import CellLabelType from 'global/components/lib/grid/cell/CellLabelType'
import IncidentTypeLabel, { StatusIds } from 'global/components/lib/incidentTypeLabel/IncidentTypeLabel'
import ExportToCsvButton from 'global/components/lib/exportToCsvButton/ExportToCsvButton'

import useRecipientsTableLogic, {
  ModifiedIncidentEmail
} from 'sen/components/pages/ato/content/tabs/incidents/recipientsTable/useRecipientsTableLogic'
import styles from 'sen/components/pages/ato/content/tabs/tabStyles'

const BASE_I18N_TABLE_KEY = 'sen.app.data_tables.internal_attacks'

export interface RecipientsTableProps {
  isInternal?: boolean
}

const RecipientsTable: React.FC<RecipientsTableProps> = ({ isInternal = false }) => {
  const [inProgress, tableConfig, error] = useRecipientsTableLogic(isInternal)
  const tableFormatMessage = useFormatMessage(BASE_I18N_TABLE_KEY)
  const formatMessageError = useErrorFormatMessage()
  const classes = styles()

  return useMemo(
    () => (
      <Grid container direction="column" data-field="recipients-grid">
        {error && (
          <AlertComponent
            className={classes.error}
            variant="filled"
            severity="error"
            data-field="recipients-table-alert"
          >
            {formatMessageError(error)}
          </AlertComponent>
        )}

        <Grid item data-field="recipients-grid-paper">
          <Paper className={classes.tableCard} elevation={0} data-field="incidents-paper">
            {!tableConfig.isLoaded && (
              <Grid container justifyContent="center" className={classes.tableCircularProgress}>
                <CircularProgress data-field="loader" />
              </Grid>
            )}

            {tableConfig.isLoaded && (
              <Grid container direction="column" className={classes.tableWrapper} data-field="recipients-table-wrapper">
                <Grid container justifyContent="flex-end">
                  <ExportToCsvButton {...tableConfig.exportToCsvConfig} data-field="export-csv-button" />
                </Grid>

                <Grid
                  className={`${tableConfig.isFlexibleTable ? classes.flexibleTableArea : classes.tableArea} ${
                    !tableConfig.tableData.total ? 'empty-table' : ''
                  }`}
                  data-table="recipients"
                  item
                  xs={12}
                  data-field="grid-table"
                >
                  {inProgress && <LinearProgress className={classes.indicator} data-field="linear-progress" />}
                  <DataTable
                    data-testid="recipients-table"
                    className={`${
                      tableConfig.tableData.total
                        ? tableConfig.isFlexibleTable
                          ? classes.flexibleDataTable
                          : classes.dataTable
                        : classes.emptyDataTable
                    } incidents-table`}
                    data={tableConfig.tableData}
                    {...tableConfig.pageConfig}
                    {...tableConfig.sortConfig}
                    pager={Pager}
                    data-field="data-table"
                  >
                    <DataTableNoRecords data-field="empty-table">
                      <Typography>{!tableConfig.tableData.total && tableFormatMessage('empty_table')}</Typography>
                    </DataTableNoRecords>
                    <DataTableColumn
                      field={tableConfig.columns.RECIPIENT}
                      {...tableConfig.columnsConfig[tableConfig.columns.RECIPIENT]}
                      title={tableFormatMessage(tableConfig.columns.RECIPIENT)}
                      cell={({ dataItem }: { dataItem: ModifiedIncidentEmail }) => (
                        <Cell className={classes.cell} data-field="table-column-recipient">
                          <CellText className={classes.boldCell} data-field="table-column-recipient-display-name">
                            {dataItem.displayName}
                          </CellText>
                          {dataItem.title && (
                            <>
                              <br />
                              <CellText className={classes.normalCell} data-field="table-column-recipient-title">
                                {dataItem.title}
                              </CellText>
                            </>
                          )}
                          {dataItem.email && dataItem.displayName !== dataItem.email && (
                            <>
                              <br />
                              <CellText className={classes.normalCell} data-field="table-column-recipient-email">
                                {dataItem.email}
                              </CellText>
                            </>
                          )}
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      field={tableConfig.columns.READ}
                      {...tableConfig.columnsConfig[tableConfig.columns.READ]}
                      title={tableFormatMessage(tableConfig.columns.READ)}
                      cell={({ dataItem }: { dataItem: ModifiedIncidentEmail }) => (
                        <Cell className={classes.cell} data-field="table-column-read">
                          <CellText className={classes.boldCell} data-field="table-column-read-text">
                            {tableFormatMessage(`statuses.${dataItem.isRead.toLowerCase()}`)}
                          </CellText>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      field={tableConfig.columns.STATUS}
                      {...tableConfig.columnsConfig[tableConfig.columns.STATUS]}
                      title={tableFormatMessage(tableConfig.columns.STATUS)}
                      cell={({ dataItem }: { dataItem: ModifiedIncidentEmail }) => (
                        <Cell className={classes.cell} data-field="table-column-status">
                          <CellLabelType>
                            <IncidentTypeLabel
                              status={dataItem.status as StatusIds}
                              isInternal={dataItem.isInternal}
                              data-field="table-column-status-label"
                            />
                          </CellLabelType>
                        </Cell>
                      )}
                    />
                  </DataTable>
                </Grid>
              </Grid>
            )}
          </Paper>
        </Grid>
      </Grid>
    ),
    [classes, inProgress, tableConfig, tableFormatMessage, formatMessageError, error]
  )
}

RecipientsTable.propTypes = {
  isInternal: PropTypes.bool
}
RecipientsTable.defaultProps = {
  isInternal: false
}

export default RecipientsTable
