import { createSlice } from '@reduxjs/toolkit'

import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'global/redux/toolkit/api'

import { refreshO365AccessToken } from 'global/redux/features/cloud/cloudApiThunks'

export interface CloudState {
  refreshO365AccessTokenApiStatus: ApiStatus
}

// initialState
export const INITIAL_STATE: CloudState = {
  refreshO365AccessTokenApiStatus: inIdle
}

/* eslint-disable no-param-reassign */
export const cloudSlice = createSlice({
  name: 'CLOUD',
  initialState: INITIAL_STATE,
  reducers: {
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(refreshO365AccessToken.pending, state => {
        state.refreshO365AccessTokenApiStatus = inProgress
      })
      .addCase(refreshO365AccessToken.fulfilled, state => {
        state.refreshO365AccessTokenApiStatus = successResponse
      })
      .addCase(refreshO365AccessToken.rejected, (state, action) => {
        state.refreshO365AccessTokenApiStatus = failedResponse(action.payload as string)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { reset } = cloudSlice.actions

export { refreshO365AccessToken }

export default cloudSlice.reducer
