import { makeStyles } from '@material-ui/core/styles'
import DEFAULTS from 'ets/configs/styles/defaults'

const SEPARATOR_WIDTH = 192
const SEPARATOR_HEIGHT = 8

export default makeStyles(theme => ({
  innerRoot: {
    width: DEFAULTS.DESKTOP_WIDTH,
    maxWidth: DEFAULTS.DESKTOP_WIDTH,
    minWidth: DEFAULTS.DESKTOP_WIDTH
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: 40,
    fontWeight: 'bold',
    letterSpacing: '0.86px',
    lineHeight: '47px',
    userSelect: 'none',
    cursor: 'default'
  },
  separator: {
    width: SEPARATOR_WIDTH,
    height: SEPARATOR_HEIGHT,
    backgroundColor: theme.palette.text.primary,
    marginTop: theme.spacing(4) - SEPARATOR_HEIGHT / 2,
    marginBottom: theme.spacing(4) - SEPARATOR_HEIGHT / 2
  }
}))
