import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  wrapper: {
    margin: 30
  },
  subtitle: {
    fontSize: 14,
    color: '#777777'
  },
  settingsIcon: {
    marginBottom: -theme.spacing(1)
  },
  content: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  previewLabel: {
    marginTop: theme.spacing(1)
  },
  emailLabel: {
    color: '#777777',
    display: 'inline-block',
    minWidth: 100
  },
  emailValue: {
    display: 'inline-block'
  },
  greyText: {
    color: '#777777',
    display: 'inline-block'
  },
  frameContent: {
    overflow: 'scroll',
    width: '100%',
    padding: '8px',
    border: 0,
    marginTop: theme.spacing(1),
    height: 350
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  formLine: {
    marginBottom: theme.spacing(3),
    width: '100%'
  },
  formLineError: {
    marginBottom: theme.spacing(3),
    color: '#EF5350'
  },
  inlineTextField: {
    width: '100%'
  },
  errorMessage: {
    width: '100%',
    paddingBottom: theme.spacing(2)
  },
  previewContent: {
    marginTop: -theme.spacing(3)
  }
}))
