import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from 'global/configs/theme/defaults'
import { ScoreSize } from 'global/components/lib/score/Score'

const [EMPTY, WARNING, DANGER] = [COLORS.PRODUCT_UI_ELEMENT, COLORS.ORANGE_400, COLORS.RED_600]

const SCORE_FONT = {
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '16px',
  margin: '0 0 4px 0'
}

const WARNING_LABEL = {
  ...SCORE_FONT,
  color: COLORS.PRODUCT_GRAY_DARKER
}

const DANGER_LABEL = {
  ...SCORE_FONT,
  color: DANGER
}

const SCORE_COMMONS = {
  position: 'static',
  height: '8px',
  marginRight: '1px',
  top: 0
}

const LEFT_SCORE = {
  ...SCORE_COMMONS,
  borderRadius: '25px 0px 0px 25px'
}

const RIGHT_SCORE = {
  ...SCORE_COMMONS,
  borderRadius: '0px 25px 25px 0px'
}

const SCORE_LEVELS = [
  [WARNING, EMPTY, EMPTY, EMPTY, EMPTY],
  [WARNING, WARNING, EMPTY, EMPTY, EMPTY],
  [DANGER, DANGER, DANGER, EMPTY, EMPTY],
  [DANGER, DANGER, DANGER, DANGER, EMPTY],
  [DANGER, DANGER, DANGER, DANGER, DANGER]
]
const [SMALL_WIDTH, NORMAL_WIDTH] = ['15.2px', '24px']
const SCORE_LABELS = [WARNING_LABEL, WARNING_LABEL, DANGER_LABEL, DANGER_LABEL, DANGER_LABEL]

function generateScoreRules(isSmall = false) {
  return SCORE_LEVELS.reduce(
    (all: any, levels: string[], level: number) => ({
      ...all,
      [`lvl${level + 1}label`]: SCORE_LABELS[level],
      ...levels.reduce(
        (rules: any, levelColor: string, colorIndex: number) => ({
          ...rules,
          [`scoreLvl${level + 1}${isSmall ? ScoreSize.small : ScoreSize.normal}Pos${colorIndex + 1}`]: {
            ...(colorIndex === 0 && { ...LEFT_SCORE }),
            ...(colorIndex !== 0 && colorIndex !== 4 && { ...SCORE_COMMONS }),
            ...(colorIndex === 4 && { ...RIGHT_SCORE }),
            backgroundColor: levelColor,
            width: isSmall ? SMALL_WIDTH : NORMAL_WIDTH
          }
        }),
        {}
      )
    }),
    {}
  )
}

export default makeStyles(() => ({
  ...generateScoreRules(),
  ...generateScoreRules(true)
}))
