import ReactHtmlParser from 'react-html-parser'

import { COLORS } from 'ets/configs/styles/defaults'

const ESCAPE_CHARS = /[.*+?^${}()|[\]\\]/g

export default function highlightText(text: string, highlights: string[]) {
  if (!highlights || !text) {
    return text
  }

  return highlights
    .filter((keyword: string) => !!keyword.length)
    .reduce((all: string, highlight: string) => {
      const pattern = new RegExp(highlight.replace(ESCAPE_CHARS, '\\$&'), 'ig')

      return ReactHtmlParser(
        all.replace(pattern, str => `<data style="background-color: ${COLORS.HIGHLIGHT}">${str}</data>`)
      )
    }, text)
}
