import React, { useMemo } from 'react'

import AreaChart from '@barracuda-internal/bds-core/dist/AreaChart/AreaChart'
import ChartWrapper from '@barracuda-internal/bds-core/dist/ChartWrapper/ChartWrapper'

import { WorkflowDashboardProps } from 'fir/redux/types/Workflows'

const WorkflowRunsDashboard: React.FC<WorkflowDashboardProps> = props => {
  return useMemo(
    () => (
      <ChartWrapper chartWrapper={{ ...props.chartWrapperConfig }}>
        <AreaChart areaChart={{ ...props.chartConfig }} />
      </ChartWrapper>
    ),
    [props.chartConfig, props.chartWrapperConfig]
  )
}

export default WorkflowRunsDashboard
