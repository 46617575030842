import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Alert } from '@barracuda-internal/bds-core'

import globalStyles from 'global/components/features/signup/SigninStyles'
import styles from 'global/components/features/signinSignup/signinSignupStyles'
import { useErrorFormatMessage } from 'global/lib/localization'
import MaintenanceBanner from 'global/components/lib/layout/maintenanceBanner/MaintenanceBanner'

export interface Props {
  bgImage?: string
  bgImageGradient?: string
  children: any
  error?: string | false
  isMaintenanceBannerEnabled?: boolean
}

const SigninSignupLayout: React.FC<any> = ({
  bgImage,
  bgImageGradient,
  isMaintenanceBannerEnabled,
  error,
  children
}) => {
  const globalClasses = globalStyles()
  const classes = styles()
  const formatErrorMessage = useErrorFormatMessage()

  const styledBackground = !!(bgImage || bgImageGradient)
  const backgroundImage = bgImageGradient || `url(${bgImage})`

  return useMemo(
    () => (
      <div
        className={`${globalClasses.root} ${globalClasses.rootWhite} ${classes.root}`}
        style={styledBackground ? { backgroundImage, backgroundColor: 'rgba(0, 0, 0, 0.25)' } : {}}
      >
        {isMaintenanceBannerEnabled && <MaintenanceBanner />}
        {error && (
          <Alert className={classes.signinAlert} variant="filled" severity="error" data-field="signinsignup-alert">
            {formatErrorMessage(error)}
          </Alert>
        )}
        {children}
      </div>
    ),
    [
      backgroundImage,
      children,
      classes.root,
      classes.signinAlert,
      error,
      formatErrorMessage,
      globalClasses.root,
      globalClasses.rootWhite,
      isMaintenanceBannerEnabled,
      styledBackground
    ]
  )
}

SigninSignupLayout.propTypes = {
  children: PropTypes.any.isRequired,
  backgroundImage: PropTypes.string,
  bgImage: PropTypes.string,
  error: PropTypes.any
}

SigninSignupLayout.defaultProps = {
  error: false
}

export default SigninSignupLayout
