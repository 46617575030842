export const ATTACK_TYPES = {
  CONVERSATION_HIJACKING: 'conversation_hijacking',
  EXTORTION: 'extortion',
  IMPERSONATION: 'impersonation',
  PHISHING: 'phishing',
  SCAMMING: 'scamming'
}

export const ATTACK_TYPES_BACKEND_KEYWORDS = {
  [ATTACK_TYPES.CONVERSATION_HIJACKING]: ['conversationHijacking'],
  [ATTACK_TYPES.EXTORTION]: ['blackmail'],
  [ATTACK_TYPES.IMPERSONATION]: ['becSpoofing'],
  [ATTACK_TYPES.PHISHING]: ['phishing'],
  [ATTACK_TYPES.SCAMMING]: ['spam']
}

export default function attackTypeValidator(type: string): string | undefined {
  const validatedAttackType: string | undefined = Object.keys(
    ATTACK_TYPES_BACKEND_KEYWORDS
  ).find((attackType: string) => ATTACK_TYPES_BACKEND_KEYWORDS[attackType].includes(type))

  return validatedAttackType
}
