import { useMemo } from 'react'

import { process } from '@progress/kendo-data-query'

import { BDSGridPagerConfig } from 'global/types/dataTables/dataTables'
import { InboxRule } from 'global/types/api/remediation'

import { DataTableState } from 'sen/redux/types/dataTables'
import useInboxRules from 'sen/components/lib/dialogs/alertDialog/tabs/useInboxRules'
import { useAppDispatch } from 'sen/redux/toolkit/hooks'
import { TableConfig } from 'sen/components/lib/dialogs/inboxRules/inboxRulesTable/InboxRulesTable'

export interface UseInboxRulesTableLogic {
  inboxRulesTable: DataTableState
  inboxRules: InboxRule[] | undefined
  updateTable: any
}

export default function useInboxRulesTableLogic({
  inboxRulesTable,
  inboxRules,
  updateTable
}: UseInboxRulesTableLogic): [TableConfig] {
  const dispatch = useAppDispatch()
  const [getActions, getConditions] = useInboxRules()

  const tableData = useMemo(() => {
    const { skip, take } = inboxRulesTable

    const { data } = process(
      (inboxRules || []).map((report: InboxRule) => ({
        ...(report && {
          ...report,
          formattedActions: getActions(report),
          formattedConditions: getConditions(report)
        })
      })),
      { skip, take }
    )

    return {
      data: data.filter(report => report.id),
      total: inboxRules?.length || 0
    }
  }, [inboxRules, inboxRulesTable, getActions, getConditions])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = inboxRulesTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: tableData.total,
      onPageChange: (e: any) => {
        dispatch(updateTable(e.page))
      }
    }
  }, [inboxRulesTable, tableData, dispatch, updateTable])

  return useMemo(() => {
    return [
      {
        tableData,
        pageConfig,
        columns: inboxRulesTable.GRID_COLUMNS,
        columnsConfig: inboxRulesTable.columnsConfig
      }
    ]
  }, [tableData, pageConfig, inboxRulesTable])
}
