import React from 'react'

import GlobalLayout, { GlobalLayoutProps } from 'global/components/lib/layout/Layout'

import SenNavbar from './Navbar'
import useLayoutLogic from './useLayoutLogic'

const Layout = (props: GlobalLayoutProps): JSX.Element => {
  const [layoutLogic] = useLayoutLogic()
  const { initAccount, navbarContextMenuO365Props, onSelectAccountCallback } = layoutLogic

  return (
    <GlobalLayout
      isNewLayout
      isLicenseBannerEnabled
      isMaintenanceBannerEnabled
      isPermissionBannerEnabled
      initAccount={initAccount}
      navbar={<SenNavbar />}
      navbarContextMenuO365Props={navbarContextMenuO365Props}
      onSelectAccountCallback={onSelectAccountCallback}
      pageTitle={props.pageTitle}
      pageSubtitle={props.pageSubtitle}
      rightContent={props.rightContent}
    >
      {props.children}
    </GlobalLayout>
  )
}

export default Layout
