import { useMemo, useCallback } from 'react'
import { uniqBy } from 'lodash'

export interface PageConfig {
  isActive: boolean
  page: number
  onClick: (e: any) => void
}

export interface ButtonConfig {
  isDisabled: boolean
  onClick: (e: any) => void
}

export interface PagerParams {
  total: number
  skip: number
  take: number
  onPageChange: (e: any) => void
  info: boolean
  buttonCount: number
  pageSizes?: number[]
  limit?: number | undefined
}

export interface MenuItem {
  id: number
  value: number
}

export interface UsePagerLogic {
  from: number
  to: number
  pages: PageConfig[]
  buttons: {
    [key: string]: ButtonConfig
  }
  itemsPerPageConfig?: {
    onChange: (e: any) => void
    value: number
    menuItems: MenuItem[]
  }
  dotsStatuses: {
    isPrevVisible: boolean
    isNextVisible: boolean
  }
  maxPage: number
  shouldShowLimitLabel: boolean
}

export default function usePagerLogic(params: PagerParams): [UsePagerLogic] {
  const { total, skip, take, onPageChange, buttonCount, pageSizes, limit } = params
  const OFFSET_RANGE = Math.floor(buttonCount / 2)
  const ADDITIONAL_OFFSET = buttonCount % 2

  const from = useMemo(() => {
    return 1 + skip
  }, [skip])

  const to = useMemo(() => {
    return Math.min(from + take - 1, total)
  }, [from, take, total])

  const currentPage = useMemo(() => {
    return skip / take
  }, [take, skip])

  const maxPage = useMemo(() => {
    const maxBasePage = Math.ceil(total / take)
    const maxLimitedPage = limit ? Math.floor(limit / take) : maxBasePage

    return Math.min(maxLimitedPage, maxBasePage)
  }, [total, take, limit])

  const placeOnChangeEvent = useCallback(
    (e: any, newSkip: number) => {
      if (newSkip !== skip) {
        onPageChange({
          target: null,
          skip: newSkip,
          take,
          syntheticEvent: e,
          nativeEvent: e.nativeEvent
        })
      }
    },
    [onPageChange, skip, take]
  )

  const pages = useMemo((): PageConfig[] => {
    const offsets: PageConfig[] = [...Array(maxPage).keys()].map((i: number) => ({
      isActive: currentPage === i,
      page: i + 1,
      onClick: (e: any) => {
        placeOnChangeEvent(e, i * take)
      }
    }))

    const startOffsetfromStart = Math.max(currentPage - OFFSET_RANGE, 0)
    const startOffsetDiff = Math.abs(currentPage - OFFSET_RANGE - startOffsetfromStart)
    let collectedOffsets = offsets.slice(startOffsetfromStart, startOffsetfromStart + OFFSET_RANGE - startOffsetDiff)

    const endOffsetFromEnd = Math.min(currentPage + OFFSET_RANGE, offsets.length - ADDITIONAL_OFFSET)
    const endOffsetDiff = Math.abs(currentPage + OFFSET_RANGE - endOffsetFromEnd)
    const takenFromEnd = endOffsetFromEnd - currentPage + ADDITIONAL_OFFSET
    collectedOffsets = [...collectedOffsets, ...offsets.slice(currentPage, currentPage + takenFromEnd)]

    if (startOffsetDiff) {
      const newEndOffset = currentPage + takenFromEnd
      collectedOffsets = [...collectedOffsets, ...offsets.slice(newEndOffset, newEndOffset + startOffsetDiff)]
    }

    if (endOffsetDiff && startOffsetfromStart) {
      const newStartOffset = Math.max(startOffsetfromStart - endOffsetDiff, 0)
      collectedOffsets = [...offsets.slice(newStartOffset, newStartOffset + endOffsetDiff), ...collectedOffsets]
    }

    return uniqBy(collectedOffsets, 'page')
  }, [take, currentPage, placeOnChangeEvent, maxPage, OFFSET_RANGE, ADDITIONAL_OFFSET])

  const buttons = useMemo(() => {
    return {
      firstPage: {
        isDisabled: !currentPage,
        onClick: (e: any) => {
          placeOnChangeEvent(e, 0)
        }
      },
      prevPage: {
        isDisabled: !currentPage,
        onClick: (e: any) => {
          placeOnChangeEvent(e, skip - take)
        }
      },
      nextPage: {
        isDisabled: currentPage === maxPage - 1,
        onClick: (e: any) => {
          placeOnChangeEvent(e, skip + take)
        }
      },
      lastPage: {
        isDisabled: currentPage === maxPage - 1,
        onClick: (e: any) => {
          placeOnChangeEvent(e, (maxPage - 1) * take)
        }
      }
    }
  }, [currentPage, maxPage, placeOnChangeEvent, skip, take])

  const shouldShowLimitLabel = useMemo(() => {
    return !!limit && maxPage - 1 === currentPage && total > limit
  }, [maxPage, currentPage, total, limit])

  const itemsPerPageConfig = useMemo(() => {
    if (!pageSizes) {
      return undefined
    }

    return {
      onChange: (e: any) => {
        onPageChange({
          target: null,
          skip: 0,
          take: e.target.value,
          syntheticEvent: e,
          nativeEvent: e.nativeEvent
        })
      },
      value: take,
      menuItems: pageSizes.map((size: number) => ({
        id: size,
        value: size
      }))
    }
  }, [pageSizes, onPageChange, take])

  const dotsStatuses = useMemo(() => {
    return {
      isPrevVisible: (pages[0] || {}).page !== 1,
      isNextVisible: ([...pages].pop() || {}).page !== maxPage
    }
  }, [pages, maxPage])

  return useMemo(() => {
    return [
      {
        from,
        to,
        buttons,
        pages,
        itemsPerPageConfig,
        dotsStatuses,
        maxPage,
        shouldShowLimitLabel
      }
    ]
  }, [from, to, buttons, pages, itemsPerPageConfig, dotsStatuses, maxPage, shouldShowLimitLabel])
}
