import React, { useMemo } from 'react'

import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'

import { Grid, Typography, Paper, DataTable, DataTableColumn, Button, Alert } from '@barracuda-internal/bds-core'

import CircularProgress from 'global/components/lib/circularProgress/CircularProgress'
import { useErrorFormatMessage, useFormatMessage } from 'global/lib/localization'
import { Cell } from 'global/components/lib/grid/cell'
import { getCountryName } from 'global/lib/isoCountries'
import { FraudUnifiedData } from 'global/types/api/dmarc'

import useDmarcReviewTopCountriesLogic from 'sen/components/pages/domainFraudDmarcReview/content/sections/DmarcReviewTopCountries/useDmarcReviewTopCountriesLogic'
import styles from 'sen/components/pages/domainFraudDmarcReview/content/sections/DmarcReviewTopCountries/dmarcReviewTopCountriesStyles'
import DmarcCountriesDialog from 'sen/components/lib/dialogs/dmarcCountries/DmarcCountriesDialog'

const BASE_I18N_KEY = 'sen.app.domain_fraud_dmarc_review.top_countries'
const BASE_I18N_TABLE_KEY = 'sen.app.domain_fraud_dmarc_review.top_countries.table'

const DmarcReviewTopCountries: React.FC = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const tableFormatMessage = useFormatMessage(BASE_I18N_TABLE_KEY)
  const errorFormatMessage = useErrorFormatMessage()
  const [tableConfig, dialogConfig, error] = useDmarcReviewTopCountriesLogic()
  const buttonText =
    tableConfig.countriesLeft >= 1
      ? formatMessage('load_more', { count: tableConfig.countriesLeft })
      : formatMessage('view_map')

  return useMemo(() => {
    return (
      <Grid className={classes.container} container direction="column" data-field="root-grid">
        <Grid container direction="column">
          <Typography className={classes.title} data-field="dmarc-review-countries-title">
            {formatMessage('title')}
          </Typography>
          <Typography className={classes.subtitle} data-field="dmarc-review-countries-subtitle">
            {formatMessage('subtitle')}
          </Typography>
        </Grid>
        <Paper>
          {error && (
            <Alert severity="error" data-field="dmarc-review-top-countries-alert">
              {errorFormatMessage('default')}
            </Alert>
          )}
          {!tableConfig.isLoaded && (
            <Grid className={classes.loaderWrapper} container justifyContent="center">
              <CircularProgress data-field="dmarc-review-top-countries-paper-loader" />
            </Grid>
          )}
          {tableConfig.isLoaded && (
            <Grid container direction="column" data-field="table-wrapper">
              <Grid
                className={`${classes.tableArea} ${!tableConfig.tableData.total ? 'empty-table' : ''}`}
                data-table="dmarc-review-top-countries"
                data-testid="dmarc-review-top-countries"
                item
                xs={12}
                data-field="table-grid"
              >
                <DataTable
                  className={`${tableConfig.tableData.total ? classes.dataTable : classes.emptyDataTable} scans-table`}
                  data={tableConfig.tableData}
                  data-field="table"
                >
                  <DataTableNoRecords>
                    <Typography data-field="no-records">
                      {!tableConfig.tableData.total && tableFormatMessage('empty_table')}
                    </Typography>
                  </DataTableNoRecords>

                  <DataTableColumn
                    field={tableConfig.columns.COUNTRY}
                    {...tableConfig.columnsConfig[tableConfig.columns.COUNTRY]}
                    title={tableFormatMessage(`${tableConfig.columns.COUNTRY}`)}
                    cell={({ dataItem }: { dataItem: FraudUnifiedData }) => (
                      <Cell data-field="country-cell" className={classes.cell}>
                        {getCountryName(dataItem.country)}
                      </Cell>
                    )}
                  />
                  <DataTableColumn
                    field={tableConfig.columns.REPORTS}
                    {...tableConfig.columnsConfig[tableConfig.columns.REPORTS]}
                    title={tableFormatMessage(`${tableConfig.columns.REPORTS}`)}
                    cell={({ dataItem }: { dataItem: FraudUnifiedData }) => (
                      <Cell data-field="reports-cell" className={classes.cell}>
                        {dataItem.totalEmailCount.toLocaleString()}
                      </Cell>
                    )}
                  />
                </DataTable>
              </Grid>
              <Button
                className={classes.loadMoreButton}
                color="primary"
                variant="text"
                onClick={() => dialogConfig.onOpenDialog(buttonText)}
                data-field="dmarc-review-top-countries-load-more-button"
                data-action="open-dialog"
              >
                {buttonText}
              </Button>
            </Grid>
          )}
        </Paper>
        {dialogConfig.isMapDialogOpened && <DmarcCountriesDialog onClose={dialogConfig.onCloseDialog} />}
      </Grid>
    )
  }, [classes, formatMessage, tableFormatMessage, tableConfig, dialogConfig, buttonText, error, errorFormatMessage])
}

export default DmarcReviewTopCountries
