import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'

export interface emailDetailsProps {
  handleScrollToTop: () => void
  modalContentRef: RefObject<HTMLDivElement>
  showScrollToTopButton: boolean
}

export default function useEmailDetailsLogic(): [emailDetailsProps] {
  const modalContentRef = useRef<HTMLDivElement>(null)
  const [showScrollToTopButton, setShowScrollToTopButton] = useState(false)

  const handleScroll = useCallback(() => {
    if (modalContentRef.current) {
      setShowScrollToTopButton(modalContentRef.current.scrollTop > 1000)
    }
  }, [])

  useEffect(() => {
    // setTimeout can fix the issue Ref is being accessed before the component has mounted
    // eslint-disable-next-line consistent-return
    setTimeout(() => {
      if (modalContentRef.current) {
        modalContentRef.current.addEventListener('scroll', handleScroll)
        return () => {
          if (modalContentRef.current) {
            modalContentRef.current.removeEventListener('scroll', handleScroll)
          }
        }
      }
    }, 2000)
  }, [handleScroll])

  const handleScrollToTop = useCallback(() => {
    if (modalContentRef.current) {
      modalContentRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }, [])

  return useMemo(
    () => [
      {
        handleScrollToTop,
        modalContentRef,
        showScrollToTopButton
      }
    ],
    [handleScrollToTop, showScrollToTopButton]
  )
}
