import { createSlice } from '@reduxjs/toolkit'

import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'global/redux/toolkit/api'

import { getEmailInfo } from 'fir/redux/features/emails/emailsApiThunks'

import { Email } from 'global/types/api/emailsType'

export interface EmailsState {
  emailInfoList: { [key: string]: Email }
  emailInfoLoadingApiStatus: ApiStatus
  lastFailedEmailId: string
}

// Initial state
export const INITIAL_STATE: EmailsState = {
  emailInfoList: {},
  emailInfoLoadingApiStatus: inIdle,
  lastFailedEmailId: ''
}

/* eslint-disable no-param-reassign */
export const emailsSlice = createSlice({
  name: 'EMAILS',
  initialState: INITIAL_STATE,
  // reducers object creates an action and executes the reducer function
  reducers: {
    reset: () => {
      return { ...INITIAL_STATE }
    }
  },
  // extraReducers do not create an action but executes the reducer function
  extraReducers: builder => {
    builder
      .addCase(getEmailInfo.pending, state => {
        state.emailInfoLoadingApiStatus = inProgress
      })
      .addCase(getEmailInfo.fulfilled, (state, action) => {
        state.emailInfoLoadingApiStatus = successResponse
        state.emailInfoList = {
          ...state.emailInfoList,
          [(action.payload as any).emailId]: action.payload
        }
      })
      .addCase(getEmailInfo.rejected, (state, action) => {
        state.emailInfoLoadingApiStatus = failedResponse(action.payload as string)
        state.lastFailedEmailId = action.meta.arg as any
      })
  }
})
/* eslint-enable no-param-reassign */

export const { reset } = emailsSlice.actions

export { getEmailInfo }

export default emailsSlice.reducer
