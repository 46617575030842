import { Theme, createStyles } from '@material-ui/core/styles'
import { COLORS } from 'global/configs/theme/defaults'

export default function tabStyles(theme: Theme) {
  return createStyles({
    tabs: {
      borderBottom: `1px solid ${COLORS.LIGHT_GRAY}`,

      '& button': {
        color: theme.palette.text.secondary,

        '& span': {
          fontSize: 16,
          letterSpacing: 0,
          lineHeight: '19px'
        }
      }
    },
    indicator: {
      backgroundColor: COLORS.BLUE
    },
    tab: {
      color: COLORS.BLUE
    },
    tabContent: {
      marginTop: theme.spacing(2)
    },
    dialogTabContent: {
      padding: theme.spacing(2)
    }
  })
}
