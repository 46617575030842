import React, { useMemo } from 'react'

import {
  Typography,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Zoom
} from '@barracuda-internal/bds-core'

import { ModifiedInboxRuleFeed } from 'global/types/api/remediation'
import DEFAULTS from 'global/configs/theme/defaults'
import { useFormatMessage } from 'global/lib/localization'

import styles from 'sen/components/lib/dialogs/inboxRules/inboxRulesDialogStyles'
import useRangeInboxRulesDialogLogic from 'sen/components/lib/dialogs/inboxRules/useRangeInboxRuleDialogLogic'
import InboxRulesTable from 'sen/components/lib/dialogs/inboxRules/inboxRulesTable/InboxRulesTable'

export interface InboxRulesDialogProps {
  feed: ModifiedInboxRuleFeed
  onClose: () => void
}

const BASE_I18N_KEY = 'sen.app.range_inbox_rules_dialog'

export const InboxRulesDialog: React.FC<InboxRulesDialogProps> = ({ feed, onClose }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const [newInboxRulesTableConfig, previousInboxRulesTableConfig] = useRangeInboxRulesDialogLogic(feed)

  return useMemo(
    () => (
      <Dialog
        style={{ zIndex: DEFAULTS.DIALOGS_ZINDEX }}
        maxWidth="lg"
        fullWidth
        open
        TransitionComponent={Zoom}
        data-testid="range-inbox-rules-dialog"
      >
        <DialogTitle>
          <Typography className={classes.dialogTitle}>
            {formatMessage('title', {
              email: feed.userPrincipalName,
              date: feed.formattedDate,
              b: (txt: string) => <b key={txt}>{txt}</b>
            })}
          </Typography>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <Grid container direction="column">
            <InboxRulesTable tableConfig={newInboxRulesTableConfig} rangeTable />
          </Grid>
        </DialogContent>

        <DialogTitle>
          <Typography className={classes.dialogTitle}>
            {formatMessage('title_2', {
              email: feed.userPrincipalName,
              b: (txt: string) => <b key={txt}>{txt}</b>
            })}
          </Typography>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <Grid container direction="column">
            <InboxRulesTable tableConfig={previousInboxRulesTableConfig} rangeTable />
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button color="primary" variant="contained" size="large" onClick={onClose}>
            &nbsp;&nbsp;{formatMessage('button')}&nbsp;&nbsp;
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [classes, formatMessage, onClose, newInboxRulesTableConfig, previousInboxRulesTableConfig, feed]
  )
}

export default InboxRulesDialog
