import React, { useMemo } from 'react'

import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'

import { Grid, DataTable, DataTableColumn, Paper, Typography } from '@barracuda-internal/bds-core'

import CircularProgress from 'global/components/lib/circularProgress/CircularProgress'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import SearchField from 'global/components/lib/searchField/SearchField'
import { Cell, CellText } from 'global/components/lib/grid/cell'
import { useFormatMessage } from 'global/lib/localization'
import Pager from 'global/components/lib/grid/pager/Pager'
import { SentinelModifiedScan } from 'global/types/api/scan'
import CellLabelType from 'global/components/lib/grid/cell/CellLabelType'

import useAllAccountsLogic from 'admin/components/pages/dashboard/tabs/impersonationProtection/tabs/allAccounts/useAllAccountsLogic'
import styles from 'admin/components/pages/dashboard/tabs/impersonationProtection/tabs/allAccounts/allAccountsStyles'
import ImpersonateDialog from 'admin/components/lib/dialogs/impersonateDialog/ImpersonateDialog'
import ScanDialog from 'admin/components/lib/dialogs/scanDialog/ScanDialog'
import ScaryDialog from 'admin/components/lib/dialogs/deactivate/ScaryDialog'
import DeactivateDialog from 'admin/components/lib/dialogs/deactivate/DeactivateDialog'

const BASE_I18N_KEY = 'admin.app.sentinel'
const BASE_I18N_TABLE_KEY = 'admin.app.sentinel.table'

const AllAccounts: React.FC = () => {
  const [
    accessTokens,
    tableConfig,
    scaryDialogConfig,
    deactivateDialogConfig,
    scanDialogConfig,
    impersonateDialogConfig
  ] = useAllAccountsLogic()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const tableFormatMessage = useFormatMessage(BASE_I18N_TABLE_KEY)
  const classes = styles()

  return useMemo(
    () => (
      <Grid container spacing={2} data-section="scans-table" direction="column" data-field="root-grid">
        <Grid item>
          <Paper className={classes.tableCard} elevation={0}>
            {!tableConfig.isLoaded && (
              <Grid container justifyContent="center" className={classes.progressWrapper}>
                <CircularProgress data-field="paper-loader" />
              </Grid>
            )}

            {tableConfig.isLoaded && (
              <Grid className={classes.tableWrapper} container direction="column" data-field="table-wrapper">
                <Grid container direction="row">
                  <Grid item xs={12}>
                    <SearchField
                      {...tableConfig.searchFieldConfig}
                      placeholder={formatMessage('search_field.placeholder')}
                      data-field="search-field"
                    />
                  </Grid>
                </Grid>

                <Grid
                  className={`${classes.tableArea} ${!tableConfig.tableData.total ? 'empty-table' : ''}`}
                  data-table="scans"
                  data-testid="scans"
                  item
                  xs={12}
                  data-field="table-grid"
                >
                  {tableConfig.inProgress && <LinearProgress data-field="table-loader" />}
                  <DataTable
                    className={`${
                      tableConfig.tableData.total ? classes.dataTable : classes.emptyDataTable
                    } scans-table ${classes.gridLayout}`}
                    data={tableConfig.tableData}
                    {...tableConfig.pageConfig}
                    {...tableConfig.sortConfig}
                    pager={Pager}
                    data-field="table"
                  >
                    <DataTableNoRecords>
                      <Typography data-field="no-records">
                        {!tableConfig.tableData.total && tableFormatMessage('empty_table')}
                      </Typography>
                    </DataTableNoRecords>
                    <DataTableColumn
                      field={tableConfig.columns.CREATED_ON}
                      {...tableConfig.columnsConfig[tableConfig.columns.CREATED_ON]}
                      title={tableFormatMessage(`${tableConfig.columns.CREATED_ON}`)}
                      cell={({ dataItem }: { dataItem: SentinelModifiedScan }) => (
                        <Cell className={classes.cell}>
                          <CellText ellipsis data-field="created-on">
                            {dataItem.createdOnFormatted}
                          </CellText>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      field={tableConfig.columns.ADMIN}
                      {...tableConfig.columnsConfig[tableConfig.columns.ADMIN]}
                      title={tableFormatMessage(`${tableConfig.columns.ADMIN}`)}
                      cell={({ dataItem }: { dataItem: SentinelModifiedScan }) => (
                        <Cell className={classes.cell}>
                          <CellText data-field="triggered-by">
                            {dataItem.triggeredBy}
                            <br />
                            <span
                              className={classes.link}
                              role="presentation"
                              data-field="impersonate-action"
                              data-action="impersonate"
                              onClick={() => {
                                tableConfig.onOpenImpersonate(dataItem)
                              }}
                            >
                              {' '}
                              {tableFormatMessage('impersonate')}
                            </span>
                          </CellText>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      field={tableConfig.columns.STATUS}
                      {...tableConfig.columnsConfig[tableConfig.columns.STATUS]}
                      title={tableFormatMessage(`${tableConfig.columns.STATUS}`)}
                      cell={({ dataItem }: { dataItem: SentinelModifiedScan }) => (
                        <Cell className={classes.cell}>
                          {dataItem.bootstrapInProgress ? (
                            <CellText data-field="status">{tableFormatMessage('bootstrapping')}</CellText>
                          ) : (
                            <CellText data-field="status">{tableFormatMessage('active')}</CellText>
                          )}
                        </Cell>
                      )}
                    />

                    <DataTableColumn
                      field={tableConfig.columns.OFFICE_365_NAME}
                      {...tableConfig.columnsConfig[tableConfig.columns.OFFICE_365_NAME]}
                      title={tableFormatMessage(`${tableConfig.columns.OFFICE_365_NAME}`)}
                      cell={({ dataItem }: { dataItem: SentinelModifiedScan }) => (
                        <Cell className={classes.cell}>
                          <CellText data-field="office-365-name">
                            {dataItem.accessTokenName}
                            <br />
                            <span
                              className={classes.link}
                              role="presentation"
                              data-action="open-deactivate"
                              onClick={() => {
                                tableConfig.onOpenScary(dataItem, 'single')
                              }}
                            >
                              {' '}
                              {tableFormatMessage('deactivate')}
                            </span>
                            <br />
                            <br />
                            <span
                              className={classes.link}
                              role="presentation"
                              data-action="open-details"
                              onClick={() => {
                                tableConfig.onOpenScan(dataItem, accessTokens[dataItem.accessTokenId])
                              }}
                            >
                              {' '}
                              {tableFormatMessage('other_details')}
                            </span>
                          </CellText>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      field={tableConfig.columns.SERIAL}
                      {...tableConfig.columnsConfig[tableConfig.columns.SERIAL]}
                      title={tableFormatMessage(`${tableConfig.columns.SERIAL}`)}
                      cell={({ dataItem }: { dataItem: SentinelModifiedScan }) => (
                        <Cell className={classes.cell}>
                          <CellText className={classes.serialCell} data-field="serial" data-action="update-serial">
                            {accessTokens[dataItem.accessTokenId]?.sentinelSerialNumber || tableFormatMessage('trial')}
                          </CellText>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      field={tableConfig.columns.REPORT}
                      {...tableConfig.columnsConfig[tableConfig.columns.REPORT]}
                      title={tableFormatMessage(`${tableConfig.columns.REPORT}`)}
                      cell={({ dataItem }: { dataItem: SentinelModifiedScan }) => (
                        <Cell className={classes.cell}>
                          <CellText data-field="report">
                            <span
                              className={classes.link}
                              role="presentation"
                              data-action="open-report"
                              onClick={() => {
                                tableConfig.onOpenReport(dataItem)
                              }}
                            >
                              {' '}
                              {tableFormatMessage('history_report')}
                            </span>
                          </CellText>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      field={tableConfig.columns.MAILBOXES}
                      {...tableConfig.columnsConfig[tableConfig.columns.MAILBOXES]}
                      headerClassName={classes.rightAlignedText}
                      title={tableFormatMessage(`${tableConfig.columns.MAILBOXES}`)}
                      cell={({ dataItem }: { dataItem: SentinelModifiedScan }) => (
                        <Cell className={classes.cell}>
                          <CellLabelType align="right">
                            <CellText data-field="mailboxes">{dataItem.mailboxCount || 0}</CellText>
                          </CellLabelType>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      field={tableConfig.columns.PAST_ATTACKS}
                      {...tableConfig.columnsConfig[tableConfig.columns.PAST_ATTACKS]}
                      headerClassName={classes.rightAlignedText}
                      title={tableFormatMessage(`${tableConfig.columns.PAST_ATTACKS}`)}
                      cell={({ dataItem }: { dataItem: SentinelModifiedScan }) => (
                        <Cell className={classes.cell}>
                          <CellLabelType align="right">
                            <CellText data-field="past-attacks">{dataItem.bootstrapSpAttackCount || 0}</CellText>
                          </CellLabelType>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      field={tableConfig.columns.NEW_ATTACKS}
                      {...tableConfig.columnsConfig[tableConfig.columns.NEW_ATTACKS]}
                      headerClassName={classes.rightAlignedText}
                      title={tableFormatMessage(`${tableConfig.columns.NEW_ATTACKS}`)}
                      cell={({ dataItem }: { dataItem: SentinelModifiedScan }) => (
                        <Cell className={classes.cell}>
                          <CellLabelType align="right">
                            <CellText data-field="new-attacks">{dataItem.cmSpAttackCount || 0}</CellText>
                          </CellLabelType>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      field={tableConfig.columns.LAST_EMAIL_PROCESSED}
                      {...tableConfig.columnsConfig[tableConfig.columns.LAST_EMAIL_PROCESSED]}
                      title={tableFormatMessage(`${tableConfig.columns.LAST_EMAIL_PROCESSED}`)}
                      cell={({ dataItem }: { dataItem: SentinelModifiedScan }) => (
                        <Cell className={classes.cell}>
                          <CellText data-field="refreshed">
                            {dataItem.refreshedOnDateFormatted} @ {dataItem.refreshedOnTimeFormatted}
                          </CellText>
                        </Cell>
                      )}
                    />
                  </DataTable>
                </Grid>
              </Grid>
            )}
          </Paper>
        </Grid>
        {scanDialogConfig.open && scanDialogConfig.scan && (
          <ScanDialog
            scan={scanDialogConfig.scan}
            accessToken={scanDialogConfig.accessToken}
            product={scanDialogConfig.product}
            onClose={scanDialogConfig.onClose}
          />
        )}
        {scaryDialogConfig.open && scaryDialogConfig.accessTokenId && (
          <ScaryDialog
            accessTokenId={scaryDialogConfig.accessTokenId}
            deactivateType={scaryDialogConfig.deactivateType}
            onClose={scaryDialogConfig.onClose}
            product={scaryDialogConfig.product}
            updateDeactivateType={scaryDialogConfig.updateDeactivateType}
          />
        )}
        {deactivateDialogConfig.open && deactivateDialogConfig.accessTokenId && (
          <DeactivateDialog accessTokenId={deactivateDialogConfig.accessTokenId} />
        )}
        {impersonateDialogConfig.open && impersonateDialogConfig.email && (
          <ImpersonateDialog email={impersonateDialogConfig.email} />
        )}
      </Grid>
    ),
    [
      accessTokens,
      classes,
      deactivateDialogConfig,
      formatMessage,
      impersonateDialogConfig,
      scanDialogConfig,
      scaryDialogConfig,
      tableConfig,
      tableFormatMessage
    ]
  )
}

export default AllAccounts
