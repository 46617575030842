import React, { useMemo } from 'react'
import ReactFlow, { Controls, ControlButton } from 'react-flow-renderer'

import {
  Button,
  MenuItem,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Zoom
} from '@barracuda-internal/bds-core'
import { Delete as DeleteIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'

import Alert from 'global/components/lib/alerts/Alert'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import { useFormatMessage } from 'global/lib/localization'

import WorkflowBuilder from './workflowBuilder/WorkflowBuilder'

import useWorkflowDialogLogic, { UseWorkflowDialogParams } from './useWorkflowDialogLogic'
import styles, { workflowFlowDiagramStyles } from './workflowDialogStyles'

type WorkflowDialogProps = UseWorkflowDialogParams

const BASE_I18N_KEY = 'fir.app.automated_workflows.workflow_dialog'

const WorkflowDialog: React.FC<WorkflowDialogProps> = props => {
  const classes = styles()
  const flowClasses = workflowFlowDiagramStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [workflowDialogLogic] = useWorkflowDialogLogic(props)

  return useMemo(() => {
    const {
      alertConfig,
      handleWorkflowDescription,
      handleWorkflowName,
      isCreateUpdateDisabled,
      isWorkflowNameValid,
      onChangeBlueprint,
      onClickElement,
      onClickElementDelete,
      onConnect,
      onElementsRemove,
      onNodeDragStop,
      validateWorkflow,
      workflowDialogConfig
    } = workflowDialogLogic

    return (
      <Dialog fullWidth maxWidth="md" open={workflowDialogConfig.isOpened} TransitionComponent={Zoom}>
        {(workflowDialogConfig.showError || workflowDialogConfig.showSuccess) && <Alert {...alertConfig} />}
        <DialogTitle>
          <Typography variant="h4" component="span">
            {formatMessage(
              `labels.page_title${
                workflowDialogConfig.workflowId && !workflowDialogConfig.workflowBlueprintSelected ? '_update' : ''
              }`
            )}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div>
            {workflowDialogConfig.isNewWorkflow && (
              <TextField
                autoFocus
                fullWidth
                select
                label={formatMessage('labels.workflow_templates')}
                onChange={onChangeBlueprint}
                value={workflowDialogConfig.workflowBlueprintName}
              >
                {workflowDialogConfig.workflowBlueprints.data
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((blueprint: any) => (
                    <MenuItem key={blueprint.name} value={blueprint.name} className={classes.menuItems}>
                      {blueprint.name}
                    </MenuItem>
                  ))}
              </TextField>
            )}
            <TextField
              className={classes.workflowNameInput}
              data-field="workflowName"
              value={workflowDialogConfig.workflowName}
              onChange={handleWorkflowName}
              error={!isWorkflowNameValid}
              helperText={!isWorkflowNameValid ? workflowDialogConfig.workflowDialogErrorText : ''}
              label={formatMessage('labels.name')}
              type="text"
              fullWidth
              required
            />
            <TextField
              data-field="workflowDescription"
              value={workflowDialogConfig.workflowDescription}
              onChange={handleWorkflowDescription}
              label={formatMessage('labels.description')}
              type="text"
              fullWidth
            />
            {!workflowDialogConfig.isNewWorkflow && !workflowDialogConfig.existingWorkflow.uuid && (
              <div className={classes.loadingContainer}>
                <LinearProgress />
              </div>
            )}
            {((!workflowDialogConfig.isNewWorkflow && workflowDialogConfig.existingWorkflow.uuid) ||
              (workflowDialogConfig.isNewWorkflow && !workflowDialogConfig.workflowBlueprintSelected)) && (
              <WorkflowBuilder />
            )}
            <div>
              {workflowDialogConfig.showDeleteButton &&
                !workflowDialogConfig.workflowBlueprintSelected &&
                !workflowDialogConfig.existingWorkflow.uuid &&
                workflowDialogConfig.workflowBuilder.triggerCount !== 1 &&
                workflowDialogConfig.workflowDialogErrorText.length === 0 && (
                  <Typography variant="caption" className={classes.helperText}>
                    <span>{formatMessage('labels.node_order_helper_text')}</span>
                  </Typography>
                )}
              {!workflowDialogConfig.showDeleteButton && !workflowDialogConfig.workflowBlueprintSelected && (
                <Typography variant="caption" className={classes.helperText}>
                  <span>{formatMessage('labels.trigger_delete_1')}</span>
                  <br />
                  <span>
                    {formatMessage('labels.trigger_delete_2', {
                      b: (txt: any) => <b key={txt}>{txt}</b>
                    })}
                  </span>
                </Typography>
              )}
              {/* This component should only display when there is an error on the dialog */}
              {isCreateUpdateDisabled && isWorkflowNameValid && (
                <Typography variant="caption" className={`${classes.helperText} ${classes.errorText}`}>
                  <div>{workflowDialogConfig.workflowDialogErrorText}</div>
                </Typography>
              )}
            </div>
          </div>
          <div className={classes.flowChart}>
            {workflowDialogConfig.workflowBlueprintSelected && (
              <div className={classes.blueprintControls}>
                <WorkflowBuilder />
              </div>
            )}
            {workflowDialogConfig.elements.length > 0 && (
              <ReactFlow
                className={flowClasses.flowStyles}
                arrowHeadColor="#000006"
                defaultPosition={[300, 0]}
                // We need to disable allowing users to delete nodes/edges with a keypress, by default this is set to the Backspace key
                deleteKeyCode=""
                elements={workflowDialogConfig.elements}
                onElementsRemove={onElementsRemove}
                onElementClick={onClickElement}
                onConnect={onConnect}
                onLoad={reactFlowInstance => {
                  reactFlowInstance.fitView({ padding: 0.17 })
                }}
                onNodeDragStop={onNodeDragStop}
                snapGrid={[15, 15]}
                snapToGrid
                zoomOnDoubleClick={false}
                zoomOnScroll={false}
              >
                <Controls
                  className={workflowDialogConfig.workflowBlueprintSelected ? '' : classes.editWorkflowControls}
                  showInteractive={false}
                >
                  {workflowDialogConfig.showDeleteButton && !workflowDialogConfig.workflowBlueprintSelected && (
                    <ControlButton onClick={onClickElementDelete}>
                      <DeleteIcon />
                    </ControlButton>
                  )}
                </Controls>
              </ReactFlow>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => workflowDialogConfig.onClose()} color="primary" variant="text">
            {formatMessage('buttons.cancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={validateWorkflow}>
            {formatMessage(
              `buttons.${
                workflowDialogConfig.workflowId && !workflowDialogConfig.workflowBlueprintSelected
                  ? 'update_workflow'
                  : 'create_workflow'
              }`
            )}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }, [classes, flowClasses, formatMessage, workflowDialogLogic])
}

export default WorkflowDialog
