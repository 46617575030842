/* eslint-disable no-unused-expressions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { orderBy, union } from 'lodash'

import {
  CompanyDomain,
  DomainsStats,
  DomainStatus,
  SpfRecord,
  DmarcEntry,
  FraudUnifiedData,
  RufSample,
  DomainTotals,
  DmarcSourcesReport,
  DmarcSourceTypes,
  DmarcSubdomainsReport,
  DmarcHostsReport,
  DmarcRufSamplesReport,
  FraudUnifiedRufDataReport
} from 'global/types/api/dmarc'
import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'global/redux/toolkit/api'
import insertToArray from 'global/lib/insertToArray'

import {
  getCompanyDomains,
  getDomainTotals,
  getDomainsStats,
  resolveDmarc,
  resolveSpf,
  setupDmarc,
  addDomain,
  verifyDomain,
  deleteDomain,
  getDmarcFraudUnifiedData,
  getDmarcFraudUnifiedRufData,
  getRufSample,
  getAllApprovedSources,
  getAllRejectedSources,
  getDmarcHighVolumeSources,
  getDmarcLowVolumeSources,
  setApprovalStatusForSender,
  setApprovalStatusForVolumeGroup,
  getDmarcDkimPassingHosts,
  getDmarcFailedSubdomains,
  getDmarcPassedSubdomains,
  getRufSamples
} from 'sen/redux/features/dmarc/dmarcApiThunks'
import { INITIAL_STATE as dmarcFraudUnifiedRufDataTableInitialState } from 'sen/redux/features/dataTables/dmarcFraudUnifiedRufData/dmarcFraudUnifiedRufDataSlice'
import { HardResetReport, resetReport } from 'global/redux/features/reports/reducerHandlers'
import {
  update as updateDmarcAllApprovedTable,
  INITIAL_STATE as dmarcAllApprovedTableInitialState
} from 'sen/redux/features/dataTables/dmarcSources/dmarcAllApproved'
import {
  update as updateDmarcAllRejectedTable,
  INITIAL_STATE as dmarcAllRejectedTableInitialState
} from 'sen/redux/features/dataTables/dmarcSources/dmarcAllRejected'
import {
  update as updateDmarcLowVolumeSourcesTable,
  INITIAL_STATE as dmarcLowVolumeSourcesTableInitialState
} from 'sen/redux/features/dataTables/dmarcSources/dmarcLowVolumeSources'
import {
  update as updateDmarcHighVolumeSourcesTable,
  INITIAL_STATE as dmarcHighVolumeSourcesTableInitialState
} from 'sen/redux/features/dataTables/dmarcSources/dmarcHighVolumeSources'
import {
  update as updateDmarcFailedSubdomainsTable,
  INITIAL_STATE as dmarcFailedSubdomainsTableInitialState
} from 'sen/redux/features/dataTables/dmarcSubdomains/failedSubdomains'
import {
  update as updateDmarcPassedSubdomainsTable,
  INITIAL_STATE as dmarcPassedSubdomainsTableInititalState
} from 'sen/redux/features/dataTables/dmarcSubdomains/passedSubdomains'
import {
  update as updateRufSamplesTable,
  INITIAL_STATE as rufSamplesTableInitialState
} from 'sen/redux/features/dataTables/dmarcSamples/ruf'

export interface DmarcState {
  companyDomains: CompanyDomain[]
  getCompanyDomainsApiStatus: ApiStatus
  domainTotals: DomainTotals | undefined
  getDomainTotalsApiStatus: ApiStatus
  domainsStats: DomainsStats | undefined
  getDomainsStatsApiStatus: ApiStatus
  resolvedDmarc: DmarcEntry | undefined
  resolveDmarcApiStatus: ApiStatus
  resolveSpfApiStatus: ApiStatus
  setupDmarcApiStatus: ApiStatus
  resolvedSpf: SpfRecord | undefined
  addedDomain: DomainStatus | undefined
  addDomainApiStatus: ApiStatus
  verifiedDomain: DomainStatus | undefined
  verifyDomainApiStatus: ApiStatus
  deletedDomain: DomainStatus | undefined
  deleteDomainApiStatus: ApiStatus
  getDmarcDkimPassingHostsApiStatus: ApiStatus
  dmarcDkimPassingHosts: DmarcHostsReport | undefined
  allApprovedSources: DmarcSourcesReport | undefined
  getAllApprovedSourcesApiStatus: ApiStatus
  loadedAllApprovedSourcesOffsets: number[]
  allRejectedSources: DmarcSourcesReport | undefined
  getAllRejectedSourcesApiStatus: ApiStatus
  loadedAllRejectedSourcesOffsets: number[]
  dmarcHighVolumeSources: DmarcSourcesReport | undefined
  getDmarcHighVolumeSourcesApiStatus: ApiStatus
  loadedDmarcHighVolumeSourcesOffsets: number[]
  dmarcLowVolumeSources: DmarcSourcesReport | undefined
  getDmarcLowVolumeSourcesApiStatus: ApiStatus
  loadedDmarcLowVolumeSourcesOffsets: number[]
  setApprovalStatusForSenderApiStatus: ApiStatus
  setApprovalStatusForVolumeGroupApiStatus: ApiStatus
  dmarcFailedDomains: DmarcSubdomainsReport | undefined
  getDmarcFailedSubdomainsApiStatus: ApiStatus
  loadedDmarcFailedSubdomainsOffsets: number[]
  dmarcPassedDomains: DmarcSubdomainsReport | undefined
  getDmarcPassedSubdomainsApiStatus: ApiStatus
  loadedDmarcPassedSubdomainsOffsets: number[]
  rufSample: RufSample | undefined
  getRufSampleApiStatus: ApiStatus
  rufSamples: DmarcRufSamplesReport | undefined
  getRufSamplesApiStatus: ApiStatus
  loadedRufSamplesOffsets: number[]
  getDmarcFraudUnifiedDataApiStatus: ApiStatus
  dmarcFraudUnifiedData: FraudUnifiedData[] | undefined
  getDmarcFraudUnifiedMapDataApiStatus: ApiStatus
  dmarcFraudUnifiedMapData: FraudUnifiedData[] | undefined
  getDmarcFraudUnifiedRufDataApiStatus: ApiStatus
  dmarcFraudUnifiedRufData: FraudUnifiedRufDataReport | undefined
  loadedDmarcFraudRufOffsets: number[]
}

// initialState
export const INITIAL_STATE: DmarcState = {
  companyDomains: [],
  getCompanyDomainsApiStatus: inIdle,
  domainTotals: undefined,
  getDomainTotalsApiStatus: inIdle,
  domainsStats: undefined,
  getDomainsStatsApiStatus: inIdle,
  resolvedDmarc: undefined,
  resolveDmarcApiStatus: inIdle,
  resolveSpfApiStatus: inIdle,
  setupDmarcApiStatus: inIdle,
  resolvedSpf: undefined,
  addedDomain: undefined,
  addDomainApiStatus: inIdle,
  verifiedDomain: undefined,
  verifyDomainApiStatus: inIdle,
  deletedDomain: undefined,
  deleteDomainApiStatus: inIdle,
  getDmarcFraudUnifiedDataApiStatus: inIdle,
  dmarcFraudUnifiedData: undefined,
  getDmarcFraudUnifiedMapDataApiStatus: inIdle,
  dmarcFraudUnifiedMapData: undefined,
  getDmarcFraudUnifiedRufDataApiStatus: inIdle,
  dmarcFraudUnifiedRufData: undefined,
  loadedDmarcFraudRufOffsets: [],
  getRufSampleApiStatus: inIdle,
  rufSample: undefined,
  getDmarcDkimPassingHostsApiStatus: inIdle,
  dmarcDkimPassingHosts: undefined,
  allApprovedSources: undefined,
  getAllApprovedSourcesApiStatus: inIdle,
  loadedAllApprovedSourcesOffsets: [],
  allRejectedSources: undefined,
  getAllRejectedSourcesApiStatus: inIdle,
  loadedAllRejectedSourcesOffsets: [],
  dmarcHighVolumeSources: undefined,
  getDmarcHighVolumeSourcesApiStatus: inIdle,
  loadedDmarcHighVolumeSourcesOffsets: [],
  dmarcLowVolumeSources: undefined,
  getDmarcLowVolumeSourcesApiStatus: inIdle,
  loadedDmarcLowVolumeSourcesOffsets: [],
  setApprovalStatusForSenderApiStatus: inIdle,
  setApprovalStatusForVolumeGroupApiStatus: inIdle,
  dmarcFailedDomains: undefined,
  getDmarcFailedSubdomainsApiStatus: inIdle,
  loadedDmarcFailedSubdomainsOffsets: [],
  dmarcPassedDomains: undefined,
  getDmarcPassedSubdomainsApiStatus: inIdle,
  loadedDmarcPassedSubdomainsOffsets: [],
  rufSamples: undefined,
  getRufSamplesApiStatus: inIdle,
  loadedRufSamplesOffsets: []
}

/* eslint-disable no-param-reassign */
export const dmarcSlice = createSlice({
  name: 'DMARC',
  initialState: INITIAL_STATE,
  reducers: {
    resetCompanyDomains: state => {
      state.companyDomains = INITIAL_STATE.companyDomains
      state.getCompanyDomainsApiStatus = INITIAL_STATE.getCompanyDomainsApiStatus
    },

    resetDomainTotals: state => {
      state.domainTotals = INITIAL_STATE.domainTotals
      state.getDomainTotalsApiStatus = INITIAL_STATE.getDomainTotalsApiStatus
    },

    resetDomainsStats: state => {
      state.domainsStats = INITIAL_STATE.domainsStats
      state.getDomainsStatsApiStatus = INITIAL_STATE.getDomainsStatsApiStatus
    },

    resetResolvedDmarc: state => {
      state.resolvedDmarc = INITIAL_STATE.resolvedDmarc
      state.resolveDmarcApiStatus = INITIAL_STATE.resolveDmarcApiStatus
    },

    resetResolveSpf: state => {
      state.resolvedSpf = INITIAL_STATE.resolvedSpf
      state.resolveSpfApiStatus = INITIAL_STATE.resolveSpfApiStatus
    },

    resetSetupDmarc: state => {
      state.setupDmarcApiStatus = INITIAL_STATE.setupDmarcApiStatus
    },

    resetAddDomain: state => {
      state.addedDomain = INITIAL_STATE.addedDomain
      state.addDomainApiStatus = INITIAL_STATE.addDomainApiStatus
    },

    resetVerifiedDomain: state => {
      state.verifiedDomain = INITIAL_STATE.verifiedDomain
      state.verifyDomainApiStatus = INITIAL_STATE.verifyDomainApiStatus
    },

    resetDeletedDomain: state => {
      state.deletedDomain = INITIAL_STATE.deletedDomain
      state.deleteDomainApiStatus = INITIAL_STATE.deleteDomainApiStatus
    },
    resetFraudData: state => {
      state.getDmarcFraudUnifiedDataApiStatus = INITIAL_STATE.getDmarcFraudUnifiedDataApiStatus
      state.dmarcFraudUnifiedData = INITIAL_STATE.dmarcFraudUnifiedData
    },
    resetFraudDataMap: state => {
      state.getDmarcFraudUnifiedMapDataApiStatus = INITIAL_STATE.getDmarcFraudUnifiedMapDataApiStatus
      state.dmarcFraudUnifiedMapData = INITIAL_STATE.dmarcFraudUnifiedMapData
    },
    resetFraudRufData: (state, action: PayloadAction<HardResetReport>) => {
      state.dmarcFraudUnifiedRufData =
        resetReport(state.dmarcFraudUnifiedRufData, action.payload) || INITIAL_STATE.dmarcFraudUnifiedRufData
      state.loadedDmarcFraudRufOffsets = INITIAL_STATE.loadedDmarcFraudRufOffsets
    },
    resetAllApprovedSources: state => {
      state.allApprovedSources = INITIAL_STATE.allApprovedSources
      state.getAllApprovedSourcesApiStatus = INITIAL_STATE.getAllApprovedSourcesApiStatus
      state.loadedAllApprovedSourcesOffsets = INITIAL_STATE.loadedAllApprovedSourcesOffsets
    },

    resetAllRejectedSources: state => {
      state.allRejectedSources = INITIAL_STATE.allRejectedSources
      state.getAllRejectedSourcesApiStatus = INITIAL_STATE.getAllRejectedSourcesApiStatus
      state.loadedAllRejectedSourcesOffsets = INITIAL_STATE.loadedAllRejectedSourcesOffsets
    },

    resetDmarcHighVolumeSources: state => {
      state.dmarcHighVolumeSources = INITIAL_STATE.dmarcHighVolumeSources
      state.getDmarcHighVolumeSourcesApiStatus = INITIAL_STATE.getDmarcHighVolumeSourcesApiStatus
      state.loadedDmarcHighVolumeSourcesOffsets = INITIAL_STATE.loadedDmarcHighVolumeSourcesOffsets
    },

    resetDmarcLowVolumeSources: state => {
      state.dmarcLowVolumeSources = INITIAL_STATE.dmarcLowVolumeSources
      state.getDmarcLowVolumeSourcesApiStatus = INITIAL_STATE.getDmarcLowVolumeSourcesApiStatus
      state.loadedDmarcLowVolumeSourcesOffsets = INITIAL_STATE.loadedDmarcLowVolumeSourcesOffsets
    },

    resetApprovalStatuses: state => {
      state.setApprovalStatusForSenderApiStatus = INITIAL_STATE.setApprovalStatusForSenderApiStatus
      state.setApprovalStatusForVolumeGroupApiStatus = INITIAL_STATE.setApprovalStatusForVolumeGroupApiStatus
    },

    resetDmarcDkimPassingHosts: state => {
      state.dmarcDkimPassingHosts = INITIAL_STATE.dmarcDkimPassingHosts
      state.getDmarcDkimPassingHostsApiStatus = INITIAL_STATE.getDmarcDkimPassingHostsApiStatus
    },

    resetDmarcSubdomains: state => {
      state.dmarcFailedDomains = INITIAL_STATE.dmarcFailedDomains
      state.getDmarcFailedSubdomainsApiStatus = INITIAL_STATE.getDmarcFailedSubdomainsApiStatus
      state.loadedDmarcFailedSubdomainsOffsets = INITIAL_STATE.loadedDmarcFailedSubdomainsOffsets
      state.dmarcPassedDomains = INITIAL_STATE.dmarcPassedDomains
      state.getDmarcPassedSubdomainsApiStatus = INITIAL_STATE.getDmarcPassedSubdomainsApiStatus
      state.loadedDmarcPassedSubdomainsOffsets = INITIAL_STATE.loadedDmarcPassedSubdomainsOffsets
    },

    resetRufSamples: state => {
      state.rufSamples = INITIAL_STATE.rufSamples
      state.getRufSamplesApiStatus = INITIAL_STATE.getRufSamplesApiStatus
      state.loadedRufSamplesOffsets = INITIAL_STATE.loadedRufSamplesOffsets
    },

    resetRufSample: state => {
      state.rufSample = INITIAL_STATE.rufSample
      state.getRufSampleApiStatus = INITIAL_STATE.getRufSampleApiStatus
    },

    // resetDmarc
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  },
  extraReducers: builder => {
    builder
      // getCompanyDomains
      .addCase(getCompanyDomains.pending, state => {
        state.getCompanyDomainsApiStatus = inProgress
      })
      .addCase(getCompanyDomains.fulfilled, (state, action) => {
        state.getCompanyDomainsApiStatus = successResponse
        state.companyDomains = orderBy(action.payload, ['userCount', 'name'], ['desc', 'asc'])
      })
      .addCase(getCompanyDomains.rejected, (state, action) => {
        state.getCompanyDomainsApiStatus = failedResponse(action.payload)
      })

      // getDomainTotals
      .addCase(getDomainTotals.pending, state => {
        state.getDomainTotalsApiStatus = inProgress
      })
      .addCase(getDomainTotals.fulfilled, (state, action) => {
        state.getDomainTotalsApiStatus = successResponse
        state.domainTotals = action.payload
      })
      .addCase(getDomainTotals.rejected, (state, action) => {
        state.getDomainTotalsApiStatus = failedResponse(action.payload)
      })

      // getDomainsStats
      .addCase(getDomainsStats.pending, state => {
        state.getDomainsStatsApiStatus = inProgress
      })
      .addCase(getDomainsStats.fulfilled, (state, action) => {
        state.getDomainsStatsApiStatus = successResponse
        state.domainsStats = action.payload
      })
      .addCase(getDomainsStats.rejected, (state, action) => {
        state.getDomainsStatsApiStatus = failedResponse(action.payload)
      })

      // resolveDmarc
      .addCase(resolveDmarc.pending, state => {
        state.resolveDmarcApiStatus = inProgress
        state.resolvedDmarc = INITIAL_STATE.resolvedDmarc
      })
      .addCase(resolveDmarc.fulfilled, (state, action) => {
        state.resolveDmarcApiStatus = successResponse
        state.resolvedDmarc = action.payload
      })
      .addCase(resolveDmarc.rejected, (state, action) => {
        state.resolveDmarcApiStatus = failedResponse(action.payload)
      })

      // resolveSpf
      .addCase(resolveSpf.pending, state => {
        state.resolveSpfApiStatus = inProgress
        state.resolvedSpf = INITIAL_STATE.resolvedSpf
      })
      .addCase(resolveSpf.fulfilled, (state, action) => {
        state.resolveSpfApiStatus = successResponse
        state.resolvedSpf = action.payload
      })
      .addCase(resolveSpf.rejected, (state, action) => {
        state.resolveSpfApiStatus = failedResponse(action.payload)
      })

      // setupDmarc
      .addCase(setupDmarc.pending, state => {
        state.setupDmarcApiStatus = inProgress
      })
      .addCase(setupDmarc.fulfilled, state => {
        state.setupDmarcApiStatus = successResponse
      })
      .addCase(setupDmarc.rejected, (state, action) => {
        state.setupDmarcApiStatus = failedResponse(action.payload)
      })

      // addDomain
      .addCase(addDomain.pending, state => {
        state.addDomainApiStatus = inProgress
      })
      .addCase(addDomain.fulfilled, (state, action) => {
        state.addDomainApiStatus = successResponse
        state.verifiedDomain = action.payload
        state.addedDomain = action.payload
      })
      .addCase(addDomain.rejected, (state, action) => {
        state.addDomainApiStatus = failedResponse(action.payload)
      })

      // verifyDomain
      .addCase(verifyDomain.pending, state => {
        state.verifyDomainApiStatus = inProgress
      })
      .addCase(verifyDomain.fulfilled, (state, action) => {
        state.verifyDomainApiStatus = successResponse
        state.verifiedDomain = action.payload
      })
      .addCase(verifyDomain.rejected, (state, action) => {
        state.verifyDomainApiStatus = failedResponse(action.payload)
      })

      // deleteDomain
      .addCase(deleteDomain.pending, state => {
        state.deleteDomainApiStatus = inProgress
      })
      .addCase(deleteDomain.fulfilled, (state, action) => {
        state.deleteDomainApiStatus = successResponse
        state.deletedDomain = action.payload
      })
      .addCase(deleteDomain.rejected, (state, action) => {
        state.deleteDomainApiStatus = failedResponse(action.payload)
      })

      // getDmarcFraudUnifiedData
      .addCase(getDmarcFraudUnifiedData.pending, (state, action) => {
        if (action.meta.arg.mapView) {
          state.getDmarcFraudUnifiedMapDataApiStatus = inProgress
        } else {
          state.getDmarcFraudUnifiedDataApiStatus = inProgress
        }
      })
      .addCase(getDmarcFraudUnifiedData.fulfilled, (state, action) => {
        if (action.meta.arg.mapView) {
          state.getDmarcFraudUnifiedMapDataApiStatus = successResponse
          state.dmarcFraudUnifiedMapData = action.payload
        } else {
          state.getDmarcFraudUnifiedDataApiStatus = successResponse
          state.dmarcFraudUnifiedData = action.payload
        }
      })
      .addCase(getDmarcFraudUnifiedData.rejected, (state, action) => {
        if (action.meta.arg.mapView) {
          state.getDmarcFraudUnifiedMapDataApiStatus = failedResponse(action.payload as string)
        } else {
          state.getDmarcFraudUnifiedDataApiStatus = failedResponse(action.payload as string)
        }
      })

      // getDmarcFraudUnifiedRufData
      .addCase(getDmarcFraudUnifiedRufData.pending, state => {
        state.getDmarcFraudUnifiedRufDataApiStatus = inProgress
        if (!state.loadedDmarcFraudRufOffsets.length) {
          state.loadedDmarcFraudRufOffsets = [dmarcFraudUnifiedRufDataTableInitialState.skip]
        }
      })
      .addCase(getDmarcFraudUnifiedRufData.fulfilled, (state, action) => {
        const { report, offset } = action.payload

        state.dmarcFraudUnifiedRufData = {
          ...report,
          results: insertToArray(state.dmarcFraudUnifiedRufData?.results || [], report?.results, offset)
        }
        state.getDmarcFraudUnifiedRufDataApiStatus = successResponse
      })
      .addCase(getDmarcFraudUnifiedRufData.rejected, (state, action) => {
        state.getDmarcFraudUnifiedRufDataApiStatus = failedResponse(action.payload as string)
      })

      // getRufSample
      .addCase(getRufSample.pending, state => {
        state.getRufSampleApiStatus = inProgress
      })
      .addCase(getRufSample.fulfilled, (state, action) => {
        state.getRufSampleApiStatus = successResponse
        state.rufSample = action.payload
      })
      .addCase(getRufSample.rejected, (state, action) => {
        state.getRufSampleApiStatus = failedResponse(action.payload as string)
      })

      // getAllApprovedSources
      .addCase(updateDmarcAllApprovedTable, (state, action) => {
        if (action.payload.config?.skip !== undefined) {
          state.loadedAllApprovedSourcesOffsets = union(state.loadedAllApprovedSourcesOffsets, [
            action.payload.config.skip
          ])
        }
      })
      .addCase(getAllApprovedSources.pending, state => {
        state.getAllApprovedSourcesApiStatus = inProgress
        if (!state.loadedAllApprovedSourcesOffsets.length) {
          state.loadedAllApprovedSourcesOffsets = [dmarcAllApprovedTableInitialState.skip]
        }
      })
      .addCase(getAllApprovedSources.fulfilled, (state, action) => {
        const { report, offset } = action.payload

        state.getAllApprovedSourcesApiStatus = successResponse
        state.allApprovedSources = {
          ...report,
          results: insertToArray(state.allApprovedSources?.results || [], report?.results, offset)
        }
      })
      .addCase(getAllApprovedSources.rejected, (state, action) => {
        state.getAllApprovedSourcesApiStatus = failedResponse(action.payload)
      })

      // getAllRejectedSources
      .addCase(updateDmarcAllRejectedTable, (state, action) => {
        if (action.payload.config?.skip !== undefined) {
          state.loadedAllRejectedSourcesOffsets = union(state.loadedAllRejectedSourcesOffsets, [
            action.payload.config.skip
          ])
        }
      })
      .addCase(getAllRejectedSources.pending, state => {
        state.getAllRejectedSourcesApiStatus = inProgress
        if (!state.loadedAllRejectedSourcesOffsets.length) {
          state.loadedAllRejectedSourcesOffsets = [dmarcAllRejectedTableInitialState.skip]
        }
      })
      .addCase(getAllRejectedSources.fulfilled, (state, action) => {
        const { report, offset } = action.payload

        state.getAllRejectedSourcesApiStatus = successResponse
        state.allRejectedSources = {
          ...report,
          results: insertToArray(state.allRejectedSources?.results || [], report?.results, offset)
        }
      })
      .addCase(getAllRejectedSources.rejected, (state, action) => {
        state.getAllRejectedSourcesApiStatus = failedResponse(action.payload)
      })

      // getDmarcHighVolumeSources
      .addCase(updateDmarcHighVolumeSourcesTable, (state, action) => {
        if (action.payload.config?.skip !== undefined) {
          state.loadedDmarcHighVolumeSourcesOffsets = union(state.loadedDmarcHighVolumeSourcesOffsets, [
            action.payload.config.skip
          ])
        }
      })
      .addCase(getDmarcHighVolumeSources.pending, state => {
        state.getDmarcHighVolumeSourcesApiStatus = inProgress
        if (!state.loadedDmarcHighVolumeSourcesOffsets.length) {
          state.loadedDmarcHighVolumeSourcesOffsets = [dmarcHighVolumeSourcesTableInitialState.skip]
        }
      })
      .addCase(getDmarcHighVolumeSources.fulfilled, (state, action) => {
        const { report, offset } = action.payload

        state.getDmarcHighVolumeSourcesApiStatus = successResponse
        state.dmarcHighVolumeSources = {
          ...report,
          results: insertToArray(state.dmarcHighVolumeSources?.results || [], report?.results, offset)
        }
      })
      .addCase(getDmarcHighVolumeSources.rejected, (state, action) => {
        state.getDmarcHighVolumeSourcesApiStatus = failedResponse(action.payload)
      })

      // getDmarcLowVolumeSources
      .addCase(updateDmarcLowVolumeSourcesTable, (state, action) => {
        if (action.payload.config?.skip !== undefined) {
          state.loadedDmarcLowVolumeSourcesOffsets = union(state.loadedDmarcLowVolumeSourcesOffsets, [
            action.payload.config.skip
          ])
        }
      })
      .addCase(getDmarcLowVolumeSources.pending, state => {
        state.getDmarcLowVolumeSourcesApiStatus = inProgress
        if (!state.loadedDmarcLowVolumeSourcesOffsets.length) {
          state.loadedDmarcLowVolumeSourcesOffsets = [dmarcLowVolumeSourcesTableInitialState.skip]
        }
      })
      .addCase(getDmarcLowVolumeSources.fulfilled, (state, action) => {
        const { report, offset } = action.payload

        state.getDmarcLowVolumeSourcesApiStatus = successResponse
        state.dmarcLowVolumeSources = {
          ...report,
          results: insertToArray(state.dmarcLowVolumeSources?.results || [], report?.results, offset)
        }
      })
      .addCase(getDmarcLowVolumeSources.rejected, (state, action) => {
        state.getDmarcLowVolumeSourcesApiStatus = failedResponse(action.payload)
      })

      // setApprovalStatusForSender
      .addCase(setApprovalStatusForSender.pending, state => {
        state.setApprovalStatusForSenderApiStatus = inProgress
        state.setApprovalStatusForVolumeGroupApiStatus = inIdle
      })
      .addCase(setApprovalStatusForSender.fulfilled, (state, action) => {
        state.setApprovalStatusForSenderApiStatus = successResponse

        const { sourceProperty, sender, status } = action.payload

        if (sourceProperty) {
          const isHighSource = sourceProperty === DmarcSourceTypes.high
          const source = (isHighSource ? state.dmarcHighVolumeSources : state.dmarcLowVolumeSources)?.results.find(
            dmarcSource => {
              return dmarcSource.host === sender
            }
          )

          if (source) {
            source.inProgressAction = status
          }
        }
      })
      .addCase(setApprovalStatusForSender.rejected, (state, action) => {
        state.setApprovalStatusForSenderApiStatus = failedResponse(action.payload)
      })

      // setApprovalStatusForVolumeGroup
      .addCase(setApprovalStatusForVolumeGroup.pending, state => {
        state.setApprovalStatusForVolumeGroupApiStatus = inProgress
        state.setApprovalStatusForSenderApiStatus = inIdle
      })
      .addCase(setApprovalStatusForVolumeGroup.fulfilled, (state, action) => {
        state.setApprovalStatusForVolumeGroupApiStatus = successResponse

        const { status, sourceProperty } = action.payload

        if (sourceProperty) {
          const source =
            sourceProperty === DmarcSourceTypes.high ? state.dmarcHighVolumeSources : state.dmarcLowVolumeSources
          source?.results.forEach(dmarcSource => {
            dmarcSource.inProgressAction = status
          })
        }
      })
      .addCase(setApprovalStatusForVolumeGroup.rejected, (state, action) => {
        state.setApprovalStatusForVolumeGroupApiStatus = failedResponse(action.payload)
      })

      // getDmarcDkimPassingHosts
      .addCase(getDmarcDkimPassingHosts.pending, state => {
        state.getDmarcDkimPassingHostsApiStatus = inProgress
      })
      .addCase(getDmarcDkimPassingHosts.fulfilled, (state, action) => {
        state.getDmarcDkimPassingHostsApiStatus = successResponse
        state.dmarcDkimPassingHosts = action.payload
      })
      .addCase(getDmarcDkimPassingHosts.rejected, (state, action) => {
        state.getDmarcDkimPassingHostsApiStatus = failedResponse(action.payload)
      })

      // getDmarcFailedSubdomains
      .addCase(updateDmarcFailedSubdomainsTable, (state, action) => {
        if (action.payload.config?.skip !== undefined) {
          state.loadedDmarcFailedSubdomainsOffsets = union(state.loadedDmarcFailedSubdomainsOffsets, [
            action.payload.config.skip
          ])
        }
      })
      .addCase(getDmarcFailedSubdomains.pending, state => {
        state.getDmarcFailedSubdomainsApiStatus = inProgress
        if (!state.loadedDmarcFailedSubdomainsOffsets.length) {
          state.loadedDmarcFailedSubdomainsOffsets = [dmarcFailedSubdomainsTableInitialState.skip]
        }
      })
      .addCase(getDmarcFailedSubdomains.fulfilled, (state, action) => {
        const { report, offset } = action.payload

        state.getDmarcFailedSubdomainsApiStatus = successResponse
        state.dmarcFailedDomains = {
          ...report,
          results: insertToArray(state.dmarcFailedDomains?.results || [], report?.results, offset)
        }
      })
      .addCase(getDmarcFailedSubdomains.rejected, (state, action) => {
        state.getDmarcFailedSubdomainsApiStatus = failedResponse(action.payload)
      })

      // getDmarcPassedSubdomains
      .addCase(updateDmarcPassedSubdomainsTable, (state, action) => {
        if (action.payload.config?.skip !== undefined) {
          state.loadedDmarcPassedSubdomainsOffsets = union(state.loadedDmarcPassedSubdomainsOffsets, [
            action.payload.config.skip
          ])
        }
      })
      .addCase(getDmarcPassedSubdomains.pending, state => {
        state.getDmarcPassedSubdomainsApiStatus = inProgress
        if (!state.loadedDmarcPassedSubdomainsOffsets.length) {
          state.loadedDmarcPassedSubdomainsOffsets = [dmarcPassedSubdomainsTableInititalState.skip]
        }
      })
      .addCase(getDmarcPassedSubdomains.fulfilled, (state, action) => {
        const { report, offset } = action.payload

        state.getDmarcPassedSubdomainsApiStatus = successResponse
        state.dmarcPassedDomains = {
          ...report,
          results: insertToArray(state.dmarcPassedDomains?.results || [], report?.results, offset)
        }
      })
      .addCase(getDmarcPassedSubdomains.rejected, (state, action) => {
        state.getDmarcPassedSubdomainsApiStatus = failedResponse(action.payload)
      })

      // getRufSamples
      .addCase(updateRufSamplesTable, (state, action) => {
        if (action.payload.config?.skip !== undefined) {
          state.loadedRufSamplesOffsets = union(state.loadedRufSamplesOffsets, [action.payload.config.skip])
        }
      })
      .addCase(getRufSamples.pending, state => {
        state.getRufSamplesApiStatus = inProgress
        if (!state.loadedRufSamplesOffsets.length) {
          state.loadedAllApprovedSourcesOffsets = [rufSamplesTableInitialState.skip]
        }
      })
      .addCase(getRufSamples.fulfilled, (state, action) => {
        const { report, offset } = action.payload

        state.getRufSamplesApiStatus = successResponse
        state.rufSamples = {
          ...report,
          results: insertToArray(state.rufSamples?.results || [], report?.results, offset)
        }
      })
      .addCase(getRufSamples.rejected, (state, action) => {
        state.getRufSamplesApiStatus = failedResponse(action.payload)
      })
  }
})
/* eslint-enable no-param-reassign */

export const {
  resetCompanyDomains,
  resetDomainTotals,
  resetDomainsStats,
  resetResolvedDmarc,
  resetResolveSpf,
  resetSetupDmarc,
  resetAddDomain,
  resetVerifiedDomain,
  resetDeletedDomain,
  resetFraudData,
  resetFraudDataMap,
  resetFraudRufData,
  resetAllApprovedSources,
  resetAllRejectedSources,
  resetDmarcHighVolumeSources,
  resetDmarcLowVolumeSources,
  resetApprovalStatuses,
  resetDmarcDkimPassingHosts,
  resetDmarcSubdomains,
  resetRufSamples,
  resetRufSample,
  reset
} = dmarcSlice.actions

export {
  getCompanyDomains,
  getDomainTotals,
  getDomainsStats,
  resolveDmarc,
  resolveSpf,
  setupDmarc,
  addDomain,
  verifyDomain,
  deleteDomain,
  getDmarcFraudUnifiedData,
  getDmarcFraudUnifiedRufData,
  getRufSample,
  getAllApprovedSources,
  getAllRejectedSources,
  getDmarcHighVolumeSources,
  getDmarcLowVolumeSources,
  setApprovalStatusForSender,
  setApprovalStatusForVolumeGroup,
  getDmarcDkimPassingHosts,
  getDmarcFailedSubdomains,
  getDmarcPassedSubdomains,
  getRufSamples
}

export default dmarcSlice.reducer
