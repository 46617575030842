import { makeStyles } from '@material-ui/core/styles'

import tableStyles, { TABLE_HEADER_HEIGHT, TABLE_FOOTER_HEIGHT } from 'global/components/lib/grid/tableStyles'

const CELL_HEIGHT = 81
export { TABLE_HEADER_HEIGHT, TABLE_FOOTER_HEIGHT }
export const TABLE_CELL_HEIGHT = CELL_HEIGHT + 1

export default makeStyles(theme => {
  const defaultTableStyles = tableStyles(theme, CELL_HEIGHT)

  return {
    root: {
      position: 'relative',
      marginTop: theme.spacing(4),
      flexGrow: 1,
      marginBottom: theme.spacing(4)
    },
    tableWrapper: {
      marginTop: theme.spacing(3),
      padding: theme.spacing(2),
      backgroundColor: 'white'
    },
    tableTotal: {
      width: '100%',
      color: theme.palette.text.primary,
      fontSize: 24,
      fontWeight: 'bold',
      letterSpacing: 0,
      lineHeight: '40px',
      paddingBottom: theme.spacing(1),
      borderBottom: '1px solid #DDDDDD'
    },
    searchField: {
      marginTop: 20
    },
    ...defaultTableStyles,
    tableArea: {
      ...defaultTableStyles.tableArea,
      marginTop: theme.spacing(2),
      minHeight: 413
    },
    dataTable: {
      ...defaultTableStyles.dataTable,
      height: (TABLE_CELL_HEIGHT + 1) * 10 + TABLE_HEADER_HEIGHT + TABLE_FOOTER_HEIGHT,

      '& .k-grid-norecords': {
        height: 335
      },

      '& td': {
        lineHeight: '20px'
      }
    },
    statusCell: {
      ...defaultTableStyles.normalCell,
      marginLeft: 10,
      lineHeight: '20px'
    },
    stateText: {
      marginLeft: 30
    },
    dmarcStateIcon: {
      height: '100%',
      width: 20
    }
  }
})
