import React, { useMemo } from 'react'

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Select,
  SelectLabel,
  SelectMenuItem,
  SelectWrapper,
  Typography,
  Zoom
} from '@barracuda-internal/bds-core'

import { FormHelperText } from '@material-ui/core'

import { TrainingGroupDialogConfigProps } from 'fir/components/pages/remediation/incidentDetails/userDetailsByIncident/useUserDetailsByIncidentLogic'
import styles from 'fir/components/pages/remediation/incidentDetails/userDetailsByIncident/trainingGroupDialog/trainingGroupDialogStyles'
import { GetSecurityTrainingInstancesApiResponse } from 'fir/redux/types/SecurityTraining'

import ChipList from 'global/components/lib/chipList/ChipList'

const TrainingGroupDialog: React.FC<TrainingGroupDialogConfigProps> = props => {
  const classes = styles()

  return useMemo(() => {
    const {
      addressBookName,
      createAddressBookErrorMessage,
      formatMessage,
      isCreateAddressBookLoading,
      isOpened,
      onClose,
      onCreateAddressBook,
      selectInstanceError,
      selectedInstance,
      selectedRecipients,
      sortedInstancesName,
      updateInstance
    } = props

    return (
      <Dialog fullWidth maxWidth="md" open={isOpened} TransitionComponent={Zoom}>
        {isCreateAddressBookLoading ? (
          <LinearProgress />
        ) : (
          <>
            <DialogTitle className={classes.title}>
              <Typography variant="h4" component="span">
                {formatMessage('title')}
              </Typography>
            </DialogTitle>
            {createAddressBookErrorMessage && (
              <Alert className={classes.alert} data-field="error" severity="error">
                {createAddressBookErrorMessage}
              </Alert>
            )}
            <DialogContent>
              <Typography className={classes.content} variant="body2" gutterBottom>
                {formatMessage('train_employees_by_selecting_address_book')}
              </Typography>
              {sortedInstancesName.length > 1 && (
                <>
                  <Typography className={classes.content} variant="body2" gutterBottom>
                    {formatMessage('more_security_training_accounts')}
                  </Typography>
                  <SelectWrapper className={classes.selectWrapper} error={selectInstanceError}>
                    <SelectLabel id="demo-simple-select-autowidth-label">
                      {formatMessage('security_awareness_training_account')}
                    </SelectLabel>
                    <Select
                      autoWidth
                      id="demo-simple-select-autowidth"
                      label="Security Awareness Training account"
                      labelId="demo-simple-select-autowidth-label"
                      value={selectedInstance}
                      onChange={updateInstance}
                    >
                      {sortedInstancesName.map((instance: GetSecurityTrainingInstancesApiResponse) => {
                        return (
                          <SelectMenuItem key={instance.name} value={instance.name}>
                            {instance.name}
                          </SelectMenuItem>
                        )
                      })}
                    </Select>
                    {selectInstanceError && (
                      <FormHelperText className={classes.formHelperText}>
                        {formatMessage('error.must_select_instance')}
                      </FormHelperText>
                    )}
                  </SelectWrapper>
                </>
              )}

              <Typography className={`${classes.content} ${classes.subTitle}`} variant="body2" gutterBottom>
                {formatMessage('selected_recipients', { count: selectedRecipients.length })}
              </Typography>
              <ChipList list={selectedRecipients} />
              <Typography className={`${classes.content} ${classes.subTitle}`} variant="body2" gutterBottom>
                {formatMessage('address_book_to_be_created')}
              </Typography>
              <Typography>{addressBookName}</Typography>
            </DialogContent>
            <DialogActions>
              <Button color="primary" variant="text" onClick={onClose}>
                {formatMessage(!createAddressBookErrorMessage ? 'buttons.cancel' : 'buttons.close')}
              </Button>
              {!createAddressBookErrorMessage && (
                <Button variant="contained" color="primary" onClick={onCreateAddressBook}>
                  {formatMessage('buttons.create')}
                </Button>
              )}
            </DialogActions>
          </>
        )}
      </Dialog>
    )
  }, [classes, props])
}

export default TrainingGroupDialog
