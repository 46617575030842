import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { validateApiError } from 'global/lib/api/restClient'
import { globalApiRoutes } from 'global/lib/api/apiRoutes'

export interface GetSpAttack {
  attackId: string
  accessTokenId?: string
}

export interface GetSpAttackId {
  feedbackPublicKey: string
}

export const getSpAttack = createAsyncThunk('ATTACK/getSpAttack', async function doGetSpAttack(
  payload: GetSpAttack,
  { rejectWithValue }
) {
  const { accessTokenId, attackId } = payload

  try {
    const resp = await restClient(globalApiRoutes.SP_ATTACK_DETAILS, {
      data: { accessTokenId, attackId }
    })

    const { attackAttributes } = resp.data.attack
    if (attackAttributes) {
      attackAttributes.highlighting_analysis_attributes = attackAttributes.highlighting_analysis_attributes || {}
      attackAttributes.highlighting_keywords = attackAttributes.highlighting_keywords || []
      attackAttributes.highlighting_suspicious_attachments = attackAttributes.highlighting_suspicious_attachments || []
      attackAttributes.highlighting_suspicious_headers = attackAttributes.highlighting_suspicious_headers || []
    }
    return resp.data.attack
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getSpAttackId = createAsyncThunk('ATTACK/getSpAttackId', async function doGetSpAttackId(
  payload: GetSpAttackId,
  { rejectWithValue }
) {
  const { feedbackPublicKey } = payload

  try {
    const resp = await restClient(globalApiRoutes.SP_ATTACK_ID, {
      data: { feedbackPublicKey }
    })
    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})
