import React, { useMemo } from 'react'

import ReactCopyToClipboard from 'react-copy-to-clipboard'

import { Button, Typography, Grid, Tooltip } from '@barracuda-internal/bds-core'
import { Copy as CopyIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'

import { useFormatMessage } from 'global/lib/localization'
import styles from 'global/components/lib/copyToClipboard/copyToClipboardStyles'
import useCopyToClipboardLogic from 'global/components/lib/copyToClipboard/useCopyToClipboardLogic'

const BASE_I18N_KEY = 'app.copy_to_clipboard'

export interface CopyToClipboardProps {
  textToCopy: string
  withCopyIcon?: boolean
  withCopiedMessage?: boolean
  customButtonText?: string
  positionCopiedMessageToRight?: boolean
  tooltip?: string
  analyticsEventName?: string
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({
  textToCopy,
  withCopyIcon = true,
  withCopiedMessage = true,
  positionCopiedMessageToRight = false,
  customButtonText,
  tooltip,
  analyticsEventName
}) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const [onCopyToClipBoard, isCopiedVisible] = useCopyToClipboardLogic(analyticsEventName, textToCopy)

  return useMemo(
    () => (
      <Grid className={classes.wrapper} container direction="row">
        {!positionCopiedMessageToRight && withCopiedMessage && isCopiedVisible && (
          <Typography className={classes.copyTextLeft}>{formatMessage('copied')}</Typography>
        )}
        <Tooltip
          className={classes.copyIcon}
          classes={{ arrow: classes.copyArrow }}
          disableHoverListener={!tooltip}
          title={tooltip || ''}
          arrow
        >
          <ReactCopyToClipboard text={textToCopy} onCopy={onCopyToClipBoard}>
            <Button color="primary" variant="text" aria-label="copyToClipboard">
              {withCopyIcon && <CopyIcon className={classes.copyIcon} />}
              {customButtonText || formatMessage('button')}
            </Button>
          </ReactCopyToClipboard>
        </Tooltip>
        {positionCopiedMessageToRight && withCopiedMessage && isCopiedVisible && (
          <Typography className={classes.copyTextRight}>{formatMessage('copied')}</Typography>
        )}
      </Grid>
    ),
    [
      withCopiedMessage,
      classes,
      textToCopy,
      onCopyToClipBoard,
      isCopiedVisible,
      withCopyIcon,
      formatMessage,
      customButtonText,
      positionCopiedMessageToRight,
      tooltip
    ]
  )
}

export default CopyToClipboard
