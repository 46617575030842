import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => {
  return {
    loading: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5)
    },
    chart: {
      height: 430,
      width: 650,
      margin: 'auto'
    }
  }
})
