import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from 'global/configs/theme/defaults'

export default makeStyles(theme => ({
  mastHeadButton: {
    margin: `0 ${theme.spacing(1)}px`,
    height: theme.spacing(4.5),
    padding: theme.spacing(2)
  },
  icon: {
    color: COLORS.BLACK_50,
    cursor: 'pointer',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1)
  }
}))
