import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  cardGrid: {
    alignItems: 'stretch',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  header: {
    margin: theme.spacing(2),
    position: 'relative',
    zIndex: 1
  },
  noDataWrapper: {
    width: '100%',
    height: 200,
    textAlign: 'center',
    paddingTop: 50,
    opacity: 0.5
  },
  pageAlert: {
    margin: theme.spacing(0, 2, 3, 2)
  },
  root: {
    display: 'block',
    position: 'relative'
  },
  settings: {
    float: 'right'
  },
  wrapper: {
    padding: theme.spacing(0, 2, 2, 2)
  }
}))
