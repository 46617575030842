import { useReducer, useMemo, useEffect, useCallback, useRef, Ref } from 'react'

import { process } from '@progress/kendo-data-query'

import { AlertProps } from 'global/components/lib/alerts/Alert'
import { useFormatMessage } from 'global/lib/localization'
import buildApiFilter, { Filter } from 'global/lib/buildApiFilter'
import * as datetime from 'global/lib/datetime'
import { config } from 'global/lib/config'
import useUserDataLib from 'global/lib/userData/useUserData'
import useFeatureLib from 'global/lib/feature/useFeature'
import useProductLib from 'global/lib/product/useProduct'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'
import { luxonDate } from 'global/lib/datetime'
import { getErrorMessage, isFailed, isPending, isSuccess } from 'global/redux/toolkit/api'

import { IncidentDetailsSource, NewIncident } from 'global/types/api/newIncident'
import { Email } from 'global/types/api/emailsType'
import { IncidentTag } from 'global/types/api/remediation'

import useEmailDetailsInterface from 'fir/components/lib/dialogs/emailDetailsDialog/useEmailDetailsInterface'
import routesConfig from 'fir/lib/routes/routesConfig'
import {
  forensicsCreateIncident,
  forensicsGetIncidentsTags,
  resetCreateIncident
} from 'fir/redux/features/remediation/remediationSlice'
import * as newIncidentTableActions from 'fir/redux/features/dataTables/newIncident/newIncidentSlice'
import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'
import { reset as resetViewEmail } from 'fir/redux/features/emails/emailsSlice'
import {
  getEmails,
  getMoreEmails,
  resetEmails,
  updateForm,
  updatePolicyOptions,
  updateUserOptions
} from 'fir/redux/features/newIncident/newIncidentSlice'
import {
  getForensicsNotificationPreview,
  getForensicsCustomNotification
} from 'fir/redux/features/settings/settingsSlice'

import { TAG_VALIDATOR } from 'fir/redux/types/Remediation'
import {
  CUSTOM_TEMPLATE_NAME,
  DEFAULT_NOTIFICATION_PREVIEW_CONTEXT,
  DEFAULT_TEMPLATE_NAME
} from 'fir/redux/types/Settings'

export type NewIncidentLogicParams = {
  incidentDetailsSource: string
  country?: string
  emailInfo?: Email
  errorMsg?: string
  isEntitledBCS?: boolean
  isEntitledESS?: boolean
  onSuccess?: () => void
  demoAlertConfig?: AlertProps
  generalAlertConfig?: AlertProps
  isOpened: boolean
  onClose: (activeStep?: number) => void
}

export type ButtonConfig = {
  cancel?: string
  onNext?: string
  onPrev?: string
  disabled?: boolean
  disableNext?: boolean
}

export type AlertConfig = {
  alertContent: string | undefined
  closeAction: () => void
  showClose: boolean
}

export type NewIncidentLogic = {
  activeStep: number
  alertConfig: AlertConfig
  buttons: ButtonConfig[]
  contentConfig: any
  dialogActions: Ref<HTMLElement | null>
  errorMsg: string | undefined
  isCreatingIncident: boolean
  isEntitledBCS: boolean
  isEntitledESS: boolean
  onNextStep: () => void
  onPrevStep: () => void
}

export interface GridRow {
  emailId: string
  created: string
  formattedCreated: string
  sender: string
  mailbox: string
  subject: string
}

export interface AnalyticsData {
  accessTokenId?: string
  country?: string
  emailId?: string
  emailSubject?: string
  includeQuarantine?: boolean
  matchExact: boolean
  policyOptions?: PolicyOptions
  senderEmail?: string
  senderName?: string
  step?: number
  timeframe?: number
  userOptions?: UserOptions
}

export interface PolicyOptions {
  addSenderPolicy: boolean
  addBCSPolicy: boolean
  senderPolicyAction: string
  senderPolicyType: string
}

export interface UserOptions {
  alertAdmin: boolean
  alertRecipients: boolean
  deleteSelectedEmails: boolean
  isTurnOnContinuousRemediation: boolean
  notificationTemplate: string
}

export const STEP_TITLES = ['Create incident', 'Review messages', 'User options', 'Policy options', 'Follow-up']
export const STEPS = ['search', 'review_messages', 'user_options', 'policy_options', 'follow_up']

const ACTIVE_SETTING_STATE = 'true'
const DEFAULT_TIMEFRAME = 720
const BASE_I18N_KEY = 'fir.app.new_incident_wizard'

export default function useNewIncidentDialogLogic(params: NewIncidentLogicParams): [NewIncidentLogic] {
  const dispatch = useAppDispatch()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const dialogActions = useRef<HTMLDivElement>(null)
  const [featureLib] = useFeatureLib()
  const [productLib] = useProductLib()
  const [userDataLib] = useUserDataLib()
  const [isEditEmailAlertDialogVisible, toggleEditEmailDialog] = useDialogLogic()

  // Redux Toolkit stores
  const { accessToken, dataTables, emails, newIncident, remediation, settings, user } = useAppSelector(_stores => ({
    accessToken: _stores.accessToken,
    dataTables: _stores.dataTables,
    emails: _stores.emails,
    newIncident: _stores.newIncident,
    remediation: _stores.remediation,
    settings: _stores.settings,
    user: _stores.user
  }))

  // Redux Toolkit state
  const {
    accessTokenId,
    accessTokenSettings,
    isEmailsSuccess,
    newIncidentEmails,
    newIncidentForm,
    newIncidentUserOptions,
    newIncidentPolicyOptions,
    isEmailsFailed,
    isMoreEmailsFailed,
    isMoreEmailsLoading,
    isEmailsLoading,
    isFirDemoUser,
    isCreateIncidentSuccess,
    isCreateIncidentFailed,
    isCreateIncidentProcessing
  } = {
    accessTokenId: accessToken.accessToken?.id || '',
    accessTokenSettings: accessToken.accessToken?.settings || {},
    isEmailsSuccess: isSuccess(newIncident.getEmailsApiStatus),
    isEmailsFailed: isFailed(newIncident.getEmailsApiStatus),
    isEmailsLoading: isPending(newIncident.getEmailsApiStatus),
    isFirDemoUser: user.isFirDemoUser,
    isMoreEmailsFailed: isFailed(newIncident.getMoreEmailsApiStatus),
    isMoreEmailsLoading: isPending(newIncident.getMoreEmailsApiStatus),
    newIncidentEmails: newIncident.emails,
    newIncidentForm: newIncident.form,
    newIncidentUserOptions: newIncident.userOptions,
    newIncidentPolicyOptions: newIncident.policyOptions,
    isCreateIncidentSuccess: isSuccess(remediation.createIncidentApiStatus),
    isCreateIncidentFailed: isFailed(remediation.createIncidentApiStatus),
    isCreateIncidentProcessing: isPending(remediation.createIncidentApiStatus)
  }

  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    activeStep: 0,
    errorMsg: '',
    originalNotificationTemplate: accessTokenSettings.forensicsEndUserNotificationTemplate,
    tags: [],
    selectedEmail: null
  })
  const [emailDetailDialogConfig, emailDetailDialogActions] = useEmailDetailsInterface({
    onClose: () => setState({ selectedEmail: null })
  })

  // init
  useEffect(() => {
    if (params.emailInfo) {
      const initialFormValues = {
        ...newIncidentForm,
        attachmentName: params.emailInfo.attachmentName || '',
        bodyLinks: params.emailInfo.bodyLinks || '',
        bodyText: params.emailInfo.bodyText || '',
        senderName: params.emailInfo.sender.name || '',
        senderEmail: params.emailInfo.sender.address || '',
        emailSubject: params.emailInfo.subject || '',
        timeframe: params.emailInfo.timeframe || DEFAULT_TIMEFRAME
      }

      dispatch(resetViewEmail())
      dispatch(updateForm({ formValues: initialFormValues }))
      setState({ activeStep: 1 })
      loadEmails(initialFormValues)
    }

    setInitialNewIncidentConfig()

    if (!settings.notificationPreview.body.length) {
      const context = {
        emailDeleted: accessTokenSettings.forensicsIncidentDeleteEmails === ACTIVE_SETTING_STATE,
        ...DEFAULT_NOTIFICATION_PREVIEW_CONTEXT
      }

      if (accessTokenSettings.forensicsEndUserNotificationTemplate !== DEFAULT_TEMPLATE_NAME) {
        dispatch(getForensicsCustomNotification({ accessTokenId, context }))
      } else {
        dispatch(getForensicsNotificationPreview({ accessTokenId, context }))
      }
    }

    return () => {
      setState({ activeStep: 0 })
      dispatch(resetEmails())
      dispatch(newIncidentTableActions.reset())
      dispatch(updateForm({ formValues: { ...newIncidentForm } }))
      dispatch(resetCreateIncident())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // load more emails when the user switched the page
  useEffect(() => {
    if (
      isEmailsSuccess &&
      !!newIncidentEmails.totalCount &&
      !newIncidentEmails.results[dataTables.newIncident.page.skip]
    ) {
      dispatch(getMoreEmails({ config: apiParams() }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmailsSuccess, dataTables.newIncident.page.skip])

  // handle the search results about the form values
  useEffect(() => {
    if (isEmailsSuccess && state.activeStep === 0) {
      if (newIncidentEmails.totalCount) {
        setState({ activeStep: 1 })
        dispatch(updateForm({ formValues: { isFormError: false } }))
      } else {
        dispatch(updateForm({ formValues: { isFormError: true } }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isEmailsSuccess, newIncidentEmails.totalCount])

  // go to the final tab/start CRE after new incident created successfully
  useEffect(() => {
    if (state.activeStep === 3 && isCreateIncidentSuccess) {
      setState({ activeStep: 4 })
    }
  }, [dispatch, isCreateIncidentSuccess, state.activeStep])

  useEffect(() => {
    if (productLib.getForensicsSerialBundleForAccessToken(accessTokenId) === config.BUNDLES.BUNDLE1) {
      routesConfig.REMEDIATION.goto()
    }
  }, [accessTokenId, productLib])

  // handle errors
  useEffect(() => {
    if (isEmailsFailed || isMoreEmailsFailed) {
      setState({ errorMsg: formatMessage('errors.search_emails') })
    } else if (isCreateIncidentFailed) {
      // handle failed incident create
      const useDefaultError = getErrorMessage(remediation.createIncidentApiStatus) === 'default'
      setState({
        errorMsg: formatMessage(
          useDefaultError
            ? 'errors.create_incident'
            : `errors.${getErrorMessage(remediation.createIncidentApiStatus) as string}`
        )
      })
    } else if (isFirDemoUser && isCreateIncidentSuccess && newIncidentUserOptions.isTurnOnContinuousRemediation) {
      setState({
        activeStep: 4,
        errorMsg: formatMessage('errors.continuous_remediation_demo_error')
      })
    } else {
      // clear error
      setState({ errorMsg: '' })
    }
  }, [
    formatMessage,
    isCreateIncidentFailed,
    isCreateIncidentSuccess,
    isEmailsFailed,
    isFirDemoUser,
    isMoreEmailsFailed,
    newIncidentUserOptions.isTurnOnContinuousRemediation,
    remediation.createIncidentApiStatus
  ])

  // api params
  const apiParams: any = useCallback(
    (form = newIncidentForm) => {
      const { GRID_COLUMNS, page, sort } = dataTables.newIncident

      let filter = null

      if (form) {
        filter = buildApiFilter([
          {
            field: `${GRID_COLUMNS.SENDER}.address`,
            value: form.senderEmail
          },
          {
            field: form.isMatchExactFrame ? `${GRID_COLUMNS.SUBJECT}.exact` : GRID_COLUMNS.SUBJECT,
            value: form.emailSubject,
            operator: form.isMatchExactFrame ? 'contains-phrase' : 'contains'
          },
          {
            field: `${GRID_COLUMNS.SENDER}.name`,
            value: form.senderName
          },
          {
            field: 'body.text',
            value: form.bodyText
          },
          {
            field: 'body.links.href',
            value: form.bodyLinks
          },
          {
            field: 'attachmentName',
            value: form.attachmentName
          }
        ] as Filter[])
      }

      return {
        page: {
          offset: Number(page.skip),
          limit: Number(page.take)
        },
        countryId: params.country,
        timeframe: form.timeframe,
        includeQuarantined: form.isIncludeQuarantined,
        sort,
        filter
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.emailInfo, params.country, dataTables.newIncident, newIncidentForm]
  )

  const isInvalidCharLength = (char: string | undefined) => {
    return char ? !(char.length >= 3) : false
  }

  const buttonsConfig = useMemo(() => {
    const isInvalidInput =
      isInvalidCharLength(newIncidentForm.bodyText) ||
      isInvalidCharLength(newIncidentForm.bodyLinks) ||
      isInvalidCharLength(newIncidentForm.senderName)

    const emptyForm =
      !newIncidentForm.senderEmail &&
      !newIncidentForm.senderName &&
      !newIncidentForm.emailSubject &&
      !newIncidentForm.attachmentName &&
      !newIncidentForm.bodyText &&
      !newIncidentForm.bodyLinks
    return [
      {
        cancel: 'cancel',
        onNext: 'search_messages',
        disabled: isEmailsLoading,
        disableNext: emptyForm || isInvalidInput
      },
      {
        cancel: 'cancel',
        onPrev: 'refine_search',
        onNext: 'review_remediation_options',
        disabled: isEmailsLoading,
        disableNext:
          newIncidentEmails.results.length === 0 || emptyForm || productLib.hasLimitedForensicsAccess(accessTokenId)
      },
      { cancel: 'cancel', onPrev: 'back', onNext: 'next' },
      {
        cancel: 'cancel',
        onPrev: 'back',
        onNext: 'remediate',
        disabled: isCreateIncidentProcessing
      },
      { onNext: 'close' }
    ]
  }, [
    newIncidentForm.senderEmail,
    newIncidentForm.senderName,
    newIncidentForm.emailSubject,
    newIncidentForm.bodyText,
    newIncidentForm.bodyLinks,
    newIncidentForm.attachmentName,
    isEmailsLoading,
    newIncidentEmails.results.length,
    accessTokenId,
    isCreateIncidentProcessing,
    productLib
  ])

  const notificationContext = useMemo(() => {
    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      emailDeleted: accessTokenSettings.forensicsIncidentDeleteEmails === ACTIVE_SETTING_STATE,
      sender_email: newIncidentForm.senderEmail,
      sender_name: newIncidentForm.senderName,
      body_text: newIncidentForm.bodyText,
      body_links: newIncidentForm.bodyLinks,
      subject: newIncidentForm.emailSubject
      /* eslint-enable @typescript-eslint/naming-convention */
    }
  }, [
    accessTokenSettings.forensicsIncidentDeleteEmails,
    newIncidentForm.bodyText,
    newIncidentForm.bodyLinks,
    newIncidentForm.emailSubject,
    newIncidentForm.senderEmail,
    newIncidentForm.senderName
  ])

  // grid data
  const reviewMessagesGridData = useMemo(() => {
    const { page } = dataTables.newIncident

    const data = process(
      (newIncidentEmails.results as any)
        .slice(page.skip, page.skip + page.take)
        .reduce((all: GridRow[], result: Email & { [key: string]: any[] }) => {
          if (!result) {
            return [...all, {}]
          }

          return [
            ...all,
            {
              attachmentCount: result.attachments.length,
              emailId: result.emailId,
              created: result.created,
              formattedCreated: datetime.formatDate(result.created, config.DATETIME.DEFAULT_DATE_WITH_TIME_FORMAT),
              sender: `${result.sender.name} ${
                result.sender.name !== result.sender.address ? `<${result.sender.address}>` : ''
              }`,
              expandedRowContent: {
                headerInfo: { ...result },
                emailInfo: emails.emailInfoList[result.emailId]
              },
              subject: result.subject,
              mailbox: result['o-365-principal-name'],
              sendingDirection: result.sendingDirection
            } as GridRow
          ]
        }, []),
      {}
    )

    return {
      total: newIncidentEmails.totalCount,
      data: data.data
    }
  }, [dataTables.newIncident, newIncidentEmails.results, newIncidentEmails.totalCount, emails.emailInfoList])

  // content config
  const contentConfig = useMemo(() => {
    const userEssRegion = userDataLib.getUser().essRegion

    return {
      emailInfo: params.emailInfo,
      country: params.country,
      searchingForEmails: {
        updateForm: (formProp: string, isCheckbox = false) => (e: any) => {
          if (formProp === 'bodyText') {
            dispatch(updateForm({ formValues: { [formProp]: e.target.value.substr(0, 200) } }))
          } else {
            dispatch(updateForm({ formValues: { [formProp]: e.target[isCheckbox ? 'checked' : 'value'] } }))
          }
        },
        isInvalidCharLength,
        isEmailsLoading,
        newIncidentForm
      },
      reviewMessages: {
        gridData: reviewMessagesGridData,
        newIncident,
        dataTables,
        isEmailsLoading,
        isEmailsSuccess,
        isMoreEmailsLoading,
        onEmailClick: (dataItem: any) => {
          setState({ selectedEmail: dataItem.emailId })
          emailDetailDialogActions.onOpen(dataItem)

          analyticsLib.trackAppEvent(analyticsLib.EVENTS.INCIDENT_WIZARD_REVIEW_MESSAGES, {
            emailId: dataItem.emailId,
            senderEmail: dataItem.sender,
            subject: dataItem.subject
          })
        },
        emailDetailsIncidentInterface: [emailDetailDialogConfig, emailDetailDialogActions],
        pageConfig: {
          pageable: newIncidentEmails.totalCount > dataTables.newIncident.page.take,
          ...dataTables.newIncident.page,
          total: newIncidentEmails.totalCount,
          onPageChange: (e: any) => {
            dispatch(newIncidentTableActions.update({ config: { page: e.page as { skip: number; take: number } } }))
          }
        }
      },
      userOptions: {
        editEmailAlertDialogConfig: {
          /* eslint-disable @typescript-eslint/naming-convention */
          getForensicsDefaultNotificationPreview: () => {
            dispatch(
              getForensicsNotificationPreview({
                accessTokenId,
                context: notificationContext
              })
            )
            dispatch(
              updateUserOptions({
                userOptions: {
                  notificationTemplate: DEFAULT_TEMPLATE_NAME
                }
              })
            )
          },
          getForensicsCustomNotificationPreview: () => {
            dispatch(
              getForensicsCustomNotification({
                accessTokenId,
                context: notificationContext
              })
            )
            dispatch(
              updateUserOptions({
                userOptions: {
                  notificationTemplate: CUSTOM_TEMPLATE_NAME
                }
              })
            )
          },
          onClose: () => {
            analyticsLib.trackAppEvent(analyticsLib.EVENTS.INCIDENT_WIZARD_CANCEL_PREVIEW)
            toggleEditEmailDialog()
            dispatch(
              updateUserOptions({ userOptions: { notificationTemplate: state.originalNotificationTemplate } } as any)
            )
          },
          onApply: () => {
            analyticsLib.trackAppEvent(analyticsLib.EVENTS.INCIDENT_WIZARD_APPLY_PREVIEW, {
              accessTokenId,
              attachmentName: newIncidentForm.attachmentName,
              bodyLinks: newIncidentForm.bodyLinks,
              bodyText: newIncidentForm.bodyText,
              notificationTemplate: newIncidentUserOptions.notificationTemplate,
              sender_email: newIncidentForm.senderEmail,
              sender_name: newIncidentForm.senderName,
              subject: newIncidentForm.emailSubject
            })
            toggleEditEmailDialog()
          },
          NOTIFICATION_TEMPLATES: newIncidentUserOptions.NOTIFICATION_TEMPLATES
        },
        forensicsNotifyAddress: accessTokenSettings.forensicsNotifyAddress || userDataLib.getUser().email,
        isEditEmailAlertDialogVisible,
        tagInputConfig: {
          autocompleteTags: remediation.tags
            .filter((autocompleteTag: IncidentTag) => {
              return !state.tags.some((newTag: string) => newTag === autocompleteTag.name)
            })
            .map((tag: IncidentTag) => tag.name),
          currentTags: state.tags,
          handleTagChange: (tags: string[]) => {
            if (tags.some((tag: string) => !TAG_VALIDATOR.test(tag))) {
              setState({ errorMsg: formatMessage('errors.tag_invalid') })
            }
            setState({ tags: tags.filter((tag: string) => TAG_VALIDATOR.test(tag)) })
          }
        },
        toggleEditEmailDialogVisibility: () => {
          analyticsLib.trackAppEvent(analyticsLib.EVENTS.INCIDENT_WIZARD_PREVIEW_NOTIFICATION, {
            accessTokenId,
            attachmentName: newIncidentForm.attachmentName,
            bodyLinks: newIncidentForm.bodyLinks,
            bodyText: newIncidentForm.bodyText,
            sender_email: newIncidentForm.senderEmail,
            sender_name: newIncidentForm.senderName,
            subject: newIncidentForm.emailSubject
          })
          if (accessTokenSettings.forensicsEndUserNotificationTemplate !== DEFAULT_TEMPLATE_NAME) {
            dispatch(
              getForensicsCustomNotification({
                accessTokenId,
                context: notificationContext
              })
            )
            setState({ originalNotificationTemplate: CUSTOM_TEMPLATE_NAME })
          } else {
            dispatch(
              getForensicsNotificationPreview({
                accessTokenId,
                context: notificationContext
              })
            )
            setState({ originalNotificationTemplate: DEFAULT_TEMPLATE_NAME })
          }
          dispatch(updateUserOptions({ userOptions: { notificationTemplate: state.originalNotificationTemplate } }))
          toggleEditEmailDialog()
        },
        /* eslint-enable @typescript-eslint/naming-convention */
        updateUserOptions: (formProp: string, isCheckbox = false) => (e: any) => {
          dispatch(
            updateUserOptions({
              userOptions: {
                [formProp]: e.target[isCheckbox ? 'checked' : 'value'],
                ...((formProp === 'isDeleteSelectedEmails' &&
                  !e.target.checked && { isTurnOnContinuousRemediation: false }) as any)
              }
            })
          )
        }
      },
      policyOptions: {
        updatePolicyOptions: (formProp: string, isCheckbox = false) => (e: any) => {
          dispatch(
            updatePolicyOptions({
              policyOptions: {
                [formProp]: e.target[isCheckbox ? 'checked' : 'value']
              }
            })
          )
        }
      },
      additionalActionsOptions: {
        securityServiceLink:
          userEssRegion === 'US'
            ? config.LINKS.BARRACUDA_ESS_LOG.US
            : config.LINKS.BARRACUDA_ESS_LOG.NON_US.replace('REGION', userEssRegion)
      }
    }
  }, [
    accessTokenId,
    dispatch,
    dataTables,
    emailDetailDialogActions,
    emailDetailDialogConfig,
    formatMessage,
    isEditEmailAlertDialogVisible,
    isEmailsSuccess,
    isEmailsLoading,
    isMoreEmailsLoading,
    newIncident,
    newIncidentEmails,
    newIncidentForm,
    newIncidentUserOptions,
    notificationContext,
    reviewMessagesGridData,
    toggleEditEmailDialog,
    userDataLib,
    accessTokenSettings.forensicsEndUserNotificationTemplate,
    accessTokenSettings.forensicsNotifyAddress,
    params.country,
    params.emailInfo,
    remediation.tags,
    state.originalNotificationTemplate,
    state.tags
  ])

  const alertConfig = useMemo(() => {
    return {
      alertContent: state.errorMsg,
      closeAction: () => {
        setState({ errorMsg: '' })
      },
      showClose: true
    }
  }, [state.errorMsg])

  const getAnalyticsData = useCallback(() => {
    return {
      url: window.location.href,
      accessTokenId,
      attachmentName: newIncidentForm.attachmentName,
      country: params.emailInfo?.['country-code'],
      emailCount: newIncidentEmails.totalCount,
      emailSubject: params.emailInfo?.subject || newIncidentForm.emailSubject,
      includeQuarantined: newIncidentForm.isIncludeQuarantined,
      isMSP: userDataLib.isMspManagedAccount(accessTokenId),
      matchExact: newIncidentForm.isMatchExactFrame,
      policyOptions: {
        addSenderPolicy: newIncidentPolicyOptions.isAddSenderPolicy,
        addBCSPolicy: newIncidentPolicyOptions.isBlockAllUserWebTraffic,
        senderPolicyAction: newIncidentPolicyOptions.policyPlace,
        senderPolicyType: newIncidentPolicyOptions.involvedScope
      },
      searchCriteria: { ...newIncidentForm },
      senderEmail: params.emailInfo?.sender.address || newIncidentForm.senderEmail,
      senderName: params.emailInfo?.sender.name || newIncidentForm.senderName,
      step: STEP_TITLES[state.activeStep],
      timeframe: params.emailInfo?.timeframe || newIncidentForm.timeframe,
      userOptions: {
        alertAdmin: newIncidentUserOptions.isSendIncidentSummary,
        alertRecipients: newIncidentUserOptions.isSendWarningEmail,
        deleteSelectedEmails: newIncidentUserOptions.isDeleteSelectedEmails,
        isTurnOnContinuousRemediation: newIncidentUserOptions.isTurnOnContinuousRemediation,
        notificationTemplate: newIncidentUserOptions.notificationTemplate
      }
    }
  }, [
    newIncidentEmails.totalCount,
    newIncidentForm,
    newIncidentPolicyOptions.involvedScope,
    newIncidentPolicyOptions.isAddSenderPolicy,
    newIncidentPolicyOptions.isBlockAllUserWebTraffic,
    newIncidentPolicyOptions.policyPlace,
    newIncidentUserOptions.isDeleteSelectedEmails,
    newIncidentUserOptions.isSendIncidentSummary,
    newIncidentUserOptions.isSendWarningEmail,
    newIncidentUserOptions.isTurnOnContinuousRemediation,
    newIncidentUserOptions.notificationTemplate,
    params.emailInfo,
    accessTokenId,
    state.activeStep,
    userDataLib
  ])

  const loadEmails = useCallback(
    (form?: any) => {
      dispatch(updateForm({ formValues: { isFormError: false } }))
      dispatch(getEmails({ config: apiParams(form) }))
    },
    [dispatch, apiParams]
  )

  const newIncidentApiParams = useCallback(
    (incidentDetailsSource: string) => {
      return {
        createdBy: user.data.email,
        incidentDetails: {
          source: incidentDetailsSource,
          sourceEmailId: null,
          sourceDomainId: null
        },
        labels: state.tags,
        notificationDetails: {
          template: newIncidentUserOptions.notificationTemplate
        },
        remediationActions: {
          quarantine: false,
          delete: newIncidentUserOptions.isDeleteSelectedEmails,
          notify: newIncidentUserOptions.isSendWarningEmail,
          addSenderPolicy:
            newIncidentPolicyOptions.isAddSenderPolicy &&
            newIncidentPolicyOptions.involvedScope === newIncidentPolicyOptions.INVOLVED_SCOPES.SENDERS,
          addDomainPolicy:
            newIncidentPolicyOptions.isAddSenderPolicy &&
            newIncidentPolicyOptions.involvedScope === newIncidentPolicyOptions.INVOLVED_SCOPES.DOMAINS,
          addBCSPolicy: newIncidentPolicyOptions.isBlockAllUserWebTraffic,
          sendSummary: newIncidentUserOptions.isSendIncidentSummary,
          ...(featureLib.hasForensicsDemoFeature(accessTokenId) && {
            queueInsights: false,
            updateMessageLog: false
          }),
          ...(incidentDetailsSource === IncidentDetailsSource.potentialIncidents && { queueInsights: false }),
          enableContinuousRemediation: newIncidentUserOptions.isTurnOnContinuousRemediation && !isFirDemoUser
        },
        searchCriteria: {
          searchId: newIncidentEmails.searchId,
          timeframe: newIncidentForm.timeframe,
          dslQuery: newIncidentEmails.dslQuery,
          sender: {
            id: '',
            displayName: newIncidentForm.senderName,
            firstName: '',
            lastName: '',
            title: '',
            email: newIncidentForm.senderEmail
          },
          emailSubject: newIncidentForm.emailSubject,
          bodyText: newIncidentForm.bodyText,
          bodyLinks: newIncidentForm.bodyLinks,
          attachmentName: newIncidentForm.attachmentName,
          subjectContainsPhrase: newIncidentForm.isMatchExactFrame,
          includeQuarantined: newIncidentForm.isIncludeQuarantined,
          includeSent: true
        },
        senderPolicyDetails: {
          policy: newIncidentPolicyOptions.policyPlace,
          policyComment: newIncidentPolicyOptions.comment.trim()
        },
        summaryDetails: {
          timezone: luxonDate().zoneName || null
        }
      } as NewIncident
    },
    [
      user.data.email,
      state.tags,
      newIncidentUserOptions.notificationTemplate,
      newIncidentUserOptions.isDeleteSelectedEmails,
      newIncidentUserOptions.isSendWarningEmail,
      newIncidentUserOptions.isSendIncidentSummary,
      newIncidentUserOptions.isTurnOnContinuousRemediation,
      newIncidentPolicyOptions.isAddSenderPolicy,
      newIncidentPolicyOptions.involvedScope,
      newIncidentPolicyOptions.INVOLVED_SCOPES.SENDERS,
      newIncidentPolicyOptions.INVOLVED_SCOPES.DOMAINS,
      newIncidentPolicyOptions.isBlockAllUserWebTraffic,
      newIncidentPolicyOptions.policyPlace,
      newIncidentPolicyOptions.comment,
      featureLib,
      accessTokenId,
      isFirDemoUser,
      newIncidentEmails.searchId,
      newIncidentEmails.dslQuery,
      newIncidentForm.timeframe,
      newIncidentForm.senderName,
      newIncidentForm.senderEmail,
      newIncidentForm.emailSubject,
      newIncidentForm.bodyText,
      newIncidentForm.bodyLinks,
      newIncidentForm.attachmentName,
      newIncidentForm.isMatchExactFrame,
      newIncidentForm.isIncludeQuarantined
    ]
  )

  // on next step
  const onNextStep = useCallback(() => {
    const analyticsData = getAnalyticsData()

    switch (state.activeStep) {
      case 0: {
        dispatch(newIncidentTableActions.reset())
        loadEmails()
        dispatch(
          updateUserOptions({
            userOptions: {
              notificationTemplate: accessTokenSettings.forensicsEndUserNotificationTemplate
            }
          })
        )

        const { userOptions, policyOptions, ...updatedData } = analyticsData

        analyticsLib.trackAppEvent(analyticsLib.EVENTS.incidentWizardNext(STEP_TITLES[state.activeStep]), {
          ...updatedData
        })
        break
      }
      case 1: {
        const { userOptions, policyOptions, ...updatedData } = analyticsData

        analyticsLib.trackAppEvent(analyticsLib.EVENTS.incidentWizardNext(STEP_TITLES[state.activeStep]), {
          ...updatedData
        })
        setState({ activeStep: state.activeStep + 1 })
        break
      }
      case 2: {
        const { policyOptions, ...updatedData } = analyticsData

        analyticsLib.trackAppEvent(analyticsLib.EVENTS.incidentWizardNext(STEP_TITLES[state.activeStep]), {
          ...updatedData
        })
        setState({ activeStep: state.activeStep + 1 })
        break
      }
      case 3:
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.incidentWizardNext(STEP_TITLES[state.activeStep]), {
          ...analyticsData
        })
        dispatch(forensicsCreateIncident({ config: newIncidentApiParams(params.incidentDetailsSource) }))
        break
      case 4:
        params.onClose(state.activeStep)
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.incidentWizardNext(STEP_TITLES[state.activeStep]), {
          ...analyticsData
        })
        if (params.onSuccess) {
          params.onSuccess()
        }
        break
      default:
        setState({ activeStep: state.activeStep + 1 })
    }
  }, [
    accessTokenSettings.forensicsEndUserNotificationTemplate,
    dispatch,
    getAnalyticsData,
    loadEmails,
    newIncidentApiParams,
    params,
    state.activeStep
  ])

  const onPrevStep = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.incidentWizardBack(STEP_TITLES[state.activeStep]), {
      step: STEP_TITLES[state.activeStep]
    })

    // reset email search data table when returning to the first step
    if (state.activeStep === 1) {
      dispatch(newIncidentTableActions.reset())
    }

    setState({ activeStep: state.activeStep - 1 })
  }, [dispatch, state.activeStep])

  // enter key navigation
  useEffect(() => {
    const keydownListener = (event: { code: string }) => {
      if (state.activeStep !== 2 && (event.code === 'Enter' || event.code === 'NumpadEnter')) {
        // BDS doesn't currently support 'ref' for Buttons so we need to find it by first referencing the parent
        const currentDialogActions = dialogActions.current && dialogActions.current.childNodes
        const nextButton =
          currentDialogActions && (currentDialogActions[currentDialogActions.length - 1] as HTMLButtonElement)
        // The following is a hack/workaround to get the button to consistently work when pressing Enter
        if (nextButton) {
          nextButton.focus()
          nextButton.blur()
        }
        onNextStep()
      }
    }
    if (
      !buttonsConfig[state.activeStep].disabled &&
      !buttonsConfig[state.activeStep].disableNext &&
      state.activeStep !== 4
    ) {
      document.addEventListener('keydown', keydownListener)
    }
    return () => {
      document.removeEventListener('keydown', keydownListener)
    }
  }, [state.activeStep, buttonsConfig, onNextStep])

  const setInitialNewIncidentConfig = useCallback(() => {
    const hasValidSerial = !productLib.hasLimitedForensicsAccess(accessTokenId)
    const hasEss = featureLib.hasESSFeature(accessTokenId)
    const hasBCS = featureLib.hasBCSFeature(accessTokenId)

    dispatch(forensicsGetIncidentsTags({ accessTokenId }))

    dispatch(
      updateUserOptions({
        userOptions: {
          isDeleteSelectedEmails:
            hasValidSerial && accessTokenSettings.forensicsIncidentDeleteEmails === ACTIVE_SETTING_STATE,
          isTurnOnContinuousRemediation:
            hasValidSerial &&
            accessTokenSettings.forensicsIncidentDeleteEmails === ACTIVE_SETTING_STATE &&
            accessTokenSettings.forensicsIncidentContinuousRemediation === ACTIVE_SETTING_STATE,
          isSendWarningEmail: accessTokenSettings.forensicsIncidentAlertEndUser === ACTIVE_SETTING_STATE,
          isSendIncidentSummary: accessTokenSettings.forensicsIncidentAlertAdmin === ACTIVE_SETTING_STATE,
          notificationTemplate: accessTokenSettings.forensicsEndUserNotificationTemplate
        }
      })
    )

    dispatch(
      updatePolicyOptions({
        policyOptions: {
          isAddSenderPolicy: !!hasEss && accessTokenSettings.forensicsIncidentSenderPolicy === ACTIVE_SETTING_STATE,
          policyPlace:
            accessTokenSettings.forensicsIncidentSenderPolicyAction === newIncidentPolicyOptions.POLICY_PLACES.BLOCK
              ? newIncidentPolicyOptions.POLICY_PLACES.BLOCK
              : newIncidentPolicyOptions.POLICY_PLACES.QUARANTINE,
          involvedScope:
            accessTokenSettings.forensicsIncidentSenderPolicyType === newIncidentPolicyOptions.INVOLVED_SCOPES.DOMAINS
              ? newIncidentPolicyOptions.INVOLVED_SCOPES.DOMAINS
              : newIncidentPolicyOptions.INVOLVED_SCOPES.SENDERS,
          isBlockAllUserWebTraffic:
            !!hasBCS && accessTokenSettings.forensicsIncidentContentShieldPolicy === ACTIVE_SETTING_STATE
        }
      })
    )
  }, [
    accessTokenId,
    dispatch,
    featureLib,
    accessTokenSettings.forensicsIncidentDeleteEmails,
    accessTokenSettings.forensicsIncidentContinuousRemediation,
    accessTokenSettings.forensicsIncidentAlertEndUser,
    accessTokenSettings.forensicsIncidentAlertAdmin,
    accessTokenSettings.forensicsEndUserNotificationTemplate,
    accessTokenSettings.forensicsIncidentSenderPolicy,
    accessTokenSettings.forensicsIncidentSenderPolicyAction,
    accessTokenSettings.forensicsIncidentSenderPolicyType,
    accessTokenSettings.forensicsIncidentContentShieldPolicy,
    newIncidentPolicyOptions.POLICY_PLACES.BLOCK,
    newIncidentPolicyOptions.POLICY_PLACES.QUARANTINE,
    newIncidentPolicyOptions.INVOLVED_SCOPES.DOMAINS,
    newIncidentPolicyOptions.INVOLVED_SCOPES.SENDERS,
    productLib
  ])

  return useMemo(
    () => [
      {
        activeStep: state.activeStep,
        alertConfig,
        buttons: buttonsConfig,
        contentConfig,
        dialogActions,
        errorMsg: state.errorMsg,
        isCreatingIncident: isCreateIncidentProcessing,
        isEntitledBCS: featureLib.hasBCSFeature(accessTokenId),
        isEntitledESS: featureLib.hasESSFeature(accessTokenId),
        onNextStep,
        onPrevStep
      }
    ],
    [
      accessTokenId,
      alertConfig,
      buttonsConfig,
      contentConfig,
      dialogActions,
      featureLib,
      isCreateIncidentProcessing,
      onNextStep,
      onPrevStep,
      state.activeStep,
      state.errorMsg
    ]
  )
}
