import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import {
  Typography,
  Button,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Zoom
} from '@barracuda-internal/bds-core'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import DEFAULTS from 'global/configs/theme/defaults'
import { useErrorFormatMessage, useFormatMessage } from 'global/lib/localization'

import useMarkAlertAsFpLogic from 'sen/components/lib/dialogs/markAlertAsFp/useMarkAlertAsFpLogic'
import styles from 'sen/components/lib/dialogs/markAlertAsFp/markAlertAsFpStyles'

export interface MarkAlertAsFpProps {
  alertId: string
  open?: boolean
  onClose: (isMarked?: boolean) => void
}

const BASE_I18N_KEY = 'sen.app.mark_alert_as_fp_dialog'

export const MarkAlertAsFp: React.FC<MarkAlertAsFpProps> = ({ open = true, onClose, alertId }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatMessageError = useErrorFormatMessage()
  const classes = styles()
  const [inProgress, onMark, isSuccess, errorMsg] = useMarkAlertAsFpLogic(alertId)

  return useMemo(
    () => (
      <Dialog
        style={{ zIndex: DEFAULTS.SECONDARY_DIALOGS_ZINDEX }}
        maxWidth="md"
        fullWidth
        open={open}
        TransitionComponent={Zoom}
        data-testid="mark-as-fp-dialog"
      >
        {inProgress && <LinearProgress />}
        <DialogTitle>
          {errorMsg && (
            <Alert className={classes.error} variant="filled" severity="error">
              {formatMessageError(`${errorMsg}`)}
            </Alert>
          )}
          <Typography className={classes.dialogTitle}>
            {formatMessage(`${isSuccess ? 'success_' : ''}title`)}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography className={classes.subtitle}>{formatMessage(`${isSuccess ? 'success_' : ''}text`)}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            size="large"
            variant="text"
            onClick={() => {
              onClose()
            }}
            disabled={inProgress}
          >
            {formatMessage(isSuccess ? 'back' : 'cancel')}
          </Button>

          {!isSuccess && (
            <Button size="large" onClick={onMark} disabled={inProgress}>
              &nbsp;&nbsp;{formatMessage('on_mark')}&nbsp;&nbsp;
            </Button>
          )}
        </DialogActions>
      </Dialog>
    ),
    [formatMessage, formatMessageError, classes, open, onClose, inProgress, onMark, errorMsg, isSuccess]
  )
}

MarkAlertAsFp.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  alertId: PropTypes.string.isRequired
}

MarkAlertAsFp.defaultProps = {
  open: true
}

export default MarkAlertAsFp
