import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { validateApiError } from 'global/lib/api/restClient'
import { AccessToken } from 'global/types/api/accessTokenType'
import accessTokenLib from 'global/lib/accessToken/accessToken'
import { getUser } from 'global/redux/features/auth/authSlice'
import { setAccessTokenSecret } from 'global/redux/features/accessToken/accessTokenSlice'
import { config } from 'global/lib/config'

import apiRoutes from 'ets/lib/api/apiRoutes'
import gotoReportPage from 'ets/lib/gotoReportPage'
import routesConfig from 'ets/lib/routes/routesConfig'

export interface DeleteEtsReport {
  accessTokenId?: AccessToken['id']
}
export interface ActivateReportSharing {
  accessTokenId: AccessToken['id']
}
export interface DeactivateReportSharing {
  accessTokenId: AccessToken['id']
  secretId?: string
}
export interface ConvertETS2Sentinel {
  isSettingsConversion?: boolean
  settings: any
  accessTokenId: AccessToken['id']
  email: string
  accountId: string
}
export interface SetAccessTokenSettingsConvert {
  settings: any
  accessTokenId: AccessToken['id']
  accountId: string
  email: string
}

export interface RerunCurrentScan {
  accessTokenId: AccessToken['id']
  accountId: string
  email: string
}

export const deleteEtsReport = createAsyncThunk('SETTINGS/deleteEtsReport', async function doDeleteEtsReport(
  payload: DeleteEtsReport | undefined,
  { rejectWithValue, dispatch }
) {
  const { accessTokenId } = payload || {}

  try {
    const resp = await restClient(apiRoutes.DELETE_ETS_REPORT, {
      data: { accessTokenId }
    })

    dispatch(getUser())

    const accessToken = accessTokenLib.getDefaultAccessTokenId()

    if (accessToken) {
      gotoReportPage({ accessTokenId: accessToken })
    } else {
      routesConfig.EMPTY_REPORT.goto()
    }

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const activateReportSharing = createAsyncThunk(
  'SETTINGS/activateReportSharing',
  async function doActivateReportSharing(payload: ActivateReportSharing | undefined, { rejectWithValue, dispatch }) {
    const accessTokenId = payload?.accessTokenId || (accessTokenLib.getActiveAccessTokenId() as string)

    try {
      const resp = await restClient(apiRoutes.ACTIVATE_REPORT_SHARING, {
        data: { accessTokenId }
      })

      dispatch(setAccessTokenSecret({ accessTokenId, reportSecret: resp.data }))

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const deactivateReportSharing = createAsyncThunk(
  'SETTINGS/deactivateReportSharing',
  async function doDeactivateReportSharing(
    payload: DeactivateReportSharing | undefined,
    { rejectWithValue, dispatch }
  ) {
    const accessTokenId = payload?.accessTokenId || (accessTokenLib.getActiveAccessTokenId() as string)
    const secretId = payload?.secretId || accessTokenLib.getAccessTokenSecretId(accessTokenId)

    try {
      const resp = await restClient(apiRoutes.DEACTIVATE_REPORT_SHARING, {
        data: { secretId, accessTokenId }
      })

      dispatch(setAccessTokenSecret({ accessTokenId, reportSecret: resp.data }))

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const convertETS2Sentinel = createAsyncThunk(
  'SETTINGS/convertETS2Sentinel',
  async function doConvertETS2Sentinel(payload: ConvertETS2Sentinel, { rejectWithValue, dispatch }) {
    const { isSettingsConversion = true, settings, accessTokenId, accountId, email } = payload

    try {
      const resp = await restClient(apiRoutes.CONVERT_ETS_2_SENTINEL, {
        data: { accessTokenId, accountId, email, isSettingsConversion }
      })

      if (resp.data.accessToken) {
        const updatedAccessToken = resp.data.accessToken
        dispatch(setAccessTokenSettingsConvert({ settings, accessTokenId: updatedAccessToken, accountId, email }))
      }

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const setAccessTokenSettingsConvert = createAsyncThunk(
  'SETTINGS/setAccessTokenSettingsConvert',
  async function doSetAccessTokenSettingsConvert(payload: SetAccessTokenSettingsConvert, { rejectWithValue }) {
    const { settings, accessTokenId, accountId, email } = payload

    try {
      const resp = await restClient(apiRoutes.SET_ACCESS_TOKEN_SETTINGS_CONVERT, {
        data: { settings, accessTokenId, accountId, email }
      })

      window.location.href = `${config.domains.sentinel}/report/${accessTokenId}`
      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const rerunCurrentScan = createAsyncThunk('SETTINGS/rerunCurrentScan', async function doRerunCurrentScan(
  payload: RerunCurrentScan,
  { rejectWithValue }
) {
  const { accessTokenId, accountId, email } = payload

  try {
    const resp = await restClient(apiRoutes.RERUN_CURRENT_SCAN, {
      data: { accessTokenId, accountId, email }
    })

    const { accessToken, user, scan } = resp.data
    gotoReportPage({ accessTokenId: accessToken.id, user, scan })
    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})
