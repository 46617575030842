import React from 'react'
import PropTypes from 'prop-types'

import { Button, ButtonProps } from '@barracuda-internal/bds-core'

import styles from './customRangesStyles'

export interface CustomRangesProps {
  ranges: CustomRange[]
  selectedRange: any
}

export const CUSTOM_RANGES = {
  LAST_HOUR: { id: 'last_hour', timeframe: 1 },
  LAST_12_HOURS: { id: 'last_12_hours', timeframe: 12 },
  LAST_24_HOURS: { id: 'last_24_hours', timeframe: 24 },
  LAST_2_DAYS: { id: 'last_2_days', timeframe: 48 },
  LAST_7_DAYS: { id: 'last_7_days', timeframe: 168 },
  LAST_30_DAYS: { id: 'last_30_days', timeframe: 720 }
}

export interface CustomRange {
  id: string
  text: string
  onClick: (e: any) => void
  enabled?: boolean
}

const CustomRanges: React.FC<CustomRangesProps> = ({ ranges, selectedRange }) => {
  const classes = styles()

  return (
    <div className={classes.wrapper}>
      {ranges.map((range: CustomRange) => {
        const buttonAttributes: ButtonProps = {
          color: 'primary',
          ...(selectedRange && selectedRange.id === range.id && { variant: 'contained' }),
          onClick: range.onClick
        }

        return (
          <Button key={range.id} className={classes.buttons} {...buttonAttributes}>
            {range.text}
          </Button>
        )
      })}
    </div>
  )
}

CustomRanges.propTypes = {
  ranges: PropTypes.any.isRequired,
  selectedRange: PropTypes.any.isRequired
}

export default CustomRanges
