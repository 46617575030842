import { createAsyncThunk } from '@reduxjs/toolkit'

import { globalApiRoutes } from 'global/lib/api/apiRoutes'
import restClient, { ApiRejectResponse, ResponseGenerator, validateApiError } from 'global/lib/api/restClient'
import { ScheduledReport } from 'global/types/api/scheduledReports'

export const getScheduledReports = createAsyncThunk<ScheduledReport[], { product: string }, ApiRejectResponse>(
  'SCHEDULED_REPORTS/getScheduledReports',
  async function doGetScheduledReports(payload, { rejectWithValue }) {
    try {
      const resp: ResponseGenerator = await restClient(globalApiRoutes.GET_SCHEDULED_REPORTS, {
        data: { ...payload }
      })

      return resp.data.schedules
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

// This is not currently used, but is here for reference
export const getScheduledReport = createAsyncThunk<ScheduledReport, { uuid: string }, ApiRejectResponse>(
  'SCHEDULED_REPORTS/getScheduledReport',
  async function doGetScheduledReport(payload, { rejectWithValue }) {
    try {
      const resp: ResponseGenerator = await restClient(globalApiRoutes.GET_SCHEDULED_REPORT, {
        data: { ...payload }
      })

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

// The result of this thunk is not currently used, but is here for reference
export const createScheduledReport = createAsyncThunk<
  ScheduledReport,
  { config: Partial<ScheduledReport> },
  ApiRejectResponse
>('SCHEDULED_REPORTS/createScheduledReport', async function doCreateScheduledReport(payload, { rejectWithValue }) {
  try {
    const resp: ResponseGenerator = await restClient(globalApiRoutes.CREATE_SCHEDULED_REPORT, {
      data: { ...payload }
    })

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const updateScheduledReport = createAsyncThunk<
  ScheduledReport,
  { uuid: string; config: Partial<ScheduledReport> },
  ApiRejectResponse
>('SCHEDULED_REPORTS/updateScheduledReport', async function doUpdateScheduledReport(payload, { rejectWithValue }) {
  try {
    const resp: ResponseGenerator = await restClient(globalApiRoutes.UPDATE_SCHEDULED_REPORT, {
      data: { ...payload }
    })

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const deleteScheduledReport = createAsyncThunk<{}, { uuid: string }, ApiRejectResponse>(
  'SCHEDULED_REPORTS/deleteScheduledReport',
  async function doDeleteScheduledReport(payload, { rejectWithValue }) {
    try {
      await restClient(globalApiRoutes.DELETE_SCHEDULED_REPORT, {
        data: { ...payload }
      })

      return {}
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)
