import { FONT as font } from 'global/configs/theme/defaults'

export default {
  root: {
    fontSize: font.size.regular
  },
  outlined: {
    fontSize: font.size.medium
  },
  shrink: {
    transform: 'translate(14px, 0px) !important'
  }
}
