import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  chartWrapper: {
    padding: theme.spacing(2)
  },
  buttons: {
    borderRadius: '5px',
    float: 'right',
    margin: theme.spacing(1)
  },
  buttonGreyText: {
    color: theme.palette.grey[600]
  },
  defaultDropDownArrowIcon: {
    color: theme.palette.primary.main
  },
  greyDropDownArrowIcon: {
    color: theme.palette.grey[600]
  },
  showChartIconWrapper: {
    borderRadius: '50%',
    backgroundColor: '#E4F4FF',
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '25px',
    height: '25px',
    cursor: 'pointer'
  },
  showChartIcon: {
    fontSize: theme.typography.pxToRem(15)
  }
}))
