export const DMARC_SENDER_APPROVAL_STATUSES = {
  unknown: null,
  approved: 'approved',
  rejected: 'rejected'
}
export type DmarcSenderApprovalStatusesKeys = keyof typeof DMARC_SENDER_APPROVAL_STATUSES
export type DmarcSenderApprovalStatuses = typeof DMARC_SENDER_APPROVAL_STATUSES[DmarcSenderApprovalStatusesKeys]

export const DMARC_MIN_PASSED_RATIO = 10

export enum DmarcSourceTypes {
  high = 'high',
  low = 'low'
}

export enum DmarcSourceVolumes {
  highVolume = 'major',
  lowVolume = 'minor'
}

export enum DmarcThreats {
  dmarc = 'dmarc',
  spf = 'spf',
  dkim = 'dkim'
}

export interface DomainDmarcStat {
  dmarcFailsFromApprovedSources: number
  dmarcTotalMailCountFromApprovedSources: number
  domain: string
  reportIsReady: boolean
  unknownMajorSendersCount: number
}

export interface DmarcEntry {
  entry: string
  recordExists: boolean
  isDmarcRecord: boolean
  policy: string
  tags: { [key in DmarcTags]: string | undefined }
  isProtected: boolean
  source: DmarcThreats
  domain: string
}

export interface CompanyDomain {
  dmarc: DmarcEntry
  domainSource: DomainSources
  name: string
  userCount: number
  verified: boolean
  verificationInprogress?: boolean
}

export interface DomainStatus {
  domainSource: DomainSources
  name: string
  txtDomain: string
  txtRecord: string
  verified: boolean
}

export interface CompanyDomainWithState extends CompanyDomain {
  state: string
}

export interface DomainsStats {
  [key: string]: DomainDmarcStat
}

export enum DmarcTags {
  v = 'v',
  p = 'p',
  fo = 'fo',
  rua = 'rua',
  ruf = 'ruf'
}

export enum DomainSources {
  o365 = 'o365',
  internal = 'internal'
}

export interface DmarcProduct {
  name: string
  website: string
  dkimFixUrl: string
  spfFixUrl: string
}

export interface DmarcCountry {
  country: string
  emailCount: number
  ipCount: number
  dmarcPass: number
  dmarcFail: number
  dkimFail: number
  spfFail: number
}

export interface DmarcIp {
  ip: string
  country: string
  emailCount: string
  dmarcPass: number
  dmarcFail: number
  dmarcFailRatio: number
  dkimFail: number
  dkimFailRatio: number
  spfFail: number
  spfFailRatio: number
}

export interface DmarcSource {
  host: string
  emailCount: number
  ipCount: number
  dmarcPass: number
  dmarcFail: number
  dmarcFailRatio: number
  dkimFail: number
  dkimFailRatio: number
  spfFail: number
  spfFailRatio: number
  product: DmarcProduct
  ips: DmarcIp[]
  countries: DmarcCountry[]
  hasRuf: boolean
  inProgressAction?: string | null
  website: string
}

export interface SpfRecord {
  domain: string
  entry: string
  isSpfRecord: boolean
  recordExists: boolean
  source: DmarcThreats.spf
}

export interface FraudUnifiedData {
  country: string
  totalEmailCount: number
  totalIpCount: number
  fraudEmailCount: number
  fraudIpCount: number
  location: {
    lat: number
    lng: number
  }
}
export interface ModifiedFraudUnifiedData extends FraudUnifiedData {
  flagSrc: string
  name: string
}

export interface FraudUnifiedRufData {
  country: string
  date: Date
  emailCount: number
  sampleKey: string
  senderType: string
  subject: string
}
export interface FraudUnifiedRufDataList {
  count: number
  totalCount: number
  data: FraudUnifiedRufData[]
}

export type EmailHeader = {
  key: string
  line: string
  name: string
  value: string
}

export type EmailAttachment = {
  filename: string
  contentDisposition: string
  contentType: string
  checksum: string
  size: number
  headers: EmailHeader[]
  content: string
  contentId: string
  cid: string
  related: boolean
}

export type EmailAddressValue = {
  address: string
  name: string
}

export type EmailFromValue = {
  html: string
  text: string
  value: EmailAddressValue[]
}

export type EmailAddress = {
  html: string
  text: string
  value: EmailFromValue
}

export interface ParsedFeedback {
  attachments: EmailAttachment[]
  headerLines: EmailHeader[]
  headers: {}
  messageId: string
}

export interface ParsedMetadata {
  attachments: EmailAttachment[]
  date: string
  from: EmailAddress
  headerLines: EmailHeader[]
  headers: {}
  inReplyTo: string
  messageId: string
  references: string[]
  subject: string
  to: EmailAddress
  cc?: EmailAddress
  bcc?: EmailAddress
  'reply-to'?: EmailAddress
}

export interface ParsedEmail {
  attachments: EmailAttachment[]
  date: string
  from: EmailAddress
  headerLines: EmailHeader[]
  headers: {}
  html: string
  inReplyTo: string
  messageId: string
  references: string[]
  subject: string
  text: string
  textAsHtml: string
  to: EmailAddress
  cc?: EmailAddress
  bcc?: EmailAddress
  'reply-to'?: EmailAddress
}

export interface RufSample {
  feedback: string
  feedbackParsed: ParsedFeedback
  metadata: string
  metadataParsed: ParsedMetadata
  rawEmail: string
  rawEmailParsed: ParsedEmail
}

export interface DomainTotals {
  totalDmarcFailures: number
  totalMajor: number
  totalMinor: number
}

export interface DmarcAuthRecordAnalysis {
  name: string
  pass: boolean
  isAligned: boolean
}

export interface DmarcSubdomain {
  host: string
  headerFrom: string
  spfAuthResp: string[]
  dkimAuthResp: string[]
  dmarc: string
  emailCount: number
}

export interface SubdomainAnalysis {
  name: string
  pass: boolean
  isAligned: boolean
}

export interface DmarcHost {
  host: CompanyDomain['name']
}

export interface DmarcSamplePreview {
  country: string
  date: string
  emailCount: number
  sampleKey: string
  senderType?: string
  subject: string
}

// reports
interface DmarcReportGenerator<ReportType> {
  count: number
  next: string | null
  previous: string | null
  results: ReportType[]
}

export type DmarcSourcesReport = DmarcReportGenerator<DmarcSource>
export type DmarcSubdomainsReport = DmarcReportGenerator<DmarcSubdomain>
export type DmarcHostsReport = DmarcReportGenerator<DmarcHost>
export type DmarcRufSamplesReport = DmarcReportGenerator<DmarcSamplePreview>
export type FraudUnifiedRufDataReport = DmarcReportGenerator<FraudUnifiedRufData>
