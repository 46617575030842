export default function truncateMiddle(text: string, maxLength: number): string {
  if (text.length <= maxLength) {
    return text
  }

  const ellipsis = '...'
  const leftLength = Math.ceil((maxLength - 3) / 2)
  const rightLength = Math.floor((maxLength - 3) / 2)
  const left = text.slice(0, leftLength)
  const right = text.slice(text.length - rightLength, text.length)

  return left + ellipsis + right
}
