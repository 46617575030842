import { useMemo, useEffect } from 'react'

import { InboxRuleFeed } from 'global/types/api/remediation'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import { TableConfig } from 'sen/components/lib/dialogs/inboxRules/inboxRulesTable/InboxRulesTable'
import useInboxRulesTableLogic from 'sen/components/lib/dialogs/inboxRules/inboxRulesTable/useInboxRulesTableLogic'
import {
  newInboxRulesActions,
  previousInboxRulesActions
} from 'sen/redux/features/dataTables/inboxRules/inboxRulesSlice'

export type IsLoaded = boolean
export type Error = string | undefined

export default function useRangeInboxRuleDialogLogic(feed: InboxRuleFeed): [TableConfig, TableConfig] {
  const dispatch = useAppDispatch()
  const { newInboxRulesTable, previousInboxRulesTable } = useAppSelector(_stores => ({
    newInboxRulesTable: _stores.dataTables.newInboxRules,
    previousInboxRulesTable: _stores.dataTables.previousInboxRules
  }))

  // Init
  useEffect(() => {
    return () => {
      dispatch(newInboxRulesActions.reset())
      dispatch(previousInboxRulesActions.reset())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [newInboxRulesTableConfig] = useInboxRulesTableLogic({
    inboxRules: feed.rulesAfter,
    inboxRulesTable: newInboxRulesTable,
    updateTable: newInboxRulesActions.update
  })
  const [previousInboxRulesTableConfig] = useInboxRulesTableLogic({
    inboxRules: feed.rulesBefore,
    inboxRulesTable: previousInboxRulesTable,
    updateTable: previousInboxRulesActions.update
  })

  return useMemo(() => {
    return [newInboxRulesTableConfig, previousInboxRulesTableConfig]
  }, [newInboxRulesTableConfig, previousInboxRulesTableConfig])
}
