import React, { useMemo } from 'react'

import { v4 as uuidv4 } from 'uuid'

import { Card, Typography, DataTable, DataTableColumn, Grid } from '@barracuda-internal/bds-core'
import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'

import MaskLayer from 'global/components/lib/maskLayer/MaskLayer'
import SearchField from 'global/components/lib/searchField/SearchField'
import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import highlightText from 'global/lib/highlightText/highlightText'
import Pager from 'global/components/lib/grid/pager/Pager'
import IMAGES from 'global/configs/theme/images.config'
import { useFormatMessage } from 'global/lib/localization'

import DmarcDialog from 'ets/components/lib/dialogs/dmarcDialog/DmarcDialog'
import useDashboardDomainsLogic, {
  ModifiedDomain,
  DashboardDomainsLogicProps
} from 'ets/components/pages/dashboard/domains/useDashboardDomainsLogic'
import styles from 'ets/components/pages/dashboard/domains/dashboardDomainsStyles'

const BASE_I18N_KEY = 'ets.app.dashboard.domains'
const BASE_PROGRESS_I18N_KEY = 'ets.app.dashboard.in_progress'
const BASE_I18N_TABLE_KEY = 'ets.app.data_tables.domains'

const DashboardDomains: React.FC<DashboardDomainsLogicProps> = props => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatMessageProgress = useFormatMessage(BASE_PROGRESS_I18N_KEY)
  const formatMessageTable = useFormatMessage(BASE_I18N_TABLE_KEY)
  const classes = styles()
  const [dashboardDomainsLogic] = useDashboardDomainsLogic(props)

  return useMemo(() => {
    const {
      isReportLoaded,
      inProgress,
      tableTotal,
      searchFieldConfig,
      GRID_COLUMNS,
      columnsConfig,
      tableData,
      pageConfig,
      sortConfig,
      highlightKeywords,
      isDmarcDialogVisible,
      onCloseDmarcDialog,
      dmarcEntry,
      dmarcPolicy,
      isDmarcPolicyValid
    } = dashboardDomainsLogic

    return (
      <Grid className={classes.root} container alignItems="center" justifyContent="center" direction="column">
        <DmarcDialog
          open={isDmarcDialogVisible}
          onClose={onCloseDmarcDialog}
          dmarcEntry={dmarcEntry}
          isDmarcPolicyValid={isDmarcPolicyValid}
          dmarcPolicy={dmarcPolicy}
        />
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Card className={classes.tableWrapper}>
              <Grid container direction="column">
                <Grid item xs={12}>
                  <Typography className={classes.tableTotal} align="left">
                    {formatMessage('table_total', { total: tableTotal })}
                  </Typography>
                </Grid>
                <Grid className={classes.searchField} item xs={12}>
                  <SearchField {...searchFieldConfig} placeholder={formatMessage('search_field_placeholder')} />
                </Grid>
                <Grid
                  className={`${classes.tableArea} ${!tableData.total ? 'empty-table' : ''}`}
                  data-table="domains"
                  item
                  xs={12}
                >
                  {isReportLoaded && inProgress && <LinearProgress className={classes.tableLoadIndicator} />}
                  <DataTable
                    className={`${tableData.total ? classes.dataTable : classes.emptyDataTable} domains-table`}
                    data={tableData}
                    {...pageConfig}
                    {...sortConfig}
                    pager={Pager}
                  >
                    <DataTableNoRecords>
                      <Typography>{!tableData.total && formatMessage('empty_table')}</Typography>
                    </DataTableNoRecords>
                    <DataTableColumn
                      width={300}
                      field={GRID_COLUMNS.DOMAIN}
                      {...columnsConfig[GRID_COLUMNS.DOMAIN]}
                      title={formatMessageTable(`${GRID_COLUMNS.DOMAIN}`)}
                      cell={({ dataItem }: { dataItem: ModifiedDomain }) => (
                        <Cell className={classes.cell}>
                          <CellText className={classes.boldCell}>
                            {highlightText(dataItem.name, highlightKeywords)}
                          </CellText>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      field={GRID_COLUMNS.STATUS}
                      {...columnsConfig[GRID_COLUMNS.STATUS]}
                      title={formatMessageTable(`${GRID_COLUMNS.STATUS}`)}
                      cell={({ dataItem }: { dataItem: ModifiedDomain }) => (
                        <Cell className={classes.cell}>
                          <Grid container direction="row">
                            <Grid item>
                              <img
                                className={classes.dmarcStateIcon}
                                alt="dmarc state"
                                src={IMAGES[dataItem.dmarcImage]}
                              />
                            </Grid>
                            <Grid item xs={11}>
                              <CellText className={classes.statusCell}>
                                {formatMessage(`dmarc_states.${dataItem.dmarcState[0]}`, {
                                  br: () => <br key={uuidv4()} />,
                                  b: (text: string) => <b key={text}>{text}</b>,
                                  policy:
                                    (dataItem.dmarcState[0] === 'protected' && formatMessage(dataItem.policy)) || ''
                                })}
                              </CellText>
                            </Grid>
                          </Grid>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      width={100}
                      field={GRID_COLUMNS.ACTION}
                      {...columnsConfig[GRID_COLUMNS.ACTION]}
                      title={formatMessageTable(`${GRID_COLUMNS.ACTION}`)}
                      cell={({ dataItem }: { dataItem: ModifiedDomain }) => (
                        <Cell className={classes.cell}>
                          <CellText onClick={() => dataItem.seeDetails(dataItem)} className={classes.blueNormalCell}>
                            {formatMessage('see_details')}
                          </CellText>
                        </Cell>
                      )}
                    />
                  </DataTable>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        {!isReportLoaded && (
          <MaskLayer>{formatMessageProgress('title', { br: () => <br key={uuidv4()} /> })}</MaskLayer>
        )}
      </Grid>
    )
  }, [classes, formatMessage, formatMessageProgress, formatMessageTable, dashboardDomainsLogic])
}

export default DashboardDomains
