import requestMiddlewareGenerator from 'global/lib/api/requestMiddleware'

export default requestMiddlewareGenerator({
  additionalConfigGenerator: baseConfig => {
    return {
      data: {
        ...baseConfig.data
      }
    }
  }
})
