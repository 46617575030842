import { makeStyles } from '@material-ui/core/styles'

import tableStyles, { TABLE_HEADER_HEIGHT, TABLE_FOOTER_HEIGHT } from 'global/components/lib/grid/tableStyles'

export const CELL_HEIGHT = 65
const TABLE_MIN_HEIGHT = CELL_HEIGHT * 5 + TABLE_HEADER_HEIGHT + TABLE_FOOTER_HEIGHT

export default makeStyles(theme => {
  const defaultTableStyles = tableStyles(theme, CELL_HEIGHT)
  const dataTable: any = {
    ...defaultTableStyles.dataTable,
    height: TABLE_MIN_HEIGHT,

    '& td': {
      lineHeight: `${theme.spacing(2.5)}px`
    }
  }

  return {
    ...defaultTableStyles,
    tableArea: {
      ...defaultTableStyles.tableArea,
      minHeight: TABLE_MIN_HEIGHT
    },
    flexibleTableArea: {
      ...defaultTableStyles.tableArea,
      minHeight: 'auto',
      marginBottom: theme.spacing(2)
    },
    infoContainer: {
      marginTop: theme.spacing(1)
    },
    dataTable,
    flexibleDataTable: {
      ...dataTable,
      height: 'auto'
    }
  }
})
