import { FilterDescriptor, SortDescriptor } from '@progress/kendo-data-query'
import { createSlice } from '@reduxjs/toolkit'

import { ColumnsConfig, GridColumns } from 'global/types/dataTables/dataTables'
import { config } from 'global/lib/config'

interface EmailsByRegionCountryState {
  GRID_COLUMNS: GridColumns
  columnsConfig: ColumnsConfig
  sort: SortDescriptor[]
  page: { skip: number; take: number }
  group: any[]
  collapsedGroups: any[]
  expandedRows: any[]
  filter: null | FilterDescriptor[] | string[]
}

export const GRID_COLUMNS = {
  RECEIVED: 'formattedCreated',
  SENDER: 'sender',
  UNIQUE_RECIPIENTS: 'uniqueRecipients',
  SUBJECT: 'subject',
  CREATE_INCIDENT: 'createIncident'
}

export const COLUMNS_CONFIG = {
  [GRID_COLUMNS.RECEIVED]: {
    orderIndex: 0,
    show: true,
    sortable: false
  },
  [GRID_COLUMNS.SENDER]: {
    orderIndex: 1,
    show: true,
    sortable: false
  },
  [GRID_COLUMNS.UNIQUE_RECIPIENTS]: {
    orderIndex: 2,
    show: true,
    sortable: false
  },
  [GRID_COLUMNS.SUBJECT]: {
    show: true,
    orderIndex: 3,
    sortable: false
  },
  [GRID_COLUMNS.CREATE_INCIDENT]: {
    orderIndex: 4,
    show: true,
    sortable: false
  }
}

// Store
export const INITIAL_STATE: EmailsByRegionCountryState = {
  GRID_COLUMNS,
  columnsConfig: COLUMNS_CONFIG,
  sort: [{ field: GRID_COLUMNS.RECEIVED, dir: 'desc' }],
  page: { skip: 0, take: config.DATA_TABLE.FORENSICS.ITEMS_PER_PAGE },
  group: [],
  collapsedGroups: [],
  expandedRows: [],
  filter: null
}

export const emailsByRegionCountrySlice = createSlice({
  name: 'DATATABLE/EMAILS_BY_REGION_COUNTRY',
  initialState: INITIAL_STATE,
  reducers: {
    update: (state, action) => {
      return {
        ...state,
        ...action.payload?.config
      }
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  }
})

export const { update, reset } = emailsByRegionCountrySlice.actions

export default emailsByRegionCountrySlice.reducer
