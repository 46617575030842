import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { generateAccessTokenLib } from 'global/lib/accessToken/accessToken'

export default function useAccessToken() {
  const { accessTokenStore }: any = useSelector((_stores: any) => ({
    accessTokenStore: _stores.accessToken || {}
  }))

  const lib = useMemo(() => {
    return generateAccessTokenLib(() => accessTokenStore)
  }, [accessTokenStore])

  // always return lib first followed by store
  return [lib, accessTokenStore]
}
