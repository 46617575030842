import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

interface RedirectRouteProps {
  to: (path: string) => string
}

const RedirectRoute = ({ to }: RedirectRouteProps) => {
  const location = useLocation()
  const navTo = to(location.pathname)
  return <Navigate to={navTo} replace />
}

export default RedirectRoute
