import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { DialogContent, Grid, Typography } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'global/lib/localization'

import BaseDialogActions from 'sen/components/lib/dialogs/newIncidentDialog/lib/BaseDialogActions'

import styles from 'sen/components/lib/dialogs/newIncidentDialog/newIncidentDialogStyles'

const BASE_I18N_KEY = 'sen.app.new_incident_dialog.step_done'

export interface DoneProps {
  activeStep: number
  onClose: () => void
}

const Done: React.FC<DoneProps> = ({ onClose, activeStep = 5 }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  const buttonStatus = useMemo(() => {
    return {
      onNext: 'close',
      onNextStep: onClose,
      onPrevStep: onClose
    }
  }, [onClose])

  return useMemo(
    () => (
      <Grid container item direction="column">
        <DialogContent className={classes.dialogContent}>
          <Grid container item direction="column">
            <Grid item className={classes.spacerBottom}>
              <Typography className={classes.title}>{formatMessage('title')}</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>{formatMessage('description')}</Typography>
            </Grid>
          </Grid>
        </DialogContent>

        <BaseDialogActions activeStep={activeStep} buttonStatus={buttonStatus} onClose={onClose} />
      </Grid>
    ),
    [classes, formatMessage, onClose, buttonStatus, activeStep]
  )
}

Done.propTypes = {
  onClose: PropTypes.func.isRequired,
  activeStep: PropTypes.number.isRequired
}

export default Done
