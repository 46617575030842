import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Grid } from '@barracuda-internal/bds-core'

import styles from 'global/components/features/signinSignup/signinSignupStyles'

export interface SigninSignupHeaderProps {
  children: any
}

const SigninSignupHeader: React.FC<SigninSignupHeaderProps> = ({ children }) => {
  const classes = styles()

  return useMemo(
    () => (
      <Grid container className={classes.headingWrapperSingleColumn}>
        {children}
      </Grid>
    ),
    [children, classes]
  )
}

SigninSignupHeader.propTypes = {
  children: PropTypes.any.isRequired
}

export default SigninSignupHeader
