import React from 'react'
import PropTypes from 'prop-types'

import { Typography, Grid as BDSGrid } from '@barracuda-internal/bds-core'
import { Back as ArrowLeftIcon, Forward as ArrowRightIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'
import { Warning } from '@barracuda-internal/bds-core/dist/Icons/Feedback'
import { Attachment as AttachmentIcon, Email as EmailIcon } from '@barracuda-internal/bds-core/dist/Icons/Email'

import { Grid, GridColumn } from '@progress/kendo-react-grid'

import { Cell, CellText, CellIcon } from 'global/components/lib/grid/cell'
import EmailDetailsDialog from 'global/components/lib/dialogs/emailDetailsDialog/EmailDetailsDialog'
import { config } from 'global/lib/config'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import { useFormatMessage } from 'global/lib/localization'
import { Email } from 'global/types/api/emailsType'

import { UseEmailDetailsInterface } from 'fir/components/lib/dialogs/emailDetailsDialog/useEmailDetailsInterface'

import styles, { reviewMessagesStyles } from '../newIncidentStyles'

export interface Props {
  country?: string
  emailInfo?: Email
  reviewMessages: {
    onEmailClick: (emailId: string) => void
    dataTables: any
    emailDetailConfig: any
    emailDetailsIncidentInterface: UseEmailDetailsInterface
    gridData: any[]
    isEmailsLoading: boolean
    isEmailsSuccess: boolean
    isMoreEmailsLoading: boolean
    newIncident: any
    pageConfig: any
  }
}

const BASE_I18N_KEY = 'fir.app.new_incident_wizard'
const BASE_TABLE_I18N_KEY = 'fir.app.insights.emails_by_region_country'
const WARNING_COUNT = 100

const ReviewMessages: React.FC<Props> = ({ country, reviewMessages }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatMessageTable = useFormatMessage(BASE_TABLE_I18N_KEY)
  const baseClasses = styles()
  const classes = reviewMessagesStyles()
  const [emailDetailDialogConfig, emailDetailDialogActions] = reviewMessages.emailDetailsIncidentInterface

  const { GRID_COLUMNS } = reviewMessages.dataTables.newIncident
  const {
    gridData,
    isEmailsLoading,
    isEmailsSuccess,
    isMoreEmailsLoading,
    newIncident,
    onEmailClick,
    pageConfig
  } = reviewMessages

  return (
    <div className={baseClasses.wrapper}>
      {emailDetailDialogActions.open && (
        <EmailDetailsDialog data={emailDetailDialogConfig} hideReplyTo isGroupedRecipients showThreatTab />
      )}
      {(isEmailsLoading || isMoreEmailsLoading) && <LinearProgress />}
      {isEmailsSuccess && (
        <>
          {newIncident.emails.totalCount > WARNING_COUNT && (
            <Typography className={classes.warningText} variant="subtitle1">
              <span className={classes.warningIcon}>
                <Warning data-field="warning-icon" fontSize="medium" />
              </span>
              {formatMessage('we_have_found', { count: newIncident.emails.totalCount })}
            </Typography>
          )}
          {newIncident.emails.totalCount <= WARNING_COUNT && (
            <Typography className={baseClasses.bold} variant="subtitle1">
              {formatMessage('we_have_found', { count: newIncident.emails.totalCount })}
            </Typography>
          )}
          <BDSGrid container spacing={1}>
            {(newIncident.form.senderName || newIncident.form.senderEmail) && (
              <>
                <BDSGrid item xs={2} className={classes.leftGrid}>
                  <Typography className={baseClasses.bold} variant="subtitle1">
                    {formatMessage('from')}:
                  </Typography>
                </BDSGrid>
                <BDSGrid item xs={10} className={classes.rightGrid}>
                  <Typography className={classes.ellipsis}>
                    {newIncident.form.senderName && `${newIncident.form.senderName}`}
                    {newIncident.form.senderEmail && ` <${newIncident.form.senderEmail}>`}
                  </Typography>
                </BDSGrid>
              </>
            )}
            {newIncident.form.emailSubject && (
              <>
                <BDSGrid item xs={2} className={classes.leftGrid}>
                  <Typography className={baseClasses.bold} variant="subtitle1">
                    {formatMessage('subject')}:
                  </Typography>
                </BDSGrid>
                <BDSGrid item xs={10} className={classes.rightGrid}>
                  <Typography> {newIncident.form.emailSubject} </Typography>
                </BDSGrid>
              </>
            )}
            {newIncident.form.bodyText && (
              <>
                <BDSGrid item xs={2} className={classes.leftGrid}>
                  <Typography className={baseClasses.bold}>{formatMessage('form.message_body_text')}:</Typography>
                </BDSGrid>
                <BDSGrid item className={classes.rightGrid} xs={10}>
                  <Typography className={classes.ellipsis}> {newIncident.form.bodyText} </Typography>
                </BDSGrid>
              </>
            )}
            {newIncident.form.bodyLinks && (
              <>
                <BDSGrid item xs={2} className={classes.leftGrid}>
                  <Typography className={baseClasses.bold}> {formatMessage('form.message_body_links')}: </Typography>
                </BDSGrid>
                <BDSGrid item className={classes.rightGrid} xs={10}>
                  <Typography className={classes.ellipsis}> {newIncident.form.bodyLinks} </Typography>
                </BDSGrid>
              </>
            )}
            {newIncident.form.attachmentName && (
              <>
                <BDSGrid item xs={2} className={classes.leftGrid}>
                  <Typography className={baseClasses.bold}>{formatMessage('form.attachment_name')}:</Typography>
                </BDSGrid>
                <BDSGrid item xs={10}>
                  <Typography className={classes.rightGrid}>{newIncident.form.attachmentName}</Typography>
                </BDSGrid>
              </>
            )}
          </BDSGrid>

          <Grid className={classes.grid} {...pageConfig} data={gridData}>
            <GridColumn
              field={GRID_COLUMNS.RECEIVED}
              title={formatMessageTable('received_date')}
              cell={({ dataItem }) => (
                <Cell>
                  <CellText>
                    {dataItem.formattedCreated}
                    <br />
                    <CellIcon
                      className={classes.smallIcon}
                      icon={
                        dataItem.sendingDirection === config.SENDING_DIRECTION.OUTGOING ? ArrowLeftIcon : ArrowRightIcon
                      }
                    />
                    <CellIcon className={classes.smallIcon} icon={EmailIcon} />
                    {formatMessage(
                      `${dataItem.sendingDirection === config.SENDING_DIRECTION.OUTGOING ? 'outbound' : 'inbound'}`
                    )}
                  </CellText>
                </Cell>
              )}
            />
            <GridColumn
              field={GRID_COLUMNS.SENDER}
              title={formatMessageTable(`${GRID_COLUMNS.SENDER}`)}
              cell={({ dataItem }) => {
                if (!dataItem.sender) {
                  return null
                }

                const [senderName, senderAddress] = dataItem.sender.split('<')

                return (
                  <Cell>
                    <CellText>
                      {senderName && <b>{senderName}</b>}
                      {senderAddress && (
                        <>
                          <br />
                          {/* need to re-add the < since we split on it in the const above */}
                          {`<${senderAddress}`}
                        </>
                      )}
                    </CellText>
                  </Cell>
                )
              }}
            />
            <GridColumn
              field={GRID_COLUMNS.MAILBOX}
              title={formatMessageTable('mailbox')}
              cell={({ dataItem }) => (
                <Cell>
                  <CellText>{dataItem.mailbox}</CellText>
                </Cell>
              )}
            />
            <GridColumn
              field={GRID_COLUMNS.SUBJECT}
              title={formatMessage('subject')}
              cell={({ dataItem }) => (
                <Cell>
                  <CellText ellipsis>{dataItem.subject}</CellText>
                </Cell>
              )}
            />
            <GridColumn
              headerClassName={classes.emailColumn}
              cell={({ dataItem }) => (
                <Cell className={classes.emailColumn}>
                  <div>
                    <CellIcon className={dataItem.attachmentCount === 0 ? classes.hidden : ''} icon={AttachmentIcon} />
                    <span
                      className={classes.pointer}
                      role="presentation"
                      onClick={() => {
                        onEmailClick(dataItem)
                      }}
                    >
                      <CellIcon icon={EmailIcon} />
                    </span>
                  </div>
                </Cell>
              )}
            />
          </Grid>
        </>
      )}
    </div>
  )
}

ReviewMessages.propTypes = {
  country: PropTypes.string,
  emailInfo: PropTypes.any,
  reviewMessages: PropTypes.any.isRequired
}

ReviewMessages.defaultProps = {
  country: undefined,
  emailInfo: undefined
}

export default ReviewMessages
