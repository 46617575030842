import { useEffect, useMemo, useState, useCallback, useRef, RefObject } from 'react'

import { process } from '@progress/kendo-data-query'

import { isPending, isSuccess, getErrorMessage } from 'global/redux/toolkit/api'
import {
  DMARC_SENDER_APPROVAL_STATUSES,
  DMARC_MIN_PASSED_RATIO,
  DmarcSource,
  CompanyDomain
} from 'global/types/api/dmarc'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { BDSGridPagerConfig } from 'global/types/dataTables/dataTables'
import { ColumnsConfig } from 'global/types/dataTables/columnsConfigType'
import { SearchFieldProps, useSearchFieldLogic } from 'global/components/lib/searchField/SearchField'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import {
  getDomainTotals,
  getAllRejectedSources,
  resetAllRejectedSources,
  setApprovalStatusForSender,
  resetApprovalStatuses
} from 'sen/redux/features/dmarc/dmarcSlice'
import {
  update as updateDmarcAllRejectedTable,
  reset as resetDmarcAllRejectedTable
} from 'sen/redux/features/dataTables/dmarcSources/dmarcAllRejected'

export interface ModifiedDmarcSource extends DmarcSource {
  isDmarcFailed: boolean
}

export interface TableConfig {
  isLoaded: boolean
  inProgress: boolean
  tableData: {
    total: number
    data: ModifiedDmarcSource[]
  }
  pageConfig: BDSGridPagerConfig
  columns: { [key: string]: string }
  columnsConfig: { [key: string]: ColumnsConfig }
  isFlexibleTable: boolean
}

export interface RejectedTabLogicProps {
  domain: CompanyDomain
}

export interface LogicConfig {
  searchFieldConfig: SearchFieldProps
  isMarkInProgress: boolean
  dmarcSourcesTableRef: RefObject<any>
  tableConfig: TableConfig
  error: string | undefined
}

export interface EventHandlers {
  onMarkAsApproved: (source: DmarcSource) => void
}

export const SEARCH_FIELD_ID = 'dmarc-review-tables-search'

export default function useRejectedTabLogic({ domain }: RejectedTabLogicProps): [LogicConfig, EventHandlers] {
  const {
    allDmarcSources,
    isAllDmarcSourcesLoading,
    allDmarcSourcesError,
    allDmarcSourcesLoadedOffsets,
    allDmarcSourcesTable,
    isMarkInProgress,
    isMarkSuccess,
    markError
  } = useAppSelector(_stores => ({
    allDmarcSources: _stores.dmarc.allRejectedSources,
    isAllDmarcSourcesLoading: isPending(_stores.dmarc.getAllRejectedSourcesApiStatus),
    allDmarcSourcesError: getErrorMessage(_stores.dmarc.getAllRejectedSourcesApiStatus),
    allDmarcSourcesLoadedOffsets: _stores.dmarc.loadedAllRejectedSourcesOffsets,
    allDmarcSourcesTable: _stores.dataTables.dmarcSources.allRejected,
    isMarkInProgress: isPending(_stores.dmarc.setApprovalStatusForSenderApiStatus),
    isMarkSuccess: isSuccess(_stores.dmarc.setApprovalStatusForSenderApiStatus),
    markError: getErrorMessage(_stores.dmarc.setApprovalStatusForSenderApiStatus)
  }))
  const dispatch = useAppDispatch()
  const [searchString, setSearchString] = useState<string>('')
  const dmarcSourcesTableRef = useRef(null)

  // init
  useEffect(() => {
    dispatch(getAllRejectedSources({ domain: domain.name }))

    return () => {
      dispatch(resetDmarcAllRejectedTable())
      dispatch(resetAllRejectedSources())
      dispatch(resetApprovalStatuses())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isMarkSuccess) {
      dispatch(getDomainTotals(domain.name))
      dispatch(resetDmarcAllRejectedTable())
      dispatch(resetAllRejectedSources())
      dispatch(getAllRejectedSources({ domain: domain.name }))
    }
  }, [dispatch, isMarkSuccess, domain])

  const [debouncedOnChange, validateSearchString] = useSearchFieldLogic(
    (search: string) => {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.DF_DOMAINS_DMARC_REVIEW_REJECTED_TAB_SEARCH, { domain })

      dispatch(updateDmarcAllRejectedTable({ search: search.trim(), skip: 0 }))
      dispatch(resetAllRejectedSources())
      dispatch(getAllRejectedSources({ domain: domain.name }))

      setSearchString(search.trim())
    },
    isAllDmarcSourcesLoading,
    SEARCH_FIELD_ID
  )

  const searchFieldConfig: SearchFieldProps = useMemo(() => {
    return {
      id: SEARCH_FIELD_ID,
      value: searchString,
      onChange: (e: any) => {
        const validatedSearchString = validateSearchString(e.target.value)

        debouncedOnChange(validatedSearchString)
        setSearchString(validatedSearchString)
      },
      disabled: isAllDmarcSourcesLoading || isMarkInProgress
    }
  }, [searchString, debouncedOnChange, validateSearchString, isAllDmarcSourcesLoading, isMarkInProgress])

  const tableData = useMemo(() => {
    const { skip, take } = allDmarcSourcesTable

    const { data } = process(
      (allDmarcSources?.results || []).map((report: DmarcSource) => ({
        ...(report && {
          ...report,
          isDmarcFailed: report.dmarcFailRatio > DMARC_MIN_PASSED_RATIO
        })
      })),
      { skip, take }
    )

    return {
      data: data.filter(report => report.host),
      total: allDmarcSources?.count || 0
    }
  }, [allDmarcSources, allDmarcSourcesTable])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = allDmarcSourcesTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: tableData.total,
      onPageChange: (e: any) => {
        dispatch(updateDmarcAllRejectedTable(e.page))
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.DF_DOMAINS_DMARC_REVIEW_REJECTED_TAB_PAGINATE, { domain })

        if (!allDmarcSourcesLoadedOffsets?.includes(e.page.skip)) {
          dispatch(getAllRejectedSources({ domain: domain.name }))
        }
      }
    }
  }, [allDmarcSourcesTable, tableData, dispatch, allDmarcSourcesLoadedOffsets, domain])

  const onMarkAsApproved: EventHandlers['onMarkAsApproved'] = useCallback(
    source => {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.DF_DOMAINS_DMARC_REVIEW_REJECTED_TAB_MARK_AS_APPROVED, {
        domain,
        source: source.host
      })

      dispatch(
        setApprovalStatusForSender({
          sender: source.host,
          domain: domain.name,
          status: DMARC_SENDER_APPROVAL_STATUSES.approved
        })
      )
    },
    [dispatch, domain]
  )

  return useMemo(() => {
    return [
      {
        searchFieldConfig,
        isMarkInProgress,
        dmarcSourcesTableRef,
        tableConfig: {
          isLoaded: !!allDmarcSources,
          inProgress: isAllDmarcSourcesLoading,
          tableData,
          pageConfig,
          columns: allDmarcSourcesTable.GRID_COLUMNS,
          columnsConfig: allDmarcSourcesTable.columnsConfig,
          isFlexibleTable: tableData.total < allDmarcSourcesTable.ITEMS_PER_PAGE
        },
        error: allDmarcSourcesError || markError
      },
      {
        onMarkAsApproved
      }
    ]
  }, [
    searchFieldConfig,
    isMarkInProgress,
    allDmarcSourcesError,
    allDmarcSources,
    isAllDmarcSourcesLoading,
    tableData,
    pageConfig,
    allDmarcSourcesTable,
    onMarkAsApproved,
    dmarcSourcesTableRef,
    markError
  ])
}
