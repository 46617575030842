import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from 'global/configs/theme/defaults'

import tableStyles from 'global/components/lib/grid/tableStyles'

export const CELL_HEIGHT = 47
const EMPTY_HEIGHT = 80
export default makeStyles(theme => {
  const defaultTableStyles = tableStyles(theme, CELL_HEIGHT)

  return {
    ...defaultTableStyles,
    container: {
      marginTop: theme.spacing(1)
    },
    tableArea: {
      marginTop: 0
    },
    title: {
      marginBottom: theme.spacing(1)
    },
    dataTable: {
      ...defaultTableStyles.dataTable,
      '& td': {
        fontSize: '13px',
        lineHeight: 'normal',
        paddingRight: `${theme.spacing(3)}px !important`,
        paddingLeft: `${theme.spacing(3)}px !important`
      },
      '& th': {
        paddingRight: `${theme.spacing(3)}px !important`,
        paddingLeft: `${theme.spacing(3)}px !important`
      },
      // Disable hover over background color
      '& .k-grid-table tr:hover': {
        background: 'transparent'
      },
      '& .k-grid-table tr.k-alt:hover': {
        background: 'transparent'
      },
      '& .k-grid-header tr:hover': {
        background: 'transparent'
      },
      '& .k-grid-header tr.k-alt:hover': {
        background: 'transparent'
      },
      '& .k-grid-table tr:nth-child(odd)': {
        background: COLORS.LIGHT_BACKGROUND
      }
    },
    loadMoreButton: {
      alignSelf: 'end',
      height: CELL_HEIGHT,
      marginRight: theme.spacing(2)
    },
    subtitle: {
      marginBottom: theme.spacing(2),
      lineHeight: '10px',
      color: COLORS.GRAY
    },
    loaderWrapper: {
      alignItems: 'center',
      display: 'flex',
      height: EMPTY_HEIGHT,
      justifyContent: 'center'
    }
  }
})
