import { useCallback, useMemo } from 'react'

import { useFormatMessage } from 'global/lib/localization'

interface TimePeriodData {
  readonly name: string
  readonly value: number
}

export type GetTimePeriodByValue = (period: number) => TimePeriodData | undefined

const TIME_PERIOD_OPTIONS: TimePeriodData[] = [
  { name: 'one_hour', value: 1 },
  { name: 'three_hours', value: 3 },
  { name: 'twelve_hours', value: 12 },
  { name: 'one_day', value: 24 },
  { name: 'one_week', value: 168 },
  { name: 'one_month', value: 720 }
]

const BASE_I18N_KEY = 'app.time_periods'

export default function useTimePeriod(): [GetTimePeriodByValue] {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const formattedPeriodOptions: TimePeriodData[] = useMemo(() => {
    return TIME_PERIOD_OPTIONS.map(period => ({
      ...period,
      name: formatMessage(period.name)
    }))
  }, [formatMessage])

  const getTimePeriodByValue = useCallback(
    (value: TimePeriodData['value']) => {
      return formattedPeriodOptions.find(period => period.value === value)
    },
    [formattedPeriodOptions]
  )

  return [getTimePeriodByValue]
}
