import { useMemo, useCallback, useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'global/redux/toolkit/hooks'
import { removeAlert, Alert, AlertId } from 'global/redux/features/app/appSlice'

export type RouteAlerts = Alert[]
export type RemoveAlert = (alertId: AlertId) => void

export default function useTestAiDialogLogic(): [RouteAlerts, RemoveAlert] {
  const dispatch = useAppDispatch()

  const { activePathId, alerts } = useAppSelector(_stores => ({
    activePathId: _stores.app.activePath.id,
    alerts: _stores.app.alerts
  }))

  const onRemoveAlert = useCallback(
    (alertId: AlertId) => {
      dispatch(removeAlert(alertId))
    },
    [dispatch]
  )

  useEffect(() => {
    if (alerts.length && activePathId === 'DASHBOARD') {
      const timeOutId = setTimeout(() => {
        onRemoveAlert(alerts[alerts.length - 1][0])
      }, 3000)

      return () => {
        clearTimeout(timeOutId)
      }
    }
    return undefined
  }, [alerts, alerts.length, activePathId, onRemoveAlert])

  const routeAlerts = useMemo(() => {
    if (alerts.length && activePathId === 'DASHBOARD') {
      return [alerts[alerts.length - 1]]
    }
    return alerts.filter(attack => attack[3].includes(activePathId || '') || !attack[3].length)
  }, [alerts, activePathId])

  return useMemo(() => {
    return [routeAlerts, onRemoveAlert]
  }, [routeAlerts, onRemoveAlert])
}
