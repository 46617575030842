import { makeStyles } from '@material-ui/core/styles'

import dialogStyles from 'global/components/lib/dialogs/dialogStyles'

export default makeStyles(theme => {
  const defaultDialogStyles = dialogStyles(theme)

  return {
    ...defaultDialogStyles,
    actions: {
      paddingTop: 8,
      paddingBottom: 16,
      paddingRight: 16
    },
    text: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2)
    },
    title: {
      fontSize: 20,
      fontWeight: 500,
      padding: theme.spacing(1)
    }
  }
})
