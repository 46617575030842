import React from 'react'
import PropTypes from 'prop-types'

import styles from './customTextStyles'

export interface CustomTextProps {
  children: any
}

const CustomText: React.FC<CustomTextProps> = ({ children }) => {
  const classes = styles()

  return <div className={classes.wrapper}>{children}</div>
}

CustomText.propTypes = {
  children: PropTypes.any.isRequired
}

export default CustomText
