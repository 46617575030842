import React, { useMemo } from 'react'

import useDFPContentCondition, {
  UseDFPContentConditionParams,
  UIState
} from 'sen/components/lib/useDFPContentCondition/useDFPContentCondition'
import MissingRequirement from 'sen/components/pages/missingRequirement/MissingRequirement'
import ScanInProgress from 'sen/components/pages/dashboard/scanInProgress/ScanInProgress'

export type UseDFPContentConditionProps = UseDFPContentConditionParams

export interface DFPContentLoader {
  default: (props: UseDFPContentConditionProps, userEmail: string) => any
  loading?: (props: UseDFPContentConditionProps, userEmail: string) => any
  missedEntitlement?: (props: UseDFPContentConditionProps, userEmail: string) => any
  scanInProgress?: (props: UseDFPContentConditionProps, userEmail: string) => any
}

export default function dfpContentLoader(loaderConfig: DFPContentLoader) {
  const DFPContentLoader: React.FC<UseDFPContentConditionProps> = props => {
    const [uiState, userEmail] = useDFPContentCondition()

    return useMemo(() => {
      switch (uiState) {
        case UIState.missedEntitlement:
          return loaderConfig.missedEntitlement?.(props, userEmail) || <MissingRequirement product="dfp" />
        case UIState.scanInProgress:
          return loaderConfig.scanInProgress?.(props, userEmail) || <ScanInProgress userEmail={userEmail} />
        default:
          return loaderConfig.default(props, userEmail)
      }
    }, [uiState, props, userEmail])
  }

  return DFPContentLoader
}
