import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Card, Button, Typography } from '@barracuda-internal/bds-core'

import BarracudaPolicy from 'global/components/lib/barracudaPolicy/BarracudaPolicy'
import styles from 'global/components/features/signinSignup/signupPolicy/signupPolicyStyles'
import SigninSignupLayout from 'global/components/features/signinSignup/layout/SigninSignupLayout'
import useSignupPolicyLogic, {
  UseSignupPolicyLogicProps
} from 'global/components/features/signinSignup/signupPolicy/useSignupPolicyLogic'
import { useFormatMessage } from 'global/lib/localization'

const BASE_I18N_KEY = 'app'

export interface SignupPolicyProps extends UseSignupPolicyLogicProps {
  bgImage?: string
  bgImageGradient?: string
}

export const SignupPolicy: React.FC<SignupPolicyProps> = props => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [signupPolicyLogic] = useSignupPolicyLogic(props)

  const { onClose } = signupPolicyLogic
  const { bgImage, bgImageGradient } = props

  return useMemo(
    () => (
      <SigninSignupLayout bgImage={bgImage} bgImageGradient={bgImageGradient}>
        <Card className={classes.wrapper}>
          <Typography className={classes.title} align="center" data-field="title">
            {formatMessage('signup_complete_policy.title')}
          </Typography>
          <div className={classes.policyContent} data-field="barracuda-policy">
            <BarracudaPolicy />
          </div>
          <div className={classes.footer} data-field="footer">
            <Button className={classes.button} onClick={onClose} variant="contained" data-action="close">
              {formatMessage('close')}
            </Button>
          </div>
        </Card>
      </SigninSignupLayout>
    ),
    [onClose, bgImage, bgImageGradient, classes, formatMessage]
  )
}

SignupPolicy.propTypes = {
  bgImage: PropTypes.string,
  bgImageGradient: PropTypes.string
}

export default SignupPolicy
