import React, { useMemo } from 'react'

import { Typography, Tooltip } from '@barracuda-internal/bds-core'
import Grid from '@material-ui/core/Grid'

import { HeaderDetail } from 'global/components/lib/dialogs/emailDetailsDialog/emailDetailDialogTypes'
import styles from 'global/components/lib/dialogs/emailDetailsDialog/emailDetailsDialogStyles'
import { useFormatMessage } from 'global/lib/localization'

export interface EmailHeaderProps {
  headerDetails: HeaderDetail[]
  shouldHighlightEnvelope: (title: string) => boolean
}

const BASE_I18N_KEY = 'app.email_details_dialog'

export const EmailHeaderDetails: React.FC<EmailHeaderProps> = ({ headerDetails, shouldHighlightEnvelope }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  return useMemo(
    () => (
      <Grid
        container
        className={classes.emailHeaderWrapper}
        alignItems="center"
        justifyContent="center"
        direction="column"
      >
        {headerDetails.map((headerDetail: HeaderDetail) => (
          <Grid
            key={headerDetail.id}
            container
            className={classes.headerRow}
            alignItems="center"
            justifyContent="flex-start"
            direction="row"
          >
            <Grid item xs={12} sm={12} md={2} lg={2} className={classes.headerLabelWrapper}>
              <Typography className={classes.headerLabel} align="left">
                {headerDetail.id.length ? `${formatMessage(headerDetail.id)}:` : <br />}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <Tooltip classes={{ tooltip: classes.tooltip }} title={headerDetail.content || ''} placement="top-start">
                <Typography
                  className={`${classes.headerText} ${shouldHighlightEnvelope(headerDetail.id) &&
                    classes.highlightHeaderText}`}
                  align="left"
                >
                  {headerDetail.content}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        ))}
      </Grid>
    ),
    [
      classes.emailHeaderWrapper,
      classes.headerRow,
      classes.headerLabelWrapper,
      classes.headerLabel,
      classes.tooltip,
      classes.headerText,
      classes.highlightHeaderText,
      formatMessage,
      headerDetails,
      shouldHighlightEnvelope
    ]
  )
}

export default EmailHeaderDetails
