import { useEffect, useCallback, useMemo } from 'react'

import { useSelector } from 'react-redux'

export interface SignupPolicyLogicLogicProps {
  onClose: () => void
}

export interface UseSignupPolicyLogicProps {
  onGoto: any
  disableUserCheck?: boolean
}

export default function useSignupPolicyLogic({
  onGoto,
  disableUserCheck = false
}: UseSignupPolicyLogicProps): [SignupPolicyLogicLogicProps] {
  const { signupId }: any = useSelector((_stores: any) => ({
    signupId: _stores.auth.signup.id
  }))

  useEffect(() => {
    if (!disableUserCheck && !signupId) {
      onGoto()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClose = useCallback(() => {
    onGoto()
  }, [onGoto])

  return useMemo(() => {
    return [
      {
        onClose
      }
    ]
  }, [onClose])
}
