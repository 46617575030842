import React, { useMemo } from 'react'

import { Grid, Typography } from '@barracuda-internal/bds-core'
import { LicenseCompliance } from '@cuda/bds.license-compliance.license-compliance'

import config from 'global/configs/global.app_config'
import { Products, ProductPrefix } from 'global/types/redux/productsType'

import { useFormatMessage } from 'global/lib/localization'

import useLicenseContentLogic from 'global/components/features/license/useLicenseContentLogic'
import styles from 'global/components/features/license/licenseContentStyles'

export interface LicenseContentProps {
  productPrefix: ProductPrefix
  product: Products
  BASE_I18N_KEY: string
}

const LicenseContent: React.FC<LicenseContentProps> = ({ product, productPrefix, BASE_I18N_KEY }) => {
  const [licenseLogic] = useLicenseContentLogic({ product })
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(() => {
    return (
      <Grid container direction="column" className={classes.root}>
        {!licenseLogic.enabled && (
          <Typography className={classes.text} data-field="text" align="center">
            {formatMessage('no_permissions')}
          </Typography>
        )}
        {licenseLogic.enabled && (
          <LicenseCompliance
            urlPrefix={config.LICENSING_COMPLIANCE_PATH}
            serial={licenseLogic.serial}
            mixpanelOptions={{
              mixpanel: window.mixpanel as Mixpanel,
              productPrefix,
              disableMixpanel: !window.mixpanel
            }}
          />
        )}
      </Grid>
    )
  }, [classes, licenseLogic, productPrefix, formatMessage])
}

export default LicenseContent
