import { useEffect, useMemo, useState } from 'react'

import { isPending, isFailed } from 'global/redux/toolkit/api'
import {
  EmailDetailsDialogProps,
  OnCloseDialogCallback,
  DialogActions,
  EMPTY_EMAIL_DETAILS
} from 'global/components/lib/dialogs/emailDetailsDialog/emailDetailDialogTypes'
import { IncidentEmailSearch } from 'global/types/api/remediation'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import { resetGetIncidentEmailBody, getIncidentEmailBody } from 'sen/redux/features/remediation/remediationSlice'

export interface UseEmailDetailsIncidentInterfaceProps {
  onClose?: OnCloseDialogCallback
  senderEmail: string
}

export type UseEmailDetailsIncidentInterface = [
  EmailDetailsDialogProps,
  DialogActions<(incident: IncidentEmailSearch) => void>,
  IncidentEmailSearch | undefined
]

const RECIPIENT_LIMIT = 2

export function renderEmail(incident: IncidentEmailSearch['recipients'][0]) {
  return `${incident.displayName}${incident.displayName ? ' <' : ''}${incident.email}${incident.displayName ? '>' : ''}`
}

export function collectRecipients(recipients: IncidentEmailSearch['recipients']) {
  return recipients
    .slice(0, RECIPIENT_LIMIT)
    .map(recipient => renderEmail(recipient))
    .join(', ')
}

export default function useEmailDetailsIncidentInterface({
  senderEmail,
  onClose
}: UseEmailDetailsIncidentInterfaceProps): UseEmailDetailsIncidentInterface {
  const dispatch = useAppDispatch()
  const { emailBody, isEmailBodyLoading, isEmailBodyFailed } = useAppSelector(_stores => ({
    emailBody: _stores.remediation.incidentEmailBody,
    isEmailBodyLoading: isPending(_stores.remediation.getIncidentEmailBodyApiStatus),
    isEmailBodyFailed: isFailed(_stores.remediation.getIncidentEmailBodyApiStatus)
  }))
  const [isDialogOpened, toggleDialog] = useDialogLogic()
  const [selectedIncident, setSelectedIncident] = useState<IncidentEmailSearch | undefined>()

  // init
  useEffect(() => {
    return () => {
      dispatch(resetGetIncidentEmailBody())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dialogActions = useMemo(
    () => ({
      open: isDialogOpened,
      onOpen: (incident: IncidentEmailSearch) => {
        setSelectedIncident(incident)
        dispatch(
          getIncidentEmailBody({
            senderEmail,
            emailId: incident.emailId
          })
        )

        toggleDialog()
      },
      onClose: () => {
        if (onClose) {
          onClose()
        }

        setSelectedIncident(undefined)
        toggleDialog()
      }
    }),
    [onClose, isDialogOpened, toggleDialog, dispatch, senderEmail]
  )

  return useMemo(() => {
    return [
      {
        emailDetails:
          emailBody && selectedIncident
            ? {
                subject: selectedIncident.subject,
                from: {
                  email: selectedIncident.senderEmail,
                  displayName: ''
                },
                to: {
                  email: collectRecipients(selectedIncident.recipients),
                  displayName: ''
                },
                replyTo: [],
                date: selectedIncident.dateSent,
                bodyMimeType: 'text/html',
                body: emailBody.data,
                attachments: []
              }
            : EMPTY_EMAIL_DETAILS,
        emailDataStatuses: {
          inProgress: isEmailBodyLoading,
          isLoaded: !!emailBody,
          isFailed: isEmailBodyFailed
        },
        eventHandlers: {
          onClose: dialogActions.onClose
        }
      },
      dialogActions,
      selectedIncident
    ]
  }, [dialogActions, emailBody, isEmailBodyLoading, isEmailBodyFailed, selectedIncident])
}
