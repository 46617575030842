import { makeStyles, createStyles } from '@material-ui/core/styles'

import commonStyles from 'global/components/features/signinSignup/styles/commonStyles'

const WRAPPER_WIDTH = 668
const CONTENT_HEIGHT = 455

export const signupPolicyStyles = createStyles({
  wrapper: {
    marginTop: 90,
    display: 'flex',
    height: 'auto',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'top',
    fontFamily: '"Roboto", sans-serif',
    overflow: 'visible',
    maxWidth: WRAPPER_WIDTH,
    flexDirection: 'column'
  },
  title: {
    ...commonStyles.title,
    fontSize: 24,
    margin: '25px auto'
  },
  policyContent: {
    width: '100%',
    height: CONTENT_HEIGHT,
    borderTop: '1px solid #DDDDDD',
    overflowY: 'scroll',
    padding: '0 30px 20px'
  },
  footer: {
    backgroundColor: '#EEEEEE'
  },
  button: {
    ...commonStyles.blueButton,
    float: 'right',
    margin: 20,
    minWidth: 120
  }
})

export default makeStyles(() => signupPolicyStyles)
