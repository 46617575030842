import colors from '@barracuda-internal/bds-core/dist/styles/colors'
import { FONT as font } from 'global/configs/theme/defaults'

export default {
  root: {
    borderRadius: 2,
    margin: '8px 0',
    '&$focused': {
      '& $notchedOutline': {
        borderWidth: 1
      }
    },
    '&:hover': {
      '& $notchedOutline': {
        color: colors.hoverBorder
      }
    }
  },
  input: {
    fontSize: font.size.medium,
    padding: 8
  }
}
