import { useEffect, useMemo } from 'react'

import useAccessTokenLib from 'global/lib/accessToken/useAccessToken'
import { config } from 'global/lib/config'

import { updateUserState } from 'global/redux/features/auth/authApiThunks'
import { useAppDispatch, useAppSelector } from 'global/redux/toolkit/hooks'
import { isSuccess } from 'global/redux/toolkit/api'

import gotoReportPage from 'fir/lib/gotoReportPage'

export interface SigninConnectLogicProps {
  error: string
}

export default function useSigninConnectLogic(): [SigninConnectLogicProps] {
  const dispatch = useAppDispatch()
  const [accessTokenLib] = useAccessTokenLib()
  const { error, isUserUpdateSuccess, userData }: any = useAppSelector((_stores: any) => ({
    error: _stores.auth.error,
    isUserUpdateSuccess: isSuccess(_stores.auth.updateUserStateApiStatus),
    userData: _stores.user.data
  }))

  // init
  useEffect(() => {
    const accessTokenId =
      accessTokenLib.getDefaultFirAccessTokenId() ||
      accessTokenLib.getDefaultBccAccountAccessTokenId(userData.defaultAccountBccId, config.PRODUCTS.FORENSICS) ||
      accessTokenLib.getAllAccessTokens()[0]?.id
    if (accessTokenId && userData.state === config.USER_STATES.PROFILE) {
      dispatch(
        updateUserState({
          accessTokenId,
          expectedState: config.USER_STATES.PROFILE
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // catch user update success and move to report page
  useEffect(() => {
    if (isUserUpdateSuccess) {
      gotoReportPage({ user: userData })
    }
  }, [isUserUpdateSuccess, userData])

  return useMemo(() => {
    return [
      {
        error
      }
    ]
  }, [error])
}
