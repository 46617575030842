import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { snakeCase } from 'lodash'

import {
  Typography,
  Button,
  TextField,
  TextareaAutosize,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Zoom,
  Alert,
  Grid
} from '@barracuda-internal/bds-core'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import DEFAULTS from 'global/configs/theme/defaults'
import { useErrorFormatMessage, useFormatMessage } from 'global/lib/localization'

import useCustomizeAlertDialogLogic from 'sen/components/lib/dialogs/customizeAlertDialog/useCustomizeAlertDialogLogic'
import styles from 'sen/components/lib/dialogs/customizeAlertDialog/customizeAlertDialogStyles'
import { NotificationEmailTemplate } from 'sen/redux/features/settings/types'

export interface CustomizeAlertDialogProps {
  onClose: () => void
}

const BASE_I18N_KEY = 'sen.app.customize_alert_dialog'

export const CustomizeAlertDialog: React.FC<CustomizeAlertDialogProps> = ({ onClose }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatMessageError = useErrorFormatMessage()
  const classes = styles()

  const [inProgress, form, content, error] = useCustomizeAlertDialogLogic({ onClose })

  return useMemo(
    () => (
      <Dialog
        style={{ zIndex: DEFAULTS.SECONDARY_DIALOGS_ZINDEX }}
        maxWidth="md"
        fullWidth
        open
        TransitionComponent={Zoom}
      >
        {(inProgress || form.isSaving) && <LinearProgress className={classes.indicator} />}

        <DialogTitle>
          <Typography className={classes.dialogTitle}>{formatMessage('title')}</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {error && <Alert severity="error">{formatMessageError(error)}</Alert>}
          {(['fromDisplayName', 'introduction', 'signature'] as (keyof NotificationEmailTemplate)[]).map(
            (templateType, index: number) => (
              <Grid key={templateType} className={classes.formContainer} container>
                <Grid container direction="row">
                  <Grid item xs={6}>
                    <Typography className={classes.textAreaLabel}>{formatMessage(snakeCase(templateType))}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      className={classes.formResetButton}
                      color="primary"
                      variant="text"
                      size="large"
                      disabled={inProgress || form.isSaving}
                      onClick={form.onReset(templateType)}
                    >
                      {formatMessage('reset_to_default')}
                    </Button>
                  </Grid>
                </Grid>
                {!!index && (
                  <TextareaAutosize
                    className={classes.textArea}
                    rows={4}
                    rowsMax={4}
                    onChange={form.onChange(templateType)}
                    value={form.template[templateType]}
                    disabled={inProgress || form.isSaving}
                  />
                )}
                {!index && (
                  <TextField
                    className={classes.textField}
                    onChange={form.onChange(templateType)}
                    value={form.template[templateType]}
                    disabled={inProgress || form.isSaving}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                  />
                )}
              </Grid>
            )
          )}
          <Grid className={classes.renderContainer} container direction="column">
            <Typography className={classes.title}>{formatMessage('alert_preview')}</Typography>
            <Grid className={classes.emailHeaderRow} container direction="row">
              <Grid item xs={1}>
                <Typography className={classes.contentLabel}>{formatMessage('from')}:</Typography>
              </Grid>
              <Grid item xs={11}>
                {!inProgress && <Typography noWrap>{content.from}</Typography>}
              </Grid>
            </Grid>
            <Grid className={classes.emailHeaderRow} container direction="row">
              <Grid item xs={1}>
                <Typography className={classes.contentLabel}>{formatMessage('subject')}:</Typography>
              </Grid>
              <Grid item xs={11}>
                <Typography>{content.subject}</Typography>
              </Grid>
            </Grid>
            <Grid>
              <iframe
                id="notification-email-preview"
                src={content.previewLink}
                title="notification-email-preview"
                className={classes.iframe}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="text" disabled={inProgress || form.isSaving} size="large" onClick={onClose}>
            {formatMessage('back')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="large"
            disabled={inProgress || form.isSaving}
            onClick={form.onSave}
          >
            {formatMessage('save')}
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [classes, formatMessage, formatMessageError, onClose, inProgress, form, content, error]
  )
}

CustomizeAlertDialog.propTypes = {
  onClose: PropTypes.func.isRequired
}

export default CustomizeAlertDialog
