import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { COLORS } from 'global/configs/theme/defaults'
import TextLabel, { LabelColors } from 'global/components/lib/textLabel/TextLabel'
import { useFormatMessage } from 'global/lib/localization'

export interface StatusTypeLabelProps {
  status: StatusIds | undefined
  isInternal: boolean | undefined
}

const BASE_STATUS_TYPES_I18N_KEY = 'app.incident_statuses'

export enum StatusIds {
  'FO' = 'FO',
  'IP' = 'IP',
  'NF' = 'NF',
  'ER' = 'ER',
  'QU' = 'QU',
  'DE' = 'DE',
  'NO' = 'NO',
  'EXTERNAL_ER' = 'EXTERNAL_ER'
}

export enum StatusColors {
  normal = 'normal',
  warning = 'warning',
  success = 'success'
}

export type StatusConfig = {
  [key in StatusIds]: StatusColors
}

const STATUS_COLORS: StatusConfig = {
  // nornal
  [StatusIds.FO]: StatusColors.normal,
  [StatusIds.IP]: StatusColors.normal,
  [StatusIds.NF]: StatusColors.normal,
  [StatusIds.ER]: StatusColors.normal,
  [StatusIds.EXTERNAL_ER]: StatusColors.normal,

  // success
  [StatusIds.QU]: StatusColors.success,
  [StatusIds.DE]: StatusColors.success,
  [StatusIds.NO]: StatusColors.success
}

const COLOR_VALUES = {
  [StatusColors.normal]: COLORS.PRODUCT_UI_ELEMENT,
  [StatusColors.warning]: COLORS.RED_600,
  [StatusColors.success]: COLORS.TEAL_600
}

const IncidentTypeLabel: React.FC<StatusTypeLabelProps> = ({ status, isInternal }) => {
  const formatMessage = useFormatMessage(BASE_STATUS_TYPES_I18N_KEY)

  return useMemo(() => {
    if (!status || !STATUS_COLORS[status]) {
      return null
    }

    const updatedStatus = !isInternal && status === StatusIds.ER ? StatusIds.EXTERNAL_ER : status

    const bgColor = STATUS_COLORS[updatedStatus]

    return (
      <TextLabel
        color={bgColor === StatusColors.normal ? LabelColors.dark : LabelColors.light}
        bgColor={COLOR_VALUES[bgColor]}
      >
        {formatMessage(String(updatedStatus.toLowerCase()))}
      </TextLabel>
    )
  }, [status, isInternal, formatMessage])
}

IncidentTypeLabel.propTypes = {
  status: PropTypes.any,
  isInternal: PropTypes.bool
}

IncidentTypeLabel.defaultProps = {
  status: undefined,
  isInternal: undefined
}

export default IncidentTypeLabel
