import React from 'react'
import PropTypes from 'prop-types'

import { Typography, Grid } from '@barracuda-internal/bds-core'

import styles from 'global/components/lib/score/scoreStyles'
import { useFormatMessage } from 'global/lib/localization'

export enum ScoreSize {
  small = 'small',
  normal = 'normal'
}
export type ScoreLevel = '0' | '1' | '2' | '3' | '4' | '5'

export interface ScoreProps {
  size?: ScoreSize
  level: ScoreLevel | undefined
}

const BASE_I18N_KEY = 'app.score'

const Score: React.FC<ScoreProps> = ({ size = ScoreSize.normal, level }) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  if (level) {
    return (
      <Grid container direction="column">
        <Typography className={classes[`lvl${level}label` as keyof typeof classes]}>
          {formatMessage(`level.${level}`)}
        </Typography>
        <Grid container direction="row">
          {[...Array(5).keys()].map((pos: number) => (
            <div key={pos} className={classes[`scoreLvl${level}${size}Pos${pos + 1}` as keyof typeof classes]} />
          ))}
        </Grid>
      </Grid>
    )
  }

  return null
}

Score.propTypes = {
  level: PropTypes.any,
  size: PropTypes.any
}

Score.defaultProps = {
  level: undefined,
  size: ScoreSize.normal
}

export default Score
