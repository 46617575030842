import { makeStyles } from '@material-ui/core/styles'

import dialogStyles from 'global/components/lib/dialogs/dialogStyles'

export default makeStyles(theme => {
  const defaultDialogStyles = dialogStyles(theme)

  return {
    ...defaultDialogStyles,
    titleWrapper: {
      padding: '0px 16px',
      height: 64,
      display: 'flex',
      alignItems: 'center'
    },
    title: {
      fontSize: 20,
      fontWeight: 500,
      padding: theme.spacing(1)
    },
    text: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2)
    }
  }
})
