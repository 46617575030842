import { makeStyles } from '@material-ui/core/styles'
import tabStyles from 'global/theme/tabStyles'

export const potentialIncidentsSettingsStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  label: {
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  subFormControl: {
    marginLeft: theme.spacing(4),
    paddingTop: 5
  },
  subContainer: {
    marginTop: theme.spacing(1)
  },
  formLine: {
    marginBottom: theme.spacing(3),
    width: '80%'
  },
  iconPadding: {
    paddingRight: theme.spacing(1)
  },
  inlineTextField: {
    width: '100%'
  },
  matchExactFrame: {
    marginLeft: theme.spacing(1),
    marginRight: 0,
    marginTop: 18
  },
  policyPlaceSelector: {
    marginLeft: '4px',
    '& .MuiSelect-root': {
      fontWeight: 600
    },
    '& .MuiSvgIcon-root': {
      color: theme.colors.barracudaBlack70
    }
  },
  mdTextField: {
    width: '50%'
  },
  radioButtonGroup: {
    marginLeft: theme.spacing(2)
  },
  smTextField: {
    width: '10%'
  },
  wordWrap: {
    wordWrap: 'break-word'
  }
}))

export default makeStyles(theme => {
  const tabClasses = tabStyles(theme)

  return {
    wrapper: {
      marginLeft: theme.spacing(7)
    },
    dialogWrapper: {
      marginTop: theme.spacing(5),
      overflowX: 'hidden'
    },
    dialogButtons: {
      marginTop: theme.spacing(3),
      paddingRight: 0
    },
    subDialogButtons: {
      float: 'right',
      '& Button': {
        margin: theme.spacing(1)
      }
    },
    emailAlertButton: {
      fontSize: '15px',
      textTransform: 'none'
    },
    emailDetailDialog: {
      padding: theme.spacing(2)
    },
    disabledLabel: {
      color: '#aaaaaa'
    },
    opacity: {
      opacity: 0.5
    },
    inline: {
      display: 'inline-block'
    },
    closerToTop: {
      marginTop: 0
    },
    subSetting: {
      paddingLeft: '20px'
    },
    splitSettings: {
      marginTop: theme.spacing(2),
      borderTop: `1px solid ${theme.palette.grey[300]}`
    },
    disabled: {
      pointerEvents: 'none',
      opacity: 0.4,
      cursor: 'not-allowed'
    },
    bold: {
      fontWeight: theme.font.weight.bold
    },
    alert: {
      marginBottom: '10px'
    },
    textLowercase: {
      textTransform: 'lowercase'
    },
    adjustHeight: {
      height: 4
    },
    invisibleIcon: {
      color: 'transparent'
    },

    // TODO: Merge the below styles into this file
    dialogTabContent: {
      ...tabClasses.dialogTabContent,
      position: 'relative'
    },
    error: {
      marginBottom: theme.spacing(2)
    },
    loadingIndicator: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0
    },
    buttonsContainer: {
      marginBottom: theme.spacing(2)
    },
    formButton: {
      padding: `0 ${theme.spacing(2)}px`,
      marginLeft: theme.spacing(1)
    }
  }
})
