import React, { useMemo } from 'react'

import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'

import {
  Button,
  DataTable,
  DataTableColumn,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
  Typography,
  Zoom
} from '@barracuda-internal/bds-core'

import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'

import useUserEmailsDialogLogic, {
  ModifiedThreat
} from 'ets/components/lib/dialogs/userEmailsDialog/useUserEmailDialogLogic'
import { GRID_COLUMNS, COLUMNS_CONFIG } from 'ets/redux/features/dataTables/userEmails/userEmailsSlice'
import styles from 'ets/components/lib/dialogs/userEmailsDialog/userEmailsDialogStyles'
import DEFAULTS from 'ets/configs/styles/defaults'
import { useFormatMessage } from 'global/lib/localization'

export interface UserEmailsDialog {
  open: boolean
  onClose: () => void
  onSeeDetails: (threatId: string) => void
  userName?: string | null
  domain?: string | null
}

const BASE_I18N_KEY = 'ets.app.dashboard.user_emails_dialog'
const BASE_I18N_TABLE_KEY = 'ets.app.data_tables.user_emails'

export const UserEmailsDialog: React.FC<UserEmailsDialog> = ({ domain, onClose, onSeeDetails, open, userName }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatMessageTable = useFormatMessage(BASE_I18N_TABLE_KEY)
  const classes = styles()
  const [userEmailDialogLogic] = useUserEmailsDialogLogic({ onSeeDetails })

  return useMemo(() => {
    if (!userName && !domain) {
      return null
    }

    const { tableData, inProgress, isViewEmailsDisabled, pageConfig, sortConfig } = userEmailDialogLogic

    return (
      <Dialog
        style={{ zIndex: DEFAULTS.DIALOGS_ZINDEX }}
        maxWidth="lg"
        fullWidth
        open={open}
        TransitionComponent={Zoom}
      >
        {inProgress && <LinearProgress className={classes.tableLoadIndicator} />}
        <DialogTitle className={classes.titleWrapper}>
          <Typography className={classes.title}>
            {userName && formatMessage('title_username', { userName })}
            {domain && formatMessage('title_domain', { domain })}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid className={classes.root} container alignItems="center" justifyContent="center" data-field="grid-root">
            <Grid className={classes.tableArea} item xs={12}>
              <DataTable
                className={`${classes.dataTable} user-emails-table`}
                data={tableData}
                {...pageConfig}
                {...sortConfig}
              >
                <DataTableNoRecords>
                  <Typography>&nbsp;</Typography>
                </DataTableNoRecords>
                <DataTableColumn
                  width={210}
                  field={GRID_COLUMNS.RECEIVED}
                  {...COLUMNS_CONFIG[GRID_COLUMNS.RECEIVED]}
                  title={formatMessageTable(`${GRID_COLUMNS.RECEIVED}`)}
                  cell={({ dataItem }: { dataItem: ModifiedThreat }) => (
                    <Cell className={classes.dateCell}>
                      <CellText className={classes.boldCell}>{dataItem.formattedDate}</CellText>
                    </Cell>
                  )}
                />
                <DataTableColumn
                  field={GRID_COLUMNS.RECIPIENT}
                  {...COLUMNS_CONFIG[GRID_COLUMNS.RECIPIENT]}
                  title={formatMessageTable(`${GRID_COLUMNS.RECIPIENT}`)}
                  cell={({ dataItem }: { dataItem: ModifiedThreat }) => (
                    <Cell className={classes.cell}>
                      <CellText ellipsis className={classes.boldCell}>
                        {dataItem.displayName}
                      </CellText>
                      {dataItem.title && (
                        <>
                          <br />
                          <CellText ellipsis className={classes.normalCell}>
                            {dataItem.title}
                          </CellText>
                        </>
                      )}
                      {dataItem.email && (
                        <>
                          <br />
                          <CellText ellipsis className={classes.lightCell}>
                            {dataItem.email}
                          </CellText>
                        </>
                      )}
                    </Cell>
                  )}
                />
                <DataTableColumn
                  field={GRID_COLUMNS.EMAIL}
                  {...COLUMNS_CONFIG[GRID_COLUMNS.EMAIL]}
                  title={formatMessageTable(`${GRID_COLUMNS.EMAIL}`)}
                  cell={({ dataItem }: { dataItem: ModifiedThreat }) => (
                    <Cell className={classes.cell}>
                      <CellText ellipsis className={classes.boldCell}>
                        {dataItem.subject}
                      </CellText>
                      {dataItem.sender.displayName && (
                        <>
                          <br />
                          <CellText ellipsis className={classes.normalCell}>
                            {dataItem.sender.displayName}
                          </CellText>
                        </>
                      )}
                      {dataItem.sender.email && (
                        <>
                          <br />
                          <CellText ellipsis className={classes.lightCell}>
                            {dataItem.sender.email}
                          </CellText>
                        </>
                      )}
                    </Cell>
                  )}
                />
                <DataTableColumn
                  width={100}
                  field={GRID_COLUMNS.ACTION}
                  {...COLUMNS_CONFIG[GRID_COLUMNS.ACTION]}
                  title={formatMessageTable(`${GRID_COLUMNS.ACTION}`)}
                  cell={({ dataItem }: { dataItem: ModifiedThreat }) => (
                    <Cell className={classes.cell}>
                      {isViewEmailsDisabled ? (
                        <Tooltip title={formatMessage('details_unavailable')}>
                          <div>
                            <CellText className={classes.disabledText}>{formatMessage('see_details')}</CellText>
                          </div>
                        </Tooltip>
                      ) : (
                        <CellText
                          onClick={() => dataItem.seeDetails(dataItem.threatId)}
                          className={classes.blueNormalCell}
                        >
                          {formatMessage('see_details')}
                        </CellText>
                      )}
                    </Cell>
                  )}
                />
              </DataTable>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.buttonWrapper}>
          <Button className={classes.closeButton} color="primary" variant="contained" size="large" onClick={onClose}>
            {formatMessage('close')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }, [open, onClose, userName, domain, userEmailDialogLogic, classes, formatMessage, formatMessageTable])
}

export default UserEmailsDialog
