import React, { useMemo } from 'react'

import { Grid, Alert } from '@barracuda-internal/bds-core'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import { useErrorFormatMessage } from 'global/lib/localization'

import useAtoTabLogic from 'sen/components/pages/settings/atoTab/useAtoTabLogic'
import styles from 'sen/components/pages/settings/atoTab/atoTabStyles'
import settingsStyles from 'sen/components/pages/settings/settingsStyles'
import AdminModeSelector from 'sen/components/pages/settings/shared/AdminMode'
import { UpdateTabSettings } from 'sen/components/pages/settings/useSettingsLogic'

const BASE_I18N_KEY = 'sen.app.settings.ato_tab'

type AtoTabProps = { updateTabSettings: UpdateTabSettings }

export const AtoTab: React.FC<AtoTabProps> = ({ updateTabSettings }) => {
  const classes = styles()
  const settingsClasses = settingsStyles()
  const errorFormatMessage = useErrorFormatMessage()

  const [tabConfig, adminModeSection] = useAtoTabLogic(updateTabSettings)

  return useMemo(
    () => (
      <Grid container className={settingsClasses.tabContent} direction="column">
        {tabConfig.errorMsg && (
          <Alert className={settingsClasses.error} severity="error">
            {errorFormatMessage(tabConfig.errorMsg)}
          </Alert>
        )}

        {tabConfig.isTabInProgress && <LinearProgress className={settingsClasses.loadingIndicator} />}

        <Grid container className={classes.lastSection} data-section="admin-mode" direction="column">
          <AdminModeSelector
            withTitle
            BASE_I18N_KEY={BASE_I18N_KEY}
            isTabInProgress={tabConfig.isTabInProgress}
            {...adminModeSection}
          />
        </Grid>
      </Grid>
    ),
    [adminModeSection, classes, errorFormatMessage, settingsClasses, tabConfig]
  )
}

export default AtoTab
