import { createSlice } from '@reduxjs/toolkit'

import { Workflow as NewWorkflow } from '@barracuda/automated-workflows'
import insertToArray from 'global/lib/insertToArray'
import { ApiStatus, failedResponse, inIdle, inProgress, successResponse } from 'global/redux/toolkit/api'
import { Builder, WorkflowBlueprints, WorkflowRuns, Workflows } from 'fir/redux/types/Workflows'
import {
  createUpdateWorkflow,
  getMoreWorkflowRuns,
  getMoreWorkflows,
  getWorkflow,
  getWorkflowBlueprints,
  getWorkflowRuns,
  getWorkflows,
  GetWorkflowBlueprintsResponse,
  GetWorkflowResponse,
  GetWorkflowRunsResponse,
  GetWorkflowsResponse,
  toggleWorkflowStatus
} from './workflowsApiThunks'

export interface WorkflowsState {
  builder: Builder
  createUpdateWorkflowAPIStatus: ApiStatus
  getWorkflowAPIStatus: ApiStatus
  getWorkflowsAPIStatus: ApiStatus
  getWorkflowRunsAPIStatus: ApiStatus
  getWorkflowBlueprintsAPIStatus: ApiStatus
  toggleWorkflowStatusAPIStatus: ApiStatus
  workflow: any
  workflowBlueprints: WorkflowBlueprints
  workflowRuns: WorkflowRuns
  workflows: Workflows
}

// Initial state
export const INITIAL_STATE: WorkflowsState = {
  builder: {
    actionCount: 0,
    actionId: '',
    actionValue: '',
    conditionCount: 0,
    conditionId: '',
    conditionValue: '',
    elements: [],
    isConditionDataValid: true,
    triggerCount: 0,
    triggerId: '',
    triggerValue: '',
    workflowNodes: {},
    workflowTree: new NewWorkflow()
  },
  createUpdateWorkflowAPIStatus: inIdle,
  getWorkflowAPIStatus: inIdle,
  getWorkflowsAPIStatus: inIdle,
  getWorkflowRunsAPIStatus: inIdle,
  getWorkflowBlueprintsAPIStatus: inIdle,
  toggleWorkflowStatusAPIStatus: inIdle,
  workflow: {},
  workflowBlueprints: { data: [] },
  workflowRuns: { runs: [], total: 0 },
  workflows: { data: [], total: 0 }
}

/* eslint-disable no-param-reassign */
export const workflowsSlice = createSlice({
  name: 'WORKFLOWS',
  initialState: INITIAL_STATE,
  // reducers object creates an action and executes the reducer function
  reducers: {
    setWorkflowBlueprint: (state, action) => {
      state.workflow = action.payload?.workflow
    },
    updateBuilder: (state, action) => {
      state.builder = {
        ...state.builder,
        ...action.payload?.builder
      }
    },
    reset: () => {
      return {
        ...INITIAL_STATE,
        builder: { ...INITIAL_STATE.builder, triggerCount: 0, workflowTree: new NewWorkflow() }
      }
    },
    resetCreateUpdate: state => {
      state.builder = { ...INITIAL_STATE.builder, triggerCount: 0, workflowTree: new NewWorkflow() }
      state.createUpdateWorkflowAPIStatus = INITIAL_STATE.createUpdateWorkflowAPIStatus
      state.workflow = INITIAL_STATE.workflow
    },
    resetWorkflow: state => {
      state.getWorkflowAPIStatus = INITIAL_STATE.getWorkflowAPIStatus
      state.workflow = INITIAL_STATE.workflow
    },
    resetWorkflowRuns: state => {
      state.getWorkflowRunsAPIStatus = INITIAL_STATE.getWorkflowRunsAPIStatus
      state.workflowRuns = INITIAL_STATE.workflowRuns
    },
    resetToggleWorkflowStatus: state => {
      state.toggleWorkflowStatusAPIStatus = INITIAL_STATE.toggleWorkflowStatusAPIStatus
    },
    setConditionValidationStatus: (state, action) => {
      state.builder = {
        ...state.builder,
        isConditionDataValid: action.payload?.isConditionDataValid
      }
    }
  },
  // extraReducers do not create an action but executes the reducer function
  extraReducers: builder => {
    builder
      .addCase(createUpdateWorkflow.pending, state => {
        state.createUpdateWorkflowAPIStatus = inProgress
      })
      .addCase(createUpdateWorkflow.fulfilled, state => {
        state.createUpdateWorkflowAPIStatus = successResponse
      })
      .addCase(createUpdateWorkflow.rejected, (state, action) => {
        state.createUpdateWorkflowAPIStatus = failedResponse(action.payload as string)
      })
      .addCase(getWorkflow.pending, state => {
        state.getWorkflowAPIStatus = inProgress
      })
      .addCase(getWorkflow.fulfilled, (state, action) => {
        state.getWorkflowAPIStatus = successResponse
        state.workflow = (action.payload as GetWorkflowResponse).workflow
      })
      .addCase(getWorkflow.rejected, (state, action) => {
        state.getWorkflowAPIStatus = failedResponse(action.payload as string)
      })
      .addCase(getWorkflows.pending, state => {
        state.getWorkflowsAPIStatus = inProgress
      })
      .addCase(getWorkflows.fulfilled, (state, action) => {
        state.getWorkflowsAPIStatus = successResponse
        state.workflows = (action.payload as GetWorkflowsResponse).workflows
      })
      .addCase(getWorkflows.rejected, (state, action) => {
        state.getWorkflowsAPIStatus = failedResponse(action.payload as string)
      })
      .addCase(getMoreWorkflows.pending, state => {
        state.getWorkflowsAPIStatus = inProgress
      })
      .addCase(getMoreWorkflows.fulfilled, (state, action) => {
        state.getWorkflowsAPIStatus = successResponse
        state.workflows = {
          ...(action.payload as GetWorkflowsResponse).workflows,
          data: insertToArray(
            state.workflows.data,
            ((action.payload as GetWorkflowsResponse).workflows || {}).data || [],
            (action.payload as GetWorkflowsResponse).skip || 0
          )
        }
      })
      .addCase(getMoreWorkflows.rejected, (state, action) => {
        state.getWorkflowsAPIStatus = failedResponse(action.payload as string)
      })
      .addCase(getWorkflowBlueprints.pending, state => {
        state.getWorkflowBlueprintsAPIStatus = inProgress
      })
      .addCase(getWorkflowBlueprints.fulfilled, (state, action) => {
        state.getWorkflowBlueprintsAPIStatus = successResponse
        state.workflowBlueprints = {
          data: insertToArray(
            state.workflowBlueprints.data,
            ((action.payload as GetWorkflowBlueprintsResponse).workflowBlueprints || {}).data || [],
            0
          )
        }
      })
      .addCase(getWorkflowBlueprints.rejected, (state, action) => {
        state.getWorkflowBlueprintsAPIStatus = failedResponse(action.payload as string)
      })
      .addCase(getWorkflowRuns.pending, state => {
        state.getWorkflowRunsAPIStatus = inProgress
      })
      .addCase(getWorkflowRuns.fulfilled, (state, action) => {
        state.getWorkflowRunsAPIStatus = successResponse
        state.workflowRuns = (action.payload as GetWorkflowRunsResponse).workflowRuns
      })
      .addCase(getWorkflowRuns.rejected, (state, action) => {
        state.getWorkflowRunsAPIStatus = failedResponse(action.payload as string)
      })
      .addCase(getMoreWorkflowRuns.pending, state => {
        state.getWorkflowRunsAPIStatus = inProgress
      })
      .addCase(getMoreWorkflowRuns.fulfilled, (state, action) => {
        state.getWorkflowRunsAPIStatus = successResponse
        state.workflowRuns = {
          ...(action.payload as GetWorkflowRunsResponse).workflowRuns,
          runs: insertToArray(
            state.workflowRuns.runs,
            ((action.payload as GetWorkflowRunsResponse).workflowRuns || {}).runs || [],
            (action.payload as GetWorkflowRunsResponse).skip || 0
          )
        }
      })
      .addCase(getMoreWorkflowRuns.rejected, (state, action) => {
        state.getWorkflowRunsAPIStatus = failedResponse(action.payload as string)
      })
      .addCase(toggleWorkflowStatus.pending, state => {
        state.toggleWorkflowStatusAPIStatus = inProgress
      })
      .addCase(toggleWorkflowStatus.fulfilled, state => {
        state.toggleWorkflowStatusAPIStatus = successResponse
      })
      .addCase(toggleWorkflowStatus.rejected, (state, action) => {
        state.toggleWorkflowStatusAPIStatus = failedResponse(action.payload as string)
      })
  }
})
/* eslint-enable no-param-reassign */

export const {
  setWorkflowBlueprint,
  updateBuilder,
  reset,
  resetCreateUpdate,
  resetWorkflow,
  resetWorkflowRuns,
  setConditionValidationStatus,
  resetToggleWorkflowStatus
} = workflowsSlice.actions

export {
  createUpdateWorkflow,
  getMoreWorkflowRuns,
  getMoreWorkflows,
  getWorkflow,
  getWorkflowBlueprints,
  getWorkflowRuns,
  getWorkflows,
  toggleWorkflowStatus
}

export default workflowsSlice.reducer
