import { Elements } from 'react-flow-renderer'

// keep NODE_TYPES in this order to match workflow order
export const NODE_TYPES = {
  triggers: 'triggers',
  conditions: 'conditions',
  actions: 'actions'
}

export const OPERATORS = {
  CONTAINS: 'contains',
  NOT_CONTAINS: 'notContains',
  '==': 'equals',
  '!=': 'notEquals',
  '>': 'greaterThan',
  '<': 'lessThan'
} as any

export interface BlueprintReference {
  uuid: string
  revision: string
}

export interface Builder {
  actionCount?: number
  actionId?: string
  actionValue?: string
  conditionCount?: number
  conditionId?: string
  conditionValue?: string
  elements?: Elements
  isConditionDataValid?: boolean
  triggerCount?: number
  triggerId?: string
  triggerValue?: string
  workflowNodes?: any
  workflowTree?: Workflow
}

export interface Node {
  config: NodeConfig
  eventType: string
  targets: string[]
  type: string
  uuid: string
}

export interface NodeConfig {
  conditionOperator: string
  conditionValue: string
}

export interface Workflow {
  workflowBlueprint?: BlueprintReference
  revision?: number
  description: string
  diagram: string
  editor: string
  name: string
  nodes: Node[]
  uuid: string
}

export interface WorkflowRuns {
  runs: any[]
  total: number
}

export interface WorkflowDashboardProps {
  chartConfig: any
  chartWrapperConfig: any
}

export interface Workflows {
  data: Workflow[]
  total: number
}

export interface WorkflowBlueprints {
  data: any[]
}
