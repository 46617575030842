import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from 'global/configs/theme/defaults'
import dialogStyles from 'global/components/lib/dialogs/dialogStyles'

export const CONTENT_HORIZONTAL_PADDING = 24
export const CONTENT_PADDING = 16
export const ANALYSIS_PANEL_WIDTH = 320 + CONTENT_HORIZONTAL_PADDING

export const EMAIL_CONTENT_TABS_HEIGHT = 48
export const EMAIL_CONTENT_HEIGHT = 405

export default makeStyles(theme => {
  const defaultDialogStyles = dialogStyles(theme)

  const analysisTitle = {
    ...defaultDialogStyles.title,
    fontSize: 25,
    marginBottom: theme.spacing(2)
  }

  return {
    ...defaultDialogStyles,
    dialogContent: {
      padding: '0 !important'
    },
    dialogContentContainer: {
      overflow: 'hidden',
      height: '70vh'
    },
    backToTop: {
      position: 'sticky',
      bottom: '15px',
      backgroundColor: '#64646480',
      color: COLORS.BACKGROUND,
      zIndex: 999,
      '&:hover': {
        backgroundColor: COLORS.SCALE_GRAY_600
      }
    },
    emailHeaderWrapper: {
      padding: `0, ${CONTENT_HORIZONTAL_PADDING}`,
      marginBottom: '16px'
    },
    attachmentContainer: {
      color: COLORS.SCALE_GRAY_600,
      borderRadius: '5px',
      fontSize: '13px',
      marginBottom: '8px',
      padding: theme.spacing(1)
    },
    highlightAttachmentContainer: {
      backgroundColor: COLORS.RED_50,
      color: COLORS.RED_600
    },
    highlightAttachmentContent: {
      color: COLORS.SCALE_GRAY_900
    },
    attachmentName: {
      display: 'block',
      fontSize: '13px'
    },
    attachmentInfo: {
      fontSize: '10px'
    },
    emailContentWrapper: {
      paddingLeft: CONTENT_HORIZONTAL_PADDING,
      height: '69vh'
    },
    emailSection: {
      width: `calc(100% - ${ANALYSIS_PANEL_WIDTH}px)`,
      overflowY: 'auto',
      paddingRight: '20px'
    },
    analysisSection: {
      width: ANALYSIS_PANEL_WIDTH,
      padding: CONTENT_PADDING,
      paddingRight: CONTENT_HORIZONTAL_PADDING,
      backgroundColor: COLORS.LIGHT_BACKGROUND
    },
    analysisIcon: {
      ...defaultDialogStyles.icon,
      verticalAlign: -4,
      marginRight: 10
    },
    analysisTitle: {
      ...analysisTitle
    },
    analysisSubtitle: {
      ...defaultDialogStyles.title,
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1)
    },
    analysisItem: {
      marginBottom: theme.spacing(3)
    },
    analysisItemLabel: {
      ...defaultDialogStyles.text,
      fontWeight: 500
    },
    attackType: {
      marginBottom: -2
    },
    indicatorWrapper: {
      marginBottom: theme.spacing(2)
    },
    errorIcon: {
      ...defaultDialogStyles.icon,
      color: COLORS.STATUS_ERROR
    },
    indicatorText: {
      paddingLeft: 5,
      ...defaultDialogStyles.text
    },
    indicatorBold: {
      fontWeight: 500,
      wordBreak: 'break-all'
    },
    indicatorItalic: {
      fontStyle: 'italic',
      wordBreak: 'break-all'
    },
    headerRow: {
      marginBottom: theme.spacing(1.5)
    },
    headerLabelWrapper: {
      width: 100,
      maxWidth: 100
    },
    headerLabel: {
      ...defaultDialogStyles.text
    },
    headerText: {
      ...defaultDialogStyles.text,
      ...defaultDialogStyles.ellipsis,
      fontWeight: 500
    },
    highlightHeaderText: {
      backgroundColor: COLORS.RED_50,
      color: COLORS.RED_600,
      padding: '4px 5px',
      borderRadius: '5px'
    },
    tooltip: {
      ...defaultDialogStyles.tooltip
    },
    tooltipArrow: {
      ...defaultDialogStyles.tooltipArrow
    },
    dialogFooter: {
      ...defaultDialogStyles.dialogFooter,
      padding: '16px'
    },
    reportFpButton: {
      position: 'absolute',
      left: CONTENT_HORIZONTAL_PADDING
    }
  }
})
