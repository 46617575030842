import React, { useMemo } from 'react'

import { Grid } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'global/lib/localization'

import RecentAttacksTab from 'sen/components/pages/dashboard/content/tabs/recentAttacks/RecentAttacks'
import Layout from 'sen/components/lib/layout/Layout'
import ContentLayout from 'sen/components/lib/layout/ContentLayout'

import DashboardHeader from '../header/DashboardHeader'

const BASE_I18N_KEY = 'sen.app.dashboard'

const DashboardContent: React.FC = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <Layout pageTitle={formatMessage('page_title')} rightContent={<DashboardHeader data-field="dashboard-header" />}>
        <ContentLayout data-field="content-layout">
          <Grid container direction="column" data-field="root-grid">
            <RecentAttacksTab />
          </Grid>
        </ContentLayout>
      </Layout>
    ),
    [formatMessage]
  )
}

export default DashboardContent
