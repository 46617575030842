import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from 'global/configs/theme/defaults'

import tableStyles, { TABLE_HEADER_HEIGHT, TABLE_FOOTER_HEIGHT } from 'global/components/lib/grid/tableStyles'

const CARD_HEIGHT = 320
export const CELL_HEIGHT = 65
const TABLE_MIN_HEIGHT = CELL_HEIGHT * 10 + TABLE_HEADER_HEIGHT + TABLE_FOOTER_HEIGHT

export default makeStyles(theme => {
  const defaultTableStyles = tableStyles(theme, CELL_HEIGHT)

  return {
    ...defaultTableStyles,
    title: {
      marginTop: theme.spacing(3)
    },
    subtitle: {
      marginBottom: theme.spacing(3),
      lineHeight: '10px',
      color: COLORS.GRAY
    },
    tableCard: {
      position: 'relative',
      minHeight: CARD_HEIGHT,
      padding: 0
    },
    progressWrapper: {
      alignItems: 'center',
      display: 'flex',
      height: CARD_HEIGHT,
      justifyContent: 'center'
    },
    tableCircularProgress: {
      paddingTop: theme.spacing(20)
    },
    tableWrapper: {
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`
    },
    tableArea: {
      ...defaultTableStyles.tableArea,
      marginTop: theme.spacing(1),
      minHeight: TABLE_MIN_HEIGHT
    },
    dataTable: {
      ...defaultTableStyles.dataTable,
      minHeight: TABLE_MIN_HEIGHT,
      '& td': {
        fontSize: '13px',
        lineHeight: 'normal'
      }
    },
    userFlagKey: {
      display: 'inline',
      fontSize: '13px',
      color: COLORS.GRAY,
      lineHeight: 'normal'
    },
    userFlagValue: {
      display: 'inline',
      fontSize: '13px',
      wordBreak: 'break-all',
      lineHeight: 'normal'
    },
    link: {
      color: COLORS.GRAY,
      textDecoration: 'underline',
      cursor: 'pointer'
    },
    gridLayout: {
      '& .k-grid-table': {
        wordBreak: 'break-word'
      },
      '& .k-grid-header col:nth-of-type(1)': {
        width: '13%'
      },
      '& .k-grid-table col:nth-of-type(1)': {
        width: '13%'
      },
      '& .k-grid-header col:nth-of-type(2)': {
        width: '20%'
      },
      '& .k-grid-table col:nth-of-type(2)': {
        width: '20%'
      },
      '& .k-grid-header col:nth-of-type(3)': {
        width: '10%'
      },
      '& .k-grid-table col:nth-of-type(3)': {
        width: '10%'
      },
      '& .k-grid-header col:nth-of-type(4)': {
        width: '10%'
      },
      '& .k-grid-table col:nth-of-type(4)': {
        width: '10%'
      },
      '& .k-grid-header col:nth-of-type(5)': {
        width: '15%'
      },
      '& .k-grid-table col:nth-of-type(5)': {
        width: '15%'
      },
      '& .k-grid-header col:nth-of-type(6)': {
        width: '8%'
      },
      '& .k-grid-table col:nth-of-type(6)': {
        width: '8%'
      },
      '& .k-grid-header col:nth-of-type(7)': {
        width: '8%'
      },
      '& .k-grid-table col:nth-of-type(7)': {
        width: '8%'
      },
      '& .k-grid-header col:nth-of-type(8)': {
        width: '8%'
      },
      '& .k-grid-table col:nth-of-type(8)': {
        width: '8%'
      },
      '& .k-grid-header col:nth-of-type(9)': {
        width: '8%'
      },
      '& .k-grid-table col:nth-of-type(9)': {
        width: '8%'
      }
    }
  }
})
