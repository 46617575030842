import { useEffect, useCallback, useRef } from 'react'

import * as configurationLib from 'global/lib/configuration/configuration'

export type InitTableRefresh = (cb: () => void, freq?: number) => void
export type ClearTableRefresh = () => void

export default function useTablePeriodicCheck(): [InitTableRefresh, ClearTableRefresh] {
  const periodicCheck = useRef<ReturnType<typeof setInterval> | null>()

  useEffect(() => {
    return () => {
      if (periodicCheck.current) {
        clearInterval(periodicCheck.current)
      }
    }
  }, [])

  const clearTableRefresh: ClearTableRefresh = useCallback(() => {
    if (periodicCheck.current) {
      clearInterval(periodicCheck.current)
    }
  }, [])

  const initTableRefresh: InitTableRefresh = useCallback(
    (cb, initFreq) => {
      const freq: number = initFreq || configurationLib.getSpAutoRefreshFreq()

      if (freq > 0) {
        clearTableRefresh()

        periodicCheck.current = setInterval(cb, freq)
      }
    },
    [clearTableRefresh]
  )

  return [initTableRefresh, clearTableRefresh]
}
