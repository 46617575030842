import browserHistory from 'global/lib/routes/browserHistory'
import { config } from 'global/lib/config'

import accessTokenLib from 'global/lib/accessToken/accessToken'
import { updateActivePath } from 'global/redux/features/app/appSlice'
import { reduxStore } from 'global/lib/reduxStore'

export interface UiRoutes {
  [index: string]: UiRoute
}

export interface CustomRouteGenerators {
  [key: string]: (route: string, path: string, createUrl: any, metadata: any, browserHistory: any) => UiRoute
}

export interface RouteMetadata {
  sideMenu?: string
  accountSwitchTargetId?: string
  isReportRoute?: boolean
}

export interface UiRoute {
  id: string
  path: string
  metadata?: RouteMetadata
  url(params?: {}): string
  isActiveUrl(params?: {}): boolean
  softGoto(params?: {}, withUrlParams?: boolean): void
  goto(params?: {}, withUrlParams?: boolean): void
  forceGoto(params?: {}, withUrlParams?: boolean): void
  refresh(params?: {}): void
}

interface Paths {
  [key: string]: any
}

interface Params {
  [key: string]: string
}

export function createUrlGenerator(path: string) {
  return (params: Params = {}) => {
    const activeAccessTokenId = accessTokenLib.getActiveAccessTokenId()

    const baseParams: Params = {
      ...(activeAccessTokenId && { accessToken: activeAccessTokenId }),
      ...params
    }

    // TODO: We need to look for an exact string match, this could break routing.
    // for example, if we had a property called 'report' it would replace ':reportId' with 'somevalue` and append `Id`
    // report = 1234 we would get `1234Id`
    return Object.keys(baseParams).reduce((updatedPath, param) => {
      return updatedPath.replace(`:${param}`, baseParams[param])
    }, path)
  }
}

export default function routesConfig(PATHS: Paths, allRoutes = {}, customRoutes: CustomRouteGenerators = {}): UiRoutes {
  return Object.keys(PATHS).reduce((builtRoutes, route) => {
    const modifiedBuiltRoutes: UiRoutes = { ...builtRoutes }

    const routeConfig = (PATHS as Paths)[route]

    if (routeConfig.path) {
      const { path } = routeConfig

      const createUrl = createUrlGenerator(path)

      if (customRoutes[route]) {
        modifiedBuiltRoutes[route] = customRoutes[route](
          route,
          path,
          createUrlGenerator,
          routeConfig.metadata,
          browserHistory
        )
      } else {
        modifiedBuiltRoutes[route] = {
          id: route,
          path,
          url: createUrl,
          isActiveUrl: (params = {}) => {
            return createUrl(params) === window.location.pathname
          },
          // goto to the route without alert the react-router
          softGoto: (params: {} = {}, withUrlParams = false) => {
            const newPathName = `${createUrl(params)}${withUrlParams ? window.location.search : ''}`
            window.history.replaceState(null, '', newPathName)

            // update the app store with the new path information
            reduxStore.dispatch(updateActivePath({ id: route || '', url: newPathName, params: params as any }))
          },
          goto: (params: {} = {}, withUrlParams = false) => {
            const currentPath = `${createUrl(params)}${withUrlParams ? window.location.search : ''}`
            // prevPath can be accessed when both the previous page and current page use this goto function
            browserHistory.push(currentPath, { prevPath: path })
          },
          forceGoto: (params: {} = {}, withUrlParams = false) => {
            const currentPath = `${createUrl(params)}${withUrlParams ? window.location.search : ''}`

            window.location.href = `${window.location.origin}${currentPath}`
          },
          refresh: (params: {} = {}) => {
            window.location.href = createUrl(params)
          },
          metadata: routeConfig.metadata || {}
        }
      }
    } else {
      return routesConfig(routeConfig, builtRoutes, customRoutes)
    }

    return modifiedBuiltRoutes
  }, allRoutes)
}

export function forceRedirectToSignup() {
  const fullPath = `${window.location.origin}/${!config.domainConfig.isForensics ? 'v2/' : ''}signup`
  window.location.href = fullPath
}
