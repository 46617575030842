import { Theme, createStyles } from '@material-ui/core/styles'
import { COLORS } from 'global/configs/theme/defaults'

export default function defaultDialogStyles(theme: Theme) {
  const textStyles = createStyles({
    default: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '18px'
    }
  })

  return createStyles({
    dialogTitleWrapper: {
      paddingBottom: theme.spacing(2.5),
      borderBottom: `1px solid ${COLORS.PRODUCT_UI_ELEMENT}`
    },
    dialogTitle: {
      fontSize: 24,
      fontStyle: 'normal',
      fontWeight: 300,
      lineHeight: '32px',
      letterSpacing: '0.46000000834465027px'
    },
    dialogSubtitle: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      color: COLORS.GRAY,
      fontSize: 14,
      fontWeight: 300,
      letterSpacing: 0,
      lineHeight: '16px'
    },
    dialogBiggerText: {
      ...textStyles.default,
      fontSize: 16,
      lineHeight: '16px'
    },
    dialogText: {
      ...textStyles.default
    },
    dialogSmallText: {
      ...textStyles.default,
      fontSize: 10,
      lineHeight: '10px'
    },
    dialogContent: {
      marginTop: theme.spacing(2)
    },
    icon: {
      width: 20,
      height: 20
    },
    text: {
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '16px',
      letterSpacing: '0.46000000834465027px'
    },
    title: {
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.15000000596046448px'
    },
    subtitle: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      color: COLORS.GRAY,
      fontSize: 14,
      fontWeight: 300,
      letterSpacing: 0,
      lineHeight: '16px'
    },
    ellipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    tooltip: {
      fontSize: 10,
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '14px',
      letterSpacing: '0px',
      backgroundColor: '#616161 !important',
      boxShadow: '0 2px 5px 0 rgba(0,0,0,0.26), 0 2px 10px 0 rgba(0,0,0,0.16)'
    },
    tooltipArrow: {
      color: '#616161 !important',

      '&:before': {
        backgroundColor: 'transparent'
      }
    },
    dialogFooter: {
      borderTop: `1px solid ${COLORS.PRODUCT_UI_ELEMENT}`
    },
    loading: {
      margin: `${theme.spacing(5)}px 0`
    },
    error: {
      marginBottom: theme.spacing(2)
    }
  })
}
