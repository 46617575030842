import React, { useMemo } from 'react'

import { Grid } from '@barracuda-internal/bds-core'
import ContentTabSelector, { TabConfig } from 'global/components/lib/contentTabSelector/ContentTabSelector'

import routesConfig from 'admin/lib/routes/routesConfig'
import AllAccounts from 'admin/components/pages/dashboard/tabs/domainFraudProtection/tabs/allAccounts/AllAccounts'
import styles from 'admin/components/pages/dashboard/tabs/domainFraudProtection/domainFraudProtectionStyles'

const BASE_I18N_KEY = 'admin.app.dfp'

const TABS: TabConfig[] = [[[routesConfig.DASHBOARD_FORENSICS], 'all_accounts', AllAccounts]]

const DomainFraudProtection: React.FC = () => {
  const classes = styles()

  return useMemo(
    () => (
      <Grid className={classes.container} data-field="root-grid">
        <ContentTabSelector BASE_I18N_KEY={BASE_I18N_KEY} tabs={TABS} data-field="content-tab-selector" />
      </Grid>
    ),
    [classes]
  )
}

export default DomainFraudProtection
