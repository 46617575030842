import React from 'react'

import Loading from 'global/components/lib/loading/Loading'

import useSharedLogic from 'ets/components/pages/shared/useSharedLogic'
import Dashboard from 'ets/components/pages/dashboard/Dashboard'

const Shared: React.FC = () => {
  const [{ isAccessTokenSet }] = useSharedLogic()

  if (!isAccessTokenSet) {
    return <Loading />
  }

  return <Dashboard />
}

export default Shared
