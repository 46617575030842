import { PipelineSpThreatFields } from '@barracuda/reporting'

import { Column } from 'global/types/api/unifiedReporting'

export const pipelineSpThreatFieldsColumnConfig: Column[] = [
  {
    accessorKey: PipelineSpThreatFields.CREATED_ON.name,
    header: 'created_on',
    fieldType: PipelineSpThreatFields.CREATED_ON.type,
    possibleValues: PipelineSpThreatFields.CREATED_ON.possibleValues,
    category: 'recipient',
    hideColumnFromList: true
  },
  {
    accessorKey: PipelineSpThreatFields.IDENTITY_EMAIL.name,
    header: 'identity_email',
    fieldType: PipelineSpThreatFields.IDENTITY_EMAIL.type,
    possibleValues: PipelineSpThreatFields.IDENTITY_EMAIL.possibleValues,
    category: 'recipient'
  },
  {
    accessorKey: PipelineSpThreatFields.IDENTITY_DISPLAY_NAME.name,
    header: 'identity_display_name',
    fieldType: PipelineSpThreatFields.IDENTITY_DISPLAY_NAME.type,
    possibleValues: PipelineSpThreatFields.IDENTITY_DISPLAY_NAME.possibleValues,
    category: 'recipient'
  },
  {
    accessorKey: PipelineSpThreatFields.IDENTITY_JOB_TITLE.name,
    header: 'identity_job_title',
    fieldType: PipelineSpThreatFields.IDENTITY_JOB_TITLE.type,
    possibleValues: PipelineSpThreatFields.IDENTITY_JOB_TITLE.possibleValues,
    category: 'recipient'
  },
  {
    accessorKey: PipelineSpThreatFields.IDENTITY_DEPARTMENT.name,
    header: 'identity_department',
    fieldType: PipelineSpThreatFields.IDENTITY_DEPARTMENT.type,
    possibleValues: PipelineSpThreatFields.IDENTITY_DEPARTMENT.possibleValues,
    category: 'recipient'
  },
  {
    accessorKey: PipelineSpThreatFields.IS_READ.name,
    header: 'is_read',
    fieldType: PipelineSpThreatFields.IS_READ.type,
    possibleValues: PipelineSpThreatFields.IS_READ.possibleValues,
    category: 'recipient'
  },
  {
    accessorKey: PipelineSpThreatFields.CORRESPONDENT_SENDER_EMAIL.name,
    header: 'correspondent_sender_email',
    fieldType: PipelineSpThreatFields.CORRESPONDENT_SENDER_EMAIL.type,
    possibleValues: PipelineSpThreatFields.CORRESPONDENT_SENDER_EMAIL.possibleValues,
    category: 'email'
  },
  {
    accessorKey: PipelineSpThreatFields.CORRESPONDENT_SENDER_DISPLAY_NAME.name,
    header: 'correspondent_sender_display_name',
    fieldType: PipelineSpThreatFields.CORRESPONDENT_SENDER_DISPLAY_NAME.type,
    possibleValues: PipelineSpThreatFields.CORRESPONDENT_SENDER_DISPLAY_NAME.possibleValues,
    category: 'email'
  },
  {
    accessorKey: PipelineSpThreatFields.CORRESPONDENT_SENDER_DOMAIN.name,
    header: 'correspondent_sender_domain',
    fieldType: PipelineSpThreatFields.CORRESPONDENT_SENDER_DOMAIN.type,
    possibleValues: PipelineSpThreatFields.CORRESPONDENT_SENDER_DOMAIN.possibleValues,
    category: 'email'
  },
  {
    accessorKey: PipelineSpThreatFields.SENDER_IP_ADDRESS.name,
    header: 'sender_ip_address',
    fieldType: PipelineSpThreatFields.SENDER_IP_ADDRESS.type,
    possibleValues: PipelineSpThreatFields.SENDER_IP_ADDRESS.possibleValues,
    category: 'sender'
  },
  {
    accessorKey: PipelineSpThreatFields.SENDER_DOMAIN_REGISTRATION_DATE.name,
    header: 'sender_domain_registration_date',
    fieldType: PipelineSpThreatFields.SENDER_DOMAIN_REGISTRATION_DATE.type,
    possibleValues: PipelineSpThreatFields.SENDER_DOMAIN_REGISTRATION_DATE.possibleValues,
    category: 'sender'
  },
  {
    accessorKey: PipelineSpThreatFields.SENDER_IP_LOCATION.name,
    header: 'sender_ip_location',
    fieldType: PipelineSpThreatFields.SENDER_IP_LOCATION.type,
    possibleValues: PipelineSpThreatFields.SENDER_IP_LOCATION.possibleValues,
    category: 'sender'
  },
  {
    accessorKey: PipelineSpThreatFields.SENDER_IP_REPUTATION.name,
    header: 'sender_ip_reputation',
    fieldType: PipelineSpThreatFields.SENDER_IP_REPUTATION.type,
    possibleValues: PipelineSpThreatFields.SENDER_IP_REPUTATION.possibleValues,
    category: 'sender'
  },
  {
    accessorKey: PipelineSpThreatFields.SUBJECT.name,
    header: 'subject',
    fieldType: PipelineSpThreatFields.SUBJECT.type,
    possibleValues: PipelineSpThreatFields.SUBJECT.possibleValues,
    category: 'email'
  },
  {
    accessorKey: PipelineSpThreatFields.STATUS.name,
    header: 'status',
    fieldType: PipelineSpThreatFields.STATUS.type,
    possibleValues: PipelineSpThreatFields.STATUS.possibleValues,
    category: 'email'
  },
  {
    accessorKey: PipelineSpThreatFields.DATE_RECEIVED.name,
    header: 'date_received',
    fieldType: PipelineSpThreatFields.DATE_RECEIVED.type,
    possibleValues: PipelineSpThreatFields.DATE_RECEIVED.possibleValues,
    category: 'email'
  },
  {
    accessorKey: PipelineSpThreatFields.DATE_SEND.name,
    header: 'date_sent',
    fieldType: PipelineSpThreatFields.DATE_SEND.type,
    possibleValues: PipelineSpThreatFields.DATE_SEND.possibleValues,
    category: 'email'
  },
  {
    accessorKey: PipelineSpThreatFields.HAS_ATTACHMENTS.name,
    header: 'has_attachments',
    fieldType: PipelineSpThreatFields.HAS_ATTACHMENTS.type,
    possibleValues: PipelineSpThreatFields.HAS_ATTACHMENTS.possibleValues,
    category: 'email'
  },
  {
    accessorKey: PipelineSpThreatFields.EMAIL_ID.name,
    header: 'email_id',
    fieldType: PipelineSpThreatFields.EMAIL_ID.type,
    possibleValues: PipelineSpThreatFields.EMAIL_ID.possibleValues,
    category: 'email'
  },
  {
    accessorKey: PipelineSpThreatFields.INTERNET_MESSAGE_ID.name,
    header: 'internet_message_id',
    fieldType: PipelineSpThreatFields.INTERNET_MESSAGE_ID.type,
    possibleValues: PipelineSpThreatFields.INTERNET_MESSAGE_ID.possibleValues,
    category: 'email'
  },
  {
    accessorKey: PipelineSpThreatFields.PARENT_FOLDER_NAME.name,
    header: 'parent_folder_name',
    fieldType: PipelineSpThreatFields.PARENT_FOLDER_NAME.type,
    possibleValues: PipelineSpThreatFields.PARENT_FOLDER_NAME.possibleValues,
    category: 'email'
  },
  {
    accessorKey: PipelineSpThreatFields.THREAT_DETECTS_COUNT.name,
    header: 'threat_detects_count',
    fieldType: PipelineSpThreatFields.THREAT_DETECTS_COUNT.type,
    possibleValues: PipelineSpThreatFields.THREAT_DETECTS_COUNT.possibleValues,
    category: 'barracuda'
  },
  {
    accessorKey: PipelineSpThreatFields.TAXONOMY.name,
    header: 'taxonomy',
    fieldType: PipelineSpThreatFields.TAXONOMY.type,
    possibleValues: PipelineSpThreatFields.TAXONOMY.possibleValues,
    category: 'barracuda'
  },
  {
    accessorKey: PipelineSpThreatFields.SERVICE_PHISHING_KEYWORDS.name,
    header: 'service_phishing_keywords',
    fieldType: PipelineSpThreatFields.SERVICE_PHISHING_KEYWORDS.type,
    possibleValues: PipelineSpThreatFields.SERVICE_PHISHING_KEYWORDS.possibleValues,
    category: 'barracuda'
  },
  {
    accessorKey: PipelineSpThreatFields.SERVICE_PHISHING_DOMAINS.name,
    header: 'service_phishing_domains',
    fieldType: PipelineSpThreatFields.SERVICE_PHISHING_DOMAINS.type,
    possibleValues: PipelineSpThreatFields.SERVICE_PHISHING_DOMAINS.possibleValues,
    category: 'email'
  },
  {
    accessorKey: PipelineSpThreatFields.CONFIDENCE.name,
    header: 'confidence',
    fieldType: PipelineSpThreatFields.CONFIDENCE.type,
    possibleValues: PipelineSpThreatFields.CONFIDENCE.possibleValues,
    category: 'barracuda'
  },

  {
    accessorKey: PipelineSpThreatFields.SEVERITY.name,
    header: 'severity',
    fieldType: PipelineSpThreatFields.SEVERITY.type,
    possibleValues: PipelineSpThreatFields.SEVERITY.possibleValues,
    category: 'barracuda'
  },
  {
    accessorKey: PipelineSpThreatFields.IS_WHITELISTED.name,
    header: 'is_whitelisted',
    fieldType: PipelineSpThreatFields.IS_WHITELISTED.type,
    possibleValues: PipelineSpThreatFields.IS_WHITELISTED.possibleValues,
    category: 'barracuda'
  }
]
