import React from 'react'
import PropTypes from 'prop-types'

import {
  Checkbox,
  CheckboxLabel,
  Link,
  MenuItem,
  Select,
  Radio,
  RadioGroup,
  Typography,
  TextField
} from '@barracuda-internal/bds-core'
import { FormControl, FormControlLabel } from '@material-ui/core'

import { useSelector } from 'react-redux'

import { config } from 'global/lib/config'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'

import { useFormatMessage } from 'global/lib/localization'
import styles, { remediationOptionsStyles } from '../newIncidentStyles'

export interface Props {
  policyOptions: {
    updatePolicyOptions: any
  }
  isCreatingIncident: boolean
  isEntitledBCS: boolean
  isEntitledESS: boolean
}

const BASE_I18N_KEY = 'fir.app.new_incident_wizard'

const PolicyOptions: React.FC<any> = ({
  policyOptions: { updatePolicyOptions },
  isCreatingIncident,
  isEntitledBCS,
  isEntitledESS
}) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const baseClasses = styles()
  const classes = remediationOptionsStyles()
  const stores: any = useSelector((_stores: any) => _stores)

  return (
    <div className={baseClasses.wrapper}>
      <Typography className={baseClasses.opacity} variant="subtitle1">
        {formatMessage('select_options_remediate', { b: (txt: any) => <b key="bold-text">{txt}</b> })}
      </Typography>
      {isCreatingIncident && <LinearProgress className={classes.createIncident} />}
      {!isCreatingIncident && (
        <>
          <FormControl className={classes.formControl}>
            <CheckboxLabel
              classes={{ label: classes.senderPolicyLabel }}
              control={
                <Checkbox
                  disabled={!isEntitledESS}
                  color="primary"
                  checked={stores.newIncident.policyOptions.isAddSenderPolicy}
                  onChange={updatePolicyOptions('isAddSenderPolicy', true)}
                />
              }
              label={
                <span>
                  {formatMessage('add_sender_policy')}{' '}
                  <Select
                    disabled={!isEntitledESS}
                    className={classes.policyPlaceSelector}
                    value={stores.newIncident.policyOptions.policyPlace}
                    onChange={updatePolicyOptions('policyPlace')}
                  >
                    {[
                      stores.newIncident.policyOptions.POLICY_PLACES.QUARANTINE,
                      stores.newIncident.policyOptions.POLICY_PLACES.BLOCK
                    ].map((place: string) => (
                      <MenuItem key={place} value={place}>
                        <span className={baseClasses.lowercase}>{formatMessage(`policy_places.${place}`)}</span>
                      </MenuItem>
                    ))}
                  </Select>{' '}
                  <span className={baseClasses.lowercase}>{formatMessage('email')}</span>
                </span>
              }
            />
            <FormControl className={`${classes.formControl} ${baseClasses.closerToTop}`}>
              <Typography className={classes.subLabel} variant="body2">
                {formatMessage('adds_global_policy', { b: (txt: any) => <b key="bold-text">{txt}</b> })}
              </Typography>
              <RadioGroup
                row
                name="email-alert"
                value={stores.newIncident.policyOptions.involvedScope}
                onChange={updatePolicyOptions('involvedScope')}
              >
                <FormControlLabel
                  control={<Radio color="primary" />}
                  disabled={!stores.newIncident.policyOptions.isAddSenderPolicy}
                  key={stores.newIncident.policyOptions.INVOLVED_SCOPES.SENDERS}
                  value={stores.newIncident.policyOptions.INVOLVED_SCOPES.SENDERS}
                  label={formatMessage('by_all_unique_senders', { b: (txt: any) => <b key="bold-text">{txt}</b> })}
                />
                <FormControlLabel
                  control={<Radio color="primary" />}
                  disabled={!stores.newIncident.policyOptions.isAddSenderPolicy}
                  key={stores.newIncident.policyOptions.INVOLVED_SCOPES.DOMAINS}
                  value={stores.newIncident.policyOptions.INVOLVED_SCOPES.DOMAINS}
                  label={formatMessage('by_all_unique_domains', { b: (txt: any) => <b key="bold-text">{txt}</b> })}
                />
              </RadioGroup>
              <Typography className={classes.subLabel} variant="body2">
                <span className={`${baseClasses.opacity} ${baseClasses.inline}`}>
                  {formatMessage('add_sender_policy_help')}
                </span>{' '}
                <Link target="_blank" href={config.LINKS.BARRACUDA_ESSENTIALS}>
                  {formatMessage('learn_more')}...
                </Link>
              </Typography>
              <TextField
                disabled={!stores.newIncident.policyOptions.isAddSenderPolicy}
                hidden={!stores.newIncident.policyOptions.isAddSenderPolicy}
                className={classes.policyComment}
                value={stores.newIncident.policyOptions.comment}
                onChange={updatePolicyOptions('comment')}
                label={formatMessage('comment')}
                placeholder="Added by Incident Response"
                multiline
              />
            </FormControl>
          </FormControl>
          <FormControl className={classes.formControl}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!isEntitledBCS}
                  color="primary"
                  checked={stores.newIncident.policyOptions.isBlockAllUserWebTraffic}
                  onChange={updatePolicyOptions('isBlockAllUserWebTraffic', true)}
                />
              }
              label={formatMessage('block_all_user_web_traffic')}
            />
            <Typography className={classes.subLabel} variant="body2">
              <span className={`${baseClasses.opacity} ${baseClasses.inline}`}>
                {formatMessage('add_dns_block_exceptions')}
              </span>{' '}
              <Link target="_blank" href={config.LINKS.BARRACUDA_CONTENT_SHIELD}>
                {formatMessage('learn_more')}...
              </Link>
            </Typography>
            <Typography className={classes.bottomText} variant="subtitle1">
              {formatMessage('clicking_remediate_to_create')}
            </Typography>
          </FormControl>
        </>
      )}
    </div>
  )
}

PolicyOptions.propTypes = {
  policyOptions: PropTypes.any.isRequired,
  isCreatingIncident: PropTypes.bool.isRequired,
  isEntitledBCS: PropTypes.bool.isRequired,
  isEntitledESS: PropTypes.bool.isRequired
}

export default PolicyOptions
