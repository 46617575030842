import { AttackType } from 'global/types/api/attackType'
import { StatusIds } from 'global/components/lib/statusTypeLabel/StatusIds.enum'
import { AnalyzedAttack as GlobalAnalyzedAttack } from 'global/lib/analyzeAttack/analyzeAttack'

export const EMPTY_IDENTITY = {
  email: '',
  displayName: ''
}

export const EMPTY_EMAIL_DETAILS: EmailDetails = {
  subject: '',
  from: EMPTY_IDENTITY,
  to: EMPTY_IDENTITY,
  replyTo: [],
  date: '',
  headers: [],
  bodyMimeType: '',
  body: '',
  attachments: []
}

export interface Identity {
  email: string
  displayName: string
}

export interface Header {
  name: string
  value: string
}

export interface Attachment {
  contentType: string
  isInline: boolean
  name: string
  size: string
}

export interface EmailStatistics {
  domain: string
  senderDomainRegistrationDate: string | undefined
  senderDomainRegistrationDateRelative: string | undefined
  senderIpAddress: string | undefined
  senderIpLocation: string | undefined
  senderIpReputation: string | undefined
  threatsDetectCount: string | undefined
  isDkimAuthenticated: boolean
  isSpfAuthenticated: boolean
  isDmarcAuthenticated: boolean
  isDmarcAlignmentAuthenticated: boolean
}

export interface EmailDetails {
  subject: string
  from: Identity
  to: Identity
  replyTo?: Identity[]
  date?: string | Date
  headers?: Header[]
  bodyMimeType: string
  body: string
  attachments: Attachment[]
  failureReport?: Header[]
  statistics?: EmailStatistics
}

export interface EmailDataStatuses {
  inProgress: boolean
  isLoaded: boolean
  isFailed: boolean
}

export interface HeaderDetail {
  id: string
  content?: string
}

// interfaces
export interface EventHandlers {
  onClose: () => void
  onMarkAsFp?: (attackId: AttackType['id'], confidenceLevel: string) => void
  onFindMessagesInForensics?: boolean
  onDownloadEmail?: () => void
}

export type Analysis = GlobalAnalyzedAttack & {
  statusId?: StatusIds
  isStatusEmpty: boolean
  isMarkedAsFp: boolean
  hasRemediation: boolean
}

export interface EmailDetailsDialogProps {
  emailDetails: EmailDetails
  emailDataStatuses: EmailDataStatuses
  eventHandlers: EventHandlers
  analysis?: Analysis
}

export interface DialogActions<OnOpenType> {
  open: boolean
  onOpen: OnOpenType
  onClose: () => void
}

export interface MarkedasFpDialogActions {
  open: boolean
  onOpen: (attackId: AttackType['id'], confidenceLevel: string) => void
  onClose: (isMarked?: boolean) => void
}

export enum InterfaceType {
  attackDetail
}

export type OnOpenDialogCallback = (attack: AttackType) => void
export type OnCloseDialogCallback = () => void
export type OnOpenReportFpDialogCallback = (attackId: string, confidenceLevel: string) => void
export type OnCloseReportFpDialogCallback = (isMarked: boolean) => void
