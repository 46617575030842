import React from 'react'
import PropTypes from 'prop-types'

import FormControl from '@material-ui/core/FormControl'
import { TextField, Typography, Grid } from '@barracuda-internal/bds-core'

import { Email as EmailIcon } from '@barracuda-internal/bds-core/dist/Icons/Email'
import IMAGES from 'global/configs/theme/images.config'

import YesNoSwitch from 'global/components/lib/form/YesNoSwitch'
import { useFormatMessage } from 'global/lib/localization'

import styles, { potentialIncidentsSettingsStyles } from '../SettingsStyles'

const BASE_I18N_KEY = 'fir.app.settings_dialog'

const AutomatedWorkflowsSettings: React.FC<any> = ({
  automatedWorkflowSettingsOptions: {
    forensicsSettings,
    isInvalidEmail,
    isInvalidSlackWebhook,
    isInvalidMSTeamsWebhook,
    updateAutomatedWorkflowSettingsOptions
  }
}) => {
  const baseClasses = styles()
  const classes = potentialIncidentsSettingsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return (
    <div data-field="wrapper" className={baseClasses.wrapper}>
      <div>
        <Typography data-field="header" variant="h5">
          {formatMessage('automated_workflows.labels.source')}
        </Typography>
      </div>
      <FormControl data-field="root-form" component="fieldset" className={classes.formControl}>
        <Grid data-field="root-grid" component="div" container direction="column" spacing={4}>
          <Grid container direction="row" spacing={1} className={classes.formControl}>
            <Grid xs="auto" item>
              <EmailIcon data-field="email-icon" fontSize="medium" color="secondary" />
            </Grid>
            <Grid component="label" className={classes.label} xs={8} item>
              <Typography data-field="email-label" variant="body1" className={classes.wordWrap}>
                {formatMessage('automated_workflows.labels.sync_autoincident_email_address')}
              </Typography>
            </Grid>
            <Grid xs={3} item>
              <YesNoSwitch
                checked={forensicsSettings.forensicsAutomatedWorkflowSyncAddress}
                onChange={updateAutomatedWorkflowSettingsOptions('forensicsAutomatedWorkflowSyncAddress', true)}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.formControl}>
            {/* This <EmailIcon /> needs to be here so that the <TextField /> aligns properly */}
            <Grid xs="auto" item>
              <EmailIcon className={baseClasses.invisibleIcon} fontSize="large" />
            </Grid>
            <Grid xs={11} item>
              <TextField
                className={
                  forensicsSettings.forensicsAutomatedWorkflowSyncAddress
                    ? `${baseClasses.disabled} ${classes.mdTextField}`
                    : classes.mdTextField
                }
                value={forensicsSettings.forensicsAutomatedWorkflowNotifyAddress}
                data-field="email-field"
                onChange={updateAutomatedWorkflowSettingsOptions('forensicsAutomatedWorkflowNotifyAddress')}
                label={formatMessage('automated_workflows.labels.notify_address')}
                error={isInvalidEmail}
                helperText={isInvalidEmail ? formatMessage('labels.invalid_email') : ''}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.formControl}>
            <Grid xs="auto" item>
              <img data-field="slack-icon" alt="Slack Icon" className={classes.iconPadding} src={IMAGES.slackIcon} />
            </Grid>
            <Grid component="label" className={classes.label} xs={11} item>
              <Typography data-field="slack-label" variant="body1" className={classes.wordWrap}>
                {formatMessage('automated_workflows.labels.slack_webhook')}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.formControl}>
            {/* This <EmailIcon /> needs to be here so that the <TextField /> aligns properly */}
            <Grid xs="auto" item>
              <EmailIcon className={baseClasses.invisibleIcon} fontSize="large" />
            </Grid>
            <Grid xs={11} item>
              <TextField
                className={classes.mdTextField}
                label={formatMessage('automated_workflows.labels.slack_webhook_label')}
                value={forensicsSettings.forensicsAutomatedWorkflowSlackWebhook}
                onChange={updateAutomatedWorkflowSettingsOptions('forensicsAutomatedWorkflowSlackWebhook')}
                error={isInvalidSlackWebhook}
                helperText={
                  isInvalidSlackWebhook ? formatMessage('automated_workflows.labels.slack_webhook_error') : ''
                }
              />
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.formControl}>
            <Grid xs="auto" item>
              <img
                data-field="teams-icon"
                alt="MSTeams Icon"
                className={classes.iconPadding}
                src={IMAGES.msTeamsIcon}
              />
            </Grid>
            <Grid component="label" className={classes.label} xs={11} item>
              <Typography data-field="teams-label" variant="body1" className={classes.wordWrap}>
                {formatMessage('automated_workflows.labels.msTeams_webhook')}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.formControl}>
            {/* This <EmailIcon /> needs to be here so that the <TextField /> aligns properly */}
            <Grid xs="auto" item>
              <EmailIcon className={baseClasses.invisibleIcon} fontSize="large" />
            </Grid>
            <Grid xs={11} item>
              <TextField
                className={classes.mdTextField}
                label={formatMessage('automated_workflows.labels.msTeams_webhook_label')}
                value={forensicsSettings.forensicsAutomatedWorkflowMSTeamsWebhook}
                onChange={updateAutomatedWorkflowSettingsOptions('forensicsAutomatedWorkflowMSTeamsWebhook')}
                error={isInvalidMSTeamsWebhook}
                helperText={
                  isInvalidMSTeamsWebhook ? formatMessage('automated_workflows.labels.msTeams_webhook_error') : ''
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>
    </div>
  )
}

AutomatedWorkflowsSettings.propTypes = {
  automatedWorkflowSettingsOptions: PropTypes.any.isRequired
}

export default AutomatedWorkflowsSettings
