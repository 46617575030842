import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from 'ets/configs/styles/defaults'

export default makeStyles(theme => {
  const tabContent = {
    overflow: 'scroll',
    border: `2px solid ${COLORS.LIGHT_GRAY}`
  }

  return {
    tabs: {
      '& button': {
        color: theme.palette.text.secondary,

        '& span': {
          fontSize: 16,
          letterSpacing: 0,
          lineHeight: '19px'
        }
      }
    },
    indicator: {
      backgroundColor: COLORS.BLUE
    },
    tabContentForFrame: {
      ...tabContent,
      padding: theme.spacing(2),
      overflow: 'auto'
    },
    tabContent: {
      ...tabContent
    },
    tabLabel: {
      padding: theme.spacing(0, 1)
    },
    frameContent: {
      border: `1px solid ${COLORS.LIGHT_GRAY}`,
      borderRadius: '10px',
      width: '100%',
      overflowX: 'scroll'
    },
    headersContent: {
      wordBreak: 'break-all',
      // Disable hover over background color
      '& .k-grid-table tr:hover': {
        background: 'transparent'
      },
      '& .k-grid-table tr.k-alt:hover': {
        background: 'transparent'
      },
      '& .k-grid-header tr:hover': {
        background: 'transparent'
      },
      '& .k-grid-header tr.k-alt:hover': {
        background: 'transparent'
      }
    },
    highlightHeaderRow: {
      backgroundColor: COLORS.RED_50,
      color: COLORS.RED_600
    },
    typeColumn: {
      width: 200
    },
    checkIcon: {
      color: 'green'
    },
    removeIcon: {
      color: 'red'
    },
    tableWrapper: {
      position: 'relative'
    },
    copyIcon: {
      position: 'absolute',
      right: 0,
      top: 13,
      zIndex: 10,
      cursor: 'pointer',

      '& svg': {
        width: 16
      }
    },
    copyArrow: {
      '&:before': {
        backgroundColor: 'transparent'
      }
    },
    copyText: {
      color: 'black',
      position: 'absolute',
      zIndex: 10,
      right: 52,
      top: 17
    },
    outboundNoContent: {
      width: '100%',
      textAlign: 'center',
      padding: theme.spacing(2)
    },
    statisticsTabValueContainer: {
      marginTop: theme.spacing(2)
    },
    statisticsTabName: {
      fontSize: 12,
      fontWeight: 500
    },
    statisticsTabValue: {
      fontSize: 12,
      fontWeight: 300
    },
    statisticsTabSecondValue: {
      marginTop: theme.spacing(1),
      fontSize: 12,
      fontWeight: 300
    },
    highlightingLabel: {
      margin: 0
    },
    stickOnTop: {
      justifyContent: 'end',
      position: 'sticky',
      top: 0,
      zIndex: 999,
      backgroundColor: 'white',
      minHeight: '20px'
    }
  }
})
