import { useMemo, useCallback, useReducer } from 'react'

import { process } from '@progress/kendo-data-query'

import { SearchFieldProps, useSearchFieldLogic } from 'global/components/lib/searchField/SearchField'
import { BDSGridPagerConfig, BDSGridSortConfig } from 'global/types/dataTables/dataTables'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'
import { isPending } from 'global/redux/toolkit/api'

import { update as updateAttackingDomainsTable } from 'ets/redux/features/dataTables/attackingDomains/attackingDomainsSlice'
import {
  update as updateUserEmailsTable,
  reset as resetUserEmailsTable
} from 'ets/redux/features/dataTables/userEmails/userEmailsSlice'
import {
  getThreatsByUsernameReport,
  resetThreatsByUsernameReport
} from 'ets/redux/features/reports/threatsByUsername/threatsByUsernameSlice'
import {
  getAttacksByDomainReport,
  resetAttacksByDomainReport
} from 'ets/redux/features/reports/attacksByDomain/attacksByDomainSlice'
import { IsUserInputDisabledForTable } from 'ets/components/pages/dashboard/isUserInputDisabledForTableType'

import {
  TABLE_CELL_HEIGHT,
  TABLE_HEADER_HEIGHT,
  TABLE_FOOTER_HEIGHT
} from 'ets/components/pages/dashboard/attackingDomains/dashboardAttackingDomainsStyles'
import { useAppDispatch, useAppSelector } from 'ets/redux/toolkit/hooks'
import { ColumnsConfig } from 'ets/redux/types/dataTables'

const SEARCH_FIELD_ID = 'attacking-domains-search'

export interface UseDashboardAttackingDomainsLogic {
  isReportLoaded: boolean
  inProgress: boolean
  tableTotal: number | string
  searchFieldConfig: SearchFieldProps
  GRID_COLUMNS: {
    [key: string]: string
  }
  columnsConfig: ColumnsConfig
  tableData: {
    total: number
    data: any[]
  }
  pageConfig: BDSGridPagerConfig
  sortConfig: BDSGridSortConfig | {}
  selectedDomain: string | null
  isUserEmailDialogOpened: boolean
  closeUserEmailDialog: () => void
  highlightKeywords: string[]
}

export interface UseDashboardAttackingDomainsLogicProps {
  isUserInputDisabledForTable: IsUserInputDisabledForTable
  fixTableHeight: (tableId: string, newHeight: number) => void
}

export default function useDashboardAttackingDomainsLogic(
  props: UseDashboardAttackingDomainsLogicProps
): [UseDashboardAttackingDomainsLogic] {
  const { isUserInputDisabledForTable, fixTableHeight } = props
  const dispatch = useAppDispatch()
  const {
    accessTokenId,
    inProgress,
    reportData,
    loadedOffsets,
    attackingDomainsTable,
    isReportLoaded,
    userEmailsTable
  } = useAppSelector(_stores => ({
    inProgress: isPending(_stores.reports.attacksByDomain.apiStatus),
    reportData: _stores.reports.attacksByDomain.data,
    loadedOffsets: _stores.reports.attacksByDomain.loadedOffsets,
    isReportLoaded: !!_stores.reports.attacksByDomain.data.accessTokenId,
    attackingDomainsTable: _stores.dataTables.attackingDomains,
    userEmailsTable: _stores.dataTables.userEmails,
    accessTokenId: _stores.accessToken.accessToken?.id
  }))
  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    selectedDomain: null,
    searchString: ''
  })
  const [isUserEmailDialogOpened, toggleUserEmailDialog] = useDialogLogic()

  const closeUserEmailDialog = useCallback(() => {
    dispatch(resetThreatsByUsernameReport())
    dispatch(resetUserEmailsTable())
    setState({ selectedDomain: null })
    toggleUserEmailDialog()
  }, [dispatch, toggleUserEmailDialog])

  const isUserInputDisabled = useMemo(() => {
    return isUserInputDisabledForTable(attackingDomainsTable, reportData, isReportLoaded) || inProgress
  }, [reportData, isReportLoaded, attackingDomainsTable, isUserInputDisabledForTable, inProgress])

  const updateTableData = useCallback(
    (changes: any = {}) => {
      const { selectedTab, ...updatedValues } = changes

      dispatch(
        updateAttackingDomainsTable({
          ...updatedValues,
          skip: 0
        })
      )
      dispatch(resetAttacksByDomainReport())
      dispatch(getAttacksByDomainReport())
    },
    [dispatch]
  )

  const seeDetails = useCallback(
    (domain: string) => {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.VIEW_ATTACK_DOMAINS_DETAILS, { accessTokenId })
      setState({ selectedDomain: domain })
      toggleUserEmailDialog()
      dispatch(updateUserEmailsTable({ filter: [`${userEmailsTable.FILTER_FIELD.SENDER_DOMAIN}:"${domain}"`] }))
      dispatch(getThreatsByUsernameReport())
    },
    [dispatch, toggleUserEmailDialog, userEmailsTable.FILTER_FIELD, accessTokenId]
  )

  const tableTotal = useMemo(() => {
    return isReportLoaded ? reportData.report.totalCount : attackingDomainsTable.DEMO_DATA.ALL
  }, [reportData, isReportLoaded, attackingDomainsTable])

  const tableLabelTotal = useMemo(() => {
    if (isReportLoaded && attackingDomainsTable.skip === 0 && inProgress) {
      return '-'
    }

    return tableTotal
  }, [isReportLoaded, attackingDomainsTable.skip, inProgress, tableTotal])

  const tableData = useMemo(() => {
    const { skip, take } = attackingDomainsTable

    const { data } = process(
      (reportData.report.data || []).map((report: any) => ({
        ...(report && {
          ...report,
          emailsWithThreats: report.spFraudCount,
          seeDetails
        })
      })),
      { skip, take }
    )

    const fixedCount = Math.max(reportData.report.totalCount, 6)
    const newHeight =
      fixedCount <= attackingDomainsTable.ITEMS_PER_PAGE
        ? fixedCount * TABLE_CELL_HEIGHT + TABLE_HEADER_HEIGHT
        : attackingDomainsTable.ITEMS_PER_PAGE * TABLE_CELL_HEIGHT + TABLE_HEADER_HEIGHT + TABLE_FOOTER_HEIGHT

    fixTableHeight('attacking-domains', newHeight)

    return {
      data: data.filter(report => report.domain),
      total: tableTotal
    }
  }, [reportData, seeDetails, attackingDomainsTable, tableTotal, fixTableHeight])

  const [debouncedOnChange, validateSearchString] = useSearchFieldLogic(
    (search: string) => {
      updateTableData({ search: search.trim() })
      setState({ searchString: search.trim() })
    },
    inProgress,
    SEARCH_FIELD_ID
  )

  const searchFieldConfig = useMemo(() => {
    return {
      id: SEARCH_FIELD_ID,
      value: state.searchString,
      onChange: (e: any) => {
        const validatedSearchString = validateSearchString(e.target.value)
        if (attackingDomainsTable.search !== validatedSearchString) {
          debouncedOnChange(validatedSearchString)
          setState({ searchString: validatedSearchString })
        }
      },
      disabled: isUserInputDisabled
    }
  }, [state.searchString, attackingDomainsTable, isUserInputDisabled, validateSearchString, debouncedOnChange])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = attackingDomainsTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: tableData.total,
      onPageChange: (e: any) => {
        dispatch(updateAttackingDomainsTable(e.page))

        if (!loadedOffsets.includes(e.page.skip)) {
          dispatch(getAttacksByDomainReport())
        }
      }
    }
  }, [attackingDomainsTable, tableData, loadedOffsets, dispatch])

  const sortConfig: BDSGridSortConfig | {} = useMemo(() => {
    if (!tableData.total) {
      return {}
    }

    return {
      sortable: !isUserInputDisabled && {
        allowUnsort: false,
        mode: 'single'
      },
      sort: attackingDomainsTable.sort,
      onSortChange: (e: any) => {
        updateTableData({ sort: e.sort })
      }
    }
  }, [attackingDomainsTable, updateTableData, tableData.total, isUserInputDisabled])

  return useMemo(() => {
    return [
      {
        isReportLoaded,
        tableTotal: tableLabelTotal,
        searchFieldConfig,
        GRID_COLUMNS: attackingDomainsTable.GRID_COLUMNS,
        columnsConfig: attackingDomainsTable.columnsConfig,
        tableData,
        pageConfig,
        sortConfig,
        inProgress,
        selectedDomain: state.selectedDomain,
        isUserEmailDialogOpened,
        closeUserEmailDialog,
        highlightKeywords: [attackingDomainsTable.search]
      }
    ]
  }, [
    isReportLoaded,
    tableLabelTotal,
    searchFieldConfig,
    attackingDomainsTable,
    tableData,
    pageConfig,
    sortConfig,
    inProgress,
    state,
    isUserEmailDialogOpened,
    closeUserEmailDialog
  ])
}
