/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react'

import * as analyticsLib from 'global/lib/analytics/analyticsService'

import routesConfig from 'sen/lib/routes/routesConfig'

const DEFAULT_PUBLIC_PAGE = routesConfig.SIGNIN_SIGNUP
const DEFAULT_PRIVATE_PAGE = routesConfig.EMPTY_REPORT

const UnregisteredPage: React.FC = () => {
  useEffect(() => {
    const isPrivateRoute = window.location.pathname.includes('/report')
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.UNREGISTERED_PAGE_VISITED, { url: window.location.pathname })
    isPrivateRoute ? DEFAULT_PRIVATE_PAGE.goto() : DEFAULT_PUBLIC_PAGE.goto()
  }, [])

  return null
}

export default UnregisteredPage
