import { makeStyles } from '@material-ui/core/styles'
import { COLORS } from 'global/configs/theme/defaults'

export default makeStyles(theme => {
  return {
    switcher: {
      marginLeft: -12
    },
    formRow: {
      marginBottom: theme.spacing(2)
    },
    actionButtons: {
      marginTop: theme.spacing(2)
    },
    testResultsRow: {
      marginBottom: theme.spacing(3)
    },
    textField: {
      width: '100%'
    },
    formControl: {
      width: '100%'
    },
    secondColumn: {
      marginLeft: theme.spacing(2)
    },
    actionButton: {
      minWidth: 90,
      marginRight: theme.spacing(1)
    },
    successIcon: {
      color: COLORS.GREEN,
      marginRight: theme.spacing(1),
      verticalAlign: -6
    },
    failedIcon: {
      color: COLORS.RED,
      marginRight: theme.spacing(1),
      verticalAlign: -6
    }
  }
})
