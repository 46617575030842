import React from 'react'

import { Grid, Alert, AlertProps } from '@barracuda-internal/bds-core'
import { Close as CloseIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'

import useAppAlert from 'global/components/lib/appAlert/useAppAlert'
import styles from 'global/components/lib/appAlert/appAlertStyles'

const AppAlert: React.FC<AlertProps> = props => {
  const classes = styles()
  const [routeAlerts, onRemoveAlert] = useAppAlert()

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center">
      {routeAlerts.map(alert => {
        const [alertId, severity, message] = alert

        return (
          <Alert
            className={classes.alert}
            key={alertId}
            severity={severity}
            {...props}
            action={
              <CloseIcon
                className={classes.icon}
                onClick={() => {
                  onRemoveAlert(alertId)
                }}
              />
            }
          >
            {message}
          </Alert>
        )
      })}
    </Grid>
  )
}

export default AppAlert
