import { useMemo, useEffect, useCallback } from 'react'
import { process } from '@progress/kendo-data-query'

import { FraudUnifiedRufData } from 'global/types/api/dmarc'
import { getErrorMessage, isPending } from 'global/redux/toolkit/api'
import useTablePeriodicCheck from 'global/lib/useTablePeriodicCheck/useTablePeriodicCheck'
import { BDSGridPagerConfig, ColumnsConfig } from 'global/types/dataTables/dataTables'
import * as analyticsLib from 'global/lib/analytics/analyticsService'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import { getDmarcFraudUnifiedRufData, resetFraudRufData } from 'sen/redux/features/dmarc/dmarcSlice'
import {
  reset as resetDataTable,
  update as updateDataTable
} from 'sen/redux/features/dataTables/dmarcFraudUnifiedRufData/dmarcFraudUnifiedRufDataSlice'

export interface TableConfig {
  isLoaded: boolean
  inProgress: boolean
  tableData: {
    total: number
    data: FraudUnifiedRufData[]
  }
  pageConfig: BDSGridPagerConfig
  columns: { [key: string]: string }
  columnsConfig: ColumnsConfig
}

export interface LogicConfig {
  error: string | undefined
  isRufSampleLoading: boolean
}

export default function useDmarcFraudLogic(country: string): [TableConfig, LogicConfig] {
  const {
    activePath,
    getDmarcUnifiedFraudRufDataInProgress,
    dmarcUnifiedFraudRufDataError,
    dmarcFraudUnifiedRufData,
    loadedFraudDataOffsets,
    dmarcFraudUnifiedRufDataTable,
    isRufSampleLoading
  } = useAppSelector(_stores => ({
    activePath: _stores.app.activePath,
    getDmarcUnifiedFraudRufDataInProgress: isPending(_stores.dmarc.getDmarcFraudUnifiedRufDataApiStatus),
    dmarcUnifiedFraudRufDataError: getErrorMessage(_stores.dmarc.getDmarcFraudUnifiedRufDataApiStatus),
    dmarcFraudUnifiedRufData: _stores.dmarc.dmarcFraudUnifiedRufData,
    loadedFraudDataOffsets: _stores.dmarc.loadedDmarcFraudRufOffsets,
    dmarcFraudUnifiedRufDataTable: _stores.dataTables.dmarcFraudUnifiedRufData,
    isRufSampleLoading: isPending(_stores.dmarc.getRufSampleApiStatus)
  }))

  const dispatch = useAppDispatch()
  const [initTableRefresh] = useTablePeriodicCheck()

  // init
  useEffect(() => {
    initTableRefresh(tableRefresh)
    dispatch(getDmarcFraudUnifiedRufData({ domain: activePath.params.domain, country }))
    analyticsLib.trackEvent(analyticsLib.EVENTS.DF_DOMAINS_DMARC_REVIEW_COUNTRIES_RUF_SAMPLES_TABLE_PREVIEW, {
      domain: activePath.params.domain,
      country
    })

    return () => {
      dispatch(resetFraudRufData(true))
      dispatch(resetDataTable())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resetTable = useCallback(() => {
    dispatch(resetDataTable())
    dispatch(getDmarcFraudUnifiedRufData({ domain: activePath.params.domain, country }))
  }, [dispatch, activePath, country])

  const tableRefresh = useCallback(() => {
    if (!getDmarcUnifiedFraudRufDataInProgress) {
      resetTable()
    }
  }, [resetTable, getDmarcUnifiedFraudRufDataInProgress])

  const tableData = useMemo(() => {
    const { skip, take } = dmarcFraudUnifiedRufDataTable

    const { data } = process(
      (dmarcFraudUnifiedRufData?.results || []).map((report: FraudUnifiedRufData) => ({
        ...(report && {
          ...report
        })
      })),
      { skip, take }
    )

    return {
      data: data.filter(report => report.subject),
      total: dmarcFraudUnifiedRufData?.count || 0
    }
  }, [dmarcFraudUnifiedRufData, dmarcFraudUnifiedRufDataTable])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = dmarcFraudUnifiedRufDataTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: tableData.total,
      onPageChange: (e: any) => {
        dispatch(updateDataTable(e.page))

        if (!loadedFraudDataOffsets?.includes(e.page.skip)) {
          dispatch(getDmarcFraudUnifiedRufData({ domain: activePath.params.domain, country }))
          analyticsLib.trackEvent(analyticsLib.EVENTS.DF_DOMAINS_DMARC_REVIEW_COUNTRIES_RUF_SAMPLES_TABLE_PAGINATE, {
            domain: activePath.params.domain,
            country
          })
        }
      }
    }
  }, [activePath, country, dmarcFraudUnifiedRufDataTable, tableData, dispatch, loadedFraudDataOffsets])

  return useMemo(() => {
    return [
      {
        isLoaded: !!dmarcFraudUnifiedRufData,
        inProgress: getDmarcUnifiedFraudRufDataInProgress,
        tableData,
        pageConfig,
        columns: dmarcFraudUnifiedRufDataTable.GRID_COLUMNS,
        columnsConfig: dmarcFraudUnifiedRufDataTable.columnsConfig
      },
      {
        error: dmarcUnifiedFraudRufDataError,
        isRufSampleLoading
      }
    ]
  }, [
    dmarcFraudUnifiedRufData,
    dmarcFraudUnifiedRufDataTable,
    getDmarcUnifiedFraudRufDataInProgress,
    dmarcUnifiedFraudRufDataError,
    isRufSampleLoading,
    pageConfig,
    tableData
  ])
}
