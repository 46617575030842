import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { insertReportData } from 'global/redux/features/reports/reducerHandlers'
import { ApiStatus, failedResponse, inIdle, inProgress, successResponse } from 'global/redux/toolkit/api'

import { getDomainsReport } from 'ets/redux/features/reports/reportsApiThunks'
import { INITIAL_STATE as domainsTableInitialState } from 'ets/redux/features/dataTables/domains/domainSlice'
import { Domain, DomainReport } from 'ets/types/domainTypes'

export interface DomainsState {
  apiStatus: ApiStatus
  loadedOffsets: number[]
  originalData: DomainReport | undefined
  data: DomainReport | undefined
  pdfData: DomainReport | undefined
}

// initialState
export const INITIAL_STATE: DomainsState = {
  apiStatus: inIdle,
  loadedOffsets: [],
  originalData: undefined,
  data: undefined,
  pdfData: undefined
}

/* eslint-disable no-param-reassign */
export const domainsSlice = createSlice({
  name: 'REPORTS/DOMAINS',
  initialState: INITIAL_STATE,
  reducers: {
    softUpdateReportCounts: {
      reducer: (state: DomainsState, action: PayloadAction<{ counts: number }>) => {
        if (state.data) {
          state.data.report.count = action.payload.counts
          state.data.report.totalCount = action.payload.counts
        }
      },
      prepare: (counts: number) => ({
        payload: {
          counts
        }
      })
    },
    softUpdateReportData: {
      reducer: (state: DomainsState, action: PayloadAction<{ data: Domain[] }>) => {
        if (state.data) {
          state.data.report.data = action.payload.data
        }
      },
      prepare: (data: Domain[]) => ({
        payload: {
          data
        }
      })
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getDomainsReport.pending, state => {
        state.apiStatus = inProgress
        if (!state.loadedOffsets.length) {
          state.loadedOffsets = [domainsTableInitialState.skip]
        }
      })
      .addCase(getDomainsReport.fulfilled, (state, action) => {
        state.apiStatus = successResponse
        state.data = insertReportData(state.data, action.payload as any)
        state.originalData = insertReportData(state.data, action.payload as any)
        state.pdfData = insertReportData(state.pdfData, action.payload as any)
      })
      .addCase(getDomainsReport.rejected, (state, action) => {
        state.apiStatus = failedResponse(action.payload as string)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { softUpdateReportCounts, softUpdateReportData, reset } = domainsSlice.actions

export { getDomainsReport }

export default domainsSlice.reducer
