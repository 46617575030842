import React, { useMemo } from 'react'

import { Chip, Grid, Menu, MenuItem, Pagination as BDSPagination, Typography } from '@barracuda-internal/bds-core'

import IMAGES from 'global/configs/theme/images.config'

import styles from './paginationStyles'
import usePaginationLogic from './usePaginationLogic'

const DEFAULT_ITEMS_PER_PAGE = [15, 25, 50, 100]

interface PaginationProps {
  itemsPerPageOptions?: number[]
  onPageChange: (page: number) => void
  onItemsPerPageChange: (itemsPerPage: number) => void
  skip: number
  take: number
  total: number
}

const Pagination = ({
  itemsPerPageOptions = DEFAULT_ITEMS_PER_PAGE,
  onPageChange,
  onItemsPerPageChange,
  skip,
  take,
  total
}: PaginationProps): JSX.Element => {
  const classes = styles()

  const [paginationLogic] = usePaginationLogic({ onPageChange, onItemsPerPageChange, skip, take, total })

  const {
    anchorEl,
    handleClickListItem,
    handleClose,
    handleMenuItemClick,
    handlePageChange,
    itemsChangePagination,
    itemsPerPage,
    page,
    totalPage
  } = paginationLogic

  return useMemo(
    () => (
      <Grid container className={classes.paginationContainer}>
        <Grid item className={classes.paginationActions}>
          <BDSPagination
            count={totalPage}
            showFirstButton
            showLastButton
            onChange={handlePageChange}
            page={page}
            siblingCount={2}
          />
          <Chip
            size="small"
            label={itemsPerPage}
            clickable
            onClick={handleClickListItem}
            onDelete={handleClickListItem}
            deleteIcon={<img alt="Arrow Drop Down" src={IMAGES.arrowDropDown} />}
            className={classes.paginationChip}
          />
          <Menu id="page-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            {itemsPerPageOptions.map(itemsPerPageOption => (
              <MenuItem
                key={itemsPerPageOption}
                selected={itemsPerPageOption === itemsPerPage}
                onClick={() => handleMenuItemClick(itemsPerPageOption)}
              >
                {itemsPerPageOption}
              </MenuItem>
            ))}
          </Menu>
          <Typography variant="button" color="secondary" className={classes.paginationText}>
            items per page
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="button" color="secondary" className={classes.paginationText}>
            {itemsChangePagination}
          </Typography>
        </Grid>
      </Grid>
    ),
    [
      anchorEl,
      classes.paginationActions,
      classes.paginationChip,
      classes.paginationContainer,
      classes.paginationText,
      handleClickListItem,
      handleClose,
      handleMenuItemClick,
      handlePageChange,
      itemsChangePagination,
      itemsPerPage,
      itemsPerPageOptions,
      page,
      totalPage
    ]
  )
}

export default Pagination
