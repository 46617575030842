import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Button, Typography, Card, Grid } from '@barracuda-internal/bds-core'
import { EpImpersonationProtection } from '@barracuda-internal/bds-core/dist/Logos/Products'

import styles from 'sen/components/pages/signupRemediates/signupRemediatesStyles'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import ModeHandlerSelector from 'global/components/lib/modeHandlerSelector/ModeHandlerSelector'
import AccountSwitcher from 'global/components/features/signinSignup/layout/AccountSwitcher'
import { useFormatMessage } from 'global/lib/localization'

import SigninSignupLayout from 'sen/components/lib/layout/SigninSignupLayout'
import useSignupRemediatesLogic from 'sen/components/pages/signupRemediates/useSignupRemediatesLogic'

const BASE_I18N_KEY = 'sen.app.signup_remediates'

const SignupRemediates: React.FC = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [signupRemediatesLogic] = useSignupRemediatesLogic()
  const { selectedCMSetting, onUpdateCMSettings, onContinue, inProgress, logout, userEmail } = signupRemediatesLogic

  return useMemo(
    () => (
      <SigninSignupLayout>
        <Card className={classes.wrapper}>
          <div className={classes.linearProgress}>{inProgress && <LinearProgress />}</div>
          <div className={classes.headingLogoWrapper}>
            <EpImpersonationProtection alt="" />
          </div>
          <Typography className={classes.title} align="center" data-field="title">
            {formatMessage('title')}
          </Typography>
          <ModeHandlerSelector
            selectedCMSetting={selectedCMSetting}
            onUpdateCMSettings={onUpdateCMSettings}
            appName={formatMessage('app_name')}
          />
          <Grid container justifyContent="flex-end">
            <Button
              className={classes.primaryButton}
              onClick={onContinue}
              disabled={inProgress}
              color="primary"
              data-action="continue"
              variant="contained"
              size="large"
            >
              {formatMessage('continue')}
            </Button>
          </Grid>
        </Card>
        <AccountSwitcher userEmail={userEmail} onSwitchAccount={logout} />
      </SigninSignupLayout>
    ),
    [selectedCMSetting, onUpdateCMSettings, onContinue, inProgress, userEmail, logout, classes, formatMessage]
  )
}

SignupRemediates.propTypes = {
  location: PropTypes.any
}

SignupRemediates.defaultProps = {
  location: undefined
}

export default SignupRemediates
