import React, { useEffect, useMemo } from 'react'
import { forceRedirectToSignup } from 'global/lib/routes/routesConfig'
import { useAppSelector, useAppDispatch } from 'global/redux/toolkit/hooks'
import Loading from 'global/components/lib/loading/Loading'
import { getUser } from 'global/redux/features/auth/authSlice'
import { isSuccess, isFailed } from 'global/redux/toolkit/api'
import { reduxStore } from 'global/lib/reduxStore'

import routesConfig from 'admin/lib/routes/routesConfig'

export default function validateAdmin<P extends {}>(WrappedComponent: React.ComponentType<P>) {
  const ValidateAdmin: React.FC<any> = props => {
    const { auth, hasUser, activeUrl, isStaff } = useAppSelector((_stores: any) => ({
      auth: _stores.auth,
      hasUser: !!_stores.user.data.id,
      activeUrl: _stores.app?.activePath?.url,
      isStaff: _stores.user?.data?.isStaff
    }))
    const dispatch = useAppDispatch()

    useEffect(() => {
      if (!hasUser) {
        const activePath = reduxStore.getState()?.app?.activePath?.id

        // Change old ets admin url to the new one
        if (activePath === routesConfig.DASHBOARD_ETS.id) {
          routesConfig.DASHBOARD_EMAIL_THREAT_SCANNER.softGoto()
        }

        // Change old sentinel admin url to the new one
        if (activePath === routesConfig.DASHBOARD_SENTINEL.id) {
          routesConfig.DASHBOARD_IMPERSONATION_PROTECTION.softGoto()
        }

        // Change old forensics admin url to the new one
        if (activePath === routesConfig.DASHBOARD_FORENSICS.id) {
          routesConfig.DASHBOARD_INCIDENT_RESPONSE.softGoto()
        }

        dispatch(getUser())
      }
    }, [dispatch, hasUser])

    const isAuthenticated: boolean = useMemo(() => {
      if (isSuccess(auth.getUserApiStatus) || isFailed(auth.getUserApiStatus)) {
        const isAdminPage = activeUrl.includes('admin')
        if (isAdminPage && !isStaff) {
          forceRedirectToSignup()
          return false
        }

        return true
      }

      return false
    }, [auth, isStaff, activeUrl])

    return useMemo(() => {
      if (!isAuthenticated) {
        return <Loading />
      }

      return <WrappedComponent {...(props as any)} />
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated])
  }

  return ValidateAdmin
}
