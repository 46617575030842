/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { DialogContent, Grid } from '@barracuda-internal/bds-core'

import CircularProgress from 'global/components/lib/circularProgress/CircularProgress'
import GeoChart from 'global/components/lib/charts/GeoChart'

import styles from 'sen/components/lib/dialogs/dmarcCountries/dmarcCountriesMap/dmarcCountriesMapStyles'
import useDmarcCountriesMapLogic from 'sen/components/lib/dialogs/dmarcCountries/dmarcCountriesMap/useDmarcCountriesMapLogic'

export interface DmarcAllCountriesMapProps {
  onChartSelect: (country: string) => void
}

export const DmarcCountriesMap: React.FC<DmarcAllCountriesMapProps> = ({ onChartSelect }) => {
  const [isLoading, chartHeader, chartData] = useDmarcCountriesMapLogic()
  const classes = styles()
  return useMemo(
    () => (
      <DialogContent>
        {isLoading && (
          <Grid container justifyContent="center">
            <CircularProgress className={classes.loading} data-field="loader" />
          </Grid>
        )}
        {!isLoading && (
          <div className={classes.chart} data-field="chart-wrapper">
            <GeoChart data={[chartHeader, ...chartData]} onChartSelect={onChartSelect} />
          </div>
        )}
      </DialogContent>
    ),
    [isLoading, chartHeader, chartData, classes, onChartSelect]
  )
}

DmarcCountriesMap.propTypes = {
  onChartSelect: PropTypes.func.isRequired
}

export default DmarcCountriesMap
