import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  TextareaAutosize,
  TextField,
  Zoom
} from '@barracuda-internal/bds-core'

import DEFAULTS from 'global/configs/theme/defaults'
import { useFormatMessage } from 'global/lib/localization'

import useNotificationEmailDialogLogic from 'sen/components/lib/dialogs/newIncidentDialog/lib/notificationEmailDialog/useNotificationEmailDialogLogic'

import styles from 'sen/components/lib/dialogs/newIncidentDialog/newIncidentDialogStyles'

export interface NotificationEmailDialogProps {
  onClose: () => void
  selectedSender: {
    displayName: string
    email: string
  }
}

const BASE_I18N_KEY = 'sen.app.new_incident_dialog.notification_email'

export const NotificationEmailDialog: React.FC<NotificationEmailDialogProps> = ({ onClose, selectedSender }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  const [form] = useNotificationEmailDialogLogic({ onClose, selectedSender })

  return useMemo(
    () => (
      <Dialog
        style={{ zIndex: DEFAULTS.SECONDARY_DIALOGS_ZINDEX }}
        maxWidth="md"
        fullWidth
        open
        TransitionComponent={Zoom}
      >
        <DialogTitle>
          <Typography className={classes.paddedTitle}>{formatMessage('title')}</Typography>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <Grid container item spacing={2}>
            <Grid item>
              <Typography className={classes.dialogText}>{formatMessage('from')}</Typography>
            </Grid>

            <Grid item>
              <Typography className={classes.dialogText}>
                {formatMessage('email', { displayName: selectedSender?.displayName, email: selectedSender?.email })}
              </Typography>
            </Grid>
          </Grid>

          <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item>
              <Typography className={classes.dialogText}>{formatMessage('subject')}</Typography>
            </Grid>

            <Grid item xs={11}>
              <TextField
                className={classes.textField}
                value={form.template.subject}
                onChange={form.onChange('subject')}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
              />
            </Grid>
          </Grid>

          <Grid container>
            <TextareaAutosize
              className={classes.textField}
              value={form.template.body}
              onChange={form.onChange('body')}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="text" size="large" onClick={onClose}>
            {formatMessage('cancel')}
          </Button>
          <Button color="primary" variant="contained" size="large" onClick={form.onSave}>
            {formatMessage('apply')}
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [classes, formatMessage, onClose, form, selectedSender]
  )
}

NotificationEmailDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  selectedSender: PropTypes.any.isRequired
}

export default NotificationEmailDialog
