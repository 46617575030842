import React, { useMemo } from 'react'

import NavigationBar from 'global/components/lib/navigation/NavigationBar'

import useNavbarLogic from 'sen/components/lib/layout/useNavbarLogic'

const SentinelNavbar: React.FC = () => {
  const [navbarLogic] = useNavbarLogic()

  const { title, logo, currentPath, onNavigate, routes, options } = navbarLogic

  return useMemo(
    () => (
      <NavigationBar
        title={title}
        logo={logo}
        currentPath={currentPath}
        routes={routes}
        options={options}
        onNavigate={onNavigate}
      />
    ),
    [currentPath, logo, onNavigate, options, routes, title]
  )
}

export default SentinelNavbar
