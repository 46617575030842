const UI_ROUTES = {
  signinSignup: {
    SIGNIN_SIGNUP: {
      path: '/signup'
    },
    SIGNIN_CONNECT: {
      path: '/signin/connect'
    },
    SIGNUP_COMPLETE: {
      path: '/signup/complete'
    },
    SIGNUP_COMPLETE_POLICY: {
      path: '/signup/complete/policy'
    }
  },
  HOW_MANY_SCANS: {
    path: '/howmanyscans'
  },
  HOW_MANY_SCANS_PARTNER_PORTAL: {
    path: '/howmanyscanspartnerportal'
  },
  dashboard: {
    DASHBOARD: {
      path: '/report/:reportId',
      metadata: {
        isReportRoute: true
      }
    },
    EMPTY_REPORT: {
      path: '/report'
    },
    SHARED: {
      path: '/shared/:shareSecret'
    }
  },
  static: {
    MYLINK: {
      path: '/mylink'
    },
    MYLINK_AWS_VAR: {
      path: '/aws-var'
    },
    TECHDATA: {
      path: '/techdata'
    },
    ARROW: {
      path: '/arrow'
    },
    SYNNEX: {
      path: '/synnex'
    }
  }
}

export default UI_ROUTES
