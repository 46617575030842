import { makeStyles, createStyles } from '@material-ui/core/styles'

import { COLORS } from 'global/configs/theme/defaults'

const OVERVIEW_HEIGHT = 180

export default makeStyles(theme => {
  const styles = createStyles({
    ellipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  })

  return {
    error: {
      marginTop: theme.spacing(1)
    },
    loading: {
      width: '16px !important',
      height: '16px !important',
      marginLeft: theme.spacing(1),
      verticalAlign: -3
    },
    indicator: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0
    },
    title: {
      display: 'inline-block',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    linkText: {
      cursor: 'pointer',
      color: COLORS.BLUE,
      fontWeight: 300
    },
    linkData: {
      fontWeight: 400
    },
    overview: {
      position: 'relative',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
      height: OVERVIEW_HEIGHT
    },
    overviewFullRow: {
      borderBottom: `1px solid ${COLORS.PRODUCT_UI_ELEMENT}`
    },
    overviewRow: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    },
    overviewLabel: {
      color: COLORS.BLACK_50,
      lineHeight: '48px'
    },
    overviewText: {
      ...styles.ellipsis,
      lineHeight: '48px'
    },
    searchInProgress: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    searchInProgressLoading: {
      width: '16px !important',
      height: '16px !important',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(2),
      verticalAlign: -2
    }
  }
})
