import { useMemo, useEffect } from 'react'

import { process } from '@progress/kendo-data-query'

import { luxonDate, formatDateWithTime } from 'global/lib/datetime'

import { BDSGridPagerConfig } from 'global/types/dataTables/dataTables'
import { ColumnsConfig } from 'global/types/dataTables/columnsConfigType'
import { isPending, getErrorMessage } from 'global/redux/toolkit/api'
import { AlertSignin, SigninByCountry } from 'global/types/api/ato'
import { getCountryName } from 'global/lib/isoCountries'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'

import {
  update as updateSigninsByCountryTable,
  reset as resetSigninsByCountryTable
} from 'sen/redux/features/dataTables/signinsByCountry/signinsByCountrySlice'
import { getSigninsByCountry, resetSigninsByCountry } from 'sen/redux/features/ato/atoSlice'
import { GetSigninsByCountry } from 'sen/redux/features/ato/atoApiThunks'

export interface ModifiedAlertSignin extends AlertSignin {
  formattedDate: string
  countryName: string
}

export type InProgress = boolean
export type IsLoaded = boolean
export type ApiError = string | undefined
export interface TableConfig {
  tableData: {
    total: number
    data: any[]
  }
  isFlexibleTable: boolean
  pageConfig: BDSGridPagerConfig
  columns: { [key: string]: string }
  columnsConfig: { [key: string]: ColumnsConfig }
}
export type Timeframe = number | undefined
export type Limit = number

export interface Country {
  code: string
  timeframe: number
}

export enum DialogType {
  Alert = 'alert',
  User = 'user',
  Country = 'country'
}

export interface UseCountryRelatedSigninsLogic {
  country: SigninByCountry['country']
  timeframe: number
  dialogType: DialogType
}

const OFFSET_LIMIT = 10000

export default function useCountryRelatedSigninsLogic({
  country,
  timeframe,
  dialogType
}: UseCountryRelatedSigninsLogic): [IsLoaded, InProgress, ApiError, TableConfig, Timeframe, Limit] {
  const dispatch = useAppDispatch()
  const { inProgress, apiError, countryRelatedSignins, countryRelatedSigninsTable, loadedOffsets } = useAppSelector(
    _stores => ({
      inProgress: isPending(_stores.ato.getSigninsByCountryApiStatus),
      apiError: getErrorMessage(_stores.ato.getSigninsByCountryApiStatus),
      countryRelatedSigninsTable: _stores.dataTables.signinsByCountry,
      countryRelatedSignins: _stores.ato.signinsByCountry,
      loadedOffsets: _stores.ato.loadedSigninsByCountryOffsets
    })
  )
  const isActiveLogic = dialogType === DialogType.Country

  // Init
  useEffect(() => {
    if (isActiveLogic) {
      dispatch(getSigninsByCountry(countryRelatedSigninsApiParams))
    }

    return () => {
      if (isActiveLogic) {
        dispatch(resetSigninsByCountryTable())
        dispatch(resetSigninsByCountry(true))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const countryRelatedSigninsApiParams: GetSigninsByCountry = useMemo(() => {
    return {
      country,
      timeframe,
      startTime: luxonDate()
        .minus({ hours: timeframe })
        .toUTC()
        .toISO(),
      endTime: luxonDate()
        .toUTC()
        .toISO()
    }
  }, [country, timeframe])

  const tableData = useMemo(() => {
    const { skip, take } = countryRelatedSigninsTable

    const { data } = process(
      (countryRelatedSignins?.report?.data || []).map((report: SigninByCountry) => ({
        ...(report && {
          ...report,
          formattedDate: formatDateWithTime(report.date || ''),
          countryName: getCountryName(report.country)
        })
      })),
      { skip, take }
    )

    return {
      data: data.filter(report => report.date),
      total: countryRelatedSignins?.report?.totalCount || 0
    }
  }, [countryRelatedSignins, countryRelatedSigninsTable])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take } = countryRelatedSigninsTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: tableData.total,
      onPageChange: (e: any) => {
        dispatch(updateSigninsByCountryTable(e.page))

        if (!loadedOffsets.includes(e.page.skip)) {
          dispatch(getSigninsByCountry(countryRelatedSigninsApiParams))
        }
      }
    }
  }, [countryRelatedSigninsTable, tableData, loadedOffsets, dispatch, countryRelatedSigninsApiParams])

  return useMemo(() => {
    return [
      !!countryRelatedSignins || !!apiError,
      inProgress,
      apiError,
      {
        tableData,
        pageConfig,
        isFlexibleTable: !!tableData.total && tableData.total < countryRelatedSigninsTable.ITEMS_PER_PAGE,
        columns: countryRelatedSigninsTable.GRID_COLUMNS,
        columnsConfig: countryRelatedSigninsTable.columnsConfig
      },
      timeframe,
      OFFSET_LIMIT
    ]
  }, [tableData, pageConfig, countryRelatedSigninsTable, inProgress, apiError, timeframe, countryRelatedSignins])
}
