import { useMemo, useCallback, useState, useEffect } from 'react'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import useUserDataLib from 'global/lib/userData/useUserData'
import { isFailed, isPending, isSuccess } from 'global/redux/toolkit/api'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import { sendFeedback, reset as resetFeedback } from 'sen/redux/features/feedback/feedbackSlice'
import { addWhitelistItem } from 'sen/redux/features/settings/settingsSlice'

import routesConfig from 'sen/lib/routes/routesConfig'

export type InProgress = boolean
export type IsCleanupPage = boolean
export interface RadioButtonConfig {
  selectedRadio: number
  setSelectedRadio: (_e: object, newValue: string) => void
  email: string
  domain: string
}
export type OnReport = () => void
export type ErrorMsg = string | undefined

const REPORT_LEVELS = {
  NONE: 'none',
  DOMAIN: 'domain',
  EMAIL: 'email'
}

export default function useReportFalsePositiveLogic(
  onClose: (isMarked?: boolean) => void
): [InProgress, RadioButtonConfig, OnReport, ErrorMsg, IsCleanupPage] {
  const [selectedRadio, setSelectedRadio] = useState(0)
  const {
    attack,
    accessTokenId,
    feedback,
    isFeedbackPending,
    isFeedbackSuccess,
    isFeedbackFailed,
    isSpAttackDetailsLoading,
    isCleanupPage,
    isAddWhitelistItem,
    userEmail
  } = useAppSelector(_stores => ({
    attack: _stores.attack.details,
    accessTokenId: _stores.accessToken.accessToken?.id,
    feedback: _stores.feedback,
    isFeedbackPending: isPending(_stores.feedback.sendFeedbackApiStatus),
    isFeedbackSuccess: isSuccess(_stores.feedback.sendFeedbackApiStatus),
    isFeedbackFailed: isFailed(_stores.feedback.sendFeedbackApiStatus),
    isSpAttackDetailsLoading: isPending(_stores.attack.spAttackApiStatus),
    isCleanupPage: _stores.app.activePath?.id === routesConfig.CLEANUP_TABLE.id,
    isAddWhitelistItem: isPending(_stores.settings.addWhitelistItemApiStatus),
    userEmail: _stores.user.data?.email || ''
  }))
  const dispatch = useAppDispatch()
  const [userDataLib] = useUserDataLib()

  // Init
  useEffect(() => {
    return () => {
      dispatch(resetFeedback())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const inProgress: boolean = useMemo(() => {
    return isSpAttackDetailsLoading || isFeedbackPending || isAddWhitelistItem
  }, [isSpAttackDetailsLoading, isFeedbackPending, isAddWhitelistItem])

  const domain: string = useMemo(() => {
    return (attack.sender?.email.split('@') || ['']).pop() || ''
  }, [attack])

  const radioButtonConfig: RadioButtonConfig = useMemo(
    () => ({
      selectedRadio,
      setSelectedRadio: (_e, newValue) => {
        setSelectedRadio(Number(newValue))
      },
      email: attack.sender?.email,
      domain
    }),
    [selectedRadio, setSelectedRadio, attack, domain]
  )

  // onReport
  useEffect(() => {
    if (isFeedbackSuccess) {
      if (feedback.redirect) {
        window.open(feedback.redirect, '_blank')
      }

      onClose(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFeedbackSuccess])

  useEffect(() => {
    if (isFeedbackFailed && !feedback.error) {
      onClose(true)
    }
  }, [isFeedbackFailed, feedback.error, onClose])

  const addWhitelistItemIfNecessary = useCallback(() => {
    const reportLevel = Object.values(REPORT_LEVELS)[selectedRadio]

    if (reportLevel !== REPORT_LEVELS.NONE) {
      dispatch(
        addWhitelistItem({
          pattern: reportLevel === REPORT_LEVELS.DOMAIN ? domain : attack.sender?.email,
          remarks: `Added by ${userDataLib.getUser().email}`
        })
      )
    }
  }, [dispatch, selectedRadio, attack.sender, domain, userDataLib])

  const onReport = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.REPORT_FALSE_POSITIVE, {
      accessTokenId,
      attackId: attack.id
    })
    if (attack.feedbackPublicKey) {
      dispatch(sendFeedback({ publicKey: attack.feedbackPublicKey, userEmail }))
    }
    addWhitelistItemIfNecessary()
  }, [accessTokenId, attack.id, attack.feedbackPublicKey, addWhitelistItemIfNecessary, dispatch, userEmail])

  return useMemo(() => {
    return [inProgress, radioButtonConfig, onReport, feedback.error, isCleanupPage]
  }, [inProgress, radioButtonConfig, onReport, feedback.error, isCleanupPage])
}
