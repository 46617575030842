import { useMemo, useCallback } from 'react'

import { useAppSelector, useAppDispatch } from 'global/redux/toolkit/hooks'
import { logout } from 'global/redux/features/auth/authSlice'

export type NavbarLogicProp = {
  isNavbarVisible: boolean
  onLogout: () => void
}

export default function useNavbarLogic(): [NavbarLogicProp] {
  const dispatch = useAppDispatch()
  const { isNavbarVisible } = useAppSelector((_stores: any) => ({
    isNavbarVisible: _stores.app.activePath.isNavbarVisible
  }))

  // start logout
  const onLogout = useCallback(() => {
    dispatch(logout(true))
  }, [dispatch])

  return useMemo(
    () => [
      {
        isNavbarVisible,
        onLogout
      }
    ],
    [isNavbarVisible, onLogout]
  )
}
