import React, { useMemo } from 'react'
import { Card, Typography, Grid } from '@barracuda-internal/bds-core'

import CircularProgress from 'global/components/lib/circularProgress/CircularProgress'
import { useFormatMessage } from 'global/lib/localization'

import styles from 'admin/components/pages/dashboard/metrics/metricsStyle'

const CARD_LOADER_SIZE = 19

export interface CardData {
  icon: React.ElementType
  value: number
  messageId: string
}

export interface MetricsProps {
  inProgress: boolean
  cardData: CardData[]
  BASE_I18N_KEY: string
}

type GridSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

const Metrics: React.FC<MetricsProps> = ({ inProgress, cardData, BASE_I18N_KEY }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const itemSize = (12 / cardData.length) as GridSize
  return useMemo(
    () => (
      <Grid data-field="metrics-container">
        <Grid item data-field="metrics-grid-title">
          <Typography data-field="metrics-title">{formatMessage('title')}</Typography>
          <Typography className={classes.subtitle} variant="subtitle2" data-field="metrics-subtitle">
            {formatMessage('subtitle')}
          </Typography>
        </Grid>
        <Grid container spacing={2} alignItems="center" justifyContent="space-between" data-field="card-container">
          {cardData.map((data, i) => {
            return (
              <Grid key={data.messageId} item xs={itemSize}>
                <Card className={classes.card} elevation={1} data-field="card">
                  <data.icon className={classes.cardIcon} data-field="card-icon" />
                  {inProgress && (
                    <Grid container justifyContent="center">
                      <CircularProgress size={CARD_LOADER_SIZE} data-field="card-loader" />
                    </Grid>
                  )}
                  {!inProgress && (
                    <Typography className={classes.cardValue} align="center" data-field="card-value">
                      {data.value?.toLocaleString()}
                    </Typography>
                  )}
                  <Typography className={classes.cardText} align="center" data-field="card-text">
                    {formatMessage(data.messageId)}
                  </Typography>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    ),
    [formatMessage, classes, inProgress, cardData, itemSize]
  )
}

export default Metrics
