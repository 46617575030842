import React from 'react'

import { Link, Typography } from '@barracuda-internal/bds-core'

import { config } from 'global/lib/config'
import { useFormatMessage } from 'global/lib/localization'

const BASE_I18N_KEY = 'fir.app.cta.emails_by_region'

const EmailsByRegionCTA: React.FC<any> = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return (
    <div>
      <Typography variant="body1">
        {formatMessage('description')}
        &nbsp;
        <Link target="_blank" href={config.LINKS.CAMPUS_GEOGRAPHICAL_INSIGHTS}>
          {formatMessage('learn_more')}...
        </Link>
      </Typography>
    </div>
  )
}

export default EmailsByRegionCTA
