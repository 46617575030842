import React, { useMemo } from 'react'

import { Button, Fab, Tooltip } from '@barracuda-internal/bds-core'
import { Download as DownloadIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'

import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'

import useEmailDetailsDialogLogic from 'global/components/lib/dialogs/emailDetailsDialog/useEmailDetailsDialogLogic'
import styles from 'global/components/lib/dialogs/emailDetailsDialog/emailDetailsDialogStyles'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'

import { useFormatMessage } from 'global/lib/localization'

import { EmailPreviewDialogProps } from 'global/components/lib/dialogs/emailDetailsDialog/EmailDetailsDialog'
import EmailAnalysis from 'global/components/lib/dialogs/emailDetailsDialog/components/EmailAnalysis'
import EmailContentDetails, {
  TABS
} from 'global/components/lib/dialogs/emailDetailsDialog/components/emailContentDetails/EmailContentDetails'

import useEmailDetailsLogic from 'global/components/lib/dialogs/emailDetailsDialog/components/emailDetails/useEmailDetailsLogic'

const BASE_I18N_KEY = 'app.email_details_dialog'

export const EmailDetails: React.FC<EmailPreviewDialogProps> = ({
  buttonText,
  data,
  hideReplyTo,
  isExpandRow,
  isGroupedRecipients,
  showThreatTab
}) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [emailInfo, headerDetails, isHtml, onFindMessagesInForensics] = useEmailDetailsDialogLogic({
    data,
    hideReplyTo,
    isGroupedRecipients
  })
  const { analysis, emailDataStatuses, eventHandlers } = data
  const [emailDetailsLogic] = useEmailDetailsLogic()
  const { modalContentRef, showScrollToTopButton, handleScrollToTop } = emailDetailsLogic

  return useMemo(() => {
    return (
      <>
        {emailDataStatuses.inProgress && <LinearProgress />}
        <DialogContent className={classes.dialogContent}>
          {!emailDataStatuses.inProgress && (
            <Grid container direction="row" className={classes.dialogContentContainer}>
              {analysis && <EmailAnalysis analysis={analysis} />}
              <Grid item xs className={classes.emailSection} ref={modalContentRef}>
                <Grid className={classes.emailContentWrapper} container justifyContent="flex-end">
                  <EmailContentDetails
                    borderlessContent
                    emailInfo={emailInfo}
                    isHtml={isHtml}
                    showEmailError={emailDataStatuses.isFailed}
                    disabledTabs={[`${!showThreatTab && TABS.THREAT_DETAILS}`]}
                    headerDetails={headerDetails}
                  />
                  {showScrollToTopButton && (
                    <Tooltip title={formatMessage('back_to_top_tooltip')} placement="left-end">
                      <Fab className={classes.backToTop} onClick={handleScrollToTop}>
                        <ExpandLessIcon />
                      </Fab>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions className={classes.dialogFooter}>
          {!emailDataStatuses.inProgress && eventHandlers.onDownloadEmail && (
            <Button color="primary" size="medium" variant="text" onClick={eventHandlers.onDownloadEmail}>
              <DownloadIcon />
              &nbsp;{formatMessage('download_email')}
            </Button>
          )}

          {!!eventHandlers.onMarkAsFp && !emailDataStatuses.inProgress && !!analysis && !analysis?.isMarkedAsFp && (
            <Button
              className={classes.reportFpButton}
              id="report-fp-button"
              color="primary"
              size="medium"
              variant="text"
              onClick={() => {
                if (analysis.confidenceScore && eventHandlers.onMarkAsFp) {
                  eventHandlers.onMarkAsFp(analysis.attack.id, analysis.confidenceScore)
                }
              }}
            >
              {formatMessage('report_false_positive')}
            </Button>
          )}

          {!emailDataStatuses.inProgress && eventHandlers.onFindMessagesInForensics && (
            <Button color="primary" size="medium" variant="text" onClick={onFindMessagesInForensics}>
              {formatMessage('search_for_similar_messages')}
            </Button>
          )}

          {!isExpandRow && (
            <Button color="primary" size="medium" onClick={eventHandlers.onClose}>
              {buttonText || formatMessage('back')}
            </Button>
          )}
        </DialogActions>
      </>
    )
  }, [
    analysis,
    buttonText,
    classes.backToTop,
    classes.dialogContent,
    classes.dialogContentContainer,
    classes.dialogFooter,
    classes.emailContentWrapper,
    classes.emailSection,
    classes.reportFpButton,
    emailDataStatuses.inProgress,
    emailDataStatuses.isFailed,
    emailInfo,
    eventHandlers,
    formatMessage,
    handleScrollToTop,
    headerDetails,
    isExpandRow,
    isHtml,
    modalContentRef,
    onFindMessagesInForensics,
    showScrollToTopButton,
    showThreatTab
  ])
}

export default EmailDetails
