import React, { forwardRef } from 'react'
import { SwitchLabel, Switch } from '@barracuda-internal/bds-core'
import PropTypes from 'prop-types'
import { useFormatMessage } from 'global/lib/localization'

const BASE_I18N_KEY = 'app.switch'

export interface Props {
  checked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

const YesNoSwitch = forwardRef<null, Props>(
  (props, ref): JSX.Element => {
    const formatMessage = useFormatMessage(BASE_I18N_KEY)
    return (
      <SwitchLabel
        label={props.checked ? formatMessage('yes') : formatMessage('no')}
        control={<Switch color="primary" checked={props.checked} onChange={props.onChange} {...{ ref }} />}
      />
    )
  }
)

YesNoSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}

export default YesNoSwitch
