import { useState, useCallback, Dispatch } from 'react'

export type IsDialogOpened = boolean
export type ToggleDialog = () => void
export type SetIsDialogOpened = Dispatch<boolean>

export interface UseDialogLogic {
  isOpen: IsDialogOpened
  toggle: ToggleDialog
}

export interface DialogProps {
  toggleDialog: ToggleDialog
  isDialogOpened: IsDialogOpened
  setOpenDialog?: SetIsDialogOpened
}

export type DialogLogic = [IsDialogOpened, ToggleDialog, SetIsDialogOpened]

export default function useDialogLogic(): DialogLogic {
  const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false)

  const toggleDialog: () => void = useCallback(() => {
    setIsDialogOpened(!isDialogOpened)
  }, [isDialogOpened])

  return [isDialogOpened, toggleDialog, setIsDialogOpened]
}
