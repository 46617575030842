import React from 'react'
import PropTypes from 'prop-types'

import { v4 as uuidv4 } from 'uuid'

import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid
} from '@barracuda-internal/bds-core'

import FreeTrialDialog from 'ets/components/lib/dialogs/freeTrialDialog/FreeTrialDialog'

import styles from 'ets/components/lib/dialogs/dmarcDialog/dmarcDialogStyles'
import DEFAULTS from 'ets/configs/styles/defaults'
import { useFormatMessage } from 'global/lib/localization'

const BASE_I18N_KEY = 'ets.app.dialogs.dmarc'

export interface DmarcDialogProps {
  open: boolean
  onClose: () => void
  dmarcEntry?: string
  dmarcPolicy?: string
  isDmarcPolicyValid: boolean
}

export const DmarcDialog: React.FC<DmarcDialogProps> = ({
  open,
  onClose,
  dmarcEntry,
  isDmarcPolicyValid,
  dmarcPolicy
}) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  return (
    <div data-field="dialog-container">
      <Dialog
        style={{ zIndex: DEFAULTS.DIALOGS_ZINDEX }}
        classes={{ paper: classes.paper }}
        open={open}
        onClose={onClose}
        data-field="dialog"
      >
        <DialogTitle className={classes.titleWrapper} id="form-dialog-title" data-field="title">
          <Typography className={classes.title}>{formatMessage('title')}</Typography>
        </DialogTitle>
        <DialogContent data-field="content">
          <DialogContentText className={classes.text} data-field="content-text">
            {formatMessage('description')}
          </DialogContentText>

          {dmarcEntry && isDmarcPolicyValid && (
            <Grid
              container
              className={classes.dmarcContainer}
              justifyContent="center"
              data-field="grid-root"
              direction="column"
            >
              <Grid container direction="row">
                <Grid item className={classes.dmarcEntry}>
                  <Typography className={classes.text} align="left" data-field="entry">
                    {formatMessage('your_dmarc_entry')}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography className={classes.redText}>&quot;{dmarcEntry}&quot;</Typography>
                </Grid>
              </Grid>
              <Grid className={classes.dmarcPolicyContainer} container direction="row">
                <Grid item xs={12}>
                  <Typography className={classes.text} align="left" data-field="policy">
                    {formatMessage('policy', {
                      policy: dmarcPolicy,
                      br: () => <br key={uuidv4()} />,
                      b: (text: string) => <b key={text}>{text}</b>
                    })}
                  </Typography>
                </Grid>
              </Grid>
              <DialogContentText className={classes.text} data-field="settings">
                {formatMessage(`${dmarcPolicy}_setting`, {
                  b: (text: string) => <b key={text}>{text}</b>
                })}
              </DialogContentText>
            </Grid>
          )}

          {!dmarcEntry && (
            <DialogContentText className={classes.redText} data-field="not-configured">
              {formatMessage('dmarc_not_configured')}
            </DialogContentText>
          )}

          {dmarcPolicy === 'invalid dmarc' && (
            <DialogContentText className={classes.redText} data-field="invalid">
              {formatMessage('dmarc_not_valid')}
            </DialogContentText>
          )}

          <DialogContentText className={classes.text} data-field="trial">
            {formatMessage('start_a_free_trial')}
          </DialogContentText>
        </DialogContent>
        <DialogActions data-field="dialog-actions">
          <Button color="primary" variant="text" onClick={onClose} data-action="close" data-field="close">
            {formatMessage('close')}
          </Button>
          <FreeTrialDialog />
        </DialogActions>
      </Dialog>
    </div>
  )
}

DmarcDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dmarcEntry: PropTypes.string,
  dmarcPolicy: PropTypes.string,
  isDmarcPolicyValid: PropTypes.bool.isRequired
}

DmarcDialog.defaultProps = {
  dmarcEntry: undefined,
  dmarcPolicy: undefined
}

export default DmarcDialog
