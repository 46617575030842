const REDIRECT_STATUS_CODES = [
  401, // Unauthorized
  403 // Forbidden
]

// TODO
const REDIRECT_URLS_BLOCKLIST = [
  '/signin',
  '/signup',
  '/signin',
  '/signin/otp',
  '/signup',
  '/signupNew',
  '/start-trial',
  '/signin/connect',
  '/missing-requirements/entitlements'
]
const SKIP_API_ENDPOINTS = ['app/configuration', 'analytics/mixpanel-track-event']

export default function responseErrorGenerator(href: string, customErrorHandler?: any) {
  return function responseError(error: any) {
    if (customErrorHandler) {
      customErrorHandler(error)
    }

    const isAllowedApiEndpoint = !SKIP_API_ENDPOINTS.some((endpoint: string) => error?.config?.url?.includes(endpoint))

    // redirect to /signin if any request failed with one of REDIRECT_STATUS_CODES
    if (
      isAllowedApiEndpoint &&
      REDIRECT_STATUS_CODES.includes(error?.status) &&
      !REDIRECT_URLS_BLOCKLIST.includes(window.location.pathname)
    ) {
      window.location.href = href
    }

    return Promise.reject(error)
  }
}
