import { useMemo, useEffect, useCallback, useRef } from 'react'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { getScanStats, getScanThreatLog } from 'global/redux/features/scan/scanSlice'
import { isPending } from 'global/redux/toolkit/api'

import { getEmployeesReport } from 'ets/redux/features/reports/employees/employeesSlice'
import { getThreatsReport } from 'ets/redux/features/reports/threats/threatsSlice'
import { getDomainsReport } from 'ets/redux/features/reports/domains/domainsSlice'
import { getAttacksByDomainReport } from 'ets/redux/features/reports/attacksByDomain/attacksByDomainSlice'
import { getChartsReports } from 'ets/redux/features/reports/chartReport/chartReportSlice'
import { useAppDispatch, useAppSelector } from 'ets/redux/toolkit/hooks'
import { IsUserInputDisabledForTable } from 'ets/components/pages/dashboard/isUserInputDisabledForTableType'

import routesConfig from 'ets/lib/routes/routesConfig'

export interface DashboardLogic {
  error?: string | false
  inProgress: boolean
  scrollToSection: (sectionIndex: number) => void
  isUserInputDisabledForTable: IsUserInputDisabledForTable
  getSectionElement: (section: string) => HTMLElement | null
  fixTableHeight: (tableId: string, newHeight: number) => void
}

export const TABS = {
  OVERVIEW: 'overview',
  EMPLOYEES: 'employees',
  THREATS: 'threats',
  DOMAINS: 'domains',
  SOURCES_OF_ATTACKS: 'attacks'
}

export const APP_HEADER_HEIGHT = 70

export default function useDashboardLogic(): [DashboardLogic] {
  const isScanStartedToLoad = useRef(false)
  const dispatch = useAppDispatch()
  const {
    isStatsInProgress,
    isInitialScanStatsLoaded,
    error,
    isScanInProgress,
    accessTokenId,
    isReportsInProgress
  } = useAppSelector(_stores => ({
    isStatsInProgress: !!_stores.scan.stats?.inProgress,
    isInitialScanStatsLoaded: _stores.scan.isInitialScanStatsLoaded,
    error: _stores.report.error,
    isScanInProgress: _stores.scan.stats?.id ? _stores.scan.stats.inProgress : true,
    accessTokenId: _stores.accessToken.accessToken?.id || '',
    isReportsInProgress: isPending(_stores.reports.employees.apiStatus)
  }))

  // check query params only on init
  useEffect(() => {
    if (window.location.toString().includes(analyticsLib.QUERY_PARAMS.PDF)) {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.REPORT_OPENED_IN_PDF, {
        url: window.location.href
      })
    }
  }, [])

  useEffect(() => {
    // user is logged in but the report is being opened from email
    if (window.location.toString().includes('emailscancomplete')) {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.REPORT_OPENED_IN_EMAIL, {
        url: window.location.href
      })
      routesConfig.DASHBOARD.goto({ reportId: accessTokenId })
    }

    if (!isScanStartedToLoad.current) {
      isScanStartedToLoad.current = true
      dispatch(getScanStats())
      dispatch(getScanThreatLog())
    }

    if (isStatsInProgress)
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.VIEW_SCAN_IN_PROGRESS, { url: window.location.href })

    if (isInitialScanStatsLoaded && !isScanInProgress && !isReportsInProgress) {
      dispatch(getChartsReports({ accessTokenId }))
      dispatch(getEmployeesReport({ accessTokenId }))
      dispatch(getThreatsReport({ accessTokenId }))
      dispatch(getDomainsReport({ accessTokenId }))
      dispatch(getAttacksByDomainReport({ accessTokenId }))

      analyticsLib.trackAppEvent(analyticsLib.EVENTS.DASHBOARD_VIEW, { accessTokenId })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isInitialScanStatsLoaded, isScanInProgress, accessTokenId, isStatsInProgress])

  const inProgress = useMemo(() => {
    return !isInitialScanStatsLoaded
  }, [isInitialScanStatsLoaded])

  const isUserInputDisabledForTable: IsUserInputDisabledForTable = useCallback(
    (tableData, reportData, isReportLoaded) => {
      const { totalCount } = reportData.report
      const isDataFiltered = !!tableData.search.length || !!tableData.filter

      return !isReportLoaded || (isReportLoaded && !totalCount && !isDataFiltered)
    },
    []
  )

  const getSectionElement = useCallback((section: string): HTMLElement | null => {
    return document.getElementsByClassName(`${section}-section`)[0] as HTMLElement | null
  }, [])

  const fixTableHeight = useCallback((tableId: string, newHeight: number) => {
    const table = document.getElementsByClassName(`${tableId}-table`)[0] as HTMLElement | null

    if (table) {
      table.style.height = `${newHeight}px`
    }
  }, [])

  const scrollToSection = useCallback(
    (sectionIndex: number) => {
      const sectionElement = getSectionElement(Object.values(TABS)[sectionIndex])

      if (sectionElement) {
        if ('scrollBehavior' in document.documentElement.style) {
          window.scroll({
            top: sectionElement.offsetTop - APP_HEADER_HEIGHT,
            behavior: 'smooth'
          })
        } else {
          window.scroll(0, sectionElement.offsetTop)
        }
      }
    },
    [getSectionElement]
  )

  return useMemo(
    () => [
      {
        error,
        inProgress,
        scrollToSection,
        isUserInputDisabledForTable,
        getSectionElement,
        fixTableHeight
      }
    ],
    [error, inProgress, scrollToSection, isUserInputDisabledForTable, getSectionElement, fixTableHeight]
  )
}
