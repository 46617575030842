import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Alert, DialogContent, Grid, Radio, RadioGroup, Typography } from '@barracuda-internal/bds-core'

import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'

import { useFormatMessage, useErrorFormatMessage } from 'global/lib/localization'

import BaseDialogActions from 'sen/components/lib/dialogs/newIncidentDialog/lib/BaseDialogActions'
import RecipientsTable from 'sen/components/lib/dialogs/newIncidentDialog/lib/RecipientsTable'

import styles from 'sen/components/lib/dialogs/newIncidentDialog/newIncidentDialogStyles'
import useQuarantineLogic from 'sen/components/lib/dialogs/newIncidentDialog/steps/useQuarantineLogic'

const BASE_I18N_KEY = 'sen.app.new_incident_dialog.step_quarantine'

export interface QuarantineProps {
  onClose: () => void
  activeStep: number
}
const Quarantine: React.FC<QuarantineProps> = ({ activeStep, onClose }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatMessageError = useErrorFormatMessage()
  const classes = styles()

  const [buttonStatus, recipientsConfig, tableConfig, error, radioButtonConfig] = useQuarantineLogic()

  return useMemo(
    () => (
      <Grid container direction="column">
        <DialogContent className={classes.dialogContent}>
          {error && (
            <Alert className={classes.error} variant="filled" severity="error">
              {formatMessageError(error)}
            </Alert>
          )}

          <RecipientsTable
            tableConfig={tableConfig}
            recipientsConfig={recipientsConfig}
            BASE_I18N_KEY={BASE_I18N_KEY}
          />

          {tableConfig.isLoaded && recipientsConfig.hasRecipients && (
            <Grid container data-section="clean-up-selection" direction="column">
              <FormControl>
                <FormLabel>
                  <Typography>{formatMessage('radio_title')}</Typography>
                </FormLabel>
                <RadioGroup
                  value={radioButtonConfig.radioButtonsConfig.selectedRadioButton}
                  onChange={radioButtonConfig.radioButtonsConfig.onSelectRadioButton}
                >
                  {radioButtonConfig.RADIO_VALUES.map((key, index) => (
                    <Grid item xs={12} key={String(key)}>
                      <FormControlLabel
                        className={classes.radioButtonLabels}
                        control={<Radio color="primary" />}
                        label={formatMessage(`radio_select_${index + 1}`)}
                        disabled={recipientsConfig.inProgress}
                        value={String(key)}
                      />
                    </Grid>
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
          )}
        </DialogContent>
        <BaseDialogActions activeStep={activeStep} buttonStatus={buttonStatus} onClose={onClose} />
      </Grid>
    ),
    [
      classes,
      formatMessage,
      formatMessageError,
      activeStep,
      buttonStatus,
      error,
      onClose,
      tableConfig,
      recipientsConfig,
      radioButtonConfig
    ]
  )
}

Quarantine.propTypes = {
  onClose: PropTypes.func.isRequired,
  activeStep: PropTypes.number.isRequired
}

export default Quarantine
