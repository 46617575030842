import { createSlice } from '@reduxjs/toolkit'

import { ThreatEmailsState } from 'ets/redux/types/dataTables'

export const GRID_COLUMNS = {
  RECEIVED: 'received',
  EMPLOYEE: 'employee',
  FROM: 'from',
  SUBJECT: 'subject',
  ATTACK_TYPES: 'attackTypes'
}

export const COLUMNS_CONFIG = {
  [GRID_COLUMNS.RECEIVED]: {
    orderIndex: 0,
    show: true
  },
  [GRID_COLUMNS.EMPLOYEE]: {
    orderIndex: 1,
    show: true
  },
  [GRID_COLUMNS.FROM]: {
    orderIndex: 2,
    show: true
  },
  [GRID_COLUMNS.SUBJECT]: {
    orderIndex: 3,
    show: true
  },
  [GRID_COLUMNS.ATTACK_TYPES]: {
    orderIndex: 4,
    show: true
  }
}

// initialState
export const INITIAL_STATE: ThreatEmailsState = {
  GRID_COLUMNS,
  columnsConfig: COLUMNS_CONFIG
}

export const threatEmailsSlice = createSlice({
  name: 'DATATABLE/THREAT_EMAILS',
  initialState: INITIAL_STATE,
  reducers: {}
})

export default threatEmailsSlice.reducer
