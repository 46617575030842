const API_ROUTES = {
  admin: {
    REVERT_IMPERSONATION: {
      method: 'POST',
      path: '/admin/revert-impersonation'
    }
  },
  analytics: {
    MIXPANEL_TRACK_EVENT: {
      method: 'POST',
      path: '/analytics/mixpanel-track-event'
    }
  },
  auth: {
    SIGNIN: {
      method: 'POST',
      path: '/auth/signin'
    },
    SIGNIN_OTP: {
      method: 'POST',
      path: '/auth/signin/otp'
    },
    GET_USER: {
      method: 'GET',
      path: '/auth/user'
    },
    GET_DEMO_USER: {
      method: 'GET',
      path: '/auth/sentinel-demo'
    },
    UPDATE_STATE: {
      method: 'POST',
      path: '/signup/update-state'
    },
    ACTIVATE_DEVELOPER_INTERFACE: {
      method: 'POST',
      path: '/auth/developer-interface/activate'
    },
    REFRESH_TOKEN: {
      method: 'POST',
      path: '/auth/bcc/refreshToken'
    }
  },
  ato: {
    ALERT_SUMMARY: {
      method: 'POST',
      path: '/report/account-takeover/alerts-summary'
    },
    GET_ALERTS: {
      method: 'POST',
      path: '/report/account-takeover/alerts'
    },
    GET_ALERT_DETAILS: {
      method: 'POST',
      path: '/report/account-takeover/alerts/details'
    },
    CLOSE_ALERT: {
      method: 'POST',
      path: '/report/account-takeover/alerts/close'
    },
    DRILLDOWN_INBOX_RULES: {
      method: 'POST',
      path: '/report/account-takeover/alerts/drilldown-inbox-rules'
    },
    DRILLDOWN_SIGNINS: {
      method: 'POST',
      path: '/report/account-takeover/alerts/drilldown-sign-in'
    },
    ATO_MARK_AS_FP: {
      method: 'POST',
      path: '/report/account-takeover/alerts/mark-as-fp'
    },
    USER_RELATED_SIGNINS: {
      method: 'POST',
      path: '/report/account-takeover/sign-ins/user'
    },
    SIGNINS_BY_ALL_COUNTRIES: {
      method: 'POST',
      path: '/report/account-takeover/sign-ins/all-countries'
    },
    SIGNINS_BY_COUNTRY: {
      method: 'POST',
      path: '/report/account-takeover/sign-ins/country'
    },
    DRILLDOWN_THREATS: {
      method: 'POST',
      path: '/report/account-takeover/alerts/drilldown'
    },
    EXPORT_ALERTS_AS_CSV: {
      method: 'GET',
      path: '/report/account-takeover/alerts-csv?sessionId=:sessionId&accessTokenId=:accessTokenId'
    }
  },
  cloud: {
    REFRESH_O365_ACCESS_TOKEN: {
      method: 'POST',
      path: '/cloud/office365/refresh-access-token'
    }
  },
  cm: {
    CM_STATS: {
      method: 'POST',
      path: '/cm/:provider/stats'
    }
  },
  dmarc: {
    DMARC_COMPANY_DOMAINS: {
      method: 'POST',
      path: '/dmarc/company-domains'
    },
    DMARC_DOMAINS_STATS: {
      method: 'POST',
      path: '/dmarc/domains-stats'
    },
    RESOLVE_DMARC: {
      method: 'POST',
      path: '/report/domain/resolve-dmarc'
    },
    RESOLVE_SPF: {
      method: 'POST',
      path: '/report/domain/resolve-spf'
    },
    SETUP_DMARC: {
      method: 'POST',
      path: '/report/domain/setup-dmarc'
    },
    ADD_DOMAIN: {
      method: 'POST',
      path: '/dmarc/add-domain'
    },
    VERIFY_DOMAIN: {
      method: 'POST',
      path: '/dmarc/verify-domain'
    },
    DELETE_DOMAIN: {
      method: 'POST',
      path: '/dmarc/delete-domain'
    },
    GET_DMARC_FRAUD_UNIFIED_DATA: {
      method: 'POST',
      path: '/dmarc/fraud-data/unified'
    },
    GET_DMARC_FRAUD_UNIFIED_RUF_DATA: {
      method: 'POST',
      path: '/dmarc/fraud-data/unified/ruf'
    },
    GET_RUF_SAMPLE: {
      method: 'POST',
      path: '/dmarc/ruf-sample'
    },
    DOMAIN_TOTALS: {
      method: 'POST',
      path: '/dmarc/domain-totals'
    },
    ALL_DMARC_SENDERS: {
      method: 'POST',
      path: '/dmarc/all-senders'
    },
    MAJOR_DMARC_SENDERS: {
      method: 'POST',
      path: '/dmarc/major-senders'
    },
    MINOR_DMARC_SENDERS: {
      method: 'POST',
      path: '/dmarc/minor-senders'
    },
    APPROVE_ACTIONS_SET_FOR_SENDER: {
      method: 'POST',
      path: '/dmarc/approve-actions/set-for-sender'
    },
    APPROVE_ACTIONS_SET_FOR_VOLUME_GROUP: {
      method: 'POST',
      path: '/dmarc/approve-actions/set-for-volume-group'
    },
    GET_DMARC_DKIM_PASSING_HOSTS: {
      method: 'POST',
      path: '/dmarc/dkim-passing-hosts'
    },
    SUBDOMAIN_SENDERS: {
      method: 'POST',
      path: '/dmarc/subdomain-senders'
    },
    RUF_SAMPLES: {
      method: 'POST',
      path: '/dmarc/ruf-samples'
    }
  },
  identities: {
    GET_IDENTITY_AUTOCOMPLETE: {
      method: 'POST',
      path: '/identities/autocomplete'
    },
    GET_IDENTITY_DETAILS: {
      method: 'POST',
      path: '/identities/details'
    }
  },
  feedback: {
    SEND_FEEDBACK: {
      method: 'POST',
      path: '/feedback/send/:publicKey'
    }
  },
  signup: {
    SIGNUP: {
      method: 'POST',
      path: '/signup/create'
    },
    SIGNUP_FLAGS: {
      method: 'POST',
      path: '/signup/flags'
    },
    SIGNUP_SAVE_PROFILE: {
      method: 'POST',
      path: '/signup/save-profile'
    }
  },
  setting: {
    UPDATE_ACCESS_TOKEN: {
      method: 'POST',
      path: '/settings/access-token/update'
    },
    GET_NOTIFICATION_EMAIL: {
      method: 'POST',
      path: '/settings/notification-email/get'
    },
    SET_NOTIFICATION_EMAIL: {
      method: 'POST',
      path: '/settings/notification-email/set'
    },
    GET_CUSTOM_TEMPLATE: {
      method: 'POST',
      path: '/settings/template/custom/get'
    },
    SAVE_CUSTOM_TEMPLATE: {
      method: 'POST',
      path: '/settings/template/custom/save'
    },
    GET_NOTIFICATION_PREVIEW_EMAIL_BODY: {
      method: 'GET',
      path: '/settings/template/preview/body/get'
    },
    GET_NOTIFICATION_PREVIEW_EMAIL_SUBJECT: {
      method: 'POST',
      path: '/settings/template/preview/subject/get'
    },
    GET_NOTIFICATION_EMAIL_DEFAULTS: {
      method: 'POST',
      path: '/settings/template/default/get'
    }
  },
  sp: {
    COMPANY_DOMAINS: {
      method: 'POST',
      path: '/sp/company-domains'
    },
    CAN_SEND_REPLY_TO: {
      method: 'POST',
      path: '/sp/:provider/can-send-reply-to'
    },
    GET_EMPLOYEE_DEFAULTS: {
      method: 'POST',
      path: '/sp/employee-defaults'
    },
    ATTACKS_PER_DAY: {
      method: 'POST',
      path: '/sp/attacks-per-day'
    },
    ATTACKS_BY_SENDER: {
      method: 'POST',
      path: '/sp/attacks-by-sender'
    },
    ATTACKS_BY_SERVICE: {
      method: 'POST',
      path: '/sp/attacks-by-service'
    },
    ATTACKS_BY_IDENTITY: {
      method: 'POST',
      path: '/sp/attacks-by-identity'
    },
    ATTACKS_BY_DOMAIN: {
      method: 'POST',
      path: '/sp/attacks-by-domain'
    },
    ATTACKS_BY_SUBJECT: {
      method: 'POST',
      path: '/sp/attacks-by-subject'
    },
    ATTACKS_BY_TAXONOMY_PERIODIC: {
      method: 'POST',
      path: '/sp/attacks-by-taxonomy-periodic'
    },
    TYPES_OF_FRAUD: {
      method: 'POST',
      path: '/sp/attacks-by-type'
    },
    GET_MONITORED_IDENTITIES: {
      method: 'POST',
      path: '/sp/monitored-identities'
    },
    GET_ATTACKS: {
      method: 'POST',
      path: '/sp/attacks'
    },
    GET_LIST_FEEDBACK: {
      method: 'POST',
      path: '/sp/list-feedback'
    },
    EXPORT_MAILBOXES_AS_CSV: {
      method: 'GET',
      path: '/sp/mailboxes-csv?sessionId=:sessionId&accessTokenId=:accessTokenId'
    },
    EXPORT_ATTACKS_AS_CSV: {
      method: 'GET',
      path: '/sp/attacks-csv?sessionId=:sessionId&accessTokenId=:accessTokenId'
    },
    SP_ATTACK_DETAILS: {
      method: 'POST',
      path: '/sp/details'
    },
    TEST_ATTACK: {
      method: 'POST',
      path: '/sp/test-attack'
    }
  },
  remediation: {
    ACCOUNT_COMPROMISE_INCIDENTS: {
      method: 'POST',
      path: '/remediation/account-compromise/incidents'
    },
    ACCOUNT_COMPROMISE_INCIDENT_DETAILS: {
      method: 'POST',
      path: '/remediation/account-compromise/incident-details'
    },
    ACCOUNT_COMPROMISE_GET_RECIPIENTS: {
      method: 'POST',
      path: '/remediation/account-compromise/get-recipients'
    },
    ACCOUNT_COMPROMISE_GET_INBOX_RULES: {
      method: 'POST',
      path: '/remediation/account-compromise/get-inbox-rules'
    },
    ACCOUNT_COMPROMISE_DELETE_INBOX_RULE: {
      method: 'POST',
      path: '/remediation/account-compromise/delete-inbox-rule'
    },
    ACCOUNT_COMPROMISE_GET_INBOX_RULE_FEED: {
      method: 'POST',
      path: '/remediation/account-compromise/get-inbox-rule-feed'
    },
    ACCOUNT_COMPROMISE_SEARCH_EMAILS: {
      method: 'POST',
      path: '/remediation/account-compromise/search'
    },
    ACCOUNT_COMPROMISE_GET_EMAIL_BODY: {
      method: 'POST',
      path: '/remediation/account-compromise/email-body'
    },
    ACCOUNT_COMPROMISE_CREATE_INCIDENT: {
      method: 'POST',
      path: '/remediation/account-compromise/create-incident'
    },
    ACCOUNT_COMPROMISE_QUARANTINE_EMAILS: {
      method: 'POST',
      path: '/remediation/account-compromise/quarantine-emails'
    },
    ACCOUNT_COMPROMISE_DELETE_EMAILS: {
      method: 'POST',
      path: '/remediation/account-compromise/delete-emails'
    },
    ACCOUNT_COMPROMISE_NOTIFY_EXTERNAL_EMAILS: {
      method: 'POST',
      path: '/remediation/account-compromise/notify-external-emails'
    },
    ACCOUNT_COMPROMISE_INCIDENT_TASK_STATUS: {
      method: 'POST',
      path: '/remediation/account-compromise/incident-task-status'
    },
    EXPORT_INTERNAL_ATTACKS_AS_CSV: {
      method: 'GET',
      path:
        '/remediation/account-compromise/get-internal-recipients-csv?sessionId=:sessionId&accessTokenId=:accessTokenId&incidentId=:incidentId'
    },
    EXPORT_EXTERNAL_ATTACKS_AS_CSV: {
      method: 'GET',
      path:
        '/remediation/account-compromise/get-external-recipients-csv?sessionId=:sessionId&accessTokenId=:accessTokenId&incidentId=:incidentId'
    }
  },
  report: {
    SCAN_STATUS: {
      method: 'POST',
      path: '/report/:provider/scan-status'
    },
    SIMPLE_SCAN_STATUS: {
      method: 'POST',
      path: '/report/:provider/simple-scan-status'
    }
  },
  syslog: {
    GET_SYSLOG_CONFIG: {
      method: 'POST',
      path: '/settings/syslog/get-config'
    },
    SAVE_SYSLOG_CONFIG: {
      method: 'POST',
      path: '/settings/syslog/save-config'
    },
    TEST_SYSLOG_CONFIG: {
      method: 'POST',
      path: '/settings/syslog/test-config'
    }
  },
  account: {
    GET_SERIAL_WITH_ACCOUNT_ID: {
      method: 'POST',
      path: '/account/get-serial-with-accountId'
    }
  },
  cleanup: {
    CLEANUP_ALL_THREATS: {
      method: 'POST',
      path: '/cleanup/cleanup-all-threats'
    }
  },
  LOGOUT: {
    method: 'POST',
    path: '/logout'
  }
}

export default API_ROUTES
