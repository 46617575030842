import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ReportState {
  error: undefined | string
}

// initialState
export const INITIAL_STATE: ReportState = {
  error: undefined
}

/* eslint-disable no-param-reassign */
export const reportSlice = createSlice({
  name: 'REPORT',
  initialState: INITIAL_STATE,
  reducers: {
    setError: {
      reducer: (state: ReportState, action: PayloadAction<{ error: string }>) => {
        state.error = action.payload.error
      },
      prepare: (error: string) => ({ payload: { error } })
    },

    resetError: (state: ReportState) => {
      state.error = INITIAL_STATE.error
    },

    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  }
})
/* eslint-enable no-param-reassign */

export const { setError, resetError, reset } = reportSlice.actions

export default reportSlice.reducer
