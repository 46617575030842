/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react'

import { Typography, DataTable, DataTableColumn, Button, Grid, Paper, Tooltip, Box } from '@barracuda-internal/bds-core'
import { Assignment as AssignmentIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'
import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'

import AlertComponent from '@material-ui/lab/Alert'
import { useErrorFormatMessage, useFormatMessage } from 'global/lib/localization'
import CircularProgress from 'global/components/lib/circularProgress/CircularProgress'
import ExportToCsvButton from 'global/components/lib/exportToCsvButton/ExportToCsvButton'
import Pager from 'global/components/lib/grid/pager/Pager'
import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'
import EmailCell from 'global/components/lib/grid/cell/customCells/EmailCell'
import CellLabelType from 'global/components/lib/grid/cell/CellLabelType'
import StatusTypeLabel from 'global/components/lib/statusTypeLabel/StatusTypeLabel'
import { StatusIds } from 'global/components/lib/statusTypeLabel/StatusIds.enum'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import { Alert, ModifiedAlert } from 'global/types/api/ato'

import AlertDialog from 'sen/components/lib/dialogs/alertDialog/AlertDialog'
import useAlertsLogic from 'sen/components/pages/ato/content/tabs/alerts/useAlertsLogic'

import NewIncidentDialog from 'sen/components/lib/dialogs/newIncidentDialog/NewIncidentDialog'
import NewIncidentDialogWizard from 'sen/components/lib/dialogs/newIncidentDialog/NewIncidentDialogWizard'
import useNewIncidentDialogLogic from 'sen/components/lib/dialogs/newIncidentDialog/useNewIncidentDialogLogic'

import ResetPasswordDialog from 'sen/components/lib/dialogs/newIncidentDialog/resetPasswordDialog/ResetPasswordDialog'
import ResetPasswordDoneDialog from 'sen/components/lib/dialogs/newIncidentDialog/resetPasswordDialog/ResetPasswordDoneDialog'

import styles, { CELL_HEIGHT } from 'sen/components/pages/ato/content/tabs/tabStyles'

const BASE_I18N_KEY = 'sen.app.account_takeover.alerts'
const BASE_I18N_TABLE_KEY = 'sen.app.data_tables.alerts'

const Alerts: React.FC = () => {
  const [
    inProgress,
    tableConfig,
    alertDialogConfig,
    createIncidentDialogConfig,
    resetPasswordDialogConfig,
    error
  ] = useAlertsLogic()
  const [newIncidentDialogConfig, newIncidentDialogWizardConfig] = useNewIncidentDialogLogic()

  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const tableFormatMessage = useFormatMessage(BASE_I18N_TABLE_KEY)
  const formatMessageError = useErrorFormatMessage()
  const classes = styles()

  return useMemo(
    () => (
      <Grid container data-section="alerts" direction="column" data-field="alerts-grid">
        {error && (
          <AlertComponent variant="filled" severity="error" data-testid="alerts-alert">
            {formatMessageError(error)}
          </AlertComponent>
        )}
        <Grid item data-field="alerts-title">
          <Typography className={classes.title} data-field="alerts-table-title">
            {formatMessage('title')}
          </Typography>
          <Typography className={classes.subtitle} variant="subtitle2" data-field="alerts-table-subtitle">
            {formatMessage('subtitle')}
          </Typography>
        </Grid>
        <Grid item data-field="alerts-table-grid-paper">
          <Paper className={classes.tableCard} elevation={0} data-field="alerts-table-paper">
            {!tableConfig.isLoaded && (
              <Grid container justifyContent="center" className={classes.tableCircularProgress}>
                <CircularProgress data-field="loader" />
              </Grid>
            )}

            {tableConfig.isLoaded && (
              <Grid container direction="column" className={classes.tableWrapper} data-field="alerts-table-wrapper">
                <Grid container justifyContent="flex-end">
                  <ExportToCsvButton {...tableConfig.exportToCsvConfig} />
                </Grid>

                <Grid
                  className={`${tableConfig.isFlexibleTable ? classes.flexibleTableArea : classes.tableArea} ${
                    !tableConfig.tableData.total ? 'empty-table' : ''
                  }`}
                  data-table="alerts"
                  item
                  xs={12}
                  data-field="grid-table"
                >
                  {inProgress && <LinearProgress className={classes.indicator} data-field="linear-progress" />}
                  <DataTable
                    data-testid="alerts-table"
                    className={`${
                      tableConfig.tableData.total
                        ? tableConfig.isFlexibleTable
                          ? classes.flexibleDataTable
                          : classes.dataTable
                        : classes.emptyDataTable
                    } alerts-table`}
                    data={tableConfig.tableData}
                    {...tableConfig.pageConfig}
                    {...tableConfig.sortConfig}
                    pager={Pager}
                    rowRender={(trElement, props) => {
                      const trProps = {
                        ...(props.dataItem.alertStatus === StatusIds.olderThank30days && {
                          className: `${trElement.props.className} faded-row`
                        })
                      }

                      return React.cloneElement(trElement, { ...trProps }, trElement.props.children)
                    }}
                  >
                    <DataTableNoRecords data-field="empty-table">
                      <Typography>{!tableConfig.tableData.total && tableFormatMessage('empty_table')}</Typography>
                    </DataTableNoRecords>
                    <DataTableColumn
                      width={110}
                      field={tableConfig.columns.DATE}
                      {...tableConfig.columnsConfig[tableConfig.columns.DATE]}
                      title={tableFormatMessage(tableConfig.columns.DATE)}
                      cell={({ dataItem }: { dataItem: ModifiedAlert }) => (
                        <Cell className={classes.cell} data-field="table-column-date">
                          <CellText className={classes.normalCell} data-field="table-column-date-text">
                            {dataItem.formattedDate}
                          </CellText>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      field={tableConfig.columns.ACCOUNT_TAKEN_OVER}
                      {...tableConfig.columnsConfig[tableConfig.columns.ACCOUNT_TAKEN_OVER]}
                      title={tableFormatMessage(tableConfig.columns.ACCOUNT_TAKEN_OVER)}
                      cell={({ dataItem }: { dataItem: ModifiedAlert }) => (
                        <EmailCell
                          CELL_HEIGHT={CELL_HEIGHT}
                          name={dataItem.displayName}
                          email={dataItem.emailAddress}
                          data-field="table-column-email"
                        />
                      )}
                    />
                    <DataTableColumn
                      field={tableConfig.columns.ACTIVITY}
                      {...tableConfig.columnsConfig[tableConfig.columns.ACTIVITY]}
                      title={tableFormatMessage(tableConfig.columns.ACTIVITY)}
                      cell={({ dataItem }: { dataItem: ModifiedAlert }) => (
                        <Cell className={classes.cell} data-field="table-column-activity">
                          <CellText
                            ellipsis
                            className={classes.lightCell}
                            data-field="table-column-activity-text"
                            data-sample-subject={dataItem.sampleSubject || ''}
                            data-sample-login-id={dataItem.sampleLoginId || ''}
                            data-sample-rule-id={dataItem.sampleRuleId || ''}
                          >
                            {dataItem.sampleSubject && (
                              <>
                                {tableFormatMessage('activities.sample_subject')}
                                <br />
                              </>
                            )}
                            {dataItem.sampleLoginId && (
                              <>
                                {tableFormatMessage('activities.sample_login_id')}
                                <br />
                              </>
                            )}
                            {dataItem.sampleRuleId && (
                              <>
                                {tableFormatMessage('activities.sample_rule_id')}
                                <br />
                              </>
                            )}
                          </CellText>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      width={200}
                      field={tableConfig.columns.STATUS}
                      {...tableConfig.columnsConfig[tableConfig.columns.STATUS]}
                      title={tableFormatMessage(tableConfig.columns.STATUS)}
                      cell={({ dataItem }: { dataItem: ModifiedAlert }) => (
                        <Cell className={classes.cell} data-field="table-column-alerts">
                          <CellLabelType>
                            <span
                              className={classes.domDataForAlertStatus}
                              data-field="table-column-status-label"
                              data-status={dataItem.status}
                              data-marked-as-fp={dataItem.markedAsFp}
                            />
                            <StatusTypeLabel status={dataItem.alertStatus} />
                          </CellLabelType>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      width={100}
                      headerClassName={classes.centerAlignedText}
                      field={tableConfig.columns.DETAILS}
                      {...tableConfig.columnsConfig[tableConfig.columns.DETAILS]}
                      title=" "
                      cell={({ dataItem }: { dataItem: ModifiedAlert }) => (
                        <Cell className={classes.cell} data-field="table-column-details">
                          <Grid container direction="row" justifyContent="center">
                            {dataItem.alertStatus === StatusIds.notReviewed && (
                              <Button
                                color="primary"
                                variant="text"
                                data-field="open-details-button"
                                onClick={() => {
                                  tableConfig.onOpenDetails(dataItem, false)
                                }}
                              >
                                {tableFormatMessage('review')}
                              </Button>
                            )}
                            {dataItem.alertStatus !== StatusIds.notReviewed && (
                              <Tooltip
                                classes={{ tooltip: classes.tooltip }}
                                title={tableFormatMessage('details')}
                                placement="top-start"
                                data-field="table-column-details-tooltip"
                              >
                                <Box>
                                  <AssignmentIcon
                                    data-testid={`more-details-${dataItem.id}`}
                                    onClick={() => {
                                      tableConfig.onOpenDetails(dataItem)
                                    }}
                                    className={classes.icon}
                                    data-field="open-details-button-tooltip"
                                    data-action="details-dialog-open"
                                  />
                                </Box>
                              </Tooltip>
                            )}
                          </Grid>
                        </Cell>
                      )}
                    />
                  </DataTable>
                </Grid>
              </Grid>
            )}
          </Paper>
        </Grid>
        {alertDialogConfig.open && alertDialogConfig.alert && (
          <AlertDialog
            alert={alertDialogConfig.alert}
            onClose={alertDialogConfig.onClose}
            onOpenCreateIncidentDialog={(alert: Alert) => {
              createIncidentDialogConfig.onOpen(alert)
              alertDialogConfig.onClose()
            }}
          />
        )}
        {newIncidentDialogConfig.open && (
          <NewIncidentDialog
            cleanupMessages={newIncidentDialogWizardConfig.onOpen}
            onClose={newIncidentDialogConfig.onClose}
          />
        )}
        {newIncidentDialogWizardConfig.open && (
          <NewIncidentDialogWizard onClose={newIncidentDialogWizardConfig.onClose} />
        )}

        {createIncidentDialogConfig.open && (
          <ResetPasswordDialog
            onClose={createIncidentDialogConfig.onClose}
            onOpenNewIncidentDialog={() => {
              newIncidentDialogConfig.onOpen()
              createIncidentDialogConfig.onClose()
            }}
          />
        )}

        {resetPasswordDialogConfig.open && (
          <ResetPasswordDoneDialog
            onClose={resetPasswordDialogConfig.onClose}
            onOpenNewIncidentDialog={() => {
              newIncidentDialogWizardConfig.onOpen()
              resetPasswordDialogConfig.onClose()
            }}
          />
        )}
      </Grid>
    ),
    [
      classes,
      formatMessage,
      inProgress,
      tableConfig,
      alertDialogConfig,
      tableFormatMessage,
      newIncidentDialogConfig,
      newIncidentDialogWizardConfig,
      createIncidentDialogConfig,
      resetPasswordDialogConfig,
      error,
      formatMessageError
    ]
  )
}

export default Alerts
