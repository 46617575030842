import Joi from 'joi'
import { GetDmarcDkimPassingHostsResponse } from 'sen/redux/features/dmarc/dmarcTypes'

export const validateDmarcDkimPassingHostsResponse = (payload: any): GetDmarcDkimPassingHostsResponse => {
  const schema = Joi.object().keys({
    hosts: Joi.object()
      .keys({
        count: Joi.number().required(),
        next: Joi.string().allow(null),
        previous: Joi.string().allow(null),
        results: Joi.array()
          .items(Joi.object().keys({ host: Joi.string().required() }))
          .required()
      })
      .required()
  })
  Joi.assert(payload, schema)

  return payload as GetDmarcDkimPassingHostsResponse
}
