import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import reducerHandlers from 'global/redux/features/dataTables/reducerHandlers'
import { UpdateDataTable } from 'global/types/dataTables/dataTables'

import { DataTableState, GridColumns } from 'admin/redux/types/dataTables'

export const GRID_COLUMNS: GridColumns = {
  CREATED_ON: 'created_on',
  ADMIN: 'admin',
  OFFICE_365_NAME: 'office_365_name'
}

export const SORT_FIELDS = {
  [GRID_COLUMNS.CREATED_ON]: 'createdOn'
}

export const COLUMNS_CONFIG = {
  [GRID_COLUMNS.CREATED_ON]: {
    sortable: true,
    orderIndex: 0,
    show: true
  },
  [GRID_COLUMNS.ADMIN]: {
    sortable: false,
    orderIndex: 1,
    show: true
  },
  [GRID_COLUMNS.OFFICE_365_NAME]: {
    sortable: false,
    orderIndex: 2,
    show: true
  }
}

const ITEMS_PER_PAGE = 10
const ITEMS_PER_REQUEST = 10

// initialState
export const INITIAL_STATE: DataTableState = {
  SORT_FIELDS,
  GRID_COLUMNS,
  ITEMS_PER_REQUEST,
  ITEMS_PER_PAGE,
  columnsConfig: COLUMNS_CONFIG,
  skip: 0, // offset
  take: ITEMS_PER_PAGE, // limit
  filter: null,
  search: '',
  sort: [{ field: GRID_COLUMNS.CREATED_ON, dir: 'desc' }]
}

export const dfpAccessTokensSlice = createSlice({
  name: 'DATA_TABLE/DFP_ACCESS_TOKENS',
  initialState: INITIAL_STATE,
  reducers: {
    update: {
      reducer: (state: DataTableState, action: PayloadAction<{ config: UpdateDataTable }>) => {
        return reducerHandlers({ COLUMNS_CONFIG }).update({ state, payload: action.payload })
      },
      prepare: (config: UpdateDataTable) => ({
        payload: { config }
      })
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  }
})

export const { update, reset } = dfpAccessTokensSlice.actions

export default dfpAccessTokensSlice.reducer
