import React, { useMemo } from 'react'

import { Grid, Typography, Paper, Box, Button, Alert, List, ListItem } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'global/lib/localization'
import CircularProgress from 'global/components/lib/circularProgress/CircularProgress'

import styles from 'sen/components/pages/domainFraudDmarcReview/content/domainFraudDmarcReviewContentStyles'
import DmarcEnforcementWizardDialog from 'sen/components/lib/dialogs/dmarcEnforcementWizard/DmarcEnforcementWizardDialog'
import localStyles from 'sen/components/pages/domainFraudDmarcReview/content/sections/DmarcReviewYourDnsConfiguration/dmarcReviewYourDnsConfigurationStyles'
import { useDmarcReviewYourDnsConfigurationLogic as useLogic } from 'sen/components/pages/domainFraudDmarcReview/content/sections/DmarcReviewYourDnsConfiguration/useDmarcReviewYourDnsConfigurationLogic'
import { DmarcReviewYourDnsConfigurationProps } from 'sen/components/pages/domainFraudDmarcReview/content/sections/DmarcReviewYourDnsConfiguration/dmarcReviewYourDnsConfigurationTypes'

export const BASE_I18N_KEY = 'sen.app.domain_fraud_dmarc_review.your_dns'

const DmarcReviewYourDnsConfiguration: React.FC<DmarcReviewYourDnsConfigurationProps> = ({ companyDomain }) => {
  const classes = styles()
  const localClasses = localStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [{ isFetchDone, alertMessage }, dmarcBlock, spfBlock, dkimBlock, actionButton, enforcementWizard] = useLogic(
    companyDomain,
    BASE_I18N_KEY
  )

  return useMemo(() => {
    return (
      <Grid className={classes.container} container direction="column" data-field="root-grid">
        <Grid container direction="column">
          <Typography className={classes.title} data-field="dmarc-review-dns-config-title">
            {formatMessage('title')}
          </Typography>
        </Grid>
        <Paper className={`${classes.sectionPaper} ${localClasses.dnsSection}`}>
          {alertMessage && (
            <Alert className={classes.error} severity="error" data-field="dmarc-dns-config-alert">
              {alertMessage}
            </Alert>
          )}
          {!isFetchDone && (
            <Grid container className={localClasses.progressWrapper}>
              <CircularProgress data-field="dmarc-dns-config-loader" />
            </Grid>
          )}
          {isFetchDone && !alertMessage && (
            <>
              <Grid
                container
                direction="column"
                className={localClasses.dmarcDnsBlock}
                data-block-type="DMARC"
                data-field="DMARC"
              >
                <Grid item xs={12} className={localClasses.dmarcDnsTitle} data-block-field="title" data-testid="title">
                  {dmarcBlock.title}
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={localClasses.dmarcDnsRecord}
                  data-block-field="record-text"
                  data-testid="record-text"
                >
                  {dmarcBlock.record}
                </Grid>
              </Grid>
              <Grid
                container
                direction="column"
                className={localClasses.dmarcDnsBlock}
                data-block-type="SPF"
                data-field="SPF"
              >
                <Grid item xs={12} className={localClasses.dmarcDnsTitle} data-block-field="title" data-testid="title">
                  {spfBlock.title}
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={localClasses.dmarcDnsRecord}
                  data-block-field="record-text"
                  data-testid="record-text"
                >
                  {spfBlock.record}
                </Grid>
              </Grid>
              <Grid
                container
                direction="column"
                className={localClasses.dmarcDnsBlock}
                data-block-type="DKIM"
                data-field="DKIM"
              >
                <Grid item xs={12} className={localClasses.dmarcDnsTitle} data-block-field="title" data-testid="title">
                  {dkimBlock.title}
                </Grid>
                <Grid item xs={12} className={localClasses.dmarcDnsRecord}>
                  <Typography className={localClasses.dmarcDnsRecord} data-block-field="header" data-testid="header">
                    {dkimBlock.record.header}
                  </Typography>
                  {!!dkimBlock.record.list.length && (
                    <List data-block-field="list" data-testid="list">
                      {dkimBlock.record.list.map(item => (
                        <ListItem
                          className={localClasses.recordListItem}
                          data-block-field="item"
                          data-testid="item"
                          key={item}
                        >
                          {item}
                        </ListItem>
                      ))}
                    </List>
                  )}
                </Grid>
              </Grid>
            </>
          )}
          {isFetchDone && !alertMessage && actionButton && (
            <Box className={localClasses.actionButtons}>
              <Button
                color="primary"
                size="large"
                variant="text"
                onClick={actionButton.onClick}
                data-field="dmarc-dns-config-action-button"
              >
                {actionButton.label}
              </Button>
            </Box>
          )}
        </Paper>
        {enforcementWizard.isOpen && enforcementWizard.goalConfig[1] && (
          <DmarcEnforcementWizardDialog
            onClose={enforcementWizard.onClose}
            domain={enforcementWizard.domain}
            goal={enforcementWizard.goalConfig[0]}
          />
        )}
      </Grid>
    )
  }, [
    actionButton,
    alertMessage,
    classes.container,
    classes.error,
    classes.sectionPaper,
    classes.title,
    dkimBlock.record.header,
    dkimBlock.record.list,
    dkimBlock.title,
    dmarcBlock.record,
    dmarcBlock.title,
    enforcementWizard.domain,
    enforcementWizard.goalConfig,
    enforcementWizard.isOpen,
    enforcementWizard.onClose,
    formatMessage,
    isFetchDone,
    localClasses.actionButtons,
    localClasses.dmarcDnsBlock,
    localClasses.dmarcDnsRecord,
    localClasses.dmarcDnsTitle,
    localClasses.dnsSection,
    localClasses.progressWrapper,
    localClasses.recordListItem,
    spfBlock.record,
    spfBlock.title
  ])
}

export default DmarcReviewYourDnsConfiguration
