import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient from 'global/lib/api/restClient'
import { globalApiRoutes } from 'global/lib/api/apiRoutes'
import { updateCurrentAccessToken } from 'global/redux/features/accessToken/accessTokenSlice'
import { setError } from 'global/redux/features/report/reportSlice'
import { config } from 'global/lib/config'

export interface GetScanStatus {
  provider?: string
  accessTokenId: string | undefined
  updateAccessToken?: boolean
}

export interface GetScanStats {
  provider?: string
}

export const getScanStatus = createAsyncThunk('SCAN/getScanStatus', async function doGetScanStatus(
  payload: GetScanStatus | undefined,
  { rejectWithValue, dispatch }
) {
  try {
    const { accessTokenId, provider = config.CLOUD_PROVIDERS.office365.id, updateAccessToken } = payload || {}

    const resp = await restClient(globalApiRoutes.SCAN_STATUS, {
      urlParams: { provider },
      data: { accessTokenId, withEtsStats: true, withThreatLogInfo: true }
    })

    if (updateAccessToken) {
      dispatch(updateCurrentAccessToken(resp.data.accessToken))
    }

    return resp.data
  } catch (e) {
    dispatch(setError('unable_to_load_scan'))

    return rejectWithValue('unable_to_load_scan')
  }
})

export const getScanStats = createAsyncThunk('SCAN/getScanStats', async function doGetScanStats(
  payload: GetScanStats | undefined,
  { rejectWithValue, dispatch }
) {
  try {
    const { provider = config.CLOUD_PROVIDERS.office365.id } = payload || {}

    const resp = await restClient(globalApiRoutes.SCAN_STATS, {
      urlParams: { provider },
      data: {}
    })

    return resp.data.totals
  } catch (e) {
    dispatch(setError('unable_to_load_scan'))

    return rejectWithValue('unable_to_load_scan')
  }
})

export const getScanThreatLog = createAsyncThunk('SCAN/getScanThreatLog', async function doGetScanThreatLog(
  _,
  { rejectWithValue, dispatch }
) {
  try {
    const resp = await restClient(globalApiRoutes.SCAN_THREAT_LOG, { data: {} })

    return resp.data.scanThreatLog.data
  } catch (e) {
    dispatch(setError('unable_to_load_threats'))

    return rejectWithValue('unable_to_load_threats')
  }
})
