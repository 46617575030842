import { useMemo } from 'react'

import { config } from 'global/lib/config'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'

import { useAppSelector, useAppDispatch } from 'sen/redux/toolkit/hooks'
import { NewIncidentDialogProps } from 'sen/components/lib/dialogs/newIncidentDialog/NewIncidentDialog'
import { NewIncidentDialogWizardProps } from 'sen/components/lib/dialogs/newIncidentDialog/NewIncidentDialogWizard'
import { resetCompromisedAccount } from 'sen/redux/features/ato/atoSlice'
import {
  resetSearchForIncidentEmails,
  resetCreateIncident,
  resetRecipients
} from 'sen/redux/features/remediation/remediationSlice'

export interface NewIncidentDialogConfig extends NewIncidentDialogProps {
  onOpen: () => void
  onOpenOfficeAdminPortal: () => void
  onResetCompromisedAccount: () => void
}

export interface NewIncidentDialogWizardConfig extends NewIncidentDialogWizardProps {
  onOpen: () => void
}

export default function useNewIncidentDialogLogic(): [NewIncidentDialogConfig, NewIncidentDialogWizardConfig] {
  const { accessTokenId } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id
  }))
  const [isNewIncidentDialogOpened, toggleNewIncidentDialog] = useDialogLogic()
  const [isNewIncidentDialogWizardOpened, toggleNewIncidentDialogWizard] = useDialogLogic()

  const dispatch = useAppDispatch()

  const newIncidentDialogConfig: NewIncidentDialogConfig = useMemo(
    () => ({
      open: isNewIncidentDialogOpened,
      onOpen: () => {
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.ACCOUNT_COMPROMISE_NEW_INCIDENT, {
          accessTokenId,
          url: window.location.href
        })
        toggleNewIncidentDialog()
      },
      onOpenOfficeAdminPortal: () => {
        window.open(config.LINKS.OFFICE_ADMIN_PORTAL, '_blank')
      },
      onClose: () => {
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.ACCOUNT_COMPROMISE_INCIDENT_CLOSE, {
          accessTokenId,
          url: window.location.href
        })

        toggleNewIncidentDialog()
      },
      onResetCompromisedAccount: () => {
        dispatch(resetCompromisedAccount())
      }
    }),
    [isNewIncidentDialogOpened, toggleNewIncidentDialog, accessTokenId, dispatch]
  )

  const newIncidentDialogWizardConfig: NewIncidentDialogWizardConfig = useMemo(
    () => ({
      open: isNewIncidentDialogWizardOpened,
      onOpen: () => {
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.ACCOUNT_COMPROMISE_INCIDENT_WIZARD_CLEAN_UP, {
          accessTokenId,
          url: window.location.href
        })
        toggleNewIncidentDialog()
        toggleNewIncidentDialogWizard()
      },
      onClose: (activeStep?: number | undefined) => {
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.ACCOUNT_COMPROMISE_INCIDENT_WIZARD_CLOSE, {
          accessTokenId,
          url: window.location.href,
          activeStep
        })

        // reset the new incident form when we close the wizard
        dispatch(resetSearchForIncidentEmails())
        dispatch(resetCreateIncident())
        dispatch(resetCompromisedAccount())
        dispatch(resetRecipients())

        toggleNewIncidentDialogWizard()
      }
    }),
    [isNewIncidentDialogWizardOpened, accessTokenId, toggleNewIncidentDialog, toggleNewIncidentDialogWizard, dispatch]
  )

  return useMemo(() => {
    return [newIncidentDialogConfig, newIncidentDialogWizardConfig]
  }, [newIncidentDialogConfig, newIncidentDialogWizardConfig])
}
