import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  listItem: {
    marginBottom: theme.spacing(1)
  },
  wrapper: {
    marginTop: theme.spacing(2)
  }
}))
