import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  card: {
    position: 'relative',
    height: 190,
    marginTop: 54,
    minWidth: 960,
    textAlign: 'center',
    paddingTop: 32,
    width: 1200
  },
  connect0365: {
    height: 40,
    marginBottom: 32,
    marginTop: 16,
    width: 276
  },
  text0365: {
    color: theme.palette.info.contrastText
  },
  text: {
    lineHeight: '19px',
    fontSize: 16,
    color: 'theme.palette.text.secondary'
  }
}))
