import React, { useMemo } from 'react'

import { Dialog, DialogTitle, Stepper, Step, StepLabel, Alert, Typography } from '@barracuda-internal/bds-core'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import { useErrorFormatMessage } from 'global/lib/localization'

import DEFAULTS from 'global/configs/theme/defaults'

import useDomainWizardDialogLogic from 'sen/components/lib/dialogs/domainWizardDialog/useDomainWizardDialogLogic'
import {
  DomainWizardDialogProps,
  WizardSteps
} from 'sen/components/lib/dialogs/domainWizardDialog/domainWizardDialogTypes'
import styles from 'sen/components/lib/dialogs/domainWizardDialog/domainWizardDialogStyles'
import DomainWizardStepInit from 'sen/components/lib/dialogs/domainWizardDialog/steps/DomainWizardStepInit'
import DomainWizardStepSpfCheck from 'sen/components/lib/dialogs/domainWizardDialog/steps/DomainWizardStepSpfCheck'
import DomainWizardStepSpfCheckFailure from 'sen/components/lib/dialogs/domainWizardDialog/steps/DomainWizardStepSpfCheckFailure'
import DomainWizardStepSpfCheckSuccess from 'sen/components/lib/dialogs/domainWizardDialog/steps/DomainWizardStepSpfCheckSuccess'
import DomainWizardStepDmarcPrep from 'sen/components/lib/dialogs/domainWizardDialog/steps/DomainWizardStepDmarcPrep'
import DomainWizardStepDmarcSetup from 'sen/components/lib/dialogs/domainWizardDialog/steps/DomainWizardStepDmarcSetup'
import DomainWizardStepDmarcCheck from 'sen/components/lib/dialogs/domainWizardDialog/steps/DomainWizardStepDmarcCheck'
import DomainWizardStepDmarcInvalid from 'sen/components/lib/dialogs/domainWizardDialog/steps/DomainWizardStepDmarcInvalid'
import DomainWizardStepDmarcNotFound from 'sen/components/lib/dialogs/domainWizardDialog/steps/DomainWizardStepDmarcNotFound'
import DomainWizardStepDmarcCheckSuccess from 'sen/components/lib/dialogs/domainWizardDialog/steps/DomainWizardStepDmarcCheckSuccess'

export const AddDomainWizardDialog: React.FC<DomainWizardDialogProps> = ({ onClose, domain }) => {
  const [logicConfig, formEvents] = useDomainWizardDialogLogic({ onClose, domain })
  const errorFormatMessage = useErrorFormatMessage()
  const classes = styles()

  return useMemo(
    () => (
      <Dialog style={{ zIndex: DEFAULTS.DIALOGS_ZINDEX }} maxWidth="md" fullWidth open data-dialog-type="domain-wizard">
        <Stepper className={classes.stepper} activeStep={logicConfig.stepperStep} alternativeLabel>
          {[...Array(3).keys()].map(label => (
            <Step key={label}>
              <StepLabel />
            </Step>
          ))}
        </Stepper>

        {logicConfig.inProgress && <LinearProgress />}

        <DialogTitle>
          {logicConfig.error && (
            <Alert className={classes.alert} severity="error" data-field="domain-wizard-alert">
              {errorFormatMessage(logicConfig.error)}
            </Alert>
          )}

          <Typography className={classes.dialogTitle}>{domain.name}</Typography>
        </DialogTitle>

        {logicConfig.wizardStep === WizardSteps.init && (
          <DomainWizardStepInit config={logicConfig} events={formEvents} domain={domain} />
        )}

        {logicConfig.wizardStep === WizardSteps.spfCheck && (
          <DomainWizardStepSpfCheck config={logicConfig} events={formEvents} domain={domain} />
        )}

        {logicConfig.wizardStep === WizardSteps.spfCheckFailure && (
          <DomainWizardStepSpfCheckFailure config={logicConfig} events={formEvents} domain={domain} />
        )}

        {logicConfig.wizardStep === WizardSteps.spfCheckSuccess && (
          <DomainWizardStepSpfCheckSuccess config={logicConfig} events={formEvents} domain={domain} />
        )}

        {logicConfig.wizardStep === WizardSteps.dmarcPrep && (
          <DomainWizardStepDmarcPrep config={logicConfig} events={formEvents} domain={domain} />
        )}

        {logicConfig.wizardStep === WizardSteps.dmarcSetup && (
          <DomainWizardStepDmarcSetup config={logicConfig} events={formEvents} domain={domain} />
        )}

        {logicConfig.wizardStep === WizardSteps.dmarcCheck && (
          <DomainWizardStepDmarcCheck config={logicConfig} events={formEvents} domain={domain} />
        )}

        {logicConfig.wizardStep === WizardSteps.dmarcInvalid && (
          <DomainWizardStepDmarcInvalid config={logicConfig} events={formEvents} domain={domain} />
        )}

        {logicConfig.wizardStep === WizardSteps.dmarcNotFound && (
          <DomainWizardStepDmarcNotFound config={logicConfig} events={formEvents} domain={domain} />
        )}

        {logicConfig.wizardStep === WizardSteps.dmarcCheckSuccess && (
          <DomainWizardStepDmarcCheckSuccess config={logicConfig} events={formEvents} domain={domain} />
        )}
      </Dialog>
    ),
    [logicConfig, formEvents, classes, errorFormatMessage, domain]
  )
}

export default AddDomainWizardDialog
