import { createSlice } from '@reduxjs/toolkit'

import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'global/redux/toolkit/api'

import {
  getRemediationEmailThreatsStats,
  getRemediationIncidentsStats,
  getRemediationTopUsersStats,
  getUserReportedTopReportersStats,
  getWorkflowRunsStats,
  getWorkflowTopRunsStats
} from 'fir/redux/features/stats/statsApiThunks'

import {
  EmailThreatsStat,
  IncidentsStat,
  TopUserReportedStat,
  TopUserStat,
  WorkflowRunsStat,
  WorkflowTopRunsStat
} from 'fir/redux/types/Stats'

export interface StatsState {
  emailThreatsStats: EmailThreatsStat[]
  incidentsStats: IncidentsStat[]
  automatedWorkflowRunsStatsApiStatus: ApiStatus
  automatedWorkflowsTopRunsStatsApiStatus: ApiStatus
  emailThreatsStatsApiStatus: ApiStatus
  incidentsStatsApiStatus: ApiStatus
  topUsersApiStatus: ApiStatus
  userReportedStatsApiStatus: ApiStatus
  topUsersStats: TopUserStat[]
  topWorkflowsStats: WorkflowTopRunsStat[]
  userReportedUserStats: TopUserReportedStat[]
  workflowRunsStats: WorkflowRunsStat[]
}

// Initial state
export const INITIAL_STATE: StatsState = {
  emailThreatsStats: [],
  incidentsStats: [],
  automatedWorkflowRunsStatsApiStatus: inIdle,
  automatedWorkflowsTopRunsStatsApiStatus: inIdle,
  emailThreatsStatsApiStatus: inIdle,
  incidentsStatsApiStatus: inIdle,
  topUsersApiStatus: inIdle,
  userReportedStatsApiStatus: inIdle,
  topUsersStats: [],
  topWorkflowsStats: [],
  userReportedUserStats: [],
  workflowRunsStats: []
}

/* eslint-disable no-param-reassign */
export const statsSlice = createSlice({
  name: 'STATS',
  initialState: INITIAL_STATE,
  // reducers object creates an action and executes the reducer function
  reducers: {
    reset: () => {
      return { ...INITIAL_STATE }
    },
    resetRemediationStatFailures: (state: StatsState) => {
      return {
        ...state,
        emailThreatsStatsApiStatus: INITIAL_STATE.emailThreatsStatsApiStatus,
        incidentsStatsApiStatus: INITIAL_STATE.incidentsStatsApiStatus,
        topUsersApiStatus: INITIAL_STATE.topUsersApiStatus
      }
    }
  },
  // extraReducers do not create an action but executes the reducer function
  extraReducers: builder => {
    builder
      // getIncidentsStats
      .addCase(getRemediationIncidentsStats.pending, state => {
        state.incidentsStats = INITIAL_STATE.incidentsStats
        state.incidentsStatsApiStatus = inProgress
      })
      .addCase(getRemediationIncidentsStats.fulfilled, (state, action) => {
        state.incidentsStats = action.payload
        state.incidentsStatsApiStatus = successResponse
      })
      .addCase(getRemediationIncidentsStats.rejected, (state, action) => {
        state.incidentsStatsApiStatus = failedResponse(action.payload)
      })

      // getRemediationThreatStats
      .addCase(getRemediationEmailThreatsStats.pending, state => {
        state.emailThreatsStats = INITIAL_STATE.emailThreatsStats
        state.emailThreatsStatsApiStatus = inProgress
      })
      .addCase(getRemediationEmailThreatsStats.fulfilled, (state, action) => {
        state.emailThreatsStats = action.payload
        state.emailThreatsStatsApiStatus = successResponse
      })
      .addCase(getRemediationEmailThreatsStats.rejected, (state, action) => {
        state.emailThreatsStatsApiStatus = failedResponse(action.payload)
      })

      // getRemediationTopUsersStats
      .addCase(getRemediationTopUsersStats.pending, state => {
        state.topUsersStats = INITIAL_STATE.topUsersStats
        state.topUsersApiStatus = inProgress
      })
      .addCase(getRemediationTopUsersStats.fulfilled, (state, action) => {
        state.topUsersStats = action.payload
        state.topUsersApiStatus = successResponse
      })
      .addCase(getRemediationTopUsersStats.rejected, (state, action) => {
        state.topUsersApiStatus = failedResponse(action.payload)
      })

      // getUserReportedTopReportersStats
      .addCase(getUserReportedTopReportersStats.pending, state => {
        state.userReportedUserStats = INITIAL_STATE.userReportedUserStats
        state.userReportedStatsApiStatus = inProgress
      })
      .addCase(getUserReportedTopReportersStats.fulfilled, (state, action) => {
        state.userReportedUserStats = action.payload
        state.userReportedStatsApiStatus = successResponse
      })
      .addCase(getUserReportedTopReportersStats.rejected, (state, action) => {
        state.userReportedStatsApiStatus = failedResponse(action.payload)
      })

      // getWorkflowRunsStats
      .addCase(getWorkflowRunsStats.pending, state => {
        state.workflowRunsStats = INITIAL_STATE.workflowRunsStats
        state.automatedWorkflowRunsStatsApiStatus = inProgress
      })
      .addCase(getWorkflowRunsStats.fulfilled, (state, action) => {
        state.workflowRunsStats = action.payload
        state.automatedWorkflowRunsStatsApiStatus = successResponse
      })
      .addCase(getWorkflowRunsStats.rejected, (state, action) => {
        state.automatedWorkflowRunsStatsApiStatus = failedResponse(action.payload)
      })

      // getWorkflowTopRunsStats
      .addCase(getWorkflowTopRunsStats.pending, state => {
        state.topWorkflowsStats = INITIAL_STATE.topWorkflowsStats
        state.automatedWorkflowsTopRunsStatsApiStatus = inProgress
      })
      .addCase(getWorkflowTopRunsStats.fulfilled, (state, action) => {
        state.topWorkflowsStats = action.payload
        state.automatedWorkflowsTopRunsStatsApiStatus = successResponse
      })
      .addCase(getWorkflowTopRunsStats.rejected, (state, action) => {
        state.automatedWorkflowsTopRunsStatsApiStatus = failedResponse(action.payload)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { reset, resetRemediationStatFailures } = statsSlice.actions

export {
  getRemediationEmailThreatsStats,
  getRemediationIncidentsStats,
  getRemediationTopUsersStats,
  getUserReportedTopReportersStats,
  getWorkflowRunsStats,
  getWorkflowTopRunsStats
}

export default statsSlice.reducer
