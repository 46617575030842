import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from 'global/configs/theme/defaults'

import tableStyles, { TABLE_HEADER_HEIGHT, TABLE_FOOTER_HEIGHT } from 'global/components/lib/grid/tableStyles'

const CARD_HEIGHT = 320
export const CELL_HEIGHT = 65
const TABLE_MIN_HEIGHT = CELL_HEIGHT * 10 + TABLE_HEADER_HEIGHT + TABLE_FOOTER_HEIGHT
export const INBOX_FEED_CELL_HEIGHT = 81
const INBOX_FEED_TABLE_MIN_HEIGHT = INBOX_FEED_CELL_HEIGHT * 7 + TABLE_HEADER_HEIGHT + TABLE_FOOTER_HEIGHT

export default makeStyles(theme => {
  const defaultTableStyles = tableStyles(theme, CELL_HEIGHT)

  return {
    title: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    subtitle: {
      marginBottom: theme.spacing(2),
      lineHeight: '10px',
      color: COLORS.GRAY
    },
    tableCard: {
      position: 'relative',
      minHeight: CARD_HEIGHT,
      padding: 0
    },
    tableCircularProgress: {
      paddingTop: theme.spacing(10)
    },
    tableWrapper: {
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`
    },
    error: {
      marginBottom: theme.spacing(2)
    },

    ...defaultTableStyles,
    tableArea: {
      ...defaultTableStyles.tableArea,
      marginTop: theme.spacing(1),
      minHeight: TABLE_MIN_HEIGHT
    },
    inboxFeedTableArea: {
      ...defaultTableStyles.tableArea,
      marginTop: theme.spacing(1),
      minHeight: INBOX_FEED_TABLE_MIN_HEIGHT
    },
    flexibleTableArea: {
      ...defaultTableStyles.tableArea,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2)
    },
    dataTable: {
      ...defaultTableStyles.dataTable,
      height: TABLE_MIN_HEIGHT,

      '& td': {
        lineHeight: `${theme.spacing(2.5)}px`
      }
    },
    inboxFeedDataTable: {
      ...defaultTableStyles.dataTable,
      height: INBOX_FEED_TABLE_MIN_HEIGHT,

      '& td': {
        lineHeight: `${theme.spacing(2.5)}px`
      }
    },
    flexibleDataTable: {
      ...defaultTableStyles.dataTable,

      '& td': {
        lineHeight: `${theme.spacing(2.5)}px`
      }
    },
    icon: {
      verticalAlign: -5,
      color: COLORS.BLACK_50,
      cursor: 'pointer'
    },
    tooltip: {
      backgroundColor: COLORS.BLACK_50,
      fontSize: 12,
      letterSpacing: 0,
      lineHeight: '16px',
      boxShadow: '0 2px 5px 0 rgba(0,0,0,0.26), 0 2px 10px 0 rgba(0,0,0,0.16)'
    },
    autocompleteContainer: {
      padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`
    },
    autocompleteButton: {
      marginLeft: theme.spacing(2)
    },
    subContent: {
      marginTop: theme.spacing(2)
    },
    contentLoading: {
      marginTop: theme.spacing(2)
    },
    warning: {
      color: COLORS.ORANGE_400,
      width: 40,
      height: 40
    },
    domDataForAlertStatus: {
      display: 'none'
    }
  }
})
