import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from 'global/configs/theme/defaults'

const CARD_HEIGHT = 80

export default makeStyles(theme => {
  const cardCount = {
    lineHeight: `${CARD_HEIGHT}px`,
    fontSize: 40,
    letterSpacing: '-4px',
    fontWeight: 500,
    marginRight: theme.spacing(2)
  }

  return {
    card: {
      position: 'relative',
      height: CARD_HEIGHT,
      padding: `0px ${theme.spacing(2)}px`,
      marginTop: theme.spacing(1.5)
    },
    circularProgress: {
      marginTop: 3,
      marginRight: 10
    },
    cardCountWrapper: {
      height: CARD_HEIGHT,
      marginTop: theme.spacing(0)
    },
    cardCountGreen: {
      ...cardCount,
      color: COLORS.LIGHT_GREEN
    },
    cardCountRed: {
      ...cardCount,
      color: COLORS.RED
    },
    cardCountGreenDate: {
      ...cardCount,
      color: COLORS.LIGHT_GREEN,
      lineHeight: '25px',
      fontSize: 20,
      letterSpacing: '-1px'
    },
    cardCountRedDate: {
      ...cardCount,
      color: COLORS.RED,
      lineHeight: '25px',
      fontSize: 20,
      letterSpacing: '-1px'
    },
    cardCountTitle: {
      fontSize: 16,
      lineHeight: '18px',
      verticalAlign: 'middle'
    },
    newIncidentButtonWrapper: {
      justifyContent: 'flex-end'
    }
  }
})
