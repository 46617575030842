import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Grid, DialogContent, Typography } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'global/lib/localization'

import BaseDialogActions from 'sen/components/lib/dialogs/newIncidentDialog/lib/BaseDialogActions'

import useNewIncidentInitLogic from 'sen/components/lib/dialogs/newIncidentDialog/steps/useNewIncidentInitLogic'

import styles from 'sen/components/lib/dialogs/newIncidentDialog/newIncidentDialogStyles'

const BASE_I18N_KEY = 'sen.app.new_incident_dialog.step_init'

export interface Props {
  onClose: () => void
  activeStep: number
}
const NewIncidentInit: React.FC<Props> = ({ onClose, activeStep }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [buttonStatus] = useNewIncidentInitLogic()
  const classes = styles()

  return useMemo(
    () => (
      <Grid container direction="column">
        <DialogContent className={classes.dialogContent}>
          <Typography className={classes.text}>{formatMessage('title')}</Typography>
          <ul className={classes.initList}>
            <li className={classes.initListItem}>
              <Grid item>
                <Typography className={classes.text}>
                  {formatMessage('description_1', {
                    b: (txt: string) => <b key={txt}>{txt}</b>
                  })}
                </Typography>
              </Grid>
            </li>
            <li className={classes.initListItem}>
              <Grid item>
                <Typography className={classes.text}>
                  {formatMessage('description_2', {
                    b: (txt: string) => <b key={txt}>{txt}</b>
                  })}
                </Typography>
              </Grid>
            </li>
          </ul>
        </DialogContent>
        <BaseDialogActions activeStep={activeStep} buttonStatus={buttonStatus} onClose={onClose} />
      </Grid>
    ),
    [classes, formatMessage, activeStep, buttonStatus, onClose]
  )
}

export default NewIncidentInit

NewIncidentInit.propTypes = {
  activeStep: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired
}
