/* eslint-disable import/no-mutable-exports */
import deepmerge from 'deepmerge'

import { AppConfiguration, GlobalConfig, AppConfig, WindowConfiguration } from 'global/types/api/configurationType'
import GLOBAL_APP_CONFIG from 'global/configs/global.app_config'
import apiRoutes from 'fir/lib/api/apiRoutes'
import restClient from './api/restClient'

declare global {
  export interface Window {
    config: WindowConfiguration | null
  }
}

// Ensuring that config can be imported in a node environment (e.g. the e2e tests)
const safeWindow: any = typeof window === 'undefined' ? { config: null } : window

let config: any = deepmerge(safeWindow.config as WindowConfiguration, GLOBAL_APP_CONFIG as GlobalConfig)
safeWindow.config = null
if (typeof window !== 'undefined') {
  window.config = null
}
export { config }

export default async function initConfig(APP_CONFIG: {}, extraConfig: any = null): Promise<any> {
  if (!extraConfig && config.isDev) {
    const resp = await restClient(apiRoutes.MAIN_CONFIGURATION)
    config = deepmerge(resp.data.configuration as AppConfiguration, config)
  } else {
    config = deepmerge(extraConfig, config)
  }
  config = deepmerge(config, APP_CONFIG as AppConfig)
  return config
}

export function addAppConfig(APP_CONFIG: any) {
  config = deepmerge(config, APP_CONFIG)
  return config
}
/* eslint-enable import/no-mutable-exports */
