import { useMemo, useEffect, useReducer, useCallback, useRef, RefObject } from 'react'

import { process } from '@progress/kendo-data-query'

import { BDSGridPagerConfig, BDSGridSortConfig } from 'global/types/dataTables/dataTables'
import { ColumnsConfig } from 'global/types/dataTables/columnsConfigType'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import useSpService from 'global/lib/useSpService/useSpService'
import useTablePeriodicCheck from 'global/lib/useTablePeriodicCheck/useTablePeriodicCheck'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'
import { formatDate, formatDateWithTime } from 'global/lib/datetime'
import useStatusTypeLabelLogicForSpAttacks from 'global/components/lib/statusTypeLabel/useStatusTypeLabelLogicForSpAttacks'
import { isSuccess, isPending } from 'global/redux/toolkit/api'
import { getScanStatus, reset as resetScan } from 'global/redux/features/scan/scanSlice'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import { resetCleanupAllThreats } from 'sen/redux/features/cleanup/cleanupSlice'
import {
  getBootstrappedAttacks,
  getAttacksToRemediate,
  getListFeedback,
  resetBootstrappedAttacks,
  resetAttacksToRemediate,
  resetListFeedback
} from 'sen/redux/features/sp/spSlice'

import {
  update as updateSpAttacksTable,
  reset as resetSpAttacksTable
} from 'sen/redux/features/dataTables/spAttacks/spAttacksSlice'
import { CleanupDialogProps } from 'sen/components/lib/dialogs/cleanupDialog/CleanupDialog'

export type InProgress = boolean
export interface CleanupTableConfig {
  ref: RefObject<HTMLDivElement>
  isLoaded: boolean
  inProgress: boolean
  tableData: {
    total: number
    data: any[]
  }
  pageConfig: BDSGridPagerConfig
  sortConfig: BDSGridSortConfig | {}
  columns: { [key: string]: string }
  columnsConfig: { [key: string]: ColumnsConfig }
  isUserInteractionDisabledForTable: boolean
}
export type Error = string | undefined

export interface CleanupDialogConfig extends CleanupDialogProps {
  open: boolean
  onOpen: () => void
}

const SCAN_STATUS_CHECK_INTERVAL = 10000

let interval: ReturnType<typeof setInterval>

export default function useCleanupContentLogic(): [CleanupTableConfig, CleanupDialogConfig] {
  const dispatch = useAppDispatch()
  const [isCleanupDialogOpened, toggleCleanupDialog] = useDialogLogic()
  const [statusTypeLabelLogic] = useStatusTypeLabelLogicForSpAttacks()
  const [spService] = useSpService()

  const {
    accessTokenId,
    cleanupTable,
    isCleanupInProgress,
    isListFeedbackInProgress,
    listFeedback,
    isCleanupThreatsSuccess,
    spBootstrappedAttacks,
    spRemediatedAttacks,
    isBootstrappedAttacksinProgress,
    isBootstrappedAttacksLoadedSuccess,
    spAttacksLoadedOffsets
  } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id,
    cleanupTable: _stores.dataTables.spAttacks,
    isCleanupInProgress: !!_stores.scan.scanResults?.cleanupInProgress,
    isListFeedbackInProgress: isPending(_stores.sp.getListFeedbackApiStatus),
    listFeedback: _stores.sp.listFeedback,
    isCleanupThreatsSuccess: isSuccess(_stores.cleanup.cleanupAllThreatsApiStatus),
    spBootstrappedAttacks: _stores.sp.bootstrappedAttacks,
    spRemediatedAttacks: _stores.sp.attacksToRemediate,
    isBootstrappedAttacksinProgress: isPending(_stores.sp.getBootstrappedAttacksApiStatus),
    isBootstrappedAttacksLoadedSuccess: isSuccess(_stores.sp.getBootstrappedAttacksApiStatus),
    spAttacksLoadedOffsets: _stores.sp.attacksLoadedOffsets
  }))

  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    isDeleteInProgress: false
  })
  const [initTableRefresh] = useTablePeriodicCheck()
  const cleanupTableRef = useRef<null | HTMLDivElement>(null)

  // init
  useEffect(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.CLEANUP_VIEW, {
      accessTokenId,
      url: window.location.href
    })

    initTableRefresh(tableRefresh)

    dispatch(getScanStatus())
    dispatch(getBootstrappedAttacks())
    dispatch(getAttacksToRemediate())

    return () => {
      if (interval) {
        clearInterval(interval)
      }

      dispatch(resetScan())
      dispatch(resetBootstrappedAttacks())
      dispatch(resetAttacksToRemediate())
      dispatch(resetSpAttacksTable())
      dispatch(resetListFeedback())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // set the threadIds and get the list feedback
  const threatIds = useMemo(() => {
    return (
      spBootstrappedAttacks?.report?.data
        ?.slice(cleanupTable.skip, cleanupTable.skip + cleanupTable.take)
        .map((attack: any) => attack?.threatId) || []
    )
  }, [spBootstrappedAttacks, cleanupTable])

  useEffect(() => {
    if (isBootstrappedAttacksLoadedSuccess) {
      dispatch(getListFeedback(threatIds))
    }
  }, [isBootstrappedAttacksLoadedSuccess, threatIds, dispatch])

  const getFalsePositiveStatus = useCallback(
    (threatId: string) => {
      const feedbackForThreat = listFeedback?.feedback?.data?.find((feedback: any) => feedback.threatId === threatId)

      return feedbackForThreat && !!feedbackForThreat.markedAsFp
    },
    [listFeedback]
  )

  // cleanup table
  const isUserInteractionDisabledForTable: boolean = useMemo(() => {
    return (
      state.isDeleteInProgress ||
      isBootstrappedAttacksinProgress ||
      isCleanupInProgress ||
      spBootstrappedAttacks?.report?.totalCount === 0 ||
      spRemediatedAttacks?.report?.totalCount === 0
    )
  }, [
    state.isDeleteInProgress,
    isBootstrappedAttacksinProgress,
    spBootstrappedAttacks,
    spRemediatedAttacks,
    isCleanupInProgress
  ])

  const resetTable = useCallback(() => {
    dispatch(resetBootstrappedAttacks())
    dispatch(resetAttacksToRemediate())

    dispatch(getBootstrappedAttacks())
    dispatch(getAttacksToRemediate())

    dispatch(resetListFeedback())
  }, [dispatch])

  const resetScanStatus = useCallback(() => {
    dispatch(getScanStatus())
    dispatch(getBootstrappedAttacks())
    dispatch(getAttacksToRemediate())
  }, [dispatch])

  const checkScanStatus = useCallback(() => {
    if ((state.isDeleteInProgress || isCleanupInProgress) && !interval) {
      interval = setInterval(() => {
        resetScanStatus()
      }, SCAN_STATUS_CHECK_INTERVAL)
    }
  }, [resetScanStatus, state.isDeleteInProgress, isCleanupInProgress])

  const tableRefresh = useCallback(() => {
    if (!isBootstrappedAttacksinProgress) {
      resetTable()
    }
  }, [resetTable, isBootstrappedAttacksinProgress])

  // Check that the cleanup call is successful and set state
  useEffect(() => {
    if (isCleanupThreatsSuccess) {
      setState({ isDeleteInProgress: true })
      checkScanStatus()
    }
  }, [state.isDeleteInProgress, isCleanupThreatsSuccess, checkScanStatus])

  // stop periodic scan when attacks are deleted
  useEffect(() => {
    if ((spRemediatedAttacks?.report?.totalCount === 0 || !isCleanupInProgress) && interval) {
      setState({ isDeleteInProgress: false })
      dispatch(resetCleanupAllThreats())

      clearInterval(interval)
    }
  }, [dispatch, spRemediatedAttacks, isCleanupInProgress])

  const updateTableData = useCallback(
    (changes: any = {}) => {
      const { selectedTab, ...updatedValues } = changes

      dispatch(
        updateSpAttacksTable({
          ...updatedValues,
          skip: 0
        })
      )

      initTableRefresh(tableRefresh)
      resetTable()
    },
    [resetTable, dispatch, initTableRefresh, tableRefresh]
  )

  const tableData: any = useMemo(() => {
    const { skip, take } = cleanupTable

    const { data } = process(
      (spBootstrappedAttacks?.report?.data || []).map((report: any) => ({
        ...(report && {
          ...report,
          formattedDate: formatDateWithTime(report.date || ''),
          formatShortDate: formatDate(report.date || ''),
          markedAsFp: getFalsePositiveStatus(report.threatId),
          hasRemediation: spService.hasRemediation(report),
          status: {
            inProgress:
              isListFeedbackInProgress ||
              isBootstrappedAttacksinProgress ||
              isCleanupInProgress ||
              spService.remediationInProgress(report) ||
              state.isDeleteInProgress,
            ...(!isListFeedbackInProgress &&
              !isBootstrappedAttacksinProgress && {
                id: statusTypeLabelLogic.getCleanupStatusId(report, isCleanupInProgress)
              })
          }
        })
      })),
      { skip, take }
    )

    return {
      data: data.filter(report => report.displayName),
      total: spBootstrappedAttacks?.report?.totalCount || 0
    }
  }, [
    spBootstrappedAttacks,
    cleanupTable,
    isListFeedbackInProgress,
    isBootstrappedAttacksinProgress,
    statusTypeLabelLogic,
    getFalsePositiveStatus,
    isCleanupInProgress,
    state.isDeleteInProgress,
    spService
  ])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = cleanupTable

    return {
      pageable: {
        buttonCount: 10
      },
      skip,
      take,
      total: tableData.total,
      onPageChange: (e: any) => {
        dispatch(updateSpAttacksTable(e.page))

        if (!spAttacksLoadedOffsets.includes(e.page.skip)) {
          dispatch(getBootstrappedAttacks())
        }
      }
    }
  }, [cleanupTable, tableData, spAttacksLoadedOffsets, dispatch])

  const sortConfig: BDSGridSortConfig | {} = useMemo(() => {
    if (!tableData.total) {
      return {}
    }

    return {
      sortable: !isBootstrappedAttacksinProgress && {
        allowUnsort: false,
        mode: 'single'
      },
      sort: cleanupTable.sort,
      onSortChange: (e: any) => {
        updateTableData({ sort: e.sort })
      }
    }
  }, [cleanupTable, updateTableData, tableData.total, isBootstrappedAttacksinProgress])

  const cleanupTableConfig: CleanupTableConfig = useMemo(() => {
    return {
      ref: cleanupTableRef,
      isLoaded: !!spBootstrappedAttacks?.accessTokenId,
      inProgress: isBootstrappedAttacksinProgress,
      tableData,
      pageConfig,
      sortConfig,
      columns: cleanupTable.GRID_COLUMNS,
      columnsConfig: cleanupTable.columnsConfig,
      isUserInteractionDisabledForTable
    }
  }, [
    cleanupTableRef,
    spBootstrappedAttacks,
    isBootstrappedAttacksinProgress,
    tableData,
    pageConfig,
    sortConfig,
    cleanupTable.GRID_COLUMNS,
    cleanupTable.columnsConfig,
    isUserInteractionDisabledForTable
  ])

  const cleanupDialogConfig: CleanupDialogConfig = useMemo(
    () => ({
      open: isCleanupDialogOpened,
      onOpen: () => {
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.CLEANUP_DIALOG_OPEN, {
          accessTokenId,
          url: window.location.href
        })
        toggleCleanupDialog()
      },
      onClose: () => {
        toggleCleanupDialog()
      }
    }),
    [isCleanupDialogOpened, toggleCleanupDialog, accessTokenId]
  )

  return useMemo(() => {
    return [cleanupTableConfig, cleanupDialogConfig]
  }, [cleanupTableConfig, cleanupDialogConfig])
}
