import { createSlice } from '@reduxjs/toolkit'

import { ColumnsConfig, GridColumns } from 'global/types/dataTables/dataTables'

interface ThreatDetailsByIncidentState {
  GRID_COLUMNS: GridColumns
  columnsConfig: ColumnsConfig
  collapsedGroups: any[]
  expandedRows: any[]
}

export const GRID_COLUMNS = {
  CATEGORY: 'vector',
  DETAILS: 'extra',
  THREAT: 'path',
  TYPE: 'type'
}

export const COLUMNS_CONFIG = {
  [GRID_COLUMNS.TYPE]: {
    orderIndex: 0,
    show: true,
    sortable: false
  },
  [GRID_COLUMNS.THREAT]: {
    orderIndex: 1,
    show: true,
    sortable: false
  },
  [GRID_COLUMNS.CATEGORY]: {
    orderIndex: 2,
    show: true,
    sortable: false
  },
  [GRID_COLUMNS.DETAILS]: {
    show: true,
    orderIndex: 3,
    sortable: false
  }
}

// Store
export const INITIAL_STATE: ThreatDetailsByIncidentState = {
  GRID_COLUMNS,
  columnsConfig: COLUMNS_CONFIG,
  collapsedGroups: [],
  expandedRows: []
}

export const threatDetailsByIncidentSlice = createSlice({
  name: 'DATATABLE/THREAT_DETAILS_BY_INCIDENT',
  initialState: INITIAL_STATE,
  reducers: {
    update: (state, action) => {
      return {
        ...state,
        ...action.payload?.config
      }
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  }
})

export const { update, reset } = threatDetailsByIncidentSlice.actions

export default threatDetailsByIncidentSlice.reducer
