import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { v4 as uuidv4 } from 'uuid'

import {
  Typography,
  Button,
  Radio,
  RadioGroup,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Zoom
} from '@barracuda-internal/bds-core'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import DEFAULTS from 'global/configs/theme/defaults'

import useReportFalsePositiveLogic from 'sen/components/lib/dialogs/reportFalsePositive/useReportFalsePositiveLogic'
import styles from 'sen/components/lib/dialogs/reportFalsePositive/reportFalsepositiveStyles'
import { useErrorFormatMessage, useFormatMessage } from 'global/lib/localization'

export interface ReportFalsePositiveDialogProps {
  open: boolean
  onClose: (isMarked?: boolean) => void
}

const BASE_I18N_KEY = 'sen.app.report_false_positive_dialog'

export const ReportFalsePositiveDialog: React.FC<ReportFalsePositiveDialogProps> = ({ open, onClose }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatMessageError = useErrorFormatMessage()
  const classes = styles()
  const [inProgress, radioButtonConfig, onReport, errorMsg, isCleanupPage] = useReportFalsePositiveLogic(onClose)

  return useMemo(
    () => (
      <Dialog
        style={{ zIndex: DEFAULTS.SECONDARY_DIALOGS_ZINDEX }}
        maxWidth="md"
        fullWidth
        open={open}
        TransitionComponent={Zoom}
      >
        {inProgress && <LinearProgress />}
        <DialogTitle>
          {errorMsg && (
            <Alert className={classes.error} variant="filled" severity="error">
              {formatMessageError(`${errorMsg}`)}
            </Alert>
          )}
          <Typography className={classes.dialogTitle}>{formatMessage('title')}</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography className={classes.subtitle}>{formatMessage('subtitle_1')}</Typography>
          <FormControl>
            <FormLabel>
              <Typography className={classes.subtitle}>{formatMessage('select_title')}:</Typography>
            </FormLabel>
            <RadioGroup value={radioButtonConfig.selectedRadio} onChange={radioButtonConfig.setSelectedRadio}>
              {[...Array(3)].map((_value, index) => (
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label={formatMessage(`select_${index}`, {
                    email: radioButtonConfig.email,
                    domain: radioButtonConfig.domain,
                    b: (txt: string) => <b key={uuidv4()}>{txt}</b>
                  })}
                  disabled={inProgress}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  value={index}
                />
              ))}
            </RadioGroup>
          </FormControl>
          {isCleanupPage && <Typography className={classes.cleanUpMessage}>{formatMessage('clean_up')}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button
            size="large"
            color="primary"
            variant="text"
            onClick={() => {
              onClose()
            }}
            disabled={inProgress}
          >
            {formatMessage('cancel')}
          </Button>

          <Button color="primary" variant="contained" size="large" onClick={onReport} disabled={inProgress}>
            &nbsp;&nbsp;{formatMessage('submit')}&nbsp;&nbsp;
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [
      formatMessage,
      formatMessageError,
      classes,
      open,
      onClose,
      inProgress,
      radioButtonConfig,
      onReport,
      errorMsg,
      isCleanupPage
    ]
  )
}

ReportFalsePositiveDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default ReportFalsePositiveDialog
