import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import reducerHandlers from 'global/redux/features/dataTables/reducerHandlers'
import { UpdateDataTable } from 'global/types/dataTables/dataTables'

import { DataTableWithDemoState } from 'ets/redux/types/dataTables'

export const GRID_COLUMNS = {
  DOMAIN: 'domain',
  EMAILS_WITH_TREATS: 'emails_with_threats',
  EMPTY_COLUMN: 'empty_column',
  ACTION: 'action'
}

export const SORT_FIELDS = {
  [GRID_COLUMNS.DOMAIN]: 'domain',
  [GRID_COLUMNS.EMAILS_WITH_TREATS]: 'spFraudCount'
}

export const COLUMNS_CONFIG = {
  [GRID_COLUMNS.DOMAIN]: {
    sortable: true,
    orderIndex: 0,
    show: true
  },
  [GRID_COLUMNS.EMAILS_WITH_TREATS]: {
    isDescOrderFirst: true,
    sortable: true,
    orderIndex: 1,
    show: true
  },
  [GRID_COLUMNS.EMPTY_COLUMN]: {
    sortable: false,
    orderIndex: 2,
    show: true
  },
  [GRID_COLUMNS.ACTION]: {
    sortable: false,
    orderIndex: 3,
    show: true
  }
}

const ITEMS_PER_PAGE = 10
const PDF_ITEMS_PER_PAGE = 10
export const DEMO_DATA = {
  ALL: 7012,
  COUNTS: {}
}

// initialState
// Do not allow isBootstrap in filter
export const INITIAL_STATE: DataTableWithDemoState = {
  SORT_FIELDS,
  GRID_COLUMNS,
  DEMO_DATA,
  ITEMS_PER_PAGE,
  columnsConfig: COLUMNS_CONFIG,
  skip: 0, // offset
  take: ITEMS_PER_PAGE, // limit
  filter: null,
  search: '',
  sort: [{ field: GRID_COLUMNS.EMAILS_WITH_TREATS, dir: 'desc' }],
  pdfConfig: {
    SORT_FIELDS,
    skip: 0, // offset
    take: PDF_ITEMS_PER_PAGE, // limit
    filter: null,
    search: '',
    sort: [{ field: GRID_COLUMNS.EMAILS_WITH_TREATS, dir: 'desc' }]
  }
}

export const attackingDomainsSlice = createSlice({
  name: 'DATATABLE/ATTACKING_DOMAINS',
  initialState: INITIAL_STATE,
  reducers: {
    update: {
      reducer: (state: DataTableWithDemoState, action: PayloadAction<{ config: UpdateDataTable }>) => {
        return reducerHandlers({ COLUMNS_CONFIG }).update({ state, payload: action.payload })
      },
      prepare: (config: UpdateDataTable) => ({
        payload: { config }
      })
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  }
})

export const { update, reset } = attackingDomainsSlice.actions

export default attackingDomainsSlice.reducer
