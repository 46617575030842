import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  bold: {
    fontWeight: 'bold'
  },
  blue: {
    backgroundColor: theme.chart.blue
  },
  chart: {
    padding: theme.spacing(1)
  },
  chartTooltipIcon: {
    height: 12,
    width: 12,
    borderRadius: 6,
    marginBottom: -1,
    display: 'inline-block'
  },
  chartTooltipTitle: {
    opacity: 0.5,
    fontWeight: 'bold',
    overflowWrap: 'anywhere'
  },
  chartTooltipValueCell: {
    float: 'right'
  },
  chartTooltipValueDetails: {
    verticalAlign: 'middle'
  },
  chartTooltipValueDetailsText: {
    display: 'inline-block',
    paddingLeft: 7,
    fontWeight: 'bold'
  },
  chartTooltipWrapper: {
    width: 225
  },
  highlight: {
    color: theme.chart.red,
    fontWeight: 600,
    paddingRight: theme.spacing(0.5)
  },
  loadingBox: {
    textAlign: 'center'
  },
  red: {
    backgroundColor: theme.chart.red
  }
}))
