import React from 'react'
import PropTypes from 'prop-types'

import { FormControl } from '@material-ui/core'
import { Select, MenuItem, TextField } from '@barracuda-internal/bds-core'

import textFilterLogic from './TextFilterLogic'
import styles from './filterStyles'

export interface Props {
  onDropdownChange: (e: any) => void
  selectedOperator: string
  onValueChange: (e: any) => void
  value: string
  dropdownValues: Dropdown[]
}

export interface Dropdown {
  value: string
  content: any
}

const TextFilter: React.FC<Props> = props => {
  const classes = styles()

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <Select className={classes.dropdown} value={props.selectedOperator} onChange={props.onDropdownChange}>
        {props.dropdownValues.map((item: Dropdown) => (
          <MenuItem key={item.value} value={item.value}>
            {item.content}
          </MenuItem>
        ))}
      </Select>
      <TextField className={classes.textField} onChange={props.onValueChange} value={props.value} />
    </FormControl>
  )
}

TextFilter.propTypes = {
  onDropdownChange: PropTypes.func.isRequired,
  dropdownValues: PropTypes.array.isRequired,
  selectedOperator: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
}

export default textFilterLogic(TextFilter)
