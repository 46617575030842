import { makeStyles, createStyles } from '@material-ui/core/styles'

const styles = createStyles({
  text: {
    fontSize: 14,
    letterSpacing: 0,
    whiteSpace: 'nowrap',
    cursor: 'default'
  },
  smallText: {
    fontSize: 12,
    letterSpacing: 0,
    whiteSpace: 'nowrap',
    cursor: 'default'
  }
})

export default makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  shareButton: {
    height: 40,
    width: 109,
    marginRight: 10
  },
  smallText: {
    ...styles.smallText,
    color: theme.palette.text.secondary
  },
  text: {
    ...styles.text,
    color: theme.palette.text.secondary
  },
  blackText: {
    ...styles.text,
    color: theme.palette.text.primary
  },
  shareDropdown: {
    flexGrow: 1,
    padding: '18px 10px 10px 10px'
  },
  menuWrapper: {
    marginTop: theme.spacing(2),
    cursor: 'pointer'
  },
  menuWrapperDisabled: {
    marginTop: theme.spacing(2),
    cursor: 'default',
    opacity: '0.5'
  },
  menuIcon: {
    display: 'inline-flex',
    right: 10
  },
  menuText: {
    ...styles.text,
    color: theme.palette.text.primary,
    marginLeft: 5
  },
  shareUrlGroup: {
    marginTop: 8,
    marginBottom: theme.spacing(4)
  },
  shareUrl: {
    height: 32,
    width: 224
  },
  copyToClipboard: {
    position: 'absolute',
    marginTop: theme.spacing(1),
    maxWidth: 90,
    right: 0
  }
}))
