import { useMemo, useEffect, useCallback } from 'react'

import { process } from '@progress/kendo-data-query'
import { BDSGridPagerConfig } from 'global/types/dataTables/dataTables'

import { getErrorMessage, isPending } from 'global/redux/toolkit/api'
import { ColumnsConfig } from 'global/types/dataTables/columnsConfigType'
import { formatDateWithTime } from 'global/lib/datetime'
import { Incident } from 'global/types/api/remediation'

import routesConfig from 'sen/lib/routes/routesConfig'
import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import {
  update as updateIncidentsTable,
  reset as resetIncidentsTable
} from 'sen/redux/features/dataTables/incidents/incidentsSlice'
import { getIncidents, resetIncidents } from 'sen/redux/features/remediation/remediationSlice'

export interface ModifiedIncident extends Incident {
  formattedDate: string
}

export type InProgress = boolean
export interface TableConfig {
  isLoaded: boolean
  tableData: {
    total: number
    data: ModifiedIncident[]
  }
  pageConfig: BDSGridPagerConfig
  columns: { [key: string]: string }
  columnsConfig: { [key: string]: ColumnsConfig }
  isFlexibleTable: boolean
  onViewIncident: (IncidentId: Incident['id']) => void
}

export type Error = string | undefined

export default function useIncidentsLogic(): [InProgress, TableConfig, Error] {
  const dispatch = useAppDispatch()
  const {
    accessTokenId,
    incidentsTable,
    incidents,
    loadedIncidentsOffsets,
    inProgress,
    isLoaded,
    error
  } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id,
    incidentsTable: _stores.dataTables.incidents,
    incidents: _stores.remediation.incidents,
    loadedIncidentsOffsets: _stores.remediation.loadedIncidentsOffsets,
    inProgress: isPending(_stores.remediation.getIncidentsApiStatus),
    isLoaded: !!_stores.remediation.incidents?.accessTokenId,
    error: getErrorMessage(_stores.remediation.getIncidentsApiStatus)
  }))

  // init
  useEffect(() => {
    dispatch(getIncidents())

    return () => {
      dispatch(resetIncidents(true))
      dispatch(resetIncidentsTable())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tableData = useMemo(() => {
    const { skip, take } = incidentsTable

    const { data } = process(
      (incidents?.report?.data || []).map((report: Incident) => ({
        ...(report && {
          ...report,
          formattedDate: formatDateWithTime(report.created || '')
        })
      })),
      { skip, take }
    )

    return {
      data: data.filter(report => report.id),
      total: incidents?.report?.totalCount || 0
    }
  }, [incidents, incidentsTable])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = incidentsTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: tableData.total,
      onPageChange: (e: any) => {
        dispatch(updateIncidentsTable(e.page))

        if (!loadedIncidentsOffsets?.includes(e.page.skip)) {
          dispatch(getIncidents())
        }
      }
    }
  }, [incidentsTable, tableData, dispatch, loadedIncidentsOffsets])

  const onViewIncident = useCallback(
    (incidentId: Incident['id']) => {
      routesConfig.ACCOUNT_TAKEOVER_INCIDENT.softGoto({ reportId: accessTokenId, incidentId })
    },
    [accessTokenId]
  )

  return useMemo(() => {
    return [
      inProgress,
      {
        isLoaded,
        tableData,
        pageConfig,
        columns: incidentsTable.GRID_COLUMNS,
        columnsConfig: incidentsTable.columnsConfig,
        onViewIncident,
        isFlexibleTable: tableData.total < incidentsTable.ITEMS_PER_PAGE
      },
      error
    ]
  }, [inProgress, incidentsTable, tableData, pageConfig, isLoaded, onViewIncident, error])
}
