import { createAsyncThunk } from '@reduxjs/toolkit'

import { globalApiRoutes } from 'global/lib/api/apiRoutes'
import restClient, { validateApiError } from 'global/lib/api/restClient'
import { config as globalConfig } from 'global/lib/config'
import { AccessTokenSettings, SettingsValue } from 'global/types/api/accessTokenType'
import { updateCurrentSettings } from 'global/redux/features/accessToken/accessTokenSlice'
import { buildReportQueryFor } from 'global/redux/features/dataTables/buildQueryForTable'
import {
  AddWhitelistItemResponse,
  AddWhitelistItemRequest,
  DeleteWhitelistItemResponse,
  DeleteWhitelistItemRequest,
  EditWhitelistItemResponse,
  EditWhitelistItemRequest,
  GetWhitelistItemListResponse,
  GetWhitelistItemListRequest,
  OptionalGetWhitelistItemListRequest,
  WhitelistItem
} from 'global/types/redux/settings'

import apiRoutes from 'fir/lib/api/apiRoutes'
import { setSettingsForAccessToken } from 'fir/redux/features/user/userSlice'
import { displayCustomizeEmailAlertDialog } from 'fir/redux/features/settings/settingsSlice'
import {
  CreateCustomNotificationTemplate,
  DeleteCustomNotificationTemplate,
  ForensicsNotificationPreview,
  ForensicsSettings,
  GetNotificationPreview,
  GetSiemSettings,
  SaveAccessTokenSettings,
  SaveSiemSettings,
  SiemApps
} from 'fir/redux/types/Settings'
import { RootState } from 'fir/redux/toolkit/store'

/**
 * API call to settingsController::addWhitelistItem
 */
export const addWhitelistItem = createAsyncThunk<
  AddWhitelistItemResponse,
  AddWhitelistItemRequest,
  Record<string, unknown>
>('SETTINGS/addWhitelistItem', async function doAddWhitelistItem(
  payload: AddWhitelistItemRequest,
  { rejectWithValue }
) {
  try {
    const resp = await restClient(globalApiRoutes.ADD_WHITELIST_ITEM, {
      data: payload
    })
    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

async function updateNotificationPreview(accessTokenId: string, context: ForensicsNotificationPreview) {
  try {
    const resp = await restClient(apiRoutes.FORENSICS_NOTIFICATION_PREVIEW, {
      data: { accessTokenId, context }
    })

    return {
      subject: context.customSubject || resp.data.template.subject,
      body: resp.data.template.body
    }
  } catch (e) {
    return validateApiError(e)
  }
}

export const createForensicsCustomNotification = createAsyncThunk(
  'SETTINGS/createForensicsCustomNotification',
  async function doCreateCustomNotificationTemplate(
    payload: CreateCustomNotificationTemplate,
    { getState, dispatch, rejectWithValue }
  ) {
    const { accessTokenId, template } = payload
    const state = getState() as RootState

    try {
      // show demo error if using demo token
      if (state.user.isFirDemoUser) {
        return rejectWithValue(validateApiError({ data: { error: globalConfig.API_ERROR.DEMO_ERROR.FORENSICS } }))
      }

      await restClient(apiRoutes.FORENSICS_CREATE_CUSTOM_NOTIFICATION_TEMPLATE, {
        data: { accessTokenId, template }
      })
      dispatch(displayCustomizeEmailAlertDialog(false))

      return ''
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const deleteForensicsCustomNotification = createAsyncThunk(
  'SETTINGS/deleteForensicsCustomNotification',
  async function doDeleteCustomNotificationTemplate(
    payload: DeleteCustomNotificationTemplate,
    { rejectWithValue, dispatch }
  ) {
    const { accessTokenId, templateName } = payload

    try {
      await restClient(apiRoutes.FORENSICS_DELETE_CUSTOM_NOTIFICATION_TEMPLATE, {
        data: { accessTokenId, templateName }
      })
      dispatch(displayCustomizeEmailAlertDialog(false))

      return ''
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

/**
 * API call to settingsController::deleteWhitelistItem
 */
export const deleteWhitelistItem = createAsyncThunk<
  DeleteWhitelistItemResponse,
  DeleteWhitelistItemRequest,
  Record<string, unknown>
>('SETTINGS/deleteWhitelistItem', async function doDeleteWhitelistItem(
  payload: DeleteWhitelistItemRequest,
  { rejectWithValue }
) {
  try {
    const resp = await restClient(globalApiRoutes.DELETE_WHITELIST_ITEM, {
      data: payload
    })
    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

/**
 * API call to settingsController::editWhitelistItem
 */
export const editWhitelistItem = createAsyncThunk<
  EditWhitelistItemResponse,
  EditWhitelistItemRequest,
  Record<string, unknown>
>('SETTINGS/editWhitelistItem', async function doEditWhitelistItem(
  payload: EditWhitelistItemRequest,
  { rejectWithValue }
) {
  try {
    const resp = await restClient(globalApiRoutes.EDIT_WHITELIST_ITEM, {
      data: payload
    })
    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getForensicsCustomNotification = createAsyncThunk(
  'SETTINGS/getForensicsCustomNotification',
  async function doGetCustomNotificationPreview(payload: GetNotificationPreview, { rejectWithValue }) {
    const { accessTokenId, context } = payload

    try {
      const resp = await restClient(apiRoutes.FORENSICS_CUSTOM_NOTIFICATION_TEMPLATES, {
        data: { accessTokenId }
      })

      const modifiedContext = {
        ...context,
        introduction: resp.data.template.data[0].introduction || '',
        signature: resp.data.template.data[0].signature || '',
        customSubject: resp.data.template.data[0].subject || ''
      }

      const preview = await updateNotificationPreview(accessTokenId, modifiedContext)

      if (typeof preview === 'string') {
        return rejectWithValue(preview)
      }

      return {
        editEmailFormValues: {
          formIntro: modifiedContext.introduction,
          formSignature: modifiedContext.signature,
          formSubject: modifiedContext.customSubject || modifiedContext.subject
        },
        notificationPreview: preview
      }
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getForensicsNotificationPreview = createAsyncThunk(
  'SETTINGS/getForensicsNotificationPreview',
  async function doGetDefaultNotificationPreview(payload: GetNotificationPreview, { rejectWithValue }) {
    const { accessTokenId, context } = payload

    const preview = await updateNotificationPreview(accessTokenId, context)

    if (typeof preview === 'string') {
      return rejectWithValue(preview)
    }

    return preview
  }
)

export const getForensicsSiemSettings = createAsyncThunk(
  'SETTINGS/getForensicsSiemSettings',
  async function doGetSiemSettings(payload: GetSiemSettings, { rejectWithValue }) {
    const { accessTokenId, app } = payload

    try {
      const resp = await restClient(apiRoutes.GET_SIEM_SETTINGS, {
        data: { accessTokenId, app: app || SiemApps.forensics }
      })

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

/**
 * API call to settingsController::getWhitelistItemList
 */
export const getWhitelistItemList = createAsyncThunk<
  GetWhitelistItemListResponse,
  OptionalGetWhitelistItemListRequest,
  Record<string, unknown>
>('SETTINGS/getWhitelistItemList', async function doGetWhitelistItemList(
  payload: GetWhitelistItemListRequest = { query: {} },
  { getState, rejectWithValue }
) {
  try {
    const query = buildReportQueryFor((getState() as RootState).dataTables.allowedSenders)

    const {
      data: { whitelistItems, ...reportData }
    } = await restClient(globalApiRoutes.GET_WHITELIST_ITEMS, {
      data: {
        query: {
          ...query,
          ...(payload.query || {}),
          offset: 0
        }
      }
    })
    return {
      report: {
        ...reportData,
        data: whitelistItems?.sort((a: WhitelistItem, b: WhitelistItem) =>
          a.pattern.toLowerCase() > b.pattern.toLowerCase() ? 1 : -1
        )
      }
    }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const saveAccessTokenSettings = createAsyncThunk(
  'SETTINGS/saveAccessTokenSettings',
  async function doSaveAccessTokenSettings(payload: SaveAccessTokenSettings, { getState, dispatch, rejectWithValue }) {
    const { accessTokenId, settings } = payload

    // convert booleans to strings since that's what the backend expects
    const accessTokenSettings = Object.entries(settings || {}).reduce(
      (tokenSettings: { [index: string]: string }, [key, value]) => {
        if (typeof value === 'boolean') {
          return { ...tokenSettings, [key]: value.toString() }
        }
        return { ...tokenSettings, [key]: value }
      },
      {}
    ) as AccessTokenSettings
    const state = getState() as RootState

    try {
      // show demo error if using demo token
      if (state.user.isFirDemoUser) {
        return rejectWithValue(validateApiError({ data: { error: globalConfig.API_ERROR.DEMO_ERROR.FORENSICS } }))
      }
      const resp = await restClient(apiRoutes.UPDATE_ACCESS_TOKEN_SETTINGS, {
        data: { accessTokenId, settings: accessTokenSettings }
      })

      // settings for accessToken and user accessToken stores keep strings
      dispatch(updateCurrentSettings(resp.data.settings))
      dispatch(setSettingsForAccessToken({ accessTokenId, settings: resp.data.settings }))

      // convert 'true|false' strings to boolean values
      const forensicsSettings: ForensicsSettings = Object.entries(resp.data.settings as AccessTokenSettings).reduce(
        (tokenSettings: { [index: string]: string | boolean }, [key, value]) => {
          if (value === SettingsValue.off || value === SettingsValue.on) {
            return { ...tokenSettings, [key]: value === SettingsValue.on }
          }
          return { ...tokenSettings, [key]: value }
        },
        {}
      )

      return forensicsSettings
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const saveForensicsSiemSettings = createAsyncThunk(
  'SETTINGS/saveForensicsSiemSettings',
  async function doSaveSiemSettings(payload: SaveSiemSettings, { getState, dispatch, rejectWithValue }) {
    const { accessTokenId, app, siemSettings } = payload
    const state = getState() as RootState

    try {
      // show demo error if using demo token
      if (state.user.isFirDemoUser) {
        return rejectWithValue(validateApiError({ data: { error: globalConfig.API_ERROR.DEMO_ERROR.FORENSICS } }))
      }
      await restClient(apiRoutes.SAVE_SIEM_SETTINGS, {
        data: { accessTokenId, app: app || SiemApps.forensics, syslogConfig: siemSettings }
      })

      return siemSettings
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const testForensicsSiemSettings = createAsyncThunk(
  'SETTINGS/testForensicsSiemSettings',
  async function doTestSiemSettings(payload: GetSiemSettings, { rejectWithValue }) {
    const { accessTokenId, app } = payload

    try {
      const resp = await restClient(apiRoutes.TEST_SIEM_SETTINGS, {
        data: { accessTokenId, app: app || SiemApps.forensics }
      })

      if (resp.data.delivered) {
        return ''
      }

      return rejectWithValue(validateApiError(false))
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)
