import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  listItem: {
    cursor: 'pointer',
    borderRadius: 4,
    justifyContent: 'space-between'
  },
  listItemSecondary: {
    marginTop: 8,
    marginBottom: 8,
    '&:hover': {
      background: '#E4F4FF',
      '& $pushPin': {
        display: 'block'
      }
    },
    '&.Mui-selected': {
      background: '#E4F4FF',
      color: '#0A5D91',
      '&:hover': {
        background: '#E4F4FF',
        color: '#0A5D91'
      }
    }
  },
  pushPin: {
    display: 'none'
  },
  listItemText: {
    cursor: 'pointer'
  }
}))
