import React from 'react'
import PropTypes from 'prop-types'

import { MultiSeriesBarChart } from '@barracuda-internal/bds-core'
import ChartWrapper from '@barracuda-internal/bds-core/dist/ChartWrapper/ChartWrapper'

export interface TopReportedDashboardProps {
  chartConfig: any
  chartWrapperConfig: any
}

const TopReportedDashboard: React.FC<TopReportedDashboardProps> = ({ chartConfig, chartWrapperConfig }) => {
  return (
    <ChartWrapper chartWrapper={{ ...chartWrapperConfig }}>
      <MultiSeriesBarChart multiSeriesBarChart={{ ...chartConfig }} />
    </ChartWrapper>
  )
}

TopReportedDashboard.propTypes = {
  chartConfig: PropTypes.any.isRequired,
  chartWrapperConfig: PropTypes.any.isRequired
}

export default TopReportedDashboard
