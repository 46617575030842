import globalMessages, { MICROSOFT_365 } from 'global/lib/i18n/en'

const ACCESS_TOKEN_CREATED_ON = 'Access token created on'
const ADMIN = 'Admin'
const ALL_ACCOUNTS = 'All accounts'
const CANCEL = 'Cancel'
const DEACTIVATE = 'Deactivate'
const DISMISS = 'Dismiss'
const IMPERSONATE = 'Impersonate'
const NO_TOKENS_FOUND = 'No access tokens found'
const OTHER_DETAILS = 'Other details'
const TRIAL = 'Trial'

/* eslint-disable @typescript-eslint/naming-convention, quotes */
export default {
  ...globalMessages,
  admin: {
    name: 'admin',
    title: 'ETS/IP/IR/DFP Admin',
    tabs: {
      dfp_name: 'Domain Fraud Protection',
      ets_name: 'Email Threat Scanner',
      fir_name: 'Incident Response',
      sen_name: 'Impersonation Protection',
      dfp_name_title: 'Domain Fraud Protection admin',
      ets_name_title: 'Email Threat Scanner admin',
      fir_name_title: 'Incident Response admin',
      sen_name_title: 'Impersonation Protection admin'
    },
    dialogs: {
      scan_dialog: {
        title: 'Access token information',
        serial_number: 'Serial number',
        serial_expire: 'Serial expiration',
        office_365_name: `${MICROSOFT_365} name`,
        office_365_app: `${MICROSOFT_365} app`,
        account_id: 'Account UUID',
        access_token_id: 'Access token UUID',
        scan_id: 'Scan UUID',
        bcc_account_id: 'BCC account ID',
        bcc_account_name: 'BCC account name',
        dismiss: DISMISS,
        trial: TRIAL,
        extend_trial: 'Extend trial by 14 days'
      },
      token_dialog: {
        title: 'Access token information',
        product_enable_date: 'Product enabled date',
        serial_number: 'Serial number',
        serial_expire: 'Serial expiration',
        office_365_name: `${MICROSOFT_365} name`,
        office_365_app: `${MICROSOFT_365} app`,
        account_id: 'Account UUID',
        access_token_id: 'Access token UUID',
        bcc_account_id: 'BCC account ID',
        bcc_account_name: 'BCC account name',
        dismiss: DISMISS,
        trial: TRIAL,
        extend_trial: 'Extend trial by 14 Days',
        add_serial_number: 'Add serial number'
      },
      incident_dialog: {
        title: 'Incident information',
        last_incident_created: 'Last incident created',
        mailbox_count: 'Mailbox count',
        dismiss: DISMISS,
        table: {
          statistic: 'Statistic',
          last_30_days: 'Last 30 days',
          lifetime: 'Lifetime'
        }
      },
      impersonate_dialog: {
        title: 'Entering impersonation mode',
        text: 'Impersonating <span>{email}</span>. Please wait for the page to be reloaded. It may take a few seconds.'
      },
      scary_dialog: {
        cancel: CANCEL,
        checkbox: 'I know this will stop protecting the customer and I still want to do this',
        deactivate: DEACTIVATE,
        label: 'Please confirm if you want to deactivate account with token <span>{accessTokenId}</span>',
        deactivate_single: 'Deactivate {productName} only',
        deactivate_all: 'Deactivate all products (IP, IR, DFP)',
        title: DEACTIVATE
      },
      deactivate_dialog: {
        title: 'Status',
        text: 'Deactivating scan <span>{accessTokenId}</span>. Please wait a few seconds.'
      },
      serial_number_dialog: {
        placeholder: 'Update serial number'
      },
      user_select_dialog: {
        cancel: CANCEL,
        impersonate: IMPERSONATE,
        title: 'Select user to impersonate'
      }
    },
    ets: {
      title: 'Metrics',
      subtitle: 'Does not include Barracuda and Sookasa users',
      accounts: 'ETS accounts',
      scans: 'Scans',
      mailboxes: 'Mailboxes scanned',
      attacks: 'Spear phishing attacks',
      search_field: {
        placeholder: 'Search ETS scans'
      },
      scans_title: 'Scans',
      scans_subtitle: 'Does not include Barracuda and Sookasa users',
      table: {
        created_on: 'Created on',
        triggered_by: 'Triggered by',
        user_tags: 'User tags',
        status: 'Status',
        office_365_name: `${MICROSOFT_365} name`,
        report: 'Report',
        mailboxes: 'Mailboxes',
        sp_attacks: 'SP attacks',
        emails: 'Emails',
        impersonate: IMPERSONATE,
        view_report: 'View report',
        other_details: OTHER_DETAILS,
        empty_table: 'No scans found',
        not_associated: 'Not associated',
        in_progress: 'In progress',
        finished_on: 'Finished on '
      }
    },
    sentinel: {
      title: 'Metrics',
      subtitle: 'Does not include Barracuda and Sookasa users',
      accounts: 'Impersonation Protection accounts',
      mailboxes: 'Mailboxes protected',
      attacks: 'Spear phishing attacks',
      tabs: {
        all_accounts: ALL_ACCOUNTS
      },
      search_field: {
        placeholder: 'Search Impersonation Protection accounts'
      },
      scans_title: 'Scans',
      scans_subtitle: 'Does not include Barracuda and Sookasa users',
      table: {
        created_on: 'Created on',
        admin: ADMIN,
        status: 'Status',
        office_365_name: `${MICROSOFT_365} account`,
        serial: 'Serial #',
        report: 'Report',
        history_report: 'History report',
        mailboxes: 'Mailboxes',
        past_attacks: 'Past attacks',
        new_attacks: 'New attacks',
        last_email_processed: 'Last email processed',
        impersonate: IMPERSONATE,
        view_report: 'View report',
        other_details: OTHER_DETAILS,
        empty_table: 'No scans found',
        not_associated: 'Not associated',
        bootstrapping: 'Bootstrapping',
        active: 'Active',
        deactivate: DEACTIVATE,
        serial_placeholder: 'Update serial number',
        trial: TRIAL
      }
    },
    fir: {
      tabs: {
        all_accounts: ALL_ACCOUNTS
      },
      search_field: {
        placeholder: 'Search Incident Response accounts'
      },
      table: {
        created_on: ACCESS_TOKEN_CREATED_ON,
        admin: ADMIN,
        office_365_name: `${MICROSOFT_365} account`,
        report: 'Report',
        incidents: 'Incidents',
        impersonate: IMPERSONATE,
        other_details: OTHER_DETAILS,
        empty_table: NO_TOKENS_FOUND,
        deactivate: DEACTIVATE
      }
    },
    dfp: {
      tabs: {
        all_accounts: ALL_ACCOUNTS
      },
      search_field: {
        placeholder: 'Search Domain Fraud Protection accounts'
      },
      table: {
        created_on: ACCESS_TOKEN_CREATED_ON,
        admin: ADMIN,
        office_365_name: `${MICROSOFT_365} account`,
        impersonate: IMPERSONATE,
        other_details: OTHER_DETAILS,
        empty_table: NO_TOKENS_FOUND,
        deactivate: DEACTIVATE
      }
    }
  }
}
/* eslint-enable @typescript-eslint/naming-convention, quotes */
