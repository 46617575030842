import { makeStyles, createStyles } from '@material-ui/core/styles'

import { COLORS, FONT } from 'global/configs/theme/defaults'
import commonStyles from 'global/components/features/signinSignup/styles/commonStyles'

const WRAPPER_WIDTH = 922
const FORM_AREA_WIDTH = 355

const styles = createStyles({
  wrapper: {
    alignItems: 'top',
    display: 'flex',
    fontFamily: '"Roboto", sans-serif',
    justifyContent: 'center',
    margin: 'auto',
    overflow: 'visible'
  },
  areaGrid: {
    paddingLeft: 32,
    paddingRight: 32
  },
  blueText: {
    color: COLORS.BLUE,
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  headingTitle: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '35px'
  }
})

export const SINGLE_COLUMN_WRAPPER_WIDTH = 450

export default makeStyles(theme => {
  const formSigninButton = {
    width: FORM_AREA_WIDTH,
    height: 32,
    borderRadius: 2,
    marginTop: theme.spacing(2)
  }

  return {
    root: {
      '@media screen and (max-width:960px)': {
        bottom: -310
      }
    },
    loginWrapper: {
      ...styles.wrapper,
      boxShadow: '0px 12px 24px 0px #00000033',
      width: WRAPPER_WIDTH,
      height: 'auto',
      flexWrap: 'nowrap',
      marginTop: theme.spacing(11.25),

      '& .info-area': {
        ...styles.areaGrid
      },
      '& .form-area': {
        ...styles.areaGrid
      },

      '@media screen and (max-width:960px)': {
        width: 'auto',
        maxWidth: SINGLE_COLUMN_WRAPPER_WIDTH,
        flexDirection: 'column',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(5),

        '& .info-area': {
          ...styles.areaGrid,
          width: 'auto',
          flexGrow: 1
        },
        '& .form-area': {
          ...styles.areaGrid,
          width: 'auto',
          flexGrow: 1
        }
      }
    },
    loginInnerWrapper: {
      padding: theme.spacing(4)
    },
    linearWrapper: {
      position: 'relative',
      top: 0
    },
    headingWrapper: {
      marginBottom: theme.spacing(5)
    },
    headingLogoWrapper: {
      justifyContent: 'center',
      marginBottom: theme.spacing(3.5)
    },
    headingTitle: {
      ...styles.headingTitle,
      paddingBottom: theme.spacing(1)
    },
    headingSubTitle: {
      fontSize: 18,
      lineHeight: `${theme.spacing(3)}px`
    },
    headingTitleSingleColumn: {
      ...styles.headingTitle
    },
    headingWrapperSingleColumn: {
      marginBottom: theme.spacing(3)
    },
    formTitle: {
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: 0.005,
      lineHeight: `${theme.spacing(3)}px`
    },
    formTitleWrapper: {
      marginBottom: theme.spacing(2)
    },
    formSubtitle: {
      fontSize: 14,
      fontWeight: 300,
      lineHeight: `${theme.spacing(3)}px`
    },
    formBlue: {
      ...styles.blueText
    },
    formBold: {
      fontWeight: FONT.weight.bold
    },
    formButton: {
      height: 40,
      width: FORM_AREA_WIDTH
    },
    formButtonContainer: {
      marginTop: 24,
      marginBottom: 16,
      display: 'flex'
    },
    formButtonSingleColumn: {
      height: 40,
      width: '100%'
    },
    formInputWrapper: {
      marginTop: theme.spacing(1),

      '& input': {
        height: theme.spacing(4),
        width: FORM_AREA_WIDTH,
        fontSize: 14,
        lineHeight: `${theme.spacing(3)}px`,
        boxShadow: 'inset 0px -1px 0px #DDDDDD'
      },
      '& .MuiTypography-root': {
        whiteSpace: 'nowrap',
        fontSize: 12,
        lineHeight: `${theme.spacing(2)}px`
      }
    },
    formInputText: {
      fontSize: 14,
      lineHeight: `${theme.spacing(3)}px`
    },
    formSigninTitle: {
      ...commonStyles.inputTitle
    },
    formSigninWrapper: {
      marginTop: theme.spacing(7)
    },
    formSigninWrapperSingleColumn: {
      marginTop: theme.spacing(3)
    },
    formForgotPasswordLink: {
      fontSize: 12,
      lineHeight: `${theme.spacing(2)}px`,
      marginTop: theme.spacing(1),
      fontWeight: 400
    },
    formSigninButton: {
      ...formSigninButton,
      background: '#7F7F82',

      '&:hover': {
        background: '#7F7F82 !important'
      }
    },
    sentinelSigninButton: {
      ...formSigninButton
    },
    infoContent: {
      marginBottom: theme.spacing(2)
    },
    infoContentLine: {
      marginBottom: theme.spacing(1)
    },
    senInfoContentLine: {
      marginBottom: theme.spacing(4)
    },
    infoCheckmarkIcon: {
      width: 17.59,
      height: 13.41,
      marginRight: theme.spacing(1)
    },
    infoContentTitle: {
      fontSize: 16,
      lineHeight: '24px',
      marginBottom: theme.spacing(2.5)
    },
    infoListItem: {
      display: 'inline-block',
      fontSize: 16,
      fontWeight: 400,
      lineHeight: `${theme.spacing(3)}px`
    },
    senInfoListItem: {
      display: 'inline-block',
      fontSize: 16,
      fontWeight: 400,
      lineHeight: `${theme.spacing(3)}px`,
      paddingLeft: 17,
      position: 'relative',

      '&::before': {
        fontSize: 20,
        // eslint-disable-next-line quotes
        content: "'•'",
        color: 'black',
        marginRight: 10,
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0
      }
    },
    infoWatchVideo: {
      alignItems: 'center',
      color: COLORS.BLUE,
      display: 'flex',
      fontWeight: 500,
      marginTop: theme.spacing(1.5)
    },
    infoWatchVideoText: {
      fontSize: 14,
      lineHeight: `${theme.spacing(2)}px`,
      fontWeight: 500,
      paddingLeft: theme.spacing(0.5),
      textTransform: 'unset !important' as 'unset',
      ...styles.blueText
    },
    infoCountBox: {
      paddingRight: theme.spacing(2.5)
    },
    infoCountBoxNumber: {
      color: COLORS.DARK_GRAY,
      fontSize: 14,
      fontWeight: 500,
      lineHeight: `${theme.spacing(3)}px`
    },
    infoCountBoxText: {
      color: COLORS.DARK_GRAY,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: `${theme.spacing(2)}px`
    },
    signinAlert: {
      marginBottom: 10
    },
    centerText: {
      fontSize: 14,
      margin: '0 auto',
      display: 'flex'
    },
    privacyText: {
      fontSize: 14
    },

    accountSwitcher: {
      marginTop: theme.spacing(2)
    },
    underline: {
      textDecoration: 'underline'
    },
    whiteText: {
      fontWeight: 'bold',
      textTransform: 'none',
      color: theme.palette.info.contrastText,
      fontSize: 12,
      lineHeight: '5px'
    }
  }
})
