import { config } from 'global/lib/config'
import { StatusIds } from 'global/components/lib/dmarcTypeLabel/DmarcTypeLabel'

export const DMARC_STATES = config.DOMAIN_RESOLVE_STATES.DMARC

export default function getDmarcType(status: string): StatusIds | undefined {
  switch (status) {
    case DMARC_STATES.UNVERIFIED:
      return StatusIds.unverified
    case DMARC_STATES.MISCONFIGURED:
    case DMARC_STATES.UNPROTECTED:
      return StatusIds.notConfigured
    case DMARC_STATES.REPORTING:
      return StatusIds.reportingOnly
    case DMARC_STATES.PROTECTED:
      return StatusIds.protected
    default:
      return undefined
  }
}
