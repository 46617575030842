import deepmerge from 'deepmerge'
import * as bdsTheme from '@barracuda-internal/bds-core/dist/styles/themes/baseMui'
import theme from 'global/theme'

const appTheme = deepmerge(bdsTheme.default, theme) as any

type CustomTheme = {
  [Key in keyof typeof appTheme]: typeof appTheme[Key]
}

declare module '@material-ui/core/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Theme extends CustomTheme {}
}
declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    border?: string
    black?: string
    hover?: string
    blue: {
      color: string
    }
    red: {
      color: string
    }
    pagination: {
      selected: string
    }
  }
  interface PaletteOptions {
    border?: string
    black?: string
    hover?: string
    blue: {
      color: string
    }
    red: {
      color: string
    }
    pagination: {
      selected: string
    }
  }
  interface TypeText {
    tertiary: string
    link: string
    light: string
  }
}
