enum ContestSource {
  ARROW = 'arrow',
  CDWC = 'cdwc',
  DMR = 'dmr',
  GBSC = 'gbsc',
  SYNNEX = 'synnex',
  TD = 'td',
  AWS_VAR = 'awsvar'
}

export default ContestSource
