import { makeStyles } from '@material-ui/core/styles'

import dialogStyles from 'ets/configs/styles/dialogStyles'
import { COLORS } from 'ets/configs/styles/defaults'

const DIALOG_WIDTH = 800

export default makeStyles(theme => {
  const defaultDialogStyles = dialogStyles(theme)

  return {
    ...defaultDialogStyles,
    paper: {
      minWidth: DIALOG_WIDTH
    },
    title: {
      ...defaultDialogStyles.title,
      fontWeight: 400
    },
    dmarcContainer: {
      paddingTop: theme.spacing(1)
    },
    dmarcEntry: {
      paddingRight: theme.spacing(1)
    },
    dmarcPolicyContainer: {
      paddingTop: theme.spacing(1),
      marginBottom: theme.spacing(1.5)
    },
    redText: {
      ...defaultDialogStyles.text,
      wordWrap: 'break-word',
      color: COLORS.ERROR
    },
    button: {
      height: 40,
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2)
    }
  }
})
