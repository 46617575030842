import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  chart: {
    height: 615,
    margin: theme.spacing(-10, 'auto', 2),
    position: 'relative',
    width: 984,
    zIndex: 0
  },
  chartHeader: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    position: 'relative',
    zIndex: 1,
    height: theme.spacing(1)
  },
  header: {
    margin: theme.spacing(2),
    position: 'relative',
    zIndex: 1
  },
  maskLayer: {
    backgroundColor: theme.palette.background.paper,
    bottom: 0,
    height: 15,
    position: 'absolute',
    width: '100%',
    zIndex: 1
  },
  pageAlert: {
    margin: theme.spacing(0, 2, 3, 2)
  },
  toggleGroup: {
    float: 'right'
  },
  root: {
    display: 'block',
    position: 'relative'
  },
  wrapper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 2,
    margin: theme.spacing(2, 2, 3, 2),
    overflow: 'hidden',
    position: 'relative'
  },
  // Date Range Overrides - START
  gridDateRangePicker: {
    position: 'absolute',
    padding: theme.spacing(0.5, 2, 0.5, 0),
    '& .k-textbox-container': {
      backgroundColor: theme.palette.info.contrastText,
      border: `1px solid ${theme.palette.border}`,
      borderRadius: '3px',
      height: '36px',
      width: theme.spacing(20),
      paddingTop: 0,
      '& > .k-label': {
        transform: 'translate(1px, -1px) translate(8px, -1.03125em) translate(-12.5%, -9.375%) scale(1)'
      },
      '&::after': {
        display: 'none'
      }
    },
    '& .k-dateinput-wrap': {
      border: '0',
      paddingLeft: theme.spacing(6)
    },
    '& .k-dateinput': {
      fontSize: theme.font.size.medium
    },
    '& .k-label': {
      display: 'none'
    }
  },
  gridDateRangePickerIcon: {
    margin: theme.spacing(1.5, 1, 1.5, 1.5)
  }
}))
