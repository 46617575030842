import React, { useMemo } from 'react'

import { Grid } from '@barracuda-internal/bds-core'
import ContentTabSelector, { TabConfig } from 'global/components/lib/contentTabSelector/ContentTabSelector'

import routesConfig from 'admin/lib/routes/routesConfig'
import useImpersonationProtectionLogic from 'admin/components/pages/dashboard/tabs/impersonationProtection/useImpersonationProtectionLogic'
import Metrics from 'admin/components/pages/dashboard/metrics/Metrics'
import styles from 'admin/components/pages/dashboard/tabs/impersonationProtection/impersonationProtectionStyles'
import AllAccounts from './tabs/allAccounts/AllAccounts'

const BASE_I18N_KEY = 'admin.app.sentinel'

const TABS: TabConfig[] = [[[routesConfig.DASHBOARD_SENTINEL], 'all_accounts', AllAccounts]]

const ImpersonationProtection: React.FC = () => {
  const [inProgress, cards] = useImpersonationProtectionLogic()
  const classes = styles()

  return useMemo(
    () => (
      <Grid>
        <Metrics inProgress={inProgress} cardData={cards} BASE_I18N_KEY={BASE_I18N_KEY} data-field="metrics" />
        <Grid className={classes.container} container direction="column" data-field="root-grid">
          <ContentTabSelector BASE_I18N_KEY={BASE_I18N_KEY} tabs={TABS} data-field="content-tab-selector" />
        </Grid>
      </Grid>
    ),
    [inProgress, cards, classes]
  )
}

export default ImpersonationProtection
