import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useParams, useLocation } from 'react-router-dom'

import queryString from 'query-string'
import Loading from 'global/components/lib/loading/Loading'
import { config } from 'global/lib/config'
import { useAppDispatch, useAppSelector } from 'global/redux/toolkit/hooks'
import { setActivePath } from 'global/redux/features/app/appSlice'
import { getUser, getForensicsDemoUser, getSentinelDemoUser } from 'global/redux/features/auth/authSlice'
import { UiRoute } from 'global/lib/routes/routesConfig'
import { setFeedbackPublicKey } from 'global/redux/features/attack/attackSlice'

export interface PublicRouteProps {
  Component: any
  route: UiRoute
  disableUserCheck?: boolean
}

export default function publicRoute(
  customRenderComponent?: (component: any, childProps: any, route: any) => JSX.Element
): React.FC<PublicRouteProps> {
  const PublicRoute: React.FC<PublicRouteProps> = ({ Component, route, disableUserCheck }) => {
    const dispatch = useAppDispatch()
    const params = useParams()
    const location = useLocation()
    const { isUserDataLoaded, justLoggedOut }: any = useAppSelector((_stores: any) => ({
      isUserDataLoaded: _stores.user.isUserDataLoaded,
      justLoggedOut: _stores.app.justLoggedOut
    }))
    const [shouldRenderComponent, setShouldRenderComponent] = useState(false)
    const isDemoPage = location.pathname.includes('/forensics-demo') || location.pathname.includes('/sentinel-demo')
    const queryParams = queryString.parse(location.search)
    const isDomainFraudPage = queryParams?.provider === 'onprem' || queryParams?.product === 'dp'
    const isUnregisteredPage = location.pathname === '*' && !isDemoPage

    // reset when path is changed
    useEffect(() => {
      return () => {
        setShouldRenderComponent(false)
      }
    }, [location.pathname])

    // init
    const init = useCallback(() => {
      if (isDemoPage) {
        if (config.domainConfig.isForensics) {
          dispatch(getForensicsDemoUser())
        } else {
          dispatch(getSentinelDemoUser())
        }
      } else if (!justLoggedOut && !isUnregisteredPage && !disableUserCheck) {
        if (location.pathname.includes('/feedback')) {
          dispatch(setFeedbackPublicKey(location.pathname.split('/feedback/')[1]))
        }
        dispatch(getUser(isDomainFraudPage))
      } else {
        setShouldRenderComponent(true)
      }
    }, [
      disableUserCheck,
      dispatch,
      isDemoPage,
      isDomainFraudPage,
      isUnregisteredPage,
      justLoggedOut,
      location.pathname
    ])

    useEffect(() => {
      init()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUnregisteredPage, location.pathname])

    useEffect(() => {
      if (!isUnregisteredPage) {
        dispatch(
          setActivePath({
            id: route.id || '',
            url: location.pathname,
            params,
            isNavbarVisible: false,
            isPublicRoute: true
          } as any)
        )
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, location.pathname, route.id, isUnregisteredPage])

    const RenderComponent = useCallback(
      (childProps: any) => {
        if (customRenderComponent) {
          return customRenderComponent(Component, childProps, route)
        }

        return <Component {...childProps} />
      },
      [Component, route]
    )

    return useMemo(() => {
      if (!shouldRenderComponent && !isUserDataLoaded && !isUnregisteredPage) {
        return <Loading />
      }

      if (isDemoPage) {
        return null
      }

      return <RenderComponent route={route} />
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldRenderComponent, isUserDataLoaded, isUnregisteredPage, isDemoPage, location.pathname, route.id])
  }

  return PublicRoute
}
