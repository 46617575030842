import React from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@barracuda-internal/bds-core'

import styles from './onOffLabelStyles'

export interface Props {
  enabledText?: string
  disabledText?: string
  isEnabled: boolean
}

export const DEFAULTS = {
  ON: 'On',
  OFF: 'Off'
}

const OnOffLabel: React.FC<Props> = ({ enabledText, disabledText, isEnabled }): JSX.Element => {
  const classes = styles()

  return (
    <>
      <Typography component="div" className={classes.wrapper}>
        <div className={isEnabled ? classes.enabled : classes.disabled}>
          <div className={classes.textWrapper}>{isEnabled ? enabledText : disabledText}</div>
        </div>
      </Typography>
    </>
  )
}

OnOffLabel.propTypes = {
  enabledText: PropTypes.string,
  disabledText: PropTypes.string,
  isEnabled: PropTypes.bool.isRequired
}

OnOffLabel.defaultProps = {
  enabledText: DEFAULTS.ON,
  disabledText: DEFAULTS.OFF
}

export default OnOffLabel
