import { AtoAlertStatus } from 'global/lib/getAtoAlertStatus/getAtoAlertStatus'
import { StatusIds } from 'global/components/lib/statusTypeLabel/StatusIds.enum'

export interface AlertSummary {
  accessTokenId: string
  report: {
    latestAlertDate: string
    openAlertsCount: number
  }
}

export interface Identity {
  deleted: boolean
  displayName: string
  hasMailbox: boolean
  id: string
  lastSentDate: Date
  updatedOn: Date
  userPrincipalName: string
}
export enum AlertStatus {
  open = 'o',
  close = 'c'
}

export interface Alert {
  createdDay: string
  createdOn: Date
  displayName: string
  emailAddress: string
  id: string
  identity: Identity
  markedAsFp: boolean
  sampleLoginIp: string | null
  sampleLoginId: string | null
  sampleRuleId: string
  sampleSubject: string | null
  sampleThreatCtaDomain: string | null
  sampleThreatId: string | null
  status: AlertStatus
}

export interface ModifiedAlert extends Alert {
  formattedDate: string
  alertStatus: StatusIds
  atoAlertStatus: AtoAlertStatus
}

export interface InboxRuleRecipient {
  emailAddress: {
    address: string
  }
}

export interface InboxRuleActions {
  assignCategories?: string[]
  copyToFolder?: string
  delete?: boolean
  forwardAsAttachmentTo?: InboxRuleRecipient[]
  forwardTo?: InboxRuleRecipient[]
  markAsRead?: boolean
  markImportance?: string
  moveToFolder?: string
  permanentDelete?: boolean
  redirectTo?: InboxRuleRecipient[]
  stopProcessingRules?: boolean
}

export interface InboxRuleConditions {
  bodyContains?: string[]
  bodyOrSubjectContains?: string[]
  categories?: string[]
  fromAddresses?: InboxRuleRecipient
  hasAttachments?: boolean
  headerContains?: string[]
  importance?: string
  isApprovalRequest?: boolean
  isAutomaticForward?: boolean
  isAutomaticReply?: boolean
  isEncrypted?: boolean
  isMeetingRequest?: boolean
  isMeetingResponse?: boolean
  isNonDeliveryReport?: boolean
  isPermissionControlled?: boolean
  isReadReceipt?: boolean
  isSigned?: boolean
  isVoicemail?: boolean
  messageActionFlag?: string
  notSentToMe?: boolean
  recipientContains?: string[]
  senderContains?: string[]
  sensitivity?: string
  sentCcMe?: boolean
  sentOnlyToMe?: boolean
  sentToAddresses?: InboxRuleRecipient[]
  sentToMe?: boolean
  sentToOrCcMe?: boolean
  subjectContains?: string[]
  withinSizeRange?: boolean
}

export interface AlertInboxRule {
  actions: InboxRuleActions | null
  conditions: InboxRuleConditions | null
  date: Date
  detectionType: 'SR'
  enabled: boolean
  exceptions: null | string
  name: string
  ruleId: string
  sequenceNumber: number
}

export interface AlertSignin {
  country: string
  date: Date
  detectionType: string
  ip: string
  loginId: string
  userAgent: string
  status: AlertStatus
}

export interface AlertThreat {
  dateReceived: Date
  dateSent: Date
  recipient: {
    email: string
    displayName: string
  }
  displayName: string
  email: string
  recipientCount: number
  subject: string
  threatCtaDomain: string
  threatId: string
}

export interface Attachment {
  contentType: string
  id?: string
  isInline: boolean
  name: string
  size: string
}

export interface Header {
  name: string
  value: string
}

export interface AlertDetails {
  attachments: Attachment[]
  body: string
  bodyMimeType: string
  headers: Header[]
}

export interface Location {
  lat: number
  lng: number
}

export interface SigninByAllCountries {
  country: string
  failedLoginCount: number
  locations: Location
  name: string
  signInCount: number
}

export interface SigninByCountry {
  country: string
  date: string
  detectionType: string | null
  ip: string
  loginId: string
  status: boolean
  userAgent: string
  userPrincipalName: string
}

export interface AtoReport {
  accessTokenId: string
}

export interface ResetPassword {
  newPassword: string
}

export type AlertsReport = AtoReport & {
  report: {
    data: Alert[]
    totalCount: number
  }
}

export type AlertInboxRulesReport = AtoReport & {
  report: {
    data: AlertInboxRule[]
    totalCount: number
  }
}

export type AlertSigninsReport = AtoReport & {
  report: {
    data: AlertSignin[]
    totalCount: number
  }
}

export type AlertThreatsReport = AtoReport & {
  report: {
    data: AlertThreat[]
    totalCount: number
  }
}

export type SigninsByAllCountriesReport = AtoReport & {
  report: {
    countries: SigninByAllCountries[]
  }
}

export type SigninsByCountrysReport = AtoReport & {
  report: {
    data: SigninByCountry[]
    totalCount: number
  }
}
