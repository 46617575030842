export const CURIO_CONTAINS = 'CURIO_CONTAINS'
export const CURIO_CONTAINS_PHRASE = 'CURIO_CONTAINS_PHRASE'
export const DJANGO_CONTAINS = 'DJANGO_CONTAINS'
export const DJANGO_DOES_NOT_CONTAIN = 'DJANGO_DOES_NOT_CONTAIN'
export const DJANGO_REMEDIATED = 'DJANGO_REMEDIATED'
export const DJANGO_NOT_REMEDIATED = 'DJANGO_NOT_REMEDIATED'
export const KOTLIN_CONTAINS = 'KOTLIN_CONTAINS'
export const KOTLIN_DOES_NOT_CONTAIN = 'KOTLIN_DOES_NOT_CONTAIN'

export default {
  [CURIO_CONTAINS]: 'contains',
  [CURIO_CONTAINS_PHRASE]: 'contains-phrase',
  [DJANGO_CONTAINS]: '=',
  [DJANGO_DOES_NOT_CONTAIN]: '!=',
  [DJANGO_REMEDIATED]: '=',
  [DJANGO_NOT_REMEDIATED]: '=',
  [KOTLIN_CONTAINS]: 'contains',
  [KOTLIN_DOES_NOT_CONTAIN]: 'not_contains'
} as any

// Creates a filter object for curio backends, this needs to be tested when we implement filtering for Curio
export const curioFilters = (dataTableFilter: any) => {
  if (dataTableFilter) {
    const filterObject = dataTableFilter.filter.filters.map((filtersByColumn: any) => {
      return filtersByColumn.filters.map((columnFilters: any) => {
        return `(and (${columnFilters.operator} type \\"${columnFilters.field}\\") )`
      })
    })

    return filterObject
  }

  return []
}

// Creates a filter object for Django backends
export const djangoFilters = (dataTableFilter: any) => {
  if (dataTableFilter) {
    const filterObject = dataTableFilter.filters.map((filtersByColumn: any) => {
      return filtersByColumn.filters.map((columnFilters: any) => {
        return `${columnFilters.field}${columnFilters.operator}${columnFilters.value}`
      })
    })
    return filterObject
  }

  return []
}

// Creates a filter object for Kotlin backends
export const kotlinFilters = (dataTableFilter: any) => {
  if (dataTableFilter) {
    const filterObject = dataTableFilter.filters.map((filtersByColumn: any) => {
      return filtersByColumn.filters.map((columnFilters: any) => {
        return `${columnFilters.operator},${columnFilters.field},${columnFilters.value}`
      })
    })
    return filterObject
  }

  return []
}
