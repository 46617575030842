import { useEffect, useMemo } from 'react'

import { config } from 'global/lib/config'
import { SettingsValue } from 'global/types/api/accessTokenType'

import routesConfig from 'fir/lib/routes/routesConfig'
import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'
import { getForensicsBootstrapProgress } from 'fir/redux/features/search/searchApiThunks'

const STATUS_REFRESH_INTERVAL = config.autoRefresh.etsReportRefreshFreqMillis

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OnboardingProps {}

export default function useOnboardingLogic(): [OnboardingProps] {
  const appDispatch = useAppDispatch()
  const { accessTokenId, accessTokenSettings }: any = useAppSelector((_stores: any) => ({
    accessTokenId: _stores.accessToken.accessToken?.id,
    accessTokenSettings: _stores.accessToken.accessToken?.settings || {}
  }))

  // Periodic refresh
  useEffect(() => {
    // Fetch the forensics bootstrap progress if the onboarding is in progress
    if (
      (accessTokenSettings.forensicsBootstrapProgress === SettingsValue.on ||
        accessTokenSettings.ForensicsOnboardingInQueue === SettingsValue.on) &&
      accessTokenId
    ) {
      appDispatch(getForensicsBootstrapProgress({ accessTokenId }))
    }

    // Refresh the status every STATUS_REFRESH_INTERVAL (default 3 minutes, defaultAutoRefreshFrequency)
    const refreshInterval = setInterval(() => {
      if (
        (accessTokenSettings.forensicsBootstrapProgress === SettingsValue.on ||
          accessTokenSettings.ForensicsOnboardingInQueue === SettingsValue.on) &&
        accessTokenId
      ) {
        appDispatch(getForensicsBootstrapProgress({ accessTokenId }))
      }
    }, STATUS_REFRESH_INTERVAL)

    return () => {
      clearInterval(refreshInterval)
      routesConfig.REMEDIATION.goto()
    }
  }, [
    accessTokenId,
    accessTokenSettings.ForensicsOnboardingInQueue,
    accessTokenSettings.forensicsBootstrapProgress,
    appDispatch
  ])

  return useMemo(() => [{}], [])
}
