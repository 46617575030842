import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { v4 as uuidv4 } from 'uuid'

import { DataTable, DataTableColumn, Typography, Grid } from '@barracuda-internal/bds-core'

import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'

import { Alert } from 'global/types/api/ato'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import Pager from 'global/components/lib/grid/pager/Pager'
import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'
import { useFormatMessage } from 'global/lib/localization'

import styles from 'sen/components/lib/dialogs/alertDialog/tabs/alertTabStyles'
import useAlertInboxRulesLogic, {
  ModifiedAlertInboxRule
} from 'sen/components/lib/dialogs/alertDialog/tabs/useAlertInboxRulesLogic'

export interface AlertInboxRulesProps {
  alert: Alert
}

const BASE_I18N_TABLE_KEY = 'sen.app.data_tables.alert_inbox_rules'

export const AlertInboxRules: React.FC<AlertInboxRulesProps> = ({ alert }) => {
  const formatMessageTable = useFormatMessage(BASE_I18N_TABLE_KEY)
  const classes = styles()
  const [inProgress, tableConfig] = useAlertInboxRulesLogic(alert)

  return useMemo(
    () => (
      <Grid container direction="column">
        <Grid className={classes.flexibleTableArea} data-table="alert-inbox-rules" item xs={12}>
          {inProgress && <LinearProgress className={classes.indicator} />}

          <DataTable
            className={classes.flexibleDataTable}
            data={tableConfig.tableData}
            {...tableConfig.pageConfig}
            pager={Pager}
          >
            <DataTableNoRecords data-field="empty-table">
              <Typography>{!tableConfig.tableData.total && formatMessageTable('empty_table')}</Typography>
            </DataTableNoRecords>

            <DataTableColumn
              width={110}
              field={tableConfig.columns.DATE}
              {...tableConfig.columnsConfig[tableConfig.columns.DATE]}
              title={formatMessageTable(tableConfig.columns.DATE)}
              cell={({ dataItem }: { dataItem: ModifiedAlertInboxRule }) => (
                <Cell className={classes.flexibleCell} data-field="table-column-date">
                  <CellText className={classes.normalCell} data-field="table-column-date-text">
                    {dataItem.formattedDate}
                  </CellText>
                </Cell>
              )}
            />
            <DataTableColumn
              width={110}
              field={tableConfig.columns.SEQUENCE}
              {...tableConfig.columnsConfig[tableConfig.columns.SEQUENCE]}
              title={formatMessageTable(tableConfig.columns.SEQUENCE)}
              cell={({ dataItem }: { dataItem: ModifiedAlertInboxRule }) => (
                <Cell className={classes.flexibleCell} data-field="table-column-sequence">
                  <CellText className={classes.normalCell} data-field="table-column-sequence-text">
                    {dataItem.sequenceNumber}
                  </CellText>
                </Cell>
              )}
            />
            <DataTableColumn
              field={tableConfig.columns.NAME}
              {...tableConfig.columnsConfig[tableConfig.columns.NAME]}
              title={formatMessageTable(tableConfig.columns.NAME)}
              cell={({ dataItem }: { dataItem: ModifiedAlertInboxRule }) => (
                <Cell className={classes.flexibleCell} data-field="table-column-name">
                  <CellText className={classes.normalCell} data-field="table-column-name-text">
                    {dataItem.name}
                  </CellText>
                </Cell>
              )}
            />
            <DataTableColumn
              field={tableConfig.columns.ACTIONS}
              {...tableConfig.columnsConfig[tableConfig.columns.ACTIONS]}
              title={formatMessageTable(tableConfig.columns.ACTIONS)}
              cell={({ dataItem }: { dataItem: ModifiedAlertInboxRule }) => (
                <Cell className={classes.flexibleCell} data-field="table-column-actions">
                  <CellText className={classes.breakWordCell} data-field="table-column-actions-text">
                    {dataItem.formattedActions
                      .reduce((all: any[], action: string) => {
                        return [...all, action, <br key={uuidv4()} />]
                      }, [])
                      .slice(0, -1)}
                  </CellText>
                </Cell>
              )}
            />
            <DataTableColumn
              field={tableConfig.columns.CONDITIONS}
              {...tableConfig.columnsConfig[tableConfig.columns.CONDITIONS]}
              title={formatMessageTable(tableConfig.columns.CONDITIONS)}
              cell={({ dataItem }: { dataItem: ModifiedAlertInboxRule }) => (
                <Cell className={classes.flexibleCell} data-field="table-column-conditions">
                  <CellText className={classes.breakWordCell} data-field="table-column-conditions-text">
                    {dataItem.formattedConditions
                      .reduce((all: any[], action: string) => {
                        return [...all, action, <br key={uuidv4()} />]
                      }, [])
                      .slice(0, -1)}
                  </CellText>
                </Cell>
              )}
            />
            <DataTableColumn
              width={110}
              field={tableConfig.columns.EXCEPTIONS}
              {...tableConfig.columnsConfig[tableConfig.columns.EXCEPTIONS]}
              title={formatMessageTable(tableConfig.columns.EXCEPTIONS)}
              cell={({ dataItem }: { dataItem: ModifiedAlertInboxRule }) => (
                <Cell className={classes.flexibleCell} data-field="table-column-exceptions">
                  <CellText className={classes.normalCell} data-field="table-column-exceptions-text">
                    {formatMessageTable(dataItem.exceptions ? 'yes' : 'no')}
                  </CellText>
                </Cell>
              )}
            />
            <DataTableColumn
              width={110}
              field={tableConfig.columns.ENABLED}
              {...tableConfig.columnsConfig[tableConfig.columns.ENABLED]}
              title={formatMessageTable(tableConfig.columns.ENABLED)}
              cell={({ dataItem }: { dataItem: ModifiedAlertInboxRule }) => (
                <Cell className={classes.flexibleCell} data-field="table-column-enabled">
                  <CellText className={classes.normalCell} data-field="table-column-enabled-text">
                    {formatMessageTable(dataItem.enabled ? 'yes' : 'no')}
                  </CellText>
                </Cell>
              )}
            />
          </DataTable>
        </Grid>
      </Grid>
    ),
    [classes, formatMessageTable, inProgress, tableConfig]
  )
}

AlertInboxRules.propTypes = {
  alert: PropTypes.any.isRequired
}

export default AlertInboxRules
