export interface ScheduledReport {
  createdOn: string
  enabled: boolean
  format: 'CSV' | 'PDF'
  frequency: 'DAILY' | 'WEEKLY' | 'MONTHLY'
  frequencyDayOfMonth: number
  frequencyDayOfWeek: number
  frequencyMonthly: 'FIRST' | 'SECOND' | 'THIRD' | 'FOURTH' | 'LAST' | 'SPECIFIC_DATE'
  frequencyTime: string // '19:00' only hour is used
  lastModifiedOn: string
  lastRun: string
  nextRun: string
  product: 'IR' | 'IP'
  recipients: string[]
  relativeDateRange: 'LAST_DAY' | 'LAST_3_DAYS' | 'LAST_7_DAYS' | 'LAST_30_DAYS' | 'LAST_90_DAYS' | 'LAST_6_MONTHS'
  reportName: string
  reportUuid: string
  uuid: string
  zoneId: string
}

export interface ScheduledReportsDrawerType {
  enabled: boolean
  format: 'CSV' | 'PDF'
  frequency: 'DAILY' | 'WEEKLY' | 'MONTHLY'
  frequencyDayOfMonth: number
  frequencyDayOfWeek: number
  frequencyMonthly: 'FIRST' | 'SECOND' | 'THIRD' | 'FOURTH' | 'LAST' | 'SPECIFIC_DATE'
  frequencyTime: string
  isReportNameFixed: boolean
  lastModifiedOn: string
  recipients: string[]
  relativeDateRange: 'LAST_DAY' | 'LAST_3_DAYS' | 'LAST_7_DAYS' | 'LAST_30_DAYS' | 'LAST_90_DAYS' | 'LAST_6_MONTHS'
  reportName: string
  reportUuid: string
  uuid: string
  zoneId: string
}

export interface ReportNameDropDown {
  reportId: string
  reportName: string
}

export enum FrequencyMonthly {
  first = 'FIRST',
  second = 'SECOND',
  third = 'THIRD',
  fourth = 'FOURTH',
  last = 'LAST',
  specificDate = 'SPECIFIC_DATE'
}

export enum WeekDays {
  su = 'Su',
  mo = 'Mo',
  tu = 'Tu',
  we = 'We',
  th = 'Th',
  fr = 'Fr',
  sa = 'Sa'
}

export enum Frequency {
  daily = 'DAILY',
  weekly = 'WEEKLY',
  monthly = 'MONTHLY'
}

export enum ReportFormat {
  CSV = 'CSV',
  PDF = 'PDF'
}

export enum TimeRange {
  lastDay = 'LAST_DAY',
  last3Days = 'LAST_3_DAYS',
  last7Days = 'LAST_7_DAYS',
  last30Days = 'LAST_30_DAYS',
  last90Days = 'LAST_90_DAYS',
  last6Months = 'LAST_6_MONTHS'
}
