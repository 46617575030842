import { Theme, createStyles } from '@material-ui/core/styles'

import { COLORS } from 'global/configs/theme/defaults'

export default function defaultDialogStyles(theme: Theme) {
  return createStyles({
    root: {},
    titleWrapper: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    title: {
      color: theme.palette.text.primary,
      fontSize: 20,
      fontWeight: 300,
      letterSpacing: 0,
      lineHeight: '32px'
    },
    subtitle: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      color: COLORS.GRAY,
      fontSize: 14,
      fontWeight: 300,
      letterSpacing: 0,
      lineHeight: '16px'
    },
    error: {
      marginBottom: theme.spacing(2)
    },
    loading: {
      margin: `${theme.spacing(5)}px 0`
    },
    text: {
      color: theme.palette.text.primary,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: '22px'
    },
    closeButton: {
      paddingLeft: 22,
      paddingRight: 22
    }
  })
}
