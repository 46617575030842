/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react'

import {
  Typography,
  Button,
  DataTable,
  DataTableColumn,
  Grid,
  Paper,
  Tooltip,
  Alert as AlertComponent
} from '@barracuda-internal/bds-core'
import { Critical as CriticalIcon } from '@barracuda-internal/bds-core/dist/Icons/Feedback'
import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'

import { useFormatMessage, useErrorFormatMessage } from 'global/lib/localization'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'
import CircularProgress from 'global/components/lib/circularProgress/CircularProgress'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import Pager from 'global/components/lib/grid/pager/Pager'
import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'
import { ModifiedInboxRuleFeed } from 'global/types/api/remediation'

import IdentityAutocomplete from 'sen/components/lib/autocompletes/identity/IdentityAutocomplete'
import styles from 'sen/components/pages/ato/content/tabs/tabStyles'
import useInboxRulesLogic from 'sen/components/pages/ato/content/tabs/inboxRules/useInboxRulesLogic'
import InboxRulesDialog from 'sen/components/lib/dialogs/inboxRules/InboxRulesDialog'
import RangeInboxRulesDialog from 'sen/components/lib/dialogs/inboxRules/RangeInboxRulesDialog'

const BASE_I18N_KEY = 'sen.app.account_takeover.inbox_rules'
const BASE_I18N_TABLE_KEY = 'sen.app.data_tables.inbox_rule_feed_table'
const BASE_RECIPIENTS_AUTOCOMPLETE_I18N_KEY = 'sen.app.autocompletes.recipients'

const InboxRules: React.FC = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const tableFormatMessage = useFormatMessage(BASE_I18N_TABLE_KEY)
  const formatMessageError = useErrorFormatMessage()
  const classes = styles()
  const [
    permission,
    recipientsSelector,
    tableConfig,
    eventHandlers,
    rangeInboxRulesDialog,
    error
  ] = useInboxRulesLogic()
  const [isInboxRulesDialogOpened, toggleInboxRulesDialog] = useDialogLogic()

  return useMemo(
    () => (
      <Grid container direction="column" data-field="inbox-rules-grid">
        {error && (
          <AlertComponent variant="filled" severity="error" data-field="alert" data-testid="inbox-rules-alert">
            {formatMessageError(error)}
          </AlertComponent>
        )}

        {!permission.isChecked && (
          <Grid container justifyContent="center" className={classes.contentLoading} data-field="loader">
            <CircularProgress />
          </Grid>
        )}

        {permission.isChecked && permission.hasReadActivityFeedPermission && (
          <>
            <Grid item data-field="inbox-rules-grid-title">
              <Typography className={classes.title} data-field="inbox-rules-title">
                {formatMessage('title')}
              </Typography>
              <Typography className={classes.subtitle} variant="subtitle2" data-field="inbox-rules-subtitle">
                {formatMessage('subtitle')}
              </Typography>
            </Grid>
            <Grid item data-field="inbox-rules-investigate-mailbox">
              <Paper className={classes.autocompleteContainer} elevation={0}>
                <Grid container direction="row" alignItems="center" justifyContent="center">
                  <Grid item xs data-testid="autocomplete-wrapper">
                    <IdentityAutocomplete
                      {...recipientsSelector.config}
                      BASE_I18N_KEY={BASE_RECIPIENTS_AUTOCOMPLETE_I18N_KEY}
                      inProgress={false}
                      dataTestid="inbox-rules-identities"
                    />
                  </Grid>
                  <Grid item xs="auto">
                    <Button
                      color="primary"
                      size="large"
                      className={classes.autocompleteButton}
                      disabled={!recipientsSelector.selectedRecipient}
                      onClick={toggleInboxRulesDialog}
                      data-field="view-inbox-rules"
                    >
                      &nbsp;&nbsp;{formatMessage('view_inbox_rules')}&nbsp;&nbsp;
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item className={classes.subContent} data-field="inbox-rule-feed-grid-title">
              <Typography className={classes.title} data-field="inbox-rule-feed-title">
                {formatMessage('feed_title')}
              </Typography>
              <Typography className={classes.subtitle} variant="subtitle2" data-field="inbox-rule-feed-subtitle">
                {formatMessage('feed_subtitle')}
              </Typography>
            </Grid>

            <Paper className={classes.tableCard} elevation={0}>
              {!tableConfig.isLoaded && (
                <Grid
                  container
                  justifyContent="center"
                  className={classes.tableCircularProgress}
                  data-field="circular-progress"
                >
                  <CircularProgress data-field="loader" />
                </Grid>
              )}

              {tableConfig.isLoaded && (
                <Grid
                  container
                  direction="column"
                  className={classes.tableWrapper}
                  data-field="inbox-rule-feed-table-wrapper"
                >
                  <Grid
                    className={`${
                      tableConfig.isFlexibleTable ? classes.flexibleTableArea : classes.inboxFeedTableArea
                    } ${!tableConfig.tableData.total ? 'empty-table' : ''}`}
                    data-table="signins-by-country"
                    item
                    xs={12}
                    data-field="grid-table"
                  >
                    {tableConfig.inProgress && (
                      <LinearProgress className={classes.indicator} data-field="linear-progress" />
                    )}
                    <DataTable
                      data-testid="signins-by-country-table"
                      className={`${
                        tableConfig.tableData.total
                          ? tableConfig.isFlexibleTable
                            ? classes.flexibleDataTable
                            : classes.inboxFeedDataTable
                          : classes.emptyDataTable
                      } signin-by-country-table`}
                      data={tableConfig.tableData}
                      {...tableConfig.pageConfig}
                      pager={Pager}
                      data-field="data-table"
                    >
                      <DataTableNoRecords data-field="empty-table">
                        <Typography>
                          {!tableConfig.inProgress && !tableConfig.tableData.total && tableFormatMessage('empty_table')}
                        </Typography>
                      </DataTableNoRecords>
                      <DataTableColumn
                        field={tableConfig.columns.USER}
                        {...tableConfig.columnsConfig[tableConfig.columns.USER]}
                        title={tableFormatMessage(tableConfig.columns.USER)}
                        cell={({ dataItem }: { dataItem: ModifiedInboxRuleFeed }) => (
                          <Cell className={classes.cell} data-field="table-column-user">
                            <Tooltip
                              classes={{ tooltip: classes.tooltip }}
                              title={
                                <div>
                                  <b>{dataItem.userDisplayName}</b>
                                  {dataItem.userPrincipalName}
                                  {dataItem.formattedDate}
                                </div>
                              }
                              placement="top-start"
                              data-field="table-column-email-tooltip"
                            >
                              <span>
                                <CellText
                                  ellipsis
                                  disableTitle
                                  className={classes.boldCell}
                                  data-field="table-column-user-display-name"
                                >
                                  {dataItem.userDisplayName}
                                </CellText>
                                <br />
                                <CellText
                                  ellipsis
                                  disableTitle
                                  className={classes.lightCell}
                                  data-field="table-column-user-email"
                                >
                                  {dataItem.userPrincipalName}
                                </CellText>
                                <br />
                                <CellText
                                  ellipsis
                                  disableTitle
                                  className={classes.boldCell}
                                  data-field="table-column-user-date"
                                >
                                  {dataItem.formattedDate}
                                </CellText>
                              </span>
                            </Tooltip>
                          </Cell>
                        )}
                      />
                      <DataTableColumn
                        field={tableConfig.columns.ACTIONS}
                        {...tableConfig.columnsConfig[tableConfig.columns.ACTIONS]}
                        title={tableFormatMessage(tableConfig.columns.ACTIONS)}
                        cell={({ dataItem }: { dataItem: ModifiedInboxRuleFeed }) => (
                          <Cell className={classes.cell} data-field="table-column-actions">
                            <CellText
                              className={classes.normalCell}
                              data-field="table-column-actions-text"
                              data-action={dataItem.formattedAction}
                            >
                              {dataItem.formattedAction && tableFormatMessage(dataItem.formattedAction)}
                            </CellText>
                          </Cell>
                        )}
                      />
                      <DataTableColumn
                        field={tableConfig.columns.INVES}
                        {...tableConfig.columnsConfig[tableConfig.columns.INVESTIGATE]}
                        title=" "
                        cell={({ dataItem }: { dataItem: ModifiedInboxRuleFeed }) => (
                          <Cell className={classes.cell} data-field="table-column-actions-investigate">
                            <Grid container direction="row" justifyContent="center">
                              <Button
                                color="primary"
                                size="large"
                                onClick={() => {
                                  eventHandlers.onInvestigate(dataItem)
                                }}
                                data-field="on-investigate-button"
                              >
                                {tableFormatMessage('investigate')}
                              </Button>
                            </Grid>
                          </Cell>
                        )}
                      />
                    </DataTable>
                  </Grid>
                </Grid>
              )}
            </Paper>
          </>
        )}

        {permission.isChecked && !permission.hasReadActivityFeedPermission && (
          <Grid container className={classes.subContent} direction="row" data-field="add-permission-row">
            <Grid item xs={1}>
              <Grid container justifyContent="center" alignItems="center">
                <CriticalIcon data-field="add-permission-critical" className={classes.warning} />
              </Grid>
            </Grid>
            <Grid item xs>
              {formatMessage('missed_permission', {
                br: (txt: string) => (
                  <data key={txt}>
                    <br />
                    {txt}
                    <br />
                  </data>
                )
              })}
              <Button
                color="primary"
                size="large"
                data-field="add-permission-button"
                onClick={permission.reconnectO365}
              >
                {formatMessage('add_permission')}
              </Button>
            </Grid>
          </Grid>
        )}

        {isInboxRulesDialogOpened && (
          <InboxRulesDialog
            userPrincipalName={recipientsSelector.selectedRecipient?.email || ''}
            onClose={toggleInboxRulesDialog}
          />
        )}

        {rangeInboxRulesDialog.isDialogOpened && rangeInboxRulesDialog.selectedFeed && (
          <RangeInboxRulesDialog
            feed={rangeInboxRulesDialog.selectedFeed}
            onClose={rangeInboxRulesDialog.toggleDialog}
          />
        )}
      </Grid>
    ),
    [
      classes,
      formatMessage,
      recipientsSelector,
      isInboxRulesDialogOpened,
      toggleInboxRulesDialog,
      permission,
      eventHandlers,
      tableConfig,
      tableFormatMessage,
      formatMessageError,
      rangeInboxRulesDialog,
      error
    ]
  )
}

export default InboxRules
