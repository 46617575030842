import { isObject } from 'lodash'
import { useIntl } from 'react-intl'
import { useCallback } from 'react'
import { config } from 'global/lib/config'

const APP_NAMESPACE = 'app'

interface Messages {
  [key: string]: {} | string
}

interface MessagesCollection {
  [key: string]: {}
}

export interface Localization {
  locale: string
  messages: {}
}

function collectAppMessages(messages: Messages) {
  function stringifyKeys(messagesBlock: MessagesCollection, prefix = ''): MessagesCollection {
    const updatedPrefix = prefix === `${config.NAME}.` ? `${prefix}${APP_NAMESPACE}.` : prefix

    return Object.keys(messagesBlock).reduce((all, messageId) => {
      if (isObject(messagesBlock[messageId])) {
        return {
          ...all,
          ...stringifyKeys(messagesBlock[messageId], `${updatedPrefix}${messageId}.`)
        }
      }

      return {
        ...all,
        [`${updatedPrefix}${messageId}`]: messagesBlock[messageId]
      }
    }, {})
  }

  const collectedMessages = Object.keys(messages).reduce((all, messageId) => {
    if (messageId.includes(config.NAME) || messageId === APP_NAMESPACE) {
      return {
        ...all,
        [messageId.replace(`${config.NAME}.`, '')]: messages[messageId]
      }
    }

    return all
  }, {} as {})

  return stringifyKeys(collectedMessages)
}

export const useFormatMessage = (baseKey: string) => {
  const intl = useIntl()
  const formatMessage = useCallback(
    (path: string, data = {}) =>
      intl.formatMessage(
        {
          id: `${baseKey}.${path}`
        },
        data
      ),
    [baseKey, intl]
  )
  return formatMessage
}

export const useErrorFormatMessage = () => {
  return useFormatMessage('app.error')
}

export default function initLocalization(messages: Messages): Localization {
  return {
    messages: collectAppMessages(messages),
    locale: config.LANGUAGE
  }
}
