import { useCallback, useMemo } from 'react'

import { DmarcSubdomain, DmarcSource, DmarcAuthRecordAnalysis, SubdomainAnalysis } from 'global/types/api/dmarc'
import * as analyticsLib from 'global/lib/analytics/analyticsService'

import useInvestigateDmarcAnalysisLogic from 'sen/components/lib/dialogs/investigateDmarc/views/lib/useInvestigateDmarcAnalysis'

export enum LinkTypes {
  alignmentOnly = 'alignmentOnly',
  spfOnly = 'spfOnly',
  dkimOnly = 'dkimOnly',
  spfAndDkim = 'spfAndDkim'
}

export interface UseInvestigateDmarcAnalysisLogic {
  getDkimSubdomainAnalysis: (subdomain: DmarcSubdomain) => SubdomainAnalysis[]
  getSpfSubdomainAnalysis: (subdomain: DmarcSubdomain) => SubdomainAnalysis[]
  isDmarcFailure: (source: DmarcSource) => boolean
  isSpfFailure: (source: DmarcSource) => boolean
  isDkimFailure: (source: DmarcSource) => boolean
  getSpfSetupLink: (source: DmarcSource) => string
  getDkimSetupLink: (source: DmarcSource) => string
  getAlignmentLink: () => string
  getDmarcPercentage: (isFail: boolean, source: DmarcSource, subdomain: DmarcSubdomain) => string
}

export interface UseDmarcRecommendationCellLogicProps {
  subdomain: DmarcSubdomain
  dmarcSource: DmarcSource
}

export interface LogicConfig {
  getLinkTypeForRecommendation: () => string | undefined
  isSameDkimAndSpfLinks: boolean
}

export interface EventHandlers {
  onOpenDkimDocLink: () => void
  onOpenSpfDocLink: () => void
  onOpenAlignmentDocLink: () => void
}

export default function useDmarcRecommendationCellLogic({
  subdomain,
  dmarcSource
}: UseDmarcRecommendationCellLogicProps): [LogicConfig, EventHandlers] {
  const [investigateDmarcAnalysisLogic] = useInvestigateDmarcAnalysisLogic()

  const analyzedDkimDomains = useMemo(() => {
    return investigateDmarcAnalysisLogic.getDkimSubdomainAnalysis(subdomain)
  }, [subdomain, investigateDmarcAnalysisLogic])

  const analyzedSpfDomains = useMemo(() => {
    return investigateDmarcAnalysisLogic.getSpfSubdomainAnalysis(subdomain)
  }, [subdomain, investigateDmarcAnalysisLogic])

  const isAuthRecordFail = useCallback((record: DmarcAuthRecordAnalysis): boolean => {
    return !record?.pass
  }, [])

  const isDkimFail = useMemo((): boolean => {
    return !!analyzedDkimDomains.some(isAuthRecordFail)
  }, [analyzedDkimDomains, isAuthRecordFail])

  const isSpfFail = useMemo((): boolean => {
    return !!analyzedSpfDomains?.some(isAuthRecordFail)
  }, [analyzedSpfDomains, isAuthRecordFail])

  const isAuthRecordMisaligned = useCallback((record: DmarcAuthRecordAnalysis): boolean => {
    return !record?.isAligned
  }, [])

  const isDkimMisaligned = useMemo((): boolean => {
    return !!analyzedDkimDomains?.some(isAuthRecordMisaligned)
  }, [analyzedDkimDomains, isAuthRecordMisaligned])

  const isSpfMisaligned = useMemo((): boolean => {
    return !!analyzedSpfDomains?.some(isAuthRecordMisaligned)
  }, [analyzedSpfDomains, isAuthRecordMisaligned])

  const getLinkTypeForRecommendation = useCallback((): string | undefined => {
    switch (true) {
      case isDkimFail && isSpfFail:
        return LinkTypes.spfAndDkim
      case isDkimFail:
        return LinkTypes.dkimOnly
      case isSpfFail:
        return LinkTypes.spfOnly
      case isDkimMisaligned || isSpfMisaligned:
        return LinkTypes.alignmentOnly
      default:
        return undefined
    }
  }, [isDkimFail, isSpfFail, isDkimMisaligned, isSpfMisaligned])

  const isSameDkimAndSpfLinks = useMemo(() => {
    return (
      investigateDmarcAnalysisLogic.getDkimSetupLink(dmarcSource) ===
      investigateDmarcAnalysisLogic.getSpfSetupLink(dmarcSource)
    )
  }, [investigateDmarcAnalysisLogic, dmarcSource])

  const onOpenDkimDocLink = useCallback(() => {
    const link = investigateDmarcAnalysisLogic.getDkimSetupLink(dmarcSource)

    analyticsLib.trackAppEvent(
      analyticsLib.EVENTS.DF_DOMAINS_DMARC_REVIEW_INVESTIGATE_DIALOG_OPEN_DKIM_RECOMMENDATION,
      { link }
    )
    window.open(link, '_blank')
  }, [investigateDmarcAnalysisLogic, dmarcSource])

  const onOpenSpfDocLink = useCallback(() => {
    const link = investigateDmarcAnalysisLogic.getSpfSetupLink(dmarcSource)

    analyticsLib.trackAppEvent(analyticsLib.EVENTS.DF_DOMAINS_DMARC_REVIEW_INVESTIGATE_DIALOG_OPEN_SPF_RECOMMENDATION, {
      link
    })
    window.open(link, '_blank')
  }, [investigateDmarcAnalysisLogic, dmarcSource])

  const onOpenAlignmentDocLink = useCallback(() => {
    const link = investigateDmarcAnalysisLogic.getAlignmentLink()

    analyticsLib.trackAppEvent(
      analyticsLib.EVENTS.DF_DOMAINS_DMARC_REVIEW_INVESTIGATE_DIALOG_OPEN_ALIGNMEN_RECOMMENDATION,
      { link }
    )
    window.open(link, '_blank')
  }, [investigateDmarcAnalysisLogic])

  return useMemo(
    () => [
      {
        getLinkTypeForRecommendation,
        isSameDkimAndSpfLinks
      },
      {
        onOpenDkimDocLink,
        onOpenSpfDocLink,
        onOpenAlignmentDocLink
      }
    ],
    [getLinkTypeForRecommendation, isSameDkimAndSpfLinks, onOpenDkimDocLink, onOpenSpfDocLink, onOpenAlignmentDocLink]
  )
}
