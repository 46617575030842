import React, { useMemo } from 'react'

import { Select, MenuItem, SelectWrapper, Typography } from '@barracuda-internal/bds-core'

import { COLORS } from 'global/configs/theme/defaults'
import IMAGES from 'global/configs/theme/images.config'
import PagerIcon from 'global/components/lib/grid/pager/PagerIcon'
import usePagerLogic, { PagerParams } from 'global/components/lib/grid/pager/usePagerLogic'
import styles from 'global/components/lib/grid/pager/pagerStyles'
import { useFormatMessage } from 'global/lib/localization'
import TextLabel, { LabelColors } from 'global/components/lib/textLabel/TextLabel'

const BASE_I18N_KEY = 'app.grid.pager'

export type PagerProps = PagerParams

const Pager: React.FC<PagerProps> = props => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const [pagerLogic] = usePagerLogic(props)
  const { info, total } = props

  return useMemo(() => {
    if (pagerLogic.maxPage < 2) {
      return null
    }

    return (
      <div className="k-pager-wrap k-floatwrap k-widget k-grid-pager">
        <PagerIcon
          config={pagerLogic.buttons.firstPage}
          title={formatMessage('goto_first_page')}
          imageSrc={IMAGES.iconFirstPage}
        />
        <PagerIcon
          config={pagerLogic.buttons.prevPage}
          title={formatMessage('goto_prev_page')}
          imageSrc={IMAGES.iconPrevPage}
        />
        {pagerLogic.dotsStatuses.isPrevVisible && <strong className={classes.dots}>...</strong>}
        <ul className="k-pager-numbers k-reset">
          <li>
            {pagerLogic.pages.map(pageConfig => (
              <button
                key={pageConfig.page}
                onClick={pageConfig.onClick}
                style={{ marginRight: 2, marginLeft: 2 }}
                className={`k-link ${pageConfig.isActive ? 'k-state-selected' : ''}`}
              >
                {pageConfig.page}
              </button>
            ))}
          </li>
        </ul>
        {pagerLogic.dotsStatuses.isNextVisible && <strong className={classes.dots}>...</strong>}
        <PagerIcon
          config={pagerLogic.buttons.nextPage}
          title={formatMessage('goto_next_page')}
          imageSrc={IMAGES.iconNextPage}
        />
        <PagerIcon
          config={pagerLogic.buttons.lastPage}
          title={formatMessage('goto_last_page')}
          imageSrc={IMAGES.iconLastPage}
        />
        {pagerLogic.itemsPerPageConfig && (
          <span className={`k-pager-sizes k-label ${classes.itemsPerPage}`}>
            {formatMessage('items_per_page')}&nbsp;&nbsp;
            <SelectWrapper size="small">
              <Select
                onChange={pagerLogic.itemsPerPageConfig.onChange}
                value={pagerLogic.itemsPerPageConfig.value}
                renderValue={(): JSX.Element => (
                  <div>
                    <Typography>{(pagerLogic.itemsPerPageConfig || {}).value}</Typography>
                  </div>
                )}
              >
                {pagerLogic.itemsPerPageConfig.menuItems.map(menuItem => (
                  <MenuItem key={menuItem.id} value={menuItem.id}>
                    {`${menuItem.value}`}
                  </MenuItem>
                ))}
              </Select>
            </SelectWrapper>
          </span>
        )}
        {pagerLogic.shouldShowLimitLabel && (
          <div className={classes.limitText}>
            <TextLabel color={LabelColors.dark} bgColor={COLORS.PRODUCT_UI_ELEMENT}>
              {formatMessage('limit')}
            </TextLabel>
          </div>
        )}
        {info && <div className="k-pager-info k-label">{`${pagerLogic.from}-${pagerLogic.to} of ${total}`}</div>}
      </div>
    )
  }, [pagerLogic, classes, formatMessage, info, total])
}

export default Pager
