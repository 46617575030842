import React, { useMemo } from 'react'

import { MenuItem, ListItemText } from '@barracuda-internal/bds-core'

import * as datetime from 'global/lib/datetime'
import { config } from 'global/lib/config'

import styles from 'global/components/lib/layout/navbar/navbarStyles'
import { useFormatMessage } from 'global/lib/localization'

export interface NavbarContextMenuUserInfoProps {
  userEmail?: string
  serialNumber?: string | null
  serialExpiry?: string | null
}

const BASE_I18N_KEY = 'app.logoutmenu'

const NavbarContextMenuUserInfo: React.FC<NavbarContextMenuUserInfoProps> = ({
  userEmail,
  serialNumber,
  serialExpiry
}) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  return useMemo(
    () => (
      <MenuItem className={classes.navbarMenuContextItem}>
        <ListItemText
          primary={userEmail}
          secondary={
            <>
              {serialNumber && (
                <span className={classes.navbarSerial}>
                  {formatMessage('serial_number', { serial: serialNumber })}
                  <br />
                </span>
              )}
              {serialExpiry && (
                <span className={classes.navbarSerial}>
                  {formatMessage('expiration', {
                    expiration: datetime.formatDate(serialExpiry, config.DATETIME.DEFAULT_DATE_FORMAT)
                  })}
                  <br />
                </span>
              )}
            </>
          }
        />
      </MenuItem>
    ),

    [classes.navbarMenuContextItem, classes.navbarSerial, formatMessage, serialExpiry, serialNumber, userEmail]
  )
}

export default NavbarContextMenuUserInfo
