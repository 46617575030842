import { useMemo, useEffect } from 'react'

import { process } from '@progress/kendo-data-query'

import { formatDateWithTime } from 'global/lib/datetime'
import { BDSGridPagerConfig } from 'global/types/dataTables/dataTables'
import { ColumnsConfig } from 'global/types/dataTables/columnsConfigType'
import { isPending } from 'global/redux/toolkit/api'

import { Alert, AlertInboxRule } from 'global/types/api/ato'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import useInboxRules from 'sen/components/lib/dialogs/alertDialog/tabs/useInboxRules'

import {
  update as updateAlertInboxRulesTable,
  reset as resetAlertInboxRulesTable
} from 'sen/redux/features/dataTables/alert/inboxRulesSlice'
import { getAlertInboxRules } from 'sen/redux/features/ato/atoSlice'

export interface ModifiedAlertInboxRule extends AlertInboxRule {
  formattedDate: string
  formattedActions: any
  formattedConditions: any
}

export type InProgress = boolean
export interface TableConfig {
  tableData: {
    total: number
    data: any[]
  }
  isFlexibleTable: boolean
  pageConfig: BDSGridPagerConfig
  columns: { [key: string]: string }
  columnsConfig: { [key: string]: ColumnsConfig }
}

export default function useAlertInboxRulesLogic(alert: Alert): [InProgress, TableConfig] {
  const dispatch = useAppDispatch()
  const { inProgress, alertInboxRulesTable, alertInboxRules, loadedOffsets } = useAppSelector(_stores => ({
    inProgress: isPending(_stores.ato.getAlertInboxRulesApiStatus),
    alertInboxRulesTable: _stores.dataTables.alert.inboxRules,
    alertInboxRules: _stores.ato.alertInboxRules,
    loadedOffsets: _stores.ato.loadedInboxRulesOffsets
  }))

  const [getActions, getConditions] = useInboxRules()

  // Init
  useEffect(() => {
    return () => {
      dispatch(resetAlertInboxRulesTable())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tableData = useMemo(() => {
    const { skip, take } = alertInboxRulesTable

    const { data } = process(
      (alertInboxRules?.report?.data || []).map((report: AlertInboxRule) => ({
        ...(report && {
          ...report,
          formattedDate: formatDateWithTime(report.date || ''),
          formattedActions: getActions(report),
          formattedConditions: getConditions(report)
        })
      })),
      { skip, take }
    )

    return {
      data: data.filter(report => report.ruleId),
      total: alertInboxRules?.report?.totalCount || 0
    }
  }, [alertInboxRules, alertInboxRulesTable, getActions, getConditions])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take } = alertInboxRulesTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: tableData.total,
      onPageChange: (e: any) => {
        dispatch(updateAlertInboxRulesTable(e.page))

        if (!loadedOffsets.includes(e.page.skip)) {
          dispatch(getAlertInboxRules(alert.id))
        }
      }
    }
  }, [alertInboxRulesTable, tableData, loadedOffsets, dispatch, alert])

  return useMemo(() => {
    return [
      inProgress,
      {
        tableData,
        pageConfig,
        isFlexibleTable: tableData.total < alertInboxRulesTable.ITEMS_PER_PAGE,
        columns: alertInboxRulesTable.GRID_COLUMNS,
        columnsConfig: alertInboxRulesTable.columnsConfig
      }
    ]
  }, [tableData, pageConfig, alertInboxRulesTable, inProgress])
}
