import React, { useMemo } from 'react'

import { Button, Typography, Card, Grid } from '@barracuda-internal/bds-core'
import { Add as AddIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'

import ConnectO365Dialog from 'global/components/lib/dialogs/connectO365/ConnectO365Dialog'

import useEmptyReportLogic from 'ets/components/pages/emptyReport/useEmptyReportLogic'
import styles from 'ets/components/pages/emptyReport/emptyReportStyles'
import { useFormatMessage } from 'global/lib/localization'

const BASE_I18N_KEY = 'ets.app.empty_report'

export const EmptyReport: React.FC = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const [emptyReportLogic] = useEmptyReportLogic()

  return useMemo(() => {
    const { toggleDialog, connectO365DialogProps } = emptyReportLogic
    return (
      <Grid container item xs justifyContent="center" className={classes.root} data-field="grid">
        <Card className={classes.card} elevation={2} data-field="card">
          <Typography className={classes.text} data-field="text">
            {formatMessage('top_title')}
          </Typography>
          <Button
            className={classes.connect0365}
            onClick={toggleDialog}
            color="primary"
            variant="contained"
            data-action="connect"
            startIcon={<AddIcon />}
          >
            <Typography className={classes.text0365} variant="subtitle2" data-field="text0365">
              {formatMessage('connect_to_o365')}
            </Typography>
          </Button>
          <Typography className={classes.text} data-field="text-2">
            {formatMessage('bottom_title')}
          </Typography>
          <ConnectO365Dialog {...connectO365DialogProps} data-field="0365-dialog" />
        </Card>
      </Grid>
    )
  }, [emptyReportLogic, classes, formatMessage])
}

export default EmptyReport
