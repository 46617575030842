import React, { useMemo } from 'react'

import { Button, DialogActions, Typography } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'global/lib/localization'

import { StepProps } from 'sen/components/lib/dialogs/domainWizardDialog/domainWizardDialogTypes'
import ConfigureStepContent from 'sen/components/lib/dialogs/domainWizardDialog/steps/ConfigureStepContent'
import styles from 'sen/components/lib/dialogs/domainWizardDialog/domainWizardDialogStyles'

const BASE_I18N_KEY = 'sen.app.domain_wizard_dialog.dmarc_not_found'
const DMARC_RECORD_DOMAIN = '_dmarc.'

export const DomainWizardStepSpfDmarcNotFound: React.FC<StepProps> = ({ domain, config, events }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  return useMemo(
    () => (
      <>
        <ConfigureStepContent
          config={config}
          title={formatMessage('title')}
          domainValues={config.dmarcDomainValues}
          withSubtitleLabel
        >
          <Typography className={classes.dialogText} data-step-name="dmarc-not-found">
            {formatMessage('troubleshooting_title', {
              b: (txt: string) => <b key={txt}>{txt}</b>
            })}
          </Typography>
          <Typography className={classes.dialogText}>
            -&nbsp;
            {formatMessage('troubleshooting_subtitle_1', {
              dmarcRecord: `${DMARC_RECORD_DOMAIN}${domain.name}`,
              domainName: domain.name
            })}
          </Typography>
          <Typography className={classes.dialogText}>-&nbsp;{formatMessage('troubleshooting_subtitle_2')}</Typography>
          <Typography className={classes.dialogText}>-&nbsp;{formatMessage('troubleshooting_subtitle_3')}</Typography>
          <Typography className={classes.dialogText}>-&nbsp;{formatMessage('troubleshooting_subtitle_4')}</Typography>
        </ConfigureStepContent>
        <DialogActions>
          <Button color="primary" variant="text" size="large" onClick={events.onCloseDialog}>
            {formatMessage('cancel')}
          </Button>
          <Button color="primary" variant="contained" size="large" onClick={events.onNextStep}>
            {formatMessage('check_dmarc_again')}
          </Button>
        </DialogActions>
      </>
    ),
    [formatMessage, events, config, classes, domain]
  )
}

export default DomainWizardStepSpfDmarcNotFound
