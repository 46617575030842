import React from 'react'
import PropTypes from 'prop-types'

import {
  GridColumnMenuSort,
  GridColumnMenuFilter,
  GridColumnMenuItemGroup,
  GridColumnMenuItem,
  GridColumnMenuItemContent
} from '@progress/kendo-react-grid'

import columnMenuLogic, { WrappedColumnMenu } from './ColumnMenuLogic'
import styles from './columnMenuStyles'

const ColumnMenu: React.FC<WrappedColumnMenu> = ({
  filterOptions,
  isOnlyOneColumnIsVisible,
  isColumnsExpanded,
  columns,
  onToggleColumn,
  onReset,
  onSubmit,
  onMenuItemClick,
  isColumnFilterable,
  isColumnSortable,
  isColumnSelectable,
  sortConfig
}) => {
  const classes = styles()

  return (
    <div className={classes.columnMenuCustom}>
      {isColumnSortable && <GridColumnMenuSort {...(sortConfig as any)} />}
      {isColumnFilterable && <GridColumnMenuFilter {...(filterOptions as any)} />}
      {isColumnSelectable && (
        <GridColumnMenuItemGroup>
          <GridColumnMenuItem title="Columns" iconClass="k-i-columns" onClick={onMenuItemClick} />
          <GridColumnMenuItemContent show={isColumnsExpanded}>
            <div className="k-column-list-wrapper">
              <form onSubmit={onSubmit} onReset={onReset}>
                <div className="k-column-list">
                  {columns.map(
                    (column, idx) =>
                      !column.unableToHide && (
                        <div key={column.field} className="k-column-list-item">
                          <span>
                            <input
                              id={`column-visiblity-show-${idx}`}
                              className="k-checkbox"
                              type="checkbox"
                              readOnly
                              disabled={column.show && isOnlyOneColumnIsVisible}
                              checked={column.show}
                              onClick={() => {
                                onToggleColumn(idx)
                              }}
                            />
                            <label
                              htmlFor={`column-visiblity-show-${idx}`}
                              className="k-checkbox-label"
                              style={{
                                userSelect: 'none',
                                width: 100,
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                              }}
                            >
                              &nbsp;&nbsp;&nbsp;{column.title}
                            </label>
                          </span>
                        </div>
                      )
                  )}
                </div>
                <div className="k-columnmenu-actions">
                  <button type="reset" className="k-button">
                    Reset
                  </button>
                  <button type="submit" className="k-button k-primary">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </GridColumnMenuItemContent>
        </GridColumnMenuItemGroup>
      )}
    </div>
  )
}

ColumnMenu.propTypes = {
  filterOptions: PropTypes.object.isRequired,
  isOnlyOneColumnIsVisible: PropTypes.bool.isRequired,
  isColumnsExpanded: PropTypes.bool.isRequired,
  columns: PropTypes.array.isRequired,
  onToggleColumn: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onMenuItemClick: PropTypes.func.isRequired,
  isColumnFilterable: PropTypes.bool.isRequired,
  isColumnSortable: PropTypes.bool.isRequired,
  isColumnSelectable: PropTypes.bool.isRequired,
  sortConfig: PropTypes.any.isRequired
}

export default columnMenuLogic(ColumnMenu)
