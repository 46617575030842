import { createSlice } from '@reduxjs/toolkit'

import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'global/redux/toolkit/api'
import { Recipient, IdentityDetails } from 'global/types/api/identity'

import {
  getRecipients,
  getSenders,
  getRecipientDetails,
  getSenderDetails
} from 'sen/redux/features/identity/identityApiThunks'

export interface IdentityState {
  getRecipientsApiStatus: ApiStatus
  recipients: Recipient[] | undefined
  getSendersApiStatus: ApiStatus
  senders: Recipient[] | undefined
  getIdentityDetailsApiStatus: ApiStatus
  recipientIdentityDetails: IdentityDetails | undefined
  senderIdentityDetails: IdentityDetails | undefined
}

// initialState
export const INITIAL_STATE: IdentityState = {
  getRecipientsApiStatus: inIdle,
  recipients: undefined,
  getSendersApiStatus: inIdle,
  senders: undefined,
  getIdentityDetailsApiStatus: inIdle,
  recipientIdentityDetails: undefined,
  senderIdentityDetails: undefined
}

/* eslint-disable no-param-reassign */
export const indentitySlice = createSlice({
  name: 'IDENTITY',
  initialState: INITIAL_STATE,
  reducers: {
    resetRecipients: state => {
      state.getRecipientsApiStatus = INITIAL_STATE.getRecipientsApiStatus
      state.recipients = INITIAL_STATE.recipients
    },
    resetSenders: state => {
      state.getSendersApiStatus = INITIAL_STATE.getSendersApiStatus
      state.senders = INITIAL_STATE.senders
    },
    resetRecipientDetails: state => {
      state.getIdentityDetailsApiStatus = INITIAL_STATE.getIdentityDetailsApiStatus
      state.recipientIdentityDetails = INITIAL_STATE.recipientIdentityDetails
    },
    resetSenderDetails: state => {
      state.getIdentityDetailsApiStatus = INITIAL_STATE.getIdentityDetailsApiStatus
      state.senderIdentityDetails = INITIAL_STATE.senderIdentityDetails
    },

    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  },
  extraReducers: builder => {
    builder
      // getRecipients
      .addCase(getRecipients.pending, state => {
        state.getRecipientsApiStatus = inProgress
        state.recipients = INITIAL_STATE.recipients
      })
      .addCase(getRecipients.fulfilled, (state, action) => {
        state.getRecipientsApiStatus = successResponse
        state.recipients = action.payload
      })
      .addCase(getRecipients.rejected, (state, action) => {
        state.getRecipientsApiStatus = failedResponse(action.payload as string)
      })

      // getSenders
      .addCase(getSenders.pending, state => {
        state.getSendersApiStatus = inProgress
        state.senders = INITIAL_STATE.senders
      })
      .addCase(getSenders.fulfilled, (state, action) => {
        state.getSendersApiStatus = successResponse
        state.senders = action.payload
      })
      .addCase(getSenders.rejected, (state, action) => {
        state.getSendersApiStatus = failedResponse(action.payload as string)
      })

      // getRecipientDetails
      .addCase(getRecipientDetails.pending, state => {
        state.getIdentityDetailsApiStatus = inProgress
        state.recipientIdentityDetails = INITIAL_STATE.recipientIdentityDetails
      })
      .addCase(getRecipientDetails.fulfilled, (state, action) => {
        state.getIdentityDetailsApiStatus = successResponse
        state.recipientIdentityDetails = action.payload
      })
      .addCase(getRecipientDetails.rejected, (state, action) => {
        state.getIdentityDetailsApiStatus = failedResponse(action.payload as string)
      })

      // getSenderDetails
      .addCase(getSenderDetails.pending, state => {
        state.getIdentityDetailsApiStatus = inProgress
        state.senderIdentityDetails = INITIAL_STATE.senderIdentityDetails
      })
      .addCase(getSenderDetails.fulfilled, (state, action) => {
        state.getIdentityDetailsApiStatus = successResponse
        state.senderIdentityDetails = action.payload
      })
      .addCase(getSenderDetails.rejected, (state, action) => {
        state.getIdentityDetailsApiStatus = failedResponse(action.payload as string)
      })
  }
})
/* eslint-enable no-param-reassign */

export const {
  resetRecipients,
  resetSenders,
  resetRecipientDetails,
  resetSenderDetails,
  reset
} = indentitySlice.actions

export { getRecipients, getSenders, getRecipientDetails, getSenderDetails }

export default indentitySlice.reducer
