import { makeStyles, createStyles } from '@material-ui/core/styles'

import { COLORS } from 'global/configs/theme/defaults'
import commonStyles from 'global/components/features/signinSignup/styles/commonStyles'

const WRAPPER_WIDTH = 500
const INPUTS_WIDTH = 250

export const signupCompleteStyles = createStyles({
  wrapper: {
    marginTop: 90,
    position: 'relative',
    display: 'flex',
    height: 'auto',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'top',
    fontFamily: '"Roboto", sans-serif',
    overflow: 'visible',
    maxWidth: WRAPPER_WIDTH,
    padding: 30,
    flexDirection: 'column'
  },
  linearProgress: {
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0
  },
  headingLogoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20
  },
  title: {
    ...commonStyles.title
  },
  inputsWrapper: {
    marginTop: 30,
    width: INPUTS_WIDTH,
    margin: 'auto',

    '& input': {
      ...commonStyles.input,
      marginBottom: 20,

      '&[data-field="password"]': {
        marginBottom: 5
      },

      '&[data-field="phone"]': {
        marginBottom: 5
      }
    }
  },
  inputTitle: {
    ...commonStyles.inputTitle
  },
  privacyPolicy: {
    marginTop: 15,
    margin: 'auto'
  },
  blueText: {
    ...commonStyles.blueText
  },
  button: {
    ...commonStyles.blueButton,
    width: INPUTS_WIDTH,
    margin: '5px auto 10px auto'
  },
  formError: {
    listStyle: 'none',
    color: COLORS.ERROR,
    fontSize: 12,
    fontWeight: 400,
    marginBottom: 5
  }
})

export default makeStyles(() => signupCompleteStyles)
