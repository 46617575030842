import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { MenuItem, ListItemIcon } from '@barracuda-internal/bds-core'
import { ExitToApp as ExitToAppIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'

import styles from 'global/components/lib/layout/navbar/navbarStyles'
import { useFormatMessage } from 'global/lib/localization'
import { NavbarContextMenuContext } from 'global/components/lib/layout/navbar/contextMenu/NavbarContextMenu'

export interface NavbarContextMenuLogoutProps {
  onLogout: () => void
}

const BASE_I18N_KEY = 'app.logoutmenu'

const NavbarContextMenuLogout: React.FC<NavbarContextMenuLogoutProps> = ({ onLogout }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const navbarContextMenuContext = useContext(NavbarContextMenuContext)
  const classes = styles()

  return (
    <MenuItem
      className={classes.navbarMenuContextItem}
      onClick={() => {
        navbarContextMenuContext.closeMenu()
        onLogout()
      }}
    >
      <ListItemIcon>
        <ExitToAppIcon id="logout_button" />
      </ListItemIcon>
      {formatMessage('sign_out')}
    </MenuItem>
  )
}

NavbarContextMenuLogout.propTypes = {
  onLogout: PropTypes.func.isRequired
}

export default NavbarContextMenuLogout
