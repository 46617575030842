import React from 'react'
import PropTypes from 'prop-types'

import { Link, Typography } from '@barracuda-internal/bds-core'

import { config } from 'global/lib/config'

import { useFormatMessage } from 'global/lib/localization'
import styles from '../additionalActionsStyles'

export interface Props {
  additionalActionsOptions: {
    securityServiceLink: string
  }
}

const BASE_I18N_KEY = 'fir.app.follow_up'

const AdditionalActions: React.FC<any> = ({ additionalActionsOptions: { securityServiceLink } }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  return (
    <div className={classes.wrapper}>
      <Typography variant="h6">{formatMessage('suggested_follow_up_actions')}</Typography>
      <Typography variant="body1" component="span">
        <ol>
          <li className={classes.listItem}>
            {formatMessage('consider_remaining_user_endpoints')}
            <ul>
              <li>{formatMessage('opened_a_malicious_attachment')}</li>
              <li>{formatMessage('clicked_on_suspiious_link')}</li>
            </ul>
          </li>
          <li className={classes.listItem}>
            {formatMessage('goto_barracuda_email_security_service', {
              a: (txt: any) => (
                <Link key="link" target="_blank" href={securityServiceLink}>
                  {txt}
                </Link>
              )
            })}
          </li>
          <li className={classes.listItem}>
            {formatMessage('ask_your_endusers_to_watch', {
              a: (txt: any) => (
                <Link key="link" target="_blank" href={config.LINKS.BARRACUDA_PHISHLINE_VIDEO}>
                  {txt}
                </Link>
              )
            })}
          </li>
        </ol>
      </Typography>
    </div>
  )
}

AdditionalActions.propTypes = {
  additionalActionsOptions: PropTypes.any.isRequired
}

export default AdditionalActions
