import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from 'global/configs/theme/defaults'

export default makeStyles(theme => {
  return {
    subtitle: {
      marginBottom: theme.spacing(3),
      lineHeight: '10px',
      color: COLORS.GRAY
    },
    card: {
      position: 'relative',
      padding: 26,
      textAlign: 'center',
      color: '#01579b'
    },
    cardValue: {
      lineHeight: 'normal',
      color: COLORS.BLUE_700
    },
    cardText: {
      lineHeight: 'normal',
      color: COLORS.BLUE_700
    },
    cardIcon: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      fontSize: 40,
      color: 'rgba(0, 0, 0, 0.54)'
    }
  }
})
