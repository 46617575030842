import { useEffect, useMemo, useCallback } from 'react'
import { process } from '@progress/kendo-data-query'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'

import { IncidentEmail } from 'global/types/api/remediation'
import { BDSGridPagerConfig } from 'global/types/dataTables/dataTables'
import { ExportToCsvButtonProps } from 'global/components/lib/exportToCsvButton/ExportToCsvButton'

import { isPending, isSuccess, getErrorMessage } from 'global/redux/toolkit/api'
import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'

import { TableConfig, RecipientsConfig } from 'sen/components/lib/dialogs/newIncidentDialog/lib/RecipientsTable'

import useSendersAutocompleteLogic, {
  SendersAutocompleteConfig,
  SelectedSender
} from 'sen/components/lib/autocompletes/identity/senders/useSendersAutocompleteLogic'

import {
  getRecipients,
  resetRecipients,
  getInboxRules,
  notifyExternalEmails,
  resetNotifyExternalEmails,
  resetNotifyExternalEmailValues
} from 'sen/redux/features/remediation/remediationSlice'

import {
  update as updateRecipientsWizardTable,
  reset as resetRecipientsWizardTable
} from 'sen/redux/features/dataTables/incident/recipientsWizardSlice'

import apiRoutes from 'sen/lib/api/apiRoutes'

import useNewIncidentDialogWizardLogic, {
  ButtonStatus
} from 'sen/components/lib/dialogs/newIncidentDialog/useNewIncidentDialogWizardLogic'

export type Error = string | undefined

export interface SendersSelectorProps {
  selectedSender: SelectedSender
  config: SendersAutocompleteConfig
}

export interface NotificationEmailDialogConfig {
  open: boolean
  onClose: () => void
  sender: SelectedSender
}

export type EditNotificationEmail = () => void

export default function useNotifyLogic(): [
  ButtonStatus,
  RecipientsConfig,
  TableConfig,
  SendersSelectorProps,
  Error,
  ExportToCsvButtonProps,
  NotificationEmailDialogConfig,
  EditNotificationEmail
] {
  const {
    accessTokenId,
    formValues,
    createIncidentId,
    recipientsTable,
    recipients,
    hasRecipients,
    loadedRecipientssOffsets,
    tableInProgress,
    isLoaded,
    error,
    notifyExternalEmailValues,
    emailSentSuccess
  } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken?.accessToken?.id || '',
    formValues: _stores.remediation.incidentEmailSearchFormValues,
    createIncidentId: _stores.remediation?.currentIncident?.id || '',
    recipientsTable: _stores.dataTables.incident.recipientsWizard,
    recipients: _stores.remediation.recipients,
    hasRecipients: !!_stores.remediation?.recipients?.report?.data?.length,
    loadedRecipientssOffsets: _stores.remediation.loadedGetRecipientsWizardOffsets,
    tableInProgress: isPending(_stores.remediation.getRecipientsApiStatus),
    error:
      getErrorMessage(_stores.remediation.getRecipientsApiStatus) ||
      getErrorMessage(_stores.remediation.notifyExternalEmailsApiStatus),
    isLoaded: !!_stores.remediation.recipients?.accessTokenId,
    emailSentSuccess: isSuccess(_stores.remediation.notifyExternalEmailsApiStatus),
    notifyExternalEmailValues: _stores.remediation?.notifyExternalEmailValues
  }))
  const dispatch = useAppDispatch()

  const [isNotificationEmailDialogOpened, toggleNotificationEmailDialog] = useDialogLogic()

  const [multiStepConfig] = useNewIncidentDialogWizardLogic()

  const currentStep = 3
  const currentPage = 'Notify Page'

  const [sendersAutocompleteConfig, selectedSender] = useSendersAutocompleteLogic({
    shouldSetInititalIdentity: false,
    isRequired: true
  })

  // Init
  useEffect(() => {
    return () => {
      dispatch(resetRecipients(true))
      dispatch(resetRecipientsWizardTable())
      dispatch(resetNotifyExternalEmails())
      dispatch(resetNotifyExternalEmailValues())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // recipients table
  const tableData = useMemo(() => {
    const { skip, take } = recipientsTable

    const { data } = process(
      (recipients?.report?.data || []).map((report: IncidentEmail) => ({
        ...(report && {
          ...report
        })
      })),
      { skip, take }
    )

    return {
      data: data.filter(report => report.status),
      total: recipients?.report?.totalCount || 0
    }
  }, [recipients, recipientsTable])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = recipientsTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: tableData.total,
      onPageChange: (e: any) => {
        dispatch(updateRecipientsWizardTable(e.page))

        if (!loadedRecipientssOffsets?.includes(e.page.skip)) {
          dispatch(getRecipients({ incidentId: createIncidentId, distinctRecipient: true, wizardTable: true }))
        }
      }
    }
  }, [recipientsTable, tableData, dispatch, loadedRecipientssOffsets, createIncidentId])

  const exportToCsvConfig: ExportToCsvButtonProps = useMemo(() => {
    const exportName = analyticsLib.EVENTS.ATO_EXPORT_AS_CSV_EXTERNAL_ATTACKS
    return {
      getExportPath: sessionId => {
        return apiRoutes.EXPORT_EXTERNAL_ATTACKS_AS_CSV.path({
          incidentId: createIncidentId,
          accessTokenId,
          sessionId
        })
      },
      exportName,
      analyticsParams: {
        accessTokenId
      },
      totalCount: recipients?.report?.totalCount || 0
    }
  }, [accessTokenId, recipients, createIncidentId])

  const editNotificationEmail = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.ACCOUNT_COMPROMISE_INCIDENT_WIZARD_EDIT_NOTIFY_EMAIL, {
      accessTokenId,
      senderEmail: formValues.senderEmail
    })
    toggleNotificationEmailDialog()
  }, [toggleNotificationEmailDialog, accessTokenId, formValues.senderEmail])

  // dialog action
  const onDispatchNextStep = useCallback(() => {
    if (hasRecipients) {
      dispatch(notifyExternalEmails({ ...notifyExternalEmailValues }))
    }

    if (!hasRecipients || emailSentSuccess) {
      dispatch(getInboxRules(formValues.senderEmail))
      multiStepConfig.onNextStep(currentStep, currentPage)
    }
  }, [multiStepConfig, dispatch, formValues.senderEmail, hasRecipients, notifyExternalEmailValues, emailSentSuccess])

  const onDispatchPrevStep = useCallback(() => {
    // This step doesn't have a back
    // Skip allows the user to go to the next step
    dispatch(getInboxRules(formValues.senderEmail))
    multiStepConfig.onNextStep(currentStep, currentPage, 'skip to next step')
  }, [multiStepConfig, dispatch, formValues.senderEmail])

  return useMemo(() => {
    return [
      {
        disabled: tableInProgress,
        disableNext:
          tableInProgress ||
          (hasRecipients && (!selectedSender || !Object.values(notifyExternalEmailValues).every(value => !!value))),
        cancel: 'cancel',
        onPrev: hasRecipients ? 'skip' : '',
        onNext: hasRecipients ? 'send_notifications' : 'continue',
        onNextStep: onDispatchNextStep,
        onPrevStep: onDispatchPrevStep
      },
      {
        inProgress: tableInProgress,
        hasRecipients
      },
      {
        isLoaded: isLoaded || !!error,
        tableData,
        pageConfig,
        columns: recipientsTable.GRID_COLUMNS,
        columnsConfig: recipientsTable.columnsConfig,
        isFlexibleTable: tableData.total < recipientsTable.ITEMS_PER_PAGE
      },
      {
        selectedSender,
        config: sendersAutocompleteConfig
      },
      error,
      exportToCsvConfig,
      {
        open: isNotificationEmailDialogOpened,
        onClose: toggleNotificationEmailDialog,
        sender: selectedSender
      },
      editNotificationEmail
    ]
  }, [
    onDispatchNextStep,
    onDispatchPrevStep,
    tableInProgress,
    notifyExternalEmailValues,
    recipientsTable,
    tableData,
    pageConfig,
    isLoaded,
    hasRecipients,
    error,
    exportToCsvConfig,
    sendersAutocompleteConfig,
    selectedSender,
    editNotificationEmail,
    isNotificationEmailDialogOpened,
    toggleNotificationEmailDialog
  ])
}
