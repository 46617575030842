import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Grid } from '@barracuda-internal/bds-core'

import styles from 'global/components/features/signinSignup/signinSignupStylesNew'

export interface SigninSignupContentProps {
  children: any
}

const SigninSignupContent: React.FC<SigninSignupContentProps> = ({ children }) => {
  const classes = styles()

  return useMemo(
    () => (
      <Grid container className={classes.contentWrapper}>
        {children}
      </Grid>
    ),
    [children, classes]
  )
}

SigninSignupContent.propTypes = {
  children: PropTypes.any.isRequired
}

export default SigninSignupContent
