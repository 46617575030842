import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from 'global/configs/theme/defaults'
import tableStyles from 'global/components/lib/grid/tableStyles'

export const CELL_HEIGHT = 47

export default makeStyles(theme => {
  const defaultTableStyles = tableStyles(theme, CELL_HEIGHT)

  return {
    ...defaultTableStyles,
    dialogContent: {
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 24,
      paddingBottom: 16
    },
    title: {
      fontSize: 20,
      fontWeight: 500,
      padding: theme.spacing(1)
    },
    contentRow: {
      display: 'flex',
      paddingLeft: theme.spacing(1),
      whiteSpace: 'pre-wrap'
    },
    label: {
      color: COLORS.GRAY
    },
    actions: {
      paddingTop: 8,
      paddingBottom: 16,
      paddingRight: 16
    },
    tableArea: {
      ...defaultTableStyles.tableArea,
      marginTop: theme.spacing(1),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    },
    dataTable: {
      ...defaultTableStyles.dataTable,
      '& td': {
        fontSize: '13px',
        lineHeight: 'normal'
      }
    }
  }
})
