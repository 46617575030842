import React from 'react'
import PropTypes from 'prop-types'

import {
  Checkbox,
  CheckboxLabel,
  Link,
  MenuItem,
  Select,
  SelectLabel,
  TextField,
  Typography,
  Box
} from '@barracuda-internal/bds-core'

import { FormControl, FormGroup } from '@material-ui/core'

import { Form } from 'fir/redux/types/NewIncident'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import { CUSTOM_RANGES } from 'global/components/lib/dateRangePicker/CustomRanges'
import { useFormatMessage } from 'global/lib/localization'
import { config } from 'global/lib/config'
import styles, { searchingForEmailsStyles } from '../newIncidentStyles'

export interface Props {
  searchingForEmails: {
    updateForm: (formProp: string) => (e: any) => {}
    isInvalidCharLength: (char: string) => boolean
    isEmailsLoading: boolean
    newIncidentForm: Form
  }
}

const BASE_I18N_KEY = 'fir.app.new_incident_wizard'
const BASE_APP_RANGE_I18N_KEY = 'app.custom_ranges'

const SearchingForEmails: React.FC<any> = ({
  searchingForEmails: { updateForm, isInvalidCharLength, isEmailsLoading, newIncidentForm }
}) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatMessageAppRange = useFormatMessage(BASE_APP_RANGE_I18N_KEY)
  const baseClasses = styles()
  const classes = searchingForEmailsStyles()

  return (
    <div className={baseClasses.wrapper}>
      {isEmailsLoading && <LinearProgress />}
      <Typography className={baseClasses.opacity} variant="subtitle2">
        {formatMessage('email_subject')}
      </Typography>
      <Typography className={baseClasses.opacity} variant="subtitle2">
        {formatMessage('for_example_searching', { i: (txt: any) => <i key={txt}>{txt}</i> })}
      </Typography>
      <Typography className={baseClasses.opacity} variant="subtitle2">
        <Link target="_blank" href={config.LINKS.CAMPUS_SEARCHING_FOR_EMAILS}>{`${formatMessage(
          'learn_more'
        )}...`}</Link>
      </Typography>
      <FormControl component="fieldset" className={classes.formControl}>
        {newIncidentForm.isFormError && (
          <Typography className={classes.formLineError} variant="h6">
            {formatMessage('form_error')}
          </Typography>
        )}
        <TextField
          className={classes.formLine}
          value={newIncidentForm.senderEmail}
          onChange={updateForm('senderEmail')}
          label={formatMessage('form.sender_email')}
        />
        <TextField
          className={classes.formLine}
          value={newIncidentForm.senderName}
          onChange={updateForm('senderName')}
          label={formatMessage('form.sender_name')}
          error={isInvalidCharLength(newIncidentForm.senderName)}
          helperText={
            isInvalidCharLength(newIncidentForm.senderName) ? formatMessage('errors.invalid_char_length') : ''
          }
        />
        <FormGroup className={classes.formLine} row>
          <Box flexGrow={1}>
            <TextField
              className={classes.inlineTextField}
              value={newIncidentForm.emailSubject}
              onChange={updateForm('emailSubject')}
              label={formatMessage('form.email_subject')}
            />
          </Box>
          <Box>
            <CheckboxLabel
              className={classes.matchExactFrame}
              control={
                <Checkbox
                  color="primary"
                  checked={newIncidentForm.isMatchExactFrame}
                  onChange={updateForm('isMatchExactFrame', true)}
                />
              }
              label={formatMessage('form.match_exact_phrase')}
            />
          </Box>
        </FormGroup>
        <TextField
          className={classes.formLine}
          value={newIncidentForm.bodyText}
          onChange={updateForm('bodyText')}
          label={formatMessage('form.message_body_text')}
          multiline
          maxRows={2}
          error={isInvalidCharLength(newIncidentForm.bodyText)}
          helperText={
            isInvalidCharLength(newIncidentForm.bodyText)
              ? formatMessage('errors.invalid_char_length')
              : formatMessage('errors.ignore_text')
          }
        />
        <TextField
          className={classes.formLine}
          value={newIncidentForm.bodyLinks}
          onChange={updateForm('bodyLinks')}
          label={formatMessage('form.message_body_links')}
          multiline
          error={isInvalidCharLength(newIncidentForm.bodyLinks)}
          helperText={isInvalidCharLength(newIncidentForm.bodyLinks) ? formatMessage('errors.invalid_char_length') : ''}
        />
        <TextField
          className={classes.formLine}
          value={newIncidentForm.attachmentName}
          onChange={updateForm('attachmentName')}
          label={formatMessage('form.attachment_name')}
        />
        <FormControl className={classes.formLine}>
          <SelectLabel className={classes.selectLabel} id="demo-simple-select-helper-label">
            {formatMessage('form.incident_occurred')}
          </SelectLabel>
          <Select value={newIncidentForm.timeframe} onChange={updateForm('timeframe')}>
            {[
              CUSTOM_RANGES.LAST_HOUR,
              CUSTOM_RANGES.LAST_12_HOURS,
              CUSTOM_RANGES.LAST_24_HOURS,
              CUSTOM_RANGES.LAST_2_DAYS,
              CUSTOM_RANGES.LAST_7_DAYS,
              CUSTOM_RANGES.LAST_30_DAYS
            ].map((range: any) => (
              <MenuItem key={range.timeframe} value={range.timeframe}>
                {formatMessageAppRange(`${range.id}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <CheckboxLabel
          control={
            <Checkbox
              color="primary"
              checked={newIncidentForm.isIncludeQuarantined}
              onChange={updateForm('isIncludeQuarantined', true)}
            />
          }
          label={formatMessage('form.include_junk_emails')}
        />
      </FormControl>
    </div>
  )
}

SearchingForEmails.propTypes = {
  searchingForEmails: PropTypes.any.isRequired
}

export default SearchingForEmails
