import theme from '@barracuda-internal/bds-core/dist/styles/themes/baseMui'
import { FONT as font } from 'global/configs/theme/defaults'

const PRIMARY_COLOR = theme.palette.divider
const SECONDARY_COLOR = theme.palette.primary.main
const DATALESS_REGION_COLOR = '#F5F5F5'

const FONT = 'Roboto, Helvetica Neue, Helvetica, sans-serif'

export default {
  region: 'world',
  displayMode: 'auto',
  colors: [PRIMARY_COLOR, SECONDARY_COLOR],
  datalessRegionColor: DATALESS_REGION_COLOR,
  tooltip: {
    isHtml: true
  },
  fontName: FONT,
  legend: { position: 'top', textStyle: { color: theme.palette.common.black, fontSize: font.size.small } },
  keepAspectRatio: true,
  magnifyingGlass: {
    enable: true,
    zoomFactor: 7.5
  },
  sizeAxis: {
    maxSize: 20,
    minSize: 7
  }
}
