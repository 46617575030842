import { useMemo, useEffect } from 'react'

import { getErrorMessage } from 'global/redux/toolkit/api'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import { getInboxRules, resetInboxRules } from 'sen/redux/features/remediation/remediationSlice'
import { TableConfig } from 'sen/components/lib/dialogs/inboxRules/inboxRulesTable/InboxRulesTable'
import useInboxRulesTableLogic from 'sen/components/lib/dialogs/inboxRules/inboxRulesTable/useInboxRulesTableLogic'
import { inboxRulesActions } from 'sen/redux/features/dataTables/inboxRules/inboxRulesSlice'

export type IsLoaded = boolean
export type Error = string | undefined

export default function useInboxRulesDialogLogic(userPrincipalName: string): [IsLoaded, TableConfig, Error] {
  const dispatch = useAppDispatch()
  const { inboxRules, error, inboxRulesTable } = useAppSelector(_stores => ({
    inboxRules: _stores.remediation.inboxRules,
    error: getErrorMessage(_stores.remediation.getInboxRulesApiStatus),
    inboxRulesTable: _stores.dataTables.inboxRules
  }))
  const [tableConfig] = useInboxRulesTableLogic({
    inboxRules: inboxRules?.report,
    inboxRulesTable,
    updateTable: inboxRulesActions.update
  })

  // Init
  useEffect(() => {
    dispatch(getInboxRules(userPrincipalName))

    return () => {
      dispatch(resetInboxRules())
      dispatch(inboxRulesActions.reset())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return useMemo(() => {
    return [!!inboxRules?.accessTokenId, tableConfig, error]
  }, [error, inboxRules, tableConfig])
}
