import { cloneDeep } from 'lodash'
import { useEffect, useMemo } from 'react'

import colors from '@barracuda-internal/bds-core/dist/styles/colors'

import { useFormatMessage } from 'global/lib/localization'
import { isPending } from 'global/redux/toolkit/api'

import { getWorkflowRunsStats } from 'fir/redux/features/stats/statsSlice'
import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'
import { WorkflowRunsStat } from 'fir/redux/types/Stats'
import { WorkflowDashboardProps } from 'fir/redux/types/Workflows'

export interface WorkflowRunsDashboardProps {
  workflowRunsDashboardConfig: WorkflowDashboardProps
}

const BASE_I18N_KEY = 'fir.app.automated_workflows'

export default function useWorkflowRunsDashboardLogic(): [WorkflowRunsDashboardProps] {
  const dispatch = useAppDispatch()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  // Redux Toolkit stores
  const { accessTokenId, automatedWorkflowStats } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id || ' ',
    automatedWorkflowStats: _stores.stats
  }))

  //  workflows state
  const { isAutomatedWorkflowRunsStatsLoading } = {
    isAutomatedWorkflowRunsStatsLoading: isPending(automatedWorkflowStats.automatedWorkflowRunsStatsApiStatus)
  }

  // init
  useEffect(() => {
    dispatch(getWorkflowRunsStats({ accessTokenId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const workflowRunsDashboardConfig = useMemo(() => {
    // Need to deep clone the store data, Kendo is attempting to write to a read only object
    const clonedUserReportedUserStats = cloneDeep(automatedWorkflowStats.workflowRunsStats) || []
    const chartData = automatedWorkflowStats.workflowRunsStats.map((stat: WorkflowRunsStat) => stat.count || 0)

    return {
      chartConfig: {
        chartCategoryAxisItemProps: {
          baseUnit: 'months',
          labels: {
            color: colors.humpbackGray,
            font: '400 12px Roboto, sans-serif',
            format: 'MMMM'
          },
          majorGridLines: { visible: false }
        },
        chartData: clonedUserReportedUserStats,
        chartSeriesItemProps: {
          categoryField: 'date',
          color: colors.coralOrange,
          field: 'count',
          line: { color: 'red', width: 2 },
          opacity: 0.3
        },
        chartSeriesItemTooltipProps: {
          color: 'red',
          formatTitle: (category: Date) =>
            `${category.toLocaleDateString(undefined, { month: 'long' })} ${category.getFullYear()}`,
          text: formatMessage('charts.workflow_runs.tooltip')
        },
        chartStyle: { height: 210 },
        chartValueAxisItemProps: {
          labels: {
            color: colors.humpbackGray,
            font: '600 12px Roboto, sans-serif',
            step: 2
          },
          majorGridLines: { step: 2 },
          min: 0
        },
        showChartSeriesItemTooltip: true
      },
      chartWrapperConfig: {
        chartData: clonedUserReportedUserStats,
        chartHighlight: {
          description: formatMessage('charts.workflow_runs.highlight'),
          value: clonedUserReportedUserStats.length && chartData.reduce((a: number, b: number) => a + b)
        },
        chartLoading: isAutomatedWorkflowRunsStatsLoading,
        chartTitle: formatMessage('charts.workflow_runs.title'),
        noDataText: formatMessage('charts.workflow_runs.noData')
      }
    }
  }, [automatedWorkflowStats.workflowRunsStats, isAutomatedWorkflowRunsStatsLoading, formatMessage])

  return useMemo(() => [{ workflowRunsDashboardConfig }], [workflowRunsDashboardConfig])
}
