import React from 'react'
import PropTypes from 'prop-types'

import IMAGES from 'ets/configs/theme/images.config'

import GlobalLayout from 'global/components/features/signinSignup/layoutV3/SigninSignupLayout'
import Wrapper from 'global/components/features/signinSignup/layoutV3/SigninSignupWrapper'
import Header from 'global/components/features/signinSignup/layoutV3/SigninSignupHeader'
import Content from 'global/components/features/signinSignup/layoutV3/SigninSignupContent'
import ContentColumn from 'global/components/features/signinSignup/layoutV3/SigninSignupContentColumn'
import Connect from 'global/components/features/signinSignup/layoutV3/SigninSignupConnect'

export interface Props {
  error?: string | false
  children: any
}

interface SigninSignupLayoutFC<TProps> extends React.FC<TProps> {
  Wrapper: React.FC<any>
  Header: React.FC<any>
  Content: React.FC<any>
  ContentColumn: React.FC<any>
  Connect: React.FC<any>
}

const SigninSignupLayout: SigninSignupLayoutFC<Props> = ({ error, children }) => {
  return (
    <GlobalLayout error={error} bgImage={IMAGES.loginBackground} isMaintenanceBannerEnabled>
      {children}
    </GlobalLayout>
  )
}

SigninSignupLayout.Wrapper = Wrapper
SigninSignupLayout.Header = Header
SigninSignupLayout.Content = Content
SigninSignupLayout.ContentColumn = ContentColumn
SigninSignupLayout.Connect = Connect

SigninSignupLayout.propTypes = {
  error: PropTypes.any,
  children: PropTypes.any.isRequired
}

SigninSignupLayout.defaultProps = {
  error: false
}

export default SigninSignupLayout
