import { NodeConstants } from '@barracuda/automated-workflows'
import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { ApiRejectResponse, validateApiError } from 'global/lib/api/restClient'
import {
  BaseStat,
  EmailThreatsStat,
  IncidentsStat,
  StatsApiConfig,
  TopUserReportedStat,
  TopUserStat,
  WorkflowStat,
  WorkflowRunsStat,
  WorkflowTopRunsStat
} from 'fir/redux/types/Stats'
import { luxonDate } from 'global/lib/datetime'

import apiRoutes from 'fir/lib/api/apiRoutes'

function aggregateTotalRuns(stats: WorkflowStat[]): WorkflowTopRunsStat[] {
  const result = [
    ...stats
      .reduce((acc: any, workflowStat: WorkflowStat) => {
        const key: string = workflowStat.workflowUuid

        const stat =
          acc.get(key) ||
          ({
            actionTotalCount: 0,
            actions: {},
            conditionTotalCount: 0,
            conditions: {},
            triggerTotalCount: 0,
            triggers: {},
            total: 0,
            workflowName: ''
          } as WorkflowTopRunsStat)

        if (workflowStat.type === NodeConstants.TRIGGER) {
          stat.triggers[workflowStat.eventType] = stat.triggers[workflowStat.eventType]
            ? stat.triggers[workflowStat.eventType] + workflowStat.count
            : workflowStat.count
          stat.triggerTotalCount += workflowStat.count
          stat.total += workflowStat.count
        }
        if (workflowStat.type === NodeConstants.CONDITION) {
          stat.conditions[workflowStat.eventType] = stat.conditions[workflowStat.eventType]
            ? stat.conditions[workflowStat.eventType] + workflowStat.count
            : workflowStat.count
          stat.conditionTotalCount += workflowStat.count
          stat.total += workflowStat.count
        }
        if (workflowStat.type === NodeConstants.ACTION) {
          stat.actions[workflowStat.eventType] = stat.actions[workflowStat.eventType]
            ? stat.actions[workflowStat.eventType] + workflowStat.count
            : workflowStat.count
          stat.actionTotalCount += workflowStat.count
          stat.total += workflowStat.count
        }

        stat.workflowName = workflowStat.workflowName

        return acc.set(key, stat)
      }, new Map())
      .values()
  ]

  return result.sort((a, b) => b.total - a.total)
}

export function mapMonthlyStats(stats: BaseStat[], months = 6) {
  const statsMap: { [key: number]: BaseStat } = {}

  for (let i = 0; i < months; i++) {
    statsMap[
      Number(
        luxonDate()
          .minus({ months: i })
          .toFormat('L')
      )
    ] = {
      date: luxonDate()
        .minus({ months: i })
        .toJSDate()
    } as BaseStat
  }

  stats.forEach((stat: BaseStat) => {
    if (stat.month in statsMap) {
      statsMap[stat.month] = {
        ...statsMap[stat.month],
        ...stat
      }
    }
  })
  return Object.values(statsMap)
}

export const getRemediationIncidentsStats = createAsyncThunk<IncidentsStat[], StatsApiConfig, ApiRejectResponse>(
  'STATS/getIncidentsStats',
  async function doForensicsGetIncidentsStats(payload, { rejectWithValue }) {
    const { accessTokenId } = payload

    try {
      const resp = await restClient(apiRoutes.REMEDIATION_INCIDENTS_STATS, {
        data: { accessTokenId }
      })

      return mapMonthlyStats(resp.data.incidentsStats.data) as IncidentsStat[]
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getRemediationEmailThreatsStats = createAsyncThunk<EmailThreatsStat[], StatsApiConfig, ApiRejectResponse>(
  'STATS/getRemediationThreatStats',
  async function doForensicsGetEmailThreatsStats(payload, { rejectWithValue }) {
    const { accessTokenId } = payload

    try {
      const resp = await restClient(apiRoutes.REMEDIATION_EMAIL_THREATS_STATS, {
        data: { accessTokenId }
      })

      return mapMonthlyStats(resp.data.emailThreatsStats.data) as EmailThreatsStat[]
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getRemediationTopUsersStats = createAsyncThunk<TopUserStat[], StatsApiConfig, ApiRejectResponse>(
  'STATS/getRemediationTopUsersStats',
  async function doForensicsGetRemediationTopUsersStats(payload, { rejectWithValue }) {
    const { accessTokenId } = payload

    try {
      const resp = await restClient(apiRoutes.REMEDIATION_TOP_USERS_STATS, {
        data: { accessTokenId }
      })

      return resp.data.topUsersStats as TopUserStat[]
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getUserReportedTopReportersStats = createAsyncThunk<
  TopUserReportedStat[],
  StatsApiConfig,
  ApiRejectResponse
>('STATS/getUserReportedTopReportersStats', async function doForensicsGetUserReportedTopReportersStats(
  payload,
  { rejectWithValue }
) {
  const { accessTokenId } = payload

  try {
    const resp = await restClient(apiRoutes.USER_REPORTED_TOP_REPORTERS_STATS, {
      data: { accessTokenId }
    })

    return resp.data.topReportingUsers as TopUserReportedStat[]
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getWorkflowRunsStats = createAsyncThunk<WorkflowRunsStat[], StatsApiConfig, ApiRejectResponse>(
  'STATS/getWorkflowRunsStats',
  async function doForensicsGetWorkflowRunsStats(payload, { rejectWithValue }) {
    const { accessTokenId } = payload

    try {
      const resp = await restClient(apiRoutes.WORKFLOW_RUNS_STATS, {
        data: { accessTokenId }
      })

      return mapMonthlyStats(resp.data.workflowRuns) as WorkflowRunsStat[]
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getWorkflowTopRunsStats = createAsyncThunk<WorkflowTopRunsStat[], StatsApiConfig, ApiRejectResponse>(
  'STATS/getWorkflowTopRunsStats',
  async function doForensicsGetWorkflowTopRunsStats(payload, { rejectWithValue }) {
    const { accessTokenId } = payload

    try {
      const resp = await restClient(apiRoutes.WORKFLOWS_TOP_RUNS_STATS, {
        data: { accessTokenId }
      })

      return aggregateTotalRuns(resp.data.topWorkflows) as WorkflowTopRunsStat[]
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)
