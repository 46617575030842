import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  listItem: {
    cursor: 'pointer',
    borderRadius: theme.spacing(0.5),
    justifyContent: 'space-between'
  },
  listItemSecondary: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&:hover': {
      background: theme.colors.blue050,
      '& $pushPin': {
        display: 'block'
      }
    },
    '&.Mui-selected': {
      background: theme.colors.blue050,
      color: theme.colors.blue700,
      '&:hover': {
        background: theme.colors.blue050,
        color: theme.colors.blue700
      }
    }
  },
  pushPin: {
    display: 'none'
  },
  listItemText: {
    cursor: 'pointer'
  }
}))
