import { useReducer, useMemo, useCallback, useEffect } from 'react'
import validator from 'validator'

import { isFailed, isPending, isSuccess } from 'global/redux/toolkit/api'

import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'
import { saveForensicsSiemSettings, testForensicsSiemSettings } from 'fir/redux/features/settings/settingsSlice'

interface FormValues {
  isActive: boolean
  syslogHost: string
  syslogPort: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseSiemSettingsParams {}

export interface SiemSettingsProps {
  formValues: FormValues
  handleOnSave: () => void
  handleOnTest: () => void
  isInvalidHost: boolean
  isInvalidPort: boolean
  isPageInProgress: boolean
  isSaveButtonDisabled: boolean
  isTestButtonDisabled: boolean
  onFormChange: any
  onSaveFailed: boolean
  onSaveSuccess: boolean
  onTestFailed: boolean
  onTestSuccess: boolean
}

export default function useSiemSettingsLogic(): [SiemSettingsProps] {
  const dispatch = useAppDispatch()
  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    isActive: false,
    syslogHost: '',
    syslogPort: ''
  })

  // Redux Toolkit stores
  const { accessTokenId, settings } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id || '',
    settings: _stores.settings
  }))

  // settings state
  const {
    forensicsSiem,
    isSaveSiemSettingsFailed,
    isSaveSiemSettingsSuccess,
    isSiemSettingsLoading,
    isTestSiemSettingsFailed,
    isTestSiemSettingsSuccess
  } = {
    forensicsSiem: settings.forensicsSiem,
    isSaveSiemSettingsFailed: isFailed(settings.saveSiemSettingsApiStatus),
    isSaveSiemSettingsSuccess: isSuccess(settings.saveSiemSettingsApiStatus),
    isSiemSettingsLoading:
      isPending(settings.getForensicsSiemSettingsApiStatus) ||
      isPending(settings.saveSiemSettingsApiStatus) ||
      isPending(settings.testSiemSettingsApiStatus),
    isTestSiemSettingsFailed: isFailed(settings.testSiemSettingsApiStatus),
    isTestSiemSettingsSuccess: isSuccess(settings.testSiemSettingsApiStatus)
  }

  useEffect(() => {
    setState({
      ...forensicsSiem,
      syslogPort: String(forensicsSiem.syslogPort)
    })
  }, [forensicsSiem])

  const isInvalidHost = useMemo(() => {
    return !!state.syslogHost.length && !validator.isURL(state.syslogHost) && !validator.isIP(state.syslogHost)
  }, [state.syslogHost])

  const isInvalidPort = useMemo(() => {
    return !!state.syslogPort.length && !validator.isPort(state.syslogPort)
  }, [state.syslogPort])

  const settingsDiff = useMemo(() => {
    return (
      state.isActive !== forensicsSiem.isActive ||
      state.syslogHost !== forensicsSiem.syslogHost ||
      state.syslogPort !== String(forensicsSiem.syslogPort)
    )
  }, [forensicsSiem, state.isActive, state.syslogHost, state.syslogPort])

  const isSaveButtonDisabled = useMemo(() => {
    return (
      !settingsDiff ||
      isInvalidHost ||
      isInvalidPort ||
      !state.syslogHost.length ||
      !state.syslogPort.length ||
      isSiemSettingsLoading
    )
  }, [
    settingsDiff,
    isInvalidHost,
    isInvalidPort,
    state.syslogHost.length,
    state.syslogPort.length,
    isSiemSettingsLoading
  ])

  const isTestButtonDisabled = useMemo(() => {
    return settingsDiff || !forensicsSiem.isActive || !forensicsSiem.syslogHost || !forensicsSiem.syslogPort
  }, [settingsDiff, forensicsSiem])

  const onFormChange = useCallback(
    (formProp: string, isCheckbox = false) => (e: any) => {
      setState({ [formProp]: e.target[isCheckbox ? 'checked' : 'value'] })
    },
    []
  )

  const handleOnSave = useCallback(() => {
    dispatch(
      saveForensicsSiemSettings({
        accessTokenId,
        siemSettings: {
          ...state,
          syslogPort: Number(state.syslogPort)
        }
      })
    )
  }, [accessTokenId, dispatch, state])

  const handleOnTest = useCallback(() => {
    dispatch(testForensicsSiemSettings({ accessTokenId }))
  }, [accessTokenId, dispatch])

  return useMemo(
    () => [
      {
        formValues: state as FormValues,
        handleOnSave,
        handleOnTest,
        isInvalidHost,
        isInvalidPort,
        isPageInProgress: isSiemSettingsLoading,
        isSaveButtonDisabled,
        isTestButtonDisabled,
        onFormChange,
        onSaveFailed: isSaveSiemSettingsFailed,
        onSaveSuccess: isSaveSiemSettingsSuccess,
        onTestFailed: isTestSiemSettingsFailed,
        onTestSuccess: isTestSiemSettingsSuccess
      }
    ],
    [
      handleOnSave,
      handleOnTest,
      isInvalidHost,
      isInvalidPort,
      isSaveButtonDisabled,
      isSaveSiemSettingsFailed,
      isSaveSiemSettingsSuccess,
      isSiemSettingsLoading,
      isTestButtonDisabled,
      isTestSiemSettingsFailed,
      isTestSiemSettingsSuccess,
      onFormChange,
      state
    ]
  )
}
