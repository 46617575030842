import { useEffect, useCallback, useReducer, useMemo } from 'react'

import { config } from 'global/lib/config'
import useUserDataLib from 'global/lib/userData/useUserData'
import { logout } from 'global/redux/features/auth/authSlice'
import { setCurrentAccessToken } from 'global/redux/features/accessToken/accessTokenSlice'
import useAccessTokenLib from 'global/lib/accessToken/useAccessToken'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import routesConfig from 'sen/lib/routes/routesConfig'
import { updateAccessToken } from 'sen/redux/features/settings/settingsSlice'
import { isFailed, isSuccess } from 'global/redux/toolkit/api'
import { UpdateAccessTokenRequest } from 'sen/redux/features/settings/types'

export interface SignupRemediatesLogicProps {
  onUpdateCMSettings: (e: any) => void
  selectedCMSetting: string
  onContinue: () => void
  inProgress: boolean
  logout: () => void
  userEmail: string
}

export default function useSignupRemediatesLogic(): [SignupRemediatesLogicProps] {
  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    selectedCMSetting: config.CM_SETTINGS.ENFORCE_MODE.name,
    inProgress: false
  })
  const { isUpdateAccessTokenFailed, isUpdateAccessTokenSuccess, accessToken, user } = useAppSelector(_stores => ({
    accessToken: _stores.accessToken.accessToken,
    isUpdateAccessTokenFailed: isFailed(_stores.settings.updateAccessTokenApiStatus),
    isUpdateAccessTokenSuccess: isSuccess(_stores.settings.updateAccessTokenApiStatus),
    user: _stores.user.data
  }))
  const dispatch = useAppDispatch()
  const [userDataLib] = useUserDataLib()
  const [accessTokenLib] = useAccessTokenLib()

  // init
  useEffect(() => {
    if (!user?.id) {
      routesConfig.SIGNIN_SIGNUP.goto()
    }

    // If accessToken is not set yet
    if (!accessToken) {
      const firstAccessTokenId = accessTokenLib.getAllAccessTokens()?.[0]?.id

      if (!firstAccessTokenId) {
        routesConfig.SIGNIN_SIGNUP.goto()
      }

      dispatch(setCurrentAccessToken(firstAccessTokenId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // accessToken is updated
  useEffect(() => {
    if (isUpdateAccessTokenFailed) {
      setState({ inProgress: false })
      dispatch(logout())
    } else if (isUpdateAccessTokenSuccess) {
      setState({ inProgress: false })
      routesConfig.DASHBOARD.goto({ reportId: accessToken?.id })
    }
  }, [isUpdateAccessTokenSuccess, isUpdateAccessTokenFailed, dispatch, accessToken])

  const cmSettings: any = useMemo(() => {
    return Object.values(config.CM_SETTINGS).reduce(
      (all: any, setting: any) => ({
        ...all,
        [setting.name]: setting.value
      }),
      {}
    )
  }, [])

  const onContinue = useCallback(() => {
    const { accountId } = userDataLib.getAccountByAccessToken(accessToken?.id) || {}

    setState({ inProgress: true })
    const nextAccessToken: UpdateAccessTokenRequest = {
      settings: cmSettings[state.selectedCMSetting],
      accountId,
      scanType: config.SCAN_TYPES.SENTINEL,
      email: user?.email
    }
    dispatch(updateAccessToken(nextAccessToken))
  }, [state.selectedCMSetting, cmSettings, dispatch, userDataLib, accessToken, user])

  const onUpdateCMSettings = useCallback((selectedCMSetting: any) => {
    setState({ selectedCMSetting })
  }, [])

  return useMemo(() => {
    return [
      {
        onUpdateCMSettings,
        selectedCMSetting: state.selectedCMSetting,
        onContinue,
        inProgress: state.inProgress,
        logout: () => dispatch(logout()),
        userEmail: user?.email || ''
      }
    ]
  }, [state.selectedCMSetting, onUpdateCMSettings, onContinue, state.inProgress, user, dispatch])
}
