import React, { useCallback, useEffect, useMemo, useReducer } from 'react'
import { FilterOperation } from '@barracuda/reporting'
import {
  FormControl,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Typography
} from '@barracuda-internal/bds-core'
import { Filter } from '@barracuda-internal/bds-core/dist/Icons/Core'

import { Filter as FilterType } from 'global/types/api/unifiedReporting'

import styles from 'global/components/lib/dataTable/columnFilters/filterStyles'

interface MinMaxFilterProps {
  columnFilter?: FilterType
  columnId: string
  columnMax?: number
  columnMin?: number
  placeholder?: string
  setColumnFilter: (filterValue: FilterType) => void
}

const MinMaxFilter: React.FC<MinMaxFilterProps> = ({
  columnFilter,
  columnId,
  columnMax,
  columnMin,
  placeholder,
  setColumnFilter
}) => {
  const classes = styles()
  const [initialMin, initialMax] = columnFilter?.value || [columnMin, columnMax]
  const [{ min, max }, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    min: initialMin,
    max: initialMax
  })

  const handleSetMax = useCallback((e: any) => {
    setState({ max: parseInt(e.target.value, 10) })
  }, [])

  const handleSetMin = useCallback((e: any) => {
    setState({ min: parseInt(e.target.value, 10) })
  }, [])

  useEffect(() => {
    if (typeof min === 'number' || typeof max === 'number') {
      setColumnFilter({ fieldName: columnId, value: [min, max], operation: FilterOperation.BETWEEN })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnId, max, min])

  return useMemo(
    () => (
      <>
        <MenuItem key="title">
          <ListItemIcon>
            <Filter fontSize="small" />
          </ListItemIcon>
          <ListItemText>Filter</ListItemText>
        </MenuItem>

        <Grid container justifyContent="center" style={{ paddingBottom: '15px', marginBottom: '5px' }}>
          <FormControl style={{ width: '85%' }}>
            <Grid container spacing={1} alignContent="center" alignItems="center" justifyContent="space-between">
              <Grid container item xs={4} className={classes.label} justifyContent="flex-end">
                <Typography align="right" data-field="condition-filter-max" variant="body1">
                  Max&nbsp;
                </Typography>
                {typeof columnMax === 'number' && (
                  <Typography align="right" data-field="condition-filter-max" variant="body1" color="secondary">
                    {`(${columnMax})`}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  InputProps={{ inputProps: { min: columnMin, max: columnMax } }}
                  onChange={handleSetMax}
                  onKeyDown={e => {
                    e.stopPropagation()
                  }}
                  placeholder={placeholder}
                  type="number"
                  value={max}
                  variant="standard"
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} alignContent="center" alignItems="center" justifyContent="space-between">
              <Grid container item xs={4} className={classes.label} justifyContent="flex-end">
                <Typography align="right" data-field="condition-filter-max" variant="body1">
                  Min&nbsp;
                </Typography>
                {typeof columnMin === 'number' && (
                  <Typography align="right" data-field="condition-filter-max" variant="body1" color="secondary">
                    {`(${columnMin})`}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  InputProps={{ inputProps: { min: columnMin, max: columnMax } }}
                  onChange={handleSetMin}
                  onKeyDown={e => {
                    e.stopPropagation()
                  }}
                  placeholder={placeholder}
                  type="number"
                  value={min}
                  variant="standard"
                />
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
      </>
    ),
    [classes.label, columnMax, columnMin, handleSetMax, handleSetMin, max, min, placeholder]
  )
}

export default MinMaxFilter
