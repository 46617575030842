import React from 'react'

import styles from 'global/components/lib/layout/navbar/navbarStyles'

const NavbarSpacer: React.FC<{}> = () => {
  const classes = styles()

  return <div className={classes.flexGrow} />
}

export default NavbarSpacer
