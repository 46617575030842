import { ApiConfigRoutes } from 'global/lib/api/apiRoutes'

const API_ROUTES: ApiConfigRoutes = {
  account: {
    ADD_PRODUCT_ASSIGNMENT: {
      method: 'POST',
      path: '/account/add-product-assignment'
    },
    CHECK_BCS_SUBSCRIPTION: {
      method: 'POST',
      path: '/account/check-BCS-subscription'
    },
    SET_SERIAL_NUMBER: {
      method: 'POST',
      path: '/account/set-serial-number'
    },
    GET_SERIAL_WITH_ACCOUNT_ID: {
      method: 'POST',
      path: '/account/get-serial-with-accountId'
    },
    GET_PRODUCT_SERIAL: {
      method: 'POST',
      path: '/account/get-product-serial'
    },
    EXTEND_PRODUCT_TRIAL: {
      method: 'POST',
      path: '/account/extend-product-trial'
    }
  },
  admin: {
    REVERT_IMPERSONATION: {
      method: 'POST',
      path: '/admin/revert-impersonation'
    }
  },
  analytics: {
    MIXPANEL_TRACK_EVENT: {
      method: 'POST',
      path: '/analytics/mixpanel-track-event'
    }
  },
  auth: {
    SIGNIN: {
      method: 'POST',
      path: '/auth/signin'
    },
    SIGNIN_OTP: {
      method: 'POST',
      path: '/auth/signin/otp'
    },
    GET_USER: {
      method: 'POST',
      path: '/auth/user'
    },
    GET_FORENSICS_DEMO_USER: {
      method: 'GET',
      path: '/auth/forensics-demo'
    },
    GET_SENTINEL_DEMO_USER: {
      method: 'GET',
      path: '/auth/sentinel-demo'
    },
    UPDATE_STATE: {
      method: 'POST',
      path: '/signup/update-state'
    },
    ACTIVATE_DEVELOPER_INTERFACE: {
      method: 'POST',
      path: '/auth/developer-interface/activate'
    },
    REFRESH_TOKEN: {
      method: 'POST',
      path: '/auth/bcc/refreshToken'
    }
  },
  egd: {
    GET_CONVERGED_MESSAGE_LOG: {
      method: 'POST',
      path: '/converged-message-log/get-converged-message'
    }
  },
  LOGOUT: {
    method: 'POST',
    path: '/logout'
  },
  remediationService: {
    CHECK_O365_PERMISSIONS: {
      method: 'POST',
      path: '/remediation/office365/permissions'
    }
  },
  report: {
    SCAN_STATUS: {
      method: 'POST',
      path: '/report/:provider/scan-status'
    },
    SCAN_STATS: {
      method: 'POST',
      path: '/report/:provider/stats/sources'
    },
    SCAN_THREAT_LOG: {
      method: 'POST',
      path: '/report/scan-threat-log'
    }
  },
  scheduledReports: {
    CREATE_SCHEDULED_REPORT: {
      method: 'POST',
      path: '/scheduled-reports/create'
    },
    DELETE_SCHEDULED_REPORT: {
      method: 'POST',
      path: '/scheduled-reports/delete'
    },
    GET_SCHEDULED_REPORTS: {
      method: 'POST',
      path: '/scheduled-reports/schedules'
    },
    GET_SCHEDULED_REPORT: {
      method: 'POST',
      path: '/scheduled-reports/schedule'
    },
    UPDATE_SCHEDULED_REPORT: {
      method: 'POST',
      path: '/scheduled-reports/update'
    }
  },
  settings: {
    ADD_WHITELIST_ITEM: {
      method: 'POST',
      path: '/settings/whitelist/add'
    },
    DELETE_WHITELIST_ITEM: {
      method: 'POST',
      path: '/settings/whitelist/delete'
    },
    EDIT_WHITELIST_ITEM: {
      method: 'POST',
      path: '/settings/whitelist/edit'
    },
    GET_WHITELIST_ITEMS: {
      method: 'POST',
      path: '/settings/whitelist/list'
    }
  },
  signup: {
    SIGNUP: {
      method: 'POST',
      path: '/signup/create'
    },
    SIGNUP_FLAGS: {
      method: 'POST',
      path: '/signup/flags'
    },
    SIGNUP_SAVE_PROFILE: {
      method: 'POST',
      path: '/signup/save-profile'
    }
  },
  sp: {
    SP_ATTACK_DETAILS: {
      method: 'POST',
      path: '/sp/details'
    },
    SP_ATTACK_ID: {
      method: 'POST',
      path: '/sp/attack-id'
    }
  },
  unifiedReporting: {
    CREATE_UNIFIED_REPORTING_REPORT: {
      method: 'POST',
      path: '/unified-reporting/create-report'
    },
    DELETE_UNIFIED_REPORTING_REPORT: {
      method: 'POST',
      path: '/unified-reporting/delete-report'
    },
    GET_UNIFIED_REPORTING_REPORTS: {
      method: 'POST',
      path: '/unified-reporting/reports'
    },
    GET_UNIFIED_REPORTING_REPORT: {
      method: 'POST',
      path: '/unified-reporting/report'
    },
    GET_UNIFIED_REPORTING_REPORT_CSV: {
      method: 'POST',
      path: '/unified-reporting/export-as-csv'
    },
    GET_UNIFIED_REPORTING_REPORT_PDF: {
      method: 'POST',
      path: '/unified-reporting/export-as-pdf'
    },
    GET_UNIFIED_REPORTING_REPORT_DATA: {
      method: 'POST',
      path: '/unified-reporting/report-data'
    },
    UPDATE_UNIFIED_REPORTING_REPORT: {
      method: 'POST',
      path: '/unified-reporting/update-report'
    },
    PIN_UNIFIED_REPORTING_REPORT: {
      method: 'POST',
      path: '/unified-reporting/pin-report'
    }
  }
}

export default API_ROUTES
