import React from 'react'
import PropTypes from 'prop-types'

import { BarChart } from '@barracuda-internal/bds-core'
import ChartWrapper from '@barracuda-internal/bds-core/dist/ChartWrapper/ChartWrapper'

export interface RemediatedThreatsDashboardProps {
  chartConfig: any
  chartWrapperConfig: any
}

const RemediatedThreatsDashboard: React.FC<RemediatedThreatsDashboardProps> = ({ chartConfig, chartWrapperConfig }) => {
  return (
    <ChartWrapper chartWrapper={{ ...chartWrapperConfig }}>
      <BarChart barChart={{ ...chartConfig }} />
    </ChartWrapper>
  )
}

RemediatedThreatsDashboard.propTypes = {
  chartConfig: PropTypes.any.isRequired,
  chartWrapperConfig: PropTypes.any.isRequired
}

export default RemediatedThreatsDashboard
