import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AttackType } from 'global/types/api/attackType'
import { getSpAttack, getSpAttackId } from 'global/redux/features/attack/attackApiThunks'
import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'global/redux/toolkit/api'

export interface AttackState {
  confidenceLevel: string
  details: AttackType
  feedbackPublicKey: string
  spAttackId: string
  spAttackApiStatus: ApiStatus
  spAttackIdApiStatus: ApiStatus
}

// initialState
export const INITIAL_STATE: AttackState = {
  confidenceLevel: '',
  details: {} as AttackType,
  feedbackPublicKey: '',
  spAttackId: '',
  spAttackApiStatus: inIdle,
  spAttackIdApiStatus: inIdle
}

/* eslint-disable no-param-reassign */
export const attackSlice = createSlice({
  name: 'ATTACK',
  initialState: INITIAL_STATE,
  reducers: {
    setAttack: (state: AttackState, action: PayloadAction<AttackType>) => {
      state.details = action.payload
      state.spAttackApiStatus = successResponse
    },
    setFeedbackPublicKey: (state: AttackState, action: any) => {
      state.feedbackPublicKey = action.payload
    },
    resetAttackId: (state: AttackState) => {
      state.spAttackId = ''
      state.confidenceLevel = ''
    },
    resetFeedbackPublicKey: (state: AttackState) => {
      state.feedbackPublicKey = ''
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getSpAttack.pending, state => {
        state.spAttackApiStatus = inProgress
      })
      .addCase(getSpAttack.fulfilled, (state, action) => {
        state.spAttackApiStatus = successResponse
        state.details = action.payload
      })
      .addCase(getSpAttack.rejected, (state, action) => {
        state.spAttackApiStatus = failedResponse(action.payload as string)
      })
      .addCase(getSpAttackId.pending, state => {
        state.spAttackIdApiStatus = inProgress
      })
      .addCase(getSpAttackId.fulfilled, (state, action) => {
        state.spAttackIdApiStatus = successResponse
        state.spAttackId = action.payload.attackId
        state.confidenceLevel = action.payload.confidenceLevel
      })
      .addCase(getSpAttackId.rejected, (state, action) => {
        state.spAttackIdApiStatus = failedResponse(action.payload as string)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { reset, resetAttackId, resetFeedbackPublicKey, setAttack, setFeedbackPublicKey } = attackSlice.actions

export { getSpAttack, getSpAttackId }

export default attackSlice.reducer
