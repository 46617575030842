import { useState, useMemo, useCallback } from 'react'

import * as analyticsLib from 'global/lib/analytics/analyticsService'

export type OnCopyToClipBoard = () => void
export type IsCopiedVisible = boolean

const COPIED_VISIBLE_TIMESTAMP = 2000

export default function useCopyToClipboardLogic(
  analyticsEventName?: string,
  textToCopy?: string
): [OnCopyToClipBoard, IsCopiedVisible] {
  const [isCopiedVisible, setIsCopiedVisible] = useState<boolean>(false)

  const onCopyToClipBoard = useCallback(() => {
    setIsCopiedVisible(true)
    if (analyticsEventName) {
      analyticsLib.trackAppEvent(analyticsEventName, { text: textToCopy })
    }
    setTimeout(() => {
      setIsCopiedVisible(false)
    }, COPIED_VISIBLE_TIMESTAMP)
  }, [analyticsEventName, textToCopy])

  return useMemo(() => {
    return [onCopyToClipBoard, isCopiedVisible]
  }, [onCopyToClipBoard, isCopiedVisible])
}
