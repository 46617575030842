import { AutomatedWorkflowsEventFields } from '@barracuda/reporting'

import { Column } from 'global/types/api/unifiedReporting'

export type AutomatedWorkflowsEventRunWorkflow = {
  accountId: string
  accessTokenId: string
  createdOn: string
  deleted: boolean
  description: string
  editor: string
  enabled: boolean
  name: string
  uuid: string
  revision: number
}

export type AutomatedWorkflowsEventRun = {
  anyActions: boolean
  createdOn: string
  workflow: AutomatedWorkflowsEventRunWorkflow
}

export type AutomatedWorkflowsEventNode = {
  eventType: string
  type: string
}

export type AutomatedWorkflowsEvent = {
  eventCreatedOn: string
  eventResult?: string | null
  eventSuccess: boolean
  run: AutomatedWorkflowsEventRun
  node: AutomatedWorkflowsEventNode
}

export const automatedWorkflowsEventColumnConfig: Column[] = [
  {
    accessorKey: AutomatedWorkflowsEventFields.EVENT_CREATED_ON.name,
    header: 'event_time',
    fieldType: AutomatedWorkflowsEventFields.EVENT_CREATED_ON.type,
    possibleValues: AutomatedWorkflowsEventFields.EVENT_CREATED_ON.possibleValues,
    category: 'event',
    hideColumnFromList: true
  },
  {
    accessorKey: AutomatedWorkflowsEventFields.NODE_TYPE.name,
    header: 'node_type',
    fieldType: AutomatedWorkflowsEventFields.NODE_TYPE.type,
    possibleValues: AutomatedWorkflowsEventFields.NODE_TYPE.possibleValues,
    category: 'event'
  },
  {
    accessorKey: AutomatedWorkflowsEventFields.NODE_EVENT_TYPE.name,
    header: 'node_event_type',
    fieldType: AutomatedWorkflowsEventFields.NODE_EVENT_TYPE.type,
    possibleValues: AutomatedWorkflowsEventFields.NODE_EVENT_TYPE.possibleValues,
    category: 'event'
  },
  // TODO: need to add node_config
  {
    accessorKey: AutomatedWorkflowsEventFields.EVENT_RESULT.name,
    header: 'event_result',
    fieldType: AutomatedWorkflowsEventFields.EVENT_RESULT.type,
    possibleValues: AutomatedWorkflowsEventFields.EVENT_RESULT.possibleValues,
    category: 'event'
  },
  {
    accessorKey: AutomatedWorkflowsEventFields.RUN_ANY_ACTIONS.name,
    header: 'run_any_action',
    fieldType: AutomatedWorkflowsEventFields.RUN_ANY_ACTIONS.type,
    possibleValues: AutomatedWorkflowsEventFields.RUN_ANY_ACTIONS.possibleValues,
    category: 'run'
  },
  {
    accessorKey: AutomatedWorkflowsEventFields.RUN_CREATED_ON.name,
    header: 'last_edited_on',
    fieldType: AutomatedWorkflowsEventFields.RUN_CREATED_ON.type,
    possibleValues: AutomatedWorkflowsEventFields.RUN_CREATED_ON.possibleValues,
    category: 'run'
  },
  {
    accessorKey: AutomatedWorkflowsEventFields.WORKFLOW_NAME.name,
    header: 'workflow_name',
    fieldType: AutomatedWorkflowsEventFields.WORKFLOW_NAME.type,
    possibleValues: AutomatedWorkflowsEventFields.WORKFLOW_NAME.possibleValues,
    category: 'workflow'
  },
  {
    accessorKey: AutomatedWorkflowsEventFields.WORKFLOW_DESCRIPTION.name,
    header: 'workflow_description',
    fieldType: AutomatedWorkflowsEventFields.WORKFLOW_DESCRIPTION.type,
    possibleValues: AutomatedWorkflowsEventFields.WORKFLOW_DESCRIPTION.possibleValues,
    category: 'workflow'
  },
  {
    accessorKey: AutomatedWorkflowsEventFields.WORKFLOW_CREATED_ON.name,
    header: 'workflow_created_on',
    fieldType: AutomatedWorkflowsEventFields.WORKFLOW_CREATED_ON.type,
    possibleValues: AutomatedWorkflowsEventFields.WORKFLOW_CREATED_ON.possibleValues,
    category: 'workflow'
  },
  {
    accessorKey: AutomatedWorkflowsEventFields.WORKFLOW_EDITOR.name,
    header: 'last_edited_by',
    fieldType: AutomatedWorkflowsEventFields.WORKFLOW_EDITOR.type,
    possibleValues: AutomatedWorkflowsEventFields.WORKFLOW_EDITOR.possibleValues,
    category: 'workflow'
  },
  {
    accessorKey: AutomatedWorkflowsEventFields.WORKFLOW_ENABLED.name,
    header: 'workflow_enabled',
    fieldType: AutomatedWorkflowsEventFields.WORKFLOW_ENABLED.type,
    possibleValues: AutomatedWorkflowsEventFields.WORKFLOW_ENABLED.possibleValues,
    category: 'workflow'
  },
  {
    accessorKey: AutomatedWorkflowsEventFields.WORKFLOW_UUID.name,
    header: 'workflow_uuid',
    fieldType: AutomatedWorkflowsEventFields.WORKFLOW_UUID.type,
    possibleValues: AutomatedWorkflowsEventFields.WORKFLOW_UUID.possibleValues,
    category: 'workflow'
  }
  // TODO: need to add workfow_saved_version as a column once backend supports it
]
