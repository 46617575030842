import { makeStyles } from '@material-ui/core/styles'

import tableStyles, {
  CELL_HEIGHT,
  TABLE_HEADER_HEIGHT,
  TABLE_FOOTER_HEIGHT
} from 'global/components/lib/grid/tableStyles'
import { COLORS } from 'ets/configs/styles/defaults'

const EMPLOYEES_PANEL_HEIGHT = 54
export { TABLE_HEADER_HEIGHT, TABLE_FOOTER_HEIGHT }
export const TABLE_CELL_HEIGHT = CELL_HEIGHT + 2

export default makeStyles(theme => {
  const defaultTableStyles = tableStyles(theme)

  return {
    root: {
      position: 'relative',
      marginTop: theme.spacing(4),
      flexGrow: 1,
      marginBottom: theme.spacing(4)
    },
    employeesPanel: {
      cursor: 'pointer',
      height: EMPLOYEES_PANEL_HEIGHT,
      backgroundColor: 'white',

      '&.selected': {
        backgroundColor: COLORS.GREEN,

        '& p': {
          color: 'white'
        }
      }
    },
    disabledText: {
      ...defaultTableStyles.lightCell,
      cursor: 'default',
      opacity: '0.5'
    },
    disabledTab: {
      cursor: 'not-allowed'
    },
    tabTitle: {
      marginLeft: theme.spacing(3),
      color: theme.palette.text.primary,
      fontSize: 16,
      fontWeight: 'bold',
      letterSpacing: 0,
      lineHeight: `${EMPLOYEES_PANEL_HEIGHT}px`,
      userSelect: 'none'
    },
    tabCount: {
      marginRight: theme.spacing(3),
      float: 'right',
      fontSize: 24,
      fontWeight: 'bold',
      letterSpacing: 0,
      lineHeight: `${EMPLOYEES_PANEL_HEIGHT}px`,
      userSelect: 'none'
    },
    tableWrapper: {
      marginTop: theme.spacing(3),
      padding: theme.spacing(2),
      backgroundColor: 'white'
    },
    tableTotal: {
      width: '100%',
      color: theme.palette.text.primary,
      fontSize: 24,
      fontWeight: 'bold',
      letterSpacing: 0,
      lineHeight: '40px',
      paddingBottom: theme.spacing(1),
      borderBottom: '1px solid #DDDDDD'
    },
    searchField: {
      marginTop: 20
    },
    ...defaultTableStyles,
    tableArea: {
      ...defaultTableStyles.tableArea,
      marginTop: theme.spacing(2),
      minHeight: 413
    },
    dataTable: {
      ...defaultTableStyles.dataTable,
      height: TABLE_CELL_HEIGHT * 10 + TABLE_HEADER_HEIGHT + TABLE_FOOTER_HEIGHT
    },
    riskType: {
      display: 'block'
    }
  }
})
