import React from 'react'
import PropTypes from 'prop-types'

import deepmerge from 'deepmerge'

import { Chart } from 'react-google-charts'

import { config } from 'global/lib/config'
import chartConfig from './geoChartConfig'

import styles from './geoChartStyles'

export interface GeoChartProps {
  data: any[]
  config?: {
    [key: string]: any
  }
  onChartSelect?: (value: string) => void
}

const GeoChart: React.FC<GeoChartProps> = ({ data, config: customChartConfig = {}, onChartSelect }) => {
  const classes = styles()

  return (
    <Chart
      chartType="GeoChart"
      className={classes.geoChart}
      data={data}
      options={deepmerge(chartConfig, customChartConfig) as any}
      mapsApiKey={config.googleMapsApiKey}
      chartEvents={[
        {
          eventName: 'select',
          callback: (e: any) => {
            const chart = e.chartWrapper.getChart()
            const selection = chart.getSelection()

            if (selection.length === 1) {
              const [selectedItem] = selection
              const dataTable = e.chartWrapper.getDataTable()
              const selectedValue = dataTable.getValue(selectedItem.row, selectedItem.column || 0)

              if (onChartSelect) {
                onChartSelect(selectedValue)
              }
            }
          }
        }
      ]}
    />
  )
}

GeoChart.propTypes = {
  data: PropTypes.array.isRequired,
  config: PropTypes.any,
  onChartSelect: PropTypes.func
}

GeoChart.defaultProps = {
  config: {},
  onChartSelect: undefined
}

export default GeoChart
