// TODO update with value from config service
import { config } from 'global/lib/config'

export const BASE_IMG_PATH = `/${config.API_VERSION}/assets/img/`

function imagePath(path: string) {
  return `${BASE_IMG_PATH}${path}`
}

const images = {
  arrowDropDown: imagePath('unifiedReporting/arrow-drop-down.svg'),
  cudaTeeth: imagePath('barracuda/barracuda-teeth.png'),
  barracudaEtsForDark: imagePath('barracuda/logo_ets.svg'),
  emptyStateChart: imagePath('icons/empty_state_chart.svg'),
  emptyStateSchedules: imagePath('unifiedReporting/email_reports_empty_schedules_state.svg'),
  horizontalBarChartGrey: imagePath('unifiedReporting/horizontal_bar_chart_grey.svg'),
  horizontalBarChartBlue: imagePath('unifiedReporting/horizontal_bar_chart_blue.svg'),
  horizontalStackedBarChartGrey: imagePath('unifiedReporting/bar_chart_stacked_horizontal.svg'),
  horizontalStackedBarChartBlue: imagePath('unifiedReporting/bar_chart_stacked_horizontal_blue.svg'),
  hamburgerMenu: imagePath('hamburgerMenuIcon.svg'),
  logoSquareFIR: imagePath('fir/fir_logo_square.svg'),
  logoSquareSEN: imagePath('sen/logo_square_SEN_UI.svg'),
  pushPin: imagePath('unifiedReporting/push_pin.svg'),
  pushPinGrey: imagePath('unifiedReporting/push_pin_grey.svg'),
  iconAlertWarning: imagePath('icons/icon_alert_warning.svg'),
  startTrialOverlay: imagePath('start_trial_overlay.svg'),
  statusCREFailed: imagePath('icons/icon_cr_fail.svg'),
  statusCRESuccess: imagePath('icons/icon_cr_success.svg'),
  statusFailed: imagePath('icons/icon_status_fail.svg'),
  statusInfo: imagePath('icons/icon_status_info.svg'),
  statusPending: imagePath('icons/icon_status_pending.svg'),
  statusSuccess: imagePath('icons/icon_status_success.svg'),
  statusWarning: imagePath('icons/Warning.svg'),
  emailInbound: imagePath('icons/icon_inbound.svg'),
  emailScanning: imagePath('img_email-scanning.svg'),
  emailOutbound: imagePath('icons/icon_outbound.svg'),
  officeO365Rectangle: imagePath('office-365-rectangle.png'),
  iconFilter: imagePath('icons/Filter.svg'),
  iconFirstPage: imagePath('icons/FirstPage.svg'),
  iconPrevPage: imagePath('icons/PreviousPage.svg'),
  iconNextPage: imagePath('icons/NextPage.svg'),
  iconLastPage: imagePath('icons/LastPage.svg'),
  iconAccountWhite: imagePath('icons/Account_white.svg'),
  statusSuccessPng: imagePath('icons/success.png'),
  statusWarningPng: imagePath('icons/Warning.png'),
  statusFailedPng: imagePath('icons/Critical.png'),
  o365LogoWhite: imagePath('icons/icon-o365-logo-white.png'),
  pdfIcon: imagePath('icons/pdf.svg'),
  iconO365White: imagePath('icon-o365-white.svg'),
  slackIcon: imagePath('slack_logo.svg'),
  msTeamsIcon: imagePath('ms_teams.svg'),
  awsMarketplace: imagePath('aws_marketplace_logo.svg')
}

export type ImageKeys = keyof typeof images
export type Images = typeof images[ImageKeys]

export default images
