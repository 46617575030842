import { useEffect, useCallback, useReducer, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import * as mixpanelService from 'global/lib/analytics/mixpanelService'
import { config } from 'global/lib/config'
import * as cloudLib from 'global/lib/cloud/cloud'
import { resetSignupUser, resetError, setError, reset } from 'global/redux/features/auth/authSlice'
import { resetLoggedOut } from 'global/redux/features/app/appSlice'
import { User } from 'global/types/api/userType'

import o365Connect from 'ets/components/pages/signinSignup/o365Connect/o365Connect'
import { useAppDispatch, useAppSelector } from 'ets/redux/toolkit/hooks'
import gotoReportPage from 'ets/lib/gotoReportPage'

import ContestSource from 'ets/components/lib/contest/ContestSource.enum'

export interface SigninSignupLogicProps {
  isLoading: boolean
  onSignin: () => void
  onSignup: () => void
  onTogglePermissionDialog: () => void
  onTrackDemoVideoStart: () => void
  onTrackDemoVideoEnd: () => void
  demoVideoPath: string
  requestDemoPath: string
  isPermissionDialogVisible: boolean
  error: string | false
  sendSpeakToExpertTrackingEvent?: () => void
  expandedAccordionPanel: string | boolean
  setExpandedAccordionPanel: (panelId: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => void
  isCobranded: boolean
}

export const ACCORDION_PANELS = {
  PANEL_1: 'panel_1',
  PANEL_2: 'panel_2',
  PANEL_3: 'panel_3',
  PANEL_4: 'panel_4'
}

declare global {
  interface Window {
    __completeBccSignin__: (user: User) => void
    __failedBccSignin__: (error: any) => void
  }
}

export default function useSigninSignupLogic(): [SigninSignupLogicProps] {
  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    isPermissionDialogVisible: false,
    expandedAccordionPanel: ACCORDION_PANELS.PANEL_1,
    isLoading: false
  })
  const { auth } = useAppSelector(_stores => ({
    auth: _stores.auth
  }))

  const dispatch = useAppDispatch()
  const location = useLocation()

  const userFlags = queryString.parse(window.location.search)

  // init
  useEffect(() => {
    dispatch(reset())

    mixpanelService.updateAlias(userFlags)

    analyticsLib.trackAppEvent(analyticsLib.EVENTS.SIGNUP_PAGEVIEW, { url: window.location.href, flags: userFlags })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // BCC signin flow
  const onCompleteBccSignin = useCallback((user: User) => {
    setState({ isLoading: true })
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.SIGNUP_SIGNIN_SUCCESSFUL, {
      url: window.location.href,
      email: user.email
    })
    gotoReportPage({ user })
  }, [])

  const onFailedBccSignin = useCallback(
    (data: any) => {
      const errorMessage = data.error.includes('UserDoesNotExist') ? 'user_does_not_exist' : 'default'
      dispatch(setError(errorMessage))
    },
    [dispatch]
  )

  const onSignin = useCallback(() => {
    dispatch(resetError())
    dispatch(resetLoggedOut())

    analyticsLib.trackAppEvent(analyticsLib.EVENTS.SIGNIN_LOGIN, { url: window.location.href })

    /* eslint-disable no-underscore-dangle */
    window.__completeBccSignin__ = onCompleteBccSignin
    window.__failedBccSignin__ = onFailedBccSignin
    /* eslint-enable no-underscore-dangle */

    cloudLib.bccSignin(config.SCAN_TYPES.ETS)
  }, [dispatch, onCompleteBccSignin, onFailedBccSignin])

  // signup flow
  const onSignup = useCallback(() => {
    dispatch(resetError())
    dispatch(resetSignupUser())

    o365Connect(location)
  }, [dispatch, location])

  const onTogglePermissionDialog = useCallback(() => {
    setState({ isPermissionDialogVisible: !state.isPermissionDialogVisible })
    // we only want to send the tracking once, when the user clicks
    if (!state.isPermissionDialogVisible) {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.SIGNUP_PERMISSION, { url: window.location.href })
    }
  }, [state.isPermissionDialogVisible, setState])

  const sendSpeakToExpertTrackingEvent = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.SIGNUP_SPEAK_TO_EXPERT, { url: window.location.href })
  }, [])

  const onTrackDemoVideoStart = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.SIGNUP_WATCH_DEMO_VIDEO, {
      url: window.location.href
    })
  }, [])

  const onTrackDemoVideoEnd = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.SIGNUP_WATCH_DEMO_VIDEO_END, {
      url: window.location.href
    })
  }, [])

  const setExpandedAccordionPanel = useCallback(
    (panel: string) => (_: React.ChangeEvent<{}>, newExpanded: boolean) => {
      setState({ expandedAccordionPanel: newExpanded ? panel : false })
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.SIGNUP_PERMISSION_ACCORDION, {
        url: window.location.href,
        panel
      })
    },
    []
  )

  const isCobranded = useMemo(() => {
    return window.location.search.includes(ContestSource.AWS_VAR)
  }, [])

  return useMemo(() => {
    return [
      {
        isLoading: state.isLoading,
        onSignin,
        onSignup,
        onTogglePermissionDialog,
        onTrackDemoVideoStart,
        onTrackDemoVideoEnd,
        demoVideoPath: config.LINKS.DEMO_VIDEO,
        requestDemoPath: config.LINKS.REQUEST_A_DEMO,
        isPermissionDialogVisible: state.isPermissionDialogVisible,
        error: auth.error || false,
        sendSpeakToExpertTrackingEvent,
        expandedAccordionPanel: state.expandedAccordionPanel,
        setExpandedAccordionPanel,
        isCobranded
      }
    ]
  }, [
    state,
    onSignin,
    onSignup,
    onTogglePermissionDialog,
    onTrackDemoVideoStart,
    onTrackDemoVideoEnd,
    auth.error,
    sendSpeakToExpertTrackingEvent,
    setExpandedAccordionPanel,
    isCobranded
  ])
}
