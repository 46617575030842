import React from 'react'
import PropTypes from 'prop-types'

import ReactFrame from 'react-frame-component'
import reactHtmlParser from 'react-html-parser'
import {
  Button,
  Radio,
  RadioGroup,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Zoom
} from '@barracuda-internal/bds-core'
import { Settings as SettingsIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'
import { FormControl, FormControlLabel, FormLabel } from '@material-ui/core'

import { useSelector } from 'react-redux'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'

import { useFormatMessage } from 'global/lib/localization'
import styles from './editEmailAlertDialogStyles'

const BASE_I18N_KEY = 'fir.app.new_incident_wizard'

const DEFAULT_NAME = 'Barracuda Email Protection'
const DEFAULT_EMAIL = '<noreply@barracuda.com>'

export interface EditEmailAlertDialogProps {
  getForensicsCustomNotificationPreview: () => void
  getForensicsDefaultNotificationPreview: () => void
  NOTIFICATION_TEMPLATES: any
  onApply: () => void
  onClose: () => void
  updateUserOptions: any
}

const EditEmailAlertDialog: React.FC<EditEmailAlertDialogProps> = ({
  getForensicsCustomNotificationPreview,
  getForensicsDefaultNotificationPreview,
  NOTIFICATION_TEMPLATES,
  onApply,
  onClose,
  updateUserOptions
}) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const stores: any = useSelector((_stores: any) => _stores)

  return (
    <Dialog className={classes.wrapper} fullWidth maxWidth="lg" open TransitionComponent={Zoom}>
      <DialogTitle>
        <Typography variant="h5">{formatMessage('choose_the_notification')}</Typography>
        <Typography variant="subtitle2">
          {formatMessage('to_create_custom_notification')}&nbsp;
          <SettingsIcon className={classes.settingsIcon} />
          &nbsp;
          {formatMessage('settings_on_the_incidents')}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <FormControl>
          <FormLabel>{formatMessage('template')}</FormLabel>
          <RadioGroup
            name="email-alert"
            value={stores.newIncident.userOptions.notificationTemplate}
            onChange={updateUserOptions('notificationTemplate')}
            row
          >
            <FormControlLabel
              control={<Radio color="primary" />}
              value={NOTIFICATION_TEMPLATES.DEFAULT}
              onChange={getForensicsDefaultNotificationPreview}
              label={formatMessage('radio_buttons.default')}
            />
            {stores.accessToken.accessToken.settings.forensicsEndUserNotificationTemplate ===
              NOTIFICATION_TEMPLATES.CUSTOM && (
              <FormControlLabel
                control={<Radio color="primary" />}
                value={NOTIFICATION_TEMPLATES.CUSTOM}
                onChange={getForensicsCustomNotificationPreview}
                label={formatMessage('radio_buttons.custom')}
              />
            )}
          </RadioGroup>
        </FormControl>
        <Typography className={classes.previewLabel} variant="h6">
          {formatMessage('notification_preview')}
        </Typography>
        <Typography className={classes.previewLabel} variant="subtitle2">
          <span className={classes.emailLabel}>{formatMessage('from')}:</span>
          <span className={classes.emailValue}>{DEFAULT_NAME}</span>&nbsp;
          <span className={classes.greyText}>{DEFAULT_EMAIL}</span>
        </Typography>
        <Typography className={classes.previewLabel} variant="subtitle2">
          <span className={classes.emailLabel}>{formatMessage('subject')}:</span>
          <span className={classes.emailValue}>{stores.settings.notificationPreview.subject}</span>
        </Typography>
        {stores.settings.isNotificationPreviewLoading && <LinearProgress />}
        {!!stores.settings.notificationPreview.body.length && (
          <ReactFrame className={classes.frameContent}>
            {reactHtmlParser(stores.settings.notificationPreview.body)}
          </ReactFrame>
        )}
        <DialogActions>
          <Button color="secondary" variant="text" onClick={onClose}>
            {formatMessage('buttons.cancel')}
          </Button>
          <Button onClick={onApply} color="primary" variant="contained">
            {formatMessage('buttons.apply')}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

EditEmailAlertDialog.propTypes = {
  onApply: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  getForensicsDefaultNotificationPreview: PropTypes.func.isRequired,
  getForensicsCustomNotificationPreview: PropTypes.func.isRequired,
  NOTIFICATION_TEMPLATES: PropTypes.any.isRequired,
  updateUserOptions: PropTypes.func.isRequired
}

export default EditEmailAlertDialog
