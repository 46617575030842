import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { validateApiError } from 'global/lib/api/restClient'

import apiRoutes from 'sen/lib/api/apiRoutes'

export type GetIdentities = string
export type GetIdentityDetails = string

const RECIPIENTS_LIMIT = 10

async function doGetIdentities(payload: GetIdentities, { rejectWithValue }: any) {
  try {
    const resp = await restClient(apiRoutes.GET_IDENTITY_AUTOCOMPLETE, {
      data: { search: payload, limit: RECIPIENTS_LIMIT }
    })

    return resp.data.identities
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
}

export const getRecipients = createAsyncThunk('IDENTITY/getRecipients', doGetIdentities)
export const getSenders = createAsyncThunk('IDENTITY/getSenders', doGetIdentities)

async function doGetIdentityDetails(payload: GetIdentityDetails, { rejectWithValue }: any) {
  try {
    const resp = await restClient(apiRoutes.GET_IDENTITY_DETAILS, {
      data: { id: payload }
    })

    return resp.data.details
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
}

export const getRecipientDetails = createAsyncThunk('IDENTITY/getRecipientDetails', doGetIdentityDetails)
export const getSenderDetails = createAsyncThunk('IDENTITY/getSenderDetails', doGetIdentityDetails)
