const APP = {
  NAME: 'ets',
  LANGUAGE: 'en',
  LINKS: {
    REQUEST_A_DEMO: 'https://www.barracuda.com/email-scan#lead-form',
    DEMO_VIDEO: 'https://share.vidyard.com/watch/Cyf1dX27U1eYv2iqb5PZfu'
  },
  SCAN_STATUSES: {
    PREPARING: 'scan_preparing',
    IN_PROGRESS: 'scan_in_progress',
    COMPLETED: 'scan_completed'
  }
}

export default APP
