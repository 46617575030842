import { AttackType, ConfidenceLevel } from 'global/types/api/attackType'
import { ScoreLevel } from 'global/components/lib/score/Score'

export interface KeyType {
  [key: string]: string
}

export interface Indicator {
  id: string
  meta: { [key: string]: any }
}

export interface AnalyizeAttack {
  id: AttackType['id']
  attackTaxonomy?: AttackType['attackTaxonomy']
  confidenceLevel?: AttackType['confidenceLevel']
  sender?: AttackType['sender']
  attackAttributes?: AttackType['attackAttributes']
}

export interface AnalyzedAttack {
  attack: AnalyizeAttack
  indicators: Indicator[]
  severityScore: ScoreLevel | undefined
  confidenceScore: ScoreLevel | undefined
  attackType: AttackType['attackTaxonomy'] | undefined
}

export enum SeverityScore {
  'spam' = 2,
  'blackmail' = 3,
  'phishing' = 4,
  'becSpoofing' = 4,
  'conversationHijacking' = 5
}

export const TAXONOMIES = {
  BEC_SPOOFING: 'becSpoofing',
  SPAM: 'spam',
  BLACKMAIL: 'blackmail',
  PHISHING: 'phishing',
  CONVERSATION_HIJACKING: 'conversationHijacking'
}

export const REMEDIATION_TYPES: KeyType = {
  QUARANTINE: 'SP_QUARANTINE_EMAIL',
  CLEANUP: 'SP_CLEANUP_EMAIL',
  MOVE_BACK: 'SP_MOVE_BACK_EMAIL'
}

export const IMPERSONATIONS_TYPES: KeyType = {
  REPLY_TO: 'reply_to',
  FROM: 'sender'
}

export const ATTACK_TYPES: KeyType = {
  HAS_FROM_IMPERSONATION: 'has_from_impersonation',
  HAS_REPLY_TO_IMPERSONATION: 'has_reply_to_impersonation',
  HELLO_URL: 'hello_url',
  CEO_OR_EXT_CEO: 'ceo_or_ext_ceo',
  SCAMMING: 'scamming',
  BLACKMAILING: 'blackmailing',
  ATTACHMENT_ATTACK: 'attachment_attack',
  SERVICE_PHISHING: 'service_phishing',
  SERVICE_PHISHING_WITH_KEYWORDS: 'service_phishing_with_keywords',
  DOMAIN_IMPERSONATION: 'domain_impersonation',
  CONVERSATION_HIJACKING: 'conversation_hijacking',
  ATTACHMENT_CONVERSATION_HIJACKING: 'attachment_conversation_hijacking'
}

export const SERVICE_PHISHING_PRODUCTS: KeyType = {
  Docusign: 'DocuSign',
  Whatsapp: 'WhatsApp',
  Paypal: 'PayPal',
  Chase: 'Chase Bank',
  Wellsfargo: 'Wells Fargo',
  Bofa: 'Bank of America',
  Citi: 'Citibank',
  Wetransfer: 'WeTransfer',
  Efax: 'eFax',
  Linkedin: 'LinkedIn'
}

export const REPLY_TO_FIELDS_FOR_DOMAIN_IMPERSONATION: string[] = [
  'reply_to_sender',
  'reply_to_recipient',
  'reply_to_cc'
]
export const SENDER_FIELDS_FOR_DOMAIN_IMPERSONATION: string[] = ['sender_recipient', 'sender_cc', 'body_sender']

export default function analyzeAttack(attack: AnalyizeAttack): AnalyzedAttack {
  if (!attack.id) {
    return {
      attack,
      indicators: [],
      severityScore: '0',
      confidenceScore: '0',
      attackType: undefined
    }
  }

  const { attackTaxonomy, confidenceLevel, sender, attackAttributes = {} } = attack
  const attackHasDomainAttributes = attackAttributes?.domains?.length
  const attackHasKeywordAttributes = attackAttributes?.keywords?.length
  const attackImpersonationType = attackAttributes?.impersonation?.impersonation_type
  const isDomainImpersonationAsReplyTo =
    attackAttributes?.reason && REPLY_TO_FIELDS_FOR_DOMAIN_IMPERSONATION.includes(attackAttributes.reason)
  const isDomainImpersonationAsSender =
    attackAttributes?.reason && SENDER_FIELDS_FOR_DOMAIN_IMPERSONATION.includes(attackAttributes.reason)

  function hasAttributes(attributes: string[]) {
    return attributes.some((attribute: string) => !!(attackAttributes as any)[attribute])
  }

  function domainImpersonationReason() {
    if (isDomainImpersonationAsReplyTo) {
      return 'reply to'
    }
    if (isDomainImpersonationAsSender) {
      return 'sender'
    }
    return ''
  }

  const domainImpersonationFound =
    attackAttributes?.attacked_domain_type &&
    attackAttributes?.domain &&
    attackAttributes?.bad_domain &&
    attackAttributes?.reason

  const domainImpersonationMeta = {
    type: attackAttributes?.attacked_domain_type,
    domain: attackAttributes?.domain,
    badDomain: attackAttributes?.bad_domain,
    reason: domainImpersonationReason()
  }

  const attackTypes: any = {
    [ATTACK_TYPES.CEO_OR_EXT_CEO]: {
      found: attackTaxonomy === TAXONOMIES.BEC_SPOOFING && !hasAttributes(['attacked_domain_type', 'bad_domain']),
      meta: {}
    },
    [ATTACK_TYPES.HELLO_URL]: {
      found:
        attackTaxonomy === TAXONOMIES.BEC_SPOOFING &&
        attackAttributes?.domain &&
        !hasAttributes(['attacked_domain_type', 'bad_domain']),
      meta: {}
    },
    [ATTACK_TYPES.HAS_FROM_IMPERSONATION]: {
      found: attackTaxonomy === TAXONOMIES.BEC_SPOOFING && attackImpersonationType === IMPERSONATIONS_TYPES.FROM,
      meta: {
        name: sender?.displayName
      }
    },
    [ATTACK_TYPES.HAS_REPLY_TO_IMPERSONATION]: {
      found: attackTaxonomy === TAXONOMIES.BEC_SPOOFING && attackImpersonationType === IMPERSONATIONS_TYPES.REPLY_TO,
      meta: {
        name: sender?.displayName
      }
    },
    [ATTACK_TYPES.DOMAIN_IMPERSONATION]: {
      found: attackTaxonomy === TAXONOMIES.BEC_SPOOFING && domainImpersonationFound,
      meta: domainImpersonationMeta
    },
    [ATTACK_TYPES.SCAMMING]: {
      found: attackTaxonomy === TAXONOMIES.SPAM,
      meta: {}
    },
    [ATTACK_TYPES.BLACKMAILING]: {
      found: attackTaxonomy === TAXONOMIES.BLACKMAIL,
      meta: {}
    },
    [ATTACK_TYPES.ATTACHMENT_ATTACK]: {
      found: attackTaxonomy === TAXONOMIES.PHISHING && !attackHasDomainAttributes && !attackHasKeywordAttributes,
      meta: {}
    },
    [ATTACK_TYPES.SERVICE_PHISHING]: {
      found: attackTaxonomy === TAXONOMIES.PHISHING && attackHasDomainAttributes && !attackHasKeywordAttributes,
      meta: {
        domains: (attackAttributes?.domains || []).join(', ')
      }
    },
    [ATTACK_TYPES.SERVICE_PHISHING_WITH_KEYWORDS]: {
      found: attackTaxonomy === TAXONOMIES.PHISHING && attackHasKeywordAttributes,
      meta: {
        keywords: (attackAttributes?.keywords || []).map(
          (keyword: string) => SERVICE_PHISHING_PRODUCTS[keyword] || keyword
        )
      }
    },
    [ATTACK_TYPES.CONVERSATION_HIJACKING]: {
      found: attackTaxonomy === TAXONOMIES.CONVERSATION_HIJACKING && domainImpersonationFound,
      meta: domainImpersonationMeta
    },
    [ATTACK_TYPES.ATTACHMENT_CONVERSATION_HIJACKING]: {
      found: attackTaxonomy === TAXONOMIES.CONVERSATION_HIJACKING && !domainImpersonationFound,
      meta: {}
    }
  }

  const indicators = Object.keys(attackTypes).reduce((all: Indicator[], type: string) => {
    const attackType: { found: boolean; meta: any } = attackTypes[type]

    if (attackType.found) {
      if (type === ATTACK_TYPES.SERVICE_PHISHING_WITH_KEYWORDS) {
        return [
          ...all,
          ...attackType.meta.keywords.map((keyword: string) => ({ id: type, meta: { keyword } } as Indicator))
        ]
      }

      return [...all, { id: type, meta: attackType.meta } as Indicator]
    }

    return all
  }, [])

  return {
    attack,
    indicators,
    severityScore: String(SeverityScore[attackTaxonomy as keyof typeof SeverityScore]) as ScoreLevel,
    confidenceScore: confidenceLevel ? (ConfidenceLevel[confidenceLevel] as ScoreLevel) : undefined,
    attackType: attackTaxonomy
  }
}
