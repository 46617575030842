import { makeStyles } from '@material-ui/core/styles'

import dialogStyles from 'global/components/lib/dialogs/dialogStyles'

export default makeStyles(theme => {
  const defaultDialogStyles = dialogStyles(theme)

  const { dialogText, dialogTitle, dialogSubtitle } = defaultDialogStyles

  const saveReportDialogTitle = {
    ...dialogTitle,
    fontWeight: 400
  }

  const saveReportDialogSubtitle = {
    ...dialogSubtitle,
    fontWeight: 300,
    color: theme.colors.black
  }

  const dataRetentionWarningContainer = {
    backgroundColor: theme.colors.redOrange050,
    borderRadius: theme.spacing(0.5),
    marginTop: theme.spacing(2),
    padding: theme.spacing(2)
  }

  const dataRetentionWarningText = {
    ...dialogText,
    color: theme.colors.redOrange600
  }

  const dateRangePickerWarningText = {
    ...saveReportDialogSubtitle,
    marginBottom: theme.spacing(2)
  }

  return {
    ...defaultDialogStyles,
    dataRetentionWarningContainer,
    dataRetentionWarningText,
    dateRangePickerWarningText,
    saveReportDialogTitle,
    saveReportDialogSubtitle,
    button: {
      borderRadius: '5px'
    }
  }
})
