import React, { useCallback, useEffect, useMemo } from 'react'
import { Elements } from 'react-flow-renderer'
import { useParams } from 'react-router-dom'

import { process, SortDescriptor } from '@progress/kendo-data-query'
import { GridDetailRow } from '@progress/kendo-react-grid'

import cloneDeep from 'lodash/cloneDeep'
import routesConfig from 'fir/lib/routes/routesConfig'

import * as workflowDetailsDatatableActions from 'fir/redux/features/dataTables/automatedWorkflowDetails/automatedWorkflowDetailsSlice'
import {
  getWorkflow,
  getWorkflowRuns,
  getMoreWorkflowRuns,
  resetWorkflowRuns
} from 'fir/redux/features/workflows/workflowsSlice'
import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'

import { config } from 'global/lib/config'
import * as datetime from 'global/lib/datetime'
import { useFormatMessage } from 'global/lib/localization'
import toggleInArray from 'global/lib/toggleInArray'
import { isPending, isSuccess } from 'global/redux/toolkit/api'
import { BDSGridPagerConfig, BDSGridSortConfig } from 'global/types/dataTables/dataTables'

import RunDetails from './runDetails/RunDetails'

const BASE_I18N_KEY = 'fir.app.automated_workflows.workflow_details'
const EXPAND_FIELD = 'expandField'

export interface WorkflowDetailsProps {
  breadcrumbList: any
  columnsConfig: any
  expandConfig: any
  GRID_COLUMNS: any
  gridData: any
  isPageLoading: boolean
  isTableLoading: boolean
  pageConfig: BDSGridPagerConfig
  sortConfig: BDSGridSortConfig
  workflowDiagram: Elements
  workflowDescription: string
  workflowEditedBy: string
  workflowUUID: string
}

export default function useWorkflowDetailLogic(): [WorkflowDetailsProps] {
  const dispatch = useAppDispatch()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const urlParams: any = useParams()

  // Redux Toolkit stores
  const { accessTokenId, automatedWorkflows, automatedWorkflowDetailsTable } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id || ' ',
    automatedWorkflows: _stores.workflows,
    automatedWorkflowDetailsTable: _stores.dataTables.automatedWorkflowDetails
  }))

  const { isGetWorkflowRunsLoading, isGetWorkflowRunsSuccess, isGetWorkflowSuccess, isGetWorkflowLoading } = {
    isGetWorkflowRunsLoading: isPending(automatedWorkflows.getWorkflowRunsAPIStatus),
    isGetWorkflowRunsSuccess: isSuccess(automatedWorkflows.getWorkflowRunsAPIStatus),
    isGetWorkflowSuccess: isSuccess(automatedWorkflows.getWorkflowAPIStatus),
    isGetWorkflowLoading: isPending(automatedWorkflows.getWorkflowAPIStatus)
  }

  // Init
  useEffect(() => {
    dispatch(getWorkflow({ accessTokenId, uuid: urlParams.workflowId }))
    dispatch(getWorkflowRuns({ accessTokenId, uuid: urlParams.workflowId, config: apiParams }))

    return () => {
      // Reset workflowRuns object and workflow detail table actions
      dispatch(workflowDetailsDatatableActions.reset())
      dispatch(resetWorkflowRuns())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // on sortChange
  useEffect(() => {
    if (!isGetWorkflowRunsLoading) {
      dispatch(resetWorkflowRuns())
      dispatch(getWorkflowRuns({ accessTokenId, uuid: urlParams.workflowId, config: apiParams }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, automatedWorkflowDetailsTable.sort])

  // // Pagination
  useEffect(() => {
    if (isGetWorkflowRunsSuccess && !automatedWorkflows.workflowRuns.runs[automatedWorkflowDetailsTable.page.skip]) {
      dispatch(getMoreWorkflowRuns({ accessTokenId, uuid: urlParams.workflowId, config: apiParams }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [automatedWorkflowDetailsTable.page.skip])

  const apiParams = useMemo(() => {
    const { page, sort } = automatedWorkflowDetailsTable

    return {
      query: {
        limit: Number(page.take),
        page: Math.abs(Number(page.skip) / Number(page.take)) + 1,
        order: `${sort[0].dir === 'asc' ? '' : '-'}${sort[0].field}`
      }
    }
  }, [automatedWorkflowDetailsTable])

  const breadcrumbList = useMemo(() => {
    const { workflow } = automatedWorkflows

    return [
      {
        text: formatMessage('automated_workflows'),
        key: `workflow-breadcrumb-${workflow.uuid}`,
        onClick: routesConfig.AUTOMATED_WORKFLOWS.goto
      },
      {
        jsx: <span>{workflow.name}</span>
      }
    ]
  }, [automatedWorkflows, formatMessage])

  const populateGroupedItems: any = useCallback(
    (items: any[]) => {
      return items
        .filter((item: any) => Object.keys(item).length)
        .map((item: any) => {
          const expandField = automatedWorkflowDetailsTable.expandedRows.includes(item.createdOn)

          return {
            ...item,
            [EXPAND_FIELD]: expandField
          }
        })
    },
    [automatedWorkflowDetailsTable.expandedRows]
  )

  const gridData = useMemo(() => {
    const { page, sort } = automatedWorkflowDetailsTable

    const results = process(
      // .slice does shallow copy only, Immer will blame any state mutations, use lodash.clonedeep to deep copy instead
      cloneDeep(automatedWorkflows.workflowRuns.runs)
        .slice(page.skip, page.skip + page.take)
        .reduce((all: any[], run: any & { [key: string]: any[] }) => {
          if (!run) {
            return [...all, {}]
          }

          return [
            ...all,
            {
              actionsTaken: `${run.anyActions}`,
              createdOn: run.createdOn,
              expandedRowContent: {
                events: run.events.sort((a: any, b: any) => {
                  return a.createdOn < b.createdOn ? -1 : 1
                })
              },
              formatedDate: datetime.formatDate(run.createdOn, config.DATETIME.DEFAULT_DATE_WITH_TIME_FORMAT),
              workflowName: run.workflow.name
            }
          ]
        }, [])
        .filter((item: any) => Object.keys(item).length),
      { sort }
    )

    return { data: populateGroupedItems(results.data), total: automatedWorkflows.workflowRuns.total }
  }, [
    automatedWorkflowDetailsTable,
    automatedWorkflows.workflowRuns.runs,
    automatedWorkflows.workflowRuns.total,
    populateGroupedItems
  ])

  const onTableConfigChange = useCallback(
    (newConfig: any) => {
      dispatch(workflowDetailsDatatableActions.update({ config: newConfig }))
    },
    [dispatch]
  )

  const onExpandChange = useCallback(
    (e: any) => {
      onTableConfigChange({
        expandedRows: toggleInArray(automatedWorkflowDetailsTable.expandedRows, e.dataItem.createdOn)
      })
    },
    [automatedWorkflowDetailsTable.expandedRows, onTableConfigChange]
  )

  const workflowDiagram = useMemo(() => {
    if (isGetWorkflowSuccess) {
      return JSON.parse(automatedWorkflows.workflow.diagram).map((element: any) => {
        if (element.data) {
          return { ...element, data: { label: element.data.label.props.children } }
        }
        return element
      })
    }

    return []
  }, [isGetWorkflowSuccess, automatedWorkflows.workflow.diagram])

  return useMemo(
    () => [
      {
        columnsConfig: automatedWorkflowDetailsTable.columnsConfig,
        expandConfig: {
          detail: (cellProps: any) => {
            return (
              <>
                <GridDetailRow dataItem={{}} dataIndex={0} />
                <RunDetails {...cellProps.dataItem.expandedRowContent} />
              </>
            )
          },
          expandField: EXPAND_FIELD,
          onExpandChange
        },
        GRID_COLUMNS: { ...automatedWorkflowDetailsTable.GRID_COLUMNS },
        isPageLoading: isGetWorkflowLoading,
        isTableLoading: isGetWorkflowRunsLoading,
        pageConfig: {
          ...automatedWorkflowDetailsTable.page,
          pageable: true,
          total: automatedWorkflows.workflowRuns.total,
          onPageChange: (e: any) => {
            onTableConfigChange({ page: e.page as { skip: number; take: number } })
          }
        },
        sortConfig: {
          sortable: {
            allowUnsort: false,
            mode: 'single'
          },
          sort: automatedWorkflowDetailsTable.sort as SortDescriptor[],
          onSortChange: (e: any) => {
            onTableConfigChange({ sort: e.sort as SortDescriptor[] })
          }
        },
        workflowDescription: automatedWorkflows.workflow.description,
        workflowEditedBy: automatedWorkflows.workflow.editor,
        workflowUUID: automatedWorkflows.workflow.uuid,
        breadcrumbList,
        gridData,
        workflowDiagram
      }
    ],
    [
      automatedWorkflowDetailsTable,
      isGetWorkflowLoading,
      isGetWorkflowRunsLoading,
      automatedWorkflows.workflow.description,
      automatedWorkflows.workflow.editor,
      automatedWorkflows.workflow.uuid,
      automatedWorkflows.workflowRuns.total,
      breadcrumbList,
      gridData,
      onExpandChange,
      onTableConfigChange,
      workflowDiagram
    ]
  )
}
