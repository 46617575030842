import { useMemo, useEffect, useCallback } from 'react'

import { process } from '@progress/kendo-data-query'

import { luxonDate, isBeforeDate, formatDateWithTime } from 'global/lib/datetime'
import { config } from 'global/lib/config'
import { BDSGridPagerConfig } from 'global/types/dataTables/dataTables'
import { ColumnsConfig } from 'global/types/dataTables/columnsConfigType'
import { isPending } from 'global/redux/toolkit/api'
import useDialogLogic, { IsDialogOpened, ToggleDialog } from 'global/lib/dialogs/useDialogLogic'
import * as analyticsLib from 'global/lib/analytics/analyticsService'

import { Alert, AlertSignin } from 'global/types/api/ato'
import { getCountryName } from 'global/lib/isoCountries'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'

import {
  update as updateAlertSigninsTable,
  reset as resetAlertSigninsTable
} from 'sen/redux/features/dataTables/alert/signinsSlice'
import { getAlertSignins } from 'sen/redux/features/ato/atoSlice'

export interface ModifiedAlertSignin extends AlertSignin {
  formattedDate: string
  countryName: string
}

export type InProgress = boolean
export interface TableConfig {
  tableData: {
    total: number
    data: any[]
  }
  isFlexibleTable: boolean
  pageConfig: BDSGridPagerConfig
  columns: { [key: string]: string }
  columnsConfig: { [key: string]: ColumnsConfig }
}
export enum AllSignInsDisableReasons {
  Release,
  ThirtyDays,
  None
}
export type SwitchAlertDrilldownDisabledReason = AllSignInsDisableReasons
export type OnViewRelatedSignins = () => void
export interface DialogConfig {
  open: IsDialogOpened
  toggle: ToggleDialog
}

export default function useAlertSigninsLogic(
  alert: Alert
): [InProgress, TableConfig, SwitchAlertDrilldownDisabledReason, OnViewRelatedSignins, DialogConfig] {
  const dispatch = useAppDispatch()
  const { accessTokenId, inProgress, alertSigninsTable, alertSignins, loadedOffsets } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id,
    inProgress: isPending(_stores.ato.getAlertSigninsApiStatus),
    alertSigninsTable: _stores.dataTables.alert.signins,
    alertSignins: _stores.ato.alertSignins,
    loadedOffsets: _stores.ato.loadedSigninsOffsets
  }))

  const [isUserRelatedSigninsDialogOpened, toggleUserRelatedSigninsDialog] = useDialogLogic()

  // Init
  useEffect(() => {
    return () => {
      dispatch(resetAlertSigninsTable())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tableData = useMemo(() => {
    const { skip, take } = alertSigninsTable

    const { data } = process(
      (alertSignins?.report?.data || []).map((report: AlertSignin) => ({
        ...(report && {
          ...report,
          formattedDate: formatDateWithTime(report.date || ''),
          countryName: getCountryName(report.country)
        })
      })),
      { skip, take }
    )

    return {
      data: data.filter(report => report.date),
      total: alertSignins?.report?.totalCount || 0
    }
  }, [alertSignins, alertSigninsTable])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take } = alertSigninsTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: tableData.total,
      onPageChange: (e: any) => {
        dispatch(updateAlertSigninsTable(e.page))

        if (!loadedOffsets.includes(e.page.skip)) {
          dispatch(getAlertSignins(alert.id))
        }
      }
    }
  }, [alertSigninsTable, tableData, loadedOffsets, dispatch, alert])

  const switchAlertDrilldownDisabledReason: AllSignInsDisableReasons = useMemo(() => {
    const featureReleaseDate = luxonDate(config.ALL_SIGNINS_COLLECTION_START_DATE).toISO()
    const alertDate = luxonDate(alert.createdOn).toISO()
    const thirtyDaysAgo = luxonDate()
      .minus({ days: 30 })
      .toISO()

    if (isBeforeDate({ initialDate: alertDate, subtractedDate: featureReleaseDate })) {
      return AllSignInsDisableReasons.Release
    }
    if (isBeforeDate({ initialDate: alertDate, subtractedDate: thirtyDaysAgo })) {
      return AllSignInsDisableReasons.ThirtyDays
    }

    return AllSignInsDisableReasons.None
  }, [alert])

  const onToggleUserRelatedSigninsDialog = useCallback(() => {
    const mixpanelEvent = isUserRelatedSigninsDialogOpened
      ? analyticsLib.EVENTS.ATO_ALERT_DIALOG_RELATED_SIGNINS_GO_BACK
      : analyticsLib.EVENTS.ATO_ALERT_DIALOG_VIEW_RELATED_SIGNINS
    analyticsLib.trackAppEvent(mixpanelEvent, {
      accessTokenId,
      alert: alert.id
    })
    toggleUserRelatedSigninsDialog()
  }, [accessTokenId, alert.id, isUserRelatedSigninsDialogOpened, toggleUserRelatedSigninsDialog])

  const onViewRelatedSignins = useCallback(() => {
    onToggleUserRelatedSigninsDialog()
  }, [onToggleUserRelatedSigninsDialog])

  return useMemo(() => {
    return [
      inProgress,
      {
        tableData,
        pageConfig,
        isFlexibleTable: tableData.total < alertSigninsTable.ITEMS_PER_PAGE,
        columns: alertSigninsTable.GRID_COLUMNS,
        columnsConfig: alertSigninsTable.columnsConfig
      },
      switchAlertDrilldownDisabledReason,
      onViewRelatedSignins,
      {
        open: isUserRelatedSigninsDialogOpened,
        toggle: onToggleUserRelatedSigninsDialog
      }
    ]
  }, [
    tableData,
    pageConfig,
    alertSigninsTable,
    switchAlertDrilldownDisabledReason,
    onViewRelatedSignins,
    inProgress,
    isUserRelatedSigninsDialogOpened,
    onToggleUserRelatedSigninsDialog
  ])
}
