import { makeStyles, createStyles } from '@material-ui/core/styles'

const styles = createStyles({
  text: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '16px'
  }
})

export const permissionDialogStyles = createStyles({
  root: {
    width: 550,
    padding: 30
  },
  arrowUp: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0 10px 10px 10px',
    borderColor: 'transparent transparent white transparent',
    position: 'absolute',
    top: -10,
    left: '45%',
    padding: 0,
    textShadow: '1 2px 2px rgba(255, 255, 255, 0.7), 0 -2px 4px rgba(0, 0, 0, 0.5)',

    '@media screen and (max-width:960px)': {
      left: '30%'
    }
  },
  close: {
    position: 'absolute',
    right: 15,
    fontSize: 20,
    top: 10,
    cursor: 'pointer',
    transform: 'scale(1.3, 1)'
  },
  title: {
    fontSize: 24,
    lineHeight: '28px',
    paddingRight: 30
  },
  subtitle: {
    ...styles.text,
    marginTop: 20,
    fontWeight: 600
  },
  mainText: {
    ...styles.text,
    marginTop: 20
  },
  listItemText: {
    ...styles.text,
    marginTop: 10,
    position: 'relative',
    paddingLeft: 20,

    '&::before': {
      fontSize: 20,
      // eslint-disable-next-line quotes
      content: "'•'",
      color: 'black',
      marginRight: 10,
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0
    }
  }
})

export default makeStyles(() => permissionDialogStyles)
