import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { Typography, Button, TextField, Box } from '@barracuda-internal/bds-core'

import styles from 'global/components/features/static/staticPageStyles'
import StaticPageLayout from 'global/components/features/static/layout/StaticPageLayout'
import IMAGES from 'ets/configs/theme/images.config'

const BASE_I18N_KEY = 'app.contest'

interface HowManyScansProps {
  onScanClick: () => void
  isSending?: boolean
  isSent?: boolean
  email: string
  onEmailChange: (email: string) => void
  error?: string
}

const HowManyScans: React.FC<HowManyScansProps> = props => {
  const { onScanClick, isSending, isSent, email, onEmailChange, error } = props
  const intl = useIntl()
  const classes = styles()

  return (
    <StaticPageLayout
      error={error ? `ets.app.error.${error}` : undefined}
      bgImage={IMAGES.loginBackground}
      isLoading={isSending}
      small
    >
      {isSent && (
        <Box display="flex" px={5}>
          <div>
            <Typography variant="h6" className={classes.pageTitle}>
              {intl.formatMessage({ id: `${BASE_I18N_KEY}.successTitle` })}
            </Typography>
            <Typography variant="body2">
              {intl.formatMessage({ id: `${BASE_I18N_KEY}.successText` }, { email })}
            </Typography>
          </div>
        </Box>
      )}
      {!isSent && (
        <Box px={5}>
          <Typography variant="h6" className={classes.pageTitle}>
            {intl.formatMessage({ id: `${BASE_I18N_KEY}.title` })}
          </Typography>
          <Typography variant="body2">{intl.formatMessage({ id: `${BASE_I18N_KEY}.text` })}</Typography>
          <Box display="flex" flexDirection="column" width="100%">
            <Box my={3}>
              <TextField
                value={email}
                onChange={ev => onEmailChange(ev.target.value)}
                placeholder="Email"
                fullWidth
                variant="standard"
                autoFocus
              />
            </Box>
            <div className={classes.btnContainer}>
              <Button onClick={onScanClick} color="primary" variant="contained" disabled={isSending}>
                {intl.formatMessage({ id: `${BASE_I18N_KEY}.buttonText` })}
              </Button>
            </div>
          </Box>
        </Box>
      )}
    </StaticPageLayout>
  )
}

HowManyScans.propTypes = {
  onScanClick: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  onEmailChange: PropTypes.func.isRequired,
  isSending: PropTypes.bool,
  isSent: PropTypes.bool,
  error: PropTypes.string
}

HowManyScans.defaultProps = {
  isSending: false,
  isSent: false,
  error: ''
}

export default HowManyScans
