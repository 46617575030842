import { combineReducers, AnyAction } from 'redux'
import { splitReducer } from '@splitsoftware/splitio-redux'

import { RootState } from 'sen/redux/toolkit/store'

// global slices
import accessToken, {
  INITIAL_STATE as accessTokenInitialState
} from 'global/redux/features/accessToken/accessTokenSlice'
import account, { INITIAL_STATE as accountInitialState } from 'global/redux/features/account/accountSlice'
import admin, { INITIAL_STATE as adminInitialState } from 'global/redux/features/admin/adminSlice'
import analytics, { INITIAL_STATE as analyticsInitialState } from 'global/redux/features/analytics/analyticsSlice'
import app, { INITIAL_STATE as appInitialState } from 'global/redux/features/app/appSlice'
import attack, { INITIAL_STATE as attackInitialState } from 'global/redux/features/attack/attackSlice'
import auth, { logout } from 'global/redux/features/auth/authSlice'
import cloud, { INITIAL_STATE as cloudInitialState } from 'global/redux/features/cloud/cloudSlice'
import convergedMessageLog, {
  INITIAL_STATE as convergedMessageLogState
} from 'global/redux/features/convergedMessageLog/convergedMessageLogSlice'
import globalRemediation, {
  INITIAL_STATE as globalRemediationState
} from 'global/redux/features/globalRemediation/globalRemediationSlice'
import report, { INITIAL_STATE as reportInitialState } from 'global/redux/features/report/reportSlice'
import scan, { INITIAL_STATE as scanInitialState } from 'global/redux/features/scan/scanSlice'
import scheduledReports, {
  INITIAL_STATE as scheduledReportsInitialState
} from 'global/redux/features/scheduledReports/scheduledReportsSlice'
import unifiedReporting, {
  INITIAL_STATE as unifiedReportingState
} from 'global/redux/features/unifiedReporting/unifiedReportingSlice'

// sen slices
import 'sen/redux/features/auth/authSlice'
import ato, { INITIAL_STATE as atoInitialState } from 'sen/redux/features/ato/atoSlice'
import cleanup, { INITIAL_STATE as cleanupInitialState } from 'sen/redux/features/cleanup/cleanupSlice'
import cm, { INITIAL_STATE as cmInitialState } from 'sen/redux/features/cm/cmSlice'
import dataTables, { INITIAL_STATE as dataTablesInitialState } from 'sen/redux/features/dataTables/dataTablesSlice'
import dmarc, { INITIAL_STATE as dmarcInitialState } from 'sen/redux/features/dmarc/dmarcSlice'
import identity, { INITIAL_STATE as identityInitialState } from 'sen/redux/features/identity/identitySlice'
import remediation, { INITIAL_STATE as remediationInitialState } from 'sen/redux/features/remediation/remediationSlice'
import settings, { INITIAL_STATE as settingsInitialState } from 'sen/redux/features/settings/settingsSlice'
import feedback, { INITIAL_STATE as feedbackInitialState } from 'sen/redux/features/feedback/feedbackSlice'
import sp, { INITIAL_STATE as spInitialState } from 'sen/redux/features/sp/spSlice'
import syslog, { INITIAL_STATE as syslogInitialState } from 'sen/redux/features/syslog/syslogSlice'
import user, { INITIAL_STATE as userInitialState } from 'sen/redux/features/user/userSlice'

export const reducers = {
  ato,
  cleanup,
  cm,
  dataTables,
  dmarc,
  identity,
  remediation,
  settings,
  feedback,
  sp,
  syslog,
  user,
  splitio: splitReducer
}

const combinedReducers = combineReducers({
  accessToken,
  account,
  admin,
  analytics,
  app,
  ato,
  attack,
  auth,
  cleanup,
  cloud,
  cm,
  convergedMessageLog,
  dataTables,
  dmarc,
  globalRemediation,
  feedback,
  identity,
  remediation,
  report,
  scan,
  scheduledReports,
  settings,
  sp,
  syslog,
  unifiedReporting,
  user,
  splitio: splitReducer
})

export default function(state: RootState, action: AnyAction) {
  let appState = { ...state }

  if (logout.pending.match(action) && appState.accessToken?.accessToken?.id) {
    appState.auth = {
      ...appState.auth,
      logoutValues: {
        accessTokenId: appState.accessToken?.accessToken?.id
      }
    }
  }

  if (logout.fulfilled.match(action)) {
    // reset stores on logout
    appState = {
      ...appState,
      splitio: {
        ...appState.splitio,
        treatments: {}
      },
      auth: {
        ...appState.auth
      },
      accessToken: accessTokenInitialState,
      account: accountInitialState,
      admin: adminInitialState,
      analytics: analyticsInitialState,
      app: appInitialState,
      ato: atoInitialState,
      attack: attackInitialState,
      cleanup: cleanupInitialState,
      cloud: cloudInitialState,
      cm: cmInitialState,
      convergedMessageLog: convergedMessageLogState,
      dataTables: dataTablesInitialState,
      dmarc: dmarcInitialState,
      feedback: feedbackInitialState,
      globalRemediation: globalRemediationState,
      identity: identityInitialState,
      remediation: remediationInitialState,
      report: reportInitialState,
      scan: scanInitialState,
      scheduledReports: scheduledReportsInitialState,
      syslog: syslogInitialState,
      sp: spInitialState,
      unifiedReporting: unifiedReportingState,
      user: userInitialState,
      settings: settingsInitialState
    }
  }

  return combinedReducers(appState, action)
}
