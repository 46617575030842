import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import reducerHandlers from 'global/redux/features/dataTables/reducerHandlers'
import { GridColumns, SortFields, UpdateDataTable } from 'global/types/dataTables/dataTables'

import { DataTableState } from 'sen/redux/types/dataTables'

export const GRID_COLUMNS: GridColumns = {
  DISPLAY_NAME: 'display_name',
  EMAIL: 'email'
}

export const COLUMNS_CONFIG = {
  [GRID_COLUMNS.DISPLAY_NAME]: {
    sortable: false,
    orderIndex: 0,
    show: true
  },
  [GRID_COLUMNS.EMAIL]: {
    sortable: false,
    orderIndex: 1,
    show: true
  }
}

export const SORT_FIELDS: SortFields = {}

const ITEMS_PER_PAGE = 5

// initialState
export const INITIAL_STATE: DataTableState = {
  SORT_FIELDS,
  GRID_COLUMNS,
  ITEMS_PER_PAGE,
  columnsConfig: COLUMNS_CONFIG,
  skip: 0, // offset
  take: ITEMS_PER_PAGE, // limit
  filter: ['isInternal=true'],
  search: '',
  sort: []
}

export const incidentRecipientsWizardSlice = createSlice({
  name: 'DATA_TABLE/INCIDENT/RECIPIENTS/WIZARD',
  initialState: INITIAL_STATE,
  reducers: {
    update: {
      reducer: (state: DataTableState, action: PayloadAction<{ config: UpdateDataTable }>) => {
        return reducerHandlers({ COLUMNS_CONFIG }).update({ state, payload: action.payload })
      },
      prepare: (config: UpdateDataTable) => ({
        payload: { config }
      })
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  }
})

export const { update, reset } = incidentRecipientsWizardSlice.actions

export default incidentRecipientsWizardSlice.reducer
