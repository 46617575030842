import React from 'react'
import PropTypes from 'prop-types'

import { Card, TextField, Link, Typography, Grid } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'global/lib/localization'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import CopyToClipboard from 'global/components/lib/copyToClipboard/CopyToClipboard'

import ContestSource from 'ets/components/lib/contest/ContestSource.enum'

import IMAGES from 'ets/configs/theme/images.config'
import SigninSignupLayout from 'ets/components/pages/signinSignup/layout/SigninSignupLayout'
import useContestLogic from 'ets/components/lib/contest/useContestLogic'
import styles from 'ets/components/lib/contest/contestStyles'

const BASE_I18N_KEY = 'ets.app.my_link'

export interface ContestPageProps {
  source: string
}

const ContestPage: React.FC<ContestPageProps> = ({ source }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const [contestLogic] = useContestLogic(source)

  const { onEmailChange, email, link } = contestLogic

  return (
    <SigninSignupLayout>
      <Grid className={classes.root} container alignItems="center" direction="column">
        <Grid item xl={4} lg={5} md={6} sm={9} xs={12}>
          <Card className={classes.card} elevation={1}>
            <Grid container direction="column">
              <Grid item className={classes.logoWrapper}>
                <img className={classes.barracudaLogo} alt="" src={IMAGES.barracudaEtsForDark} />
              </Grid>

              {source === ContestSource.AWS_VAR && (
                <Grid item className={classes.logoWrapper}>
                  <img className={classes.barracudaLogo} alt="" src={IMAGES.awsMarketplace} />
                </Grid>
              )}
            </Grid>
            <Typography className={classes.headingTitle} align="left" data-field="heading-title">
              {formatMessage('heading_title')}
            </Typography>
            <Typography className={classes.headingSubtitle} align="left" data-field="heading-subtitle">
              {formatMessage('heading_subtitle')}
            </Typography>
          </Card>
          <Card className={classes.card2} elevation={1}>
            <Typography className={classes.title} align="center" data-field="title">
              {formatMessage('title')}
            </Typography>
            <Typography className={classes.cardNumbering} align="left" data-field="email-title">
              {formatMessage('email_heading')}
            </Typography>
            <TextField
              className={classes.emailInput}
              value={email}
              onChange={onEmailChange}
              placeholder={formatMessage('email')}
              type="email"
              fullWidth
              required
              autoFocus
              variant="standard"
            />
            <Typography className={classes.cardNumbering} align="left" data-field="link">
              {formatMessage('link_heading')}
            </Typography>
            <Grid className={classes.linkRow} container alignItems="center" justifyContent="center" direction="row">
              <Grid item xs={10}>
                <TextField
                  className={classes.linkInput}
                  value={link}
                  inputProps={{
                    readOnly: true,
                    style: {
                      fontSize: '12px'
                    }
                  }}
                  type="text"
                  fullWidth
                  variant="standard"
                  data-field="link-field"
                />
              </Grid>
              <Grid item xs={2} className={classes.copyToClipboard}>
                <CopyToClipboard
                  textToCopy={link}
                  positionCopiedMessageToRight
                  customButtonText=" "
                  data-field="clipboard"
                  analyticsEventName={analyticsLib.EVENTS.MY_LINK_COPY}
                />
              </Grid>
            </Grid>
            <Typography className={classes.cardNumbering} align="left" data-field="description">
              {formatMessage('share_description')}
            </Typography>
            <Typography className={classes.footer} align="left" data-field="footer">
              {formatMessage('footer')}
              <Link className={classes.link} href="howmanyscans">
                {formatMessage('footer_link')}
              </Link>
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </SigninSignupLayout>
  )
}

ContestPage.propTypes = {
  source: PropTypes.string.isRequired
}

export default ContestPage
