import { useMemo, useEffect, useCallback, useState } from 'react'

import { process } from '@progress/kendo-data-query'

import { BDSGridPagerConfig } from 'global/types/dataTables/dataTables'
import { ColumnsConfig } from 'global/types/dataTables/columnsConfigType'
import { getErrorMessage, isPending, isSuccess } from 'global/redux/toolkit/api'
import { formatDateWithTime } from 'global/lib/datetime'
import { Alert, AlertThreat } from 'global/types/api/ato'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import useEmailDetailsAlertThreatInterface, {
  UseEmailDetailsAlertThreatInterface
} from 'sen/components/lib/dialogs/emailDetailsDialog/interfaces/useEmailDetailsAlertThreatInterface'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'

import {
  update as updateAlertThreatsTable,
  reset as resetAlertThreatsTable
} from 'sen/redux/features/dataTables/alert/threatsSlice'
import { getAlertThreats, getAlertDetails, resetAlertDetails } from 'sen/redux/features/ato/atoSlice'

export interface ModifiedAlertThreat extends AlertThreat {
  formattedDate: string
}

export type InProgress = boolean

export type AlertDetailError = string | undefined

export interface TableConfig {
  tableData: {
    total: number
    data: any[]
  }
  isFlexibleTable: boolean
  pageConfig: BDSGridPagerConfig
  columns: { [key: string]: string }
  columnsConfig: { [key: string]: ColumnsConfig }
  onOpenMessage: (alert: ModifiedAlertThreat) => void
}

export default function useAlertThreatsLogic(
  alert: Alert
): [InProgress, TableConfig, UseEmailDetailsAlertThreatInterface, string | undefined] {
  const dispatch = useAppDispatch()
  const {
    accessTokenId,
    inProgress,
    isGetAlertDetails,
    isAlertDetailsLoaded,
    alertDetails,
    alertThreatsTable,
    alertThreats,
    loadedOffsets,
    alertDetailError
  } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id,
    inProgress: isPending(_stores.ato.getAlertThreatsApiStatus),
    isGetAlertDetails: isPending(_stores.ato.getAlertDetailsApiStatus),
    isAlertDetailsLoaded: isSuccess(_stores.ato.getAlertDetailsApiStatus),
    alertDetails: _stores.ato.alertDetails,
    alertThreatsTable: _stores.dataTables.alert.threats,
    alertThreats: _stores.ato.alertThreats,
    loadedOffsets: _stores.ato.loadedALertThreatsOffsets,
    alertDetailError: getErrorMessage(_stores.ato.getAlertDetailsApiStatus)
  }))
  const [selectedThreat, setSelectedThreat] = useState<ModifiedAlertThreat | undefined>()

  // Init
  useEffect(() => {
    return () => {
      dispatch(resetAlertThreatsTable())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onCloseEmailDetailsDialog = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.ATO_ALERT_THREAT_GO_BACK_FROM_EMAIL_PREVIEW, {
      accessTokenId,
      alert: alert.id
    })
    dispatch(resetAlertDetails())
  }, [accessTokenId, alert.id, dispatch])

  const [emailDetailDialogConfig, emailDetailDialogActions] = useEmailDetailsAlertThreatInterface({
    onClose: onCloseEmailDetailsDialog,
    alert
  })

  // Opent the AttackDetailDialog
  useEffect(() => {
    if (!emailDetailDialogActions.open && isAlertDetailsLoaded && selectedThreat && alertDetails) {
      emailDetailDialogActions.onOpen(selectedThreat)
    }
  }, [isAlertDetailsLoaded, selectedThreat, alertDetails, alert, dispatch, emailDetailDialogActions])

  const tableData = useMemo(() => {
    const { skip, take } = alertThreatsTable

    const { data } = process(
      (alertThreats?.report?.data || []).map((report: AlertThreat) => ({
        ...(report && {
          ...report,
          formattedDate: formatDateWithTime(report.dateSent || '')
        })
      })),
      { skip, take }
    )

    return {
      data: data.filter(report => report.threatId),
      total: alertThreats?.report?.totalCount || 0
    }
  }, [alertThreats, alertThreatsTable])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take } = alertThreatsTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: tableData.total,
      onPageChange: (e: any) => {
        dispatch(updateAlertThreatsTable(e.page))

        if (!loadedOffsets.includes(e.page.skip)) {
          dispatch(getAlertThreats(alert.id))
        }
      }
    }
  }, [alertThreatsTable, tableData, loadedOffsets, dispatch, alert])

  const onOpenMessage = useCallback(
    (threat: ModifiedAlertThreat) => {
      setSelectedThreat(threat)
      dispatch(getAlertDetails(threat.threatId))
    },
    [dispatch]
  )

  return useMemo(() => {
    return [
      inProgress || isGetAlertDetails,
      {
        tableData,
        pageConfig,
        isFlexibleTable: tableData.total < alertThreatsTable.ITEMS_PER_PAGE,
        columns: alertThreatsTable.GRID_COLUMNS,
        columnsConfig: alertThreatsTable.columnsConfig,
        onOpenMessage
      },
      [emailDetailDialogConfig, emailDetailDialogActions],
      alertDetailError
    ]
  }, [
    inProgress,
    isGetAlertDetails,
    tableData,
    pageConfig,
    alertThreatsTable,
    onOpenMessage,
    emailDetailDialogConfig,
    emailDetailDialogActions,
    alertDetailError
  ])
}
