import { makeStyles, createStyles } from '@material-ui/core/styles'

import { COLORS } from 'global/configs/theme/defaults'
import commonStyles from 'global/components/features/signinSignup/styles/commonStyles'
import IMAGES from 'ets/configs/theme/images.config'

const styles = createStyles({
  absolutized: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0
  },
  backgroundImage: {
    backgroundAttachment: 'fixed',
    backgroundImage: `url(${IMAGES.loginBackground})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  backgroundScales: {
    backgroundAttachment: 'fixed',
    backgroundColor: '#425160',
    backgroundImage: `url(${IMAGES.bgScales})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  blueText: {
    color: COLORS.BLUE,
    cursor: 'pointer',
    display: 'block',
    fontSize: 14,
    textDecoration: 'none'
  }
})

export default makeStyles(theme => {
  const formSigninButton = {
    width: '100%',
    height: 32,
    borderRadius: 2,
    marginTop: theme.spacing(2)
  }

  return {
    root: {
      [`${theme.breakpoints.up('xl')} and (orientation: landscape)`]: {
        ...styles.absolutized,
        ...styles.backgroundImage
      }
    },
    loginWrapper: {
      ...styles.absolutized,
      [`${theme.breakpoints.up('xl')} and (orientation: landscape)`]: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        justifyItems: 'center'
      }
    },
    linearWrapper: {
      position: 'relative',
      top: 0
    },
    contentWrapper: {
      flexDirection: 'column',
      width: 'auto',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
        height: '100%'
      },
      [theme.breakpoints.up('lg')]: {
        minHeight: 781
      },
      [`${theme.breakpoints.up('xl')} and (orientation: landscape)`]: {
        ...styles.absolutized,
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.26), 0px 2px 10px rgba(0, 0, 0, 0.16)',
        height: 732,
        margin: 'auto',
        minHeight: 'auto',
        width: 1400
      },
      '& .info-area': {
        ...styles.backgroundImage,
        [`${theme.breakpoints.up('xl')} and (orientation: landscape)`]: {
          ...styles.backgroundScales
        }
      },
      '& .form-area': {
        backgroundColor: COLORS.BACKGROUND
      }
    },
    infoAreaBG: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      height: '100%',
      [theme.breakpoints.up('xl')]: {
        ...styles.backgroundScales
      }
    },
    accordionWrapper: {
      height: '100%'
    },
    formAreaWrapper: {
      display: 'block',
      padding: theme.spacing(2),
      width: 'auto',
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4)
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(7, 5)
      },
      [`${theme.breakpoints.up('md')} and (orientation: landscape)`]: {
        padding: theme.spacing(4),
        width: 400
      },
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(7, 5),
        width: 616
      },
      [`${theme.breakpoints.up('lg')} and (orientation: landscape)`]: {
        padding: theme.spacing(5, 5, 0),
        width: 577
      }
    },
    barracudaLogo: {
      height: 40,
      marginBottom: theme.spacing(2),
      width: 'auto',
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(3)
      }
    },
    logoWrapper: {
      textAlign: 'center'
    },
    headingTitle: {
      fontSize: 34,
      fontWeight: 700,
      lineHeight: '42px',
      letterSpacing: 'normal',
      paddingBottom: theme.spacing(1),
      whiteSpace: 'pre-line',
      [theme.breakpoints.up('sm')]: {
        fontSize: 56,
        lineHeight: '64px'
      },
      [`${theme.breakpoints.up('md')} and (orientation: landscape)`]: {
        fontSize: 40,
        fontWeight: 500,
        lineHeight: '48px'
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 56,
        fontWeight: 500,
        lineHeight: '64px'
      }
    },
    headingSubTitle: {
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: 'normal',
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        fontSize: 28,
        paddingBottom: theme.spacing(4)
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 32,
        fontWeight: 400,
        lineHeight: '40px'
      },
      [`${theme.breakpoints.up('md')} and (orientation: landscape)`]: {
        fontSize: 20,
        paddingBottom: theme.spacing(3)
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 32,
        fontWeight: 400,
        lineHeight: '40px',
        paddingBottom: theme.spacing(4)
      }
    },
    headingText: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '18px',
      [theme.breakpoints.up('sm')]: {
        fontSize: 16,
        lineHeight: '24px'
      }
    },
    formTitle: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '24px'
    },
    formSubtitle: {
      fontSize: 11,
      fontStyle: 'italic',
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        fontSize: 14
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 16
      }
    },
    formSignupWrapper: {
      display: 'block',
      padding: theme.spacing(4, 0, 2),
      [`${theme.breakpoints.up('md')} and (orientation: landscape)`]: {
        padding: theme.spacing(5, 0, 2)
      },
      [theme.breakpoints.up('xl')]: {
        padding: theme.spacing(9, 0, 2)
      }
    },
    formSignupButton: {
      ...commonStyles.o365Button,
      fontSize: 12,
      fontWeight: 700,
      height: 40,
      [theme.breakpoints.up('sm')]: {
        fontSize: 16,
        height: 48
      },
      [`${theme.breakpoints.up('md')} and (orientation: landscape)`]: {
        fontSize: 14
      }
    },
    formO365Icon: {
      ...commonStyles.o365Icon,
      height: 24,
      width: 'auto',
      [theme.breakpoints.up('lg')]: {
        height: 28
      }
    },
    formSigninWrapper: {
      display: 'block',
      padding: theme.spacing(4, 0, 2),
      [`${theme.breakpoints.up('md')} and (orientation: landscape)`]: {
        padding: theme.spacing(5, 0, 2)
      },
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(6, 0, 2)
      },
      [`${theme.breakpoints.up('lg')} and (orientation: landscape)`]: {
        padding: theme.spacing(3, 0, 2)
      }
    },
    formSigninTitle: {
      ...commonStyles.inputTitle
    },
    formForgotPasswordLink: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '16px'
    },
    formSigninButton: {
      marginTop: theme.spacing(4)
    },
    sentinelSigninButton: {
      ...formSigninButton
    },
    signinAlert: {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('xl')]: {
        left: 0,
        position: 'absolute',
        right: 0,
        zIndex: 1
      }
    },
    centerText: {
      fontSize: 14,
      margin: '0 auto',
      display: 'flex'
    },
    privacyText: {
      fontSize: 14
    },
    accountSwitcher: {
      marginTop: theme.spacing(2)
    },
    underline: {
      textDecoration: 'underline'
    },
    whiteText: {
      fontWeight: 'bold',
      textTransform: 'none',
      color: theme.palette.info.contrastText,
      fontSize: 12,
      lineHeight: '5px'
    },
    whiteIcon: {
      color: theme.palette.info.contrastText
    },
    infoAccordionItem: {
      color: theme.palette.common.white,
      background: 'transparent',
      boxShadow: 'none',
      '&:before': {
        backgroundColor: '#ffffff80'
      },
      '&:last-of-type': {
        borderBottom: '1px solid #ffffff80',
        [theme.breakpoints.up('xl')]: {
          borderBottom: 'none'
        }
      },
      '&.Mui-expanded': {
        borderBottom: '1px solid #ffffff80',
        margin: 0,
        '&:before': {
          opacity: 1
        }
      }
    },
    infoAccordionItemSummary: {
      padding: theme.spacing(0, 2),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(0, 3)
      },
      '&.Mui-expanded': {
        fontWeight: 600
      }
    },
    infoAccordionItemSummaryTitle: {
      color: theme.palette.common.white,
      fontSize: 12,
      fontWeight: 'inherit',
      lineHeight: 'normal',
      [theme.breakpoints.up('sm')]: {
        fontSize: 14
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 16
      }
    },
    infoAccordionItemSummaryTitleLarge: {
      fontSize: 14,
      fontWeight: 500,
      [theme.breakpoints.up('sm')]: {
        fontSize: 20
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 24
      }
    },
    infoAccordionItemDetails: {
      padding: theme.spacing(0, 2, 2),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(0, 3, 3)
      }
    },
    infoContent: {
      flexWrap: 'wrap',
      [theme.breakpoints.up('sm')]: {
        flexWrap: 'nowrap'
      }
    },
    infoContentText: {
      color: theme.palette.common.white,
      fontSize: 12,
      lineHeight: '16px',
      [theme.breakpoints.up('sm')]: {
        fontSize: 14,
        lineHeight: '18px'
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 16,
        lineHeight: '24px'
      }
    },
    infoContentList: {
      margin: theme.spacing(1, 2),
      padding: theme.spacing(1)
    },
    infoContentListItem: {
      lineHeight: '16px',
      listStyleType: 'disc',
      [theme.breakpoints.up('sm')]: {
        fontSize: 14,
        lineHeight: '24px'
      },
      [theme.breakpoints.up('md')]: {
        lineHeight: '32px'
      }
    },
    speakToExpertText: {
      color: theme.palette.common.white,
      border: '2px solid #ffffff',
      borderRadius: 2,
      boxSizing: 'content-box',
      fontSize: 12,
      height: 44,
      lineHeight: '44px',
      whiteSpace: 'nowrap',
      [theme.breakpoints.up('md')]: {
        fontSize: 14
      },
      cursor: 'pointer'
    },
    msPermissionsImg: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 200
      },
      [`${theme.breakpoints.up('lg')} and (orientation: landscape)`]: {
        width: 196
      }
    },
    accountBlueText: {
      ...styles.blueText,
      height: 32,
      lineHeight: '32px',
      [theme.breakpoints.up('sm')]: {
        height: 40,
        lineHeight: '40px'
      }
    },
    strongText: {
      fontWeight: 600
    },
    [`${theme.breakpoints.up('lg')} and (orientation: landscape)`]: {
      reactPlayer: {
        maxWidth: 767
      }
    }
  }
})
