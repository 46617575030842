import { SortDescriptor } from '@progress/kendo-data-query'
import { createSlice } from '@reduxjs/toolkit'

import { GridColumns } from 'global/types/dataTables/dataTables'
import * as datetime from 'global/lib/datetime'

interface EmailsByRegionState {
  GRID_COLUMNS: GridColumns
  timeframe: number
  dateRange: datetime.DateRange
  sort: SortDescriptor[]
}

export const GRID_COLUMNS = {
  COUNTRY: 'country',
  COUNT: 'count'
}
export const DEFAULT_RANGE_IN_HOURS = 24

export function updateDatesAndCalculateTimeframe(dateRange: datetime.DateRange, isCustomRange = false) {
  let timeframe = datetime.getHours(dateRange)
  const updatedRange = { ...dateRange }

  if (timeframe > 24 && isCustomRange) {
    // adjust start time to be the start of the day for custom ranges over 24 hours
    updatedRange.start = datetime
      .luxonDate(dateRange.end)
      .minus({ days: timeframe / 24 - 1 })
      .startOf('day')
      .toJSDate()

    // update timeframe to match new range if not 30 days
    if (timeframe < 720) {
      timeframe = datetime.getHours(updatedRange)
    }
  }

  return {
    timeframe,
    dateRange: updatedRange
  }
}

// Store
export const INITIAL_STATE: EmailsByRegionState = {
  GRID_COLUMNS,
  ...updateDatesAndCalculateTimeframe({
    start: datetime.removeHours(new Date(), DEFAULT_RANGE_IN_HOURS),
    end: new Date()
  }),
  timeframe: DEFAULT_RANGE_IN_HOURS,
  sort: [{ field: GRID_COLUMNS.COUNT, dir: 'asc' }]
}

export const emailsByRegionSlice = createSlice({
  name: 'DATATABLE/EMAILS_BY_REGION',
  initialState: INITIAL_STATE,
  reducers: {
    update: (state, action) => {
      return {
        ...state,
        ...action.payload?.config,
        ...(action.payload?.config?.dateRange && {
          ...updateDatesAndCalculateTimeframe(action.payload?.config.dateRange, action.payload?.isCustomRange)
        })
      }
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  }
})

export const { update, reset } = emailsByRegionSlice.actions

export default emailsByRegionSlice.reducer
