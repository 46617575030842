import React from 'react'
import PropTypes from 'prop-types'

import { Chip } from '@barracuda-internal/bds-core'

import { COLORS } from 'global/configs/theme/defaults'

export enum LabelColors {
  'light',
  'dark'
}

export interface TextLabelProps {
  children: string
  color?: LabelColors
  bgColor: string
}

const LABEL_COLORS = {
  [LabelColors.light]: COLORS.WHITE,
  [LabelColors.dark]: COLORS.GRAY_DARKEST
}

const TextLabel: React.FC<TextLabelProps> = ({ color = LabelColors.light, bgColor, children, ...rest }) => {
  return (
    <Chip size="small" {...rest} style={{ backgroundColor: bgColor, color: LABEL_COLORS[color] }} label={children} />
  )
}

TextLabel.propTypes = {
  children: PropTypes.string.isRequired,
  color: PropTypes.any,
  bgColor: PropTypes.string.isRequired
}

TextLabel.defaultProps = {
  color: LabelColors.light
}

export default TextLabel
