import { createSlice, isAnyOf } from '@reduxjs/toolkit'

import { reset as resetDataTables } from 'global/redux/features/dataTables/dataTablesSlice'

import { DataTablesState } from 'admin/redux/types/dataTables'
import * as etsScans from 'admin/redux/features/dataTables/etsScans/etsScansSlice'
import * as senScans from 'admin/redux/features/dataTables/senScans/senScansSlice'
import * as dfpAccessTokens from 'admin/redux/features/dataTables/dfpAccessTokens/dfpAccessTokensSlice'
import * as firAccessTokens from 'admin/redux/features/dataTables/firAccessTokens/firAccessTokensSlice'
import * as remediationStats from 'admin/redux/features/dataTables/remediationStats/remediationStatsSlice'

// initialState
export const INITIAL_STATE: DataTablesState = {
  etsScans: etsScans.INITIAL_STATE,
  senScans: senScans.INITIAL_STATE,
  dfpAccessTokens: dfpAccessTokens.INITIAL_STATE,
  firAccessTokens: firAccessTokens.INITIAL_STATE,
  remediationStats: remediationStats.INITIAL_STATE
}

/* eslint-disable no-param-reassign */
export const dataTablesSlice = createSlice({
  name: 'DATA_TABLES',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(resetDataTables.toString(), () => {
      return {
        ...INITIAL_STATE
      }
    })
    builder.addMatcher(isAnyOf(etsScans.update, etsScans.reset), (state, action) => {
      state.etsScans = { ...etsScans.default(state.etsScans, action) }
    })
    builder.addMatcher(isAnyOf(senScans.update, senScans.reset), (state, action) => {
      state.senScans = { ...senScans.default(state.senScans, action) }
    })
    builder.addMatcher(isAnyOf(dfpAccessTokens.update, dfpAccessTokens.reset), (state, action) => {
      state.dfpAccessTokens = { ...dfpAccessTokens.default(state.dfpAccessTokens, action) }
    })
    builder.addMatcher(isAnyOf(firAccessTokens.update, firAccessTokens.reset), (state, action) => {
      state.firAccessTokens = { ...firAccessTokens.default(state.firAccessTokens, action) }
    })
    builder.addMatcher(isAnyOf(remediationStats.update, remediationStats.reset), (state, action) => {
      state.remediationStats = { ...remediationStats.default(state.remediationStats, action) }
    })
  }
})
/* eslint-enable no-param-reassign */

export default dataTablesSlice.reducer
