import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Typography, Dialog, DialogTitle, Zoom } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'global/lib/localization'

import BlockAccount from 'sen/components/lib/dialogs/newIncidentDialog/steps/BlockAccount'
import styles from 'sen/components/lib/dialogs/newIncidentDialog/newIncidentDialogStyles'

export interface NewIncidentDialogProps {
  open?: boolean
  onClose: () => void
  cleanupMessages?: () => void
}

const BASE_I18N_KEY = 'sen.app.new_incident_dialog'

const NewIncidentDialog: React.FC<NewIncidentDialogProps> = ({
  open = true,
  onClose,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  cleanupMessages = () => {}
}) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  return useMemo(
    () => (
      <Dialog fullWidth maxWidth="md" open={open} TransitionComponent={Zoom}>
        <DialogTitle>
          <Typography className={classes.dialogTitle}>{formatMessage('step_block_account.title')}</Typography>
        </DialogTitle>
        <BlockAccount cleanupMessages={cleanupMessages} onClose={onClose} />
      </Dialog>
    ),
    [formatMessage, classes, onClose, open, cleanupMessages]
  )
}

NewIncidentDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  cleanupMessages: PropTypes.func
}

NewIncidentDialog.defaultProps = {
  open: true,
  cleanupMessages: undefined
}

export default NewIncidentDialog
