import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import {
  Typography,
  Button,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Zoom
} from '@barracuda-internal/bds-core'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import DEFAULTS from 'global/configs/theme/defaults'
import styles from 'sen/components/lib/dialogs/cleanupDialog/cleanupDialogStyles'
import { useErrorFormatMessage, useFormatMessage } from 'global/lib/localization'
import useCleanupDialogLogic from 'sen/components/lib/dialogs/cleanupDialog/useCleanupDialogLogic'

export interface CleanupDialogProps {
  open: boolean
  onClose: () => void
}

const BASE_I18N_KEY = 'sen.app.cleanup.cleanup_dialog'

export const CleanupDialog: React.FC<CleanupDialogProps> = ({ open, onClose }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatMessageError = useErrorFormatMessage()
  const classes = styles()

  const [inProgress, onDelete, onCancel, error] = useCleanupDialogLogic(open, onClose)

  return useMemo(
    () => (
      <Dialog
        style={{ zIndex: DEFAULTS.DIALOGS_ZINDEX }}
        maxWidth="sm"
        fullWidth
        open={open}
        TransitionComponent={Zoom}
      >
        {inProgress && <LinearProgress />}
        <DialogTitle>
          {error && (
            <Alert className={classes.error} variant="filled" severity="error">
              {formatMessageError(`${error}`)}
            </Alert>
          )}
          <Typography className={classes.dialogTitle}>{formatMessage('title')}</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography className={classes.dialogSubtitle}>{formatMessage('subtitle')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="text" size="large" onClick={onCancel} disabled={inProgress}>
            &nbsp;&nbsp;{formatMessage('cancel')}&nbsp;&nbsp;
          </Button>
          <Button color="primary" variant="contained" size="large" onClick={onDelete} disabled={inProgress}>
            &nbsp;&nbsp;{formatMessage('delete_emails')}&nbsp;&nbsp;
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [formatMessage, formatMessageError, classes, open, onCancel, inProgress, error, onDelete]
  )
}

CleanupDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default CleanupDialog
