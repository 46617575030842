import { DateTime } from 'luxon'

import { createAsyncThunk } from '@reduxjs/toolkit'

import apiRoutes from 'fir/lib/api/apiRoutes'
import {
  CreateIncidentTagsApiConfig,
  DeleteExistingEmailsApiConfig,
  DeleteIncidentTagApiConfig,
  EmailsByIncidentApiConfig,
  EmailsByIncidentApiResponse,
  EssAccount,
  ForensicsGetEssAccount,
  GetIncidentApiConfig,
  GetIncidentApiResponse,
  GetIncidentClickedLinksApiResponse,
  GetIncidentUserDetailsApiResponse,
  NewIncident
} from 'fir/redux/types/Remediation'
import { RootState } from 'fir/redux/toolkit/store'

import restClient, { ApiRejectResponse, ResponseGenerator, validateApiError } from 'global/lib/api/restClient'
import { Incident, IncidentTag } from 'global/types/api/remediation'
import { config as globalConfig } from 'global/lib/config'

async function doForensicsGetIncidentEmails(payload: EmailsByIncidentApiConfig, { rejectWithValue }: any) {
  const { distinctRecipient = false, config, ...rest } = payload

  try {
    const resp: ResponseGenerator = await restClient(apiRoutes.FORENSICS_GET_INCIDENT_EMAILS, {
      data: { distinctRecipient, query: { ...config.query }, ...rest }
    })
    const result = {
      emailsByIncident: {
        data: resp.data.recipients.data,
        totalCount: resp.data.recipients.totalCount
      },
      skip: (config.query.page ? config.query.page - 1 : 0) * config.query.limit
    }
    return result
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
}

async function doForensicsGetIncidentClickedLinks(payload: EmailsByIncidentApiConfig, { rejectWithValue }: any) {
  const { config, ...rest } = payload

  try {
    const resp: ResponseGenerator = await restClient(apiRoutes.FORENSICS_GET_INCIDENT_LINK_PROTECT_DETAILS, {
      data: { query: { ...config.query }, ...rest }
    })

    const result = {
      clickedLinksByIncident: {
        data: resp.data.linkProtect.data,
        totalCount: resp.data.linkProtect.totalCount
      },
      skip: (config.query.page ? config.query.page - 1 : 0) * config.query.limit
    }

    return result
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
}

async function doForensicsGetIncidentUserDetails(payload: EmailsByIncidentApiConfig, { rejectWithValue }: any) {
  const { config, ...rest } = payload

  try {
    const resp: ResponseGenerator = await restClient(apiRoutes.FORENSICS_GET_INCIDENT_USER_DETAILS, {
      data: { query: { ...config.query }, ...rest }
    })

    const result = {
      userDetailsByIncident: {
        data: resp.data.recipientDetails.data,
        totalCount: resp.data.recipientDetails.totalCount
      },
      skip: (config.query.page ? config.query.page - 1 : 0) * config.query.limit
    }
    return result
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
}

async function doForensicsGetIncidents(payload: GetIncidentApiConfig, { rejectWithValue }: any) {
  const { accessTokenId, config } = payload
  try {
    const resp = await restClient(apiRoutes.FORENSICS_GET_INCIDENTS, {
      data: { accessTokenId, query: { ...config.query } }
    })

    const result = {
      incidents: {
        data: resp.data.incidents.data,
        totalCount: resp.data.incidents.totalCount
      },
      skip: (config.query.page ? config.query.page - 1 : 0) * config.query.limit
    }
    return result
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
}

export const forensicsGetIncidentEmails = createAsyncThunk<
  EmailsByIncidentApiResponse,
  EmailsByIncidentApiConfig,
  ApiRejectResponse
>('REMEDIATION/forensicsGetIncidentEmails', doForensicsGetIncidentEmails)

export const forensicsGetMoreIncidentEmails = createAsyncThunk<
  EmailsByIncidentApiResponse,
  EmailsByIncidentApiConfig,
  ApiRejectResponse
>('REMEDIATION/forensicsGetMoreIncidentEmails', doForensicsGetIncidentEmails)

export const forensicsGetIncidentClickedLinks = createAsyncThunk<
  GetIncidentClickedLinksApiResponse,
  EmailsByIncidentApiConfig,
  ApiRejectResponse
>('REMEDIATION/forensicsGetIncidentClickedLinks', doForensicsGetIncidentClickedLinks)

export const forensicsGetMoreIncidentClickedLinks = createAsyncThunk<
  GetIncidentClickedLinksApiResponse,
  EmailsByIncidentApiConfig,
  ApiRejectResponse
>('REMEDIATION/forensicsGetMoreIncidentClickedLinks', doForensicsGetIncidentClickedLinks)

export const forensicsGetIncidentUserDetails = createAsyncThunk<
  GetIncidentUserDetailsApiResponse,
  EmailsByIncidentApiConfig,
  ApiRejectResponse
>('REMEDIATION/forensicsGetIncidentUserDetails', doForensicsGetIncidentUserDetails)

export const forensicsGetMoreIncidentUserDetails = createAsyncThunk<
  GetIncidentUserDetailsApiResponse,
  EmailsByIncidentApiConfig,
  ApiRejectResponse
>('REMEDIATION/forensicsGetMoreIncidentUserDetails', doForensicsGetIncidentUserDetails)

export const forensicsGetAllIncidentUserDetails = createAsyncThunk<
  GetIncidentUserDetailsApiResponse,
  EmailsByIncidentApiConfig,
  ApiRejectResponse
>('REMEDIATION/forensicsGetAllIncidentUserDetails', doForensicsGetIncidentUserDetails)

export const forensicsGetIncidents = createAsyncThunk<GetIncidentApiResponse, GetIncidentApiConfig, ApiRejectResponse>(
  'REMEDIATION/forensicsGetIncidents',
  doForensicsGetIncidents
)

export const forensicsGetMoreIncidents = createAsyncThunk<
  GetIncidentApiResponse,
  GetIncidentApiConfig,
  ApiRejectResponse
>('REMEDIATION/forensicsGetMoreIncidents', doForensicsGetIncidents)

export const forensicsGetEssAccount = createAsyncThunk<EssAccount, ForensicsGetEssAccount, ApiRejectResponse>(
  'REMEDIATION/forensicsGetEssAccount',
  async function doForensicsGetEssAccount(payload, { rejectWithValue }) {
    try {
      const resp: ResponseGenerator = await restClient(apiRoutes.FORENSICS_ESS_ACCOUNT, {
        data: { ...payload }
      })
      return resp.data.essAccount
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const forensicsCreateIncident = createAsyncThunk<NewIncident, NewIncident, ApiRejectResponse>(
  'REMEDIATION/forensicsCreateIncident',
  async function doForensicsCreateIncident(payload, { rejectWithValue }) {
    const accessTokenId = payload.accessTokenId || null
    try {
      const resp: ResponseGenerator = await restClient(apiRoutes.FORENSICS_CREATE_INCIDENT, {
        data: { accessTokenId, ...payload.config }
      })

      // catch 400 error and pass code to see if we want to show a special message
      if (resp.data.incident && resp.data.incident.code) {
        return rejectWithValue(validateApiError(resp.data.incident.code))
      }

      return resp.data.incident
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const forensicsCreateIncidentTags = createAsyncThunk<
  IncidentTag[],
  CreateIncidentTagsApiConfig,
  ApiRejectResponse
>('REMEDIATION/forensicsCreateIncidentTags', async function doForensicsCreateIncidentTags(
  payload,
  { rejectWithValue }
) {
  const accessTokenId = payload.accessTokenId || null
  const incidentId = payload.incidentId || null
  const tags = payload.tags || []

  try {
    const resp: ResponseGenerator = await restClient(apiRoutes.FORENSICS_CREATE_INCIDENT_TAGS, {
      data: { accessTokenId, incidentId, tags }
    })
    return resp.data.tags
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const forensicsDeleteIncidentTag = createAsyncThunk<
  IncidentTag[],
  DeleteIncidentTagApiConfig,
  ApiRejectResponse
>('REMEDIATION/forensicsDeleteIncidentTag', async function doForensicsDeleteIncidentTag(payload, { rejectWithValue }) {
  const { accessTokenId, incidentId, tagId, tags } = payload

  try {
    await restClient(apiRoutes.FORENSICS_DELETE_INCIDENT_TAG, {
      data: { accessTokenId, incidentId, tagId }
    })

    // remove tag from tag list
    const updatedTags = (tags as IncidentTag[]).filter(tag => tag.id !== tagId)
    return updatedTags
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const forensicsIncidentDeleteExistingEmails = createAsyncThunk<
  boolean,
  DeleteExistingEmailsApiConfig,
  ApiRejectResponse
>('REMEDIATION/forensicsIncidentDeleteExistingEmails', async function doForensicsIncidentDeleteExistingEmails(
  payload,
  { rejectWithValue }
) {
  try {
    await restClient(apiRoutes.FORENSICS_INCIDENT_DELETE_EXISTING_EMAILS, {
      data: { ...payload }
    })
    return true
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const forensicsGetIncident = createAsyncThunk<Incident, DeleteExistingEmailsApiConfig, ApiRejectResponse>(
  'REMEDIATION/forensicsGetIncident',
  async function doForensicsGetIncident(payload, { rejectWithValue }) {
    try {
      const resp: ResponseGenerator = await restClient(apiRoutes.FORENSICS_GET_INCIDENT, {
        data: { ...payload }
      })

      const incident = {
        ...resp.data.incident,
        continuousRemediationEnabled:
          resp.data.incident.continuousRemediationUntil &&
          new Date(resp.data.incident.continuousRemediationUntil) > new Date()
      }
      return incident
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const forensicsGetIncidentDetails = createAsyncThunk<any, DeleteExistingEmailsApiConfig, ApiRejectResponse>(
  'REMEDIATION/forensicsGetIncidentDetails',
  async function doForensicsGetIncidentDetails(payload, { rejectWithValue }) {
    try {
      const resp: ResponseGenerator = await restClient(apiRoutes.FORENSICS_GET_INCIDENT_DETAILS, {
        data: { ...payload }
      })
      return resp.data.incidentDetails
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const forensicsGetIncidentsTags = createAsyncThunk<IncidentTag[], { accessTokenId: string }, ApiRejectResponse>(
  'REMEDIATION/forensicsGetIncidentsTags',
  async function doForensicsGetIncidentsTags(payload, { rejectWithValue }) {
    const { accessTokenId } = payload

    try {
      const resp: ResponseGenerator = await restClient(apiRoutes.FORENSICS_GET_INCIDENTS_TAGS, {
        data: { accessTokenId }
      })
      return resp.data.tags || []
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const forensicsRestartContinuousRemediation = createAsyncThunk<
  any,
  DeleteExistingEmailsApiConfig,
  ApiRejectResponse
>('REMEDIATION/forensicsRestartContinuousRemediation', async function doForensicsRestartContinuousRemediation(
  payload,
  { getState, rejectWithValue }
) {
  const state = getState() as RootState

  try {
    // show demo error if using demo token
    if (state.user.isFirDemoUser) {
      return rejectWithValue(validateApiError({ data: { error: globalConfig.API_ERROR.DEMO_ERROR.FORENSICS } }))
    }
    await restClient(apiRoutes.FORENSICS_RESTART_CONTINUOUS_REMEDIATION, {
      data: { ...payload }
    })

    return {
      continuousRemediationUntil: DateTime.local()
        .plus({ days: 3 })
        .toISO()
    }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const forensicsStopContinuousRemediation = createAsyncThunk<
  any,
  DeleteExistingEmailsApiConfig,
  ApiRejectResponse
>('REMEDIATION/forensicsStopContinuousRemediation', async function doForensicsStopContinuousRemediation(
  payload,
  { getState, rejectWithValue }
) {
  const state = getState() as RootState

  try {
    // show demo error if using demo token
    if (state.user.isFirDemoUser) {
      return rejectWithValue(validateApiError({ data: { error: globalConfig.API_ERROR.DEMO_ERROR.FORENSICS } }))
    }

    await restClient(apiRoutes.FORENSICS_STOP_CONTINUOUS_REMEDIATION, {
      data: { ...payload }
    })
    return true
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})
