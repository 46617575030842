import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from 'global/configs/theme/defaults'
import dialogStyles from 'global/components/lib/dialogs/dialogStyles'

export default makeStyles(theme => {
  const defaultDialogStyles = dialogStyles(theme)

  return {
    ...defaultDialogStyles,
    dangerButton: {
      backgroundColor: COLORS.RED_600,

      '&:hover': {
        backgroundColor: COLORS.RED_600
      }
    },
    alert: {
      marginBottom: theme.spacing(2)
    }
  }
})
