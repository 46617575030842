import { CompanyDomain, SpfRecord, DmarcEntry } from 'global/types/api/dmarc'
import { DomainValues } from 'sen/components/lib/dialogs/addDomainWizardDialog/steps/DomainValues'

export interface DomainWizardDialogProps {
  onClose: (isDomainModified: boolean) => void
  domain: CompanyDomain
}

export interface DialogConfig {
  wizardStep: WizardSteps
  stepperStep: StepperSteps
  inProgress: boolean
  error: string | undefined
  getRegistrarUrlForDomain: () => string
  spfDomainValues: DomainValues
  dmarcDomainValues: DomainValues
  spfRecord: SpfRecord | undefined
  resolvedDmarc: DmarcEntry | undefined
}

export interface DialogEvents {
  onCloseDialog: () => void
  onNextStep: () => void
}

export enum WizardSteps {
  init,
  spfCheck,
  spfCheckSuccess,
  spfCheckFailure,
  dmarcPrep,
  dmarcSetup,
  dmarcCheck,
  dmarcInvalid,
  dmarcNotFound,
  dmarcCheckSuccess
}

export enum StepperSteps {
  spfCheck,
  dmarcCheck,
  success
}

export interface StepProps {
  domain: CompanyDomain
  config: DialogConfig
  events: DialogEvents
}
