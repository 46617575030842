import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => {
  return {
    // partner portal specific styles
    rootPP: {
      backgroundColor: '#FFFFFF',
      display: 'flex',
      marginTop: -58
    },
    cardPP: {
      border: 'none',
      boxShadow: 'none',
      paddingLeft: 4,
      postiion: 'relative',
      width: '100%'
    },
    inputPP: {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      border: '1px solid #D4D4D3',
      borderRadius: 6,
      fontSize: 20,
      fontWeight: 300,
      lineHeight: '1',
      padding: '8.5px 13px',
      width: '100%'
    },
    inputTextPP: {
      fontSize: 13,
      fontWeight: 300
    },
    btnContainerPP: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginBottom: 4,
      marginTop: 15
    },
    buttonPP: {
      '-webkit-box-shadow': '0 0 0.5rem -0.125rem rgb(0 0 0/75%)',
      background: '#fff',
      border: 'none',
      borderRadius: 4,
      color: '#0076d6',
      cursor: 'pointer',
      display: 'inline-block',
      fontSize: 16,
      fontWeight: 600,
      height: 40,
      letterSpacing: 0.8,
      lineHeight: '1.25',
      minHeight: 20,
      outline: 'none',
      padding: '10px 24px',
      textDecoration: 'none',
      '&:hover': {
        '-webkit-box-shadow': '0 0 0.5rem -0.125rem rgb(0 0 0/75%)',
        backgroundColor: '#0076d6',
        color: '#fff',
        textDecoration: 'none'
      }
    },
    titlePP: {
      '-moz-osx-font-smoothing': 'grayscale',
      '-webkit-font-smoothing': 'antialiased',
      fontSize: 25,
      fontWeight: 400,
      marginBottom: 15,
      marginTop: 0
    },
    textPP: {
      color: '#000000',
      fontSize: 17,
      fontWeight: 300,
      letterSpacing: '0',
      marginBottom: 15,
      marginTop: '0'
    }
  }
})
