import React, { useMemo } from 'react'

import { v4 as uuidv4 } from 'uuid'

import { Typography, DataTable, DataTableColumn, Grid } from '@barracuda-internal/bds-core'
import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'

import { InboxRule } from 'global/types/api/remediation'
import { BDSGridPagerConfig } from 'global/types/dataTables/dataTables'
import { ColumnsConfig } from 'global/types/dataTables/columnsConfigType'
import Pager from 'global/components/lib/grid/pager/Pager'
import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'
import { useFormatMessage } from 'global/lib/localization'

import styles from 'sen/components/lib/dialogs/inboxRules/inboxRulesDialogStyles'

export interface ModifiedAlertInboxRule extends InboxRule {
  formattedActions: any
  formattedConditions: any
}

export interface TableConfig {
  tableData: {
    total: number
    data: ModifiedAlertInboxRule[]
  }
  pageConfig: BDSGridPagerConfig
  columns: { [key: string]: string }
  columnsConfig: { [key: string]: ColumnsConfig }
}
export interface InboxRulesDialogProps {
  tableConfig: TableConfig
  rangeTable?: boolean
}

const BASE_I18N_TABLE_KEY = 'sen.app.data_tables.inbox_rules'

export const InboxRulesTable: React.FC<InboxRulesDialogProps> = ({ tableConfig, rangeTable = false }) => {
  const formatMessageTable = useFormatMessage(BASE_I18N_TABLE_KEY)
  const classes = styles()
  const dataTableClass = rangeTable ? 'rangeDataTable' : 'dataTable'

  return useMemo(
    () => (
      <Grid className={classes[rangeTable ? 'rangeTableArea' : 'tableArea']} data-table="inbox-rules" item xs={12}>
        <DataTable
          className={classes[tableConfig.tableData.total ? dataTableClass : 'emptyDataTable']}
          data={tableConfig.tableData}
          {...tableConfig.pageConfig}
          pager={Pager}
        >
          <DataTableNoRecords>
            <Typography>{!tableConfig.tableData.total && formatMessageTable('empty_table')}</Typography>
          </DataTableNoRecords>
          <DataTableColumn
            width={110}
            field={tableConfig.columns.SEQUENCE}
            {...tableConfig.columnsConfig[tableConfig.columns.SEQUENCE]}
            title={formatMessageTable(`${tableConfig.columns.SEQUENCE}`)}
            cell={({ dataItem }: { dataItem: ModifiedAlertInboxRule }) => (
              <Cell className={classes.cell} data-field="table-column-squence">
                <CellText ellipsis className={classes.normalCell} data-field="table-column-sequence-text">
                  {dataItem.sequence}
                </CellText>
              </Cell>
            )}
          />
          <DataTableColumn
            field={tableConfig.columns.NAME}
            {...tableConfig.columnsConfig[tableConfig.columns.NAME]}
            title={formatMessageTable(`${tableConfig.columns.NAME}`)}
            cell={({ dataItem }: { dataItem: ModifiedAlertInboxRule }) => (
              <Cell className={classes.cell} data-field="table-column-name">
                <CellText className={classes.normalCell} data-field="table-column-name-text">
                  {dataItem.displayName}
                </CellText>
              </Cell>
            )}
          />
          <DataTableColumn
            field={tableConfig.columns.ACTIONS}
            {...tableConfig.columnsConfig[tableConfig.columns.ACTIONS]}
            title={formatMessageTable(`${tableConfig.columns.ACTIONS}`)}
            cell={({ dataItem }: { dataItem: ModifiedAlertInboxRule }) => (
              <Cell className={classes.flexibleCell} data-field="table-column-actions">
                <CellText className={classes.breakWordCell} data-field="table-column-actions-text">
                  {dataItem.formattedActions
                    .reduce((all: any[], action: string) => {
                      return [...all, action, <br key={uuidv4()} />]
                    }, [])
                    .slice(0, -1)}
                </CellText>
              </Cell>
            )}
          />
          <DataTableColumn
            field={tableConfig.columns.CONDITIONS}
            {...tableConfig.columnsConfig[tableConfig.columns.CONDITIONS]}
            title={formatMessageTable(`${tableConfig.columns.CONDITIONS}`)}
            cell={({ dataItem }: { dataItem: ModifiedAlertInboxRule }) => (
              <Cell className={classes.flexibleCell} data-field="table-column-conditions">
                <CellText className={classes.breakWordCell} data-field="table-column-conditions-text">
                  {dataItem.formattedConditions
                    .reduce((all: any[], action: string) => {
                      return [...all, action, <br key={uuidv4()} />]
                    }, [])
                    .slice(0, -1)}
                </CellText>
              </Cell>
            )}
          />
          <DataTableColumn
            width={110}
            field={tableConfig.columns.EXCEPTIONS}
            {...tableConfig.columnsConfig[tableConfig.columns.EXCEPTIONS]}
            title={formatMessageTable(tableConfig.columns.EXCEPTIONS)}
            cell={({ dataItem }: { dataItem: ModifiedAlertInboxRule }) => (
              <Cell className={classes.flexibleCell} data-field="table-column-exceptions">
                <CellText className={classes.normalCell} data-field="table-column-exceptions-text">
                  {formatMessageTable(dataItem.exceptions ? 'yes' : 'no')}
                </CellText>
              </Cell>
            )}
          />
          <DataTableColumn
            width={110}
            field={tableConfig.columns.ENABLED}
            {...tableConfig.columnsConfig[tableConfig.columns.ENABLED]}
            title={formatMessageTable(tableConfig.columns.ENABLED)}
            cell={({ dataItem }: { dataItem: ModifiedAlertInboxRule }) => (
              <Cell className={classes.flexibleCell} data-field="table-column-enabled">
                <CellText className={classes.normalCell} data-field="table-column-enabled-text">
                  {formatMessageTable(dataItem.isEnabled ? 'yes' : 'no')}
                </CellText>
              </Cell>
            )}
          />
        </DataTable>
      </Grid>
    ),
    [classes, formatMessageTable, tableConfig, dataTableClass, rangeTable]
  )
}

export default InboxRulesTable
