import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { generateFeatureLib } from 'global/lib/feature/feature'

export default function useFeature() {
  const { userStore }: any = useSelector((_stores: any) => ({
    accessTokenStore: _stores.user || {}
  }))

  const lib = useMemo(() => {
    return generateFeatureLib()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore])

  // always return lib first followed by store
  return [lib, userStore]
}
