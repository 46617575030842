import React, { useMemo, useCallback } from 'react'

import { Button, Typography, TextField, Select, MenuItem, Grid } from '@barracuda-internal/bds-core'
import { EpDomainFraudProtection } from '@barracuda-internal/bds-core/dist/Logos/Products'

import { isString } from 'lodash'

import globalStyles from 'global/components/features/signinSignup/signinSignupStyles'
import { useFormatMessage } from 'global/lib/localization'
import { getCountryList } from 'global/lib/isoCountries'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import { onKeyDown } from 'global/lib/keyEvents'

import SigninSignupLayout from 'sen/components/lib/layout/SigninSignupLayout'
import usedomainFraudSignupLogic from 'sen/components/pages/signup/domainFraudSignup/useDomainFraudSignupLogic'
import styles, { wrapperStyles } from 'sen/components/pages/signup/domainFraudSignup/domainFraudSignupStyles'

const BASE_I18N_KEY = 'sen.app.domain_fraud_signup'

const DomainFraudSignup: React.FC = () => {
  const classes = styles()
  const globalClasses = globalStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [inProgress, form, formHandlers, formStates, errors] = usedomainFraudSignupLogic()

  const renderTextField = useCallback(
    (field: string, error = false) => {
      return (
        <>
          <TextField
            type={field === 'password' ? 'password' : 'text'}
            error={!!error}
            className={classes.input}
            disabled={inProgress}
            placeholder={formatMessage(`placeholders.${field}`)}
            value={form.values[field]}
            onKeyDown={onKeyDown(['Enter'], !formStates.isSignupButtonDisabled && formHandlers.onSignup)}
            onChange={(e: any) => {
              form.setFormValues({ [field]: e.target.value })
            }}
            data-field={field}
          />
          {!!error && isString(error) && (
            <div className={classes.formError} data-field="field-error">
              {formatMessage(`form_errors.${error}`, { li: (txt: any) => <li key={txt}>{txt}</li> })}
            </div>
          )}
        </>
      )
    },
    [form, classes, formatMessage, formHandlers, inProgress, formStates]
  )

  return useMemo(() => {
    return (
      <SigninSignupLayout error={errors.signup}>
        <SigninSignupLayout.Wrapper isFormInProgress={false} style={wrapperStyles}>
          {inProgress && <LinearProgress className={classes.linearProgress} data-field="linear-progress" />}
          <Grid className={classes.header} item xs={12}>
            <Grid container item xs={12} className={globalClasses.headingLogoWrapper}>
              <EpDomainFraudProtection alt="" />
            </Grid>

            <Grid item xs={12}>
              <Typography className={classes.title} data-field="title">
                {formatMessage('title')}
              </Typography>
            </Grid>
          </Grid>
          <SigninSignupLayout.Content>
            <Typography className={classes.formTitle} data-field="form-title">
              {formatMessage('form_title')}
            </Typography>

            {renderTextField('name')}
            {renderTextField('company')}

            <Select
              className={classes.input}
              displayEmpty
              value={form.values.country}
              disabled={inProgress}
              renderValue={(selected: any) => {
                if (!selected?.length) {
                  return <data className={classes.selectPlaceholder}>{formatMessage('placeholders.country')}</data>
                }

                return selected
              }}
              onKeyDown={onKeyDown(['Enter'], !formStates.isSignupButtonDisabled && formHandlers.onSignup)}
              onChange={(e: any) => {
                form.setFormValues({ country: e.target.value })
              }}
              data-field="select-country"
            >
              <MenuItem disabled value="">
                <data className={classes.selectPlaceholder}>{formatMessage('placeholders.country')}</data>
              </MenuItem>
              {getCountryList().map((menuItem: any) => (
                <MenuItem className={classes.countryMenuItem} key={menuItem.code} value={menuItem.code}>
                  {menuItem.name}
                </MenuItem>
              ))}
            </Select>

            {renderTextField('email', errors.form.isInvalidEmail && 'email_error')}
            {renderTextField('password', errors.form.isInvalidPassword && 'weak_password')}

            <Grid item xs={12}>
              <Button
                className={classes.signupButton}
                onClick={formHandlers.onSignup}
                disabled={formStates.isSignupButtonDisabled}
                color="primary"
                variant="contained"
                data-action="signup"
              >
                {formatMessage('button')}
              </Button>

              <Button
                className={classes.linkButton}
                size="small"
                color="primary"
                variant="text"
                onClick={formHandlers.onGotoSignin}
                disabled={inProgress}
                data-field="goto-signin-link"
              >
                {formatMessage('have_account')}
              </Button>

              <Typography className={classes.privacyPolicy}>
                {formatMessage('privacy_policy', {
                  b: (txt: string) => <b key={txt}>{txt}</b>,
                  policy: (txt: string) => (
                    <data
                      key={txt}
                      onClick={() => {
                        if (!inProgress) formHandlers.onGotoPrivacyPolicy()
                      }}
                      role="presentation"
                      className={classes.privacyLink}
                      data-field="privacy-policy-link"
                    >
                      {txt}
                    </data>
                  )
                })}
              </Typography>
            </Grid>
          </SigninSignupLayout.Content>
        </SigninSignupLayout.Wrapper>
      </SigninSignupLayout>
    )
  }, [globalClasses, formatMessage, inProgress, errors, form, formHandlers, classes, formStates, renderTextField])
}

export default DomainFraudSignup
