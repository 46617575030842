import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  appLoading: {
    height: '100%',
    fontSize: 18,
    backgroundColor: 'rgb(250, 250, 250)',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  cudaLogo: {
    height: 55,
    maxHeight: 55
  },
  wholeScreen: {
    height: '100vh',
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  wholeScreenLoading: {
    display: 'flex',
    '-ms-flex-align': 'center',
    '-webkit-align-items': 'center',
    '-webkit-box-align': 'center',
    alignItems: 'center',
    justifyContent: 'center'
  },
  appLoadingWrapper: {
    padding: 25
  }
}))
