import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  wrapper: {
    margin: theme.spacing(2)
  },
  buttons: {
    width: '100%'
  }
}))
