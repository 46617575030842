import React from 'react'
import PropTypes from 'prop-types'

import { ChartSeriesItemProps } from '@progress/kendo-react-charts'

import GlobalAreaChart from '@barracuda-internal/bds-core/dist/AreaChart/AreaChart'

import { COLORS } from 'ets/configs/styles/defaults'
import { CHART_AREA_HEIGHT } from 'ets/components/lib/chartPanel/chartPanelStyles'

export interface AreaChartProps {
  chartData?: ChartSeriesItemProps
}

const chartSteps = {
  1: 5
} as { [key: number]: number }

const demoData = [...Array(12).keys()].map(i => ({
  count: Math.floor(Math.random() * Math.floor(600)),
  date: new Date(`1970-${i + 1}-12`)
}))

const AreaChart: React.FC<AreaChartProps> = ({ chartData }) => {
  const isEmptyChart = !!chartData && !(chartData.data || []).some(dataItem => dataItem[chartData.field || ''] > 0)
  const validatedChartData: any = chartData || {
    data: demoData,
    field: 'count',
    categoryField: 'date'
  }
  const valueAxisMaxValue: number = validatedChartData.data.reduce((all: number, data: any) => {
    return Math.max(data[validatedChartData.field], all)
  }, 0)

  return (
    <GlobalAreaChart
      areaChart={{
        chartProps: {
          renderAs: 'canvas'
        },
        chartStyle: { width: '100%', height: CHART_AREA_HEIGHT },
        chartCategoryAxisItemProps: {
          labels: { step: 2, format: 'MMM yyyy' },
          majorGridLines: { visible: false }
        },
        chartValueAxisItemProps: {
          ...(isEmptyChart && { max: 30 }),
          labels: { format: '#', step: chartSteps[valueAxisMaxValue] || 2 }
        },
        chartSeriesItemProps: {
          field: validatedChartData.field,
          categoryField: validatedChartData.categoryField,
          tooltip: { background: COLORS.RED, format: 'n' },
          markers: { visible: true, type: 'circle', background: COLORS.RED, size: 7, border: { color: COLORS.RED } },
          line: { color: COLORS.RED, width: 2, style: 'normal' },
          color: COLORS.LIGHT_RED
        },
        chartData: validatedChartData.data as any
      }}
    />
  )
}

AreaChart.propTypes = {
  chartData: PropTypes.any
}

AreaChart.defaultProps = {
  chartData: undefined
}

export default AreaChart
