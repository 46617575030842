import { DateTime, ToRelativeUnit } from 'luxon'
import { isDate, isString, isNaN } from 'lodash'

import humanizeDurationLib from 'humanize-duration'

import { config } from 'global/lib/config'

export type DateFormat = Date | string | undefined
export interface TimeDifference {
  initialDate?: DateFormat
  subtractedDate?: DateFormat
}

export interface DateRange {
  start: Date
  end: Date
}

// luxon methods
export function luxonDate(date?: DateFormat) {
  switch (true) {
    case isDate(date):
      return DateTime.fromJSDate(date as Date)
    case isString(date):
      return DateTime.fromISO(date as string)
    default:
      return DateTime.now()
  }
}

export function formatDate(date?: DateFormat, format: string = config.DATETIME.DEFAULT_DATE_FORMAT) {
  const luxonDateObject = luxonDate(date)

  return luxonDateObject.isValid ? luxonDateObject.toFormat(format) : ''
}

export function formatTime(date?: DateFormat) {
  return formatDate(date, config.DATETIME.DEFAULT_TIME_FORMAT)
}

export function formatDateWithTime(date?: DateFormat) {
  return formatDate(date, config.DATETIME.DEFAULT_DATE_WITH_TIME_FORMAT)
}

export function formatMonth(date?: DateFormat) {
  return formatDate(date, config.DATETIME.MONTH_ONLY_FORMAT)
}

export function formatMonthWithDay(date?: DateFormat) {
  return formatDate(date, config.DATETIME.MONTH_WITH_DAY_FORMAT)
}

export function format24HoursTime(date?: DateFormat) {
  return formatDate(date, config.DATETIME.TIME_FORMAT)
}

// The dates are optional.
// If they are not set the luxonDate method will get the now (actual) date
export function timeDifference({ initialDate, subtractedDate }: TimeDifference) {
  return luxonDate(initialDate)
    .toUTC()
    .diff(luxonDate(subtractedDate).toUTC())
}

export function isTimedOut(checkUntil: DateTime | undefined) {
  return checkUntil && DateTime.local() > checkUntil
}

export function isAfterDate(dates: TimeDifference) {
  const diff = timeDifference(dates).milliseconds

  return !isNaN(diff) && diff > 0
}

export function isBeforeDate({ initialDate, subtractedDate }: TimeDifference) {
  const diff = timeDifference({ initialDate: subtractedDate, subtractedDate: initialDate }).milliseconds

  return !isNaN(diff) && diff > 0
}

// js date methods
export function getHours(dateRange: DateRange) {
  const dateDiff = DateTime.fromJSDate(dateRange.end)
    .diff(DateTime.fromJSDate(dateRange.start))
    .as('hours')
  return Math.round(dateDiff)
}

export function removeHours(date: Date, hours: number) {
  date.setTime(date.getTime() - hours * 36e5)

  return date
}

// humanize
export function humanizeDuration(
  duration: any,
  durationConfig: any = config.DATETIME.DEFAULT_HUMANIZE_DURATION_CONFIG
): string {
  return humanizeDurationLib(duration, durationConfig)
}

// relative date
export function relativeDate(date: DateFormat, unit?: ToRelativeUnit) {
  const luxonDateObject = luxonDate(date)

  return luxonDateObject.toRelative({ ...(unit && { unit }) }) || undefined
}
