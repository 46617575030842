import React, { ReactNode, useMemo, useEffect } from 'react'

import {
  Typography,
  Button,
  DataTable,
  DataTableColumn,
  SelectWrapper,
  Select,
  MenuItem,
  Grid,
  Paper,
  Tooltip,
  Box
} from '@barracuda-internal/bds-core'
import { Assignment as AssignmentIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'
import { Report as ReportIcon } from '@barracuda-internal/bds-core/dist/Icons/Email'
import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'

import highlightText from 'global/lib/highlightText/highlightText'
import { useFormatMessage } from 'global/lib/localization'
import { formatNumber } from 'global/lib/number'

import CircularProgress from 'global/components/lib/circularProgress/CircularProgress'
import ExportToCsvButton from 'global/components/lib/exportToCsvButton/ExportToCsvButton'
import SearchField from 'global/components/lib/searchField/SearchField'
import Pager from 'global/components/lib/grid/pager/Pager'
import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'
import CellLabelType from 'global/components/lib/grid/cell/CellLabelType'
import EmailCell from 'global/components/lib/grid/cell/customCells/EmailCell'
import AttackTypeLabel from 'global/components/lib/attackTypeLabel/AttackTypeLabel'
import StatusTypeLabel from 'global/components/lib/statusTypeLabel/StatusTypeLabel'
import CellConfidenceScore from 'global/components/lib/grid/cell/CellConfidenceScore'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import EmailDetailsDialog from 'global/components/lib/dialogs/emailDetailsDialog/EmailDetailsDialog'
import useEmailDetailsSpAttackInterface from 'global/components/lib/dialogs/emailDetailsDialog/interfaces/useEmailDetailsSpAttackInterface'

import IMAGES from 'global/configs/theme/images.config'

import useRecentAttacksLogic from 'sen/components/pages/dashboard/content/tabs/recentAttacks/useRecentAttacksLogic'
import styles, { CELL_HEIGHT } from 'sen/components/pages/dashboard/content/tabs/recentAttacks/recentAttacksStyles'
import MailboxesDialog from 'sen/components/lib/dialogs/mailboxes/MailboxesDialog'
import ReportFalsePositiveDialog from 'sen/components/lib/dialogs/reportFalsePositive/ReportFalsePositiveDialog'

const BASE_I18N_KEY = 'sen.app.dashboard.recent_attacks'
const BASE_I18N_TABLE_KEY = 'sen.app.data_tables.sp_attacks'
const BASE_ATTACK_TYPES_KEY = 'app.attack_types'

const RecentAttacksTab: React.FC = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const tableFormatMessage = useFormatMessage(BASE_I18N_TABLE_KEY)
  const attackTypesFormatMessage = useFormatMessage(BASE_ATTACK_TYPES_KEY)
  const classes = styles()

  const [
    accountName,
    card1Config,
    card2Config,
    card3Config,
    card4Config,
    confidenceLevel,
    spAttacksTableConfig,
    spAttackId,
    attackSelectorConfig,
    emailDetailsDialogActionCallbacks
  ] = useRecentAttacksLogic()

  const [
    emailDetailDialogConfig,
    emailDetailDialogActions,
    reportFalsePositiveDialogActions
  ] = useEmailDetailsSpAttackInterface(emailDetailsDialogActionCallbacks)

  useEffect(() => {
    if (spAttackId) {
      reportFalsePositiveDialogActions.onOpen(spAttackId, confidenceLevel)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spAttackId])

  function AttacksCard({
    isLoaded,
    title,
    subtitle,
    children,
    linkButton,
    dataField
  }: {
    isLoaded: boolean
    title: string
    subtitle?: string
    children: any
    linkButton: ReactNode
    dataField: string
  }) {
    return (
      <Paper className={classes.card} elevation={1} data-field={dataField}>
        <Grid
          className={classes.cardContent}
          container
          direction="column"
          justifyContent={!isLoaded ? 'flex-start' : 'space-between'}
        >
          <CardTitle title={title} subtitle={subtitle} />
          {!isLoaded ? (
            <Grid container justifyContent="center" className={classes.circularProgress}>
              <CircularProgress data-field={`${title}-card-circular-progress`} />
            </Grid>
          ) : (
            <>{children}</>
          )}
        </Grid>
        {linkButton}
      </Paper>
    )
  }

  function CardTitle({ title, subtitle }: { title: string; subtitle?: string }) {
    return (
      <Grid container direction="column" justifyContent="flex-start">
        <Typography className={classes.cardTitle} color="secondary" variant="h5">
          {title}
        </Typography>
        <Typography className={classes.cardSubtitle} color="secondary" variant="body1">
          {subtitle}
        </Typography>
      </Grid>
    )
  }

  function CardCount({
    count,
    title,
    status,
    subCount,
    dataField
  }: {
    count: number
    title: string | ReactNode
    status: 'Warning' | 'Info' | 'Success'
    dataField: string
    subCount?: number
  }) {
    const countClassName = `cardCount${status || ''}` as keyof typeof classes

    return (
      <Grid item data-field={dataField}>
        <Grid container alignItems="baseline">
          <Tooltip
            arrow
            title={`${subCount}`}
            disableHoverListener={typeof subCount !== 'number'}
            placement="top-start"
          >
            <Typography variant="h1" className={classes[countClassName]} align="left">
              {typeof subCount === 'number'
                ? `${`${formatNumber(subCount < 1 / 100000000 ? 0 : subCount, '0.000%')}`}${
                    subCount < 0.00001 ? '*' : ''
                  }`
                : formatNumber(count)}
            </Typography>
          </Tooltip>
        </Grid>
        <Typography variant="body1" align="left" className={classes.cardCountTitle} color="secondary">
          {title}
          {typeof subCount === 'number' && ` (${formatNumber(count, '0a')})`}
        </Typography>
      </Grid>
    )
  }

  function CardDate({ date, title, dataField }: { date: string; title: string | ReactNode; dataField: string }) {
    return (
      <Grid container className={classes.cardDateWrapper} justifyContent="flex-end" data-field={dataField}>
        {!!date?.length && (
          <>
            <Typography className={classes.cardDate} variant="overline" color="secondary">
              {title}&nbsp;
              {date}
            </Typography>
          </>
        )}
      </Grid>
    )
  }

  function CardLinkButton({ onClick, title }: { onClick: () => void; title: string }) {
    return (
      <Grid className={classes.linkButtonWrapper} container justifyContent="center">
        <Button size="small" color="primary" variant="text" onClick={onClick}>
          &nbsp;&nbsp;{title}&nbsp;&nbsp;
        </Button>
      </Grid>
    )
  }

  return useMemo(() => {
    return (
      <Grid container direction="column" data-field="root-grid">
        <Typography className={classes.title} data-field="title">
          {formatMessage('title', { accountName })}
        </Typography>

        {/* Attack cards */}
        <Grid container spacing={2} data-field="attack-cards-grid">
          {/* Real-time Phishing protection */}
          <Grid item xs={12} sm={12} md={12} lg={!card3Config.isVisible && !card4Config.isVisible ? 6 : 4}>
            <AttacksCard
              isLoaded={card1Config.isLoaded}
              title={formatMessage('card_1.title')}
              subtitle={formatMessage('card_1.subtitle')}
              linkButton={<CardLinkButton onClick={card1Config.onClick} title={formatMessage('card_1.link_title')} />}
              dataField="attack-card_1"
            >
              <Grid
                container
                direction="row"
                data-field="attack-card_1-grid"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                {[0, 1, 2].map((spIndex: number) => (
                  <Grid item key={spIndex} xs={4} data-field={`attack-card_1-grid-item-${spIndex}`}>
                    <CardCount
                      count={card1Config.spCounts[spIndex]}
                      subCount={spIndex === 1 ? card1Config.fpRatio : undefined}
                      title={formatMessage(`card_1.count_${spIndex}_title`, { count: card1Config.spCounts[spIndex] })}
                      dataField={`attack-card_1-item-${spIndex}-count`}
                      status={spIndex === 0 ? 'Success' : 'Info'}
                    />
                  </Grid>
                ))}
              </Grid>
              <CardDate
                date={card1Config.date}
                title={formatMessage('card_1.date_title')}
                dataField="attack-card_1-date"
              />
            </AttacksCard>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={!card3Config.isVisible && !card4Config.isVisible ? 6 : 8}>
            <Grid container spacing={2}>
              {/* Account takeover protecton */}
              <Grid item xs={12} sm={12} md>
                <AttacksCard
                  isLoaded={card2Config.isLoaded}
                  title={formatMessage('card_2.title')}
                  subtitle={formatMessage('card_2.subtitle', { timeframe: card2Config.timeframe })}
                  linkButton={
                    <CardLinkButton onClick={card2Config.onClick} title={formatMessage('card_2.link_title')} />
                  }
                  dataField="attack-card_2"
                >
                  <Grid
                    container
                    direction="row"
                    data-field="attack-card_2-grid"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Grid item xs={6} data-field="attack-card_2-grid-item">
                      <CardCount
                        count={card2Config.outstandingAlert}
                        title={formatMessage('card_2.count_title', {
                          count: card2Config.outstandingAlert
                        })}
                        status={card2Config.isWarning ? 'Warning' : 'Success'}
                        dataField="attack-card_2-count"
                      />
                    </Grid>
                  </Grid>
                  <CardDate
                    date={card2Config.date}
                    title={formatMessage('card_2.date_title')}
                    dataField="attack-card_2-date"
                  />
                </AttacksCard>
              </Grid>

              {/* Domain fraund protection */}
              {card3Config.isVisible && (
                <Grid item xs={12} sm={12} md>
                  <AttacksCard
                    isLoaded={card3Config.isLoaded}
                    title={formatMessage('card_3.title')}
                    linkButton={
                      <CardLinkButton onClick={card3Config.onClick} title={formatMessage('card_3.link_title')} />
                    }
                    dataField="attack-card_3"
                  >
                    <Grid
                      container
                      direction="row"
                      data-field="attack-card_3-grid"
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      {[0, 1, 2].map((dmarcIndex: number) => (
                        <Grid item key={dmarcIndex} xs={4} data-field={`attack-card_3-grid-item-${dmarcIndex}`}>
                          <CardCount
                            count={card3Config.dmarcCounts[dmarcIndex]}
                            title={formatMessage(`card_3.count_${dmarcIndex}_title`)}
                            dataField={`attack-card_3-item-${dmarcIndex}-count`}
                            status={dmarcIndex === 0 ? 'Success' : 'Info'}
                          />
                        </Grid>
                      ))}
                    </Grid>
                    <CardDate date="" title="" dataField="" />
                  </AttacksCard>
                </Grid>
              )}

              {/* Licensing information */}
              {card4Config.isVisible && (
                <Grid item xs={12} sm={12} md>
                  <AttacksCard
                    isLoaded={card4Config.isLoaded}
                    title={formatMessage('card_4.title')}
                    linkButton={
                      <CardLinkButton
                        onClick={card4Config.onClick}
                        title={formatMessage('card_4.link_title')}
                        data-action="card_4-action"
                      />
                    }
                    dataField="attack-card_4"
                  >
                    <Grid
                      container
                      direction="row"
                      data-field="attack-card_4-grid"
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Grid item xs={6} data-field="attack-card_4-grid-item">
                        <CardCount
                          count={card4Config.licenseCounts}
                          title={formatMessage('card_4.count_title', {
                            count: card4Config.licenseCounts
                          })}
                          dataField="attack-card_4-count"
                          status="Success"
                        />
                      </Grid>
                    </Grid>
                    <CardDate
                      date={card4Config.serialNumber}
                      title={formatMessage('card_4.date_title')}
                      dataField="attack-card_4-date"
                    />
                  </AttacksCard>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* Spear Phishing attacks table */}
        <Grid
          ref={spAttacksTableConfig.ref}
          className={classes.spearPhishingAttacks}
          container
          data-section="spear-phishing-attacks"
          direction="column"
          data-field="attack-table-grid"
        >
          <Grid item data-field="attack-table-grid-title">
            <Typography className={classes.title} data-field="attack-table-title">
              {formatMessage('sp_title')}
            </Typography>
            <Typography className={classes.subtitle} variant="subtitle2" data-field="attack-table-subtitle">
              {formatMessage('sp_subtitle')}
            </Typography>
          </Grid>
          <Grid item data-field="attack-table-grid-paper">
            <Paper className={classes.tableCard} elevation={0} data-field="attack-table-paper">
              {!spAttacksTableConfig.isLoaded && (
                <Grid container justifyContent="center" className={classes.tableCircularProgress}>
                  <CircularProgress data-field="loader" />
                </Grid>
              )}

              {spAttacksTableConfig.isLoaded && (
                <Grid container direction="column" className={classes.tableWrapper} data-field="attack-table-wrapper">
                  <Grid container justifyContent="flex-end">
                    <ExportToCsvButton
                      className={classes.exportButton}
                      {...spAttacksTableConfig.exportToCsvConfig}
                      data-field="export-csv-button"
                    />
                  </Grid>
                  <Grid container direction="row">
                    <Grid item xs={9}>
                      <SearchField
                        {...spAttacksTableConfig.searchFieldConfig}
                        placeholder={formatMessage('search_field.placeholder')}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <SelectWrapper fullWidth size="medium" data-field="select-wrapper">
                        <Select
                          MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left'
                            }
                          }}
                          className={classes.pickList}
                          {...(attackSelectorConfig.select as any)}
                          renderValue={(): JSX.Element => (
                            <div className={attackSelectorConfig.select.disabled ? classes.disabledPicklist : ''}>
                              <img className={classes.pickListIcon} alt="" src={IMAGES.iconFilter} />
                              <Typography className={classes.pickListText}>
                                {attackTypesFormatMessage(`${attackSelectorConfig.select.value}.text`)}
                              </Typography>
                            </div>
                          )}
                          data-field="select"
                        >
                          {attackSelectorConfig.menuItems.map((menuItem: any) => (
                            <MenuItem className={classes.selectItem} key={menuItem.id} value={menuItem.id}>
                              {attackTypesFormatMessage(`${menuItem.id}.text`)}
                            </MenuItem>
                          ))}
                        </Select>
                      </SelectWrapper>
                    </Grid>
                  </Grid>

                  <Grid
                    className={`${classes.tableArea} ${!spAttacksTableConfig.tableData.total ? 'empty-table' : ''}`}
                    data-table="attacks"
                    item
                    xs={12}
                    data-field="grid-table"
                  >
                    {spAttacksTableConfig.inProgress && (
                      <LinearProgress className={classes.indicator} data-field="linear-progress" />
                    )}
                    <DataTable
                      data-testid="sp-attack-table"
                      className={`${
                        spAttacksTableConfig.tableData.total ? classes.dataTable : classes.emptyDataTable
                      } attacks-table`}
                      data={spAttacksTableConfig.tableData}
                      {...spAttacksTableConfig.pageConfig}
                      {...spAttacksTableConfig.sortConfig}
                      pager={Pager}
                      data-field="data-table"
                    >
                      <DataTableNoRecords data-field="empty-table">
                        <Typography>
                          {!spAttacksTableConfig.tableData.total && tableFormatMessage('empty_table')}
                        </Typography>
                      </DataTableNoRecords>
                      <DataTableColumn
                        width={110}
                        field={spAttacksTableConfig.columns.DATE}
                        {...spAttacksTableConfig.columnsConfig[spAttacksTableConfig.columns.DATE as any]}
                        title={tableFormatMessage(spAttacksTableConfig.columns.DATE)}
                        cell={({ dataItem }: { dataItem: any }) => (
                          <Cell className={classes.cell} data-field="table-column-date">
                            <CellText className={classes.normalCell} data-field="table-column-date-text">
                              {dataItem.formattedDate}
                            </CellText>
                          </Cell>
                        )}
                      />
                      <DataTableColumn
                        field={spAttacksTableConfig.columns.EMPLOYEE}
                        {...spAttacksTableConfig.columnsConfig[spAttacksTableConfig.columns.EMPLOYEE as any]}
                        title={tableFormatMessage(spAttacksTableConfig.columns.EMPLOYEE)}
                        cell={({ dataItem }: { dataItem: any }) => (
                          <EmailCell CELL_HEIGHT={CELL_HEIGHT} name={dataItem.displayName} email={dataItem.email} />
                        )}
                      />
                      <DataTableColumn
                        field={spAttacksTableConfig.columns.EMAIL}
                        {...spAttacksTableConfig.columnsConfig[spAttacksTableConfig.columns.EMAIL as any]}
                        title={tableFormatMessage(spAttacksTableConfig.columns.EMAIL)}
                        cell={({ dataItem }: { dataItem: any }) => (
                          <Cell className={classes.cell} data-field="table-column-email">
                            <Tooltip
                              classes={{ tooltip: classes.tooltip }}
                              title={
                                <span>
                                  {(dataItem.sender.displayName || dataItem.sender.email) && (
                                    <>
                                      <b>{dataItem.sender.displayName || dataItem.sender.email}</b>
                                    </>
                                  )}
                                  {dataItem.sender.displayName && dataItem.sender.email && (
                                    <>
                                      &nbsp;&lt;
                                      {dataItem.sender.email}&gt;
                                    </>
                                  )}
                                  {(dataItem.sender.displayName || dataItem.sender.email) && <br />}
                                  {!!dataItem.subject?.length && (
                                    <>
                                      {formatMessage('subject').toLowerCase()}
                                      &nbsp;
                                      <b>{dataItem.subject}</b>
                                    </>
                                  )}
                                </span>
                              }
                              placement="top-start"
                              data-field="table-column-email-tooltip"
                            >
                              <div>
                                {(dataItem.sender.displayName || dataItem.sender.email) && (
                                  <CellText
                                    ellipsis
                                    disableTitle
                                    className={classes.boldCell}
                                    data-field="table-column-email-display-name"
                                  >
                                    {highlightText(
                                      dataItem.sender.displayName || dataItem.sender.email,
                                      spAttacksTableConfig.highlightKeywords
                                    )}
                                  </CellText>
                                )}
                                {dataItem.sender.displayName && dataItem.sender.email && (
                                  <CellText
                                    ellipsis
                                    disableTitle
                                    className={classes.lightCell}
                                    data-field="table-column-email-highlight"
                                  >
                                    &nbsp;&lt;
                                    {highlightText(dataItem.sender.email, spAttacksTableConfig.highlightKeywords)}&gt;
                                  </CellText>
                                )}
                                {!!dataItem.subject?.length && (
                                  <>
                                    <br />
                                    <CellText
                                      ellipsis
                                      disableTitle
                                      className={classes.normalCell}
                                      data-field="table-column-email-subject-title"
                                    >
                                      {formatMessage('subject').toLowerCase()}
                                      &nbsp;
                                    </CellText>
                                    <CellText
                                      ellipsis
                                      disableTitle
                                      className={classes.boldCell}
                                      data-field="table-column-email-subject"
                                    >
                                      {highlightText(dataItem.subject, spAttacksTableConfig.highlightKeywords)}
                                    </CellText>
                                  </>
                                )}
                              </div>
                            </Tooltip>
                          </Cell>
                        )}
                      />
                      <DataTableColumn
                        width={200}
                        field={spAttacksTableConfig.columns.ATTACK_TYPE}
                        headerClassName={classes.rightAlignedText}
                        {...spAttacksTableConfig.columnsConfig[spAttacksTableConfig.columns.ATTACK_TYPE as any]}
                        title={tableFormatMessage(spAttacksTableConfig.columns.ATTACK_TYPE)}
                        cell={({ dataItem }: { dataItem: any }) => (
                          <Cell className={classes.cell} data-field="table-column-attack">
                            <CellLabelType align="right">
                              <AttackTypeLabel attackType={dataItem.taxonomy} />
                            </CellLabelType>
                          </Cell>
                        )}
                      />
                      <DataTableColumn
                        width={100}
                        field={spAttacksTableConfig.columns.CONFIDENCE}
                        {...spAttacksTableConfig.columnsConfig[spAttacksTableConfig.columns.CONFIDENCE as any]}
                        title={tableFormatMessage(spAttacksTableConfig.columns.CONFIDENCE)}
                        cell={({ dataItem }: { dataItem: any }) => (
                          <Cell className={classes.cell} data-field="table-column-confidence">
                            <CellConfidenceScore
                              level={dataItem.confidenceLevel}
                              data-field="table-column-confidence-score"
                            />
                          </Cell>
                        )}
                      />
                      <DataTableColumn
                        width={200}
                        field={spAttacksTableConfig.columns.STATUS}
                        {...spAttacksTableConfig.columnsConfig[spAttacksTableConfig.columns.STATUS as any]}
                        title={tableFormatMessage(spAttacksTableConfig.columns.STATUS)}
                        cell={({ dataItem }: { dataItem: any }) => (
                          <Cell className={classes.cell} data-field="table-column-status">
                            {dataItem.status.inProgress && <CircularProgress data-field="table-column-status-loader" />}
                            {!dataItem.status.inProgress && (
                              <CellLabelType>
                                <StatusTypeLabel status={dataItem.status.id} />
                              </CellLabelType>
                            )}
                          </Cell>
                        )}
                      />
                      <DataTableColumn
                        width={50}
                        field={spAttacksTableConfig.columns.DETAILS}
                        {...spAttacksTableConfig.columnsConfig[spAttacksTableConfig.columns.DETAILS as any]}
                        title=" "
                        cell={({ dataItem }: { dataItem: any }) => (
                          <Cell className={classes.cell} data-field="table-column-details">
                            {dataItem.disableDetails ? (
                              <CellText>
                                <Tooltip
                                  classes={{ tooltip: classes.tooltip }}
                                  title={formatMessage('details_unavailable')}
                                  placement="top-start"
                                  data-field="table-column-details-tooltip"
                                >
                                  <Box>
                                    <AssignmentIcon
                                      className={classes.disabledIcon}
                                      data-testid={`more-details-${dataItem.threatId}`}
                                      data-action="email-details-dialog-open-disabled"
                                    />
                                  </Box>
                                </Tooltip>
                              </CellText>
                            ) : (
                              <CellText>
                                <Tooltip
                                  classes={{ tooltip: classes.tooltip }}
                                  title={formatMessage('more_details')}
                                  placement="top-start"
                                  data-field="table-column-details-tooltip"
                                >
                                  <Box>
                                    <AssignmentIcon
                                      data-testid={`more-details-${dataItem.threatId}`}
                                      onClick={() => {
                                        emailDetailDialogActions.onOpen(dataItem)
                                      }}
                                      className={classes.icon}
                                      data-action="email-details-dialog-open"
                                    />
                                  </Box>
                                </Tooltip>
                              </CellText>
                            )}
                          </Cell>
                        )}
                      />
                      <DataTableColumn
                        width={50}
                        field={spAttacksTableConfig.columns.REPORT}
                        {...spAttacksTableConfig.columnsConfig[spAttacksTableConfig.columns.REPORT as any]}
                        title=" "
                        cell={({ dataItem }: { dataItem: any }) => (
                          <Cell className={classes.cell} data-field="table-column-report">
                            <CellText>
                              {!dataItem.status.inProgress && !dataItem.markedAsFp && (
                                <Tooltip
                                  classes={{ tooltip: classes.tooltip }}
                                  title={formatMessage('report_false_positive')}
                                  placement="top-start"
                                  data-field="table-column-report-tooltip"
                                >
                                  <Box>
                                    <ReportIcon
                                      onClick={() => {
                                        reportFalsePositiveDialogActions.onOpen(
                                          dataItem.threatId,
                                          dataItem.confidenceLevel
                                        )
                                      }}
                                      className={classes.icon}
                                      data-action="report-false-positive-dialog-open"
                                    />
                                  </Box>
                                </Tooltip>
                              )}
                            </CellText>
                          </Cell>
                        )}
                      />
                    </DataTable>
                  </Grid>
                </Grid>
              )}
            </Paper>
          </Grid>
        </Grid>

        {card4Config.isMailboxesDialogOpened && <MailboxesDialog onClose={card4Config.onCloseMailboxesDialog} />}

        {emailDetailDialogActions.open && (
          <EmailDetailsDialog data={emailDetailDialogConfig} buttonText={formatMessage('button')} />
        )}

        {reportFalsePositiveDialogActions.open && (
          <ReportFalsePositiveDialog open onClose={reportFalsePositiveDialogActions.onClose} />
        )}
      </Grid>
    )
  }, [
    formatMessage,
    attackTypesFormatMessage,
    tableFormatMessage,
    classes,
    accountName,
    card1Config,
    card2Config,
    card3Config,
    card4Config,
    spAttacksTableConfig,
    attackSelectorConfig,
    emailDetailDialogActions,
    reportFalsePositiveDialogActions,
    emailDetailDialogConfig
  ])
}

export default RecentAttacksTab
