import createDmarcSourcesStore from 'sen/redux/features/dataTables/dmarcSources/createDmarcSourcesStore'

export const dmarcLowVolumesSourcesStore = createDmarcSourcesStore('LOW_VOLUME_SOURCES')

export {
  INITIAL_STATE,
  GRID_COLUMNS,
  COLUMNS_CONFIG
} from 'sen/redux/features/dataTables/dmarcSources/createDmarcSourcesStore'
export const { update, reset } = dmarcLowVolumesSourcesStore.actions
export default dmarcLowVolumesSourcesStore.reducer
