import { createSlice } from '@reduxjs/toolkit'

import insertToArray from 'global/lib/insertToArray'

import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'global/redux/toolkit/api'
import { Emails, EmailsResponse } from 'global/types/api/emailsType'
import { Geoip } from 'global/types/api/search'

import {
  getEmails,
  getMoreEmails,
  getForensicsBootstrapProgress,
  getGeoipAggregate
} from 'fir/redux/features/search/searchApiThunks'

export interface SearchState {
  bootstrapInProgress: boolean
  emails: Emails
  geoip: Geoip[]
  getBootstrapProgressApiStatus: ApiStatus
  getEmailsApiStatus: ApiStatus
  getGeoipApiStatus: ApiStatus
  getMoreEmailsApiStatus: ApiStatus
  skip: number
  startBootstrapApiStatus: ApiStatus
}

// Initial state
export const INITIAL_STATE: SearchState = {
  bootstrapInProgress: false,
  emails: {
    dslQuery: '',
    results: [],
    searchId: '',
    totalCount: 0
  },
  geoip: [],
  getBootstrapProgressApiStatus: inIdle,
  getEmailsApiStatus: inIdle,
  getGeoipApiStatus: inIdle,
  getMoreEmailsApiStatus: inIdle,
  skip: 0,
  startBootstrapApiStatus: inIdle
}

/* eslint-disable no-param-reassign */
export const searchSlice = createSlice({
  name: 'SEARCH',
  initialState: INITIAL_STATE,
  // reducers object creates an action and executes the reducer function
  reducers: {
    resetEmails: state => {
      state.emails = INITIAL_STATE.emails
      state.getEmailsApiStatus = INITIAL_STATE.getEmailsApiStatus
      state.getMoreEmailsApiStatus = INITIAL_STATE.getMoreEmailsApiStatus
    },
    resetGeoip: state => {
      state.geoip = INITIAL_STATE.geoip
      state.getGeoipApiStatus = INITIAL_STATE.getGeoipApiStatus
    },
    resetEmailsFailures: state => {
      state.getEmailsApiStatus = INITIAL_STATE.getEmailsApiStatus
      state.getMoreEmailsApiStatus = INITIAL_STATE.getMoreEmailsApiStatus
    },
    resetGeoipAggregateFailures: state => {
      state.getGeoipApiStatus = INITIAL_STATE.getGeoipApiStatus
    },
    reset: () => {
      return { ...INITIAL_STATE }
    }
  },

  // extraReducers do not create an action but executes the reducer function
  extraReducers: builder => {
    builder
      // getEmails
      .addCase(getEmails.pending, state => {
        state.getEmailsApiStatus = inProgress
      })
      .addCase(getEmails.fulfilled, (state, action) => {
        state.getEmailsApiStatus = successResponse
        state.emails = (action.payload as EmailsResponse).emails
        state.skip = (action.payload as EmailsResponse).skip
      })
      .addCase(getEmails.rejected, (state, action) => {
        state.getEmailsApiStatus = failedResponse(action.payload as string)
      })

      // getMoreEmails
      .addCase(getMoreEmails.pending, (state, action) => {
        state.getMoreEmailsApiStatus = inProgress
        if (action.meta.arg.resetResult) {
          state.emails.results = []
        }
      })
      .addCase(getMoreEmails.fulfilled, (state, action) => {
        state.getMoreEmailsApiStatus = successResponse
        state.emails = {
          ...state.emails,
          results: insertToArray(
            state.emails.results,
            (action.payload.emails || {}).results || [],
            action.payload.skip || 0
          )
        }
      })
      .addCase(getMoreEmails.rejected, (state, action) => {
        state.getMoreEmailsApiStatus = failedResponse(action.payload as string)
      })

      // getForensicsBootstrapProgress
      .addCase(getForensicsBootstrapProgress.pending, state => {
        state.getBootstrapProgressApiStatus = inProgress
      })
      .addCase(getForensicsBootstrapProgress.fulfilled, (state, action) => {
        state.bootstrapInProgress = action.payload
        state.getBootstrapProgressApiStatus = successResponse
      })
      .addCase(getForensicsBootstrapProgress.rejected, (state, action) => {
        state.getBootstrapProgressApiStatus = failedResponse(action.payload as string)
      })

      // getGeoipAggregate
      .addCase(getGeoipAggregate.pending, state => {
        state.getGeoipApiStatus = inProgress
      })
      .addCase(getGeoipAggregate.fulfilled, (state, action) => {
        state.getGeoipApiStatus = successResponse
        state.geoip = action.payload
      })
      .addCase(getGeoipAggregate.rejected, (state, action) => {
        state.getGeoipApiStatus = failedResponse(action.payload as string)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { resetEmails, resetGeoip, resetEmailsFailures, resetGeoipAggregateFailures, reset } = searchSlice.actions

export { getForensicsBootstrapProgress, getGeoipAggregate }

export default searchSlice.reducer
