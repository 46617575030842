import { cloneDeep } from 'lodash'
import { useEffect, useMemo } from 'react'

import colors from '@barracuda-internal/bds-core/dist/styles/colors'

import { MultiSeriesTooltip } from 'global/components/lib/charts/chartTooltips/MultiSeriesTooltip'
import { useFormatMessage } from 'global/lib/localization'
import { isPending } from 'global/redux/toolkit/api'

import { getWorkflowTopRunsStats } from 'fir/redux/features/stats/statsSlice'
import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'
import { WorkflowTopRunsStat } from 'fir/redux/types/Stats'
import { WorkflowDashboardProps } from 'fir/redux/types/Workflows'

export interface TopWorkflowRunsDashboardProps {
  topTriggeredWorkflowsConfig: WorkflowDashboardProps
  topWorkflowActionsConfig: WorkflowDashboardProps
}

const BASE_I18N_KEY = 'fir.app.automated_workflows'

export default function useTopWorkflowRunsDashboardLogic(): [TopWorkflowRunsDashboardProps] {
  const dispatch = useAppDispatch()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  // Redux Toolkit stores
  const { accessTokenId, automatedWorkflowStats } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id || ' ',
    automatedWorkflowStats: _stores.stats
  }))

  //  workflows state
  const { isAutomatedWorkflowsTopRunsStatsLoading } = {
    isAutomatedWorkflowsTopRunsStatsLoading: isPending(automatedWorkflowStats.automatedWorkflowsTopRunsStatsApiStatus)
  }

  // init
  useEffect(() => {
    dispatch(getWorkflowTopRunsStats({ accessTokenId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const topTriggeredWorkflowsConfig = useMemo(() => {
    // Need to deep clone the store data, Kendo is attempting to write to a read only object
    const clonedUserReportedUserStats = cloneDeep(automatedWorkflowStats.topWorkflowsStats) || []
    const labelSkip = []

    return {
      chartConfig: {
        chartStyle: { height: 250 },
        customTooltip: MultiSeriesTooltip,
        chartCategoryAxisItemProps: {
          labels: {
            color: colors.humpbackGray,
            content: (e: { value: string }) => (e.value.length > 20 ? `${e.value.substring(0, 20)}...` : e.value),
            font: '400 14px Roboto, sans-serif'
          },
          majorGridLines: { visible: false }
        },
        chartSeriesItems: [
          {
            chartData: Object.values(clonedUserReportedUserStats).slice(
              0,
              Object.values(clonedUserReportedUserStats).length < 5
                ? Object.values(clonedUserReportedUserStats).length
                : 5
            ),
            chartSeriesItemProps: {
              categoryField: 'workflowName',
              color: colors.barracudaBlue80,
              field: 'triggerTotalCount',
              line: { color: colors.barracudaBlue80, width: 2 },
              stack: true,
              name: formatMessage('charts.top_workflow_runs.tooltip_triggers'),
              visibleInLegend: false,
              gap: clonedUserReportedUserStats.length === 1 ? 10 : undefined,
              type: 'bar'
            },
            chartSeriesItemTooltipProps: {
              formatTitle: (category: string) => category
            }
          },
          {
            chartData: Object.values(clonedUserReportedUserStats).slice(
              0,
              Object.values(clonedUserReportedUserStats).length < 5
                ? Object.values(clonedUserReportedUserStats).length
                : 5
            ),
            chartSeriesItemProps: {
              categoryField: 'workflowName',
              color: colors.barracudaTeal80,
              field: 'conditionTotalCount',
              line: { color: colors.barracudaTeal80, width: 2 },
              name: formatMessage('charts.top_workflow_runs.tooltip_conditions'),
              visibleInLegend: false,
              type: 'bar'
            },
            chartSeriesItemTooltipProps: {
              formatTitle: (category: string) => category
            }
          },
          {
            chartData: Object.values(clonedUserReportedUserStats).slice(
              0,
              Object.values(clonedUserReportedUserStats).length < 5
                ? Object.values(clonedUserReportedUserStats).length
                : 5
            ),
            chartSeriesItemProps: {
              categoryField: 'workflowName',
              color: colors.starfishOrange,
              field: 'actionTotalCount',
              line: { color: colors.starfishOrange, width: 2 },
              name: formatMessage('charts.top_workflow_runs.tooltip_actions'),
              visibleInLegend: false,
              type: 'bar'
            },
            chartSeriesItemTooltipProps: {
              formatTitle: (category: string) => category
            }
          }
        ],
        chartValueAxisItemProps: {
          labels: {
            format: 'MMMM',
            font: '600 12px Roboto, sans-serif',
            color: colors.humpbackGray,
            step: labelSkip.length > 0 ? 0 : 5
          }
        }
      },
      chartWrapperConfig: {
        chartData: Object.values(clonedUserReportedUserStats).slice(
          0,
          Object.values(clonedUserReportedUserStats).length < 5 ? Object.values(clonedUserReportedUserStats).length : 5
        ),
        chartLoading: isAutomatedWorkflowsTopRunsStatsLoading,
        chartTitle: formatMessage('charts.top_workflow_runs.title'),
        noDataText: formatMessage('charts.top_workflow_runs.noData')
      }
    }
  }, [isAutomatedWorkflowsTopRunsStatsLoading, automatedWorkflowStats.topWorkflowsStats, formatMessage])

  const topWorkflowActionsConfig = useMemo(() => {
    const chartData = {} as any
    automatedWorkflowStats.topWorkflowsStats
      .filter((stat: WorkflowTopRunsStat) => Object.keys(stat.actions).length)
      .forEach((stat: WorkflowTopRunsStat) => {
        Object.keys(stat.actions).forEach((actionName: string) => {
          if (!(actionName in chartData)) {
            chartData[actionName] = {
              actionCount: stat.actions[actionName],
              actionName: formatMessage(`charts.top_workflow_actions.actions.${actionName}`)
            }
          } else {
            chartData[actionName].actionCount += stat.actions[actionName]
          }
        })
      })

    const labelSkip = []

    return {
      chartConfig: {
        chartCategoryAxisItemProps: {
          labels: {
            color: colors.humpbackGray,
            content: (e: { value: string }) => (e.value.length > 20 ? `${e.value.substring(0, 20)}...` : e.value),
            font: '400 14px Roboto, sans-serif'
          },
          majorGridLines: { visible: false }
        },
        chartData: Object.values(chartData).slice(
          0,
          Object.values(chartData).length < 5 ? Object.values(chartData).length : 5
        ),
        chartSeriesItemProps: {
          categoryField: 'actionName',
          color: colors.barracudaBlue80,
          field: 'actionCount',
          line: { color: colors.coralOrange, width: 2 },
          gap: Object.values(chartData).length === 1 ? 10 : undefined,
          type: 'bar'
        },
        chartSeriesItemTooltipProps: {
          formatTitle: (category: string) => category,
          text: formatMessage('charts.top_workflow_actions.tooltip')
        },
        showChartSeriesItemTooltip: true,
        chartStyle: { height: 250 },
        chartValueAxisItemProps: {
          labels: {
            format: 'MMMM',
            font: '600 12px Roboto, sans-serif',
            color: colors.humpbackGray,
            step: labelSkip.length > 0 ? 0 : 5
          }
        }
      },
      chartWrapperConfig: {
        chartData: Object.values(chartData).slice(
          0,
          Object.values(chartData).length < 5 ? Object.values(chartData).length : 5
        ),
        chartLoading: isAutomatedWorkflowsTopRunsStatsLoading,
        chartTitle: formatMessage('charts.top_workflow_actions.title'),
        noDataText: formatMessage('charts.top_workflow_actions.noData')
      }
    }
  }, [automatedWorkflowStats.topWorkflowsStats, isAutomatedWorkflowsTopRunsStatsLoading, formatMessage])

  return useMemo(
    () => [
      {
        topTriggeredWorkflowsConfig,
        topWorkflowActionsConfig
      }
    ],
    [topTriggeredWorkflowsConfig, topWorkflowActionsConfig]
  )
}
