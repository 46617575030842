import { createSlice } from '@reduxjs/toolkit'

import { ColumnsConfig, GridColumns } from 'global/types/dataTables/dataTables'
import { config } from 'global/lib/config'

interface IncidentsState {
  GRID_COLUMNS: GridColumns
  columnsConfig: ColumnsConfig
  page: { skip: number; take: number }
}

export const GRID_COLUMNS = {
  CREATED_ON: 'createdOn',
  CREATED_BY: 'createdBy',
  INCIDENTS: 'incidents',
  MESSAGES_RECEIVED: 'messagesReceived',
  CONTINUOUS_REMEDIATION: 'continuousRemediation',
  TAGS: 'labels',
  VIEW_INCIDENTS: 'viewIncidents'
}

export const COLUMNS_CONFIG = {
  [GRID_COLUMNS.CREATED_ON]: {
    orderIndex: 0,
    show: true,
    sortable: false
  },
  [GRID_COLUMNS.CREATED_BY]: {
    orderIndex: 1,
    show: true,
    sortable: false
  },
  [GRID_COLUMNS.INCIDENTS]: {
    orderIndex: 2,
    show: true,
    sortable: false
  },
  [GRID_COLUMNS.MESSAGES_RECEIVED]: {
    show: true,
    orderIndex: 3,
    sortable: false
  },
  [GRID_COLUMNS.CONTINUOUS_REMEDIATION]: {
    orderIndex: 4,
    show: true,
    sortable: false
  },
  [GRID_COLUMNS.TAGS]: {
    orderIndex: 5,
    show: true,
    sortable: false
  },
  [GRID_COLUMNS.VIEW_INCIDENTS]: {
    orderIndex: 6,
    show: true,
    sortable: false
  }
}

// Store
export const INITIAL_STATE: IncidentsState = {
  GRID_COLUMNS,
  columnsConfig: COLUMNS_CONFIG,
  page: { skip: 0, take: config.DATA_TABLE.FORENSICS.ITEMS_PER_PAGE }
}

export const incidentsSlice = createSlice({
  name: 'DATATABLE/INCIDENTS',
  initialState: INITIAL_STATE,
  reducers: {
    update: (state, action) => {
      return {
        ...state,
        ...action.payload?.config
      }
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  }
})

export const { update, reset } = incidentsSlice.actions

export default incidentsSlice.reducer
