import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => {
  return {
    radioButtonGroup: {
      '& [data-panel-description="true"]': {
        marginLeft: -theme.spacing(2)
      }
    },
    separator: {
      height: 12
    },
    footerText: {
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: '16px',
      fontStyle: 'italic'
    },
    settingsIcon: {
      verticalAlign: 'middle'
    }
  }
})
