import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from 'global/configs/theme/defaults'
import tableStyles, { TABLE_HEADER_HEIGHT, TABLE_FOOTER_HEIGHT } from 'global/components/lib/grid/tableStyles'

const EMPTY_TABLE_HEIGHT = 100
const CELL_HEIGHT = 45
const TABLE_MIN_HEIGHT = CELL_HEIGHT * 5 + TABLE_HEADER_HEIGHT + TABLE_FOOTER_HEIGHT

export default makeStyles(theme => {
  const defaultTableStyles = tableStyles(theme, CELL_HEIGHT, EMPTY_TABLE_HEIGHT)

  return {
    // table styles
    ...defaultTableStyles,
    tableWrapper: {
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
      paddingTop: 0
    },
    tableArea: {
      ...defaultTableStyles.tableArea,
      minHeight: TABLE_MIN_HEIGHT
    },
    flexibleTableArea: {
      ...defaultTableStyles.tableArea,
      marginBottom: theme.spacing(2)
    },
    reservedTableArea: {
      height: EMPTY_TABLE_HEIGHT
    },
    dataTable: {
      ...defaultTableStyles.dataTable,
      height: TABLE_MIN_HEIGHT,

      '& td': {
        lineHeight: `${theme.spacing(2.5)}px`
      }
    },
    emptyDataTable: {
      ...defaultTableStyles.emptyDataTable,
      padding: `0 ${theme.spacing(1)}px`
    },
    flexibleDataTable: {
      ...defaultTableStyles.dataTable,

      '& td': {
        lineHeight: `${theme.spacing(2.5)}px`
      }
    },
    successText: {
      ...defaultTableStyles.normalCell,
      color: COLORS.GREEN_400
    },
    failedText: {
      ...defaultTableStyles.normalCell,
      color: COLORS.RED_600
    },
    greyCell: {
      ...defaultTableStyles.cell,
      backgroundColor: COLORS.LIGHT_BACKGROUND
    },
    headerGreyCell: {
      backgroundColor: COLORS.LIGHT_BACKGROUND
    },
    headerAdditionalText: {
      fontSize: 12,
      textAlign: 'center',
      width: 450,
      float: 'right',
      lineHeight: '16px'
    }
  }
})
