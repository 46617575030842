import React from 'react'
import PropTypes from 'prop-types'

import { v4 as uuidv4 } from 'uuid'

import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress
} from '@barracuda-internal/bds-core'

import DEFAULTS from 'ets/configs/styles/defaults'

import styles from 'ets/components/lib/dialogs/deleteDialog/deleteDialogStyles'
import { useFormatMessage } from 'global/lib/localization'

const BASE_I18N_KEY = 'ets.app.dialogs.delete_report'

export interface DeleteDialogProps {
  toggleDialog: () => void
  isDialogOpened: boolean
  onDeleteScan: () => void
  isDeleteInProgress: boolean
}

export const DeleteDialog: React.FC<DeleteDialogProps> = ({
  isDialogOpened,
  toggleDialog,
  onDeleteScan,
  isDeleteInProgress
}) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  return (
    <div data-field="dialog-container">
      <Dialog
        style={{ zIndex: DEFAULTS.DIALOGS_ZINDEX }}
        classes={{ paper: classes.paper }}
        open={isDialogOpened}
        onClose={toggleDialog}
        data-field="dialog"
      >
        <DialogTitle disableTypography id="form-dialog-title" data-field="title">
          <Typography data-field="delete-report" className={classes.title}>
            {formatMessage('title')}
          </Typography>
        </DialogTitle>
        <DialogContent data-field="content" style={{ overflow: 'hidden' }}>
          <DialogContentText data-field="content-text" className={classes.description}>
            {formatMessage('description', { br: () => <br key={uuidv4()} /> })}
          </DialogContentText>
        </DialogContent>
        <DialogActions data-field="dialog-actions">
          <Button
            className={classes.secondary}
            variant="text"
            onClick={toggleDialog}
            color="primary"
            disabled={isDeleteInProgress}
            data-action="cancel"
          >
            {formatMessage('cancel')}
          </Button>
          <Button
            className={classes.primary}
            variant="contained"
            onClick={onDeleteScan}
            color="primary"
            disabled={isDeleteInProgress}
            data-action="delete"
          >
            {formatMessage('delete')}
          </Button>
          {isDeleteInProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
        </DialogActions>
      </Dialog>
    </div>
  )
}

DeleteDialog.propTypes = {
  isDialogOpened: PropTypes.bool.isRequired,
  onDeleteScan: PropTypes.func.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  isDeleteInProgress: PropTypes.bool.isRequired
}

export default DeleteDialog
