import React from 'react'
import PropTypes from 'prop-types'

import { v4 as uuidv4 } from 'uuid'

import { Card, Typography, Link, Grid } from '@barracuda-internal/bds-core'
import { ArrowDownward as ArrowDownwardIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'

import MaskLayer from 'global/components/lib/maskLayer/MaskLayer'

import styles from 'ets/components/lib/chartPanel/chartPanelStyles'
import { useFormatMessage } from 'global/lib/localization'

export interface ChartPanelProps {
  id?: string
  section: string
  showTotal?: boolean
  total?: number
  chart?: any
  intlTagHandlers?: any
  inProgress: boolean
  children?: any
  withSpacer?: boolean
  biggerChart?: boolean
  onDetailsClick: (e: any) => void
}

const BASE_I18N_KEY = 'ets.app.dashboard.overview'
const BASE_PROGRESS_I18N_KEY = 'ets.app.dashboard.in_progress'

const DEMO_TOTAL = 123456

const ChartPanel: React.FC<ChartPanelProps> = ({
  id = '',
  section,
  total,
  chart,
  inProgress,
  showTotal,
  children,
  withSpacer,
  biggerChart,
  onDetailsClick,
  intlTagHandlers
}) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatMessageProgress = useFormatMessage(BASE_PROGRESS_I18N_KEY)
  const classes = styles()

  return (
    <Grid className={classes.panel} data-chart-panel={id} item xs={6}>
      <Card className={classes.panelCard} elevation={1}>
        <Grid container direction="row">
          <Grid item xs={8}>
            <Typography className={classes.title}>{formatMessage(`${section}.title`)}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Grid container justifyContent="flex-end" direction="row">
              <Typography className={classes.details} onClick={onDetailsClick}>
                <Link href="#none">
                  {formatMessage('details')}
                  <ArrowDownwardIcon className={classes.arrowDown} />
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Typography className={classes.description}>
            {formatMessage(`${section}.description`, { br: () => <br key={uuidv4()} />, ...(intlTagHandlers || {}) })}
          </Typography>
        </Grid>
        <Grid className={classes.content} container direction="column">
          {showTotal && (
            <Grid container>
              <Typography className={classes.total}>
                {(inProgress ? DEMO_TOTAL : total || 0).toLocaleString()}
              </Typography>
            </Grid>
          )}
          {withSpacer && <div className={classes.spacer} />}
          {chart && (
            <Grid className={biggerChart ? classes.biggerChartArea : classes.chartArea} container direction="row">
              {chart}
            </Grid>
          )}
        </Grid>
        {children}
        {inProgress && <MaskLayer>{formatMessageProgress('title', { br: () => <br key={uuidv4()} /> })}</MaskLayer>}
      </Card>
    </Grid>
  )
}

ChartPanel.propTypes = {
  id: PropTypes.string,
  section: PropTypes.string.isRequired,
  showTotal: PropTypes.bool,
  total: PropTypes.number,
  chart: PropTypes.any,
  inProgress: PropTypes.bool.isRequired,
  children: PropTypes.any,
  withSpacer: PropTypes.bool,
  biggerChart: PropTypes.bool,
  onDetailsClick: PropTypes.func.isRequired,
  intlTagHandlers: PropTypes.any
}

ChartPanel.defaultProps = {
  id: undefined,
  total: undefined,
  chart: undefined,
  showTotal: true,
  children: null,
  withSpacer: false,
  biggerChart: false,
  intlTagHandlers: {}
}

export default ChartPanel
