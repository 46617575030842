import { useMemo, useCallback } from 'react'

import * as analyticsLib from 'global/lib/analytics/analyticsService'

import { useAppSelector } from 'sen/redux/toolkit/hooks'

export default function useDashboardHeaderLogic() {
  const { accessTokenId } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id || ''
  }))

  const sendTrackEventInfo = useCallback(
    (event: any) => {
      return analyticsLib.trackAppEvent(event, { accessToken: accessTokenId, url: window.location.href })
    },
    [accessTokenId]
  )

  return useMemo(() => {
    return [sendTrackEventInfo]
  }, [sendTrackEventInfo])
}
