import { createSlice } from '@reduxjs/toolkit'

import { analyticsMixpanelTrackEvent } from 'global/redux/features/analytics/analyticsApiThunks'
import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'global/redux/toolkit/api'

export interface AnalyticsState {
  trackedEvent: string
  analyticsMixpaneltrackEventApiStatus: ApiStatus
}

// initialState
export const INITIAL_STATE: AnalyticsState = {
  trackedEvent: '',
  analyticsMixpaneltrackEventApiStatus: inIdle
}

/* eslint-disable no-param-reassign */
export const analyticsSlice = createSlice({
  name: 'ANALYTICS',
  initialState: INITIAL_STATE,
  reducers: {
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(analyticsMixpanelTrackEvent.pending, (state, action) => {
        state.analyticsMixpaneltrackEventApiStatus = inProgress
        state.trackedEvent = action.meta.arg.eventName
      })
      .addCase(analyticsMixpanelTrackEvent.fulfilled, state => {
        state.analyticsMixpaneltrackEventApiStatus = successResponse
      })
      .addCase(analyticsMixpanelTrackEvent.rejected, (state, action) => {
        if (action.payload) {
          state.analyticsMixpaneltrackEventApiStatus = failedResponse(action.payload as string)
        }
      })
  }
})
/* eslint-enable no-param-reassign */

export const { reset } = analyticsSlice.actions

export { analyticsMixpanelTrackEvent }

export default analyticsSlice.reducer
