import React from 'react'

import { EpIncidentResponse } from '@barracuda-internal/bds-core/dist/Logos/Products'

import GlobalSignupComplete from 'global/components/features/signinSignup/signupComplete/SignupComplete'
import { config } from 'global/lib/config'

import routesConfig from 'fir/lib/routes/routesConfig'

export const SigninSignupComplete: React.FC = () => (
  <GlobalSignupComplete
    isFinalStep={false}
    productLogo={<EpIncidentResponse alt="Incident Response" />}
    routesConfig={routesConfig}
    gotoNextStep={() => {
      routesConfig.REMEDIATION.goto()
    }}
    scanType={config.SCAN_TYPES.FORENSICS}
  />
)

export default SigninSignupComplete
