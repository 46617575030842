import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  root: {
    alignContent: 'center',
    marginTop: theme.spacing(2)
  },
  text: {
    margin: 'auto',
    maxWidth: '65%',
    maxHeight: '100%',
    marginTop: theme.spacing(4),
    fontSize: 16,
    lineHeight: '20px'
  }
}))
