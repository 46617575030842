import { useCallback, useEffect, useMemo, useState } from 'react'

interface PaginationLogicParams {
  onPageChange?: (page: number) => void
  onItemsPerPageChange?: (itemsPerPage: number) => void
  skip: number
  take: number
  total: number
}

interface PaginationLogicProps {
  anchorEl: null | HTMLElement
  handleClickListItem: (event: React.MouseEvent<HTMLElement>) => void
  handleClose: () => void
  handleMenuItemClick: (itemsPerPageValue: number) => void
  handlePageChange: (event: React.ChangeEvent<unknown>, value: number) => void
  itemsChangePagination: string
  itemsPerPage: number
  page: number
  totalPage: number
}

export default function usePaginationLogic({
  onPageChange,
  onItemsPerPageChange,
  skip,
  take,
  total
}: PaginationLogicParams): PaginationLogicProps[] {
  const [page, setPage] = useState<number>(skip / take + 1)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [itemsPerPage, setItemsPerPage] = useState<number>(take)

  useEffect(() => {
    if (typeof onPageChange === 'function') {
      onPageChange(page)
    }
  }, [page, onPageChange])

  useEffect(() => {
    if (typeof onItemsPerPageChange === 'function') {
      onItemsPerPageChange(itemsPerPage)
    }
  }, [itemsPerPage, onItemsPerPageChange])

  const handleClickListItem = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleMenuItemClick = useCallback((itemsPerPageValue: number) => {
    setItemsPerPage(itemsPerPageValue)
    setPage(1)
    setAnchorEl(null)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const totalPage = useMemo(() => {
    return Math.ceil(total / itemsPerPage)
  }, [total, itemsPerPage])

  const itemsChangePagination = useMemo(() => {
    let itemsPerPageText
    if (page === totalPage) {
      itemsPerPageText = `${itemsPerPage * (page - 1) + 1} - ${total}`
    } else {
      itemsPerPageText = `${itemsPerPage * (page - 1) + 1} - ${itemsPerPage * page}`
    }

    return `${itemsPerPageText} of ${total} items`
  }, [page, itemsPerPage, total, totalPage])

  const handlePageChange = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
    event.preventDefault()
    setPage(value)
  }, [])

  return useMemo(
    () => [
      {
        anchorEl,
        handleClickListItem,
        handleClose,
        handleMenuItemClick,
        handlePageChange,
        itemsChangePagination,
        itemsPerPage,
        page,
        totalPage
      }
    ],
    [
      anchorEl,
      handleClickListItem,
      handleClose,
      handleMenuItemClick,
      handlePageChange,
      itemsChangePagination,
      itemsPerPage,
      page,
      totalPage
    ]
  )
}
