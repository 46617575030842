import { useMemo, useEffect } from 'react'

import { process } from '@progress/kendo-data-query'

import { isFailed, isPending } from 'global/redux/toolkit/api'
import { useAppDispatch, useAppSelector } from 'admin/redux/toolkit/hooks'

import { getRemediationStats, transformRemediationStats } from 'admin/redux/features/admin/adminSlice'
import { getIdentityStats } from 'admin/redux/features/insights/insightsSlice'
import { RemediationStats, TransformedRemediationStats } from 'admin/redux/types/remediation'
import { IdentityStats } from 'admin/redux/types/insights'
import { ColumnsConfig } from 'admin/redux/types/dataTables'

export interface TableConfig {
  tableData: {
    total: number
    data: TransformedRemediationStats[]
  }
  columns: { [key: string]: string }
  columnsConfig: ColumnsConfig
}

export default function useIncidentDialogLogic(
  accessTokenId: string
): [boolean, RemediationStats, IdentityStats, TableConfig, boolean] {
  const {
    getRemediationStatsInProgress,
    getRemediationStatsFailed,
    remediationStats,
    getIdentityStatsInProgress,
    getIdentityStatsFailed,
    identityStats,
    remediationStatsTable,
    transformedRemediationStats
  } = useAppSelector(_stores => ({
    getRemediationStatsInProgress: isPending(_stores.admin.getRemediationStatsApiStatus),
    getRemediationStatsFailed: isFailed(_stores.admin.getRemediationStatsApiStatus),
    remediationStats: _stores.admin.remediationStats,
    getIdentityStatsInProgress: isPending(_stores.insights.getIdentityStatsApiStatus),
    getIdentityStatsFailed: isFailed(_stores.insights.getIdentityStatsApiStatus),
    identityStats: _stores.insights.identityStats,
    remediationStatsTable: _stores.dataTables.remediationStats,
    transformedRemediationStats: _stores.admin.transformedRemediationStats
  }))

  const dispatch = useAppDispatch()

  // Init
  useEffect(() => {
    dispatch(getRemediationStats({ accessTokenId }))
    dispatch(getIdentityStats({ accessTokenId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Init
  useEffect(() => {
    if (remediationStats?.lastIncidentCreatedDate && identityStats) {
      dispatch(transformRemediationStats({ remediationStats, count: identityStats.count }))
    }
  }, [dispatch, remediationStats, identityStats])

  const tableData = useMemo(() => {
    const { skip, take } = remediationStatsTable
    const { data } = process(
      (transformedRemediationStats || []).map((report: TransformedRemediationStats) => ({
        ...(report && {
          ...report
        })
      })),
      { skip, take }
    )

    return {
      data,
      total: transformedRemediationStats.length
    }
  }, [remediationStatsTable, transformedRemediationStats])

  return useMemo(() => {
    const isLoading = getRemediationStatsInProgress || getIdentityStatsInProgress
    const error = getRemediationStatsFailed || getIdentityStatsFailed
    return [
      isLoading,
      remediationStats,
      identityStats,
      {
        tableData,
        columns: remediationStatsTable.GRID_COLUMNS,
        columnsConfig: remediationStatsTable.columnsConfig
      },
      error
    ]
  }, [
    getRemediationStatsInProgress,
    getRemediationStatsFailed,
    remediationStats,
    getIdentityStatsInProgress,
    getIdentityStatsFailed,
    identityStats,
    tableData,
    remediationStatsTable
  ])
}
