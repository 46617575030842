import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { validateApiError } from 'global/lib/api/restClient'
import { Geoip } from 'global/types/api/search'
import { updateCurrentSettings } from 'global/redux/features/accessToken/accessTokenSlice'

import apiRoutes from 'fir/lib/api/apiRoutes'
import { doGetEmails } from '../shared/sharedApiThunks'

export interface GetBootstrapProgress {
  accessTokenId: string
}

export interface GetGeoipAggregate {
  accessTokenId?: string
  timeframe: number
}

export interface StartBootstrap {
  accessTokenId: string
  template: string
  userEmail: string
}

export const getEmails = createAsyncThunk('SEARCH/getEmails', doGetEmails)
export const getMoreEmails = createAsyncThunk('SEARCH/getMoreEmails', doGetEmails)

/**
 * Retrieves all of the access token settings, updates the current settings in the Redux store, and returns the
 * forensics bootstrap progress.
 *
 * @param payload - The payload containing the access token ID.
 * @param rejectWithValue - The function to reject the async thunk with a value.
 * @param dispatch - The Redux dispatch function.
 * @returns A Promise that resolves to the progress of the forensics bootstrap process.
 */
export const getForensicsBootstrapProgress = createAsyncThunk(
  'SEARCH/getForensicsBootstrapProgress',
  async function doGetForensicsBootstrapProgress(payload: GetBootstrapProgress, { rejectWithValue, dispatch }) {
    const { accessTokenId } = payload

    try {
      const resp = await restClient(apiRoutes.GET_ACCESS_TOKEN_SETTINGS, {
        data: { accessTokenId }
      })

      dispatch(updateCurrentSettings(resp.data.settings))

      return resp.data.settings.forensicsBootstrapProgress
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getGeoipAggregate = createAsyncThunk('SEARCH/getGeoipAggregate', async function doGetGeoipAggregate(
  payload: GetGeoipAggregate,
  { rejectWithValue }
) {
  const { timeframe, accessTokenId = null } = payload

  try {
    const resp = await restClient(apiRoutes.GEOIP_AGGREGATE, {
      data: { accessTokenId, timeframe }
    })

    return resp.data.geoip as Geoip[]
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})
