import React from 'react'

import { Grid } from '@barracuda-internal/bds-core'

import styles from 'global/components/lib/radioButtonPanel/radioButtonPanelStyles'

export interface RadioButtonPanelProps {
  value: string
  isActive: boolean
  onClick: (panelId: string) => void
}

const RadioButtonPanelSeparator: React.FC = () => {
  const classes = styles()

  return (
    <Grid container alignItems="center" justifyContent="center">
      <div className={classes.separator} />
    </Grid>
  )
}

export default RadioButtonPanelSeparator
