import { v4 as uuidv4 } from 'uuid'
import { createSlice, PayloadAction, AnyAction } from '@reduxjs/toolkit'

import { logout, signin, signinOtp, saveSignupUser } from 'global/redux/features/auth/authSlice'
import { RouteMetadata } from 'global/lib/routes/routesConfig'

export type AlertId = string
export type AlertSeverity = 'error' | 'warning' | 'info' | 'success'
export type Message = string
export type RouteIds = string[]

export type Alert = [AlertId, AlertSeverity, Message, RouteIds]
export type Metadata = { [key: string]: string | number }

export interface ActivePath {
  id?: string
  url: string
  params: { [key: string]: string }
  metadata?: RouteMetadata
  isNavbarVisible?: boolean
  isPublicRoute: boolean
}

export interface AppState {
  metadata: Metadata
  justLoggedOut: boolean
  activePath: ActivePath
  alerts: Alert[]
}

export interface AddAlertAction {
  alert: Alert
}

export interface AddAlertTypeAction {
  message: Message
  routeIds: RouteIds
}

// initialState
export const INITIAL_STATE: AppState = {
  metadata: {},
  justLoggedOut: false,
  activePath: {
    url: window.location.pathname,
    params: {},
    isNavbarVisible: false,
    isPublicRoute: false
  },
  alerts: []
}

function isLoggedInAction(action: AnyAction) {
  return [signin.fulfilled.toString(), signinOtp.fulfilled.toString(), saveSignupUser.fulfilled.toString()].includes(
    action.type
  )
}

/* eslint-disable no-param-reassign */
export const appSlice = createSlice({
  name: 'APP',
  initialState: INITIAL_STATE,
  reducers: {
    setAppRelatedMetaData: {
      reducer: (state: AppState, action: PayloadAction<{ metadata: Metadata; isResetMetadata: boolean }>) => {
        state.metadata = {
          ...(!action.payload.isResetMetadata && { ...state.metadata }),
          ...action.payload.metadata
        }
      },
      prepare: (metadata: Metadata, isResetMetadata = false) => ({
        payload: { metadata, isResetMetadata }
      })
    },
    setActivePath: {
      reducer: (state: AppState, action: PayloadAction<{ activePath: ActivePath }>) => {
        state.activePath = action.payload.activePath
      },
      prepare: (activePath: ActivePath) => ({ payload: { activePath } })
    },
    updateActivePath: (
      state: AppState,
      action: PayloadAction<{ id: ActivePath['id']; url: ActivePath['url']; params: ActivePath['params'] }>
    ) => {
      state.activePath = {
        ...(state.activePath || {}),
        ...action.payload
      }
    },
    addSuccessAlert: {
      reducer: (state: AppState, action: PayloadAction<AddAlertAction>) => {
        state.alerts = [...state.alerts, action.payload.alert]
      },
      prepare: (message: Message, routeIds: RouteIds = []) => ({
        payload: { alert: [uuidv4(), 'success', message, routeIds] as Alert }
      })
    },
    addErrorAlert: {
      reducer: (state: AppState, action: PayloadAction<AddAlertAction>) => {
        state.alerts = [...state.alerts, action.payload.alert]
      },
      prepare: (message: Message, routeIds: RouteIds = []) => ({
        payload: { alert: [uuidv4(), 'error', message, routeIds] as Alert }
      })
    },
    addWarningAlert: {
      reducer: (state: AppState, action: PayloadAction<AddAlertAction>) => {
        state.alerts = [...state.alerts, action.payload.alert]
      },
      prepare: (message: Message, routeIds: RouteIds = []) => ({
        payload: { alert: [uuidv4(), 'warning', message, routeIds] as Alert }
      })
    },
    addInfoAlert: {
      reducer: (state: AppState, action: PayloadAction<AddAlertAction>) => {
        state.alerts = [...state.alerts, action.payload.alert]
      },
      prepare: (message: Message, routeIds: RouteIds = []) => ({
        payload: { alert: [uuidv4(), 'info', message, routeIds] as Alert }
      })
    },
    removeAlert: {
      reducer: (state: AppState, action: PayloadAction<{ alertId: AlertId }>) => {
        state.alerts = state.alerts.filter(alert => alert[0] !== action.payload.alertId)
      },
      prepare: (alertId: AlertId) => ({ payload: { alertId } })
    },
    removeRouteAlerts: {
      reducer: (state: AppState, action: PayloadAction<{ routeId: string }>) => {
        state.alerts = state.alerts.filter(
          alert => !alert[3].includes(action.payload.routeId || '') || !!alert[3].length
        )
      },
      prepare: (routeId: string) => ({ payload: { routeId } })
    },
    resetAlerts: (state: AppState) => {
      state.alerts = INITIAL_STATE.alerts
    },
    resetLoggedOut: (state: AppState) => {
      state.justLoggedOut = INITIAL_STATE.justLoggedOut
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(logout.fulfilled, (state: AppState) => {
        state.justLoggedOut = true
      })
      .addMatcher(isLoggedInAction, (state: AppState) => {
        state.justLoggedOut = false
      })
  }
})
/* eslint-enable no-param-reassign */

export const {
  setAppRelatedMetaData,
  setActivePath,
  updateActivePath,
  addSuccessAlert,
  addErrorAlert,
  addWarningAlert,
  addInfoAlert,
  removeAlert,
  removeRouteAlerts,
  resetAlerts,
  resetLoggedOut,
  reset
} = appSlice.actions

export default appSlice.reducer
