import { makeStyles, createStyles } from '@material-ui/core/styles'

import { COLORS } from 'ets/configs/styles/defaults'

export default makeStyles(theme => {
  const copytext = createStyles({
    defaults: {
      lineHeight: '32px',
      fontSize: 12,
      letterSpacing: 0,
      whiteSpace: 'nowrap',
      cursor: 'default',
      color: COLORS.BLUE
    }
  })

  return {
    wrapper: {
      width: 'auto',
      position: 'relative'
    },
    copyIcon: {
      marginRight: theme.spacing(1)
    },
    copyTextLeft: {
      ...copytext.defaults,
      marginRight: theme.spacing(2)
    },
    copyTextRight: {
      ...copytext.defaults,
      marginLeft: theme.spacing(2)
    },
    copyArrow: {
      '&:before': {
        backgroundColor: 'transparent'
      }
    }
  }
})
