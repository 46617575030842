import { makeStyles } from '@material-ui/core/styles'

const EMPTY_HEIGHT = 80

export default makeStyles(theme => ({
  dmarcDnsBlock: {
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    wordBreak: 'break-word',

    '&:first-of-type': {
      paddingTop: theme.spacing(2)
    },
    '&:last-of-type': {
      paddingBottom: theme.spacing(2)
    }
  },
  dmarcDnsRecord: {
    fontSize: 12,
    lineHeight: '14px'
  },
  dmarcDnsTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
    fontSize: 14
  },
  dmarcDnsDkimList: {
    margin: 0
  },
  dnsSection: {
    '&&&': {
      minHeight: EMPTY_HEIGHT,
      padding: 0
    }
  },
  actionButtons: {
    borderTop: `1px solid ${theme.palette.border}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    padding: `0 ${theme.spacing(1)}px`
  },
  progressWrapper: {
    alignItems: 'center',
    display: 'flex',
    height: EMPTY_HEIGHT,
    justifyContent: 'center'
  },
  recordListItem: {
    padding: `0 0 0 ${theme.spacing(1)}px`,

    '&::before': {
      content: '"•"',
      marginRight: theme.spacing(0.5)
    }
  }
}))
