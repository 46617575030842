import { useEffect, useMemo } from 'react'

import { process } from '@progress/kendo-data-query'

import { DmarcIp } from 'global/types/api/dmarc'
import { BDSGridPagerConfig } from 'global/types/dataTables/dataTables'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import * as T from 'sen/components/lib/dialogs/investigateDmarc/investigateDmarcDialogTypes'
import useDmarcAnalyticsLogic from 'sen/lib/dmarc/useDmarcAnalyticsLogic'
import {
  update as updateRDmarcFailedIpsTable,
  reset as resetDmarcFailedIpsTable
} from 'sen/redux/features/dataTables/dmarcIps/failedIps'
import {
  update as updateRDmarcPassedIpsTable,
  reset as resetDmarcPassedIpsTable
} from 'sen/redux/features/dataTables/dmarcIps/passedIps'

export default function useViewByIpsLogic({ dmarcSource, selectedTab }: T.UseViewLogicProps): [T.ViewsLogicConfig] {
  const { dmarcFailedIpsTable, dmarcPassedIpsTable } = useAppSelector(_stores => ({
    dmarcFailedIpsTable: _stores.dataTables.dmarcIps.failed,
    dmarcPassedIpsTable: _stores.dataTables.dmarcIps.passed
  }))
  const dispatch = useAppDispatch()
  const dmarcAnalyticsLogic = useDmarcAnalyticsLogic()

  // init
  useEffect(() => {
    return () => {
      dispatch(resetDmarcFailedIpsTable())
      dispatch(resetDmarcPassedIpsTable())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const failedIps = useMemo(() => {
    return dmarcSource.ips.filter(dmarcAnalyticsLogic.isDmarcFailed) || []
  }, [dmarcSource, dmarcAnalyticsLogic])

  const passedIps = useMemo(() => {
    return dmarcSource.ips.filter(dmarcAnalyticsLogic.isDmarcPassed) || []
  }, [dmarcSource, dmarcAnalyticsLogic])

  const isFail = useMemo(() => {
    return selectedTab === T.Tabs.fail
  }, [selectedTab])

  const failedIpsTableData = useMemo(() => {
    const { skip, take } = dmarcFailedIpsTable

    const { data } = process(
      failedIps.map((report: DmarcIp) => ({
        ...(report && {
          ...report
        })
      })),
      { skip, take }
    )

    return {
      data,
      total: failedIps.length || 0
    }
  }, [failedIps, dmarcFailedIpsTable])

  const failedIpsPageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = dmarcFailedIpsTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: failedIpsTableData.total,
      onPageChange: (e: any) => {
        dispatch(updateRDmarcFailedIpsTable(e.page))
      }
    }
  }, [dmarcFailedIpsTable, failedIpsTableData, dispatch])

  const passedIpsTableData = useMemo(() => {
    const { skip, take } = dmarcPassedIpsTable

    const { data } = process(
      passedIps.map((report: DmarcIp) => ({
        ...(report && {
          ...report
        })
      })),
      { skip, take }
    )

    return {
      data: data.filter(report => report.ip),
      total: passedIps.length || 0
    }
  }, [passedIps, dmarcPassedIpsTable])

  const passedIpsPageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = dmarcPassedIpsTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: passedIpsTableData.total,
      onPageChange: (e: any) => {
        dispatch(updateRDmarcPassedIpsTable(e.page))
      }
    }
  }, [dmarcPassedIpsTable, passedIpsTableData, dispatch])

  const tableConfig = useMemo(() => {
    if (isFail) {
      return {
        isLoaded: true,
        inProgress: false,
        tableData: failedIpsTableData,
        pageConfig: failedIpsPageConfig,
        columns: dmarcFailedIpsTable.GRID_COLUMNS,
        columnsConfig: dmarcFailedIpsTable.columnsConfig,
        isFlexibleTable: failedIpsTableData.total < dmarcFailedIpsTable.ITEMS_PER_PAGE
      }
    }

    return {
      isLoaded: true,
      inProgress: false,
      tableData: passedIpsTableData,
      pageConfig: passedIpsPageConfig,
      columns: dmarcPassedIpsTable.GRID_COLUMNS,
      columnsConfig: dmarcPassedIpsTable.columnsConfig,
      isFlexibleTable: passedIpsTableData.total < dmarcPassedIpsTable.ITEMS_PER_PAGE
    }
  }, [
    isFail,
    failedIpsTableData,
    passedIpsTableData,
    failedIpsPageConfig,
    passedIpsPageConfig,
    dmarcFailedIpsTable,
    dmarcPassedIpsTable
  ])

  return useMemo(() => {
    return [
      {
        isFail,
        tableConfig
      }
    ]
  }, [tableConfig, isFail])
}
