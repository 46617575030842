import { makeStyles } from '@material-ui/core/styles'
import IMAGES from 'ets/configs/theme/images.config'
import { navbarHeight } from 'global/components/lib/layout/navbar/navbarStyles'

export default makeStyles(() => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      backgroundImage: `url(${IMAGES.loginBackground})`,
      backgroundSize: 'cover',
      marginTop: -navbarHeight,
      paddingTop: 90,
      alignItems: 'center',
      backgroundPosition: 'center center'
    },
    logoContainer: {
      marginBottom: 20,
      textAlign: 'center'
    },
    card: {
      maxWidth: 922,
      paddingTop: 32,
      paddingBottom: 32
    },
    small: {
      maxWidth: 617
    },
    pageTitle: {
      marginBottom: 20
    },
    btnContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 20
    },
    image: {
      minWidth: 72,
      minHeight: 72,
      marginLeft: 50
    },
    loader: {
      marginTop: -32,
      marginBottom: 25,
      marginLeft: -25,
      marginRight: -25
    },
    alert: {
      position: 'fixed',
      top: 0,
      right: 0,
      left: 0
    }
  }
})
