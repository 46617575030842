import { makeStyles } from '@material-ui/core/styles'

import dialogStyles from 'ets/configs/styles/dialogStyles'
import { COLORS } from 'ets/configs/styles/defaults'

export default makeStyles(theme => {
  const defaultDialogStyles = dialogStyles(theme)
  const rectangle = {
    display: 'flex',
    height: 109,
    width: '100%',
    backgroundColor: COLORS.BACKGROUND,
    borderRadius: 4
  }

  return {
    activeRectangle: {
      ...rectangle,
      border: `2px solid ${COLORS.BLUE}`,
      boxShadow: '0 2px 5px 0 rgba(0,0,0,0.26)'
    },
    inactiveRectangle: {
      ...rectangle,
      border: `2px solid ${COLORS.LIGHT_GRAY}`
    },
    radioButtonWrapper: {
      paddingLeft: theme.spacing(2)
    },
    descriptionWrapper: {
      paddingLeft: theme.spacing(1)
    },
    text: {
      ...defaultDialogStyles.text
    },
    activeText: {
      ...defaultDialogStyles.text,
      fontWeight: 'bold'
    },
    radioText: {
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: '20px'
    },
    separator: {
      height: theme.spacing(1.5)
    }
  }
})
