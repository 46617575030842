import React, { useMemo } from 'react'

import { Button, Link, TextField, Typography, Grid, Alert } from '@barracuda-internal/bds-core'
import FormControl from '@material-ui/core/FormControl'

import { config } from 'global/lib/config'
import YesNoSwitch from 'global/components/lib/form/YesNoSwitch'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import { useFormatMessage } from 'global/lib/localization'

import styles, { potentialIncidentsSettingsStyles } from '../SettingsStyles'
import useSiemSettingsLogic, { UseSiemSettingsParams } from './useSiemSettingsLogic'

const BASE_I18N_KEY = 'fir.app.settings_dialog'

type SiemSettingsProps = UseSiemSettingsParams

const SiemSettings: React.FC<SiemSettingsProps> = () => {
  const baseClasses = styles()
  const classes = potentialIncidentsSettingsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [siemSettingsLogic] = useSiemSettingsLogic()

  return useMemo(() => {
    const {
      formValues,
      handleOnSave,
      handleOnTest,
      isInvalidHost,
      isInvalidPort,
      isPageInProgress,
      isSaveButtonDisabled,
      isTestButtonDisabled,
      onFormChange,
      onSaveFailed,
      onSaveSuccess,
      onTestFailed,
      onTestSuccess
    } = siemSettingsLogic
    return (
      <div data-field="wrapper" className={baseClasses.wrapper}>
        <div>
          <Typography data-field="header" variant="h5">
            {formatMessage('siem.labels.title')}
          </Typography>
          <Typography variant="subtitle2">
            <span className={`${baseClasses.opacity}`}>{formatMessage('siem.labels.subtitle')}</span>{' '}
            <Link target="_blank" href={config.LINKS.CAMPUS_SYSLOG}>
              {formatMessage('learn_more')}...
            </Link>
          </Typography>
          <Typography variant="subtitle2" className={`${baseClasses.opacity}`}>
            {formatMessage('siem.labels.note')}
          </Typography>
        </div>
        <br />
        {isPageInProgress && <LinearProgress />}
        {onSaveSuccess && (
          <Alert className={baseClasses.alert} variant="filled" severity="success">
            {formatMessage('siem.alerts.save_success')}
          </Alert>
        )}
        {onSaveFailed && (
          <Alert className={baseClasses.alert} variant="filled" severity="error">
            {formatMessage('siem.alerts.save_error')}
          </Alert>
        )}
        {onTestSuccess && (
          <Alert className={baseClasses.alert} variant="filled" severity="success">
            {formatMessage('siem.alerts.test_success')}
          </Alert>
        )}
        {onTestFailed && (
          <Alert className={baseClasses.alert} variant="filled" severity="error">
            {formatMessage('siem.alerts.test_error')}
          </Alert>
        )}
        <FormControl
          data-field="root-form"
          component="fieldset"
          className={`${classes.formControl} ${isPageInProgress ? baseClasses.disabled : ''}`}
        >
          <Grid data-field="root-grid" component="div" container spacing={2}>
            <Grid component="label" className={classes.label} xs={5} item>
              <Typography variant="body1">{formatMessage('siem.labels.activate')}</Typography>
            </Grid>
            <Grid data-field="syslog-integration-grid" xs={7} item>
              <YesNoSwitch checked={formValues.isActive} onChange={onFormChange('isActive', true)} />
            </Grid>
            <Grid data-field="syslog-host-grid" xs={12} item>
              <TextField
                data-field="syslog-host-field"
                className={`${classes.formLine} ${classes.mdTextField}`}
                value={formValues.syslogHost}
                onChange={onFormChange('syslogHost')}
                error={isInvalidHost}
                disabled={!formValues.isActive}
                label={formatMessage('siem.labels.host')}
              />
            </Grid>
            <Grid data-field="syslog-port-grid" xs={12} item>
              <TextField
                data-field="syslog-port-field"
                className={`${classes.formLine} ${classes.smTextField}`}
                value={formValues.syslogPort}
                onChange={onFormChange('syslogPort')}
                error={isInvalidPort}
                disabled={!formValues.isActive}
                label={formatMessage('siem.labels.port')}
              />
            </Grid>
            <Grid data-field="dialog-buttons-grid" xs={6} item>
              <div className={baseClasses.subDialogButtons}>
                <Button
                  data-field="siem-test-button"
                  onClick={handleOnTest}
                  color="primary"
                  variant="outlined"
                  disabled={isTestButtonDisabled}
                >
                  {formatMessage('siem.buttons.test')}
                </Button>
                <Button
                  data-field="siem-save-button"
                  onClick={handleOnSave}
                  color="primary"
                  variant="contained"
                  disabled={isSaveButtonDisabled}
                >
                  {formatMessage('siem.buttons.save')}
                </Button>
              </div>
            </Grid>
          </Grid>
        </FormControl>
      </div>
    )
  }, [baseClasses, classes, formatMessage, siemSettingsLogic])
}

export default SiemSettings
