import React from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@barracuda-internal/bds-core'

import styles from './breadcrumbsStyles'

export interface BreadcrumbsProps {
  list: BreadcrumbList[]
}

export interface BreadcrumbList {
  key?: string
  jsx?: JSX.Element
  onClick?: () => void
  text: string
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ list }) => {
  const classes = styles()

  return (
    <>
      {list.map((breadcrumb, idx, all) => {
        const attributes = {
          ...(breadcrumb.onClick && { onClick: breadcrumb.onClick }),
          className: classes[breadcrumb.onClick ? 'breadcrumbLink' : 'breadcrumb']
        }

        return (
          <React.Fragment key={breadcrumb.key || breadcrumb.text || idx}>
            <Typography {...attributes} variant="subtitle1">
              {breadcrumb.jsx || breadcrumb.text}
            </Typography>
            {idx !== all.length - 1 && (
              <Typography
                key={`${breadcrumb.key || breadcrumb.text || idx}-arrow`}
                className={classes.breadcrumb}
                variant="subtitle1"
              >
                &nbsp;{'>'}&nbsp;
              </Typography>
            )}
          </React.Fragment>
        )
      })}
    </>
  )
}

Breadcrumbs.propTypes = {
  list: PropTypes.array.isRequired
}

export default Breadcrumbs
