import { createAsyncThunk } from '@reduxjs/toolkit'

import { Workflow, WorkflowBlueprints, WorkflowRuns, Workflows } from 'fir/redux/types/Workflows'
import { RootState } from 'fir/redux/toolkit/store'
import apiRoutes from 'fir/lib/api/apiRoutes'

import restClient, { ResponseGenerator, validateApiError } from 'global/lib/api/restClient'
import { config as globalConfig } from 'global/lib/config'

export interface GetWorkflowsResponse {
  workflows: Workflows
  skip: number
}

export interface GetWorkflowResponse {
  workflow: Workflow
}

export interface GetWorkflowBlueprintsResponse {
  workflowBlueprints: WorkflowBlueprints
}

export interface GetWorkflowRunsResponse {
  workflowRuns: WorkflowRuns
  skip: number
}

export async function doGetWorkflows(payload: { accessTokenId: string; config: any }, { rejectWithValue }: any) {
  const { accessTokenId, config } = payload
  try {
    const resp: ResponseGenerator = await restClient(apiRoutes.GET_WORKFLOWS, {
      data: {
        accessTokenId,
        query: config.query
      }
    })
    const respData = {
      workflows: {
        data: resp.data.workflows.content,
        total: resp.data.workflows.totalElements
      },
      skip: (config.query.page ? config.query.page - 1 : 0) * config.query.limit
    }
    return respData
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
}

export async function doGetWorkflowRuns(
  payload: { accessTokenId: string; config: any; uuid: string },
  { rejectWithValue }: any
) {
  const { accessTokenId, config, uuid } = payload

  try {
    const resp: ResponseGenerator = await restClient(apiRoutes.GET_WORKFLOW_RUNS, {
      data: {
        accessTokenId,
        query: config.query,
        uuid
      }
    })
    const respData = {
      workflowRuns: {
        runs: resp.data.content,
        total: resp.data.totalElements
      },
      skip: (config.query.page ? config.query.page - 1 : 0) * config.query.limit
    }
    return respData
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
}

export const getWorkflows = createAsyncThunk('WORKFLOWS/getWorkflows', doGetWorkflows)
export const getMoreWorkflows = createAsyncThunk('WORKFLOWS/getMoreWorkflows', doGetWorkflows)
export const getWorkflowRuns = createAsyncThunk('WORKFLOWS/getWorkflowRuns', doGetWorkflowRuns)
export const getMoreWorkflowRuns = createAsyncThunk('WORKFLOWS/getMoreWorkflowRuns', doGetWorkflowRuns)

export const createUpdateWorkflow = createAsyncThunk(
  'WORKFLOWS/createUpdateWorkflow',
  async function doCreateUpdateWorkflow(
    payload: { accessTokenId: string; workflow: Workflow },
    { getState, rejectWithValue }
  ) {
    const { accessTokenId, workflow } = payload
    const state = getState() as RootState

    try {
      // show demo error if using demo token
      if (state.user.isFirDemoUser) {
        return rejectWithValue(validateApiError({ data: { error: globalConfig.API_ERROR.DEMO_ERROR.FORENSICS } }))
      }
      await restClient(apiRoutes.CREATE_UPDATE_WORKFLOW, {
        data: {
          accessTokenId,
          workflow
        }
      })
      return ''
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getWorkflow = createAsyncThunk('WORKFLOWS/getWorkflow', async function doGetWorkflow(
  payload: { accessTokenId: string; uuid: string },
  { rejectWithValue }
) {
  const { accessTokenId, uuid } = payload

  try {
    const resp: ResponseGenerator = await restClient(apiRoutes.GET_WORKFLOW, {
      data: {
        accessTokenId,
        uuid
      }
    })
    return { workflow: resp.data.workflow }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getWorkflowBlueprints = createAsyncThunk(
  'WORKFLOWS/getWorkflowBlueprints',
  async function doGetWorkFlowBlueprints(payload: { accessTokenId: string }, { rejectWithValue }) {
    const { accessTokenId } = payload

    try {
      const resp: ResponseGenerator = await restClient(apiRoutes.GET_WORKFLOW_BLUEPRINTS, {
        data: {
          accessTokenId
        }
      })
      return { workflowBlueprints: { data: resp.data.workflowBlueprints } }
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const toggleWorkflowStatus = createAsyncThunk(
  'WORKFLOWS/toggleWorkflowStatus',
  async function doToggleWorkflowStatus(
    payload: { workflowUUID: string; workflowStatus: string },
    { rejectWithValue }
  ) {
    const { workflowUUID, workflowStatus } = payload

    try {
      await restClient(apiRoutes.TOGGLE_WORKFLOW_STATUS, {
        data: {
          workflowUUID,
          workflowStatus
        }
      })
      return ''
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)
