import React, { useMemo } from 'react'

import { Page, View, Text, Image, StyleSheet } from '@react-pdf/renderer'
import { snakeCase } from 'lodash'

import IMAGES from 'global/configs/theme/images.config'

import { COLORS } from 'ets/configs/styles/defaults'
import baseStyles from 'ets/components/lib/PDFReport/pdfReportStyles'
import PDFFooter, { PDFFooterProps } from 'ets/components/lib/PDFReport/PDFFooter'

export interface Domain {
  name: string
  dmarcImage: string
  dmarcState: string
  policy: string
  dmarc: {
    policy: string
  }
}

export interface Page5DomainsConfig {
  topCount: string
  domains: Domain[]
}

export interface Page5DomainsProps {
  pdfConfig: Page5DomainsConfig
  intl: any
  footerConfig: PDFFooterProps
}

const DOMAINS_TABLE_WIDTHS = ['3%', '28%', '69%']
const TABLE_CELL_HEIGHT = 57

const styles = StyleSheet.create({
  ...baseStyles,
  description: {
    ...baseStyles.darkText,
    marginTop: 10,
    marginBottom: 5,
    fontSize: 9,
    lineHeight: 1.5
  },
  subtitle: {
    ...baseStyles.subtitle,
    marginTop: 10
  },
  tableheaderRow: baseStyles.tableheaderRow,
  countColumnHeader: {
    ...baseStyles.tableHeaderText,
    width: DOMAINS_TABLE_WIDTHS[0]
  },
  domainColumnHeader: {
    ...baseStyles.tableHeaderText,
    width: DOMAINS_TABLE_WIDTHS[1]
  },
  dmarcStatusColumnHeader: {
    ...baseStyles.tableHeaderText,
    width: DOMAINS_TABLE_WIDTHS[2]
  },
  tableRow: {
    ...baseStyles.tableRow,
    height: TABLE_CELL_HEIGHT
  },
  countColumn: {
    ...baseStyles.tableText,
    color: COLORS.PDF.LIGHT_GRAY,
    width: DOMAINS_TABLE_WIDTHS[0]
  },
  domainColumn: {
    ...baseStyles.tableText,
    width: DOMAINS_TABLE_WIDTHS[1]
  },
  dmarcStatusColumn: {
    ...baseStyles.tableText,
    width: DOMAINS_TABLE_WIDTHS[2],
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  dmarcStatusImage: {
    width: 15,
    marginRight: 5
  },
  dmarcStatusDescription: {
    ...baseStyles.cellText,
    marginRight: 20,
    maxLines: 3
  },
  boldCellText: baseStyles.boldCellText,
  cellText: baseStyles.cellText
})

const BASE_I18N_KEY = 'ets.app.pdf.domains'

const Page5Domains: React.FC<Page5DomainsProps> = ({ pdfConfig, footerConfig, intl }) => {
  return useMemo(() => {
    return (
      <Page size="A4" style={styles.page}>
        <Text style={styles.title}>{intl.formatMessage({ id: `${BASE_I18N_KEY}.title` })}</Text>
        <Text style={styles.description}>{intl.formatMessage({ id: `${BASE_I18N_KEY}.description` })}</Text>
        <Text style={styles.subtitle}>
          {intl.formatMessage({ id: `${BASE_I18N_KEY}.subtitle` }, { count: pdfConfig.topCount })}
        </Text>
        <View style={styles.tableheaderRow}>
          <Text style={styles.countColumnHeader} />
          {['domain', 'dmarcStatus'].map((columnType: string) => (
            <Text key={columnType} style={styles[`${columnType}ColumnHeader`]}>
              {intl.formatMessage({ id: `${BASE_I18N_KEY}.table.${snakeCase(columnType)}` })}
            </Text>
          ))}
        </View>
        {pdfConfig.domains?.length ? (
          <View style={styles.tableView}>
            {pdfConfig.domains.map((domain: Domain, idx: number) => (
              <View key={domain.name} style={styles.tableRow}>
                <Text style={styles.countColumn}>{idx + 1}</Text>
                <Text style={styles.domainColumn}>{domain.name}</Text>
                <View style={styles.dmarcStatusColumn}>
                  <Image style={styles.dmarcStatusImage} src={(IMAGES as any)[domain.dmarcImage]} />
                  <View>
                    <Text style={styles.boldCellText}>
                      {intl.formatMessage(
                        { id: `${BASE_I18N_KEY}.dmarc_states.${domain.dmarcState}.name` },
                        {
                          policy:
                            (domain.dmarcState === 'protected' &&
                              intl.formatMessage({ id: `${BASE_I18N_KEY}.${domain.policy}` })) ||
                            ''
                        }
                      )}
                    </Text>
                    <Text style={styles.dmarcStatusDescription}>
                      {intl.formatMessage({ id: `${BASE_I18N_KEY}.dmarc_states.${domain.dmarcState}.description` })}
                    </Text>
                  </View>
                </View>
              </View>
            ))}
          </View>
        ) : (
          <View style={styles.emptyTableWrap}>
            <Text style={styles.emptyTableText}>{intl.formatMessage({ id: `${BASE_I18N_KEY}.empty_table` })}</Text>
          </View>
        )}

        <PDFFooter page={4} intl={intl} {...footerConfig} />
      </Page>
    )
  }, [pdfConfig, footerConfig, intl])
}

export default Page5Domains
