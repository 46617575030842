import { makeStyles } from '@material-ui/core/styles'

import colors from '@barracuda-internal/bds-core/dist/styles/colors'

export default makeStyles(theme => {
  return {
    baseStyle: {
      backgroundColor: colors.deepOcean025,
      margin: theme.spacing(0, 1, 1, 0),
      maxWidth: '300px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    highlight: {
      backgroundColor: colors.red050,
      color: colors.red600
    },
    statisticsHeaderTitle: {
      fontSize: 16,
      fontWeight: 500,
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1)
    }
  }
})
