import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

export function makeSigninStyles(theme: Theme) {
  return createStyles({
    buttons: {
      width: '50%',
      margin: 'auto',
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(1)
    },
    emailField: {
      width: '100%'
    },
    firlogoWrapper: {
      position: 'relative',
      width: '100%',
      padding: theme.spacing(4),
      paddingTop: theme.spacing(14),
      marginBottom: '-40px'
    },
    firLogo: {
      display: 'block',
      width: 400,
      margin: 'auto'
    },
    formError: {
      color: theme.colors.statusCritical,
      fontSize: 12,
      fontWeight: 400,
      marginTop: 5
    },
    footer: {
      color: theme.colors.barracudaBlack50,
      margin: theme.spacing(1.25, 5, 0)
    },
    headerText: {
      position: 'relative',
      width: '100%',
      textAlign: 'center',
      margin: theme.spacing(1, 0, 5),
      verticalAlign: 'center'
    },
    inputs: {
      width: '70%',
      margin: 'auto'
    },
    linearWrapper: {
      position: 'relative',
      width: '450px',
      margin: 'auto'
    },
    link: {
      cursor: 'pointer'
    },
    links: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '50%',
      margin: 'auto'
    },
    loginLogo: {
      width: 135,
      margin: theme.spacing(2.5)
    },
    passwordField: {
      marginTop: theme.spacing(5),
      width: '100%'
    },
    root: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundPosition: 'center center',
      backgroundSize: 'cover'
    },
    rootWhite: {
      backgroundColor: '#FFF'
    },
    signinAlert: {
      lineHeight: '14px',
      marginBottom: '34px'
    },
    signinButton: {
      width: '100%'
    },
    wrapper: {
      position: 'relative',
      height: 'auto',
      width: '450px',
      margin: 'auto',
      padding: theme.spacing(1)
    }
  })
}

export default makeStyles(theme => makeSigninStyles(theme))
