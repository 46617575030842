import React from 'react'
import { Typography } from '@barracuda-internal/bds-core'
import PropTypes from 'prop-types'
import styles from 'global/components/lib/layout/navbar/navbarStyles'

export interface NavbarLogoTextProps {
  logoText: string
}

const NavbarLogoText: React.FC<NavbarLogoTextProps> = ({ logoText }) => {
  const classes = styles()

  return (
    <Typography variant="h6" className={classes.navbarLogoText}>
      {logoText}
    </Typography>
  )
}

NavbarLogoText.propTypes = {
  logoText: PropTypes.string.isRequired
}

export default NavbarLogoText
