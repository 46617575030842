import React, { useMemo } from 'react'

import { BarChart } from '@barracuda-internal/bds-core'
import ChartWrapper from '@barracuda-internal/bds-core/dist/ChartWrapper/ChartWrapper'

import { WorkflowDashboardProps } from 'fir/redux/types/Workflows'

const TopWorkflowActionsDashboard: React.FC<WorkflowDashboardProps> = props => {
  return useMemo(
    () => (
      <ChartWrapper chartWrapper={{ ...props.chartWrapperConfig }}>
        <BarChart barChart={{ ...props.chartConfig }} />
      </ChartWrapper>
    ),
    [props.chartConfig, props.chartWrapperConfig]
  )
}

export default TopWorkflowActionsDashboard
