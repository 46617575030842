import {
  Incident,
  IncidentClick,
  IncidentDetails,
  IncidentEmail,
  IncidentTag,
  IncidentUser
} from 'global/types/api/remediation'

export const INCIDENT_EMAIL_DIRECTION = {
  INCOMING: 'I',
  OUTGOING: 'O'
}

export const INCIDENT_EMAIL_STATUS = {
  FOUND: 'FO',
  IN_PROGRESS: 'IP',
  QUARANTINED: 'QU',
  DELETED: 'DE',
  NOT_FOUND: 'NF',
  ERROR: 'ER',
  NOTIFIED: 'NO'
}

export const INCIDENT_USER_STATUS = {
  YES: 'Y',
  NO: 'N',
  UNKNOWN: 'U',
  OFF: 'Off',
  CONTINUOUS_REMEDIATION: 'Continuous Remediation'
}

export const TAG_VALIDATOR = /^[a-zA-Z\d\s]{1,100}$/

export const TASK_STATUS = {
  NOT_STARTED: 'Not Started',
  IN_PROGRESS: 'In Progress',
  COMPLETE: 'Completed'
}

export interface RemediationConfig {
  query: {
    filter?: string[]
    order?: string
    limit: number
    page: number
  }
}

export interface CreateIncidentTagsApiConfig {
  accessTokenId: string
  incidentId: string
  tags: IncidentTag[] | string[]
}

export interface DeleteIncidentTagApiConfig extends CreateIncidentTagsApiConfig {
  tagId: number
}

export interface RemediationApiConfig extends RemediationConfig {
  accessTokenId: string
}

export interface EmailsByIncidentApiConfig {
  accessTokenId: string
  config: RemediationConfig
  incidentId: string
  distinctRecipient?: boolean
  resetResults?: boolean
}

export interface GetIncidentApiConfig {
  accessTokenId: string
  config: RemediationConfig
  resetResults?: boolean
}

export interface GetIncidentApiResponse {
  incidents: Incidents
  skip: number
}

export interface EmailsByIncidentApiResponse {
  emailsByIncident: IncidentEmails
  skip: number
}

export interface GetIncidentClickedLinksApiResponse {
  clickedLinksByIncident: IncidentClicks
  skip: number
}

export interface DeleteExistingEmailsApiConfig {
  accessTokenId: string
  incidentId: string
}

export interface IncidentDetailsResponse {
  incidentDetails: IncidentDetails
}

export interface EssAccount {
  [key: string]: any
}

export interface NewIncident {
  [key: string]: any
}

export interface ForensicsGetEssAccount {
  accessTokenId?: string
  config?: string[]
}

export interface IncidentClicks {
  data: IncidentClick[]
  totalCount: number
}

export interface IncidentEmails {
  data: IncidentEmail[]
  totalCount: number
}

export interface IncidentUsers {
  data: IncidentUser[]
  totalCount: number
}

export interface GetIncidentUserDetailsApiResponse {
  userDetailsByIncident: IncidentUsers
  skip: number
}

export interface Incidents {
  data: Incident[]
  totalCount: number
}
