import React, { useMemo } from 'react'

import { Chip, Grid, Tooltip, Typography } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'global/lib/localization'

import useStatisticsFieldLogic, { UseStatisticsFieldParams } from './useStatisticsFieldLogic'
import styles from './statisticsFieldStyles'

const BASE_I18N_KEY = 'app.email_details.statistics_tab'

const ANALYSIS_FIELDS = [
  'domain',
  'senderDomainRegistrationDate',
  'senderIpAddress',
  'senderIpLocation',
  'senderIpReputation',
  'threatsDetectCount'
]

const AUTHENTICATION_FIELDS = ['isDkimAuthenticated', 'isSpfAuthenticated', 'isDmarcAuthenticated']

const StatisticsField: React.FC<UseStatisticsFieldParams> = props => {
  const { emailStats, shouldHighlightAnalysis, switchKeywordHighlighting } = props
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [statisticsFieldLogic] = useStatisticsFieldLogic()

  return useMemo(() => {
    const { renderFieldContent } = statisticsFieldLogic

    return (
      <>
        <Grid container direction="row" id="statistics-field">
          <Grid item xs={8}>
            <Grid>
              <Typography className={classes.statisticsHeaderTitle}>{formatMessage('sender_analysis')}</Typography>

              {ANALYSIS_FIELDS.map((fieldName: string) => (
                <Tooltip key={fieldName} title={renderFieldContent(fieldName, emailStats[fieldName])}>
                  <Chip
                    size="small"
                    className={`${classes.baseStyle} ${shouldHighlightAnalysis(fieldName) && classes.highlight}`}
                    key={fieldName}
                    label={renderFieldContent(fieldName, emailStats[fieldName], true)}
                  />
                </Tooltip>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <Grid>
              <Typography className={classes.statisticsHeaderTitle}>
                {formatMessage('sender_authentication')}
              </Typography>

              {AUTHENTICATION_FIELDS.map((fieldName: string) => (
                <Tooltip key={fieldName} title={renderFieldContent(fieldName, emailStats[fieldName])}>
                  <Chip
                    size="small"
                    className={`${classes.baseStyle} ${!emailStats[fieldName] &&
                      emailStats[fieldName] !== null &&
                      switchKeywordHighlighting &&
                      classes.highlight}`}
                    key={fieldName}
                    label={renderFieldContent(fieldName, emailStats[fieldName])}
                  />
                </Tooltip>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }, [
    classes.baseStyle,
    classes.highlight,
    classes.statisticsHeaderTitle,
    emailStats,
    formatMessage,
    shouldHighlightAnalysis,
    statisticsFieldLogic,
    switchKeywordHighlighting
  ])
}

export default StatisticsField
