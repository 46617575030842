import React, { useMemo } from 'react'

import { kebabCase } from 'lodash'

import { Grid, Tab, Tabs } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'global/lib/localization'
import useTabContentSelectorLogic, {
  TabConfig as Config
} from 'global/components/lib/contentTabSelector/useContentTabSelectorLogic'
import styles from 'global/components/lib/contentTabSelector/contentTabSelectorStyles'

export type TabConfig = Config
export interface ContentTabSelectorProps {
  BASE_I18N_KEY: string
  tabs: TabConfig[]
  analyticsEventName?: string
  changePageTitle?: boolean
}

const ContentTabSelector: React.FC<ContentTabSelectorProps> = ({
  tabs,
  analyticsEventName,
  BASE_I18N_KEY,
  changePageTitle = false
}) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const [tabContentSelectorLogic] = useTabContentSelectorLogic({
    BASE_I18N_KEY,
    tabs,
    analyticsEventName,
    changePageTitle
  })

  const TabContent = tabs[tabContentSelectorLogic.selectedTab as number]?.[2]

  return useMemo(() => {
    return (
      <>
        <Grid item data-field="grid-tabs">
          <Tabs
            className={classes.tabs}
            textColor="primary"
            value={tabContentSelectorLogic.selectedTab}
            onChange={tabContentSelectorLogic.onTabSelected}
            classes={{
              indicator: classes.indicator
            }}
            data-field="tabs"
          >
            {tabs.map(tab => {
              const [uiRoute, i18nKey] = tab

              return (
                <Tab
                  classes={{ textColorPrimary: classes.tab }}
                  data-testid={`${kebabCase(uiRoute[0].id)}-tab`}
                  label={formatMessage(`tabs.${i18nKey}`)}
                  key={uiRoute[0].id}
                  data-field="tab"
                />
              )
            })}
          </Tabs>
        </Grid>
        <Grid className={classes.tabContent} item data-field="grid-tab-content">
          {TabContent && <TabContent />}
        </Grid>
      </>
    )
  }, [tabContentSelectorLogic, formatMessage, classes, tabs, TabContent])
}

export default ContentTabSelector
