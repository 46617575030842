import { useEffect, useMemo } from 'react'

import { isPending, isFailed } from 'global/redux/toolkit/api'
import {
  EmailDetailsDialogProps,
  OnCloseDialogCallback,
  DialogActions,
  EMPTY_EMAIL_DETAILS
} from 'global/components/lib/dialogs/emailDetailsDialog/emailDetailDialogTypes'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'

import * as T from 'sen/redux/features/dmarc/dmarcTypes'
import { getRufSample, resetRufSample } from 'sen/redux/features/dmarc/dmarcSlice'
import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'

export interface UseEmailDetailsRufSampleInterfaceProps {
  onClose?: OnCloseDialogCallback
  onDownloadEmail?: EmailDetailsDialogProps['eventHandlers']['onDownloadEmail']
}

export type UseEmailDetailsRufSampleInterface = [
  EmailDetailsDialogProps,
  DialogActions<(getRufSampleParams: T.GetRufSamplePayload) => void>
]

export default function useEmailDetailsRufSampleInterface({
  onClose,
  onDownloadEmail
}: UseEmailDetailsRufSampleInterfaceProps): UseEmailDetailsRufSampleInterface {
  const dispatch = useAppDispatch()
  const { rufSample, isRufSampleLoading, isRufSampleFailed } = useAppSelector(_stores => ({
    rufSample: _stores.dmarc.rufSample,
    isRufSampleLoading: isPending(_stores.dmarc.getRufSampleApiStatus),
    isRufSampleFailed: isFailed(_stores.dmarc.getRufSampleApiStatus)
  }))
  const [isDialogOpened, toggleDialog] = useDialogLogic()

  // init
  useEffect(() => {
    return () => {
      dispatch(resetRufSample())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dialogActions = useMemo(
    () => ({
      open: isDialogOpened,
      onOpen: (getRufSampleParams: T.GetRufSamplePayload) => {
        toggleDialog()
        dispatch(getRufSample(getRufSampleParams))
      },
      onClose: () => {
        if (onClose) {
          onClose()
        }

        toggleDialog()
      }
    }),
    [onClose, isDialogOpened, toggleDialog, dispatch]
  )

  return useMemo(() => {
    return [
      {
        emailDetails: rufSample
          ? {
              subject: rufSample.rawEmailParsed.subject,
              from: {
                email: rufSample.rawEmailParsed.from.text,
                displayName: ''
              },
              to: {
                email: rufSample.rawEmailParsed.to.text,
                displayName: ''
              },
              replyTo: [
                {
                  email: rufSample.rawEmailParsed.inReplyTo,
                  displayName: ''
                }
              ],
              date: rufSample.rawEmailParsed.date,
              headers: rufSample.rawEmailParsed.headerLines,
              failureReport: rufSample.feedbackParsed.headerLines,
              bodyMimeType: 'text/html',
              body: rufSample.rawEmailParsed.html,
              attachments: []
            }
          : EMPTY_EMAIL_DETAILS,
        emailDataStatuses: {
          inProgress: isRufSampleLoading,
          isLoaded: !!rufSample,
          isFailed: isRufSampleFailed
        },
        eventHandlers: {
          onClose: dialogActions.onClose,
          onDownloadEmail
        }
      },
      dialogActions
    ]
  }, [dialogActions, rufSample, isRufSampleLoading, isRufSampleFailed, onDownloadEmail])
}
