import { makeStyles } from '@material-ui/core/styles'

import tableStyles from 'global/components/lib/grid/tableStyles'

import dialogStyles from 'ets/configs/styles/dialogStyles'

const CELL_HEIGHT = 96

export default makeStyles(theme => {
  const defaultTableStyles = tableStyles(theme, CELL_HEIGHT)
  const defaultDialogStyles = dialogStyles(theme)

  return {
    ...defaultDialogStyles,
    ...defaultTableStyles,
    tableLoadIndicator: {
      ...defaultTableStyles.tableLoadIndicator,
      top: 0
    },
    dataTable: {
      ...defaultTableStyles.dataTable,

      '& td': {
        lineHeight: `${theme.spacing(3)}px`
      }
    },
    dateCell: {
      ...defaultTableStyles.cell,
      paddingRight: '100px !important'
    },
    buttonWrapper: {
      paddingTop: theme.spacing(1)
    },
    disabledText: {
      color: theme.palette.text.disabled
    }
  }
})
