import { useMemo, useCallback, useEffect } from 'react'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { isPending, isSuccess, getErrorMessage } from 'global/redux/toolkit/api'
import { Alert } from 'global/types/api/ato'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import { getAlerts, markAlertAsFp, resetMarkAlertAsFp, resetAlerts } from 'sen/redux/features/ato/atoSlice'

export type InProgress = boolean
export type IsSuccess = boolean
export type OnMark = () => void
export type ErrorMsg = string | undefined

export default function useMarkAlertAsFpLogic(alertId: Alert['id']): [InProgress, OnMark, IsSuccess, ErrorMsg] {
  const { accessTokenId, inProgress, isSuccessFullyMarked, error } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id,
    inProgress: isPending(_stores.ato.markAlertAsFpApiStatus),
    isSuccessFullyMarked: isSuccess(_stores.ato.markAlertAsFpApiStatus),
    error: getErrorMessage(_stores.ato.markAlertAsFpApiStatus)
  }))
  const dispatch = useAppDispatch()

  // Init
  useEffect(() => {
    return () => {
      dispatch(resetMarkAlertAsFp())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // reload alerts
  useEffect(() => {
    dispatch(resetAlerts())
    dispatch(getAlerts())
  }, [dispatch, isSuccessFullyMarked])

  const onMark = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.ATO_MARK_ALERT_AS_FP, {
      accessTokenId,
      alert: alertId
    })

    dispatch(markAlertAsFp(alertId))
  }, [dispatch, accessTokenId, alertId])

  return useMemo(() => {
    return [inProgress, onMark, isSuccessFullyMarked, error]
  }, [inProgress, isSuccessFullyMarked, onMark, error])
}
