import React, { useMemo } from 'react'

import { Button, Grid } from '@barracuda-internal/bds-core'

import useDialogLogic from 'global/lib/dialogs/useDialogLogic'
import * as analyticsLib from 'global/lib/analytics/analyticsService'

import useDashboardHeaderLogic from 'sen/components/pages/dashboard/header/useDashboardHeaderLogic'
import ReportMissedAttackDialog from 'sen/components/lib/dialogs/reportMissedAttack/ReportMissedAttackDialog'
import styles from 'sen/components/pages/dashboard/header/dashboardHeaderStyles'

import { useFormatMessage } from 'global/lib/localization'

const BASE_I18N_KEY = 'sen.app.dashboard.header'

export interface DashboardHeaderProps {
  scanInProgress?: boolean
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({ scanInProgress }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const [isReportMissedAttackDialogOpened, toggleReportMissedAttackDialog] = useDialogLogic()
  const [sendTrackEventInfo] = useDashboardHeaderLogic()

  return useMemo(() => {
    return (
      <Grid container justifyContent="flex-end" direction="row" data-field="root-grid">
        {isReportMissedAttackDialogOpened && <ReportMissedAttackDialog onClose={toggleReportMissedAttackDialog} />}
        {!scanInProgress && (
          <Button
            className={classes.mastHeadButton}
            color="primary"
            size="large"
            variant="contained"
            onClick={() => {
              toggleReportMissedAttackDialog()
              sendTrackEventInfo(analyticsLib.EVENTS.REPORT_MISSED_ATTACK)
            }}
            data-field="missed-attack-button"
          >
            {formatMessage('report_missed_attack')}
          </Button>
        )}
      </Grid>
    )
  }, [
    isReportMissedAttackDialogOpened,
    toggleReportMissedAttackDialog,
    formatMessage,
    classes,
    sendTrackEventInfo,
    scanInProgress
  ])
}

export default DashboardHeader
