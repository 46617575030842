import React, { useMemo } from 'react'

import styles from 'global/components/lib/layout/navbar/navbarStyles'
import { config } from 'global/lib/config'
import useNoticableWidgetLogic, {
  NavbarNoticableWidgetLogicParams
} from 'global/components/lib/layout/navbar/noticableWidget/useNavbarNoticableWidgetLogic'

export type NavbarNoticableWidgetProps = NavbarNoticableWidgetLogicParams

const NoticeableWidget: React.FC<NavbarNoticableWidgetProps> = props => {
  const classes = styles()
  const widgetId = config.domainConfig.isSentinel ? 'sentinel' : 'email-security'
  useNoticableWidgetLogic(props)

  const { trackNoticeableClick, title } = props

  return useMemo(
    () => (
      <div className={classes.noticeableWrapper}>
        <noticeable-widget id={`${widgetId}-noticeable-widget`} onClick={trackNoticeableClick}>
          <span>{title}</span>
        </noticeable-widget>
      </div>
    ),
    [trackNoticeableClick, title, classes, widgetId]
  )
}

export default NoticeableWidget
