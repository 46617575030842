import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient from 'global/lib/api/restClient'
import etsApiRoutes from 'ets/lib/api/apiRoutes'
import { setError } from 'global/redux/features/report/reportSlice'

export const getContestScanStatus = createAsyncThunk('CONTEST/getScanStatus', async function doGetScanStatus(
  payload: string,
  { rejectWithValue, dispatch }
) {
  try {
    const resp = await restClient(etsApiRoutes.CONTEST_SCAN_STATS, {
      data: { email: payload }
    })

    return resp.data
  } catch (e) {
    dispatch(setError('unable_to_load_scan'))

    return rejectWithValue('unable_to_load_scan')
  }
})
