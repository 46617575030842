import { makeStyles } from '@material-ui/core/styles'
import { COLORS } from 'global/configs/theme/defaults'

export default makeStyles(() => ({
  tooltip: {
    backgroundColor: COLORS.BLACK_50,
    fontSize: 12,
    letterSpacing: 0,
    lineHeight: '16px',
    boxShadow: '0 2px 5px 0 rgba(0,0,0,0.26), 0 2px 10px 0 rgba(0,0,0,0.16)'
  }
}))
