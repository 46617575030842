import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Typography, Button, DialogTitle, DialogContent, DialogActions, Grid } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'global/lib/localization'
import CopyToClipboard from 'global/components/lib/copyToClipboard/CopyToClipboard'

import styles from 'sen/components/lib/dialogs/dmarcEnforcementWizard/dmarcEnforcementWizardDialogStyles'
import { StepProps } from 'sen/components/lib/dialogs/dmarcEnforcementWizard/dmarcEnforcementWizardDialogTypes'

const BASE_I18N_KEY = 'sen.app.enforce_dmarc_dialog'

export const DmarcEnforcementStepInit: React.FC<StepProps> = ({ config, events }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  return useMemo(
    () => (
      <>
        <DialogTitle data-step-name="init">
          <Typography className={classes.dialogTitle}>{formatMessage(`${config.goal}_title`)}</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container direction="column">
            <Typography className={classes.dialogText}>{formatMessage(`${config.goal}_subtitle_1`)}</Typography>
            <Grid className={classes.copyToClipboard} container justifyContent="flex-end">
              <CopyToClipboard textToCopy={config.dmarcRecord} customButtonText={formatMessage('copy_dns_record')} />
            </Grid>
            <Grid item className={classes.dmarcRecord}>
              {config.dmarcRecord}
            </Grid>
            <Typography className={classes.dialogText}>{formatMessage(`${config.goal}_subtitle_2`)}</Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="text" size="large" onClick={events.onCloseDialog}>
            {formatMessage('cancel')}
          </Button>
          <Button color="primary" variant="contained" size="large" onClick={events.onCheckDmarc}>
            {formatMessage('check_dmarc_record')}
          </Button>
        </DialogActions>
      </>
    ),
    [classes, formatMessage, config, events]
  )
}

DmarcEnforcementStepInit.propTypes = {
  config: PropTypes.any.isRequired,
  events: PropTypes.any.isRequired
}

export default DmarcEnforcementStepInit
