import deepmerge from 'deepmerge'

import { makeStyles } from '@material-ui/core/styles'

import { makeGridStyles } from 'global/components/lib/grid/gridStyles'

export default makeStyles(theme => ({
  bold: {
    fontWeight: theme.font.weight.bold
  },
  buttonSpacing: {
    marginRight: theme.spacing(2)
  },
  cellIcon: {
    width: 40
  },
  dateWrapper: {
    height: 25
  },
  directionWrapper: {
    '& img': {
      height: 15,
      verticalAlign: 'middle'
    },
    '& div': {
      verticalAlign: 'middle'
    }
  },
  exportButton: {
    float: 'right',
    marginTop: theme.spacing(1.5)
  },
  exportIframe: {
    display: 'none'
  },
  header: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    position: 'relative',
    zIndex: 1
  },
  hidden: {
    visibility: 'hidden'
  },
  root: {
    display: 'block',
    position: 'relative'
  },
  statusColumn: {
    width: 55
  },
  statusWrapper: {
    '& img': {
      verticalAlign: 'middle',
      zoom: 0.9
    }
  },
  title: {
    display: 'inline-block'
  },
  wrapper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 2,
    margin: theme.spacing(2, 2, 3, 2),
    overflow: 'hidden',
    position: 'relative'
  }
}))

export const gridStyle = {
  gridLayout: {}
}

export const emailsByIncidentGridStyle = makeStyles(theme => ({
  ...deepmerge(makeGridStyles(theme), gridStyle)
}))
