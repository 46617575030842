import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Alert } from '@barracuda-internal/bds-core'

import globalStyles from 'global/components/features/signup/SigninStyles'
import styles from 'global/components/features/signinSignup/signinSignupStylesNew'
import { useErrorFormatMessage } from 'global/lib/localization'
import MaintenanceBanner from 'global/components/lib/layout/maintenanceBanner/MaintenanceBanner'

export interface Props {
  children: any
  error?: string | false
  isMaintenanceBannerEnabled?: boolean
}

const SigninSignupLayout: React.FC<any> = ({ children, error, isMaintenanceBannerEnabled }) => {
  const globalClasses = globalStyles()
  const classes = styles()
  const formatErrorMessage = useErrorFormatMessage()
  return useMemo(
    () => (
      <div className={`${globalClasses.root} ${classes.root}`}>
        {error && (
          <Alert className={classes.signinAlert} variant="filled" severity="error" data-field="signinsignup-alert">
            {formatErrorMessage(error)}
          </Alert>
        )}
        {isMaintenanceBannerEnabled && (
          <div className={classes.signinAlert}>
            <MaintenanceBanner />
          </div>
        )}
        {children}
      </div>
    ),
    [children, classes, error, formatErrorMessage, globalClasses, isMaintenanceBannerEnabled]
  )
}

SigninSignupLayout.propTypes = {
  error: PropTypes.any,
  children: PropTypes.any.isRequired
}

SigninSignupLayout.defaultProps = {
  error: false
}

export default SigninSignupLayout
