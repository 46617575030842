import { useMemo, useCallback, useReducer } from 'react'

import { process } from '@progress/kendo-data-query'

import { SearchFieldProps, useSearchFieldLogic } from 'global/components/lib/searchField/SearchField'
import { BDSGridPagerConfig, BDSGridSortConfig } from 'global/types/dataTables/dataTables'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'
import { isPending } from 'global/redux/toolkit/api'

import { update as updateEmployeesTable } from 'ets/redux/features/dataTables/employees/employeesSlice'
import {
  update as updateUserEmailsTable,
  reset as resetUserEmailsTable
} from 'ets/redux/features/dataTables/userEmails/userEmailsSlice'
import {
  getThreatsByUsernameReport,
  resetThreatsByUsernameReport
} from 'ets/redux/features/reports/threatsByUsername/threatsByUsernameSlice'
import { getEmployeesReport, resetEmployeesReport } from 'ets/redux/features/reports/employees/employeesSlice'
import { IsUserInputDisabledForTable } from 'ets/components/pages/dashboard/isUserInputDisabledForTableType'
import getRiskTypeByScore from 'ets/lib/getRiskTypeByScore'

import {
  TABLE_CELL_HEIGHT,
  TABLE_HEADER_HEIGHT,
  TABLE_FOOTER_HEIGHT
} from 'ets/components/pages/dashboard/employees/dashboardEmployeesStyles'
import { useAppDispatch, useAppSelector } from 'ets/redux/toolkit/hooks'
import { ColumnsConfig, FilterConfig } from 'ets/redux/types/dataTables'

const SEARCH_FIELD_ID = 'employees-search'

export interface TabConfig {
  id: string
  count: number
  isSelected: boolean
  onClick: () => void
  disabled: boolean
}

export interface UseDashboardEmployeesLogic {
  tabs: TabConfig[]
  isReportLoaded: boolean
  inProgress: boolean
  tableTotal: number | string
  searchFieldConfig: SearchFieldProps
  GRID_COLUMNS: {
    [key: string]: string
  }
  columnsConfig: ColumnsConfig
  tableData: {
    total: number
    data: any[]
  }
  pageConfig: BDSGridPagerConfig
  sortConfig: BDSGridSortConfig | {}
  selectedUser: string | null
  isUserEmailDialogOpened: boolean
  closeUserEmailDialog: () => void
  highlightKeywords: string[]
  employeesTotal: number
}

export interface UseDashboardEmployeesLogicProps {
  isUserInputDisabledForTable: IsUserInputDisabledForTable
  fixTableHeight: (tableId: string, newHeight: number) => void
}

export default function useDashboardEmployeesLogic(
  props: UseDashboardEmployeesLogicProps
): [UseDashboardEmployeesLogic] {
  const { isUserInputDisabledForTable, fixTableHeight } = props
  const dispatch = useAppDispatch()
  const {
    accessTokenId,
    inProgress,
    reportData,
    loadedOffsets,
    employeesTable,
    isReportLoaded,
    userEmailsTable
  } = useAppSelector(_stores => ({
    inProgress: isPending(_stores.reports.employees.apiStatus),
    reportData: _stores.reports.employees.data,
    loadedOffsets: _stores.reports.employees.loadedOffsets,
    isReportLoaded: !!_stores.reports.employees.data.accessTokenId,
    employeesTable: _stores.dataTables.employees,
    userEmailsTable: _stores.dataTables.userEmails,
    accessTokenId: _stores.accessToken.accessToken?.id
  }))
  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    selectedTab: employeesTable.FILTER_CONFIG.ALL.id,
    searchString: '',
    selectedUser: null
  })
  const [isUserEmailDialogOpened, toggleUserEmailDialog] = useDialogLogic()

  const closeUserEmailDialog = useCallback(() => {
    dispatch(resetThreatsByUsernameReport())
    dispatch(resetUserEmailsTable())
    toggleUserEmailDialog()
    setState({ selectedUser: null })
  }, [dispatch, toggleUserEmailDialog])

  const tableTotal = useMemo(() => {
    return isReportLoaded ? reportData.report.totalCount : employeesTable.DEMO_DATA.ALL
  }, [reportData, isReportLoaded, employeesTable])

  const tableLabelTotal = useMemo(() => {
    if (isReportLoaded && employeesTable.skip === 0 && inProgress) {
      return '-'
    }

    return tableTotal
  }, [isReportLoaded, employeesTable.skip, inProgress, tableTotal])

  const isUserInputDisabled = useMemo(() => {
    return (
      isUserInputDisabledForTable(employeesTable, reportData, isReportLoaded) ||
      inProgress ||
      (tableLabelTotal === 0 && !state.searchString.length)
    )
  }, [
    reportData,
    isReportLoaded,
    employeesTable,
    isUserInputDisabledForTable,
    inProgress,
    tableLabelTotal,
    state.searchString
  ])

  const riskLevelCounts = useMemo(() => {
    const { riskLevels } = reportData.report
    const { FILTER_CONFIG, DEMO_DATA } = employeesTable

    if (isReportLoaded) {
      return {
        [FILTER_CONFIG.ALL.id]: riskLevels.high + riskLevels.medium + riskLevels.low,
        [FILTER_CONFIG.HIGH_RISK.id]: riskLevels.high,
        [FILTER_CONFIG.MEDIUM_RISK.id]: riskLevels.medium,
        [FILTER_CONFIG.LOW_RISK.id]: riskLevels.low
      }
    }

    return DEMO_DATA.COUNTS
  }, [reportData, isReportLoaded, employeesTable])

  const employeesTotal = useMemo(() => {
    return riskLevelCounts[state.selectedTab]
  }, [riskLevelCounts, state.selectedTab])

  const updateTableData = useCallback(
    (changes: any = {}) => {
      const { selectedTab, ...updatedValues } = changes

      dispatch(
        updateEmployeesTable({
          ...updatedValues,
          skip: 0,
          ...(selectedTab && {
            filter:
              (
                Object.values(employeesTable.FILTER_CONFIG as FilterConfig).find(
                  (config: any) => config.id === selectedTab
                ) || {}
              ).filterQuery || null
          })
        })
      )
      dispatch(resetEmployeesReport())
      dispatch(getEmployeesReport())
    },
    [dispatch, employeesTable]
  )

  const tabs = useMemo(() => {
    const { FILTER_CONFIG } = employeesTable
    const isDisabledTabs = riskLevelCounts[FILTER_CONFIG.ALL.id] === 0

    return Object.keys(FILTER_CONFIG).map((key: string) => {
      const filterType = (FILTER_CONFIG as any)[key].id

      return {
        id: filterType,
        count: riskLevelCounts[filterType],
        isSelected: state.selectedTab === filterType,
        onClick: () => {
          if (state.selectedTab !== filterType && !isDisabledTabs) {
            updateTableData({ selectedTab: filterType, search: '' })
            setState({ selectedTab: filterType, searchString: '' })
          }
        },
        disabled: isDisabledTabs
      }
    })
  }, [state, riskLevelCounts, employeesTable, updateTableData])

  const seeDetails = useCallback(
    (userName: string) => {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.VIEW_EMPLOYEE_DETAILS, { accessTokenId })
      toggleUserEmailDialog()
      setState({ selectedUser: userName })
      dispatch(updateUserEmailsTable({ filter: [`${userEmailsTable.FILTER_FIELD.DISPLAY_NAME}:"${userName}"`] }))
      dispatch(getThreatsByUsernameReport())
    },
    [dispatch, userEmailsTable.FILTER_FIELD, accessTokenId, toggleUserEmailDialog]
  )

  const tableData = useMemo(() => {
    const { skip, take } = employeesTable

    const { data } = process(
      (reportData.report.data || []).map((report: any) => ({
        ...(report && {
          ...report,
          riskType: getRiskTypeByScore(report.spScore),
          threatsFound: report.spFraudCount,
          seeDetails
        })
      })),
      { skip, take }
    )

    const fixedCount = Math.max(reportData.report.totalCount, 6)
    const newHeight =
      fixedCount <= employeesTable.ITEMS_PER_PAGE
        ? fixedCount * TABLE_CELL_HEIGHT + TABLE_HEADER_HEIGHT
        : employeesTable.ITEMS_PER_PAGE * TABLE_CELL_HEIGHT + TABLE_HEADER_HEIGHT + TABLE_FOOTER_HEIGHT

    fixTableHeight('employees', newHeight)

    return {
      data: data.filter(report => report.id),
      total: tableTotal
    }
  }, [reportData, seeDetails, employeesTable, tableTotal, fixTableHeight])

  const [debouncedOnChange, validateSearchString] = useSearchFieldLogic(
    (search: string) => {
      updateTableData({ search: search.trim() })
      setState({ searchString: search.trim() })
    },
    inProgress,
    SEARCH_FIELD_ID
  )

  const searchFieldConfig = useMemo(() => {
    return {
      id: SEARCH_FIELD_ID,
      value: state.searchString,
      onChange: (e: any) => {
        const validatedSearchString = validateSearchString(e.target.value)
        if (employeesTable.search !== validatedSearchString) {
          debouncedOnChange(validatedSearchString)
          setState({ searchString: validatedSearchString })
        }
      },
      disabled: isUserInputDisabled
    }
  }, [state.searchString, employeesTable, isUserInputDisabled, validateSearchString, debouncedOnChange])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = employeesTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: tableData.total,
      onPageChange: (e: any) => {
        dispatch(updateEmployeesTable(e.page))
        if (!loadedOffsets.includes(e.page.skip)) {
          dispatch(getEmployeesReport())
        }
      }
    }
  }, [employeesTable, tableData, loadedOffsets, dispatch])

  const sortConfig: BDSGridSortConfig | {} = useMemo(() => {
    if (!tableData.total) {
      return {}
    }

    return {
      sortable: !isUserInputDisabled && {
        allowUnsort: false,
        mode: 'single'
      },
      sort: employeesTable.sort,
      onSortChange: (e: any) => {
        updateTableData({ sort: e.sort })
      }
    }
  }, [employeesTable, updateTableData, tableData.total, isUserInputDisabled])

  return useMemo(() => {
    return [
      {
        tabs,
        isReportLoaded,
        tableTotal: tableLabelTotal,
        searchFieldConfig,
        GRID_COLUMNS: employeesTable.GRID_COLUMNS,
        columnsConfig: employeesTable.columnsConfig,
        tableData,
        pageConfig,
        sortConfig,
        inProgress,
        selectedUser: state.selectedUser,
        isUserEmailDialogOpened,
        closeUserEmailDialog,
        highlightKeywords: [employeesTable.search],
        employeesTotal
      }
    ]
  }, [
    tableLabelTotal,
    tabs,
    isReportLoaded,
    searchFieldConfig,
    employeesTable,
    tableData,
    pageConfig,
    sortConfig,
    inProgress,
    state,
    isUserEmailDialogOpened,
    closeUserEmailDialog,
    employeesTotal
  ])
}
