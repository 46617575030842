import queryString from 'query-string'
import { reduxStore } from 'global/lib/reduxStore'

import * as analyticsLib from 'global/lib/analytics/analyticsService'

import { config } from 'global/lib/config'
import accessTokenLib from 'global/lib/accessToken/accessToken'
import featureLib from 'global/lib/feature/feature'
import * as globalO365Connect from 'global/lib/o365Connect/o365Connect'
import { setSignupUser, setError, SignupUser } from 'global/redux/features/auth/authSlice'

let urlQueries: any

export type CompleteCb = (data: any) => void

export interface O365Connect {
  scanType: string
  location: any
  isComplete?: boolean
  accountId?: string
  onSigninComplete?: CompleteCb | undefined
  onSignupComplete?: CompleteCb | undefined
}

export interface O365Reconnect {
  scanType: string
  location: any
  accountId: string
  accessTokenId: string
  connectSuccess?: CompleteCb | undefined
  source: string
}

const BASE_FLAGS = {
  product: '',
  provider: ''
}

export function initCompleteSignup(cb?: CompleteCb) {
  return function onCompleteSignup(data: any) {
    if (data.error) {
      reduxStore.dispatch(setError(globalO365Connect.signupError(data)))
    } else {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.SIGNUP_CONNECT_O365_SUCCESSFUL, {
        email: data.user?.email
      })
      reduxStore.dispatch(
        setSignupUser({
          ...data,
          user: { ...data.user, flags: { ...BASE_FLAGS, ...queryString.parse(urlQueries) } }
        } as SignupUser)
      )
      if (cb) cb(data)
    }
  }
}

export function initCompleteSignin(cb?: CompleteCb) {
  return function onCompleteSignin(data: any) {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.SIGNIN_CONNECT_O365_SUCCESSFUL, {
      email: data.user?.email
    })
    if (cb) cb(data)
  }
}

export function authFailure(data: any) {
  reduxStore.dispatch(setError(`connect_o365_dialog.${globalO365Connect.authFailureError(data)}`))
}

export function reconnect({ scanType, location, accountId, accessTokenId, connectSuccess, source }: O365Reconnect) {
  urlQueries = location.search

  if (
    featureLib.hasSentinelDemoFeature(accessTokenLib.getActiveAccessTokenId()) ||
    featureLib.hasForensicsDemoFeature(accessTokenLib.getActiveAccessTokenId())
  ) {
    reduxStore.dispatch(setError('operation_not_permitted_on_demo_account'))
  } else {
    globalO365Connect.initReconnect({
      provider: config.CLOUD_PROVIDERS.office365.id,
      scanType,
      accountId,
      accessTokenId,
      source,
      connectSuccess: data => {
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.RECONNECT_O365_SUCCESSFUL, {
          source
        })
      },
      authFailure
    })
  }
}

export default function connect({
  scanType,
  location,
  isComplete = false,
  accountId,
  onSigninComplete,
  onSignupComplete
}: O365Connect) {
  urlQueries = location.search

  if (
    featureLib.hasSentinelDemoFeature(accessTokenLib.getActiveAccessTokenId()) ||
    featureLib.hasForensicsDemoFeature(accessTokenLib.getActiveAccessTokenId())
  ) {
    reduxStore.dispatch(setError('operation_not_permitted_on_demo_account'))
  } else {
    globalO365Connect.init({
      provider: config.CLOUD_PROVIDERS.office365.id,
      scanType,
      accountId,
      source: config.SCAN_SOURCES[isComplete ? 'COMPLETE_SIGNUP' : 'SIGNUP'],
      queryStrings: queryString.parse(location.search),
      completeSignup: initCompleteSignup(onSignupComplete),
      completeSignin: initCompleteSignin(onSigninComplete),
      authFailure
    })
  }
}
