import { makeStyles, createStyles } from '@material-ui/core/styles'

import { COLORS } from 'ets/configs/styles/defaults'

const styles = createStyles({
  dmarcNumber: {
    fontSize: 48,
    fontWeight: 'bold',
    letterSpacing: '1.03px',
    lineHeight: '57px'
  }
})

export default makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
    flexGrow: 1,
    marginBottom: theme.spacing(4)
  },
  subContainer: {
    marginTop: theme.spacing(2)
  },
  subtitle: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '20px',
    cursor: 'default'
  },
  subDescription: {
    color: theme.palette.text.primary,
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: '18px',
    cursor: 'default'
  },
  dmarcRed: {
    ...styles.dmarcNumber,
    color: COLORS.RED
  },
  dmarcYellow: {
    ...styles.dmarcNumber,
    color: COLORS.YELLOW
  },
  dmarcGreen: {
    ...styles.dmarcNumber,
    color: COLORS.LIGHT_GREEN
  },
  dmarcCount: {
    float: 'right'
  },
  dmarcText: {
    marginLeft: theme.spacing(1.5),
    marginTop: theme.spacing(1),
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: '21px'
  }
}))
