import React from 'react'
import PropTypes from 'prop-types'

import { MenuItem, ListItemIcon } from '@barracuda-internal/bds-core'
import { Add as AddIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'

import ConnectO365Dialog from 'global/components/lib/dialogs/connectO365/ConnectO365Dialog'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'
import { Account } from 'global/types/api/accountType'

import styles from 'global/components/lib/layout/navbar/navbarStyles'
import { useFormatMessage } from 'global/lib/localization'

const BASE_I18N_KEY = 'app.logoutmenu'

export interface NavbarContextMenuO365Props {
  accessTokenId?: string
  sendNavO365TrackingEvent?: () => void
  successCb?: (data: any, scanType: string) => void
  showScanTypeSelection?: boolean
  accounts?: Account[]
}

const NavbarContextMenuO365: React.FC<NavbarContextMenuO365Props> = ({
  /* eslint-disable @typescript-eslint/no-empty-function */
  accessTokenId,
  sendNavO365TrackingEvent = () => {},
  successCb = () => {},
  showScanTypeSelection = false,
  accounts = []
  /* eslint-enable @typescript-eslint/no-empty-function */
}) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  const [isDialogOpened, toggleDialog] = useDialogLogic()

  return (
    <>
      <MenuItem
        className={classes.navbarMenuContextItem}
        onClick={() => {
          toggleDialog()
          sendNavO365TrackingEvent()
        }}
      >
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>
        {formatMessage('connect_office')}
      </MenuItem>
      <ConnectO365Dialog
        toggleDialog={toggleDialog}
        isDialogOpened={isDialogOpened}
        accessTokenId={accessTokenId}
        successCb={successCb}
        showScanTypeSelection={showScanTypeSelection}
        accounts={accounts}
      />
    </>
  )
}

NavbarContextMenuO365.propTypes = {
  accessTokenId: PropTypes.string,
  sendNavO365TrackingEvent: PropTypes.func,
  successCb: PropTypes.func,
  showScanTypeSelection: PropTypes.bool,
  accounts: PropTypes.array
}

NavbarContextMenuO365.defaultProps = {
  accessTokenId: undefined,
  sendNavO365TrackingEvent: undefined,
  successCb: undefined,
  showScanTypeSelection: false,
  accounts: []
}

export default NavbarContextMenuO365
