import { makeStyles } from '@material-ui/core/styles'

import tableStyles, { TABLE_HEADER_HEIGHT, TABLE_FOOTER_HEIGHT } from 'global/components/lib/grid/tableStyles'
import { COLORS } from 'ets/configs/styles/defaults'

const CELL_HEIGHT = 93
export { TABLE_HEADER_HEIGHT, TABLE_FOOTER_HEIGHT }
export const TABLE_CELL_HEIGHT = CELL_HEIGHT + 2

export default makeStyles(theme => {
  const defaultTableStyles = tableStyles(theme, CELL_HEIGHT)

  return {
    root: {
      position: 'relative',
      marginTop: theme.spacing(4),
      flexGrow: 1,
      marginBottom: theme.spacing(4)
    },
    tableWrapper: {
      marginTop: theme.spacing(3),
      padding: theme.spacing(2),
      backgroundColor: 'white'
    },
    tableTotal: {
      width: '100%',
      color: theme.palette.text.primary,
      fontSize: 24,
      fontWeight: 'bold',
      letterSpacing: 0,
      lineHeight: '40px',
      paddingBottom: theme.spacing(1),
      borderBottom: '1px solid #DDDDDD'
    },
    searchField: {
      marginTop: 20
    },
    ...defaultTableStyles,
    tableArea: {
      ...defaultTableStyles.tableArea,
      marginTop: theme.spacing(2),
      minHeight: 413
    },
    dataTable: {
      ...defaultTableStyles.dataTable,
      height: (CELL_HEIGHT + 1) * 10 + TABLE_HEADER_HEIGHT + TABLE_FOOTER_HEIGHT,

      '& td': {
        lineHeight: `${theme.spacing(3)}px`
      }
    },
    recipientsCell: {
      fontSize: 25,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: '32px'
    },
    disabledText: {
      color: theme.palette.text.disabled
    },
    pickList: {
      height: 40,
      backgroundColor: COLORS.INPUT_BACKGROUNDS,
      marginLeft: 10
    },
    disabledPicklist: {
      opacity: 0.1
    },
    pickListIcon: {
      verticalAlign: -2
    },
    pickListText: {
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: '24px',
      display: 'inline-block',
      verticalAlign: 4,
      paddingLeft: 5
    }
  }
})
