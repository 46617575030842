import React from 'react'
import PropTypes from 'prop-types'

import { isString } from 'lodash'

import styles from './cellStyles'

export interface Props {
  children?: any
  className?: string
  ellipsis?: boolean
  disableTitle?: boolean
  onClick?: () => void
}

const CellText: React.FC<Props> = ({
  children: text,
  className,
  ellipsis = false,
  disableTitle = false,
  onClick,
  ...rest
}) => {
  const classes = styles()

  return (
    <span
      {...rest}
      role="presentation"
      onClick={onClick}
      title={!disableTitle && ellipsis && isString(text) ? text : ''}
      className={`${classes[ellipsis ? 'ellipsisText' : 'text']} ${className}`}
    >
      {text}
    </span>
  )
}

CellText.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  ellipsis: PropTypes.bool,
  disableTitle: PropTypes.bool,
  onClick: PropTypes.func
}

CellText.defaultProps = {
  children: undefined,
  className: undefined,
  ellipsis: false,
  disableTitle: false,
  onClick: undefined
}

export default CellText
