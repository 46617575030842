import { config } from 'global/lib/config'
import productLib from 'global/lib/product/product'
import { reduxStore } from 'global/lib/reduxStore'

import { setCurrentAccessToken } from 'global/redux/features/accessToken/accessTokenSlice'

import routesConfig, { UiRoute } from 'sen/lib/routes/routesConfig'

const CLEANUP_ROUTES: UiRoute['id'][] = [routesConfig.CLEANUP_TABLE.id, routesConfig.CLEANUP.id]

// List of valid routes for DFP
const DFP_ROUTES: UiRoute['id'][] = [
  routesConfig.DOMAIN_FRAUD.id,
  routesConfig.DOMAIN_FRAUD_DMARC_REVIEW_WITH_UNKNOWN_TAB.id,
  routesConfig.DOMAIN_FRAUD_DMARC_REVIEW_WITH_APPROVED_TAB.id,
  routesConfig.DOMAIN_FRAUD_DMARC_REVIEW_WITH_REJECTED_TAB.id
]

export default async function validateAppForAccessToken(accessTokenId: string, cb?: any) {
  const activePath = reduxStore.getState()?.app?.activePath
  const bundleVersion = productLib.getSentinelSerialBundleForAccessToken(accessTokenId)
  const hasDFP = productLib.hasDFPProduct(accessTokenId)
  const hasSentinel = productLib.hasSentinelProduct(accessTokenId)

  // validate the bundle version for the current path
  if (activePath?.metadata?.bundle && bundleVersion) {
    const isValidRouteForBundle = activePath?.metadata?.bundle.includes(bundleVersion)

    if (!isValidRouteForBundle) {
      routesConfig.DASHBOARD.goto({ reportId: accessTokenId })
    }

    cb(false)
  }

  // This prevents users from navigating to non-DFP paths by typing into URL
  if (!hasSentinel && hasDFP) {
    const isValidRouteForDFP = DFP_ROUTES.includes(activePath.id)

    if (!isValidRouteForDFP) {
      routesConfig.DOMAIN_FRAUD.goto({ reportId: accessTokenId })
    }

    cb(false)
  }

  if (!hasSentinel && !hasDFP) {
    if (productLib.hasETSProduct(accessTokenId)) {
      let newHref = `${config.domains.ets}/report/${accessTokenId}`

      if (config.isDev) {
        newHref = newHref.replace('3005', window.location.port)
      }

      window.location.replace(newHref)

      // if navigating to the cleanup page with a broken AT show cleanup page
    } else if (CLEANUP_ROUTES.some(routeId => routeId === activePath?.id)) {
      routesConfig.CLEANUP.goto()

      // else check if we need to show the start trial page
    } else if (productLib.hasForensicsProduct(accessTokenId)) {
      // set the accessToken then go to the start trial page
      await reduxStore.dispatch(setCurrentAccessToken(accessTokenId))
      routesConfig.START_TRIAL.goto()
      // keep the user on the empty report page
    } else {
      cb(false)
    }
  } else if (cb) {
    cb(true)
  }
}
