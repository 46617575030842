/* eslint-disable no-underscore-dangle */
import { config } from 'global/lib/config'
import userDataLib from 'global/lib/userData/userData'

export function generateFeatureLib() {
  /* **************** *
   * Helper functions *
   * **************** */
  function _hasFeature(accessTokenId: string, feature: string): boolean {
    return userDataLib.getFeaturesForAccessToken(accessTokenId).includes(feature)
  }

  /* ********************* *
   * ETS feature functions *
   * ********************* */
  function hasETSReadOnlyForAnyAccessToken(): boolean {
    return userDataLib.getAccessTokens().some(token => {
      return token.providerApp === config.OFFICE365_PROVIDER_APPS.READ_ONLY_APP
    })
  }

  function hasETSFeature(accessTokenId: string): boolean {
    return !hasForensicsFeature(accessTokenId) && !hasSentinelFeature(accessTokenId)
  }

  function hasShareReportFeature(accessTokenId: string): boolean {
    return _hasFeature(accessTokenId, config.FEATURE_SWITCHES.SHARE_REPORT)
  }

  /* *********************************** *
   * Incident Response feature functions *
   * *********************************** */
  function hasForensicsFeatureForAnyAccessToken(): boolean {
    return userDataLib.getAccessTokens().some(token => {
      return hasForensicsFeature(token.id)
    })
  }

  function hasForensicsFeature(accessTokenId: string): boolean {
    return _hasFeature(accessTokenId, config.FEATURE_SWITCHES.FORENSICS_DASHBOARD)
  }

  function hasForensicsDemoFeature(accessTokenId: string | undefined): boolean {
    if (!accessTokenId) {
      return false
    }

    return _hasFeature(accessTokenId, config.FEATURE_SWITCHES.FORENSICS_DEMO)
  }

  function hasBCSFeature(accessTokenId: string): boolean | null {
    if (hasForensicsDemoFeature(accessTokenId)) {
      return true
    }

    const account = userDataLib.getAccountByAccessToken(accessTokenId)
    const accessToken = userDataLib.getAccessTokenById(accessTokenId)
    const bcsSubscription = accessToken?.bcsSubscription || null

    return !!account && account.bcsEntitled && !!bcsSubscription && bcsSubscription.length > 0
  }

  function hasESSFeature(accessTokenId: string): boolean | null {
    if (hasForensicsDemoFeature(accessTokenId)) {
      return true
    }

    const account = userDataLib.getAccountByAccessToken(accessTokenId)

    return !!account && account.essEntitled
  }

  /* ************************** *
   * Sentinel feature functions *
   * ************************** */
  function hasSentinelFeatureForAnyAccessToken(): boolean {
    return userDataLib.getAccessTokens().some(token => {
      return hasSentinelFeature(token.id)
    })
  }

  function hasSentinelFeature(accessTokenId: string): boolean {
    return _hasFeature(accessTokenId, config.FEATURE_SWITCHES.SENTINEL_DASHBOARD)
  }

  function hasSentinelDemoFeature(accessTokenId: string | undefined): boolean {
    if (!accessTokenId) {
      return false
    }

    return _hasFeature(accessTokenId, config.FEATURE_SWITCHES.SENTINEL_DEMO)
  }

  function hasPhishingSimulationFeature(accessTokenId: string): boolean {
    return _hasFeature(accessTokenId, config.FEATURE_SWITCHES.PHISHING_SIMULATION)
  }

  function hasSimulationCampaignsFeature(accessTokenId: string): boolean {
    return _hasFeature(accessTokenId, config.FEATURE_SWITCHES.SIMULATION_CAMPAIGNS)
  }

  return {
    hasETSReadOnlyForAnyAccessToken,
    hasETSFeature,
    hasShareReportFeature,
    hasForensicsFeatureForAnyAccessToken,
    hasForensicsFeature,
    hasForensicsDemoFeature,
    hasBCSFeature,
    hasESSFeature,
    hasSentinelFeatureForAnyAccessToken,
    hasSentinelFeature,
    hasSentinelDemoFeature,
    hasPhishingSimulationFeature,
    hasSimulationCampaignsFeature
  }
}

export default generateFeatureLib()
