import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { union } from 'lodash'

import {
  Alert,
  AlertSummary,
  AlertsReport,
  AlertInboxRulesReport,
  AlertSigninsReport,
  AlertThreatsReport,
  AlertDetails,
  SigninsByAllCountriesReport,
  SigninsByCountrysReport,
  ResetPassword
} from 'global/types/api/ato'
import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'global/redux/toolkit/api'
import { insertReportData, resetReport, HardResetReport } from 'global/redux/features/reports/reducerHandlers'

import { RootState } from 'sen/redux/toolkit/store'
import {
  getAlerts,
  getAlertInboxRules,
  getAlertSignins,
  getSigninsByAllCountries,
  getSigninsByCountry,
  getUserRelatedSignins,
  getAlertThreats,
  getAlertSummary,
  getAlertDetails,
  markAlertAsFp,
  closeAtoAlert,
  DEFAULT_ALERT_SUMMARY_TIMEFRAME
} from 'sen/redux/features/ato/atoApiThunks'
import {
  update as updateAlertsTable,
  INITIAL_STATE as alertsTableInitialState
} from 'sen/redux/features/dataTables/alerts/alertsSlice'
import {
  update as updateAlertInboxRulesTable,
  INITIAL_STATE as alertInboxRulesTableInitialState
} from 'sen/redux/features/dataTables/alert/inboxRulesSlice'
import {
  update as updateAlertSigninsTable,
  INITIAL_STATE as alertSigninsTableInitialState
} from 'sen/redux/features/dataTables/alert/signinsSlice'
import {
  update as updateAlertThreatsTable,
  INITIAL_STATE as alertThreatsTableInitialState
} from 'sen/redux/features/dataTables/alert/threatsSlice'
import {
  update as updateUserRelatedSigninsTable,
  INITIAL_STATE as userRelatedSigninsTableInitialState
} from 'sen/redux/features/dataTables/signinsByUser/signinsByUser'
import {
  update as updateSigninByCountryTable,
  INITIAL_STATE as signinByCountryTableInitialState
} from 'sen/redux/features/dataTables/signinsByCountry/signinsByCountrySlice'

export interface AtoState {
  alertSummary: AlertSummary | undefined
  getAlertSummaryApiStatus: ApiStatus
  alerts: AlertsReport | undefined
  loadedAlertOffsets: number[]
  getAlertsApiStatus: ApiStatus
  alertInboxRules: AlertInboxRulesReport | undefined
  loadedInboxRulesOffsets: number[]
  getAlertInboxRulesApiStatus: ApiStatus
  alertSignins: AlertSigninsReport | undefined
  loadedSigninsOffsets: number[]
  getAlertSigninsApiStatus: ApiStatus
  signinsByAllCountries: SigninsByAllCountriesReport | undefined
  getSigninsByAllCountriesApiStatus: ApiStatus
  signinsByCountry: SigninsByCountrysReport | undefined
  getSigninsByCountryApiStatus: ApiStatus
  loadedSigninsByCountryOffsets: number[]
  userRelatedSignins: AlertSigninsReport | undefined
  loadedUserRelatedSigninsOffsets: number[]
  getUserRelatedSigninsApiStatus: ApiStatus
  alertThreats: AlertThreatsReport | undefined
  loadedALertThreatsOffsets: number[]
  getAlertThreatsApiStatus: ApiStatus
  alertDetails: AlertDetails | undefined
  getAlertDetailsApiStatus: ApiStatus
  markAlertAsFpApiStatus: ApiStatus
  closeAtoAlertApiStatus: ApiStatus
  compromisedAccount: Alert | undefined
  resetPassword: ResetPassword | undefined
}

// initialState
export const INITIAL_STATE: AtoState = {
  alertSummary: undefined,
  getAlertSummaryApiStatus: inIdle,
  alerts: undefined,
  loadedAlertOffsets: [],
  getAlertsApiStatus: inIdle,
  alertInboxRules: undefined,
  loadedInboxRulesOffsets: [],
  getAlertInboxRulesApiStatus: inIdle,
  alertSignins: undefined,
  loadedSigninsOffsets: [],
  getAlertSigninsApiStatus: inIdle,
  signinsByAllCountries: undefined,
  getSigninsByAllCountriesApiStatus: inIdle,
  signinsByCountry: undefined,
  loadedSigninsByCountryOffsets: [],
  getSigninsByCountryApiStatus: inIdle,
  userRelatedSignins: undefined,
  loadedUserRelatedSigninsOffsets: [],
  getUserRelatedSigninsApiStatus: inIdle,
  alertThreats: undefined,
  loadedALertThreatsOffsets: [],
  getAlertThreatsApiStatus: inIdle,
  alertDetails: undefined,
  getAlertDetailsApiStatus: inIdle,
  markAlertAsFpApiStatus: inIdle,
  closeAtoAlertApiStatus: inIdle,
  compromisedAccount: undefined,
  resetPassword: undefined
}

const resetAlerts = createAsyncThunk<number, HardResetReport>('ATO/resetAlerts', async (_, { getState }) => {
  return (getState() as RootState).dataTables.alerts.skip
})

/* eslint-disable no-param-reassign */
export const atoSlice = createSlice({
  name: 'ATO',
  initialState: INITIAL_STATE,

  // set state for compromised account
  reducers: {
    setCompromisedAccount: {
      reducer: (state: AtoState, action: PayloadAction<{ alert: Alert }>) => {
        state.compromisedAccount = action.payload.alert || {}
      },
      prepare: (alert: Alert) => ({
        payload: { alert }
      })
    },
    resetCompromisedAccount: state => {
      state.compromisedAccount = INITIAL_STATE.compromisedAccount
    },
    // set state for reset password callback
    setResetPassword: {
      reducer: (state: AtoState, action: PayloadAction<{ resetPassword: ResetPassword }>) => {
        state.resetPassword = action.payload.resetPassword || {}
      },
      prepare: (resetPassword: ResetPassword) => ({
        payload: { resetPassword }
      })
    },
    resetResetPassword: state => {
      state.resetPassword = INITIAL_STATE.resetPassword
    },

    // resetAlert
    resetAlert: (state, action: PayloadAction<HardResetReport>) => {
      state.alertInboxRules = resetReport(state.alertInboxRules, action.payload) || INITIAL_STATE.alertInboxRules
      state.loadedInboxRulesOffsets = INITIAL_STATE.loadedInboxRulesOffsets
      state.getAlertInboxRulesApiStatus = INITIAL_STATE.getAlertInboxRulesApiStatus

      state.alertSignins = resetReport(state.alertSignins, action.payload) || INITIAL_STATE.alertSignins
      state.loadedSigninsOffsets = INITIAL_STATE.loadedSigninsOffsets
      state.getAlertSigninsApiStatus = INITIAL_STATE.getAlertSigninsApiStatus

      state.alertThreats = resetReport(state.alertThreats, action.payload) || INITIAL_STATE.alertThreats
      state.loadedALertThreatsOffsets = INITIAL_STATE.loadedALertThreatsOffsets
      state.getAlertThreatsApiStatus = INITIAL_STATE.getAlertThreatsApiStatus
    },

    // reset userRelatedSignins
    resetUserRelatedSignins: (state, action: PayloadAction<HardResetReport>) => {
      state.userRelatedSignins =
        resetReport(state.userRelatedSignins, action.payload) || INITIAL_STATE.userRelatedSignins
      state.loadedUserRelatedSigninsOffsets = INITIAL_STATE.loadedUserRelatedSigninsOffsets
      state.getUserRelatedSigninsApiStatus = INITIAL_STATE.getUserRelatedSigninsApiStatus
    },

    // reset signinsByAllCountries
    resetSigninsByAllCountries: state => {
      state.signinsByAllCountries = INITIAL_STATE.signinsByAllCountries
      state.getSigninsByAllCountriesApiStatus = INITIAL_STATE.getSigninsByAllCountriesApiStatus
    },

    // reset signinsByCountry
    resetSigninsByCountry: (state, action: PayloadAction<HardResetReport>) => {
      state.signinsByCountry = resetReport(state.signinsByCountry, action.payload) || INITIAL_STATE.signinsByCountry
      state.loadedSigninsByCountryOffsets = INITIAL_STATE.loadedSigninsByCountryOffsets
      state.getSigninsByCountryApiStatus = INITIAL_STATE.getSigninsByCountryApiStatus
    },

    // resetAlertDetails
    resetAlertDetails: state => {
      state.alertDetails = INITIAL_STATE.alertDetails
      state.getAlertDetailsApiStatus = INITIAL_STATE.getAlertDetailsApiStatus
    },

    // resetMarkAlertAsFp
    resetMarkAlertAsFp: state => {
      state.markAlertAsFpApiStatus = INITIAL_STATE.markAlertAsFpApiStatus
    },

    // resetCloseAtoAlert
    resetCloseAtoAlert: state => {
      state.closeAtoAlertApiStatus = INITIAL_STATE.closeAtoAlertApiStatus
    },

    // reset
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  },
  extraReducers: builder => {
    builder
      // resetAlerts
      .addCase(resetAlerts.fulfilled, (state, action) => {
        const doHardReset = action.meta.arg

        state.alerts = resetReport(state.alerts, doHardReset) || INITIAL_STATE.alerts
        state.loadedAlertOffsets = doHardReset ? INITIAL_STATE.loadedAlertOffsets : [action.payload]
        state.getAlertsApiStatus = doHardReset ? INITIAL_STATE.getAlertsApiStatus : state.getAlertsApiStatus
      })

      // getAlerts
      .addCase(updateAlertsTable, (state, action) => {
        if (action.payload.config?.skip !== undefined) {
          state.loadedAlertOffsets = union(state.loadedAlertOffsets, [action.payload.config.skip])
        }
      })
      .addCase(getAlerts.pending, state => {
        state.getAlertsApiStatus = inProgress
        if (!state.loadedAlertOffsets.length) {
          state.loadedAlertOffsets = [alertsTableInitialState.skip]
        }
      })
      .addCase(getAlerts.fulfilled, (state, action) => {
        state.getAlertsApiStatus = successResponse
        state.alerts = insertReportData(state.alerts, action.payload as any)
      })
      .addCase(getAlerts.rejected, (state, action) => {
        state.getAlertsApiStatus = failedResponse(action.payload as string)
      })

      // getAlertInboxRules
      .addCase(updateAlertInboxRulesTable, (state, action) => {
        if (action.payload.config?.skip !== undefined) {
          state.loadedInboxRulesOffsets = union(state.loadedInboxRulesOffsets, [action.payload.config.skip])
        }
      })
      .addCase(getAlertInboxRules.pending, state => {
        state.getAlertInboxRulesApiStatus = inProgress
        if (!state.loadedInboxRulesOffsets.length) {
          state.loadedInboxRulesOffsets = [alertInboxRulesTableInitialState.skip]
        }
      })
      .addCase(getAlertInboxRules.fulfilled, (state, action) => {
        state.getAlertInboxRulesApiStatus = successResponse
        state.alertInboxRules = insertReportData(state.alertInboxRules, action.payload as any)
      })
      .addCase(getAlertInboxRules.rejected, (state, action) => {
        state.getAlertInboxRulesApiStatus = failedResponse(action.payload as string)
      })

      // getAlertSignins
      .addCase(updateAlertSigninsTable, (state, action) => {
        if (action.payload.config?.skip !== undefined) {
          state.loadedSigninsOffsets = union(state.loadedSigninsOffsets, [action.payload.config.skip])
        }
      })
      .addCase(getAlertSignins.pending, state => {
        state.getAlertSigninsApiStatus = inProgress
        if (!state.loadedSigninsOffsets.length) {
          state.loadedSigninsOffsets = [alertSigninsTableInitialState.skip]
        }
      })
      .addCase(getAlertSignins.fulfilled, (state, action) => {
        state.getAlertSigninsApiStatus = successResponse
        state.alertSignins = insertReportData(state.alertSignins, action.payload as any)
      })
      .addCase(getAlertSignins.rejected, (state, action) => {
        state.getAlertSigninsApiStatus = failedResponse(action.payload as string)
      })

      // getSigninsByAllCountries
      .addCase(getSigninsByAllCountries.pending, state => {
        state.getSigninsByAllCountriesApiStatus = inProgress
        if (state.signinsByAllCountries) {
          state.signinsByAllCountries.report.countries = []
        }
      })
      .addCase(getSigninsByAllCountries.fulfilled, (state, action) => {
        state.getSigninsByAllCountriesApiStatus = successResponse
        state.signinsByAllCountries = action.payload
      })
      .addCase(getSigninsByAllCountries.rejected, (state, action) => {
        state.getSigninsByAllCountriesApiStatus = failedResponse(action.payload as string)
      })

      // GetSigninsByCountry
      .addCase(updateSigninByCountryTable, (state, action) => {
        if (action.payload.config?.skip !== undefined) {
          state.loadedSigninsByCountryOffsets = union(state.loadedSigninsByCountryOffsets, [action.payload.config.skip])
        }
      })
      .addCase(getSigninsByCountry.pending, state => {
        state.getSigninsByCountryApiStatus = inProgress
        if (!state.loadedSigninsByCountryOffsets.length) {
          state.loadedSigninsByCountryOffsets = [signinByCountryTableInitialState.skip]
        }
      })
      .addCase(getSigninsByCountry.fulfilled, (state, action) => {
        state.getSigninsByCountryApiStatus = successResponse
        state.signinsByCountry = insertReportData(state.signinsByCountry, action.payload as any)
      })
      .addCase(getSigninsByCountry.rejected, (state, action) => {
        state.getSigninsByCountryApiStatus = failedResponse(action.payload as string)
      })

      // getUserRelatedSignins
      .addCase(updateUserRelatedSigninsTable, (state, action) => {
        if (action.payload.config?.skip !== undefined) {
          state.loadedUserRelatedSigninsOffsets = union(state.loadedUserRelatedSigninsOffsets, [
            action.payload.config.skip
          ])
        }
      })
      .addCase(getUserRelatedSignins.pending, state => {
        state.getUserRelatedSigninsApiStatus = inProgress
        if (!state.loadedUserRelatedSigninsOffsets.length) {
          state.loadedUserRelatedSigninsOffsets = [userRelatedSigninsTableInitialState.skip]
        }
      })
      .addCase(getUserRelatedSignins.fulfilled, (state, action) => {
        state.getUserRelatedSigninsApiStatus = successResponse
        state.userRelatedSignins = insertReportData(state.userRelatedSignins, action.payload as any)
      })
      .addCase(getUserRelatedSignins.rejected, (state, action) => {
        state.getUserRelatedSigninsApiStatus = failedResponse(action.payload as string)
      })

      // getAlertThreats
      .addCase(updateAlertThreatsTable, (state, action) => {
        if (action.payload.config?.skip !== undefined) {
          state.loadedALertThreatsOffsets = union(state.loadedALertThreatsOffsets, [action.payload.config.skip])
        }
      })
      .addCase(getAlertThreats.pending, state => {
        state.getAlertThreatsApiStatus = inProgress
        if (!state.loadedALertThreatsOffsets.length) {
          state.loadedALertThreatsOffsets = [alertThreatsTableInitialState.skip]
        }
      })
      .addCase(getAlertThreats.fulfilled, (state, action) => {
        state.getAlertThreatsApiStatus = successResponse
        state.alertThreats = insertReportData(state.alertThreats, action.payload as any)
      })
      .addCase(getAlertThreats.rejected, (state, action) => {
        state.getAlertThreatsApiStatus = failedResponse(action.payload as string)
      })

      // getAlertsSummary
      .addCase(getAlertSummary.pending, state => {
        state.getAlertSummaryApiStatus = inProgress
        state.alertSummary = INITIAL_STATE.alertSummary
      })
      .addCase(getAlertSummary.fulfilled, (state, { payload }) => {
        state.getAlertSummaryApiStatus = successResponse
        state.alertSummary = payload
      })
      .addCase(getAlertSummary.rejected, (state, action) => {
        state.getAlertSummaryApiStatus = failedResponse(action.payload as string)
      })

      // getAlertDetails
      .addCase(getAlertDetails.pending, state => {
        state.getAlertDetailsApiStatus = inProgress
        state.alertDetails = INITIAL_STATE.alertDetails
      })
      .addCase(getAlertDetails.fulfilled, (state, { payload }) => {
        state.getAlertDetailsApiStatus = successResponse
        state.alertDetails = payload
      })
      .addCase(getAlertDetails.rejected, (state, action) => {
        state.getAlertDetailsApiStatus = failedResponse(action.payload as string)
      })

      // markAlertAsFp
      .addCase(markAlertAsFp.pending, state => {
        state.markAlertAsFpApiStatus = inProgress
      })
      .addCase(markAlertAsFp.fulfilled, state => {
        state.markAlertAsFpApiStatus = successResponse
      })
      .addCase(markAlertAsFp.rejected, (state, action) => {
        state.markAlertAsFpApiStatus = failedResponse(action.payload as string)
      })

      // closeAtoAlert
      .addCase(closeAtoAlert.pending, state => {
        state.closeAtoAlertApiStatus = inProgress
      })
      .addCase(closeAtoAlert.fulfilled, state => {
        state.closeAtoAlertApiStatus = successResponse
      })
      .addCase(closeAtoAlert.rejected, (state, action) => {
        state.closeAtoAlertApiStatus = failedResponse(action.payload as string)
      })
  }
})
/* eslint-enable no-param-reassign */

export const {
  resetAlert,
  resetAlertDetails,
  resetSigninsByAllCountries,
  resetSigninsByCountry,
  resetUserRelatedSignins,
  resetMarkAlertAsFp,
  setCompromisedAccount,
  resetCompromisedAccount,
  setResetPassword,
  resetResetPassword,
  resetCloseAtoAlert,
  reset
} = atoSlice.actions

export {
  resetAlerts,
  getAlerts,
  getAlertInboxRules,
  getAlertSignins,
  getUserRelatedSignins,
  getSigninsByAllCountries,
  getSigninsByCountry,
  getAlertThreats,
  getAlertSummary,
  getAlertDetails,
  markAlertAsFp,
  closeAtoAlert,
  DEFAULT_ALERT_SUMMARY_TIMEFRAME
}

export default atoSlice.reducer
