import { createSlice } from '@reduxjs/toolkit'
import { NewIncidentState } from 'fir/redux/types/NewIncident'
import insertToArray from 'global/lib/insertToArray'
import { failedResponse, inIdle, inProgress, successResponse } from 'global/redux/toolkit/api'
import { getEmails, getMoreEmails } from './newIncidentApiThunks'

const DEFAULT_TIMEFRAME = 720

const POLICY_PLACES = {
  QUARANTINE: 'quarantine',
  BLOCK: 'block'
}

const INVOLVED_SCOPES = {
  SENDERS: 'senders',
  DOMAINS: 'domains'
}

const NOTIFICATION_TEMPLATES = {
  DEFAULT: 'Default',
  CUSTOM: 'Custom'
}

export const INITIAL_STATE: NewIncidentState = {
  form: {
    senderEmail: '',
    senderName: '',
    emailSubject: '',
    attachmentName: '',
    bodyText: '',
    bodyLinks: '',
    timeframe: DEFAULT_TIMEFRAME,
    isIncludeQuarantined: true,
    isMatchExactFrame: true,
    isFormError: false
  },
  userOptions: {
    NOTIFICATION_TEMPLATES,
    isDeleteSelectedEmails: true,
    isTurnOnContinuousRemediation: false,
    isSendIncidentSummary: true,
    isSendWarningEmail: true,
    notificationTemplate: NOTIFICATION_TEMPLATES.DEFAULT
  },
  policyOptions: {
    POLICY_PLACES,
    INVOLVED_SCOPES,
    isAddSenderPolicy: false,
    policyPlace: POLICY_PLACES.QUARANTINE,
    involvedScope: INVOLVED_SCOPES.SENDERS,
    isBlockAllUserWebTraffic: false,
    comment: ''
  },
  getEmailsApiStatus: inIdle,
  getMoreEmailsApiStatus: inIdle,
  emails: {
    dslQuery: '',
    results: [],
    searchId: '',
    totalCount: 0
  }
}

/* eslint-disable no-param-reassign */
export const newIncidentSlice = createSlice({
  name: 'NEW_INCIDENT',
  initialState: INITIAL_STATE,
  reducers: {
    updateForm: (state, action) => {
      state.form = {
        ...state.form,
        ...action.payload.formValues
      }
    },
    updateUserOptions: (state, action) => {
      state.userOptions = {
        ...state.userOptions,
        ...action.payload.userOptions
      }
    },
    updatePolicyOptions: (state, action) => {
      state.policyOptions = {
        ...state.policyOptions,
        ...action.payload.policyOptions
      }
    },
    resetEmails: state => {
      state.emails = INITIAL_STATE.emails
      state.getEmailsApiStatus = INITIAL_STATE.getEmailsApiStatus
      state.getMoreEmailsApiStatus = INITIAL_STATE.getMoreEmailsApiStatus
    },
    reset: () => {
      return { ...INITIAL_STATE }
    }
  },

  // extraReducers do not create an action but executes the reducer function
  extraReducers: builder => {
    builder
      .addCase(getEmails.pending, state => {
        state.emails = { ...INITIAL_STATE.emails }
        state.getEmailsApiStatus = inProgress
      })
      .addCase(getEmails.fulfilled, (state, action) => {
        state.emails = action.payload.emails
        state.getEmailsApiStatus = successResponse
      })
      .addCase(getEmails.rejected, (state, action) => {
        state.getEmailsApiStatus = failedResponse(action.payload as string)
      })
      .addCase(getMoreEmails.pending, (state, action) => {
        state.getMoreEmailsApiStatus = inProgress
        if (action.meta.arg.resetResult) {
          state.emails = {
            ...state.emails,
            results: []
          }
        }
      })
      .addCase(getMoreEmails.fulfilled, (state, action) => {
        state.getMoreEmailsApiStatus = successResponse
        state.emails = {
          ...state.emails,
          results: insertToArray(
            state.emails.results,
            (action.payload.emails || {}).results || [],
            action.payload.skip || 0
          )
        }
      })
      .addCase(getMoreEmails.rejected, (state, action) => {
        state.getMoreEmailsApiStatus = failedResponse(action.payload as string)
      })
  }
})

export const { updateForm, updateUserOptions, updatePolicyOptions, resetEmails, reset } = newIncidentSlice.actions

export { getEmails, getMoreEmails }

export default newIncidentSlice.reducer
