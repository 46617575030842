import { useMemo, useCallback, useState } from 'react'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { isSuccess } from 'global/redux/toolkit/api'

import { useAppSelector } from 'ets/redux/toolkit/hooks'

export interface PdfConfig {
  isPDFRendering: boolean
  isButtonDisabled: boolean
  isPDFReportRequested: boolean
  isUIReportsLoaded: boolean
  setPDFRenderingStatus: (newStatus: boolean) => void
  onClick: () => void
}

export default function useShareMenuLogic(): [PdfConfig] {
  const {
    accessToken,
    scanStatsId,
    isDomainsLoaded,
    isEmployeesLoaded,
    isThreatsLoaded,
    isAttacksLoaded,
    isChartReportLoaded
  } = useAppSelector(_stores => ({
    accessToken: _stores.accessToken?.accessToken,
    scanStatsId: _stores.scan.stats?.id,
    isDomainsLoaded: isSuccess(_stores.reports.domains.apiStatus),
    isEmployeesLoaded: isSuccess(_stores.reports.employees.apiStatus),
    isThreatsLoaded: isSuccess(_stores.reports.threats.apiStatus),
    isAttacksLoaded: isSuccess(_stores.reports.attacksByDomain.apiStatus),
    isChartReportLoaded: isSuccess(_stores.reports.chartReport.apiStatus)
  }))

  const [isPDFReportRequested, setIsPDFReportRequested] = useState<boolean>(false)

  const isUIReportsLoaded = useMemo(() => {
    return (
      !!accessToken?.name &&
      !!accessToken?.created &&
      !!scanStatsId &&
      isDomainsLoaded &&
      isThreatsLoaded &&
      isEmployeesLoaded &&
      isAttacksLoaded &&
      isChartReportLoaded
    )
  }, [
    accessToken,
    scanStatsId,
    isDomainsLoaded,
    isThreatsLoaded,
    isEmployeesLoaded,
    isAttacksLoaded,
    isChartReportLoaded
  ])

  const setPDFRenderingStatus = useCallback((isLoading: boolean) => {
    if (!isLoading) {
      setTimeout(() => {
        const renderedPDF: HTMLInputElement | null = document.querySelector('[data-pdf-report="true"] a')

        if (renderedPDF) {
          renderedPDF.click()
        }

        setTimeout(() => {
          setIsPDFReportRequested(false)
        }, 0)
      }, 0)
    }
  }, [])

  const isPDFButtonDisabled = useMemo(() => {
    return !isUIReportsLoaded || isPDFReportRequested
  }, [isUIReportsLoaded, isPDFReportRequested])

  const onPDFButtonClick = useCallback(() => {
    if (!isPDFButtonDisabled) {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.PDF_REPORT_REQUEST)
      setIsPDFReportRequested(true)
    }
  }, [isPDFButtonDisabled])

  return useMemo(
    () => [
      {
        isPDFRendering: isPDFReportRequested,
        isButtonDisabled: isPDFButtonDisabled,
        isPDFReportRequested,
        isUIReportsLoaded,
        setPDFRenderingStatus,
        onClick: onPDFButtonClick
      }
    ],
    [isUIReportsLoaded, isPDFButtonDisabled, setPDFRenderingStatus, isPDFReportRequested, onPDFButtonClick]
  )
}
