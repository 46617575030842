import React from 'react'
import { makeStyles, Chip, Tooltip } from '@material-ui/core'

const useStyles = makeStyles(() => {
  return {
    root: {
      padding: '14px 16px',
      borderBottom: 'thin solid #E0E0E0'
    },
    chip: {
      marginRight: '4px',
      marginBottom: '4px'
    }
  }
})

type Props = {
  list: string[]
  truncateAt?: number
}

function ChipList({ list, truncateAt = 2 }: Props) {
  const classes = useStyles()

  const displaylist = list.slice(0, truncateAt)
  const remainingList = list.slice(truncateAt)

  const displayChips = displaylist.map((displayItem: string) => (
    <Chip size="small" className={classes.chip} key={displayItem} label={displayItem} />
  ))

  if (remainingList.length > 0) {
    const title = remainingList.map((displayItem: string) => displayItem).join(', ')

    displayChips.push(
      <Tooltip title={title} placement="bottom" key="remaining">
        <Chip size="small" className={classes.chip} label={`+${remainingList.length} more`} />
      </Tooltip>
    )
  }

  return <div>{displayChips}</div>
}

export default ChipList
