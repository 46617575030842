import { useEffect, useMemo } from 'react'

import { FEATURES, isMyFeatureOn } from 'global/lib/splitio/splitio'
import useUserDataLib from 'global/lib/userData/useUserData'
import { getConvergedMessageLogs, MessageLog } from 'global/redux/features/convergedMessageLog/convergedMessageLogSlice'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import routesConfig from 'sen/lib/routes/routesConfig'

export interface ConvergedMessageLogLogic {
  messageLogs: MessageLog[]
}

export default function useConvergedMessageLogLogic(): { messageLogs: MessageLog[] }[] {
  const dispatch = useAppDispatch()
  const [userDataLib] = useUserDataLib()
  const { accessTokenId, messageLogs, userBccId, splitStore } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id,
    messageLogs: _stores.convergedMessageLog.messageLogs,
    userBccId: _stores.user.data?.bccUserId,
    splitStore: _stores.splitio
  }))

  // Check if ConvergedMessageLog feature is on
  const accountId = useMemo(() => userDataLib.getAccountByAccessToken(accessTokenId)?.accountId, [
    accessTokenId,
    userDataLib
  ])
  const isConvergedMessageLogFeatureOn = useMemo(
    () => isMyFeatureOn(splitStore, FEATURES.EGD_IP_IR_CONVERGED_EMAIL_LOG_ROLLOUT, accountId),
    [accountId, splitStore]
  )

  // If convergedMessageLog feature is not on, redirect to dashboard
  useEffect(() => {
    if (!isConvergedMessageLogFeatureOn) {
      routesConfig.DASHBOARD.goto({ reportId: accessTokenId })
    } else if (userBccId) {
      dispatch(getConvergedMessageLogs(userBccId))
    }
  }, [accessTokenId, accountId, dispatch, isConvergedMessageLogFeatureOn, userBccId])

  return useMemo(() => [{ messageLogs }], [messageLogs])
}
