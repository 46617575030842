import React from 'react'
import PropTypes from 'prop-types'

export interface CellLabelTypeProps {
  align?: 'left' | 'right'
  children: any
}

const CellLabelType: React.FC<CellLabelTypeProps> = ({ children, align = 'left' }) => {
  return <span style={{ float: align }}>{children}</span>
}

CellLabelType.propTypes = {
  children: PropTypes.any.isRequired,
  align: PropTypes.any
}

CellLabelType.defaultProps = {
  align: 'left'
}

export default CellLabelType
