import React from 'react'

import dfpContentLoader from 'sen/components/lib/useDFPContentCondition/DFPContentLoader'

import DomainFraudDmarcReviewContent from 'sen/components/pages/domainFraudDmarcReview/content/DomainFraudDmarcReviewContent'
import { DmarcReviewTabs } from 'sen/components/pages/domainFraudDmarcReview/domainFraudDmarcReviewTypes'

export default dfpContentLoader({
  default: () => <DomainFraudDmarcReviewContent activeTab={DmarcReviewTabs.approved} />
})
