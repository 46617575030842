import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  formControl: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: 'block'
  },
  dropdown: {
    width: '100%'
  },
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'block',
    '& .MuiInput-root': {
      width: '100%'
    }
  }
}))
