import { useMemo, useCallback, useReducer, useEffect } from 'react'

import Cookies from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { config } from 'global/lib/config'

export interface ExportToCsvButtonLogicProps {
  getExportPath: (sessionId: string) => string | undefined
  exportName: string
  analyticsParams: { [key: string]: string }
  totalCount?: number
}

export type SrcPath = string | undefined
export type OnExport = () => void
export type ShouldShowTooltip = boolean
export type ExportLimit = number

export const CSV_EXPORT_LIMIT = 10000
export const INCREASED_BACKEND_EXPORT_LIMIT = 20000

export interface State {
  exportPath: string | undefined
  sessionId: string | undefined
  retries: number
}

let timeout: any

export default function useExportToCsvButtonLogic({
  getExportPath,
  exportName,
  analyticsParams,
  totalCount = 0
}: ExportToCsvButtonLogicProps): [SrcPath, OnExport, ShouldShowTooltip, ExportLimit] {
  const [state, setState] = useReducer((_state: State, newState: Partial<State>) => ({ ..._state, ...newState }), {
    exportPath: undefined,
    sessionId: undefined,
    retries: 0
  })

  // init
  useEffect(() => {
    // clear timeout on unmount
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [])

  // check csv-report cookie
  useEffect(() => {
    if (state.retries) {
      if (
        Cookies.get(config.COOKIES.CSV_EXPORT_IN_PROGRESS) !== state.sessionId &&
        state.retries <= config.CSV_EXPORT_COOKIE.MAX_RETRIES
      ) {
        timeout = setTimeout(() => {
          setState({ retries: state.retries + 1 })
        }, config.CSV_EXPORT_COOKIE.CHECK_INTERVAL)
      } else {
        setState({ exportPath: undefined, sessionId: undefined, retries: 0 })
      }
    }
  }, [state.sessionId, state])

  const onExportData = useCallback(() => {
    if (!state.exportPath) {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.exportAsCsv(exportName), { ...analyticsParams })

      const sessionId = uuidv4()
      setState({
        exportPath: getExportPath(sessionId),
        sessionId
      })

      timeout = setTimeout(() => {
        setState({ retries: 1 })
      }, config.CSV_EXPORT_COOKIE.CHECK_INTERVAL)
    }
  }, [state.exportPath, analyticsParams, exportName, getExportPath])

  const exportLimit = useMemo(() => {
    return exportName === 'SP Mailboxes' ? INCREASED_BACKEND_EXPORT_LIMIT : CSV_EXPORT_LIMIT
  }, [exportName])

  const shouldShowTooltip = useMemo(() => {
    return totalCount > exportLimit
  }, [exportLimit, totalCount])

  return useMemo(() => {
    return [state.exportPath, onExportData, shouldShowTooltip, exportLimit]
  }, [state.exportPath, onExportData, shouldShowTooltip, exportLimit])
}
