import React from 'react'
import PropTypes from 'prop-types'

import { Button, Link, Radio, RadioGroup, TextField, Typography, Grid } from '@barracuda-internal/bds-core'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'

import { config } from 'global/lib/config'
import { useFormatMessage } from 'global/lib/localization'

import YesNoSwitch from 'global/components/lib/form/YesNoSwitch'
import styles, { potentialIncidentsSettingsStyles } from '../SettingsStyles'

const BASE_I18N_KEY = 'fir.app.settings_dialog'

const AutomaticRemediationSettings: React.FC<any> = ({
  automaticRemediationSettingsOptions: {
    forensicsSettings,
    isBasicProduct,
    isInvalidEmail,
    openCustomizeEmailAlertDialog,
    updateAutomaticRemediationSettingsOptions
  }
}) => {
  const baseClasses = styles()
  const classes = potentialIncidentsSettingsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return (
    <div data-field="wrapper" className={baseClasses.wrapper}>
      <div>
        <Typography data-field="header" variant="h5">
          {formatMessage('automatic_remediation.labels.automatic_remediation')}
        </Typography>
        <Typography variant="subtitle2">
          <div>
            <span className={`${baseClasses.opacity}`}>
              {formatMessage('automatic_remediation.labels.automatic_remediation_subtitle')}
            </span>{' '}
            <Link target="_blank" href={config.LINKS.CAMPUS_CREATE_INCIDENT}>
              {formatMessage('learn_more')}...
            </Link>
          </div>
        </Typography>
      </div>
      <br />
      <FormControl data-field="root-form" component="fieldset" className={classes.formControl}>
        <Grid data-field="root-grid" component="div" container>
          <Grid component="label" className={classes.label} xs={6} item>
            <Typography variant="body1">{formatMessage('automatic_remediation.labels.activate')}</Typography>
          </Grid>
          <Grid data-field="enable-automatic-remediation-grid" xs={6} item>
            <YesNoSwitch
              checked={forensicsSettings.forensicsIncidentAutoRemediateEmails}
              onChange={updateAutomaticRemediationSettingsOptions('forensicsIncidentAutoRemediateEmails', true)}
            />
          </Grid>
          <Grid
            data-field="automatic-remediation-grid"
            component="div"
            container
            spacing={2}
            className={`${
              !forensicsSettings.forensicsIncidentAutoRemediateEmails && isBasicProduct ? baseClasses.disabled : ''
            } ${classes.subContainer}`}
          >
            <Grid data-field="create-incident-grid" component="label" className={classes.label} xs={12} item>
              <FormControl>
                <FormLabel data-field="create-incident-label">
                  {formatMessage('automatic_remediation.labels.create_incident')}:
                </FormLabel>
                <RadioGroup
                  className={classes.radioButtonGroup}
                  name="delete-emails"
                  value={forensicsSettings.forensicsAutomaticIncidentDeleteEmails}
                  onChange={updateAutomaticRemediationSettingsOptions('forensicsAutomaticIncidentDeleteEmails')}
                >
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    value={false}
                    label={formatMessage('automatic_remediation.labels.move_emails')}
                  />
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    value
                    label={formatMessage('automatic_remediation.labels.delete_emails')}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid
              data-field="continuous-remediation-label"
              component="label"
              className={`${!forensicsSettings.forensicsAutomaticIncidentDeleteEmails ? baseClasses.disabled : ''} ${
                classes.label
              }`}
              xs={6}
              item
            >
              <Typography className={baseClasses.subSetting} variant="body1">
                {formatMessage('remediation.labels.continuous_remediation')}
              </Typography>
            </Grid>
            <Grid
              data-field="continuous-remediation-grid"
              className={!forensicsSettings.forensicsAutomaticIncidentDeleteEmails ? baseClasses.disabled : ''}
              xs={6}
              item
            >
              <YesNoSwitch
                checked={forensicsSettings.forensicsAutomaticIncidentContinuousRemediation}
                onChange={updateAutomaticRemediationSettingsOptions(
                  'forensicsAutomaticIncidentContinuousRemediation',
                  true
                )}
              />
            </Grid>
            <Grid component="label" className={classes.label} xs={6} item>
              <Typography variant="body1">
                {formatMessage('remediation.labels.ignore_phishline_campaign_emails')}{' '}
              </Typography>
            </Grid>
            <Grid data-field="ignore-phishline-grid" xs={6} item>
              <YesNoSwitch
                checked={forensicsSettings.forensicsAutomaticIncidentIgnorePhishline}
                onChange={updateAutomaticRemediationSettingsOptions('forensicsAutomaticIncidentIgnorePhishline', true)}
              />
            </Grid>
            <Grid component="label" className={classes.label} xs={6} item>
              <Typography variant="body1">{formatMessage('remediation.labels.notify_end_users')} </Typography>
            </Grid>
            <Grid data-field="incident-alert-grid" xs={6} item>
              <YesNoSwitch
                checked={forensicsSettings.forensicsAutomaticIncidentAlertEndUser}
                onChange={updateAutomaticRemediationSettingsOptions('forensicsAutomaticIncidentAlertEndUser', true)}
              />
            </Grid>
            <Grid
              data-field="customize-alert-grid"
              className={`${!forensicsSettings.forensicsAutomaticIncidentAlertEndUser ? baseClasses.disabled : ''}`}
              xs={12}
              item
            >
              <Button
                className={baseClasses.emailAlertButton}
                color="primary"
                variant="text"
                onClick={openCustomizeEmailAlertDialog}
              >
                {formatMessage('remediation.labels.customize_alert')}
              </Button>
            </Grid>
            <Grid component="label" className={classes.label} xs={6} item>
              <Typography variant="body1">{formatMessage('remediation.labels.notify_admin_address')}</Typography>
            </Grid>
            <Grid data-field="admin-incident-alert-grid" xs={6} item>
              <YesNoSwitch
                checked={forensicsSettings.forensicsAutomaticIncidentAlertAdmin}
                onChange={updateAutomaticRemediationSettingsOptions('forensicsAutomaticIncidentAlertAdmin', true)}
              />
            </Grid>
            <Grid
              data-field="email-field"
              className={!forensicsSettings.forensicsAutomaticIncidentAlertAdmin ? baseClasses.disabled : ''}
              xs={6}
              item
            >
              <div className={baseClasses.subSetting}>
                <TextField
                  className={classes.formLine}
                  value={forensicsSettings.forensicsAutomaticIncidentNotifyAddress}
                  onChange={updateAutomaticRemediationSettingsOptions('forensicsAutomaticIncidentNotifyAddress')}
                  label={formatMessage('remediation.labels.security_team_email')}
                  error={isInvalidEmail}
                  helperText={isInvalidEmail ? formatMessage('labels.invalid_email') : ''}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </FormControl>
    </div>
  )
}

AutomaticRemediationSettings.propTypes = {
  automaticRemediationSettingsOptions: PropTypes.any.isRequired
}

export default AutomaticRemediationSettings
