import requestMiddlewareGenerator from 'global/lib/api/requestMiddleware'

import { reduxStore } from 'global/lib/reduxStore'

export default requestMiddlewareGenerator({
  additionalConfigGenerator: baseConfig => {
    return {
      data: {
        ...baseConfig.data,
        ...(reduxStore && { shareSecret: reduxStore.getState().accessToken.shareSecret?.value || undefined })
      }
    }
  }
})
