import { createSlice } from '@reduxjs/toolkit'
import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'global/redux/toolkit/api'
import { sendFeedback } from './feedbackApiThunk'

export interface FeedbackState {
  sendFeedbackApiStatus: ApiStatus
  redirect: string | undefined
  error: string | undefined
}

export const INITIAL_STATE: FeedbackState = {
  sendFeedbackApiStatus: inIdle,
  redirect: undefined,
  error: undefined
}

/* eslint-disable no-param-reassign */
export const feedbackSlice = createSlice({
  name: 'FEEDBACK',
  initialState: INITIAL_STATE,
  reducers: {
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(sendFeedback.pending, state => {
        state.sendFeedbackApiStatus = inProgress
      })
      .addCase(sendFeedback.fulfilled, (state, action) => {
        state.sendFeedbackApiStatus = successResponse
        state.redirect = action.payload.redirect
      })
      .addCase(sendFeedback.rejected, (state, action) => {
        const error = action.payload as string
        state.sendFeedbackApiStatus = failedResponse(error)
        state.error = error === 'duplicate_whitelist' ? undefined : error
      })
  }
})
/* eslint-enable no-param-reassign */

export const { reset } = feedbackSlice.actions

export { sendFeedback }

export default feedbackSlice.reducer
