import React, { useMemo } from 'react'

import { Typography, Button, DialogTitle, DialogContent, DialogActions, Grid } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'global/lib/localization'

import styles from 'sen/components/lib/dialogs/addDomainWizardDialog/addDomainWizardDialogStyles'
import { StepProps } from 'sen/components/lib/dialogs/addDomainWizardDialog/addDomainWizardDialogTypes'

const BASE_I18N_KEY = 'sen.app.add_domain_dialog'

export type DomainInfoConfig = [string, string, 2 | 4]

export const AddDomainStepVerificationSuccess: React.FC<StepProps> = ({ config, events }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  return useMemo(
    () => (
      <>
        <DialogTitle data-step-name="verification-success">
          <Typography className={classes.dialogTitle}>{config.domainName}</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container direction="column">
            <Typography className={classes.dialogBiggerText}>
              {formatMessage('verification_success.subtitle_1')}
            </Typography>
            <Typography className={classes.dialogText}>{formatMessage('verification_success.subtitle_2')}</Typography>
            <Typography className={classes.dialogText}>{formatMessage('verification_success.subtitle_3')}</Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="text" size="large" onClick={events.onCloseDialog}>
            {formatMessage('cancel')}
          </Button>
          <Button color="primary" variant="contained" size="large" onClick={events.onNextStep}>
            {formatMessage('setup_dmarc')}
          </Button>
        </DialogActions>
      </>
    ),
    [classes, formatMessage, config, events]
  )
}

export default AddDomainStepVerificationSuccess
