import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { validateApiError } from 'global/lib/api/restClient'
import { config } from 'global/lib/config'

import apiRoutes from 'sen/lib/api/apiRoutes'

export type GetCmStats = string | undefined

export const getCmStats = createAsyncThunk('CM/getCmStats', async function doGetCmStats(
  provider = config.CLOUD_PROVIDERS.office365.id,
  { rejectWithValue }
) {
  try {
    const resp = await restClient(apiRoutes.CM_STATS, {
      data: { provider }
    })

    return resp.data.stats
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})
