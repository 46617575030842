import { Column } from 'global/types/api/unifiedReporting'

import { pipelineSpThreatFieldsColumnConfig } from './pipelineSpThreat'
import { BASE_DATA_TYPES } from './constants'

export const getColumnConfig = (baseDataType?: string): Column[] => {
  switch (baseDataType) {
    case BASE_DATA_TYPES.PIPELINE_SP_THREAT:
      return pipelineSpThreatFieldsColumnConfig
    default:
      return []
  }
}
