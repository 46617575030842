import { useEffect, useMemo, useCallback, useReducer } from 'react'

import { isNumber, orderBy } from 'lodash'

import { Recipient, IdentityDetails } from 'global/types/api/identity'

import { reset as resetIdentity } from 'sen/redux/features/identity/identitySlice'
import { useAppDispatch } from 'sen/redux/toolkit/hooks'

export type OnTestAttack = () => void

export interface IdentityInputConfig {
  value: string
  error: boolean
}

export interface AutocompleteWidgetConfig {
  options: Recipient[]
  getOptionLabel: (option: Recipient) => string
  open: boolean
  onOpen: (e: any) => void
  onInputChange: (e: any) => void
  onChange: (_e: any, selectedValue: any) => void
  onClose: () => void
  getOptionSelected: (option: Recipient, value: any) => boolean
  value: Recipient | undefined
  loading: boolean
  onFocus: () => void
  onBlur: () => void
  renderOption: (identity: Recipient) => string
}

export interface IdentityAutocompleteConfig {
  widget: AutocompleteWidgetConfig
  input: IdentityInputConfig
}

export type SelectedIdentity = Recipient | undefined

export interface State {
  text: string
  isSetSelectedIdentiy: boolean
  isVisited: boolean
  selectedIdentity: SelectedIdentity
  isMenuOpened: boolean
}

export interface IdentityAutocompleteLogicProps {
  identites: Recipient[] | undefined
  isLoading: boolean
  isSuccess: boolean
  identityDetails: IdentityDetails | undefined
  getIdentities: any
  getDetails: any
  resetIdentityDetails: any
  shouldSetInititalIdentity?: boolean
  isRequired?: boolean
}

export default function useIdentitiesAutocompleteLogic({
  identites,
  isLoading,
  isSuccess,
  identityDetails,
  resetIdentityDetails,
  getIdentities,
  getDetails,
  shouldSetInititalIdentity = true,
  isRequired = false
}: IdentityAutocompleteLogicProps): [IdentityAutocompleteConfig, SelectedIdentity] {
  const dispatch = useAppDispatch()

  const [state, setState] = useReducer((_state: State, newState: Partial<State>) => ({ ..._state, ...newState }), {
    text: '',
    isSetSelectedIdentiy: false,
    isVisited: false,
    selectedIdentity: undefined,
    isMenuOpened: false
  })

  // init
  useEffect(() => {
    return () => {
      dispatch(resetIdentity())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // identity autocomplete callback
  useEffect(() => {
    if (isSuccess && !state.selectedIdentity?.email && state.text) {
      const matchedIdentity = identites?.find((identity: Recipient) => {
        return identity.email === state.text
      })

      if (matchedIdentity) {
        setState({ selectedIdentity: matchedIdentity, isMenuOpened: false })
      }
    }
  }, [state.text, state.selectedIdentity, isSuccess, identites])

  // load the identity details when the selected identity is changed
  useEffect(() => {
    if (state.selectedIdentity?.id && state.selectedIdentity.id !== identityDetails?.id) {
      dispatch(getDetails(state.selectedIdentity.id))
    } else if (!state.selectedIdentity?.id) {
      dispatch(resetIdentityDetails())
    }
  }, [dispatch, state.selectedIdentity, identityDetails, getDetails, resetIdentityDetails])

  // set the initial selected identity
  useEffect(() => {
    if (shouldSetInititalIdentity && identites && !state.selectedIdentity) {
      setState({ selectedIdentity: identites[0], isSetSelectedIdentiy: true })
    }
  }, [shouldSetInititalIdentity, identites, state.selectedIdentity])

  const onOpenMenu = useCallback(
    e => {
      dispatch(getIdentities(e.target.value))
      setState({ isMenuOpened: true, isVisited: true })
    },
    [dispatch, getIdentities]
  )

  const onIdentitiesInputChange = useCallback(
    e => {
      if (e) {
        const newValue = e.target.value
        const isSelectedIdentity = isNumber(e.target.value)
        const identityText = isSelectedIdentity ? identites?.[newValue].email : e.target.value

        dispatch(getIdentities(identityText))
        setState({
          text: identityText,
          ...(isSelectedIdentity && { isSetSelectedIdentiy: true }),
          selectedIdentity: isSelectedIdentity ? identites?.[newValue] : ({} as Recipient)
        })
      }
    },
    [dispatch, identites, getIdentities]
  )

  const onIdentitiesChange = useCallback((_e, selectedValue) => {
    if (selectedValue) {
      setState({ selectedIdentity: selectedValue || ({} as Recipient), isMenuOpened: false })
    }
  }, [])

  const onIdentitiesMenuClose = useCallback(() => {
    setState({ isMenuOpened: false })
  }, [])

  const onFocus = useCallback(() => {
    setState({ isVisited: true })
  }, [])

  const onBlur = useCallback(() => {
    if (!isRequired) {
      setState({ isVisited: false, isSetSelectedIdentiy: false })
    }
  }, [isRequired])

  return useMemo(() => {
    return [
      {
        widget: {
          options: orderBy(identites, 'displayName'),
          open: state.isMenuOpened,
          onOpen: onOpenMenu,
          onInputChange: onIdentitiesInputChange,
          onChange: onIdentitiesChange,
          onClose: onIdentitiesMenuClose,
          getOptionSelected: (identity, value) => identity?.email === value?.email,
          getOptionLabel: identity => identity?.email || state.text || '',
          renderOption: (identity: Recipient) => `${identity?.displayName} <${identity?.email}>`,
          value: state.selectedIdentity || ({} as Recipient),
          loading: isLoading && state.isMenuOpened,
          onFocus,
          onBlur
        },
        input: {
          value: state.text,
          error: state.isVisited && state.isSetSelectedIdentiy && !state.selectedIdentity?.email
        }
      },
      state.selectedIdentity?.id ? state.selectedIdentity : undefined
    ]
  }, [
    state,
    isLoading,
    identites,
    onOpenMenu,
    onIdentitiesInputChange,
    onIdentitiesMenuClose,
    onIdentitiesChange,
    onFocus,
    onBlur
  ])
}
