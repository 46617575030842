import React, { useMemo } from 'react'

import {
  Button,
  Link,
  Typography,
  RadioGroup,
  SelectWrapper,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
  MenuItem,
  Select
} from '@barracuda-internal/bds-core'
import InputLabel from '@material-ui/core/InputLabel'

import { config } from 'global/lib/config'
import DEFAULTS from 'global/configs/theme/defaults'
import { DialogProps } from 'global/lib/dialogs/useDialogLogic'
import useConnectO365DialogLogic, {
  ConnectO365DialogLogicProps
} from 'global/components/lib/dialogs/connectO365/useConnectO365DialogLogic'
import RadioButtonPanel from 'global/components/lib/radioButtonPanel/RadioButtonPanel'
import RadioButtonPanelSeparator from 'global/components/lib/radioButtonPanel/RadioButtonPanelSeparator'

import styles from 'global/components/lib/dialogs/connectO365/connectO365DialogStyles'
import { useErrorFormatMessage, useFormatMessage } from 'global/lib/localization'

const BASE_I18N_KEY = 'app.connect_o365_dialog'
const BASE_APP_I18N_KEY = 'app'

const ConnectO365Dialog: React.FC<ConnectO365DialogLogicProps & DialogProps> = props => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatMessageApp = useFormatMessage(BASE_APP_I18N_KEY)
  const formatErrorMessage = useErrorFormatMessage()
  const classes = styles()
  const [connectO365DialogLogic] = useConnectO365DialogLogic(props)

  return useMemo(() => {
    const {
      connectError,
      dataPrivacyLink,
      onCloseAlert,
      onClickConnect,
      showConnectError,
      subtitleText,
      onPanelSelect,
      selectedPanel,
      multiStepConfig,
      showBCCAccountSelectOverride,
      showEchoError
    } = connectO365DialogLogic
    const { isDialogOpened, toggleDialog, showScanTypeSelection = false } = props

    const isFirstStep = multiStepConfig.isActive && multiStepConfig.activeStep === 0
    const isSecondStep = multiStepConfig.isActive && multiStepConfig.activeStep === 1
    const isFinalStep = (multiStepConfig.isActive && multiStepConfig.activeStep === 1) || !multiStepConfig.isActive
    const showBCCAccountSelect = showBCCAccountSelectOverride || isSecondStep

    return (
      <div>
        <Dialog
          style={{ zIndex: DEFAULTS.DIALOGS_ZINDEX }}
          maxWidth="sm"
          open={isDialogOpened}
          onClose={() => {
            toggleDialog()
            onCloseAlert()
          }}
          PaperProps={{
            style: {
              boxShadow: 'none'
            }
          }}
        >
          <DialogTitle disableTypography id="form-dialog-title">
            <Typography variant="h5">{formatMessage('connect_to_o365')}</Typography>
            {multiStepConfig.isActive && (
              <Stepper className={classes.stepper} activeStep={multiStepConfig.activeStep}>
                {[0, 1].map(label => (
                  <Step key={label}>
                    <StepLabel />
                  </Step>
                ))}
              </Stepper>
            )}
          </DialogTitle>
          <DialogContent>
            {showConnectError && (
              <Alert variant="filled" severity="error" onClose={onCloseAlert}>
                {formatErrorMessage(connectError)}
              </Alert>
            )}
            <DialogContentText variant="subtitle2">{formatMessage(subtitleText)}</DialogContentText>

            {showScanTypeSelection && (!multiStepConfig.isActive || isFirstStep) && (
              <RadioGroup
                row
                name="ets-sentinel-conversion"
                value={selectedPanel}
                onChange={(e: any) => onPanelSelect(e.target.value)}
              >
                <RadioButtonPanel
                  value={config.SCAN_TYPES.SENTINEL}
                  isActive={selectedPanel === config.SCAN_TYPES.SENTINEL}
                  onClick={onPanelSelect}
                  title={formatMessage('sentinel.title')}
                  subtitle={formatMessage('sentinel.subtitle')}
                />
                <RadioButtonPanelSeparator />
                <RadioButtonPanel
                  value={config.SCAN_TYPES.ETS}
                  isActive={selectedPanel === config.SCAN_TYPES.ETS}
                  onClick={onPanelSelect}
                  title={formatMessage('ets.title')}
                  subtitle={formatMessage('ets.subtitle')}
                />
              </RadioGroup>
            )}

            {showBCCAccountSelect && (
              <SelectWrapper className={classes.selectWrapper} size="medium">
                <InputLabel color="primary" className={classes.text}>
                  {formatMessage('account_want_to_connect')}
                </InputLabel>
                <Select
                  MenuProps={{
                    style: { zIndex: DEFAULTS.DIALOGS_ZINDEX }
                  }}
                  value={multiStepConfig.selectedAccountId}
                  onChange={multiStepConfig.onAccountChange}
                  className={classes.select}
                >
                  {multiStepConfig.accounts.map((account: any) => (
                    <MenuItem className={classes.menuItem} key={account.accountId} value={account.accountId}>
                      {account.accountName}
                    </MenuItem>
                  ))}
                </Select>
              </SelectWrapper>
            )}

            {isFinalStep && showEchoError && (
              <Alert severity="error" variant="filled" className={classes.echoError}>
                <Typography className={classes.echoErrorText}>
                  {formatErrorMessage('connect_o365_dialog.echo_activation_required')}
                </Typography>
                <Typography className={classes.echoErrorDetailText}>
                  {formatErrorMessage('connect_o365_dialog.echo_links', {
                    br: (text: string) => (
                      <data key={text}>
                        <br />
                        {text}
                      </data>
                    ),
                    echoNALink: () => (
                      <Link
                        key="echoNALink"
                        className={classes.boldLinks}
                        target="_blank"
                        href={config.LINKS.ECHO_NORTH_AMERICA}
                      >
                        {formatErrorMessage('connect_o365_dialog.north_america')}&nbsp;&nbsp;
                      </Link>
                    ),
                    echoOutsideNALink: () => (
                      <Link
                        key="echoOutsideNALink"
                        className={classes.boldLinks}
                        target="_blank"
                        href={config.LINKS.ECHO_OUTSIDE_NORTH_AMERICA}
                      >
                        {formatErrorMessage('connect_o365_dialog.other')}
                      </Link>
                    )
                  })}
                </Typography>
              </Alert>
            )}

            {isFinalStep && (
              <DialogContentText variant="subtitle2">
                {formatMessage('microsoft_explainer', {
                  a: (txt: any) => (
                    <Link className={classes.boldLinks} key="link" target="_blank" href={dataPrivacyLink}>
                      {txt}
                    </Link>
                  )
                })}
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              onClick={() => {
                toggleDialog()
                onCloseAlert()
              }}
              color="primary"
            >
              {formatMessage('cancel')}
            </Button>
            {isFirstStep && (
              <Button variant="contained" onClick={() => multiStepConfig.onSetActiveStep(1)} color="primary">
                {formatMessageApp('next')}
              </Button>
            )}
            {isSecondStep && (
              <Button variant="text" onClick={() => multiStepConfig.onSetActiveStep(0)} color="primary">
                {formatMessageApp('back')}
              </Button>
            )}
            {isFinalStep && (
              <Button variant="contained" onClick={onClickConnect} disabled={showEchoError} color="primary">
                {formatMessage('connect_to_o365')}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    )
  }, [props, connectO365DialogLogic, classes, formatMessage, formatErrorMessage, formatMessageApp])
}

export default ConnectO365Dialog
