import { createSlice, isAnyOf } from '@reduxjs/toolkit'

import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'global/redux/toolkit/api'

import {
  deletePostDeliveryCard,
  deleteRelatedCard,
  dismissPostDeliveryCard,
  dismissRelatedCard,
  getPostDelivery,
  getRelated
} from 'fir/redux/features/insights/insightsApiThunks'
import { PotentialThreat } from 'fir/redux/types/Insights'

export interface InsightsState {
  dismissCardApiStatus: ApiStatus
  dismissCardId: number
  getPostDeliveryApiStatus: ApiStatus
  getRelatedApiStatus: ApiStatus
  postDelivery: PotentialThreat[]
  related: PotentialThreat[]
}

// Initial state
export const INITIAL_STATE: InsightsState = {
  dismissCardApiStatus: inIdle,
  dismissCardId: 0,
  getPostDeliveryApiStatus: inIdle,
  getRelatedApiStatus: inIdle,
  postDelivery: [],
  related: []
}

/* eslint-disable no-param-reassign */
export const insightsSlice = createSlice({
  name: 'INSIGHTS',
  initialState: INITIAL_STATE,
  // reducers object creates an action and executes the reducer function
  reducers: {
    resetDismissCard: state => {
      state.dismissCardId = INITIAL_STATE.dismissCardId
      state.dismissCardApiStatus = INITIAL_STATE.dismissCardApiStatus
    },
    resetPotentialIncidentsFailures: state => {
      state.getPostDeliveryApiStatus = INITIAL_STATE.getPostDeliveryApiStatus
      state.getRelatedApiStatus = INITIAL_STATE.getRelatedApiStatus
    },
    updateInsightsStateFailedId: (state, action) => {
      state.dismissCardId = action.payload
    },
    reset: () => {
      return { ...INITIAL_STATE }
    }
  },

  // extraReducers do not create an action but executes the reducer function
  extraReducers: builder => {
    builder
      // getPostDelivery
      .addCase(getPostDelivery.pending, state => {
        state.getPostDeliveryApiStatus = inProgress
        state.postDelivery = INITIAL_STATE.postDelivery
      })
      .addCase(getPostDelivery.fulfilled, (state, action) => {
        state.getPostDeliveryApiStatus = successResponse
        state.postDelivery = action.payload
      })
      .addCase(getPostDelivery.rejected, (state, action) => {
        state.getPostDeliveryApiStatus = failedResponse(action.payload)
      })

      // getRelated
      .addCase(getRelated.pending, state => {
        state.getRelatedApiStatus = inProgress
        state.related = INITIAL_STATE.related
      })
      .addCase(getRelated.fulfilled, (state, action) => {
        state.getRelatedApiStatus = successResponse
        state.related = action.payload
      })
      .addCase(getRelated.rejected, (state, action) => {
        state.getRelatedApiStatus = failedResponse(action.payload)
      })

    builder
      // delete and dismiss postDelivery and related
      .addMatcher(
        isAnyOf(
          deletePostDeliveryCard.pending,
          deleteRelatedCard.pending,
          dismissPostDeliveryCard.pending,
          dismissRelatedCard.pending
        ),
        state => {
          state.dismissCardApiStatus = inProgress
        }
      )
      .addMatcher(
        isAnyOf(
          deletePostDeliveryCard.fulfilled,
          deleteRelatedCard.fulfilled,
          dismissPostDeliveryCard.fulfilled,
          dismissRelatedCard.fulfilled
        ),
        state => {
          state.dismissCardApiStatus = successResponse
          state.dismissCardId = INITIAL_STATE.dismissCardId
        }
      )
      .addMatcher(
        isAnyOf(
          deletePostDeliveryCard.rejected,
          deleteRelatedCard.rejected,
          dismissPostDeliveryCard.rejected,
          dismissRelatedCard.rejected
        ),
        (state, action) => {
          state.dismissCardApiStatus = failedResponse(action.payload)
        }
      )
  }
})

export const {
  reset,
  resetDismissCard,
  resetPotentialIncidentsFailures,
  updateInsightsStateFailedId
} = insightsSlice.actions

export {
  deletePostDeliveryCard,
  deleteRelatedCard,
  dismissPostDeliveryCard,
  dismissRelatedCard,
  getPostDelivery,
  getRelated
}

export default insightsSlice.reducer
