import { useCallback, useMemo } from 'react'

import { DmarcSource, CompanyDomain, CompanyDomainWithState, DomainDmarcStat, DmarcIp } from 'global/types/api/dmarc'
import { config } from 'global/lib/config'

import { useAppSelector } from 'sen/redux/toolkit/hooks'

export const MIN_FAIL_BY_PERCENTAGE = 10
export const BASE_DMARC_PASS_RATIO = 100

export interface DmarcAnalyticsLogic {
  getDmarcStatsForItem: (item: CompanyDomain | CompanyDomainWithState) => DomainDmarcStat | undefined
  dmarcPassRatio: (source: DmarcSource) => number
  isDmarcFailByPercentage: (percentage: number) => boolean
  getDomainFailuresFromApprovedSourcesPercentage: (item: CompanyDomain | CompanyDomainWithState) => number
  isDmarcPassing: (item: CompanyDomain | CompanyDomainWithState) => boolean
  isDmarcReportReady: (item: CompanyDomain | CompanyDomainWithState) => boolean
  getUnknownMajorSourcesCount: (item: CompanyDomain | CompanyDomainWithState) => number | undefined
  getFailsFromApprovedSources: (item: CompanyDomain | CompanyDomainWithState) => number | undefined
  hasNoUnknownMajorSenders: (item: CompanyDomain | CompanyDomainWithState) => boolean
  isReadyToBeEnforced: (item: CompanyDomainWithState) => boolean
  isDmarcFailed: (source: DmarcIp) => boolean
  isDmarcPassed: (source: DmarcIp) => boolean
  percentageLabel: (value: number) => string
}

export default function useDmarcAnalyticsLogic(): DmarcAnalyticsLogic {
  const { domainStats } = useAppSelector(_stores => ({
    domainStats: _stores.dmarc.domainsStats
  }))

  const getDmarcStatsForItem: DmarcAnalyticsLogic['getDmarcStatsForItem'] = useCallback(
    item => {
      return domainStats?.[item.name]
    },
    [domainStats]
  )

  const dmarcPassRatio: DmarcAnalyticsLogic['dmarcPassRatio'] = useCallback(source => {
    return BASE_DMARC_PASS_RATIO - source.dmarcFailRatio
  }, [])

  const isDmarcFailByPercentage: DmarcAnalyticsLogic['isDmarcFailByPercentage'] = useCallback(percentage => {
    return percentage > MIN_FAIL_BY_PERCENTAGE
  }, [])

  const getDomainFailuresFromApprovedSourcesPercentage: DmarcAnalyticsLogic['getDomainFailuresFromApprovedSourcesPercentage'] = useCallback(
    item => {
      const dmarcStatsForItem = getDmarcStatsForItem(item)

      if (
        dmarcStatsForItem?.dmarcFailsFromApprovedSources &&
        dmarcStatsForItem?.dmarcTotalMailCountFromApprovedSources
      ) {
        return (
          (dmarcStatsForItem.dmarcFailsFromApprovedSources / dmarcStatsForItem.dmarcTotalMailCountFromApprovedSources) *
          BASE_DMARC_PASS_RATIO
        )
      }

      return 0
    },
    [getDmarcStatsForItem]
  )

  const isDmarcPassing: DmarcAnalyticsLogic['isDmarcPassing'] = useCallback(
    item => {
      return !isDmarcFailByPercentage(getDomainFailuresFromApprovedSourcesPercentage(item))
    },
    [getDomainFailuresFromApprovedSourcesPercentage, isDmarcFailByPercentage]
  )

  const isDmarcReportReady: DmarcAnalyticsLogic['isDmarcReportReady'] = useCallback(
    item => {
      return getDmarcStatsForItem(item)?.reportIsReady || false
    },
    [getDmarcStatsForItem]
  )

  const getUnknownMajorSourcesCount: DmarcAnalyticsLogic['getUnknownMajorSourcesCount'] = useCallback(
    item => {
      return getDmarcStatsForItem(item)?.unknownMajorSendersCount
    },
    [getDmarcStatsForItem]
  )

  const getFailsFromApprovedSources: DmarcAnalyticsLogic['getFailsFromApprovedSources'] = useCallback(
    item => {
      return getDmarcStatsForItem(item)?.dmarcFailsFromApprovedSources
    },
    [getDmarcStatsForItem]
  )

  const hasNoUnknownMajorSenders: DmarcAnalyticsLogic['hasNoUnknownMajorSenders'] = useCallback(
    item => {
      return getUnknownMajorSourcesCount(item) === 0
    },
    [getUnknownMajorSourcesCount]
  )

  const isReadyToBeEnforced: DmarcAnalyticsLogic['isReadyToBeEnforced'] = useCallback(
    item => {
      if (getDmarcStatsForItem(item)) {
        return (
          item?.state === config.DOMAIN_RESOLVE_STATES.DMARC.REPORTING &&
          isDmarcReportReady(item) &&
          hasNoUnknownMajorSenders(item) &&
          isDmarcPassing(item)
        )
      }

      return false
    },
    [isDmarcReportReady, hasNoUnknownMajorSenders, isDmarcPassing, getDmarcStatsForItem]
  )

  const isDmarcFailed: DmarcAnalyticsLogic['isDmarcFailed'] = useCallback(
    source => isDmarcFailByPercentage(source.dmarcFailRatio),
    [isDmarcFailByPercentage]
  )

  const isDmarcPassed: DmarcAnalyticsLogic['isDmarcPassed'] = useCallback(source => !isDmarcFailed(source), [
    isDmarcFailed
  ])

  const percentageLabel: DmarcAnalyticsLogic['percentageLabel'] = useCallback((value: number) => {
    return value > 1 ? `${Math.round(value)}%` : '<1%'
  }, [])

  return useMemo(
    () => ({
      getDmarcStatsForItem,
      dmarcPassRatio,
      isDmarcFailByPercentage,
      getDomainFailuresFromApprovedSourcesPercentage,
      isDmarcPassing,
      isDmarcReportReady,
      getUnknownMajorSourcesCount,
      getFailsFromApprovedSources,
      hasNoUnknownMajorSenders,
      isReadyToBeEnforced,
      isDmarcFailed,
      isDmarcPassed,
      percentageLabel
    }),
    [
      isDmarcReportReady,
      dmarcPassRatio,
      isDmarcFailByPercentage,
      getDomainFailuresFromApprovedSourcesPercentage,
      isDmarcPassing,
      getFailsFromApprovedSources,
      getDmarcStatsForItem,
      getUnknownMajorSourcesCount,
      hasNoUnknownMajorSenders,
      isReadyToBeEnforced,
      isDmarcFailed,
      isDmarcPassed,
      percentageLabel
    ]
  )
}
