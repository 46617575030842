import { useCallback, useEffect, useMemo } from 'react'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'
import { isFailed } from 'global/redux/toolkit/api'
import { FraudUnifiedData } from 'global/types/api/dmarc'
import { ColumnsConfig } from 'global/types/dataTables/dataTables'

import { getDmarcFraudUnifiedData, resetFraudData } from 'sen/redux/features/dmarc/dmarcSlice'
import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'

export interface TableConfig {
  isLoaded: boolean
  tableData: {
    total: number
    data: FraudUnifiedData[]
  }
  columns: { [key: string]: string }
  columnsConfig: ColumnsConfig
  countriesLeft: number
}

export interface DialogConfig {
  isMapDialogOpened: boolean
  onOpenDialog: (buttonText: string) => void
  onCloseDialog: () => void
}

export default function useDmarcReviewTopCountriesLogic(): [TableConfig, DialogConfig, boolean] {
  const { activePath, dmarcFraudUnifiedData, dataTable, failed } = useAppSelector(_stores => ({
    activePath: _stores.app.activePath,
    failed: isFailed(_stores.dmarc.getDmarcFraudUnifiedDataApiStatus),
    dmarcFraudUnifiedData: _stores.dmarc.dmarcFraudUnifiedData,
    dataTable: _stores.dataTables.dmarcFraudUnifiedData
  }))

  const dispatch = useAppDispatch()
  const [isMapDialogOpened, toggleMapDialog] = useDialogLogic()

  // init
  useEffect(() => {
    dispatch(getDmarcFraudUnifiedData({ domain: activePath.params.domain }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // unmount
  useEffect(() => {
    return () => {
      dispatch(resetFraudData())
    }
  }, [dispatch])

  const onOpenDialog = useCallback(
    (buttonText: string) => {
      analyticsLib.trackEvent(analyticsLib.EVENTS.DF_DOMAINS_DMARC_REVIEW_COUNTRIES_OPEN_DIALOG, {
        domain: activePath.params.domain,
        buttonText
      })
      toggleMapDialog()
    },
    [toggleMapDialog, activePath]
  )

  const onCloseDialog = useCallback(() => {
    analyticsLib.trackEvent(analyticsLib.EVENTS.DF_DOMAINS_DMARC_REVIEW_COUNTRIES_CLOSE_DIALOG, {
      domain: activePath.params.domain
    })
    toggleMapDialog()
  }, [activePath, toggleMapDialog])

  return useMemo(() => {
    return [
      {
        isLoaded: !!dmarcFraudUnifiedData,
        tableData: {
          total: dmarcFraudUnifiedData?.length || 0,
          data: dmarcFraudUnifiedData?.slice(0, dataTable.ITEMS_PER_PAGE) || []
        },
        columns: dataTable.GRID_COLUMNS,
        columnsConfig: dataTable.columnsConfig,
        countriesLeft: (dmarcFraudUnifiedData?.length || 0) - dataTable.ITEMS_PER_PAGE
      },
      {
        isMapDialogOpened,
        onOpenDialog,
        onCloseDialog
      },
      failed
    ]
  }, [dmarcFraudUnifiedData, dataTable, isMapDialogOpened, onOpenDialog, onCloseDialog, failed])
}
