import { useMemo, useEffect } from 'react'

import { isNumber } from 'lodash'

import { isSuccess } from 'global/redux/toolkit/api'

import * as analyticsLib from 'global/lib/analytics/analyticsService'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import { getAlertSummary } from 'sen/redux/features/ato/atoSlice'
import { getIncidentsCount } from 'sen/redux/features/remediation/remediationSlice'

export type IsLoaded = boolean
export type OverviewValue = string | undefined
export type IsSuccess = boolean
export type IsDate = boolean

export type CardConfig = [IsLoaded, OverviewValue, IsSuccess, IsDate?]

export interface UseAtoOverviewLogic {
  openAlertsCount: number | undefined
  latestAlertDate: string | undefined
  incidentsCount: number | undefined
  isAlertSummaryLoaded: boolean
  isIncidentsLoaded: boolean
}

export default function useAtoOverviewLogic(): [CardConfig[]] {
  const dispatch = useAppDispatch()

  const { alertSummary, isAlertSummaryLoaded, incidentsCount, isIncidentsLoaded } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id,
    alertSummary: _stores.ato.alertSummary,
    isAlertSummaryLoaded: isSuccess(_stores.ato.getAlertSummaryApiStatus),
    incidentsCount: _stores.remediation.incidentsCount,
    isIncidentsLoaded: isSuccess(_stores.remediation.getIncidentsCountApiStatus)
  }))

  // init
  useEffect(() => {
    if (window.location.toString().includes('accounttakeoveralert')) {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.ATO_ALERT_OPENED_IN_EMAIL, {
        url: window.location.href
      })
    }

    dispatch(getAlertSummary())
    dispatch(getIncidentsCount())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return useMemo(() => {
    const openAlertsCount = alertSummary?.report?.openAlertsCount
    const latestAlertDate = alertSummary?.report?.latestAlertDate

    return [
      [
        [isAlertSummaryLoaded, String(openAlertsCount || 0), isNumber(openAlertsCount) && !openAlertsCount],
        [isIncidentsLoaded, String(incidentsCount || 0), true],
        [isAlertSummaryLoaded, latestAlertDate || '-', true, !!latestAlertDate]
      ]
    ]
  }, [alertSummary, incidentsCount, isAlertSummaryLoaded, isIncidentsLoaded])
}
