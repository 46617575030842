import React from 'react'
import { useLocation } from 'react-router-dom'

import GlobalSignupPolicy from 'global/components/features/signinSignup/signupPolicy/SignupPolicy'

import routesConfig from 'sen/lib/routes/routesConfig'

export const SignupPolicy: React.FC = () => {
  const location = useLocation()
  return (
    <GlobalSignupPolicy
      disableUserCheck={location.pathname === routesConfig.SIGNUP_POLICY.path}
      onGoto={() => {
        routesConfig.SIGNIN_SIGNUP.goto({}, true)
      }}
    />
  )
}

export default SignupPolicy
