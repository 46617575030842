import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getUser, logout } from 'global/redux/features/auth/authApiThunks'
import { AccessToken } from 'global/types/api/accessTokenType'
import { User } from 'global/types/api/userType'

export interface UserState {
  data: User
  isUserDataLoaded: boolean
}

// initialState
export const INITIAL_STATE: {
  data: User
  isUserDataLoaded: boolean
} = {
  data: {} as User,
  isUserDataLoaded: false
}

/* eslint-disable no-param-reassign */
export const userSlice = createSlice({
  name: 'USER',
  initialState: INITIAL_STATE,
  reducers: {
    setUser: {
      reducer: (state: UserState, action: PayloadAction<{ user: User }>) => {
        state.data = action.payload.user || {}
        state.isUserDataLoaded = true
      },
      prepare: (user: User) => ({
        payload: { user }
      })
    },
    updateAccessToken: {
      reducer: (state: UserState, action: PayloadAction<{ accessToken?: AccessToken }>) => {
        const nextStateData = {
          ...state.data,
          accounts: state.data.accounts.map(account => ({
            ...account,
            accessTokens: account.accessTokens.map(token => {
              if (token.id === action.payload.accessToken?.id) {
                return action.payload.accessToken
              }
              return token
            })
          }))
        }

        return {
          ...state,
          data: nextStateData
        }
      },
      prepare: (accessToken?: AccessToken) => ({
        payload: { accessToken }
      })
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getUser.rejected, state => {
        return {
          ...state,
          isUserDataLoaded: true
        }
      })
      .addCase(logout.fulfilled, () => {
        return {
          ...INITIAL_STATE
        }
      })
  }
})
/* eslint-enable no-param-reassign */

export const { setUser, reset, updateAccessToken } = userSlice.actions

export default userSlice.reducer
