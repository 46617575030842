import { createSlice } from '@reduxjs/toolkit'

import { config } from 'global/lib/config'
import { Order } from 'global/types/dataTables/dataTables'

type OrderFields = 'email' | 'userType' | 'clickedLinks' | 'isRead' | 'replied' | 'forwarded'

interface UserDetailsByIncidentState {
  page: number
  order: Order
  orderBy: OrderFields
  rowsPerPage: number
}

// Store
export const INITIAL_STATE: UserDetailsByIncidentState = {
  page: 0,
  order: 'desc',
  orderBy: 'email',
  rowsPerPage: config.DATA_TABLE.FORENSICS.ITEMS_PER_PAGE
}

export const userDetailsByIncidentSlice = createSlice({
  name: 'DATATABLE/USER_DETAILS_BY_INCIDENT',
  initialState: INITIAL_STATE,
  reducers: {
    update: (state, action) => {
      return {
        ...state,
        ...action.payload?.config
      }
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  }
})

export const { update, reset } = userDetailsByIncidentSlice.actions

export default userDetailsByIncidentSlice.reducer
