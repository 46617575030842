import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'

import {
  Alert,
  Button,
  DataTable,
  DataTableColumn,
  DialogContent,
  Grid,
  Typography
} from '@barracuda-internal/bds-core'

import CircularProgress from 'global/components/lib/circularProgress/CircularProgress'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import { Cell, CellText } from 'global/components/lib/grid/cell'
import { formatDate } from 'global/lib/datetime'
import { config } from 'global/lib/config'
import { useErrorFormatMessage, useFormatMessage } from 'global/lib/localization'
import { FraudUnifiedRufData } from 'global/types/api/dmarc'
import { getCountryName } from 'global/lib/isoCountries'
import Pager from 'global/components/lib/grid/pager/Pager'

import styles from 'sen/components/lib/dialogs/dmarcCountries/dmarcFraudTable/dmarcFraudTableStyles'
import useDmarcFraudLogic from 'sen/components/lib/dialogs/dmarcCountries/dmarcFraudTable/useDmarcFraudLogic'

export interface DmarcAllCountriesMapProps {
  country: string
  onOpenFraud: (fraud: FraudUnifiedRufData) => void
}

const BASE_I18N_KEY = 'sen.app.dmarc_countries_dialog.dmarc_fraud_table'

export const DmarcFraudTable: React.FC<DmarcAllCountriesMapProps> = ({ country, onOpenFraud }) => {
  const [tableConfig, logicConfig] = useDmarcFraudLogic(country)
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const errorFormatMessage = useErrorFormatMessage()
  const classes = styles()

  return useMemo(
    () => (
      <DialogContent>
        {logicConfig.error && (
          <Alert severity="error" data-field="table-error">
            {errorFormatMessage(logicConfig.error)}
          </Alert>
        )}
        {!tableConfig.isLoaded && (
          <Grid container justifyContent="center">
            <CircularProgress className={classes.loading} data-field="paper-loader" />
          </Grid>
        )}
        {tableConfig.isLoaded && (
          <Grid
            className={`${classes.tableArea} ${!tableConfig.tableData.total ? 'empty-table' : ''}`}
            data-table="dmarc-countries"
            data-testid="dmarc-countries"
            item
            xs={12}
            data-field="table-grid"
          >
            {tableConfig.inProgress && <LinearProgress data-field="table-loader" />}
            <DataTable
              className={`${tableConfig.tableData.total ? classes.dataTable : classes.emptyDataTable} scans-table ${
                classes.gridLayout
              }`}
              data={tableConfig.tableData}
              {...tableConfig.pageConfig}
              pager={Pager}
              data-field="table"
            >
              <DataTableNoRecords>
                <Typography data-field="no-records">
                  {!tableConfig.tableData.total && formatMessage('empty_table')}
                </Typography>
              </DataTableNoRecords>
              <DataTableColumn
                field={tableConfig.columns.DATE}
                {...tableConfig.columnsConfig[tableConfig.columns.DATE]}
                title={formatMessage(`${tableConfig.columns.DATE}`)}
                cell={({ dataItem }: { dataItem: FraudUnifiedRufData }) => (
                  <Cell className={classes.cell}>
                    <CellText ellipsis data-field="date-cell">
                      {formatDate(dataItem.date, config.DATETIME.DEFAULT_DATE_FORMAT)}
                    </CellText>
                  </Cell>
                )}
              />
              <DataTableColumn
                field={tableConfig.columns.SUBJECT}
                {...tableConfig.columnsConfig[tableConfig.columns.SUBJECT]}
                title={formatMessage(`${tableConfig.columns.SUBJECT}`)}
                cell={({ dataItem }: { dataItem: FraudUnifiedRufData }) => (
                  <Cell className={classes.cell}>
                    <CellText ellipsis data-field="subject-cell">
                      {dataItem.subject}
                    </CellText>
                  </Cell>
                )}
              />
              <DataTableColumn
                field={tableConfig.columns.ORIGIN}
                {...tableConfig.columnsConfig[tableConfig.columns.ORIGIN]}
                title={formatMessage(`${tableConfig.columns.ORIGIN}`)}
                cell={({ dataItem }: { dataItem: FraudUnifiedRufData }) => (
                  <Cell className={classes.cell}>
                    <CellText ellipsis data-field="origin-cell">
                      {getCountryName(dataItem.country)}
                    </CellText>
                  </Cell>
                )}
              />
              <DataTableColumn
                field={tableConfig.columns.EMAILS}
                {...tableConfig.columnsConfig[tableConfig.columns.EMAILS]}
                title={formatMessage(`${tableConfig.columns.EMAILS}`)}
                cell={({ dataItem }: { dataItem: FraudUnifiedRufData }) => (
                  <Cell className={classes.cell}>
                    <CellText ellipsis data-field="emails-cell">
                      {dataItem.emailCount}
                    </CellText>
                  </Cell>
                )}
              />
              <DataTableColumn
                {...tableConfig.columnsConfig[tableConfig.columns.VIEW]}
                cell={({ dataItem }: { dataItem: FraudUnifiedRufData }) => (
                  <Cell className={classes.cell}>
                    <CellText ellipsis data-field="action-cell">
                      <Button
                        color="primary"
                        variant="text"
                        disabled={logicConfig.isRufSampleLoading}
                        size="large"
                        onClick={() => onOpenFraud(dataItem)}
                        data-field="open-fraud-button"
                        data-action="open-fraud-action"
                      >
                        &nbsp;&nbsp;{formatMessage('view_action')}&nbsp;&nbsp;
                      </Button>
                    </CellText>
                  </Cell>
                )}
              />
            </DataTable>
          </Grid>
        )}
      </DialogContent>
    ),
    [formatMessage, classes, tableConfig, onOpenFraud, logicConfig, errorFormatMessage]
  )
}

DmarcFraudTable.propTypes = {
  country: PropTypes.string.isRequired,
  onOpenFraud: PropTypes.func.isRequired
}

export default DmarcFraudTable
