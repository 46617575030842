import React, { useMemo } from 'react'

import { Grid, IconButton } from '@barracuda-internal/bds-core'
import { Search as SearchIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'
import InputBase from '@material-ui/core/InputBase'

import styles from 'global/components/lib/searchField/searchFieldStyles'

import useSearchFieldLogic from 'global/components/lib/searchField/useSearchFieldLogic'

export interface SearchFieldProps {
  id?: string
  value: string
  placeholder?: string
  onChange: (e: any) => void
  onSearch?: () => void
  onKeyDown?: (event: KeyboardEvent) => void
  disabled?: boolean
  searchContainerClassName?: string
  inputClassName?: string
  iconSize?: 'medium' | 'small'
}

const SearchField: React.FC<SearchFieldProps> = ({
  id = 'search',
  value,
  placeholder = '',
  onChange,
  onSearch,
  onKeyDown,
  disabled = false,
  searchContainerClassName = '',
  inputClassName = '',
  iconSize = 'medium'
}) => {
  const classes = styles()

  return useMemo(
    () => (
      <Grid className={`${searchContainerClassName || classes.root} search-field-container`}>
        <InputBase
          data-input={id}
          disabled={disabled}
          className={`${classes.inputField} ${inputClassName}`}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          onKeyDown={onKeyDown as any}
        />
        <IconButton onClick={onSearch} disabled={disabled} aria-label="search" size={iconSize}>
          <SearchIcon />
        </IconButton>
      </Grid>
    ),
    [
      classes.inputField,
      classes.root,
      disabled,
      iconSize,
      id,
      inputClassName,
      onChange,
      onKeyDown,
      onSearch,
      placeholder,
      searchContainerClassName,
      value
    ]
  )
}

export { useSearchFieldLogic }

export default SearchField
