import React, { useMemo } from 'react'

import GlobalEmptyReport from 'global/components/features/emptyReport/EmptyReport'
import { useFormatMessage } from 'global/lib/localization'

import routesConfig from 'sen/lib/routes/routesConfig'
import Layout from 'sen/components/lib/layout/Layout'

const EmptyReport: React.FC = () => {
  const formatMessage = useFormatMessage('sen.app.empty_report')

  return useMemo(
    () => (
      <Layout pageTitle={formatMessage('page_title')}>
        <GlobalEmptyReport handler={accessTokenId => routesConfig.DASHBOARD.goto({ reportId: accessTokenId })} />
      </Layout>
    ),
    [formatMessage]
  )
}

export default EmptyReport
