/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react'

import { Typography, DataTable, DataTableColumn, Button, Grid, Paper } from '@barracuda-internal/bds-core'
import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'

import AlertComponent from '@material-ui/lab/Alert'
import { useErrorFormatMessage, useFormatMessage } from 'global/lib/localization'
import CircularProgress from 'global/components/lib/circularProgress/CircularProgress'
import Pager from 'global/components/lib/grid/pager/Pager'
import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'
import EmailCell from 'global/components/lib/grid/cell/customCells/EmailCell'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'

import useIncidentsLogic, { ModifiedIncident } from 'sen/components/pages/ato/content/tabs/incidents/useIncidentsLogic'
import styles, { CELL_HEIGHT } from 'sen/components/pages/ato/content/tabs/tabStyles'

const BASE_I18N_KEY = 'sen.app.account_takeover.incidents'
const BASE_I18N_TABLE_KEY = 'sen.app.data_tables.incidents'

const Incidents: React.FC = () => {
  const [inProgress, tableConfig, error] = useIncidentsLogic()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const tableFormatMessage = useFormatMessage(BASE_I18N_TABLE_KEY)
  const formatMessageError = useErrorFormatMessage()
  const classes = styles()

  return useMemo(
    () => (
      <Grid container direction="column" data-field="incidents-grid">
        {error && (
          <AlertComponent variant="filled" severity="error" data-testid="incidents-alert">
            {formatMessageError(error)}
          </AlertComponent>
        )}
        <Grid item data-field="incidents-grid-title">
          <Typography className={classes.title} data-field="incidents-title">
            {formatMessage('title')}
          </Typography>
          <Typography className={classes.subtitle} variant="subtitle2" data-field="incidents-subtitle">
            {formatMessage('subtitle')}
          </Typography>
        </Grid>
        <Grid item data-field="incidents-grid-paper">
          <Paper className={classes.tableCard} elevation={0} data-field="incidents-paper">
            {!tableConfig.isLoaded && (
              <Grid container justifyContent="center" className={classes.tableCircularProgress}>
                <CircularProgress data-field="loader" />
              </Grid>
            )}

            {tableConfig.isLoaded && (
              <Grid container direction="column" className={classes.tableWrapper} data-field="incidents-table-wrapper">
                <Grid
                  className={`${tableConfig.isFlexibleTable ? classes.flexibleTableArea : classes.tableArea} ${
                    !tableConfig.tableData.total ? 'empty-table' : ''
                  }`}
                  data-table="incidents"
                  item
                  xs={12}
                  data-field="grid-table"
                >
                  {inProgress && <LinearProgress className={classes.indicator} data-field="linear-progress" />}
                  <DataTable
                    data-testid="incidents-table"
                    className={`${
                      tableConfig.tableData.total
                        ? tableConfig.isFlexibleTable
                          ? classes.flexibleDataTable
                          : classes.dataTable
                        : classes.emptyDataTable
                    } incidents-table`}
                    data={tableConfig.tableData}
                    {...tableConfig.pageConfig}
                    pager={Pager}
                    data-field="data-table"
                  >
                    <DataTableNoRecords data-field="empty-table">
                      <Typography>{!tableConfig.tableData.total && tableFormatMessage('empty_table')}</Typography>
                    </DataTableNoRecords>
                    <DataTableColumn
                      field={tableConfig.columns.REPORTED}
                      {...tableConfig.columnsConfig[tableConfig.columns.REPORTED]}
                      title={tableFormatMessage(tableConfig.columns.REPORTED)}
                      cell={({ dataItem }: { dataItem: ModifiedIncident }) => (
                        <Cell className={classes.cell} data-field="table-column-reported">
                          <CellText className={classes.normalCell} data-field="table-column-reported-text">
                            {dataItem.formattedDate}
                          </CellText>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      field={tableConfig.columns.ACCOUNT_TAKEN_OVER}
                      {...tableConfig.columnsConfig[tableConfig.columns.ACCOUNT_TAKEN_OVER]}
                      title={tableFormatMessage(tableConfig.columns.ACCOUNT_TAKEN_OVER)}
                      cell={({ dataItem }: { dataItem: ModifiedIncident }) => (
                        <EmailCell
                          CELL_HEIGHT={CELL_HEIGHT}
                          name={dataItem.sender.title}
                          email={dataItem.sender.email}
                          data-field="table-column-email"
                        />
                      )}
                    />
                    <DataTableColumn
                      field={tableConfig.columns.IMPACT}
                      {...tableConfig.columnsConfig[tableConfig.columns.IMPACT]}
                      title={tableFormatMessage(tableConfig.columns.IMPACT)}
                      cell={({ dataItem }: { dataItem: ModifiedIncident }) => (
                        <Cell className={classes.cell} data-field="table-column-activity">
                          <CellText ellipsis className={classes.lightCell} data-field="table-column-activity-text">
                            {tableFormatMessage('internal_attacks', { count: dataItem.internalAttackCount })}
                            <br />
                            {tableFormatMessage('external_attacks', { count: dataItem.externalAttackCount })}
                          </CellText>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      headerClassName={classes.centerAlignedText}
                      field={tableConfig.columns.VIEW_INCIDENT}
                      {...tableConfig.columnsConfig[tableConfig.columns.VIEW_INCIDENT]}
                      title=" "
                      cell={({ dataItem }: { dataItem: ModifiedIncident }) => (
                        <Cell className={classes.cell} data-field="table-column-details">
                          <Grid container direction="row" justifyContent="center">
                            <Button
                              color="primary"
                              size="large"
                              onClick={() => {
                                tableConfig.onViewIncident(dataItem.id)
                              }}
                              data-field="view-incident-button"
                            >
                              {tableFormatMessage('view_incident')}
                            </Button>
                          </Grid>
                        </Cell>
                      )}
                    />
                  </DataTable>
                </Grid>
              </Grid>
            )}
          </Paper>
        </Grid>
      </Grid>
    ),
    [classes, formatMessage, inProgress, tableConfig, tableFormatMessage, error, formatMessageError]
  )
}

export default Incidents
