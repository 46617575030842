import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { GroupConfig, FilterConfig } from 'global/components/lib/dataTable/DataTable'

export interface DataTableParams {
  filterConfig: FilterConfig
  groupConfig: GroupConfig
}

export interface DataTableLogicProps {
  handleClearColumnFilters: () => void
  handleRemoveColumnFilter: (columnId: string) => void
  isColumnVisibilityOpen: boolean
  toggleColumnVisibilityModal: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export default function useDataTableLogic({ filterConfig, groupConfig }: DataTableParams): DataTableLogicProps[] {
  const [isColumnVisibilityOpen, setIsColumnVisibilityOpen] = useState<boolean>(false)

  // Column Visibility
  const toggleColumnVisibilityModal = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setIsColumnVisibilityOpen(!isColumnVisibilityOpen)
    },
    [isColumnVisibilityOpen]
  )

  // Grouping
  useEffect(() => {
    if (typeof groupConfig.onGroupingChange === 'function') groupConfig.onGroupingChange(groupConfig.grouping)
  }, [groupConfig, groupConfig.onGroupingChange])

  return useMemo(
    () => [
      {
        handleClearColumnFilters: filterConfig.handleClearColumnFilters,
        handleRemoveColumnFilter: filterConfig.handleRemoveColumnFilter,
        isColumnVisibilityOpen,
        toggleColumnVisibilityModal
      }
    ],
    [
      filterConfig.handleClearColumnFilters,
      filterConfig.handleRemoveColumnFilter,
      isColumnVisibilityOpen,
      toggleColumnVisibilityModal
    ]
  )
}
