import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Button, Card, Checkbox, Typography } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'global/lib/localization'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import styles from 'global/components/features/signinSignup/signupComplete/signupCompleteStyles'
import useSignupCompleteLogic, {
  UseSignupCompleteLogicProps
} from 'global/components/features/signinSignup/signupComplete/useSignupCompleteLogic'
import SigninSignupLayout from 'global/components/features/signinSignup/layout/SigninSignupLayout'
import AccountSwitcher from 'global/components/features/signinSignup/layout/AccountSwitcher'

const BASE_I18N_KEY = 'app.signup_complete'
const BASE_APP_I18N_KEY = 'app'

const MIN_PASSWORD_LENGTH = 8

export interface SignupCompleteProps extends UseSignupCompleteLogicProps {
  productLogo: JSX.Element
  scanType: string
  bgImage?: string
  bgImageGradient?: string
  isFinalStep?: boolean
}

export const SignupComplete: React.FC<SignupCompleteProps> = props => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatAppMessage = useFormatMessage(BASE_APP_I18N_KEY)

  const [signupCompleteLogic] = useSignupCompleteLogic(props)
  const { bgImage, bgImageGradient, productLogo, isFinalStep = true } = props

  return useMemo(() => {
    if (!signupCompleteLogic) {
      return null
    }

    const {
      error,
      user,
      formValues,
      onPasswordChange,
      onPhoneChange,
      isButtonDisabled,
      onTogglePrivacyPolicy,
      onReadPrivacyPolicy,
      onBlurField,
      onScanNow,
      formErrors,
      isFormInProgress,
      isPasswordError,
      logout
    } = signupCompleteLogic

    return (
      <SigninSignupLayout bgImage={bgImage} bgImageGradient={bgImageGradient} error={error}>
        <Card className={classes.wrapper}>
          <div className={classes.linearProgress}>{isFormInProgress && <LinearProgress />}</div>
          <div className={classes.headingLogoWrapper}>{productLogo}</div>
          <Typography className={classes.title} align="center" data-field="title">
            {formatMessage('title')}
          </Typography>
          <div className={classes.inputsWrapper}>
            <Typography className={classes.inputTitle} align="left" data-field="input-email">
              {formatAppMessage('email')}
            </Typography>
            <input data-field="email" data-error={false} type="text" value={user.email} disabled />
            <Typography className={classes.inputTitle} align="left" data-field="input-password">
              {formatAppMessage('password')}
            </Typography>
            <input
              data-field="password"
              data-error={isPasswordError}
              type="password"
              onBlur={onBlurField}
              placeholder={formatMessage('placeholders.password', {
                char: MIN_PASSWORD_LENGTH
              })}
              value={formValues.password}
              onChange={onPasswordChange('password')}
              disabled={isFormInProgress}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
            {formErrors.isWeakPassword && (
              <div className={classes.formError}>
                {formatMessage('form_errors.weak_password', {
                  li: (txt: any) => <li key={txt}>{txt}</li>
                })}
              </div>
            )}
            {formErrors.isPasswordDontMatch && (
              <div className={classes.formError}>{formatMessage('form_errors.password_match')}</div>
            )}
            <input
              data-field="confirmPassword"
              onBlur={onBlurField}
              placeholder={formatMessage('placeholders.confirm_password')}
              value={formValues.confirmPassword}
              onChange={onPasswordChange('confirmPassword')}
              data-error={isPasswordError}
              type="password"
              disabled={isFormInProgress}
            />
            <Typography className={classes.inputTitle} align="left" data-field="input-phone">
              {formatMessage('phone_number')}
            </Typography>
            <input
              data-field="phone"
              onBlur={onBlurField}
              data-error={false}
              type="text"
              placeholder="+_ (___) ___-____"
              value={formValues.phone}
              onChange={onPhoneChange}
              disabled={isFormInProgress}
            />
            {formErrors.isInvalidPhone && (
              <div className={classes.formError}>{formatMessage('form_errors.invalid_phone')}</div>
            )}
          </div>
          <FormControlLabel
            className={classes.privacyPolicy}
            data-field="privacy-policy"
            control={
              <Checkbox
                data-action="toggle-privacy-policy"
                color="primary"
                name="agree_privacy_policy"
                checked={formValues.isAcceptedPrivacyPolicy}
                onClick={onTogglePrivacyPolicy}
                disabled={isFormInProgress}
              />
            }
            label={formatMessage('agree_privacy_policy', {
              a: (txt: any) => (
                <data
                  key={txt}
                  onClick={onReadPrivacyPolicy}
                  role="presentation"
                  className={classes.blueText}
                  data-action="read-privacy-policy"
                >
                  {txt}
                </data>
              )
            })}
          />
          <Button
            className={classes.button}
            onClick={onScanNow}
            disabled={isButtonDisabled || isFormInProgress}
            data-action="scan"
          >
            {formatMessage(`${isFinalStep ? 'scan_now' : 'continue'}`)}
          </Button>
        </Card>
        <AccountSwitcher userEmail={user.email} onSwitchAccount={logout} />
      </SigninSignupLayout>
    )
  }, [
    signupCompleteLogic,
    productLogo,
    bgImage,
    bgImageGradient,
    classes,
    isFinalStep,
    formatMessage,
    formatAppMessage
  ])
}

SignupComplete.propTypes = {
  productLogo: PropTypes.any.isRequired,
  bgImage: PropTypes.string,
  bgImageGradient: PropTypes.string
}

export default SignupComplete
