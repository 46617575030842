import React, { useMemo } from 'react'

import { DataTable, DataTableColumn, Paper } from '@barracuda-internal/bds-core'

import { Cell, CellAction, CellText } from 'global/components/lib/grid/cell'

import { config } from 'global/lib/config'
import * as datetime from 'global/lib/datetime'
import { useFormatMessage } from 'global/lib/localization'
import { getCountryNameFromCode2 } from 'global/lib/isoCountries'

import routesConfig from 'fir/lib/routes/routesConfig'

import { OPERATORS } from 'fir/redux/types/Workflows'

import styles, { runDetailsGridStyles } from './runDetailsStyles'

const BASE_I18N_KEY = 'fir.app.automated_workflows'

type RunDetailsProps = {
  events: any[]
}

const RunDetails: React.FC<RunDetailsProps> = props => {
  const classes = styles()
  const gridClasses = runDetailsGridStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(() => {
    return (
      <>
        <Paper className={classes.wrapper} elevation={1}>
          <DataTable className={gridClasses.gridLayout} data={props.events}>
            <DataTableColumn
              field="createdOn"
              title={formatMessage('workflow_details.run_details_table.event_time')}
              cell={({ dataItem }: { dataItem: any }) => (
                <Cell>
                  <CellText>
                    {datetime.formatDate(dataItem.createdOn, config.DATETIME.DEFAULT_DATE_WITH_TIME_FORMAT_WITH_SEC)}
                  </CellText>
                </Cell>
              )}
            />
            <DataTableColumn
              field="eventType"
              title={formatMessage('workflow_details.run_details_table.event_type')}
              cell={({ dataItem }: { dataItem: any }) => (
                <Cell>
                  <CellText className={classes.capitalize}>{dataItem.node.type}</CellText>
                </Cell>
              )}
            />
            <DataTableColumn
              field="eventDetails"
              title={formatMessage('workflow_details.run_details_table.event_details')}
              cell={({ dataItem }: { dataItem: any }) => (
                <Cell>
                  {dataItem.node.type !== 'condition' ? (
                    <CellText>
                      {formatMessage(`workflow_dialog.events.${dataItem.node.type}s.${dataItem.node.eventType}`)}
                    </CellText>
                  ) : (
                    <CellText>
                      {`${formatMessage(`workflow_dialog.events.${dataItem.node.type}s.${dataItem.node.eventType}`)} ${
                        dataItem.node.config
                          ? `${formatMessage(
                              `workflow_dialog.events.operators.${OPERATORS[dataItem.node.config.conditionOperator]}`
                            ).toLowerCase()} ${
                              dataItem.node.eventType.endsWith('Country')
                                ? getCountryNameFromCode2(dataItem.node.config.conditionValue)
                                : dataItem.node.config.conditionValue
                            }`
                          : ''
                      } `}
                    </CellText>
                  )}
                </Cell>
              )}
            />
            <DataTableColumn
              field="result"
              title={formatMessage('workflow_details.run_details_table.event_result')}
              cell={({ dataItem }: { dataItem: any }) => (
                <Cell>
                  {dataItem.node.eventType === 'createIncident' && dataItem.success && (
                    <CellAction onClick={() => routesConfig.REMEDIATION_INCIDENT.goto({ incidentId: dataItem.result })}>
                      <CellText className={classes.link}>
                        {formatMessage('workflow_details.run_details_table.created_incident_link')}
                      </CellText>
                    </CellAction>
                  )}
                  {dataItem.node.eventType === 'createIncident' &&
                    !dataItem.success &&
                    (dataItem.result ? (
                      <CellText>{formatMessage(`workflow_details.run_details_table.${dataItem.result}`)}</CellText>
                    ) : (
                      <CellText>{formatMessage('workflow_details.run_details_table.no_matching_messages')}</CellText>
                    ))}
                  {dataItem.node.eventType !== 'createIncident' && (
                    <CellText className={classes.capitalize}>{dataItem.result}</CellText>
                  )}
                </Cell>
              )}
            />
          </DataTable>
        </Paper>
      </>
    )
  }, [classes, gridClasses, formatMessage, props.events])
}

export default RunDetails
