import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { union } from 'lodash'

import {
  IncidentsReport,
  Incident,
  IncidentEmailReport,
  InboxRulesReport,
  InboxRuleFeed,
  IncidentsEmailSearch,
  IncidentEmailSearchFormValues,
  IncidentEmailBody,
  NotifyExternalEmails,
  RemediationStatuses
} from 'global/types/api/remediation'
import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'global/redux/toolkit/api'
import { insertReportData, resetReport, HardResetReport } from 'global/redux/features/reports/reducerHandlers'

import {
  getInboxRules,
  getInboxRuleFeed,
  deleteInboxRule,
  getIncidentsCount,
  getIncidents,
  getIncidentDetails,
  createIncident,
  getRecipients,
  searchForIncidentEmails,
  getIncidentEmailBody,
  deleteEmails,
  quarantineEmails,
  notifyExternalEmails,
  getIncidentTaskStatus
} from 'sen/redux/features/remediation/remediationApiThunks'
import {
  update as updateIncidentsTable,
  INITIAL_STATE as incidentsTableInitialState
} from 'sen/redux/features/dataTables/incidents/incidentsSlice'
import {
  update as updateRecipientsTable,
  INITIAL_STATE as recipientsTableInitialState
} from 'sen/redux/features/dataTables/incident/recipientsSlice'
import {
  update as updateRecipientsWizardTable,
  INITIAL_STATE as recipientsWizardTableInitialState
} from 'sen/redux/features/dataTables/incident/recipientsWizardSlice'
import {
  update as updateIncidentEmailsTable,
  INITIAL_STATE as incidentEmailsInitialState
} from 'sen/redux/features/dataTables/incidents/incidentsEmailsSlice'

export interface RemediationState {
  inboxRules: InboxRulesReport | undefined
  getInboxRulesApiStatus: ApiStatus
  inboxRuleFeed: InboxRuleFeed[] | undefined
  inboxRuleFeedNextKey: string | undefined
  getInboxRuleFeedApiStatus: ApiStatus
  deleteInboxRuleApiStatus: ApiStatus
  incidents: IncidentsReport | undefined
  getIncidentsApiStatus: ApiStatus
  loadedIncidentsOffsets: number[]
  getIncidentsCountApiStatus: ApiStatus
  incidentsCount: number | undefined
  getIncidentDetailsApiStatus: ApiStatus
  incident: Incident | undefined
  getRecipientsApiStatus: ApiStatus
  recipients: IncidentEmailReport | undefined
  loadedGetRecipientsOffsets: number[]
  loadedGetRecipientsWizardOffsets: number[]
  searchForIncidentEmailsApiStatus: ApiStatus
  incidentsEmails: IncidentsEmailSearch | undefined
  loadedSearchForIncidentEmailsOffsets: number[]
  incidentEmailSearchFormValues: IncidentEmailSearchFormValues
  getIncidentEmailBodyApiStatus: ApiStatus
  incidentEmailBody: IncidentEmailBody | undefined
  createIncidentApiStatus: ApiStatus
  currentIncident: Incident | undefined
  deleteEmailsApiStatus: ApiStatus
  quarantineEmailsApiStatus: ApiStatus
  notifyExternalEmailsApiStatus: ApiStatus
  notifyExternalEmailValues: NotifyExternalEmails
  createIncidentStatus: RemediationStatuses | undefined
  getIncidentTaskStatusApiStatus: ApiStatus
}

// initialState
export const INITIAL_STATE: RemediationState = {
  inboxRules: undefined,
  getInboxRulesApiStatus: inIdle,
  inboxRuleFeed: undefined,
  inboxRuleFeedNextKey: undefined,
  getInboxRuleFeedApiStatus: inIdle,
  deleteInboxRuleApiStatus: inIdle,
  incidents: undefined,
  getIncidentsApiStatus: inIdle,
  loadedIncidentsOffsets: [],
  getIncidentsCountApiStatus: inIdle,
  incidentsCount: undefined,
  getIncidentDetailsApiStatus: inIdle,
  incident: undefined,
  getRecipientsApiStatus: inIdle,
  recipients: undefined,
  loadedGetRecipientsOffsets: [],
  loadedGetRecipientsWizardOffsets: [],
  searchForIncidentEmailsApiStatus: inIdle,
  loadedSearchForIncidentEmailsOffsets: [],
  incidentsEmails: undefined,
  incidentEmailSearchFormValues: {
    senderEmail: '',
    emailSubject: '',
    timeframe: 0
  },
  incidentEmailBody: undefined,
  getIncidentEmailBodyApiStatus: inIdle,
  createIncidentApiStatus: inIdle,
  currentIncident: undefined,
  deleteEmailsApiStatus: inIdle,
  quarantineEmailsApiStatus: inIdle,
  notifyExternalEmailsApiStatus: inIdle,
  notifyExternalEmailValues: {
    incidentId: '',
    notificationBody: '',
    notificationSubject: '',
    notificationSender: {
      displayName: '',
      email: ''
    }
  },
  getIncidentTaskStatusApiStatus: inIdle,
  createIncidentStatus: undefined
}

/* eslint-disable no-param-reassign */
export const remediationSlice = createSlice({
  name: 'REMEDIATION',
  initialState: INITIAL_STATE,
  reducers: {
    // set state for reset password callback
    setNotifyExternalEmailValues: {
      reducer: (
        state: RemediationState,
        action: PayloadAction<{ notifyExternalEmailValues: NotifyExternalEmails }>
      ) => {
        state.notifyExternalEmailValues = action.payload.notifyExternalEmailValues || {}
      },
      prepare: (notifyExternalEmailValues: NotifyExternalEmails) => ({
        payload: { notifyExternalEmailValues }
      })
    },
    resetNotifyExternalEmailValues: state => {
      state.notifyExternalEmailValues = INITIAL_STATE.notifyExternalEmailValues
    },
    // reset
    resetInboxRules: state => {
      state.inboxRules = INITIAL_STATE.inboxRules
      state.getInboxRulesApiStatus = INITIAL_STATE.getInboxRulesApiStatus
    },
    resetInboxRuleFeed: state => {
      state.inboxRuleFeed = INITIAL_STATE.inboxRuleFeed
      state.inboxRuleFeedNextKey = INITIAL_STATE.inboxRuleFeedNextKey
      state.getInboxRuleFeedApiStatus = INITIAL_STATE.getInboxRuleFeedApiStatus
    },
    resetIncidents: (state, action: PayloadAction<HardResetReport>) => {
      state.incidents = resetReport(state.incidents, action.payload) || INITIAL_STATE.incidents
      state.loadedIncidentsOffsets = INITIAL_STATE.loadedIncidentsOffsets
      state.getIncidentsApiStatus = INITIAL_STATE.getIncidentsApiStatus
    },
    resetIncidentDetails: state => {
      state.getIncidentDetailsApiStatus = INITIAL_STATE.getIncidentDetailsApiStatus
      state.incident = INITIAL_STATE.incident
    },
    resetIncidentTaskStatus: state => {
      state.getIncidentTaskStatusApiStatus = INITIAL_STATE.getIncidentTaskStatusApiStatus
      state.createIncidentStatus = INITIAL_STATE.createIncidentStatus
    },
    resetRecipients: (state, action: PayloadAction<HardResetReport>) => {
      state.recipients = resetReport(state.recipients, action.payload) || INITIAL_STATE.recipients
      state.loadedGetRecipientsOffsets = INITIAL_STATE.loadedGetRecipientsOffsets
      state.loadedGetRecipientsWizardOffsets = INITIAL_STATE.loadedGetRecipientsWizardOffsets
      state.getRecipientsApiStatus = INITIAL_STATE.getRecipientsApiStatus
    },
    resetSearchForIncidentEmails: (state, action: PayloadAction<HardResetReport>) => {
      state.incidentsEmails = resetReport(state.incidentsEmails, action.payload) || INITIAL_STATE.incidentsEmails
      state.incidentEmailSearchFormValues = INITIAL_STATE.incidentEmailSearchFormValues
      state.loadedSearchForIncidentEmailsOffsets = INITIAL_STATE.loadedSearchForIncidentEmailsOffsets
      state.searchForIncidentEmailsApiStatus = INITIAL_STATE.searchForIncidentEmailsApiStatus
    },
    resetCreateIncident: state => {
      state.createIncidentApiStatus = INITIAL_STATE.createIncidentApiStatus
      state.currentIncident = INITIAL_STATE.currentIncident
    },
    resetGetIncidentEmailBody: state => {
      state.getIncidentEmailBodyApiStatus = INITIAL_STATE.getIncidentEmailBodyApiStatus
      state.incidentEmailBody = INITIAL_STATE.incidentEmailBody
    },
    resetDeleteEmails: state => {
      state.deleteEmailsApiStatus = { ...INITIAL_STATE.deleteEmailsApiStatus }
    },
    resetQuarantineEmails: state => {
      state.quarantineEmailsApiStatus = { ...INITIAL_STATE.quarantineEmailsApiStatus }
    },
    resetNotifyExternalEmails: state => {
      state.notifyExternalEmailsApiStatus = { ...INITIAL_STATE.notifyExternalEmailsApiStatus }
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  },
  extraReducers: builder => {
    builder
      // getInboxRules
      .addCase(getInboxRules.pending, state => {
        state.getInboxRulesApiStatus = inProgress
      })
      .addCase(getInboxRules.fulfilled, (state, action) => {
        state.getInboxRulesApiStatus = successResponse
        state.inboxRules = action.payload as any
      })
      .addCase(getInboxRules.rejected, (state, action) => {
        state.getInboxRulesApiStatus = failedResponse(action.payload as string)
      })

      // deleteInboxRule
      .addCase(deleteInboxRule.pending, state => {
        state.deleteInboxRuleApiStatus = inProgress
      })
      .addCase(deleteInboxRule.fulfilled, (state, action) => {
        state.deleteInboxRuleApiStatus = successResponse
        state.inboxRules = action.payload as any
      })
      .addCase(deleteInboxRule.rejected, (state, action) => {
        state.deleteInboxRuleApiStatus = failedResponse(action.payload as string)
      })

      // getInboxRuleFeed
      .addCase(getInboxRuleFeed.pending, state => {
        state.getInboxRuleFeedApiStatus = inProgress
      })
      .addCase(getInboxRuleFeed.fulfilled, (state, action) => {
        state.getInboxRuleFeedApiStatus = successResponse
        state.inboxRuleFeed = [...(state.inboxRuleFeed || []), ...((action.payload as any)?.data || [])]
        state.inboxRuleFeedNextKey = (action.payload as any)?.nextKey
      })
      .addCase(getInboxRuleFeed.rejected, (state, action) => {
        state.getInboxRuleFeedApiStatus = failedResponse(action.payload as string)
      })

      // getIncidents
      .addCase(updateIncidentsTable, (state, action) => {
        if (action.payload.config?.skip !== undefined) {
          state.loadedIncidentsOffsets = union(state.loadedIncidentsOffsets, [action.payload.config.skip])
        }
      })
      .addCase(getIncidents.pending, state => {
        state.getIncidentsApiStatus = inProgress
        if (!state.loadedIncidentsOffsets.length) {
          state.loadedIncidentsOffsets = [incidentsTableInitialState.skip]
        }
      })
      .addCase(getIncidents.fulfilled, (state, action) => {
        state.getIncidentsApiStatus = successResponse
        state.incidents = insertReportData(state.incidents, action.payload as any)
        state.incidentsCount = (action.payload as any).report?.report?.totalCount
      })
      .addCase(getIncidents.rejected, (state, action) => {
        state.getIncidentsApiStatus = failedResponse(action.payload as string)
      })

      // getIncidentsCount
      .addCase(getIncidentsCount.pending, state => {
        state.getIncidentsCountApiStatus = inProgress
      })
      .addCase(getIncidentsCount.fulfilled, (state, action) => {
        state.getIncidentsCountApiStatus = successResponse
        state.incidentsCount = action.payload
      })
      .addCase(getIncidentsCount.rejected, (state, action) => {
        state.getIncidentsCountApiStatus = failedResponse(action.payload as string)
      })

      // getIncidentDetails
      .addCase(getIncidentDetails.pending, state => {
        state.getIncidentDetailsApiStatus = inProgress
      })
      .addCase(getIncidentDetails.fulfilled, (state, action) => {
        state.getIncidentDetailsApiStatus = successResponse
        state.incident = action.payload
      })
      .addCase(getIncidentDetails.rejected, (state, action) => {
        state.getIncidentDetailsApiStatus = failedResponse(action.payload as string)
      })

      // getIncidentTaskStatus
      .addCase(getIncidentTaskStatus.pending, state => {
        state.getIncidentTaskStatusApiStatus = inProgress
      })
      .addCase(getIncidentTaskStatus.fulfilled, (state, action) => {
        state.getIncidentTaskStatusApiStatus = successResponse
        state.createIncidentStatus = (action.payload as any).data?.status
      })
      .addCase(getIncidentTaskStatus.rejected, (state, action) => {
        state.getIncidentTaskStatusApiStatus = failedResponse(action.payload as string)
      })

      // getRecipients
      .addCase(updateRecipientsTable, (state, action) => {
        if (action.payload.config?.skip !== undefined) {
          state.loadedGetRecipientsOffsets = union(state.loadedGetRecipientsOffsets, [action.payload.config.skip])
        }
      })
      .addCase(updateRecipientsWizardTable, (state, action) => {
        if (action.payload.config?.skip !== undefined) {
          state.loadedGetRecipientsWizardOffsets = union(state.loadedGetRecipientsWizardOffsets, [
            action.payload.config.skip
          ])
        }
      })
      .addCase(getRecipients.pending, state => {
        state.getRecipientsApiStatus = inProgress
        if (!state.loadedGetRecipientsOffsets.length) {
          state.loadedGetRecipientsOffsets = [recipientsTableInitialState.skip]
        } else if (!state.loadedGetRecipientsWizardOffsets.length) {
          state.loadedGetRecipientsWizardOffsets = [recipientsWizardTableInitialState.skip]
        }
      })
      .addCase(getRecipients.fulfilled, (state, action) => {
        state.getRecipientsApiStatus = successResponse
        state.recipients = insertReportData(state.recipients, action.payload as any)
      })
      .addCase(getRecipients.rejected, (state, action) => {
        state.getRecipientsApiStatus = failedResponse(action.payload as string)
      })

      // searchForIncidentEmails
      .addCase(updateIncidentEmailsTable, (state, action) => {
        if (action.payload.config?.skip !== undefined) {
          state.loadedSearchForIncidentEmailsOffsets = union(state.loadedSearchForIncidentEmailsOffsets, [
            action.payload.config.skip
          ])
        }
      })
      .addCase(searchForIncidentEmails.pending, state => {
        state.searchForIncidentEmailsApiStatus = inProgress
        if (!state.loadedSearchForIncidentEmailsOffsets.length) {
          state.loadedSearchForIncidentEmailsOffsets = [incidentEmailsInitialState.skip]
        }
      })
      .addCase(searchForIncidentEmails.fulfilled, (state, action) => {
        state.searchForIncidentEmailsApiStatus = successResponse
        state.incidentsEmails = insertReportData(state.incidentsEmails, action.payload as any)
        state.incidentsCount = (action.payload as any).report?.report?.totalCount
        state.incidentEmailSearchFormValues = {
          ...state.incidentEmailSearchFormValues,
          senderEmail: action.meta.arg.senderEmail || '',
          emailSubject: action.meta.arg.emailSubject || '',
          timeframe: action.meta.arg.timeframe
        }
      })
      .addCase(searchForIncidentEmails.rejected, (state, action) => {
        state.searchForIncidentEmailsApiStatus = failedResponse(action.payload as string)
      })

      // getIncidentEmailBody
      .addCase(getIncidentEmailBody.pending, state => {
        state.getIncidentEmailBodyApiStatus = inProgress
      })
      .addCase(getIncidentEmailBody.fulfilled, (state, action) => {
        state.getIncidentEmailBodyApiStatus = successResponse
        state.incidentEmailBody = action.payload as any
      })
      .addCase(getIncidentEmailBody.rejected, (state, action) => {
        state.getIncidentEmailBodyApiStatus = failedResponse(action.payload as string)
      })

      // createIncident
      .addCase(createIncident.pending, state => {
        state.createIncidentApiStatus = inProgress
      })
      .addCase(createIncident.fulfilled, (state, action) => {
        state.createIncidentApiStatus = successResponse
        state.currentIncident = action.payload as any
      })
      .addCase(createIncident.rejected, (state, action) => {
        state.createIncidentApiStatus = failedResponse(action.payload as string)
      })

      // deleteEmails
      .addCase(deleteEmails.pending, state => {
        state.deleteEmailsApiStatus = inProgress
      })
      .addCase(deleteEmails.fulfilled, (state, action) => {
        state.deleteEmailsApiStatus = successResponse
        state.recipients = action.payload as any
      })
      .addCase(deleteEmails.rejected, (state, action) => {
        state.deleteEmailsApiStatus = failedResponse(action.payload as string)
      })

      // quarantineEmails
      .addCase(quarantineEmails.pending, state => {
        state.quarantineEmailsApiStatus = inProgress
      })
      .addCase(quarantineEmails.fulfilled, (state, action) => {
        state.quarantineEmailsApiStatus = successResponse
        state.recipients = action.payload as any
      })
      .addCase(quarantineEmails.rejected, (state, action) => {
        state.quarantineEmailsApiStatus = failedResponse(action.payload as string)
      })

      // notifyExternalEmails
      .addCase(notifyExternalEmails.pending, state => {
        state.notifyExternalEmailsApiStatus = inProgress
      })
      .addCase(notifyExternalEmails.fulfilled, state => {
        state.notifyExternalEmailsApiStatus = successResponse
      })
      .addCase(notifyExternalEmails.rejected, (state, action) => {
        state.notifyExternalEmailsApiStatus = failedResponse(action.payload as string)
      })
  }
})
/* eslint-enable no-param-reassign */

export const {
  resetInboxRules,
  resetInboxRuleFeed,
  resetIncidents,
  resetIncidentDetails,
  resetIncidentTaskStatus,
  resetRecipients,
  resetSearchForIncidentEmails,
  resetGetIncidentEmailBody,
  resetCreateIncident,
  resetDeleteEmails,
  resetQuarantineEmails,
  resetNotifyExternalEmails,
  resetNotifyExternalEmailValues,
  setNotifyExternalEmailValues,
  reset
} = remediationSlice.actions

export {
  getInboxRules,
  getInboxRuleFeed,
  deleteInboxRule,
  getIncidents,
  getIncidentsCount,
  getIncidentDetails,
  getIncidentTaskStatus,
  getRecipients,
  searchForIncidentEmails,
  getIncidentEmailBody,
  createIncident,
  deleteEmails,
  quarantineEmails,
  notifyExternalEmails
}

export default remediationSlice.reducer
