import React, { ReactNode } from 'react'
import PropTypes from 'prop-types'

import { AppBar, Toolbar } from '@barracuda-internal/bds-core'

import styles from 'global/components/lib/layout/navbar/navbarStyles'

export interface NavbarProps {
  isNavbarVisible: boolean
  children?: ReactNode
}

const Navbar: React.FC<NavbarProps> = ({ isNavbarVisible, children }) => {
  const classes = styles()

  if (!isNavbarVisible) {
    return null
  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolBar}>{children}</Toolbar>
      </AppBar>
    </div>
  )
}

Navbar.propTypes = {
  isNavbarVisible: PropTypes.bool.isRequired,
  children: PropTypes.any
}

Navbar.defaultProps = {
  children: null
}

export default Navbar
