import { InsightsUserSubmissionFields } from '@barracuda/reporting'

import { Column } from 'global/types/api/unifiedReporting'

export type InsightsUserSubmissionSearchCriteria = {
  subjectQuery?: string
  senderEmail?: string
}

export type InsightsUserSubmission = {
  senderDomain?: string
  accessTokenId: string
  reportedBy: string
  deliveredDate: string
  reportedDate: string
  state: string
  matchedEmailCount: number
  isPhishline: boolean
  searchCriteria: InsightsUserSubmissionSearchCriteria
}

export const insightsUserSubmissionColumnConfig: Column[] = [
  {
    accessorKey: InsightsUserSubmissionFields.REPORTED_DATE.name,
    header: 'reported_date',
    fieldType: InsightsUserSubmissionFields.REPORTED_DATE.type,
    possibleValues: InsightsUserSubmissionFields.REPORTED_DATE.possibleValues,
    category: 'barracuda',
    hideColumnFromList: true
  },
  {
    accessorKey: InsightsUserSubmissionFields.REPORTED_BY.name,
    header: 'reporter',
    fieldType: InsightsUserSubmissionFields.REPORTED_BY.type,
    possibleValues: InsightsUserSubmissionFields.REPORTED_BY.possibleValues,
    category: 'barracuda'
  },
  {
    accessorKey: InsightsUserSubmissionFields.STATE.name,
    header: 'status',
    fieldType: InsightsUserSubmissionFields.STATE.type,
    possibleValues: InsightsUserSubmissionFields.STATE.possibleValues,
    category: 'barracuda'
  },
  {
    accessorKey: InsightsUserSubmissionFields.MATCHED_EMAIL_COUNT.name,
    header: 'matched_email_count',
    fieldType: InsightsUserSubmissionFields.MATCHED_EMAIL_COUNT.type,
    possibleValues: InsightsUserSubmissionFields.MATCHED_EMAIL_COUNT.possibleValues,
    category: 'barracuda'
  },
  {
    accessorKey: InsightsUserSubmissionFields.IS_PHISHLINE.name,
    header: 'is_phishline',
    fieldType: InsightsUserSubmissionFields.IS_PHISHLINE.type,
    possibleValues: InsightsUserSubmissionFields.IS_PHISHLINE.possibleValues,
    category: 'barracuda'
  },
  {
    accessorKey: InsightsUserSubmissionFields.SEARCH_CRITERIA_SENDER_EMAIL.name,
    header: 'from_address',
    fieldType: InsightsUserSubmissionFields.SEARCH_CRITERIA_SENDER_EMAIL.type,
    possibleValues: InsightsUserSubmissionFields.SEARCH_CRITERIA_SENDER_EMAIL.possibleValues,
    category: 'sender'
  },
  {
    accessorKey: InsightsUserSubmissionFields.SENDER_DOMAIN.name,
    header: 'sender_domain',
    fieldType: InsightsUserSubmissionFields.SENDER_DOMAIN.type,
    possibleValues: InsightsUserSubmissionFields.SENDER_DOMAIN.possibleValues,
    category: 'sender'
  },
  {
    accessorKey: InsightsUserSubmissionFields.SEARCH_CRITERIA_SUBJECT_QUERY.name,
    header: 'subject',
    fieldType: InsightsUserSubmissionFields.SEARCH_CRITERIA_SUBJECT_QUERY.type,
    possibleValues: InsightsUserSubmissionFields.SEARCH_CRITERIA_SUBJECT_QUERY.possibleValues,
    category: 'email'
  },
  {
    accessorKey: InsightsUserSubmissionFields.DELIVERED_DATE.name,
    header: 'delivered_date',
    fieldType: InsightsUserSubmissionFields.DELIVERED_DATE.type,
    possibleValues: InsightsUserSubmissionFields.DELIVERED_DATE.possibleValues,
    category: 'email'
  },
  {
    accessorKey: InsightsUserSubmissionFields.MS_MESSAGE_ID.name,
    header: 'ms_message_id',
    fieldType: InsightsUserSubmissionFields.MS_MESSAGE_ID.type,
    possibleValues: InsightsUserSubmissionFields.MS_MESSAGE_ID.possibleValues,
    category: 'email'
  }
]
