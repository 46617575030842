import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { validateApiError } from 'global/lib/api/restClient'
import { config } from 'global/lib/config'

import apiRoutes from 'sen/lib/api/apiRoutes'
import {
  GetSyslogConfigRequest,
  GetSyslogConfigResponse,
  SaveSyslogConfigRequest,
  SaveSyslogConfigResponse,
  TestSyslogConfigRequest,
  TestSyslogConfigResponse
} from 'sen/redux/features/syslog/syslogTypes'

export const getSyslogConfig = createAsyncThunk<GetSyslogConfigResponse, GetSyslogConfigRequest, {}>(
  'SYSLOG/getSyslogConfig',
  async function doGetSyslogConfig(request: GetSyslogConfigRequest, { rejectWithValue }) {
    try {
      const resp = await restClient(apiRoutes.GET_SYSLOG_CONFIG, {
        data: { app: config.PRODUCT_ABBR.SENTINEL }
      })

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const saveSyslogConfig = createAsyncThunk<SaveSyslogConfigResponse, SaveSyslogConfigRequest, {}>(
  'SYSLOG/saveSyslogConfig',
  async function doSaveSyslogConfig(request: SaveSyslogConfigRequest, { rejectWithValue }) {
    try {
      const resp = await restClient(apiRoutes.SAVE_SYSLOG_CONFIG, {
        data: {
          app: config.PRODUCT_ABBR.SENTINEL,
          ...request
        }
      })

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const testSyslogConfig = createAsyncThunk<TestSyslogConfigResponse, TestSyslogConfigRequest, {}>(
  'SYSLOG/testSyslogConfig',
  async function doTestSyslogConfig(request: TestSyslogConfigRequest, { rejectWithValue }) {
    try {
      const resp = await restClient(apiRoutes.TEST_SYSLOG_CONFIG, {
        data: { app: config.PRODUCT_ABBR.SENTINEL }
      })

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)
