import React, { useMemo } from 'react'

import {
  Button,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Zoom
} from '@barracuda-internal/bds-core'

import { Email as EmailIcon } from '@barracuda-internal/bds-core/dist/Icons/Email'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import { useFormatMessage } from 'global/lib/localization'

import { webhookDialogStyles } from './webhookDialogStyles'
import useWebhookDialogLogic, { UseWebhookDialogParams } from './useWebhookDialogLogic'

const BASE_I18N_KEY = 'fir.app.automated_workflows.webhook_dialog'

type WebhookDialogProps = UseWebhookDialogParams

const WebhookDialog: React.FC<WebhookDialogProps> = props => {
  const classes = webhookDialogStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [webhookDialogProps] = useWebhookDialogLogic(props)

  const {
    isOpened,
    isSaveSettingsLoading,
    onClose,
    onSave,
    showValidationError,
    updateWebhook,
    webhookComponentStrings,
    webhookSource,
    webhookValue
  } = webhookDialogProps

  return useMemo(() => {
    return (
      <Dialog fullWidth maxWidth="sm" open={isOpened} TransitionComponent={Zoom}>
        <DialogTitle>
          <Typography variant="h5" component="span">
            {webhookComponentStrings.title}
          </Typography>
        </DialogTitle>
        {isSaveSettingsLoading && <LinearProgress />}
        <DialogContent>
          <Grid container direction="row" className={classes.formControl}>
            <Grid xs="auto" item>
              <img alt="Webhook Icon" className={classes.iconPadding} src={webhookComponentStrings.iconPath} />
            </Grid>
            <Grid component="label" className={classes.label} xs={11} item>
              <Typography variant="subtitle2" className={classes.wordWrap}>
                {webhookComponentStrings.description}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.formControl}>
            {/* This <EmailIcon /> needs to be here so that the <TextField /> aligns properly */}
            <Grid xs="auto" item>
              <EmailIcon className={classes.invisibleIcon} fontSize="large" />
            </Grid>
            <Grid xs={11} item>
              <TextField
                autoFocus
                className={classes.mdTextField}
                label={webhookComponentStrings.label}
                value={webhookValue}
                onChange={updateWebhook(webhookSource.action)}
                error={showValidationError}
                helperText={showValidationError ? webhookComponentStrings.errorText : ''}
              />
            </Grid>
          </Grid>
          <Grid container direction="row">
            {/* This <img /> needs to be here so that the <TextField /> aligns properly */}
            <Grid xs="auto" item>
              <EmailIcon className={classes.invisibleIcon} fontSize="large" />
            </Grid>
            <Grid component="label" className={classes.label} xs="auto" item>
              <Link variant="body2" href={webhookComponentStrings.helpLink} target="_blank" rel="noopener">
                {webhookComponentStrings.helpLinkLabel}
              </Link>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid component="label" className={classes.label} xs={12} item>
              <Typography variant="caption" className={classes.helperText}>
                {webhookComponentStrings.helperText}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            {formatMessage('buttons.cancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={onSave}>
            {formatMessage('buttons.save')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }, [
    classes,
    formatMessage,
    isOpened,
    isSaveSettingsLoading,
    onClose,
    onSave,
    showValidationError,
    updateWebhook,
    webhookComponentStrings,
    webhookSource.action,
    webhookValue
  ])
}

export default WebhookDialog
