import { createSlice } from '@reduxjs/toolkit'

import { insertReportData } from 'global/redux/features/reports/reducerHandlers'
import { ApiStatus, failedResponse, inIdle, inProgress, successResponse } from 'global/redux/toolkit/api'

import { getChartsReports } from 'ets/redux/features/reports/reportsApiThunks'

export interface ChartReportState {
  apiStatus: ApiStatus
  attacksByThreats: any
  attacksPerMonth: any
  employeesWithThreatsPerMonth: any
}

// initialState
export const INITIAL_STATE: ChartReportState = {
  apiStatus: inIdle,
  attacksByThreats: { report: {} },
  attacksPerMonth: { report: {} },
  employeesWithThreatsPerMonth: { report: {} }
}

/* eslint-disable no-param-reassign */
export const chartReportSlice = createSlice({
  name: 'REPORTS/ATTACK_PER_MONTH',
  initialState: INITIAL_STATE,
  reducers: {
    resetChartReport: () => {
      return {
        ...INITIAL_STATE
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getChartsReports.pending, state => {
        state.apiStatus = inProgress
      })
      .addCase(getChartsReports.fulfilled, (state, action) => {
        state.apiStatus = successResponse
        state.attacksByThreats = insertReportData(state.attacksByThreats, action.payload.attacksByThreats)
        state.attacksPerMonth = insertReportData(state.attacksPerMonth, action.payload.attacksPerMonth)
        state.employeesWithThreatsPerMonth = insertReportData(
          state.employeesWithThreatsPerMonth,
          action.payload.employeesWithThreatsPerMonth
        )
      })
      .addCase(getChartsReports.rejected, (state, action) => {
        state.apiStatus = failedResponse(action.payload as string)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { resetChartReport } = chartReportSlice.actions

export { getChartsReports }

export default chartReportSlice.reducer
