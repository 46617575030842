import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  alert: {
    margin: 10
  },
  content: {
    fontSize: 17,
    marginTop: 15
  },
  formHelperText: {
    fontSize: 13,
    fontWeight: 'bold',
    marginLeft: 0,
    whiteSpace: 'nowrap'
  },
  selectWrapper: {
    width: '40%'
  },
  subTitle: {
    color: theme.colors.barracudaBlack50,
    fontSize: 15
  },
  tags: {
    marginRight: theme.spacing(0.5)
  },
  title: {
    fontWeight: 600
  }
}))
