import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { render } from 'react-dom'
import { v4 as uuidv4 } from 'uuid'
import $ from 'jquery'

import * as datetime from 'global/lib/datetime'

import CustomRanges from './CustomRanges'
import CustomText from './CustomText'

export interface DateRangePickerConfig {
  focusedDate: any
  onBlur?: (e?: any) => void
  onChange: (e: any) => void
  onFocus?: (e?: any) => void
  show?: boolean
  value: {
    start: Date | null
    end: Date | null
  }
}

export interface DateRangePickerLogicProps {
  dateRangePickerConfig: DateRangePickerConfig
}

export interface UseDateRangePickerLogicParams {
  dateRange: DateRange
  customRanges?: CustomRange[]
  isEndDateFixed?: boolean
  onDateRangesChanged: (dateRange: DateRange, isCustomRange?: boolean) => void
  selectedRange?: CustomRange | undefined
  setDateRange: (DateRange: DateRange, isCustomRange?: boolean) => void
}

export interface CustomRange {
  id: string
  text: any
  timeframe: number
}

export interface DateRange {
  end: Date
  start: Date
}

const CUSTOM_WIDGET_ID = 'custom-date-picker-text'
const CUSTOM_POPUP_ID = 'custom-date-picker-times'

export default function useDateRangePickerLogic(
  dateRangePickerLogicConfig: UseDateRangePickerLogicParams
): [DateRangePickerLogicProps] {
  const [shouldHideDatePicker, setShouldHideDatePicker] = useState<boolean>(false)
  const [originalDates, setOriginalDates] = useState<any>({})
  const widgetId = `date-range-picker-${uuidv4()}`

  // init
  useEffect(() => {
    if (dateRangePickerLogicConfig.customRanges) {
      if (!document.getElementById(CUSTOM_WIDGET_ID)) {
        const $dateRangePickerWidget = $(`#${widgetId}`)
        const newChild = $('<div/>')
          .attr('id', CUSTOM_WIDGET_ID)
          .css({ display: 'inline-block' })

        if ($dateRangePickerWidget) {
          $dateRangePickerWidget.append(newChild)

          renderCustomText()
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // widget customizations with predefined ranges
  // render custom text
  const renderCustomText = useCallback(() => {
    if (dateRangePickerLogicConfig.customRanges && document.getElementById(CUSTOM_WIDGET_ID)) {
      if (dateRangePickerLogicConfig.selectedRange) {
        render(
          <CustomText>{dateRangePickerLogicConfig.selectedRange.text}</CustomText>,
          document.getElementById(CUSTOM_WIDGET_ID)
        )
      } else {
        render(<div />, document.getElementById(CUSTOM_WIDGET_ID))
      }
    }
  }, [dateRangePickerLogicConfig.selectedRange, dateRangePickerLogicConfig.customRanges])

  // update custom text when selected range has been changed
  useEffect(() => {
    renderCustomText()
  }, [dateRangePickerLogicConfig.selectedRange, renderCustomText])

  const submitDateRange = useCallback(
    (dateRange: DateRange, isCustomRange = false) => {
      setShouldHideDatePicker(true)
      dateRangePickerLogicConfig.setDateRange(dateRange, isCustomRange)
      dateRangePickerLogicConfig.onDateRangesChanged(dateRange, isCustomRange)
    },
    [setShouldHideDatePicker, dateRangePickerLogicConfig]
  )

  // add custom predefined ranges
  const addCustomRanges = useCallback(() => {
    if (dateRangePickerLogicConfig.customRanges) {
      // Delay hack for waiting the popup
      setTimeout(() => {
        if (!document.getElementById(CUSTOM_POPUP_ID)) {
          const $dateRangePickerPopup = $('.k-popup.k-child-animation-container')
          const newChild = $('<div/>')
            .attr('id', CUSTOM_POPUP_ID)
            .css({ display: 'flex-inline', width: 200 })

          $dateRangePickerPopup.css({ flexDirection: 'row' }).append(newChild)

          render(
            <CustomRanges
              selectedRange={dateRangePickerLogicConfig.selectedRange}
              ranges={(dateRangePickerLogicConfig.customRanges || []).map((range: CustomRange) => ({
                ...range,
                onClick: () => {
                  submitDateRange({ start: datetime.removeHours(new Date(), range.timeframe), end: new Date() }, true)
                }
              }))}
            />,
            document.getElementById(CUSTOM_POPUP_ID)
          )
        }
      }, 0)
    }
  }, [dateRangePickerLogicConfig, submitDateRange])

  const onDateRangeBlur = useCallback(() => {
    if (
      !dateRangePickerLogicConfig.dateRange.start ||
      (!dateRangePickerLogicConfig.dateRange.end && !dateRangePickerLogicConfig.isEndDateFixed)
    ) {
      dateRangePickerLogicConfig.setDateRange(originalDates)
    }
  }, [dateRangePickerLogicConfig, originalDates])

  // callbacks for user interactions
  const onDateRangeChange = useCallback(
    (e: any) => {
      if (e.nativeEvent instanceof MouseEvent) {
        if (shouldHideDatePicker) {
          setShouldHideDatePicker(false)
          dateRangePickerLogicConfig.setDateRange(e.value)
        } else if (e.value.start && e.value.end) {
          submitDateRange(e.value)
        } else if (e.value.start && dateRangePickerLogicConfig.isEndDateFixed) {
          submitDateRange({
            start: e.value.start,
            end: dateRangePickerLogicConfig.dateRange.end
          })
        }
      }
    },
    [shouldHideDatePicker, dateRangePickerLogicConfig, submitDateRange]
  )

  const onDateRangeFocus = useCallback(
    ({ target }) => {
      // Kendo bug fix (kendo calls the onFocus method on second click)
      const isOutsideClick = target.getAttribute('role') !== 'grid'

      if (isOutsideClick) {
        addCustomRanges()
        setOriginalDates(dateRangePickerLogicConfig.dateRange)
        setShouldHideDatePicker(false)
      }
    },
    [dateRangePickerLogicConfig.dateRange, addCustomRanges]
  )

  // config for the kendo component
  const dateRangePickerConfig = useMemo(() => {
    const {
      dateRange,
      onDateRangesChanged,
      setDateRange,
      customRanges,
      selectedRange,
      ...restConfig
    } = dateRangePickerLogicConfig

    return {
      onFocus: onDateRangeFocus,
      onBlur: onDateRangeBlur,
      onChange: onDateRangeChange,
      value: dateRangePickerLogicConfig.dateRange,
      focusedDate: dateRangePickerLogicConfig.dateRange.end,
      id: widgetId,
      ...restConfig,
      ...(shouldHideDatePicker && { show: false })
    } as DateRangePickerConfig
  }, [onDateRangeChange, onDateRangeFocus, onDateRangeBlur, shouldHideDatePicker, dateRangePickerLogicConfig, widgetId])

  return useMemo(
    () => [
      {
        dateRangePickerConfig
      }
    ],
    [dateRangePickerConfig]
  )
}
