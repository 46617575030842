import { createSlice } from '@reduxjs/toolkit'

import { insertReportData } from 'global/redux/features/reports/reducerHandlers'
import { ApiStatus, failedResponse, inIdle, inProgress, successResponse } from 'global/redux/toolkit/api'

import { getEmployeesReport, getPDFEmployeesReport } from 'ets/redux/features/reports/reportsApiThunks'
import { INITIAL_STATE as employeesTableInitialState } from 'ets/redux/features/dataTables/employees/employeesSlice'

export interface EmployeesState {
  apiStatus: ApiStatus
  pdfApiStatus: ApiStatus
  loadedOffsets: number[]
  pdfLoadedOffsets: number[]
  data: any
  pdfData: any
}

// initialState
export const INITIAL_STATE: EmployeesState = {
  apiStatus: inIdle,
  pdfApiStatus: inIdle,
  loadedOffsets: [],
  pdfLoadedOffsets: [],
  data: { report: {} },
  pdfData: { report: {} }
}

/* eslint-disable no-param-reassign */
export const employeesSlice = createSlice({
  name: 'REPORTS/EMPLOYEES',
  initialState: INITIAL_STATE,
  reducers: {
    resetEmployeesReport: () => {
      return {
        ...INITIAL_STATE
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getEmployeesReport.pending, state => {
        state.apiStatus = inProgress
        if (!state.loadedOffsets.length) {
          state.loadedOffsets = [employeesTableInitialState.skip]
        }
      })
      .addCase(getEmployeesReport.fulfilled, (state, action) => {
        state.apiStatus = successResponse
        state.data = insertReportData(state.data, action.payload as any)
      })
      .addCase(getEmployeesReport.rejected, (state, action) => {
        state.apiStatus = failedResponse(action.payload as string)
      })
      .addCase(getPDFEmployeesReport.pending, state => {
        state.pdfApiStatus = inProgress
      })
      .addCase(getPDFEmployeesReport.fulfilled, (state, action) => {
        state.pdfApiStatus = successResponse
        state.pdfData = insertReportData(state.pdfData, action.payload as any)
      })
      .addCase(getPDFEmployeesReport.rejected, (state, action) => {
        state.pdfApiStatus = failedResponse(action.payload as string)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { resetEmployeesReport } = employeesSlice.actions

export { getEmployeesReport, getPDFEmployeesReport }
export default employeesSlice.reducer
