import { useMemo, useEffect, useCallback, useState } from 'react'

import { process } from '@progress/kendo-data-query'

import { isSuccess, isPending, getErrorMessage } from 'global/redux/toolkit/api'
import { config } from 'global/lib/config'
import usePermissionBannerLogic from 'global/components/lib/layout/permissionBanner/usePermissionBannerLogic'
import { InboxRuleFeed, ModifiedInboxRuleFeed } from 'global/types/api/remediation'
import { BDSGridPagerConfig } from 'global/types/dataTables/dataTables'
import { ColumnsConfig } from 'global/types/dataTables/columnsConfigType'
import { formatDateWithTime } from 'global/lib/datetime'
import useDialogLogic, { DialogProps } from 'global/lib/dialogs/useDialogLogic'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import useRecipientsAutocompleteLogic, {
  RecipientsAutocompleteConfig,
  SelectedRecipient
} from 'sen/components/lib/autocompletes/identity/recipients/useRecipientsAutocompleteLogic'
import { getInboxRuleFeed, resetInboxRuleFeed } from 'sen/redux/features/remediation/remediationSlice'
import {
  update as updateInboxRuledFeedTable,
  reset as resetInboxRuleFeedTable
} from 'sen/redux/features/dataTables/inboxRuleFeed/inboxRuleFeedSlice'

export interface Permission {
  isChecked: boolean
  hasReadActivityFeedPermission: boolean
  reconnectO365: () => void
}
export interface RecipientsSelectorProps {
  selectedRecipient: SelectedRecipient
  config: RecipientsAutocompleteConfig
}

export interface TableConfig {
  isLoaded: boolean
  inProgress: boolean
  isFlexibleTable: boolean
  tableData: {
    total: number
    data: ModifiedInboxRuleFeed[]
  }
  pageConfig: BDSGridPagerConfig
  columns: { [key: string]: string }
  columnsConfig: { [key: string]: ColumnsConfig }
}
export interface EventHandlers {
  onInvestigate: (feed: ModifiedInboxRuleFeed) => void
}
export interface RangeInboxRulesDialogConfig extends DialogProps {
  selectedFeed: ModifiedInboxRuleFeed | undefined
}
export type Error = string | undefined

export default function useInboxRulesLogic(): [
  Permission,
  RecipientsSelectorProps,
  TableConfig,
  EventHandlers,
  RangeInboxRulesDialogConfig,
  Error
] {
  const dispatch = useAppDispatch()
  const {
    isPermissionChecked,
    hasReadActivityFeedPermission,
    inboxRuleFeed,
    inboxRuleFeedTable,
    inProgress,
    isDataLoaded,
    error,
    nextKey
  } = useAppSelector(_stores => ({
    isPermissionChecked: isSuccess(_stores.globalRemediation.checkO365PermissionsApiStatus),
    hasReadActivityFeedPermission: _stores.globalRemediation.o365Permissions.hasReadActivityFeedPermission,
    inboxRuleFeed: _stores.remediation.inboxRuleFeed,
    inboxRuleFeedTable: _stores.dataTables.inboxRuleFeed,
    inProgress: isPending(_stores.remediation.getInboxRuleFeedApiStatus),
    isDataLoaded: isSuccess(_stores.remediation.getInboxRuleFeedApiStatus),
    error: getErrorMessage(_stores.remediation.getInboxRuleFeedApiStatus),
    nextKey: _stores.remediation.inboxRuleFeedNextKey
  }))
  const [recipientsAutocompleteConfig, selectedRecipient] = useRecipientsAutocompleteLogic()
  const [isRangeInboxRulesDialogOpened, toggleRangeInboxRulesDialog] = useDialogLogic()
  const [selectedFeed, setSelectedFeed] = useState<ModifiedInboxRuleFeed | undefined>()
  const [, globalReconnectO365] = usePermissionBannerLogic()

  // init
  useEffect(() => {
    dispatch(getInboxRuleFeed())

    return () => {
      dispatch(resetInboxRuleFeed())
      dispatch(resetInboxRuleFeedTable())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const reconnectO365 = useCallback(() => {
    globalReconnectO365(config.RECONNECT_SOURCES.ATO_RULES_PAGE)
  }, [globalReconnectO365])

  const tableData = useMemo(() => {
    const { skip, take } = inboxRuleFeedTable

    const formattedAction = (report: InboxRuleFeed) => {
      if (!!report.rulesAfter?.length || !!report.rulesBefore?.length) {
        switch (true) {
          case report.rulesAfter?.length > report.rulesBefore?.length:
            return 'added_rule'
          case report.rulesAfter?.length === report.rulesBefore?.length:
            return 'modified_rule'
          case report.rulesAfter?.length < report.rulesBefore?.length:
            return 'deleted_rule'
          default:
            return false
        }
      }

      return false
    }

    const { data } = process(
      (inboxRuleFeed || []).map((report: InboxRuleFeed) => ({
        ...(report && {
          ...report,
          formattedAction: formattedAction(report),
          formattedDate: formatDateWithTime(report.changeTime)
        })
      })),
      { skip, take }
    )

    return {
      data: data.filter(report => report.userDisplayName),
      total: inboxRuleFeed?.length || 0
    }
  }, [inboxRuleFeed, inboxRuleFeedTable])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = inboxRuleFeedTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: tableData.total,
      onPageChange: (e: any) => {
        dispatch(updateInboxRuledFeedTable(e.page))
      }
    }
  }, [inboxRuleFeedTable, tableData, dispatch])

  const onInvestigate = useCallback(
    (feed: ModifiedInboxRuleFeed) => {
      setSelectedFeed(feed)
      toggleRangeInboxRulesDialog()
    },
    [toggleRangeInboxRulesDialog]
  )

  const onCloseRangeInboxRulesDialog = useCallback(() => {
    setSelectedFeed(undefined)
    toggleRangeInboxRulesDialog()
  }, [toggleRangeInboxRulesDialog])

  // fetch more data if there are any
  useEffect(() => {
    const isLastPage =
      Math.ceil(tableData.total / inboxRuleFeedTable.ITEMS_PER_PAGE) * inboxRuleFeedTable.ITEMS_PER_PAGE ===
      inboxRuleFeedTable.skip + inboxRuleFeedTable.ITEMS_PER_PAGE
    const hasMoreData = nextKey || (!nextKey && !isDataLoaded)

    if (isDataLoaded && !inProgress && isLastPage && hasMoreData) {
      dispatch(getInboxRuleFeed())
    }
  }, [inboxRuleFeedTable, tableData, nextKey, inProgress, isDataLoaded, dispatch])

  return useMemo(() => {
    return [
      {
        isChecked: isPermissionChecked,
        hasReadActivityFeedPermission,
        reconnectO365
      },
      {
        selectedRecipient,
        config: recipientsAutocompleteConfig
      },
      {
        isLoaded: !!inboxRuleFeed,
        inProgress,
        isFlexibleTable: tableData.total < inboxRuleFeedTable.ITEMS_PER_PAGE,
        tableData,
        pageConfig,
        columns: inboxRuleFeedTable.GRID_COLUMNS,
        columnsConfig: inboxRuleFeedTable.columnsConfig
      },
      {
        onInvestigate
      },
      {
        isDialogOpened: isRangeInboxRulesDialogOpened,
        toggleDialog: onCloseRangeInboxRulesDialog,
        selectedFeed
      },
      error
    ]
  }, [
    recipientsAutocompleteConfig,
    inboxRuleFeed,
    selectedRecipient,
    isPermissionChecked,
    hasReadActivityFeedPermission,
    reconnectO365,
    tableData,
    pageConfig,
    inProgress,
    inboxRuleFeedTable,
    onInvestigate,
    isRangeInboxRulesDialogOpened,
    selectedFeed,
    onCloseRangeInboxRulesDialog,
    error
  ])
}
