import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { COLORS } from 'global/configs/theme/defaults'
import TextLabel, { LabelColors } from 'global/components/lib/textLabel/TextLabel'
import { useFormatMessage } from 'global/lib/localization'
import { StatusIds } from './StatusIds.enum'

export interface StatusTypeLabelProps {
  status: StatusIds | undefined
}

const BASE_STATUS_TYPES_I18N_KEY = 'app.attack_statuses'

export enum StatusColors {
  normal = 'normal',
  warning = 'warning',
  success = 'success'
}

export type StatusConfig = {
  [key in StatusIds]: StatusColors
}

const STATUS_COLORS: StatusConfig = {
  // normal
  [StatusIds.movedToJunkFolder]: StatusColors.normal,
  [StatusIds.alertedRecipient]: StatusColors.normal,
  [StatusIds.alertedSentinelAdmin]: StatusColors.normal,
  [StatusIds.alertedImpersonatedUser]: StatusColors.normal,
  [StatusIds.deleted]: StatusColors.normal,
  [StatusIds.emptyStatus]: StatusColors.normal,
  [StatusIds.markedAsFp]: StatusColors.normal,
  [StatusIds.olderThank30days]: StatusColors.normal,
  [StatusIds.markedAsFpRemediation]: StatusColors.normal,
  [StatusIds.inProgress]: StatusColors.normal,

  // warning
  [StatusIds.notReviewed]: StatusColors.warning,
  [StatusIds.failedToDelete]: StatusColors.warning,

  // success
  [StatusIds.reviewed]: StatusColors.success
}

const COLOR_VALUES = {
  [StatusColors.normal]: COLORS.PRODUCT_UI_ELEMENT,
  [StatusColors.warning]: COLORS.RED_600,
  [StatusColors.success]: COLORS.TEAL_600
}

const StatusTypeLabel: React.FC<StatusTypeLabelProps> = ({ status }) => {
  const formatMessage = useFormatMessage(BASE_STATUS_TYPES_I18N_KEY)

  return useMemo(() => {
    if (!status || !STATUS_COLORS[status] || status === StatusIds.emptyStatus) {
      return null
    }

    const bgColor = STATUS_COLORS[status]

    return (
      <TextLabel
        color={bgColor === StatusColors.normal ? LabelColors.dark : LabelColors.light}
        bgColor={COLOR_VALUES[bgColor]}
        data-field="status-type-label"
      >
        {formatMessage(String(status))}
      </TextLabel>
    )
  }, [status, formatMessage])
}

StatusTypeLabel.propTypes = {
  status: PropTypes.any
}

StatusTypeLabel.defaultProps = {
  status: undefined
}

export default StatusTypeLabel
