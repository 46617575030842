import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from 'global/configs/theme/defaults'

import tableStyles, {
  CELL_HEIGHT,
  TABLE_HEADER_HEIGHT,
  TABLE_FOOTER_HEIGHT
} from 'global/components/lib/grid/tableStyles'

export { TABLE_HEADER_HEIGHT, TABLE_FOOTER_HEIGHT }
export const TABLE_CELL_HEIGHT = CELL_HEIGHT
const TABLE_MIN_HEIGHT = TABLE_CELL_HEIGHT * 5 + TABLE_HEADER_HEIGHT + TABLE_FOOTER_HEIGHT

const TAB_CONTENT_HEIGTH = 525

export default makeStyles(theme => {
  const defaultTableStyles = tableStyles(theme)

  return {
    tabContent: {
      minHeight: TAB_CONTENT_HEIGTH
    },
    addButton: {
      marginTop: theme.spacing(2)
    },
    footer: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    ...defaultTableStyles,
    tableArea: {
      ...defaultTableStyles.tableArea,
      marginTop: theme.spacing(2),
      minHeight: TABLE_MIN_HEIGHT
    },
    dataTable: {
      ...defaultTableStyles.dataTable,
      height: TABLE_MIN_HEIGHT
    },
    icon: {
      verticalAlign: -5,
      color: COLORS.BLACK_50,
      cursor: 'pointer'
    },
    title: {
      color: theme.palette.text.primary,
      fontSize: 24,
      fontWeight: 300,
      letterSpacing: 0,
      lineHeight: '32px',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    formRow: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    lastFormRow: {
      height: theme.spacing(3),
      marginTop: theme.spacing(2)
    },
    textField: {
      width: '100%'
    }
  }
})
