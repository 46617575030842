import React, { useState } from 'react'
import queryString from 'query-string'

import { isPending, isSuccess, isFailed } from 'global/redux/toolkit/api'
import { getContestScanStatus } from 'global/redux/features/contest/contestSlice'
import HowManyScans from 'global/components/features/static/howManyScans/HowManyScans'
import HowManyScansPartnerPortal from 'global/components/features/static/howManyScans/HowManyScansPartnerPortal'

import { useAppDispatch, useAppSelector } from 'ets/redux/toolkit/hooks'

const ETSHowManyScans: React.FC = () => {
  const isPartnerPortal = window.location.pathname.includes('partnerportal')
  const emailFromUrl = queryString.parse(window.location.search).email as string

  const [email, setEmail] = useState(emailFromUrl || '')
  const dispatch = useAppDispatch()

  const apiStatus = useAppSelector(state => {
    return state?.contest?.contestApiStatus
  })

  const HowManyScansProps = {
    error: isFailed(apiStatus) ? 'default' : '',
    isSending: isPending(apiStatus),
    isSent: isSuccess(apiStatus),
    email,
    onEmailChange: setEmail,
    onScanClick: () => {
      dispatch(getContestScanStatus(email))
    }
  }

  return (
    <>
      {!isPartnerPortal && <HowManyScans {...HowManyScansProps} />}
      {isPartnerPortal && <HowManyScansPartnerPortal {...HowManyScansProps} />}
    </>
  )
}

export default ETSHowManyScans
