import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import {
  Alert as AlertComponent,
  DataTable,
  DataTableColumn,
  Button,
  Typography,
  Grid
} from '@barracuda-internal/bds-core'
import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'

import { Alert } from 'global/types/api/ato'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import Pager from 'global/components/lib/grid/pager/Pager'
import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'
import EmailCell from 'global/components/lib/grid/cell/customCells/EmailCell'
import { useErrorFormatMessage, useFormatMessage } from 'global/lib/localization'
import EmailDetailsDialog from 'global/components/lib/dialogs/emailDetailsDialog/EmailDetailsDialog'

import styles, { CELL_HEIGHT } from 'sen/components/lib/dialogs/alertDialog/tabs/alertTabStyles'
import useAlertThreatsLogic, {
  ModifiedAlertThreat
} from 'sen/components/lib/dialogs/alertDialog/tabs/useAlertThreatsLogic'

export interface AlertThreatsProps {
  alert: Alert
}

const BASE_I18N_TABLE_KEY = 'sen.app.data_tables.alert_threats'

export const AlertThreats: React.FC<AlertThreatsProps> = ({ alert }) => {
  const formatMessageTable = useFormatMessage(BASE_I18N_TABLE_KEY)
  const formatMessageError = useErrorFormatMessage()
  const classes = styles()
  const [inProgress, tableConfig, useEmailDetailAlertThreatInterface, alertDetailError] = useAlertThreatsLogic(alert)
  const [emailDetailDialogConfig, emailDetailDialogActions] = useEmailDetailAlertThreatInterface

  return useMemo(
    () => (
      <Grid
        className={classes[tableConfig.isFlexibleTable ? 'flexibleTableArea' : 'tableArea']}
        data-table="alert-threats"
        item
        xs={12}
      >
        {alertDetailError && (
          <AlertComponent variant="filled" severity="error" data-testid="alert-threats-alert">
            {formatMessageError(alertDetailError)}
          </AlertComponent>
        )}

        {inProgress && <LinearProgress className={classes.indicator} />}

        <DataTable
          className={classes[tableConfig.isFlexibleTable ? 'flexibleDataTable' : 'dataTable']}
          data={tableConfig.tableData}
          {...tableConfig.pageConfig}
          pager={Pager}
        >
          <DataTableNoRecords data-field="empty-table">
            <Typography>{!tableConfig.tableData.total && formatMessageTable('empty_table')}</Typography>
          </DataTableNoRecords>

          <DataTableColumn
            width={110}
            field={tableConfig.columns.DATE}
            {...tableConfig.columnsConfig[tableConfig.columns.DATE]}
            title={formatMessageTable(tableConfig.columns.DATE)}
            cell={({ dataItem }: { dataItem: ModifiedAlertThreat }) => (
              <Cell className={classes.cell} data-field="table-column-date">
                <CellText className={classes.normalCell} data-field="table-column-date-text">
                  {dataItem.formattedDate}
                </CellText>
              </Cell>
            )}
          />
          <DataTableColumn
            field={tableConfig.columns.RECIPIENT}
            {...tableConfig.columnsConfig[tableConfig.columns.RECIPIENT]}
            title={formatMessageTable(tableConfig.columns.RECIPIENT)}
            cell={({ dataItem }: { dataItem: ModifiedAlertThreat }) => (
              <EmailCell
                CELL_HEIGHT={CELL_HEIGHT}
                name={`${dataItem.recipient.displayName} ${
                  dataItem.recipientCount > 1
                    ? formatMessageTable('additional_recipients', { count: dataItem.recipientCount })
                    : ''
                }`}
                email={dataItem.recipient.email}
              />
            )}
          />
          <DataTableColumn
            field={tableConfig.columns.SUBJECT}
            {...tableConfig.columnsConfig[tableConfig.columns.SUBJECT]}
            title={formatMessageTable(tableConfig.columns.SUBJECT)}
            cell={({ dataItem }: { dataItem: ModifiedAlertThreat }) => (
              <Cell className={classes.cell} data-field="table-column-subject">
                <CellText ellipsis className={classes.normalCell} data-field="table-column-subject-text">
                  {dataItem.subject}
                </CellText>
              </Cell>
            )}
          />
          <DataTableColumn
            width={130}
            headerClassName={classes.centerAlignedText}
            field={tableConfig.columns.VIEW_MESSAGE}
            {...tableConfig.columnsConfig[tableConfig.columns.VIEW_MESSAGE]}
            title=" "
            cell={({ dataItem }: { dataItem: ModifiedAlertThreat }) => (
              <Cell className={classes.cell} data-field="table-column-view-message">
                <Grid container direction="row" justifyContent="center">
                  <Button
                    color="primary"
                    variant="text"
                    disabled={inProgress}
                    onClick={() => {
                      tableConfig.onOpenMessage(dataItem)
                    }}
                  >
                    {formatMessageTable('view_message')}
                  </Button>
                </Grid>
              </Cell>
            )}
          />
        </DataTable>

        {emailDetailDialogActions.open && (
          <EmailDetailsDialog data={emailDetailDialogConfig} buttonText={formatMessageTable('close')} />
        )}
      </Grid>
    ),
    [
      classes,
      tableConfig,
      alertDetailError,
      formatMessageError,
      inProgress,
      formatMessageTable,
      emailDetailDialogActions.open,
      emailDetailDialogConfig
    ]
  )
}

AlertThreats.propTypes = {
  alert: PropTypes.any.isRequired
}

export default AlertThreats
