import { useEffect, useMemo } from 'react'

import useAccessTokenLib from 'global/lib/accessToken/useAccessToken'
import useProductLib from 'global/lib/product/useProduct'
import { getScanStatus, reset as resetScan } from 'global/redux/features/scan/scanSlice'
import { SettingsValue } from 'global/types/api/accessTokenType'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'

export type UserEmail = string

export interface UseDFPContentConditionParams {
  location: Location
}

export enum UIState {
  'missedEntitlement',
  'renderContent',
  'scanInProgress'
}

const SCAN_STATUS_CHECK_INTERVAL = 10000

let interval: ReturnType<typeof setInterval>

export default function useDFPContentCondition(): [UIState, UserEmail] {
  const [accessTokenLib] = useAccessTokenLib()
  const [productLib] = useProductLib()
  const dispatch = useAppDispatch()
  const {
    accessTokenId,
    accessTokenSettings,
    isAccessTokenSet,
    isBootstrapInProgress,
    isScanStatusLoaded,
    scanStatusAccessTokenId,
    userEmail
  } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id,
    accessTokenSettings: _stores.accessToken.accessToken?.settings,
    isAccessTokenSet: _stores.accessToken.isAccessTokenSet,
    isBootstrapInProgress: !!_stores.scan.scanResults?.bootstrapInProgress,
    isScanStatusLoaded: !!_stores.scan.stats?.id,
    scanStatusAccessTokenId: _stores.scan.scanResults?.accessTokenId,
    userEmail: _stores.user.data?.email
  }))

  const hasDFPEntitlement = useMemo(() => {
    return accessTokenLib.hasDFPEntitlement(accessTokenId)
  }, [accessTokenId, accessTokenLib])

  const hasSentinelProduct = useMemo(() => {
    return productLib.hasSentinelProduct(accessTokenId)
  }, [accessTokenId, productLib])

  // init
  useEffect(() => {
    if (isAccessTokenSet && accessTokenId !== scanStatusAccessTokenId && hasSentinelProduct) {
      dispatch(resetScan())
      dispatch(getScanStatus())
    }

    // unmount
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAccessTokenSet])

  useEffect(() => {
    if (isScanStatusLoaded && isBootstrapInProgress && !interval) {
      interval = setInterval(() => {
        dispatch(getScanStatus())
      }, SCAN_STATUS_CHECK_INTERVAL)
    } else if (!isBootstrapInProgress && interval) {
      clearInterval(interval)
    }
  }, [dispatch, isScanStatusLoaded, isBootstrapInProgress])

  const uiState: UIState = useMemo(() => {
    switch (true) {
      case !hasDFPEntitlement:
        return UIState.missedEntitlement
      // Check that the scan is loaded and that either the bootstrap is in progress OR the sentinel onboarding process
      // is in queue (i.e. waiting for IR onboarding to complete before starting the scan)
      case isScanStatusLoaded &&
        (isBootstrapInProgress || accessTokenSettings?.SentinelOnboardingInQueue === SettingsValue.on):
        return UIState.scanInProgress
      default:
        return UIState.renderContent
    }
  }, [accessTokenSettings, hasDFPEntitlement, isBootstrapInProgress, isScanStatusLoaded])

  return useMemo(() => {
    return [uiState, userEmail || '']
  }, [uiState, userEmail])
}
