import deepmerge from 'deepmerge'

import { makeStyles } from '@material-ui/core/styles'
import { makeGridStyles } from 'global/components/lib/grid/gridStyles'

export default makeStyles(theme => ({
  bold: {
    fontWeight: theme.font.weight.bold
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  dateColumn: {
    width: 400
  },
  link: {
    color: theme.colors.barracudaBlue,
    cursor: 'pointer'
  },
  wrapper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 2,
    margin: theme.spacing(2, 2, 3, 2),
    overflow: 'hidden',
    position: 'relative'
  }
}))

export const gridStyle = {
  gridLayout: {
    '& .k-link': {
      whiteSpace: 'normal'
    },
    '& .k-grid-table': {
      wordBreak: 'break-word'
    },
    '& .k-grid-header col:nth-of-type(1)': {
      width: '8% !important'
    },
    '& .k-grid-table col:nth-of-type(1)': {
      width: '8% !important'
    },
    '& .k-grid-header col:nth-of-type(2)': {
      width: '5% !important'
    },
    '& .k-grid-table col:nth-of-type(2)': {
      width: '5% !important'
    },
    '& .k-grid-header col:nth-of-type(3)': {
      width: '15% !important'
    },
    '& .k-grid-table col:nth-of-type(3)': {
      width: '15% !important'
    },
    '& .k-grid-header col:nth-of-type(4)': {
      width: '15% !important'
    },
    '& .k-grid-table col:nth-of-type(4)': {
      width: '15% !important'
    },
    // Disable hover over background color
    '& .k-grid-table tr:hover': {
      background: 'transparent'
    },
    '& .k-grid-table tr.k-alt:hover': {
      background: 'transparent'
    },
    '& .k-grid-header tr:hover': {
      background: 'transparent'
    },
    '& .k-grid-header tr.k-alt:hover': {
      background: 'transparent'
    }
  }
}

export const runDetailsGridStyles = makeStyles(theme => ({
  ...deepmerge(makeGridStyles(theme), gridStyle)
}))
