import { config } from 'global/lib/config'

import GLOBAL_API_ROUTES from 'global/lib/api/api_routes'

export interface ApiRoutes {
  [index: string]: ApiRoute
}

export interface ApiConfigRoutes {
  [index: string]:
    | ApiConfigRoute
    | {
        [index: string]: ApiConfigRoute
      }
}

export interface FlattenedRoutes {
  [index: string]: ApiConfigRoute
}

export interface ApiConfigRoute {
  method: string
  path: string
  metadata?: string
}

export interface ApiRoute extends Omit<ApiConfigRoute, 'path'> {
  path: (UrlParams: UrlParams) => string
}

interface UrlParams {
  [key: string]: string
}

export const globalApiRoutes: ApiRoutes = initApiRoutes({ API_ROUTES: GLOBAL_API_ROUTES })

export default function initApiRoutes({ API_ROUTES }: { API_ROUTES: ApiConfigRoutes }): ApiRoutes {
  function flattenRoutes(routes: ApiConfigRoutes, collectedAll = {}): FlattenedRoutes {
    return Object.entries(routes).reduce((all: FlattenedRoutes, [key, route]) => {
      if (!route.path) {
        return flattenRoutes(route as ApiConfigRoutes, all)
      }

      return {
        ...all,
        [key]: route
      }
    }, collectedAll)
  }

  const flattenedRoutes: FlattenedRoutes = flattenRoutes(API_ROUTES)

  return Object.keys(flattenedRoutes).reduce((all, apiRoute) => {
    const generatePath = (urlParams: UrlParams = {}) => {
      const path = Object.keys(urlParams).reduce((updatedPath, urlParam) => {
        return updatedPath.replace(`:${urlParam}`, urlParams[urlParam])
      }, flattenedRoutes[apiRoute].path)

      return `${config.urlPathBase}/api${path}`
    }

    return {
      ...all,
      [apiRoute]: {
        ...flattenedRoutes[apiRoute],
        path: generatePath,
        url: flattenedRoutes[apiRoute].path
      }
    }
  }, {})
}
