import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { ApiRejectResponse, validateApiError } from 'global/lib/api/restClient'
import { config } from 'global/lib/config'

import apiRoutes from 'fir/lib/api/apiRoutes'
import { updateInsightsStateFailedId } from 'fir/redux/features/insights/insightsSlice'
import { DeleteDismissThreatCard, PotentialThreat } from 'fir/redux/types/Insights'
import { RootState } from 'fir/redux/toolkit/store'

interface GetThreatCards {
  accessTokenId: string
}
export const deletePostDeliveryCard = createAsyncThunk<boolean, DeleteDismissThreatCard, ApiRejectResponse>(
  'INSIGHTS/deletePostDeliveryCard',
  async function doDeletePostDeliveryCard(payload, { getState, dispatch, rejectWithValue }) {
    const { accessTokenId, insightId } = payload
    const state = getState() as RootState

    try {
      // show demo error if using demo token
      if (state.user.isFirDemoUser) {
        dispatch(updateInsightsStateFailedId(insightId))
        return rejectWithValue(validateApiError({ data: { error: config.API_ERROR.DEMO_ERROR.FORENSICS } }))
      }
      await restClient(apiRoutes.DELETE_POST_DELIVERY, {
        data: { accessTokenId, insightId }
      })
      dispatch(getPostDelivery({ accessTokenId }))

      return true
    } catch (e) {
      dispatch(updateInsightsStateFailedId(insightId))
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const deleteRelatedCard = createAsyncThunk<boolean, DeleteDismissThreatCard, ApiRejectResponse>(
  'INSIGHTS/deleteRelatedCard',
  async function doDeleteRelatedCard(payload, { getState, dispatch, rejectWithValue }) {
    const { accessTokenId, insightId } = payload
    const state = getState() as RootState

    try {
      // show demo error if using demo token
      if (state.user.isFirDemoUser) {
        dispatch(updateInsightsStateFailedId(insightId))
        return rejectWithValue(validateApiError({ data: { error: config.API_ERROR.DEMO_ERROR.FORENSICS } }))
      }
      await restClient(apiRoutes.DELETE_RELATED, {
        data: { accessTokenId, insightId }
      })
      dispatch(getRelated({ accessTokenId }))

      return true
    } catch (e) {
      dispatch(updateInsightsStateFailedId(insightId))
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const dismissPostDeliveryCard = createAsyncThunk<boolean, DeleteDismissThreatCard, ApiRejectResponse>(
  'INSIGHTS/dismissPostDeliveryCard',
  async function doDismissPostDeliveryCard(payload, { getState, dispatch, rejectWithValue }) {
    const { accessTokenId, insightId } = payload
    const state = getState() as RootState

    try {
      // show demo error if using demo token
      if (state.user.isFirDemoUser) {
        dispatch(updateInsightsStateFailedId(insightId))
        return rejectWithValue(validateApiError({ data: { error: config.API_ERROR.DEMO_ERROR.FORENSICS } }))
      }
      await restClient(apiRoutes.DISMISS_POST_DELIVERY, {
        data: { accessTokenId, insightId }
      })
      dispatch(getPostDelivery({ accessTokenId }))

      return true
    } catch (e) {
      dispatch(updateInsightsStateFailedId(insightId))
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const dismissRelatedCard = createAsyncThunk<boolean, DeleteDismissThreatCard, ApiRejectResponse>(
  'INSIGHTS/dismissRelatedCard',
  async function doDismissRelatedCard(payload, { getState, dispatch, rejectWithValue }) {
    const { accessTokenId, insightId } = payload
    const state = getState() as RootState

    try {
      // show demo error if using demo token
      if (state.user.isFirDemoUser) {
        dispatch(updateInsightsStateFailedId(insightId))
        return rejectWithValue(validateApiError({ data: { error: config.API_ERROR.DEMO_ERROR.FORENSICS } }))
      }
      await restClient(apiRoutes.DISMISS_RELATED, {
        data: { accessTokenId, insightId }
      })
      dispatch(getRelated({ accessTokenId }))

      return true
    } catch (e) {
      dispatch(updateInsightsStateFailedId(insightId))
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getPostDelivery = createAsyncThunk<PotentialThreat[], GetThreatCards, ApiRejectResponse>(
  'INSIGHTS/getPostDeliveryCard',
  async function doGetPostDeliveryCard(payload, { rejectWithValue }) {
    const { accessTokenId } = payload

    try {
      const resp = await restClient(apiRoutes.POST_DELIVERY, {
        data: { accessTokenId }
      })
      return resp.data.insights
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getRelated = createAsyncThunk<PotentialThreat[], GetThreatCards, ApiRejectResponse>(
  'INSIGHTS/getRelatedCard',
  async function doGetRelatedCard(payload, { rejectWithValue }) {
    const { accessTokenId } = payload

    try {
      const resp = await restClient(apiRoutes.RELATED, {
        data: { accessTokenId }
      })
      return resp.data.insights
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)
