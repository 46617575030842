import { resetLoggedOut } from 'global/redux/features/app/appSlice'
import { reduxStore } from 'global/lib/reduxStore'
import { setCurrentAccessToken } from 'global/redux/features/accessToken/accessTokenSlice'
import { User } from 'global/types/api/userType'
import accessTokenLib from 'global/lib/accessToken/accessToken'
import { config } from 'global/lib/config'

import routesConfig from 'fir/lib/routes/routesConfig'
import { setUser } from 'fir/redux/features/user/userSlice'

export interface GotoReportPageParams {
  user?: User
  scan?: any
  accessTokenId?: string | null
}

export default function gotoReportPage({ user, accessTokenId }: GotoReportPageParams) {
  reduxStore.dispatch(resetLoggedOut())

  // store optional values
  if (user) reduxStore.dispatch(setUser({ user }))

  const validatedAccessTokenId =
    accessTokenId ||
    accessTokenLib.getDefaultFirAccessTokenId() ||
    accessTokenLib.getDefaultBccAccountAccessTokenId(user?.defaultAccountBccId, config.PRODUCTS.FORENSICS) ||
    accessTokenLib.getAllAccessTokens()[0]?.id

  if (!validatedAccessTokenId) {
    routesConfig.SIGNIN_CONNECT.goto()
    return
  }

  reduxStore.dispatch(setCurrentAccessToken(validatedAccessTokenId))
  routesConfig.REMEDIATION.goto({ accessToken: validatedAccessTokenId })
}
