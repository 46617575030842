import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { DataTable, DataTableColumn, Button, Typography, Grid } from '@barracuda-internal/bds-core'

import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'

import { formatDate } from 'global/lib/datetime'
import { config } from 'global/lib/config'
import { COLORS } from 'global/configs/theme/defaults'
import TextLabel, { LabelColors } from 'global/components/lib/textLabel/TextLabel'
import { Alert } from 'global/types/api/ato'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import Pager from 'global/components/lib/grid/pager/Pager'
import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'
import { useFormatMessage } from 'global/lib/localization'

import styles from 'sen/components/lib/dialogs/alertDialog/tabs/alertTabStyles'
import useAlertSigninsLogic, {
  ModifiedAlertSignin,
  AllSignInsDisableReasons
} from 'sen/components/lib/dialogs/alertDialog/tabs/useAlertSigninsLogic'
import SigninsDialog, { DialogType } from 'sen/components/lib/dialogs/signins/SigninsDialog'

export interface AlertSigninsProps {
  alert: Alert
}

const BASE_I18N_TABLE_KEY = 'sen.app.data_tables.alert_signins'

export const AlertSignins: React.FC<AlertSigninsProps> = ({ alert }) => {
  const formatMessageTable = useFormatMessage(BASE_I18N_TABLE_KEY)
  const classes = styles()
  const [
    inProgress,
    tableConfig,
    switchAlertDrilldownDisabledReason,
    onViewRelatedSignins,
    userRelatedSigninsDialogConfig
  ] = useAlertSigninsLogic(alert)

  return useMemo(
    () => (
      <Grid container direction="column">
        <Grid
          className={classes.infoContainer}
          container
          direction="column"
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          <Grid item xs>
            {switchAlertDrilldownDisabledReason === AllSignInsDisableReasons.Release && (
              <TextLabel color={LabelColors.dark} bgColor={COLORS.PRODUCT_UI_ELEMENT}>
                {formatMessageTable('data_collected_since', {
                  date: formatDate(config.ALL_SIGNINS_COLLECTION_START_DATE)
                })}
              </TextLabel>
            )}

            {switchAlertDrilldownDisabledReason === AllSignInsDisableReasons.ThirtyDays && (
              <TextLabel color={LabelColors.dark} bgColor={COLORS.PRODUCT_UI_ELEMENT}>
                {formatMessageTable('older_than_30_days')}
              </TextLabel>
            )}
          </Grid>
          <Grid item xs>
            <Button
              color="primary"
              size="large"
              disabled={inProgress || switchAlertDrilldownDisabledReason !== AllSignInsDisableReasons.None}
              onClick={onViewRelatedSignins}
              variant="text"
            >
              &nbsp;&nbsp;{formatMessageTable('view_related_signins')}&nbsp;&nbsp;
            </Button>
          </Grid>
        </Grid>

        <Grid
          className={classes[tableConfig.isFlexibleTable ? 'flexibleTableArea' : 'tableArea']}
          data-table="alert-signins"
          item
          xs={12}
        >
          {inProgress && <LinearProgress className={classes.indicator} />}

          <DataTable
            className={classes[tableConfig.isFlexibleTable ? 'flexibleDataTable' : 'dataTable']}
            data={tableConfig.tableData}
            {...tableConfig.pageConfig}
            pager={Pager}
          >
            <DataTableNoRecords data-field="empty-table">
              <Typography>{!tableConfig.tableData.total && formatMessageTable('empty_table')}</Typography>
            </DataTableNoRecords>

            <DataTableColumn
              width={110}
              field={tableConfig.columns.DATE}
              {...tableConfig.columnsConfig[tableConfig.columns.DATE]}
              title={formatMessageTable(tableConfig.columns.DATE)}
              cell={({ dataItem }: { dataItem: ModifiedAlertSignin }) => (
                <Cell className={classes.cell} data-field="table-column-date">
                  <CellText className={classes.normalCell} data-field="table-column-date-text">
                    {dataItem.formattedDate}
                  </CellText>
                </Cell>
              )}
            />
            <DataTableColumn
              field={tableConfig.columns.IP}
              {...tableConfig.columnsConfig[tableConfig.columns.IP]}
              title={formatMessageTable(tableConfig.columns.IP)}
              cell={({ dataItem }: { dataItem: ModifiedAlertSignin }) => (
                <Cell className={classes.cell} data-field="table-column-ip">
                  <CellText className={classes.normalCell} data-field="table-column-ip-text">
                    {dataItem.ip}
                  </CellText>
                </Cell>
              )}
            />
            <DataTableColumn
              field={tableConfig.columns.USER_AGENT}
              {...tableConfig.columnsConfig[tableConfig.columns.USER_AGENT]}
              title={formatMessageTable(tableConfig.columns.USER_AGENT)}
              cell={({ dataItem }: { dataItem: ModifiedAlertSignin }) => (
                <Cell className={classes.cell} data-field="table-column-user-agent">
                  <CellText className={classes.normalCell} data-field="table-column-user-agent-text">
                    {dataItem.userAgent}
                  </CellText>
                </Cell>
              )}
            />
            <DataTableColumn
              field={tableConfig.columns.LOCATION}
              {...tableConfig.columnsConfig[tableConfig.columns.LOCATION]}
              title={formatMessageTable(tableConfig.columns.LOCATION)}
              cell={({ dataItem }: { dataItem: ModifiedAlertSignin }) => (
                <Cell className={classes.cell} data-field="table-column-location">
                  <CellText className={classes.normalCell} data-field="table-column-location-text">
                    {dataItem.countryName}
                  </CellText>
                </Cell>
              )}
            />
            <DataTableColumn
              field={tableConfig.columns.ISSUE}
              {...tableConfig.columnsConfig[tableConfig.columns.ISSUE]}
              title={formatMessageTable(tableConfig.columns.ISSUE)}
              cell={({ dataItem }: { dataItem: ModifiedAlertSignin }) => (
                <Cell className={classes.cell} data-field="table-column-issue">
                  <CellText className={classes.normalCell} data-field="table-column-issue-text">
                    {formatMessageTable(`detection_types.${dataItem.detectionType.toLowerCase()}`)}
                  </CellText>
                </Cell>
              )}
            />
          </DataTable>
        </Grid>

        {userRelatedSigninsDialogConfig.open && (
          <SigninsDialog dialogType={DialogType.Alert} alert={alert} onBack={userRelatedSigninsDialogConfig.toggle} />
        )}
      </Grid>
    ),
    [
      alert,
      classes,
      formatMessageTable,
      inProgress,
      tableConfig,
      switchAlertDrilldownDisabledReason,
      onViewRelatedSignins,
      userRelatedSigninsDialogConfig
    ]
  )
}

AlertSignins.propTypes = {
  alert: PropTypes.any.isRequired
}

export default AlertSignins
