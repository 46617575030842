import React, { useCallback, useMemo, useReducer } from 'react'
import _ from 'lodash'
import {
  Checkbox,
  CheckboxGroup,
  CheckboxLabel,
  FormControl,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField
} from '@barracuda-internal/bds-core'
import { Filter } from '@barracuda-internal/bds-core/dist/Icons/Core'
import { FilterOperation } from '@barracuda/reporting'

import { Filter as FilterType } from 'global/types/api/unifiedReporting'

interface ColumnFilterValue {
  label: string
  value: string | null
}

interface ConditionFilterProps {
  columnFilter?: FilterType
  columnId: string
  columnValues: ColumnFilterValue[]
  filterTitle: string
  selectAllLabel: string
  handleRemoveColumnFilter: (columnId: string) => void
  setColumnFilters: (filter: FilterType) => void
}

type State = {
  checkBoxValues: ColumnFilterValue[]
  checkedFilters: (string | null)[]
  filterInputValue: string
}

const InputCheckboxFilter: React.FC<ConditionFilterProps> = ({
  columnFilter,
  columnId,
  columnValues,
  filterTitle,
  handleRemoveColumnFilter,
  selectAllLabel,
  setColumnFilters
}) => {
  const [{ checkBoxValues, checkedFilters, filterInputValue }, setState] = useReducer(
    (_state: State, newState: Partial<State>) => ({ ..._state, ...newState }),
    {
      checkBoxValues: columnValues,
      checkedFilters: columnFilter?.value || [],
      filterInputValue: ''
    }
  )

  const handleFilterInputChange = useCallback(
    (e: any) => {
      const { value } = e.target

      setState({
        filterInputValue: value,
        checkBoxValues: _.filter(columnValues, columnValue => {
          return columnValue.label.toLowerCase().includes(value.toLowerCase())
        })
      })
    },
    [columnValues]
  )

  const handleCheckboxSelectAllChange = useCallback(
    (e: any) => {
      const { checked } = e.target

      if (checked) {
        const updatedCheckedFilters = _.map(checkBoxValues, checkBoxValue => checkBoxValue.value)
        setState({ checkedFilters: updatedCheckedFilters })
        setColumnFilters({
          fieldName: columnId,
          operation: FilterOperation.IN,
          value: updatedCheckedFilters
        })
      } else {
        setState({ checkedFilters: [] })
        handleRemoveColumnFilter(columnId)
      }
    },
    [checkBoxValues, columnId, handleRemoveColumnFilter, setColumnFilters]
  )

  const handleCheckboxChange = useCallback(
    (e: any) => {
      const { checked, value } = e.target
      let updatedCheckedFilters: any[] = []
      // Checkbox value of null is considered as empty string
      const nullableValue = value === '' ? null : value

      if (checked) {
        if (!_.includes(checkedFilters, nullableValue)) {
          updatedCheckedFilters = [...checkedFilters, nullableValue]
        }
      } else {
        updatedCheckedFilters = _.filter(checkedFilters, filter => filter !== nullableValue)
      }

      setState({ checkedFilters: updatedCheckedFilters })

      if (updatedCheckedFilters.length === 0) {
        handleRemoveColumnFilter(columnId)
      } else {
        setColumnFilters({
          fieldName: columnId,
          operation: FilterOperation.IN,
          value: updatedCheckedFilters
        })
      }
    },
    [checkedFilters, columnId, handleRemoveColumnFilter, setColumnFilters]
  )

  return useMemo(
    () => (
      <>
        <MenuItem key="title">
          <ListItemIcon>
            <Filter fontSize="small" />
          </ListItemIcon>
          <ListItemText>{filterTitle}</ListItemText>
        </MenuItem>

        <Grid container justifyContent="center" style={{ paddingBottom: '15px', marginBottom: '5px' }}>
          <FormControl style={{ width: '85%' }}>
            <TextField label="Value" onChange={handleFilterInputChange} value={filterInputValue} />
            <CheckboxGroup>
              <CheckboxLabel
                control={
                  <Checkbox
                    checked={checkedFilters.length === columnValues.length}
                    indeterminate={checkedFilters.length > 0 && checkedFilters.length < columnValues.length}
                    onChange={handleCheckboxSelectAllChange}
                    name="select all"
                    color={checkedFilters.length === columnValues.length ? 'primary' : 'secondary'}
                    size="small"
                  />
                }
                label={selectAllLabel}
              />
              {_.map(checkBoxValues, checkBoxVal => {
                return (
                  <CheckboxLabel
                    key={checkBoxVal.value}
                    control={
                      <Checkbox
                        size="small"
                        color="primary"
                        checked={_.includes(checkedFilters, checkBoxVal.value)}
                        onChange={handleCheckboxChange}
                        value={checkBoxVal.value}
                      />
                    }
                    label={checkBoxVal.label}
                  />
                )
              })}
            </CheckboxGroup>
          </FormControl>
        </Grid>
      </>
    ),
    [
      filterTitle,
      handleFilterInputChange,
      filterInputValue,
      checkedFilters,
      columnValues.length,
      handleCheckboxSelectAllChange,
      selectAllLabel,
      checkBoxValues,
      handleCheckboxChange
    ]
  )
}

export default InputCheckboxFilter
