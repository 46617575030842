import React, { useMemo } from 'react'
import { Button } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'global/lib/localization'

import Layout from 'sen/components/lib/layout/Layout'
import sentintelContentLoader from 'sen/components/lib/useSentinelContentCondition/SentinelContentLoader'
import NewIncidentDialog from 'sen/components/lib/dialogs/newIncidentDialog/NewIncidentDialog'
import NewIncidentDialogWizard from 'sen/components/lib/dialogs/newIncidentDialog/NewIncidentDialogWizard'
import useNewIncidentDialogLogic from 'sen/components/lib/dialogs/newIncidentDialog/useNewIncidentDialogLogic'
import ContentLayout from 'sen/components/lib/layout/ContentLayout'
import AtoOverview from 'sen/components/pages/ato/overview/atoOverview'
import AtoContent from 'sen/components/pages/ato/content/AccountTakeoverContent'

const BASE_I18N_KEY = 'sen.app.account_takeover'

const AccountTakeover = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [newIncidentDialogConfig, newIncidentDialogWizardConfig] = useNewIncidentDialogLogic()

  return useMemo(
    () => (
      <Layout
        pageTitle={formatMessage('page_title')}
        rightContent={
          <Button
            style={{ margin: '0 8px', height: '36px', padding: '16px' }}
            color="primary"
            variant="contained"
            size="large"
            onClick={() => {
              newIncidentDialogConfig.onOpen()
            }}
            data-field="incident-dialog-button"
            data-action="incident-dialog-button"
          >
            {formatMessage('new_incident_button')}
          </Button>
        }
      >
        {newIncidentDialogConfig.open && (
          <NewIncidentDialog
            cleanupMessages={newIncidentDialogWizardConfig.onOpen}
            onClose={newIncidentDialogConfig.onClose}
          />
        )}
        {newIncidentDialogWizardConfig.open && (
          <NewIncidentDialogWizard onClose={newIncidentDialogWizardConfig.onClose} />
        )}
        <ContentLayout data-field="content-layout">
          <AtoOverview />
          <AtoContent />
        </ContentLayout>
      </Layout>
    ),
    [formatMessage, newIncidentDialogConfig, newIncidentDialogWizardConfig]
  )
}

export default sentintelContentLoader({
  default: () => <AccountTakeover />
})
