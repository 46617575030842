import { CSSProperties } from '@material-ui/core/styles/withStyles'

export default {
  // fixes label alignment issue when using FormControl
  formControlLabel: {
    transform: 'translate(0px, 0px) scale(1) !important'
  } as CSSProperties,

  // fixes label alignment issue when using SelectWrapper
  wrappedLabel: {
    position: 'relative',
    transform: 'translate(0px, 0px) scale(1) !important'
  } as CSSProperties
}
