import { useMemo, useEffect } from 'react'

import { isPending, getErrorMessage } from 'global/redux/toolkit/api'
import { Incident, RemediationStatuses } from 'global/types/api/remediation'
import { formatDate, formatDateWithTime } from 'global/lib/datetime'
import useTimePeriod from 'global/lib/useTimePeriod'

import routesConfig from 'sen/lib/routes/routesConfig'
import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import { getIncidentDetails, resetIncidentDetails } from 'sen/redux/features/remediation/remediationSlice'

export interface ModifiedIncident extends Incident {
  formattedDate: string
  formattedDateWithTime: string
  timePeriodName: string | undefined
}

export type InProgress = boolean
export type Error = string | undefined
export interface IncidentLogic {
  gotoIncidents: () => void
  searchInProgress: boolean
}

export default function useIncidentLogic(): [InProgress, ModifiedIncident | undefined, IncidentLogic, Error] {
  const dispatch = useAppDispatch()
  const { urlParams, incident, inProgress, error } = useAppSelector(_stores => ({
    urlParams: _stores.app.activePath?.params || {},
    incident: _stores.remediation.incident,
    inProgress: isPending(_stores.remediation.getIncidentDetailsApiStatus),
    error: getErrorMessage(_stores.remediation.getIncidentDetailsApiStatus)
  }))
  const [getTimePeriodByValue] = useTimePeriod()

  // init
  useEffect(() => {
    dispatch(getIncidentDetails(urlParams?.incidentId))

    return () => {
      dispatch(resetIncidentDetails())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const modifiedIncident = useMemo(() => {
    if (!incident) {
      return incident
    }

    return {
      ...incident,
      formattedDate: formatDate(incident.created),
      formattedDateWithTime: formatDateWithTime(incident.created),
      timePeriodName: getTimePeriodByValue(incident.timeframe)?.name
    }
  }, [incident, getTimePeriodByValue])

  const searchInProgress = useMemo(() => {
    return incident?.remediationStatus === RemediationStatuses.inProgress
  }, [incident])

  return useMemo(() => {
    return [
      inProgress,
      modifiedIncident,
      {
        gotoIncidents: () => {
          routesConfig.ACCOUNT_TAKEOVER_INCIDENTS.softGoto({ reportId: urlParams.reportId })
        },
        searchInProgress
      },
      error
    ]
  }, [inProgress, modifiedIncident, error, urlParams.reportId, searchInProgress])
}
