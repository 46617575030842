import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from 'global/configs/theme/defaults'

export default makeStyles(theme => {
  return {
    title: {
      fontSize: 20,
      fontWeight: 500,
      padding: theme.spacing(1)
    },
    contentRow: {
      display: 'flex',
      padding: theme.spacing(1),
      whiteSpace: 'pre-wrap'
    },
    label: {
      color: COLORS.GRAY,
      lineHeight: 'normal'
    },
    value: {
      lineHeight: 'normal'
    },
    valueIcon: {
      lineHeight: 'normal',
      display: 'flex'
    },
    link: {
      color: COLORS.GRAY,
      textDecoration: 'underline',
      cursor: 'pointer'
    },
    actions: {
      paddingTop: 8,
      paddingBottom: 16,
      paddingRight: 16
    },
    saveIcon: {
      width: 18,
      height: 18,
      color: COLORS.GRAY
    },
    inputForm: {
      margin: 0
    },
    warningText: {
      backgroundColor: COLORS.GRAY_25,
      color: COLORS.GRAY,
      padding: theme.spacing(1.2)
    }
  }
})
