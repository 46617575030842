import { useMemo, useState } from 'react'

import { isFailed } from 'global/redux/toolkit/api'
import {
  EmailDetailsDialogProps,
  OnCloseDialogCallback,
  DialogActions,
  Analysis,
  EMPTY_EMAIL_DETAILS
} from 'global/components/lib/dialogs/emailDetailsDialog/emailDetailDialogTypes'
import { StatusIds } from 'global/components/lib/statusTypeLabel/StatusIds.enum'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'
import analyzeAttack, { TAXONOMIES } from 'global/lib/analyzeAttack/analyzeAttack'
import { Alert, AlertThreat } from 'global/types/api/ato'

import { useAppSelector } from 'sen/redux/toolkit/hooks'

export interface UseEmailDetailsAlertThreatInterfaceProps {
  onClose: OnCloseDialogCallback
  alert: Alert
}

export type UseEmailDetailsAlertThreatInterface = [
  EmailDetailsDialogProps,
  DialogActions<(threat: AlertThreat) => void>
]

export default function useEmailDetailsAlertThreatInterface({
  onClose,
  alert
}: UseEmailDetailsAlertThreatInterfaceProps): UseEmailDetailsAlertThreatInterface {
  const { isAlertDetailsFailed, alertDetails } = useAppSelector(_stores => ({
    isAlertDetailsFailed: isFailed(_stores.ato.getAlertDetailsApiStatus),
    alertDetails: _stores.ato.alertDetails
  }))
  const [isDialogOpened, toggleDialog] = useDialogLogic()
  const [selectedThreat, setSelectedThreat] = useState<AlertThreat | undefined>()

  const attackDetails = useMemo(() => {
    if (alertDetails && selectedThreat) {
      return {
        id: 'custom_attack',
        date: selectedThreat.dateSent,
        subject: selectedThreat.subject,
        body: alertDetails.body,
        bodyMimeType: alertDetails.bodyMimeType,
        headers: alertDetails.headers,
        attachments: alertDetails.attachments,
        identity: selectedThreat.recipient,
        sender: {
          email: alert.emailAddress,
          displayName: alert.displayName
        },
        replyTo: [],
        attackTaxonomy: TAXONOMIES.PHISHING,
        attackAttributes: {
          domains: [selectedThreat.threatCtaDomain]
        },
        threatId: selectedThreat.threatId
      }
    }

    return undefined
  }, [alertDetails, selectedThreat, alert])

  const analyzedAttack: Analysis | undefined = useMemo(() => {
    if (attackDetails) {
      return {
        ...analyzeAttack(attackDetails),
        statusId: StatusIds.emptyStatus,
        isStatusEmpty: true,
        isMarkedAsFp: false,
        hasRemediation: false
      }
    }

    return undefined
  }, [attackDetails])

  const dialogActions = useMemo(
    () => ({
      open: isDialogOpened,
      onOpen: (threat: AlertThreat) => {
        toggleDialog()
        setSelectedThreat(threat)
      },
      onClose: () => {
        onClose()

        toggleDialog()
        setSelectedThreat(undefined)
      }
    }),
    [onClose, isDialogOpened, toggleDialog]
  )

  return useMemo(() => {
    return [
      {
        emailDetails: attackDetails
          ? {
              subject: attackDetails.subject,
              from: attackDetails.sender,
              to: attackDetails.identity,
              replyTo: attackDetails.replyTo,
              date: attackDetails.date,
              headers: attackDetails.headers,
              bodyMimeType: attackDetails.bodyMimeType,
              body: attackDetails.body,
              attachments: attackDetails.attachments
            }
          : EMPTY_EMAIL_DETAILS,
        emailDataStatuses: {
          inProgress: false,
          isLoaded: true,
          isFailed: isAlertDetailsFailed
        },
        eventHandlers: {
          onClose: dialogActions.onClose
        },
        analysis: analyzedAttack
      },
      dialogActions
    ]
  }, [dialogActions, attackDetails, analyzedAttack, isAlertDetailsFailed])
}
