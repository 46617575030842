import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Button, Typography, Grid } from '@barracuda-internal/bds-core'

import styles from 'global/components/features/signinSignup/signinSignupStyles'
import { useFormatMessage } from 'global/lib/localization'

const BASE_I18N_KEY = 'app.signin_signup'

export interface AccountSwitcherProps {
  userEmail: string
  onSwitchAccount: () => void
}

const AccountSwitcher: React.FC<AccountSwitcherProps> = ({ userEmail, onSwitchAccount }) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <Grid
        className={classes.accountSwitcher}
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
      >
        <Typography variant="subtitle2" className={classes.whiteText}>
          {userEmail}
        </Typography>
        <Button data-field="switch-account" variant="text" size="small" onClick={onSwitchAccount}>
          <Typography className={`${classes.underline} ${classes.whiteText}`} variant="subtitle2">
            {formatMessage('switch_account')}
          </Typography>
        </Button>
      </Grid>
    ),
    [userEmail, onSwitchAccount, formatMessage, classes]
  )
}

AccountSwitcher.propTypes = {
  userEmail: PropTypes.string.isRequired,
  onSwitchAccount: PropTypes.func.isRequired
}

export default AccountSwitcher
