import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import {
  Typography,
  DataTable,
  DataTableColumn,
  DialogContent,
  Alert,
  Grid,
  Tooltip,
  Box
} from '@barracuda-internal/bds-core'
import { Assignment as AssignmentIcon, Build as BuildIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'

import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import Pager from 'global/components/lib/grid/pager/Pager'
import EmailDetailsDialog from 'global/components/lib/dialogs/emailDetailsDialog/EmailDetailsDialog'
import { IncidentEmailSearch } from 'global/types/api/remediation'
import { useFormatMessage, useErrorFormatMessage } from 'global/lib/localization'

import BaseDialogActions from 'sen/components/lib/dialogs/newIncidentDialog/lib/BaseDialogActions'
import useConfirmIncidentLogic from 'sen/components/lib/dialogs/newIncidentDialog/steps/useConfirmIncidentLogic'
import styles from 'sen/components/lib/dialogs/newIncidentDialog/newIncidentDialogStyles'

const BASE_I18N_KEY = 'sen.app.new_incident_dialog.step_confirm_incident'
const BASE_I18N_TABLE_KEY = 'sen.app.data_tables.confirm_incident'

export interface NewIncidentDialogWizardProps {
  onClose: (activeStep?: number) => void
  activeStep: number
}

export interface ModifiedIncidentEmailSearch extends IncidentEmailSearch {
  formattedDate: string
  recipientCount: number
}

const ConfirmIncident: React.FC<NewIncidentDialogWizardProps> = ({ onClose, activeStep }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatMessageTable = useFormatMessage(BASE_I18N_TABLE_KEY)
  const formatMessageError = useErrorFormatMessage()
  const classes = styles()

  const [
    buttonStatus,
    tableConfig,
    isLoaded,
    formConfig,
    error,
    inProgress,
    emailDetailsIncidentInterface,
    emailDetailsDialogCustomTitleConfig
  ] = useConfirmIncidentLogic()
  const [emailDetailDialogConfig, emailDetailDialogActions] = emailDetailsIncidentInterface

  return useMemo(
    () => (
      <Grid container item direction="column">
        <DialogContent className={classes.dialogContent}>
          {error && (
            <Alert className={classes.error} variant="filled" severity="error">
              {formatMessageError(error)}
            </Alert>
          )}
          <Typography className={classes.dialogText}>
            {formatMessage(`${formConfig.isEmptySubject ? 'title_no_subject' : 'title'}`)}
          </Typography>
          {tableConfig.resultsLimit <= tableConfig.tableData.total && (
            <Typography className={classes.dialogText}>
              {formatMessage('limit_search_results', {
                results: tableConfig.resultsLimit,
                b: (txt: string) => <b key={txt}>{txt}</b>
              })}
            </Typography>
          )}

          {isLoaded && (
            <Grid className={classes.flexibleTableArea} data-table="confirm-incident" item xs={12}>
              {inProgress && <LinearProgress className={classes.indicator} />}
              <DataTable
                className={classes.flexibleDataTable}
                data={tableConfig.tableData}
                {...tableConfig.pageConfig}
                pager={Pager}
              >
                <DataTableColumn
                  width={220}
                  field={tableConfig.columns.SENT}
                  {...tableConfig.columnsConfig[tableConfig.columns.SENT]}
                  title={formatMessageTable(`${tableConfig.columns.SENT}`)}
                  cell={({ dataItem }: { dataItem: ModifiedIncidentEmailSearch }) => (
                    <Cell className={classes.cell} data-field="table-column-sent">
                      <CellText ellipsis className={classes.normalCell} data-field="table-column-sent-text">
                        {dataItem.formattedDate}
                      </CellText>
                    </Cell>
                  )}
                />

                <DataTableColumn
                  field={tableConfig.columns.RECIPIENTS}
                  {...tableConfig.columnsConfig[tableConfig.columns.RECIPIENTS]}
                  title={formatMessageTable(`${tableConfig.columns.RECIPIENTS}`)}
                  cell={({ dataItem }: { dataItem: ModifiedIncidentEmailSearch }) => (
                    <Cell className={classes.cell} data-field="table-column-sent">
                      {dataItem.recipients.slice(0, 1).map((recipient: any) => (
                        <CellText
                          ellipsis
                          className={classes.breakSpacesCell}
                          data-field="table-column-sent-text"
                          key={recipient.email}
                        >
                          {`${recipient.displayName} <${recipient.email}> ${
                            dataItem.recipientCount > 1
                              ? formatMessage('additional_recipients', { count: dataItem.recipientCount - 1 })
                              : ''
                          }`}
                        </CellText>
                      ))}
                    </Cell>
                  )}
                />

                <DataTableColumn
                  field={tableConfig.columns.SUBJECT}
                  {...tableConfig.columnsConfig[tableConfig.columns.SUBJECT]}
                  title={formatMessageTable(`${tableConfig.columns.SUBJECT}`)}
                  cell={({ dataItem }: { dataItem: ModifiedIncidentEmailSearch }) => (
                    <Cell className={classes.flexibleCell} data-field="table-column-subject">
                      <CellText className={classes.normalCell} data-field="table-column-name-text">
                        {dataItem.subject}
                      </CellText>
                    </Cell>
                  )}
                />

                <DataTableColumn
                  width={50}
                  field={tableConfig.columns.DETAILS}
                  {...tableConfig.columnsConfig[tableConfig.columns.DETAILS as any]}
                  title=" "
                  cell={({ dataItem }: { dataItem: ModifiedIncidentEmailSearch }) => (
                    <Cell className={classes.cell} data-field="table-column-details">
                      <CellText>
                        <Tooltip
                          classes={{ tooltip: classes.tooltip }}
                          title={formatMessageTable('more_details')}
                          placement="top-start"
                          data-field="table-column-details-tooltip"
                        >
                          <Box>
                            <AssignmentIcon
                              data-testid={`more-details-${dataItem.emailId}`}
                              onClick={() => {
                                formConfig.onEmailPreview(dataItem)
                              }}
                              className={classes.icon}
                              data-action="email-preview-dialog-open"
                            />
                          </Box>
                        </Tooltip>
                      </CellText>
                    </Cell>
                  )}
                />

                {formConfig.isEmptySubject && (
                  <DataTableColumn
                    width={50}
                    field={tableConfig.columns.DETAILS}
                    {...tableConfig.columnsConfig[tableConfig.columns.DETAILS as any]}
                    title=" "
                    cell={({ dataItem }: { dataItem: ModifiedIncidentEmailSearch }) => (
                      <Cell className={classes.cell} data-field="table-column-details">
                        <CellText>
                          <Tooltip
                            classes={{ tooltip: classes.tooltip }}
                            title={formatMessageTable('remediate')}
                            placement="top-start"
                            data-field="table-column-details-tooltip"
                          >
                            <Box>
                              <BuildIcon
                                data-testid={`remediate-${dataItem.emailId}`}
                                onClick={() => {
                                  formConfig.onSearchWithSubject(dataItem.subject)
                                }}
                                className={classes.icon}
                                data-action="remediate-dialog-open"
                              />
                            </Box>
                          </Tooltip>
                        </CellText>
                      </Cell>
                    )}
                  />
                )}
              </DataTable>
            </Grid>
          )}
          {formConfig.isEmptySubject && (
            <Typography className={classes.dialogText}>
              &nbsp;
              {formatMessage('footer_no_subject', {
                emailAddress: formConfig.senderEmail,
                icon: <BuildIcon key="icon" className={classes.icon} />
              })}
            </Typography>
          )}

          {!formConfig.isEmptySubject && (
            <Typography className={classes.dialogText}>
              &nbsp;
              {formatMessage('footer')}
            </Typography>
          )}
        </DialogContent>

        <BaseDialogActions activeStep={activeStep} buttonStatus={buttonStatus} onClose={onClose} />

        {emailDetailDialogActions.open && (
          <EmailDetailsDialog
            data={emailDetailDialogConfig}
            buttonText={formatMessage('close')}
            titleText={formatMessage('email_details_dialog_title', {
              ...emailDetailsDialogCustomTitleConfig
            })}
          />
        )}
      </Grid>
    ),
    [
      classes,
      formatMessage,
      formatMessageError,
      formatMessageTable,
      error,
      isLoaded,
      tableConfig,
      activeStep,
      buttonStatus,
      onClose,
      formConfig,
      inProgress,
      emailDetailDialogConfig,
      emailDetailDialogActions,
      emailDetailsDialogCustomTitleConfig
    ]
  )
}

ConfirmIncident.propTypes = {
  onClose: PropTypes.func.isRequired,
  activeStep: PropTypes.number.isRequired
}

export default ConfirmIncident
