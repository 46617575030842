import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Grid } from '@barracuda-internal/bds-core'

export interface SigninSignupContentColumnProps {
  className: string
  children: any
}

const SigninSignupContentColumn: React.FC<SigninSignupContentColumnProps> = ({ children, className }) => {
  return useMemo(
    () => (
      <Grid container item xs={12} sm={6} className={className} direction="row">
        {children}
      </Grid>
    ),
    [children, className]
  )
}

SigninSignupContentColumn.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired
}

export default SigninSignupContentColumn
