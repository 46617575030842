import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { EpImpersonationProtection } from '@barracuda-internal/bds-core/dist/Logos/Products'

import Feedback from 'global/components/features/static/feedback/Feedback'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { isFailed, isPending, isSuccess } from 'global/redux/toolkit/api'

import { sendFeedback } from 'sen/redux/features/feedback/feedbackSlice'
import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'

const SentinelFeedback: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const dispatch = useAppDispatch()

  const { feedback, isFeedbackPending, isFeedbackSuccess, userEmail } = useAppSelector(_stores => ({
    feedback: _stores.feedback,
    isFeedbackPending: isPending(_stores.feedback.sendFeedbackApiStatus),
    isFeedbackSuccess: isSuccess(_stores.feedback.sendFeedbackApiStatus),
    isFeedbackFailed: isFailed(_stores.feedback.sendFeedbackApiStatus),
    userEmail: _stores.user.data?.email
  }))

  useEffect(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.SEN_FPFN_FEEDBACK_FORM_PAGEVIEW, {
      url: window.location.href
    })
  }, [])

  useEffect(() => {
    if (feedback.redirect) {
      window.open(feedback.redirect, '_blank')
    }
  }, [feedback.redirect])

  return (
    <Feedback
      error={feedback.error}
      isSending={isFeedbackPending}
      isSent={isFeedbackSuccess}
      onReportClick={() => {
        dispatch(sendFeedback({ publicKey: id || '', userEmail: userEmail || '' }))
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.SEN_FPFN_FEEDBACK_FORM_BUTTON_CLICK, {
          url: window.location.href
        })
      }}
      logo={<EpImpersonationProtection />}
    />
  )
}

export default SentinelFeedback
