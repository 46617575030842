import React, { useCallback, useEffect, useMemo, useReducer } from 'react'
import { DateTime } from 'luxon'
import { FilterOperation } from '@barracuda/reporting'
import {
  DatePicker,
  FormControl,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectLabel,
  SelectWrapper
} from '@barracuda-internal/bds-core'
import { Filter } from '@barracuda-internal/bds-core/dist/Icons/Core'

import { Filter as FilterType } from 'global/types/api/unifiedReporting'

import { Condition } from './ConditionInputFilter'

interface DateFilterProps {
  columnFilter?: FilterType
  columnId: string
  conditions: Condition[]
  setColumnFilter: (filterValue: FilterType) => void
}

const DateFilter: React.FC<DateFilterProps> = ({ columnFilter, columnId, conditions, setColumnFilter }) => {
  const [{ conditionValue, minDate, maxDate, isRendered }, setState] = useReducer(
    (_state: any, newState: any) => ({ ..._state, ...newState }),
    {
      conditionValue: columnFilter?.operation || conditions[0].value,
      minDate: DateTime.local()
        .minus({ days: 7 })
        .toJSDate(),
      maxDate: DateTime.local().toJSDate(),
      isRendered: false
    }
  )

  const filterValue = useMemo(() => {
    switch (conditionValue) {
      case FilterOperation.GREATER:
        return DateTime.fromJSDate(minDate)
          .endOf('day')
          .toISO()
      case FilterOperation.BETWEEN:
        return [
          DateTime.fromJSDate(minDate)
            .startOf('day')
            .toISO(),
          DateTime.fromJSDate(maxDate)
            .endOf('day')
            .toISO()
        ]
      case FilterOperation.LESS:
        return DateTime.fromJSDate(maxDate)
          .startOf('day')
          .toISO()
      case FilterOperation.DATE_EQUALS:
        return DateTime.fromJSDate(maxDate).toISODate()
      default:
        return []
    }
  }, [conditionValue, maxDate, minDate])

  const handleConditionChange = useCallback((e: any) => {
    setState({ conditionValue: e.target.value })
  }, [])

  const handleSetMinDate = useCallback((e: any) => {
    setState({ minDate: e })
  }, [])

  const handleSetMaxDate = useCallback((e: any) => {
    setState({ maxDate: e })
  }, [])

  // TODO: we could possibly refactor this to fire on unmount
  useEffect(() => {
    setState({ isRendered: true })
    if (isRendered) {
      setColumnFilter({ fieldName: columnId, value: filterValue, operation: conditionValue })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnId, conditionValue, filterValue])

  return useMemo(
    () => (
      <>
        <MenuItem key="title">
          <ListItemIcon>
            <Filter fontSize="small" />
          </ListItemIcon>
          <ListItemText>Filter</ListItemText>
        </MenuItem>

        <Grid container justifyContent="center" style={{ paddingBottom: '15px', marginBottom: '5px' }}>
          <FormControl style={{ width: '85%' }}>
            <SelectWrapper variant="outlined">
              <SelectLabel id="condition-filter-outlined-label">Condition</SelectLabel>
              <Select label="Condition" value={conditionValue} onChange={handleConditionChange}>
                {conditions.map((c: Condition) => (
                  <MenuItem key={c.value} value={c.value}>
                    {c.label}
                  </MenuItem>
                ))}
              </Select>
            </SelectWrapper>
            {conditionValue === FilterOperation.LESS || conditionValue === FilterOperation.DATE_EQUALS || (
              <DatePicker
                fullWidth
                disableFuture
                format="MM/dd/yyyy"
                label="Start date"
                value={minDate}
                onChange={handleSetMinDate}
              />
            )}
            {conditionValue === FilterOperation.GREATER || (
              <DatePicker
                fullWidth
                disableFuture
                format="MM/dd/yyyy"
                label="End date"
                value={maxDate}
                onChange={handleSetMaxDate}
              />
            )}
          </FormControl>
        </Grid>
      </>
    ),
    [conditionValue, conditions, handleConditionChange, handleSetMaxDate, handleSetMinDate, maxDate, minDate]
  )
}

export default DateFilter
