import { createSlice } from '@reduxjs/toolkit'
import { ApiStatus, failedResponse, inIdle, inProgress, successResponse } from 'global/redux/toolkit/api'
import {
  UnifiedReportingReport,
  UnifiedReportingReportData,
  UnifiedReportingReportProduct,
  UnifiedReportingReportType
} from 'global/types/api/unifiedReporting'
import { ChartPeriod, ChartType } from 'global/components/lib/chartsV2/ChartConfig'
import {
  createUnifiedReportingReport,
  deleteUnifiedReportingReport,
  getUnifiedReportingCSVReport,
  getUnifiedReportingPDFReport,
  getUnifiedReportingReport,
  getUnifiedReportingReportData,
  getUnifiedReportingReports,
  pinUnifiedReportingReport,
  updateUnifiedReportingReport
} from './unifiedReportingApiThunks'

export interface UnifiedReportingState {
  createUnifiedReportingReportApiStatus: ApiStatus
  deleteUnifiedReportingReportApiStatus: ApiStatus
  errorMessage: string
  getUnifiedReportingReportApiStatus: ApiStatus
  getUnifiedReportingReportDataApiStatus: ApiStatus
  getUnifiedReportingReportsApiStatus: ApiStatus
  pinnedReportUuid: string
  pinUnifiedReportingReportApiStatus: ApiStatus
  report: UnifiedReportingReport
  reports: UnifiedReportingReport[]
  reportData: UnifiedReportingReportData
  updateUnifiedReportingReportApiStatus: ApiStatus
}

type UpdatePayload = Partial<UnifiedReportingState>

const initialReport: UnifiedReportingReport = {
  baseDataType: '',
  config: {
    absoluteDateRangeEnd: '',
    absoluteDateRangeStart: '',
    chartPeriod: ChartPeriod.monthly,
    chartType: ChartType.line,
    filters: [],
    groupBy: [],
    hiddenColumns: [],
    searchQuery: '',
    sortBy: []
  },
  createdOn: '',
  lastModifiedBy: '',
  lastModifiedOn: '',
  name: '',
  product: UnifiedReportingReportProduct.IR,
  type: UnifiedReportingReportType.BARRACUDA,
  uuid: ''
}

// Initial state
export const INITIAL_STATE: UnifiedReportingState = {
  deleteUnifiedReportingReportApiStatus: inIdle,
  errorMessage: '',
  getUnifiedReportingReportApiStatus: inIdle,
  getUnifiedReportingReportDataApiStatus: inIdle,
  getUnifiedReportingReportsApiStatus: inIdle,
  createUnifiedReportingReportApiStatus: inIdle,
  pinnedReportUuid: '',
  pinUnifiedReportingReportApiStatus: inIdle,
  report: initialReport,
  reports: [initialReport],
  reportData: {
    table: {
      data: [],
      count: 0,
      totalCount: 0,
      isGrouped: false,
      baseDataType: ''
    },
    chart: {
      data: [],
      isGrouped: false,
      fieldNames: []
    }
  },
  updateUnifiedReportingReportApiStatus: inIdle
}

/* eslint-disable no-param-reassign */
export const unifiedReportingSlice = createSlice({
  name: 'UNIFIED_REPORTING',
  initialState: INITIAL_STATE,
  // reducers object creates an action and executes the reducer function
  reducers: {
    update: (state, action: { payload: UpdatePayload }) => {
      return {
        ...state,
        ...action.payload
      }
    },
    resetReportData: state => {
      return {
        ...state,
        getUnifiedReportingReportDataApiStatus: INITIAL_STATE.getUnifiedReportingReportDataApiStatus,
        reportData: INITIAL_STATE.reportData
      }
    },
    reset: () => {
      return { ...INITIAL_STATE }
    },
    resetCreateUpdateDeleteReportStatus: (state: UnifiedReportingState) => {
      state.createUnifiedReportingReportApiStatus = INITIAL_STATE.createUnifiedReportingReportApiStatus
      state.updateUnifiedReportingReportApiStatus = INITIAL_STATE.updateUnifiedReportingReportApiStatus
      state.deleteUnifiedReportingReportApiStatus = INITIAL_STATE.deleteUnifiedReportingReportApiStatus
    },
    resetGetUnifiedReportingReportsApiStatus: (state: UnifiedReportingState) => {
      state.getUnifiedReportingReportsApiStatus = INITIAL_STATE.getUnifiedReportingReportsApiStatus
    },
    setErrorMessage: (state, action: any) => {
      state.errorMessage = action.payload
    }
  },
  // extraReducers do not create an action but executes the reducer function
  extraReducers: builder => {
    builder
      .addCase(getUnifiedReportingReports.pending, state => {
        state.getUnifiedReportingReportsApiStatus = inProgress
      })
      .addCase(getUnifiedReportingReports.fulfilled, (state, action) => {
        state.reports = action.payload.reports
        state.pinnedReportUuid = action.payload.pinnedReportUuid
        state.getUnifiedReportingReportsApiStatus = successResponse
      })
      .addCase(getUnifiedReportingReports.rejected, (state, action) => {
        state.getUnifiedReportingReportsApiStatus = failedResponse(action.payload)
      })
      // getUnifiedReportingReport reducers are not needed as the report is fetched from the reports array
      .addCase(getUnifiedReportingReport.pending, state => {
        state.getUnifiedReportingReportApiStatus = inProgress
      })
      .addCase(getUnifiedReportingReport.fulfilled, (state, action) => {
        state.report = action.payload
        state.getUnifiedReportingReportApiStatus = successResponse
      })
      .addCase(getUnifiedReportingReport.rejected, (state, action) => {
        state.getUnifiedReportingReportApiStatus = failedResponse(action.payload)
      })
      // getUnifiedReportingReportData
      .addCase(getUnifiedReportingReportData.pending, state => {
        state.getUnifiedReportingReportDataApiStatus = inProgress
      })
      .addCase(getUnifiedReportingReportData.fulfilled, (state, action) => {
        state.reportData = action.payload
        state.getUnifiedReportingReportDataApiStatus = successResponse
      })
      .addCase(getUnifiedReportingReportData.rejected, (state, action) => {
        state.getUnifiedReportingReportDataApiStatus = failedResponse(action.payload)
      })
      .addCase(createUnifiedReportingReport.pending, state => {
        state.createUnifiedReportingReportApiStatus = inProgress
      })
      .addCase(createUnifiedReportingReport.fulfilled, (state, action) => {
        state.createUnifiedReportingReportApiStatus = successResponse
      })
      .addCase(createUnifiedReportingReport.rejected, (state, action) => {
        state.createUnifiedReportingReportApiStatus = failedResponse(action.payload)
      })
      .addCase(updateUnifiedReportingReport.pending, state => {
        state.updateUnifiedReportingReportApiStatus = inProgress
      })
      .addCase(updateUnifiedReportingReport.fulfilled, (state, action) => {
        state.report = action.payload
        state.updateUnifiedReportingReportApiStatus = successResponse
      })
      .addCase(updateUnifiedReportingReport.rejected, (state, action) => {
        state.updateUnifiedReportingReportApiStatus = failedResponse(action.payload)
      })
      .addCase(deleteUnifiedReportingReport.pending, state => {
        state.deleteUnifiedReportingReportApiStatus = inProgress
      })
      .addCase(deleteUnifiedReportingReport.fulfilled, (state, action) => {
        state.deleteUnifiedReportingReportApiStatus = successResponse
      })
      .addCase(deleteUnifiedReportingReport.rejected, (state, action) => {
        state.deleteUnifiedReportingReportApiStatus = failedResponse(action.payload)
      })
      .addCase(pinUnifiedReportingReport.pending, (state, action) => {
        state.pinUnifiedReportingReportApiStatus = inProgress
      })
      .addCase(pinUnifiedReportingReport.fulfilled, (state, action) => {
        state.pinnedReportUuid = action.payload
        state.pinUnifiedReportingReportApiStatus = successResponse
      })
      .addCase(pinUnifiedReportingReport.rejected, (state, action) => {
        state.pinUnifiedReportingReportApiStatus = failedResponse(action.payload)
      })
  }
})
/* eslint-enable no-param-reassign */

export const {
  reset,
  resetReportData,
  resetCreateUpdateDeleteReportStatus,
  resetGetUnifiedReportingReportsApiStatus,
  setErrorMessage,
  update
} = unifiedReportingSlice.actions

export {
  createUnifiedReportingReport,
  deleteUnifiedReportingReport,
  getUnifiedReportingCSVReport,
  getUnifiedReportingPDFReport,
  getUnifiedReportingReportData,
  getUnifiedReportingReports,
  pinUnifiedReportingReport,
  updateUnifiedReportingReport
}

export default unifiedReportingSlice.reducer
