import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Button, Typography } from '@barracuda-internal/bds-core'

import styles from 'global/components/features/signinSignup/signinSignupStylesNew'

import IMAGES from 'global/configs/theme/images.config'
import { useFormatMessage } from 'global/lib/localization'

export interface SigninSignupConnectProps {
  onSignup: () => void
}

const BASE_I18N_KEY = 'app.signin_signup'

const SigninSignupConnect: React.FC<SigninSignupConnectProps> = ({ onSignup }) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <div className={classes.formSignupWrapper}>
        <Typography className={classes.formSubtitle} align="center" data-field="form-subtitle">
          {formatMessage('alternate_layout.subtitle')}
        </Typography>
        <Button
          className={classes.formSignupButton}
          color="primary"
          variant="contained"
          data-action="signup"
          disableElevation
          fullWidth
          onClick={onSignup}
          size="large"
        >
          <img className={classes.formO365Icon} alt="" src={IMAGES.iconO365White} data-field="form-o365-icon" />
          {formatMessage('alternate_layout.o365_button')}
        </Button>
      </div>
    ),
    [onSignup, classes, formatMessage]
  )
}

SigninSignupConnect.propTypes = {
  onSignup: PropTypes.func.isRequired
}

export default SigninSignupConnect
