import React, { useState, useMemo, useCallback } from 'react'

export type AnchorEl = HTMLButtonElement | undefined
export type SetAnchorEl = (event: React.MouseEvent<HTMLButtonElement>) => void
export type UnsetAnchorEl = () => void

export default function useAnchorElLogic(): [AnchorEl, SetAnchorEl, UnsetAnchorEl] {
  const [anchorEl, setAnchorEl] = useState<undefined | HTMLButtonElement>(undefined)

  const onSetAnchorEl: SetAnchorEl = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget)
  }, [])

  const onUnsetAnchorEl: UnsetAnchorEl = useCallback(() => {
    setAnchorEl(undefined)
  }, [])

  return useMemo(() => {
    return [anchorEl, onSetAnchorEl, onUnsetAnchorEl]
  }, [anchorEl, onSetAnchorEl, onUnsetAnchorEl])
}
