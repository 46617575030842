import { createSlice, isAnyOf, isAsyncThunkAction } from '@reduxjs/toolkit'
import { union } from 'lodash'

import { update as updateAttackingDomainsTable } from 'ets/redux/features/dataTables/attackingDomains/attackingDomainsSlice'
import { update as updateDomainsTable } from 'ets/redux/features/dataTables/domains/domainSlice'
import { update as updateEmployeesTable } from 'ets/redux/features/dataTables/employees/employeesSlice'
import { update as updateThreatsTable } from 'ets/redux/features/dataTables/threats/threatsSlice'
import { update as updateUserEmailsTable } from 'ets/redux/features/dataTables/userEmails/userEmailsSlice'
import * as attacksByDomain from 'ets/redux/features/reports/attacksByDomain/attacksByDomainSlice'
import * as chartReport from 'ets/redux/features/reports/chartReport/chartReportSlice'
import * as domains from 'ets/redux/features/reports/domains/domainsSlice'
import * as employees from 'ets/redux/features/reports/employees/employeesSlice'
import * as threats from 'ets/redux/features/reports/threats/threatsSlice'
import * as threatsByUsername from 'ets/redux/features/reports/threatsByUsername/threatsByUsernameSlice'

export interface ReportState {
  getChartReportsApiStatus: chartReport.ChartReportState
  attacksByDomain: attacksByDomain.AttacksByDomainState
  domains: domains.DomainsState
  employees: employees.EmployeesState
  threats: threats.ThreatsState
  threatsByUsername: threatsByUsername.ThreatsByUsernameState
}

// initialState
export const INITIAL_STATE = {
  chartReport: chartReport.INITIAL_STATE,
  attacksByDomain: attacksByDomain.INITIAL_STATE,
  domains: domains.INITIAL_STATE,
  employees: employees.INITIAL_STATE,
  threats: threats.INITIAL_STATE,
  threatsByUsername: threatsByUsername.INITIAL_STATE
}

/* eslint-disable no-param-reassign */
export const reportsSlice = createSlice({
  name: 'REPORTS',
  initialState: INITIAL_STATE,
  reducers: {
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(updateAttackingDomainsTable, (state, action) => {
        if (action.payload.config?.skip !== undefined) {
          state.attacksByDomain.loadedOffsets = union(state.attacksByDomain.loadedOffsets, [action.payload.config.skip])
        }
      })
      .addCase(updateDomainsTable, (state, action) => {
        if (action.payload.config?.skip !== undefined) {
          state.domains.loadedOffsets = union(state.domains.loadedOffsets, [action.payload.config.skip])
        }
      })
      .addCase(updateEmployeesTable, (state, action) => {
        if (action.payload.config?.skip !== undefined) {
          state.employees.loadedOffsets = union(state.employees.loadedOffsets, [action.payload.config.skip])
        }
      })
      .addCase(updateThreatsTable, (state, action) => {
        if (action.payload.config?.skip !== undefined) {
          state.threats.loadedOffsets = union(state.threats.loadedOffsets, [action.payload.config.skip])
        }
      })
      .addCase(updateUserEmailsTable, (state, action) => {
        if (action.payload.config?.skip !== undefined) {
          state.threatsByUsername.loadedOffsets = union(state.threatsByUsername.loadedOffsets, [
            action.payload.config.skip
          ])
        }
      })
      .addMatcher(
        isAnyOf(isAsyncThunkAction(chartReport.getChartsReports), chartReport.resetChartReport),
        (state, action) => {
          state.chartReport = chartReport.default(state.chartReport, action)
        }
      )
      .addMatcher(
        isAnyOf(
          isAsyncThunkAction(attacksByDomain.getAttacksByDomainReport),
          isAsyncThunkAction(attacksByDomain.getPDFAttacksByDomainReport),
          attacksByDomain.resetAttacksByDomainReport
        ),
        (state, action) => {
          state.attacksByDomain = attacksByDomain.default(state.attacksByDomain, action)
        }
      )
      .addMatcher(
        isAnyOf(
          isAsyncThunkAction(domains.getDomainsReport),
          domains.softUpdateReportCounts,
          domains.softUpdateReportData,
          domains.reset
        ),
        (state, action) => {
          state.domains = { ...domains.default(state.domains, action) }
        }
      )
      .addMatcher(
        isAnyOf(
          isAsyncThunkAction(employees.getEmployeesReport),
          isAsyncThunkAction(employees.getPDFEmployeesReport),
          employees.resetEmployeesReport
        ),
        (state, action) => {
          state.employees = { ...employees.default(state.employees, action) }
        }
      )
      .addMatcher(
        isAnyOf(
          isAsyncThunkAction(threats.getThreatsReport),
          isAsyncThunkAction(threats.getPDFThreatsReport),
          threats.resetThreatsReport
        ),
        (state, action) => {
          state.threats = { ...threats.default(state.threats, action) }
        }
      )
      .addMatcher(
        isAnyOf(
          isAsyncThunkAction(threatsByUsername.getThreatsByUsernameReport),
          threatsByUsername.resetThreatsByUsernameReport
        ),
        (state, action) => {
          state.threatsByUsername = { ...threatsByUsername.default(state.threatsByUsername, action) }
        }
      )
  }
})
/* eslint-enable no-param-reassign */

export const { reset } = reportsSlice.actions

export default reportsSlice.reducer
