import { makeStyles } from '@material-ui/core/styles'

export const PANEL_HEIGHT = 364
export const CHART_AREA_HEIGHT = 136
export const CHART_AREA_BIGGER_HEIGHT = 209

export default makeStyles(theme => ({
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  maskLayer: {
    width: '100%',
    height: '100%',
    zIndex: 999,
    backgroundColor: 'white',
    opacity: 0.9
  },
  textLayer: {
    position: 'absolute',
    display: 'table',
    width: '100%',
    height: '100%',
    zIndex: 1000
  },
  text: {
    display: 'table-cell',
    verticalAlign: 'middle',
    textAlign: 'center',
    color: theme.palette.text.primary,
    fontSize: 18,
    fontWeight: 900,
    letterSpacing: 0,
    lineHeight: '21px',
    cursor: 'default'
  }
}))
