import { useMemo, useRef, useEffect, RefObject } from 'react'

import { debounce } from 'lodash'

import { DEFAULT_DEBOUNCE_RATE } from 'global/lib/keyEvents'
import validateSearchString, { ValidateSearchString } from 'global/lib/validateSearchString'
import usePrevious from 'global/lib/usePrevious'

export type DebounceCb = (...args: any[]) => void
export type DebounceOnChange = (...args: any[]) => void

export default function useSearchFieldLogic(
  debounceCb: DebounceCb,
  inProgress: boolean,
  searchId: string,
  debaounceRate: number = DEFAULT_DEBOUNCE_RATE
): [DebounceOnChange, ValidateSearchString, RefObject<boolean>] {
  const onSearchChanged = useRef(false)
  const prevInProgress = usePrevious(inProgress)

  const debouncedOnChange = useMemo(() => {
    return debounce((...args: any[]) => {
      onSearchChanged.current = true
      debounceCb(...args)
    }, debaounceRate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Focus in search input after filtered data is loaded
  useEffect(() => {
    if (!inProgress && prevInProgress && onSearchChanged.current) {
      const searchInput: any = document.querySelector(`[data-input="${searchId}"] > input`)
      // eslint-disable-next-line no-unused-expressions
      searchInput?.focus()
      onSearchChanged.current = false
    }
  }, [inProgress, prevInProgress, onSearchChanged, searchId])

  return useMemo(() => {
    return [debouncedOnChange, validateSearchString, onSearchChanged]
  }, [debouncedOnChange, onSearchChanged])
}
