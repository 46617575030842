import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { DataTable, DataTableColumn, Grid, Typography } from '@barracuda-internal/bds-core'

import { IncidentEmail } from 'global/types/api/remediation'
import { BDSGridPagerConfig } from 'global/types/dataTables/dataTables'
import { ColumnsConfig } from 'global/types/dataTables/columnsConfigType'

import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'
import CircularProgress from 'global/components/lib/circularProgress/CircularProgress'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import Pager from 'global/components/lib/grid/pager/Pager'

import { useFormatMessage } from 'global/lib/localization'

import styles from 'sen/components/lib/dialogs/newIncidentDialog/newIncidentDialogStyles'

export interface TableConfig {
  isLoaded: boolean
  tableData: {
    total: number
    data: IncidentEmail[]
  }
  pageConfig: BDSGridPagerConfig
  columns: { [key: string]: string }
  columnsConfig: { [key: string]: ColumnsConfig }
  isFlexibleTable: boolean
}

export interface RecipientsConfig {
  hasRecipients: boolean
  inProgress: boolean
}

export interface RecipientsTableProps {
  tableConfig: TableConfig
  recipientsConfig: RecipientsConfig
  BASE_I18N_KEY: string
}

const RecipientsTable: React.FC<RecipientsTableProps> = ({ tableConfig, recipientsConfig, BASE_I18N_KEY }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  return useMemo(
    () => (
      <Grid container direction="column">
        {!tableConfig.isLoaded && (
          <Grid container justifyContent="center" className={classes.contentLoading}>
            <CircularProgress data-field="loader" />
          </Grid>
        )}

        {tableConfig.isLoaded && (
          <Grid container item>
            <Grid item className={classes.spacerBottom}>
              {!recipientsConfig.hasRecipients && (
                <Typography className={classes.title}>{formatMessage('title_no_recipient')}</Typography>
              )}

              {recipientsConfig.hasRecipients && (
                <Typography className={classes.title}>
                  {formatMessage('title', {
                    count: tableConfig.tableData.total
                  })}
                </Typography>
              )}
            </Grid>
          </Grid>
        )}

        {tableConfig.isLoaded && recipientsConfig.hasRecipients && (
          <Grid container direction="column" data-field="recipients-table-wrapper">
            <Grid className={classes.flexibleTableArea} data-table="recipients" item xs={12}>
              <>
                {recipientsConfig.inProgress && <LinearProgress className={classes.indicator} />}

                <DataTable
                  className={classes.flexibleDataTable}
                  data={tableConfig.tableData}
                  {...tableConfig.pageConfig}
                  pager={Pager}
                >
                  <DataTableColumn
                    field={tableConfig.columns.DISPLAY_NAME}
                    {...tableConfig.columnsConfig[tableConfig.columns.DISPLAY_NAME]}
                    title={formatMessage(`${tableConfig.columns.DISPLAY_NAME}`)}
                    cell={({ dataItem }: { dataItem: IncidentEmail }) => (
                      <Cell className={classes.flexibleCell} data-field="table-column-name">
                        <CellText ellipsis className={classes.normalCell} data-field="table-column-sent">
                          {dataItem.displayName}
                        </CellText>
                      </Cell>
                    )}
                  />
                  <DataTableColumn
                    field={tableConfig.columns.EMAIL}
                    {...tableConfig.columnsConfig[tableConfig.columns.EMAIL]}
                    title={formatMessage(`${tableConfig.columns.EMAIL}`)}
                    cell={({ dataItem }: { dataItem: IncidentEmail }) => (
                      <Cell className={classes.flexibleCell} data-field="table-column-email">
                        <CellText className={classes.normalCell} data-field="table-column-email-text">
                          {dataItem.email}
                        </CellText>
                      </Cell>
                    )}
                  />
                </DataTable>
              </>
            </Grid>
          </Grid>
        )}
      </Grid>
    ),
    [classes, formatMessage, tableConfig, recipientsConfig]
  )
}

RecipientsTable.propTypes = {
  tableConfig: PropTypes.any.isRequired,
  recipientsConfig: PropTypes.any.isRequired,
  BASE_I18N_KEY: PropTypes.string.isRequired
}

export default RecipientsTable
