export interface Filter {
  field: string
  value: string
  operator?: string
  logic?: 'and' | 'or'
}

const DEFAULT_OPERATOR = 'contains-phrase'
const DEFAULT_LOGIC = 'and'

export default function buildApiFilter(
  filters: Filter[],
  defaultOperator: string = DEFAULT_OPERATOR,
  defaultLogic: 'and' | 'or' = DEFAULT_LOGIC
) {
  return {
    filters: [
      {
        logic: defaultLogic,
        filters: filters
          .filter((filter: any) => !!filter.value.length)
          .map((filter: Filter) => ({
            operator: defaultOperator,
            ...filter
          }))
      }
    ],
    logic: DEFAULT_LOGIC
  }
}
