import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Account } from 'global/types/api/accountType'

export interface CloudState {
  hasScanTypeSelection: boolean
  accounts: Account[]
  account: Account | undefined
}

// initialState
export const INITIAL_STATE = {
  hasScanTypeSelection: false,
  accounts: [],
  account: undefined
}

/* eslint-disable no-param-reassign */
export const cloudSlice = createSlice({
  name: 'CLOUD',
  initialState: INITIAL_STATE,
  reducers: {
    init: {
      reducer: (
        state: CloudState,
        action: PayloadAction<{ hasScanTypeSelection: boolean; accounts: Account[]; account: Account }>
      ) => {
        state.hasScanTypeSelection = action.payload.hasScanTypeSelection
        state.accounts = action.payload.accounts || []
        state.account = action.payload.account
      },
      prepare: (hasScanTypeSelection: boolean, accounts: Account[]) => ({
        payload: { hasScanTypeSelection, accounts, ...(accounts && { account: accounts[0] }) }
      })
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  }
})
/* eslint-enable no-param-reassign */

export const { init, reset } = cloudSlice.actions

export default cloudSlice.reducer
