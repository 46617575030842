import { makeStyles } from '@material-ui/core/styles'
import { COLORS } from 'global/configs/theme/defaults'

export default makeStyles(theme => {
  return {
    radioButtonGroup: {
      margin: `${theme.spacing(2)}px 0`
    },
    borderedSection: {
      paddingBottom: theme.spacing(2),
      borderBottom: `1px solid ${COLORS.LIGHT_GRAY}`
    },
    lastSection: {
      marginBottom: theme.spacing(1)
    },
    adminContainer: {
      paddingLeft: theme.spacing(4)
    },
    adminLabel: {
      fontSize: 12,
      lineHeight: `${theme.spacing(1.5)}px`,
      marginBottom: theme.spacing(1),
      color: theme.palette.text.secondary
    }
  }
})
