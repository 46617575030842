import { createSlice } from '@reduxjs/toolkit'

import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'global/redux/toolkit/api'

import {
  activateReportSharing,
  convertETS2Sentinel,
  deactivateReportSharing,
  deleteEtsReport,
  rerunCurrentScan,
  setAccessTokenSettingsConvert
} from 'ets/redux/features/settings/settingsApiThunks'

export interface SettingsState {
  deleteEtsReportApiStatus: ApiStatus
  activateReportSharingApiStatus: ApiStatus
  deactivateReportSharingApiStatus: ApiStatus
  convertETS2SentinelApiStatus: ApiStatus
  setAccessTokenSettingsConvertApiStatus: ApiStatus
  rerunCurrentScanApiStatus: ApiStatus
}

// initialState
export const INITIAL_STATE = {
  deleteEtsReportApiStatus: inIdle,
  activateReportSharingApiStatus: inIdle,
  deactivateReportSharingApiStatus: inIdle,
  convertETS2SentinelApiStatus: inIdle,
  setAccessTokenSettingsConvertApiStatus: inIdle,
  rerunCurrentScanApiStatus: inIdle,
  data: {
    accessToken: {}
  },
  error: false
}

/* eslint-disable no-param-reassign */
export const settingsSlice = createSlice({
  name: 'SETTINGS',
  initialState: INITIAL_STATE,
  reducers: {
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(deleteEtsReport.pending, state => {
        state.deleteEtsReportApiStatus = inProgress
      })
      .addCase(deleteEtsReport.fulfilled, state => {
        state.deleteEtsReportApiStatus = successResponse
      })
      .addCase(deleteEtsReport.rejected, (state, action) => {
        state.deleteEtsReportApiStatus = failedResponse(action.payload as string)
      })
      .addCase(activateReportSharing.pending, state => {
        state.activateReportSharingApiStatus = inProgress
      })
      .addCase(activateReportSharing.fulfilled, state => {
        state.activateReportSharingApiStatus = successResponse
      })
      .addCase(activateReportSharing.rejected, (state, action) => {
        state.activateReportSharingApiStatus = failedResponse(action.payload as string)
      })
      .addCase(deactivateReportSharing.pending, state => {
        state.deactivateReportSharingApiStatus = inProgress
      })
      .addCase(deactivateReportSharing.fulfilled, state => {
        state.deactivateReportSharingApiStatus = successResponse
      })
      .addCase(deactivateReportSharing.rejected, (state, action) => {
        state.deactivateReportSharingApiStatus = failedResponse(action.payload as string)
      })
      .addCase(convertETS2Sentinel.pending, state => {
        state.convertETS2SentinelApiStatus = inProgress
      })
      .addCase(convertETS2Sentinel.fulfilled, state => {
        state.convertETS2SentinelApiStatus = successResponse
      })
      .addCase(convertETS2Sentinel.rejected, (state, action) => {
        state.convertETS2SentinelApiStatus = failedResponse(action.payload as string)
      })
      .addCase(setAccessTokenSettingsConvert.pending, state => {
        state.setAccessTokenSettingsConvertApiStatus = inProgress
      })
      .addCase(setAccessTokenSettingsConvert.fulfilled, state => {
        state.setAccessTokenSettingsConvertApiStatus = successResponse
      })
      .addCase(setAccessTokenSettingsConvert.rejected, (state, action) => {
        state.setAccessTokenSettingsConvertApiStatus = failedResponse(action.payload as string)
      })
      .addCase(rerunCurrentScan.pending, state => {
        state.rerunCurrentScanApiStatus = inProgress
      })
      .addCase(rerunCurrentScan.fulfilled, state => {
        state.rerunCurrentScanApiStatus = successResponse
      })
      .addCase(rerunCurrentScan.rejected, (state, action) => {
        state.rerunCurrentScanApiStatus = failedResponse(action.payload as string)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { reset } = settingsSlice.actions

export {
  activateReportSharing,
  convertETS2Sentinel,
  deactivateReportSharing,
  deleteEtsReport,
  rerunCurrentScan,
  setAccessTokenSettingsConvert
}

export default settingsSlice.reducer
