import React, { useMemo, useCallback, useState } from 'react'

import onScroll from 'global/lib/onScroll/onScroll'

export interface DashboardMenuLogic {
  tabs: string[]
  activeTab: number
  onTabChange: (e: React.ChangeEvent<{}>, newValue: string) => void
}

export interface DashboardMenuLogicProps {
  TABS: { [key: string]: string }
  APP_HEADER_HEIGHT: number
  scrollToSection: (sectionIndex: number) => void
  getSectionElement: (section: string) => HTMLElement | null
}

export default function useDashboardMenuLogic({
  TABS,
  APP_HEADER_HEIGHT,
  scrollToSection,
  getSectionElement
}: DashboardMenuLogicProps): [DashboardMenuLogic] {
  const [activeTab, setActiveTab] = useState<number>(0)

  const tabs: string[] = useMemo(() => {
    return Object.values(TABS)
  }, [TABS])

  onScroll({
    cb: ({ currPos }: { currPos: { y: number } }) => {
      const isScrolledToBottom = document.body.clientHeight - window.innerHeight - 20 < currPos.y
      const currentSectionIndex = isScrolledToBottom
        ? tabs.length - 1
        : tabs
            .map((tab: string) => {
              const sectionElement = getSectionElement(tab)

              return sectionElement ? sectionElement.offsetTop - APP_HEADER_HEIGHT : 0
            })
            .reduce((all: number, sectionOffset: number, idx: number) => {
              return currPos.y >= sectionOffset ? idx : all
            }, -1)

      if (currentSectionIndex !== activeTab) {
        setActiveTab(currentSectionIndex)
      }
    },
    deps: [activeTab],
    throttle: 100
  })

  const onTabChange = useCallback(
    (_e, newValue) => {
      scrollToSection(newValue)
    },
    [scrollToSection]
  )

  return useMemo(
    () => [
      {
        tabs,
        activeTab,
        onTabChange
      }
    ],
    [tabs, activeTab, onTabChange]
  )
}
