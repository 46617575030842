export const COLORS = {
  ERROR: '#E34050',
  RED: '#F05336',
  LIGHT_RED: '#F7DED8',
  BLUE: '#0088CE',
  YELLOW: '#EEAA48',
  GREEN: '#00B098',
  LIGHT_GREEN: '#95CC66',
  LIGHT_GRAY: '#D4D4D3',
  GRAY: '#757575',
  DARK_GRAY: '#5F5F5F',
  HIGHLIGHT: '#FBD25B',
  INPUT_BACKGROUNDS: '#EFEFEF',
  BACKGROUND: '#FFFFFF',
  ORANGE: '#EB3D01',
  BLACK_50: '#7F7F82',
  SELECTION: {
    COLOR: '#000006',
    BG_COLOR: '#b4d5ff'
  },

  // BDS COLORS
  MAGENTA_600: '#D6269B',

  SCALE_GRAY_100: '#E2E2E1',
  SCALE_GRAY_500: '#7D7D7D',
  SCALE_GRAY_600: '#646464',
  SCALE_GRAY_800: '#323232',
  SCALE_GRAY_900: '#191919',

  BLUE_600: '#067CC1',
  BLUE_700: '#0A5D91',

  TEAL_500: '#009680',
  TEAL_600: '#007B68',

  RED_50: '#FFEEEE',
  RED_600: '#DE3131',

  ORANGE_400: '#DDA021',

  ROSE_700: '#972534',

  GREEN_200: '#9FD86E',
  GREEN_400: '#66AA28',

  HUMPBACK_GRAY_600: '#566978',

  SKY_BLUE_500: '#0F98AE',

  DEEP_OCEAN_500: '#5E728D',

  GRAY_25: '#F0F3FA',

  WHITE: '#FFFFFF',
  STATUS_ERROR: '#DE3131',
  GRAY_DARKEST: '#191919',
  PRODUCT_UI_ELEMENT: '#DDDDDD',
  PRODUCT_GRAY_DARKER: '#4B4B4B',
  LIGHT_BACKGROUND: '#F7F7F7',

  // BDS INPUTS
  DISABLED_PLACEHOLDER_TEXT: '#8e8e8e',
  ACTIVE_PLACEHOLDER_TEXT: '#696969',
  USER_ENTERED_TEXT: '#191919',

  // PDF
  PDF: {
    LIGHT_GRAY: '#4C4C50',
    GRAY: '#A5A5A5'
  },

  // ALERT BOX
  ALERT_BOX: {
    ALERT_BACKGROUND: '#FEECEB',
    ALERT_TEXT: '#621B16',
    RED: '#F44336'
  }
}

// TODO: remove this once BDS makes fonts accessible in their theme
export const FONT = {
  size: {
    xsmall: 10,
    small: 12,
    medium: 14,
    regular: 16,
    large: 20,
    xlarge: 24,
    xxlarge: 32,
    h1: 88,
    h2: 60,
    h3: 40,
    h4: 32,
    h5: 24,
    h6: 20
  },
  weight: {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700
  },
  lineheight: {
    regular: '16px',
    large: '24px',
    xlarge: '32px',
    h1: '104px',
    h2: '72px',
    h3: '56px',
    h4: '40px',
    h5: '32px',
    h6: '32px'
  }
}

export default {
  DATE_RANGE_ZINDEX: 1300,
  DIALOGS_ZINDEX: 1500,
  SECONDARY_DIALOGS_ZINDEX: 1800
}
