import { useCallback, useEffect, useMemo, useReducer } from 'react'
import { useParams } from 'react-router-dom'

import { process, SortDescriptor } from '@progress/kendo-data-query'
import { cloneDeep } from 'lodash'

import apiRoutes from 'fir/lib/api/apiRoutes'
import {
  forensicsGetIncidentClickedLinks,
  forensicsGetMoreIncidentClickedLinks,
  forensicsResetIncidentClickedLinks
} from 'fir/redux/features/remediation/remediationSlice'
import * as clickedLinksByIncidentTableActions from 'fir/redux/features/dataTables/clickedLinksByIncident/clickedLinksByIncidentSlice'
import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { config } from 'global/lib/config'
import * as datetime from 'global/lib/datetime'
import { isPending, isSuccess } from 'global/redux/toolkit/api'

import { ClickedBy, IncidentClick, UserAgent } from 'global/types/api/remediation'

import { UrlParams } from '../useIncidentDetailsLogic'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseClickedLinksByIncidentParams {}

export interface ClickedLinksByIncidentProps {
  exportPath?: string
  columnsConfig: any
  GRID_COLUMNS: any
  gridData: any
  isPageInProgress: boolean
  onExportData: () => void
  pageConfig: any
  sortConfig: any
}

export interface GridRow {
  clickedIp: string[]
  dateSent: string
  essId: string
  contentType: string
  mimeType: string
  recipients: string[]
  url: string
  userAgents: string[]
}

export default function useClickedLinksByIncidentLogic(
  params: UseClickedLinksByIncidentParams
): [ClickedLinksByIncidentProps] {
  const urlParams: UrlParams = useParams()
  const dispatch = useAppDispatch()

  const {
    accessTokenId,
    dataTables,
    remediation,
    isIncidentClickedLinksSuccess,
    isIncidentUserDetailsLoading
  } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id || '',
    dataTables: _stores.dataTables,
    remediation: _stores.remediation,
    isIncidentClickedLinksSuccess: isSuccess(_stores.remediation.getIncidentClickedLinksApiStatus),
    isIncidentUserDetailsLoading: isPending(_stores.remediation.getIncidentUserDetailsApiStatus)
  }))

  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    exportPath: null
  })

  useEffect(() => {
    dispatch(
      forensicsGetIncidentClickedLinks({ accessTokenId, config: apiParams, incidentId: urlParams.incidentId as string })
    )
    return () => {
      dispatch(forensicsResetIncidentClickedLinks())
      dispatch(clickedLinksByIncidentTableActions.reset())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const apiParams = useMemo(() => {
    const { page, sort } = dataTables.clickedLinksByIncident

    return {
      query: {
        page: Math.abs(Number(page.skip) / Number(page.take)) + 1,
        limit: Number(page.take),
        order: (sort[0].dir === 'desc' ? '' : '-') + sort[0].field,
        filter: []
      }
    }
  }, [dataTables.clickedLinksByIncident])

  // Sort hook
  useEffect(() => {
    if (isIncidentClickedLinksSuccess) {
      dispatch(
        forensicsGetMoreIncidentClickedLinks({
          accessTokenId,
          config: apiParams,
          incidentId: urlParams.incidentId as string
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTables.clickedLinksByIncident.sort])

  // Pagination hook
  useEffect(() => {
    if (
      isIncidentClickedLinksSuccess &&
      !remediation.clickedLinksByIncident.data[dataTables.clickedLinksByIncident.page.skip]
    ) {
      dispatch(
        forensicsGetMoreIncidentClickedLinks({
          accessTokenId,
          config: apiParams,
          incidentId: urlParams.incidentId as string
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTables.clickedLinksByIncident.page.skip])

  const gridData = useMemo(() => {
    const { page } = dataTables.clickedLinksByIncident
    const clonedRemediationClickedLinksByIncidentData = cloneDeep(remediation.clickedLinksByIncident.data)
    const data = process(
      clonedRemediationClickedLinksByIncidentData
        .slice(page.skip, page.skip + page.take)
        .reduce((all: IncidentClick[], click: IncidentClick) => {
          if (!click) {
            return [...all, {} as any]
          }

          // pull out client ip and user agent data into arrays
          let clickedIp = [] as string[]
          let userAgents = [] as string[]
          if (click.clickedBy) {
            clickedIp = click.clickedBy.map((clickedBy: ClickedBy) => clickedBy.clientIp)
            userAgents = click.clickedBy.flatMap((clickedBy: ClickedBy) =>
              clickedBy.userAgents.map((userAgent: UserAgent) => userAgent.userAgent)
            )
          }

          return [
            ...all,
            {
              clickedIp,
              dateSent: datetime.formatDate(new Date(click.dateSent), config.DATETIME.DEFAULT_DATE_WITH_TIME_FORMAT),
              essId: click.essId,
              contentType: click.contentType,
              mimeType: click.mimeType,
              recipients: click.recipients,
              subject: click.subject,
              url: click.url,
              userAgents
            } as GridRow
          ]
        }, [] as IncidentClick[])
        .filter((item: any) => Object.keys(item).length),
      {}
    )

    return {
      total: remediation.clickedLinksByIncident.totalCount,
      data: data.data
    }
  }, [
    dataTables.clickedLinksByIncident,
    remediation.clickedLinksByIncident.data,
    remediation.clickedLinksByIncident.totalCount
  ])

  const onExportData = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.exportAsCsv('ClickedLinks'), {
      accessTokenId,
      incidentId: remediation.incident.id,
      page: 'incidents'
    })
    setState({
      exportPath: apiRoutes.FORENSICS_GET_INCIDENT_LINK_PROTECT_CSV.path({
        accessTokenId,
        incidentId: remediation.incident.id
      })
    })
  }, [accessTokenId, remediation.incident.id])

  const onTableConfigChange = useCallback(
    (newConfig: any) => {
      dispatch(clickedLinksByIncidentTableActions.update({ config: newConfig }))
    },
    [dispatch]
  )

  return useMemo(
    () => [
      {
        columnsConfig: dataTables.clickedLinksByIncident.columnsConfig,
        exportPath: state.exportPath,
        GRID_COLUMNS: dataTables.clickedLinksByIncident.GRID_COLUMNS,
        gridData,
        isPageInProgress: isIncidentUserDetailsLoading,
        onExportData,
        pageConfig: {
          ...dataTables.clickedLinksByIncident.page,
          total: remediation.clickedLinksByIncident.totalCount,
          onPageChange: (e: any) => {
            onTableConfigChange({ page: e.page as { skip: number; take: number } })
          }
        },
        sortConfig: {
          sortable: {
            allowUnsort: false
          },
          sort: dataTables.clickedLinksByIncident.sort,
          onSortChange: (e: any) => {
            onTableConfigChange({ sort: e.sort as SortDescriptor[] })
          }
        }
      }
    ],
    [
      dataTables.clickedLinksByIncident.GRID_COLUMNS,
      dataTables.clickedLinksByIncident.columnsConfig,
      dataTables.clickedLinksByIncident.page,
      dataTables.clickedLinksByIncident.sort,
      gridData,
      onExportData,
      onTableConfigChange,
      remediation.clickedLinksByIncident.totalCount,
      isIncidentUserDetailsLoading,
      state.exportPath
    ]
  )
}
