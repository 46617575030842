import { FilterDescriptor, SortDescriptor } from '@progress/kendo-data-query'
import { createSlice } from '@reduxjs/toolkit'

import { ColumnsConfig, GridColumns } from 'global/types/dataTables/dataTables'
import { config } from 'global/lib/config'

interface AutomatedWorkflowsState {
  GRID_COLUMNS: GridColumns
  columnsConfig: ColumnsConfig
  filter: FilterDescriptor[] | undefined
  page: { skip: number; take: number }
  sort: SortDescriptor[]
}

export const DISABLED_FILTER = 'is,enabled,false'
export const DISABLED_STATE = 'DISABLE'
export const ENABLED_FILTER = 'is,enabled,true'
export const ENABLED_STATE = 'ENABLE'

export const GRID_COLUMNS = {
  ACTIONS: 'actions',
  CREATED_ON: 'createdOn',
  EDITED_BY: 'editor',
  LAST_RUN: 'lastReported',
  ACTIONS_TAKEN: 'numberOfActionsTaken',
  CONDITIONS_CHECKED: 'numberOfConditionsChecked',
  TIMES_TRIGGERED: 'numberOfTimesTriggered',
  WORKFLOW_NAME: 'name'
}

export const COLUMNS_CONFIG = {
  [GRID_COLUMNS.CREATED_ON]: {
    orderIndex: 0,
    show: true,
    sortable: true
  },
  [GRID_COLUMNS.WORKFLOW_NAME]: {
    orderIndex: 1,
    show: true,
    sortable: true
  },
  [GRID_COLUMNS.EDITED_BY]: {
    orderIndex: 2,
    show: true,
    sortable: true
  },
  [GRID_COLUMNS.TIMES_TRIGGERED]: {
    orderIndex: 3,
    show: true,
    sortable: false
  },
  [GRID_COLUMNS.CONDITIONS_CHECKED]: {
    orderIndex: 4,
    show: true,
    sortable: false
  },
  [GRID_COLUMNS.ACTIONS_TAKEN]: {
    orderIndex: 5,
    show: true,
    sortable: false
  },
  [GRID_COLUMNS.ACTIONS]: {
    orderIndex: 6,
    show: true,
    sortable: false
  }
}

// Store
export const INITIAL_STATE: AutomatedWorkflowsState = {
  GRID_COLUMNS,
  columnsConfig: COLUMNS_CONFIG,
  filter: undefined,
  page: { skip: 0, take: config.DATA_TABLE.FORENSICS.ITEMS_PER_PAGE },
  sort: [{ field: GRID_COLUMNS.CREATED_ON, dir: 'desc' }]
}

export const automatedWorkflowsSlice = createSlice({
  name: 'DATATABLE/AUTOMATED_WORKFLOWS',
  initialState: INITIAL_STATE,
  reducers: {
    update: (state, action) => {
      return {
        ...state,
        ...action.payload?.config
      }
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  }
})

export const { update, reset } = automatedWorkflowsSlice.actions

export default automatedWorkflowsSlice.reducer
