import React, { useCallback, useEffect, useMemo, useReducer } from 'react'
import { useParams } from 'react-router-dom'

import { process, SortDescriptor } from '@progress/kendo-data-query'
import { GridColumnMenuFilter, GridDetailRow } from '@progress/kendo-react-grid'

import { cloneDeep } from 'lodash'

import apiRoutes from 'fir/lib/api/apiRoutes'
import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'
import {
  forensicsGetIncidentEmails,
  forensicsGetMoreIncidentEmails,
  forensicsGetMoreIncidents,
  forensicsIncidentDeleteExistingEmails,
  forensicsResetIncidentEmails
} from 'fir/redux/features/remediation/remediationSlice'
import * as emailsByIncidentTableActions from 'fir/redux/features/dataTables/emailsByIncident/emailsByIncidentSlice'

import useEmailDetailsInterface, {
  collectRecipients
} from 'fir/components/lib/dialogs/emailDetailsDialog/useEmailDetailsInterface'

import { djangoFilters } from 'global/components/lib/grid/filter/operators'
import EmailDetails from 'global/components/lib/dialogs/emailDetailsDialog/components/emailDetails/EmailDetails'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { config } from 'global/lib/config'
import { BDSGridSortConfig } from 'global/types/dataTables/dataTables'
import * as datetime from 'global/lib/datetime'
import { useFormatMessage } from 'global/lib/localization'
import toggleInArray from 'global/lib/toggleInArray'
import { isFailed, isPending, isSuccess } from 'global/redux/toolkit/api'
import { IncidentEmail } from 'global/types/api/remediation'

import { UrlParams } from '../useIncidentDetailsLogic'

export interface EmailsByIncidentProps {
  columnMenuConfig?: {
    columns: any
    filter: any[]
  }
  columnsConfig?: any
  expandConfig?: {
    expandField: any
    onExpandChange: (e: any) => void
  }
  exportPath?: string
  filterConfig?: { onFilterChange: (e: any) => void }
  GRID_COLUMNS?: any
  gridData?: any
  isColumnActive?: (field: string) => boolean
  isDeleteDialogOpen?: boolean
  isPageInProgress?: boolean
  onDeleteExistingEmails?: () => void
  onExportData?: () => void
  pageConfig?: {
    skip: number
    take: number
    total: number
    onPageChange: (e: any) => void
  }
  showDeleteEmails?: boolean
  sortConfig?: BDSGridSortConfig
  toggleDeleteDialog?: () => void
}

export interface GridRow {
  emailId: string
  receivedDate: string
  senderEmail: string
  email: string
  displayName: string
  subject: string
  status: string
  sendingDirection: string
  hasAttachment: boolean
  isContinuousRemediation: boolean
  isViewable: boolean
}

const BASE_I18N_KEY = 'fir.app.remediation.email_by_incident'
const EXPAND_FIELD = 'expandField'

export default function useEmailByIncidentLogic(): [EmailsByIncidentProps] {
  const urlParams: UrlParams = useParams()
  const dispatch = useAppDispatch()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [emailDetailDialogConfig, emailDetailDialogActions] = useEmailDetailsInterface({})

  // Redux Toolkit stores
  const {
    accessTokenId,
    emails,
    emailsByIncident,
    isEmailInfoFailed,
    isEmailInfoLoading,
    remediation,
    isIncidentSuccess,
    isIncidentDetailsLoading,
    isIncidentEmailsSuccess,
    isIncidentEmailsLoading,
    isDeleteExistingEmailsSuccess
  } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id || '',
    emails: _stores.emails,
    emailsByIncident: _stores.dataTables.emailsByIncident,
    isEmailInfoFailed: isFailed(_stores.emails.emailInfoLoadingApiStatus),
    isEmailInfoLoading: isPending(_stores.emails.emailInfoLoadingApiStatus),
    remediation: _stores.remediation,
    isIncidentSuccess: isSuccess(_stores.remediation.getIncidentApiStatus),
    isIncidentDetailsLoading: isPending(_stores.remediation.getIncidentDetailsApiStatus),
    isIncidentEmailsSuccess: isSuccess(_stores.remediation.getIncidentEmailsApiStatus),
    isIncidentEmailsLoading: isPending(_stores.remediation.getIncidentEmailsApiStatus),
    isDeleteExistingEmailsSuccess: isSuccess(_stores.remediation.deleteExistingEmailsApiStatus)
  }))

  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    exportPath: null,
    isDeleteDialogOpen: false
  })

  // Init
  useEffect(() => {
    dispatch(
      forensicsGetIncidentEmails({ accessTokenId, config: apiParams, incidentId: urlParams.incidentId as string })
    )
    return () => {
      dispatch(forensicsResetIncidentEmails())
      dispatch(emailsByIncidentTableActions.reset())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // on sortChange or on filterChange
  useEffect(() => {
    if (!isIncidentDetailsLoading) {
      dispatch(forensicsResetIncidentEmails())
      dispatch(
        forensicsGetIncidentEmails({ accessTokenId, config: apiParams, incidentId: urlParams.incidentId as string })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailsByIncident.filter, emailsByIncident.sort, dispatch])

  // Create filter for api query
  const filters = useMemo(() => {
    return djangoFilters(emailsByIncident.filter)
  }, [emailsByIncident.filter])

  const apiParams = useMemo(() => {
    const { page, sort } = emailsByIncident

    return {
      query: {
        page: Math.abs(Number(page.skip) / Number(page.take)) + 1,
        limit: Number(page.take),
        order: `${sort[0].dir === 'asc' ? '' : '-'}${sort[0].field}`,
        filter: filters.flat()
      }
    }
  }, [emailsByIncident, filters])

  // Periodic refresh
  useEffect(() => {
    const refreshInterval = setInterval(() => {
      if (isIncidentSuccess && emailsByIncident.expandedRows.length === 0) {
        dispatch(forensicsGetMoreIncidents({ accessTokenId, config: apiParams }))
      }
    }, 30000)

    return () => {
      clearInterval(refreshInterval)
    }
  }, [accessTokenId, dispatch, apiParams, emailsByIncident.expandedRows.length, isIncidentSuccess])

  // Pagination
  useEffect(() => {
    if (isIncidentEmailsSuccess && !remediation.emailsByIncident.data[emailsByIncident.page.skip]) {
      dispatch(
        forensicsGetMoreIncidentEmails({ accessTokenId, config: apiParams, incidentId: urlParams.incidentId as string })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailsByIncident.page.skip])

  const populateGroupedItems: any = useCallback(
    (items: any[]) => {
      return items
        .filter((item: any) => Object.keys(item).length)
        .map((item: any) => {
          let expandField = !emailsByIncident.collapsedGroups.includes(`${item.field}/${item.value}`)
          if (item.emailId) {
            expandField = emailsByIncident.expandedRows.includes(item.emailId)
          }

          return {
            ...item,
            [EXPAND_FIELD]: expandField
          }
        })
    },
    [emailsByIncident.collapsedGroups, emailsByIncident.expandedRows]
  )

  const gridData = useMemo(() => {
    const { page } = emailsByIncident
    const clonedRemediationEmailsByIncidentData = cloneDeep(remediation.emailsByIncident.data)
    const data = process(
      clonedRemediationEmailsByIncidentData
        .slice(page.skip, page.skip + page.take)
        .reduce((all: IncidentEmail[], email: IncidentEmail) => {
          if (!email) {
            return [...all, {} as any]
          }
          const emailInfo = emails.emailInfoList[email.emailId]
          const emailDetails = emailInfo && {
            attachments: emailInfo.attachments,
            body: emailInfo.body.content,
            bodyMimeType: 'text/html',
            date: emailInfo.created,
            from: {
              email: emailInfo.sender.address,
              displayName: emailInfo?.sender.name
            },
            headers: emailInfo['internet-message-headers'],
            subject: emailInfo.subject,
            to: {
              email: collectRecipients(emailInfo.recipients || []),
              displayName: ''
            }
          }
          return [
            ...all,
            {
              emailId: email.emailId,
              receivedDate: datetime.formatDate(email.dateSent, config.DATETIME.DEFAULT_DATE_WITH_TIME_FORMAT),
              senderEmail: email.senderEmail,
              email: email.displayName ? `<${email.email}>` : email.email,
              displayName: email.displayName,
              subject: email.subject,
              status: email.status,
              sendingDirection: email.sendingDirection,
              hasAttachment: email.attachments && !!email.attachments.length,
              isContinuousRemediation: email.isContinuousRemediation,
              isViewable:
                email.dateSent &&
                new Date(email.dateSent) >
                  datetime
                    .luxonDate()
                    .minus({ days: 30 })
                    .toJSDate(),
              expandedRowContent: {
                emailDetailDialogConfig: emailDetails
                  ? { ...emailDetailDialogConfig, emailDetails }
                  : emailDetailDialogConfig
              }
            } as GridRow
          ]
        }, []),
      {}
    )

    return {
      total: remediation.emailsByIncident.totalCount,
      data: populateGroupedItems(data.data)
    }
  }, [
    remediation.emailsByIncident.data,
    emails.emailInfoList,
    emailsByIncident,
    emailDetailDialogConfig,
    remediation.emailsByIncident.totalCount,
    populateGroupedItems
  ])

  const isColumnActive = useCallback(
    (field: string) => {
      return GridColumnMenuFilter.active(field, emailsByIncident.filter as any)
    },
    [emailsByIncident.filter]
  )

  const onTableConfigChange = useCallback(
    (newConfig: any) => {
      dispatch(emailsByIncidentTableActions.update({ config: newConfig }))
    },
    [dispatch]
  )

  const onDeleteExistingEmails = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.DELETE_EXISTING_EMAILS, {
      accessTokenId,
      incidentId: remediation.incident.id
    })
    dispatch(forensicsIncidentDeleteExistingEmails({ accessTokenId, incidentId: remediation.incident.id }))

    setState({ isDeleteDialogOpen: false })
  }, [accessTokenId, dispatch, remediation.incident.id])

  const onExpandChange = useCallback(
    (e: any) => {
      if (e.dataItem.emailId) {
        if (
          !isEmailInfoLoading &&
          (!isEmailInfoFailed || (isEmailInfoFailed && e.dataItem.emailId !== emails.lastFailedEmailId)) &&
          !emails.emailInfoList[e.dataItem.emailId] &&
          e.value &&
          e.dataItem.isViewable
        ) {
          emailDetailDialogActions.onOpen(e.dataItem)
        }
        onTableConfigChange({
          expandedRows: toggleInArray(emailsByIncident.expandedRows, e.dataItem.emailId)
        })
      } else {
        onTableConfigChange({
          collapsedGroups: toggleInArray(emailsByIncident.collapsedGroups, `${e.dataItem.field}/${e.dataItem.value}`)
        })
      }
    },
    [
      emails.emailInfoList,
      emails.lastFailedEmailId,
      onTableConfigChange,
      emailsByIncident.expandedRows,
      emailsByIncident.collapsedGroups,
      emailDetailDialogActions,
      isEmailInfoFailed,
      isEmailInfoLoading
    ]
  )

  const onExportData = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.exportAsCsv('Emails'), {
      accessTokenId,
      incidentId: remediation.incident.id,
      page: 'incidents'
    })
    setState({
      exportPath: apiRoutes.FORENSICS_GET_INCIDENT_CSV.path({
        accessTokenId,
        incidentId: remediation.incident.id
      })
    })
  }, [accessTokenId, remediation.incident.id])

  const showDeleteEmails = useMemo(() => {
    return !(remediation.incident.remediationActions?.delete || isDeleteExistingEmailsSuccess)
  }, [remediation.incident, isDeleteExistingEmailsSuccess])

  const sortConfig: BDSGridSortConfig = useMemo(() => {
    return {
      sortable: {
        allowUnsort: false,
        mode: 'single'
      },
      sort: emailsByIncident.sort,
      onSortChange: (e: any) => {
        dispatch(
          emailsByIncidentTableActions.update({
            config: {
              sort: e.sort as SortDescriptor[],
              page: { skip: 0, take: 10 }
            }
          })
        )
      }
    } as BDSGridSortConfig
  }, [dispatch, emailsByIncident.sort])

  const toggleDeleteDialog = useCallback(() => {
    setState({ isDeleteDialogOpen: !state.isDeleteDialogOpen })
  }, [state.isDeleteDialogOpen])

  return useMemo(
    () => [
      {
        columnMenuConfig: {
          filter: emailsByIncident.filter as any,
          columns: emailsByIncident.columnsConfig
        },
        columnsConfig: emailsByIncident.columnsConfig,
        expandConfig: {
          detail: (cellProps: any) => {
            if (!cellProps.dataItem.emailId) {
              return null
            }
            if (!cellProps.dataItem.isViewable) {
              return <div>{formatMessage('email_table.old_email')}</div>
            }

            return (
              <div>
                <GridDetailRow dataItem={{}} dataIndex={0} />
                <EmailDetails
                  data={cellProps.dataItem.expandedRowContent.emailDetailDialogConfig}
                  hideReplyTo
                  isExpandRow
                  isGroupedRecipients
                  showThreatTab
                />
              </div>
            )
          },
          expandField: EXPAND_FIELD,
          onExpandChange
        },
        exportPath: state.exportPath,
        filterConfig: {
          onFilterChange: (e: any) => {
            onTableConfigChange({ filter: e.filter as any, page: { skip: 0, take: 10 } })
          }
        },
        GRID_COLUMNS: emailsByIncident.GRID_COLUMNS,
        gridData,
        isColumnActive,
        isDeleteDialogOpen: state.isDeleteDialogOpen,
        isPageInProgress: isIncidentEmailsLoading,
        onDeleteExistingEmails,
        onExportData,
        pageConfig: {
          ...emailsByIncident.page,
          total: remediation.emailsByIncident.totalCount,
          onPageChange: (e: any) => {
            onTableConfigChange({ page: e.page as { skip: number; take: number } })
          }
        },
        showDeleteEmails,
        sortConfig,
        toggleDeleteDialog
      }
    ],
    [
      emailsByIncident.GRID_COLUMNS,
      emailsByIncident.columnsConfig,
      emailsByIncident.filter,
      emailsByIncident.page,
      formatMessage,
      gridData,
      isColumnActive,
      onDeleteExistingEmails,
      onExpandChange,
      onExportData,
      onTableConfigChange,
      remediation.emailsByIncident.totalCount,
      isIncidentEmailsLoading,
      showDeleteEmails,
      sortConfig,
      state.exportPath,
      state.isDeleteDialogOpen,
      toggleDeleteDialog
    ]
  )
}
