import React from 'react'

import { Fade } from '@barracuda-internal/bds-core'

export interface TabPanelProps {
  children: JSX.Element | JSX.Element[]
  index: string | number
  value: string | number
  className?: string
}

const TabPanel: React.FC<TabPanelProps> = (props: any) => {
  const { children, value, index, className = '' } = props

  if (value === index) {
    return (
      <Fade in style={{ transitionDelay: '150ms' }}>
        <div role="tabpanel" className={className}>
          {children}
        </div>
      </Fade>
    )
  }

  return null
}

export default TabPanel
