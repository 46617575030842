/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react'

import { useAppSelector } from 'sen/redux/toolkit/hooks'
import routesConfig from 'sen/lib/routes/routesConfig'

import Incidents from 'sen/components/pages/ato/content/tabs/incidents/Incidents'
import Incident from 'sen/components/pages/ato/content/tabs/incidents/Incident'

const IncidentsBase: React.FC = () => {
  const { activePathId } = useAppSelector(_stores => ({
    activePathId: _stores.app.activePath?.id
  }))

  return useMemo(
    () => (
      <>
        {activePathId === routesConfig.ACCOUNT_TAKEOVER_INCIDENTS.id && <Incidents />}
        {[
          routesConfig.ACCOUNT_TAKEOVER_INCIDENT.id,
          routesConfig.ACCOUNT_TAKEOVER_INCIDENT_EXTERNAL_RECIPIENTS.id
        ].includes(activePathId || '') && <Incident />}
      </>
    ),
    [activePathId]
  )
}

export default IncidentsBase
