import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  container: {
    width: 500,
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  menuItems: {
    paddingLeft: theme.spacing(4),
    width: '100%'
  },
  frequencyTextField: {
    borderRadius: '50% !important'
  },
  frequencyGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  frequencyText: {
    flexBasis: '150px',
    marginLeft: '30px'
  },
  listSubheader: {
    fontWeight: 600,
    fontSize: 14,
    color: theme.colors.scaleGray900
  },
  frequencyOption: {
    flexGrow: 2
  },
  toggleButtonFrequencyText: {
    width: '105px',
    marginLeft: '30px'
  },
  weekDayButton: {
    borderRadius: '50% !important',
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginRight: '5px',
    border: 'none !important',
    fontWeight: 'normal',
    backgroundColor: theme.colors.scaleGray050,
    color: theme.colors.scaleGray600,
    '&.Mui-selected': {
      background: theme.colors.blue050,
      color: theme.colors.blue600,
      '&:hover': {
        background: theme.colors.blue050,
        color: theme.colors.blue600
      }
    }
  },
  frequencyButton: {
    width: '150px',
    border: `1px solid ${theme.palette.divider} !important`,
    color: theme.colors.scaleGray600,
    '&.Mui-selected': {
      backgroundColor: theme.colors.blue050,
      color: theme.colors.blue600,
      '&:hover': {
        background: theme.colors.blue050,
        color: theme.colors.blue600
      }
    }
  },
  helperText: {
    fontSize: '13px',
    fontWeight: 'normal'
  },
  actions: {
    alignSelf: 'flex-end',
    position: 'absolute',
    bottom: 40
  },
  saveButton: {
    marginLeft: 10
  }
}))
