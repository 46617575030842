import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient from 'global/lib/api/restClient'
import { globalApiRoutes } from 'global/lib/api/apiRoutes'

export const getConvergedMessageLogs = createAsyncThunk(
  'CONVERGED_MESSAGE_LOG/getConvergedMessageLogs',
  async function doGetConvergedMessageLog(bccId: string, { rejectWithValue }) {
    try {
      const resp = await restClient(globalApiRoutes.GET_CONVERGED_MESSAGE_LOG, {
        data: {
          bccId
        }
      })

      return resp.data
    } catch (e) {
      // TODO: set a global error state (reportSlice)?
      return rejectWithValue('error_getting_converged_message_logs')
    }
  }
)
