export enum ApiRequestStatus {
  Idle = 'idle',
  Pending = 'pending'
}
export enum ApiResponseStatus {
  Success = 'success',
  Failed = 'failed'
}
export type ApiResponseError = string | undefined
export type ApiStatus = [ApiRequestStatus, ApiResponseStatus?, ApiResponseError?]

export const inIdle: ApiStatus = [ApiRequestStatus.Idle]
export const inProgress: ApiStatus = [ApiRequestStatus.Pending]
export const successResponse: ApiStatus = [ApiRequestStatus.Idle, ApiResponseStatus.Success]
export const failedResponse = (error: ApiResponseError): ApiStatus => {
  return [ApiRequestStatus.Idle, ApiResponseStatus.Failed, error]
}

export function isIdle(apiStatus: ApiStatus) {
  return apiStatus?.[0] === ApiRequestStatus.Idle
}

export function isPending(apiStatus: ApiStatus) {
  return apiStatus?.[0] === ApiRequestStatus.Pending
}

export function isSuccess(apiStatus: ApiStatus) {
  return apiStatus?.[1] === ApiResponseStatus.Success
}

export function isFailed(apiStatus: ApiStatus) {
  return apiStatus?.[1] === ApiResponseStatus.Failed
}

export function getErrorMessage(apiStatus: ApiStatus) {
  return apiStatus?.[2]
}
