import { cloneDeep } from 'lodash'
import React, { useCallback, useEffect, useMemo, useReducer } from 'react'
import { useLocation } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import queryString from 'query-string'

import colors from '@barracuda-internal/bds-core/dist/styles/colors'
import { process } from '@progress/kendo-data-query'

import { isFailed, isPending, isSuccess } from 'global/redux/toolkit/api'
import { setCurrentAccessToken } from 'global/redux/features/accessToken/accessTokenSlice'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { config } from 'global/lib/config'
import * as datetime from 'global/lib/datetime'
import { parseAddress } from 'global/lib/email/email'
import useAccessTokenLib from 'global/lib/accessToken/useAccessToken'
import useProductLib from 'global/lib/product/useProduct'
import { useFormatMessage } from 'global/lib/localization'

import { IncidentDetailsSource } from 'global/types/api/newIncident'
import { Incident, IncidentTag } from 'global/types/api/remediation'

import routesConfig from 'fir/lib/routes/routesConfig'
import * as incidentsTableActions from 'fir/redux/features/dataTables/incidents/incidentsSlice'
import {
  forensicsGetIncidents,
  forensicsGetMoreIncidents,
  forensicsResetIncidents,
  resetIncidentsFailures
} from 'fir/redux/features/remediation/remediationSlice'
import {
  getRemediationEmailThreatsStats,
  getRemediationIncidentsStats,
  getRemediationTopUsersStats,
  resetRemediationStatFailures
} from 'fir/redux/features/stats/statsSlice'
import { REMEDIATION_CTA_COOKIE } from 'fir/components/lib/ctaBanner/useCTABannerLogic'
import { NewIncidentDialogProps } from 'fir/components/lib/newIncidentDialog/NewIncidentDialog'
import { STEP_TITLES } from 'fir/components/lib/newIncidentDialog/useNewIncidentDialogLogic'
import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'

import { TASK_STATUS } from 'fir/redux/types/Remediation'
import { EmailThreatsStat, IncidentsStat, TopUserStat } from 'fir/redux/types/Stats'

export interface GridRow {
  attachmentName?: string
  createdBy?: string
  createdByName?: string
  senderEmail?: string
  senderName?: string
  subject?: string
  continuousRemediation: boolean
  createdOn: string
  incidentId: string
  messagesReceived: number
  pendingCreate: boolean
}

const BASE_I18N_KEY = 'fir.app.remediation'
const NEW_INCIDENT_QUERY_PARAMS = ['country', 'email', 'source', 'subject']

export default function remediationLogic(WrappedComponent: React.ComponentType<any>) {
  const RemediationLogic: React.FC<any> = props => {
    const [cookies] = useCookies([REMEDIATION_CTA_COOKIE])
    const dispatch = useAppDispatch()
    const { accessTokenId, incidentsTable, remediation, stats } = useAppSelector(_stores => ({
      accessTokenId: _stores.accessToken.accessToken?.id || '',
      incidentsTable: _stores.dataTables.incidents,
      remediation: _stores.remediation,
      stats: _stores.stats
    }))
    const {
      isIncidentsLoading,
      isIncidentsSuccess,
      isIncidentsFailed,
      isMoreIncidentsLoading,
      isMoreIncidentsFailed,
      isEmailThreatsStatsFailed,
      isEmailThreatsStatsLoading,
      isIncidentsStatsFailed,
      isIncidentsStatsLoading,
      isTopUsersFailed,
      isTopUsersLoading
    } = {
      isIncidentsLoading: isPending(remediation.getIncidentsApiStatus),
      isIncidentsSuccess: isSuccess(remediation.getIncidentsApiStatus),
      isIncidentsFailed: isFailed(remediation.getIncidentsApiStatus),
      isMoreIncidentsLoading: isPending(remediation.getMoreIncidentsApiStatus),
      isMoreIncidentsFailed: isFailed(remediation.getMoreIncidentsApiStatus),
      isIncidentsStatsFailed: isFailed(stats.incidentsStatsApiStatus),
      isIncidentsStatsLoading: isPending(stats.incidentsStatsApiStatus),
      isEmailThreatsStatsFailed: isFailed(stats.emailThreatsStatsApiStatus),
      isEmailThreatsStatsLoading: isPending(stats.emailThreatsStatsApiStatus),
      isTopUsersFailed: isFailed(stats.topUsersApiStatus),
      isTopUsersLoading: isPending(stats.topUsersApiStatus)
    }
    const formatMessage = useFormatMessage(BASE_I18N_KEY)
    const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
      newIncidentDialog: null,
      newIncidentSearchCriteria: null
    })
    const [accessTokenLib] = useAccessTokenLib()
    const [productLib] = useProductLib()
    const location = useLocation()

    // Init
    useEffect(() => {
      let loadRemediation = true

      /* eslint-disable react/prop-types, @typescript-eslint/no-non-null-assertion */
      if (location && location.search) {
        let newCriteria = { sender: {} }
        const searchParams = queryString.parse(location.search)

        // new-incident link bypasses report logic
        // need to check if access token has FIR, is onprem, and user has FIR entitlements
        const checkAccessToken = searchParams.accessToken || accessTokenId
        dispatch(setCurrentAccessToken(checkAccessToken as string))
        if (productLib.hasMissingRequirement(checkAccessToken)) {
          loadRemediation = false
          routesConfig.MISSING_REQUIREMENTS.goto({ type: 'o365' })
        } else if (!accessTokenLib.hasForensicsEntitlement(checkAccessToken)) {
          loadRemediation = false
          routesConfig.MISSING_REQUIREMENTS.goto({ type: 'entitlements' })
        } else if (!productLib.hasForensicsProduct(checkAccessToken)) {
          loadRemediation = false
          routesConfig.START_TRIAL.goto()
        }

        if (loadRemediation) {
          NEW_INCIDENT_QUERY_PARAMS.forEach((param: string) => {
            if (searchParams[param]) {
              // need to handle email differently
              if (param === 'email') {
                newCriteria = {
                  ...newCriteria,
                  sender: { address: parseAddress(searchParams[param]) || searchParams[param] }
                }
              } else {
                newCriteria = { ...newCriteria, [param]: searchParams[param] }
              }
            }
          })

          if (newCriteria) {
            openNewIncidentDialog(newCriteria)
          }
        }
      }
      /* eslint-enable react/prop-types, @typescript-eslint/no-non-null-assertion */

      if (loadRemediation) {
        dispatch(forensicsGetIncidents({ config: apiParams, accessTokenId }))
        dispatch(getRemediationIncidentsStats({ accessTokenId }))
        dispatch(getRemediationEmailThreatsStats({ accessTokenId }))
        dispatch(getRemediationTopUsersStats({ accessTokenId }))

        analyticsLib.trackAppEvent(analyticsLib.EVENTS.VIEW_INCIDENTS_PAGE, {
          accessTokenId
        })
      }

      return () => {
        dispatch(forensicsResetIncidents())
        dispatch(incidentsTableActions.reset())
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const apiParams = useMemo(() => {
      const { page } = incidentsTable

      return {
        query: {
          page: Math.abs(Number(page.skip) / Number(page.take)) + 1,
          limit: Number(page.take)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [incidentsTable])

    // Periodic refresh
    useEffect(() => {
      const refreshInterval = setInterval(() => {
        if (isIncidentsSuccess && !isIncidentsLoading && incidentsTable.page.skip === 0) {
          dispatch(forensicsGetMoreIncidents({ accessTokenId, config: apiParams }))
        }
      }, 30000)

      return () => {
        clearInterval(refreshInterval)
      }
    }, [accessTokenId, apiParams, dispatch, incidentsTable.page.skip, isIncidentsSuccess, isIncidentsLoading])

    // Pagination
    useEffect(() => {
      if (isIncidentsSuccess && !remediation.incidents.data[incidentsTable.page.skip]) {
        dispatch(forensicsGetMoreIncidents({ accessTokenId, config: apiParams }))
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [incidentsTable.page.skip])

    const basicIncidentResponse = useMemo(() => {
      return productLib.getForensicsSerialBundleForAccessToken(accessTokenId) === config.BUNDLES.BUNDLE1
    }, [accessTokenId, productLib])

    const gridData = useMemo(() => {
      const { page } = incidentsTable
      const remediationIncidentsData = cloneDeep(remediation.incidents.data)
      const data = process(
        remediationIncidentsData
          .slice(page.skip, page.skip + page.take)
          .reduce((all: Incident[], incident: Incident) => {
            if (!incident) {
              return [...all, {} as any]
            }
            return [
              ...all,
              {
                attachmentName: incident.attachmentName,
                continuousRemediation:
                  incident.continuousRemediationUntil && new Date(incident.continuousRemediationUntil) > new Date(),
                createdBy: incident.createdBy,
                createdByName: incident.createdByName,
                createdOn: datetime.formatDate(incident.created, config.DATETIME.DEFAULT_DATE_WITH_TIME_FORMAT),
                incidentId: incident.id,
                messagesReceived: incident.internalAttackCount,
                pendingCreate:
                  incident.taskStatuses && incident.taskStatuses.incidentCreate === TASK_STATUS.IN_PROGRESS,
                senderEmail: incident.sender && incident.sender.email,
                senderName: incident.sender && incident.sender.displayName,
                subject: incident.subject,
                tags: incident.labels?.map((tag: IncidentTag) => tag.name) || [],
                bodyText: incident.bodyText,
                bodyLinks: incident.bodyLinks
              } as GridRow
            ]
          }, [])
          .filter((item: any) => Object.keys(item).length),
        {}
      )

      return {
        total: remediation.incidents.totalCount,
        data: data.data
      }
    }, [incidentsTable, remediation.incidents.data, remediation.incidents.totalCount])

    const gotoIncident = useCallback(
      dataItem => {
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.VIEW_INCIDENT, {
          accessTokenId,
          incidentId: dataItem.incidentId,
          page: 'incidents',
          senderEmail: dataItem.senderEmail,
          subject: dataItem.subject,
          attachmentName: dataItem.attachmentName
        })
        routesConfig.REMEDIATION_INCIDENT.goto({ incidentId: dataItem.incidentId })
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [remediation.incidents]
    )

    const onTableConfigChange = useCallback(
      (newConfig: any) => {
        dispatch(incidentsTableActions.update({ config: newConfig }))
      },
      [dispatch]
    )

    const incidentsTableConfig = useMemo(() => {
      return {
        isPageInProgress: isIncidentsLoading,
        isMoreResultsLoading: isMoreIncidentsLoading,
        gridData,
        columnMenuConfig: {
          filter: null,
          columns: incidentsTable.columnsConfig
        },
        columnsConfig: incidentsTable.columnsConfig,
        pageConfig: {
          ...incidentsTable.page,
          total: remediation.incidents.totalCount,
          onPageChange: (e: any) => {
            onTableConfigChange({ page: e.page as { skip: number; take: number } })
          }
        },
        GRID_COLUMNS: incidentsTable.GRID_COLUMNS,
        gotoIncident
      }
    }, [
      isIncidentsLoading,
      isMoreIncidentsLoading,
      remediation.incidents.totalCount,
      gridData,
      incidentsTable.columnsConfig,
      incidentsTable.page,
      incidentsTable.GRID_COLUMNS,
      gotoIncident,
      onTableConfigChange
    ])

    const incidentsCreatedDashboardConfig = useMemo(() => {
      // Need to deep clone the store data, Kendo is attempting to add _date_date attribute to the object
      const clonedIncidentsStats = cloneDeep(stats.incidentsStats)
      const chartData = clonedIncidentsStats.map((stat: IncidentsStat) => stat.count || 0)

      return {
        chartConfig: {
          chartCategoryAxisItemProps: {
            baseUnit: 'months',
            labels: {
              color: colors.humpbackGray,
              font: '400 12px Roboto, sans-serif',
              format: 'MMMM'
            },
            majorGridLines: { visible: false }
          },
          chartData: clonedIncidentsStats,
          chartSeriesItemProps: {
            categoryField: 'date',
            color: colors.coralOrange,
            field: 'count',
            line: { color: 'red', width: 2 },
            opacity: 0.3
          },
          chartSeriesItemTooltipProps: {
            color: 'red',
            formatTitle: (category: Date) =>
              `${category.toLocaleDateString(undefined, { month: 'long' })} ${category.getFullYear()}`,
            text: formatMessage('dashboards.createdIncidents.tooltip')
          },
          chartStyle: { height: 258 },
          chartValueAxisItemProps: {
            labels: {
              color: colors.humpbackGray,
              font: '600 12px Roboto, sans-serif',
              step: 2
            },
            majorGridLines: { step: 2 },
            min: 0
          },
          showChartSeriesItemTooltip: true
        },
        chartWrapperConfig: {
          chartData: clonedIncidentsStats,
          chartHighlight: {
            description: formatMessage('dashboards.createdIncidents.highlight'),
            value: clonedIncidentsStats.length && chartData.reduce((a: number, b: number) => a + b)
          },
          chartLoading: isIncidentsStatsLoading,
          chartTitle: formatMessage('dashboards.createdIncidents.title'),
          noDataText: formatMessage('dashboards.createdIncidents.noData')
        }
      }
    }, [formatMessage, stats.incidentsStats, isIncidentsStatsLoading])

    const pageAlertConfig = useMemo(() => {
      return {
        alertContent: formatMessage('labels.page_error'),
        closeAction: () => {
          if (isIncidentsFailed || isMoreIncidentsFailed) {
            dispatch(resetIncidentsFailures())
          }
          if (isEmailThreatsStatsFailed || isIncidentsStatsFailed || isTopUsersFailed) {
            dispatch(resetRemediationStatFailures())
          }
        },
        pageAlert: true,
        showClose: true
      }
    }, [
      dispatch,
      formatMessage,
      isIncidentsFailed,
      isMoreIncidentsFailed,
      isEmailThreatsStatsFailed,
      isIncidentsStatsFailed,
      isTopUsersFailed
    ])

    const remediatedThreatsDashboardConfig = useMemo(() => {
      // Need to deep clone the store data, Kendo is attempting to add _date_date attribute to the object
      const clonedRemediationThreatsStats = cloneDeep(stats.emailThreatsStats)
      const chartData = clonedRemediationThreatsStats.map((stat: EmailThreatsStat) => stat.total || 0)

      return {
        chartConfig: {
          chartCategoryAxisItemProps: {
            baseUnit: 'months',
            labels: {
              color: colors.humpbackGray,
              font: '400 12px Roboto, sans-serif',
              format: 'MMMM'
            },
            majorGridLines: { visible: false }
          },
          chartData: clonedRemediationThreatsStats,
          chartSeriesItemProps: {
            categoryField: 'date',
            color: colors.skyBlue,
            field: 'total'
          },
          chartSeriesItemTooltipProps: {
            color: 'blue',
            formatTitle: (category: Date) =>
              `${category.toLocaleDateString(undefined, { month: 'long' })} ${category.getFullYear()}`,
            text: formatMessage('dashboards.threatsRemediated.tooltip')
          },
          chartStyle: { height: 258 },
          chartValueAxisItemProps: {
            labels: {
              color: colors.humpbackGray,
              font: '600 12px Roboto, sans-serif',
              step: 2
            },
            majorGridLines: { step: 2 },
            min: 0,
            minorGridLines: { visible: false }
          },
          showChartSeriesItemTooltip: true
        },
        chartWrapperConfig: {
          chartData: clonedRemediationThreatsStats,
          chartHighlight: {
            description: formatMessage('dashboards.threatsRemediated.highlight'),
            value: clonedRemediationThreatsStats.length && chartData.reduce((a: number, b: number) => a + b)
          },
          chartLoading: isEmailThreatsStatsLoading,
          chartTitle: formatMessage('dashboards.threatsRemediated.title'),
          noDataText: formatMessage('dashboards.threatsRemediated.noData')
        }
      }
    }, [formatMessage, isEmailThreatsStatsLoading, stats.emailThreatsStats])

    const topUsersDashboardConfig = useMemo(() => {
      const chartData = {} as any
      stats.topUsersStats.forEach((stat: TopUserStat) => {
        if (!(stat.recipientEmail in chartData)) {
          chartData[stat.recipientEmail] = { ...stat }
        } else {
          chartData[stat.recipientEmail].total += stat.total
        }
      })

      // Used to skip duplicate valueAxis labels if datapoint is less than 5
      const labelSkip = stats.topUsersStats.filter((topUserStat: { total: number }) => topUserStat.total >= 5)

      return {
        chartConfig: {
          chartCategoryAxisItemProps: {
            labels: {
              color: colors.humpbackGray,
              content: (e: { value: string }) => (e.value.length > 20 ? `${e.value.substring(0, 20)}...` : e.value),
              font: '400 14px Roboto, sans-serif'
            },
            majorGridLines: { visible: false }
          },
          chartData: Object.values(chartData).slice(
            0,
            Object.values(chartData).length < 5 ? Object.values(chartData).length : 5
          ),
          chartSeriesItemProps: {
            categoryField: 'recipientEmail',
            color: colors.coralOrange,
            field: 'total',
            line: { color: colors.coralOrange, width: 2 },
            gap: Object.values(chartData).length === 1 ? 10 : undefined,
            type: 'bar'
          },
          chartSeriesItemTooltipProps: {
            formatTitle: (category: string) => category,
            text: formatMessage('dashboards.topUsers.tooltip')
          },
          showChartSeriesItemTooltip: true,
          chartStyle: { height: 250 },
          chartValueAxisItemProps: {
            labels: {
              format: 'MMMM',
              font: '600 12px Roboto, sans-serif',
              color: colors.humpbackGray,
              step: labelSkip.length > 0 ? 0 : 5
            }
          }
        },
        chartWrapperConfig: {
          chartData: Object.values(chartData).slice(
            0,
            Object.values(chartData).length < 5 ? Object.values(chartData).length : 5
          ),
          chartLoading: isTopUsersLoading,
          chartSubtitle: formatMessage('dashboards.topUsers.subtitle'),
          chartTitle: formatMessage('dashboards.topUsers.title'),
          noDataText: formatMessage('dashboards.topUsers.noData')
        }
      }
    }, [stats.topUsersStats, isTopUsersLoading, formatMessage])

    const openNewIncidentDialog = useCallback(
      (searchCriteria: any = {}) => {
        if (window.location.toString().includes('new-incident')) {
          analyticsLib.trackAppEvent(analyticsLib.EVENTS.INCIDENT_WIZARD_NEW_INCIDENT_STARTED, {
            accessTokenId,
            url: window.location.href,
            page: 'remediation/new',
            source: searchCriteria.source
              ? IncidentDetailsSource[searchCriteria.source as keyof typeof IncidentDetailsSource]
              : IncidentDetailsSource.ess
          })
        } else {
          analyticsLib.trackAppEvent(analyticsLib.EVENTS.INCIDENT_WIZARD_NEW_INCIDENT_STARTED, {
            accessTokenId,
            url: window.location.href,
            page: 'incidents',
            source: IncidentDetailsSource.incident
          })
        }
        setState({ newIncidentDialog: true, newIncidentSearchCriteria: searchCriteria })
      },
      [accessTokenId]
    )

    // we need to track the step that the wizard was closed
    const closeNewIncidentDialog = useCallback(
      (activeStep: number) => {
        // Conditional to prevent two mixpanel events from firing when clicking next on step 4
        if (activeStep !== 4) {
          analyticsLib.trackAppEvent(analyticsLib.EVENTS.incidentWizardCancel(STEP_TITLES[activeStep]), {
            step: STEP_TITLES[activeStep],
            accessTokenId
          })
        }
        dispatch(forensicsGetMoreIncidents({ accessTokenId, config: apiParams, resetResults: true }))
        setState({ newIncidentDialog: null, newIncidentSearchCriteria: null })
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [accessTokenId]
    )

    const showIncidentsPageError = useMemo(() => {
      return (
        isIncidentsFailed ||
        isMoreIncidentsFailed ||
        isEmailThreatsStatsFailed ||
        isIncidentsStatsFailed ||
        isTopUsersFailed
      )
    }, [isIncidentsFailed, isMoreIncidentsFailed, isEmailThreatsStatsFailed, isIncidentsStatsFailed, isTopUsersFailed])

    return (
      <WrappedComponent
        {...(props as any)}
        basicIncidentResponse={basicIncidentResponse}
        hideCTA={cookies[REMEDIATION_CTA_COOKIE] === 'true'}
        incidentTableConfig={incidentsTableConfig}
        incidentsCreatedDashboardConfig={incidentsCreatedDashboardConfig}
        newIncidentDialogConfig={
          {
            incidentDetailsSource: IncidentDetailsSource.incident,
            isOpened: !!state.newIncidentDialog,
            ...(state.newIncidentSearchCriteria &&
              Object.entries(state.newIncidentSearchCriteria).length && {
                emailInfo: state.newIncidentSearchCriteria
              }),
            onClose: closeNewIncidentDialog
          } as NewIncidentDialogProps
        }
        openNewIncidentDialog={openNewIncidentDialog}
        pageAlertConfig={pageAlertConfig}
        remediatedThreatsDashboardConfig={remediatedThreatsDashboardConfig}
        showIncidentsPageError={showIncidentsPageError}
        topUsersDashboardConfig={topUsersDashboardConfig}
      />
    )
  }

  return RemediationLogic
}
