import React, { useEffect, useMemo, useState } from 'react'
import { isNil } from 'lodash'
import { ExpandMore, ExpandLess } from '@material-ui/icons'

import { Collapse, Grid, List, ListItem, Typography } from '@barracuda-internal/bds-core'

import SearchField from 'global/components/lib/searchField/SearchField'

import styles from './sideMenuStyles'

export interface Dropdown {
  id: string
  count?: number
  content: React.ReactNode
  label: string
}

export interface SideMenuProps {
  dropdownItems: Dropdown[]
  onSearchChange: (filterString: string) => void
  searchPlaceholder?: string
  subtitle?: string
  title: string
}

const SideMenu: React.FC<SideMenuProps> = ({ dropdownItems, onSearchChange, searchPlaceholder, subtitle, title }) => {
  const classes = styles()
  const [filterValue, setFilterValue] = useState<string>('')
  const [openDropdowns, setOpenDropdowns] = useState<{ [key: string]: boolean }>(
    dropdownItems.reduce((acc, { id }) => ({ ...acc, [id]: true }), {}) as any
  )

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value)
  }

  const handleDropdownClick = (id: string) => {
    setOpenDropdowns((prevState: any) => ({ ...prevState, [id]: !prevState[id] }))
  }

  useEffect(() => {
    onSearchChange(filterValue)
  }, [filterValue, onSearchChange])

  return useMemo(
    () => (
      <Grid className={classes.sidemenuContainer} container direction="column">
        <Grid item>
          <SearchField
            value={filterValue}
            onChange={handleFilterChange}
            searchContainerClassName={classes.searchContainer}
            placeholder={searchPlaceholder || 'Search'}
          />
        </Grid>
        <Grid item>
          {dropdownItems.map(({ id, label, count, content }) => (
            <List className={classes.list} disablePadding key={id}>
              <ListItem onClick={() => handleDropdownClick(id)} disableGutters>
                <Grid container direction="row">
                  <Typography className={classes.listLabel} variant="body1">
                    {label}
                  </Typography>
                  {!isNil(count) && (
                    <Typography className={classes.listLabelCount} color="secondary" variant="body2">
                      &nbsp;({count})
                    </Typography>
                  )}
                </Grid>
                {openDropdowns[id] ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openDropdowns[id]} timeout="auto" unmountOnExit>
                {content}
              </Collapse>
            </List>
          ))}
        </Grid>
      </Grid>
    ),
    [
      classes.list,
      classes.listLabel,
      classes.listLabelCount,
      classes.searchContainer,
      classes.sidemenuContainer,
      dropdownItems,
      filterValue,
      openDropdowns,
      searchPlaceholder
    ]
  )
}

export default SideMenu
