import { useMemo, useCallback, useReducer } from 'react'

import { process } from '@progress/kendo-data-query'

import { SearchFieldProps, useSearchFieldLogic } from 'global/components/lib/searchField/SearchField'
import { BDSGridPagerConfig, BDSGridSortConfig } from 'global/types/dataTables/dataTables'
import * as dmarcService from 'global/lib/domain/dmarc'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'
import { isPending } from 'global/redux/toolkit/api'
import { ImageKeys } from 'global/configs/theme/images.config'

import { update as updateDomainsTable } from 'ets/redux/features/dataTables/domains/domainSlice'
import { softUpdateReportCounts, softUpdateReportData } from 'ets/redux/features/reports/domains/domainsSlice'
import sortDomains from 'ets/lib/sortDomains'
import { IsUserInputDisabledForTable } from 'ets/components/pages/dashboard/isUserInputDisabledForTableType'
import { Domain, DomainReport } from 'ets/types/domainTypes'

import {
  TABLE_CELL_HEIGHT,
  TABLE_HEADER_HEIGHT,
  TABLE_FOOTER_HEIGHT
} from 'ets/components/pages/dashboard/domains/dashboardDomainsStyles'
import { useAppDispatch, useAppSelector } from 'ets/redux/toolkit/hooks'
import { ColumnsConfig } from 'ets/redux/types/dataTables'

const SEARCH_FIELD_ID = 'domains-search'

export interface ModifiedDomain extends Domain {
  policy: string
  dmarcImage: ImageKeys
  seeDetails: (dataItem: ModifiedDomain) => void
}

export interface DashboardDomainsLogic {
  isReportLoaded: boolean
  inProgress: boolean
  tableTotal: number | string
  searchFieldConfig: SearchFieldProps
  GRID_COLUMNS: {
    [key: string]: string
  }
  columnsConfig: ColumnsConfig
  tableData: {
    total: number
    data: ModifiedDomain[]
  }
  pageConfig: BDSGridPagerConfig
  sortConfig: BDSGridSortConfig | {}
  highlightKeywords: string[]
  isDmarcDialogVisible: boolean
  onCloseDmarcDialog: () => void
  dmarcEntry: string | undefined
  dmarcPolicy: string | undefined
  isDmarcPolicyValid: boolean
}

export interface DashboardDomainsLogicProps {
  isUserInputDisabledForTable: IsUserInputDisabledForTable
  fixTableHeight: (tableId: string, newHeight: number) => void
}

export interface State {
  searchString: string
  dmarcEntry: string | undefined
  dmarcPolicy: string | undefined
  isDmarcPolicyValid: boolean
}

export default function useDashboardDomainsLogic({
  isUserInputDisabledForTable,
  fixTableHeight
}: DashboardDomainsLogicProps): [DashboardDomainsLogic] {
  const dispatch = useAppDispatch()
  const { inProgress, reportData, originalReportData, domainsTable, isReportLoaded, accessTokenId } = useAppSelector(
    _stores => ({
      inProgress: isPending(_stores.reports.domains.apiStatus),
      reportData: _stores.reports.domains.data,
      originalReportData: _stores.reports.domains.originalData,
      isReportLoaded: !!_stores.reports.domains?.data?.accessTokenId,
      domainsTable: _stores.dataTables.domains,
      accessTokenId: _stores.accessToken.accessToken?.id
    })
  )
  const [state, setState] = useReducer((_state: State, newState: Partial<State>) => ({ ..._state, ...newState }), {
    searchString: '',
    dmarcEntry: undefined,
    dmarcPolicy: undefined,
    isDmarcPolicyValid: false
  })
  const [isDmarcDialogOpened, toggleDmarcDialog] = useDialogLogic()

  const onCloseDmarcDialog = useCallback(() => {
    toggleDmarcDialog()
    setState({ dmarcEntry: undefined, isDmarcPolicyValid: false, dmarcPolicy: undefined })
  }, [toggleDmarcDialog])

  const isUserInputDisabled = useMemo(() => {
    if (!reportData) {
      return true
    }

    return isUserInputDisabledForTable(domainsTable, reportData, isReportLoaded) || inProgress
  }, [reportData, isReportLoaded, domainsTable, isUserInputDisabledForTable, inProgress])

  const [debouncedOnChange, validateSearchString] = useSearchFieldLogic(
    (searchString: string, originReportData: DomainReport) => {
      const search = searchString.trim()
      dispatch(updateDomainsTable({ search }))
      setState({ searchString: search })
      const newData = search.length
        ? originReportData.report.data.filter((data: { name: string }) => data.name.includes(search))
        : originReportData.report.data

      dispatch(softUpdateReportCounts(newData.length))
      dispatch(softUpdateReportData(newData))
    },
    inProgress,
    SEARCH_FIELD_ID
  )

  const searchFieldConfig = useMemo(() => {
    return {
      value: state.searchString,
      onChange: (e: React.FormEvent<HTMLInputElement>) => {
        const validatedSearchString = validateSearchString(e.currentTarget.value)
        if (domainsTable.search !== validatedSearchString) {
          debouncedOnChange(validatedSearchString, originalReportData)
          setState({ searchString: validatedSearchString })
        }
      },
      disabled: isUserInputDisabled
    }
  }, [state, isUserInputDisabled, domainsTable.search, originalReportData, validateSearchString, debouncedOnChange])

  const tableTotal = useMemo(() => {
    return isReportLoaded ? reportData?.report.totalCount : domainsTable.DEMO_DATA.ALL
  }, [reportData, isReportLoaded, domainsTable])

  const tableLabelTotal = useMemo(() => {
    if (isReportLoaded && domainsTable.skip === 0 && inProgress) {
      return '-'
    }

    return tableTotal
  }, [isReportLoaded, domainsTable.skip, inProgress, tableTotal])

  const tableData = useMemo(() => {
    const { skip, take } = domainsTable

    const { data } = process(
      (reportData?.report.data || []).map((report: Domain) => {
        const [dmarcState] = report.dmarcState

        return {
          ...(report && {
            ...report,
            policy: report.dmarc.policy,
            dmarcImage: dmarcService.DMARC_STATE_IMAGES[dmarcState],
            seeDetails: (dataItem: Domain) => {
              analyticsLib.trackAppEvent(analyticsLib.EVENTS.VIEW_DMARC_DETAILS, { accessTokenId })
              toggleDmarcDialog()
              setState({
                dmarcEntry: dataItem.dmarc.recordExists ? dataItem.dmarc.entry : undefined,
                dmarcPolicy: dataItem.dmarc.recordExists ? dataItem.dmarc.policy : undefined,
                isDmarcPolicyValid: dmarcService.ALL_POLICIES.includes(dataItem.dmarc.policy)
              })
            }
          })
        }
      }),
      { skip, take }
    )

    const fixedCount = Math.max(reportData?.report.totalCount || 0, 4)
    const newHeight =
      fixedCount <= domainsTable.ITEMS_PER_PAGE
        ? fixedCount * TABLE_CELL_HEIGHT + TABLE_HEADER_HEIGHT
        : TABLE_CELL_HEIGHT * domainsTable.ITEMS_PER_PAGE + TABLE_HEADER_HEIGHT + TABLE_FOOTER_HEIGHT

    fixTableHeight('domains', newHeight)

    return {
      data: data.filter(report => report.name),
      total: tableTotal || 0
    }
  }, [reportData, domainsTable, tableTotal, fixTableHeight, accessTokenId, toggleDmarcDialog])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = domainsTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: tableData.total || 0,
      onPageChange: e => {
        dispatch(updateDomainsTable(e.page))
      }
    }
  }, [domainsTable, tableData, dispatch])

  const sortConfig: BDSGridSortConfig | {} = useMemo(() => {
    if (!tableData.total) {
      return {}
    }

    return {
      sortable: {
        allowUnsort: false,
        mode: 'single'
      },
      sort: domainsTable.sort,
      onSortChange: e => {
        dispatch(updateDomainsTable({ sort: e.sort, skip: 0 }))
        dispatch(softUpdateReportData(sortDomains([...(reportData?.report.data || [])])))
      }
    }
  }, [domainsTable, dispatch, reportData, tableData.total])

  return useMemo(
    () => [
      {
        isReportLoaded,
        tableTotal: tableLabelTotal || 0,
        searchFieldConfig,
        GRID_COLUMNS: domainsTable.GRID_COLUMNS,
        columnsConfig: domainsTable.columnsConfig,
        tableData,
        pageConfig,
        sortConfig,
        inProgress,
        highlightKeywords: [domainsTable.search],
        isDmarcDialogVisible: isDmarcDialogOpened,
        onCloseDmarcDialog,
        dmarcEntry: state.dmarcEntry,
        dmarcPolicy: state.dmarcPolicy,
        isDmarcPolicyValid: state.isDmarcPolicyValid
      }
    ],
    [
      isReportLoaded,
      tableLabelTotal,
      searchFieldConfig,
      domainsTable,
      tableData,
      pageConfig,
      sortConfig,
      inProgress,
      isDmarcDialogOpened,
      onCloseDmarcDialog,
      state
    ]
  )
}
