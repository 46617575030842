export default {
  values: {
    sm1: 320,
    sm2: 375,
    sm3: 540,
    md1: 768,
    md2: 960,
    md3: 1024,
    lg1: 1200,
    lg2: 1500
  }
}
