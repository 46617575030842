import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from 'ets/configs/styles/defaults'

export const PANEL_HEIGHT = 364
export const CHART_AREA_HEIGHT = 136
export const CHART_AREA_BIGGER_HEIGHT = 209

export default makeStyles(theme => ({
  panel: {
    height: PANEL_HEIGHT
  },
  panelCard: {
    position: 'relative',
    padding: theme.spacing(2.5),
    width: '100%',
    height: '100%'
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: 30,
    fontWeight: 'bold',
    letterSpacing: '0.2px',
    lineHeight: '35px',
    cursor: 'default'
  },
  details: {
    fontSize: 14,
    color: '#0088CE',
    letterSpacing: 0,
    lineHeight: '35px',
    textAlign: 'center',
    cursor: 'default'
  },
  arrowDown: {
    verticalAlign: -6
  },
  description: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.primary,
    fontSize: 16,
    letterSpacing: 0,
    lineHeight: '22px',
    cursor: 'default'
  },
  total: {
    marginTop: 12,
    color: COLORS.RED,
    fontSize: 48,
    fontWeight: 'bold',
    letterSpacing: 1.03,
    lineHeight: '57px',
    cursor: 'default'
  },
  content: {
    position: 'relative'
  },
  spacer: {
    width: '100%',
    height: 20
  },
  chartArea: {
    marginTop: 12,
    height: CHART_AREA_HEIGHT,

    '& .k-chart ::selection': {
      backgroundColor: `${COLORS.SELECTION.BG_COLOR} !important`,
      color: `${COLORS.SELECTION.COLOR} !important`
    }
  },
  biggerChartArea: {
    marginTop: 12,
    height: CHART_AREA_BIGGER_HEIGHT
  }
}))
