/* eslint-disable no-param-reassign */
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { process } from '@progress/kendo-data-query'
import { cloneDeep } from 'lodash'

import {
  forensicsGetIncidentDetails,
  forensicsResetIncidentDetails
} from 'fir/redux/features/remediation/remediationSlice'
import * as threatDetailsByIncidentTableActions from 'fir/redux/features/dataTables/threatDetailsByIncident/threatDetailsByIncidentSlice'
import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'

import { isPending } from 'global/redux/toolkit/api'
import { IncidentDetails, Threat } from 'global/types/api/remediation'

import { UrlParams } from '../useIncidentDetailsLogic'

export interface GridRow {
  extra: string
  path: string
  type: string
  vector: string
}

export type ThreatDetailsByIncidentLogic = {
  isPageInProgress: boolean
  gridData: Threat[]
  columnsConfig: {
    [key: string]: {
      orderIndex: number
      show: boolean
    }
  }
  GRID_COLUMNS: { [key: string]: string }
}

export default function useThreatDetailsByIncidentLogic(): [ThreatDetailsByIncidentLogic] {
  const urlParams: UrlParams = useParams()
  const dispatch = useAppDispatch()

  const { accessTokenId, incidentDetails, isIncidentDetailsLoading, threatDetailsByIncident } = useAppSelector(
    _stores => ({
      accessTokenId: _stores.accessToken.accessToken?.id || '',
      incidentDetails: _stores.remediation.incidentDetails,
      isIncidentDetailsLoading: isPending(_stores.remediation.getIncidentDetailsApiStatus),
      threatDetailsByIncident: _stores.dataTables.threatDetailsByIncident
    })
  )

  useEffect(() => {
    dispatch(forensicsGetIncidentDetails({ accessTokenId, incidentId: urlParams.incidentId as string }))
    return () => {
      dispatch(forensicsResetIncidentDetails())
      dispatch(threatDetailsByIncidentTableActions.reset())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessTokenId])

  const gridData = useMemo(() => {
    const clonedIncidentDetails: IncidentDetails = cloneDeep(incidentDetails)
    const incidentDetailsThreats = clonedIncidentDetails.threats || []

    const data = process(
      incidentDetailsThreats
        .reduce((all: Threat[], threat: Threat) => {
          if (!threat) {
            return [...all, {} as any]
          }

          // handle link threat format
          let { extra } = threat
          if (threat.type.toLowerCase() === 'link') {
            const CONNECTOR = ' -> '
            const oldPath = threat.extra.split(CONNECTOR)

            // replace 'file' with 'message body'
            if (oldPath[1] === 'file') {
              oldPath[1] = 'message body'
            }

            // index 0 is always root, drop it
            const newPath = oldPath.slice(1).join(CONNECTOR)
            extra = newPath
          }

          return [
            ...all,
            {
              extra,
              path: threat.path,
              type: threat.type[0] + threat.type.substring(1).toLowerCase(),
              vector: threat.vector
            } as GridRow
          ]
        }, [])
        .filter((item: any) => Object.keys(item).length),
      {}
    )

    return data.data
  }, [incidentDetails])

  return useMemo(
    () => [
      {
        isPageInProgress: isIncidentDetailsLoading,
        gridData,
        columnsConfig: threatDetailsByIncident.columnsConfig,
        GRID_COLUMNS: threatDetailsByIncident.GRID_COLUMNS
      }
    ],
    [isIncidentDetailsLoading, gridData, threatDetailsByIncident]
  )
}
