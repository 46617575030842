import React, { useMemo } from 'react'

import { Collapse, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@barracuda-internal/bds-core'
import Icon from '@material-ui/core/Icon'
import { ExpandMore, ExpandLess } from '@material-ui/icons'

import IMAGES from 'global/configs/theme/images.config'
import styles from 'global/components/lib/layout/navbar/navbarStyles'
import { useFormatMessage } from 'global/lib/localization'
import useNavbarSideMenuLogic, {
  NavbarSideMenuLogicParams,
  ListItemProps
} from 'global/components/lib/layout/navbar/sideMenu/useNavbarSideMenuLogic'

export type NavbarSideMenuProps = NavbarSideMenuLogicParams

const BASE_I18N_KEY = 'app.navbar'

const NavbarSideMenu: React.FC<NavbarSideMenuProps> = props => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [navbarSideMenuLogic] = useNavbarSideMenuLogic(props)
  const { listItems, selectedIndex } = props

  const list = useMemo(
    () => (
      <div className={classes.list} role="presentation">
        <List component="nav">
          {listItems.map((listItem: ListItemProps, index: number) => (
            <React.Fragment key={listItem.id}>
              <ListItem
                button
                disabled={listItem.disabled}
                selected={selectedIndex === index}
                classes={{ selected: classes.activeListItem }}
                onClick={listItem.hasSubMenu ? undefined : navbarSideMenuLogic.handleListItemClick(index)}
                onMouseOver={() =>
                  navbarSideMenuLogic.handleListItemMouseOver(listItem.id, listItem.hasSubMenu || false)
                }
                data-testid={`SIDE_NAV_ITEM_${listItem.id}`}
                aria-selected={selectedIndex === index}
              >
                <ListItemText primary={listItem.text} classes={{ primary: classes.listItemText }} />
                {listItem.chip && (
                  <ListItemIcon>
                    {listItem.chip({ color: 'primary', label: formatMessage('new'), size: 'small' })}
                  </ListItemIcon>
                )}
                {listItem.hasSubMenu &&
                  (navbarSideMenuLogic.subMenuOpenState.isSubMenuOpen &&
                  navbarSideMenuLogic.subMenuOpenState.id === listItem.id ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  ))}

                {listItem.icon && <listItem.icon />}
              </ListItem>
              {/* This is the sub navigation drawer */}
              {listItem.hasSubMenu && (
                <Collapse in={navbarSideMenuLogic.subMenuOpenState.id === listItem.id} timeout="auto" unmountOnExit>
                  <List className={classes.subMenu} component="div" disablePadding>
                    {listItem.subMenuItems &&
                      listItem.subMenuItems.map((subMenuItem: any) => (
                        <ListItem
                          key={`subMenu${subMenuItem.text}`}
                          button
                          classes={{ selected: classes.activeListItem }}
                          onClick={navbarSideMenuLogic.handleListItemClick(index, subMenuItem.routeId)}
                        >
                          <ListItemText style={{ marginLeft: '24px' }} classes={{ primary: classes.listItemText }}>
                            {subMenuItem.text}
                          </ListItemText>
                        </ListItem>
                      ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
      </div>
    ),
    [
      classes.activeListItem,
      classes.list,
      classes.listItemText,
      classes.subMenu,
      listItems,
      selectedIndex,
      navbarSideMenuLogic,
      formatMessage
    ]
  )

  return useMemo(() => {
    const { toggle, toggleHamburger } = navbarSideMenuLogic

    return (
      <div className={classes.navigationHamburger}>
        <div id="fir-menu-sidenav" data-testid="side-navigation-toggle">
          <Icon onClick={toggleHamburger}>
            <img className={classes.hamburger} alt="MENU" src={IMAGES.hamburgerMenu} />
          </Icon>
          <Drawer
            className={classes.drawer}
            anchor="left"
            open={toggle}
            onClose={toggleHamburger}
            data-testid="side-navigation"
          >
            {list}
          </Drawer>
        </div>
      </div>
    )
  }, [navbarSideMenuLogic, list, classes])
}

export default NavbarSideMenu
