import React from 'react'

import { EpImpersonationProtection } from '@barracuda-internal/bds-core/dist/Logos/Products'

import GlobalSignupComplete from 'global/components/features/signinSignup/signupComplete/SignupComplete'
import { config } from 'global/lib/config'

import routesConfig from 'sen/lib/routes/routesConfig'

export const SignupComplete: React.FC = () => (
  <GlobalSignupComplete
    isFinalStep={false}
    productLogo={<EpImpersonationProtection alt="Impersonation Protection" />}
    routesConfig={routesConfig}
    gotoNextStep={() => {
      routesConfig.SIGNUP_REMEDIATES.goto()
    }}
    scanType={config.SCAN_TYPES.SENTINEL}
  />
)

export default SignupComplete
