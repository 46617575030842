import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  alert: {
    marginBottom: theme.spacing(1)
  },
  icon: {
    cursor: 'pointer',
    width: theme.spacing(2)
  }
}))
