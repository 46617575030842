import { useRef, useEffect } from 'react'

export type CbHandler = () => any
export type Dependecies = any[]
export type Condition = boolean

export function createEffectHandler(handleIfInitialRender = false) {
  return function useInitialRenderHandler(cb: CbHandler, dependecies: Dependecies) {
    const isInitialRender = useRef<boolean>(true)
    const unMountCb = useRef<() => void | undefined>()

    useEffect(() => {
      if ((handleIfInitialRender && isInitialRender.current) || (!handleIfInitialRender && !isInitialRender.current)) {
        unMountCb.current = cb()
      }

      isInitialRender.current = false

      return () => {
        if (unMountCb.current) {
          unMountCb.current()
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...dependecies])
  }
}
export const useEffectOnInit = createEffectHandler(true)
export const useEffectWhenRendered = createEffectHandler()

export function useEffectOnlyOnce(cb: CbHandler, dependecies: Dependecies, condition: Condition) {
  const isCbHandled = useRef<boolean>(false)
  const unMountCb = useRef<() => void | undefined>()

  useEffect(() => {
    if (!isCbHandled.current && condition) {
      unMountCb.current = cb()
      isCbHandled.current = true
    }

    return () => {
      if (unMountCb.current) {
        unMountCb.current()
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependecies])
}
