import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { validateApiError } from 'global/lib/api/restClient'

import apiRoutes from 'sen/lib/api/apiRoutes'

export const refreshO365AccessToken = createAsyncThunk(
  'CLOUD/refreshO365AccessToken',
  async function doRefreshO365AccessToken(_, { rejectWithValue }) {
    try {
      const resp = await restClient(apiRoutes.REFRESH_O365_ACCESS_TOKEN, {
        data: {}
      })

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)
