import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'

import { process } from '@progress/kendo-data-query'

import {
  User as UserIcon,
  SupervisedUserCircle as SupervisorAccount,
  FindReplace
} from '@barracuda-internal/bds-core/dist/Icons/Core'
import { MarkunreadMailbox } from '@barracuda-internal/bds-core/dist/Icons/Email'

import { isFailed, isPending } from 'global/redux/toolkit/api'
import { EtsModifiedScan, ScanAccessToken, ScanAccessTokens, ScanResults, ScanUserFlags } from 'global/types/api/scan'
import { SearchFieldProps, useSearchFieldLogic } from 'global/components/lib/searchField/SearchField'
import { BDSGridPagerConfig, BDSGridSortConfig } from 'global/types/dataTables/dataTables'
import useTablePeriodicCheck from 'global/lib/useTablePeriodicCheck/useTablePeriodicCheck'
import { formatDate } from 'global/lib/datetime'
import { config } from 'global/lib/config'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'

import { ColumnsConfig } from 'admin/redux/types/dataTables'
import { useAppDispatch, useAppSelector } from 'admin/redux/toolkit/hooks'
import { scanTotals, getScans, resetScans, impersonate } from 'admin/redux/features/admin/adminSlice'
import { CardData } from 'admin/components/pages/dashboard/metrics/Metrics'
import {
  update as updateScansTable,
  reset as resetScansTable
} from 'admin/redux/features/dataTables/etsScans/etsScansSlice'
import { ScanDialogProps } from 'admin/components/lib/dialogs/scanDialog/ScanDialog'
import { ImpersonateDialogProps } from 'admin/components/lib/dialogs/impersonateDialog/ImpersonateDialog'

export type InProgress = boolean
export interface TableConfig {
  isLoaded: boolean
  inProgress: boolean
  searchFieldConfig: SearchFieldProps
  tableData: {
    total: number
    data: EtsModifiedScan[]
  }
  pageConfig: BDSGridPagerConfig
  sortConfig: BDSGridSortConfig | {}
  columns: { [key: string]: string }
  columnsConfig: ColumnsConfig
  onOpenDetails: (scan: EtsModifiedScan, accessToken: ScanAccessToken) => void
}

export interface ScanDialogConfig {
  open: boolean
  onClose: () => void
  scan: ScanDialogProps['scan'] | undefined
  accessToken: ScanDialogProps['accessToken'] | undefined
  product: ScanDialogProps['product']
}
export interface ImpersonateDialogConfig {
  open: boolean
  email: ImpersonateDialogProps['email'] | undefined
}

const scanType = 'ets'
const SEARCH_FIELD_ID = 'scans-table-search'

export default function useEmailThreatScannerLogic(): [
  InProgress,
  CardData[],
  ScanUserFlags,
  ScanAccessTokens,
  TableConfig,
  ScanDialogConfig,
  ImpersonateDialogConfig,
  (scan: EtsModifiedScan) => void,
  (email: string) => void
] {
  const dispatch = useAppDispatch()
  const {
    inProgress,
    totals,
    getScansInProgress,
    scans,
    loadedScansOffsets,
    scansTable,
    impersonateFailed
  } = useAppSelector(_stores => ({
    inProgress: isPending(_stores.admin.scanTotalsApiStatus),
    totals: _stores.admin.totals,
    getScansInProgress: isPending(_stores.admin.getScansApiStatus),
    scans: _stores.admin.scans,
    loadedScansOffsets: _stores.admin.loadedScansOffsets,
    scansTable: _stores.dataTables.etsScans,
    impersonateFailed: isFailed(_stores.admin.impersonateApiStatus)
  }))

  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    searchString: ''
  })

  const [initTableRefresh] = useTablePeriodicCheck()
  const [isScanDialogOpened, toggleScanDialog] = useDialogLogic()
  const [isImpersonateDialogOpened, toggleImpersonateDialog] = useDialogLogic()
  const [selectedScan, setSelectedScan] = useState<{
    scan: ScanDialogConfig['scan']
    accessToken: ScanDialogConfig['accessToken']
    product: ScanDialogProps['product']
  }>()
  const [selectedImpersonate, setSelectedImpersonate] = useState<{
    email: ImpersonateDialogProps['email']
  }>()

  // init
  useEffect(() => {
    initTableRefresh(tableRefresh)

    dispatch(scanTotals({ scanType }))
    dispatch(getScans({ scanType }))
    return () => {
      dispatch(resetScans(true))
      dispatch(resetScansTable())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resetTable = useCallback(() => {
    dispatch(resetScans())
    dispatch(getScans({ scanType }))
  }, [dispatch])

  const tableRefresh = useCallback(() => {
    if (!getScansInProgress) {
      resetTable()
    }
  }, [resetTable, getScansInProgress])

  const updateTableData = useCallback(
    (changes: any = {}) => {
      dispatch(
        updateScansTable({
          ...changes,
          skip: 0
        })
      )

      initTableRefresh(tableRefresh)
      resetTable()
    },
    [dispatch, resetTable, initTableRefresh, tableRefresh]
  )

  const [debouncedOnChange, validateSearchString] = useSearchFieldLogic(
    (search: string) => {
      updateTableData({ search: search.trim() })
      setState({ searchString: search.trim() })
    },
    getScansInProgress,
    SEARCH_FIELD_ID
  )

  const tableData = useMemo(() => {
    const { skip, take } = scansTable

    const { data } = process(
      (scans?.data || []).map((report: ScanResults) => ({
        ...(report && {
          ...report,
          createdOnFormatted: formatDate(report.createdOn || '', config.DATETIME.ADMIN_DATE_FORMAT),
          finishedOnFormatted: formatDate(report.finishedOn || '', config.DATETIME.ADMIN_DATE_FORMAT)
        })
      })),
      { skip, take }
    )

    return {
      data: data.filter(report => report.id),
      total: scans?.totalCount || 0
    }
  }, [scans, scansTable])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = scansTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: tableData.total,
      onPageChange: (e: any) => {
        dispatch(updateScansTable(e.page))

        if (!loadedScansOffsets?.includes(e.page.skip)) {
          dispatch(getScans({ scanType }))
        }
      }
    }
  }, [scansTable, tableData, dispatch, loadedScansOffsets])

  const sortConfig: BDSGridSortConfig | {} = useMemo(() => {
    if (!tableData.total) {
      return {}
    }

    return {
      sortable: !getScansInProgress && {
        allowUnsort: false,
        mode: 'single'
      },
      sort: scansTable.sort,
      onSortChange: (e: any) => {
        updateTableData({ sort: e.sort })
      }
    }
  }, [scansTable, updateTableData, tableData.total, getScansInProgress])

  const searchFieldConfig: SearchFieldProps = useMemo(() => {
    return {
      id: SEARCH_FIELD_ID,
      value: state.searchString,
      onChange: (e: any) => {
        const validatedSearchString = validateSearchString(e.target.value)

        if (scansTable.search !== validatedSearchString) {
          debouncedOnChange(validatedSearchString)
          setState({ searchString: validatedSearchString })
        }
      },
      disabled: getScansInProgress
    }
  }, [state.searchString, scansTable, debouncedOnChange, validateSearchString, getScansInProgress])

  const onOpenDetails = useCallback(
    (scan, accessToken) => {
      setSelectedScan({ scan, accessToken, product: scanType })
      toggleScanDialog()
    },
    [toggleScanDialog]
  )

  const onCloseDetails = useCallback(() => {
    setSelectedScan(undefined)
    toggleScanDialog()
  }, [toggleScanDialog])

  const openAdminReportURI = useCallback(
    (scan: EtsModifiedScan) => {
      const url = scans.accessTokens[scan.accessTokenId]
        ? `${config.shareReportURIPrefix}${scans.accessTokens[scan.accessTokenId].adminSecret.value}`
        : undefined
      window.open(url)
    },
    [scans]
  )

  const onOpenImpersonate = useCallback(
    (email: string) => {
      setSelectedImpersonate({ email })
      toggleImpersonateDialog()
      dispatch(impersonate({ email, host: config.domains.ets, path: 'v2/report' }))
    },
    [dispatch, toggleImpersonateDialog]
  )

  // close impersonate dialog on failed request
  useEffect(() => {
    if (impersonateFailed) {
      setSelectedImpersonate(undefined)
      toggleImpersonateDialog()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [impersonateFailed])

  return useMemo(() => {
    const cards = [
      {
        icon: UserIcon,
        value: totals.accountsCount,
        messageId: 'accounts'
      },
      {
        icon: FindReplace,
        value: totals.scanCount,
        messageId: 'scans'
      },
      {
        icon: MarkunreadMailbox,
        value: totals.mailboxCount,
        messageId: 'mailboxes'
      },
      {
        icon: SupervisorAccount,
        value: totals.spAttacksCount,
        messageId: 'attacks'
      }
    ]
    return [
      inProgress,
      cards,
      scans.userFlags,
      scans.accessTokens,
      {
        isLoaded: !!scans.data,
        inProgress: getScansInProgress,
        searchFieldConfig,
        tableData,
        pageConfig,
        sortConfig,
        columns: scansTable.GRID_COLUMNS,
        columnsConfig: scansTable.columnsConfig,
        onOpenDetails
      },
      {
        open: isScanDialogOpened,
        onClose: onCloseDetails,
        scan: selectedScan?.scan,
        accessToken: selectedScan?.accessToken,
        product: scanType
      },
      {
        open: isImpersonateDialogOpened,
        email: selectedImpersonate?.email
      },
      openAdminReportURI,
      onOpenImpersonate
    ]
  }, [
    inProgress,
    totals,
    getScansInProgress,
    scans,
    scansTable,
    tableData,
    pageConfig,
    sortConfig,
    onOpenDetails,
    isScanDialogOpened,
    selectedScan,
    onCloseDetails,
    openAdminReportURI,
    searchFieldConfig,
    onOpenImpersonate,
    isImpersonateDialogOpened,
    selectedImpersonate
  ])
}
