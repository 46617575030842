import { createSlice } from '@reduxjs/toolkit'

import { insertReportData } from 'global/redux/features/reports/reducerHandlers'
import { ApiStatus, failedResponse, inIdle, inProgress, successResponse } from 'global/redux/toolkit/api'

import { getPDFThreatsReport, getThreatsReport } from 'ets/redux/features/reports/reportsApiThunks'
import { INITIAL_STATE as threatsTableInitialState } from 'ets/redux/features/dataTables/threats/threatsSlice'

export interface ThreatsState {
  apiStatus: ApiStatus
  pdfApiStatus: ApiStatus
  loadedOffsets: number[]
  pdfLoadedOffsets: number[]
  data: any
  pdfData: any
}

// initialState
export const INITIAL_STATE: ThreatsState = {
  apiStatus: inIdle,
  pdfApiStatus: inIdle,
  loadedOffsets: [],
  pdfLoadedOffsets: [],
  data: { report: {} },
  pdfData: { report: {} }
}

/* eslint-disable no-param-reassign */
export const threatsSlice = createSlice({
  name: 'REPORTS/THREATS',
  initialState: INITIAL_STATE,
  reducers: {
    resetThreatsReport: () => {
      return {
        ...INITIAL_STATE
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getThreatsReport.pending, state => {
        state.apiStatus = inProgress
        if (!state.loadedOffsets.length) {
          state.loadedOffsets = [threatsTableInitialState.skip]
        }
      })
      .addCase(getThreatsReport.fulfilled, (state, action) => {
        state.apiStatus = successResponse
        state.data = insertReportData(state.data, action.payload as any)
      })
      .addCase(getThreatsReport.rejected, (state, action) => {
        state.apiStatus = failedResponse(action.payload as string)
      })
      .addCase(getPDFThreatsReport.pending, state => {
        state.pdfApiStatus = inProgress
      })
      .addCase(getPDFThreatsReport.fulfilled, (state, action) => {
        state.pdfApiStatus = successResponse
        state.pdfData = insertReportData(state.pdfData, action.payload as any)
      })
      .addCase(getPDFThreatsReport.rejected, (state, action) => {
        state.pdfApiStatus = failedResponse(action.payload as string)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { resetThreatsReport } = threatsSlice.actions

export { getThreatsReport, getPDFThreatsReport }

export default threatsSlice.reducer
