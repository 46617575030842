import React, { ReactElement, useCallback, useEffect, useMemo, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { Elements, FlowElement } from 'react-flow-renderer'
import { v4 as uuidv4 } from 'uuid'
import validator from 'validator'

// eslint-disable-next-line import/no-extraneous-dependencies
import { setAutoFreeze } from 'immer'

import { EventConstants, NodeConstants, WorkflowNode } from '@barracuda/automated-workflows'

import { Typography } from '@barracuda-internal/bds-core'

import { setConditionValidationStatus, updateBuilder } from 'fir/redux/features/workflows/workflowsSlice'
import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'
import { Node, NODE_TYPES, OPERATORS } from 'fir/redux/types/Workflows'

import config from 'fir/configs/app_config'
import useAccessTokenLib from 'global/lib/accessToken/useAccessToken'
import { luxonDate, isBeforeDate } from 'global/lib/datetime'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'
import { getCountryNameFromCode2 } from 'global/lib/isoCountries'
import { useFormatMessage } from 'global/lib/localization'

import { UseWebhookDialogParams } from 'fir/components/pages/automatedWorkflows/webhookDialog/useWebhookDialogLogic'

export interface UseWorkflowBuilderParams {
  rowType?: string
}

type WebhookDialogConfig = UseWebhookDialogParams

interface WorkflowBuilderConfig extends UseWorkflowBuilderParams {
  actionCount: number
  actionId: string
  actionUuid: string
  actionValue: string
  conditionCount: number
  conditionData: string
  conditionId: string
  conditionUuid: string
  conditionValue: string
  elements: Elements
  eventRowList: any[]
  eventTypeValue: string
  events: any
  operatorValue: string
  triggerCount: number
  triggerId: string
  triggerUuid: string
  triggerValue: string
}

export interface WorkflowBuilderProps {
  buildNodeLabel: (nodeType: string, label: string, details: string) => ReactElement
  displayNewIcon: boolean
  handleConditionData: (e: any) => void
  onAddAction: () => void
  onAddCondition: () => void
  onAddTrigger: () => void
  onChangeEvent: (e: any, uuid: string) => void
  onChangeEventType: (e: any) => void
  onChangeOperator: (e: any) => void
  conditionDataErrorText: string
  isValidConditionValue: boolean
  isWorkflowBlueprint: boolean
  webhookDialogConfig: WebhookDialogConfig
  workflowBuilderConfig: WorkflowBuilderConfig
}

const BASE_I18N_KEY = 'fir.app.automated_workflows.workflow_dialog'
const WEBHOOK_I18N_KEY = 'fir.app.automated_workflows.webhook_dialog'
const INIT_STATE = {
  conditionData: '',
  conditionDataErrorText: '',
  eventTypeValue: 'triggers',
  eventRowList: [],
  events: {},
  msTeamsWebhookValue: '',
  slackWebhookValue: '',
  isConditionDataValid: true,
  operatorValue: '',
  validateConditionData: false,
  webhookSource: { name: 'default', action: 'default' },
  workflowLoaded: false
}

export default function useWorkflowBuilderLogic(): [WorkflowBuilderProps] {
  // TODO remove it once implementing AW V2.
  setAutoFreeze(false)
  const appDispatch = useAppDispatch()
  const dispatch = useDispatch()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatWebhookMessage = useFormatMessage(WEBHOOK_I18N_KEY)
  const [accessTokenLib] = useAccessTokenLib()

  // TODO: any after implementing strong type for workflows store
  const { existingWorkflow, settings, workflowBuilder }: any = useAppSelector(_stores => ({
    existingWorkflow: _stores.workflows.workflow,
    settings: _stores.settings,
    workflowBuilder: _stores.workflows.builder
  }))

  const { msTeamsWebhook, slackWebhook } = {
    msTeamsWebhook: settings.forensics.forensicsAutomatedWorkflowMSTeamsWebhook,
    slackWebhook: settings.forensics.forensicsAutomatedWorkflowSlackWebhook
  }

  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), INIT_STATE)
  const [isDialogOpened, toggleDialog, setIsDialogOpened] = useDialogLogic()

  // init
  useEffect(() => {
    return () => {
      setState({
        ...INIT_STATE,
        actionUuid: uuidv4(),
        conditionUuid: uuidv4(),
        triggerUuid: uuidv4()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // used to see when webhooks are added
  useEffect(() => {
    setState({ msTeamsWebhookValue: msTeamsWebhook, slackWebhookValue: slackWebhook })
  }, [msTeamsWebhook, slackWebhook])

  const displayNewIcon = useMemo(() => {
    const now = luxonDate().toISO()

    return isBeforeDate({
      initialDate: now,
      subtractedDate: config.NEW_FEATURE_RELEASE_DATES.AW_MSTEAMS_ACTION
    })
  }, [])

  const buildNodeLabel = useCallback(
    (nodeType: string, label: string, details = '') => {
      const detailParts = details.split(' ')
      const operator =
        Object.keys(OPERATORS).indexOf(detailParts[0]) > -1
          ? formatMessage(`events.operators.${OPERATORS[detailParts[0]]}`).toLowerCase()
          : detailParts[0]
      const remainingDetails = detailParts.slice(1).join(' ')
      let formattedLabel = `${label} ${details}`

      if (nodeType in NODE_TYPES) {
        formattedLabel = formatMessage(`events.${nodeType}.${label}`)
        if (!label.endsWith('_noInput')) {
          formattedLabel += ` ${operator} ${
            label.endsWith('Country') && remainingDetails ? getCountryNameFromCode2(remainingDetails) : remainingDetails
          }`
        }
      }
      return <Typography variant="body2">{formattedLabel}</Typography>
    },
    [formatMessage]
  )

  // Update the workflowTree in the builder with the new event, if we get blueprints that are not
  // trigger, condition, action this logic will need to change
  const updateWorkflowTree = useCallback(
    (eventType: string, updatedWorkflowNode: any) => {
      switch (eventType) {
        case NODE_TYPES.actions:
          workflowBuilder.workflowTree.removeLink(
            workflowBuilder.workflowNodes[workflowBuilder.conditionId],
            workflowBuilder.workflowNodes[workflowBuilder.actionId]
          )
          workflowBuilder.workflowTree.addLink(
            workflowBuilder.workflowNodes[workflowBuilder.conditionId],
            updatedWorkflowNode
          )
          break
        case NODE_TYPES.conditions:
          workflowBuilder.workflowTree.removeLink(
            workflowBuilder.workflowNodes[workflowBuilder.triggerId],
            workflowBuilder.workflowNodes[workflowBuilder.conditionId]
          )
          workflowBuilder.workflowTree.addLink(
            workflowBuilder.workflowNodes[workflowBuilder.triggerId],
            updatedWorkflowNode
          )
          workflowBuilder.workflowTree.addLink(
            updatedWorkflowNode,
            workflowBuilder.workflowNodes[workflowBuilder.actionId]
          )
          break
        default:
          break
      }
    },
    [workflowBuilder]
  )

  const updateNode = useCallback(
    (eventType: string, id: string, value: any, details = '') => {
      const eventTypeKey = `${eventType.split('s')[0]}Value`
      const event = `${eventType.split('s')[0]}`

      // Check if we are updating a blueprint node or a newly created node
      if (existingWorkflow.workflowBlueprint?.uuid) {
        const updatedNode = workflowBuilder.elements.find((element: FlowElement) => element.id === id)
        updatedNode.data = { label: buildNodeLabel(eventType, value, details) }

        // Update the workflowTree in the builder with the new event
        const updatedWorkflowNode = new WorkflowNode(event, value, details, id)

        // Update the workflow tree in the builder
        updateWorkflowTree(eventType, updatedWorkflowNode)

        appDispatch(
          updateBuilder({
            builder: {
              [eventTypeKey]: value,
              elements: [...workflowBuilder.elements],
              workflowNodes: { ...workflowBuilder.workflowNodes, [id]: updatedWorkflowNode }
            }
          })
        )
      } else {
        appDispatch(updateBuilder({ builder: { [eventTypeKey]: value } }))
      }
    },
    [
      appDispatch,
      existingWorkflow.workflowBlueprint,
      workflowBuilder.elements,
      workflowBuilder.workflowNodes,
      buildNodeLabel,
      updateWorkflowTree
    ]
  )

  const updateNodeConditionOperator = useCallback(
    (operatorValue: string) => {
      if (existingWorkflow.workflowBlueprint?.uuid) {
        const conditionDataDetails = state.conditionData
          ? `${operatorValue} ${state.conditionData}`
          : `${operatorValue}`
        const updatedNode = workflowBuilder.elements.find(
          (element: FlowElement) => element.id === workflowBuilder.conditionId
        )
        updatedNode.data = {
          label: buildNodeLabel(NODE_TYPES.conditions, workflowBuilder.conditionValue, conditionDataDetails)
        }

        // Update the workflowTree in the builder with the new condition data
        const conditiondata = { config: { conditionOperator: operatorValue, conditionValue: state.conditionData } }

        const updatedWorkflowNode = new WorkflowNode(
          NodeConstants.CONDITION,
          workflowBuilder.conditionValue,
          conditiondata,
          workflowBuilder.conditionId
        )

        // Update the workflow tree in the builder
        updateWorkflowTree(NODE_TYPES.conditions, updatedWorkflowNode)

        appDispatch(
          updateBuilder({
            builder: {
              elements: [...workflowBuilder.elements],
              workflowNodes: { ...workflowBuilder.workflowNodes, [workflowBuilder.conditionId]: updatedWorkflowNode }
            }
          })
        )
        setState({ ...state, operatorValue, validateConditionData: true })
      } else {
        setState({ ...state, operatorValue })
      }
    },
    [
      appDispatch,
      buildNodeLabel,
      existingWorkflow.workflowBlueprint,
      state,
      updateWorkflowTree,
      workflowBuilder.conditionId,
      workflowBuilder.conditionValue,
      workflowBuilder.elements,
      workflowBuilder.workflowNodes
    ]
  )

  const updateNodeConditionData = useCallback(
    (conditionData: string) => {
      const conditionDataDetails = `${state.operatorValue} ${conditionData}`
      const updatedNode = workflowBuilder.elements.find(
        (element: FlowElement) => element.id === workflowBuilder.conditionId
      )
      updatedNode.data = {
        label: buildNodeLabel(NODE_TYPES.conditions, workflowBuilder.conditionValue, conditionDataDetails)
      }

      // Update the workflowTree in the builder with the new condition data
      const treeConditionData = { config: { conditionOperator: state.operatorValue, conditionValue: conditionData } }

      const updatedWorkflowNode = new WorkflowNode(
        NodeConstants.CONDITION,
        workflowBuilder.conditionValue,
        treeConditionData,
        workflowBuilder.conditionId
      )

      // Update the workflow tree in the builder
      updateWorkflowTree(NODE_TYPES.conditions, updatedWorkflowNode)

      appDispatch(
        updateBuilder({
          builder: {
            elements: [...workflowBuilder.elements],
            workflowNodes: { ...workflowBuilder.workflowNodes, [workflowBuilder.conditionId]: updatedWorkflowNode }
          }
        })
      )
    },
    [
      appDispatch,
      buildNodeLabel,
      state.operatorValue,
      updateWorkflowTree,
      workflowBuilder.conditionId,
      workflowBuilder.conditionValue,
      workflowBuilder.elements,
      workflowBuilder.workflowNodes
    ]
  )

  // pre-populate builder if workflow exists
  useEffect(() => {
    if ((existingWorkflow.uuid && !state.workflowLoaded) || existingWorkflow.workflowBlueprint) {
      // fill out workflowNodes, set state of event values, count events
      let actionCount = 0
      let conditionCount = 0
      let triggerCount = 0
      let conditionData
      let elements
      const builderUpdate = {} as any
      const workflowNodes = {} as any
      const newUuidMap = {} as any

      existingWorkflow.nodes.forEach((node: Node) => {
        const newblueprintUuid = uuidv4()
        const workflowNode = new WorkflowNode(node.type, node.eventType)
        newUuidMap[node.uuid] = existingWorkflow.workflowBlueprint?.uuid ? newblueprintUuid : node.uuid
        workflowNode.node.config = node.config
        workflowNode.node.uuid = newUuidMap[node.uuid]
        workflowNodes[newUuidMap[node.uuid]] = workflowNode
        switch (node.type) {
          case NodeConstants.ACTION:
            builderUpdate.actionId = newUuidMap[node.uuid]
            builderUpdate.actionValue = node.eventType
            ++actionCount
            break
          case NodeConstants.CONDITION:
            builderUpdate.conditionId = newUuidMap[node.uuid]
            builderUpdate.conditionValue = node.eventType
            conditionData = existingWorkflow.nodes.find((n: Node) => n.type === NodeConstants.CONDITION).config
            ++conditionCount
            setState({
              conditionData: conditionData ? conditionData.conditionValue : '',
              operatorValue: conditionData ? conditionData.conditionOperator : '',
              validateConditionData: true
            })
            break
          case NodeConstants.TRIGGER:
            workflowBuilder.workflowTree.setRoot(workflowNode)
            builderUpdate.triggerId = newUuidMap[node.uuid]
            builderUpdate.triggerValue = node.eventType
            ++triggerCount
            break
          default:
            break
        }
      })

      // Replace old uuids in diagram to new uuids for blueprints
      if (existingWorkflow.workflowBlueprint?.uuid) {
        let newDiagram = existingWorkflow.diagram
        Object.keys(newUuidMap).forEach((uuid: string) => {
          newDiagram = newDiagram.replaceAll(uuid, newUuidMap[uuid])
        })

        // re-build labels for flow diagram for blueprint
        elements = JSON.parse(newDiagram).map((element: FlowElement) => {
          const label = element.data ? buildNodeLabel('', element.data?.label?.props?.children) : undefined
          return label ? { ...element, data: { label } } : element
        })
      } else {
        // re-build labels for flow diagram using original uuids
        elements = JSON.parse(existingWorkflow.diagram).map((element: FlowElement) => {
          const label = element.data ? buildNodeLabel('', element.data?.label?.props?.children) : undefined
          return label ? { ...element, data: { label } } : element
        })
      }

      // re-build workflow tree
      existingWorkflow.nodes.forEach((node: Node) => {
        node.targets.forEach((target: string) => {
          workflowBuilder.workflowTree.addLink(workflowNodes[newUuidMap[node.uuid]], workflowNodes[newUuidMap[target]])
        })
      })

      appDispatch(
        updateBuilder({
          builder: { actionCount, conditionCount, elements, triggerCount, workflowNodes, ...builderUpdate }
        })
      )
      setState({ workflowLoaded: true })
    }
  }, [
    appDispatch,
    buildNodeLabel,
    dispatch,
    existingWorkflow,
    state.actionUuid,
    state.conditionUuid,
    state.events,
    state.triggerUuid,
    state.workflowLoaded,
    workflowBuilder.workflowTree
  ])

  // reset eventTypeValue if trigger is deleted
  useEffect(() => {
    if (workflowBuilder.triggerCount === 0) {
      setState({ eventTypeValue: 'triggers' })
    }
  }, [workflowBuilder.triggerCount])

  // Validates the inputs for conditions
  const isValidConditionValue = useMemo(() => {
    if (state.validateConditionData) {
      let isConditionDataValid

      switch (workflowBuilder.conditionValue) {
        case EventConstants.POTENTIAL_INCIDENT_SENDER_EMAIL:
        case EventConstants.SENDER_POLICY_CREATED_BY:
        case EventConstants.USER_REPORTED:
        case EventConstants.USER_REPORTED_REPORTED_BY:
        case EventConstants.USER_REPORTED_SENDER_EMAIL:
          if (state.operatorValue === '==' || state.operatorValue === '!=') {
            isConditionDataValid = validator.isEmail(state.conditionData)
            setState({ conditionDataErrorText: formatMessage('errors.invalid_email'), isConditionDataValid })
            return isConditionDataValid
          }
          if (state.conditionData.length < 3) {
            setState({
              conditionDataErrorText: formatMessage('errors.condition_too_short'),
              isConditionDataValid: false
            })
            return false
          }
          setState({ isConditionDataValid: true })
          return true
        case EventConstants.POTENTIAL_INCIDENT_EMAILS_DETECTED_COUNT:
        case EventConstants.POTENTIAL_INCIDENT_MAILBOXES_AFFECTED_COUNT:
        case EventConstants.SENDER_POLICY_EMAILS_DETECTED_COUNT:
        case EventConstants.SENDER_POLICY_MAILBOXES_AFFECTED_COUNT:
        case EventConstants.USER_REPORTED_MATCHED_EMAIL_COUNT:
        case EventConstants.USER_REPORTED_REPORTED_EMAIL_COUNT: {
          const conditionDataErrorText = !validator.isNumeric(state.conditionData)
            ? formatMessage('errors.invalid_number')
            : formatMessage('errors.invalid_positive_integer')
          isConditionDataValid = Number(state.conditionData) > 0 && Number.isInteger(Number(state.conditionData))
          setState({ conditionDataErrorText, isConditionDataValid })
          return isConditionDataValid
        }
        case EventConstants.POTENTIAL_INCIDENT_SENDER_COUNTRY:
        case EventConstants.SENDER_POLICY_SENDER_COUNTRY:
        case EventConstants.USER_REPORTED_SENDER_COUNTRY: {
          const conditionDataErrorText = !state.conditionData.length ? formatMessage('errors.select_country') : ''
          setState({ conditionDataErrorText })
          return !!state.conditionData.length
        }
        default:
          setState({ isConditionDataValid: true })
          return true
      }
    }

    setState({ isConditionDataValid: true })
    return true
  }, [
    state.validateConditionData,
    state.operatorValue,
    state.conditionData,
    workflowBuilder.conditionValue,
    formatMessage
  ])

  // Lifecycle method to set builder store with condition data validation status
  useEffect(() => {
    if (existingWorkflow.workflowBlueprint?.uuid && state.validateConditionData) {
      appDispatch(setConditionValidationStatus({ isConditionDataValid: state.isConditionDataValid }))
    }
  }, [appDispatch, existingWorkflow.workflowBlueprint, state.isConditionDataValid, state.validateConditionData])

  const horizontalNodePosition = (count: number): number => {
    const basePosition = 30 + count * 220

    if (count !== 0) {
      return basePosition + 8 * (Math.random() % 5) + 8
    }

    return basePosition
  }

  const onAddAction = useCallback(() => {
    const newElements = []
    const eventValue = workflowBuilder.actionValue
    const action = new WorkflowNode(NodeConstants.ACTION, eventValue)
    const actionId = action.node.uuid

    // Check if valid webhook exists, if not open webhook dialog when clicking add button
    if (eventValue === EventConstants.SLACK_NOTIFICATION && !state.slackWebhookValue) {
      setIsDialogOpened(true)
      return
    }

    if (eventValue === EventConstants.MSTEAMS_NOTIFICATION && !state.msTeamsWebhookValue) {
      setIsDialogOpened(true)
      return
    }

    if (workflowBuilder.conditionId) {
      workflowBuilder.workflowTree.addLink(workflowBuilder.workflowNodes[workflowBuilder.conditionId], action)

      // add edge to condition
      newElements.push({
        id: `edge-${workflowBuilder.conditionId}-${actionId}`,
        source: workflowBuilder.conditionId,
        target: actionId
      })
    } else {
      workflowBuilder.workflowTree.addLink(workflowBuilder.workflowNodes[workflowBuilder.triggerId], action)

      // add edge to trigger
      newElements.push({
        id: `edge-${workflowBuilder.triggerId}-${actionId}`,
        source: workflowBuilder.triggerId,
        target: actionId
      })
    }

    const node: any = {
      id: actionId,
      data: { label: buildNodeLabel(NODE_TYPES.actions, eventValue) },
      position: { x: horizontalNodePosition(workflowBuilder.actionCount), y: 350 },
      type: 'output'
    }
    newElements.push(node)

    // update workflow builder redux
    const actionCount = workflowBuilder.actionCount + 1
    appDispatch(
      updateBuilder({
        builder: {
          actionCount,
          actionId,
          elements: [...workflowBuilder.elements, ...newElements],
          workflowNodes: { ...workflowBuilder.workflowNodes, [actionId]: action }
        }
      })
    )
  }, [
    appDispatch,
    buildNodeLabel,
    setIsDialogOpened,
    state.msTeamsWebhookValue,
    state.slackWebhookValue,
    workflowBuilder.actionCount,
    workflowBuilder.actionValue,
    workflowBuilder.conditionId,
    workflowBuilder.elements,
    workflowBuilder.triggerId,
    workflowBuilder.workflowNodes,
    workflowBuilder.workflowTree
  ])

  const onAddCondition = useCallback(() => {
    const newElements = []
    const eventValue = workflowBuilder.conditionValue
    const condition = new WorkflowNode(NodeConstants.CONDITION, eventValue)
    const conditionId = condition.node.uuid
    let conditionDetails = ''
    if (eventValue.indexOf('_noInput') === -1) {
      conditionDetails = `${state.operatorValue} ${state.conditionData}`
      condition.node.config = {
        conditionOperator: state.operatorValue,
        conditionValue: state.conditionData
      }
    }

    if (workflowBuilder.triggerId) {
      workflowBuilder.workflowTree.addLink(workflowBuilder.workflowNodes[workflowBuilder.triggerId], condition)

      // add edge
      newElements.push({
        id: `edge-${workflowBuilder.triggerId}-${conditionId}`,
        source: workflowBuilder.triggerId,
        target: conditionId
      })
    }
    if (workflowBuilder.actionId) {
      workflowBuilder.workflowTree.addLink(condition, workflowBuilder.workflowNodes[workflowBuilder.actionId])

      // add edge
      newElements.push({
        id: `edge-${conditionId}-${workflowBuilder.actionId}`,
        source: conditionId,
        target: workflowBuilder.actionId
      })
    }

    const node: any = {
      id: conditionId,
      data: {
        label: buildNodeLabel(NODE_TYPES.conditions, eventValue, conditionDetails)
      },
      position: { x: horizontalNodePosition(workflowBuilder.conditionCount), y: 200 }
    }
    newElements.push(node)

    const conditionCount = workflowBuilder.conditionCount + 1
    // update workflow builder redux
    appDispatch(
      updateBuilder({
        builder: {
          conditionId,
          elements: [...workflowBuilder.elements, ...newElements],
          conditionCount,
          workflowNodes: { ...workflowBuilder.workflowNodes, [conditionId]: condition }
        }
      })
    )
  }, [
    appDispatch,
    buildNodeLabel,
    state.operatorValue,
    state.conditionData,
    workflowBuilder.actionId,
    workflowBuilder.conditionCount,
    workflowBuilder.conditionValue,
    workflowBuilder.elements,
    workflowBuilder.workflowNodes,
    workflowBuilder.workflowTree,
    workflowBuilder.triggerId
  ])

  const onAddTrigger = useCallback(() => {
    const newElements = []
    const eventValue = workflowBuilder.triggerValue
    const trigger = new WorkflowNode(NodeConstants.TRIGGER, eventValue)
    const triggerId = trigger.node.uuid
    workflowBuilder.workflowTree.setRoot(trigger)

    if (workflowBuilder.conditionId) {
      workflowBuilder.workflowTree.addLink(trigger, workflowBuilder.workflowNodes[workflowBuilder.conditionId])

      // add edge
      newElements.push({
        id: `edge-${triggerId}-${workflowBuilder.conditionId}`,
        source: triggerId,
        target: workflowBuilder.conditionId
      })
    }

    if (!workflowBuilder.conditionId && workflowBuilder.actionId) {
      workflowBuilder.workflowTree.addLink(trigger, workflowBuilder.workflowNodes[workflowBuilder.actionId])

      // add edge
      newElements.push({
        id: `edge-${triggerId}-${workflowBuilder.actionId}`,
        source: triggerId,
        target: workflowBuilder.actionId
      })
    }

    const node: any = {
      id: trigger.node.uuid,
      data: { label: buildNodeLabel(NODE_TYPES.triggers, eventValue) },
      position: { x: horizontalNodePosition(workflowBuilder.triggerCount), y: 50 },
      type: 'input'
    }
    newElements.push(node)

    // update elements
    const triggerCount = workflowBuilder.triggerCount + 1
    appDispatch(
      updateBuilder({
        builder: {
          elements: [...workflowBuilder.elements, ...newElements],
          triggerCount,
          triggerId,
          workflowNodes: { ...workflowBuilder.workflowNodes, [triggerId]: trigger }
        }
      })
    )
  }, [
    appDispatch,
    buildNodeLabel,
    workflowBuilder.actionId,
    workflowBuilder.conditionId,
    workflowBuilder.elements,
    workflowBuilder.triggerCount,
    workflowBuilder.triggerValue,
    workflowBuilder.workflowNodes,
    workflowBuilder.workflowTree
  ])

  const checkWebhook = useCallback(
    (action: string) => {
      const currentSettings = accessTokenLib.getCurrentSettings()
      const currentSlackWebhook = currentSettings.forensicsAutomatedWorkflowSlackWebhook || ''
      const currentMSTeamsWebhook = currentSettings.forensicsAutomatedWorkflowMSTeamsWebhook || ''

      switch (action) {
        case EventConstants.SLACK_NOTIFICATION:
          setIsDialogOpened(currentSlackWebhook.length === 0)
          setState({ webhookSource: { name: formatWebhookMessage('labels.slack'), action } })
          break
        case EventConstants.MSTEAMS_NOTIFICATION:
          setIsDialogOpened(currentMSTeamsWebhook.length === 0)
          setState({ webhookSource: { name: formatWebhookMessage('labels.msteams'), action } })
          break
        default:
          break
      }
    },
    [accessTokenLib, formatWebhookMessage, setIsDialogOpened]
  )

  const onChangeEvent = useCallback(
    (e: any, type: string) => {
      switch (type) {
        case NODE_TYPES.actions:
          checkWebhook(e.target.value)
          updateNode(NODE_TYPES.actions, workflowBuilder.actionId, e.target.value)
          break
        case NODE_TYPES.conditions:
          setState({
            conditionData: '',
            operatorValue: '',
            conditionDataErrorText: '',
            validateConditionData: false
          })
          updateNode(NODE_TYPES.conditions, workflowBuilder.conditionId, e.target.value)
          break
        case NODE_TYPES.triggers:
          updateNode(NODE_TYPES.triggers, workflowBuilder.triggerId, e.target.value)
          break
        default:
          break
      }
    },
    [checkWebhook, updateNode, workflowBuilder.actionId, workflowBuilder.conditionId, workflowBuilder.triggerId]
  )

  const handleConditionData = useCallback(
    (e: any) => {
      if (existingWorkflow.workflowBlueprint?.uuid) {
        updateNodeConditionData(e.target.value)
      }

      setState({ conditionData: e.target.value, validateConditionData: true })
    },
    [existingWorkflow.workflowBlueprint, updateNodeConditionData]
  )

  const onCloseWebhookDialog = useCallback(() => {
    // This resets the action to 'create incident' when the webhook dialog is closed for workflow blueprints
    if (existingWorkflow.workflowBlueprint?.uuid) {
      updateNode(NODE_TYPES.actions, workflowBuilder.actionId, EventConstants.CREATE_INCIDENT)
    }

    toggleDialog()
  }, [existingWorkflow.workflowBlueprint, toggleDialog, updateNode, workflowBuilder.actionId])

  return useMemo(
    () => [
      {
        buildNodeLabel,
        conditionDataErrorText: state.conditionDataErrorText,
        displayNewIcon,
        handleConditionData,
        isValidConditionValue,
        isWorkflowBlueprint: !!existingWorkflow.workflowBlueprint?.uuid,
        onAddAction,
        onAddCondition,
        onAddTrigger,
        onChangeEvent,
        onChangeEventType: (e: any) => {
          setState({ conditionData: '', eventTypeValue: e.target.value, operatorValue: '' })
        },
        onChangeOperator: (e: any) => {
          updateNodeConditionOperator(e.target.value)
        },
        webhookDialogConfig: {
          isOpened: isDialogOpened,
          webhookSource: state.webhookSource,
          toggleDialog,
          onCloseWebhookDialog
        },
        workflowBuilderConfig: { ...state, ...workflowBuilder }
      }
    ],
    [
      buildNodeLabel,
      state,
      displayNewIcon,
      handleConditionData,
      isValidConditionValue,
      existingWorkflow.workflowBlueprint,
      onAddAction,
      onAddCondition,
      onAddTrigger,
      onChangeEvent,
      onCloseWebhookDialog,
      isDialogOpened,
      toggleDialog,
      workflowBuilder,
      updateNodeConditionOperator
    ]
  )
}
