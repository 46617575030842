import React, { useMemo } from 'react'

import { Page, View, Text, StyleSheet } from '@react-pdf/renderer'
import { snakeCase, camelCase } from 'lodash'

import { COLORS } from 'ets/configs/styles/defaults'
import baseStyles from 'ets/components/lib/PDFReport/pdfReportStyles'
import PDFFooter, { PDFFooterProps } from 'ets/components/lib/PDFReport/PDFFooter'

export interface Employee {
  id: string
  displayName: string
  email: string
  title: string
  riskType: string
  risks: string
  spFraudCount: number
  spScore: number
}

export interface Page3EmployeesConfig {
  riskLevels: {
    [key: string]: number
    allEmployees: number
    highRisk: number
    mediumRisk: number
    lowRisk: number
  }
  topCount: string
  employees: Employee[]
}

export interface Page3EmployeeProps {
  pdfConfig: Page3EmployeesConfig
  intl: any
  footerConfig: PDFFooterProps
}

const EMPLOYEES_TABLE_WIDTHS = ['3%', '20%', '21%', '17%', '11%', '16%', '12%']
const TABLE_CELL_HEIGHT = 58

const styles = StyleSheet.create({
  ...baseStyles,
  subtitle: {
    ...baseStyles.subtitle,
    marginTop: 10
  },
  tableheaderRow: baseStyles.tableheaderRow,
  countColumnHeader: {
    ...baseStyles.tableHeaderText,
    width: EMPLOYEES_TABLE_WIDTHS[0]
  },
  nameColumnHeader: {
    ...baseStyles.tableHeaderText,
    width: EMPLOYEES_TABLE_WIDTHS[1]
  },
  emailColumnHeader: {
    ...baseStyles.tableHeaderText,
    width: EMPLOYEES_TABLE_WIDTHS[2]
  },
  jobTitleColumnHeader: {
    ...baseStyles.tableHeaderText,
    width: EMPLOYEES_TABLE_WIDTHS[3]
  },
  riskLevelColumnHeader: {
    ...baseStyles.tableHeaderText,
    width: EMPLOYEES_TABLE_WIDTHS[4]
  },
  factorsColumnHeader: {
    ...baseStyles.tableHeaderText,
    width: EMPLOYEES_TABLE_WIDTHS[5]
  },
  threatsFoundColumnHeader: {
    ...baseStyles.tableHeaderText,
    width: EMPLOYEES_TABLE_WIDTHS[6]
  },
  tableRow: {
    ...baseStyles.tableRow,
    height: TABLE_CELL_HEIGHT
  },
  countColumn: {
    ...baseStyles.tableText,
    color: COLORS.PDF.LIGHT_GRAY,
    width: EMPLOYEES_TABLE_WIDTHS[0]
  },
  nameColumn: {
    ...baseStyles.tableText,
    fontWeight: 'bold',
    width: EMPLOYEES_TABLE_WIDTHS[1]
  },
  emailColumn: {
    ...baseStyles.tableText,
    width: EMPLOYEES_TABLE_WIDTHS[2]
  },
  jobTitleColumn: {
    ...baseStyles.tableText,
    width: EMPLOYEES_TABLE_WIDTHS[3]
  },
  lowRiskLevelColumn: {
    ...baseStyles.tableText,
    width: EMPLOYEES_TABLE_WIDTHS[4]
  },
  mediumRiskLevelColumn: {
    ...baseStyles.tableText,
    fontWeight: 'bold',
    width: EMPLOYEES_TABLE_WIDTHS[4]
  },
  highRiskLevelColumn: {
    ...baseStyles.tableText,
    color: COLORS.RED,
    fontWeight: 'bold',
    width: EMPLOYEES_TABLE_WIDTHS[4]
  },
  factorsColumn: {
    ...baseStyles.tableText,
    width: EMPLOYEES_TABLE_WIDTHS[5]
  },
  threatsFoundColumn: {
    ...baseStyles.tableText,
    textAlign: 'center',
    fontWeight: 'bold',
    color: COLORS.RED,
    width: EMPLOYEES_TABLE_WIDTHS[6]
  }
})

const BASE_I18N_KEY = 'ets.app.pdf.employees'
const BASE_RISK_I18N_KEY = 'ets.app'

const Page3Employees: React.FC<Page3EmployeeProps> = ({ pdfConfig, footerConfig, intl }) => {
  return useMemo(() => {
    return (
      <Page size="A4" style={styles.page}>
        <Text style={styles.title}>{intl.formatMessage({ id: `${BASE_I18N_KEY}.title` })}</Text>
        <View style={styles.summaryWrapper}>
          {['allEmployees', 'highRisk', 'mediumRisk', 'lowRisk'].map((sectionId: string) => (
            <View key={sectionId} style={styles[sectionId === 'lowRisk' ? 'summaryElem' : 'summaryElemBordered']}>
              <Text style={styles.summaryTitle}>
                {intl.formatMessage({ id: `${BASE_I18N_KEY}.${snakeCase(sectionId)}` })}
              </Text>
              <Text style={styles.summaryValue}>{pdfConfig.riskLevels[sectionId]}</Text>
            </View>
          ))}
        </View>
        <Text style={styles.subtitle}>
          {intl.formatMessage({ id: `${BASE_I18N_KEY}.subtitle` }, { count: pdfConfig.topCount })}
        </Text>
        <View style={styles.tableheaderRow}>
          <Text style={styles.countColumnHeader} />
          {['name', 'email', 'jobTitle', 'riskLevel', 'factors', 'threatsFound'].map((columnType: string) => (
            <Text key={columnType} style={styles[`${columnType}ColumnHeader`]}>
              {intl.formatMessage({ id: `${BASE_I18N_KEY}.table.${snakeCase(columnType)}` })}
            </Text>
          ))}
        </View>
        {pdfConfig.employees?.length ? (
          <View style={styles.tableView}>
            {pdfConfig.employees.map((employee: Employee, idx: number) => (
              <View key={employee.id} style={styles.tableRow}>
                <Text style={styles.countColumn}>{idx + 1}</Text>
                <Text style={styles.nameColumn}>{employee.displayName}</Text>
                <Text style={styles.emailColumn}>{employee.email}</Text>
                <Text style={styles.jobTitleColumn}>{employee.title}</Text>
                <Text style={styles[`${camelCase(employee.riskType)}LevelColumn`]}>
                  {intl.formatMessage({ id: `${BASE_RISK_I18N_KEY}.risk_levels.${employee.riskType}` })}
                </Text>
                <View style={styles.factorsColumn}>
                  {(employee.risks || '').split(',').map((risk: string) => {
                    if (risk.length) {
                      return (
                        <Text key={risk} style={styles.factorsColumn}>
                          {intl.formatMessage({
                            id: `${BASE_RISK_I18N_KEY}.high_risk_factors.${risk.toLowerCase()}`
                          })}
                        </Text>
                      )
                    }

                    return null
                  })}
                </View>
                <Text style={styles.threatsFoundColumn}>{employee.spFraudCount}</Text>
              </View>
            ))}
          </View>
        ) : (
          <View style={styles.emptyTableWrap}>
            <Text style={styles.emptyTableText}>{intl.formatMessage({ id: `${BASE_I18N_KEY}.empty_table` })}</Text>
          </View>
        )}

        <PDFFooter page={2} intl={intl} {...footerConfig} />
      </Page>
    )
  }, [pdfConfig, footerConfig, intl])
}

export default Page3Employees
