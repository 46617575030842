import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'

import { ScanStats, ScanResults, ThreatLog } from 'global/types/api/scan'
import { getScanStatus, getScanStats, getScanThreatLog } from 'global/redux/features/scan/scanApiThunks'
import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'global/redux/toolkit/api'

export interface ScanState {
  scanStatusApiStatus: ApiStatus
  scanResults: ScanResults | undefined
  stats: ScanStats
  isInitialScanStatsLoaded: boolean
  scanStatsApiStatus: ApiStatus
  threatLog: ThreatLog[]
  threatLogApiStatus: ApiStatus
}

// initialState
export const INITIAL_STATE: ScanState = {
  scanStatusApiStatus: inIdle,
  scanResults: undefined,
  stats: {} as ScanStats,
  isInitialScanStatsLoaded: false,
  scanStatsApiStatus: inIdle,
  threatLog: [],
  threatLogApiStatus: inIdle
}

const getGuestScanStatus = createAsyncThunk('SCAN/getGuestScanStatus', async function doGetGuestScanStatus(
  _,
  { dispatch }
) {
  dispatch(getScanStatus({ accessTokenId: undefined, updateAccessToken: true }))
})

/* eslint-disable no-param-reassign */
export const scanSlice = createSlice({
  name: 'SCAN',
  initialState: INITIAL_STATE,
  reducers: {
    setScan: {
      reducer: (state: ScanState, action: PayloadAction<{ scan: any }>) => {
        state.stats = action.payload.scan
      },
      prepare: (scan: any) => ({ payload: { scan } })
    },

    resetScanStatus: (state: ScanState) => {
      state.scanStatusApiStatus = INITIAL_STATE.scanStatusApiStatus
    },

    resetScanStats: (state: ScanState) => {
      state.scanStatsApiStatus = INITIAL_STATE.scanStatsApiStatus
    },

    resetScanThreatLog: (state: ScanState) => {
      state.threatLogApiStatus = INITIAL_STATE.threatLogApiStatus
    },

    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getScanStatus.pending, state => {
        state.scanStatusApiStatus = inProgress
      })
      .addCase(getScanStatus.fulfilled, (state, action) => {
        state.scanStatusApiStatus = successResponse
        state.scanResults = action.payload.scanResults
        state.stats = action.payload.scanResults.stats
        state.threatLog = action.payload.scanResults.threatLog
      })
      .addCase(getScanStatus.rejected, (state, action) => {
        state.scanStatusApiStatus = failedResponse(action.payload as string)
      })

      .addCase(getScanStats.pending, state => {
        state.scanStatsApiStatus = inProgress
      })
      .addCase(getScanStats.fulfilled, (state, action) => {
        state.scanStatsApiStatus = successResponse
        state.isInitialScanStatsLoaded = true
        state.stats = action.payload
      })
      .addCase(getScanStats.rejected, (state, action) => {
        state.scanStatsApiStatus = failedResponse(action.payload as string)
      })

      .addCase(getScanThreatLog.pending, state => {
        state.threatLogApiStatus = inProgress
      })
      .addCase(getScanThreatLog.fulfilled, (state, action) => {
        state.threatLogApiStatus = successResponse
        state.threatLog = action.payload
      })
      .addCase(getScanThreatLog.rejected, (state, action) => {
        state.threatLogApiStatus = failedResponse(action.payload as string)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { setScan, resetScanStatus, resetScanStats, resetScanThreatLog, reset } = scanSlice.actions

export { getScanStatus, getGuestScanStatus, getScanStats, getScanThreatLog }

export default scanSlice.reducer
