import { useMemo, useEffect, useCallback } from 'react'

import { getProductSerial, extendProductTrial } from 'global/redux/features/account/accountSlice'
import { Products } from 'global/types/redux/productsType'
import { SerialInformation } from 'global/types/api/accountType'

import { useAppDispatch, useAppSelector } from 'admin/redux/toolkit/hooks'
import { isFailed, isPending } from 'global/redux/toolkit/api'

export default function useScanDialogLogic(
  accountId: string,
  accessTokenId: string,
  product: Products
): [boolean, SerialInformation, () => void, boolean] {
  const {
    getProductInProgress,
    extendProductInProgress,
    productSerial,
    getProductFailed,
    extendProductFailed
  } = useAppSelector(_stores => ({
    getProductInProgress: isPending(_stores.account.getProductSerialApiStatus),
    extendProductInProgress: isPending(_stores.account.extendProductTrialApiStatus),
    productSerial: _stores.account.productSerial,
    getProductFailed: isFailed(_stores.account.getProductSerialApiStatus),
    extendProductFailed: isFailed(_stores.account.extendProductTrialApiStatus)
  }))

  const dispatch = useAppDispatch()

  // Init
  useEffect(() => {
    dispatch(getProductSerial({ accountId, accessTokenId, product }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onExtendProductTrial = useCallback(() => {
    dispatch(extendProductTrial({ accountId, accessTokenId, product }))
  }, [dispatch, accountId, accessTokenId, product])

  return useMemo(() => {
    const isLoading = getProductInProgress || extendProductInProgress
    const error = getProductFailed || extendProductFailed
    return [isLoading, productSerial, onExtendProductTrial, error]
  }, [
    getProductInProgress,
    extendProductInProgress,
    productSerial,
    onExtendProductTrial,
    getProductFailed,
    extendProductFailed
  ])
}
