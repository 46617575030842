import { useMemo, useEffect, useCallback } from 'react'

import { process } from '@progress/kendo-data-query'
import { BDSGridPagerConfig, BDSGridSortConfig } from 'global/types/dataTables/dataTables'

import { isPending, getErrorMessage } from 'global/redux/toolkit/api'
import { ColumnsConfig } from 'global/types/dataTables/columnsConfigType'
import { ExportToCsvButtonProps } from 'global/components/lib/exportToCsvButton/ExportToCsvButton'
import { IncidentEmail } from 'global/types/api/remediation'
import * as analyticsLib from 'global/lib/analytics/analyticsService'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import apiRoutes from 'sen/lib/api/apiRoutes'
import {
  update as updateRecipientsTable,
  reset as resetRecipientsTable
} from 'sen/redux/features/dataTables/incident/recipientsSlice'
import { getRecipients, resetRecipients } from 'sen/redux/features/remediation/remediationSlice'

export type ModifiedIncidentEmail = IncidentEmail

export type InProgress = boolean
export interface TableConfig {
  isLoaded: boolean
  tableData: {
    total: number
    data: ModifiedIncidentEmail[]
  }
  pageConfig: BDSGridPagerConfig
  sortConfig: BDSGridSortConfig | {}
  columns: { [key: string]: string }
  columnsConfig: { [key: string]: ColumnsConfig }
  exportToCsvConfig: ExportToCsvButtonProps
  isFlexibleTable: boolean
}
export type Error = string | undefined

export default function useRecipientsTableLogic(isInternal: boolean): [InProgress, TableConfig, Error] {
  const dispatch = useAppDispatch()
  const {
    accessTokenId,
    recipientsTable,
    recipients,
    loadedRecipientssOffsets,
    inProgress,
    isLoaded,
    activePathParams,
    error
  } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id || '',
    recipientsTable: _stores.dataTables.incident.recipients,
    recipients: _stores.remediation.recipients,
    loadedRecipientssOffsets: _stores.remediation.loadedGetRecipientsOffsets,
    inProgress: isPending(_stores.remediation.getRecipientsApiStatus),
    error: getErrorMessage(_stores.remediation.getRecipientsApiStatus),
    isLoaded: !!_stores.remediation.recipients?.accessTokenId,
    activePathParams: _stores.app.activePath?.params
  }))

  // init
  useEffect(() => {
    dispatch(getRecipients({ incidentId: activePathParams.incidentId, distinctRecipient: !isInternal }))

    return () => {
      dispatch(resetRecipients(true))
      dispatch(resetRecipientsTable())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resetTable = useCallback(() => {
    dispatch(resetRecipients())
    dispatch(getRecipients({ incidentId: activePathParams.incidentId, distinctRecipient: !isInternal }))
  }, [dispatch, activePathParams, isInternal])

  const updateTableData = useCallback(
    (changes: any = {}) => {
      const { selectedTab, ...updatedValues } = changes

      dispatch(
        updateRecipientsTable({
          ...updatedValues,
          skip: 0
        })
      )

      resetTable()
    },
    [dispatch, resetTable]
  )

  const tableData = useMemo(() => {
    const { skip, take } = recipientsTable

    const { data } = process(
      (recipients?.report?.data || []).map((report: IncidentEmail) => ({
        ...(report && {
          ...report
        })
      })),
      { skip, take }
    )

    return {
      data: data.filter(report => report.status),
      total: recipients?.report?.totalCount || 0
    }
  }, [recipients, recipientsTable])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = recipientsTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: tableData.total,
      onPageChange: (e: any) => {
        dispatch(updateRecipientsTable(e.page))

        if (!loadedRecipientssOffsets?.includes(e.page.skip)) {
          dispatch(getRecipients({ incidentId: activePathParams.incidentId, distinctRecipient: !isInternal }))
        }
      }
    }
  }, [recipientsTable, tableData, dispatch, loadedRecipientssOffsets, activePathParams, isInternal])

  const sortConfig: BDSGridSortConfig | {} = useMemo(() => {
    if (!tableData.total) {
      return {}
    }

    return {
      sortable: !inProgress && {
        allowUnsort: false,
        mode: 'single'
      },
      sort: recipientsTable.sort,
      onSortChange: (e: any) => {
        updateTableData({ sort: e.sort })
      }
    }
  }, [recipientsTable, updateTableData, tableData.total, inProgress])

  const exportToCsvConfig: ExportToCsvButtonProps = useMemo(() => {
    const exportName = isInternal
      ? analyticsLib.EVENTS.ATO_EXPORT_AS_CSV_INTERNAL_ATTACKS
      : analyticsLib.EVENTS.ATO_EXPORT_AS_CSV_EXTERNAL_ATTACKS
    return {
      getExportPath: sessionId => {
        return apiRoutes[`EXPORT_${isInternal ? 'INTERNAL' : 'EXTERNAL'}_ATTACKS_AS_CSV`].path({
          ...activePathParams,
          accessTokenId,
          sessionId
        })
      },
      exportName,
      analyticsParams: {
        accessTokenId,
        ...activePathParams
      },
      totalCount: recipients?.report?.totalCount || 0
    }
  }, [accessTokenId, activePathParams, recipients, isInternal])

  return useMemo(() => {
    return [
      inProgress,
      {
        isLoaded: isLoaded || !!error,
        tableData,
        pageConfig,
        sortConfig,
        columns: recipientsTable.GRID_COLUMNS,
        columnsConfig: recipientsTable.columnsConfig,
        exportToCsvConfig,
        isFlexibleTable: tableData.total < recipientsTable.ITEMS_PER_PAGE
      },
      error
    ]
  }, [inProgress, recipientsTable, tableData, pageConfig, sortConfig, exportToCsvConfig, isLoaded, error])
}
