import React, { useMemo } from 'react'

import { Typography, DialogContent, Grid, DialogActions, Button } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'global/lib/localization'

import DomainValues from 'sen/components/lib/dialogs/addDomainWizardDialog/steps/DomainValues'
import styles from 'sen/components/lib/dialogs/domainWizardDialog/domainWizardDialogStyles'
import { StepProps } from 'sen/components/lib/dialogs/domainWizardDialog/domainWizardDialogTypes'

const BASE_I18N_KEY = 'sen.app.domain_wizard_dialog.dmarc_invalid'

export const DomainWizardStepDmarcInvalid: React.FC<StepProps> = ({ config, events }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  return useMemo(
    () => (
      <>
        <DialogContent className={classes.dialogContent} data-step-name="dmarc-invalid">
          <Grid container direction="column">
            <Typography className={classes.dialogBiggerText}>{formatMessage('title')}</Typography>
            <Typography className={classes.dialogText}>{formatMessage('subtitle_1')}</Typography>
            <DomainValues domainValues={config.dmarcDomainValues} withTitle={false} />
            <Typography className={classes.dialogText}>{formatMessage('subtitle_2')}</Typography>
            <Grid item className={classes.dmarcRecord}>
              {config.resolvedDmarc?.entry}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="text" size="large" onClick={events.onCloseDialog}>
            {formatMessage('cancel')}
          </Button>
          <Button color="primary" variant="contained" size="large" onClick={events.onNextStep}>
            {formatMessage('check_dmarc_again')}
          </Button>
        </DialogActions>
      </>
    ),
    [classes, formatMessage, events, config]
  )
}

export default DomainWizardStepDmarcInvalid
