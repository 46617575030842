/* eslint-disable no-nested-ternary */
import React, { useMemo, useCallback } from 'react'

import { v4 as uuidv4 } from 'uuid'
import { Grid, Typography, Alert, DataTable, DataTableColumn, Button, Tooltip } from '@barracuda-internal/bds-core'
import {
  Launch as LaunchIcon,
  Block as BlockIcon,
  Checkmark as CheckmarkIcon
} from '@barracuda-internal/bds-core/dist/Icons/Core'
import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'

import { useFormatMessage, useErrorFormatMessage } from 'global/lib/localization'
import SearchField from 'global/components/lib/searchField/SearchField'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import Pager from 'global/components/lib/grid/pager/Pager'
import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'
import { DmarcSource, DmarcSourceTypes } from 'global/types/api/dmarc'

import { SectionsProps } from 'sen/components/pages/domainFraudDmarcReview/domainFraudDmarcReviewTypes'
import styles from 'sen/components/pages/domainFraudDmarcReview/content/domainFraudDmarcReviewContentStyles'
import useUnknownTabLogic, {
  TableConfig
} from 'sen/components/pages/domainFraudDmarcReview/content/sections/tabs/useUnknownTabLogic'
import InvestigateDmarcDialog from 'sen/components/lib/dialogs/investigateDmarc/InvestigateDmarcDialog'

const BASE_I18N_KEY = 'sen.app.domain_fraud_dmarc_review.sources.tab_contents.unknown'
const BASE_I18N_TABLE_KEY = 'sen.app.data_tables.dmarc_sources'

const UnknownTab: React.FC<SectionsProps> = ({ domain }) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const tableFormatMessage = useFormatMessage(BASE_I18N_TABLE_KEY)
  const errorFormatMessage = useErrorFormatMessage()
  const [logicConfig, eventHandlers] = useUnknownTabLogic({ domain })

  const dmarcSourcesTable = useCallback(
    (tableConfig: TableConfig, type: DmarcSourceTypes, tableRef = null) => (
      <>
        {!tableConfig.isLoaded && (
          <Grid container className={classes.reservedTableArea} data-field={`reserved-table-area-${type}`} />
        )}

        {tableConfig.isLoaded && (
          <Grid
            container
            direction="column"
            className={classes.tableWrapper}
            data-field={`domains-table-wrapper-${type}`}
          >
            <Grid
              ref={tableRef}
              className={`${tableConfig.isFlexibleTable ? classes.flexibleTableArea : classes.tableArea} ${
                !tableConfig.tableData.total ? 'empty-table' : ''
              }`}
              data-table={`${type}-volume-sources`}
              item
              xs={12}
              data-field={`grid-table-${type}`}
            >
              <DataTable
                data-testid={`${type}-volume-sources-table`}
                data-field={`data-table-${type}`}
                className={`${
                  tableConfig.tableData.total
                    ? tableConfig.isFlexibleTable
                      ? classes.flexibleDataTable
                      : classes.dataTable
                    : classes.emptyDataTable
                } alerts-table`}
                data={tableConfig.tableData}
                {...tableConfig.pageConfig}
                pager={Pager}
              >
                <DataTableNoRecords data-field="empty-table">
                  <Typography>{!tableConfig.tableData.total && tableFormatMessage('empty_table')}</Typography>
                </DataTableNoRecords>

                <DataTableColumn
                  field={tableConfig.columns.HOST}
                  {...tableConfig.columnsConfig[tableConfig.columns.HOST]}
                  title={' '}
                  cell={({ dataItem }: { dataItem: DmarcSource }) => (
                    <Cell className={classes.cell} data-field={`table-column-host-${type}`}>
                      {!dataItem.inProgressAction && (
                        <CellText ellipsis className={classes.normalCell} data-field={`table-column-host-text-${type}`}>
                          {!dataItem.product && (
                            <Tooltip title={dataItem.host} placement="top-start">
                              <data>{dataItem.host}</data>
                            </Tooltip>
                          )}
                          {dataItem.product && (
                            <Tooltip
                              title={`${dataItem.product.name} (${dataItem.host})`}
                              placement="top-start"
                              data-field={`product-tooltip-${type}`}
                            >
                              <data>
                                {dataItem.product.name}&nbsp;
                                <data className={classes.lightText}>({dataItem.host})</data>
                                &nbsp;
                                <br />
                                <data
                                  role="presentation"
                                  onClick={() => {
                                    eventHandlers.onVisitSource(dataItem.product.website)
                                  }}
                                  className={classes.linkText}
                                  data-action={`visit-source-${type}`}
                                >
                                  {formatMessage('visit_source')}&nbsp;
                                  <LaunchIcon className={classes.linkIcon} />
                                </data>
                              </data>
                            </Tooltip>
                          )}
                        </CellText>
                      )}

                      {!!dataItem.inProgressAction && (
                        <CellText
                          ellipsis
                          className={classes.normalCell}
                          data-field={`table-column-email-marked-as-${type}`}
                        >
                          <Tooltip
                            title={
                              <>
                                {!dataItem.product && dataItem.host}
                                {dataItem.product && dataItem.product.name}
                                &nbsp;
                                {formatMessage('marked_as', {
                                  status: formatMessage(`progress_status_${dataItem.inProgressAction}`)
                                })}
                              </>
                            }
                            placement="top-start"
                          >
                            <data>
                              {!dataItem.product && dataItem.host}
                              {dataItem.product && dataItem.product.name}
                              &nbsp;
                              {formatMessage('marked_as', {
                                status: (
                                  <b key={uuidv4()}>{formatMessage(`progress_status_${dataItem.inProgressAction}`)}</b>
                                )
                              })}
                            </data>
                          </Tooltip>
                        </CellText>
                      )}
                    </Cell>
                  )}
                />

                <DataTableColumn
                  width={150}
                  field={tableConfig.columns.EMAIL_COUNT}
                  {...tableConfig.columnsConfig[tableConfig.columns.EMAIL_COUNT]}
                  title={' '}
                  cell={({ dataItem }: { dataItem: DmarcSource }) => (
                    <Cell className={classes.cell} data-field={`table-column-email-count-${type}`}>
                      <CellText className={classes.normalCell} data-field={`table-column-email-count-text-${type}`}>
                        {!dataItem.inProgressAction && dataItem.emailCount.toLocaleString()}
                      </CellText>
                    </Cell>
                  )}
                />

                <DataTableColumn
                  width={380}
                  field={tableConfig.columns.ACTIONS}
                  {...tableConfig.columnsConfig[tableConfig.columns.ACTIONS]}
                  title={' '}
                  cell={({ dataItem }: { dataItem: DmarcSource }) => (
                    <Cell className={classes.cell} data-field={`table-column-actions-${type}`}>
                      <CellText className={classes.actionCell} data-field={`table-column-email-actions-${type}`}>
                        {!dataItem.inProgressAction && (
                          <Grid container direction="column" justifyContent="flex-end">
                            <Button
                              className={classes.investigateButton}
                              size="small"
                              color="primary"
                              variant="text"
                              onClick={() => {
                                eventHandlers.onOpenInvestigateDialog(dataItem)
                              }}
                              data-action={`open-investigate-dialog-${type}`}
                            >
                              {formatMessage('investigate')}
                            </Button>
                            <Grid item>
                              <Button
                                disabled={logicConfig.isMarkInProgress}
                                size="small"
                                color="primary"
                                variant="outlined"
                                onClick={() => {
                                  eventHandlers.onMarkAsRejected(dataItem, type)
                                }}
                                data-action={`mark-as-rejected-${type}`}
                              >
                                <BlockIcon className={classes.blockIcon} />
                                {formatMessage('mark_as_rejected')}
                              </Button>
                              &nbsp;&nbsp;
                              <Button
                                disabled={logicConfig.isMarkInProgress}
                                size="small"
                                color="primary"
                                variant="outlined"
                                onClick={() => {
                                  eventHandlers.onMarkAsApproved(dataItem, type)
                                }}
                                data-action={`mark-as-approved-${type}`}
                              >
                                <CheckmarkIcon className={classes.checkmarIcon} />
                                {formatMessage('mark_as_approved')}
                              </Button>
                            </Grid>
                          </Grid>
                        )}

                        {!!dataItem.inProgressAction && (
                          <Grid container direction="row" justifyContent="flex-end">
                            <Button
                              disabled={logicConfig.isMarkInProgress}
                              size="small"
                              color="primary"
                              variant="text"
                              onClick={() => {
                                eventHandlers.onUndo(dataItem, type)
                              }}
                              data-action={`undo-${type}`}
                            >
                              {formatMessage('undo')}
                            </Button>
                          </Grid>
                        )}
                      </CellText>
                    </Cell>
                  )}
                />
              </DataTable>
            </Grid>
          </Grid>
        )}
      </>
    ),
    [classes, tableFormatMessage, eventHandlers, formatMessage, logicConfig]
  )

  return useMemo(() => {
    return (
      <Grid container direction="column">
        {logicConfig.error && (
          <Alert className={classes.tabMargin} severity="error" data-field="dmarc-review-unknown-alert">
            {errorFormatMessage(logicConfig.error)}
          </Alert>
        )}

        <Grid container direction="column">
          <Grid item className={classes.tabInfoContainer} xs>
            <Typography className={classes.tabInfoTitle}>{formatMessage('information_title')}</Typography>
            <Typography className={classes.tabInfoSubtitle}>{formatMessage('information_subtitle')}</Typography>
          </Grid>

          <Grid item className={classes.searchfieldContainer}>
            <SearchField
              {...logicConfig.searchFieldConfig}
              placeholder={formatMessage('search_placeholder')}
              data-field="search-field"
            />
          </Grid>

          <Grid item className={classes.tabMargin}>
            <Typography data-first-custom-header="true" className={classes.customHeaderTitle}>
              {tableFormatMessage('host')}
            </Typography>
            <Typography className={classes.customHeaderTitle}>
              {tableFormatMessage('email_count', {
                br: (txt: string) => (
                  <data key={txt}>
                    <br />
                    {txt}
                  </data>
                )
              })}
            </Typography>
          </Grid>

          <Grid className={classes.tableTitleContainer} container direction="column">
            {logicConfig.dmarcHighVolumeSourcesTableConfig.inProgress && (
              <LinearProgress className={classes.tableTitleProgress} data-field="linear-progress-high" />
            )}
            <Typography className={classes.tableTitle}>
              {formatMessage('high_volume_sources', {
                count:
                  logicConfig.dmarcHighVolumeSourcesTableConfig.tableData.total -
                  logicConfig.dmarcHighVolumeSourcesTableConfig.markedSources
              })}
            </Typography>
          </Grid>
          {dmarcSourcesTable(
            logicConfig.dmarcHighVolumeSourcesTableConfig,
            DmarcSourceTypes.high,
            logicConfig.dmarcSourcesTableRef
          )}

          <Grid className={classes.tableTitleSecondContainer} container direction="column">
            {logicConfig.dmarcLowVolumeSourcesTableConfig.inProgress && (
              <LinearProgress className={classes.tableTitleProgress} data-field="linear-progress-low" />
            )}
            <Typography className={classes.tableTitle}>
              {formatMessage('low_volume_sources', {
                count:
                  logicConfig.dmarcLowVolumeSourcesTableConfig.tableData.total -
                  logicConfig.dmarcLowVolumeSourcesTableConfig.markedSources
              })}

              <Button
                className={classes.titleButton}
                disabled={logicConfig.isMarkInProgress}
                size="small"
                color="secondary"
                variant="outlined"
                onClick={eventHandlers.onMarkAllAsRejected}
              >
                <BlockIcon className={classes.blockIcon} />
                {formatMessage('mark_all_as_rejected', {
                  count:
                    logicConfig.dmarcLowVolumeSourcesTableConfig.tableData.total -
                    logicConfig.dmarcLowVolumeSourcesTableConfig.markedSources
                })}
              </Button>
            </Typography>
          </Grid>
          {dmarcSourcesTable(logicConfig.dmarcLowVolumeSourcesTableConfig, DmarcSourceTypes.low)}
        </Grid>

        {logicConfig.investigateDialogConfig.isOpen && logicConfig.investigateDialogConfig.source && (
          <InvestigateDmarcDialog
            domain={domain}
            dmarcSource={logicConfig.investigateDialogConfig.source}
            onClose={logicConfig.investigateDialogConfig.toggle}
          />
        )}
      </Grid>
    )
  }, [
    classes,
    formatMessage,
    logicConfig,
    errorFormatMessage,
    tableFormatMessage,
    dmarcSourcesTable,
    eventHandlers,
    domain
  ])
}

export default UnknownTab
