import { useReducer, useMemo, useCallback, useState, useEffect } from 'react'

import { useEffectOnlyOnce, useEffectOnInit } from 'global/lib/useCustomEffect'

import { HourRanges, DateRangeProps } from 'global/components/lib/dateRangeSelector/DateRangeSelector'
import DEFAULTS from 'global/configs/theme/defaults'

import { isSuccess, isPending, isFailed, getErrorMessage } from 'global/redux/toolkit/api'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'

import useRecipientsAutocompleteLogic, {
  RecipientsAutocompleteConfig,
  SelectedRecipient
} from 'sen/components/lib/autocompletes/identity/recipients/useRecipientsAutocompleteLogic'

import useNewIncidentDialogWizardLogic, {
  ButtonStatus
} from 'sen/components/lib/dialogs/newIncidentDialog/useNewIncidentDialogWizardLogic'

import { getRecipients } from 'sen/redux/features/identity/identitySlice'
import { resetCompromisedAccount } from 'sen/redux/features/ato/atoSlice'
import { searchForIncidentEmails } from 'sen/redux/features/remediation/remediationSlice'

export interface NewIncidentFormConfig {
  handleSubject: (e: any) => void
  emailSubject: string | ''
  emailSubjectLength: number
  isSubjectDisabled: boolean
  handleSubjectCheckbox: (e: any) => void
  isNoSubjectChecked: boolean
  isSubjectValid: boolean
  timeframe: number
  error: boolean
  errorMsg?: string | undefined
  noData: boolean
}

export interface RecipientsSelectorProps {
  selectedRecipient: SelectedRecipient
  config: RecipientsAutocompleteConfig
}

export interface State {
  isInitialDataLoaded: boolean
  emailSubject: string
  isNoSubjectChecked: boolean
  isSubjectFieldTouched: boolean
}

export type OnDispatch = () => void
export type InProgress = boolean
export type ActiveStep = number

export const MAX_LENGTH = 200

const DATE_SELECTOR_RANGES = [
  HourRanges['1Hour'],
  HourRanges['12hours'],
  HourRanges.lastDay,
  HourRanges.lastWeek,
  HourRanges.lastMonth
]

// emails matching incident
export const INCIDENT_QUERY = {
  EMAILS: { page: 1, limit: 5 },
  INITIAL_EMAILS: { page: 1, limit: 200 },
  INTERNAL_RECIPIENTS: { page: 1, limit: 5, filter: ['isInternal=true'] },
  EXTERNAL_RECIPIENTS: { page: 1, limit: 1000, filter: ['isInternal=false'] },
  EXTERNAL_RECIPIENTS_LIST: { page: 1, limit: 5, filter: ['isInternal=false'] }
}

export default function useNewIncidentFormLogic(): [
  ButtonStatus,
  NewIncidentFormConfig,
  RecipientsSelectorProps,
  DateRangeProps,
  InProgress
] {
  const [state, setState] = useReducer((_state: State, newState: Partial<State>) => ({ ..._state, ...newState }), {
    isInitialDataLoaded: false,
    emailSubject: '',
    isNoSubjectChecked: false,
    isSubjectFieldTouched: false
  })
  const {
    totalIncidentEmails,
    searchForIncidentEmailsIsPending,
    searchForIncidentEmailsIsLoaded,
    searchForIncidentEmailsIsFailed,
    incidentSearchErrorMsg,
    compromisedAccount,
    isAlert
  } = useAppSelector(_stores => ({
    totalIncidentEmails: _stores.remediation?.incidentsEmails?.report?.totalCount,
    searchForIncidentEmailsIsPending: isPending(_stores.remediation?.searchForIncidentEmailsApiStatus),
    searchForIncidentEmailsIsLoaded: isSuccess(_stores.remediation?.searchForIncidentEmailsApiStatus),
    searchForIncidentEmailsIsFailed: isFailed(_stores.remediation?.searchForIncidentEmailsApiStatus),
    incidentSearchErrorMsg: getErrorMessage(_stores.remediation?.searchForIncidentEmailsApiStatus),
    compromisedAccount: _stores.ato.compromisedAccount,
    formValues: _stores.remediation.incidentEmailSearchFormValues,
    isAlert: !!_stores.ato.compromisedAccount
  }))
  const currentStep = 1
  const currentPage = 'New Incident Form Page'
  const dispatch = useAppDispatch()
  const [multiStepConfig] = useNewIncidentDialogWizardLogic()

  const [recipientsAutocompleteConfig, selectedRecipient] = useRecipientsAutocompleteLogic({
    shouldSetInititalIdentity: !!isAlert,
    isRequired: true
  })

  const [selectedRange, setSelectedRange] = useState<number>(4)

  // init
  useEffectOnInit(() => {
    return () => {
      // dispatch(resetCompromisedAccount())
    }
  }, [])

  useEffect(() => {
    if (isAlert) {
      if (compromisedAccount?.sampleSubject) {
        setState({ emailSubject: compromisedAccount.sampleSubject })
      } else {
        setState({ isNoSubjectChecked: true })
      }

      const defaultRecipient = compromisedAccount?.emailAddress || ''

      dispatch(getRecipients(defaultRecipient))
    }
  }, [isAlert, compromisedAccount, dispatch])

  // render the dialog when the initial recipient is set
  useEffectOnlyOnce(
    () => {
      setState({ isInitialDataLoaded: true })
    },
    [selectedRecipient?.id],
    !!selectedRecipient?.id
  )

  // new incident form
  const timeframe = useMemo(() => {
    return DATE_SELECTOR_RANGES[selectedRange]
  }, [selectedRange])

  const handleSubject = useCallback((e: any) => {
    setState({
      emailSubject: e.target.value,
      isSubjectFieldTouched: true
    })
  }, [])

  const handleSubjectCheckbox = useCallback((e: any) => {
    setState({ isNoSubjectChecked: e.target.checked })
    if (e.target.checked) {
      setState({ emailSubject: '', isSubjectFieldTouched: false })
    }
  }, [])

  const isSubjectValid = useMemo(() => {
    return !state.isSubjectFieldTouched || (state.isSubjectFieldTouched && !!state.emailSubject.length)
  }, [state.isSubjectFieldTouched, state.emailSubject])

  const senderEmail: string | undefined = useMemo(() => {
    return selectedRecipient?.email
  }, [selectedRecipient])

  const isValidForm = useMemo(() => {
    return senderEmail && (state.emailSubject || state.isNoSubjectChecked) && state.emailSubject.length <= MAX_LENGTH
  }, [senderEmail, state.emailSubject, state.isNoSubjectChecked])

  const onDispatchNextStep = useCallback(() => {
    // trim whitespace on enter
    setState({ emailSubject: state.emailSubject.trim() })

    dispatch(
      searchForIncidentEmails({
        senderEmail,
        emailSubject: state.emailSubject,
        timeframe,
        query: INCIDENT_QUERY.INITIAL_EMAILS
      })
    )
    multiStepConfig.onNextStep(currentStep, currentPage, 'Search for incident emails')
  }, [dispatch, senderEmail, state.emailSubject, timeframe, multiStepConfig])

  const onDispatchPrevStep = useCallback(() => {
    dispatch(resetCompromisedAccount())

    multiStepConfig.onPrevStep(currentStep, currentPage)
  }, [multiStepConfig, dispatch])

  return useMemo(() => {
    return [
      {
        disabled: searchForIncidentEmailsIsPending,
        disableNext: !isValidForm,
        cancel: 'cancel',
        onPrev: 'back',
        onNext: 'next',
        onNextStep: onDispatchNextStep,
        onPrevStep: onDispatchPrevStep
      },
      {
        handleSubject,
        emailSubject: state.emailSubject,
        emailSubjectLength: state.emailSubject.length,
        isSubjectDisabled: !state.emailSubject,
        timeframe: DATE_SELECTOR_RANGES[selectedRange],
        handleSubjectCheckbox,
        isNoSubjectChecked: state.isNoSubjectChecked,
        isSubjectValid,
        error: searchForIncidentEmailsIsFailed,
        errorMsg: incidentSearchErrorMsg,
        noData: searchForIncidentEmailsIsLoaded && !totalIncidentEmails
      },
      {
        selectedRecipient,
        config: recipientsAutocompleteConfig
      },
      {
        ranges: DATE_SELECTOR_RANGES,
        defaultRange: selectedRange,
        onSelectRange: setSelectedRange,
        zIndex: DEFAULTS.SECONDARY_DIALOGS_ZINDEX
      },
      searchForIncidentEmailsIsPending
    ]
  }, [
    onDispatchNextStep,
    onDispatchPrevStep,
    isValidForm,
    handleSubject,
    state,
    handleSubjectCheckbox,
    isSubjectValid,
    searchForIncidentEmailsIsFailed,
    incidentSearchErrorMsg,
    recipientsAutocompleteConfig,
    selectedRecipient,
    selectedRange,
    setSelectedRange,
    searchForIncidentEmailsIsLoaded,
    totalIncidentEmails,
    searchForIncidentEmailsIsPending
  ])
}
