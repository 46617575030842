import { makeStyles } from '@material-ui/core/styles'
import { COLORS } from 'global/configs/theme/defaults'

import dialogStyles from 'global/components/lib/dialogs/dialogStyles'

export default makeStyles(theme => {
  const defaultDialogStyles = dialogStyles(theme)

  const subtitle = {
    marginBottom: theme.spacing(1)
  }

  return {
    ...defaultDialogStyles,
    subtitle,
    cleanUpMessage: {
      ...subtitle,
      marginTop: theme.spacing(1),
      color: COLORS.ERROR
    }
  }
})
