import React from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress
} from '@barracuda-internal/bds-core'

import styles from 'ets/components/lib/dialogs/newScanDialog/newScanDialogStyles'

import DEFAULTS from 'ets/configs/styles/defaults'
import { useFormatMessage } from 'global/lib/localization'

const BASE_I18N_KEY = 'ets.app.dialogs.new_scan'

export interface NewScanDialogProps {
  toggleDialog: () => void
  isDialogOpened: boolean
  onNewScan: () => void
  isNewScanInProgress: boolean
  scanName?: string
}

export const NewScanDialog: React.FC<NewScanDialogProps> = ({
  isDialogOpened,
  toggleDialog,
  onNewScan,
  isNewScanInProgress,
  scanName
}) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  return (
    <div data-field="dialog-container">
      <Dialog
        style={{ zIndex: DEFAULTS.DIALOGS_ZINDEX }}
        classes={{ paper: classes.paper }}
        open={isDialogOpened}
        onClose={toggleDialog}
        data-field="dialog"
      >
        <DialogTitle disableTypography id="form-dialog-title" data-field="title">
          <Typography data-field="new-scan" className={classes.title}>
            {formatMessage('title')}
          </Typography>
        </DialogTitle>
        <DialogContent data-field="content" style={{ overflow: 'hidden' }}>
          <DialogContentText data-field="content-text" className={classes.description}>
            {formatMessage('description', { name: scanName, b: (text: string) => <b key={text}>{text}</b> })}
          </DialogContentText>
        </DialogContent>
        <DialogActions data-field="dialog-actions">
          <Button
            className={classes.secondary}
            variant="text"
            onClick={toggleDialog}
            color="primary"
            disabled={isNewScanInProgress}
            data-action="cancel-new-scan"
          >
            {formatMessage('cancel')}
          </Button>
          <Button
            className={classes.primary}
            variant="contained"
            onClick={onNewScan}
            color="primary"
            disabled={isNewScanInProgress}
            data-action="run-new-scan"
          >
            {formatMessage('start')}
          </Button>
          {isNewScanInProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
        </DialogActions>
      </Dialog>
    </div>
  )
}

NewScanDialog.propTypes = {
  isDialogOpened: PropTypes.bool.isRequired,
  onNewScan: PropTypes.func.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  isNewScanInProgress: PropTypes.bool.isRequired,
  scanName: PropTypes.string
}

NewScanDialog.defaultProps = {
  scanName: undefined
}

export default NewScanDialog
