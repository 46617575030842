/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react'

import {
  Button,
  DialogContent,
  DialogActions,
  Grid,
  DataTable,
  DataTableColumn,
  Typography,
  Tooltip
} from '@barracuda-internal/bds-core'
import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'

import { useFormatMessage } from 'global/lib/localization'
import Pager from 'global/components/lib/grid/pager/Pager'
import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'
import { DmarcSamplePreview } from 'global/types/api/dmarc'
import { formatDate } from 'global/lib/datetime'
import { getCountryName } from 'global/lib/isoCountries'
import CircularProgress from 'global/components/lib/circularProgress/CircularProgress'
import { BASE_IMG_PATH } from 'global/configs/theme/images.config'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import EmailDetailsDialog from 'global/components/lib/dialogs/emailDetailsDialog/EmailDetailsDialog'

import styles from 'sen/components/lib/dialogs/investigateDmarc/investigateDmarcDialogStyles'
import rufSamplesStyles from 'sen/components/lib/dialogs/investigateDmarc/views/rufSamplesStyles'
import * as T from 'sen/components/lib/dialogs/investigateDmarc/investigateDmarcDialogTypes'
import useRufSamplesLogic from 'sen/components/lib/dialogs/investigateDmarc/views/useRufSamplesLogic'

const BASE_I18N_KEY = 'sen.app.investigate_dmarc_dialog'
const BASE_I18N_TABLE_KEY = 'sen.app.data_tables.ruf_samples_table'

export const RufSamples: React.FC<T.RufSamplesProps> = ({ domain, dmarcSource, onBack, onClose }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const tableFormatMessage = useFormatMessage(BASE_I18N_TABLE_KEY)
  const classes = styles()
  const rufSamplesClasses = rufSamplesStyles()
  const [logicConfig, eventHandlers, useEmailDetailsRufSampleInterface] = useRufSamplesLogic({ domain, dmarcSource })
  const [emailDetailDialogConfig, emailDetailDialogActions] = useEmailDetailsRufSampleInterface

  return useMemo(
    () => (
      <>
        <DialogContent className={classes.dialogContent}>
          <Grid container>
            {!logicConfig.tableConfig.isLoaded && (
              <Grid container justifyContent="center" className={classes.loading}>
                <CircularProgress />
              </Grid>
            )}

            {logicConfig.tableConfig.isLoaded && (
              <Grid
                container
                direction="column"
                className={rufSamplesClasses.tableWrapper}
                data-field="domains-table-wrapper"
              >
                <Grid
                  className={`${
                    logicConfig.tableConfig.isFlexibleTable
                      ? rufSamplesClasses.flexibleTableArea
                      : rufSamplesClasses.tableArea
                  } ${!logicConfig.tableConfig.tableData.total ? 'empty-table' : ''}`}
                  data-table="ruf-samples"
                  item
                  xs={12}
                  data-field="grid-table"
                >
                  {logicConfig.tableConfig.inProgress && <LinearProgress />}

                  <DataTable
                    data-testid="ruf-samples-table"
                    className={`${
                      logicConfig.tableConfig.tableData.total
                        ? logicConfig.tableConfig.isFlexibleTable
                          ? rufSamplesClasses.flexibleDataTable
                          : rufSamplesClasses.dataTable
                        : rufSamplesClasses.emptyDataTable
                    } alerts-table`}
                    data={logicConfig.tableConfig.tableData}
                    {...logicConfig.tableConfig.pageConfig}
                    pager={Pager}
                  >
                    <DataTableNoRecords data-field="empty-table">
                      <Typography>
                        {!logicConfig.tableConfig.tableData.total && tableFormatMessage('empty_table')}
                      </Typography>
                    </DataTableNoRecords>

                    <DataTableColumn
                      width={150}
                      field={logicConfig.tableConfig.columns.DATE}
                      {...logicConfig.tableConfig.columnsConfig[logicConfig.tableConfig.columns.DATE]}
                      title={tableFormatMessage(`${logicConfig.tableConfig.columns.DATE}`)}
                      cell={({ dataItem }: { dataItem: DmarcSamplePreview }) => (
                        <Cell className={rufSamplesClasses.cell} data-field="table-column-date">
                          <CellText
                            ellipsis
                            className={rufSamplesClasses.normalCell}
                            data-field="table-column-date-text"
                          >
                            {formatDate(dataItem.date)}
                          </CellText>
                        </Cell>
                      )}
                    />

                    <DataTableColumn
                      field={logicConfig.tableConfig.columns.SUBJECT}
                      {...logicConfig.tableConfig.columnsConfig[logicConfig.tableConfig.columns.SUBJECT]}
                      title={tableFormatMessage(`${logicConfig.tableConfig.columns.SUBJECT}`)}
                      cell={({ dataItem }: { dataItem: DmarcSamplePreview }) => (
                        <Cell className={rufSamplesClasses.cell} data-field="table-column-subject">
                          <CellText
                            ellipsis
                            className={rufSamplesClasses.normalCell}
                            data-field="table-column-subject-text"
                          >
                            <Tooltip title={dataItem.subject} placement="top-start">
                              <data>{dataItem.subject}</data>
                            </Tooltip>
                          </CellText>
                        </Cell>
                      )}
                    />

                    <DataTableColumn
                      width={220}
                      field={logicConfig.tableConfig.columns.ORIGIN_COUNTRY}
                      {...logicConfig.tableConfig.columnsConfig[logicConfig.tableConfig.columns.ORIGIN_COUNTRY]}
                      title={tableFormatMessage(`${logicConfig.tableConfig.columns.ORIGIN_COUNTRY}`)}
                      cell={({ dataItem }: { dataItem: DmarcSamplePreview }) => (
                        <Cell className={rufSamplesClasses.cell} data-field="table-column-origin-country">
                          <CellText
                            className={rufSamplesClasses.normalCell}
                            data-field="table-column-origin-country-text"
                          >
                            <img
                              src={`${BASE_IMG_PATH}flags/${dataItem.country.toLowerCase()}.svg`}
                              width="10"
                              height="10"
                              alt=""
                            />
                            &nbsp;&nbsp;
                            {getCountryName(dataItem.country)}
                          </CellText>
                        </Cell>
                      )}
                    />

                    <DataTableColumn
                      width={150}
                      field={logicConfig.tableConfig.columns.NUMBER_OF_EMAILS}
                      {...logicConfig.tableConfig.columnsConfig[logicConfig.tableConfig.columns.NUMBER_OF_EMAILS]}
                      title={tableFormatMessage(`${logicConfig.tableConfig.columns.NUMBER_OF_EMAILS}`)}
                      cell={({ dataItem }: { dataItem: DmarcSamplePreview }) => (
                        <Cell className={rufSamplesClasses.cell} data-field="table-column-number-of-emails">
                          <CellText
                            className={rufSamplesClasses.normalCell}
                            data-field="table-column-number-of-emails-text"
                          >
                            {dataItem.emailCount.toLocaleString()}
                          </CellText>
                        </Cell>
                      )}
                    />

                    <DataTableColumn
                      width={100}
                      field={logicConfig.tableConfig.columns.ACTIONS}
                      {...logicConfig.tableConfig.columnsConfig[logicConfig.tableConfig.columns.ACTIONS]}
                      title={' '}
                      cell={({ dataItem }: { dataItem: DmarcSamplePreview }) => (
                        <Cell className={rufSamplesClasses.cell} data-field="table-column-actions">
                          <CellText className={rufSamplesClasses.normalCell} data-field="table-column-email-actions">
                            <Button
                              size="large"
                              color="primary"
                              variant="text"
                              onClick={() => {
                                eventHandlers.onViewEmail(dataItem)
                              }}
                            >
                              {formatMessage('view')}
                            </Button>
                          </CellText>
                        </Cell>
                      )}
                    />
                  </DataTable>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button color="primary" variant="text" size="large" onClick={onBack}>
            &nbsp;&nbsp;{formatMessage('back')}&nbsp;&nbsp;
          </Button>

          <Button color="primary" variant="contained" size="large" onClick={onClose}>
            &nbsp;&nbsp;{formatMessage('close')}&nbsp;&nbsp;
          </Button>
        </DialogActions>

        {emailDetailDialogActions.open && (
          <EmailDetailsDialog data={emailDetailDialogConfig} buttonText={formatMessage('close')} />
        )}
      </>
    ),
    [
      classes,
      formatMessage,
      onClose,
      onBack,
      eventHandlers,
      logicConfig,
      rufSamplesClasses,
      tableFormatMessage,
      emailDetailDialogConfig,
      emailDetailDialogActions
    ]
  )
}

export default RufSamples
