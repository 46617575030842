import React from 'react'

import { Typography, Grid, AppBar, Toolbar } from '@barracuda-internal/bds-core'
import { EpImpersonationProtectionRev } from '@barracuda-internal/bds-core/dist/Logos/Products'

import FreeTrialDialog from 'ets/components/lib/dialogs/freeTrialDialog/FreeTrialDialog'

import { useFormatMessage } from 'global/lib/localization'
import styles from './freeTrialBannerStyles'

const BASE_I18N_KEY = 'ets.app.dashboard.banner'

const FreeTrialBanner: React.FC<any> = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  return (
    <div className={classes.root}>
      <AppBar position="fixed" elevation={0} className={classes.appBar}>
        <Grid container item alignItems="center" justifyContent="center">
          <Toolbar>
            <Grid
              container
              item
              alignItems="center"
              justifyContent="center"
              direction="row"
              data-field="ets-footer"
              className={classes.footer}
            >
              <Grid item xs={4} container>
                <EpImpersonationProtectionRev className={classes.barracudaLogoWhite} alt="" />
              </Grid>
              <Grid item xs container>
                <Typography className={classes.text}>{formatMessage('text')}</Typography>
              </Grid>
              <Grid item xs container justifyContent="flex-end">
                <FreeTrialDialog />
              </Grid>
            </Grid>
          </Toolbar>
        </Grid>
      </AppBar>
    </div>
  )
}

export default FreeTrialBanner
