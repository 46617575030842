import React, { useMemo, useState } from 'react'
import { DateTime } from 'luxon'

import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon'

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
  TextField,
  Typography,
  Zoom
} from '@barracuda-internal/bds-core'
import { DateRangePicker } from '@cuda/bds.ui.components.date-range-picker'

import { DateRangeConfig } from 'global/components/lib/dataTable/DataTable'
import { config } from 'global/lib/config'
import { useFormatMessage } from 'global/lib/localization'
import { useEffectOnInit } from 'global/lib/useCustomEffect'

import styles from './saveReportDialogStyle'

interface SaveReportDialogProp {
  dataRetentionWarning: {
    dataLossDate: string
    shouldShowWarning: boolean
  }
  dateRangeConfig: DateRangeConfig
  onClose: () => void
  onSave: () => void
  onUpdate: (e: any) => void
  open: boolean
  reportName: string
  shouldDisableSaveButton: boolean
}

const BASE_I18N_KEY = 'app.unified_reporting.save_report_dialog'
const DATE_RANGE_I18N_KEY = 'app.unified_reporting.date_range_picker'

const SaveReportDialog: React.FC<SaveReportDialogProp> = ({
  dataRetentionWarning,
  dateRangeConfig,
  open = false,
  onClose,
  onSave,
  onUpdate,
  reportName,
  shouldDisableSaveButton
}) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const dateRangeFormatMessage = useFormatMessage(DATE_RANGE_I18N_KEY)
  const [shouldShowDateRangePicker, setShouldShowDateRangePicker] = useState(false)

  // Keep the DateRangePicker in the dialog even when error is gone.
  useEffectOnInit(() => {
    if (dateRangeConfig.errorMessage === formatMessage('date_range_empty_date_error')) {
      setShouldShowDateRangePicker(true)
    }
  }, [dateRangeConfig.errorMessage, formatMessage])

  return useMemo(
    () => (
      <Dialog maxWidth="sm" fullWidth open={open} TransitionComponent={Zoom}>
        <DialogTitle>
          <Typography className={classes.saveReportDialogTitle}>{formatMessage('dialogTitle')}</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography className={classes.saveReportDialogSubtitle} variant="body1">
            {formatMessage('dialogSubtitle')}&nbsp;&nbsp;
            <Link target="_blank" href={config.LINKS.CAMPUS_UNIFIED_REPORTING}>
              {formatMessage('learn_more')}
            </Link>
          </Typography>
          <TextField fullWidth label={formatMessage('label')} defaultValue={reportName} onChange={onUpdate} />
          {shouldShowDateRangePicker && (
            <>
              <Typography className={classes.dateRangePickerWarningText}>
                {formatMessage('date_range_warning_text')}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DateRangePicker
                  value={dateRangeConfig.dateRange}
                  onChange={dateRangeConfig.onDateRangeChange}
                  singleInput
                  label={dateRangeConfig.label}
                  maxDate={dateRangeConfig.maxDate}
                  minDate={dateRangeConfig.minDate}
                  selectedShortcutLabel={dateRangeConfig.selectedShortcutLabel}
                  error={dateRangeConfig.error}
                  errorMessage={dateRangeConfig.errorMessage}
                  shortcutsItems={[
                    {
                      label: dateRangeFormatMessage('last_24_hours'),
                      getValue: () => {
                        const today = DateTime.now()
                        return [today.minus({ days: 1 }), today]
                      }
                    },
                    {
                      label: dateRangeFormatMessage('last_7_days'),
                      getValue: () => {
                        const today = DateTime.now()
                        return [today.minus({ days: 7 }), today]
                      }
                    },
                    {
                      label: dateRangeFormatMessage('last_30_days'),
                      getValue: () => {
                        const today = DateTime.now()
                        return [today.minus({ days: 30 }), today]
                      }
                    },
                    {
                      label: dateRangeFormatMessage('last_90_days'),
                      getValue: () => {
                        const today = DateTime.now()
                        return [today.minus({ days: 90 }), today]
                      }
                    },
                    {
                      label: dateRangeFormatMessage('last_6_months'),
                      getValue: () => {
                        const today = DateTime.now()
                        return [today.minus({ days: 180 }), today]
                      }
                    },
                    { label: 'Reset', getValue: () => [null, null] }
                  ]}
                />
              </LocalizationProvider>
            </>
          )}
          {dataRetentionWarning?.shouldShowWarning && (
            <div className={classes.dataRetentionWarningContainer}>
              <Typography className={classes.dataRetentionWarningText} component="span">
                {formatMessage('dataRetentionWarning', {
                  dataLossDate: dataRetentionWarning?.dataLossDate
                })}
              </Typography>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button size="large" color="primary" variant="text" onClick={onClose}>
            {formatMessage('buttons.close')}
          </Button>
          <Button
            className={classes.button}
            color="primary"
            disabled={shouldDisableSaveButton}
            variant="contained"
            size="large"
            onClick={onSave}
          >
            {formatMessage('buttons.save')}
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [
      classes.button,
      classes.dataRetentionWarningContainer,
      classes.dataRetentionWarningText,
      classes.dateRangePickerWarningText,
      classes.dialogContent,
      classes.saveReportDialogSubtitle,
      classes.saveReportDialogTitle,
      dateRangeFormatMessage,
      dataRetentionWarning,
      dateRangeConfig.dateRange,
      dateRangeConfig.error,
      dateRangeConfig.errorMessage,
      dateRangeConfig.label,
      dateRangeConfig.maxDate,
      dateRangeConfig.minDate,
      dateRangeConfig.onDateRangeChange,
      dateRangeConfig.selectedShortcutLabel,
      formatMessage,
      onClose,
      onSave,
      onUpdate,
      open,
      reportName,
      shouldDisableSaveButton,
      shouldShowDateRangePicker
    ]
  )
}

export default SaveReportDialog
