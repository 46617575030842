import { useMemo, useCallback, useEffect, useState, useRef } from 'react'
import { DateTime } from 'luxon'

import { config } from 'global/lib/config'
import { isSuccess, isPending, getErrorMessage } from 'global/redux/toolkit/api'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { getReportingRecord, dmarcIsConfiguredThroughBarracuda } from 'global/lib/domain/dmarc'
import { isTimedOut } from 'global/lib/datetime'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import {
  resolveSpf,
  setupDmarc,
  resolveDmarc,
  resetResolveSpf,
  resetSetupDmarc,
  resetResolvedDmarc
} from 'sen/redux/features/dmarc/dmarcSlice'
import {
  DomainWizardDialogProps,
  DialogConfig,
  DialogEvents,
  WizardSteps,
  StepperSteps
} from 'sen/components/lib/dialogs/domainWizardDialog/domainWizardDialogTypes'

const REFRESH_FREQ = 5000
let inProgress = false

export const SPF_RECORD = 'v=spf1 include:spf.protection.outlook.com ~all'

export default function useAddDomainWizardDialogLogic({
  onClose,
  domain
}: DomainWizardDialogProps): [DialogConfig, DialogEvents] {
  const {
    resolvedSpf,
    isResolveSpfInProgress,
    isResolveSpfLoaded,
    resolveSpfError,
    isSetupDmarcInProgress,
    isSetupDmarcSuccess,
    setupDmarcError,
    resolvedDmarc,
    isResolveDmarcInProgress,
    isResolveDmarcLoaded,
    resolveDmarcError
  } = useAppSelector(_stores => ({
    resolvedSpf: _stores.dmarc.resolvedSpf,
    isResolveSpfInProgress: isPending(_stores.dmarc.resolveSpfApiStatus),
    isResolveSpfLoaded: isSuccess(_stores.dmarc.resolveSpfApiStatus),
    resolveSpfError: getErrorMessage(_stores.dmarc.resolveSpfApiStatus),
    isSetupDmarcInProgress: isPending(_stores.dmarc.setupDmarcApiStatus),
    isSetupDmarcSuccess: isSuccess(_stores.dmarc.setupDmarcApiStatus),
    setupDmarcError: getErrorMessage(_stores.dmarc.setupDmarcApiStatus),
    resolvedDmarc: _stores.dmarc.resolvedDmarc,
    isResolveDmarcInProgress: isPending(_stores.dmarc.resolveDmarcApiStatus),
    isResolveDmarcLoaded: isSuccess(_stores.dmarc.resolveDmarcApiStatus),
    resolveDmarcError: getErrorMessage(_stores.dmarc.resolveDmarcApiStatus)
  }))
  const dispatch = useAppDispatch()
  const [wizardStep, setWizardStep] = useState<WizardSteps>(WizardSteps.init)
  const [spfCheckUntil, setSpfCheckUntil] = useState<DateTime | undefined>()
  const [dmarcCheckUntil, setDmarcCheckUntil] = useState<DateTime | undefined>()
  const [isDomainModified, setDomainModified] = useState(false)
  const interval = useRef<ReturnType<typeof setInterval> | null>()

  const clearPeriodicCheck = useCallback(() => {
    inProgress = false
    setSpfCheckUntil(undefined)
    setDmarcCheckUntil(undefined)

    if (interval.current) {
      clearInterval(interval.current)
    }
  }, [])

  // init
  useEffect(() => {
    return () => {
      dispatch(resetResolveSpf())
      dispatch(resetSetupDmarc())
      dispatch(resetResolvedDmarc())
      clearPeriodicCheck()
      setWizardStep(WizardSteps.init)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // resolveSpf check when finished
  useEffect(() => {
    const isOverTimed = isTimedOut(spfCheckUntil)
    inProgress = isResolveSpfInProgress
    if (resolveSpfError) {
      setWizardStep(WizardSteps.init)
      clearPeriodicCheck()
    } else if (isResolveSpfLoaded && (resolvedSpf?.isSpfRecord || isOverTimed)) {
      if (resolvedSpf?.isSpfRecord) {
        setWizardStep(WizardSteps.spfCheckSuccess)
      } else {
        setWizardStep(WizardSteps.spfCheckFailure)
      }

      clearPeriodicCheck()
    }
  }, [isResolveSpfInProgress, spfCheckUntil, isResolveSpfLoaded, resolvedSpf, clearPeriodicCheck, resolveSpfError])

  // resolveDmarc check when finished
  useEffect(() => {
    const isOverTimed = isTimedOut(dmarcCheckUntil)
    inProgress = isResolveDmarcInProgress
    const dmarcIsConfigured = resolvedDmarc && dmarcIsConfiguredThroughBarracuda(resolvedDmarc)
    if (resolveDmarcError) {
      setWizardStep(WizardSteps.dmarcCheck)
      clearPeriodicCheck()
    } else if (isResolveDmarcLoaded && (dmarcIsConfigured || isOverTimed)) {
      if (dmarcIsConfigured) {
        setWizardStep(WizardSteps.dmarcCheckSuccess)
      } else if (resolvedDmarc?.isDmarcRecord) {
        setWizardStep(WizardSteps.dmarcInvalid)
      } else {
        setWizardStep(WizardSteps.dmarcNotFound)
      }

      clearPeriodicCheck()
    }
  }, [
    isResolveDmarcInProgress,
    dmarcCheckUntil,
    isResolveDmarcLoaded,
    resolvedDmarc,
    clearPeriodicCheck,
    resolveDmarcError
  ])

  // setupDmarc is finished
  useEffect(() => {
    if (isSetupDmarcSuccess) {
      setWizardStep(WizardSteps.dmarcSetup)
    } else if (setupDmarcError) {
      setWizardStep(WizardSteps.spfCheckSuccess)
    }
  }, [isSetupDmarcSuccess, setupDmarcError])

  const resolveSpfPeriodically = useCallback(() => {
    if (!inProgress) {
      dispatch(resolveSpf(domain.name))
    }
  }, [dispatch, domain])

  const resolveDmarcPeriodically = useCallback(() => {
    if (!inProgress) {
      dispatch(resolveDmarc(domain.name))
    }
  }, [dispatch, domain])

  const onCloseDialog = useCallback(() => {
    onClose(isDomainModified)
  }, [onClose, isDomainModified])

  const onNextStep = useCallback(() => {
    setDomainModified(true)
    switch (wizardStep) {
      case WizardSteps.init:
      case WizardSteps.spfCheckFailure:
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.DF_DOMAINS_WIZARD_CHECK_SPF, { domain })
        setWizardStep(WizardSteps.spfCheck)
        setSpfCheckUntil(DateTime.local().plus({ minutes: 1 }))
        dispatch(resolveSpf(domain.name))
        inProgress = true
        interval.current = setInterval(resolveSpfPeriodically, REFRESH_FREQ)
        break
      case WizardSteps.spfCheckSuccess:
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.DF_DOMAINS_WIZARD_PREPARE_DMARC_RECORD, { domain })
        setWizardStep(WizardSteps.dmarcPrep)
        dispatch(setupDmarc(domain.name))
        break
      case WizardSteps.dmarcSetup:
      case WizardSteps.dmarcInvalid:
      case WizardSteps.dmarcNotFound:
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.DF_DOMAINS_WIZARD_CHECK_DMARC, { domain })
        setWizardStep(WizardSteps.dmarcCheck)
        setDmarcCheckUntil(DateTime.local().plus({ minutes: 1 }))
        dispatch(resolveDmarc(domain.name))
        inProgress = true
        interval.current = setInterval(resolveDmarcPeriodically, REFRESH_FREQ)
        break
      default:
    }
  }, [wizardStep, resolveSpfPeriodically, resolveDmarcPeriodically, domain, dispatch])

  const stepperStep = useMemo(() => {
    switch (wizardStep) {
      case WizardSteps.init:
      case WizardSteps.spfCheck:
      case WizardSteps.spfCheckFailure:
        return StepperSteps.spfCheck
      case WizardSteps.spfCheckSuccess:
      case WizardSteps.dmarcPrep:
      case WizardSteps.dmarcSetup:
      case WizardSteps.dmarcCheck:
      case WizardSteps.dmarcInvalid:
      case WizardSteps.dmarcNotFound:
        return StepperSteps.dmarcCheck
      case WizardSteps.dmarcCheckSuccess:
        return StepperSteps.success
      default:
        return StepperSteps.spfCheck
    }
  }, [wizardStep])

  const getRegistrarUrlForDomain = useCallback(() => {
    return `${config.LINKS.DOMAIN_REGISTAR_SECTION}${domain.name}`
  }, [domain])

  return useMemo(() => {
    return [
      {
        wizardStep,
        stepperStep,
        inProgress: !!spfCheckUntil || !!dmarcCheckUntil || isSetupDmarcInProgress,
        error: resolveSpfError || setupDmarcError || resolveDmarcError,
        getRegistrarUrlForDomain,
        spfDomainValues: {
          txtDomain: domain.name,
          txtRecord: SPF_RECORD
        },
        dmarcDomainValues: {
          txtDomain: domain.name,
          txtRecord: getReportingRecord(domain)
        },
        spfRecord: resolvedSpf,
        resolvedDmarc
      },
      {
        onCloseDialog,
        onNextStep
      }
    ]
  }, [
    stepperStep,
    wizardStep,
    onNextStep,
    onCloseDialog,
    spfCheckUntil,
    dmarcCheckUntil,
    getRegistrarUrlForDomain,
    domain,
    resolvedSpf,
    resolveSpfError,
    setupDmarcError,
    isSetupDmarcInProgress,
    resolvedDmarc,
    resolveDmarcError
  ])
}
