import { useMemo } from 'react'

import { useAppSelector } from 'ets/redux/toolkit/hooks'

export interface SigninConnectLogicProps {
  error: string | undefined
}

export default function useSigninConnectLogic(): [SigninConnectLogicProps] {
  const { error } = useAppSelector(_stores => ({
    error: _stores.auth.error
  }))

  return useMemo(() => {
    return [{ error }]
  }, [error])
}
