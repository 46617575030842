import { RemediationIncidentFields } from '@barracuda/reporting'

import { Column } from 'global/types/api/unifiedReporting'

export type RemediationIncident = {
  maliciousDomainCount: number
  raQuarantine?: boolean
  raDelete?: boolean
  raNotify?: boolean
  raAddSenderPolicy?: boolean
  raAddDomainPolicy?: boolean
  raAddBcsPolicy?: boolean
  raSendSummary?: boolean
  raEnableContinuousRemediation?: boolean
  senderPoliciesCount: number
  senderPolicyType?: string
  uuid: string
  createdOn: string
  accountId: string
  accessTokenId: string
  subjectQuery: string
  senderDisplayName: string
  senderEmail: string
  createdBy?: string
  type: string
  remediationActions?: string
  senderPolicies?: string
  attachmentQuery?: string
  maliciousDomains?: string
  continuousRemediationUntil?: string
  createdByName?: string
  bodyTextQuery?: string
  bodyLinksQuery?: string
}

export const remediationIncidentColumnConfig: Column[] = [
  {
    accessorKey: RemediationIncidentFields.CREATED_ON.name,
    header: 'created_on',
    fieldType: RemediationIncidentFields.CREATED_ON.type,
    possibleValues: RemediationIncidentFields.CREATED_ON.possibleValues,
    category: 'general',
    hideColumnFromList: true
  },
  {
    accessorKey: RemediationIncidentFields.CREATED_BY.name,
    header: 'created_by',
    fieldType: RemediationIncidentFields.CREATED_BY.type,
    possibleValues: RemediationIncidentFields.CREATED_BY.possibleValues,
    category: 'general'
  },
  {
    accessorKey: RemediationIncidentFields.CREATED_BY_NAME.name,
    header: 'created_by_name',
    fieldType: RemediationIncidentFields.CREATED_BY_NAME.type,
    possibleValues: RemediationIncidentFields.CREATED_BY_NAME.possibleValues,
    category: 'general'
  },
  {
    accessorKey: RemediationIncidentFields.SENDER_EMAIL.name,
    header: 'sender_email',
    fieldType: RemediationIncidentFields.SENDER_EMAIL.type,
    possibleValues: RemediationIncidentFields.SENDER_EMAIL.possibleValues,
    category: 'search_criteria'
  },
  {
    accessorKey: RemediationIncidentFields.SENDER_DISPLAY_NAME.name,
    header: 'sender_display_name',
    fieldType: RemediationIncidentFields.SENDER_DISPLAY_NAME.type,
    possibleValues: RemediationIncidentFields.SENDER_DISPLAY_NAME.possibleValues,
    category: 'search_criteria'
  },
  {
    accessorKey: RemediationIncidentFields.SUBJECT_QUERY.name,
    header: 'subject_query',
    fieldType: RemediationIncidentFields.SUBJECT_QUERY.type,
    possibleValues: RemediationIncidentFields.SUBJECT_QUERY.possibleValues,
    category: 'search_criteria'
  },
  {
    accessorKey: RemediationIncidentFields.ATTACHMENT_QUERY.name,
    header: 'attachment_query',
    fieldType: RemediationIncidentFields.ATTACHMENT_QUERY.type,
    possibleValues: RemediationIncidentFields.ATTACHMENT_QUERY.possibleValues,
    category: 'search_criteria'
  },
  {
    accessorKey: RemediationIncidentFields.BODY_TEXT_QUERY.name,
    header: 'body_text_query',
    fieldType: RemediationIncidentFields.BODY_TEXT_QUERY.type,
    possibleValues: RemediationIncidentFields.BODY_TEXT_QUERY.possibleValues,
    category: 'search_criteria'
  },
  {
    accessorKey: RemediationIncidentFields.BODY_LINKS_QUERY.name,
    header: 'body_links_query',
    fieldType: RemediationIncidentFields.BODY_LINKS_QUERY.type,
    possibleValues: RemediationIncidentFields.BODY_LINKS_QUERY.possibleValues,
    category: 'search_criteria'
  },
  {
    accessorKey: RemediationIncidentFields.RA_NOTIFY.name,
    header: 'ra_notify',
    fieldType: RemediationIncidentFields.RA_NOTIFY.type,
    possibleValues: RemediationIncidentFields.RA_NOTIFY.possibleValues,
    category: 'incident_options'
  },
  {
    accessorKey: RemediationIncidentFields.RA_DELETE.name,
    header: 'delete',
    fieldType: RemediationIncidentFields.RA_DELETE.type,
    possibleValues: RemediationIncidentFields.RA_DELETE.possibleValues,
    category: 'incident_options'
  },
  {
    accessorKey: RemediationIncidentFields.RA_QUARANTINE.name,
    header: 'quarantine',
    fieldType: RemediationIncidentFields.RA_QUARANTINE.type,
    possibleValues: RemediationIncidentFields.RA_QUARANTINE.possibleValues,
    category: 'incident_options'
  },
  {
    accessorKey: RemediationIncidentFields.CR_EVER_ENABLED.name,
    header: 'cr_ever_enabled',
    fieldType: RemediationIncidentFields.CR_EVER_ENABLED.type,
    possibleValues: RemediationIncidentFields.CR_EVER_ENABLED.possibleValues,
    category: 'incident_options'
  },
  {
    accessorKey: RemediationIncidentFields.RA_SEND_SUMMARY.name,
    header: 'ra_send_summary',
    fieldType: RemediationIncidentFields.RA_SEND_SUMMARY.type,
    possibleValues: RemediationIncidentFields.RA_SEND_SUMMARY.possibleValues,
    category: 'incident_options'
  },
  {
    accessorKey: RemediationIncidentFields.SENDER_POLICY_TYPE.name,
    header: 'sender_policy_type',
    fieldType: RemediationIncidentFields.SENDER_POLICY_TYPE.type,
    possibleValues: RemediationIncidentFields.SENDER_POLICY_TYPE.possibleValues,
    category: 'egd_policy'
  },
  {
    accessorKey: RemediationIncidentFields.SENDER_POLICIES_COUNT.name,
    header: 'sender_policies_count',
    fieldType: RemediationIncidentFields.SENDER_POLICIES_COUNT.type,
    possibleValues: RemediationIncidentFields.SENDER_POLICIES_COUNT.possibleValues,
    category: 'egd_policy'
  },
  {
    accessorKey: RemediationIncidentFields.SENDER_POLICIES.name,
    header: 'sender_policies',
    fieldType: RemediationIncidentFields.SENDER_POLICIES.type,
    possibleValues: RemediationIncidentFields.SENDER_POLICIES.possibleValues,
    category: 'egd_policy'
  },
  {
    accessorKey: RemediationIncidentFields.RA_ADD_BCS_POLICY.name,
    header: 'ra_add_bcs_policy',
    fieldType: RemediationIncidentFields.RA_ADD_BCS_POLICY.type,
    possibleValues: RemediationIncidentFields.RA_ADD_BCS_POLICY.possibleValues,
    category: 'cloudgen_policy'
  },
  {
    accessorKey: RemediationIncidentFields.MALICIOUS_DOMAIN_COUNT.name,
    header: 'malicious_domain_count',
    fieldType: RemediationIncidentFields.MALICIOUS_DOMAIN_COUNT.type,
    possibleValues: RemediationIncidentFields.MALICIOUS_DOMAIN_COUNT.possibleValues,
    category: 'cloudgen_policy'
  },
  {
    accessorKey: RemediationIncidentFields.MALICIOUS_DOMAINS.name,
    header: 'malicious_domains',
    fieldType: RemediationIncidentFields.MALICIOUS_DOMAINS.type,
    possibleValues: RemediationIncidentFields.MALICIOUS_DOMAINS.possibleValues,
    category: 'cloudgen_policy'
  }
]
