import React, { useEffect, useMemo } from 'react'

import GlobalStartTrial from 'global/components/features/startTrial/StartTrial'
import { useFormatMessage } from 'global/lib/localization'
import productLib from 'global/lib/product/product'
import { useAppSelector } from 'global/redux/toolkit/hooks'

import Layout from 'sen/components/lib/layout/Layout'
import routesConfig from 'sen/lib/routes/routesConfig'

const BASE_I18N_KEY = 'sen.app.start_trial'

const StartTrial: React.FC = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const { accessToken } = useAppSelector((_stores: any) => ({
    accessToken: _stores.accessToken?.accessToken
  }))

  useEffect(() => {
    if (productLib.hasSentinelProduct(accessToken?.id)) {
      routesConfig.DASHBOARD.goto({ reportId: accessToken?.id })
    }
  }, [accessToken])

  return useMemo(
    () => (
      <Layout pageTitle={formatMessage('page_title')}>
        <GlobalStartTrial BASE_I18N_KEY={BASE_I18N_KEY} />
      </Layout>
    ),
    [formatMessage]
  )
}

export default StartTrial
