/* eslint-disable no-nested-ternary */
import React from 'react'
import PropTypes from 'prop-types'

import {
  Typography,
  Button,
  Alert,
  Dialog,
  DialogContent,
  DialogActions,
  Zoom,
  Grid
} from '@barracuda-internal/bds-core'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import DEFAULTS from 'global/configs/theme/defaults'
import { useErrorFormatMessage, useFormatMessage } from 'global/lib/localization'
import { ScanAccessToken, ScanResults } from 'global/types/api/scan'
import { config } from 'global/lib/config'
import { Products } from 'global/types/redux/productsType'
import { formatDate, formatTime } from 'global/lib/datetime'

import styles from 'admin/components/lib/dialogs/scanDialog/scanDialogStyles'
import useScanDialogLogic from 'admin/components/lib/dialogs/scanDialog/useScanDialogLogic'

export interface ScanDialogProps {
  scan: ScanResults
  accessToken?: ScanAccessToken
  product: Products
  onClose: () => void
}

const BASE_I18N_KEY = 'admin.app.dialogs.scan_dialog'

export const ScanDialog: React.FC<ScanDialogProps> = ({ scan, accessToken, product, onClose }) => {
  const [isLoading, productSerial, onExtendProductTrial, error] = useScanDialogLogic(
    scan.accountId,
    scan.accessTokenId,
    product
  )
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatErrorMessage = useErrorFormatMessage()
  const classes = styles()

  return (
    <Dialog
      style={{ zIndex: DEFAULTS.SECONDARY_DIALOGS_ZINDEX }}
      maxWidth="md"
      fullWidth
      open
      TransitionComponent={Zoom}
      data-field="dialog"
    >
      {error && (
        <Alert data-field="error" variant="filled" severity="error">
          {formatErrorMessage('default')}
        </Alert>
      )}
      {isLoading && <LinearProgress data-field="loader" />}
      {!isLoading && (
        <DialogContent data-field="dialog-content">
          <Typography className={classes.title} data-field="dialog-title">
            {formatMessage('title')}
          </Typography>
          <Grid className={classes.contentRow}>
            <Typography className={classes.label} data-field="dialog-serial-label">
              {formatMessage('serial_number')}:{' '}
            </Typography>
            <Typography className={classes.value} data-field="dialog-serial-value">
              {productSerial?.serialNumber
                ? productSerial.serialNumber
                : productSerial?.substate === config.PRODUCTS.SUBSTATES.TRIAL
                ? formatMessage('trial')
                : 'N/A'}
            </Typography>
          </Grid>
          <Grid className={classes.contentRow}>
            <Typography className={classes.label} data-field="dialog-expire-label">
              {formatMessage('serial_expire')}:{' '}
            </Typography>
            <Typography className={classes.value} data-field="dialog-expire-value">
              {productSerial?.expiry
                ? `${formatDate(productSerial.expiry)} ${formatTime(productSerial.expiry)}`
                : 'N/A'}
              {productSerial?.substate === config.PRODUCTS.SUBSTATES.TRIAL ? (
                <span
                  className={classes.link}
                  role="presentation"
                  data-field="dialog-expire-action"
                  onClick={onExtendProductTrial}
                >
                  {' '}
                  {formatMessage('extend_trial')}
                </span>
              ) : null}
            </Typography>
          </Grid>
          <Grid className={classes.contentRow}>
            <Typography className={classes.label} data-field="dialog-office-name-label">
              {formatMessage('office_365_name')}:{' '}
            </Typography>
            <Typography className={classes.value} data-field="dialog-office-name-value">
              {scan.accessTokenName}
            </Typography>
          </Grid>
          <Grid className={classes.contentRow}>
            <Typography className={classes.label} data-field="dialog-office-app-label">
              {formatMessage('office_365_app')}:{' '}
            </Typography>
            <Typography className={classes.value} data-field="dialog-office-app-value">
              {accessToken?.providerApp}
            </Typography>
          </Grid>
          <Grid className={classes.contentRow}>
            <Typography className={classes.label} data-field="dialog-account-label">
              {formatMessage('account_id')}:{' '}
            </Typography>
            <Typography className={classes.value} data-field="dialog-account-value">
              {scan.accountId}
            </Typography>
          </Grid>
          <Grid className={classes.contentRow}>
            <Typography className={classes.label} data-field="dialog-token-label">
              {formatMessage('access_token_id')}:{' '}
            </Typography>
            <Typography className={classes.value} data-field="dialog-token-value">
              {scan.accessTokenId}
            </Typography>
          </Grid>
          <Grid className={classes.contentRow}>
            <Typography className={classes.label} data-field="dialog-scan-label">
              {formatMessage('scan_id')}:{' '}
            </Typography>
            <Typography className={classes.value} data-field="dialog-scan-value">
              {scan.id}
            </Typography>
          </Grid>
          <Grid className={classes.contentRow}>
            <Typography className={classes.label} data-field="dialog-bcc-id-label">
              {formatMessage('bcc_account_id')}:{' '}
            </Typography>
            <Typography className={classes.value} data-field="dialog-bcc-id-value">
              {scan.accountBccId || 'N/A'}
            </Typography>
          </Grid>
          <Grid className={classes.contentRow}>
            <Typography className={classes.label} data-field="dialog-bcc-name-label">
              {formatMessage('bcc_account_name')}:{' '}
            </Typography>
            <Typography className={classes.value} data-field="dialog-bcc-name-value">
              {scan.accountName || 'N/A'}
            </Typography>
          </Grid>
        </DialogContent>
      )}
      <DialogActions className={classes.actions}>
        <Button color="primary" variant="contained" size="large" onClick={onClose} data-field="dialog-dismiss-button">
          &nbsp;&nbsp;{formatMessage('dismiss')}&nbsp;&nbsp;
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ScanDialog.propTypes = {
  scan: PropTypes.any.isRequired,
  accessToken: PropTypes.any.isRequired,
  product: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired
}

export default ScanDialog
