import React, { useMemo } from 'react'
import ReactFrame from 'react-frame-component'
import reactHTMLParser from 'react-html-parser'

import {
  Button,
  TextField,
  Typography,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Zoom
} from '@barracuda-internal/bds-core'

import FormControl from '@material-ui/core/FormControl'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import { useFormatMessage } from 'global/lib/localization'
import useCustomizeEmailNotificationLogic, { UseCustomizeEmailParams } from './useCustomizeEmailNotificationLogic'
import styles from './customizeEmailNotificationStyles'

const BASE_I18N_KEY = 'fir.app.customize_email_notification'

const DEFAULT_NAME = 'Barracuda Forensics'
const DEFAULT_EMAIL = '<noreply@barracuda.com>'

type CustomizeEmailProps = UseCustomizeEmailParams

const CustomizeEmailNotification: React.FC<CustomizeEmailProps> = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const [customizeEmailNotificationLogic] = useCustomizeEmailNotificationLogic()

  return useMemo(() => {
    const {
      errorMsg = '',
      formIntroduction,
      formSignature,
      formSubject,
      handleResetToDefault,
      handleSaveSettings,
      isNotificationPreviewLoading,
      onClose,
      onIntroChange,
      onSignatureChange,
      onSubjectChange,
      previewBody,
      previewSubject
    } = customizeEmailNotificationLogic

    return (
      <Dialog fullWidth maxWidth="lg" open TransitionComponent={Zoom}>
        <DialogTitle>
          <Typography variant="h5" component="span">
            {formatMessage('title')}
          </Typography>
          <Typography className={classes.subtitle} variant="subtitle2" component="div">
            {formatMessage('sub_title')}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.content}>
          {!!errorMsg.length && (
            <div className={classes.errorMessage}>
              <Alert variant="filled" severity="error">
                {errorMsg}
              </Alert>
            </div>
          )}
          <FormControl className={classes.formControl} component="fieldset">
            <TextField
              placeholder={formatMessage('form_labels.subject_placeholder')}
              className={classes.formLine}
              label={formatMessage('form_labels.subject')}
              value={formSubject}
              onChange={onSubjectChange}
            />
            <TextField
              multiline
              placeholder={formatMessage('form_labels.intro_placeholder')}
              className={classes.formLine}
              label={formatMessage('form_labels.intro')}
              value={formIntroduction}
              onChange={onIntroChange}
            />
            <TextField
              multiline
              placeholder={formatMessage('form_labels.signature_placeholder')}
              className={classes.formLine}
              label={formatMessage('form_labels.signature')}
              value={formSignature}
              onChange={onSignatureChange}
            />
          </FormControl>
        </DialogContent>
        <DialogTitle className={classes.previewContent}>
          <Typography variant="h5" component="div">
            {formatMessage('email_notification_preview.title')}
          </Typography>
          <Typography className={classes.previewLabel} variant="subtitle2" component="div">
            <span className={classes.emailLabel}>{formatMessage('email_notification_preview.from')}:</span>
            <span className={classes.emailValue}>{DEFAULT_NAME}</span>&nbsp;
            <span className={classes.greyText}>{DEFAULT_EMAIL}</span>
          </Typography>
          <Typography className={classes.previewLabel} variant="subtitle2" component="div">
            <span className={classes.emailLabel}>{formatMessage('email_notification_preview.subject')}:</span>
            <span className={classes.emailValue}>{previewSubject}</span>
          </Typography>
        </DialogTitle>
        {isNotificationPreviewLoading && <LinearProgress />}
        <ReactFrame id="customize-alert-modal" className={classes.frameContent}>
          {reactHTMLParser(previewBody)}
        </ReactFrame>
        <DialogActions>
          <Button onClick={onClose} variant="text" color="primary">
            {formatMessage('buttons.cancel')}
          </Button>
          <Button onClick={handleResetToDefault} variant="outlined" color="primary">
            {formatMessage('buttons.reset_to_default')}
          </Button>
          <Button onClick={handleSaveSettings} color="primary" variant="contained" disabled={!!errorMsg}>
            {formatMessage('buttons.save')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }, [classes, customizeEmailNotificationLogic, formatMessage])
}

export default CustomizeEmailNotification
