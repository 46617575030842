import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Box } from '@barracuda-internal/bds-core'

import styles from 'global/components/features/signinSignup/signinSignupStylesNew'

export interface SigninSignupWrapperProp {
  children: any
}

const SigninSignupWrapper: React.FC<SigninSignupWrapperProp> = ({ children }) => {
  const classes = styles()

  return useMemo(
    () => (
      <>
        <Box className={classes.loginWrapper}>{children}</Box>
      </>
    ),
    [children, classes]
  )
}

SigninSignupWrapper.propTypes = {
  children: PropTypes.any.isRequired
}

export default SigninSignupWrapper
