import { createSlice } from '@reduxjs/toolkit'

import { Column, Filter } from 'global/types/api/unifiedReporting'

// TODO: update this with the different types created in BNFIR-2398
interface UnifiedReportingState {
  absoluteDateRangeEnd: string | null
  absoluteDateRangeStart: string | null
  columns: Column[]
  filters: Filter[]
  groupBy: string[]
  hiddenColumns: string[]
  page: {
    skip: number
    take: number
  }
  relativeDateRange: string | null
  searchQuery: string
  sortBy: string[]
  topDisplayCount: number
}

// Store
export const INITIAL_STATE: UnifiedReportingState = {
  absoluteDateRangeEnd: null,
  absoluteDateRangeStart: null,
  columns: [],
  filters: [],
  groupBy: [],
  hiddenColumns: [],
  page: { skip: 0, take: 15 },
  relativeDateRange: 'LAST_24_HOURS',
  searchQuery: '',
  sortBy: [],
  topDisplayCount: 0
}

type UpdatePayload = Partial<UnifiedReportingState>

export const unifiedReportingSlice = createSlice({
  name: 'DATATABLE/UNIFIED_REPORTING',
  initialState: INITIAL_STATE,
  reducers: {
    update: (state, action: { payload: UpdatePayload }) => {
      return {
        ...state,
        ...action.payload
      }
    },
    resetSearch: state => {
      // eslint-disable-next-line no-param-reassign
      state.searchQuery = INITIAL_STATE.searchQuery
    },
    reset: () => {
      return { ...INITIAL_STATE }
    }
  }
})

export const { update, resetSearch, reset } = unifiedReportingSlice.actions

export default unifiedReportingSlice.reducer
