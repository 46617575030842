import { createSlice } from '@reduxjs/toolkit'
import {
  EssAccount,
  IncidentClicks,
  IncidentEmails,
  Incidents,
  IncidentUsers,
  NewIncident
} from 'fir/redux/types/Remediation'
import insertToArray from 'global/lib/insertToArray'
import { ApiStatus, failedResponse, inIdle, inProgress, successResponse } from 'global/redux/toolkit/api'
import { Incident, IncidentTag } from 'global/types/api/remediation'
import {
  forensicsCreateIncident,
  forensicsCreateIncidentTags,
  forensicsDeleteIncidentTag,
  forensicsGetEssAccount,
  forensicsGetIncident,
  forensicsGetIncidentClickedLinks,
  forensicsGetIncidentDetails,
  forensicsGetIncidentEmails,
  forensicsGetIncidents,
  forensicsGetIncidentsTags,
  forensicsGetAllIncidentUserDetails,
  forensicsGetIncidentUserDetails,
  forensicsGetMoreIncidentClickedLinks,
  forensicsGetMoreIncidentEmails,
  forensicsGetMoreIncidents,
  forensicsGetMoreIncidentUserDetails,
  forensicsIncidentDeleteExistingEmails,
  forensicsRestartContinuousRemediation,
  forensicsStopContinuousRemediation
} from './remediationApiThunks'

export interface RemediationState {
  clickedLinksByIncident: IncidentClicks
  essAccount: EssAccount
  tags: IncidentTag[]
  createdIncident: NewIncident
  emailsByIncident: IncidentEmails
  incident: Incident
  incidentDetails: any
  incidents: Incidents
  userDetailsByIncident: IncidentUsers
  allUserDetailsByIncident: IncidentUsers
  continuousRemediationApiStatus: ApiStatus
  createIncidentApiStatus: ApiStatus
  createIncidentTagsApiStatus: ApiStatus
  deleteIncidentTagApiStatus: ApiStatus
  getEssAccountApiStatus: ApiStatus
  getIncidentApiStatus: ApiStatus
  getIncidentDetailsApiStatus: ApiStatus
  getAllIncidentUserDetailsApiStatus: ApiStatus
  getIncidentEmailsApiStatus: ApiStatus
  getIncidentClickedLinksApiStatus: ApiStatus
  deleteExistingEmailsApiStatus: ApiStatus
  getIncidentUserDetailsApiStatus: ApiStatus
  getIncidentsApiStatus: ApiStatus
  getIncidentsTagsApiStatus: ApiStatus
  getMoreIncidentsApiStatus: ApiStatus
}

// Initial state
export const INITIAL_STATE: RemediationState = {
  clickedLinksByIncident: { data: [], totalCount: 0 },
  createdIncident: {},
  emailsByIncident: { data: [], totalCount: 0 },
  essAccount: {},
  incident: {} as Incident,
  incidentDetails: { threats: [] },
  incidents: { data: [], totalCount: 0 },
  tags: [],
  userDetailsByIncident: { data: [], totalCount: 0 },
  allUserDetailsByIncident: { data: [], totalCount: 0 },
  continuousRemediationApiStatus: inIdle,
  createIncidentApiStatus: inIdle,
  createIncidentTagsApiStatus: inIdle,
  deleteIncidentTagApiStatus: inIdle,
  getEssAccountApiStatus: inIdle,
  getIncidentApiStatus: inIdle,
  getIncidentDetailsApiStatus: inIdle,
  getIncidentEmailsApiStatus: inIdle,
  getIncidentClickedLinksApiStatus: inIdle,
  deleteExistingEmailsApiStatus: inIdle,
  getIncidentUserDetailsApiStatus: inIdle,
  getAllIncidentUserDetailsApiStatus: inIdle,
  getIncidentsApiStatus: inIdle,
  getIncidentsTagsApiStatus: inIdle,
  getMoreIncidentsApiStatus: inIdle
}

/* eslint-disable no-param-reassign */
export const remediationSlice = createSlice({
  name: 'REMEDIATION',
  initialState: INITIAL_STATE,
  // reducers object creates an action and executes the reducer function
  reducers: {
    resetContinuousRemediation: state => {
      state.continuousRemediationApiStatus = INITIAL_STATE.continuousRemediationApiStatus
    },
    resetCreateIncident: state => {
      state.createdIncident = INITIAL_STATE.createdIncident
      state.continuousRemediationApiStatus = INITIAL_STATE.continuousRemediationApiStatus
      state.createIncidentApiStatus = INITIAL_STATE.createIncidentApiStatus
    },
    forensicsResetIncident: state => {
      state.incident = INITIAL_STATE.incident
      state.getIncidentApiStatus = INITIAL_STATE.getIncidentApiStatus
    },
    forensicsResetIncidentDetails: state => {
      state.incidentDetails = INITIAL_STATE.incidentDetails
      state.getIncidentDetailsApiStatus = INITIAL_STATE.getIncidentDetailsApiStatus
    },
    forensicsResetIncidentEmails: state => {
      state.emailsByIncident = INITIAL_STATE.emailsByIncident
      state.getIncidentEmailsApiStatus = INITIAL_STATE.getIncidentEmailsApiStatus
    },
    forensicsResetIncidentClickedLinks: state => {
      state.clickedLinksByIncident = INITIAL_STATE.clickedLinksByIncident
      state.getIncidentClickedLinksApiStatus = INITIAL_STATE.getIncidentClickedLinksApiStatus
    },
    forensicsResetIncidentUserDetails: state => {
      state.userDetailsByIncident = INITIAL_STATE.userDetailsByIncident
      state.getIncidentUserDetailsApiStatus = INITIAL_STATE.getIncidentUserDetailsApiStatus
      state.getAllIncidentUserDetailsApiStatus = INITIAL_STATE.getAllIncidentUserDetailsApiStatus
    },
    forensicsResetIncidentDeleteExistingEmails: state => {
      state.deleteExistingEmailsApiStatus = INITIAL_STATE.deleteExistingEmailsApiStatus
    },
    forensicsResetIncidents: state => {
      state.incidents = INITIAL_STATE.incidents
      state.getIncidentsApiStatus = INITIAL_STATE.getIncidentsApiStatus
      state.getMoreIncidentsApiStatus = INITIAL_STATE.getMoreIncidentsApiStatus
    },
    resetIncidentsFailures: state => {
      state.getIncidentsApiStatus = INITIAL_STATE.getIncidentsApiStatus
      state.getMoreIncidentsApiStatus = INITIAL_STATE.getMoreIncidentsApiStatus
    },
    reset: () => {
      return { ...INITIAL_STATE }
    }
  },

  // extraReducers do not create an action but executes the reducer function
  extraReducers: builder => {
    builder
      .addCase(forensicsGetEssAccount.pending, state => {
        state.essAccount = INITIAL_STATE.essAccount
        state.getEssAccountApiStatus = inProgress
      })
      .addCase(forensicsGetEssAccount.fulfilled, (state, action) => {
        state.essAccount = action.payload
        state.getEssAccountApiStatus = successResponse
      })
      .addCase(forensicsGetEssAccount.rejected, (state, action) => {
        state.getEssAccountApiStatus = failedResponse(action.payload)
      })
      .addCase(forensicsCreateIncident.pending, state => {
        state.createIncidentApiStatus = inProgress
      })
      .addCase(forensicsCreateIncident.fulfilled, (state, action) => {
        state.createdIncident = action.payload
        state.createIncidentApiStatus = successResponse
      })
      .addCase(forensicsCreateIncident.rejected, (state, action) => {
        state.createIncidentApiStatus = failedResponse(action.payload)
      })
      .addCase(forensicsCreateIncidentTags.pending, state => {
        state.createIncidentTagsApiStatus = inProgress
      })
      .addCase(forensicsCreateIncidentTags.fulfilled, (state, action) => {
        state.incident = {
          ...state.incident,
          labels: action.payload
        }
        state.createIncidentTagsApiStatus = successResponse
      })
      .addCase(forensicsCreateIncidentTags.rejected, (state, action) => {
        state.createIncidentTagsApiStatus = failedResponse(action.payload)
      })
      .addCase(forensicsIncidentDeleteExistingEmails.pending, state => {
        state.deleteExistingEmailsApiStatus = inProgress
      })
      .addCase(forensicsIncidentDeleteExistingEmails.fulfilled, state => {
        state.deleteExistingEmailsApiStatus = successResponse
      })
      .addCase(forensicsIncidentDeleteExistingEmails.rejected, (state, action) => {
        state.deleteExistingEmailsApiStatus = failedResponse(action.payload)
      })

      .addCase(forensicsDeleteIncidentTag.pending, state => {
        state.deleteIncidentTagApiStatus = inProgress
      })
      .addCase(forensicsDeleteIncidentTag.fulfilled, (state, action) => {
        state.incident = {
          ...state.incident,
          labels: action.payload
        }
        state.deleteIncidentTagApiStatus = successResponse
      })
      .addCase(forensicsDeleteIncidentTag.rejected, (state, action) => {
        state.deleteIncidentTagApiStatus = failedResponse(action.payload)
      })
      .addCase(forensicsGetIncident.pending, state => {
        state.incident = INITIAL_STATE.incident
        state.getIncidentApiStatus = inProgress
      })
      .addCase(forensicsGetIncident.fulfilled, (state, action) => {
        state.incident = action.payload
        state.getIncidentApiStatus = successResponse
      })
      .addCase(forensicsGetIncident.rejected, (state, action) => {
        state.getIncidentApiStatus = failedResponse(action.payload)
      })
      .addCase(forensicsGetIncidentDetails.pending, state => {
        state.incidentDetails = INITIAL_STATE.incidentDetails
        state.getIncidentDetailsApiStatus = inProgress
      })
      .addCase(forensicsGetIncidentDetails.fulfilled, (state, action) => {
        state.incidentDetails = action.payload
        state.getIncidentDetailsApiStatus = successResponse
      })
      .addCase(forensicsGetIncidentDetails.rejected, (state, action) => {
        state.getIncidentDetailsApiStatus = failedResponse(action.payload)
      })
      .addCase(forensicsGetIncidentEmails.pending, state => {
        state.emailsByIncident = INITIAL_STATE.emailsByIncident
        state.getIncidentEmailsApiStatus = inProgress
      })
      .addCase(forensicsGetIncidentEmails.fulfilled, (state, action) => {
        state.emailsByIncident = action.payload.emailsByIncident
        state.getIncidentEmailsApiStatus = successResponse
      })
      .addCase(forensicsGetIncidentEmails.rejected, (state, action) => {
        state.getIncidentEmailsApiStatus = failedResponse(action.payload)
      })
      .addCase(forensicsGetMoreIncidentEmails.pending, state => {
        state.getIncidentEmailsApiStatus = inProgress
      })
      .addCase(forensicsGetMoreIncidentEmails.fulfilled, (state, action) => {
        state.emailsByIncident = {
          ...action.payload.emailsByIncident,
          data: insertToArray(
            state.emailsByIncident.data,
            (action.payload.emailsByIncident || {}).data || [],
            action.payload.skip || 0
          )
        }
        state.getIncidentEmailsApiStatus = successResponse
      })
      .addCase(forensicsGetMoreIncidentEmails.rejected, (state, action) => {
        state.getIncidentEmailsApiStatus = failedResponse(action.payload)
      })
      .addCase(forensicsGetIncidentClickedLinks.pending, state => {
        state.clickedLinksByIncident = INITIAL_STATE.clickedLinksByIncident as IncidentClicks
        state.getIncidentClickedLinksApiStatus = inProgress
      })
      .addCase(forensicsGetIncidentClickedLinks.fulfilled, (state, action) => {
        state.clickedLinksByIncident = action.payload.clickedLinksByIncident
        state.getIncidentClickedLinksApiStatus = successResponse
      })
      .addCase(forensicsGetIncidentClickedLinks.rejected, (state, action) => {
        state.getIncidentClickedLinksApiStatus = failedResponse(action.payload)
      })
      .addCase(forensicsGetMoreIncidentClickedLinks.pending, state => {
        state.getIncidentClickedLinksApiStatus = inProgress
      })
      .addCase(forensicsGetMoreIncidentClickedLinks.fulfilled, (state, action) => {
        state.clickedLinksByIncident = {
          ...action.payload.clickedLinksByIncident,
          data: insertToArray(
            state.clickedLinksByIncident.data,
            (action.payload.clickedLinksByIncident || {}).data || [],
            action.payload.skip || 0
          )
        }
        state.getIncidentClickedLinksApiStatus = successResponse
      })
      .addCase(forensicsGetMoreIncidentClickedLinks.rejected, (state, action) => {
        state.getIncidentClickedLinksApiStatus = failedResponse(action.payload)
      })
      .addCase(forensicsGetIncidentUserDetails.pending, state => {
        state.userDetailsByIncident = INITIAL_STATE.userDetailsByIncident
        state.getIncidentUserDetailsApiStatus = inProgress
      })
      .addCase(forensicsGetIncidentUserDetails.fulfilled, (state, action) => {
        state.userDetailsByIncident = action.payload.userDetailsByIncident
        state.getIncidentUserDetailsApiStatus = successResponse
      })
      .addCase(forensicsGetIncidentUserDetails.rejected, (state, action) => {
        state.getIncidentUserDetailsApiStatus = failedResponse(action.payload)
      })
      .addCase(forensicsGetMoreIncidentUserDetails.pending, (state, action) => {
        state.getIncidentUserDetailsApiStatus = inProgress
        if (action.meta.arg.resetResults) {
          state.userDetailsByIncident = {
            ...state.userDetailsByIncident,
            data: []
          }
        }
      })
      .addCase(forensicsGetMoreIncidentUserDetails.fulfilled, (state, action) => {
        state.userDetailsByIncident = {
          ...action.payload.userDetailsByIncident,
          data: insertToArray(
            state.userDetailsByIncident.data,
            (action.payload.userDetailsByIncident || {}).data || [],
            action.payload.skip || 0
          )
        }
        state.getIncidentUserDetailsApiStatus = successResponse
      })
      .addCase(forensicsGetMoreIncidentUserDetails.rejected, (state, action) => {
        state.getIncidentUserDetailsApiStatus = failedResponse(action.payload)
      })
      .addCase(forensicsGetAllIncidentUserDetails.pending, state => {
        state.allUserDetailsByIncident = INITIAL_STATE.allUserDetailsByIncident
        state.getAllIncidentUserDetailsApiStatus = inProgress
      })
      .addCase(forensicsGetAllIncidentUserDetails.fulfilled, (state, action) => {
        state.allUserDetailsByIncident = action.payload.userDetailsByIncident
        state.getAllIncidentUserDetailsApiStatus = successResponse
      })
      .addCase(forensicsGetAllIncidentUserDetails.rejected, (state, action) => {
        state.getAllIncidentUserDetailsApiStatus = failedResponse(action.payload)
      })
      .addCase(forensicsGetIncidents.pending, state => {
        state.incidents = INITIAL_STATE.incidents
        state.getIncidentsApiStatus = inProgress
      })
      .addCase(forensicsGetIncidents.fulfilled, (state, action) => {
        state.incidents = action.payload.incidents
        state.getIncidentsApiStatus = successResponse
      })
      .addCase(forensicsGetIncidents.rejected, (state, action) => {
        state.getIncidentsApiStatus = failedResponse(action.payload)
      })
      .addCase(forensicsGetMoreIncidents.pending, (state, action) => {
        if (action.meta.arg.resetResults) {
          state.incidents = {
            ...state.incidents,
            data: []
          }
        }
        state.getMoreIncidentsApiStatus = inProgress
      })
      .addCase(forensicsGetMoreIncidents.fulfilled, (state, action) => {
        state.incidents = {
          ...action.payload.incidents,
          data: insertToArray(
            state.incidents.data,
            (action.payload.incidents || {}).data || [],
            action.payload.skip || 0
          )
        }
        state.getMoreIncidentsApiStatus = successResponse
      })
      .addCase(forensicsGetMoreIncidents.rejected, (state, action) => {
        state.getMoreIncidentsApiStatus = failedResponse(action.payload)
      })
      .addCase(forensicsGetIncidentsTags.pending, state => {
        state.tags = INITIAL_STATE.tags
        state.getIncidentsTagsApiStatus = inProgress
      })
      .addCase(forensicsGetIncidentsTags.fulfilled, (state, action) => {
        state.tags = action.payload
        state.getIncidentsTagsApiStatus = successResponse
      })
      .addCase(forensicsGetIncidentsTags.rejected, (state, action) => {
        state.getIncidentsTagsApiStatus = failedResponse(action.payload)
      })
      .addCase(forensicsRestartContinuousRemediation.pending, state => {
        state.incident = {
          ...state.incident,
          continuousRemediationEnabled: true
        }
        state.continuousRemediationApiStatus = inProgress
      })
      .addCase(forensicsRestartContinuousRemediation.fulfilled, (state, action) => {
        state.incident = {
          ...state.incident,
          ...action.payload
        }
        state.continuousRemediationApiStatus = successResponse
      })
      .addCase(forensicsRestartContinuousRemediation.rejected, (state, action) => {
        state.incident = {
          ...state.incident,
          continuousRemediationEnabled: false
        }
        state.continuousRemediationApiStatus = failedResponse(action.payload)
      })
      .addCase(forensicsStopContinuousRemediation.pending, state => {
        state.incident = {
          ...state.incident,
          continuousRemediationEnabled: false
        }
        state.continuousRemediationApiStatus = inProgress
      })
      .addCase(forensicsStopContinuousRemediation.fulfilled, state => {
        state.incident = {
          ...state.incident,
          continuousRemediationUntil: null
        }
        state.continuousRemediationApiStatus = successResponse
      })
      .addCase(forensicsStopContinuousRemediation.rejected, (state, action) => {
        state.incident = {
          ...state.incident,
          continuousRemediationEnabled: true
        }
        state.continuousRemediationApiStatus = failedResponse(action.payload)
      })
  }
})
/* eslint-enable no-param-reassign */

export const {
  forensicsResetIncident,
  forensicsResetIncidentDetails,
  forensicsResetIncidentEmails,
  forensicsResetIncidentClickedLinks,
  forensicsResetIncidentUserDetails,
  forensicsResetIncidentDeleteExistingEmails,
  forensicsResetIncidents,
  reset,
  resetContinuousRemediation,
  resetCreateIncident,
  resetIncidentsFailures
} = remediationSlice.actions

export {
  forensicsCreateIncident,
  forensicsCreateIncidentTags,
  forensicsDeleteIncidentTag,
  forensicsGetEssAccount,
  forensicsGetIncident,
  forensicsGetIncidentClickedLinks,
  forensicsGetIncidentDetails,
  forensicsGetIncidentEmails,
  forensicsGetIncidents,
  forensicsGetIncidentsTags,
  forensicsGetAllIncidentUserDetails,
  forensicsGetIncidentUserDetails,
  forensicsGetMoreIncidentClickedLinks,
  forensicsGetMoreIncidentEmails,
  forensicsGetMoreIncidents,
  forensicsGetMoreIncidentUserDetails,
  forensicsIncidentDeleteExistingEmails,
  forensicsRestartContinuousRemediation,
  forensicsStopContinuousRemediation
}

export default remediationSlice.reducer
