import React from 'react'
import PropTypes from 'prop-types'

import { Typography, Dialog, DialogContent, Zoom } from '@barracuda-internal/bds-core'

import DEFAULTS from 'global/configs/theme/defaults'
import { useFormatMessage } from 'global/lib/localization'

import styles from 'admin/components/lib/dialogs/impersonateDialog/impersonateDialogStyles'

export interface ImpersonateDialogProps {
  email: string
}

const BASE_I18N_KEY = 'admin.app.dialogs.impersonate_dialog'

export const ImpersonateDialog: React.FC<ImpersonateDialogProps> = ({ email }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  return (
    <Dialog
      style={{ zIndex: DEFAULTS.SECONDARY_DIALOGS_ZINDEX }}
      maxWidth="md"
      fullWidth
      open
      TransitionComponent={Zoom}
      data-field="dialog"
    >
      <DialogContent className={classes.dialogContent} data-field="dialog-content">
        <Typography className={classes.title} data-field="dialog-title">
          {formatMessage('title')}
        </Typography>
        <Typography className={classes.text} data-field="dialog-subtitle">
          {formatMessage('text', { email, span: (text: string) => <span key={text}>{text}</span> })}
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

ImpersonateDialog.propTypes = {
  email: PropTypes.string.isRequired
}

export default ImpersonateDialog
