import { createSlice } from '@reduxjs/toolkit'

import { insertReportData } from 'global/redux/features/reports/reducerHandlers'
import { ApiStatus, failedResponse, inIdle, inProgress, successResponse } from 'global/redux/toolkit/api'

import { getThreatsByUsernameReport } from 'ets/redux/features/reports/reportsApiThunks'
import { INITIAL_STATE as userEmailsTableInitialState } from 'ets/redux/features/dataTables/userEmails/userEmailsSlice'
import { ThreatReport } from 'ets/types/threatTypes'

export interface ThreatsByUsernameState {
  apiStatus: ApiStatus
  loadedOffsets: number[]
  data: ThreatReport | undefined
}

// initialState
export const INITIAL_STATE: ThreatsByUsernameState = {
  apiStatus: inIdle,
  loadedOffsets: [],
  data: undefined
}

/* eslint-disable no-param-reassign */
export const threatsByUsernameSlice = createSlice({
  name: 'REPORTS/THREATS_BY_USERNAME',
  initialState: INITIAL_STATE,
  reducers: {
    resetThreatsByUsernameReport: () => {
      return {
        ...INITIAL_STATE
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getThreatsByUsernameReport.pending, state => {
        state.apiStatus = inProgress
        if (!state.loadedOffsets.length) {
          state.loadedOffsets = [userEmailsTableInitialState.skip]
        }
      })
      .addCase(getThreatsByUsernameReport.fulfilled, (state, action) => {
        state.apiStatus = successResponse
        state.data = insertReportData(state.data, action.payload as any)
      })
      .addCase(getThreatsByUsernameReport.rejected, (state, action) => {
        state.apiStatus = failedResponse(action.payload as string)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { resetThreatsByUsernameReport } = threatsByUsernameSlice.actions

export { getThreatsByUsernameReport }

export default threatsByUsernameSlice.reducer
