import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { isUndefined } from 'lodash'

import ReactFrame, { FrameContextConsumer } from 'react-frame-component'
import {
  Alert,
  Badge,
  DataTable,
  DataTableColumn,
  FormControlLabel,
  Grid,
  Switch,
  Tab,
  Tabs
} from '@barracuda-internal/bds-core'
import {
  CheckmarkCircle as CheckCircleIcon,
  RemoveCircle as RemoveCircleIcon
} from '@barracuda-internal/bds-core/dist/Icons/Core'

import EmailHeaderDetails from 'global/components/lib/dialogs/emailDetailsDialog/components/EmailHeaderDetails'
import CopyToClipboard from 'global/components/lib/copyToClipboard/CopyToClipboard'
import { COLORS } from 'global/configs/theme/defaults'
import { Cell, CellText, CellIcon } from 'global/components/lib/grid/cell'
import { useFormatMessage } from 'global/lib/localization'
import EmailAttachments from 'global/components/lib/dialogs/emailDetailsDialog/components/EmailAttachments'
import StatisticsField from 'global/components/lib/dialogs/emailDetailsDialog/components/statisticsField/StatisticsField'
import styles from 'global/components/lib/dialogs/emailDetailsDialog/components/emailContentDetails/emailContentDetailsStyles'
import useEmailContentDetailsLogic, {
  EmailInfo,
  TABS
} from 'global/components/lib/dialogs/emailDetailsDialog/components/emailContentDetails/useEmailContentDetailsLogic'
import { Attachment } from 'global/types/api/attackType'

import { HeaderDetail } from '../../emailDetailDialogTypes'

export { TABS }

export const HTML_MIME_TYPE = 'text/html'
const BASE_I18N_KEY = 'app.email_details'

export interface EmailContentDetailsProps {
  borderlessContent?: boolean
  contentHeight?: number
  disabledTabs?: string[]
  emailInfo?: EmailInfo | undefined
  headerDetails: HeaderDetail[]
  isHtml?: boolean
  showEmailError?: boolean
}

const EmailContentDetails: React.FC<EmailContentDetailsProps> = ({
  emailInfo,
  disabledTabs,
  showEmailError,
  borderlessContent,
  isHtml = false,
  headerDetails
}) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [
    attachmentCount,
    copyHeadersToClipboard,
    copyFailureReportToClipboard,
    handleResize,
    height,
    highlightedHeaders,
    iframeRef,
    onTabSelected,
    selectedTab,
    shouldHighlightAttachment,
    shouldHighlightAnalysis,
    shouldHighlightEnvelope,
    showKeywordHighlighting,
    switchKeywordHighlighting,
    tabContent,
    tabHighlighting,
    tabs,
    updateKeywordHighlighting
  ] = useEmailContentDetailsLogic({
    emailInfo,
    disabledTabs
  })

  return useMemo(
    () => (
      <Grid container direction="column">
        <Grid container className={classes.stickOnTop}>
          {showKeywordHighlighting && (
            <FormControlLabel
              className={classes.highlightingLabel}
              value={switchKeywordHighlighting}
              control={
                <Switch
                  checked={switchKeywordHighlighting}
                  onChange={updateKeywordHighlighting}
                  name="Highlighting"
                  color="primary"
                />
              }
              label="Highlighting"
              labelPlacement="start"
            />
          )}
        </Grid>

        {!!emailInfo?.statistics && (
          <StatisticsField
            emailStats={emailInfo?.statistics}
            shouldHighlightAnalysis={shouldHighlightAnalysis}
            switchKeywordHighlighting={switchKeywordHighlighting}
          />
        )}
        <Grid container direction="column">
          {showEmailError && (
            <Alert variant="filled" severity="error">
              {formatMessage('email_error')}
            </Alert>
          )}
        </Grid>
        {tabContent && !isUndefined(selectedTab) && (
          <Grid container direction="column" id="email-content">
            <Grid container>
              <Tabs
                className={classes.tabs}
                value={Number(selectedTab)}
                onChange={onTabSelected}
                classes={{
                  indicator: classes.indicator
                }}
              >
                {tabs.map((tab: string) => (
                  <Tab
                    label={
                      <Badge badgeContent={' '} color="error" invisible={!tabHighlighting[tab]} variant="dot">
                        <span className={classes.tabLabel}>
                          {formatMessage(`tabs.${tab}`, { count: attachmentCount })}
                        </span>
                      </Badge>
                    }
                    key={tab}
                  />
                ))}
              </Tabs>
            </Grid>
            <Grid
              className={selectedTab ? classes.tabContent : classes.tabContentForFrame}
              container
              style={{
                height: 'auto',
                maxHeight: 'auto',
                ...(borderlessContent && {
                  border: 0,
                  borderTop: `1px solid ${COLORS.PRODUCT_UI_ELEMENT}`,
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingBottom: 0
                })
              }}
            >
              {tabs[selectedTab] === TABS.EMAIL && (
                <>
                  <EmailHeaderDetails headerDetails={headerDetails} shouldHighlightEnvelope={shouldHighlightEnvelope} />
                  {!!attachmentCount && (
                    <EmailAttachments
                      attachments={emailInfo?.attachments as Attachment[]}
                      shouldHighlightAttachment={shouldHighlightAttachment}
                    />
                  )}
                  <ReactFrame
                    className={classes.frameContent}
                    ref={iframeRef}
                    style={{
                      height
                    }}
                    onLoad={() => handleResize(iframeRef)}
                  >
                    <FrameContextConsumer>
                      {({ document }: any) => {
                        const baseTag = document.createElement('base')
                        baseTag.target = '_blank'

                        const bodyStyle = document.createElement('style')
                        bodyStyle.type = 'text/css'
                        bodyStyle.textContent = `body { white-space: ${isHtml ? 'normal' : 'pre-wrap'}; }`

                        document.head.appendChild(bodyStyle)
                        document.head.appendChild(baseTag)

                        return tabContent
                      }}
                    </FrameContextConsumer>
                  </ReactFrame>
                </>
              )}

              {tabs[selectedTab] === TABS.FAILURE_REPORT && (
                <div className={classes.tableWrapper}>
                  <div className={classes.copyIcon}>
                    <CopyToClipboard
                      textToCopy={copyFailureReportToClipboard()}
                      tooltip={formatMessage('copy_to_clipboard')}
                      customButtonText=" "
                    />
                  </div>
                  <DataTable className={classes.headersContent} sortable data={tabContent}>
                    <DataTableColumn
                      field="name"
                      title={formatMessage('parameter')}
                      cell={({ dataItem }: any) => (
                        <Cell>
                          <CellText>{dataItem.name}</CellText>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      field="value"
                      title={formatMessage('value')}
                      cell={({ dataItem }: any) => (
                        <Cell>
                          <CellText>{dataItem.value}</CellText>
                        </Cell>
                      )}
                    />
                  </DataTable>
                </div>
              )}

              {tabs[selectedTab] === TABS.HEADERS && (
                <div className={classes.tableWrapper}>
                  {!tabContent.length && (
                    <div className={classes.outboundNoContent}>{formatMessage('outbound_no_header')}</div>
                  )}
                  {!!tabContent.length && (
                    <>
                      <div className={classes.copyIcon}>
                        <CopyToClipboard
                          textToCopy={copyHeadersToClipboard()}
                          tooltip={formatMessage('copy_to_clipboard')}
                          customButtonText=" "
                        />
                      </div>
                      <DataTable className={classes.headersContent} sortable data={tabContent}>
                        <DataTableColumn
                          field="name"
                          title={formatMessage('headers')}
                          cell={({ dataItem }: any) => (
                            <Cell
                              className={
                                switchKeywordHighlighting && highlightedHeaders.indexOf(dataItem.name) !== -1
                                  ? classes.highlightHeaderRow
                                  : ''
                              }
                            >
                              <CellText>{dataItem.name}</CellText>
                            </Cell>
                          )}
                        />
                        <DataTableColumn
                          field="value"
                          title={formatMessage('value')}
                          cell={({ dataItem }: any) => (
                            <Cell
                              className={
                                switchKeywordHighlighting && highlightedHeaders.indexOf(dataItem.name) !== -1
                                  ? classes.highlightHeaderRow
                                  : ''
                              }
                            >
                              <CellText>{dataItem.value}</CellText>
                            </Cell>
                          )}
                        />
                      </DataTable>
                    </>
                  )}
                </div>
              )}

              {tabs[selectedTab] === TABS.THREAT_DETAILS && (
                <>
                  {!tabContent.length && (
                    <div className={classes.outboundNoContent}>{formatMessage('outbound_no_threat_details')}</div>
                  )}
                  {!!tabContent.length && (
                    <DataTable className={classes.headersContent} sortable data={tabContent}>
                      <DataTableColumn
                        field="type"
                        headerClassName={classes.typeColumn}
                        title={formatMessage('type')}
                        cell={({ dataItem }: any) => (
                          <Cell className={classes.typeColumn}>
                            <CellText>{formatMessage(`threats.${dataItem.type}`)}</CellText>
                          </Cell>
                        )}
                      />
                      <DataTableColumn
                        field="value"
                        title={formatMessage('value')}
                        cell={({ dataItem }: any) => (
                          <Cell>
                            <CellIcon
                              className={classes[dataItem.passed ? 'checkIcon' : 'removeIcon']}
                              icon={dataItem.passed ? CheckCircleIcon : RemoveCircleIcon}
                            />
                            <CellText>{dataItem.value}</CellText>
                          </Cell>
                        )}
                      />
                    </DataTable>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    ),
    [
      classes,
      showKeywordHighlighting,
      switchKeywordHighlighting,
      updateKeywordHighlighting,
      emailInfo,
      shouldHighlightAnalysis,
      showEmailError,
      formatMessage,
      tabContent,
      selectedTab,
      onTabSelected,
      tabs,
      borderlessContent,
      headerDetails,
      shouldHighlightEnvelope,
      attachmentCount,
      shouldHighlightAttachment,
      iframeRef,
      height,
      copyFailureReportToClipboard,
      copyHeadersToClipboard,
      tabHighlighting,
      handleResize,
      isHtml,
      highlightedHeaders
    ]
  )
}

EmailContentDetails.propTypes = {
  emailInfo: PropTypes.any,
  disabledTabs: PropTypes.array,
  contentHeight: PropTypes.number,
  borderlessContent: PropTypes.bool,
  showEmailError: PropTypes.bool
}

EmailContentDetails.defaultProps = {
  emailInfo: undefined,
  showEmailError: undefined,
  disabledTabs: [],
  contentHeight: 0,
  borderlessContent: false
}

export default EmailContentDetails
