import React, { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'

import validator from 'validator'

import { Typography, Button, TextField, Box, Card, Alert } from '@barracuda-internal/bds-core'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import styles from './howManyScansPPStyles'

const BASE_I18N_KEY = 'app.contest_partner_portal'

export interface HowManyScansProps {
  email: string
  error?: string
  isSending?: boolean
  isSent?: boolean
  onEmailChange: (email: string) => void
  onScanClick: () => void
}

const HowManyScansPartnerPortal: React.FC<HowManyScansProps> = ({
  email,
  error,
  isSending,
  isSent,
  onEmailChange,
  onScanClick
}) => {
  const intl = useIntl()
  const classes = styles()

  const isInvalidEmail = useMemo(() => {
    return !!email.length && !validator.isEmail(email)
  }, [email])

  const isButtonDisabled = useMemo(() => {
    return !email.length || isInvalidEmail || isSending
  }, [isInvalidEmail, isSending, email])

  // change the background color and hide the overflow of this page only
  useEffect(() => {
    document.body.style.backgroundColor = '#FFFFFF'
    document.body.style.overflowY = 'hidden'
  }, [])

  return (
    <div className={classes.rootPP}>
      <Card className={classes.cardPP}>
        {error && (
          <Alert variant="filled" severity="error" data-field="how-many-scans-alert">
            {intl.formatMessage({ id: `${BASE_I18N_KEY}.error` })}
          </Alert>
        )}
        {isSending && <LinearProgress />}
        {isSent && (
          <Box>
            <Typography variant="h6" className={classes.titlePP}>
              {intl.formatMessage({ id: `${BASE_I18N_KEY}.successTitle` })}
            </Typography>
            <Typography variant="body2" className={classes.textPP}>
              {intl.formatMessage({ id: `${BASE_I18N_KEY}.successText` }, { email })}
            </Typography>
          </Box>
        )}
        {!isSent && (
          <Box>
            <Typography className={classes.titlePP}>{intl.formatMessage({ id: `${BASE_I18N_KEY}.title` })}</Typography>
            <Typography className={classes.textPP}>{intl.formatMessage({ id: `${BASE_I18N_KEY}.text` })}</Typography>
            <Box display="flex" flexDirection="column" width="100%">
              <Box>
                <TextField
                  InputProps={{ classes: { input: classes.inputTextPP }, disableUnderline: true }}
                  className={classes.inputPP}
                  value={email}
                  onChange={ev => onEmailChange(ev.target.value)}
                  placeholder="Email"
                  fullWidth
                  variant="standard"
                  autoFocus
                />
              </Box>
              <div className={classes.btnContainerPP}>
                <Button
                  onClick={onScanClick}
                  variant="contained"
                  disabled={isButtonDisabled}
                  className={classes.buttonPP}
                >
                  {intl.formatMessage({ id: `${BASE_I18N_KEY}.buttonText` })}
                </Button>
              </div>
            </Box>
          </Box>
        )}
      </Card>
    </div>
  )
}

export default HowManyScansPartnerPortal
