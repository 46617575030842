import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Card, Grid } from '@barracuda-internal/bds-core'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import styles from 'global/components/features/signinSignup/signinSignupStyles'

export interface SigninSignupWrapperProp {
  isFormInProgress: boolean
  children: any
  style?: any
}

const SigninSignupWrapper: React.FC<SigninSignupWrapperProp> = ({ isFormInProgress, children, style }) => {
  const classes = styles()

  return useMemo(
    () => (
      <Grid>
        <Card style={style || {}} className={classes.loginWrapper}>
          <Grid container direction="column">
            <Grid item xs={12} className={classes.linearWrapper} data-field="linear-wrapper">
              {isFormInProgress && <LinearProgress data-field="linear-progress" />}
            </Grid>
            <Grid className={classes.loginInnerWrapper} container>
              {children}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    ),
    [isFormInProgress, children, style, classes]
  )
}

SigninSignupWrapper.propTypes = {
  isFormInProgress: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  style: PropTypes.any
}

SigninSignupWrapper.defaultProps = {
  style: undefined
}

export default SigninSignupWrapper
