import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from 'global/configs/theme/defaults'

export default makeStyles(theme => {
  return {
    textWithMargin: {
      marginBottom: theme.spacing(1)
    },
    textWithMargins: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    passedIcon: {
      width: 15,
      verticalAlign: -7,
      color: COLORS.GREEN_400,
      marginRight: theme.spacing(0.5)
    },
    failedText: {
      color: COLORS.RED_600
    },
    failedTextWithMargin: {
      marginBottom: theme.spacing(1),
      color: COLORS.RED_600
    },
    failedTextWithMargins: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      color: COLORS.RED_600
    },
    failedIcon: {
      width: 15,
      verticalAlign: -7,
      color: COLORS.RED_600,
      marginRight: theme.spacing(0.5)
    }
  }
})
