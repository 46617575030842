import React from 'react'
import PropTypes from 'prop-types'
import { Link, Typography } from '@barracuda-internal/bds-core'

import { config } from 'global/lib/config'
import { useFormatMessage } from 'global/lib/localization'
import * as analyticsLib from 'global/lib/analytics/analyticsService'

const BASE_I18N_KEY = 'fir.app.cta.user_reported'

interface Props {
  userReported: {
    securityServiceLink: string
  }
}

const UserReportedCTA: React.FC<Props> = ({ userReported: { securityServiceLink } }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return (
    <div>
      <Typography variant="body1" component="span">
        {formatMessage('list.description')}
        <ul>
          <li>
            {formatMessage('list.outlook_addin', {
              a: (txt: any) => (
                <Link
                  onClick={() => {
                    analyticsLib.trackAppEvent(analyticsLib.EVENTS.CTA_BARRACUDA_OUTLOOK_ADDIN)
                  }}
                  key="link"
                  target="_blank"
                  href={config.LINKS.BARRACUDA_OUTLOOK_ADDIN}
                >
                  {txt}
                </Link>
              )
            })}
          </li>
          <li>
            {formatMessage('list.ess_message_log', {
              a: (txt: any) => (
                <Link key="link" target="_blank" href={securityServiceLink}>
                  {txt}
                </Link>
              )
            })}
          </li>
        </ul>
        {formatMessage('description')}
        &nbsp;
        <Link target="_blank" href={config.LINKS.CAMPUS_USER_REPORTED}>
          {formatMessage('learn_more')}...
        </Link>
      </Typography>
    </div>
  )
}

UserReportedCTA.propTypes = {
  userReported: PropTypes.any.isRequired
}

export default UserReportedCTA
