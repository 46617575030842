import { config } from 'global/lib/config'
import productLib from 'global/lib/product/product'
import { reduxStore } from 'global/lib/reduxStore'
import routesConfig, { UiRoute } from 'fir/lib/routes/routesConfig'

const HIDDEN_PATHS_IN_BASIC_VERSION: UiRoute['id'][] = [
  routesConfig.AUTOMATED_WORKFLOWS.id,
  routesConfig.AUTOMATED_WORKFLOWS_WORKFLOW.id,
  routesConfig.INSIGHTS_AUTOMATED.id,
  routesConfig.INSIGHTS_GEOIP.id,
  routesConfig.INSIGHTS_GEOIP_COUNTRY.id
]
const DEFAULT_VIEW = routesConfig.REMEDIATION

export default function validateAppForAccessToken(accessTokenId: string) {
  const activePath = reduxStore.getState()?.app?.activePath?.id

  // filter out the bundle1 hidden paths
  if (
    accessTokenId &&
    productLib.getForensicsSerialBundleForAccessToken(accessTokenId) === config.BUNDLES.BUNDLE1 &&
    HIDDEN_PATHS_IN_BASIC_VERSION.some(routeId => routeId === activePath)
  ) {
    DEFAULT_VIEW.goto({ accessToken: accessTokenId })
  }
}
