const APP = {
  NAME: 'sen',
  LANGUAGE: 'en',
  LINKS: {
    CAMPUS_UNIFIED_REPORTING: 'https://campus.barracuda.com/product/sentinel/doc/80740422/reports/',
    REQUEST_A_DEMO: 'https://www.barracuda.com/products/email-protection/phishing-protection',
    CUDA_ENTITLEMENTS:
      'https://campus.barracuda.com/product/cloudcontrol/doc/69960187/how-to-add-users-and-configure-product-entitlements-and-permissions/'
  }
}

export default APP
