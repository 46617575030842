const API_ROUTES = {
  accessTokens: {
    LIST_ACCESS_TOKENS: {
      method: 'POST',
      path: '/admin/access-tokens'
    }
  },
  scans: {
    SCAN_TOTALS: {
      method: 'POST',
      path: '/admin/scan/totals'
    },
    GET_SCANS: {
      method: 'POST',
      path: '/admin/scans'
    }
  },
  product: {
    GET_PRODUCT_ASSIGNMENTS: {
      method: 'POST',
      path: '/admin/product-assignments'
    },
    GET_PRODUCT_LICENSES: {
      method: 'POST',
      path: '/admin/product-licenses'
    },
    SET_SENTINEL_SERIAL_NUMBER: {
      method: 'POST',
      path: '/admin/set-sentinel-serial-number'
    }
  },
  account: {
    IMPERSONATE: {
      method: 'POST',
      path: '/admin/impersonate'
    },
    DEACTIVATE_AND_RESET: {
      method: 'POST',
      path: '/admin/deactivate-and-reset'
    },
    DEACTIVATE_DOMAIN_FRAUD: {
      method: 'POST',
      path: '/admin/deactivate-domain-fraud'
    },
    DEACTIVATE_SENTINEL: {
      method: 'POST',
      path: '/admin/deactivate-sentinel'
    },
    DEACTIVATE_FORENSICS: {
      method: 'POST',
      path: '/admin/deactivate-forensics'
    }
  },
  remediation: {
    GET_REMEDIATION_STATS: {
      method: 'POST',
      path: '/admin/remediation-stats'
    }
  },
  users: {
    GET_USERS: {
      method: 'POST',
      path: '/admin/bcc-account-users'
    }
  },
  insights: {
    GET_IDENTITY_STATS: {
      method: 'POST',
      path: '/insights/identity-count'
    }
  }
}

export default API_ROUTES
