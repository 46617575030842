import { useMemo, useCallback } from 'react'

import { snakeCase } from 'lodash'

import { useFormatMessage } from 'global/lib/localization'

import { AlertInboxRule, InboxRuleActions, InboxRuleConditions, InboxRuleRecipient } from 'global/types/api/ato'

export type ActionKey = keyof InboxRuleActions
export type CollectAction = <K extends ActionKey>(props: K, value: InboxRuleActions[K]) => string
export type ConditionKey = keyof InboxRuleConditions
export type CollectConditions = <K extends ConditionKey>(props: K, value: InboxRuleConditions[K]) => string

export enum InboxRuleActionProps {
  assignCategories = 'assignCategories',
  copyToFolder = 'copyToFolder',
  delete = 'delete',
  forwardAsAttachmentTo = 'forwardAsAttachmentTo',
  forwardTo = 'forwardTo',
  markAsRead = 'markAsRead',
  markImportance = 'markImportance',
  moveToFolder = 'moveToFolder',
  permanentDelete = 'permanentDelete',
  redirectTo = 'redirectTo',
  stopProcessingRules = 'stopProcessingRules'
}

export enum InboxRuleConditionsProps {
  bodyContains = 'bodyContains',
  bodyOrSubjectContains = 'bodyOrSubjectContains',
  categories = 'categories',
  fromAddresses = 'fromAddresses',
  hasAttachments = 'hasAttachments',
  headerContains = 'headerContains',
  importance = 'importance',
  isApprovalRequest = 'isApprovalRequest',
  isAutomaticForward = 'isAutomaticForward',
  isAutomaticReply = 'isAutomaticReply',
  isEncrypted = 'isEncrypted',
  isMeetingRequest = 'isMeetingRequest',
  isMeetingResponse = 'isMeetingResponse',
  isNonDeliveryReport = 'isNonDeliveryReport',
  isPermissionControlled = 'isPermissionControlled',
  isReadReceipt = 'isReadReceipt',
  isSigned = 'isSigned',
  isVoicemail = 'isVoicemail',
  messageActionFlag = 'messageActionFlag',
  notSentToMe = 'notSentToMe',
  recipientContains = 'recipientContains',
  senderContains = 'senderContains',
  sensitivity = 'sensitivity',
  sentCcMe = 'sentCcMe',
  sentOnlyToMe = 'sentOnlyToMe',
  sentToAddresses = 'sentToAddresses',
  sentToMe = 'sentToMe',
  sentToOrCcMe = 'sentToOrCcMe',
  subjectContains = 'subjectContains',
  withinSizeRange = 'withinSizeRange'
}

const BASE_I18N_KEY = 'app.inbox_rules'

export type Actions = any
export type Conditions = any

export default function useInboxRules(): [Actions, Conditions] {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const collectAction: CollectAction = useCallback(
    (prop, value) => {
      switch (prop) {
        case InboxRuleActionProps.copyToFolder:
        case InboxRuleActionProps.delete:
        case InboxRuleActionProps.markAsRead:
        case InboxRuleActionProps.moveToFolder:
        case InboxRuleActionProps.permanentDelete:
        case InboxRuleActionProps.stopProcessingRules:
        case InboxRuleActionProps.markImportance:
          return formatMessage(`actions.${snakeCase(prop)}`, { values: value })
        case InboxRuleActionProps.assignCategories:
          return formatMessage(`actions.${snakeCase(prop)}`, { values: (value as string[]).join(', ') })
        case InboxRuleActionProps.forwardAsAttachmentTo:
        case InboxRuleActionProps.forwardTo:
        case InboxRuleActionProps.redirectTo:
          return formatMessage(`actions.${snakeCase(prop)}`, {
            values: (value as InboxRuleRecipient[]).reduce((all: string, v) => {
              return `${all} ${v.emailAddress.address}`.trim()
            }, '')
          })
        default:
          return ''
      }
    },
    [formatMessage]
  )

  const collectConditions: CollectConditions = useCallback(
    (prop, value) => {
      switch (prop) {
        case InboxRuleConditionsProps.hasAttachments:
        case InboxRuleConditionsProps.importance:
        case InboxRuleConditionsProps.isApprovalRequest:
        case InboxRuleConditionsProps.isAutomaticForward:
        case InboxRuleConditionsProps.isAutomaticReply:
        case InboxRuleConditionsProps.isEncrypted:
        case InboxRuleConditionsProps.isMeetingRequest:
        case InboxRuleConditionsProps.isMeetingResponse:
        case InboxRuleConditionsProps.isNonDeliveryReport:
        case InboxRuleConditionsProps.isPermissionControlled:
        case InboxRuleConditionsProps.isReadReceipt:
        case InboxRuleConditionsProps.isSigned:
        case InboxRuleConditionsProps.isVoicemail:
        case InboxRuleConditionsProps.messageActionFlag:
        case InboxRuleConditionsProps.notSentToMe:
        case InboxRuleConditionsProps.sensitivity:
        case InboxRuleConditionsProps.sentCcMe:
        case InboxRuleConditionsProps.sentOnlyToMe:
        case InboxRuleConditionsProps.sentToMe:
        case InboxRuleConditionsProps.sentToOrCcMe:
        case InboxRuleConditionsProps.withinSizeRange:
          return formatMessage(`conditions.${snakeCase(prop)}`, {})
        case InboxRuleConditionsProps.bodyContains:
        case InboxRuleConditionsProps.bodyOrSubjectContains:
        case InboxRuleConditionsProps.categories:
        case InboxRuleConditionsProps.headerContains:
        case InboxRuleConditionsProps.recipientContains:
        case InboxRuleConditionsProps.senderContains:
        case InboxRuleConditionsProps.subjectContains:
          return formatMessage(`conditions.${snakeCase(prop)}`, { values: (value as string[]).join(', ') })
        case InboxRuleConditionsProps.fromAddresses:
        case InboxRuleConditionsProps.sentToAddresses:
          return formatMessage(`conditions.${snakeCase(prop)}`, {
            values: (value as InboxRuleRecipient[]).reduce((all: string, v) => {
              return `${all} ${v.emailAddress.address}`.trim()
            }, '')
          })
        default:
          return ''
      }
    },
    [formatMessage]
  )

  const getActions = useCallback(
    (inboxRule: AlertInboxRule) => {
      return Object.keys(inboxRule.actions || {}).map(actionKey =>
        collectAction(actionKey as ActionKey, inboxRule.actions?.[actionKey as ActionKey])
      )
    },
    [collectAction]
  )

  const getConditions = useCallback(
    (inboxRule: AlertInboxRule) => {
      return Object.keys(inboxRule.conditions || {}).map(actionKey =>
        collectConditions(actionKey as ConditionKey, inboxRule.conditions?.[actionKey as ConditionKey])
      )
    },
    [collectConditions]
  )

  return useMemo(() => {
    return [getActions, getConditions]
  }, [getActions, getConditions])
}
