import { findAll } from 'highlight-words-core'

export default function highlightHtml(
  highlightingKeywords: string[],
  textToHighlight: string,
  caseSensitive = false
): string {
  const chunks = findAll({
    caseSensitive,
    searchWords: highlightingKeywords,
    textToHighlight
  })

  const formattedHTML = chunks
    .map((chunk: any) => {
      const { end, highlight, start } = chunk
      const text = textToHighlight.substr(start, end - start)
      if (highlight) {
        // This return must be written in one line for the email body is not html.Otherwise the text after span tag will show up on next line.
        return `<span style="border:2px solid red; border-right:10px solid red; margin-bottom:5px; border-bottom-right-radius:8px; display:inline; padding:0px 5px">${text}</span>`
      }
      return text
    })
    .join('')

  return formattedHTML
}
