import React, { useReducer, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'

import filterOperators from './filterOperators.json'

export interface ColumnMenuLogicProps {
  columns: Column[]
  column: Column
  onColumnsSubmit?: (columns: Column[]) => void
  onCloseMenu: () => void
  sortConfig?: {}
  filterUI: any
}

export interface Column {
  field: string
  title?: string
  filter?: string
  show?: boolean
  sortable?: boolean
  selectable?: boolean
  unableToHide?: boolean
}

export interface ColumnMenuLogicState {
  columns: any[]
  isColumnsExpanded: boolean
  isFilterExpanded: boolean
}

export interface WrappedColumnMenu extends ColumnMenuLogicProps {
  filterOptions: { [key: string]: any }
  isColumnsExpanded: boolean
  isFilterExpanded: boolean
  isOnlyOneColumnIsVisible: boolean
  isColumnSelectable: boolean
  isColumnFilterable: boolean
  isColumnSortable: boolean
  onToggleColumn: (idx: number) => void
  onReset: (e: any) => void
  onSubmit: (e?: any) => void
  onMenuItemClick: () => void
  onFilterExpandChange: (isExpanded: boolean) => void
}

export default function columnMenuLogic(WrappedComponent: React.ComponentType<any>) {
  const ColumnMenuLogic: React.FC<any> = props => {
    const [state, setState] = useReducer(
      (_state: ColumnMenuLogicState, newState: any) => ({ ..._state, ...newState }),
      {
        columns: props.columns,
        originalColumns: props.columns,
        isColumnsExpanded: false,
        isFilterExpanded: true
      }
    )

    // memos
    const isOnlyOneColumnIsVisible = useMemo(() => {
      return Object.keys(state.columns).filter((key: string) => (state.columns[key] as Column).show).length === 1
    }, [state.columns])

    // callbacks
    const onToggleColumn = useCallback(
      (toggledIdx: number) => {
        setState({
          columns: state.columns.map((column: Column, idx: number) => {
            return { ...column, ...(idx === toggledIdx && { show: !column.show }) }
          })
        })
      },
      [state.columns]
    )

    const onSubmit = useCallback(() => {
      if (props.onColumnsSubmit) {
        props.onColumnsSubmit(state.columns)
      }
      props.onCloseMenu()
    }, [state.columns, props])

    const onReset = useCallback(() => {
      setState({ columns: state.originalColumns })
      onSubmit()
    }, [onSubmit, state.originalColumns])

    const onMenuItemClick = useCallback(() => {
      setState({
        isColumnsExpanded: !state.isColumnsExpanded,
        isFilterExpanded: !state.isColumnsExpanded ? false : state.isFilterExpanded
      })
    }, [state.isColumnsExpanded, state.isFilterExpanded])

    const onFilterExpandChange = useCallback(
      (isExpanded: boolean) => {
        setState({
          isFilterExpanded: isExpanded,
          isColumnsExpanded: isExpanded ? false : state.isColumnsExpanded
        })
      },
      [state.isColumnsExpanded]
    )

    // filterOptions
    const filterOptions = useMemo(() => {
      const FilterUI = props.filterUI

      return {
        filterable: true,
        hideSecondFilter: true,
        filter: props.filter,
        column: props.column,
        onExpandChange: onFilterExpandChange,
        expanded: state.isFilterExpanded,
        onFilterChange: (e: any) => {
          props.onFilterChange(e)
          props.onCloseMenu()
        },
        filterOperators,
        ...(FilterUI && { filterUI: (filterProps: any) => <FilterUI {...filterProps} /> })
      }
    }, [onFilterExpandChange, props, state.isFilterExpanded])

    return (
      <WrappedComponent
        {...props}
        isColumnsExpanded={state.isColumnsExpanded}
        filterOptions={filterOptions}
        isOnlyOneColumnIsVisible={isOnlyOneColumnIsVisible}
        isColumnSelectable={props.selectable}
        isColumnFilterable={!!props.column.filter}
        isColumnSortable={props.column.sortable}
        onToggleColumn={onToggleColumn}
        onReset={onReset}
        onSubmit={onSubmit}
        onMenuItemClick={onMenuItemClick}
      />
    )
  }

  ColumnMenuLogic.propTypes = {
    columns: PropTypes.array.isRequired,
    onColumnsSubmit: PropTypes.func,
    onCloseMenu: PropTypes.func.isRequired,
    column: PropTypes.object.isRequired,
    sortable: PropTypes.any.isRequired,
    filter: PropTypes.any.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    selectable: PropTypes.bool,
    filterUI: PropTypes.any.isRequired
  }

  ColumnMenuLogic.defaultProps = {
    onColumnsSubmit: null,
    selectable: false
  }

  return ColumnMenuLogic
}
