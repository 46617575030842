import { config } from 'global/lib/config'
import globalO365SignupConnect from 'global/components/features/signinSignup/o365Connect/o365Connect'

import routesConfig from 'ets/lib/routes/routesConfig'
import gotoReportPage from 'ets/lib/gotoReportPage'

export default function connect(location: any, isComplete = false, accountId?: string) {
  globalO365SignupConnect({
    scanType: config.SCAN_TYPES.ETS,
    location,
    isComplete,
    accountId,
    onSignupComplete: () => {
      routesConfig.SIGNUP_COMPLETE.goto()
    },
    onSigninComplete: data => {
      gotoReportPage({ accessTokenId: data.accessToken.id, user: data.user, scan: data.scan })
    }
  })
}
