import { COLORS } from 'global/configs/theme/defaults'

// TODO: Delete these overwrites after the colors are updated in BDS

export default {
  root: {
    // default color
    color: COLORS.USER_ENTERED_TEXT,

    '& input::placeholder': {
      // placeholder color
      color: COLORS.ACTIVE_PLACEHOLDER_TEXT
    },

    '&.Mui-disabled input': {
      // disabled color
      color: COLORS.DISABLED_PLACEHOLDER_TEXT
    }
  }
}
