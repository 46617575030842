import { makeStyles, createStyles } from '@material-ui/core/styles'

import commonStyles from 'global/components/features/signinSignup/styles/commonStyles'

const WRAPPER_WIDTH = 800

export const signupRemediatesStyles = createStyles({
  wrapper: {
    marginTop: 90,
    position: 'relative',
    display: 'flex',
    height: 'auto',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'top',
    fontFamily: '"Roboto", sans-serif',
    overflow: 'visible',
    maxWidth: WRAPPER_WIDTH,
    padding: 30,
    flexDirection: 'column'
  },
  linearProgress: {
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0
  },
  headingLogoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20
  },
  title: {
    ...commonStyles.title,

    marginBottom: 24
  },
  primaryButton: {
    marginTop: 8,

    '& span': {
      padding: '0 10px'
    }
  }
})

export default makeStyles(() => signupRemediatesStyles)
