import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Zoom,
  Grid,
  Tab,
  Tabs,
  Alert as AlertComponent
} from '@barracuda-internal/bds-core'

import { Alert } from 'global/types/api/ato'
import DEFAULTS from 'global/configs/theme/defaults'
import { useFormatMessage, useErrorFormatMessage } from 'global/lib/localization'
import CircularProgress from 'global/components/lib/circularProgress/CircularProgress'

import AlertThreatsTab from 'sen/components/lib/dialogs/alertDialog/tabs/AlertThreats'
import AlertSigninsTab from 'sen/components/lib/dialogs/alertDialog/tabs/AlertSignins'
import AlertInboxRulesTab from 'sen/components/lib/dialogs/alertDialog/tabs/AlertInboxRules'
import styles from 'sen/components/lib/dialogs/alertDialog/alertDialogStyles'
import useAlertDialogLogic, { UseAlertDialogLogic } from 'sen/components/lib/dialogs/alertDialog/useAlertDialogLogic'
import MarkAlertAsFpDialog from 'sen/components/lib/dialogs/markAlertAsFp/MarkAlertAsFp'

export interface AlertDialogProps {
  alert: UseAlertDialogLogic
  onClose: () => void
}

export interface ResetProps {
  onOpenCreateIncidentDialog: (alert: Alert) => void
}

const BASE_I18N_KEY = 'sen.app.alert_dialog'

const TABS_CONFIG: [string, React.FC<{ alert: Alert; isReviewed?: boolean }>?][] = [
  ['emails_sent', AlertThreatsTab],
  ['signins', AlertSigninsTab],
  ['inbox_rules', AlertInboxRulesTab]
]

export const AlertDialog: React.FC<AlertDialogProps & ResetProps> = ({
  alert,
  onClose,
  onOpenCreateIncidentDialog
}) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatMessageError = useErrorFormatMessage()
  const classes = styles()
  const [isLoaded, error, email, tabConfig, buttonsConfig, markAlertAsFpDialogConfig] = useAlertDialogLogic(alert)

  return useMemo(() => {
    const TabContent = TABS_CONFIG[tabConfig.selectedTab as number]?.[1]

    return (
      <Dialog
        style={{ zIndex: DEFAULTS.DIALOGS_ZINDEX }}
        maxWidth="lg"
        fullWidth
        open
        TransitionComponent={Zoom}
        data-testid="alert-dialog"
      >
        {error && (
          <AlertComponent className={classes.error} variant="filled" severity="error" data-testid="alert-dialog-alert">
            {formatMessageError(error)}
          </AlertComponent>
        )}

        <DialogTitle>
          <Typography className={classes.dialogTitle}>
            {formatMessage('title', { email, b: (txt: string) => <b key={txt}>{txt}</b> })}
          </Typography>
        </DialogTitle>

        <DialogContent>
          {!isLoaded && (
            <Grid container justifyContent="center" className={classes.loading}>
              <CircularProgress />
            </Grid>
          )}
          {isLoaded && (
            <>
              <Grid container className={classes.tabsContainer} data-field="alert-tabs">
                <Tabs
                  className={classes.tabs}
                  textColor="primary"
                  value={tabConfig.selectedTab}
                  onChange={tabConfig.onTabSelected}
                  classes={{
                    indicator: classes.indicator
                  }}
                  data-field="tabs"
                >
                  {TABS_CONFIG.map((tab, idx) => {
                    const [i18nKey] = tab

                    return (
                      <Tab
                        classes={{ textColorPrimary: classes.tab }}
                        disabled={!tabConfig.tabsCount[idx]}
                        label={formatMessage(`tabs.${i18nKey}`, { count: tabConfig.tabsCount[idx] })}
                        key={i18nKey}
                        data-field="tab"
                      />
                    )
                  })}
                </Tabs>
              </Grid>
              <Grid className={classes.tabContent} item data-field="grid-tab-content">
                {TabContent && <TabContent alert={alert.alert} isReviewed={alert.isReviewed} />}
              </Grid>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button color="primary" variant="text" size="large" disabled={buttonsConfig.close.disabled} onClick={onClose}>
            &nbsp;&nbsp;{formatMessage('close')}&nbsp;&nbsp;
          </Button>

          {!alert.isReviewed && !buttonsConfig.isMarkedAsFp && (
            <Button
              color="primary"
              size="large"
              disabled={buttonsConfig.markAsFp.disabled}
              onClick={buttonsConfig.onMarkAsFp}
              variant="outlined"
            >
              &nbsp;&nbsp;{formatMessage('mark_as_fp')}&nbsp;&nbsp;
            </Button>
          )}
          <Button
            color="primary"
            size="large"
            disabled={buttonsConfig.createIncident.disabled}
            onClick={() => {
              buttonsConfig.onOpenCreateIncident()
              onOpenCreateIncidentDialog(alert.alert)
            }}
            variant="contained"
          >
            &nbsp;&nbsp;{formatMessage('create_incident')}&nbsp;&nbsp;
          </Button>
        </DialogActions>

        {markAlertAsFpDialogConfig.isOpen && (
          <MarkAlertAsFpDialog onClose={markAlertAsFpDialogConfig.toggle} alertId={alert.alert.id} />
        )}
      </Dialog>
    )
  }, [
    classes,
    formatMessage,
    onClose,
    isLoaded,
    email,
    tabConfig,
    alert,
    buttonsConfig,
    error,
    formatMessageError,
    markAlertAsFpDialogConfig,
    onOpenCreateIncidentDialog
  ])
}

AlertDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onOpenCreateIncidentDialog: PropTypes.func.isRequired
}

export default AlertDialog
