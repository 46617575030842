import React from 'react'

import { EmailThreatScanner } from '@barracuda-internal/bds-core/dist/Logos/Products'

import GlobalSignupComplete from 'global/components/features/signinSignup/signupComplete/SignupComplete'
import { config } from 'global/lib/config'
import accessTokenLib from 'global/lib/accessToken/accessToken'

import routesConfig from 'ets/lib/routes/routesConfig'
import IMAGES from 'ets/configs/theme/images.config'
import gotoReportPage from 'ets/lib/gotoReportPage'

export const SignupComplete: React.FC = () => (
  <GlobalSignupComplete
    bgImage={IMAGES.loginBackground}
    productLogo={<EmailThreatScanner alt="Email Threat Scanner" />}
    routesConfig={routesConfig}
    gotoNextStep={() => {
      gotoReportPage({ accessTokenId: accessTokenLib.getDefaultAccessTokenId() || '' })
    }}
    scanType={config.SCAN_TYPES.ETS}
  />
)

export default SignupComplete
