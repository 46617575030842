import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => {
  return {
    tableCircularProgress: {
      marginTop: theme.spacing(2)
    },
    dateRangeSelector: {
      position: 'relative'
    },
    dateRangeSelectorInner: {
      position: 'absolute',
      top: theme.spacing(4)
    },
    signinsByCountryContainer: {
      marginTop: theme.spacing(4)
    },
    chartWrapper: {
      position: 'relative',
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      overflow: 'hidden'
    },
    chart: {
      margin: theme.spacing(-10, 'auto', 0),
      height: 615,
      position: 'relative',
      width: 984,
      zIndex: 0
    },
    maskLayer: {
      backgroundColor: theme.palette.background.paper,
      top: theme.spacing(8),
      height: theme.spacing(2),
      position: 'absolute',
      width: '100%',
      zIndex: 1
    }
  }
})
