import React from 'react'
import PropTypes from 'prop-types'

import { Button, Link, TextField, Typography, Grid } from '@barracuda-internal/bds-core'
import FormControl from '@material-ui/core/FormControl'

import { config } from 'global/lib/config'
import YesNoSwitch from 'global/components/lib/form/YesNoSwitch'
import { useFormatMessage } from 'global/lib/localization'

import styles, { potentialIncidentsSettingsStyles } from '../SettingsStyles'

const BASE_I18N_KEY = 'fir.app.settings_dialog'

const RemediationSettings: React.FC<any> = ({
  remediationSettingsOptions: {
    forensicsSettings,
    isInvalidEmail,
    openCustomizeEmailAlertDialog,
    updateRemediationSettingsOptions
  }
}) => {
  const baseClasses = styles()
  const classes = potentialIncidentsSettingsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return (
    <div data-field="wrapper" className={baseClasses.wrapper}>
      <div>
        <Typography data-field="header" variant="h5">
          {formatMessage('remediation.labels.remediation')}
        </Typography>
        <Typography variant="subtitle2">
          <div>
            <span className={`${baseClasses.opacity}`}>{formatMessage('remediation.labels.remediation_subtitle')}</span>{' '}
            <Link target="_blank" href={config.LINKS.CAMPUS_CREATE_INCIDENT}>
              {formatMessage('learn_more')}...
            </Link>
          </div>
        </Typography>
      </div>
      <br />
      <FormControl data-field="root-form" component="fieldset" className={classes.formControl}>
        <Grid data-field="root-grid" component="div" container spacing={2}>
          <Grid component="label" className={classes.label} xs={6} item>
            <Typography variant="body1">{formatMessage('remediation.labels.delete_emails')}</Typography>
          </Grid>
          <Grid data-field="delete-emails-grid" xs={6} item>
            <YesNoSwitch
              checked={forensicsSettings.forensicsIncidentDeleteEmails}
              onChange={updateRemediationSettingsOptions('forensicsIncidentDeleteEmails', true)}
            />
          </Grid>
          <Grid
            component="label"
            className={`${!forensicsSettings.forensicsIncidentDeleteEmails ? baseClasses.disabled : ''} ${
              classes.label
            }`}
            xs={6}
            item
          >
            <Typography className={baseClasses.subSetting} variant="body1">
              {formatMessage('remediation.labels.continuous_remediation')}
            </Typography>
          </Grid>
          <Grid
            data-field="continuous-remediation-grid"
            className={!forensicsSettings.forensicsIncidentDeleteEmails ? baseClasses.disabled : ''}
            xs={6}
            item
          >
            <YesNoSwitch
              checked={forensicsSettings.forensicsIncidentContinuousRemediation}
              onChange={updateRemediationSettingsOptions('forensicsIncidentContinuousRemediation', true)}
            />
          </Grid>
          <Grid component="label" className={classes.label} xs={6} item>
            <Typography variant="body1">{formatMessage('remediation.labels.notify_end_users')}</Typography>
          </Grid>
          <Grid data-field="alert-end-user-grid" xs={6} item>
            <YesNoSwitch
              checked={forensicsSettings.forensicsIncidentAlertEndUser}
              onChange={updateRemediationSettingsOptions('forensicsIncidentAlertEndUser', true)}
            />
          </Grid>
          <Grid
            data-field="customize-alert-grid"
            className={!forensicsSettings.forensicsIncidentAlertEndUser ? baseClasses.disabled : ''}
            xs={12}
            item
          >
            <Button
              className={baseClasses.emailAlertButton}
              color="primary"
              variant="text"
              onClick={openCustomizeEmailAlertDialog}
            >
              {formatMessage('remediation.labels.customize_alert')}
            </Button>
          </Grid>
          <Grid component="label" className={classes.label} xs={6} item>
            <Typography variant="body1">{formatMessage('remediation.labels.notify_admin_address')}</Typography>
          </Grid>
          <Grid data-field="alert-admin-grid" xs={6} item>
            <YesNoSwitch
              checked={forensicsSettings.forensicsIncidentAlertAdmin}
              onChange={updateRemediationSettingsOptions('forensicsIncidentAlertAdmin', true)}
            />
          </Grid>
          <Grid
            data-field="notify-address-grid"
            className={!forensicsSettings.forensicsIncidentAlertAdmin ? baseClasses.disabled : ''}
            xs={6}
            item
          >
            <div className={baseClasses.subSetting}>
              <TextField
                data-field="notify-address-field"
                className={classes.formLine}
                value={forensicsSettings.forensicsNotifyAddress}
                onChange={updateRemediationSettingsOptions('forensicsNotifyAddress')}
                label={formatMessage('remediation.labels.security_team_email')}
                error={isInvalidEmail}
                helperText={isInvalidEmail ? formatMessage('labels.invalid_email') : ''}
              />
            </div>
          </Grid>
        </Grid>
      </FormControl>
    </div>
  )
}

RemediationSettings.propTypes = {
  remediationSettingsOptions: PropTypes.any.isRequired
}

export default RemediationSettings
