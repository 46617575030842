import { makeStyles } from '@material-ui/core/styles'

const MAX_TEXT_WIDTHS = 770

export default makeStyles(theme => {
  return {
    container: {
      marginTop: theme.spacing(5)
    },
    image: {
      maxWidth: 200
    },
    title: {
      marginTop: theme.spacing(3),
      maxWidth: MAX_TEXT_WIDTHS,
      fontSize: 20
    },
    subtitle: {
      marginTop: theme.spacing(4),
      maxWidth: MAX_TEXT_WIDTHS,
      fontSize: 16,
      lineHeight: '20px'
    }
  }
})
