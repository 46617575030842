import React, { useMemo } from 'react'

import { Grid } from '@barracuda-internal/bds-core'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import ContentTabSelector, { TabConfig } from 'global/components/lib/contentTabSelector/ContentTabSelector'

import routesConfig from 'sen/lib/routes/routesConfig'
import styles from 'sen/components/pages/ato/content/accountTakeoverContentStyles'
import AlertsTab from 'sen/components/pages/ato/content/tabs/alerts/Alerts'
import IncidentsTab from 'sen/components/pages/ato/content/tabs/incidents/IncidentsBase'
import InboxRulesTab from 'sen/components/pages/ato/content/tabs/inboxRules/InboxRules'
import SignInsTab from 'sen/components/pages/ato/content/tabs/signIns/Signins'

const BASE_I18N_KEY = 'sen.app.account_takeover'

const TABS: TabConfig[] = [
  [[routesConfig.ACCOUNT_TAKEOVER], 'alerts', AlertsTab],
  [
    [
      routesConfig.ACCOUNT_TAKEOVER_INCIDENTS,
      routesConfig.ACCOUNT_TAKEOVER_INCIDENT,
      routesConfig.ACCOUNT_TAKEOVER_INCIDENT_EXTERNAL_RECIPIENTS
    ],
    'incidents',
    IncidentsTab
  ],
  [[routesConfig.ACCOUNT_TAKEOVER_INBOX_RULES], 'inbox_rules', InboxRulesTab],
  [[routesConfig.ACCOUNT_TAKEOVER_SIGN_INS], 'sign_ins', SignInsTab]
]

const AccountTakeoverContent: React.FC = () => {
  const classes = styles()

  return useMemo(() => {
    return (
      <Grid className={classes.container} container direction="column" data-field="root-grid">
        <Grid container direction="column" data-field="wrapper-grid">
          <ContentTabSelector
            BASE_I18N_KEY={BASE_I18N_KEY}
            tabs={TABS}
            analyticsEventName={analyticsLib.EVENTS.ATO_VIEW}
          />
        </Grid>
      </Grid>
    )
  }, [classes])
}

export default AccountTakeoverContent
