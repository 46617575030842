import { makeStyles } from '@material-ui/core/styles'

export const CELL_HEIGHT = 41

export default makeStyles(theme => ({
  topBarActions: {
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    flexDirection: 'row',
    backgroundColor: theme.palette.background.paper,
    alignItems: 'center',
    flexShrink: 1,
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      marginTop: theme.spacing(0.5),
      bottom: '-1.5rem',
      marginBottom: theme.spacing(0.5)
    }
  },
  tagInput: {
    backgroundColor: theme.palette.background.paper,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    gap: theme.spacing(2)
  },
  tag: {
    margin: theme.spacing(0.5)
  },
  topBarDropzone: {
    padding: theme.spacing(2)
  },
  searchContainer: {
    borderRadius: theme.shape.borderRadius,
    border: '1px solid #AFAFAE',
    backgroundColor: 'white',
    height: 39,
    width: 280,
    display: 'flex'
  },
  columnVisibilityButton: {
    height: 39
  },
  displayTopResultsInput: {
    height: 39,
    width: 150
  },
  displayTopResultsMenu: {
    width: 150
  },
  editColumnsText: {
    cursor: 'pointer'
  },
  drawer: {
    zIndex: '1100 !important' as any
  },
  drawerClose: {
    position: 'absolute',
    top: 90,
    right: 20,
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    width: '1.25em',
    height: '1.25em'
  },
  drawerPaper: {
    paddingTop: 70,
    width: '350px'
  },
  activeColumn: {
    backgroundColor: '#F5F5F5'
  },
  paginationContainer: {
    alignItems: 'center',
    color: '#7C7C7C',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10
  },
  tabelRow: {
    align: 'left'
  },
  tableHead: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    color: '#7C7C7C',
    '&:hover': {
      color: 'black !important'
    },
    fontWeight: theme.font.weight.medium,
    fontSize: 12,
    letterSpacing: 0,
    paddingBottom: 5
  },
  tableCell: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    fontSize: 14,
    height: CELL_HEIGHT,
    letterSpacing: 0,
    lineHeight: '16px'
  },
  icon: {
    color: 'gray',
    verticalAlign: 'middle'
  },
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    width: '100%'
  }
}))
