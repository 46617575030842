import { IdentityDetails } from 'global/types/api/identity'

export interface ReportQuery {
  filter?: string[] | null
  limit?: number
  offset?: number
  order?: string
  search?: string
}
export type ReportQueryWithTimespan = ReportQuery & {
  timespan: number
}

export const REPORT_QUERY: ReportQuery = {
  filter: null,
  limit: 9999,
  offset: 0,
  order: '',
  search: ''
}

export const TIMESPAN_REPORT_QUERY: ReportQueryWithTimespan = {
  ...REPORT_QUERY,
  timespan: 0
}

export const CHART_LIMIT = 1000

export const CLEANUP_FILTER = ['isBootstrap:true', 'markedAsFp:false']
export const REMEDIATED_FILTER = ['isBootstrap:true', 'NOT (remediationStatus:S)', 'markedAsFp:false']

export const EMPLOYEE_PAGINATION = {
  limit: 2,
  page: 1
}

// payloads
export type GetAttacksPerDayPayload = ReportQueryWithTimespan
export type GetAttacksBySenderPayload = ReportQueryWithTimespan
export type GetAttacksBySercvicePayload = ReportQueryWithTimespan
export type GetAttacksByTaxonomyPayload = ReportQueryWithTimespan
export type GetAttacksByDomainPayload = ReportQueryWithTimespan
export type GetAttacksByIdentityPayload = ReportQueryWithTimespan
export type GetAttacksBySubjectPayload = ReportQueryWithTimespan
export type GetCompanyDomainsPayload = ReportQuery | undefined
export type GetListFeedbackPayload = string[]

export type ResetMonitoredIdentitesPayload = boolean | undefined
export type ResetAttacksByDomainPayload = boolean | undefined
export type ResetAttacksByIdentityPayload = boolean | undefined
export type ResetAttacksBySubjectPayload = boolean | undefined

export type CanSendReplyToAttackActionPayload = {
  provider: string
}

export type GetEmployeeDefaultsPayload = {
  offset?: number
  page?: number
}

export type TestAttackPayload = {
  body: string
  contentType: string
  recipient: IdentityDetails
  sender: IdentityDetails
  subject: string
}
