import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from 'global/configs/theme/defaults'
import dialogStyles from 'global/components/lib/dialogs/dialogStyles'

export default makeStyles(theme => {
  const defaultDialogStyles = dialogStyles(theme)

  return {
    ...defaultDialogStyles,
    dialogContent: {
      padding: theme.spacing(3)
    },
    titleWrapper: {
      padding: '0px 16px',
      background: COLORS.RED,
      height: 64,
      display: 'flex',
      alignItems: 'center'
    },
    title: {
      fontSize: 20,
      color: COLORS.WHITE
    },
    label: {
      fontSize: 16,
      fontWeight: 700,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    checkbox: {
      fontSize: 16
    },
    actions: {
      padding: theme.spacing(2)
    },
    cancelBtn: {
      fontSize: 14,
      fontWeight: 500
    },
    deactivateBtn: {
      fontSize: 14,
      fontWeight: 500,
      color: COLORS.RED
    }
  }
})
