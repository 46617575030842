import React, { useMemo } from 'react'

import { Assignment as AssignmentIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'
import { Report as ReportIcon } from '@barracuda-internal/bds-core/dist/Icons/Email'
import { Typography, Button, DataTable, DataTableColumn, Grid, Paper, Tooltip, Box } from '@barracuda-internal/bds-core'
import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'

import { useFormatMessage } from 'global/lib/localization'

import AttackTypeLabel from 'global/components/lib/attackTypeLabel/AttackTypeLabel'
import CellConfidenceScore from 'global/components/lib/grid/cell/CellConfidenceScore'
import Cell from 'global/components/lib/grid/cell/Cell'
import CellLabelType from 'global/components/lib/grid/cell/CellLabelType'
import CellText from 'global/components/lib/grid/cell/CellText'
import CircularProgress from 'global/components/lib/circularProgress/CircularProgress'
import EmailCell from 'global/components/lib/grid/cell/customCells/EmailCell'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import Pager from 'global/components/lib/grid/pager/Pager'
import StatusTypeLabel from 'global/components/lib/statusTypeLabel/StatusTypeLabel'
import useEmailDetailsSpAttackInterface from 'global/components/lib/dialogs/emailDetailsDialog/interfaces/useEmailDetailsSpAttackInterface'
import EmailDetailsDialog from 'global/components/lib/dialogs/emailDetailsDialog/EmailDetailsDialog'

import CleanupDialog from 'sen/components/lib/dialogs/cleanupDialog/CleanupDialog'
import ReportFalsePositiveDialog from 'sen/components/lib/dialogs/reportFalsePositive/ReportFalsePositiveDialog'
import useCleanupContentLogic from 'sen/components/pages/cleanup/content/useCleanupContentLogic'
import styles, { CELL_HEIGHT } from 'sen/components/pages/dashboard/content/tabs/recentAttacks/recentAttacksStyles'

const BASE_I18N_KEY = 'sen.app.cleanup'
const BASE_I18N_TABLE_KEY = 'sen.app.data_tables.sp_attacks'

const CleanupContent: React.FC = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const tableFormatMessage = useFormatMessage(BASE_I18N_TABLE_KEY)
  const classes = styles()

  const [cleanupTableConfig, cleanupDialogConfig] = useCleanupContentLogic()

  const [
    emailDetailDialogConfig,
    emailDetailDialogActions,
    reportFalsePositiveDialogConfig
  ] = useEmailDetailsSpAttackInterface({
    withMarkAsFp: false
  })

  return useMemo(() => {
    return (
      <Grid container direction="column" data-field="root-grid">
        <Grid
          ref={cleanupTableConfig.ref}
          className={classes.spearPhishingAttacks}
          container
          data-section="cleanup-attacks"
          direction="column"
          data-field="cleanup-table-grid"
        >
          <Grid item data-field="cleanup-table-grid-title">
            <Typography className={classes.title} data-field="cleanup-table-title">
              {formatMessage('title')}
            </Typography>
          </Grid>

          <Grid item data-field="cleanup-table-grid-paper">
            <Paper className={classes.tableCard} elevation={0} data-field="cleanup-table-paper">
              {cleanupTableConfig.isLoaded && (
                <Grid container direction="column" className={classes.tableWrapper} data-field="cleanup-table-wrapper">
                  <Grid className={classes.deleteButtonWrapper} container justifyContent="flex-end">
                    <Button
                      color="primary"
                      size="large"
                      variant="contained"
                      onClick={() => {
                        cleanupDialogConfig.onOpen()
                      }}
                      disabled={cleanupTableConfig.isUserInteractionDisabledForTable}
                    >
                      {formatMessage('delete')}
                    </Button>
                  </Grid>
                  {!cleanupTableConfig.isLoaded && (
                    <Grid container justifyContent="center" className={classes.tableCircularProgress}>
                      <CircularProgress data-field="loader" />
                    </Grid>
                  )}
                  <Grid
                    className={`${classes.tableArea} ${!cleanupTableConfig.tableData.total ? 'empty-table' : ''}`}
                    data-table="cleanup"
                    item
                    xs={12}
                    data-field="grid-table"
                  >
                    {cleanupTableConfig.inProgress && (
                      <LinearProgress className={classes.indicator} data-field="linear-progress" />
                    )}
                    <DataTable
                      data-testid="sp-attack-table"
                      className={`${
                        cleanupTableConfig.tableData.total ? classes.dataTable : classes.emptyDataTable
                      } attacks-table`}
                      data={cleanupTableConfig.tableData}
                      {...cleanupTableConfig.pageConfig}
                      {...cleanupTableConfig.sortConfig}
                      pager={Pager}
                      data-field="data-table"
                    >
                      <DataTableNoRecords data-field="empty-table">
                        <Typography>{!cleanupTableConfig.tableData.total && formatMessage('empty_table')}</Typography>
                      </DataTableNoRecords>
                      <DataTableColumn
                        width={110}
                        field={cleanupTableConfig.columns.DATE}
                        {...cleanupTableConfig.columnsConfig[cleanupTableConfig.columns.DATE as any]}
                        title={tableFormatMessage(cleanupTableConfig.columns.DATE)}
                        cell={({ dataItem }: { dataItem: any }) => (
                          <Cell className={classes.cell} data-field="table-column-date">
                            <CellText className={classes.normalCell} data-field="table-column-date-text">
                              {dataItem.formattedDate}
                            </CellText>
                          </Cell>
                        )}
                      />
                      <DataTableColumn
                        field={cleanupTableConfig.columns.EMPLOYEE}
                        {...cleanupTableConfig.columnsConfig[cleanupTableConfig.columns.EMPLOYEE as any]}
                        title={tableFormatMessage(cleanupTableConfig.columns.EMPLOYEE)}
                        cell={({ dataItem }: { dataItem: any }) => (
                          <EmailCell CELL_HEIGHT={CELL_HEIGHT} name={dataItem.displayName} email={dataItem.email} />
                        )}
                      />
                      <DataTableColumn
                        field={cleanupTableConfig.columns.EMAIL}
                        {...cleanupTableConfig.columnsConfig[cleanupTableConfig.columns.EMAIL as any]}
                        title={tableFormatMessage(cleanupTableConfig.columns.EMAIL)}
                        cell={({ dataItem }: { dataItem: any }) => (
                          <Cell className={classes.cell} data-field="table-column-email">
                            <Tooltip
                              classes={{ tooltip: classes.tooltip }}
                              title={
                                <div>
                                  {(dataItem.sender.displayName || dataItem.sender.email) && (
                                    <>
                                      <b>{dataItem.sender.displayName || dataItem.sender.email}</b>
                                    </>
                                  )}
                                  {dataItem.sender.displayName && dataItem.sender.email && (
                                    <>
                                      &nbsp;&lt;
                                      {dataItem.sender.email}&gt;
                                    </>
                                  )}
                                  {(dataItem.sender.displayName || dataItem.sender.email) && <br />}
                                  {!!dataItem.subject?.length && (
                                    <>
                                      {formatMessage('subject').toLowerCase()}
                                      &nbsp;
                                      <b>{dataItem.subject}</b>
                                    </>
                                  )}
                                </div>
                              }
                              placement="top-start"
                              data-field="table-column-email-tooltip"
                            >
                              <span>
                                {(dataItem.sender.displayName || dataItem.sender.email) && (
                                  <CellText
                                    ellipsis
                                    disableTitle
                                    className={classes.boldCell}
                                    data-field="table-column-email-display-name"
                                  >
                                    {dataItem.sender.displayName || dataItem.sender.email}
                                  </CellText>
                                )}
                                {dataItem.sender.displayName && dataItem.sender.email && (
                                  <CellText
                                    ellipsis
                                    disableTitle
                                    className={classes.lightCell}
                                    data-field="table-column-email-highlight"
                                  >
                                    &nbsp;&lt;
                                    {dataItem.sender.email}
                                  </CellText>
                                )}
                                {!!dataItem.subject?.length && (
                                  <>
                                    <br />
                                    <CellText
                                      ellipsis
                                      disableTitle
                                      className={classes.normalCell}
                                      data-field="table-column-email-subject-title"
                                    >
                                      {formatMessage('subject').toLowerCase()}
                                      &nbsp;
                                    </CellText>
                                    <CellText
                                      ellipsis
                                      disableTitle
                                      className={classes.boldCell}
                                      data-field="table-column-email-subject"
                                    >
                                      {dataItem.subject}
                                    </CellText>
                                  </>
                                )}
                              </span>
                            </Tooltip>
                          </Cell>
                        )}
                      />
                      <DataTableColumn
                        width={200}
                        field={cleanupTableConfig.columns.ATTACK_TYPE}
                        headerClassName={classes.rightAlignedText}
                        {...cleanupTableConfig.columnsConfig[cleanupTableConfig.columns.ATTACK_TYPE as any]}
                        title={tableFormatMessage(cleanupTableConfig.columns.ATTACK_TYPE)}
                        cell={({ dataItem }: { dataItem: any }) => (
                          <Cell className={classes.cell} data-field="table-column-attack">
                            <CellLabelType align="right">
                              <AttackTypeLabel
                                attackType={dataItem.taxonomy || ''}
                                data-field="table-column-attack-label"
                              />
                            </CellLabelType>
                          </Cell>
                        )}
                      />
                      <DataTableColumn
                        width={100}
                        field={cleanupTableConfig.columns.CONFIDENCE}
                        {...cleanupTableConfig.columnsConfig[cleanupTableConfig.columns.CONFIDENCE as any]}
                        title={tableFormatMessage(cleanupTableConfig.columns.CONFIDENCE)}
                        cell={({ dataItem }: { dataItem: any }) => (
                          <Cell className={classes.cell} data-field="table-column-confidence">
                            <CellConfidenceScore
                              level={dataItem.confidenceLevel}
                              data-field="table-column-confidence-score"
                            />
                          </Cell>
                        )}
                      />
                      <DataTableColumn
                        width={200}
                        field={cleanupTableConfig.columns.STATUS}
                        {...cleanupTableConfig.columnsConfig[cleanupTableConfig.columns.STATUS as any]}
                        title={tableFormatMessage(cleanupTableConfig.columns.STATUS)}
                        cell={({ dataItem }: { dataItem: any }) => (
                          <Cell className={classes.cell} data-field="table-column-status">
                            {dataItem.status.inProgress && <CircularProgress data-field="table-column-status-loader" />}
                            {!dataItem.status.inProgress && (
                              <CellLabelType>
                                <StatusTypeLabel status={dataItem.status.id} data-field="table-column-status-label" />
                              </CellLabelType>
                            )}
                          </Cell>
                        )}
                      />
                      <DataTableColumn
                        width={50}
                        field={cleanupTableConfig.columns.DETAILS}
                        {...cleanupTableConfig.columnsConfig[cleanupTableConfig.columns.DETAILS as any]}
                        title=" "
                        cell={({ dataItem }: { dataItem: any }) => (
                          <Cell className={classes.cell} data-field="table-column-details">
                            <CellText>
                              <Tooltip
                                classes={{ tooltip: classes.tooltip }}
                                title={formatMessage('more_details')}
                                placement="top-start"
                                data-field="table-column-details-tooltip"
                              >
                                <Box>
                                  <AssignmentIcon
                                    data-testid={`more-details-${dataItem.threatId}`}
                                    onClick={() => {
                                      emailDetailDialogActions.onOpen(dataItem)
                                    }}
                                    className={classes.icon}
                                    data-action="cleanup-dialog-open"
                                  />
                                </Box>
                              </Tooltip>
                            </CellText>
                          </Cell>
                        )}
                      />
                      <DataTableColumn
                        width={50}
                        field={cleanupTableConfig.columns.REPORT}
                        {...cleanupTableConfig.columnsConfig[cleanupTableConfig.columns.REPORT as any]}
                        title=" "
                        cell={({ dataItem }: { dataItem: any }) => (
                          <Cell className={classes.cell} data-field="table-column-report">
                            <CellText>
                              {!dataItem.status.inProgress && !dataItem.markedAsFp && !dataItem.hasRemediation && (
                                <Tooltip
                                  classes={{ tooltip: classes.tooltip }}
                                  title={formatMessage('report_false_positive')}
                                  placement="top-start"
                                  data-field="table-column-report-tooltip"
                                >
                                  <Box>
                                    <ReportIcon
                                      onClick={() => {
                                        reportFalsePositiveDialogConfig.onOpen(
                                          dataItem.threatId,
                                          dataItem.confidenceLevel
                                        )
                                      }}
                                      className={classes.icon}
                                      data-action="report-false-positive-dialog-open"
                                    />
                                  </Box>
                                </Tooltip>
                              )}
                            </CellText>
                          </Cell>
                        )}
                      />
                    </DataTable>
                  </Grid>
                </Grid>
              )}
            </Paper>
          </Grid>
        </Grid>

        {emailDetailDialogActions.open && (
          <EmailDetailsDialog data={emailDetailDialogConfig} buttonText={formatMessage('close')} />
        )}

        {reportFalsePositiveDialogConfig.open && (
          <ReportFalsePositiveDialog open onClose={reportFalsePositiveDialogConfig.onClose} />
        )}

        {cleanupDialogConfig.open && <CleanupDialog open onClose={cleanupDialogConfig.onClose} />}
      </Grid>
    )
  }, [
    formatMessage,
    tableFormatMessage,
    classes,
    cleanupTableConfig,
    emailDetailDialogConfig,
    emailDetailDialogActions,
    reportFalsePositiveDialogConfig,
    cleanupDialogConfig
  ])
}

export default CleanupContent
