import React from 'react'
import PropTypes from 'prop-types'

import { BarChart, Card, CardContent, CardHeader, CircularProgress, Typography } from '@barracuda-internal/bds-core'
// TODO: switch back to using ChartWrapper once subtitle prop issue fixed
// import ChartWrapper from '@barracuda-internal/bds-core/dist/ChartWrapper/ChartWrapper'

import styles from './chartWrapperStyles'

export interface TopAttackedUsersDashboardProps {
  chartConfig: any
  chartWrapperConfig: any
}

const TopAttackedUsersDashboard: React.FC<TopAttackedUsersDashboardProps> = ({ chartConfig, chartWrapperConfig }) => {
  const classes = styles()
  return (
    // <ChartWrapper chartWrapper={{ ...chartWrapperConfig }}>
    <Card elevation={1}>
      <CardHeader
        title={chartWrapperConfig.chartTitle}
        subheader={!!chartWrapperConfig.chartSubtitle && chartWrapperConfig.chartSubtitle}
      />
      <CardContent>
        {!chartWrapperConfig.chartLoading && !!chartWrapperConfig.chartHighlight && (
          <>
            <Typography align="left" className={classes.highlight} display="inline" variant="h4">
              {chartWrapperConfig.chartHighlight.value}
            </Typography>
            <Typography align="left" color="textSecondary" display="inline" variant="body1">
              {chartWrapperConfig.chartHighlight.description}
            </Typography>
          </>
        )}
        {chartWrapperConfig.chartLoading && (
          <div className={classes.loadingBox}>
            <CircularProgress />
          </div>
        )}
        {!chartWrapperConfig.chartLoading && chartWrapperConfig.chartData.length === 0 && (
          <Typography align="center" color="textSecondary" variant="body1">
            {chartWrapperConfig.noDataText}
          </Typography>
        )}
        {!chartWrapperConfig.chartLoading && chartWrapperConfig.chartData.length > 0 && (
          <div className={classes.chart}>
            <BarChart barChart={{ ...chartConfig }} />
          </div>
        )}
      </CardContent>
    </Card>
    // </ChartWrapper>
  )
}

TopAttackedUsersDashboard.propTypes = {
  chartConfig: PropTypes.any.isRequired,
  chartWrapperConfig: PropTypes.any.isRequired
}

export default TopAttackedUsersDashboard
