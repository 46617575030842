import { config } from 'global/lib/config'
import { reduxStore } from 'global/lib/reduxStore'
import { setCurrentAccessToken } from 'global/redux/features/accessToken/accessTokenSlice'

import globalO365SignupConnect from 'global/components/features/signinSignup/o365Connect/o365Connect'

import routesConfig from 'fir/lib/routes/routesConfig'
import { setUser } from 'fir/redux/features/user/userSlice'

export default function connect(location: any, isComplete = false, accountId?: string) {
  globalO365SignupConnect({
    scanType: config.SCAN_TYPES.FORENSICS,
    location,
    isComplete,
    accountId,
    onSignupComplete: () => {
      routesConfig.SIGNUP_COMPLETE.goto()
    },
    onSigninComplete: data => {
      reduxStore.dispatch(setUser({ user: data.user }))
      reduxStore.dispatch(setCurrentAccessToken(data.accessToken.id))

      routesConfig.SIGNIN_REMEDIATES.goto()
    }
  })
}
