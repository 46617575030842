import { COLORS } from 'ets/configs/styles/defaults'

const TEXT = {
  color: 'white',
  fontFamily: 'Roboto',
  fontWeight: 'normal'
}
const DARK_TEXT = {
  ...TEXT,
  color: '#000000'
}
const BOLD_DARK_TEXT = {
  ...DARK_TEXT,
  fontWeight: 'bold'
}
const SUMMARY_ELEM = {
  display: 'flex',
  flexDirection: 'column',
  width: '25%'
} as any

const TABLE_HEADER_TEXT = {
  ...DARK_TEXT,
  display: 'flex',
  color: COLORS.PDF.LIGHT_GRAY,
  textOverflow: 'ellipsis',
  maxLines: 1,
  fontSize: 8,
  marginBottom: 10
}
const TABLE_TEXT = {
  ...DARK_TEXT,
  textOverflow: 'ellipsis',
  maxLines: 1,
  fontSize: 8,
  paddingRight: 5
}

const PAGE_HORIZONTAL_PADDING = 20
const PAGE_TOP_PADDING = 40

export default {
  // fonts
  darkText: DARK_TEXT,
  boldDarkText: BOLD_DARK_TEXT,
  text: TEXT,
  boldText: {
    ...TEXT,
    fontWeight: 'bold'
  },

  // page
  page: {
    backgroundColor: 'white',
    paddingHorizontal: PAGE_HORIZONTAL_PADDING,
    paddingTop: PAGE_TOP_PADDING
  },

  // page title
  title: {
    ...DARK_TEXT,
    fontSize: 25,
    fontWeight: 'bold',
    textAlign: 'center'
  },

  // subtitle
  subtitle: {
    ...BOLD_DARK_TEXT,
    fontSize: 13
  },
  subtitleDescription: {
    ...DARK_TEXT,
    marginTop: 5,
    fontSize: 10
  },

  // page summary section
  summaryWrapper: {
    marginBottom: 15,
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20
  },
  summaryElem: SUMMARY_ELEM,
  summaryElemBordered: {
    ...SUMMARY_ELEM,
    borderRight: 1,
    borderRightColor: '#B2B2B2'
  },
  summaryTitle: {
    ...DARK_TEXT,
    fontSize: 8,
    textAlign: 'center'
  },
  summaryValue: {
    ...BOLD_DARK_TEXT,
    marginTop: 3,
    fontSize: 10,
    textAlign: 'center'
  },

  // tables
  tableHeaderText: TABLE_HEADER_TEXT,
  tableText: TABLE_TEXT,
  tableheaderRow: {
    marginTop: 15,
    borderBottom: 1,
    borderBottomColor: COLORS.PDF.GRAY,
    display: 'flex',
    flexDirection: 'row'
  },
  tableRow: {
    borderBottom: 1,
    borderBottomColor: COLORS.PDF.GRAY,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  boldCellText: {
    ...BOLD_DARK_TEXT,
    lineHeight: 1.3,
    textOverflow: 'ellipsis',
    maxLines: 1
  },
  cellText: {
    ...DARK_TEXT,
    lineHeight: 1.3,
    textOverflow: 'ellipsis',
    maxLines: 1
  },
  emptyTableWrap: {
    height: 335,
    backgroundColor: '#000000',
    opacity: 0.03,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  emptyTableText: {
    opacity: 1,
    textAlign: 'center',
    fontSize: 16
  }
} as any
