import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Card, Link, Typography, CardContent, CardHeader } from '@barracuda-internal/bds-core'

import IMAGES from 'global/configs/theme/images.config'
import config from 'global/configs/global.app_config'
import { useFormatMessage } from 'global/lib/localization'
import useAccessTokenLib from 'global/lib/accessToken/useAccessToken'
import productLib from 'global/lib/product/product'
import { useAppSelector } from 'global/redux/toolkit/hooks'

import Layout from 'fir/components/lib/layout/Layout'
import routesConfig from 'fir/lib/routes/routesConfig'

import styles from './missingRequirementStyles'

interface UrlParams {
  type?: string
}

const BASE_I18N_KEY = 'fir.app.missing_requirements'

const MissingRequirement: React.FC<any> = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const urlParams: UrlParams = useParams()
  const [accessTokenLib] = useAccessTokenLib()
  const { accessToken } = useAppSelector((_stores: any) => ({
    accessToken: _stores.accessToken.accessToken
  }))

  // This logic is needed when user refresh the page
  useEffect(() => {
    if (productLib.hasForensicsProduct(accessToken?.id) && accessTokenLib.hasForensicsEntitlement(accessToken?.id)) {
      routesConfig.REMEDIATION.goto()
    }
  }, [accessToken, accessTokenLib])

  return (
    <Layout pageTitle={formatMessage('page_title')}>
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardHeader classes={{ title: classes.header }} title={formatMessage('card_title')} />
          <CardContent>
            <Typography variant="subtitle1" color="inherit">
              {formatMessage(`${urlParams.type}.subtitle`)}
            </Typography>
          </CardContent>
          {urlParams.type === 'o365' && (
            <div className={classes.missingRequirementBox}>
              <div className={classes.missingRequirementText}>You Need</div>
              <img className={classes.image} alt="" src={IMAGES.officeO365Rectangle} />
              <CardContent>
                <Typography variant="subtitle1" color="inherit">
                  {formatMessage(`${urlParams.type}.content`)}
                  &nbsp;
                  <Link href={config.LINKS.MICROSOFT_OFFICE365} target="_blank">
                    {formatMessage('learn_more')}...
                  </Link>
                </Typography>
              </CardContent>
            </div>
          )}
        </Card>
      </div>
    </Layout>
  )
}

export default MissingRequirement
