import React, { useMemo, useState } from 'react'

import { Button, Typography, Grid } from '@barracuda-internal/bds-core'

import styles from 'global/components/features/signinSignup/signinSignupStyles'
import PermissionDialog from 'global/components/features/signinSignup/permissionDialog/PermissionDialog'

import { config } from 'global/lib/config'
import { useFormatMessage } from 'global/lib/localization'

export interface SigninSignupConnectProps {
  appName: string
  isPermissionDialogVisible: boolean
  onStartTrial: () => void
  onTogglePermissionDialog: () => void
  permissionDialogtitle: string
  requestDemoPath: string
  salesLeadEpId: number
  sendDemoRequestTrackingEvent: () => void
}

const BASE_I18N_KEY = 'app.signin_signup'

const SigninSignupConnect: React.FC<SigninSignupConnectProps> = ({
  appName,
  permissionDialogtitle,
  onTogglePermissionDialog,
  isPermissionDialogVisible,
  onStartTrial,
  requestDemoPath,
  salesLeadEpId,
  sendDemoRequestTrackingEvent
}) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  return useMemo(
    () => (
      <Grid container direction="column">
        <Grid item>
          <Typography align="left" data-field="form-subtitle-1">
            {formatMessage('form.subtitle_1', {
              b: (txt: string) => (
                <span className={classes.formBold} key={txt}>
                  {txt}
                </span>
              )
            })}
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="left" data-field="form-subtitle-2">
            {formatMessage('form.subtitle_2')}
          </Typography>
        </Grid>
        <Grid item className={classes.infoContent}>
          <Typography align="left" data-field="form-permission-text">
            {formatMessage('form.permission', {
              perm: (txt: string) => (
                <data
                  id="permission-text"
                  key={txt}
                  onClick={onTogglePermissionDialog}
                  role="presentation"
                  className={classes.formBlue}
                  data-action="permission-dialog-toggle"
                  ref={setAnchorEl}
                >
                  {txt}
                </data>
              )
            })}
          </Typography>
          {isPermissionDialogVisible && (
            <PermissionDialog
              title={permissionDialogtitle}
              appName={appName}
              onClose={onTogglePermissionDialog}
              anchorEl={anchorEl as HTMLElement}
            />
          )}
        </Grid>
        <Grid container item className={classes.formButtonContainer}>
          <Button
            href={`${config.emailProtectionStartTrialUrl}?ep_id=${salesLeadEpId}`}
            className={classes.formButtonSingleColumn}
            onClick={onStartTrial}
            variant="contained"
            data-action="start-trial"
            color="primary"
          >
            {formatMessage('form.trial_button')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            href={requestDemoPath}
            onClick={sendDemoRequestTrackingEvent}
            className={classes.formButtonSingleColumn}
            variant="outlined"
            data-action="send-demo-request"
            color="primary"
          >
            {formatMessage('form.demo')}
          </Button>
        </Grid>
      </Grid>
    ),
    [
      formatMessage,
      isPermissionDialogVisible,
      permissionDialogtitle,
      appName,
      onTogglePermissionDialog,
      anchorEl,
      classes.formButtonContainer,
      classes.formButtonSingleColumn,
      classes.formBold,
      classes.formBlue,
      classes.infoContent,
      salesLeadEpId,
      onStartTrial,
      requestDemoPath,
      sendDemoRequestTrackingEvent
    ]
  )
}

export default SigninSignupConnect
