import { createSlice } from '@reduxjs/toolkit'

import {
  CreateSecurityTrainingAddressBookApiResponse,
  GetSecurityTrainingInstancesApiResponse
} from 'fir/redux/types/SecurityTraining'

import { ApiStatus, failedResponse, inIdle, inProgress, successResponse } from 'global/redux/toolkit/api'

import {
  createSecurityTrainingAddressBook,
  getSecurityTrainingInstances
} from 'fir/redux/features/securityTraining/securityTrainingApiThunks'

export interface SecurityTrainingState {
  createdAddressBook: CreateSecurityTrainingAddressBookApiResponse
  createAddressBookApiStatus: ApiStatus
  getSecurityTrainingInstancesApiStatus: ApiStatus
  securityTrainingInstances: GetSecurityTrainingInstancesApiResponse[]
}

// Initial state
export const INITIAL_STATE: SecurityTrainingState = {
  createdAddressBook: { id: 0, securityTrainingCampaignLink: '' },
  createAddressBookApiStatus: inIdle,
  getSecurityTrainingInstancesApiStatus: inIdle,
  securityTrainingInstances: [{ name: '', active: false, enableProductIntegration: false }]
}

/* eslint-disable no-param-reassign */
export const securityTrainingSlice = createSlice({
  name: 'SECURITY_TRAINING',
  initialState: INITIAL_STATE,
  // reducers object creates an action and executes the reducer function
  reducers: {
    resetCreateAddressBook: state => {
      state.createAddressBookApiStatus = INITIAL_STATE.createAddressBookApiStatus
    }
  },
  // extraReducers do not create an action but executes the reducer function
  extraReducers: builder => {
    builder
      .addCase(createSecurityTrainingAddressBook.pending, state => {
        state.createdAddressBook = INITIAL_STATE.createdAddressBook
        state.createAddressBookApiStatus = inProgress
      })
      .addCase(createSecurityTrainingAddressBook.fulfilled, (state, action) => {
        state.createdAddressBook = action.payload
        state.createAddressBookApiStatus = successResponse
      })
      .addCase(createSecurityTrainingAddressBook.rejected, (state, action) => {
        state.createAddressBookApiStatus = failedResponse(action.payload)
      })
      .addCase(getSecurityTrainingInstances.pending, state => {
        state.securityTrainingInstances = INITIAL_STATE.securityTrainingInstances
        state.getSecurityTrainingInstancesApiStatus = inProgress
      })
      .addCase(getSecurityTrainingInstances.fulfilled, (state, action) => {
        state.securityTrainingInstances = action.payload
        state.getSecurityTrainingInstancesApiStatus = successResponse
      })
      .addCase(getSecurityTrainingInstances.rejected, (state, action) => {
        state.getSecurityTrainingInstancesApiStatus = failedResponse(action.payload)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { resetCreateAddressBook } = securityTrainingSlice.actions

export { createSecurityTrainingAddressBook, getSecurityTrainingInstances }

export default securityTrainingSlice.reducer
