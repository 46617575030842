import { useMemo } from 'react'

import { isPending, isSuccess, isFailed } from 'global/redux/toolkit/api'
import {
  EmailDetailsDialogProps,
  DialogActions,
  Analysis
} from 'global/components/lib/dialogs/emailDetailsDialog/emailDetailDialogTypes'
import { AttackType } from 'global/types/api/attackType'
import { StatusIds } from 'global/components/lib/statusTypeLabel/StatusIds.enum'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'
import { useAppDispatch, useAppSelector } from 'global/redux/toolkit/hooks'
import { reset as resetAttack, getSpAttack } from 'global/redux/features/attack/attackSlice'
import analyzeAttack from 'global/lib/analyzeAttack/analyzeAttack'

export type UseEmailDetailsSpAttackInterface = [
  EmailDetailsDialogProps,
  DialogActions<(attackId: AttackType['id']) => void>
]

export default function useEmailDetailsSpAttackInterface(): UseEmailDetailsSpAttackInterface {
  const { isAttackDetailsLoading, isAttackDetailsLoaded, isAttackDetailsFailed, attackDetails } = useAppSelector(
    _stores => ({
      isAttackDetailsLoading: isPending(_stores.attack.spAttackApiStatus),
      isAttackDetailsLoaded: isSuccess(_stores.attack.spAttackApiStatus),
      isAttackDetailsFailed: isFailed(_stores.attack.spAttackApiStatus),
      attackDetails: _stores.attack.details
    })
  )
  const [isDialogOpened, toggleDialog] = useDialogLogic()
  const dispatch = useAppDispatch()

  const analyzedAttack: Analysis | undefined = useMemo(() => {
    if (attackDetails) {
      return {
        ...analyzeAttack(attackDetails),
        statusId: StatusIds.emptyStatus,
        isStatusEmpty: true,
        isMarkedAsFp: false,
        hasRemediation: false,
        confidenceScore: undefined,
        severityScore: undefined
      }
    }

    return undefined
  }, [attackDetails])

  const dialogActions = useMemo(
    () => ({
      open: isDialogOpened,
      onOpen: (attackId: AttackType['id']) => {
        toggleDialog()
        dispatch(getSpAttack({ attackId }))
      },
      onClose: () => {
        dispatch(resetAttack())
        toggleDialog()
      }
    }),
    [dispatch, isDialogOpened, toggleDialog]
  )

  return useMemo(() => {
    return [
      {
        emailDetails: {
          subject: attackDetails.subject,
          from: attackDetails.sender,
          to: attackDetails.identity,
          replyTo: attackDetails.replyTo,
          date: attackDetails.date,
          headers: attackDetails.headers,
          bodyMimeType: attackDetails.bodyMimeType,
          body: attackDetails.body,
          attachments: attackDetails.attachments
        },
        emailDataStatuses: {
          inProgress: isAttackDetailsLoading,
          isLoaded: isAttackDetailsLoaded,
          isFailed: isAttackDetailsFailed
        },
        eventHandlers: {
          onClose: dialogActions.onClose,
          onFindMessagesInForensics: false
        },
        analysis: analyzedAttack
      },
      dialogActions
    ]
  }, [
    dialogActions,
    isAttackDetailsLoading,
    isAttackDetailsLoaded,
    isAttackDetailsFailed,
    attackDetails,
    analyzedAttack
  ])
}
