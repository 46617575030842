import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from 'global/configs/theme/defaults'
import dialogStyles from 'global/components/lib/dialogs/dialogStyles'

export default makeStyles(theme => {
  const defaultDialogStyles = dialogStyles(theme)
  return {
    ...defaultDialogStyles,
    title: {
      fontSize: 20,
      fontWeight: 500
    },
    subtitle: {
      fontSize: 14,
      color: COLORS.GRAY,
      lineHeight: 'normal'
    },
    actions: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  }
})
