import { useMemo, useCallback, useReducer } from 'react'

import { process } from '@progress/kendo-data-query'

import { SearchFieldProps, useSearchFieldLogic } from 'global/components/lib/searchField/SearchField'
import { BDSGridPagerConfig, BDSGridSortConfig } from 'global/types/dataTables/dataTables'
import { ATTACK_TYPES } from 'global/lib/attackTypeValidator/attackTypeValidator'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { config } from 'global/lib/config'
import { formatDateWithTime, isBeforeDate, luxonDate } from 'global/lib/datetime'
import { isPending } from 'global/redux/toolkit/api'

import { update as updateThreatsTable } from 'ets/redux/features/dataTables/threats/threatsSlice'
import { getThreatsReport, resetThreatsReport } from 'ets/redux/features/reports/threats/threatsSlice'
import { IsUserInputDisabledForTable } from 'ets/components/pages/dashboard/isUserInputDisabledForTableType'
import useEmailDetailsSpAttackInterface, {
  UseEmailDetailsSpAttackInterface
} from 'ets/components/lib/dialogs/emailDetailsDialog/interfaces/useEmailDetailsSpAttackInterface'

import {
  TABLE_CELL_HEIGHT,
  TABLE_HEADER_HEIGHT,
  TABLE_FOOTER_HEIGHT
} from 'ets/components/pages/dashboard/threats/dashboardThreatsStyles'
import { useAppDispatch, useAppSelector } from 'ets/redux/toolkit/hooks'
import { ColumnsConfig } from 'ets/redux/types/dataTables'

const SEARCH_FIELD_ID = 'threats-search'

export interface SelectedAttack {
  id: string
  formattedDate: string
}

export interface UseDashboardThreatsLogic {
  attackSelectorConfig: any
  columnsConfig: ColumnsConfig
  GRID_COLUMNS: {
    [key: string]: string
  }
  highlightKeywords: string[]
  inProgress: boolean
  isReportLoaded: boolean
  isUserInputDisabled: boolean
  isViewEmailsDisabled: boolean
  pageConfig: BDSGridPagerConfig
  searchFieldConfig: SearchFieldProps
  selectedAttack: SelectedAttack | null
  selectedUser: string | null
  sortConfig: BDSGridSortConfig | {}
  tableData: {
    total: number
    data: any[]
  }
  tableTotal: number | string
}

export interface UseDashboardThreatsLogicProps {
  isUserInputDisabledForTable: IsUserInputDisabledForTable
  fixTableHeight: (tableId: string, newHeight: number) => void
}

export default function useDashboardThreatsLogic(
  props: UseDashboardThreatsLogicProps
): [UseDashboardThreatsLogic, UseEmailDetailsSpAttackInterface] {
  const { isUserInputDisabledForTable, fixTableHeight } = props
  const dispatch = useAppDispatch()
  const {
    accessTokenId,
    finishedOn,
    inProgress,
    isReportLoaded,
    loadedOffsets,
    reportData,
    threatsTable
  } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id,
    finishedOn: _stores.scan.stats.finishedOn,
    inProgress: isPending(_stores.reports.threats.apiStatus),
    isReportLoaded: !!_stores.reports.threats.data.accessTokenId,
    loadedOffsets: _stores.reports.threats.loadedOffsets,
    reportData: _stores.reports.threats.data,
    threatsTable: _stores.dataTables.threats
  }))
  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    searchString: '',
    selectedAttack: null,
    isAttackSelectorOpened: false,
    selectedAttackType: threatsTable.ALL_THREATS
  })
  const [emailDetailDialogConfig, emailDetailDialogActions] = useEmailDetailsSpAttackInterface()

  const openAttackDetailDialog = useCallback(
    (attackId: string) => {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.VIEW_THREATS_DETAILS, { accessTokenId })
      emailDetailDialogActions.onOpen(attackId)
    },
    [accessTokenId, emailDetailDialogActions]
  )

  const updateTableData = useCallback(() => {
    dispatch(updateThreatsTable({ skip: 0 }))
    dispatch(resetThreatsReport())
    dispatch(getThreatsReport())
  }, [dispatch])

  const isUserInputDisabled = useMemo(() => {
    return isUserInputDisabledForTable(threatsTable, reportData, isReportLoaded) || inProgress
  }, [reportData, isReportLoaded, threatsTable, isUserInputDisabledForTable, inProgress])

  const isViewEmailsDisabled = useMemo(() => {
    const maxRetention = luxonDate()
      .minus({ days: config.MAX_EMAIL_RETENTION_DAYS })
      .toISO()
    return isBeforeDate({ initialDate: finishedOn, subtractedDate: maxRetention })
  }, [finishedOn])

  const [debouncedOnChange, validateSearchString] = useSearchFieldLogic(
    (search: string) => {
      dispatch(updateThreatsTable({ search: search.trim() }))
      setState({ searchString: search.trim() })
      updateTableData()
    },
    inProgress,
    SEARCH_FIELD_ID
  )

  const searchFieldConfig = useMemo(() => {
    return {
      id: SEARCH_FIELD_ID,
      value: state.searchString,
      onChange: (e: any) => {
        const validatedSearchString = validateSearchString(e.target.value)
        if (threatsTable.search !== validatedSearchString) {
          debouncedOnChange(validatedSearchString)
          setState({ searchString: validatedSearchString })
        }
      },
      disabled: isUserInputDisabled
    }
  }, [state, isUserInputDisabled, threatsTable, validateSearchString, debouncedOnChange])

  const tableTotal = useMemo(() => {
    return isReportLoaded ? reportData.report.totalCount : threatsTable.DEMO_DATA.ALL
  }, [reportData, isReportLoaded, threatsTable])

  const tableLabelTotal = useMemo(() => {
    if (isReportLoaded && threatsTable.skip === 0 && inProgress) {
      return '-'
    }

    return tableTotal
  }, [isReportLoaded, threatsTable.skip, inProgress, tableTotal])

  const tableData = useMemo(() => {
    const { skip, take } = threatsTable

    const { data } = process(
      (reportData.report.data || []).map((report: any) => ({
        ...(report && {
          ...report,
          formattedDate: formatDateWithTime(report.date),
          seeDetails: openAttackDetailDialog
        })
      })),
      { skip, take }
    )

    const fixedCount = Math.max(reportData.report.totalCount, 4)
    const newHeight =
      fixedCount <= threatsTable.ITEMS_PER_PAGE
        ? fixedCount * TABLE_CELL_HEIGHT + TABLE_HEADER_HEIGHT
        : TABLE_CELL_HEIGHT * threatsTable.ITEMS_PER_PAGE + TABLE_HEADER_HEIGHT + TABLE_FOOTER_HEIGHT

    fixTableHeight('threats', newHeight)

    return {
      data: data.filter(report => report.id),
      total: tableTotal
    }
  }, [reportData, openAttackDetailDialog, threatsTable, tableTotal, fixTableHeight])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = threatsTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: tableData.total,
      onPageChange: (e: any) => {
        dispatch(updateThreatsTable(e.page))

        if (!loadedOffsets.includes(e.page.skip)) {
          dispatch(getThreatsReport())
        }
      }
    }
  }, [threatsTable, tableData, dispatch, loadedOffsets])

  const sortConfig: BDSGridSortConfig | {} = useMemo(() => {
    if (!tableData.total) {
      return {}
    }

    return {
      sortable: !isUserInputDisabled && {
        allowUnsort: false,
        mode: 'single'
      },
      sort: threatsTable.sort,
      onSortChange: (e: any) => {
        dispatch(updateThreatsTable({ sort: e.sort }))
        updateTableData()
      }
    }
  }, [threatsTable, dispatch, tableData.total, isUserInputDisabled, updateTableData])

  const attackSelectorConfig = useMemo(() => {
    const dashboardMenu = document.querySelector('[data-menu="true"]')
    const onCloseAttackSelector = () => {
      setState({ isAttackSelectorOpened: false })
      if (dashboardMenu) {
        dashboardMenu.removeEventListener('click', onCloseAttackSelector)
      }
    }

    return {
      menuItems: [
        {
          id: threatsTable.ALL_THREATS,
          isSelected: state.selectedAttackType === threatsTable.ALL_THREATS
        },
        {
          id: threatsTable.ADVANCED_THREATS,
          isSelected: state.selectedAttackType === threatsTable.ADVANCED_THREATS
        },
        ...Object.values(ATTACK_TYPES).map((attackType: string) => ({
          id: attackType,
          isSelected: state.selectedAttackType === attackType
        }))
      ],
      open: state.isAttackSelectorOpened,
      onOpen: () => {
        setState({ isAttackSelectorOpened: true })
        if (dashboardMenu) {
          dashboardMenu.addEventListener('click', onCloseAttackSelector)
        }
      },
      onClose: onCloseAttackSelector,
      disabled: isUserInputDisabled,
      onChange: (e: any) => {
        if (state.selectedAttackType !== e.target.value) {
          setState({ selectedAttackType: e.target.value })
          dispatch(
            updateThreatsTable({
              filter: threatsTable.FILTER_CONFIG[e.target.value].filterQuery
            })
          )
          updateTableData()
        }
      },
      value: state.selectedAttackType
    }
  }, [
    state.selectedAttackType,
    state.isAttackSelectorOpened,
    threatsTable.FILTER_CONFIG,
    dispatch,
    threatsTable.ALL_THREATS,
    threatsTable.ADVANCED_THREATS,
    updateTableData,
    isUserInputDisabled
  ])

  return useMemo(() => {
    return [
      {
        attackSelectorConfig,
        columnsConfig: threatsTable.columnsConfig,
        GRID_COLUMNS: threatsTable.GRID_COLUMNS,
        highlightKeywords: [threatsTable.search],
        inProgress,
        isReportLoaded,
        isUserInputDisabled,
        isViewEmailsDisabled,
        pageConfig,
        searchFieldConfig,
        selectedAttack: state.selectedAttack,
        selectedUser: state.selectedUser,
        sortConfig,
        tableTotal: tableLabelTotal,
        tableData
      },
      [emailDetailDialogConfig, emailDetailDialogActions]
    ]
  }, [
    attackSelectorConfig,
    threatsTable.columnsConfig,
    threatsTable.GRID_COLUMNS,
    threatsTable.search,
    inProgress,
    isReportLoaded,
    isUserInputDisabled,
    isViewEmailsDisabled,
    pageConfig,
    searchFieldConfig,
    state.selectedAttack,
    state.selectedUser,
    sortConfig,
    tableLabelTotal,
    tableData,
    emailDetailDialogConfig,
    emailDetailDialogActions
  ])
}
