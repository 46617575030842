import { useMemo } from 'react'

import { isFailed } from 'global/redux/toolkit/api'

import { useAppSelector } from 'admin/redux/toolkit/hooks'
import routesConfig from 'admin/lib/routes/routesConfig'

export default function useDashboardLogic(): [boolean] {
  const { urlParams, impersonateFailed, deactivateFailed, serialUpdateStatus } = useAppSelector(_stores => ({
    urlParams: _stores.app.activePath || {},
    impersonateFailed: isFailed(_stores.admin.impersonateApiStatus),
    deactivateFailed: isFailed(_stores.admin.deactivateApiStatus),
    serialUpdateStatus: isFailed(_stores.admin.setSentinelSerialNumberApiStatus)
  }))

  return useMemo(() => {
    const error =
      impersonateFailed ||
      deactivateFailed ||
      (serialUpdateStatus && urlParams.id !== routesConfig.DASHBOARD_INCIDENT_RESPONSE.id)
    return [error]
  }, [urlParams, impersonateFailed, deactivateFailed, serialUpdateStatus])
}
