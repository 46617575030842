import React, { useMemo } from 'react'

import { View, Text, StyleSheet, Image, Link } from '@react-pdf/renderer'

import baseStyles from 'ets/components/lib/PDFReport/pdfReportStyles'

import IMAGES from 'ets/configs/theme/images.config'

export interface PDFFooterProps {
  reportUrl: string
  page?: number
  intl?: any
}

const styles = StyleSheet.create({
  footerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    bottom: 0,
    left: 20,
    right: 20,
    paddingBottom: 20
  },
  logoWrapper: { width: '25%', order: 1, height: 20 },
  logo: { width: '80%' },
  linkWrapper: {
    display: 'flex',
    flexDirection: 'row',
    height: 20,
    width: '50%',
    order: 2,
    paddingLeft: 45,
    paddingRight: 25
  },
  linkText: {
    ...baseStyles.darkText,
    order: 1,
    fontSize: 8,
    height: 20,
    marginTop: 5
  },
  link: {
    fontFamily: 'Roboto',
    textOverflow: 'ellipsis',
    order: 2,
    fontSize: 8,
    height: 20,
    marginTop: 5,
    width: 'auto',
    maxLines: 1
  },
  pageNumber: {
    ...baseStyles.darkText,
    fontSize: 8,
    width: '25%',
    order: 3,
    textAlign: 'right',
    height: 20,
    marginTop: 5
  }
})

const TOTAL_PAGES = 5

const BASE_I18N_KEY = 'ets.app.pdf.footer'

/* eslint-disable jsx-a11y/anchor-is-valid */
const PDFFooter: React.FC<PDFFooterProps> = ({ reportUrl, page, intl }) => {
  return useMemo(() => {
    return (
      <View style={styles.footerWrapper}>
        <View style={styles.logoWrapper}>
          <Image style={styles.logo} src={IMAGES.barracudaETSForDarkPng} />
        </View>
        <View style={styles.linkWrapper}>
          <Text style={styles.linkText}>
            {`${intl.formatMessage({ id: `${BASE_I18N_KEY}.for_detailed_report` })}: `}
          </Text>
          <Link style={styles.link} src={reportUrl}>
            {reportUrl}
          </Link>
        </View>
        <Text style={styles.pageNumber}>{`${intl.formatMessage({
          id: `${BASE_I18N_KEY}.page`
        })} ${page}/${TOTAL_PAGES}`}</Text>
      </View>
    )
  }, [reportUrl, page, intl])
}
/* eslint-enable jsx-a11y/anchor-is-valid */

export default PDFFooter
