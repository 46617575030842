import { makeStyles } from '@material-ui/core/styles'

import tableStyles, {
  CELL_HEIGHT,
  TABLE_HEADER_HEIGHT,
  TABLE_FOOTER_HEIGHT
} from 'global/components/lib/grid/tableStyles'
import dialogStyles from 'global/components/lib/dialogs/dialogStyles'

export { TABLE_HEADER_HEIGHT, TABLE_FOOTER_HEIGHT }
export const TABLE_CELL_HEIGHT = CELL_HEIGHT
const TABLE_MIN_HEIGHT = TABLE_CELL_HEIGHT * 5 + TABLE_HEADER_HEIGHT + TABLE_FOOTER_HEIGHT

export const RANGE_TABLE_MIN_HEIGHT = TABLE_CELL_HEIGHT * 3 + TABLE_HEADER_HEIGHT + TABLE_FOOTER_HEIGHT

export default makeStyles(theme => {
  const defaultTableStyles = tableStyles(theme)
  const defaultDialogStyles = dialogStyles(theme)

  return {
    ...defaultTableStyles,
    ...defaultDialogStyles,
    tableArea: {
      ...defaultTableStyles.tableArea,
      marginTop: theme.spacing(2),
      minHeight: TABLE_MIN_HEIGHT
    },
    dataTable: {
      ...defaultTableStyles.dataTable,
      height: TABLE_MIN_HEIGHT
    },
    rangeTableArea: {
      ...defaultTableStyles.tableArea,
      minHeight: RANGE_TABLE_MIN_HEIGHT
    },
    rangeDataTable: {
      ...defaultTableStyles.dataTable,
      height: RANGE_TABLE_MIN_HEIGHT
    }
  }
})
