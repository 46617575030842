import { makeStyles } from '@material-ui/core/styles'

export const FIELD_HEIGHT = 40

export default makeStyles(() => ({
  root: {
    height: FIELD_HEIGHT,
    backgroundColor: '#EFEFEF',
    display: 'flex'
  },
  inputField: {
    marginLeft: 12,
    marginRight: 12,
    flex: 1
  }
}))
