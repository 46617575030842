import { RemediationEmailFields } from '@barracuda/reporting'

import { Column } from 'global/types/api/unifiedReporting'

export type RemediationEmailIncident = {
  uuid: string
  createdOn: string
  accountId: string
  accessTokenId: string
  subjectQuery: string
  senderDisplayName: string
  senderEmail: string
  createdBy?: string
  type: string
  attachmentQuery?: string
  createdByName?: string
  bodyTextQuery?: string
  bodyLinksQuery?: string
}

export type RemediationEmail = {
  senderDomain?: string
  attachmentCount: number
  clickedOnLinks: string
  totalClickedLinks?: number
  recipientEmail: string
  recipientName: string
  isInternal: boolean
  internetMessageId: string
  status: string
  isRead: string // should this be a boolean?
  emailId?: string
  senderEmail?: string
  dateSent?: string
  subject?: string
  notified?: string
  clickedLinks?: string
  attachments?: string // should this be an array?
  isContinuousRemediation: boolean
  forwarded: string
  replied: string
  direction: string
  incident: RemediationEmailIncident
}

export const remediationEmailColumnConfig: Column[] = [
  {
    accessorKey: RemediationEmailFields.DATE_SENT.name,
    header: 'date_sent',
    fieldType: RemediationEmailFields.DATE_SENT.type,
    possibleValues: RemediationEmailFields.DATE_SENT.possibleValues,
    category: 'barracuda',
    hideColumnFromList: true
  },
  {
    accessorKey: RemediationEmailFields.INCIDENT_CREATED_ON.name,
    header: 'incident_created_on',
    fieldType: RemediationEmailFields.INCIDENT_CREATED_ON.type,
    possibleValues: RemediationEmailFields.INCIDENT_CREATED_ON.possibleValues,
    category: 'barracuda'
  },
  {
    accessorKey: RemediationEmailFields.INCIDENT_CREATED_BY.name,
    header: 'incident_created_by',
    fieldType: RemediationEmailFields.INCIDENT_CREATED_BY.type,
    possibleValues: RemediationEmailFields.INCIDENT_CREATED_BY.possibleValues,
    category: 'barracuda'
  },
  {
    accessorKey: RemediationEmailFields.STATUS.name,
    header: 'status',
    fieldType: RemediationEmailFields.STATUS.type,
    possibleValues: RemediationEmailFields.STATUS.possibleValues,
    category: 'barracuda'
  },
  {
    accessorKey: RemediationEmailFields.INCIDENT_CREATED_BY_NAME.name,
    header: 'incident_created_by_name',
    fieldType: RemediationEmailFields.INCIDENT_CREATED_BY_NAME.type,
    possibleValues: RemediationEmailFields.INCIDENT_CREATED_BY_NAME.possibleValues,
    category: 'barracuda'
  },
  {
    accessorKey: RemediationEmailFields.IS_CONTINUOUS_REMEDIATION.name,
    header: 'is_continuous_remediation',
    fieldType: RemediationEmailFields.IS_CONTINUOUS_REMEDIATION.type,
    possibleValues: RemediationEmailFields.IS_CONTINUOUS_REMEDIATION.possibleValues,
    category: 'barracuda'
  },
  {
    accessorKey: RemediationEmailFields.NOTIFIED.name,
    header: 'notified',
    fieldType: RemediationEmailFields.NOTIFIED.type,
    possibleValues: RemediationEmailFields.NOTIFIED.possibleValues,
    category: 'barracuda'
  },
  {
    accessorKey: RemediationEmailFields.INCIDENT_UUID.name,
    header: 'incident_uuid',
    fieldType: RemediationEmailFields.INCIDENT_UUID.type,
    possibleValues: RemediationEmailFields.INCIDENT_UUID.possibleValues,
    category: 'barracuda'
  },
  {
    accessorKey: RemediationEmailFields.RECIPIENT_EMAIL.name,
    header: 'recipient_email',
    fieldType: RemediationEmailFields.RECIPIENT_EMAIL.type,
    possibleValues: RemediationEmailFields.RECIPIENT_EMAIL.possibleValues,
    category: 'recipient'
  },
  {
    accessorKey: RemediationEmailFields.RECIPIENT_NAME.name,
    header: 'recipient_name',
    fieldType: RemediationEmailFields.RECIPIENT_NAME.type,
    possibleValues: RemediationEmailFields.RECIPIENT_NAME.possibleValues,
    category: 'recipient'
  },
  {
    accessorKey: RemediationEmailFields.CLICKED_ON_LINKS.name,
    header: 'clicked_on_links',
    fieldType: RemediationEmailFields.CLICKED_ON_LINKS.type,
    possibleValues: RemediationEmailFields.CLICKED_ON_LINKS.possibleValues,
    category: 'recipient'
  },
  {
    accessorKey: RemediationEmailFields.TOTAL_CLICKED_LINKS.name,
    header: 'total_clicked_links',
    fieldType: RemediationEmailFields.TOTAL_CLICKED_LINKS.type,
    possibleValues: RemediationEmailFields.TOTAL_CLICKED_LINKS.possibleValues,
    category: 'recipient'
  },
  {
    accessorKey: RemediationEmailFields.CLICKED_LINKS.name,
    header: 'clicked_links',
    fieldType: RemediationEmailFields.CLICKED_LINKS.type,
    possibleValues: RemediationEmailFields.CLICKED_LINKS.possibleValues,
    category: 'recipient'
  },
  {
    accessorKey: RemediationEmailFields.IS_READ.name,
    header: 'opened_the_email',
    fieldType: RemediationEmailFields.IS_READ.type,
    possibleValues: RemediationEmailFields.IS_READ.possibleValues,
    category: 'recipient'
  },
  {
    accessorKey: RemediationEmailFields.FORWARDED.name,
    header: 'forwarded_the_email',
    fieldType: RemediationEmailFields.FORWARDED.type,
    possibleValues: RemediationEmailFields.FORWARDED.possibleValues,
    category: 'recipient'
  },
  {
    accessorKey: RemediationEmailFields.REPLIED.name,
    header: 'replied_to_the_email',
    fieldType: RemediationEmailFields.REPLIED.type,
    possibleValues: RemediationEmailFields.REPLIED.possibleValues,
    category: 'recipient'
  },
  {
    accessorKey: RemediationEmailFields.SENDER_EMAIL.name,
    header: 'from_address',
    fieldType: RemediationEmailFields.SENDER_EMAIL.type,
    possibleValues: RemediationEmailFields.SENDER_EMAIL.possibleValues,
    category: 'sender'
  },
  {
    accessorKey: RemediationEmailFields.SENDER_DOMAIN.name,
    header: 'sender_domain',
    fieldType: RemediationEmailFields.SENDER_DOMAIN.type,
    possibleValues: RemediationEmailFields.SENDER_DOMAIN.possibleValues,
    category: 'sender'
  },
  {
    accessorKey: RemediationEmailFields.SUBJECT.name,
    header: 'subject',
    fieldType: RemediationEmailFields.SUBJECT.type,
    possibleValues: RemediationEmailFields.SUBJECT.possibleValues,
    category: 'email'
  },
  {
    accessorKey: RemediationEmailFields.DIRECTION.name,
    header: 'email_flow',
    fieldType: RemediationEmailFields.DIRECTION.type,
    possibleValues: RemediationEmailFields.DIRECTION.possibleValues,
    category: 'email'
  },
  {
    accessorKey: RemediationEmailFields.ATTACHMENT_COUNT.name,
    header: 'total_attachments',
    fieldType: RemediationEmailFields.ATTACHMENT_COUNT.type,
    possibleValues: RemediationEmailFields.ATTACHMENT_COUNT.possibleValues,
    category: 'email'
  },
  {
    accessorKey: RemediationEmailFields.ATTACHMENTS.name,
    header: 'attachments',
    fieldType: RemediationEmailFields.ATTACHMENTS.type,
    possibleValues: RemediationEmailFields.ATTACHMENTS.possibleValues,
    category: 'email'
  },
  {
    accessorKey: RemediationEmailFields.EMAIL_ID.name,
    header: 'email_id',
    fieldType: RemediationEmailFields.EMAIL_ID.type,
    possibleValues: RemediationEmailFields.EMAIL_ID.possibleValues,
    category: 'email'
  },
  {
    accessorKey: RemediationEmailFields.INTERNET_MESSAGE_ID.name,
    header: 'internet_message_id',
    fieldType: RemediationEmailFields.INTERNET_MESSAGE_ID.type,
    possibleValues: RemediationEmailFields.INTERNET_MESSAGE_ID.possibleValues,
    category: 'email'
  },
  {
    accessorKey: RemediationEmailFields.INCIDENT_SENDER_EMAIL.name,
    header: 'incident_sender_email',
    fieldType: RemediationEmailFields.INCIDENT_SENDER_EMAIL.type,
    possibleValues: RemediationEmailFields.INCIDENT_SENDER_EMAIL.possibleValues,
    category: 'search_criteria'
  },
  {
    accessorKey: RemediationEmailFields.INCIDENT_SENDER_DISPLAY_NAME.name,
    header: 'incident_sender_display_name',
    fieldType: RemediationEmailFields.INCIDENT_SENDER_DISPLAY_NAME.type,
    possibleValues: RemediationEmailFields.INCIDENT_SENDER_DISPLAY_NAME.possibleValues,
    category: 'search_criteria'
  },
  {
    accessorKey: RemediationEmailFields.INCIDENT_SUBJECT_QUERY.name,
    header: 'incident_subject_query',
    fieldType: RemediationEmailFields.INCIDENT_SUBJECT_QUERY.type,
    possibleValues: RemediationEmailFields.INCIDENT_SUBJECT_QUERY.possibleValues,
    category: 'search_criteria'
  },
  {
    accessorKey: RemediationEmailFields.INCIDENT_BODY_TEXT_QUERY.name,
    header: 'incident_body_text_query',
    fieldType: RemediationEmailFields.INCIDENT_BODY_TEXT_QUERY.type,
    possibleValues: RemediationEmailFields.INCIDENT_BODY_TEXT_QUERY.possibleValues,
    category: 'search_criteria'
  },
  {
    accessorKey: RemediationEmailFields.INCIDENT_BODY_LINKS_QUERY.name,
    header: 'incident_body_links_query',
    fieldType: RemediationEmailFields.INCIDENT_BODY_LINKS_QUERY.type,
    possibleValues: RemediationEmailFields.INCIDENT_BODY_LINKS_QUERY.possibleValues,
    category: 'search_criteria'
  },
  {
    accessorKey: RemediationEmailFields.INCIDENT_ATTACHMENT_QUERY.name,
    header: 'incident_attachment_query',
    fieldType: RemediationEmailFields.INCIDENT_ATTACHMENT_QUERY.type,
    possibleValues: RemediationEmailFields.INCIDENT_ATTACHMENT_QUERY.possibleValues,
    category: 'search_criteria'
  }
]
