import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Box, Button, Tooltip } from '@barracuda-internal/bds-core'
import { Download } from '@barracuda-internal/bds-core/dist/Icons/Core'

import useExportToCsvButtonLogic, {
  ExportToCsvButtonLogicProps
} from 'global/components/lib/exportToCsvButton/useExportToCsvButtonLogic'
import styles from 'global/components/lib/exportToCsvButton/exportToCsvButtonStyles'
import { useFormatMessage } from 'global/lib/localization'

export interface ExportToCsvButtonProps extends ExportToCsvButtonLogicProps {
  className?: string
}

const BASE_I18N_KEY = 'app.export_to_csv'

export const ExportToCsvButton: React.FC<ExportToCsvButtonProps> = ({ className = '', ...logicProps }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const [srcPath, onClick, shouldShowTooltip, exportLimit] = useExportToCsvButtonLogic(logicProps)

  return useMemo(
    () => (
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        enterDelay={100}
        title={
          shouldShowTooltip
            ? formatMessage('export_tooltip', {
                exportLimit: exportLimit.toLocaleString()
              })
            : ''
        }
        placement="top-end"
      >
        <Box>
          {/* Empty wrapper is added because of BDS Button forwardRef related console issues */}
          <Button
            className={className}
            disabled={!!srcPath || !logicProps.totalCount}
            color="primary"
            variant="text"
            onClick={onClick}
            data-field="export-csv-button"
          >
            <iframe id="export-mailboxes" style={{ display: 'none' }} src={srcPath} title="export-mailboxes" />
            <Download />
            &nbsp;&nbsp;
            {formatMessage('title')}
            &nbsp;&nbsp;
          </Button>
        </Box>
      </Tooltip>
    ),
    [classes.tooltip, shouldShowTooltip, formatMessage, exportLimit, className, srcPath, logicProps.totalCount, onClick]
  )
}

ExportToCsvButton.propTypes = {
  getExportPath: PropTypes.func.isRequired,
  exportName: PropTypes.string.isRequired,
  analyticsParams: PropTypes.any.isRequired
}

export default ExportToCsvButton
