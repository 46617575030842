const btnPadding = {
  paddingBottom: 0,
  paddingLeft: 8,
  paddingRight: 8,
  paddingTop: 0
}

export default {
  root: {
    ...btnPadding,
    borderRadius: 2,
    height: 32,
    whiteSpace: 'nowrap' as const // typescript work-around
  },
  sizeSmall: {
    ...btnPadding,
    fontSize: '.75rem',
    height: 24
  },
  sizeLarge: {
    ...btnPadding,
    fontSize: '.875rem',
    height: 40
  },
  text: {
    ...btnPadding
  },
  startIcon: {
    marginRight: 4
  },
  endIcon: {
    marginLeft: 4
  }
}
